import React, { useState } from "react";
import { Table } from "semantic-ui-react";
import _ from "lodash";
import SortIcon from "@mui/icons-material/Sort";

const GenericTable = ({ data = [], headerOrder = [], columnConfigs = {}, isComparePrevious = false, atLastTotalCount = false }) => {
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

    const handleSort = (key) => {
        let direction = "asc";
        if (sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({ key, direction });
    };

    const getNestedValue = (obj, path) => {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    };

    const sortedData = () => {
        if (sortConfig.key && _.size(data) > 0) {
            const columnConfig = columnConfigs[sortConfig.key];
            const sortByPath = columnConfig?.sortBy;
            const sorted = [...data].sort((a, b) => {
                const aValue = getNestedValue(a, sortByPath);
                const bValue = getNestedValue(b, sortByPath);

                // Handle non-numeric fields like organization_type, headquarter_country, etc.
                if(sortByPath !== 'ctr') {
                    if (typeof aValue === 'string' && typeof bValue === 'string') {
                        const comparison = aValue.localeCompare(bValue); // Compare strings alphabetically
                        return sortConfig.direction === 'asc' ? comparison : -comparison;
                    }
                }
    
                // Handle numeric sorting (as previously)
                const aNumericValue = parseFloat(aValue);
                const bNumericValue = parseFloat(bValue);
    
                if (isNaN(aNumericValue) || isNaN(bNumericValue)) {
                    // Handle cases where conversion fails (non-numeric strings)
                    return 0;
                }
    
                if (aNumericValue < bNumericValue) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (aNumericValue > bNumericValue) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
    
                return 0;
            });
            return sorted;
        }
        return data;
    };
    

    const calculateTotals = () => {
        const totals = {};

        if (!Array.isArray(data)) {
            return null; // handle error appropriately
        }
        
        if (!Array.isArray(headerOrder) && Array.isArray(data)) {
            return totals; // handle error appropriately
        }

        headerOrder.forEach((header) => {
            const columnConfig = columnConfigs[header.headerKey];
            if (columnConfig?.sum) {
                if (isComparePrevious && header.hasCompare) {
                    totals[header.headerKey] = {
                        current: data.reduce((acc, row) => {
                            const value = parseFloat(row[header.headerKey]) || 0;
                            return acc + value;
                        }, 0),
                        previous: data.reduce((acc, row) => {
                            const value = parseFloat(row[header.previousHeaderKey]) || 0;
                            return acc + value;
                        }, 0)
                    };
                } else {
                    totals[header.headerKey] = data.reduce((acc, row) => {
                        const value = parseFloat(row[header.headerKey]) || 0;
                        return acc + value;
                    }, 0);
                }
            }
        });

        return totals;
    };

    const totals = calculateTotals();

    const renderCellContent = (columnConfig, rowData, key) => {
        if (columnConfig.component) {
            const CustomComponent = columnConfig.component;
            return <CustomComponent data={rowData} keyMappings={columnConfig?.keyMappings} key={key} textAlign={columnConfig.textAlign}/>;
        }
        if (columnConfig.render) {
            return <Table.Cell key={key} title={columnConfig.render(rowData[key])} textAlign={columnConfig.textAlign}>{columnConfig.render(rowData[key])}</Table.Cell>;
        }
        return <Table.Cell key={key} title={rowData[key]} textAlign={columnConfig.textAlign}>{rowData[key]}</Table.Cell>;
    };

    const renderCurrentPreCellContent = (columnConfig, rowData, key, preKey) => {
        if (columnConfig.render) {
            return [
                <Table.Cell key={`${key}-current`} title={rowData[key]} textAlign="center">{rowData[key]}</Table.Cell>,
                <Table.Cell key={`${preKey}-previous`} title={rowData[preKey]} textAlign="center">{rowData[preKey]}</Table.Cell>
            ];
        }
    }

    const renderTotalRow = () => (
        <Table.Row>
            {headerOrder.map((val, index) => {
                if (isComparePrevious && val.hasCompare) {
                    return (
                        <React.Fragment key={index}>
                            <Table.Cell textAlign="center">
                                {totals[val.headerKey]?.current || "-"}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                {totals[val.headerKey]?.previous || "-"}
                            </Table.Cell>
                        </React.Fragment>
                    );
                } else {
                    return (
                        <Table.Cell key={index} textAlign={isNaN(totals[val.headerKey]) ? "left" : "right"}>
                            {index === 0 ? "Total:" : totals[val.headerKey] || "-"}
                        </Table.Cell>
                    );
                }
            })}
        </Table.Row>
    );

    const renderTableHeader = () => {
        return (
            <Table.Row>
                {headerOrder.map((val, index) => {
                    const columnConfig = columnConfigs[val.headerKey];
                    const isSortable = columnConfig?.sortable || false;

                    if(val.hasCompare && isComparePrevious) {
                        return (
                            <Table.HeaderCell 
                                key={index} 
                                textAlign="center"
                                colSpan={2}
                                style={{ verticalAlign: "middle", borderBottom: "none" }}
                            >
                                {val.headerName}
                                <Table>
                                    <Table.Row>
                                    <Table.HeaderCell
                                        onClick={() => isSortable && handleSort(val.headerKey)}
                                        className={`${columnConfig?.className || ""} ${isSortable && sortConfig.key === val.headerKey ? (sortConfig.direction === "asc" ? "ascending sorted" : "descending sorted") : ""}`}
                                        style={{ borderBottom: "none" }}
                                        textAlign="center"
                                    >
                                        Current
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        onClick={() => isSortable && handleSort(val.previousHeaderKey)}
                                        className={`${columnConfig?.className || ""} ${isSortable && sortConfig.key === val.previousHeaderKey ? (sortConfig.direction === "asc" ? "ascending sorted" : "descending sorted") : ""}`}
                                        style={{ borderBottom: "none" }}
                                        textAlign="center"
                                    >
                                        Previous
                                    </Table.HeaderCell>
                                    </Table.Row>
                                </Table>
                            </Table.HeaderCell>
                        )
                    }

                    return (
                        <Table.HeaderCell 
                            key={index} 
                            onClick={() => isSortable && handleSort(val.headerKey)}
                            className={`${columnConfig?.className || ""} ${isSortable && sortConfig.key === val.headerKey ? (sortConfig.direction === "asc" ? "ascending sorted" : "descending sorted") : ""}`}
                            textAlign={columnConfig?.textAlign}
                        >
                            {val.headerName === "sort_roadblock_icon" ? <SortIcon/> : val.headerName}
                        </Table.HeaderCell>
                    );
                })}
            </Table.Row>
        );
    };

    const renderTableBody = () => {
        const sorted = sortedData();
        if (!Array.isArray(sorted)) {
            return null; // or handle the error appropriately
        }

        return (
            <>
                {sorted.map((row, rowIndex) => (
                    <Table.Row key={rowIndex}>
                        {headerOrder.map((val, index) => {
                            const columnConfig = columnConfigs[val.headerKey];
                            
                            if (!columnConfig) {
                                return null; // or handle the error appropriately
                            }

                            if(val.hasCompare && isComparePrevious) {
                                return renderCurrentPreCellContent(columnConfig, row, val.headerKey, val.previousHeaderKey)
                            }
                            return renderCellContent(columnConfig, row, val.headerKey)
                        })}
                    </Table.Row>
                ))}
                { atLastTotalCount && renderTotalRow()}
            </>
        );
    };

    return (
        <React.Fragment>
            <Table.Header>
                {renderTableHeader()}
            </Table.Header>
            <Table.Body>
                {renderTableBody()}
            </Table.Body>
        </React.Fragment>
    );
};

export default GenericTable;