const initialState = [];

const urlParamsReducer = (actionTypes, selKey) => (state = initialState, action) => {
   switch (action.type) {
      case actionTypes.FETCH:
         return action.payload;
      case actionTypes.CLEAR:
         return initialState;
      case actionTypes.SELECT:
         return action[selKey];
      default:
         return state;
   }
}

export default urlParamsReducer;