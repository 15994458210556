export const url = {
  searchOrganization: "/organizations/search",
  engagedOrganization: "engaged_organization/search",
  searchCampaigns: "/campaigns/search",
  searchAdOrders: "/filters/ads",
  searchOrders: "/reports/orders",
  filterConstants: "/lists",
  adCampaignReport: "/ad_campaigns",
  adImpressionReport: "/runs",
  adReportCharts: "/reports/runs/charts",
  leadEngagementCount: "/reports/engagement/count",
  leadEngagementSummaryReport: "/lead_engagement_summary/search",
  leadEngagementReport: "/leadengagements/search",
  tags: "/tags/search",
  pageTitle: "page_detail/search",
  urlParams: "/url_params/search",
  topicHubDetail: "/reports/sidebar/detail",
  topicHubSummary: "/reports/sidebar/summary",
  clientLeadEngsReport: "/lead_engagement_client/search",
  searchAuthors: "/authors/search",
  searchCountry: "/country/search",
  topicPageviewSummary: "reports/pageviews/summary/topic_tags",
  placementPageviewSummary: "/reports/pageviews/summary/placemenet_tags",
  pageviewDetails: "/reports/pageviews/detail",
  pageviewDetailed: "/reports/pageviews/details",
  pageviewCount: "reports/pageviews/count",
  appliedfilter: "/user_filters",
  searchPageTitles: "/page_title/search",
  pageviewReportCharts: "/reports/pageviews/chart",
  topichubReportChart: "reports/sidebar/charts",
  sidebarDetail: "reports/sidebar/details",
  leadValidationUpdate: "reports/leadengagement/update",
  adDetail: "reports/ad/detail",
  heartbeat: "/heartbeat",
  changedLogs: "/reports/logs",
  leadValidationReport: "/reports/lead/charts",
  leadValidationBarCharts: "/reports/leadengagement/barcharts",
  syncValidate: "reports/leadengagement/validate",
  leadMailDelivery: "reports/maildelivery/lead",
  confirmLeadMailDelivery: "reports/maildelivery/confirm",
  engagedMembers: "members/search",
  getHeaderNames: "reports/table/get_dynamic_headers",
  saveHeaderNames: "reports/table/save_dynamic_headers",
  primaryTopic: "/primary_topic/search",
  sponsoredSummary: "reports/pageviews/sponsored_summary",
  sponsoredDetail: "reports/pageviews/sponsored_details",
  pageviewPrimaryTopic: "reports/pageviews/primary_topic",
  pageviewAuthorSummary: "/reports/pageviews/authors",
  // sponsoredPageDetails: "reports/pageviews/sponsored_detail",
  sponsoredPageDetails: "reports/pageviews/sponsored_detail/v1",
  sitePGSummaryReport: "reports/pageviews/sites/all",
  siteEditorialPGSummaryReprot: "reports/pageviews/sites/editorials",
  siteSponsoredPGSummaryReport: "reports/pageviews/sites/sponsored",
  siteReportChart: "reports/pageviews/site_chart",
  organizationPageviewSummary: "reports/pageviews/summary/organization_tags",
  topicOrganizationPlacementReport: "reports/pageviews/tags",
  independentTopicSummaryCount: "reports/pageviews/primary_count",
  independentPageSummary: "reports/pageviews/pageviews_count",
  engagementSummary: "/reports/engagements/summary",
  activityDetailsURL: "/reports/activity/details",
  clearCampaignsCacheURL: "/reports/campaigns/cache/refresh",
  clearColumnsURL: "/reports/table/dynamic_headers",
  downloadURL: "/reports/export/requests",
  requestToDownloadURL: "/reports/export/requests/download",
  requestToReSend: "/reports/export/requests/resend/email",
  requestToDeleteURL: "/reports/export/requests",
  requestedUsers: "/reports/export/requests/users",
  productivityReport: "/reports/user/productivity/details",
  authorDataSearch: "/authors/details",
  getUserFilterProfile: "/users/filters/profile/get",
  sharedUserFilterProfile: "/users/filters/profile/share",
  getUserSharedFilterProfile: "/users/filters/profile/shared/user",
  addUserFilterProfile: "/users/filters/profile/add",
  editUserFilterProfile: "/users/filters/profile/update",
  deleteUserFilterProfile: "/users/filters/profile/delete",
  orderDataSearch: "/reports/orders/details",
  newslettersSummary: "/reports/newsletter/summary",
  newslettersDetails: "/reports/newsletter/details",
  newsletterAuthors: "/reports/newsletter/author",

  contentPerformanceSummaryIPA: "/reports/content_performance/summary/ipa",
  contentPerformanceSummarySenority: "/reports/content_performance/summary/seniority",
  contentPerformanceSummarySector: "/reports/content_performance/summary/sector",
  contentPerformanceDetails: "/reports/content_performance/details",

  //login url's
  loginWithOkta: "/okta/oauth/login",
  loginWithWordPress: "/admin/oauth/login",
  wordPressCallbackURL: "/admin/oauth/callback",
  oktaCallbackURL: "/okta/oauth/callback/handler",
  
  decsionInsightAPI: "/decision/insights/report",
  regulationSearch: "/filters/regulations",
  getAllReports: "/reports/get/all",
  getCurrentUserRoleAPI: "/list/user/roles",
  getAllRolesAPI: "/list/roles",
  saveRoleBaseReportAPI: "/report/role/map",
  getRoleReportDetailsAPI: "/role/details",
  
  getReportDescription: "/reports/description/get",
  saveReportDescription: "/reports/description/save",
  searchSegments: "/order/segment",

  diPopulateFilter: "/decision/insights/prepopulate_filters",
};
