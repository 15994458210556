import React, { useEffect, useRef, useState, useMemo } from "react";
import { connect } from "react-redux";
import { Chart } from "react-google-charts";
import { getDateEng } from "../../utilities/common";
import { isEmpty } from "lodash";
import { numFormat } from "../../utilities/common";

let formattedData = [];
const AdReportChartWithCompare = (props) => {
  const [legendClicked, setLegendClicked] = useState([]);
  const [chartData, setChartData] = useState([]);
  const category = [];
  const chartRefValue = useRef(null);
  let chartRef = useRef();
  const [date, setDate] = useState([]);
  const colorState = useRef({});
  const [ticks, setTicks] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [ticksY1, setTicksY1] = useState(0);
  const [minValueY1, setMinValueY1] = useState(0);
  const [maxValueY1, setMaxValueY1] = useState(0);

  const calculateTicks = (min, max, tickCount) => {
    var span = max - min,
      step = Math.pow(10, Math.floor(Math.log(span / tickCount) / Math.LN10)),
      err = (tickCount / span) * step;

    // Filter ticks to get closer to the desired count.
    if (err <= 0.15) step *= 10;
    else if (err <= 0.35) step *= 5;
    else if (err <= 0.75) step *= 2;

    // Round start and stop values to step interval.
    var tstart = Math.ceil(min / step) * step,
      tstop = Math.floor(max / step) * step + step * 0.5,
      ticks = [];

    // now generate ticks
    for (let i = tstart; i < tstop; i += step) {
      ticks.push(i);
    }
    return ticks;
  };

  const calculationY2Ticks = useMemo(
    () => calculateTicks(0, maxValue + 0.01, ticks),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [minValue, maxValue, ticks]
  );

  const calculationY1Ticks = useMemo(
    () => calculateTicks(minValueY1, maxValueY1, ticksY1),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [minValue, maxValue, ticks]
  );

  useEffect(() => {
    setMinValue(Math.min(...countCtrTicks()));
    setMaxValue(Math.max(...countCtrTicks()));
    setTicks(5);

    setMinValueY1(Math.min(...countImpressionTicks()));
    setMaxValueY1(Math.max(...countImpressionTicks()));
    setTicksY1(5);
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let adPreparedData = adChartData();
    setChartData(adPreparedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legendClicked]);

  const getCoords = (chart) => {
    var chartLayout = chart.getChartLayoutInterface();
    var chartBounds = chartLayout.getChartAreaBoundingBox();

    return {
      x: {
        min: chartLayout.getHAxisValue(chartBounds.left),
        max: chartLayout.getHAxisValue(chartBounds.width + chartBounds.left),
      },
      y: {
        min: chartLayout.getVAxisValue(chartBounds.top),
        max: chartLayout.getVAxisValue(chartBounds.height + chartBounds.top),
      },
    };
  };
  const addChartGradient = (chart) => {
    var chartDiv = chart.getContainer("chart-div-ad");
    var svg = chartDiv.getElementsByTagName("svg")[0];
    var properties = {
      id: "chartGradient",
      x1: "0%",
      y1: "10%",
      x2: "0%",
      y2: "100%",
      stops: [
        { offset: "25%", "stop-color": "#E68BBF" },
        { offset: "95%", "stop-color": "rgba(255,255,255,0)" },
      ],
    };
    createGradient(svg, properties);
    var chartPath = svg.getElementsByTagName("path")[0]; //0 path corresponds to legend path
    chartPath.setAttribute("fill", "url(#chartGradient)");
  };
  const createGradient = (svg, properties) => {
    var svgNS = svg.namespaceURI;
    var grad = document.createElementNS(svgNS, "linearGradient");
    grad.setAttribute("id", properties.id);
    ["x1", "y1", "x2", "y2"].forEach(function (name) {
      if (properties.hasOwnProperty(name)) {
        grad.setAttribute(name, properties[name]);
      }
    });
    for (var i = 0; i < properties.stops.length; i++) {
      var attrs = properties.stops[i];
      var stop = document.createElementNS(svgNS, "stop");
      for (var attr in attrs) {
        if (attrs.hasOwnProperty(attr)) stop.setAttribute(attr, attrs[attr]);
      }
      grad.appendChild(stop);
    }
    var defs = svg.querySelector("defs");
    defs.insertBefore(document.createElementNS(svgNS, "defs"), defs.firstChild);
    return defs.appendChild(grad);
  };
  formattedData.map((item, index) => {
    if (index === 0) {
      category.push(item?.key);
    } else {
      !category.includes(item?.key) && category.push(item?.key);
    }
    return "";
  });
  let colors = [
    "#FED78B",
    "#FCAF17",
    "#FA911D",
    "#F15A29",
    "#0077B8",
    "#AD064F",
    "#80BBDC",
    "#FDEBE5",
    "#F8AD94",
    "#F9E3F0",
  ];

  let ctrKeys = [];
  const getY2SeriesCount = () => {
    formattedData.forEach((element) => {
      if (
        !ctrKeys.includes(element.key) &&
        legendClicked.indexOf(element.key) === -1
      ) {
        ctrKeys.push(element.key);
      }
    });
  };

  const ctrFillCompare = () => {
    let d = [];

    ctrKeys.forEach(() => {
      d.push(0.0);
      d.push(0.0);
      d.push(false);
    });

    return d;
  };

  const getSeries = () => {
    getY2SeriesCount();
    let comparedSeries = {
      0: {
        axis: "Impressions",
        targetAxisIndex: 0,
        color:
          legendClicked.indexOf("impressions") === -1
            ? "#CC167F"
            : "transparent",
        tooltip: false,
        type: legendClicked.indexOf("impressions") === -1 && "area",
      },
      1: {
        axis: "prev-impressions",
        targetAxisIndex: 0,
        color:
          legendClicked.indexOf("impressions") === -1
            ? "#CC167F"
            : "transparent",
        tooltip: false,
      },
      2: { tooltip: { trigger: "selection" } },
    };
    for (let i = 0; i < ctrKeys.length; i++) {
      let j = i + 2; //2
      let color;
      // if(ctrKeys[i] === "impressions"){
      //   if(colorState.current["all creatives"]){
      //     color = colorState.current["all creatives"];
      //   //  color = legendClicked.indexOf("all creatives") === -1 ? colorState.current["all creatives"]  : "transparent"
      //   } else {
      //      color = legendClicked.indexOf("all creatives") === -1 ? colors[i]  : "transparent"
      //   //  setColorState((colorState)=>({...colorState, "all creatives": color}))
      //   colorState.current["all creatives"] = color;
      //   }
      // } else{
      // legendClicked.indexOf(ctrKeys[i]) === -1 ? colors[i] : "transparent"
      // if(ctrKeys[i] === "impressions"){
      //   if(colorState.current["all creatives"]){
      //     color = colorState.current["all creatives"];
      //   //  color = legendClicked.indexOf("all creatives") === -1 ? colorState.current["all creatives"]  : "transparent"
      //   } else {
      //      color = legendClicked.indexOf("all creatives") === -1 ? colors[i]  : "transparent"
      //   //  setColorState((colorState)=>({...colorState, "all creatives": color}))
      //   colorState.current["all creatives"] = color;
      //   }
      if (colorState.current[ctrKeys[i]]) {
        color = colorState.current[ctrKeys[i]];
        //color = legendClicked.indexOf(ctrKeys[i]) === -1 ? colorState.current[ctrKeys[i]]  : "transparent"
      } else {
        color =
          legendClicked.indexOf(ctrKeys[i]) === -1 ? colors[i] : "transparent";
        // setColorState((colorState)=>({...colorState, [ctrKeys[i]]: color}))
        colorState.current[ctrKeys[i]] = color;
      }
      //   }

      comparedSeries[j + i] = { axis: "ctr", targetAxisIndex: 1, color: color };
      comparedSeries[j + i + 1] = {
        axis: "compare-ctr",
        targetAxisIndex: 1,
        color: color,
      };
    }
    return comparedSeries;
  };

  function mergeData(data) {
    Object.keys(data).forEach((e) => {
      if (!isEmpty(e)) {
        data[e].map((d) => formattedData.push({ ...d, date: e }));
      }
    });
    return formattedData;
  }
  formattedData = mergeData(props.chartData);

  const onLegendClick = (e) => {
    if (legendClicked.includes(e)) {
      if (e === "impressions") {
        setLegendClicked((prev) => prev.filter((i) => i !== e));
      } else {
        setLegendClicked((prev) => prev.filter((i) => i !== e));
      }
    } else {
      if (e === "impressions") {
        setLegendClicked((prev) => [...prev, e]);
      } else {
        setLegendClicked((prev) => [...prev, e]);
      }
    }
  };

  let d1 = [];
  const adChartData = () => {
    getY2SeriesCount();

    let data = [];
    let d = [];

    // d1.push({ type: "date" });
    d.push({ type: "date" });
    d.push({ type: "string", role: "tooltip", p: { html: true } });
    d.push({ type: "number" });
    d.push({ type: "number" });
    d.push({
      type: "boolean",
      role: "certainty",
    });
    ctrKeys.forEach((e) => {
      d.push({ type: "number" });
      d.push({ type: "number" });
      d.push({
        role: "certainty",
        type: "boolean",
      });
    });
    data.push(d);

    let legendCircle =
      "<span style='height: 6px; width: 6px; margin-right: 5px; background-color: ##COLOR##;border-radius: 50%; display: inline-block;'></span>";
    // props.chartData.forEach((items, day) => {
    for (const [day, items] of Object.entries(props.chartData)) {
      let tooltipItems = [];
      d1.push(new Date(day));
      setDate(d1);

      let dataTooltip = [];
      //   d1 = new Date(day);
      items.forEach((item) => {
        if (legendClicked.indexOf(item.key) !== -1) {
          return;
        } else {
          dataTooltip.push(item.key);
          if (item.key === "impressions") {
            let l = legendCircle.replace("##COLOR##", "#CC167F");
            tooltipItems.push(
              l + numFormat(item.impressions) + ` - ` + item.key
            );
            tooltipItems.push(
              l + numFormat(item.prev_impressions) + ` - Prev- Impressions `
            );
          } else {
            let idx = ctrKeys.indexOf(item.key);
            let l = legendCircle.replace("##COLOR##", colors[idx]);
            let ctrValue = item.ctr === "" ? "No Data" : item.ctr + "%";
            let ctrValueCompared =
              item.prev_ctr === "" ? "No Data" : item.prev_ctr + "%";
            tooltipItems.push(l + ctrValue + ` - ` + item.key + " CTR");
            tooltipItems.push(
              l + ctrValueCompared + ` - Prev ` + item.key + " CTR"
            );
          }
          // if( item.key === "impressions"){
          //   let idx = ctrKeys.indexOf("impressions");
          //   let l = legendCircle.replace("##COLOR##", colors[idx]);
          //   let ctrValue = item.ctr === "" ? "No Data" : item.ctr + "%";
          //   let ctrValueCompared =  item.prev_ctr === "" ? "No Data" : item.prev_ctr + "%";
          //   tooltipItems.push(l + ctrValue + ` -  All creatives`);
          //  tooltipItems.push(l + ctrValueCompared + ` - Prev All creatives`);
          // }
        }
      });

      ctrKeys.map((ctrkey) => {
        let idx = ctrKeys.indexOf(ctrkey);
        let l = legendCircle.replace("##COLOR##", colors[idx]);
        let ctrNames = ctrkey.includes("impressions")
          ? ctrkey
          : ctrkey + " CTR";
        !dataTooltip.includes(ctrkey) &&
          tooltipItems.push(l + "No Data  - " + ctrNames);
        return "";
      });

      // d1.push(new Date(day));
      let adChartComparedData = [
        new Date(day),
        `<div class="tooltip-pageview-values" style= 'text-transform: capitalize; width: 190px; padding-top: 20px' >` +
          getDateEng(day) +
          `<hr></hr>` +
          tooltipItems.join("<br />") +
          "<br /><br />" +
          `</div>`,
        0,
        0,
        false,
        ...ctrFillCompare(),
      ];

      items.forEach((item) => {
        if (legendClicked.indexOf(item.key) === -1) {
          if (item.key === "impressions") {
            adChartComparedData[2] = item.impressions;
            adChartComparedData[3] = item.prev_impressions;
          } else {
            let idx = ctrKeys.indexOf(item.key);
            let ctrValue = item.ctr === "" ? 0.0 : parseFloat(item.ctr) / 100;
            let prevCtrValue =
              item.prev_ctr === "" ? 0.0 : parseFloat(item.prev_ctr) / 100;

            adChartComparedData[2 + (idx + 1) * 3] = ctrValue;
            adChartComparedData[(idx + 2) * 3] = prevCtrValue;
            //  adChartComparedData[(idx + 3) * 3] = false;
          }
        }
        // if(item.key === "impressions"  && !legendClicked.includes("all creatives") && legendClicked.indexOf("impressions") === -1){
        //  if(item.key === "impressions"  && !legendClicked.includes("all creatives") && legendClicked.indexOf("all creatives") === -1 && (legendClicked.indexOf("impressions") === -1 || legendClicked.indexOf("all creatives") === 0)){
        //  if(item.key === "impressions"  && !legendClicked.includes("all creatives") && !legendClicked.includes("impressions")){
        //       let idx = ctrKeys.indexOf(item.key);
        //         adChartComparedData[2 + (idx + 1) * 3] = parseFloat(item.ctr)/100;
        //         adChartComparedData[(idx + 2) * 3] = parseFloat(item.prev_ctr)/100;
        //         //adChartComparedData[(idx + 3) * 3] = false;
        //     }
      });
      data.push(adChartComparedData);
    }
    return data;
  };

  const countCtrTicks = () => {
    let ctrValues = [];
    Object.keys(props.chartData).map((key) => {
      props.chartData[key] &&
        props.chartData[key].map((dataObject) => {
          ctrValues.push(
            dataObject?.ctr === "" ? 0 : parseFloat(dataObject?.ctr) / 100
          );
          return "";
        });
      return "";
    });
    return ctrValues;
  };

  const countImpressionTicks = () => {
    let impressionValues = [];
    for (const key in props.chartData) {
      if (props.chartData.hasOwnProperty(key)) {
        props.chartData[key].forEach((dataObject) => {
          if (dataObject && dataObject.impressions) {
            impressionValues.push(dataObject.impressions);
          }
        });
      }
    }
    return impressionValues;
  };

  // let adPreparedData = adChartData();
  const options = {
    chartArea: { left: "5%", top: "13%", width: "90%", height: "80%" },
    intervals: { style: "line" },
    legend: "none",
    tooltip: { isHtml: true, showColorCode: false },
    // Gives each series an axis that matches the vAxes number below.
    vAxes: [
      {
        // gridlines: {
        //   interval: 1,
        // },
        format: "short",
        baselineColor: "#B6B7B8",
        tooltip: { isHtml: true },
        minorGridlines: {
          count: 1,
        },
        //  ticks: calculationY1Ticks,
        minValue: Math.min(...calculationY1Ticks),
        maxValue: Math.max(...calculationY1Ticks),
      },
      {
        gridlines: { color: "none" },
        minValue: Math.max(...calculationY2Ticks),
        format: "percent",
      },
    ],
    hAxis: {
      gridlines: {
        interval: 1,
      },
      baselineColor: "#fff",
      gridlineColor: "#fff",
      ticks: date,
      format: "MMM dd",
      minorGridlines: {
        count: 0,
      },
      tooltip: false,
    },
    crosshair: {
      orientation: "vertical",
      color: "grey",
      opacity: 2,
      trigger: "both",
    },
    explorer: {
      actions: ["dragToZoom", "rightClickToReset"],
      axis: "horizontal",
      keepInBounds: true,
      maxZoomIn: 2,
      //zoomDelta: 1,
    },
    seriesType: "line",
    series: getSeries(),
    focusTarget: "category",
  };
  return (
    <div ref={chartRef} className="ad-chart" id="chart-div-ad">
      <div style={{ marginBottom: "-50px", position: "relative", zIndex: 1}}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {category.includes("impressions") ? (
          <div
            style={{
              marginLeft: 23,
              cursor: "pointer",
              color: legendClicked.includes("impressions")
                ? "#D3D3D3"
                : "#656669",
              zIndex: 1
            }}
            className="legend-item"
            onClick={() => {
              onLegendClick("impressions");
            }}
          >
            <span
              style={{
                backgroundColor: !legendClicked.includes("impressions")
                  ? "#CC167F"
                  : "grey",
                float: "left",
                marginTop: "4px",
                marginRight: "10px",
                //  marginLeft: 80
                //  color:!legendClicked ? "pink" : "red"
              }}
              className="legend-circle"
            />
            Impressions
          </div>
        ) : (
          <div></div>
        )}
        <div>
          <ul className="legends" style={{marginRight: "55px"}}>
            {category.map((e, i) => {
              // if (e === "impressions") {
              //   return (
              //     <li
              //       className="legend-item"
              //       onClick={() => {
              //         onLegendClick("all creatives");
              //       }}
              //     >
              //       <span
              //         style={{
              //           backgroundColor: !legendClicked.includes(
              //             "all creatives"
              //           )
              //             ? colors[i]
              //             : "grey",
              //         }}
              //         className="legend-circle"
              //       />
              //       <label
              //         style={{
              //           color: legendClicked.includes("all creatives")
              //             ? "#D3D3D3"
              //             : "#656669",
              //         }}
              //       >
              //         All Creatives
              //       </label>
              //     </li>
              //   );
              // } else if (e !== "impressions") {
              if (e !== "impressions") {
                return (
                  <li
                    className="legend-item"
                    style={{
                      color: legendClicked.includes(e) ? "#D3D3D3" : "#656669",
                      zIndex: 1
                    }}
                    onClick={() => {
                      onLegendClick(e);
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: legendClicked.includes(e)
                          ? "grey"
                          : colors[i],
                        marginTop: "2px",
                        marginRight: "10px",
                      }}
                      className="legend-circle"
                    />
                    {e}
                  </li>
                );
              }
              return "";
            })}
          </ul>
        </div>
      </div>
      <Chart
        ref={chartRefValue}
        chartType="LineChart"
        width="100%"
        height="450px"
        data={chartData}
        options={options}
        chartEvents={[
          {
            eventName: "ready",
            callback: ({ chartWrapper }) => {
              addChartGradient(chartWrapper);
            },
          },
          {
            eventName: "select",
            callback: ({ chartWrapper }) => {
              addChartGradient(chartWrapper);
            },
          },
          {
            eventName: "ready",
            callback: ({ chartWrapper, google, e }) => {
              const chart = chartWrapper.getChart();
              let zoomLast = getCoords(chart);
              let debouncer;

              const observerCallback = () => {
                const zoomCurrent = getCoords(chart);
                if (JSON.stringify(zoomLast) !== JSON.stringify(zoomCurrent)) {
                  zoomLast = getCoords(chart);
                  addChartGradient(chartWrapper);
                }
                clearTimeout(debouncer);
                debouncer = setTimeout(() => {
                  addChartGradient(chartWrapper);
                }, 300);
              };

              const observerOptions = {
                childList: true,
                subtree: true,
              };

              const observer = new MutationObserver(observerCallback);
              observer.observe(chartRef.current, observerOptions);
            },
          },
        ]}
      />
    </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    filterData: state.adFilterData,
  };
};

export default connect(mapStateToProps)(AdReportChartWithCompare);
