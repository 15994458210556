import * as actionTypes from "./actionTypes";

export const selSponsoredContentFilterValue = (value, dispatchType, key) => {
	return {
		type: dispatchType,
		payload: value,
	};
};

export const applySponsoredDateFilterDate = (val) => {
	return {
		type: actionTypes.APPLYSPONSOREDCONTENTDATE,
		payload: val,
	};
};

export const selSCFilterValue = (payload) =>({type:actionTypes.SELPGSPONSOREDCONTENTTAGSIN, payload})

export const clearSponsoredContentSerchedValue = (dispatchType) => {
	return {
		type: dispatchType,
	};
};

export const applySponsoredContentFilter = () => {
	return {
		type: actionTypes.APPLYSCFILTER,
	};
};

export const selSCToggles = (dispatchType) => (dispatch) => {
	dispatch({
		type: dispatchType,
	});
};

export const sortSponsoredContentCampaign = (column) => {
	return {
		type: actionTypes.SORTSCCAMPAIGN,
		column: column,
	};
};

export const showMoreSponsoredTableData = (type) => {
	return {
		type,
	};
};

export const sortSponsoredContentCampaignDetail = (column) => {
	return {
		type: actionTypes.SORTSCCAMPAIGNDETAILS,
		column: column,
	};
};

export const clearScSort = () => {
	return {
		type: actionTypes.CLEARSCSORT,
	};
};

export const sortSponsoredContentCampaignPageDetail = (column) => {
	return {
		type: actionTypes.SORTSCCAMPAIGNPAGEDETAIL,
		column: column,
	};
};

export const sponsoredContentCsvLoading = (dispatchType) => (dispatch) => {
	dispatch({
		type: dispatchType,
	});
};