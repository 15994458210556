import * as actionTypes from './actionTypes';


export const changeSidebarAnimation = () => {
	return {
		type: actionTypes.CHANGEANIMATION
	}
}

export const changeGridColumn = (sidebarColumn, contentColumn) => {
	return {
		type: actionTypes.CHANGEGRID,
		sidebarColumn,
		contentColumn
	}
}