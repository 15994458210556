import _ from "lodash";
import React from "react";
import { Table, Loader, Message, Divider } from "semantic-ui-react";
import "../../css/common.css";
import { connect } from "react-redux";
import {
	topicSetPageCount,
	fetchTopicDetail,
	fetchTopicSummary,
	showMoreTopicLoading,
	sortTopicSummary,
	clearTopicSort,
} from "../../actions";
import Link from "../hyperlinks/link";
import { 
    getDisplayDate, 
    numFormat, 
    capitalizeFirstLetter, 
    impressionNumFormat, 
    getAttachedPercentage, 
    removeSquareBrackets, 
    getCapital} from "../../utilities/common";
import MultiLinks from "../hyperlinks/multiLinks";
import * as actionTypes from "../../actions/actionTypes";
import TopicHubCampaign from "../campaign/topichubCampaign";
import SortIcon from '@mui/icons-material/Sort';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HocDrawer from "../common/hocDrawer";

const TopicSummaryTable = (props) => {
	let tableBody;
	let showMoreButton;
	let loading;
	let noresult;

	//const [pageCount, setPageCount] = useState(2);

	const showMore = async () => {
		props.clearTopicSort(actionTypes.CLEARTOPICSUMMARYSORT);
		props.showMoreTopicLoading(actionTypes.MORETOPICSUMMARYLOADING);
		await props.fetchTopicSummary(
			props.filterData,
			props.PageCount,
			//pageCount,
			"",
			actionTypes.MORETOPICSUMMARYDATA
		);
		props.topicSetPageCount(actionTypes.SETTOPSUMMARYPGCOUNT);
		// setPageCount(pageCount + 1);
	};

	if (props.loading) {
		loading = (
			<div className="show-loader">
				<Loader active inline="centered" />
			</div>
		);
	}

	const createRow = (data) => {
		if (data.length > 0) {
			let tableRow = [];
			data.forEach((e) => {
				let tableCell = [];
        let impressionTimeValue = removeSquareBrackets(e.impressions_timing)
				const TopicCampaignDrawer = HocDrawer(
					ArrowForwardIcon,
					TopicHubCampaign,
					"CAMPAIGN HIGHLIGHTS ",
					e
				  )
        tableCell.push(<Table.Cell title={e.agency_name}>{e.agency_name}</Table.Cell>);
				//account name column
				tableCell.push(
					<Table.Cell title={e.organization_name} className="blogs_link">
						{e.organizations ? (
							<MultiLinks
								type="organizations"
								object={e.organizations}
							></MultiLinks>
						) : (
							e.organization_name
						)}
					</Table.Cell>
				);
				tableCell.push(
					<Table.Cell title={e.order.name} className="blogs_link">
						{e.order.name ? (
							<Link
								type={"orders"}
								urlParam={e.order.uuid}
								refValue={e.order.name}
							></Link>
						) : (
							""
						)}
					</Table.Cell>
				);
				tableCell.push(
					<Table.Cell title={e.campaign_name} className="blogs_link">
						{e.campaign_name ? (
							<Link
								type={"campaigns"}
								urlParam={e.uuid}
								refValue={e.campaign_name}
							></Link>
						) : (
							""
						)}
					</Table.Cell>
				);
        tableCell.push(<Table.Cell title={e?.client_success_manager?.name}>{e?.client_success_manager?.name}</Table.Cell>);
				tableCell.push(<Table.Cell title={getDisplayDate(e.start_date)}>{getDisplayDate(e.start_date)}</Table.Cell>);
				tableCell.push(<Table.Cell title={getDisplayDate(e.end_date)}>{getDisplayDate(e.end_date)}</Table.Cell>);
        tableCell.push(<Table.Cell title={getDisplayDate(e.actual_start_date)}>{getDisplayDate(e.actual_start_date)}</Table.Cell>);
				tableCell.push(<Table.Cell title={getDisplayDate(e.projected_end_date)}>{getDisplayDate(e.projected_end_date)}</Table.Cell>);
        tableCell.push(<Table.Cell title={capitalizeFirstLetter(e.status)}>{capitalizeFirstLetter(e.status)}</Table.Cell>);
        tableCell.push(
          <Table.Cell title={capitalizeFirstLetter(e.delivery_state)}>{capitalizeFirstLetter(e.delivery_state)}</Table.Cell>
        );
        tableCell.push(
          <Table.Cell textAlign="center" title={getCapital(impressionTimeValue)}>
            {getCapital(impressionTimeValue)}
          </Table.Cell>
        );
        tableCell.push(
          <Table.Cell textAlign="right" title={impressionNumFormat(e.impressions_target)}>
            {impressionNumFormat(e.impressions_target)}
          </Table.Cell>);
        tableCell.push(
          <Table.Cell title={numFormat(e.total_served)} textAlign="right">{numFormat(e.total_served)}</Table.Cell>,
        );
        tableCell.push(
					<Table.Cell title={numFormat(e.total_seen)} textAlign="right">{numFormat(e.total_seen)}</Table.Cell>,
				);
				tableCell.push(
					<Table.Cell title={numFormat(e.impressions)} textAlign="right">{numFormat(e.impressions)}</Table.Cell>,
				);
        //impression outstanding
        tableCell.push(
          <Table.Cell textAlign="right" title={numFormat(e.impressions_outstanding)}>
            {numFormat(e.impressions_outstanding)}
          </Table.Cell>
        );
				// unique_user_impressions column
				tableCell.push(
					<Table.Cell title={numFormat(e.unique_impressions)} textAlign="right">{numFormat(e.unique_impressions)}</Table.Cell>,
				);
				tableCell.push(
					<Table.Cell title = {numFormat(e.clicks)} textAlign="right">{numFormat(e.clicks)}</Table.Cell>,
				);
				//unique_user_clicks column
				tableCell.push(
					<Table.Cell title={numFormat(e.unique_clicks)} textAlign="right">{numFormat(e.unique_clicks)}</Table.Cell>,
				);
				tableCell.push(<Table.Cell textAlign="right" title={e.ctr}>{e.ctr}</Table.Cell>);
        tableCell.push(
          <Table.Cell textAlign="right" title={getAttachedPercentage(e.percent_delivered)}>
            {getAttachedPercentage(e.percent_delivered)}
          </Table.Cell>
        );
        tableCell.push(
          <Table.Cell textAlign="right" title={getAttachedPercentage(e.percent_complete)}>
            {getAttachedPercentage(e.percent_complete)}
          </Table.Cell>
        );
        tableCell.push(
          <>
          {
            e.impressions_timing_met ?
              <Table.Cell title={e.impressions_timing_met} textAlign="right"> <button className="button_color_green"/> </Table.Cell>
            :
              <Table.Cell title={e.impressions_timing_met} textAlign="right"> <button className="button_color_red"/> </Table.Cell>
          }
        </>
      );
      tableCell.push(
        <>
          {(e.percent_delivered === 'NA' || e.percent_delivered === 0) ? (
            <Table.Cell title={e.pace} textAlign="right">
              <button className="button_color_grey" />
            </Table.Cell>
          ) : (
            e.pace === "green" ? (
              <Table.Cell title={e.pace} textAlign="right">
                <button className="button_color_green" />
              </Table.Cell>
            ) : e.pace === "yellow" ? (
              <Table.Cell title={e.pace} textAlign="right">
                <button className="button_color_yellow" />
              </Table.Cell>
            ) : (
              <Table.Cell title={e.pace} textAlign="right">
                <button className="button_color_red" />
              </Table.Cell>
            )
          )}
        </>
      );
      
				tableCell.push(
					<Table.Cell>
						 <TopicCampaignDrawer />
					</Table.Cell>
				)
				tableRow.push(<Table.Row children={tableCell} />);
			});

			return tableRow;
		}
	};

	if (!_.isEmpty(props.topicSummary)) {
		tableBody = <Table.Body>{createRow(props.topicSummary)}</Table.Body>;
    showMoreButton = (
      <div className="table-divider">
        <Divider>
          <div className="show-more-component">
            {!props.topicFetchData.resSidebarCampaignSummaryValue ? 
            (
            <button className="show-more-button" onClick={showMore}>
              Show More
            </button>
            ):(
            <button className="show-more-button-disable">
              Show More
            </button>
            )}
            
          </div>
        </Divider>
      </div>
    );
	} else if (
		props.isFilApplied &&
		!props.loading &&
		_.isEmpty(props.topicSummary)
	) {
		noresult = <Message>No results found.</Message>;
	}
	return (
    <div>
      <div className="sortable-table-component">
        <Table sortable fixed singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                  sorted={
                    props.column === "agency_name" ? props.direction : null
                  }
                  onClick={() => props.sortTopicSummary("agency_name")}
              >
                Agency
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  props.column === "organization_name" ? props.direction : null
                }
                onClick={() => props.sortTopicSummary("organization_name")}
              >
                Account
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={props.column === "order.name" ? props.direction : null}
                onClick={() => props.sortTopicSummary("order.name")}
              >
                Order
              </Table.HeaderCell>
              <Table.HeaderCell
                className="custom-column-large"
                sorted={
                  props.column === "campaign_name" ? props.direction : null
                }
                onClick={() => props.sortTopicSummary("campaign_name")}
              >
                Campaign
              </Table.HeaderCell>
              <Table.HeaderCell
                className="custom-column-large"
                sorted={ props.column === "client_success_manager.name" ? props.direction : null }
                onClick={() => props.sortTopicSummary("client_success_manager.name")}
              >
                CSM
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={props.column === "start_date" ? props.direction : null}
                onClick={() => props.sortTopicSummary("start_date")}
              >
                Campaign Start Date
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={props.column === "end_date" ? props.direction : null}
                onClick={() => props.sortTopicSummary("end_date")}
              >
               Campaign End Date
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={props.column === "start_date" ? props.direction : null}
                onClick={() => props.sortTopicSummary("start_date")}
              >
               Actual Start Date
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={props.column === "actual_start_date" ? props.direction : null}
                onClick={() => props.sortTopicSummary("actual_start_date")}
              >
               Projected End Date
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={props.column === "projected_end_date" ? props.direction : null}
                onClick={() => props.sortTopicSummary("projected_end_date")}
                className="custom-column-medium"
              >
                Campaign Status
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={props.column === "delivery_state" ? props.direction : null}
                onClick={() => props.sortTopicSummary("delivery_state")}
              >
                Delivery Status
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={props.column === "impressions_timing" ? props.direction : null}
                onClick={() => props.sortTopicSummary("impressions_timing")}
                textAlign="center"
              >
                Impression Timing
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={props.column === "impressions_target" ? props.direction : null}
                onClick={() => props.sortTopicSummary("impressions_target")}
                className="total-impressions"
                textAlign="right"
              >
                Impressions Purchased
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={props.column === "total_served" ? props.direction : null}
                onClick={() => props.sortTopicSummary("total_served")}
                className="impressions"
                textAlign="right"
              >
                Impressions Served
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={props.column === "total_seen" ? props.direction : null}
                onClick={() => props.sortTopicSummary("total_seen")}
                className="impressions"
                textAlign="right"
              >
                Impressions Seen
              </Table.HeaderCell>
              <Table.HeaderCell
                className="impressions"
                textAlign="right"
                sorted={ !props.filterData.selCustomRangeCheckBox && props.column === "impressions" ? props.direction : null }
                onClick={() =>
                  !props.filterData.selCustomRangeCheckBox &&
                  props.sortTopicSummary("impressions")
                }
              >
                Impression Delivered
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={props.column === "impressions_outstanding" ? props.direction : null}
                onClick={() => props.sortTopicSummary("impressions_outstanding")}
                className="impressions"
                textAlign="right"
              >
                Impressions Outstanding
              </Table.HeaderCell>
              <Table.HeaderCell
                textAlign="right"
                sorted={ !props.filterData.selCustomRangeCheckBox && props.column === "unique_impressions" ? props.direction : null }
                onClick={() =>  !props.filterData.selCustomRangeCheckBox && props.sortTopicSummary("unique_impressions")}
              >
                Unique User Impressions
              </Table.HeaderCell>
              <Table.HeaderCell
                textAlign="right"
                className="clicks"
                sorted={  !props.filterData.selCustomRangeCheckBox && props.column === "clicks" ? props.direction : null}
                onClick={() =>  !props.filterData.selCustomRangeCheckBox &&  props.sortTopicSummary("clicks")}
              >
                Clicks
              </Table.HeaderCell>
              <Table.HeaderCell
                className={
                  props.filterData.selCustomRangeCheckBox &&
                  props.isFilApplied &&
                  "custom-column-large"
                }
                textAlign="right"
                sorted={ !props.filterData.selCustomRangeCheckBox &&
                  props.column === "unique_clicks" ? props.direction : null
                }
                onClick={() =>  !props.filterData.selCustomRangeCheckBox && props.sortTopicSummary("unique_clicks")}
              >
                Unique User Clicks
              </Table.HeaderCell>
              <Table.HeaderCell
                textAlign="right"
                className="ctr-for-daterange"
                sorted={props.column === "ctr" ? props.direction : null}
                onClick={() => props.sortTopicSummary("ctr")}
              >
                CTR
              </Table.HeaderCell>
              <Table.HeaderCell
                textAlign="right"
                className="percentage-served"
                sorted={props.column === "percent_delivered" ? props.direction : null}
                onClick={() => props.sortTopicSummary("percent_delivered")}
              >
                Percent Delivered
              </Table.HeaderCell>
              <Table.HeaderCell
                textAlign="right"
                sorted={props.column === "percent_complete" ? props.direction : null}
                onClick={() => props.sortTopicSummary("percent_complete")}
              >
                Percent Complete
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={props.column === "impressions_timing_met" ? props.direction : null}
                onClick={() => props.sortTopicSummary("impressions_timing_met")}
                textAlign="right"
              >
                Campaign Pace
              </Table.HeaderCell>
              <Table.HeaderCell
                textAlign="right"
                className="impressions"
                sorted={props.column === "pace" ? props.direction : null}
                onClick={() => props.sortTopicSummary("pace")}
              >
                Delivery Pace
              </Table.HeaderCell>
              <Table.HeaderCell className="icon">
                <SortIcon />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {tableBody}
        </Table>
      </div>
      {noresult}
      {loading}
      {showMoreButton}
    </div>
  );
};

const mapStateToProps = (state) => {
	return {
		wpLink: state.links.wpLink,
		isFilApplied: state.topicFetchData.isFilApplied,
		filterData: state.topicFilterData,
		topicSummary: state.topicFetchData.topicSummary,
		loading: state.topicFetchData.isLoadingTopicSummary,
		column: state.topicFetchData.column,
		direction: state.topicFetchData.direction,
		PageCount: state.topicFetchData.summaryPageCount,
    topicFetchData: state.topicFetchData
	};
};

export default connect(mapStateToProps, {
	fetchTopicDetail,
	fetchTopicSummary,
	showMoreTopicLoading,
	clearTopicSort,
	sortTopicSummary,
	topicSetPageCount,
})(TopicSummaryTable);
