import _ from "lodash";
import React, { useRef } from "react";
import { connect } from "react-redux";
import { Checkbox, Loader } from "semantic-ui-react";
import { selLeadToggles } from "../../../actions";
import * as actionTypes from "../../../actions/actionTypes";
import { getCapital, getDisplayDateChart } from "../../../utilities/common";
import DownloadChart from "../../common/downloadChart";
import LeadEngsQualificationChart from "./leadEngsQualificationChart";

const EngagementAcceptanceChart = (props) => {
  const chartNameDiv = useRef(null);
  let loading;
  if (props.loading) {
    loading = (
      <div className="show-loader">
        <Loader
          style={{ marginTop: "30px", marginBottom: "40px" }}
          active
          inline="centered"
        />
      </div>
    );
  }

  return (
    <>
      <div className="ad-graph" id="charts-gap">
        <div ref={chartNameDiv}>
          {!_.isEmpty(props.leadEngsAcceptanceBarChart) && props.leadFetchData.engsAcceptanceChart &&
            <DownloadChart
              chartNameDiv= {chartNameDiv.current}
              chartName = {(`Engagement-Acceptance-Rate-Chart-${getDisplayDateChart(new Date())}.jpg`)}
              style={{width:"36px", height: "36px", marginTop: 19, marginRight: 56, color: "#838486" }}
            />
          }
          <p className="ad-chart-name">
            {getCapital("Engagement Acceptance Rate")}
            <Checkbox
              className="checkbox-label"
              data-html2canvas-ignore="true"
              // slider
              toggle
              style={{
                color: "white",
                fontFamily: "Roboto",
                fontSize: "16px",
                paddingLeft: "20px",
                minHeight: 15,
              }}
              label={props.leadFetchData.engsAcceptanceChart ? "Hide" : "Show"}
              onChange={() =>
                props.selLeadToggles(actionTypes.SELENGSACCEPTANCECHART)
              }
              checked={props.leadFetchData.engsAcceptanceChart}
              disabled={!props.isFilApplied}
            />
            {
              props.isFilApplied &&
              props.leadFetchData.engsAcceptanceChart && loading
            }
             
          {!_.isEmpty(props.leadEngsAcceptanceBarChart) &&
            props.leadFetchData.engsAcceptanceChart && (
              <LeadEngsQualificationChart />
            )}
          </p>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    leadEngsAcceptanceBarChart: state.leadFetchData.leadEngsAcceptanceBarChart,
    isFilApplied: state.leadFetchData.isFilApplied,
    leadFetchData: state.leadFetchData,
    leadEngs: state.leadFetchData.leadEngs,
    loading: state.leadFetchData.isLoadingAcceptanceChart,
    leadEngSummary: state.leadFetchData.leadEngSummary,
  };
};

export default connect(mapStateToProps, { selLeadToggles })(
  EngagementAcceptanceChart
);
