import React, { useState } from "react";
import { connect } from "react-redux";
import { Table } from "semantic-ui-react";
import { displayWidgetType, numFormat } from "../../../utilities/common";

const Widgets = (props) => {
  const [expandedWidgets, setexpandedWidgets] = useState(false);

  let widgetsData = props.data;
  let conditionChkboxAppFilter = props.filterData.selCustomRangeCheckBox && props.isFilApplied && props.filterData.appliedDate ==="Custom Date Range";

  return (
    <>
      <Table.Row className={expandedWidgets && "li-children"}
          onDoubleClick={() => {
            if (!expandedWidgets) {
              setexpandedWidgets(true);
            } else if (expandedWidgets) {
              setexpandedWidgets(false);
            }
          }}>
        <Table.Cell></Table.Cell>
        <Table.Cell style={{ paddingLeft: "30px" }}>
          {/* {!_.isNull(widgetsData.pages) && Object.keys(widgetsData.pages).length > 0 ? (
            !expandedWidgets ? (
              <i
                className="material-icons"
                onClick={() => {
                  setexpandedWidgets(true);
                }}
              >
                add
              </i>
            ) : (
              <i
                className="material-icons"
                onClick={() => {
                  setexpandedWidgets(false);
                }}
              >
                remove
              </i>
            )
          ) : (
            ""
          )} */}
        </Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell textAlign="center" title={props.count + 1}>{props.count + 1}</Table.Cell>
        <Table.Cell title={displayWidgetType(widgetsData.widget_type)}>{displayWidgetType(widgetsData.widget_type)}</Table.Cell>
        <Table.Cell title={widgetsData.title}>{widgetsData.title}</Table.Cell>
        {/* <Table.Cell></Table.Cell> */}
        {/* <Table.Cell></Table.Cell> */}
        {(conditionChkboxAppFilter)  ?  
          <>
               <Table.Cell textAlign="right" title={numFormat(widgetsData.impressions)}> {numFormat(widgetsData.impressions)} </Table.Cell>
              <Table.Cell textAlign="right" title={numFormat(widgetsData.prev_impressions)}> {numFormat(widgetsData.prev_impressions)}</Table.Cell> 
          </>
          : 
          <>
          	<Table.Cell textAlign="right" title={numFormat(widgetsData.impressions)}>{numFormat(widgetsData.impressions)}</Table.Cell>
          </>
             
          }  
        {(conditionChkboxAppFilter)  ?  
          <>
               <Table.Cell textAlign="right" title={numFormat(widgetsData.unique_impressions)}> {numFormat(widgetsData.unique_impressions)} </Table.Cell>
              <Table.Cell textAlign="right" title={numFormat(widgetsData.prev_unique_impressions)}> {numFormat(widgetsData.prev_unique_impressions)} </Table.Cell> 
          </>
          : 
          <>
          	<Table.Cell textAlign="right" title={numFormat(widgetsData.unique_impressions)}>{numFormat(widgetsData.unique_impressions)}</Table.Cell>
          </>
             
          }
        {/* <Table.Cell></Table.Cell> */}
        {(conditionChkboxAppFilter)  ?  
          <>
               <Table.Cell textAlign="right" title={numFormat(widgetsData.clicks)}> {numFormat(widgetsData.clicks)} </Table.Cell>
              <Table.Cell textAlign="right" title={numFormat(widgetsData.prev_clicks)}> {numFormat(widgetsData.prev_clicks)}</Table.Cell> 
          </>
          : 
          <>
          	<Table.Cell textAlign="right" title={numFormat(widgetsData.clicks)}>{numFormat(widgetsData.clicks)}</Table.Cell>
          </>
             
          }
         {(conditionChkboxAppFilter)  ?  
          <>
               <Table.Cell textAlign="right" title={numFormat(widgetsData.unique_clicks)}> {numFormat(widgetsData.unique_clicks)} </Table.Cell>
              <Table.Cell textAlign="right" title={numFormat(widgetsData.prev_unique_clicks)}> {numFormat(widgetsData.prev_unique_clicks)} </Table.Cell> 
          </>
          : 
          <>
          	<Table.Cell textAlign="right" title={numFormat(widgetsData.unique_clicks)}>{numFormat(widgetsData.unique_clicks)}</Table.Cell>
          </>    
          }
        <Table.Cell textAlign="right" title={widgetsData.ctr}>{widgetsData.ctr}</Table.Cell>
      </Table.Row>
    </>
  );
};

const mapStateToProps = (state) => {
	return {
    isFilApplied: state.topicFetchData.isFilApplied,
		filterData: state.topicFilterData
	};
};

export default connect(mapStateToProps)(Widgets); 
