import React from "react";
import { Table } from "semantic-ui-react";

const SponsoredPageTitleComponent = ({ data }) => {
  const { page_title, page_path } = data;
  return (
    <Table.Cell title={page_title} className="blogs_link">
      {page_title === "N/A" ? (
        page_title
      ) : (
        <a href={page_path} target="_blank" rel="noopener noreferrer">
          {page_title}
        </a>
      )}
    </Table.Cell>
  );
};

export default SponsoredPageTitleComponent;