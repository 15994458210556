import React from "react";
import { Table } from "semantic-ui-react";
import { connect } from "react-redux";
import { clearCookie, getCookie } from "../../../../utilities/envCommon";
import { MODULE_DI_BASIC, MODULE_DI_EARNED_MEDIA, MODULE_DI_PRO } from "../../../config/const";
import _ from "lodash";

const DIPopulateFilterComponent = ({ data, keyMappings, diPopulateFilterData, filterData }) => {

    const updateCookieAndOpen = (filterData, mentionsViews) => {
        const expiryDate = new Date(getCookie("expiry") * 1000);
        document.cookie = `di_filter_data=${encodeURIComponent(JSON.stringify(filterData))}; expires=${expiryDate.toUTCString()}; path=/`;
        window.open("/activity", "_blank", "noopener,noreferrer");
    };

    const handleClick = () => {
        const baseFilterData = {
            start_date: diPopulateFilterData?.start_date,
            end_date: diPopulateFilterData?.end_date,
            org_filter: diPopulateFilterData?.org_filter,
            site: diPopulateFilterData?.site,
            exclude_internal_events: diPopulateFilterData?.exclude_internal_events,
            exclude_anonymous_member: filterData?.excludeAnonymousUsers,
            company_name: data.company_name,
            domain_name: data.domain_name,
            uuid: data.uuid,
            applied_date_filter: filterData.appliedDate,
            eng_filter: [{ name: data.company_name, uuid: data.uuid }],
            isAutomated: true,
            automatedHeader: keyMappings?.value
        };

        if (keyMappings?.label === "MENTIONS_VIEWS_EX_SDX") {
            updateCookieAndOpen({ ...baseFilterData, mentions_views: true }, true);
        } else if (keyMappings?.label === "MARKETPLACE_VIEWS_EX_SDX") {
            updateCookieAndOpen({ ...baseFilterData, marketplace_views: true }, true);
        } else {
            const columnSelectData = diPopulateFilterData?.populate_tags_filter?.find(
                (item) => item.key === keyMappings?.label
            );

            if (columnSelectData) {
                let filteredSite = [];
                if(filterData.site !== "all") {
                    filteredSite = _.filter(diPopulateFilterData?.tags, { domain: filterData.site });
                } else {
                    filteredSite = diPopulateFilterData?.tags;
                }
                
                updateCookieAndOpen({
                    ...baseFilterData,
                    populate_tags_filter: columnSelectData,
                    tags: filteredSite,
                    mentions_views: false,
                    marketplace_views: false
                });
            } else {
                clearCookie("di_filter_data");
            }
        }
    };

    const cellContent = data?.[keyMappings?.value];
    const isClickable = cellContent !== 0;

    return (
        <Table.Cell
            className={isClickable ? "blogs_link space_height" : undefined}
            style={isClickable ? { cursor: "pointer" } : undefined}
            textAlign="right"
            onClick={isClickable ? handleClick : undefined}
        >
            {cellContent}
        </Table.Cell>
    );
};

const mapStateToProps = (state) => {
    const pathName = window.location.pathname;
    const filterMap = {
        "/decision-insights-basic": MODULE_DI_EARNED_MEDIA,
        "/decision-insights-pro": MODULE_DI_BASIC,
    };
    return {
        diPopulateFilterData: state.filterData.diPopulateFilterData,
        filterData: state.commonFilterData[filterMap[pathName] || MODULE_DI_PRO],
    };
};

export default connect(mapStateToProps)(DIPopulateFilterComponent);