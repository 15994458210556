import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
    selSiteReportFilterValue,
    fetchSiteAllPGSummaryReport,
    fetchSiteEditorialPGSummaryReport,
    fetchSiteSponsoredPGSummaryReport,
    loadingSiteAllPGSummaryTable,
    loadingSiteEditorialPGSummaryTable,
    loadingSiteSponsoredPGSummaryTable,
    fetchSiteChart,
    updateCommonData
} from "../../actions";
import * as actionTypes from "../../actions/actionTypes";
import { differenceInDays, subDays, subYears } from "date-fns";
import { getDisplayDate, getStartEndDates } from "../../utilities/common";
import { Form } from "semantic-ui-react";
import DatePicker from "../common/datePicker";
import DateRange from "./dateRange";
import { url } from "../../api/urlHelper";
import _ from "lodash"
import CustomDatePickerWithCompare from "../common/CustomDatePickerWithCompare";
import { getCompareDateWithOtherFunction } from "../../utilities/Calendar";
import { ALL_PAGEVIEW_SUMMARY, INDEPENDENT_PAGEVIEW_SUMMARY, SPONSORED_PAGEVIEW_SUMMARY } from "../config/rolebasedReportNames";
import { VIEW } from "../config/const";

const SiteReport = (props) =>{
    const [datePopup, setDatePopup] = useState("custom-date-popup-close");
    const [checkSelectState, setCheckSelectState] = useState(false);
    const [comparePeriod, setComparePeriod] = useState("Previous Period");
    const tempSelection = useRef({});
    //Date range event handling and state management
    const [dateRange, setDateRange] = useState({
      selection: {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
        //color:"#e5582b",
      },
    });
    const [isSiteLoad, setIsSiteLoad] = useState(false)

    useEffect(()=> {
      if(!isSiteLoad){
        setIsSiteLoad(true)
        const dates = getStartEndDates(props.siteReportFilterData.appliedDate)
        applyFilters(getDisplayDate(dates.startDate), getDisplayDate(dates.endDate));
      }
     // props.siteReportFilterData.resSponsoredPageviewSummaryValue = null
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSiteLoad])
  
    useEffect(() => {
      if (document.getElementsByClassName("rdrDateDisplayWrapper")?.length !== 0) {
        document.getElementsByClassName("rdrDateDisplayWrapper")[0].style.display = "none";
      }
    }, [checkSelectState]);
  
   useEffect(()=>{
    if ( props.isFilter && 
        !_.isEmpty(props.siteAllPgSummaryData) &&  
        !_.isEmpty(props.siteEDPgSummaryData) &&  
        !_.isEmpty(props.siteSponsoredPgSummaryData) && 
        props.siteReportFilterData.showSiteChart 
      ) {
      props.selSiteReportFilterValue(true, actionTypes.SITEREPORTLOADINGCHARTS)
      props.fetchSiteChart(
        url.siteReportChart,
        props.siteReportFilterData.startDate,
        props.siteReportFilterData.endDate,
        1,
        props.siteReportFilterData.selCutomRangeChkBox,
        "site"
      );
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.isFilter, props.siteAllPgSummaryData, props.siteEDPgSummaryData, props.siteSponsoredPgSummaryData,  props.siteReportFilterData.showSiteChart]);
  
    const previousPeriodData = (startDate, endDate, comparePeriod) => {
      props.selSiteReportFilterValue(
        checkSelectState,
        actionTypes.SELSITEREPORTCUSTOMRANGE
      );
      let prevStartDate, prevEndDate;
      
      if (comparePeriod === "Previous Year") {
        prevStartDate = subYears(new Date(startDate), 1);
        prevEndDate = subYears(new Date(endDate), 1);
      } else if (_.includes(["7", "30", "90"], comparePeriod)) {
        const result = getCompareDateWithOtherFunction(updateCommonData, actionTypes.SET_SITE_VALUES, comparePeriod);
        startDate = new Date(result.startDate);
        endDate = new Date(result.endDate);
        prevStartDate = new Date(result.prevStartDate);
        prevEndDate = new Date(result.prevEndDate);
    } else if (comparePeriod === "Previous Day of Week") {
        endDate = startDate;
        prevStartDate = subDays(startDate, 7); // Subtract 7 days to get the previous occurrence of the same day
        prevEndDate = subDays(startDate, 7); // Similarly, adjust end date for consistency
    } else {
        let differenceinDays = differenceInDays(endDate, startDate);
        prevEndDate = subDays(new Date(startDate), 1);
        prevStartDate = subDays(new Date(prevEndDate), differenceinDays);
      }
      setDateRange({
        selection1: {
          startDate: startDate,
          endDate: endDate,
          key: "selection1",
          color: "#e5582b",
        },
        selection2: getCompareState(prevStartDate, prevEndDate),
      });
      return cutomDate(prevStartDate, prevEndDate);
    };
  
    const getCompareState = (prevStartDate, prevEndDate) => {
      return {
        startDate: prevStartDate,
        endDate: prevEndDate,
        key: "selection2",
        color: "#CC167F",
      };
    };
  
    const getClearDate = () => {
      setCheckSelectState(false);
      let sel = dateRange.selection1;
      sel.key = "selection";
      setDateRange({
        selection: sel,
      });
    };
  
    const cutomDate = (startDate, endDate) => {
      return getDisplayDate(startDate) + " - " + getDisplayDate(endDate);
    };
  
    const selectDateRange = (e, { value }) => {
      props.selSiteReportFilterValue(
        value,
        actionTypes.APPLYSITEREPORTDATE
      );
      setDatePopup("custom-date-popup-close");
      if (value === "Custom Date Range") {
        setDatePopup("custom-date-popup-open");
      } else {
        let dates = getStartEndDates(value);
        props.selSiteReportFilterValue(
          dates.startDate,
          actionTypes.SELSITEREPORTSTARTDATE
        );
        props.selSiteReportFilterValue(
          dates.endDate,
          actionTypes.SELSITEREPORTENDDATE
        );
        props.selSiteReportFilterValue(
          dates.startDate,
          actionTypes.SELSITEREPORTPREVSTARTDATE
        );
        props.selSiteReportFilterValue(
          dates.endDate,
          actionTypes.SELSITEREPORTPREVENDDATE
        );
        cancelDate();
        applyFilters(dates.startDate, dates.endDate);
      }
    };
  
    const applyDate = () => {
      props.selSiteReportFilterValue(checkSelectState, actionTypes.SELSITEREPORTCUSTOMRANGE);
      //let tempSelection = null;
      if (dateRange.hasOwnProperty("selection")) {
        tempSelection.current = dateRange.selection;
        props.selSiteReportFilterValue(
          getDisplayDate(tempSelection.current?.startDate),
          actionTypes.SELSITEREPORTSTARTDATE
        );
        props.selSiteReportFilterValue(
          getDisplayDate(tempSelection.current?.endDate),
          actionTypes.SELSITEREPORTENDDATE
        );
      } else if (dateRange.hasOwnProperty("selection1")) {
        tempSelection.current = dateRange.selection1;
        props.selSiteReportFilterValue(
          getDisplayDate(dateRange?.selection1?.startDate),
          actionTypes.SELSITEREPORTSTARTDATE
        );
        props.selSiteReportFilterValue(
          getDisplayDate(dateRange?.selection1?.endDate),
          actionTypes.SELSITEREPORTENDDATE
        );
        props.selSiteReportFilterValue(
          getDisplayDate(dateRange?.selection2?.startDate),
          actionTypes.SELSITEREPORTPREVSTARTDATE
        );
        props.selSiteReportFilterValue(
          getDisplayDate(dateRange?.selection2?.endDate),
          actionTypes.SELSITEREPORTPREVENDDATE
        );
      }
      setDatePopup("custom-date-popup-close");
      applyFilters(
          getDisplayDate(tempSelection.current.startDate),
          getDisplayDate(tempSelection.current.endDate)
      );
    };
  
    const cancelDate = () => {
      setCheckSelectState(false);
      setDateRange({
        selection: {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      });
  
      setDatePopup("custom-date-popup-close");
    };

    const applyFilters = (startDate, endDate) =>{
        props.loadingSiteAllPGSummaryTable();
        props.loadingSiteEditorialPGSummaryTable();
        props.loadingSiteSponsoredPGSummaryTable();

        if(props?.roleReports?.some((report) => [ALL_PAGEVIEW_SUMMARY].includes(report.element) && report.permission === VIEW)) {
          props.fetchSiteAllPGSummaryReport(
            url.sitePGSummaryReport,
            startDate,
            endDate,
            1,
            "site"
          );
        }
        
        if(props?.roleReports?.some((report) => [INDEPENDENT_PAGEVIEW_SUMMARY].includes(report.element) && report.permission === VIEW)) {
          props.fetchSiteEditorialPGSummaryReport(
            url.siteEditorialPGSummaryReprot,
            startDate,
            endDate,
            1,
            "site"
          );
        }
        
        if(props?.roleReports?.some((report) => [SPONSORED_PAGEVIEW_SUMMARY].includes(report.element) && report.permission === VIEW)) {
          props.fetchSiteSponsoredPGSummaryReport(
            url.siteSponsoredPGSummaryReport,
            startDate,
            endDate,
            1,
            "site"
          );
        }

      props.selSiteReportFilterValue({}, actionTypes.SITEREPORTCHARTS)
    }

    return(
        <div>
            <div className="date-picker">
                {/* Date picker */}
                <div>
          <Form>
            <Form.Group widths="equal">
              <DatePicker
                floating
                value={props.siteReportFilterData.appliedDate}
                onChange={selectDateRange}
                openDateRange={(e, { value }) => {
                  if (value === "Custom Date Range") {
                    document.getElementsByClassName(
                      "rdrDateInput"
                    )[0].style.display = "none";
                    document.getElementsByClassName(
                      "rdrDateInput"
                    )[1].style.display = "none";
                    props.selSiteReportFilterValue(
                      "Custom Date Range",
                      actionTypes.APPLYSITEREPORTDATE
                    );
                    setDatePopup("custom-date-popup-open");
                  }
                }}
              />
              <DateRange />
            </Form.Group>
          </Form>
        </div>
      </div>
      <div className="popup-component">
        {/* Custom date picker component */}
          <CustomDatePickerWithCompare
            datePopup={datePopup}
            setDatePopup={setDatePopup}
            checkSelectState={checkSelectState}
            cutomDate={cutomDate}
            dateRange={dateRange}
            setCheckSelectState={setCheckSelectState}
            setComparePeriod={setComparePeriod}
            previousPeriodData={previousPeriodData}
            comparePeriod={comparePeriod}
            getClearDate={getClearDate}
            setDateRange={setDateRange}
            cancelDate={cancelDate}
            applyDate={applyDate}
            isComparePeriod={true}
          />
        </div>
      </div>
    )
}

const mapStateToProps = (state) => {
    return {
      siteReportFilterData: state.siteReportFilterData,
      siteAllPgSummaryData: state.siteReportFilterData.siteAllPgData,
      siteEDPgSummaryData: state.siteReportFilterData.siteEditorialData,
      siteSponsoredPgSummaryData: state.siteReportFilterData.siteSponsoredData,
      isFilter: state.siteReportFilterData.isApplied,
    };
  };

export default  connect (mapStateToProps , {
    selSiteReportFilterValue,
    fetchSiteAllPGSummaryReport,
    fetchSiteEditorialPGSummaryReport,
    fetchSiteSponsoredPGSummaryReport,
    loadingSiteAllPGSummaryTable,
    loadingSiteEditorialPGSummaryTable,
    loadingSiteSponsoredPGSummaryTable,
    fetchSiteChart,
    updateCommonData,
}) (SiteReport)