import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  Table,
  Loader,
  Message,
  Icon,
  Menu,
  Checkbox,
} from "semantic-ui-react";
import "../../../css/common.css";
import { connect } from "react-redux";
import {
  setPageCount,
  fetchPageviewTableData,
  sortEditorialOrganizationTableData,
  showMorePageviewTableData,
  clearEditorialSort,
  showMoreEditorialTableData,
  fetchEditorialTableData,
  sendTableHeaders,
  getTableHeaders,
  clearEditorialSerchedValue,
  selEditorialFilterValue
} from "../../../actions";
import * as actionTypes from "../../../actions/actionTypes";
import { url } from "../../../api/urlHelper";
import { OrganizationTableData } from "../commonHeaders";
import createTableRow from "../commonGenerateTableRows";
import { PAGE_SIZE } from "../../config/TableHeaderNames";
import ShowMoreButton from "../../common/showMoreComponent";
import PageviewIndependentTagsTableHeaderRow from "../PageviewIndependentTagsTableHeaderRow";

const IndependentOrganizationSummary = (props) => {
  const [selectHeaders, setSelectHeaders] = useState();
  const [finalHeaderData, setFinalHeaderData] = useState();
  const [headerColumn, setHeaderColumn] = useState([]);
  const [isSortIcon, setSortIcon] = useState(false);
  const [displayRows, setDisplayRows] = useState(PAGE_SIZE);

  let tableBody;
  let showMoreButton;
  let loading;
  let message;
  let conditionPrevious =
    props.filterData.selCutomRangeChkBox === true &&
    props.isFilApplied &&
    props.filterData.appliedDate === "Custom Date Range";
  //const [pageCount, setPageCount] = useState(2);

  useEffect(() => {
    props.getTableHeaders(
      "independent_organization_tag_summary",
      actionTypes.GETEDITORIALORGANIZATIONLDYNAMICTABLEHEADER
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const headerDataVal = props.getDynamicHeaderData;
    if (!_.isEmpty(headerDataVal)) {
      setSelectHeaders(headerDataVal);
      setFinalHeaderData(headerDataVal);
    } else if (headerDataVal === undefined || _.size(headerDataVal) === 0 ) {
      const constantHeadersArr = [...OrganizationTableData];
      setSelectHeaders(constantHeadersArr);
      setFinalHeaderData(constantHeadersArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getDynamicHeaderData]);

  useEffect(() => {
    const headerName = [];
    finalHeaderData &&
      finalHeaderData.map((name) => {
        headerName.push(name.headerName);
        return "";
      });
    setHeaderColumn(headerName);
  }, [finalHeaderData]);

  useEffect(() => {
    setDisplayRows(PAGE_SIZE);
  }, [props.loading])

  useEffect(() => {
    if(!props.filterData.orgsSummaryToggleValue && props.filterData.selectOrganizationSummaryTable && _.isEmpty(props.editorialOrganizationSummary)){
      if(!props.filterData.selectTopicSummaryTable && !props.filterData.selectPlaceSummaryTable) {
        props.fetchEditorialTableData(
          url.topicOrganizationPlacementReport,
          props.filterData,
          1,
          "",
          actionTypes.COMMONINDEPENDENTPAGESUMMARY,
          null,
          null,
          "independent",
          ""
        );
        props.selEditorialFilterValue(true, actionTypes.ORGSSUMMARYTOGGLEVALUE);
      }
    }
    if(!props.filterData.selectOrganizationSummaryTable){
      props.selEditorialFilterValue(false, actionTypes.ORGSSUMMARYTOGGLEVALUE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterData.selectOrganizationSummaryTable, props.isFilApplied, props.editorialOrganizationSummary]);

  if (props.loading && props.filterData.selectOrganizationSummaryTable) {
    loading = (
      <div className="show-loader">
        <Loader active inline="centered" />
      </div>
    );
  }
  
  if (!_.isEmpty(props.editorialOrganizationSummary) && props.filterData.selectOrganizationSummaryTable) {
    const isShowMoreDisabled = displayRows >= props.editorialOrganizationSummary.length;
    tableBody = (
      <Table.Body>{createTableRow(props.editorialOrganizationSummary, finalHeaderData, conditionPrevious, displayRows, headerColumn, "organization")}</Table.Body>
    );
    showMoreButton = (<ShowMoreButton onClick={()=> setDisplayRows(displayRows + PAGE_SIZE)} isDisabled={isShowMoreDisabled}/>);
  } else if (props.isFilApplied && !props.loading && _.isEmpty(props.editorialOrganizationSummary) && props.filterData.selectOrganizationSummaryTable) {
    message = <Message>No results found.</Message>;
  }
  const clickOnSortIcon = () => {
    setSortIcon(!isSortIcon);
  };

  const clickOnSortHeader = (e, extraData, headerData) => {
    if (extraData.checked) {
      setSelectHeaders([...selectHeaders, headerData]);
    } else {
      const removeData = selectHeaders.filter(
        (hName) => hName.headerName !== headerData.headerName
      );
      setSelectHeaders(removeData);
    }
  };

  const applyButtonClick = () => {
    let finalHeaderArray = [];
    OrganizationTableData.map((mainHeader) => {
      return _.map(selectHeaders, function (selectHeader) {
        if (mainHeader.headerName === selectHeader.headerName) {
          finalHeaderArray.push(mainHeader);
        }
      });
    });

    setFinalHeaderData(finalHeaderArray);
    props.sendTableHeaders(
      "independent_organization_tag_summary",
      finalHeaderArray,
      actionTypes.SENDEDITORIALORGANIZATIONDYANMICTABLEHEADER
    );
    setSortIcon(!isSortIcon);
  };

  return (
    <div style={{padding : "1px"}}>
      <div className="sorted_icon_for_ads">
            <Table.HeaderCell style={{ paddingBottom: "20px" }}>
              <Icon
                name="edit"
                style={{ color: `${isSortIcon ? "#f15a29" : "#b2b3b3"}` }}
                size="large"
                onClick={clickOnSortIcon}
              />
            </Table.HeaderCell>
            {isSortIcon && (
              <div
                className="menu-list-items1"
                style={{ height: "0px", float: "right" }}
              >
                <Menu vertical className="dynamic_table--list tag_summary">
                  {Object.values(OrganizationTableData).map((tableHeader, index) => (
                    <div key={index}>
                      <Checkbox
                        className="table_header--checkbox"
                        style={{ color: "#838486" }}
                        label={tableHeader.name}
                        checked={
                          selectHeaders &&
                          selectHeaders.some(
                            (el) => el.headerName === tableHeader.headerName
                          )
                            ? true
                            : false
                        }
                        onClick={(e, data) =>
                          clickOnSortHeader(e, data, tableHeader)
                        }
                      />
                    </div>
                  ))}
                  <button
                    className="apply_menu--btn"
                    onClick={applyButtonClick}
                  >
                    Apply
                  </button>
                </Menu>
              </div>
            )}
          </div>
      <div className="sortable-table-component" style={{ paddingTop: "30px" }}>
        <Table sortable fixed singleLine>
          {!_.isEmpty(finalHeaderData) && (
            <PageviewIndependentTagsTableHeaderRow
              propData={props}
              headerData={finalHeaderData}
              conditionPrevious={conditionPrevious}
              column={props.orgsSummarycolumn}
              direction={props.orgsSummarydirection}
              click={props.sortEditorialOrganizationTableData}
              actionType={actionTypes.SORTORGANIZATIONEDITORIALSUMMARY}
            />
          )}
          {tableBody}
        </Table>
      </div>
      {message}
      {loading}
      {showMoreButton}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    filterData: state.editorialFilterData,
    reCall: state.editorialFetchDetailData.filterData,
    isFilApplied: state.editorialFetchDetailData.isFilApplied,
    editorialOrganizationSummary: state.editorialFetchDetailData.editorialOrganizationSummary,
    loading: state.editorialFetchDetailData.isLoadingEditorialOrganizationSummary,
    orgsSummarycolumn: state.editorialFetchDetailData.orgsSummarycolumn,
    orgsSummarydirection: state.editorialFetchDetailData.orgsSummarydirection,
    PageCount: state.editorialFetchDetailData.editorialOrganizationSummaryPageCount,
    getDynamicHeaderData:
      state.editorialFetchDetailData.getDynamicEditorialOrganizationSummaryHeaderData,
      editorialFetchDetailData: state.editorialFetchDetailData,
  };
};

export default connect(mapStateToProps, {
  setPageCount,
  fetchPageviewTableData,
  sortEditorialOrganizationTableData,
  showMorePageviewTableData,
  clearEditorialSort,
  showMoreEditorialTableData,
  fetchEditorialTableData,
  sendTableHeaders,
  getTableHeaders,
  clearEditorialSerchedValue,
  selEditorialFilterValue
})(IndependentOrganizationSummary);
