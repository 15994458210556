import React from "react";
import SingleSelectDropDown from "../base/singleSelectDropDown";

const activityOptions = [
    { key: "0", text: "All", value: "all" },
    { key: "1", text: "Low", value: "low" },
    { key: "2", text: "Medium", value: "medium" },
    { key: "3", text: "High", value: "high" }
];

const AccountActivityLevel = (props) => (
	<SingleSelectDropDown
		className="no-blink"
		options={activityOptions}
		label="Account Activity Level"
		placeholder="Account Activity Level"
		{...props}
	/>
);

export default AccountActivityLevel;