import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import * as actionTypes from "../../../actions/actionTypes";
import { selectValue, fetchNewsletterAuthors, clearSearchForAllReport } from "../../../actions";
import SingleSelectDropDown from "../../base/singleSelectDropDown";

const AuthorsOrCreatedBySelection = (props) => {

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    const onSearchHandler = (e) => {
		if (e.target.value.length > 1) {
			props.fetchNewsletterAuthors(
                e.target.value,
                actionTypes[props.reportActionType].FETCH_NEWSLETTERS_AUTHORS,
                props.reportActionType
            );
		} else {
			props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].RESET_NEWSLETTERS_AUTHORS, reportType: props.reportActionType });
		}
	};

    return (
        <SingleSelectDropDown
            className="no-blink"
            clearable
            options={props.commonSelectData.newsletterAuthors.map((req, index) => ({
                key: index,
                text: req.name,
                value: req.id,
            }))}
            onSearchChange={onSearchHandler}
            onChange={(e, { value }) => { dispatchDynamicSelectAction(props, "newslettersAuthor", value); }}
            value={props.filterData.newslettersAuthor}
            label="Created by"
            placeholder="Created by"
        />
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        commonSelectData: state.allReportCommonReducer[ownProps.reportActionType],
    };
};

export default compose(connect(mapStateToProps, {
    selectValue,
    fetchNewsletterAuthors,
    clearSearchForAllReport
}))(AuthorsOrCreatedBySelection);