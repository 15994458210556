import React from "react";
import { Table } from "semantic-ui-react";
import _ from "lodash";
import { DAY_OF_WEEK, DAY_SINCE_PUBLISHED, DAY_SINCE_UPDATED, PUBLISH_DATE } from "../config/TableHeaderNames";

const PageviewIndependentTagsTableHeaderRow = (props) => {
    return (
      <Table.Header>
        <Table.Row>
          {
            props.headerData?.map((hData, idx) => {
              let updateCondition = (
                  props.conditionPrevious && 
                  ( !_.includes(["TOPIC TAGS", "PLACEMENT TAGS", "ORGANIZATION TAGS", "SITES", "PAGE", "AUTHOR", "PRIMARY TOPIC", "AUTHOR NAME", PUBLISH_DATE, DAY_SINCE_PUBLISHED, DAY_SINCE_UPDATED, DAY_OF_WEEK], hData.headerName) )
              );
              return (
                <>
                  <Table.HeaderCell
                    key={idx}
                    className={hData?.className}
                    sorted={ !updateCondition && props.column === hData?.sorted ? props.direction : null }
                    onClick={() => !updateCondition && props.click(props.actionType, hData?.sorted)}
                    textAlign={updateCondition ? "center" : hData?.textAlign}
                    colSpan={updateCondition ? 2 : 1}
                    style={{
                      verticalAlign: !updateCondition && "middle",
                      borderBottom: "none",
                    }}
                  >
                  {hData?.headerName}

                    {updateCondition ? (
                      <Table>
                        <Table.Row>
                          <Table.HeaderCell
                            sorted={ props.column === hData?.currentKey ? props.direction : null }
                            onClick={() => props.click(props.actionType, hData?.currentKey) }
                            style={{ borderBottom: "none" }}
                            textAlign="center"
                          >
                            Current
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            sorted={ props.column === hData?.previousKey ? props?.direction : null }
                            onClick={() => props.click(props.actionType, hData?.previousKey) }
                            style={{ borderBottom: "none" }}
                            textAlign="center"
                          >
                            Previous
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table>
                    ) : (
                      <span
                        style={{
                          textAlign: updateCondition && "center",
                          paddingLeft: updateCondition && "50px",
                        }}
                      >
                      </span>
                    )}
                  </Table.HeaderCell>
                </>
              );
            })
          }
        </Table.Row>
      </Table.Header>
    );
};

export default PageviewIndependentTagsTableHeaderRow;
