import React from "react";
import { connect } from "react-redux";

import * as constName from "../../components/config/const";
import Main from "../../components/DecisionInsightComponent/Upgrade/Main";

const DIEarnedMedia = (props) => {
    return (
        <Main
            reportActionType={constName.MODULE_DI_UPGRADE}
            reportName={constName.DI_UPGRADE_REPORT_NAME}
            filterData={props.filterData}
            label={constName.DI_UPGRADE_LABEL}
        />
    );
};

const mapStateToProps = ({ commonFilterData }) => {
    return {
        filterData: commonFilterData[constName.MODULE_DI_UPGRADE],
    };
};

export default connect(mapStateToProps)(DIEarnedMedia);