import _ from "lodash";
const createCommonReducer = (initialData, actionTypes, id) => (state = initialData, action) => {
    switch (action.type) {
        case actionTypes.FETCH:
            if (_.size(action.selected) > 0) {
                action.selected.forEach(function (id) {
                    action.payload.forEach((data) => {
                        if (data.value === id) {
                            data.disabled = true;
                        }
                    });
                });
                return { ...state, ..._.mapKeys(action.payload, id) };
            }
            action.payload.forEach((data) => {
                data.disabled = false;
            });

            return {
                ..._.mapKeys(initialData, id),
                ..._.mapKeys(action.payload, id),
            };
        case actionTypes.ADD:
            initialData = [];
            const ids = action.payload;
            ids.forEach((id) => {
                initialData.push(state[id]);
            });
            return { ..._.mapKeys(initialData, id) };
        case actionTypes.CLEARSEARCH:
            return { ..._.mapKeys(initialData, id) };
        case actionTypes.SELECTINITIAL:
            initialData = action.data;
            return { ..._.mapKeys(action.data, id) };
        case actionTypes.RESET:
            initialData = [];
            return initialData;
        default:
            return state;
    }
};
export default createCommonReducer;