import React from "react";
import SingleSelectDropDown from "../base/singleSelectDropDown";

const AuthorPicker = (props) => {
  return (
    <SingleSelectDropDown
      clearable
      options={props.authors.map((author, index) => ({
        key: index,
        text: author.name,
        value: author.name
      }))}
      label={"Author"}
      placeholder={"Author"}
      {...props}
    />
  );
};

export default AuthorPicker;
