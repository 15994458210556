import React from "react";
import { Table } from "semantic-ui-react";
import Link from "../../../hyperlinks/link";


const OrgComp = ({ data }) => {
    return (
        (data.organization !== "Anonymous" && data.organization !== "N/A") ?
            <Table.Cell title={data.organization} className="blogs_link space_height">
                {data.organization ? (
                    <Link
                        type={"organizations"}
                        urlParam={data.organization_uuid}
                        refValue={data.organization}
                    />
                ) : (
                    data.organization
                )}
            </Table.Cell> 
            :
            <Table.Cell title={"Anonymous"} className="space_height">Anonymous</Table.Cell>
    );
};

export default OrgComp;