import React, { useState } from "react";
import { Table } from "semantic-ui-react";
import _ from "lodash";
import ExpandCollapse from "../../common/ExpandCollapse";
import Session from "./Session";
import GenerateCellContent from "./GenerateCellContent";
import { FIRST_NAME, LAST_NAME, MEMBER_ACTIONS_TAKEN, MEMBER_ENGAGGEMENTS, MEMBER_PAGEVIEWS, MEMBER_SESSIONS } from "../../config/TableHeaderNames";
import useStickyColumnsEffect from "../../../utilities/stickeyStyle";

const UserDetails = (props) => {
   const {freezeCols, isFreeze} = props
   const [userExpand, setUserExpand] = useState(false);
   const stickeyClassName = freezeCols >= 0 ? (isFreeze ? "sticky-cols-sticky" : "sticky-cols" ) : ""

   useStickyColumnsEffect(freezeCols)

   return (
      <>
         <Table.Row
            className="topic-camp-selected"
            onDoubleClick={() => { setUserExpand(!userExpand) }}
         >
         {
            <Table.Cell className={stickeyClassName}>
               {
                  props.data && !_.isEmpty(Object.values(props.data.sessions)) &&
                  <ExpandCollapse data={props.data.sessions} expanded={userExpand} setExpanded={setUserExpand} marginLeft={"30px"} />
               }
            </Table.Cell>
         }
         {
            props.props && props.props.finalHeaderData.map((header, cellIndex) => {
               const freezeClassName = freezeCols >= 0 ? (cellIndex <= freezeCols ? (isFreeze  ? "sticky-cols-sticky" : "sticky-cols") : "" ) : ""
               const data = props.data;
               let cellContent = "";
               if (header.headerName === FIRST_NAME || header.headerName === LAST_NAME) {
                  const nameKey = header.headerName === FIRST_NAME ? "first_name" : "last_name";
                  const displayName = (data[nameKey] !== "" && data[nameKey] !== "Anonymous") && data[nameKey];
                  if(data[nameKey] !== "" && data[nameKey] !== "Anonymous") {
                     cellContent = GenerateCellContent(displayName, data.user_uuid, "members", cellIndex, freezeClassName);
                  } else if (data.identified) {
                     cellContent = GenerateCellContent("Anonymous", data.user_uuid, "members", cellIndex, freezeClassName);
                  } else {
                     cellContent = <Table.Cell style={{ height: "46px" }} textAlign="left" key={cellIndex} className={freezeClassName}>{"Anonymous"}</Table.Cell>;
                  }
               } else {
                  const val = data[header.sorted];
                  cellContent = <Table.Cell 
                                    style={{ height: "46px" }} 
                                    textAlign={_.includes([MEMBER_ACTIONS_TAKEN, MEMBER_PAGEVIEWS, MEMBER_SESSIONS, MEMBER_ENGAGGEMENTS], header.headerName) ? "right" : "left"} 
                                    key={cellIndex} className={freezeClassName}>
                                       {val}
                                 </Table.Cell>;
               }

               return cellContent;
            })
         }

         </Table.Row>

         {
            props.data && !_.isEmpty(Object.values(props.data.sessions)) && 
            props.data.sessions && Object.values(props.data.sessions).map(
            (session, idx) => userExpand && <Session props={props} data={session} key={idx} freezeCols={freezeCols} isFreeze = {isFreeze}/>
         )}
      </>
   );
};

export default UserDetails;