import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Checkbox, Form, Loader } from "semantic-ui-react";
import { Table, Message } from "semantic-ui-react";
import _ from "lodash";
import { capitalizeFirstLetter, numFormat } from "../../utilities/common";
import SortIcon from "@mui/icons-material/Sort";
import DownloadIcon from '@mui/icons-material/GetApp';
import MailIcon from '@mui/icons-material/Mail';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { PopupMessage } from "./PopupMessage";
import { 
   downloadAndSendAPI, 
   deleteActionAPI, 
   downloadClearSearch, 
   fetchDownloadData,
   downloadCommonDispatch,
} from "../../actions";
import { url } from "../../api/urlHelper";
import * as actionTypes from "../../actions/actionTypes";
import HocDrawer from "../common/hocDrawer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DownloadDrawer from "./DownloadDrawer";
import MessageComponent from "../common/MessageComponent";
import ShowMoreButton from "../common/showMoreComponent";
import { RequestUsersComponent } from "./RequestUsersComponent";
import { Tooltip } from '@mui/material';
import * as titleConst from "../config/const";

const tooltip = {
   fontSize: '16px',
 }

const Main = (props) => {
   const [openMessage, setOpenMessage] = useState(false);
   const [actionType, setActionType] = useState("");
   const [downloadDataId, setDownloadData] = useState({});
   const [selectedIds, setSelectedIds] = useState([]);
   const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
   const [checkingDeleteButton, setCheckingDeleteButton] = useState(false);
   const dispatch = useDispatch();

   useEffect(() => {
      props.downloadCommonDispatch(actionTypes.ISLOADING);
      props.fetchDownloadData(
         props.filterData,
         1,
         "",
         actionTypes.FETCHDOWNLOADDATA,
         null,
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      setTimeout(() => {
         dispatch({ type: actionTypes.AGAINREQUESTRESENDURL });
      }, 5000);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.reSendResponse]);

   useEffect(() => {
      if(props.deleteResponse) {
         setSelectedIds([]);
         setSelectAllCheckbox(false);
         dispatch({ type: actionTypes.AGAINREQUESTDELETEURL });
         props.downloadClearSearch(actionTypes.APPLYDOWNLOADFILTER);
         props.fetchDownloadData( props.filterData, 1, "", actionTypes.FETCHDOWNLOADDATA, null );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.deleteResponse]);

   useEffect(() => {
      if(props.isShowMore && selectAllCheckbox && !_.isEmpty(props.downloadData)) {
         checkAllCheckboxFunction(props.downloadData);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.downloadData, props.isShowMore]);

   const handleClick = (type, data) => {
      setCheckingDeleteButton(false);
      const checkVal = type === 'delete' ? true : false;
      if(type === 'download') {
         const parts = data.response_file_path.split('/');
         const fileName = parts[parts.length - 1];
         const fileNameWithoutExtension = fileName.split('.')[0];
         props.downloadAndSendAPI(url.requestToDownloadURL, data.id, actionTypes.REQUESTDOWNLOADURL, fileNameWithoutExtension);
      } else if(type === 'resend') {
         props.downloadAndSendAPI(url.requestToReSend, data.id, actionTypes.REQUESTRESENDURL, "");
      }
      setOpenMessage(checkVal);
      setActionType(type);
      setDownloadData(data);
   };

   const handleAction = () => {
      if(actionType === 'delete') {
         props.deleteActionAPI(url.requestToDeleteURL, checkingDeleteButton ? selectedIds : [downloadDataId.id], actionTypes.REQUESTDELETEURL, "");
      }
      handleCancel();
   };

   const handleCancel = () => {
      setOpenMessage(false);
      setDownloadData({});
      setActionType('');
   };

   const checkCompletedStatus = (data) => {
      if(data.status === 'completed') {
         return numFormat(data.attempts + 1);
      } else {
         return numFormat(data.attempts);
      } 
   }

   const statusMap = {
      waiting: 'Queued',
      inprogress: 'Processing'
   };

   const handleCheckBoxValue = (e, id) => {
      const isChecked = e.target.checked;
      if (isChecked) {
         setSelectedIds([...selectedIds, id]);
      } else {
         setSelectedIds(selectedIds.filter(item => item !== id));
      }
   };

   const handleDeleteClick = () => {
      setCheckingDeleteButton(true);
      setActionType('delete');
      setOpenMessage(true);
   }

   const handleAllCheckBoxValue = (e) => {
      if(e.target.checked && !_.isEmpty(props.downloadData)) {
         setSelectAllCheckbox(true);
         checkAllCheckboxFunction(props.downloadData);
      } else {
         setSelectAllCheckbox(false);
         setSelectedIds([]);
      }
   }

   const checkAllCheckboxFunction = (data) => {
      const withoutProgressItems = data.filter(item => item.status !== 'inprogress');
      if (withoutProgressItems.length > 0) {
         const ids = withoutProgressItems.map(item => item.id);
         setSelectedIds(ids);
      }
   }

   const createRow = (data) => {
      if (data.length > 0) {
         let tableRow = [];
         data.map((val, index) => {
            const DownloadDrawerTab = HocDrawer(
               ArrowForwardIcon,
               DownloadDrawer,
               "",
               val
            );
            let tableCell = [];
            const reportName = val.user_filter?.search_query?.report_name;
            const reportTableName = val.user_filter?.search_query?.report_table_name;
            let statusVal = (val.status && val.status.toLowerCase().trim()) || '';
            statusVal = statusMap[statusVal] || capitalizeFirstLetter(statusVal);

            
            tableCell.push(
               <Table.Cell key={index + "_id"}>
                  <Checkbox
                     id={val.id}
                     disabled={val.status === 'inprogress' ? true : false}
                     style={{ color: "white" }}
                     className="calcheckbox"
                     checked={selectedIds.includes(val.id)}
                     onChange={(e) => handleCheckBoxValue(e, val.id)}
                  />
               </Table.Cell>
            )
            tableCell.push(<Table.Cell key={index + "_report"} title={reportName ? reportName : "N/A"} >{reportName ? reportName : "N/A"}</Table.Cell>);
            tableCell.push(<Table.Cell key={index + "_table"} title={reportTableName ? reportTableName : "N/A"} >{reportTableName ? reportTableName : "N/A"}</Table.Cell>);
            tableCell.push(<Table.Cell key={index + "_requesters"}><RequestUsersComponent requesters={val.requesters}/></Table.Cell>);
            tableCell.push(<Table.Cell key={index + "_file"} title={val.response_file_path} >{val.response_file_path}</Table.Cell>);
            tableCell.push(<Table.Cell key={index + "_status"} title={statusVal} >{statusVal}</Table.Cell>);
            tableCell.push(<Table.Cell key={index + "_error"} title={val.error_message} >{val.error_message || "N/A"}</Table.Cell>);
            tableCell.push(<Table.Cell key={index + "_is_automated_report"} title={val.is_automated_report} >{val.is_automated_report ? "True" : "False"}</Table.Cell>);
            tableCell.push(<Table.Cell key={index + "_date"} title={val.date} >{val.date}</Table.Cell>);
            tableCell.push(<Table.Cell key={index + "_time"} title={val.time} >{val.time}</Table.Cell>);
            tableCell.push(<Table.Cell key={index + "_attempts"}  textAlign="right" title={checkCompletedStatus(val)} >{checkCompletedStatus(val)}</Table.Cell>);
            tableCell.push(
               <Table.Cell key={index + "_actions"} title={val.actions} >
                  {
                     val.status !== 'completed' ?
                     <Tooltip 
                        title={titleConst.DOWNLOAD_TOOLTIP_TEXT} 
                        sx={tooltip}
                     >
                        <DownloadIcon className='set_disable_opacity icon_set_with_disable'/>
                     </Tooltip>
                     :
                     <DownloadIcon className='icon_set' onClick={() => handleClick("download", val)}/>
                  }
                  <MailIcon className="icon_set" onClick={() => handleClick("resend", val)}/>
                  {
                     val.status === 'inprogress' ? 
                     <Tooltip title={titleConst.DELETE_TOOLTIP_TEXT} sx={tooltip}>
                        <DeleteForeverIcon className='set_disable_opacity icon_set_with_disable'/>
                     </Tooltip>
                     :
                     <DeleteForeverIcon className='icon_set' onClick={() => handleClick("delete", val)}/>
                  }
               </Table.Cell>
            );
            tableCell.push(<Table.Cell key={index + "_icon"} textAlign="right"><DownloadDrawerTab/></Table.Cell>);

            return tableRow.push(<Table.Row key={index} children={tableCell} />);
         });

         return tableRow;
      }
   }

   const showMore = async () => {
      props.downloadCommonDispatch(actionTypes.SHOWMORELOADING);
      props.fetchDownloadData(
         props.filterData,
         props.pageCount,
         "",
         actionTypes.MOREDOWNLOADDATA,
         null,
      );
      props.downloadCommonDispatch(actionTypes.SETDOWNLOADPAGECOUNT);
   };

   return (
      <React.Fragment>
         <Form>
            <Form.Group>
               <span title={selectedIds.length === 0  && titleConst.BUTTON_DISABLE_DELETE_TOOLTIP_TEXT} sx={tooltip}>
                  <button
                     className={selectedIds.length === 0 ? "ui red button disabled" : "ui red button"}
                     disabled={selectedIds.length === 0 ? true : false}
                     onClick={handleDeleteClick}
                  >
                     Delete Selected
                  </button>
               </span>
            </Form.Group>
        </Form>
         {
            openMessage && 
               <PopupMessage 
                  props={props} 
                  data={downloadDataId} 
                  isOpen={openMessage} 
                  handleAction={handleAction}
                  handleCancel={handleCancel}
                  actionType={actionType}
               />
         }
         <div className="sortable-table-component">
            <Table sortable fixed singleLine>
               <Table.Header>
                  <Table.Row>
                     <Table.HeaderCell>
                        <Checkbox
                           id={0}
                           style={{
                              color: "white",
                              marginLeft: "0px",
                              marginRight: "16px",
                              paddingTop: "4px",
                           }}
                           className="calcheckbox"
                           checked={selectAllCheckbox}
                           onChange={handleAllCheckBoxValue}
                        />
                     </Table.HeaderCell>
                     <Table.HeaderCell>REPORT</Table.HeaderCell>
                     <Table.HeaderCell className="table-header_table">TABLE</Table.HeaderCell>
                     <Table.HeaderCell>REQUESTERS</Table.HeaderCell>
                     <Table.HeaderCell className="table-header_file">FILE</Table.HeaderCell>
                     <Table.HeaderCell>STATUS</Table.HeaderCell>
                     <Table.HeaderCell style={{width: '30em'}}>ERROR</Table.HeaderCell>
                     <Table.HeaderCell>AUTOMATED</Table.HeaderCell>
                     <Table.HeaderCell>REQUEST DATE</Table.HeaderCell>
                     <Table.HeaderCell>REQUEST TIME</Table.HeaderCell>
                     <Table.HeaderCell textAlign="right">ATTEMPTS</Table.HeaderCell>
                     <Table.HeaderCell style={{width: '14em'}}>ACTIONS</Table.HeaderCell>
                     <Table.HeaderCell
                        textAlign="right"
                        className="icon"
                     >
                        <SortIcon/>
                     </Table.HeaderCell>
                  </Table.Row>
               </Table.Header>
               {
                  !_.isEmpty(props.downloadData) && 
                  <Table.Body>{createRow(props.downloadData)}</Table.Body>
               }
            </Table>
         </div>
         {
            props.isFilApplied && _.isEmpty(props.downloadData) && !props.loading &&
            <Message>No results found.</Message>
         }
         {
            props.loading && 
            <div className="show-loader">
               <Loader active inline="centered" />
            </div>
         }
         {
            props.reSendResponse &&
            <MessageComponent msgHeader="" msgBody={`File will be send shortly at ${props.userEmail}`} />
         }
         {
            !_.isEmpty(props.downloadData) && 
            <ShowMoreButton onClick={showMore} isDisabled={props.isShowMoreDisabled}/>
         }
      </React.Fragment>
   );
};

const mapStateToProps = (state) => {
   return {
      loading: state.downloadFetchData.isLoading,
      filterData: state.downloadFilterData,
      downloadData: state.downloadFetchData.downladData,
      reSendResponse: state.downloadFilterData.reSendResponse,
      userEmail: state.authorized.userEmail,
      deleteResponse: state.downloadFilterData.deleteResponse,
      isFilApplied: state.downloadFetchData.isFilApplied,
      isShowMoreDisabled: state.downloadFetchData.isShowMoreDisabled,
      pageCount: state.downloadFetchData.pageCount,
      isShowMore: state.downloadFetchData.isShowMore,
   };
};

export default connect(mapStateToProps, {
   downloadAndSendAPI,
   deleteActionAPI,
   downloadClearSearch,
   fetchDownloadData,
   downloadCommonDispatch,
})(Main);  