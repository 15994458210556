import React, { useEffect, useState } from "react";
import { Table } from "semantic-ui-react";
import _ from "lodash";
import SortIcon from "@mui/icons-material/Sort";

const GenericTableForExpandable = ({ data = [], headerOrder = [], columnConfigs = {} }) => {
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
    const [expandedRows, setExpandedRows] = useState({});

    // Use effect to reset expandedRows when data changes
    useEffect(() => {
        setExpandedRows({});
    }, [data]);

    const handleSort = (key) => {
        let direction = "asc";
        if (sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({ key, direction });
    };

    const handleToggleExpand = (rowIndex) => {
        setExpandedRows((prevExpandedRows) => ({
            ...prevExpandedRows,
            [rowIndex]: !prevExpandedRows[rowIndex],
        }));
    };

    const getNestedValue = (obj, path) => {
        if (!path) return undefined;
        return path.split(".").reduce((acc, part) => acc && acc[part], obj);
    };

    const sortedData = () => {
        if (sortConfig.key && _.size(data) > 0) {
            const columnConfig = columnConfigs[sortConfig.key];
            const sortByPath = columnConfig?.sortBy;
            if (!sortByPath) return data;

            const sorted = [...data].sort((a, b) => {
                const aValue = getNestedValue(a, sortByPath);
                const bValue = getNestedValue(b, sortByPath);

                // Convert values to numbers if they are numeric strings
                const aNum = parseFloat(aValue);
                const bNum = parseFloat(bValue);
                const aIsNumeric = !isNaN(aNum);
                const bIsNumeric = !isNaN(bNum);

                if (aIsNumeric && bIsNumeric) {
                    if (aNum < bNum) {
                        return sortConfig.direction === "asc" ? -1 : 1;
                    }
                    if (aNum > bNum) {
                        return sortConfig.direction === "asc" ? 1 : -1;
                    }
                } else {
                    if (aValue < bValue) {
                        return sortConfig.direction === "asc" ? -1 : 1;
                    }
                    if (aValue > bValue) {
                        return sortConfig.direction === "asc" ? 1 : -1;
                    }
                }
                return 0;
            });
            return sorted;
        }
        return data;
    };

    const renderCellContent = (columnConfig, rowData, key, index) => {
        if (columnConfig.component) {
            const CustomComponent = columnConfig.component;
            return <CustomComponent data={rowData} key={key} textAlign={columnConfig.textAlign} index={index} />;
        }
        if (columnConfig.render) {
            return (
                <Table.Cell key={key} title={columnConfig.render(rowData[key])} textAlign={columnConfig.textAlign}>
                    {columnConfig.render(rowData[key])}
                </Table.Cell>
            );
        }
        return (
            <Table.Cell key={key} title={rowData[key]} textAlign={columnConfig.textAlign}>
                {rowData[key]}
            </Table.Cell>
        );
    };

    const renderTableHeader = () => {
        return (
            <Table.Row>
                {headerOrder &&
                    headerOrder.map((val, index) => {
                        const columnConfig = columnConfigs[val.headerKey];
                        const isSortable = columnConfig?.sortable || false;

                        return (
                            <Table.HeaderCell
                                key={index}
                                onClick={() => isSortable && handleSort(val.headerKey)}
                                className={`${columnConfig?.className || ""} ${
                                    isSortable && sortConfig.key === val.headerKey ? (sortConfig.direction === "asc" ? "ascending sorted" : "descending sorted") : ""
                                }`}
                                textAlign={columnConfig?.textAlign}
                            >
                                {val.headerName === "sort_roadblock_icon" ? <SortIcon /> : val.headerName}
                            </Table.HeaderCell>
                        );
                    })}
            </Table.Row>
        );
    };

    const renderChildrenRows = (children) => {
        if (!children || children.length === 0) {
            return null;
        }

        return children.map((child) => (
            <React.Fragment key={child.key}>
                <Table.Row className={`level-${child.level}`} onDoubleClick={() => handleToggleExpand(child.key)} >
                    <Table.Cell collapsing style={{ paddingLeft: `${child.level * 1.5}em` }}>
                        {
                            child.children && child.children.length > 0 &&
                            <i className="material-icons" onClick={() => handleToggleExpand(child.key)}>
                                {expandedRows[child.key] ? "remove" : "add"}
                            </i>
                        }
                    </Table.Cell>
                    {headerOrder.map((val, index) => {
                        const columnConfig = columnConfigs[val.headerKey];

                        if (!columnConfig) {
                            return null; // or handle the error appropriately
                        }

                        return renderCellContent(columnConfig, child, val.headerKey, index);
                    })}
                </Table.Row>
                {expandedRows[child.key] && child.children && renderChildrenRows(child.children, child.key)}
            </React.Fragment>
        ));
    };

    const renderTableBody = () => {
        const sorted = sortedData();
        if (!Array.isArray(sorted)) {
            return null; // or handle the error appropriately
        }

        return sorted.map((row) => (
            <React.Fragment key={row.key}>
                <Table.Row className={expandedRows[row.key] ? "topic-camp-selected-v2" : "topic-camp-un-selected"} onDoubleClick={() => handleToggleExpand(row.key)}>
                    <Table.Cell collapsing>
                        {
                            row.children && row.children.length > 0 &&
                            <i className="material-icons" onClick={() => handleToggleExpand(row.key)}>
                                {expandedRows[row.key] ? "remove" : "add"}
                            </i>
                        }
                    </Table.Cell>
                    {headerOrder.map((val, index) => {
                        const columnConfig = columnConfigs[val.headerKey];

                        if (!columnConfig) {
                            return null; // or handle the error appropriately
                        }

                        return renderCellContent(columnConfig, row, val.headerKey, index);
                    })}
                </Table.Row>
                {expandedRows[row.key] && row.children && renderChildrenRows(row.children, row.key)}
            </React.Fragment>
        ));
    };

    return (
        <React.Fragment>
            <Table.Header>
                {renderTableHeader()}
            </Table.Header>
            <Table.Body>
                {renderTableBody()}
            </Table.Body>
        </React.Fragment>
    );
};

export default GenericTableForExpandable;