import React from "react";
import { Table } from "semantic-ui-react";
import _ from "lodash";
import { ACTION, AD_ORDER, ENGAGEMENT_DATE, ENGAGEMENT_TIME, PAGEVIEWS, PAGE_ELEMENTS, TARGET_NAME } from "../../config/TableHeaderNames";
import useStickyColumnsEffect from "../../../utilities/stickeyStyle";

const PageviewActivity = ({  props, data, count, freezeCols, isFreeze }) => {
    const stickeyClassName = freezeCols >= 0 ? (isFreeze ? "sticky-cols-sticky" : "sticky-cols" ) : ""
    useStickyColumnsEffect(freezeCols)
    return (
        <Table.Row className="li-children">
            <Table.Cell className={stickeyClassName}></Table.Cell>
            {
                props && props.finalHeaderData.map((header, cellIndex) => {
                    const freezeClassName = freezeCols >= 0 ? (cellIndex <= freezeCols ? (isFreeze  ? "sticky-cols-sticky" : "sticky-cols") : "" ) : ""
                    let cellContent = "";
                    if(_.includes([ACTION, ENGAGEMENT_DATE, ENGAGEMENT_TIME, TARGET_NAME, PAGE_ELEMENTS, PAGEVIEWS, AD_ORDER], header.headerName)) {
                        if(header.headerName === TARGET_NAME) {
                            if(data.target_name !== "") {
                                const targetName = data.target_name ? data.target_name : data.target_id
                                cellContent = ( <Table.Cell key={cellIndex} title={targetName} className={`blogs_link space_height ${freezeClassName}`}>
                                                    <a href={data.target_id} target="_blank" rel="noreferrer noopener">
                                                        {targetName}
                                                    </a>
                                                </Table.Cell>
                                )
                            } else {
                                cellContent = <Table.Cell key={cellIndex} className={freezeClassName}/>;
                            }
                        } else if(header.headerName === AD_ORDER) {
                            cellContent = <Table.Cell key={cellIndex} title={data.ad_creative_name} className={freezeClassName}>{data.ad_creative_name}</Table.Cell>;
                        } else {
                            cellContent = <Table.Cell key={cellIndex} className={freezeClassName}>{data[header.sorted]}</Table.Cell>;
                        }
                    } else {
                        cellContent = <Table.Cell key={cellIndex} className={freezeClassName}/>;
                    }
                    
                    return cellContent;
                })
            }
        </Table.Row>
    );
};

export default PageviewActivity;