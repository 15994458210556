import React from "react";
import EditorialChart from "../components/editorial/editorialChart";
import EditorialPageSummmary from "../components/editorial/editorialPageSummary/editorialPageSummmary";
import EditorialPlacementSummary from "../components/editorial/editorialPlacementSummary/editorialPlacementSummary";
import EditorialPrimaryTopicSummary from "../components/editorial/editorialPrimaryTopicSummary";
import EditorialTopicSummary from "../components/editorial/editorialTopicDetails/editorialTopicSummary";
import SearchFilter from "../components/editorial/searchFilter";
import * as actionTypes from "../actions/actionTypes";
import NotifyEmailConfirmation from "../components/common/notifyEmailConfirmation";
import { Message } from "semantic-ui-react";
import { connect } from "react-redux";
import {selEditorialFilterValue, selEditorialToggles, fetchEditorialTableData, checkToggle, editorialDetailsCsvLoading, fetchPageviewTableData } from "../actions";
import Toggle from "../components/base/toggle";
import DownloadXLSX from "../components/common/downloadXLSX";
import IndependentOrganizationSummary from "../components/editorial/independentOrganizationSummary/independentOrganizationSummary";
import EditorialDetailsDetail from "../components/EditorialDetails/editoarialDetailsTable/editorialDetailsDetail";
import { getDays, getReportsByElement } from "../utilities/common";
import NotifyEmail from "../components/common/notifyEmailConfirmation";
import { ORGANIZATION_TAG_SUMMARY, PAGE_SUMMARY, PAGEVIEW_CHART, PAGEVIEW_DETAILS_TABLE, PLACEMENT_TAG_SUMMARY, PRIMARY_TOPIC_SUMMARY, ROLEBASED_ELEMENT_PAGEVIEW, TOPIC_TAG_SUMMARY } from "../components/config/rolebasedReportNames";
import { CHART,  GENERATE_CSV, VIEW } from "../components/config/const";

class Editorial extends React.Component {
	pageviewDetailCsvLink = React.createRef();

  generateCSVAllTables = (actionTypes, table_name, commonType) => {
    DownloadXLSX(
      actionTypes,
      table_name,
      "independent",
      this.props.filterData,
      this.props.userEmail,
      commonType,
      this.props.fetchEditorialTableData
    ); 
  }

  getEditorialPageSummaryExcel = () => {
    DownloadXLSX(
      actionTypes.EDITORIALEMAILPAGEDETAIL, 
      "editorial_page_summary", 
      "independent",
      this.props.filterData,
      this.props.userEmail,
      actionTypes.EDITORIALDETAILEXCEL,
      this.props.fetchEditorialTableData
    );
	};

  getDetailedPageviewExcelCSV = () => {
		let days = getDays(this.props.filterData.startDate, this.props.filterData.endDate)
		if(days > 90) {
            this.setState({ popup: true })
            setTimeout(() => {
                this.setState({ popup: false })
            }, 5000)
		} else {
			this.props.editorialDetailsCsvLoading(actionTypes.EDGENERATECSVLOADING);
            DownloadXLSX(
                actionTypes.EDEMAILCSVPAGEDETAIL, 
                "editorial_detail_table_CSV", 
                "independent-details",
                this.props.filterData,
                this.props.userEmail,
                actionTypes.EDEXCEL,
                this.props.fetchPageviewTableData
            );
	    }
	};

  render() {
    let tableView;
    let primaryTopicSummaryGenerateCSV;
    let pageSummaryGenerateCSV;
    let placementTagSummaryGenerateCSV;
    let topicTagSummaryGenerateCSV;
    let organizationSummaryGenerateCSV;
    let csvDetailDownloadButton;
    const roleReports = getReportsByElement(this?.props?.roleFilterData?.currentUserReportRoleData, this?.props?.roleFilterData?.getAllReports, ROLEBASED_ELEMENT_PAGEVIEW);

    if (!this.props.isFilApplied) {
			tableView = <Message>Please proceed with applying filter.</Message>;
		} 
          primaryTopicSummaryGenerateCSV = (
            <div>
              <button
                className="download-button"
                disabled={false}
                onClick={() => {this.generateCSVAllTables(actionTypes.EDITORIALEMAILPRIMARYESUMMARY,  "editorial_primary_topic_csv", actionTypes.EDITORIALDETAILEXCEL)}}
              >
                Generate CSV
              </button>
            </div>
          );
    
          pageSummaryGenerateCSV = (
            <div>
              <button
                className="download-button"
                disabled={false}
                onClick={() => {this.generateCSVAllTables(actionTypes.EDITORIALEMAILPAGESUMMARYCSV, "editorial_page_summary_csv", actionTypes.EDITORIALDETAILEXCEL)}}
              >
                Generate CSV
              </button>
            </div>
          );   
        placementTagSummaryGenerateCSV = (
          <div>
            <button
              className="download-button"
              disabled={false}
              onClick={() => {this.generateCSVAllTables(actionTypes.EDITORIALEMAILPLACEMENTESUMMARY, "editorial_placement_csv",actionTypes.EDITORIALDETAILEXCEL)}}
            >
              Generate CSV
            </button>
          </div>
        );    
        topicTagSummaryGenerateCSV = (
          <div>
            <button
              className="download-button"
              disabled={false}
              onClick={() => {this.generateCSVAllTables(actionTypes.EDITORIALEMAILTOPICTAGSUMMARY, "editorial_topic_tag_csv", actionTypes.EDITORIALDETAILEXCEL)}}
            >
              Generate CSV
            </button>
          </div>
        );   
        organizationSummaryGenerateCSV = (
          <div>
            <button
              className="download-button"
              disabled={false}
              onClick={() => this.generateCSVAllTables(actionTypes.EDITORIALEMAILORGANIZATIONSUMMARYCSV, "editorial_organization_summary_csv", actionTypes.EDITORIALDETAILEXCEL)}
            >
              Generate CSV
            </button>
          </div>
        );
        csvDetailDownloadButton = (
          <div>
            <button
              className="download-button"
              disabled={false}
              onClick={() => this.getDetailedPageviewExcelCSV()}
            >
              Generate CSV
            </button>
          </div>
        );   

    return (
      <div>
        <div className="page-name-comp">
          <p className="page-name">Pageview</p>
          <p>
            {this.props.reportDescription.find(report => report.name === "Pageview")?.description}
          </p>
        </div>
        <SearchFilter roleReports={roleReports} />
        {
          (roleReports?.some((report) => [PAGEVIEW_CHART].includes(report.element) && report.permission === CHART)) &&
          <EditorialChart />
        }
        <div>
          {
            roleReports?.some((report) => [PRIMARY_TOPIC_SUMMARY].includes(report.element) && [VIEW, GENERATE_CSV].includes(report.permission)) &&
            <div className="table-heading-block">
              <div className="table-name">
                <p>Primary Topic Summary</p>
              </div>
              {<Toggle
                label={this.props.filterData.isTopicSummary ? "Hide" : "Show"}
                disabled={!this.props.isFilApplied}
                style={{ marginBottom: "20px", paddingLeft: "30px", paddingTop: "10px" }}
                onChange={() => { this.props.checkToggle({ key: "isTopicSummary", value: this.props.filterData.isTopicSummary }); }}
                checked={this.props.filterData.isTopicSummary}
                />
              }
                {
                  this.props.isFilApplied && (roleReports?.some((report) => [PRIMARY_TOPIC_SUMMARY].includes(report.element) && report.permission === GENERATE_CSV)) &&
                  <div className="class-relative">
                    <div className="pg detail-excel-button">
                      {primaryTopicSummaryGenerateCSV}
                    </div>
                    <div className="pg popup">
                      {this.props.postEmailUpdatePrimaryTopicSummaryCSV ? (
                        <NotifyEmailConfirmation
                          email={this.props.postEmailUpdatePrimaryTopicSummaryCSV}
                          table="primary_topic_CSV"
                          cardPopup={this.props.postEmailUpdatePrimaryTopicSummaryCSV}
                          cardPopupClose={actionTypes.SETINDEPENDATNTPOSTEMAILUPDATEPRIMARYTOPICSUMMARY}
                          csvExcel={true}
                        />
                      ) : null}
                    </div>
                  </div>
                }
            </div>
          }
        </div>
        {
          (roleReports?.some((report) => [PRIMARY_TOPIC_SUMMARY].includes(report.element) && report.permission === VIEW)) &&
          <EditorialPrimaryTopicSummary />
        }
        <div>
          {roleReports?.some(
              (report) =>
              [TOPIC_TAG_SUMMARY].includes(report.element) &&
                            [VIEW, GENERATE_CSV].includes(report.permission)
                        ) &&
            <div className="table-heading-block">
              <div className="table-name">
                <p>Topic Tag Summary</p>
              </div>
              {<Toggle
                label={this.props.filterData.selectTopicSummaryTable ? "Hide" : "Show"}
                disabled={!this.props.isFilApplied}
                style={{ marginBottom: "20px", paddingLeft: "30px", paddingTop: "10px" }}
                onChange={() => { this.props.selEditorialToggles(actionTypes.SELEDITORIALTOPICSUMMMARYTABLETOGGLE); }}
                checked={this.props.filterData.selectTopicSummaryTable}
              />}
              { this.props.isFilApplied && (roleReports?.some((report) => [TOPIC_TAG_SUMMARY].includes(report.element) && report.permission === GENERATE_CSV)) &&
                <div className="class-relative" style={{ marginTop: -43 }}>
              
                  <div className="pg detail-excel-button">
                    {topicTagSummaryGenerateCSV}
                  </div>
              
                  <div className="pg popup">
                    {this.props.postEmailUpdateTopicTagsSummaryCSV ? (
                      <NotifyEmailConfirmation
                        email={this.props.postEmailUpdateTopicTagsSummaryCSV}
                        table="placement_topic_CSV"
                        cardPopup={this.props.postEmailUpdateTopicTagsSummaryCSV}
                        cardPopupClose={actionTypes.SETINDEPENDATNTPOSTEMAILUPDATETOPICTAGSSUMMARY}
                        csvExcel={true}
                      />
                    ) : null}
                  </div>
                
                </div>
              }
            </div>
          }
        </div>
        {
          (roleReports?.some((report) => [TOPIC_TAG_SUMMARY].includes(report.element) && report.permission === VIEW)) &&
          <EditorialTopicSummary />
        }
        <div>
          {roleReports?.some(
                        (report) =>
              [ORGANIZATION_TAG_SUMMARY].includes(report.element) &&
                          [VIEW, GENERATE_CSV].includes(report.permission)
                      ) &&
            <div className="table-heading-block">
              <div className="table-name">
                <p>Organization Tag Summary</p>
              </div>
              {
                <Toggle
                  label={this.props.filterData.selectOrganizationSummaryTable ? "Hide" : "Show"}
                  disabled={!this.props.isFilApplied}
                  style={{ marginBottom: "20px", paddingLeft: "30px", paddingTop: "10px" }}
                  onChange={() => { this.props.selEditorialToggles(actionTypes.SELEDITORIALORGANIZATIONSUMMARYTABLETOGGLE) }}
                  checked={this.props.filterData.selectOrganizationSummaryTable}
                />
              }
              {
                this.props.isFilApplied && (roleReports?.some((report) => [ORGANIZATION_TAG_SUMMARY].includes(report.element) && report.permission === GENERATE_CSV)) &&
                <div className="class-relative" style={{ marginTop: -43 }}>
                  <div className="pg detail-excel-button">
                    {organizationSummaryGenerateCSV}
                  </div>
                  <div className="pg popup">
                    {this.props.postEmailUpdateOrganizationSummaryCSV ? (
                      <NotifyEmailConfirmation
                        email={this.props.postEmailUpdateOrganizationSummaryCSV}
                        table="organization_summary_csv"
                        cardPopup={this.props.postEmailUpdateOrganizationSummaryCSV}
                        cardPopupClose={actionTypes.SETEDITORIALORGANIZATIONSUMMARYCSV}
                        csvExcel={true}
                      />
                    ) : null}
                  </div>
                </div>
              }
            </div>
          }
        </div>
        {
          (roleReports?.some((report) => [ORGANIZATION_TAG_SUMMARY].includes(report.element) && report.permission === VIEW)) &&
          <IndependentOrganizationSummary />
        }
        <div>
          {
            roleReports?.some(
                          (report) =>
                [PLACEMENT_TAG_SUMMARY].includes(report.element) &&
                            [VIEW, GENERATE_CSV].includes(report.permission)
                        ) &&
            <div className="table-heading-block">
              <div className="table-name">
                <p>Placement Tag Summary</p>
              </div>
              {
                <Toggle
                  label={this.props.filterData.selectPlaceSummaryTable ? "Hide" : "Show"}
                  disabled={!this.props.isFilApplied}
                  style={{ marginBottom: "20px", paddingLeft: "30px", paddingTop: "10px" }}
                  onChange={() => {
                    this.props.selEditorialToggles(actionTypes.SELEDITORIALPLACESUMMMARYTABLETOGGLE);
                  }}
                  checked={this.props.filterData.selectPlaceSummaryTable}
                />
                }
                {this.props.isFilApplied && (roleReports?.some((report) => [PLACEMENT_TAG_SUMMARY].includes(report.element) && report.permission === GENERATE_CSV)) &&
                  <div className="class-relative" style={{ marginTop: -43 }}>
                    <div className="pg detail-excel-button">
                      {placementTagSummaryGenerateCSV}
                    </div>
                    <div className="pg popup">
                      {this.props.postEmailUpdatePlacementeSummaryCSV ? (
                        <NotifyEmailConfirmation
                          email={this.props.postEmailUpdatePlacementeSummaryCSV}
                          table="placement_topic_CSV"
                          cardPopup={this.props.postEmailUpdatePlacementeSummaryCSV}
                          cardPopupClose={actionTypes.SETINDEPENDATNTPOSTEMAILUPDATEPLACEMENTSUMMARY}
                          csvExcel={true}
                        />
                      ) : null}
                    </div>
                  </div>
                }
            </div>
          }
        </div>
        {
          (roleReports?.some((report) => [PLACEMENT_TAG_SUMMARY].includes(report.element) && report.permission === VIEW)) &&
          <EditorialPlacementSummary />
        }
        <div>
          {
            roleReports?.some(
                          (report) =>
                [PAGE_SUMMARY].includes(report.element) &&
                            [VIEW, GENERATE_CSV].includes(report.permission)
                        ) &&
            <div className="table-heading-block">
              <div className="table-name">
                <p>Page Summary</p>
              </div>
              {<Toggle
                label={this.props.filterData.selectPageSummaryTable ? "Hide" : "Show"}
                disabled={!this.props.isFilApplied}
                style={{ marginBottom: "20px", paddingLeft: "30px", paddingTop: "10px" }}
                onChange={() => { this.props.selEditorialToggles(actionTypes.SELEDITORIALPAGESUMMARYTABLETOGGLE) }}
                checked={this.props.filterData.selectPageSummaryTable}
                />}
                {
                  this.props.isFilApplied && (roleReports?.some((report) => [PAGE_SUMMARY].includes(report.element) && report.permission === GENERATE_CSV)) &&
                  <div className="class-relative" style={{ marginTop: -43 }}>
                    <div className="pg detail-excel-button">
                      {pageSummaryGenerateCSV}
                    </div>
                    <div className="pg popup">
                      {this.props.postEmailUpdatePageSummaryCSV ? (
                        <NotifyEmailConfirmation
                          email={this.props.postEmailUpdatePageSummaryCSV}
                          table="page_summary_csv"
                          cardPopup={this.props.postEmailUpdatePageSummaryCSV}
                          cardPopupClose={actionTypes.SETEDITORIALPAGESUMMARYCSV}
                          csvExcel={true}
                        />
                      ) : null}
                    </div>
                  </div>
                }
            </div>
          }
        </div>
        {(roleReports?.some((report) => [PAGE_SUMMARY].includes(report.element) && report.permission === VIEW)) &&
          <EditorialPageSummmary />
        }
        <div>
          {
            roleReports?.some(
              (report) =>
                [PAGEVIEW_DETAILS_TABLE].includes(report.element) &&
                [VIEW, GENERATE_CSV].includes(report.permission)
            ) &&
            <div className="table-heading-block">
              <div className="table-name">
                <p>Pageview Details Table</p>
              </div>
              {
                <Toggle
                  label={this.props.filterData.selectPageDetailTable ? "Hide" : "Show"}
                  disabled={!this.props.isFilApplied}
                  style={{ marginBottom: "20px", paddingLeft: "30px", paddingTop: "10px" }}
                  onChange={() => { this.props.selEditorialToggles(actionTypes.SEL_ED_TABLE_TOGGLE) }}
                  checked={this.props.filterData.selectPageDetailTable}
                />
                }
                {this.props.isFilApplied && (roleReports?.some((report) => [PAGEVIEW_DETAILS_TABLE].includes(report.element) && report.permission === GENERATE_CSV)) &&
                  <div className="class-relative">
                    <div className="pg detail-excel-button">
                      {csvDetailDownloadButton}
                    </div>
                    <div className="pg popup">
                      {this.props.postEmailUpdateCSV ? (
                        <NotifyEmail
                          email={this.props.postEmailUpdateCSV}
                          table="independent-detail-CSV"
                          cardPopup={this.props.postEmailUpdateCSV}
                          csvExcel={true}
                        />
                      ) : null}
                    </div>
                  </div>
                }
            </div>
          }
          {(roleReports?.some((report) => [PAGEVIEW_DETAILS_TABLE].includes(report.element) && report.permission === VIEW)) &&
            <EditorialDetailsDetail />
          }
        </div>
        {tableView}
      </div>
    );
  }
};

const mapStateToProps = (state) => {
	return {
    editorialPrimaryTopicSummary: state.editorialFetchDetailData.editorialPrimaryTopicSummary,
    isFilApplied: state.editorialFetchDetailData.isFilApplied,
    editorialPageSummary:state.editorialFetchDetailData.editorialPageSummary,
		filterData: state.editorialFilterData,
		pageviewDetailCsv: state.editorialFetchDetailData.pageviewDetailCsv,
		isLoadingPageviewDetailCsv:
			state.editorialFetchDetailData.isLoadingPageviewDetailCsv,
		emailPageSummary: state.editorialFetchDetailData.emailPageSummary,
		emailPageDetail: state.editorialFetchDetailData.emailPageDetail,
		cardPopupPageSummary: state.editorialFetchDetailData.cardPopupPageSummary,
		cardPopupPageDetail: state.editorialFetchDetailData.cardPopupPageDetail,
		postEmailUpdate: state.editorialFetchDetailData.postEmailUpdate,
		postEmailUpdatePageSummary:
			state.editorialFetchDetailData.postEmailUpdatePageSummary,
		userEmail: state.authorized.userEmail,
    postEmailUpdatePageviewDetails: state.editorialFetchDetailData.postEmailUpdatePageviewDetails,
    postEmailUpdatePrimaryTopicSummaryCSV: state.editorialFetchDetailData.postEmailUpdatePrimaryTopicSummaryCSV,
    postEmailUpdatePageSummaryCSV: state.editorialFetchDetailData.postEmailUpdatePageSummaryCSV,
    placementEditorialSummary:
    state.editorialFetchDetailData.placementEditorialSummary,
    postEmailUpdatePlacementeSummaryCSV: state.editorialFetchDetailData.postEmailUpdatePlacementeSummaryCSV,
    topicEditorialSummary: state.editorialFetchDetailData.topicEditorialSummary,
    postEmailUpdateTopicTagsSummaryCSV: state.editorialFetchDetailData.postEmailUpdateTopicTagsSummaryCSV,
    editorialOrganizationSummary: state.editorialFetchDetailData.editorialOrganizationSummary,
    postEmailUpdateOrganizationSummaryCSV: state.editorialFetchDetailData.postEmailUpdateOrganizationSummaryCSV,
    pageviewAuthorSummary: state.editorialFetchDetailData.pageviewAuthorSummary,
    pageviewAuthorSummaryCSV: state.editorialFetchDetailData.pageviewAuthorSummaryCSV,
    
    pageviewDetailed: Object.values(state.editorialFetchDetailData.pageviewDetailedData),
    postEmailUpdateCSV: state.editorialDetailsFetchData.postEmailUpdateCSV,
    reportDescription: state.allFilterReportData.reportDescription,
    roleFilterData: state.allFilterReportData,
	};
};

export default connect(mapStateToProps, {
	selEditorialFilterValue,
  selEditorialToggles,
  fetchEditorialTableData,
  checkToggle,
  editorialDetailsCsvLoading,
  fetchPageviewTableData,
  getReportsByElement
})(Editorial);
