import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Chart } from "react-google-charts";
import { getDateEng, numFormat } from "../../utilities/common";

let tickSize = [];
const LeadValidationChart = (props) => {
	const [legendHidden, setLegendHidden] = useState([]);
    const chartRef = useRef(null);
	let debouncer;

	const legendClicked = () =>{
		if (legendHidden.includes("projected_completion")) {
			document.getElementsByTagName("path")[10].style.display="none";
		}
		if(legendHidden.includes("engagement_delivered")){
		  document.getElementsByTagName("path")[11].style.display="none";
		}
	}
	useEffect(()=>{
		legendClicked();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [legendHidden, props.filterData]);


	useEffect(()=>{
		setLegendHidden([]);
	}, [props.engagementValidationChart])

	const onLegendClick = (line) => {
		legendHidden.includes(line) ? setLegendHidden((i)=>i.filter((element)=> element !== line)) :setLegendHidden((i)=>[...i, line]);
	}
	
	const  getCoords = (chart) => {
		var chartLayout = chart.getChartLayoutInterface();
		var chartBounds = chartLayout.getChartAreaBoundingBox();
	
		return {
			
		  x: {
			min: chartLayout.getHAxisValue(chartBounds.left),
			max: chartLayout.getHAxisValue(chartBounds.width + chartBounds.left)
		  },
		  y: {
			min: chartLayout.getVAxisValue(chartBounds.top),
			max: chartLayout.getVAxisValue(chartBounds.height + chartBounds.top)
		  },
  
		};
	  }
	const addChartGradient = (chart) => {
			var chartDiv = chart.getContainer("chart-div");
			var svg = chartDiv.getElementsByTagName("svg")[0];
			var properties = {
				id: "chartGradient",
				x1: "0%",
				y1: "0%",
				x2: "0%",
				y2: "100%",
				stops: [
					{ offset: '0%', 'stop-color':'#F8AD94'},
					{ offset: '100%', 'stop-color': 'rgba(255,255,255,0)' }
				]
			};
			createGradient(svg, properties); 
			var chartPath = svg.getElementsByTagName('path')[0];  //0 path corresponds to legend path
			chartPath.setAttribute('fill', 'url(#chartGradient)');
		}
	const  createGradient = (svg, properties) =>  {
			var svgNS = svg.namespaceURI;
			var grad = document.createElementNS(svgNS, 'linearGradient');
			grad.setAttribute('id', properties.id);
			["x1","y1","x2","y2"].forEach(function(name) {
				if (properties.hasOwnProperty(name)) {
					grad.setAttribute(name, properties[name]);
				}
			});
			for (var i = 0; i < properties.stops.length; i++) {
				var attrs = properties.stops[i];
				var stop = document.createElementNS(svgNS, 'stop');
				for (var attr in attrs) {
					if (attrs.hasOwnProperty(attr)) stop.setAttribute(attr, attrs[attr]);
				}
				grad.appendChild(stop);
			}
			var defs = svg.querySelector('defs') 
			defs.insertBefore(document.createElementNS(svgNS, 'defs'), defs.firstChild);
			return defs.appendChild(grad);
		}
	let d1 = [];

	const leadValidationData = () => {
		var data = [];
		let d = [];

		d.push({ type: "date" });
		d.push({ type: "string", role: "tooltip", p: { html: true } });
		d.push({ type: "number" });
		d.push({ type: "number"});
		// d.push({ type: "number"});
		data.push(d);

		tickSize = [];

		props.engagementValidationChart.forEach((e) => {
			d = [];
			d1.push(new Date(e.date));
			d.push(new Date(e.date));
			d.push(
				`<div class="tooltip-pageview-values" style='width: 180px; padding-top: 10px;'>` +
					getDateEng(e.date) +
					`<hr></hr>` +
					" <span style='height: 6px; width: 6px; margin-right: 5px; background-color:#FCAF17;border-radius: 50%; display: inline-block;'></span>" +
					numFormat(e.engagement_projection) +
					` - Projected Completion` +
					`<br />` +
					" <span style='height: 6px; width: 6px; margin-right: 5px; background-color: #F15A29;border-radius: 50%; display: inline-block;'></span>" +
					numFormat(e.engagement_delivered) +
					` - Engagements Delivered` +
					`</div>`
			);
			d.push(e.engagement_projection)
			d.push(e.engagement_delivered);
			data.push(d);
            tickSize.push(new Date(e.date));
		});
		return data;
	};
	//let container = document.getElementById('chart-div');
	
	let engagementValidationChart = leadValidationData();
 
    const options = {
		chartArea: { left: "4%", top: "13%", width: '93%',height: '80%'},
		intervals: { style: "line" },
		colors: ["#CC167F", "#CC167F"],
		legend: "none",
		vAxis: {
			format: "short",
			minValue: 0,
			baselineColor: "#B6B7B8",
			minorGridlines: {
				count: 0,
			},
		},
		hAxis: {
			baselineColor: "#fff",
			gridlineColor: "#fff",
			format: "MMM dd",
			ticks: tickSize,
			// gridlines: {
			// 	minSpacing: 0,
			// },
			minorGridlines: {
				count: 0,
			},
			// gridlines: {
			// 	interval: 1,
			//   },
			viewWindowMode: "pretty",
		},
		explorer: {
			actions: ["dragToZoom", "rightClickToReset"],
			axis: "horizontal",
			keepInBounds: true,
			maxZoomIn: 2,
			//  zoomDelta: 2,
		},
		crosshair: {
			orientation: "vertical",
			color: "grey",
			opacity: 2,
			trigger: "both",
		},
		focusTarget:"category",
        seriesType: "line",
		series: {
			0: {axis: "Impressions", targetAxisIndex: 0, color: "#FCAF17",},
            1:{axis: "Ctr", targetAxisIndex: 0, color: "#F15A29", type:'area'}
		},
		//focusTarget: "category",
		tooltip: { isHtml: true },
	};
if (props.engagementValidationChart && props.isFilApplied) {
	return (
		<div className="lead-validation-graph" style={{ height: "470px" }} ref={chartRef}  id="chart-div">
			 <div>
            <div>
			  <div
			    style={{
              position: "relative",
			  marginTop: "25px",
			  zIndex: 1,
			  marginLeft: -56,
            }} >
			  <div className="legend-item"  style={{ cursor: "pointer",  paddingLeft: 30, paddingRight: -20}}>
              <span
                style={{ backgroundColor: !legendHidden.includes("projected_completion") ? "#FCAF17": "grey", cursor: "pointer"}}
                className="legend-circle"
				onClick={()=>{onLegendClick("projected_completion");}}
              />
              <text style={{ color: "#656669", marginRight:40}} className="topic-text">Projected Completion</text>
            </div>
			<div className="legend-item" style={{cursor: "pointer",  marginLeft: -37}}>
			<span
                style={{ backgroundColor: !legendHidden.includes("engagement_delivered")  ? "#F15A29": "grey", cursor: "pointer"}}
                className="legend-circle"
				onClick={()=>{onLegendClick("engagement_delivered")}}
              /><text style={{ color: "#656669", marginRight:50,}} className="topic-text">Engagements Delivered</text>
			</div>
			  </div>
              
         </div>
		</div>
		
			<Chart
				chartType="LineChart"
				width="100%"
				height="400px"
				style={{marginTop: "-25px", marginLeft: "-0.5rem"}}
				data={engagementValidationChart}
				options={options}
				chartEvents={[
					{
					  eventName: "ready",
					  callback: ({ chartWrapper}) => {
					    addChartGradient(chartWrapper);
					  }
					},
					{
					   eventName: "select",
					   callback: ({ chartWrapper}) => {
					  addChartGradient(chartWrapper);
					}
					},
					{
						eventName: "ready",
						callback: ({ chartWrapper, google, e }) => {
							const chart = chartWrapper.getChart();
							var zoomLast = getCoords(chart);
							var observer = new MutationObserver(function () {
							  var zoomCurrent = getCoords(chart);
							  if (JSON.stringify(zoomLast) !== JSON.stringify(zoomCurrent)) {
								zoomLast = getCoords(chart);
								addChartGradient(chartWrapper);
							  }
							  clearTimeout(debouncer);
							  debouncer = setTimeout(() => {
								addChartGradient(chartWrapper); 
							  }, 300);
							});
							observer.observe(chartRef.current, {
							  childList: true,
							  subtree: true
							});
						}
					}
				  ]}
			/>
		</div>
	);
}
	return (
	<></>
	);
};

const mapStateToProps = (state) => {
	return {
		engagementValidationChart: state.leadFetchData.engagementValidationChart,
		isFilApplied: state.leadFetchData.isFilApplied,
		filterData: state.leadFilterData,
	};
};

export default connect(mapStateToProps)(LeadValidationChart);
