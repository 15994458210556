import axios from "../api/axios";
import { headers } from "../api/apiConstants";
import { url } from "../api/urlHelper";
import * as actionTypes from "./actionTypes";


export const fetchProductivityDetail = (data, dispatchType) => async (dispatch) => {
	await axios
		.post(
			url.productivityReport,
			data,
			{
				headers: headers(),
			}
		)
		.then((res) => {
			dispatch({
				type: dispatchType,
				payload: res.data,
			});
		})
		.catch((err) => {
			if (err.response) {
				dispatch({ type: actionTypes.ERRORCODE, payload: err.response.status });
			}
		});
}

export const getAuthorsInfo = async (params) => {
	try {
		const res = await axios.post(
			url.authorDataSearch,
			{
				"name": params,
				"limit": 20,
				"page": 1
			},
			{
				headers: headers(),
			}
		);
		return res.data;
	} catch (err) {
		return err;
	}
};