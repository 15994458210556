import axios from "../api/axios";
import * as actionTypes from "./actionTypes";
import { headers } from "../api/apiConstants";
import { url } from "../api/urlHelper";
import { extractFileName, getApiDate } from "../utilities/common";
import { findReportFromURL } from "../components/config/FindReportFromURL";
import { getCookie } from "../utilities/envCommon";
import { PrivacyConsentDataHandler } from "../utilities/ApiPayloadHandler";

export const leadEngSetPageCount = (dispatchType) => {
	return {
		type: dispatchType,
	};
};

export const selLeadStartDate = (date) => (dispatch) => {
	dispatch({
		type: actionTypes.SELLEADSTARTDATE,
		payload: date,
	});
};

export const prevLeadAppliedDate = (date) => (dispatch) => {
	dispatch({
		type: actionTypes.SELLEADPREVAPPLIEDDATE,
		payload: date,
	});
};

export const prevLeadSelStartDate = (date) => (dispatch) => {
	dispatch({
		type: actionTypes.SELLEADSTARTDATEPREV,
		payload: date,
	});
};

export const prevLeadSelEndDate = (date) => (dispatch) => {
	dispatch({
		type: actionTypes.SELLEADENDDATEPREV,
		payload: date,
	});
};

export const selLeadEndDate = (date) => async (dispatch) => {
	await dispatch({
		type: actionTypes.SELLEADENDDATE,
		payload: date,
	});
};

export const applyLeadDate = (val) => (dispatch) => {
	dispatch({
		type: actionTypes.APPLYLEADDATE,
		payload: val,
	});
};

export const selLeadOrgs = (orgs) => (dispatch) => {
	dispatch({
		type: actionTypes.SELLEADORGS,
		payload: orgs,
	});
};

export const selLeadPageTitle = (title) => (dispatch) => {
	dispatch({
		type: actionTypes.SELLEADPAGETITLE,
		payload: title,
	});
};

export const selLeadCampaigns = (campaigns) => (dispatch) => {
	dispatch({
		type: actionTypes.SELLEADCAMPAIGNS,
		payload: campaigns,
	});
};

export const selLeadOrder = (order) => (dispatch) => {
	dispatch({
		type: actionTypes.SELLEADORDER,
		payload: order,
	});
};

export const selLeadStatus = (site) => (dispatch) => {
	dispatch({
		type: actionTypes.SELLEADSTATUS,
		payload: site,
	});
};

export const selLeadSite = (status) => (dispatch) => {
	dispatch({
		type: actionTypes.SELLEADSITE,
		payload: status,
	});
};

export const selLeadType = (type) => (dispatch) => {
	dispatch({
		type: actionTypes.SELLEADTYPE,
		payload: type,
	});
};

export const selLeadEngOrgs = (engOrg) => (dispatch) => {
	dispatch({
		type: actionTypes.SELLEADENGORGS,
		payload: engOrg,
	});
};

export const applyLeadFilter = () => (dispatch) => {
	dispatch({
		type: actionTypes.APPLYLEADFILTER,
	});
};

export const clearLeadFilter = (data) => (dispatch) => {
	dispatch({
		type: actionTypes.CLEARLEADFILTER,
		payload: data
	});
};

export const selLeadPartner = (leadPartner) => (dispatch) => {
	dispatch({
		type: actionTypes.SELLEADPARTNER,
		payload: leadPartner,
	});
};

export const selClientLeadEngState = (status) => (dispatch) => {
	dispatch({
	  type: actionTypes.SELENGAGEMENTDELIVERYSTATE,
	  payload: status,
	});
};

export const fetchLeadEngSummary =
	(uri, filterData, page, type = "", dispatchType, isLeadClient = false) =>
	async (dispatch) => {
		let siteValue = filterData.site; 
		let verificationState = isLeadClient === true  ? filterData.clientLeadFilterVerificationState : filterData.leadEngsFilterVerificationState;
		let verificationStatus = isLeadClient  === true ? filterData.clientLeadFilterStatus : filterData.leadEngsFilterStatus;

		if (filterData.site === "all") {
			siteValue = "";
		}
		let assetType = filterData.assetType;
		if (filterData.assetType === "all") {
			assetType = "";
		}
		let status = filterData.campaignStatus;
		let IncludeDeleted = false;
		if (filterData.campaignStatus === "all") {
			status = "";
			IncludeDeleted = true;
		} else if (filterData.campaignStatus === "archived") {
			status = "archived";
		}
		let report = "engagement";
		
		if (isLeadClient) {
			report = "lead";
			if(filterData.clientLeadFilterVerificationState === "All" || filterData.clientLeadFilterVerificationState === "all"){
				verificationState = "";
			} 
			if(filterData.clientLeadFilterStatus === "All" || filterData.clientLeadFilterStatus === "all"){
				verificationStatus = "";
			}
		} else{
			if(filterData.leadEngsFilterVerificationState === "All"){
				verificationState = "";
			}
			if(filterData.leadEngsFilterStatus  === "All"){
				verificationStatus = ""
			}
		}
		if (isLeadClient) {
			report = "lead";
			if(filterData.clientLeadFilterStatus === "Received"){
				verificationState = "Received";
			}
		} else{
			if(filterData.leadEngsFilterStatus  === "Received"){
				verificationState = "Received"
			}
		}
		let onPaceValue = filterData.pacingFilter
		if(filterData.pacingFilter === "all"){
			onPaceValue = ""
		}
		if(filterData.trafficType === "all") {
			filterData.trafficType = "";
		}
		const reportData = findReportFromURL(uri, "");
		const consentPayload = PrivacyConsentDataHandler(filterData);
		await axios
			.post(
				uri,
				{
					organizations: filterData.organization,
					campaigns: filterData.campaign,
					orders: filterData.order,
					engaged_organizations: filterData.engagedOrganizations,
					lead_partner: filterData.leadPartner,
					site: siteValue,
					status: status,
					asset_type: assetType,
					start_date: getApiDate(filterData.startDate).concat(" 00:00:00"),
					end_date: getApiDate(filterData.endDate).concat(" 23:59:59"),
					override_lead_report_filters: filterData.overrideLeadReportFilters,
					exclude_internal_events: filterData.excludeInternalEvents,
					exclude_organization_events: filterData.excludeOrganizationEvents,
					exclude_competitors: filterData.excludeCompetitorEvents,
					exclude_gated_pageviews: filterData.excludeGatedPageviews,
					exclude_free_email_registrations: filterData.excludeFreeEmailEvents,
					order: "ASC",
					order_by: "id",
					include_deleted: IncludeDeleted,
					limit: 50,
					page: page,
					export: type,
					Is_lead_client: isLeadClient,
					applied_date_filter: filterData.appliedDate,
					exclude_qa: filterData.excludeQACampaign,
					exclude_internal: filterData.excludeInternalCampaign,
					override_account_filters: filterData.account,
					override_vertical_filters: filterData.vertical,
					override_geo_filters: filterData.geo,
					override_custom_questions: filterData.customQuestions,
					override_firmographic_filters: filterData.firmoGraphic,
					override_organization_types_filters: filterData.types,
					override_organization_sizes_filters: filterData.size,
					override_estimate_revenues_filters: filterData.revenue,
					override_personas_filters: filterData.personas,
					override_seniority_filters: filterData.seniority,
					override_job_functions_filters: filterData.jobFunctions,
					override_job_titles_filters: filterData.jobTitles,
					exclude_email_suppression: filterData.excludeEmailSupression,
					page_title: filterData.title,
					utm_source: filterData.selUrlSource,
					utm_medium: filterData.selUrlMedium,
					utm_campaign: filterData.selUrlCampaign,
					utm_term: filterData.selUrlTerm,
					utm_content: filterData.selUrlContent,
					report: report,
					verification_state: verificationState,
					verification_status: verificationStatus,
					delivery_state: filterData.clientDeliverdFilterState && (filterData.clientDeliverdFilterState).trim(),
					pace: onPaceValue,
					utm_filters: filterData.utmParams,
					traffic_type: filterData?.trafficType,
					report_name: reportData ? reportData.report : "",
					report_table_name: reportData ? reportData.report_table_name : "",
					domain_filter: filterData?.domainFilter,
					session_id: getCookie("session_id"),
					exclude_competitor_orgs: filterData?.competitorFiltering,
					csm: filterData?.setCSM,
					...consentPayload
				},
				{
					headers: headers(),
				}
			)
			.then((res) => {
				if (type) {
					dispatch({
						type: actionTypes.LEADENGSUMMARYSCV,
						payload: res.data
					});
				} else {
					dispatch({
						type: dispatchType,
						payload: res.data,
						resCampaignSummaryLeadEngsValue: res.data === null && true
					});
				}
			})
			.catch((err) => {
				if (err.response) {
					dispatch({
						type: actionTypes.ERRORCODE,
						payload: err.response.status,
					});
				}
			});
	};

export const sortLeadEngSummary = (column) => {
	return {
		type: actionTypes.SORTLEADENGSUMMARY,
		column: column,
	};
};

export const showMoreLeadEngSummaryLoading = () => {
	return {
		type: actionTypes.MORELEADENGSUMMARYLOADING,
	};
};


export const fetchLeadEngs =
	(filterData, page, type = "", dispatchType, csvType = "", newEmail, isLeadClient = false) =>
	async (dispatch) => {
		let siteValue = filterData.site;
		if (filterData.site === "all") {
			siteValue = "";
		}
		let assetType = filterData.assetType;
		if (filterData.assetType === "all") {
			assetType = "";
		}
		let leadEngsState = filterData.leadEngsFilterVerificationState
		if (filterData.leadEngsFilterStatus === "Received") {
			leadEngsState = "Received"; 
		}
		let leadEngsVerificationStatus = filterData.leadEngsFilterStatus
		if(filterData.leadEngsFilterStatus === "All"){
			leadEngsState = ""; 
		}
		if(filterData.leadEngsFilterVerificationState === "All"){
			leadEngsVerificationStatus = ""
		}
		let status = filterData.campaignStatus;
		let IncludeDeleted = false;
		if (filterData.campaignStatus === "all") {
			status = "";
			IncludeDeleted = true;
		}
		let report = "engagement";
		if (newEmail) {
			filterData.email = newEmail;
		}
		if(filterData.trafficType === "all") {
			filterData.trafficType = "";
		}
		const reportData = findReportFromURL(url.leadEngagementReport, "");
		const consentPayload = PrivacyConsentDataHandler(filterData);
		await axios
			.post(
				url.leadEngagementReport,
				{
					Is_lead_client: isLeadClient,
					organizations: filterData.organization,
					campaigns: filterData.campaign,
					engaged_organizations: filterData.engagedOrganizations,
					lead_partner: filterData.leadPartner,
					orders: filterData.order,
					site: siteValue,
					status: status,
					asset_type: assetType,
					start_date: getApiDate(filterData.startDate).concat(" 00:00:00"),
					end_date: getApiDate(filterData.endDate).concat(" 23:59:59"),
					override_lead_report_filters: filterData.overrideLeadReportFilters,
					exclude_competitors: filterData.excludeCompetitorEvents,
					exclude_internal_events: filterData.excludeInternalEvents,
					exclude_organization_events: filterData.excludeOrganizationEvents,
					exclude_gated_pageviews: filterData.excludeGatedPageviews,
					exclude_free_email_registrations: filterData.excludeFreeEmailEvents,
					order: "ASC",
					order_by: "id",
					include_deleted: IncludeDeleted,
					limit: 50,
					page: page,
					export: type,
					applied_date_filter: filterData.appliedDate,
					exclude_qa: filterData.excludeQACampaign,
					exclude_internal: filterData.excludeInternalCampaign,
					override_account_filters: filterData.account,
					override_vertical_filters: filterData.vertical,
					override_geo_filters: filterData.geo,
					override_custom_questions: filterData.customQuestions,
					override_firmographic_filters: filterData.firmoGraphic,
					override_organization_types_filters: filterData.types,
					override_organization_sizes_filters: filterData.size,
					override_estimate_revenues_filters: filterData.revenue,
					override_personas_filters: filterData.personas,
					override_seniority_filters: filterData.seniority,
					override_job_functions_filters: filterData.jobFunctions,
					override_job_titles_filters: filterData.jobTitles,
					exclude_email_suppression: filterData.excludeEmailSupression,
					page_title: filterData.title,
					utm_source: filterData.selUrlSource,
					utm_medium: filterData.selUrlMedium,
					utm_campaign: filterData.selUrlCampaign,
					utm_term: filterData.selUrlTerm,
					utm_content: filterData.selUrlContent,
					report: report,
					verification_state: leadEngsState,
					verification_status: leadEngsVerificationStatus === "All" ? "" : leadEngsVerificationStatus,
					email: filterData.email,
					delivery_state: filterData?.clientDeliverdFilterState?.trim() ?? "",
					utm_filters: filterData.utmParams,
					traffic_type: filterData?.trafficType,
					report_name: reportData ? reportData.report : "",
					report_table_name: reportData ? reportData.report_table_name : "",
					domain_filter: filterData?.domainFilter,
					session_id: getCookie("session_id"),
					exclude_competitor_orgs: filterData?.competitorFiltering,
					csm: filterData?.setCSM,
					...consentPayload
				},
				{
					headers: headers(),
				}
			)
			.then((res) => {
				const fileName = extractFileName(res.headers);
				if (res.status === 200) {
					if (type) {
						dispatch({
							type: csvType,
							payload: url.leadEngagementReport,
							email: filterData.email,
							csv: res.data && res.data,
							downloadFileName: fileName
						});
					} else {
						dispatch({
							type: dispatchType,
							payload: res.data.report,
							filterData: filterData,
							totalLeadCount: res.data.count,
							resEngsDetailsValue: res.data.report === null && true
						});
					}
				} else if (res.status === 202) {
					if (type) {
						dispatch({
							type: csvType,
							payload: url.leadEngagementReport,
							email: filterData.email,
							downloadFileName: fileName
						});
					}
				} else if (res.status === 425) {
					// dispatch({
					// 	type: actionTypes.PAGEVIEWREPORTLOADING,
					// 	payload: uri,
					// 	filterData,
					// });
				}
			})
			.catch((err) => {
				if (err.response) {
					dispatch({
						type: actionTypes.ERRORCODE,
						payload: err.response.status,
					});
				}
			});
	};

export const sortLeadEngs = (column) => {
	return {
		type: actionTypes.SORTLEADENGS,
		column: column,
	};
};

export const showMoreLeadEngsLoading = () => {
	return {
		type: actionTypes.MORELEADENGSLOADING,
	};
};

export const clearLeadSort = () => {
	return {
		type: actionTypes.CLEARLEADSORT,
	};
};

export const leadCsvLoading = () => {
	return {
		type: actionTypes.LEADCSVLOADING,
	};
};

export const selLeadToggles = (dispatchType) => {
	return {
		type: dispatchType,
	};
};

export const resetLeadToggles = (dispatchType) => {
	return {
		type: dispatchType,
	};
};

export const clearLeadEngSerchedValue = (dispatchType) => {
	return {
		type: dispatchType,
	};
};

export const selLeadEngFilterValue = (value, dispatchType) => {
	return {
		type: dispatchType,
		payload: value,
	};
};
export const selLeadEngsCheckBoxState = (leadEngsCheckboxState) => (dispatch) => {
	dispatch({
		type: actionTypes.SELLEADENGSCHECKBOXSTATE,
		payload: leadEngsCheckboxState,
	});
};
export const selGlobalLeadEngsStatus = (globalLeadEngsStatus) => (dispatch) =>{
	dispatch({
		type: actionTypes.SELGLOBALLEADENGSSTATUS,
		payload: globalLeadEngsStatus,
	});
}
export const selGlobalLeadEngsVerificationState = (globalLeadEngsVerificationState) => (dispatch) =>{
	dispatch({
		type: actionTypes.SELGLOBALLEADENGSVERIFICATIONSTATE,
		payload: globalLeadEngsVerificationState,
	});
}
export const selDropDownFilters =  (value, dispatchType) =>{
	return{
		type:dispatchType,
		payload:value
	}
}
export const selPacingEngsFilterValue = (state) => (dispatch) => {
	dispatch({
	  type: actionTypes.SELLEADENGSPACINGFILTERVALUE,
	  payload: state,
	});
  };
  