import React from "react";
import Link from "../../../hyperlinks/link";
import { Table } from "semantic-ui-react";

const OrderComponent = ({ data }) => {
    return (
        <Table.Cell title={data?.Order?.name} className="blogs_link">
            {data?.Order?.name ? (
                <Link
                    type={"orders"}
                    urlParam={data.Order.uuid}
                    refValue={data.Order.name}
                />
            ) : (
                ""
            )}
        </Table.Cell>
    );
};

export default OrderComponent;
