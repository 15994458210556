import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";

import * as actionTypes from "../../../actions/actionTypes";
import { fetchChart, selectValue } from "../../../actions";
import _ from "lodash";
import DownloadChart from "../../common/downloadChart";
import { capitalizeFirstLetter, getDisplayDateChart } from "../../../utilities/common";
import { Checkbox, Loader } from "semantic-ui-react";
import MultiGoogleLineChartWithCompare from "../../adManager/multiGoogleLineChartWithCompare";
import MultiGoogleLineChart from "../../adManager/multiGoogleLineChart";

const Graph = (props) => {
    const dispatchAction = useDispatch();
    const chartNameDiv = useRef(null);
    let conditionChkboxAppFilter =
        props.filterData.selCustomRangeCheckBox &&
        props.filterData.isFilApplied &&
        props.filterData.appliedDate === "Custom Date Range";
    
    let loading = null;
    if (props.filterData.isLoadingChart) {
        loading = (
            <div className="show-loader">
                <Loader active inline="centered" />
            </div>
        );
    }

    useEffect(() => {
        if (props.filterData.showChart) {
            dispatchAction({
                type: actionTypes[props.reportActionType].UPDATED_CHART_STATUS,
                reportType: props.reportActionType,
                payload: {
                    chartApiCalled: true,
                },
            });
            props.fetchChart(
                props.filterData,
                1,
                "",
                actionTypes[props.reportActionType].DISPLAYING_CHARTS,
                props.reportName,
                props.reportActionType,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filterData.isFilApplied, props.filterData.showChart]);

    return (
        <div className="ad-graph">
                <div ref={chartNameDiv}>
                    {!_.isEmpty(props.filterData.chartData) && props.filterData.showChart &&
                        props.filterData.chartStatus.chartApiCompleted &&
                        <DownloadChart
                            chartNameDiv={chartNameDiv.current}
                            chartName={(`${capitalizeFirstLetter(props.reportName)}-Chart-${getDisplayDateChart(new Date())}.jpg`)}
                            style={{ width: "36px", height: "36px", marginTop: 19, marginRight: 56, color: "#838486" }}
                        />
                    }
                    <div className="ad-chart-name">
                        {`${(props.reportName).toUpperCase()} AD IMPRESSIONS AND CLICK THROUGH RATE (CTR)`}
                        <Checkbox
                            className="checkbox-label"
                            toggle
                            style={{ color: "white", fontFamily: "Roboto", fontSize: "16px", paddingLeft: "20px", minHeight: 15 }}
                            label={props.filterData.showChart ? "Hide" : "Show"}
                            onChange={() => { 
                                props.selectValue({ 
                                    dispatchType: actionTypes[props.reportActionType].SELECTVALUE, 
                                    reportType: props.reportActionType, 
                                    key: "showChart", 
                                    value: !props.filterData.showChart 
                                });
                             }}
                            checked={props.filterData.showChart}
                            disabled={!props.filterData.isFilApplied}
                            data-html2canvas-ignore="true"
                        />
                        {props.filterData.isFilApplied &&
                            // !props.filterData.chartStatus.chartApiCompleted &&
                            // props.filterData.chartStatus.campaignLoaded &&
                            // props.filterData.chartStatus.detailLoaded &&
                            // props.filterData.chartStatus.chartApiCalled &&
                            props.filterData.showChart &&
                            loading
                        }
                    </div>
                    {!_.isEmpty(props.filterData.chartData) &&
                        props.filterData.showChart &&
                        props.filterData.chartStatus.chartApiCompleted &&
                        (conditionChkboxAppFilter ? (
                            <MultiGoogleLineChartWithCompare chartData={props.filterData.chartData} />
                        ) : (
                            <MultiGoogleLineChart chartData={props.filterData.chartData} />
                        ))}
                </div>
            </div>
    )
}

export default connect(null, { 
    fetchChart,
    selectValue,
})(Graph);
