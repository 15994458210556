import * as actionTypes from '../actions/actionTypes';

const sidebarState ={
	visible: false,
	sidebarColumn: 1,
	contentColumn: 15,
	isSidebarOpen: false
}

export const sidebarVisibiltyReducer = (state = sidebarState, action) => {
	switch (action.type) {
		case actionTypes.CHANGEANIMATION:
		  return { ...state, visible: !state.visible };
		case actionTypes.CHANGEGRID:
			return { ...state, sidebarColumn: action.sidebarColumn, contentColumn: action.contentColumn}
		default:
		  return state;
	  }
}