import React from "react";
import SingleSelectDropDown from "../base/singleSelectDropDown";
import { connect } from "react-redux";

const SitePicker = (props) => {
	return (
		<SingleSelectDropDown
			className="no-blink"
			options={props.sites.map((type, index) => ({
				key: index,
				text: type.label,
				value: type.value,
			}))}
			label="Site"
			placeholder="Site"
			{...props}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		sites: Object.values(state.sites),
	};
};

export default connect(mapStateToProps, {})(SitePicker);
