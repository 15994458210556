import { url } from "../../api/urlHelper";
import _ from "lodash";

const generateReport = (method, filterData, excel, userEmail, type, tagType) => {
  method(
    url.topicOrganizationPlacementReport,
    filterData,
    1,
    "CSV",
    "",
    excel,
    userEmail,
    type,
    tagType
  );
};

const DownloadXLSX = (btnSpecificAction, table_name, type, filterData, userEmail, excel, methodType) => {
  if(table_name === "summary_table"){
    methodType(
        url.adCampaignReport,
        filterData,
        1,
        "XLSX",
        "",
        excel,
        userEmail,
        null,
        type
      );
  } else if(table_name === "ad_campaign_detail") {
    methodType(
        url.adImpressionReport,
        filterData,
        1,
        "XLSX",
        "",
        excel,
        userEmail,
        null,
        type
    );
  } else if(table_name === "ad_detail") {
    methodType(
        url.adDetail,
        filterData,
        1,
        "XLSX",
        "",
        excel,
        userEmail,
        null,
        type
    );
  } else if(table_name === "summary_table_sidebar") {
    methodType(
        filterData,
        1,
        "XLSX",
        "",
        excel,
        userEmail
    );
  } else if(table_name === "campaign_detail_sidebar") {
    methodType(
        filterData,
        1,
        "XLSX",
        "",
        excel,
        userEmail
    );
  } else if(table_name === "detail_sidebar") {
    methodType(
        url.sidebarDetail,
        filterData,
        1,
        "XLSX",
        "",
        excel,
        userEmail
    );
  } else if(table_name === "client_lead_eng") {
    methodType(
        filterData,
        1,
        "XLSX",
        "",
        excel,
        userEmail,
        true
    );
  } else if(_.includes(["lead_eng", "activity_report", "light_activity_report"], table_name)) {
    const exportType = table_name === "activity_report" ? "CSV" : "XLSX";
    methodType(
      filterData,
      1,
      exportType,
      "",
      excel,
      userEmail,
      false,
      type,
    );
  } else if(table_name === "pageview_summary_table") {
    methodType(
        url.pageviewDetails,
        filterData,
        1,
        "XLSX",
        "",
        excel,
        userEmail
    );
  } else if(table_name === "pageview_detail_table") {
    methodType(
        url.pageviewDetailed,
        filterData,
        1,
        "XLSX",
        "",
        excel,
        userEmail
    );
  } else if(table_name === "sponsored_summary_report") {
    methodType(
      url.sponsoredSummary,
      filterData,
      1,
      "XLSX",
      "",
      excel,
      userEmail
    )
  } else if(table_name === "campaign_page_details") {
    methodType(
      url.sponsoredPageDetails,
      filterData,
      1,
      "XLSX",
      "",
      excel,
      userEmail
    );
  } else if(table_name === "campaign_pageview_details") {
    methodType(
      url.sponsoredDetail,
      filterData,
      1,
      "XLSX",
      "",
      excel,
      userEmail
    );
  } else if(table_name === "editorial_summary_report") {
    methodType(
      url.pageviewPrimaryTopic,
      filterData,
      1,
      "XLSX",
      "",
      excel,
      userEmail,
      type
    );
  } else if (table_name === "editorial_page_summary") {
    methodType(
      url.pageviewDetails,
      filterData,
      1,
      "XLSX",
      "",
      excel,
      userEmail,
      type
    );
  } else if(table_name === "editorial_detail_table") {
    methodType(
      url.pageviewDetailed,
      filterData,
      1,
      "XLSX",
      "",
      excel,
      userEmail,
      type
    );
  } else if(table_name === "client_lead_detail") {
    methodType(
        filterData,
        1,
        "XLSX",
        "",
        excel,
        userEmail,
        true
    );
  } else if(table_name === "editorial_detail_table_CSV") {
    methodType(
      url.pageviewDetailed,
      filterData,
      1,
      "CSV",
      "",
      excel,
      userEmail,
      type
    );
  } else if(table_name === "ad_details_table") {
    methodType(
        url.adDetail,
        filterData,
        1,
        "CSV",
        "",
        excel,
        userEmail,
        null,
        type
    );
  } else if(table_name === "sponsored_pageview_details_table") {
    methodType(
        url.sponsoredDetail,
        filterData,
        1,
        "CSV",
        "",
        excel,
        userEmail,
        type
    );
  }  else if(table_name === "detail_sidebar_csv") {
    methodType(
        url.sidebarDetail,
        filterData,
        1,
        "CSV",
        "",
        excel,
        userEmail
    );
  } else if(table_name === "editorial_primary_topic_csv") {
    methodType(
      url.pageviewPrimaryTopic,
      filterData,
      1,
      "CSV",
      "",
      excel,
      userEmail,
      type
    );
  } else if (table_name === "editorial_page_summary_csv") {
    methodType(
      url.pageviewDetails,
      filterData,
      1,
      "CSV",
      "",
      excel,
      userEmail,
      type
    );
  }  else if(table_name === "editorial_placement_csv") {
    generateReport(methodType, filterData, excel, userEmail, type, "placement_tag");
  }  else if(table_name === "editorial_topic_tag_csv") {
    generateReport(methodType, filterData, excel, userEmail, type, "topic_tag");
  } else if (table_name === "editorial_organization_summary_csv") {
    generateReport(methodType, filterData, excel, userEmail, type, "organization_tag");
  } else if(table_name === "engagement_detail") {
    methodType(
        filterData,
        1,
        "XLSX",
        "",
        excel,
        userEmail,
        false
    );
  } else if(table_name === "pageview_author_summary_csv") {
    methodType(
        url.pageviewAuthorSummary,
        filterData,
        1,
        "CSV",
        "",
        excel,
        userEmail,
        type,
        "author"
    );
  }
};

export default DownloadXLSX;