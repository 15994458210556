import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Table, Loader, Message, Divider, Icon, Menu, Checkbox, Form } from "semantic-ui-react";
import "../../css/common.css";
import { connect } from "react-redux";
import {
	topicSetPageCount,
	fetchTopicData,
	showMoreTopicLoading,
	clearTopicSort,
	sortSidebarDetail,
	sendTableHeaders,
	getTableHeaders,
	clearTopicSearchedValue,
	selTopicFilterValue
} from "../../actions";
import * as actionTypes from "../../actions/actionTypes";
import { url } from "../../api/urlHelper";
import SidebarDetailRow from "./sidebarDetailRow";
import SidebarTableData from "./sidebarTableData";
import TableHeader from "./headerComponent";

const SidebarDetailTable = (props) => {
	const [selectHeaders, setSelectHeaders] = useState();
	const [finalHeaderData, setFinalHeaderData] = useState();
	const [headerColumn, setHeaderColumn] = useState([]);
	const [isSortIcon, setSortIcon] = useState(false);

	let tableBody;
	let showMoreButton;
	let loading;
	let noresult;

	useEffect(()=> {
		props.getTableHeaders("sidebar_detail", actionTypes.GETSIDEBARDYNAMICTABLEHEADER)
		  // eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [])
	
	  useEffect(()=> {
		const headerDataVal = props.getDynamicHeaderData;
		if(!_.isEmpty(headerDataVal)){
		  setSelectHeaders(headerDataVal)
		  setFinalHeaderData(headerDataVal)
		} else if(headerDataVal === undefined || _.size(headerDataVal) === 0) {
		  const constantHeadersArr = [ ...SidebarTableData]
		  setSelectHeaders(constantHeadersArr)
		  setFinalHeaderData(constantHeadersArr)
		}
		  // eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [props.getDynamicHeaderData])
	
	  useEffect(()=>{
		const headerName = []
		  finalHeaderData && finalHeaderData.map((name)=>{
			headerName.push(name.headerName);
			 return ""
		  });
		  setHeaderColumn(headerName);
		}, [finalHeaderData])

	//const [pageCount, setPageCount] = useState(2);

	const showMore = async () => {
		props.clearTopicSort(actionTypes.CLEARSIDEBARDETAILSORT);
		props.showMoreTopicLoading(actionTypes.MORESIDEBARDETAILLOADING);
		await props.fetchTopicData(
			url.sidebarDetail,
			props.filterData,
			props.PageCount,
			"",
			actionTypes.MORESIDEBARDETAILDATA
		);
		props.topicSetPageCount(actionTypes.SETSIDEBARDETAILPGCOUNT);
		// setPageCount(pageCount + 1);
	};

	if (props.loading && props.filterData.selectSidebarDetailTable) {
		loading = (
			<div className="show-loader">
				<Loader active inline="centered" />
			</div>
		);
	}

	useEffect(() => {
    if (!props.filterData.detailTableSidebarToggleValue && props.filterData.selectSidebarDetailTable) {
      props.filterData.selectSidebarDetailTable &&
        props.fetchTopicData(
          url.sidebarDetail,
          props.filterData,
          1,
          "",
          actionTypes.SIDEBARDETAIL
        );
	props.selTopicFilterValue(true, actionTypes.DETAILTABLESIDEBARTOGGLLEVALUE);
      props.clearTopicSearchedValue(actionTypes.UPDATESIDEBARDETAILLOADER);
    } else if (!props.filterData.selectSidebarDetailTable) {
      props.clearTopicSearchedValue(actionTypes.RESETSTATESSIDEBARDETAIL);
    }
	if(!props.filterData.selectSidebarDetailTable){
		props.selTopicFilterValue(false, actionTypes.DETAILTABLESIDEBARTOGGLLEVALUE);
	  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterData.selectSidebarDetailTable, props.isFilApplied]);

	const createRow = (data) => {
		if (data.length > 0) {
			let tableRow = [];
			data.forEach((e, id) => {
				const htmlData = (
					<SidebarDetailRow 
						sidebarRow={e}
						headerName={headerColumn}
					/>
				);
				tableRow.push(<Table.Row key={id} children={htmlData} />);
			});
			return tableRow;
		}
	};

	if (!_.isEmpty(props.sidebarDetail) && props.filterData.selectSidebarDetailTable) {
		tableBody = <Table.Body>{createRow(props.sidebarDetail)}</Table.Body>;
		showMoreButton = (
			<div className="table-divider">
			  <Divider>
				<div className="show-more-component">
				  {!props.topicFetchData.resSidebarDetailsValue ? 
				  (
				  <button className="show-more-button" onClick={showMore}>
					Show More
				  </button>
				  ):(
				  <button className="show-more-button-disable">
					Show More
				  </button>
				  )}
				  
				</div>
			  </Divider>
			</div>
		  );
	} else if (
		props.isFilApplied &&
		!props.loading &&
		_.isEmpty(props.sidebarDetail) &&
		props.filterData.selectSidebarDetailTable
	) {
		noresult = <Message>No results found.</Message>;
	}

	const clickOnSortIcon = () => {
		setSortIcon(!isSortIcon);
	  };
	
	  const clickOnSortHeader = (e, extraData, headerData) => {
		if (extraData.checked) {
		  setSelectHeaders([...selectHeaders, headerData]);
		} else {
		  const removeData = selectHeaders.filter(
			(hName) => hName.headerName !== headerData.headerName
		  );
		  setSelectHeaders(removeData);
		}
	  };
	
	  const applyButtonClick = () => {
		const constantHeadersArr = [ ...SidebarTableData]
		let finalHeaderArray = []
		constantHeadersArr.map((mainHeader)=> {
		  return _.map(selectHeaders, function(selectHeader) {
			if(mainHeader.headerName === selectHeader.headerName) {
				  finalHeaderArray.push(selectHeader)
			  }
		  })
		})
		setFinalHeaderData(finalHeaderArray);
		props.sendTableHeaders("sidebar_detail", finalHeaderArray, actionTypes.SENDSIDEBARDYANMICTABLEHEADER)
		setSortIcon(!isSortIcon);
	  };

	return (
		<div>
			<>
			<Form>
			 <div className="sorted_icon">
                <Table.HeaderCell style={{ paddingBottom: "20px" }}>
                  <Icon name="edit" style={{ color: `${isSortIcon ? "#f15a29" : "#b2b3b3" }`}} size="large" onClick={clickOnSortIcon} />
                </Table.HeaderCell>
                {isSortIcon && (
                  <div
                    className="menu-list-items"
                    style={{ height: "0px", float: "right" }}
                  >
                    <Menu vertical className="dynamic_table--list">
                      {Object.values(SidebarTableData).map((tableHeader, index) => (
                        <div
                          key={index}
                        >
                          <Checkbox
                            className="table_header--checkbox"
                            style={{ color: "#838486" }}
                            label={tableHeader.name}
                            checked={
                              selectHeaders && selectHeaders.some(
                                (el) => el.headerName === tableHeader.headerName
                              )
                                ? true
                                : false
                            }
                            onClick={(e, data) =>
                              clickOnSortHeader(e, data, tableHeader)
                            }
                          />
                        </div>
                      ))}
                      <button className="apply_menu--btn" onClick={applyButtonClick}>
                        Apply
                      </button>
                    </Menu>
                  </div>
             )}
          	 </div>
			</Form>
			</>

		  <div className="sortable-table-component" style={{ paddingTop: "30px" }}>
          <Table sortable fixed singleLine>
            <Table.Header>
              <Table.Row>           
                {
                (!_.isEmpty(finalHeaderData)) && 
                <TableHeader
                  propData={props}
                  headerData={finalHeaderData}
                ></TableHeader>
              }
              </Table.Row>
            </Table.Header>
            {tableBody}
          </Table>
      </div> 
		{noresult}
		{loading}
		{showMoreButton}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		wpLink: state.links.wpLink,
		isFilApplied: state.topicFetchData.isFilApplied,
		filterData: state.topicFilterData,
		sidebarDetail: state.topicFetchData.sidebarDetail,
		loading: state.topicFetchData.isLoadingSidebarDetail,
		column: state.topicFetchData.column,
		detailDirection: state.topicFetchData.detailDirection,
		PageCount: state.topicFetchData.sidebardetailPageCount,
		getDynamicHeaderData: state.topicFetchData.getDynamicHeaderData,
		sendDynamicHeaderArray: state.topicFetchData.dynamicHeaderArray,
		topicFetchData: state.topicFetchData
	};
};

//const WrappedComponent = TableDrawerHoc (TopicSummaryTable);

export default connect(mapStateToProps, {
	fetchTopicData,
	showMoreTopicLoading,
	clearTopicSort,
	topicSetPageCount,
	sortSidebarDetail,
	sendTableHeaders,
	getTableHeaders,
	clearTopicSearchedValue,
	selTopicFilterValue
})(SidebarDetailTable);
