import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Loader, Message, Table } from "semantic-ui-react";
import GenericTable from "../ads/common/GenericTable";
import { columnConfigs } from "../ads/common/ColumnConfig";
import { CPDetailsHeader } from "./ContentPerformanceHeaders";
import { selectValue, getTableHeaders, sendTableHeaders, fetchContentReportTableData } from "../../actions";
import WithReportHandler from "../CommonFilter/WithReportHandler";
import DynamicHeaderColumnSelector from "../common/DynamicHeaderColumnSelector";
import * as actionTypes from "../../actions/actionTypes";
import ShowMoreButton from "../common/showMoreComponent";
import _ from 'lodash';
import { url } from "../../api/urlHelper";

const CPDetails = (props) => {

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    const showMore = async () => {
        dispatchDynamicSelectAction(props, "isLoadingCPDetails", true);
        props.fetchContentReportTableData(
            url.contentPerformanceDetails,
            props.filterData,
            props.filterData.cpDetailsPageCount,
            "",
            actionTypes[props.reportActionType].CONTENT_PERFORMANCE_DETAILS_SHOW_MORE,
            null,
            null,
            "content-performance",
            props.reportActionType,
          );
    };
    
    return (
        <div>
            <div style={{ paddingBottom: "3em" }}>
                <DynamicHeaderColumnSelector
                    isSortIcon={props.isSortIcon}
                    clickOnSortIcon={() => props.setSortIcon(!props.isSortIcon)}
                    TableHeaderData={CPDetailsHeader}
                    selectHeaders={props.selectHeaders}
                    clickOnSortHeader={props.clickOnSortHeader}
                    applyButtonClick={props.applyButtonClick}
                />
            </div>
            <div className="sortable-table-component">
                <Table sortable fixed singleLine>
                    <GenericTable
                        data={props.filterData.contentPerformanceDetailsData}
                        headerOrder={CPDetailsHeader}
                        columnConfigs={columnConfigs}
                    />
                </Table>
            </div>
            {
                props.filterData.isLoadingCPDetails &&
                <div className="show-loader">
                    <Loader active inline="centered" />
                </div>
            }
            {
                !_.isEmpty(props.filterData.contentPerformanceDetailsData) &&
                <ShowMoreButton onClick={showMore} isDisabled={props.filterData.cpDetailShowMore} />
            }
            {
                props.filterData.isFilApplied && !props.filterData.isLoadingCPDetails && _.isEmpty(props.filterData.contentPerformanceDetailsData) &&
                <Message>No results found.</Message>
            }
        </div>
    );
};

export default compose(connect(null, {
        selectValue,
        getTableHeaders,
        sendTableHeaders,
        fetchContentReportTableData,
    }),
    (Component) => (props) =>
        WithReportHandler(Component, {
            initialHeader: CPDetailsHeader,
            getTableHeadersAction: actionTypes[props.reportActionType]?.GET_DYNAMIC_TABLE_HEADER,
            sendTableHeadersAction: actionTypes[props.reportActionType]?.SEND_DYANMIC_TABLE_HEADER,
            getSetReportName: "content_performance_details",
            hName: "",
            hKey: "",
        })(props)
)(CPDetails);