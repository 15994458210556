import React from "react";
import { connect } from "react-redux";

import * as constName from "../components/config/const"
import Main from "../components/ContentPerformance/Main";
import { ROLEBASED_ELEMENT_CONTENT_PERFORMANCE } from "../components/config/rolebasedReportNames";
import { getReportsByElement } from "../utilities/common";

const ContentPerformance = (props) => {
    const roleReports = getReportsByElement(props?.roleFilterData?.currentUserReportRoleData, props?.roleFilterData?.getAllReports, ROLEBASED_ELEMENT_CONTENT_PERFORMANCE);
    return (
        <Main
            reportActionType={constName.MODULE_CONTENT_PERFORMANCE}
            reportName={constName.CONTENT_PERFORMANCE_REPORT_NAME}
            filterData={props.filterData}
            reportDescription={props.reportDescription.find(report => report.name === "Content Performance")?.description }
            label={constName.CONTENT_PERFORMANCE_LABEL}
            roleReports={roleReports}
        />
    );
};

const mapStateToProps = ({ commonFilterData, allFilterReportData}) => {
    return {
        filterData: commonFilterData[constName.MODULE_CONTENT_PERFORMANCE],
        reportDescription: allFilterReportData.reportDescription,
        roleFilterData: allFilterReportData,
    };
};

export default connect(mapStateToProps, {
    getReportsByElement
})(ContentPerformance);