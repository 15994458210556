import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { downloadClearSearch, saveProfileAction, fetchRequestedUsers, addUserList } from "../../actions";
import _ from "lodash";
import MultipleSelectDropDown from "../base/multiSelectDropdown";

const CommonUserListMultiSelect = (props) => {

	
    const onClickHandler = (e, { value }) => {
		if (_.isEmpty(props.filterData.requestUserList)) {
			props.downloadClearSearch(props.clearAction);
		}
	}

	const onChangeHandler = (e, { value }) =>{
		props.saveProfileAction({ key: "requestUserList", value });
        props.addUserList(value, props.addAction);
	}

	const onSearchHandler = (e) => {
		if (e.target.value.length > 1) {
			props.fetchRequestedUsers(e.target.value, props.fetchAction);
		}
	};

	return (
        <MultipleSelectDropDown
            style={{ width: "100%" }}
			options={props.userList.map((req, index) => ({
                key: index,
                text: req?.name,
                value: req?.id,
			}))}
			onSearchChange={onSearchHandler}
			value={props.value}
			onChange={onChangeHandler}
			onClick={onClickHandler}
            label="Users"
			placeholder="Users"
		/>
	);
};

const mapStateToProps = (state) => {
	return {
        userList: Object.values(state.userListFilter),
    };
};

 export default compose(connect(mapStateToProps, {
	downloadClearSearch,
	saveProfileAction,
	fetchRequestedUsers,
    addUserList,
})
)(CommonUserListMultiSelect);