import React from 'react';
import PropTypes from 'prop-types';

const DownloadCsvXlsx = ({ onClick, label, disabled = false }) => {
    return (
        <button className="download-button" disabled={disabled} onClick={onClick}>
            {label}
        </button>
    );
};

DownloadCsvXlsx.propTypes = {
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

export default DownloadCsvXlsx;
