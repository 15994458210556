import _ from "lodash";
import React, {useRef} from "react";
import { connect } from "react-redux";
import { Checkbox, Loader } from "semantic-ui-react";
import {selSCToggles} from "../../actions";
import * as actionTypes from "../../actions/actionTypes";
import { getCapital, getDisplayDateChart } from "../../utilities/common";
import DownloadChart from "../common/downloadChart";
import SponsoredGraph from "./sponsoredGraph";

const SponsoredChart = (props) => {
  const chartNameDiv = useRef(null);
    let loading;
    if (props.loading) {
            loading = (
              <div className="show-loader">
                <Loader active inline="centered" />
              </div>
            );     
    }

    return (
        <>
        <div className="ad-graph">
            <div ref={chartNameDiv}>
            {!_.isEmpty(props.sponsoredContentCharts) && props.sponsoredContentFilterData.showScChart && 
            <DownloadChart
              chartNameDiv= {chartNameDiv.current}
              chartName = {(`Sponsored-Chart-${getDisplayDateChart(new Date())}.jpg`)}
              style={{width:"36px", height: "36px", marginTop: 19, marginRight: 50, color: "#838486" }}
            />
            }
            <p className="ad-chart-name">
            {getCapital("Total Pageviews Per Day")}
           	  <Checkbox
             className="checkbox-label"
             data-html2canvas-ignore="true"
           // slider
             toggle
             style={{
               color: "white",
               fontFamily: "Roboto",
               fontSize: "16px",
               paddingLeft: "20px",
               minHeight: 15,
             }}
             label={props.sponsoredContentFilterData.showScChart ? "Hide" : "Show"}
             onChange={() => {
               props.selSCToggles(actionTypes.SHOWSCCHARTS);
             }}
             checked={props.sponsoredContentFilterData.showScChart}
             disabled={!props.isFilApplied}
           />

            {props.isFilApplied && props.sponsoredContentFilterData.showScChart &&
              loading}
            {!_.isEmpty(props.sponsoredContentCharts) && props.sponsoredContentFilterData.showScChart && 
                <SponsoredGraph />
            }
            </p>  
            </div>
        </div>
        </>
	);
 
}

const mapStateToProps = (state) => {
	return {
    sponsoredContentFilterData: state.sponsoredContentFilterData,
    isFilApplied: state.sponsoredContentFetchData.isFilApplied,
    scCampaignDetail: state.sponsoredContentFetchData.scCampaignDetail,
    sponsoredContentCharts :state.sponsoredContentFetchData.sponsoredContentCharts ? state.sponsoredContentFetchData.sponsoredContentCharts : [],
    loading: state.sponsoredContentFetchData.isLoadingChart
	}
};

export default connect(mapStateToProps, {selSCToggles})(SponsoredChart);
