import axios from "../api/axios";
import * as actionTypes from "./actionTypes";
import { headers } from "../api/apiConstants";
import { getApiDate, sanitizePageviewRequestTag, formatDate } from "../utilities/common";
import { findReportFromURL } from "../components/config/FindReportFromURL";

export const selEditorialFilterValue = (value, dispatchType, key) => {
	return {
		type: dispatchType,
		payload: value,
	};
};

export const applyIndependentDate = (val) => {
	return {
		type: actionTypes.APPLYEDITORIALDATE,
		payload: val,
	};
};

export const selectedEditorialFilterValue = (payload) =>({type:actionTypes.SELEDITORIALFILTERVALUE, payload})

export const clearEditorialSerchedValue = (dispatchType) => {
	return {
		type: dispatchType,
	};
};

export const selEditorialToggles = (dispatchType) => (dispatch) => {
	dispatch({
		type: dispatchType,
	});
};

export const sortEditorialTableData = (
	type,
	column,
	direction = "",
	loadMoreSort = false
) => {
	return {
		type,
		column,
		direction,
		loadMoreSort,
	};
};

export const applyEditorialFilter = () => {
	return {
		type: actionTypes.APPLYEDITORIALFILTER,
	};
};

export const fetchEditorialTableData =
	(uri, filterData, page, type = "", dispatchType, csvType = "", newEmail, reportType, resEditorial, reportActionType="") =>
	async (dispatch) => {
		let siteValue = filterData?.site;
		if (filterData?.site === "all") {
			siteValue = "";
		}
		let limit = 50;
		let status = filterData?.campaignStatus;
		let IncludeDeleted = false;
		if (filterData?.campaignStatus === "all") {
			status = "";
			IncludeDeleted = true;
		} else if (filterData?.campaignStatus === "archived") {
			status = "archived";
			IncludeDeleted = true;
		}
		if (newEmail) {
			filterData.email = newEmail;
		}

		let wpStartDate = "";
		let wpEndDate = "";
		if(filterData?.pageParams === true){ 
			wpStartDate =  formatDate(filterData.wp_startDate, " 00:00:00")
			wpEndDate =  formatDate(filterData?.wp_endDate, " 23:59:59")
		}
		let orgType = filterData.selOrgType ? filterData?.selOrgType.map( a => a.charAt(0).toUpperCase() + a.substr(1)) : [];
		
		let trafficSource = filterData?.selTrafficSource;
		if(filterData?.selTrafficSource === "All"){
			trafficSource = "";
		}
		if(filterData.trafficType === "all"){
			filterData.trafficType = "";
		}

		const reportData = findReportFromURL(uri, resEditorial);
		await axios
			.post(
				uri,
				{
					organizations: filterData?.organization,
					campaigns: filterData?.campaign,
					orders: filterData?.order,
					site: siteValue,
					status: status,
					include_deleted: IncludeDeleted,
					topic_tags: {
						included_tags: sanitizePageviewRequestTag(filterData?.selTopicTagsIN),
						excluded_tags: sanitizePageviewRequestTag(filterData?.selTopicTagsEX),
					},
					organization_tags: {
						included_tags: sanitizePageviewRequestTag(filterData?.selOrgTagsIN),
						excluded_tags: sanitizePageviewRequestTag(filterData?.selOrgTagsEX),
					},
					placement_tags: {
						included_tags: sanitizePageviewRequestTag(filterData?.selPlacementTagsIN),
						excluded_tags: sanitizePageviewRequestTag(filterData?.selPlacementTagsEX),
					},
					page_title: filterData?.selPageTitle,
					utm_source: filterData?.selUrlSource,
					utm_medium: filterData?.selUrlMedium,
					utm_campaign: filterData?.selUrlCampaign,
					utm_term: filterData?.selUrlTerm,
					utm_content: filterData?.selUrlContent,
					pageviews_by_organizations: filterData?.selPageViewsByOrg,
					author: filterData?.selAuthor,
					country: filterData?.selCountry,
					start_date: getApiDate(filterData?.startDate).concat(" 00:00:00"),
					end_date: getApiDate(filterData?.endDate).concat(" 23:59:59"),
					previous_start_date: formatDate(filterData?.previousStartDate, " 00:00:00"),
        			previous_end_date: formatDate(filterData?.previousEndDate, " 23:59:59"),
					compare:filterData?.selCutomRangeChkBox,
					applied_date_filter: filterData?.appliedDate,
					order: "DESC",
					order_by: "page_views_count",
					limit: limit,
					page: page,
					export: type,
					exclude_qa: filterData?.excludeQACampaign,
					exclude_internal: filterData?.excludeInternalCampaign,
					...(filterData?.personaToggle && {
						job_title: {
							included_job_title: filterData?.selJobTitlesIN,
							excluded_job_title: filterData?.selJobTitlesEX,
						},
						seniorities: {
							included_tags: filterData?.selSenioritiesIN,
							excluded_tags: filterData?.selSenioritiesEX,
						},
						job_functions: {
							included_tags: filterData?.selJobFunctionsIN,
							excluded_tags: filterData?.selJobFunctionsEX,
						},
					}),
					email: filterData?.email,
					engaged_members:filterData?.selEnagedMember,
					organization_types: orgType,
					organization_sizes: filterData?.selOrgSize,
					organization_revenues: filterData?.selOrgRevenue,
					sector:filterData?.selSector,
					industry: filterData?.selIndustry,
					sub_industry: filterData?.selSubIndustry,
					industry_group: filterData?.selIndustryGroup,
					wp_page_start_date: wpStartDate,
					wp_page_end_date: wpEndDate,
					primary_topic_ids:{
						tag_ids:filterData?.selPrimaryTopic
					},
					type: reportType,
					traffic_source: trafficSource,
					traffic_filter: filterData?.trafficType,
					report_type: resEditorial,
					exclude_internal_events: filterData?.excludeInternalEvents,
					exclude_anonymous_organization: filterData?.exAnonymousOrgs,
					exclude_anonymous_member: filterData?.exAnonymousMember,
					page_type: filterData?.pageviewType === "all" ? "" : filterData?.pageviewType,
					report_name: reportData ? reportData.report : "",
					report_table_name: reportData ? reportData.report_table_name : "",
					days_of_week: filterData?.dayOfWeek,
					time_of_day: filterData?.timeOfDay === "all" ? "" : filterData?.timeOfDay,
					pageview_ranges: filterData?.pageviewRanges === "all" ? "" : filterData?.pageviewRanges,
				},
				{
					headers: headers(),
				}
			)
			.then((res) => {
				if (res.status === 200) {
					if (type) {
						dispatch({
							type: csvType,
							payload: uri,
							email: filterData.email,
							csv: res.data,
							reportType: reportActionType,
						});
					} else {
						dispatch({
							type: dispatchType,
							payload: res.data,
							filterData: filterData,
							resEditorial: (res.data === null || res.data.length === 0) && true,
							reportType: reportActionType,
						});
					}
				} else if (res.status === 202) {
					if (type) {
						dispatch({
							type: csvType,
							payload: uri,
							email: filterData.email,
							// reportType: resEditorial,
							reportType: reportActionType,
						});
					}
				} else if (res.status >= 500 && res.status < 600) {
					dispatch({
						type: dispatchType,
						payload: [],
					});
				}
			})
			.catch((err) => {
				if (err.response) {
					dispatch({
						type: dispatchType,
						payload: [],
					});
				}
			});
	};

export const clearEditorialSort = () => {
	return {
		type: actionTypes.CLEAREDITORIALSORT,
	};
};

export const showMoreEditorialTableData = (type) => {
	return {
		type,
	};
};

export const editorialCsvLoading = (dispatchType) => (dispatch) => {
	dispatch({
		type: dispatchType,
	});
};

export const sortEditorialPrimaryTopic = (column) =>{
	return {
		type: actionTypes.SORTEDITORIALPRIMARYTOPIC,
		column: column,
	};
}

export const sortEditorialPlacementTableData = (
	type,
	placementPageviewSummarycolumn,
	placementPageviewSummarydirection = "",
	loadMoreSort = false
) => {
	return {
		type,
		placementPageviewSummarycolumn,
		placementPageviewSummarydirection,
		loadMoreSort,
	};
};

export const sortEditorialTopicTableData = (
	type,
	topicPageviewSummarycolumn,
	topicPageviewSummarydirection = "",
	loadMoreSort = false
) => {
	return {
		type,
		topicPageviewSummarycolumn,
		topicPageviewSummarydirection,
		loadMoreSort,
	};
};

export const sortEditorialOrganizationTableData = (
	type,
	orgsSummarycolumn,
	orgsSummarydirection = "",
	loadMoreSort = false
) => {
	return {
		type,
		orgsSummarycolumn,
		orgsSummarydirection,
		loadMoreSort,
	};
};

export const checkToggle = ( payload ) =>({
	type: actionTypes.CHECKINGTOOGLE, 
	payload
});

export const sortAuthorSummaryTableData = (
	type,
	authorSummaryColumn,
	authorSummaryDirection = "",
	loadMoreSort = false
) => {
	return {
		type,
		authorSummaryColumn,
		authorSummaryDirection,
		loadMoreSort,
	};
};