import * as actionTypes from "./actionTypes";
import { getApiDate } from "../utilities/common";
import axios from "../api/axios";
import { headers } from "../api/apiConstants";

export const selSiteReportFilterValue = (value, dispatchType, key) => {
  return {
    type: dispatchType,
    payload: value,
  };
};

export const selSiteReportToggles = (dispatchType) => (dispatch) => {
  dispatch({
    type: dispatchType,
  });
};

export const sortAllPGSummaryTable = (column) => {
  return {
    type: actionTypes.SORTALLPGSUMMARYTABLE,
    column: column,
  };
};

export const sortEditorialPGSummaryTable = (column) => {
  return {
    type: actionTypes.SORTEDITORIALPGSUMMARYTABLE,
    column: column,
  };
};

export const sortSponsoredPGSummaryTable = (column) => {
  return {
    type: actionTypes.SORTSPONSOREDPGSUMMARYTABLE,
    column: column,
  };
};

export const loadingSiteAllPGSummaryTable = () => (dispatch) => {
  dispatch({
    type: actionTypes.ISLOADINGSITEALLPGSUMMARYTABLE,
  });
};

export const loadingSiteEditorialPGSummaryTable = () => (dispatch) => {
  dispatch({
    type: actionTypes.ISLOADINGSITEEDITORIALPGSUMMARYTABLE,
  });
};

export const loadingSiteSponsoredPGSummaryTable = () => (dispatch) => {
  dispatch({
    type: actionTypes.ISLOADINGSITESPRONSOREDPGSUMMARYTABLE,
  });
};

export const clearSiteSort = (dispatchType) => (dispatch) => {
  dispatch({
    type: dispatchType,
  });
};

export const showMoreTableData = (type) => {
  return {
    type,
  };
};

export const fetchSiteAllPGSummaryReport =
  (uri, startDate, endDate, page, reportType) => async (dispatch) => {
    await axios
      .post(
        uri,
        {
          start_date: getApiDate(startDate).concat(" 00:00:00"),
          end_date: getApiDate(endDate).concat(" 23:59:59"),
          page: page,
          type: reportType && reportType
        },
        {
          headers: headers(),
        }
    )
      .then((res) => {
        if (res.data) {
          dispatch({
            type: actionTypes.SITEALLPGSUMMARYREPORTDATA,
            payload: res.data,
            resPageviewSummaryValue: res.data === null && true
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: actionTypes.SITEALLPGSUMMARYREPORTDATA,
						payload: [],
          });
        }
      });
  };

export const fetchSiteEditorialPGSummaryReport =
  (uri, startDate, endDate, page, reportType) => async (dispatch) => {
    await axios
      .post(
        uri,
        {
          start_date: getApiDate(startDate).concat(" 00:00:00"),
          end_date: getApiDate(endDate).concat(" 23:59:59"),
          page: page,
          type: reportType && reportType
        },
        {
          headers: headers(),
        }
      )
      .then((res) => {
        if (res.data) {
          dispatch({
            type: actionTypes.SITEEDITORIALPGSUMMARYREPORTDATA,
            payload: res.data,
            resEditorialPageviewSummaryValue: res.data === null && true
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: actionTypes.SITEEDITORIALPGSUMMARYREPORTDATA,
            payload: [],
          });
        }
      });
  };

export const fetchSiteSponsoredPGSummaryReport =
  (uri, startDate, endDate, page, reportType) => async (dispatch) => {
    await axios
      .post(
        uri,
        {
          start_date: getApiDate(startDate).concat(" 00:00:00"),
          end_date: getApiDate(endDate).concat(" 23:59:59"),
          page: page,
          type: reportType
        },
        {
          headers: headers(),
        }
      )
      .then((res) => {
        if (res.data) {
          dispatch({
            type: actionTypes.SITESPONSOREDPGSUMMARYREPORTDATA,
            payload: res.data,
            resSponsoredPageviewSummaryValue: res.data === null && true
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: actionTypes.SITESPONSOREDPGSUMMARYREPORTDATA,
            payload: [],
          });
        }
      });
  };

export const fetchSiteChart =
  (uri, startDate, endDate, page, compare, reportType) => async (dispatch) => {
    await axios
      .post(
        uri,
        {
          start_date: getApiDate(startDate).concat(" 00:00:00"),
          end_date: getApiDate(endDate).concat(" 23:59:59"),
          page: page,
		      compare: compare,
          type: reportType && reportType
        },
        {
          headers: headers(),
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.SITEREPORTCHARTS,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: actionTypes.ERRORCODE,
            payload: err.response.status,
          });
        }
      });
  };
