import React from "react";
import _ from "lodash";

const ExpandCollapse = ({ data, expanded, setExpanded, marginLeft }) => {
	return (
		<React.Fragment>
            {!_.isNull(data) && (
                <i className="material-icons" style={{ marginLeft }} onClick={() => {setExpanded(!expanded)}} >{!expanded ? "add" : "remove"}</i>
            )}
        </React.Fragment>
	);
};


export default ExpandCollapse;
