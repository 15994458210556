import React from "react";
import { Table } from "semantic-ui-react";
import Link from "../../../hyperlinks/link";

const getNestedValue = (obj, path) => {
    return path.split('.').reduce((value, key) => value && value[key], obj);
};

const LinkComponent = ({ data, keyMappings }) => {
    const label = getNestedValue(data, keyMappings?.label);
    const value = getNestedValue(data, keyMappings?.value);

    return (
        label ? (
            <Table.Cell title={label} className="blogs_link space_height">
                <Link type={keyMappings.type} urlParam={value} refValue={label} />
            </Table.Cell>
        ) : (
            <Table.Cell className="space_height" />
        )
    );
};

export default LinkComponent;
