import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import * as actionTypes from "../../../actions/actionTypes";
import { selectValue, fetchPgAuthors, addSelectedValue, clearSearchForAllReport } from "../../../actions";
import AuthorPicker from "../author";

const AuthorsSelection = (props) => {

    const onSearchHandler = (e) => {
		if (e.target.value.length > 1) {
            props.fetchPgAuthors(
                e.target.value, 
                actionTypes[props.reportActionType].FETCH_AUTHOR,
                props.reportActionType
            );
		} else {
			props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].RESET_AUTHOR, reportType: props.reportActionType });
		}
	};

    const onChangeHandler = (e, { value }) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key: "selAuthor", value });
        props.addSelectedValue({ dispatchType: actionTypes[props.reportActionType].ADD_AUTHOR, reportType: props.reportActionType, value });
    }

    const onClickHandler = () => {
        props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].CLEAR_AUTHOR, reportType: props.reportActionType });
    }

    return (
        <AuthorPicker
            authors={props.commonSelectData.authors}
            value={props.filterData.selAuthor}
            onClick={onClickHandler}
            onChange={onChangeHandler}
            onSearchChange={onSearchHandler}
        />
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        commonSelectData: state.allReportCommonReducer[ownProps.reportActionType],
    };
};

export default compose(connect(mapStateToProps, {
    selectValue,
    fetchPgAuthors,
    addSelectedValue,
    clearSearchForAllReport
}))(AuthorsSelection);