import axios from "../api/axios";
import { url } from "../api/urlHelper";
import * as actionTypes from "./actionTypes";
import { headers } from "../api/apiConstants";
import { extractFileName, getApiDate } from "../utilities/common";
import { LIMIT_20 } from "../components/config/TableHeaderNames";
import { findReportFromURL } from "../components/config/FindReportFromURL";

export const fetchOrderReportData = (filterData, page, type = "", dispatchType, reportType, setReportType = "", email="") => async (dispatch) => {
	const reportData = findReportFromURL(url.orderDataSearch, "");
	await axios
		.post(url.orderDataSearch, 
        {
            start_date: getApiDate(filterData.startDate).concat(" 00:00:00"),
            end_date: getApiDate(filterData.endDate).concat(" 23:59:59"),
            organizations: filterData.organization,
            orders: filterData.orderSingleStatus !== "" ? [filterData.orderSingleStatus] : [],
            order_status: filterData.orderStatus !== "all" ? filterData.orderStatus : "",
            type: reportType,
            page,
			limit: LIMIT_20,
			status: filterData.campaignStatus !== "all" ? filterData.campaignStatus : "",
			exclude_competitor_orgs: filterData?.competitorFiltering,
			exclude_sdx_central: filterData.excludeSdxCentral,
			export: type,
			email,
			report_name: reportData ? reportData.report : "",
			report_table_name: reportData ? reportData.report_table_name : "",
			ad_order_type: filterData?.adOrderType !== "all" ? filterData?.adOrderType : "",
        },
		{
			headers: headers(),
		})
		.then((res) => {
			if (type) {
				const fileName = extractFileName(res.headers)
				dispatch({
                    type: dispatchType,
                    payload: res.data,
                    downloadFileName: fileName,
                    reportType: setReportType,
					email
                })
			} else {
				dispatch({
					type: dispatchType,
					payload: res.data,
					reportType: setReportType,
				});
			}
		})
		.catch((err) => {
			if (err.response) {
				dispatch({
					type: actionTypes.ERRORCODE,
					payload: err.response.status,
				});
			}
		});
	};