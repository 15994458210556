import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import SingleSelectDropDown from "../base/singleSelectDropDown";
import { clearSearch, commonSelectedValue, fetchPageTitle } from "../../actions";
import _ from "lodash";

const FetchPageTitle = (props) => {

   const onClickHandle = () => {
      if (_.isEmpty(props.value)) {
         props.clearSearch(props.clearActionsType);
      }
   }

   const onChangeHandle = (e, { value }) => {
      props.commonSelectedValue({ dispatchType: props.selectDispatchType, key: props.keyValue, value });
   }

   const onSearchHandle = (e) => {
      if (e.target.value.length > 1) {
         props.fetchPageTitle(e.target.value, props.fetchActionType);
      } else {
         props.clearSearch(props.clearActionsType);
      }
   }

   return (
      <SingleSelectDropDown
         clearable
         options={props.titles.map((page, index) => ({
            key: index,
            text: page.title,
            value: page.title,
         }))}
         value={props.value}
         label="Page Title"
         placeholder="Page Title"
         onClick={onClickHandle}
         onChange={onChangeHandle}
         onSearchChange={onSearchHandle}
      />
   );
};

const mapStateToProps = (state) => {
   return {};
};

export default compose(connect(mapStateToProps, {
   clearSearch,
   commonSelectedValue,
   fetchPageTitle,
})
)(FetchPageTitle);