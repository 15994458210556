import React from 'react';
import { connect } from 'react-redux';

const FilterDateRange = (props) => {
  return (
    <div className='ad-graph-date'>
		  {(props.filStartDate && props.filEndDate) && <p>{props.filStartDate} - {props.filEndDate}</p>}
	</div>
  );
};

const mapStateToProps = (state) => {
	return {
        filStartDate: state.editorialFilterData.wp_startDate,
        filEndDate: state.editorialFilterData.wp_endDate,
	};
  };

export default connect(mapStateToProps)(FilterDateRange);