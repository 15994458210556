import React from "react";

import NewslettersActionsPicker from "../NewslettersActionsPicker";
import * as actionTypes from "../../../actions/actionTypes";

const NewsActionsPicker = (props) => {

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };
    
    const onSelectActions = (e, { value }) => {
        dispatchDynamicSelectAction(props, "selectNewsletterAction", value);
        props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].RESET_CAMPAIGNS, reportType: props.reportActionType });
        dispatchDynamicSelectAction(props, "campaign", []);
    };

    return (
        <NewslettersActionsPicker
            value={props.filterData.selectNewsletterAction}
            onChange={onSelectActions}
        />
    )
}

export default NewsActionsPicker;