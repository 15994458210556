import _ from "lodash";
import { url } from "../../api/urlHelper";
import {
   ActivityReportLocation,
   AllAdsLocation,
   AuthorsReportLocation,
   ContentBodyReportLocation,
   ContentPerformance,
   DIBasic,
   DIEarnedMedia,
   DIPro,
   DIUpgrade,
   DisplayReportLocation,
   EngagementReportLocation,
   InterstitialReportLocation,
   LeadReportLocation,
   NewsletterReportLocation,
   NewslettersLocation,
   OrderSummaryLocation,
   // PageviewDetailReportLocation,
   PageviewReportLocation,
   RoadblockReportLocation,
   SidebarAdReportLocation,
   SidebarReportLocation,
   SponsoredReportLocation
} from "./PathNames";

const reportTypeMap = {
   topic_tag: {
      report_table_name: 'Topic Tag Summary'
   },
   organization_tag: {
      report_table_name: 'Organization Tag Summary'
   },
   placement_tag: {
      report_table_name: 'Placement Tag Summary'
   }
};

const urlsAndReports = [
   // Ads report
   { api_url: url.adCampaignReport, report_url: DisplayReportLocation, report: 'Display', report_table_name: 'Display Ad Campaign Summary' },
   { api_url: url.adImpressionReport, report_url: DisplayReportLocation, report: 'Display', report_table_name: 'Display Ad Campaign Details' },
   { api_url: url.adDetail, report_url: DisplayReportLocation, report: 'Display', report_table_name: 'Display Ad Details' },
   { api_url: url.adCampaignReport, report_url: ContentBodyReportLocation, report: 'Content Body', report_table_name: 'Content Body Ad Summary' },
   { api_url: url.adImpressionReport, report_url: ContentBodyReportLocation, report: 'Content Body', report_table_name: 'Content Body Ad Campaign Details' },
   { api_url: url.adDetail, report_url: ContentBodyReportLocation, report: 'Content Body', report_table_name: 'Content Body Ad Details' },
   { api_url: url.adCampaignReport, report_url: InterstitialReportLocation, report: 'Interstitial', report_table_name: 'Interstitial Ad Summary' },
   { api_url: url.adImpressionReport, report_url: InterstitialReportLocation, report: 'Interstitial', report_table_name: 'Interstitial Ad Campaign Details' },
   { api_url: url.adDetail, report_url: InterstitialReportLocation, report: 'Interstitial', report_table_name: 'Interstitial Ad Details' },
   { api_url: url.adCampaignReport, report_url: NewsletterReportLocation, report: 'Newsletter', report_table_name: 'Newsletter Ad Summary' },
   { api_url: url.adImpressionReport, report_url: NewsletterReportLocation, report: 'Newsletter', report_table_name: 'Newsletter Ad Campaign Details' },
   { api_url: url.adDetail, report_url: NewsletterReportLocation, report: 'Newsletter', report_table_name: 'Newsletter Ad Details' },
   { api_url: url.adCampaignReport, report_url: RoadblockReportLocation, report: 'Roadblock', report_table_name: 'Roadblock Ad Summary' },
   { api_url: url.adImpressionReport, report_url: RoadblockReportLocation, report: 'Roadblock', report_table_name: 'Roadblock Ad Campaign Details' },
   { api_url: url.adDetail, report_url: RoadblockReportLocation, report: 'Roadblock', report_table_name: 'Roadblock Ad Details' },
   { api_url: url.adDetail, report_url: AllAdsLocation, report: 'All Ads', report_table_name: 'Ad Details' },
   { api_url: url.adCampaignReport, report_url: SidebarAdReportLocation, report: 'Sidebar', report_table_name: 'Sidebar Ad Summary' },
   { api_url: url.adImpressionReport, report_url: SidebarAdReportLocation, report: 'Sidebar', report_table_name: 'Sidebar Ad Campaign Details' },
   { api_url: url.adDetail, report_url: SidebarAdReportLocation, report: 'Sidebar', report_table_name: 'Sidebar Ad Details' },

   // Sidebar report
   { api_url: url.topicHubSummary, report_url: SidebarReportLocation, report: 'Sidebars', report_table_name: 'Sidebar Summary' },
   { api_url: url.topicHubDetail, report_url: SidebarReportLocation, report: 'Sidebars', report_table_name: 'Sidebar Campaign Detail' },
   { api_url: url.sidebarDetail, report_url: SidebarReportLocation, report: 'Sidebars', report_table_name: 'Sidebar Detail' },
   { api_url: url.topichubReportChart, report_url: SidebarReportLocation, report: 'Sidebars', report_table_name: '' },

   // Engagement report
   { api_url: url.engagementSummary, report_url: EngagementReportLocation, report: 'Engagements', report_table_name: 'Engagement Campaign Summary' },
   { api_url: url.leadEngagementReport, report_url: EngagementReportLocation, report: 'Engagements', report_table_name: 'Engagements Workbook' },

   // Activity report
   { api_url: url.activityDetailsURL, report_url: ActivityReportLocation, report: 'Activity', report_table_name: 'Activity' },

   // Lead report
   { api_url: url.leadEngagementSummaryReport, report_url: LeadReportLocation, report: 'Leads', report_table_name: 'Lead Campaign Summary' },
   { api_url: url.clientLeadEngsReport, report_url: LeadReportLocation, report: 'Leads', report_table_name: 'Lead Detail' },

   // Sponsored report
   { api_url: url.sponsoredSummary, report_url: SponsoredReportLocation, report: 'Sponsored', report_table_name: 'Sponsored Campaign Summary' },
   { api_url: url.sponsoredPageDetails, report_url: SponsoredReportLocation, report: 'Sponsored', report_table_name: 'Sponsored Campaign Page Details' },
   { api_url: url.sponsoredDetail, report_url: SponsoredReportLocation, report: 'Sponsored', report_table_name: 'Sponsored Campaign Page View Details' },

   // Pageview report
   { api_url: url.pageviewPrimaryTopic, report_url: PageviewReportLocation, report: 'Pageview', report_table_name: 'Primary Topic Summary' },
   // { api_url: url.pageviewAuthorSummary, report_url: PageviewReportLocation, report: 'Pageview', report_table_name: 'Author Summary' },
   { api_url: url.pageviewDetails, report_url: PageviewReportLocation, report: 'Pageview', report_table_name: 'Page Summary' },

   // Pageview detail report
   { api_url: url.pageviewDetailed, report_url: PageviewReportLocation, report: 'Pageview', report_table_name: 'Pageview Details' },

   // new newsletter report
   { api_url: url.newslettersSummary, report_url: NewslettersLocation, report: 'Newsletters', report_table_name: 'Newsletters Summary' },
   { api_url: url.newslettersDetails, report_url: NewslettersLocation, report: 'Newsletters', report_table_name: 'Newsletters Details' },

   // content performance report
   { api_url: url.contentPerformanceSummaryIPA, report_url: ContentPerformance, report: 'Content Performance', report_table_name: 'Content Performance Summary: IPA' },
   { api_url: url.contentPerformanceSummarySenority, report_url: ContentPerformance, report: 'Content Performance', report_table_name: 'Content Performance Summary: Senority' },
   { api_url: url.contentPerformanceSummarySector, report_url: ContentPerformance, report: 'Content Performance', report_table_name: 'Content Performance Summary: Sector' },
   { api_url: url.contentPerformanceDetails, report_url: ContentPerformance, report: 'Content Performance', report_table_name: 'Content Performance Details' },

   // authro report
   { api_url: url.pageviewAuthorSummary, report_url: AuthorsReportLocation, report: 'Author', report_table_name: 'Author Summary' },

   // order report
   { api_url: url.orderDataSearch, report_url: OrderSummaryLocation, report: 'Orders', report_table_name: 'Order Summary' },
   
   // DI report
   { api_url: url.decsionInsightAPI, report_url: DIEarnedMedia, report: 'Decision Insights Basic', report_table_name: 'Basic' },
   { api_url: url.decsionInsightAPI, report_url: DIBasic, report: 'Decision Insights Pro', report_table_name: 'Pro' },
   { api_url: url.decsionInsightAPI, report_url: DIPro, report: 'Decision Insights Pro+', report_table_name: 'Pro+' },
   { api_url: url.decsionInsightAPI, report_url: DIUpgrade, report: 'Decision Insights Upgrade', report_table_name: 'Upgrade' },
];

export const findReportFromURL = (urlValue, reportType) => {
   const currentPath = window.location.pathname;
   let foundData;

   if (_.includes(["topic_tag", "organization_tag", "placement_tag"], reportType)) {
      foundData = {
         api_url: url.topicOrganizationPlacementReport,
         report_url: PageviewReportLocation,
         report: 'Pageview',
         ...reportTypeMap[reportType]
      };
   } else if (urlValue !== url.topicOrganizationPlacementReport) {
      foundData = urlsAndReports.find(item => (item.api_url === urlValue && item.report_url === currentPath));
   }

   return foundData || "";
};
