import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Table, Checkbox, Icon, Button } from 'semantic-ui-react';
import _ from 'lodash';
// import * as actionTypes from '../actions/actionTypes';
import { selectRoleValues, saveRoleBasedReportAction } from '../actions';
import SingleSelectDropDown from "../components/base/singleSelectDropDown";

const RoleMapping = (props) => {
    const [expandedRows, setExpandedRows] = useState({});
    const [roleMappings, setRoleMappings] = useState([]);

    useEffect(() => {
        const selectedRoleId = props?.filterData?.selectedRoles;
        if (!selectedRoleId) return;
    
        const matchedRole = props?.filterData?.currentUserReportRoleData?.find(
            (role) => role.ID === selectedRoleId
        );
    
        if (!matchedRole || !matchedRole.RoleReports?.length) return;
    
        setRoleMappings((prevMappings) => {
            const existingMapping = prevMappings.find(
                (mapping) => mapping.role_id === selectedRoleId
            );
    
            const newRoleMappings = matchedRole.RoleReports.map((roleReport) => {
                const matchedReport = props?.filterData?.getAllReports?.find(
                    (report) => report.uuid === roleReport.report_uuid
                );
    
                if (matchedReport) {
                    return {
                        role_id: selectedRoleId,
                        report_uuid: roleReport.report_uuid,
                        report_column_uuids: roleReport?.report_column_uuids || [], 
                    };
                }
                return null;
            }).filter(Boolean);
    
            if (existingMapping) {
                const updatedReportMappings = [
                    ...existingMapping.role_report_mapping,
                    ...newRoleMappings.filter(
                        (newMapping) =>
                            !existingMapping.role_report_mapping.some(
                                (existing) => existing.report_uuid === newMapping.report_uuid
                            )
                    ),
                ];
    
                return prevMappings.map((mapping) =>
                    mapping.role_id === selectedRoleId
                        ? {
                              ...mapping,
                              role_report_mapping: updatedReportMappings,
                          }
                        : mapping
                );
            } else {
                return [
                    ...prevMappings,
                    {
                        role_id: selectedRoleId,
                        role_report_mapping: newRoleMappings,
                    },
                ];
            }
        });
        // eslint-disable-next-line
    }, [props?.filterData?.selectedRoles, props?.filterData?.getAllReports]);
    

    const groupedData = _.chain(props?.filterData?.getAllReports)
    .groupBy('module')
    .map((items, module) => ({
        module,
        reports: _.chain(items)
            .groupBy('report')
            .map((reportItems, report) => ({
                report,
                elements: _.chain(reportItems)
                    .groupBy('element')
                    .map((elementItems, element) => ({
                        element,
                        actions: elementItems.map(item => ({
                            permission: item.permission,
                            url: item.url,
                            uuid: item.uuid,
                        })),
                    }))
                    .value(),
            }))
            .value(),
    }))
    .value();
    
    const toggleRow = (module, report) => {
        setExpandedRows(prev => ({
            ...prev,
            [module]: {
                ...prev[module],
                [report]: !prev[module]?.[report],
            },
        }));
    };

    const toggleActionVisibility = (uuid) => {
        const role_id = props?.filterData?.selectedRoles;
        if (!role_id) return;
    
        setRoleMappings((prevMappings) => {
            const updatedMappings = _.cloneDeep(prevMappings);

            let roleMapping = updatedMappings.find((mapping) => mapping.role_id === role_id);
            if (!roleMapping) {
                roleMapping = { role_id, role_report_mapping: [] };
                updatedMappings.push(roleMapping);
            }
    
            const reportMappingIndex = roleMapping.role_report_mapping.findIndex(
                (mapping) => mapping.report_uuid === uuid
            );
    
            if (reportMappingIndex !== -1) {
                roleMapping.role_report_mapping.splice(reportMappingIndex, 1);

                if (roleMapping.role_report_mapping.length === 0) {
                    roleMapping.role_report_mapping = [];
                }
            } else {
                roleMapping.role_report_mapping.push({
                    role_id,
                    report_uuid: uuid,
                    report_column_uuids: [],
                });
            }

            return updatedMappings.filter(
                (mapping) => mapping.role_report_mapping.length > 0
            );
        });
    };
    
    const handleSave = () => {
        props.selectRoleValues({ key: "isLoadingForAllRepport", value: true });
        const roleIds = _.map(props?.filterData?.currentUserRole, 'role_id');
        props.saveRoleBasedReportAction(roleMappings[0], roleIds );
    };

    return (
        <div>
            <div>
                <label>Roles</label>
                <SingleSelectDropDown
                    placeholder='Select Roles'
                    onChange={(e, {value})=> props.selectRoleValues({ key: "selectedRoles", value })}
                    options={props?.filterData?.getAllRoles?.map((role, index) => ({
                        key: index,
                        text: role.role_name,
                        value: role.ID
                    }))}
                    value={props?.filterData?.selectedRoles}
                    style={{ width: "25em" }}
                />
            </div>
            {
                props?.filterData?.selectedRoles > 0 && (
                    <Table celled>
                        <Table.Header className="table-header">
                            <Table.Row>
                                <Table.HeaderCell>Report</Table.HeaderCell>
                                <Table.HeaderCell>Element</Table.HeaderCell>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {groupedData.map((moduleData) => (
                                <React.Fragment key={moduleData.module}>
                                    {/* Report and Element Rows */}
                                    {moduleData.reports.map((reportData) => (
                                        <React.Fragment key={reportData.report}>
                                            <Table.Row
                                                onClick={() => toggleRow(moduleData.module, reportData.report)}
                                                className="report-row"
                                            >
                                                <Table.Cell colSpan="3">
                                                    <Icon
                                                        name={
                                                            expandedRows[moduleData.module]?.[reportData.report]
                                                                ? 'angle down'
                                                                : 'angle right'
                                                        }
                                                    />
                                                    {reportData.report}
                                                </Table.Cell>
                                            </Table.Row>

                                            {/* Expandable content for elements and actions */}
                                            {expandedRows[moduleData.module]?.[reportData.report] && (
                                                <>
                                                    {reportData.elements.map((elementData) => (
                                                        <Table.Row key={elementData.element} className="element-row">
                                                            <Table.Cell />
                                                            <Table.Cell>{elementData.element}</Table.Cell>
                                                            <Table.Cell>
                                                                {elementData.actions.map((action, index) => (
                                                                    <div key={index} className="action-container">
                                                                        <Checkbox
                                                                            toggle
                                                                            label={action.permission}
                                                                            checked={roleMappings.some(
                                                                                (mapping) =>
                                                                                    mapping?.role_id === props?.filterData?.selectedRoles &&
                                                                                    mapping?.role_report_mapping?.some(
                                                                                        (reportMapping) => reportMapping.report_uuid === action.uuid
                                                                                    )
                                                                            )}
                                                                            onChange={() =>
                                                                                toggleActionVisibility(action.uuid)
                                                                            }
                                                                            className="action-checkbox"
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    ))}
                                                </>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            ))}
                        </Table.Body>
                    </Table>
                )
            }
            
            {roleMappings.length > 0 && !props?.filterData?.isLoadingForAllRepport && (
                <div className="footer-save">
                    <Button
                        primary
                        onClick={handleSave}
                        animated="vertical"
                        className="save-button"
                    >
                        <Button.Content visible>Save</Button.Content>
                        <Button.Content hidden>Save</Button.Content>
                    </Button>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        filterData: state.allFilterReportData,
    };
};

export default connect(mapStateToProps, {
    selectRoleValues,
    saveRoleBasedReportAction,
})(RoleMapping);