import React from "react";
import SingleSelectDropDown from "../base/singleSelectDropDown";
import { connect } from "react-redux";

const LeadPartnerPicker = (props) => {
	return (
		<SingleSelectDropDown
			className="no-blink"
			clearable
			options={props.leadpartners.map((val, index) => ({
				key: index,
				text: val,
				value: val,
			}))}
			label={props.label}
			placeholder={props.placeholder}
			value={props.value}
			onChange={props.onChange}
			{...props}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		leadpartners: Object.values(state.leadPartner),
	};
};

export default connect(mapStateToProps, {})(LeadPartnerPicker);
