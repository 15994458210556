import React from "react";
import { Table } from "semantic-ui-react";

const CreateTableRowComponent = ({ name, value }) => {
   return (
      <Table.Body>
         <Table.Row><Table.Cell rowSpan={2}>{name}</Table.Cell></Table.Row>
         <Table.Row><Table.Cell>{value}</Table.Cell></Table.Row>
      </Table.Body>
   );
};

export default CreateTableRowComponent;