import React from 'react';
import { Message } from 'semantic-ui-react';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.error) {
      return (
        <Message warning>
          <Message.Header>Unable to load.</Message.Header>
        </Message>
      );
    }
    return this.props.children;
  }
}
