import React from "react";
import Advertising from "./advertising";
import GridIcons from "./gridIcons";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import StoreIcon from "@mui/icons-material/Store";
import TrendingUpSharpIcon from "@mui/icons-material/TrendingUpSharp";
import CampaignReport from "./campaignReport";
import {
  getDisplayDate,
  getTotalCtr,
  numFormat,
  getAttachedPercentage,
  getCapital,
} from "../../utilities/common";
import Link from "../hyperlinks/link";
import MultiLinks from "../hyperlinks/multiLinks";
import Contextual from "./contextual";

const iconsDesign = {
  height: "1.9rem",
  textAlign: "centre",
};

const AdCampaign = (props) => {
  let adCampaign = props.data;
  let percentageServed = getAttachedPercentage(
    adCampaign.percentage_served_to_date
  );
  const Cards = [
    {
      title: "Impressions",
      value: numFormat(adCampaign.impressions),
      icon: <StoreIcon style={iconsDesign} />,
    },
    {
      title: "Clicks",
      value: adCampaign.clicks,
      icon: <TouchAppIcon style={iconsDesign} />,
    },
    {
      title: "Click Through Rate",
      value: getTotalCtr(adCampaign.impressions, adCampaign.clicks) + "%",
      icon: <CallSplitIcon style={iconsDesign} />,
    },
    {
      title: "Completion %",
      value: percentageServed !== "" ? percentageServed : "-",
      icon: <TrendingUpSharpIcon style={iconsDesign} />,
    },
  ];
  return (
    <div>
      <GridIcons data={Cards} />
      <CampaignReport
        object={
          adCampaign.organizations ? (
            <MultiLinks
              type="organizations"
              object={adCampaign.organizations}
            ></MultiLinks>
          ) : (
            adCampaign.account
          )
        }
        label={"ACCOUNT"}
      />
      <CampaignReport
        object={
          adCampaign.Order.name ? (
            <Link
              type={"orders"}
              urlParam={adCampaign.Order.uuid}
              refValue={adCampaign.Order.name}
            ></Link>
          ) : (
            "-"
          )
        }
        label={"ORDER"}
      />
      <CampaignReport
        object={
          adCampaign.name ? (
            <Link
              type={"campaigns"}
              urlParam={adCampaign.uuid}
              refValue={adCampaign.name}
            ></Link>
          ) : (
            "-"
          )
        }
        label={"CAMPAIGN NAME"}
      />

      <CampaignReport
        object={
          adCampaign.start_date ? getDisplayDate(adCampaign.start_date) : " - "
        }
        label={"START DATE"}
      />
      <CampaignReport
        object={
          adCampaign.end_date ? getDisplayDate(adCampaign.end_date) : " - "
        }
        label={"END DATE"}
      />
      <CampaignReport
        object={
          adCampaign.advertising.advertising_program
            ? getCapital(adCampaign.advertising.advertising_program)
            : " - "
        }
        label={"ADVERTISING PROGRAM"}
      />
      <h5 className="drawer-heading-text"> CONTEXTUAL AD TARGETING</h5>
          {adCampaign.advertising.advertising_program === "cpm" ? (
        <h5 className="drawer-heading-text"> IMPRESSIONS PURCHASED</h5>
      ) : (
        <h5 className="drawer-heading-text"> CLICKS PURCHASES</h5>
      )}
      <Contextual
        object={
          adCampaign.advertising.contextual.include_wp_tag_names === null
            ? ""
            : adCampaign.advertising.contextual.include_wp_tag_names
        }
        label={"INCLUDE TAGS"}
      />
        <CampaignReport
          object={
            adCampaign.advertising.contextual.include_all === true
              ? "Yes"
              : "No"
          }
          label={"INCLUDE All TAGS"}
        />

      <Contextual
        object={
          adCampaign.advertising.contextual.exclude_wp_tag_names === null
            ? ""
            : adCampaign.advertising.contextual.exclude_wp_tag_names
        }
        label={"EXCLUDE TAGS"}
      />
      <CampaignReport
        object={
          adCampaign.advertising.contextual.exclude_all === true ? "Yes" : "No"
        }
        label={"EXCLUDE All TAGS"}
      />

      <Advertising data={adCampaign.advertising} />
    </div>
  );
};

export default AdCampaign;
