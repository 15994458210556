export const multiOptions = [
    {
      id: "1",
      value: "Select",
      text: "Select",
      keyvalue: "Select",
    },
    { id: "2", value: "Received", text: "Received", keyvalue: "Received" },
    {
      id: "3",
      value: "Invalid - Missing Member Data",
      text: "Invalid - Missing Member Data",
      keyvalue: "In Review",
    },
    {
      id: "4",
      value: "Invalid - Missing Org Data",
      text: "Invalid - Missing Org Data",
      keyvalue: "In Review",
    },
    {
      id: "5",
      value: "Invalid - Unmatched Organization",
      text: "Invalid - Unmatched Organization",
      keyvalue: "In Review",
    },
    {
      id: "6",
      value: "Invalid - Competitor",
      text: "Invalid - Competitor",
      keyvalue: "In Review",
    },
    {
      id: "7",
      value: "Invalid - GEO",
      text: "Invalid - GEO",
      keyvalue: "In Review",
    },
    {
      id: "8",
      value: "Invalid - Unmatched Firmographic",
      text: "Invalid - Unmatched Firmographic",
      keyvalue: "In Review",
    },
    {
      id: "9",
      value: "Unmatched Persona data",
      text: "Unmatched Persona data",
      keyvalue: "In Review",
    },
    {
      id: "10",
      value: "Invalid - Unmatched Privacy data",
      text: "Invalid - Unmatched Privacy data",
      keyvalue: "In Review",
    },
    {
      id: "11",
      value: "Invalid - Unmatched Other",
      text: "Invalid - Unmatched Other",
      keyvalue: "In Review",
    },
    { id: "12", value: "Filtered", text: "Filtered", keyvalue: "In Review", },
    { id: "13", value: "Excluded Firmographic", text: "Excluded Firmographic",  keyvalue: "In Review", },
    { id: "14", value: "Excluded Persona", text: "Excluded Persona",  keyvalue: "In Review", },
    {
      id: "15",
      value: "Pending Delivery",
      text: "Pending Delivery",
      keyvalue: "Accepted",
    },
    {
      id: "16",
      value: "Delivered",
      text: "Delivered",
      keyvalue: "Accepted",
    },
    {
      id: "17",
      value: "Bounced Email",
      text: "Bounced Email",
      keyvalue: "Rejected",
    },
    {
      id: "18",
      value: "SDxCentral Manual",
      text: "SDxCentral Manual",
      keyvalue: "Rejected",
    },
    {
      id: "19",
      value: "Rejected - Client",
      text: "Rejected - Client",
      keyvalue: "Rejected",
    },
    {
      id: "21",
      value: "Rejected - SDxCentral Excluded Competitors",
      text : "Rejected - SDxCentral Excluded Competitors",
      keyvalue: "Rejected",
    },
    {
      id: "22",
      value: "Rejected - SDxCentral  Excluded Domains",
      text : "Rejected - SDxCentral  Excluded Domains",
      keyvalue: "Rejected",
    },
    {
      id: "23",
      value: "Rejected - SDxCentral  Excluded Email",
      text : "Rejected - SDxCentral  Excluded Email",
      keyvalue: "Rejected",
    },
    {
      id: "24",
      value: "Rejected - SDxCentral Excluded GEO",
      text: "Rejected - SDxCentral Excluded GEO",
      keyvalue: "Rejected",
    },
    {
      id: "25",
      value: "Rejected - SDxCentral Non-Included GEO",
      text : "Rejected - SDxCentral Non-Included GEO",
      keyvalue: "Rejected",
    },
    {
      id: "26",
      value: "Rejected - SDxCentral Non-Included Account or Domain",
      text: "Rejected - SDxCentral Non-Included Account or Domain",
      keyvalue: "Rejected",
    },
    {
      id: "27",
      value: "Rejected - SDxCentral Excluded Vertical Filters",
      text: "Rejected - SDxCentral Excluded Vertical Filters",
      keyvalue: "Rejected",
    },
    {
      id: "28",
      value: "Rejected - SDxCentral Non-Included Vertical Filters",
      text: "Rejected - SDxCentral Non-Included Vertical Filters",
      keyvalue: "Rejected",
    },
    {
      id: "29",
      value: "Rejected - SDxCentral Excluded Account Filters",
      text: "Rejected - SDxCentral Excluded Account Filters",
      keyvalue: "Rejected",
    },
    {
      id: "30",
      value: "Rejected - SDxCentral Non-Included Organization Types",
      text: "Rejected - SDxCentral Non-Included Organization Types",
      keyvalue: "Rejected",
    },
    {
      id: "31",
      value: "Rejected - SDxCentral Non-Included Organization Sizes",
      text: "Rejected - SDxCentral Non-Included Organization Sizes",
      keyvalue: "Rejected",
    },
    {
      id: "32",
      value: "Rejected - SDxCentral Non-Included Estimated Revenue",
      text: "Rejected - SDxCentral Non-Included Estimated Revenue",
      keyvalue: "Rejected",
    },
    {
      id: "33",
      value: "Rejected - SDxCentral Excluded Seniorities",
      text: "Rejected - SDxCentral Excluded Seniorities",
      keyvalue: "Rejected",
    },
    {
      id: "34",
      value: "Rejected - SDxCentral Excluded Job Functions",
      text: "Rejected - SDxCentral Excluded Job Functions",
      keyvalue: "Rejected",
    },
    {
      id: "35",
      value: "Rejected - SDxCentral Excluded Job Titles",
      text: "Rejected - SDxCentral Excluded Job Titles",
      keyvalue: "Rejected",
    },
    {
      id: "36",
      value: "Rejected - SDxCentral Non-Included Seniorities",
      text: "Rejected - SDxCentral Non-Included Seniorities",
      keyvalue: "Rejected",
    },
    {
      id: "37",
      value: "Rejected - SDxCentral Non-Included Job Functions",
      text: "Rejected - SDxCentral Non-Included Job Functions",
      keyvalue: "Rejected",
    },
    {
      id: "38",
      value: "Rejected - SDxCentral Non-Included Job Titles",
      text: "Rejected - SDxCentral Non-Included Job Titles",
      keyvalue: "Rejected",
    },
  ];

export const options = [
    {
      id: "1",
      key: "0",
      text: "Select",
      value: "Select",
    },
    {
      id: "2",
      key: "1",
      text: "Received",
      value: "Received",
    },
    {
      id: "3",
      key: "2",
      text: "In Review",
      value: "In Review",
    },
    {
      id: "4",
      key: "3",
      text: "Accepted",
      value: "Accepted",
    },
    {
      id: "5",
      key: "4",
      text: "Rejected",
      value: "Rejected",
    },
  ];