import _ from "lodash";
import { sanitizePageviewRequestTag, getApiDate, formatDate, cleanPayload } from "./common";
import { findReportFromURL } from "../components/config/FindReportFromURL";
import { getCookie } from "./envCommon";

export const ApiPayloadData = (filterData, page, type, reportType, limit, url, noNeeded = true) => {
    const reportData = findReportFromURL(url, "");
    const payload = {
        organizations: filterData.organization,
        campaigns: filterData.campaign,
        orders: filterData.order,
        ads: filterData.adorder,
        site: filterData.site !== "all" ? filterData?.site : "",
        status: filterData.campaignStatus !== "all" ? filterData?.campaignStatus : "",
        ad_status: filterData.adorderStatus !== "all" ? filterData?.adorderStatus : "",
        ad_location: filterData.location,
        included_tags: sanitizePageviewRequestTag(filterData?.selTagsIN),
        excluded_tags: sanitizePageviewRequestTag(filterData?.selTagsEX),
        ad_type: filterData.adType,
        start_date: getApiDate(filterData.startDate).concat(" 00:00:00"),
        end_date: getApiDate(filterData.endDate).concat(" 23:59:59"),
        order: "ASC",
        order_by: "id",
        include_deleted: _.includes(["all", "archived"], filterData?.campaignStatus) ? true : false,
        limit,
        page: page,
        export: type,
        applied_date_filter: filterData.appliedDate,
        exclude_qa: filterData.excludeQACampaign,
        exclude_internal: filterData.excludeInternalCampaign,
        is_paid: filterData?.excludePaidAds,
        is_unpaid: filterData?.excludeUnpaidAds,
        country: filterData.selCountry,
        engaged_organizations: filterData.engagedOrganizations,
        type: reportType,
        ...(filterData.personaToggle && {
            job_title: {
                included_job_title: filterData?.selJobTitlesIN,
                excluded_job_title: filterData?.selJobTitlesEX,
            },
            seniorities: {
                included_tags: filterData?.selSenioritiesIN,
                excluded_tags: filterData?.selSenioritiesEX,
            },
            job_functions: {
                included_tags: filterData?.selJobFunctionsIN,
                excluded_tags: filterData?.selJobFunctionsEX,
            },
        }),
        engaged_members:filterData?.selEnagedMember,
        organization_types: filterData?.selOrgType && filterData?.selOrgType.map( a => a.charAt(0).toUpperCase() + a.substr(1)),
        organization_sizes: filterData?.selOrgSize,
        organization_revenues: filterData?.selOrgRevenue,
        sector:filterData?.selSector,
        industry: filterData?.selIndustry,
        sub_industry: filterData?.selSubIndustry,
        industry_group: filterData?.selIndustryGroup,
        persona_toggle: filterData?.personaToggle,
        firmographic_toggle: filterData?.firmographicToggle,
        exclude_anonymous_organization: filterData?.exAnonymousOrgs,
        exclude_anonymous_member: filterData?.exAnonymousMember,
        exclude_internal_events: filterData?.exInternalEvents,
        ad_order_type: filterData?.adOrderType !== "all" ? filterData?.adOrderType : "",
        traffic_filter: filterData?.trafficType !== "all" ? filterData?.trafficType : "",
        report_name: reportData ? reportData.report : "",
        report_table_name: reportData ? reportData.report_table_name : "",
        session_id: getCookie("session_id"),
        exclude_competitor_orgs: filterData?.competitorFiltering,
        csm: filterData?.setCSM
    }

    if(noNeeded) {
        payload.compare = filterData.selCustomRangeCheckBox;
        payload.previous_start_date = formatDate(filterData?.previousStartDate, " 00:00:00");
        payload.previous_end_date = formatDate(filterData?.previousEndDate, " 23:59:59");
    }

    return cleanPayload(payload);
}

export const AdSummaryExtraDataHandler = (filterData) => {
    return {
        delivery_state: filterData?.deliverdFilterState && (filterData?.deliverdFilterState).trim(),
        pace: filterData?.pacingFilter !== "all" ? filterData?.pacingFilter : "",
        ad_unit: filterData?.adUnitSelected,
    }
}

export const AdDataExtraDataHandler = (filterData, newEmail, idForShowMore) => {
    return {
        email: newEmail,
        item_ids:[idForShowMore],
        ad_unit: filterData?.adUnitSelected,
    }
}

export const NewslettersExtraDataHandler = (filterData) => {
    const payload = {
        newsletter_type: filterData?.newsletterType === "all" ? "" : filterData?.newsletterType,
        newsletter_status: filterData?.newsletterStatus === "all" ? "" : filterData?.newsletterStatus,
        author_id: parseInt(filterData?.newslettersAuthor, 10),
        action: filterData?.selectNewsletterAction === "all" ? "" : filterData?.selectNewsletterAction
    }

    return cleanPayload(payload); 
}

export const PrivacyConsentDataHandler = (filterData) => {
    const cleanField = (fieldValue) => fieldValue === "all" ? "" : (fieldValue === "yes" ? true : false);
    const payload = {
        ...(filterData?.privacyParams && {
            regulation_names: filterData?.regulationName,
            purpose_consent: cleanField(filterData?.purposeConsent),
            marketing_consent: cleanField(filterData?.marketingConsent),
            double_optin: cleanField(filterData?.doubleOptin),
            dnsmpi: cleanField(filterData?.dnsmsPersonalInfo),
            conditioning_consent: cleanField(filterData?.conditioningConsent),
            age_verification: cleanField(filterData?.ageVerification),
            right_to_be_forgotten: cleanField(filterData?.rtbForgotten),
        })
    };
    
    return cleanPayload(payload);
}