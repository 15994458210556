import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { fetchTags, addSelectedValue, clearSearchForAllReport, } from "../../../actions";
import * as actionTypes from "../../../actions/actionTypes";
import _ from "lodash";
import { TOPIC_TAGS_INCLUDE_LABEL } from "../../config/const";
import TagsPicker from "../tags";

const AccountIntrestedByKeywordSelection = (props) => {

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    const onSearchHandler = (e) => {
        if (e.target.value.length > 1) {
            props.fetchTags(
                e.target.value,
                "topic",
                actionTypes[props.reportActionType].FETCH_ACCOUNT_INTERESTED_BY_KEYWORD,
                "",
                props.filterData.site,
                props.reportActionType
            );
        }
    };

    const onChangeHandler = (e, { value }) => {
        dispatchDynamicSelectAction(props, "accountInterestedByKeywords", value);
        props.addSelectedValue({ dispatchType: actionTypes[props.reportActionType].ADD_ACCOUNT_INTERESTED_BY_KEYWORD, reportType: props.reportActionType, value });
    };

    const onClickHandler = () => {
        if (_.isEmpty(props.filterData.accountInterestedByKeywords)) {
            props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].CLEAR_ACCOUNT_INTERESTED_BY_KEYWORD, reportType: props.reportActionType });
        }
    };

    return (
        <TagsPicker
            sites={props.sites}
            tags={props.commonSelectData.accIntrestedByKeywords}
            value={props.filterData.accountInterestedByKeywords}    
            onSearchChange={onSearchHandler}
            onClick={onClickHandler}
            onChange={onChangeHandler}
            label={TOPIC_TAGS_INCLUDE_LABEL}
            placeholder={TOPIC_TAGS_INCLUDE_LABEL}
        />
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        commonSelectData: state.allReportCommonReducer[ownProps.reportActionType],
        sites: Object.values(state.sites),
    };
};

export default compose(connect(mapStateToProps, {
    fetchTags,
    addSelectedValue,
    clearSearchForAllReport,
}))(AccountIntrestedByKeywordSelection);