import React from "react";
import { connect } from "react-redux";
import AdsPage from "./../adsPage";
import * as constName from "./../../components/config/const";
import { getReportsByElement } from "../../utilities/common";
import { ROLEBASED_ELEMENT_ATF_ADS } from "../../components/config/rolebasedReportNames";

const ATFHomePage = (props) => {
    const atfAdsReports = getReportsByElement(props?.roleFilterData?.currentUserReportRoleData, props?.roleFilterData?.getAllReports, ROLEBASED_ELEMENT_ATF_ADS);


    return (
        <AdsPage
            reportActionType={constName.MODULE_ATF_HOMEPAGE}
            reportName={constName.ATF_HOMEPAGE_REPORT_NAME}
            filterData={props.filterData}
            reportDescription={props.reportDescription.find(report => report.name === "Ads - ATF Homepage")?.description}
            label={constName.ATF_HOMEPAGE_LABEL}
            roleReports={atfAdsReports}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        filterData: state.commonFilterData[constName.MODULE_ATF_HOMEPAGE],
        reportDescription: state.allFilterReportData.reportDescription,
        roleFilterData: state.allFilterReportData,
    };
};

export default connect(mapStateToProps)(ATFHomePage);