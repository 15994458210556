import React from "react";
import { Table } from "semantic-ui-react";
import Link from "../../../hyperlinks/link";

const NewsletterNameComponent = ({ data }) => {
    return (
        (data.newsletter_uuid === "N/A" || data.newsletter_uuid === "") ?
            <Table.Cell className="space_height"> {"N/A"}</Table.Cell> 
            :
            <Table.Cell title={data.newsletter_title} className="blogs_link space_height">
                {data.newsletter_uuid ? (
                    <Link
                        type={"newsletters"}
                        urlParam={data.newsletter_uuid}
                        refValue={data.newsletter_title}
                    ></Link>
                ) : (
                    ""
                )}
            </Table.Cell>
    );
};

export default NewsletterNameComponent;
