import React from 'react';
import Toggle from '../base/toggle';
import * as configName from '../config/const';
import PrivacyFilter from './PrivacyFilter';
import RegulationManagerMultiSelect from './RegulationManagerMultiSelect';

const PrivacyParametersComponent = (props) => {

    const privacyFilters = [
        { key: 'purposeConsent', label: configName.PURPOSE_CONSENT_LABEL },
        { key: 'marketingConsent', label: configName.MARKETING_CONSENT_LABEL },
        { key: 'doubleOptin', label: configName.DOUBLE_OPTIN_LABEL },
        { key: 'dnsmsPersonalInfo', label: configName.DO_NOT_SELL_MY_PERSONAL_INFO_LABEL },
        // { key: 'conditioningConsent', label: configName.CONDITIONING_CONSENT_LABEL },
        // { key: 'ageVerification', label: configName.AGE_VERFICATION_LABEL },
        // { key: 'rtbForgotten', label: configName.RIGHT_TO_BE_FORGOTTEN_LABEL }
    ];

    return (
        <>
            <Toggle
                label={configName.PRIVACY_PARAMETERS}
                className="privacy_toggle"
                onChange={() => props.selectValue({ dispatchType: props.action, key: 'privacyParams', value: !props.leadFilterData.privacyParams })}
                checked={props?.leadFilterData?.privacyParams}
            />
            {props?.leadFilterData?.privacyParams && (
                <React.Fragment>
                    <RegulationManagerMultiSelect {...props}/>
                    {
                        privacyFilters.map(({ key, label, placeholder }) => (
                            <PrivacyFilter
                                key={key}
                                label={label}
                                placeholder={label}
                                value={props.leadFilterData[key]}
                                onChange={(e, { value }) => {
                                    props.selectValue({ dispatchType: props.action, key, value });   
                                }}
                            />
                        ))
                    }
                </React.Fragment>
            )}
        </>
    );
};

export default PrivacyParametersComponent;