import React from "react";
import Link from "../../../hyperlinks/link";
import { Table } from "semantic-ui-react";

const CampaignComponent = ({ data }) => {
    return (
        <Table.Cell title={data?.name} className="blogs_link">
            {data?.name ? (
                <Link
                    type={"campaigns"}
                    urlParam={data.uuid}
                    refValue={data.name}
                />
            ) : (
                ""
            )}
        </Table.Cell>
    );
};

export default CampaignComponent;
