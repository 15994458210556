import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Chart } from "react-google-charts";
import { getDateEng } from "../../utilities/common";
import { numFormat } from "../../utilities/common";

const TopicGraph = (props) => {
	const [legendHidden, setLegendHidden] = useState([]);
	let debouncer;
	let conditionChkboxAppFilter = props.filterData.selCustomRangeCheckBox && props.isFilApplied && props.filterData.appliedDate ==="Custom Date Range";

	const legendClicked = () =>{
		if (legendHidden.includes("impressions")) {
			document.getElementsByTagName("path")[9].style.display="none";
			document.getElementsByTagName("path")[10].style.display="none";
			
		   if(conditionChkboxAppFilter){
			  document.getElementsByTagName("path")[10].style.display="none"; 
			  document.getElementsByTagName("path")[13].style.display="none";
		   }
		}
		if(legendHidden.includes("ctr")){
		  document.getElementsByTagName("path")[11].style.display="none";
		  if(conditionChkboxAppFilter){
			  document.getElementsByTagName("path")[11].style.display="none"; 
			  document.getElementsByTagName("path")[12].style.display="none";
		   }
		}
	}
	useEffect(()=>{
		legendClicked();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [legendHidden, props.filterData]);


	useEffect(()=>{
		setLegendHidden([]);
	}, [props.topicCharts])

	const  getCoords = (chart) => {
		var chartLayout = chart.getChartLayoutInterface();
		var chartBounds = chartLayout.getChartAreaBoundingBox();
	
		return {
			
		  x: {
			min: chartLayout.getHAxisValue(chartBounds.left),
			max: chartLayout.getHAxisValue(chartBounds.width + chartBounds.left)
		  },
		  y: {
			min: chartLayout.getVAxisValue(chartBounds.top),
			max: chartLayout.getVAxisValue(chartBounds.height + chartBounds.top)
		  },
  
		};
	  }
	const addChartGradient = (chart) => {
			var chartDiv = chart.getContainer("chart-div");
			var svg = chartDiv.getElementsByTagName("svg")[0];
			var properties = {
				id: "chartGradient",
				x1: "0%",
				y1: "10%",
				x2: "0%",
				y2: "100%",
				stops: [
					{ offset: '25%', 'stop-color': '#E68BBF' },
					{ offset: '95%', 'stop-color': 'rgba(255,255,255,0)' }
				]
			};
			createGradient(svg, properties); 
			var chartPath = svg.getElementsByTagName('path')[0];  //0 path corresponds to legend path
			chartPath.setAttribute('fill', 'url(#chartGradient)');
		}
	const  createGradient = (svg, properties) =>  {
			var svgNS = svg.namespaceURI;
			var grad = document.createElementNS(svgNS, 'linearGradient');
			grad.setAttribute('id', properties.id);
			["x1","y1","x2","y2"].forEach(function(name) {
				if (properties.hasOwnProperty(name)) {
					grad.setAttribute(name, properties[name]);
				}
			});
			for (var i = 0; i < properties.stops.length; i++) {
				var attrs = properties.stops[i];
				var stop = document.createElementNS(svgNS, 'stop');
				for (var attr in attrs) {
					if (attrs.hasOwnProperty(attr)) stop.setAttribute(attr, attrs[attr]);
				}
				grad.appendChild(stop);
			}
			var defs = svg.querySelector('defs') 
			defs.insertBefore(document.createElementNS(svgNS, 'defs'), defs.firstChild);
			return defs.appendChild(grad);
	}

	const onLegendClick = (line) => {
		legendHidden.includes(line) ? setLegendHidden((i)=>i.filter((element)=> element !== line)) :setLegendHidden((i)=>[...i, line]);
		} 
	  
	let d1 = [];
	const topicHubData = () => {
		var data = [];
		let d = [];

		d.push({ type: "date" });
		d.push({ type: "string", role: "tooltip", p: { html: true } });
		d.push({ type: "number" });
		conditionChkboxAppFilter &&	d.push({ type: "number" });
		(conditionChkboxAppFilter) && d.push({
			type: "boolean",
			role: "certainty",
		});
		d.push({ type: "number"});
		conditionChkboxAppFilter && d.push({ type: "number"});
		// d.push({ type: "number"});
		(conditionChkboxAppFilter) && d.push({
			type: "boolean",
			role: "certainty",
		});
	
		data.push(d);
		//props.topiHubCharts.forEach((e) => {
		props.topicCharts.forEach((e) => {
			let ctrValue = 	e.ctr === "" ? "No Data" : parseFloat(e.ctr) + "%";
			let prevCtrValue = e.prev_ctr === "" ? "No Data" : parseFloat(e.prev_ctr) + "%" 
			d = [];
			d1.push(new Date(e.date));
			d.push(new Date(e.date));
			d.push(
				conditionChkboxAppFilter ?
				`<div class="tooltip-pageview-values" style='width: 190px; padding-top: 10px'>` +
				getDateEng(e.date) +
				`<hr></hr>` +
				" <span style='height: 6px; width: 6px; margin-right: 5px; background-color: #CC167F;border-radius: 50%; display: inline-block;'></span>" +
				numFormat(e.impressions) +
				` - Impressions` +
				`<br />` +
				" <span style='height: 6px; width: 6px; margin-right: 5px; background-color: #CC167F;border-radius: 50%; display: inline-block;'></span>" +
				numFormat(e.prev_impressions) +
				` - Previous Impressions` +
				`<br />` +
				" <span style='height: 6px; width: 6px; margin-right: 5px; background-color: #FA911D;border-radius: 50%; display: inline-block;'></span>" +
				ctrValue +
				` - CTR` +
				`<br />` +
				" <span style='height: 6px; width: 6px; margin-right: 5px; background-color: #FA911D;border-radius: 50%; display: inline-block;'></span>" +
				prevCtrValue +
				` - Previous CTR` +
				`</div>` :
				`<div class="tooltip-pageview-values" style='width: 180px; padding-top: 15px; padding-left: 10px;'>` +
					getDateEng(e.date) +
					`<hr></hr>` +
					" <span style='height: 6px; width: 6px; margin-right: 5px; background-color: #CC167F;border-radius: 50%; display: inline-block;'></span>" +
					numFormat(e.impressions) +
					` - Impressions` +
					`<br />` +
					" <span style='height: 6px; width: 6px; margin-right: 5px; background-color: #FA911D;border-radius: 50%; display: inline-block;'></span>" +
					ctrValue +
					` - CTR` +
				`</div>`
			);
			d.push(e.impressions)
		//	d.push(e.clicks);
		conditionChkboxAppFilter && d.push (parseFloat(e.prev_ctr)/100);
		(conditionChkboxAppFilter) && d.push(false);
			d.push(parseFloat(e.ctr)/100);
			conditionChkboxAppFilter &&	d.push( e.prev_impressions);
			(conditionChkboxAppFilter) && d.push(false);
			
			data.push(d);
		});
		return data;
	};
	let container = document.getElementById('chart-div');

	const options = {
		chartArea: { left: "5%", top: "13%", width: '90%',height: '80%'},
		intervals: { style: "line" },
		legend: "none",
		tooltip: { isHtml: true, showColorCode: false },
		// Gives each series an axis that matches the vAxes number below.
		vAxes: [
			{
				gridlines:{
					interval:1
				},
			},
			{
				format: "percent"
			}
		],
		hAxis: {
			baselineColor: '#fff',
			gridlineColor: '#fff',
			ticks: d1,
			format: "MMM dd",
			minorGridlines:{
				count: 0
			},
			tooltip: false
		},
		vAxis: {
			// viewWindow: {
			// 	max: 30,
			// },
			format: 'short',
			baselineColor: "#B6B7B8",
			tooltip: { isHtml: true },
			minValue: 0,
			minorGridlines:{
				count: 0
			},
			// gridlines:{
			// 	interval:1
			// }
		},
		crosshair: {
			orientation: "vertical",
			color: "grey",
			opacity: 2,
			trigger: "both",
		},
		explorer: {
			actions: ["dragToZoom", "rightClickToReset"],
			axis: "horizontal",
			keepInBounds: true,
			maxZoomIn: 2,
			//zoomDelta: 1,
		},
		// legend:{
		// 	position: 'top', textStyle: {color: 'blue', fontSize: 16}
		// },
		// lineDashStyle:conditionChkboxAppFilter && [5, 1, 3],
		seriesType: "line",
		series: {
			0: {axis: "Impressions", targetAxisIndex: 0, color: "#CC167F", tooltip: false, type:'area'},
			1: { axis: "Ctr", targetAxisIndex: 1, color: "#FA911D"},
			2: { tooltip:{ trigger: 'selection' }},
			3: { axis: "Impressions", targetAxisIndex: 0, color: "#CC167F"},
		},
		 focusTarget: "category"
	};    

	return (
		<div classsName="topic-graph" style={{ height: "430px" }} id="chart-div">
			 <div>
            <div style={{marginBottom: "-30px", marginTop: "43px", position: "relative", zIndex: 1}}>
			  <div
			    style={{
              position: "relative",
              display: "flex",
              justifyContent: "space-between",
            }} >
			  <div className="legend-item"  style={{ zIndex: 1, marginLeft: -14}}>
              <span
                style={{ backgroundColor: !legendHidden.includes("impressions")
				? "#CC167F"
				: "grey",}}
                className="legend-circle"
              />
              <text id="impression" style={{ cursor: "pointer",
			    color: legendHidden.includes("impressions")
                ? "#D3D3D3"
                : "#656669", marginRight:80}} className="topic-text" onClick={()=>{
					onLegendClick("impressions");
			  }}>Impressions</text>
            </div>
			<div className="legend-item"  style={{ zIndex: 1, paddingRight: 25}}>
			<span
                style={{ backgroundColor: 
					!legendHidden.includes("ctr")
                  ? "#FA911D"
                  : "grey",
					}}
                className="legend-circle"
              /><text style={{ cursor: "pointer",
			  color: legendHidden.includes("ctr")
			  ? "#D3D3D3"
			  : "#656669",  marginRight:50,}} className="topic-text" id="ctr"
			  onClick={()=>{
				onLegendClick("ctr");
			}}
			  >CTR</text>
			</div>
			  </div>
              
         </div>
		</div>
		
			<Chart
				chartType="LineChart"
				width="100%"
				height="400px"
				style={{marginLeft: "-1.1rem"}}
				data={topicHubData()}
				options={options}
				chartEvents={[
					{
					  eventName: "ready",
					  callback: ({ chartWrapper}) => {
					    addChartGradient(chartWrapper);
					  }
					},
					{
					   eventName: "select",
					   callback: ({ chartWrapper}) => {
					  addChartGradient(chartWrapper);
					  legendClicked();
					}
					},
					{
						eventName: "ready",
						callback: ({ chartWrapper, google, e }) => {
							const chart = chartWrapper.getChart();
							var zoomLast = getCoords(chart);
							var observer = new MutationObserver(function () {
							  var zoomCurrent = getCoords(chart);
							  if (JSON.stringify(zoomLast) !== JSON.stringify(zoomCurrent)) {
								zoomLast = getCoords(chart);
								addChartGradient(chartWrapper);
							  }
							  clearTimeout(debouncer);
							  debouncer = setTimeout(() => {
								addChartGradient(chartWrapper); 
							  }, 300);
							});
							observer.observe(container, {
							  childList: true,
							  subtree: true
							});
						}
					}
				  ]}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		topicCharts: state.topicFetchData.topichubChart,
		isFilApplied: state.topicFilterData.isFilApplied,
		filStartDate: state.topicFilterData.startDate,
		filEndDate: state.topicFilterData.endDate,
		filterData: state.topicFilterData,
	};
};

export default connect(mapStateToProps)(TopicGraph);
