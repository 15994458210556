import React from "react";
import MultipleSelectDropDown from "../base/multiSelectDropdown";

const AdOrderPicker = (props) => {
	return (
		<MultipleSelectDropDown
			options={props.adorders.map((org, index) => ({
				key: index,
				text: org.name,
				value: org.uuid,
			}))}
			label="Ad Order"
			placeholder="Ad Order"
			onSearchChange={props.onSearchChange}
			value={props.value}
			onChange={props.onChange}
			onClick={props.onClick}
			style={props.style}
		/>
	);
};

export default AdOrderPicker;
