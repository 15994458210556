import React from "react";
import { connect } from "react-redux";
import GlobalFilter from "../components/ads/common/GlobalFilter";
import DownloadCsvXlsx from "../components/common/DownloadCsvXlsx";
import { Confirm, Message } from "semantic-ui-react";
import EmailPopUp from "../components/common/EmailPopUp";
import Details from "../components/ads/common/Details";
import { getDays } from "../utilities/common";
import * as downloadHelper from "../components/CommonFilter/downloadHelper";
import { url } from "../api/urlHelper";
import * as configName from "../components/config/const";
import { selectValue, fetchAdData } from "../actions";
import { ALL_ADS } from "../components/config/rolebasedReportNames";

const AllAds = (props) => {
    const [popup, setPopup] = React.useState(false);

    const handleCampaignSummaryXlsx = async (tableName) => {
        let days = getDays(props.filterData.startDate, props.filterData.endDate);
        if (days > 90) {
            setPopup(true);
            setTimeout(() => {
                setPopup(false);
            }, 5000);
        } else {
            downloadHelper.getCampaignExcel(props, url.adDetail, props.userEmail);
        }
    };

    return (
        <div>
            <div className="page-name-comp">
                <p className="page-name">{props.label}</p>
                <p>{ props.reportDescription}</p>
            </div>
            <GlobalFilter
                reportActionType={props.reportActionType}
                reportName={props.reportName}
                filterData={props.filterData}
            />
            <div>
                {
                    ((props?.roleReports?.some((report) => [ALL_ADS].includes(report.element) && report.permission === "view")) ||
                    (props?.roleReports?.some((report) => [ALL_ADS].includes(report.element) && report.permission === "Generate CSV"))) &&
                    <div className="table-heading-block">
                        <div className="table-name">
                            <p>{`${props.label} Details`}</p>
                        </div>
                        {
                            ((props?.roleReports?.some((report) => [ALL_ADS].includes(report.element) && report.permission === "Generate CSV")) &&
                            props.filterData.isFilApplied) &&
                            <div className="class-relative">
                                <DownloadCsvXlsx
                                    onClick={() => handleCampaignSummaryXlsx("details")}
                                    label={configName.GENERATE_CSV}
                                    disabled={!props.filterData.isFilApplied}
                                />
                                {popup &&
                                    <Confirm
                                        open={true}
                                        cancelButton={null}
                                        content={configName.DATE_RANGE_EXCEED_MESSAGE}
                                        onConfirm={() => setPopup(false)}
                                    />
                                }
                                <div className="pg popup">
                                    {
                                        props.filterData.postEmailDetails &&
                                        <EmailPopUp
                                            email={props.filterData.postEmailDetails}
                                            table="ads_common"
                                            cardPopup={props.filterData.postEmailDetails}
                                            csvExcel={true}
                                            emailKey="postEmailDetails"
                                            reportActionType={props.reportActionType}
                                        />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }
                {
                    (props?.roleReports?.some((report) => [ALL_ADS].includes(report.element) && report.permission === "view")) &&
                    <Details
                        reportActionType={props.reportActionType}
                        reportName={props.reportName}
                        filterData={props.filterData}
                    />
                }
            </div>
            {!props.filterData.isFilApplied && <Message>Please proceed with applying filter.</Message>}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userEmail: state.authorized.userEmail,
    };
};

export default connect(mapStateToProps, {
    selectValue,
    fetchAdData,
})(AllAds);