import React from "react";
import { Table } from "semantic-ui-react";
import { connect } from "react-redux";
import { sortLeadEngs } from "../../actions";
import SortIcon from "@mui/icons-material/Sort";

const TableHeader = (props) => {
  return props.headerData.map((hData, idx) => {
    return (
      hData.headerName !== "SHOWN" &&
      (hData.headerName === "SORT_ICON" ?
      <Table.HeaderCell textAlign="left" className="icon">
        <SortIcon />
      </Table.HeaderCell>
      :
      <Table.HeaderCell
        key={idx}
        className={hData.className}
        sorted={props.propData.column === hData.sorted ? props.propData.direction : null}
        onClick={() => props.propData.sortLeadEngs(hData.sorted)}
      >
        {hData.headerName}
      </Table.HeaderCell>
      )
    );
  });
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { sortLeadEngs })(TableHeader);
