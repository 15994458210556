import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import * as actionTypes from "../../../actions/actionTypes";
import { selectValue, fetchPageTitle, clearSearchForAllReport } from "../../../actions";
import SingleSelectDropDown from "../../base/singleSelectDropDown";

const PageTitleSelection = (props) => {

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    const onSearchHandler = (e) => {
		if (e.target.value.length > 1) {
            props.fetchPageTitle(
                e.target.value, 
                actionTypes[props.reportActionType].FETCH_PAGE_TITLES, 
                props.reportActionType,
                props.isWPdate ? props.filterData.wp_startDate : "",
                props.isWPdate ? props.filterData.wp_endDate : "",
            );
		} else {
			props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].RESET_PAGE_TITLES, reportType: props.reportActionType });
		}
	};

    return (
        <SingleSelectDropDown
            className="no-blink"
            clearable
            options={props?.commonSelectData?.pageTitles.map((page, index) => ({
                key: index,
                text: page.title,
                value: page.title,
            }))}
            onSearchChange={onSearchHandler}
            onChange={(e, { value }) => { dispatchDynamicSelectAction(props, "selPageTitle", value); }}
            value={props.filterData.selPageTitle}
            label="Page Title"
            placeholder="Page Title"
        />
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        commonSelectData: state.allReportCommonReducer[ownProps.reportActionType],
    };
};

export default compose(connect(mapStateToProps, {
    selectValue,
    fetchPageTitle,
    clearSearchForAllReport
}))(PageTitleSelection);