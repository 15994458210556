import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Message,
  Loader,
  Divider,
  Confirm,
  Checkbox,
  Form,
  Dropdown,
  Icon,
  Menu,
} from "semantic-ui-react";
import "../../css/common.css";
import { connect } from "react-redux";
import {
  leadEngSetPageCount,
  fetchLeadEngs,
  sortLeadEngs,
  clearLeadSort,
  showMoreLeadEngsLoading,
  selLeadEngsCheckBoxState,
  selGlobalLeadEngsStatus,
  selGlobalLeadEngsVerificationState,
  updateLeadValidation,
  fetchLeadEngSummary,
  fetchSyncValidate,
  selLeadEngFilterValue,
  sendTableHeaders,
  getTableHeaders,
  fetchClientLeadData,
  selClientLeadFilterValue,
} from "../../actions";
import { url } from "../../api/urlHelper";
import * as actionTypes from "../../actions/actionTypes";
import SingleSelectDropDown from "../base/singleSelectDropDown";
import EngagementTableRow from "./engagementTableRow";
import { options, multiOptions } from "../common/options";
import EngsTableData from "./engsTableHeaderData";
import { ENGAGEMENT_STATUS, VERIFICATION_STATUS } from "../config/TableHeaderNames";
import HeaderComponent from "./tableHeader";
import DownloadXLSX from "../common/downloadXLSX";
import NotifyEmailConfirmation from "../common/notifyEmailConfirmation";
import { numFormat } from "../../utilities/common";
import { downloadCSVData } from "../../utilities/common";
import { DOWNLOAD_CSV, GENERATE_XLSX } from "../config/const";
import { ENGAGEMENT_WORKBOOK } from "../config/rolebasedReportNames";

const LeadEngagementsTable = (props) => {
  let tableBody;
  let showMoreButton;
  let loading;
  let noresult;
  let testify = ["", "Select"];
  let excelDownloadButtonDetail;
  let leadDetailDownload;
  let mailDeliveryPopup;
  let notifyMailDelivery;

  //const [arrayRowState, setArrayRowState] = useState([]);
  const [headerChecboxChecked, setHeaderChecboxChecked] = useState(false);
  const [allChildChecked, setAllChildChecked] = useState(false);
  const [leadStatusValue, setLeadStatusValue] = useState(false);
  const [checkedLength, setCheckedLength] = useState(0);
  const [checkedItemIds, setCheckedItemIds] = useState([]);
  //const [pageCount, setPageCount] = useState(2);
  const [verificationStateOptions, setVerificationStateOptions] =
    useState([multiOptions[0]]);
  const [syncSuccess, setSyncSuccess] = useState(false);
  const syncedIdsObject = useRef([]);
  const [syncValidation, setSyncValidation] = useState(false);
  const [isSortIcon, setSortIcon] = useState(false);
  const [selectHeaders, setSelectHeaders] = useState();
  const [finalHeaderData, setFinalHeaderData] = useState();
  const [update, setUpdate] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [rowIds, setRowIds] = useState();
  const [rowUserIds, setUserIds] = useState();
  const syncedUserIdsObject = useRef([]);
  const [refreshPage, setRefreshPage] = useState(1);
  const [download, setDownload] = useState(false);

  useEffect(()=> {
    props.getTableHeaders("engagement_detail", actionTypes.GETENGSDYNAMICTABLEHEADER)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(()=> {
    const headerDataVal = props.getDynamicHeaderData;
    if(!_.isEmpty(headerDataVal)){
      setSelectHeaders(headerDataVal)
      setFinalHeaderData(headerDataVal)
    } else if(headerDataVal === undefined || _.size(headerDataVal) === 0) {
      const showFirstHeaderVal = { headerName: "SHOWN", sorted: "", className: "custom-column-medium", name: "" }
      const addLastArr = { headerName: "SORT_ICON", sorted: "", className: "icon" };
      const constantHeadersArr = [showFirstHeaderVal, ...EngsTableData, addLastArr]
      setSelectHeaders(constantHeadersArr)
      setFinalHeaderData(constantHeadersArr)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getDynamicHeaderData])

  useEffect(()=>{
    if(props.filterData.showMoreClicked && headerChecboxChecked){
      checkBoxChangedValue(true);
    } else{
      checkBoxChangedValue(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.leadEngs, props.filterData.showMoreClicked]);

  useEffect(()=>{
    if(props.filterData.fetchApiReq === true){
      props.fetchLeadEngSummary(
        url.engagementSummary,
        props.filterData,
        1,
        "",
        actionTypes.LEADENGSUMMARY
      );
      props.fetchLeadEngs(props.filterData, 1, "", actionTypes.LEADENGS);
      setHeaderChecboxChecked(false);
      setAllChildChecked(false);
      setCheckedItemIds([]);
      props.selGlobalLeadEngsStatus("Select");
      props.selGlobalLeadEngsVerificationState("Select");
      props.selLeadEngFilterValue("", actionTypes.SELENGSHEADERDROPDOWNOPTIONVALUE);
      setUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterData.fetchApiReq]);

  useEffect(()=>{
    if(props.filterData.fetchValidateReq){
      setCheckedItemIds([]);
      props.fetchLeadEngSummary(
        url.engagementSummary,
        props.filterData,
        1,
        "",
        actionTypes.LEADENGSUMMARY
      );
      props.fetchLeadEngs(props.filterData, 1, "", actionTypes.LEADENGS);
    }
    if (checkedItemIds.length > 0) {
      setSyncSuccess(true);
      setSyncValidation(false);
      setCheckedItemIds([]);
    }
    setHeaderChecboxChecked(false);
    setAllChildChecked(false);
    setCheckedItemIds([]);
    props.selGlobalLeadEngsStatus("Select");
    props.selGlobalLeadEngsVerificationState("Select");
    props.selLeadEngFilterValue("", actionTypes.SELENGSHEADERDROPDOWNOPTIONVALUE);
    props.selLeadEngFilterValue(undefined, actionTypes.FETCHENGSREQVALIDATE)
    setRefresh(false);
    setSyncSuccess(false);
    syncedIdsObject.current = [];
    syncedUserIdsObject.current = [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterData.fetchValidateReq])

  useEffect(() => {
    if(_.size(props.engDetailCsv) > 0 && download) {
      downloadCSVData(props.engDetailCsv, props.downloadFileName)
      setDownload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.engDetailCsv, props.downloadFileName,  download]);

  const handleCancel = (e) => {
    e.stopPropagation();
    setLeadStatusValue(false);
  };

  const handleConfirm = (e) => { 
    e.stopPropagation();
    setLeadStatusValue(false);
    if (
      checkedItemIds.length !== 0 &&
      !testify.includes(props.filterData.globalLeadEngsVerificationState) &&
      !testify.includes(props.filterData.globalLeadEngsStatus)
    ) {
      props.updateLeadValidation(
        props.filterData.selHeaderDropdownOptionValue === "all" ? [] : checkedItemIds,
        props.filterData.globalLeadEngsStatus,
        props.filterData.globalLeadEngsVerificationState,
        actionTypes.FETCHENGSREQ,
        props.filterData,
        props.filterData.selHeaderDropdownOptionValue,
        false
      );
      setUpdate(true)
    }
  };

  const checkBoxChangedValue = (checked, rowId) =>{
    setHeaderChecboxChecked(checked);
    setAllChildChecked(checked);
    if (checked) {
      setCheckedLength(props.leadEngs.length);
      let ids = [];
      props.leadEngs.forEach((o) => {
        syncedIdsObject.current = [
          ...syncedIdsObject.current,{
            [rowId] : o.campaign_id
          }
        ];
        syncedUserIdsObject.current = [
          ...syncedUserIdsObject.current,
          {
            [rowId]: o.user_uuid,
          },
        ];
        ids = [...ids, ...o.item_ids];
      });
      setCheckedItemIds(ids);
    } else {
      setCheckedLength(0);
      setCheckedItemIds([]);
      syncedIdsObject.current = [];
      syncedUserIdsObject.current = [];
    }
  }

  const getExcelReport = (btnSpecificAction, table_name) => {
    DownloadXLSX(
      btnSpecificAction,
      table_name,
      "engagement_detail",
      props.filterData,
      props.userEmail,
      actionTypes.LEADENGSDETAILEXCEL,
      props.fetchLeadEngs
    )
  };

  const getLeadDetailCSVData = async () => {
    setDownload(true)
    // props.clientLeadDetailCsvLoading();
    await props.fetchLeadEngs(
      props.filterData,
      1,
      "CSV",
      "",
      actionTypes.ENGAGEMENT_DETAIL_CSV,
      "",
      false
    );
  };

  if (props.isFilApplied) {
    leadDetailDownload = (
      <div>
        <button
          className="download-button"
          disabled={false}
          onClick={() => getLeadDetailCSVData()}
          style={{ marginLeft: "10px"}}
        >
          Download CSV
        </button>
      </div>
    );
    excelDownloadButtonDetail = (
      <div>
        <button
          className="download-button"
          disabled={false}
          onClick={() => getExcelReport(actionTypes.ENGAGEMENT_DETAIL_TABLE, "engagement_detail")}
        >
          Generate XLSX
        </button>
      </div>
    );
  }

  
  const setCheckedFromChild = (checked, itemIds, campaignUuid, rowId, userUuid) => {
    let l = checkedLength;
    let ids = checkedItemIds;
    if (checked) {
      l++;
      ids = [...ids, ...itemIds];
      setCheckedLength(l);
      setCheckedItemIds(ids);
      syncedIdsObject.current = [
        ...syncedIdsObject.current,{
          [rowId] : campaignUuid
        }
      ];
      syncedUserIdsObject.current = [
        ...syncedUserIdsObject.current,
        {
          [rowId]: userUuid,
        }
      ]
      setRowIds(syncedIdsObject.current)
      setUserIds(syncedUserIdsObject.current)
    } else {
      l--;
      setHeaderChecboxChecked(checked);
      setCheckedLength(l);
      ids = ids.filter((i) => {
        return !itemIds.includes(i);
      });
      syncedIdsObject.current = syncedIdsObject.current.filter((i) => Object.keys(i)[0] !== rowId)
      syncedUserIdsObject.current = syncedUserIdsObject.current.filter(
        (i) => Object.keys(i)[0] !== rowId
      );
      props.selLeadEngFilterValue("Select Option", actionTypes.SELENGSHEADERDROPDOWNOPTIONVALUE);
      setCheckedItemIds(ids);
    }
    if (props.leadEngs.length === l) {
      setHeaderChecboxChecked(true);
      props.selLeadEngFilterValue("shown", actionTypes.SELENGSHEADERDROPDOWNOPTIONVALUE);
    } else if (l === 0) {
      setAllChildChecked(false);
    }
  };

  const showMore = async () => {
    props.selLeadEngFilterValue(true, actionTypes.SHOWMOREENGSCLICKED);
    props.clearLeadSort();
    props.showMoreLeadEngsLoading();
    await props.fetchLeadEngs(
      props.filterData,
      props.PageCount,
      //pageCount,
      "",
      actionTypes.MORELEADENGSDATA,
      "",
      ""
    );
    props.leadEngSetPageCount(actionTypes.SETLEADENGDETAILPGCOUNT);
    setRefreshPage(props.PageCount)
    //setPageCount(pageCount + 1);
  };

  if (props.loading) {
    loading = (
      <div className="show-loader">
        <Loader active inline="centered" />
      </div>
    );
  }

  const createRow = (data) => {
    if (data.length > 0) {
      const getTotals = (data) => {
        let total = 0;
        data.forEach((e) => {
          total += 1;
        });
        return total;
      };
      let tableRow = [];
      const totalTableRow = [];
      const finalTotal = [];
      const totalGrandTableRow = [];
      const finalGrandTotal = [];
      data.forEach((e, id) => {
        let tableCell = [];
        finalHeaderData && finalHeaderData.map((headerD, index) => {
            let htmlData = (
              <EngagementTableRow
                key={index}
                checkedItemIds={checkedItemIds}
                setCheckedFromChild={setCheckedFromChild}
                allChildChecked={allChildChecked}
                headerChecboxChecked={headerChecboxChecked}
                setAllChildChecked={setAllChildChecked}
                leadEngsRow={e}
                checkedLength={checkedLength}
                syncSuccess ={syncSuccess}
                headerName={headerD.headerName}
                rowIds={rowIds}
                rowUserIds= {rowUserIds}
              />
            );
            return tableCell.push(htmlData);
          });
        tableRow.push(<Table.Row key={id} children={tableCell} />);
      });
      finalHeaderData && finalHeaderData.map((hData, idx) => {
          if(idx === 2){
            totalTableRow.push(<Table.Cell title="total">Total Shown: {numFormat(getTotals(props.leadEngs))}</Table.Cell>)
         &&  totalGrandTableRow.push(<Table.Cell title="Grand total">Grand Total: {numFormat(props.totalLeadCount)} </Table.Cell>) 
      }else {
            totalTableRow.push(<Table.Cell title="-">-</Table.Cell>)
         && totalGrandTableRow.push(<Table.Cell>-</Table.Cell>)
          }
          return "";
        });
      finalTotal.push(<Table.Row key={1} children={totalTableRow} />);
      finalGrandTotal.push(<Table.Row key={2} children={totalGrandTableRow} />);
      const finalData = _.concat(tableRow, finalTotal, finalGrandTotal);
      return finalData;
};
    }
  
  const clickOnSortIcon = () => {
    setSortIcon(!isSortIcon);
  };

  const clickOnSortHeader = (e, extraData, headerData) => {
    if (extraData.checked) {
      setSelectHeaders([...selectHeaders, headerData]);
    } else {
      const removeData = selectHeaders.filter(
        (hName) => hName.headerName !== headerData.headerName
      );
      setSelectHeaders(removeData);
    }
  };

  const applyButtonClick = () => {
    const removeSortIconVal = selectHeaders.filter(rowData => rowData.headerName !== "SORT_ICON");
    const addLastArr = [{ headerName: "SORT_ICON", sorted: "", className: "icon" }];
    const allElements = [].concat(removeSortIconVal, addLastArr);


    //added new funcatality accroding to sortable column
    const showFirstHeaderVal = { headerName: "SHOWN", sorted: "", className: "custom-column-medium", name: "" }
    const addLastVal = { headerName: "SORT_ICON", sorted: "", className: "icon" };
    const constantHeadersArr = [showFirstHeaderVal, ...EngsTableData, addLastVal]
    let finalHeaderArray = []
    constantHeadersArr.map((mainHeader)=> {
      return _.map(allElements, function(selectHeader) {
        if(mainHeader.headerName === selectHeader.headerName) {
          finalHeaderArray.push(selectHeader)
        }
      })
    })
    /////////////////////////////

    // setFinalHeaderData(allElements);
    // props.sendTableHeaders("engagement_detail", allElements, actionTypes.SENDENGSDYANMICTABLEHEADER)
    setFinalHeaderData(finalHeaderArray);
    props.sendTableHeaders("engagement_detail", finalHeaderArray, actionTypes.SENDENGSDYANMICTABLEHEADER)
    setSortIcon(!isSortIcon);
  };

  if (!_.isEmpty(props.leadEngs)) {
    tableBody = <Table.Body>{createRow(props.leadEngs)}</Table.Body>;
    showMoreButton = (
      <div className="table-divider">
        <Divider>
          <div className="show-more-component">
            {!props.leadFetchData.resEngsDetailsValue ? 
            (
              <button className="show-more-button" onClick={showMore}>
                Show More
              </button>
            ):(
              <button className="show-more-button-disable">
              Show More
            </button>
            )}

          </div>
        </Divider>
      </div>
    );
  } else if (
    props.isFilApplied &&
    !props.loading &&
    _.isEmpty(props.leadEngs)
  ) {
    noresult = <Message>No results found.</Message>;
  }

  const onChangeGlobalStatus = (e, { value }) => {
    const updateState = multiOptions.filter((name) => name.keyvalue === value);
    if(value !== "Select"){
      updateState.unshift({
        id: "1",
        value: "Select",
        text: "Select",
        keyvalue: "Select",
      });
    }
    setVerificationStateOptions(updateState);
    props.selGlobalLeadEngsVerificationState(options[0].value);
    if (props.filterData.globalLeadEngsStatus !== value) {
      props.selGlobalLeadEngsStatus(value);
    }
  };
  const onChangeVerificationState = (e, { value }) => {
    if (props.filterData.globalLeadEngsVerificationState !== value) {
      props.selGlobalLeadEngsVerificationState(value);
    }
  };

  const handleConfirmSync = () =>{
    setSyncValidation(false)
    setCheckedItemIds([]);
  }

  const syncValidate = () =>{
    setSyncValidation(true);
    let campaignIdUpdate = [];
    let userIdUpdate = [];
    syncedIdsObject.current.map((campId) => {
      campaignIdUpdate.push(Object.values(campId))
      return "";
    });
    syncedUserIdsObject.current.map((userUd) => {
      userIdUpdate.push(Object.values(userUd))
      return "";
    });

    const uniqueIds = campaignIdUpdate.flat();
    const updatedUniqueIds = [...new Set(uniqueIds)]

    const uniqueUserIds = userIdUpdate.flat();
    const updatedUniqueUserIds = [...new Set(uniqueUserIds)]

    checkedItemIds.length > 0 &&
      props.fetchSyncValidate(
        props.filterData,
        refreshPage,
        "",
        actionTypes.FETCHENGSREQVALIDATE,
        false,
        checkedItemIds,
        updatedUniqueIds,
        props.filterData.selHeaderDropdownOptionValue,
        props.filterData.selHeaderDropdownOptionValue === "shown" ? false : true,
        props.filterData.selHeaderDropdownOptionValue === "shown" ? true : false,
        updatedUniqueUserIds
      );
    checkedItemIds.length > 0 && props.selLeadEngFilterValue(2, actionTypes.SETENGSDETAILPGAFTERREFRESHCOUNT);
    checkedItemIds.length > 0 && setRefresh(true)
  }

  const onChangeHeaderDropdown = (e, {value}) =>{
    props.selLeadEngFilterValue(value, actionTypes.SELENGSHEADERDROPDOWNOPTIONVALUE);
    if(value === "all"){
      setHeaderChecboxChecked(true);
      setAllChildChecked(true);
      checkBoxChangedValue(true, e.target.id);
    }
    if(value === "shown"){
      setHeaderChecboxChecked(true);
      setAllChildChecked(true);
      checkBoxChangedValue(true, e.target.id);
    }
  }

  const onClickHeaderDropdown = () =>{
    document.getElementById("select_option").style.display = "none"
  }

  return (
    <div>
      <>
        <Form>
          {!_.isEmpty(props.leadEngs) && props.isFilApplied && (
            <Form.Group>
              <div  style={{ display: "inline-flex", width: "100%", float: "left" }}>
                <div>
                  <p
                    className="filter-text"
                    style={{ marginLeft: "5px", textTransform: "uppercase" }}
                  >
                    Engagement Status
                  </p>
                  <SingleSelectDropDown
                    onChange={onChangeGlobalStatus}
                    options={options}
                    value={props.filterData.globalLeadEngsStatus || options[0].value}
                    style={{ marginLeft: "10px" }}
                  />
                </div>
                <div>
                  <p
                    className="filter-text"
                    style={{ marginLeft: "15px", textTransform: "uppercase" }}
                  >
                    Verification Status
                  </p>
                  <SingleSelectDropDown
                    style={{ marginLeft: "20px" }}
                    onChange={onChangeVerificationState}
                    options={verificationStateOptions}
                    value={props.filterData.globalLeadEngsVerificationState || options[0].value}
                  />
                </div>
                <div>
                  <button
                    className="apply_basic_button"
                    style={{width: "170px", marginLeft: "50px", marginTop: "28px"}}
                    value={update}
                    onClick={(e) => {
                      e.preventDefault();
                      // if(arrayRowState.length === 0){
                      setLeadStatusValue(true);
                      e.stopPropagation();
                    }}
                  >
                    <span>Apply Updates</span>
                  </button>
                </div>
                <div>
                  <button
                    style={{marginTop: "28px", marginLeft: "20px"}}
                    value={refresh}
                    className="apply_basic_button"
                    onClick={(e) => {
                      syncValidate();
                    }}
                  >
                    <span>Refresh</span>
                  </button>
                  <br />
                  {refresh && props.filterData.messageBoxRefresh && (
                    <div 
                      className='error-message-block'>
                      <Message positive compact>
                        <p>Member and organization data for selected leads is being refreshed and filters reapplied</p>
                      </Message>
                    </div>
                  )}
                </div>
              </div>
              <Form.Group>
                {mailDeliveryPopup}
                {notifyMailDelivery}
                {checkedItemIds.length === 0 &&(
                  <Confirm
                    open={syncValidation}
                    content={checkedItemIds.length === 0 && "Please proceed by selecting at least one Engagement"}
                    cancelButton = {null}
                    confirmButton= {checkedItemIds.length === 0 && "Okay"}
                    onConfirm={handleConfirmSync}
                  />
                )
                }
              </Form.Group>
              <Confirm
                open={leadStatusValue}
                content={
                  checkedItemIds.length === 0
                    ? "Please proceed by selecting at least one Engagement"
                    : // : !props.filterData.globalLeadEngsStatus
                    testify.includes(props.filterData.globalLeadEngsStatus) &&
                      testify.includes(
                        props.filterData.globalLeadEngsVerificationState
                      )
                    ? "Please proceed by selecting Engagement Status & Verification Status"
                    : //  ? "Please proceed by selecting Lead status"
                    testify.includes(
                        props.filterData.globalLeadEngsVerificationState
                      )
                    ? "Please proceed by selecting Verification Status"
                    : "Are you sure you wanted to update the changes?"
                }
                cancelButton={
                  checkedItemIds.length !== 0 &&
                  !testify.includes(props.filterData.globalLeadEngsStatus) &&
                  !testify.includes(
                    props.filterData.globalLeadEngsVerificationState
                  )
                    ? "Cancel"
                    : null
                }
                confirmButton={
                  checkedItemIds.length !== 0 &&
                  !testify.includes(props.filterData.globalLeadEngsStatus) &&
                  !testify.includes(
                    props.filterData.globalLeadEngsVerificationState
                  )
                    ? "Update"
                    : "Okay"
                }
                onCancel={handleCancel}
                onConfirm={handleConfirm}
              />

              {/* start from here */}
              <div style={{ marginTop: "28px", float: "right", display: "inline-flex" }}>
                {props?.isFilApplied && (props?.roleReports?.some((report) => [ENGAGEMENT_WORKBOOK].includes(report.element) && report.permission === GENERATE_XLSX)) && 
                  <div className="excel-button" style={{ marginLeft: "10px" }}>
                    {excelDownloadButtonDetail}
                    {props.postEmailUpdateLeadDetail ? (
                      <NotifyEmailConfirmation
                        email={props.postEmailUpdateLeadDetail}
                        table="engagement_detail"
                        cardPopup={props.postEmailUpdateLeadDetail}
                        cardPopupClose={actionTypes.SETENGSLEADDETAILPOPUPPOSTEMAIL}
                      />
                    ) : null}
                  </div> 
                }
                {
                  props?.isFilApplied && (props?.roleReports?.some((report) => [ENGAGEMENT_WORKBOOK].includes(report.element) && report.permission === DOWNLOAD_CSV)) &&
                  <div className="csv-button">
                    {leadDetailDownload}
                  </div>
                }
              </div>
            </Form.Group>
          )}
          <div className="sorted_icon">
            <Table.HeaderCell style={{ paddingBottom: "20px" }}>
              <Icon name="edit" style={{ color: `${isSortIcon ? "#f15a29" : "#b2b3b3" }`}} size="large" onClick={clickOnSortIcon} />
            </Table.HeaderCell>
            {isSortIcon && (
              <div
                className="menu-list-items"
                style={{ height: "0px", float: "right" }}
              >
                <Menu vertical className="dynamic_table--list">
                  {Object.values(EngsTableData).map((tableHeader, index) => (
                    <div
                      key={index}
                      className={
                        tableHeader.headerName === ENGAGEMENT_STATUS ||
                        tableHeader.headerName === VERIFICATION_STATUS
                          ? "sort__header-table-list"
                          : ""
                      }
                    >
                      <Checkbox
                        className="table_header--checkbox"
                        style={{ color: "#838486" }}
                        label={tableHeader.name}
                        checked={
                          selectHeaders && selectHeaders.some(
                            (el) => el.headerName === tableHeader.headerName
                          )
                            ? true
                            : false
                        }
                        onClick={(e, data) =>
                          clickOnSortHeader(e, data, tableHeader)
                        }
                      />
                    </div>
                  ))}
                  <button className="apply_menu--btn" onClick={applyButtonClick}>
                    Apply
                  </button>
                </Menu>
              </div>
            )}
          </div>
        </Form>
      </>
      <div className="sortable-table-component" style={{ paddingTop: "30px" }}>
        <div className="sticky-table-container">
          <Table sortable fixed singleLine>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  style={{
                    overflow: "visible",
                    verticalAlign: "top",
                    paddingTop: "1px",
                    width: "15em"
                  }}
                >
                  {!_.isEmpty(props.leadEngs) && props.isFilApplied && (
                    <Form>
                      <Checkbox
                        id={props.leadEngs.uniqueId}
                        style={{
                          color: "white",
                          marginLeft: "0px",
                          marginRight: "16px",
                          paddingTop: "4px",
                        }}
                        className="calcheckbox"
                        checked={headerChecboxChecked}
                        onChange={(e, { checked }) => {
                          checkBoxChangedValue(checked, e.target.id);
                          if(checked){
                            props.selLeadEngFilterValue("shown", actionTypes.SELENGSHEADERDROPDOWNOPTIONVALUE);
                          } else{
                            props.selLeadEngFilterValue("Select Option", actionTypes.SELENGSHEADERDROPDOWNOPTIONVALUE);
                          }
                        }}
                      />
                      <Dropdown
                        style={{
                          borderColor: "#e5582b",
                          color: "#838486",
                          fontFamily: "Roboto",
                          fontSize: "12px",
                          letterSpacing: 0,
                          lineHeight: "16px",
                          border: "0px",
                          outline: "10px",
                          backgroundColor: "#edeeee",
                          marginTop: "10px",
                        }}
                        className="custom-column-medium"
                        value={props.filterData.selHeaderDropdownOptionValue}
                        onChange={onChangeHeaderDropdown}
                        onClick={onClickHeaderDropdown}
                        placeholder="Select Option"
                        options={[
                          {
                            key: "1",
                            text: "Select Option",
                            value: "Select Option",
                            id:"select_option"
                          },
                          {
                            key: "2",
                            text: "All",
                            value: "all",
                          },
                          {
                            key: "3",
                            text: "Shown",
                            value: "shown",
                          },
                        ]}
                      />
                    </Form>
                  )}
                </Table.HeaderCell>
                {
                  (!_.isEmpty(finalHeaderData)) &&
                  <HeaderComponent
                    propData={props}
                    headerData={finalHeaderData}
                  ></HeaderComponent>
                }
              </Table.Row>
            </Table.Header>
            {tableBody}
            {props.filterData.messageBox && update && (
              <div className="error-message-block">
                <Message positive compact>
                  <p>Engagement status and verification state are being updated and filters reapplied</p>
                </Message>
              </div>
            )}
            {props.filterData.fetchApiReq === '' && (
              <div className="error-message-block">
                <Message negative compact>
                  <p>Unable to update the selected engagements. An error has occurred</p>
                </Message>
              </div>
            )}
            {props.filterData.fetchApiReqRowWise && (
              <div className="error-message-block">
                <Message positive compact>
                  <p>The engagement is updated successfully</p>
                </Message>
              </div>
            )}
            {props.filterData.fetchApiReqRowWise === '' && (
              <div className="error-message-block">
                <Message negative compact>
                  <p> Unable to update the engagement. An error has occurred</p>
                </Message>
              </div>
            )}
          </Table>
        </div>
      </div>
      {noresult}
      {loading}
      {showMoreButton}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isFilApplied: state.leadFetchData.isFilApplied,
    assetType: Object.values(state.assetTypes),
    filterData: state.leadFilterData,
    leadEngs: state.leadFetchData.leadEngs,
    loading: state.leadFetchData.isLoadingLeadEngs,
    column: state.leadFetchData.column,
    direction: state.leadFetchData.direction,
    PageCount: state.leadFetchData.detailPageCount,
    getDynamicHeaderData: state.leadFetchData.getDynamicHeaderData,
    sendDynamicHeaderArray: state.leadFetchData.dynamicHeaderArray,
    leadFetchData: state.leadFetchData,
    totalLeadCount: state.leadFetchData.totalLeadCount,
    userEmail: state.authorized.userEmail,
    postEmailUpdateLeadDetail: state.leadFetchData.postEmailUpdateLeadDetail,
    engDetailCsv: state.leadFetchData.engDetailCsv,
    mailDelivery: state.leadFetchData.mailDelivery,
    downloadFileName: state.leadFetchData.downloadFileName
  };
};

export default connect(mapStateToProps, {
  fetchLeadEngs,
  sortLeadEngs,
  clearLeadSort,
  showMoreLeadEngsLoading,
  leadEngSetPageCount,
  selLeadEngsCheckBoxState,
  selGlobalLeadEngsStatus,
  selGlobalLeadEngsVerificationState,
  updateLeadValidation,
  fetchLeadEngSummary,
  fetchSyncValidate,
  selLeadEngFilterValue,
  sendTableHeaders,
  getTableHeaders,
  fetchClientLeadData,
  selClientLeadFilterValue,
})(LeadEngagementsTable);
