import axios from "../api/axios";
import { url } from "../api/urlHelper";
import * as actionTypes from "./actionTypes";
import { headers, auth } from "../api/apiConstants";
import { getCookie, removeCookies } from "../utilities/envCommon";
import moment from "moment";
import { ALL_MODULES } from "./actionTypes";

const setCookies = (data, expiryDate, pathName) => {
    document.cookie = `token=${data.token}; expires=${expiryDate}; path=/`;
    document.cookie = `userId=${data.user.ID}; expires=${expiryDate}; path=/`;
    document.cookie = `expiry=${expiryDate.unix()}; expires=${expiryDate}; path=/`;
    if(pathName === `/admin/oauth/callback`) {
        document.cookie = `userName=${data.user.display_name}; expires=${expiryDate}; path=/`;
        document.cookie = `userEmail=${data.user.user_email}; expires=${expiryDate}; path=/`; 
    } else if(pathName === `/okta/oauth/callback`) {
        document.cookie = `userName=${data.user.first_name + " " + data.user.last_name}; expires=${expiryDate}; path=/`;
        document.cookie = `userEmail=${data.user.email}; expires=${expiryDate}; path=/`;
    }
    
};

const handleError = (err, dispatch) => {
    if (err.response && err.response.status !== 403) {
        dispatch({ type: actionTypes.ERRORCODE, payload: err.response.status });
    }
};

export const getAuthToken = (searchParam, pathName) => async (dispatch) => {
    let isWordPress = true;
    let pagePath = `/admin/oauth/callback`;
    if(pathName === `/okta/oauth/callback`){
        pagePath = pathName;
        isWordPress = false;
    }
    try {
        const res = await axios.get(`${isWordPress ? url.wordPressCallbackURL : url.oktaCallbackURL}${searchParam}`, { auth });
        const expiryDate = moment.utc(res.data.expiry * 1000);
        setCookies(res.data, expiryDate, pagePath);
        dispatch({ type: actionTypes.AUTHLOGIN, payload: res.data, isWordPress });
    } catch (err) {
        handleError(err, dispatch);
    }
};

export const checkAuth = () => (dispatch) => {
    const token = getCookie("token");
    if (!token) {
        dispatch({ type: actionTypes.AUTHLOGOUT });
    } else {
        dispatch({
            type: actionTypes.ISLOGGEDIN,
            token,
            userId: getCookie("userId"),
            userName: getCookie("userName"),
            userEmail: getCookie("userEmail"),
        });
    }
};

export const saveProfileAction = (payload) => ({
    type: actionTypes.PROFILE_ACTIONS,
    payload
});

export const selectRoleValues = (payload) => ({
    type: actionTypes.SELECT_ROLE_VALUES,
    payload
})

export const addUserList = (id, dispatchType) => {
    return {
      type: dispatchType,
      payload: id,
    };
};

export const appLogout = () => (dispatch) => {
    removeCookies();
    dispatch({ type: actionTypes.AUTHLOGOUT });
};

const makeRequest = async (url, dispatchType, method, dispatch) => {
    try {
        const response = await axios({ method, url, headers: headers() });
        dispatch({ type: dispatchType, payload: response.data, status: response.status === 200 });
    } catch (err) {
        handleError(err, dispatch);
    }
};

export const clearCampaignCache = (url, dispatchType) => async (dispatch) => {
    await makeRequest(url, dispatchType, 'get', dispatch);
};

export const clearCacheAPI = (url, dispatchType) => async (dispatch) => {
    await makeRequest(url, dispatchType, 'delete', dispatch);
};

export const fetchConstants = () => async (dispatch) => {
    try {
        const res = await axios.get(url.filterConstants, { headers: headers(), auth });
        const { data } = res;
        const deliveryData = data.delivery_state_constant || [];
        const deliveryStatusOpt = deliveryData?.delivery_states;

        dispatch({ type: actionTypes.FETCHASSETTYPES, payload: data });
        dispatch({ type: actionTypes.FETCHSITES, payload: data });
        dispatch({ type: actionTypes.FETCHSTATUSES, payload: data });
        dispatch({ type: actionTypes.FETCHLEADPARTNER, payload: data });
        dispatch({ type: actionTypes.FETCHLOCATIONS, payload: data });
        dispatch({ type: actionTypes.WPLINK, payload: data.config });
        dispatch({ type: actionTypes.FETCHSENIORITIES, payload: data.member_constant });
        dispatch({ type: actionTypes.FETCHJOBFUNCTIONS, payload: data.member_constant });
        dispatch({ type: actionTypes.FETCHFIRMOGRAPHICORSTYPE, payload: data.firmographic_constant });
        dispatch({ type: actionTypes.FETCHFIRMOGRAPHICORSSIZE, payload: data.firmographic_constant });
        dispatch({ type: actionTypes.FETCHFIRMOGRAPHICORSREVENUE, payload: data.firmographic_constant });
        dispatch({ type: actionTypes.FETCHFIRMOGRAPHICSECTOR, payload: data.firmographic_constant });
        dispatch({ type: actionTypes.FETCHFIRMOGRAPHICINDUSTRYGROUP, payload: data.firmographic_constant });
        dispatch({ type: actionTypes.FETCHFIRMOGRAPHICINDUSTRY, payload: data.firmographic_constant });
        dispatch({ type: actionTypes.FETCHFIRMOGRAPHICSUBINDUSTRY, payload: data.firmographic_constant });
        dispatch({ type: actionTypes.GET_LIST_DATA, payload: data });

        ALL_MODULES.forEach(module => {
            dispatch({
                type: actionTypes[module].SELECTVALUE,
                payload: { dispatchType: actionTypes[module].SELECTVALUE, reportType: module, key: "deliverdFilterStateOptions", value: deliveryStatusOpt },
            });
            
            dispatch({
                type: actionTypes[module].SELECTVALUE,
                payload: { dispatchType: actionTypes[module].SELECTVALUE, reportType: module, key: "newslettersOptions", value: data.newsletters },
            });

            dispatch({
                type: actionTypes[module].SELECTVALUE,
                payload: { dispatchType: actionTypes[module].SELECTVALUE, reportType: module, key: "dayOfWeekOptions", value: data.pageviews_config },
            });
        });

        dispatch({ type: actionTypes.FETCHDELIVERYSTATE, payload: deliveryData });
        dispatch({ type: actionTypes.SET_ACTIVITY_ENGAGEMENT, payload: data.activity_engaged_actions });
        dispatch({ type: actionTypes.PAGEVIEW_CONFIG_LIST, payload: res.data.pageviews_config })
    } catch (err) {
        handleError(err, dispatch);
    }
};

export const fetchFilterProfile = (data) => async (dispatch) => {
    try {
        const res = await axios.post(url.getUserFilterProfile, { user_id: data.userId }, { headers: headers() });
        dispatch({ type: actionTypes.GET_ALL_FILTER_PROFILES, payload: res.data });
        return res.data;
    } catch (err) {
        handleError(err, dispatch);
    }
};

export const addFilterProfile = (data) => async (dispatch) => {
    try {
		const userID = data.userData.userId;
        await axios.post(url.addUserFilterProfile, { user_id: parseInt(userID), session_name: data.sessionName }, { headers: headers() });
		await dispatch(fetchFilterProfile(data.userData));
    } catch (err) {
        handleError(err, dispatch);
    }
};

export const editFilterProfile = (data) => async (dispatch) => {
    try {
		const userID = data.userData.userId;
        await axios.post(url.editUserFilterProfile, { user_id: parseInt(userID), session_name: data.sessionName, session_id: data.sessionId,}, 
		{ headers: headers() });
		await dispatch(fetchFilterProfile(data.userData));
    } catch (err) {
        handleError(err, dispatch);
    }
};

export const deleteFilterProfile = (data) => async (dispatch) => {
    try {
        await deleteActionApiRequest('DELETE', url.deleteUserFilterProfile, { session_id: data.sessionId }, dispatch );
        await dispatch(fetchFilterProfile(data.userData));
    } catch (err) {
        handleError(err, dispatch);
    }
};

const deleteActionApiRequest = async (method, url, data, dispatch) => {
    try {
      await axios({ method, url, data, headers: headers() });
    } catch (error) {
      handleError(dispatch)(error);
    }
};

export const userSharedFilterProfile = (data) => async (dispatch) => {
    try {
        await axios.post(url.sharedUserFilterProfile, { shared_user_id: data.userId, session_id: data.sessionId }, { headers: headers() });
        // dispatch({ type: actionTypes.GET_ALL_SHARED_FILTER_PROFILES, payload: res.data });
    } catch (err) {
        handleError(err, dispatch);
    }
};

export const fetchUserSharedFilterProfile = (data) => async (dispatch) => {
    try {
        const res = await axios.post(url.getUserSharedFilterProfile, { session_id: data.sessionId }, { headers: headers() });
        dispatch({ type: actionTypes.SELECTED_SHARED_USER_LIST, payload: res.data });
        dispatch({ type: actionTypes.SET_SHARED_USERS, payload: res.data });
    } catch (err) {
        handleError(err, dispatch);
    }
};

export const loginWithOktaAPI = (data) => async (dispatch) => {
    try {
        const res = await axios.get(url.loginWithOkta);
        // dispatch({ type: actionTypes.LOG_IN_WITH_OKTA, payload: res.data });
        window.open(res.data, "_self");
    } catch (err) {
        handleError(err, dispatch);
    }
}

export const loginWithWordPressAPI = (data) => async (dispatch) => {
    try {
        const res = await axios.get(url.loginWithWordPress);
        // dispatch({ type: actionTypes.LOG_IN_WITH_WORDPRESS, payload: res.data });
        window.open(res.data, "_self");
    } catch (err) {
        handleError(err, dispatch);
    }
}

export const toggleNestedMenu = (menuName) => ({
    type: actionTypes.TOGGLE_NESTED_MENU,
    payload: menuName,
});

export const getAllReportsAPI = () => async (dispatch) => {
    try {
        const res = await axios.post(url.getAllReports, {}, { headers: headers() });
        dispatch({ type: actionTypes.GET_ALL_REPORTS, payload: res.data });
    } catch (err) {
        handleError(err, dispatch);
    }
}

export const getCurrentUserRole = () => async (dispatch) => {
    try {
        const res = await axios.get(url.getCurrentUserRoleAPI, { headers: headers() });
        dispatch({ type: actionTypes.GET_CURRENT_USER_ROLE, payload: res.data });
        return res.data;
    } catch (err) {
        handleError(err, dispatch);
    }
}

export const getAllRoles = () => async (dispatch) => {
    try {
        const res = await axios.post(url.getAllRolesAPI, {}, { headers: headers() });
        dispatch({ type: actionTypes.GET_ALL_ROLES, payload: res.data });
    } catch (err) {
        handleError(err, dispatch);
    }
}

//saveRoleBaseReportAPI
export const saveRoleBasedReportAction = (data, idsArray) => async (dispatch) => {
    try {
        const res = await axios.post(url.saveRoleBaseReportAPI, data, { headers: headers() });
        await dispatch(getRoleReportDetails(idsArray));
        dispatch({ type: actionTypes.SAVE_ROLE_BASED_REPORT, payload: res.data });
    } catch (err) {
        handleError(err, dispatch);
    }
}

export const getRoleReportDetails = (idsArray) => async (dispatch) => {
    try {
        const res = await axios.post(url.getRoleReportDetailsAPI, { role_id: idsArray }, { headers: headers() });
        dispatch({ type: actionTypes.GET_ROLE_REPORT_DETAILS, payload: res.data });
    } catch (err) {
        handleError(err, dispatch);
    }
}
export const fetchReportDescription = () => async (dispatch) => {
    try {
        const res = await axios.get(url.getReportDescription, { headers: headers() });
        dispatch({ type: actionTypes.GET_REPORT_DESCRIPTION, payload: res.data });
    } catch (err) {
        handleError(err, dispatch);
    }
};

export const saveReportDescription = (data) => async (dispatch) => {
    try {
        const res = await axios.post(url.saveReportDescription, data, { headers: headers() });
        dispatch({ type: actionTypes.POST_REPORT_DESCRIPTION, payload: res.status});
    } catch (err) {
        handleError(err, dispatch);
    }
};

export const updateReportDescription = (data) => (dispatch) => {
    dispatch({ type: actionTypes.GET_REPORT_DESCRIPTION, payload: data });
};
