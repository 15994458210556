import React from "react";
import CreateTableRowComponent from "./CreateTableRowComponent";

const UTMParametersSection = ({ data }) => {
   const utmData = [
      {
         name: "UTM SOURCE",
         value: data.utm_source
      },
      {
         name: "UTM MEDIUM",
         value: data.utm_medium
      },
      {
         name: "UTM CAMPAIGN",
         value: data.utm_campaign
      },
      {
         name: "UTM TERM",
         value: data.utm_term
      },
      {
         name: "UTM CONTENT",
         value: data.utm_content
      }
   ]
   return (
      <React.Fragment>
         {
            utmData.map((utm, index) => {
               return <CreateTableRowComponent key={index} name={utm.name} value={utm.value} />
            })
         }
      </React.Fragment>
   );
};

export default UTMParametersSection;
