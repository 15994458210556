import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Table } from "semantic-ui-react";
import { getUnescapeStr, numFormat } from "../../../utilities/common";
import Link from "../../hyperlinks/link";

const CommonRowData = (props) => {
  const [e, setE] = useState([]);
  const [tableCellData, setTableCellData] = useState([]);
  const { 
    tableName
  } = props;

  useEffect(() => {
    setE(props.topicRow);
  }, [props.topicRow]);

  useEffect(() => {
    getTableCellRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [e, props.headerName]);

  const getTableCellRows = () => {
    let tableCell = [];

    const renderTagCell = (data, tagType) => {
      if (data.tag !== "N/A") {
        return (
          <Table.Cell title={data.tag} className="blogs_link space_height">
            <Link type={tagType} urlParam={data.slug} refValue={getUnescapeStr(data.tag)} domain={data.domain}></Link>
          </Table.Cell>
        );
      } else {
        return <Table.Cell>N/A</Table.Cell>;
      }
    };

    if(props.headerName.indexOf("TOPIC TAGS") !== -1) {
      tableCell.push(renderTagCell(e, "topic"));
    }

    if(props.headerName.indexOf("ORGANIZATION TAGS") !== -1) {
      tableCell.push(renderTagCell(e, "orgs"));
    }

    if(props.headerName.indexOf("PLACEMENT TAGS") !== -1) {
      tableCell.push(renderTagCell(e, "placement"));
    }

    if(props.headerName.indexOf("AUTHOR NAME") !== -1) {
      tableCell.push(<Table.Cell title={e.author_name}>{e.author_name}</Table.Cell>)
    }

    if (props.headerName.indexOf("SITES") !== -1) {
      const site = tableName === "author" ? e.display_domain : e.site
      tableCell.push(
            <Table.Cell title={site}>{site}</Table.Cell>
      );
    }

    if (props.headerName.indexOf("TOTAL PAGEVIEWS BY TAG") !== -1 || props.headerName.indexOf("TOTAL PAGEVIEWS BY AUTHOR") !== -1) {
      const pageviews = tableName === "author" ? e.pageviews : e.page_views;
      props.filterData.selCutomRangeChkBox === true &&
      props.filterData.checkboxState === true
        ? tableCell.push(
            <Table.Cell className="space_height" textAlign="center" title={numFormat(pageviews)}>{numFormat(pageviews)}</Table.Cell>,
            <Table.Cell className="space_height" textAlign="center" title={numFormat(e.previous_period_pageviews)}>{numFormat(e.previous_period_pageviews)}</Table.Cell>
          )
        : tableCell.push(
            <Table.Cell className="space_height" textAlign="right" title={numFormat(pageviews)}>{numFormat(pageviews)}</Table.Cell>
          );
    }

    if (props.headerName.indexOf("UNIQUE PAGEVIEWS BY TAG") !== -1 || props.headerName.indexOf("UNIQUE PAGEVIEWS BY AUTHOR") !== -1) {
      const uniquePageviews = tableName === "author" ? e.unique_pageviews : e.unique_impressions_count
      props.filterData.selCutomRangeChkBox === true &&
      props.filterData.checkboxState === true
        ? tableCell.push(
          <Table.Cell className="space_height" textAlign="center" title={numFormat(uniquePageviews)}>{numFormat(uniquePageviews)}</Table.Cell>,
          <Table.Cell className="space_height" textAlign="center" title={numFormat(e.previous_period_unique_pageviews)}>{numFormat(e.previous_period_unique_pageviews)}</Table.Cell>  
        )
        : tableCell.push(
            <Table.Cell className="space_height" textAlign="right" title={numFormat(uniquePageviews)}>
              {numFormat(uniquePageviews)}
            </Table.Cell>
          );
    }

    if(tableName === "author") {
      if(props.headerName.indexOf("PUBLISHED BY AUTHOR") !== -1) {
        props.filterData.selCutomRangeChkBox === true &&
        props.filterData.checkboxState === true
          ? tableCell.push(
            <Table.Cell className="space_height" textAlign="center" title={numFormat(e.published_articles)}>{numFormat(e.published_articles)}</Table.Cell>,
            <Table.Cell className="space_height" textAlign="center" title={numFormat(e.prev_published_articles)}>{numFormat(e.prev_published_articles)}</Table.Cell>  
          )
          : tableCell.push(
              <Table.Cell className="space_height" textAlign="right" title={numFormat(e.published_articles)}>
                {numFormat(e.published_articles)}
              </Table.Cell>
            );
      }
      if(props.headerName.indexOf("PAGEVIEWS PER ARTICLE") !== -1) {
        props.filterData.selCutomRangeChkBox === true &&
        props.filterData.checkboxState === true
          ? tableCell.push(
            <Table.Cell className="space_height" textAlign="center" title={numFormat(e.average_pageviews_by_article)}>{numFormat(e.average_pageviews_by_article)}</Table.Cell>,
            <Table.Cell className="space_height" textAlign="center" title={numFormat(e.previous_period_average_pageviews_by_article)}>{numFormat(e.previous_period_average_pageviews_by_article)}</Table.Cell>  
          )
          : tableCell.push(
              <Table.Cell className="space_height" textAlign="right" title={numFormat(e.average_pageviews_by_article)}>
                {numFormat(e.average_pageviews_by_article)}
              </Table.Cell>
            );
      }
    }

    setTableCellData(tableCell);
  };
  return tableCellData;
};

const mapStateToProps = (state) => {
  return {
    filterData: state.editorialFilterData,
    isFilApplied: state.editorialFetchDetailData.isFilApplied,
  };
};

export default connect(mapStateToProps, {})(CommonRowData);
