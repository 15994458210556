import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { fetchData } from "../actions";
import "../css/common.css";
import ProductivityDetails from "../components/Productivity/ProductivityDetails";
import SearchProductivity from "../components/Productivity/productivityFilter";
import { getReportsByElement } from "../utilities/common";
import { GOALS, ROLEBASED_ELEMENT_GOALS } from "../components/config/rolebasedReportNames";
import { VIEW } from "../components/config/const";

class Productivity extends React.Component {
   render() {
      const roleReports = getReportsByElement(this?.props?.roleFilterData?.currentUserReportRoleData, this?.props?.roleFilterData?.getAllReports, ROLEBASED_ELEMENT_GOALS);

      return (
         <React.Fragment>
            <div className="page-name-comp">
               <p className="page-name">Goal Report</p>
               <p>{this.props.reportDescription.find(report => report.name === "Goals")?.description }</p>
            </div>
            <SearchProductivity sendDataToParent={this.receiveChildData} />
            {
               (roleReports?.some((report) => [GOALS].includes(report.element) && report.permission === VIEW)) &&
               <React.Fragment>
                  <div>
                     <div className="table-heading-block">
                        <div className="table-name">
                           <p>Goal Report {this.props.productivityData && this.props.productivityData.label}</p>
                        </div>
                     </div>
                  </div>
                  <ProductivityDetails/>
               </React.Fragment>
            }
         </React.Fragment>
      );
   }
}

const mapStateToProps = (state) => {
   return {
      productivityData: state.ProductivityFilterData.productivityData,
      reportDescription: state.allFilterReportData.reportDescription,
      roleFilterData: state.allFilterReportData,
   };
};

export default compose(connect(mapStateToProps, 
   { fetchData })
)(Productivity);
