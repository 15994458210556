import React from 'react';

export const Logo = (props) => (
  <svg
    className='logo'
    enableBackground='new 0 0 109 16'
    viewBox='0 0 109 16'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipRule='evenodd' fillRule='evenodd'>
      <path
        d='m39 10.6c0-3 2.3-5.5 5.4-5.5 2 0 3.2.8 4.2 1.9l-1 1.1c-.8-.9-1.8-1.6-3.2-1.6-2.1 0-3.8 1.8-3.8 4 0 2.3 1.7 4.1 3.9 4.1 1.3 0 2.4-.7 3.2-1.6l1 .9c-1.1 1.2-2.3 2-4.3 2-3.1.1-5.4-2.4-5.4-5.3zm19.7-.6c-.2-1.9-1.3-3.6-3.4-3.6-1.8 0-3.2 1.5-3.4 3.6zm-8.4.6c0-3.1 2.1-5.5 5-5.5 3.1 0 4.9 2.5 4.9 5.5v.5h-8.3c.2 2.3 1.8 3.5 3.7 3.5 1.4 0 2.5-.6 3.3-1.5l1 .9c-1.1 1.2-2.3 2-4.3 2-2.9 0-5.3-2.2-5.3-5.4zm12.7-5.3h1.6v1.8c.7-1.1 1.8-2 3.6-2 2.5 0 4 1.7 4 4.2v6.5h-1.6v-6.1c0-1.9-1.1-3.2-2.9-3.2s-3.2 1.3-3.2 3.3v6h-1.5zm13.1 7.7v-6.3h-1.5v-1.4h1.5v-3.1h1.6v3.2h3.3v1.4h-3.3v6.1c0 1.3.7 1.7 1.8 1.7.5 0 1-.1 1.5-.4v1.3c-.5.3-1.1.4-1.9.4-1.7 0-3-.8-3-2.9zm7.7-7.7h1.6v2.7c.8-1.7 2.3-3 4.3-2.9v1.7h-.1c-2.3 0-4.2 1.6-4.2 4.8v4.2h-1.6zm14.7 6.6v-1c-.8-.2-1.8-.4-3.1-.4-2 0-3.1.8-3.1 2.2 0 1.3 1.2 2.1 2.6 2.1 2-.1 3.6-1.2 3.6-2.9zm-7.8.8c0-2.2 1.8-3.4 4.5-3.4 1.3 0 2.3.2 3.2.4v-.3c0-1.9-1.2-2.8-3.1-2.8-1.2 0-2.2.3-3.2.8l-.5-1.4c1.2-.5 2.3-.9 3.8-.9s2.6.4 3.4 1.2c.8.7 1.2 1.7 1.2 3.1v6.4h-1.5v-1.6c-.7.9-2 1.8-3.8 1.8-2 0-4-1.1-4-3.3zm12.7 3.1v-14.8h1.6v14.8z'
        fill='#3e3f42'
      />
      <path
        d='m106 1.5c0-.3.1-.5.2-.8s.3-.4.5-.5.5-.2.8-.2.5.1.8.2.4.3.5.5.2.5.2.8-.1.5-.2.8c-.1.2-.3.4-.5.5-.3.1-.5.2-.8.2s-.5-.1-.8-.2c-.2-.1-.4-.3-.5-.5s-.2-.5-.2-.8zm2.7 0c0-.2-.1-.4-.2-.6s-.3-.3-.4-.5c-.2-.1-.4-.2-.6-.2s-.4.1-.6.2-.3.3-.5.5c-.1.2-.2.4-.2.6s.1.4.2.6.3.3.5.5c.2.1.4.2.6.2s.4-.1.6-.2.3-.3.5-.5c.1-.2.1-.4.1-.6zm-1.5.2v.7h-.3v-1.8h.6c.2 0 .4 0 .5.1s.2.2.2.4c0 .1-.1.3-.2.3.1 0 .1.1.2.2 0 .1.1.2.1.3v.2s0 .1 0 .1h-.3s0-.2 0-.4c0-.1 0-.2-.1-.2s-.1-.1-.2-.1h-.5zm0-.3h.3c.1 0 .2 0 .3-.1.1 0 .1-.1.1-.2s0-.2-.1-.2-.1-.1-.3-.1h-.3z'
        fill='#3e3f42'
      />
      <path
        d='m0 14.1 1.4-2.1c1.2.9 2.5 1.4 3.6 1.4.9 0 1.4-.3 1.4-.9 0-.7-1.1-.9-2.4-1.3-1.6-.5-3.4-1.2-3.4-3.5 0-2.4 1.9-3.7 4.2-3.7 1.5 0 3 .5 4.3 1.3l-1.3 2.3c-1.1-.7-2.3-1.1-3.1-1.1s-1.2.3-1.2.8c0 .6 1.1.9 2.3 1.4 1.6.5 3.5 1.3 3.5 3.4 0 2.6-1.9 3.7-4.4 3.7-1.6.1-3.4-.5-4.9-1.7zm19.4-4.2c0-2-1.3-3.2-2.8-3.2s-2.8 1.2-2.8 3.2c0 1.9 1.3 3.2 2.8 3.2s2.8-1.3 2.8-3.2zm-8.8 0c0-3.9 2.5-6 5.2-6 1.7 0 2.8.8 3.6 1.7v-5.6h3.2v15.7h-3.2v-1.7c-.8 1.1-1.9 1.9-3.6 1.9-2.7 0-5.2-2.1-5.2-6zm26.4-3.6c0 .6-.2 1.1-.6 1.5s-.9.6-1.5.6c-.3 0-.7 0-1-.1-.3-.2-.6-.3-.9-.4-.1.1-.3.3-.5.5-.2.3-.5.6-.8 1 .3 1.1.5 1.9.6 2.4.2.5.3.9.5 1.4.1.4.3.7.5.9s.6.3 1.1.3c.2 0 .4 0 .7-.1s.5-.1.7-.2l-.2 1c-.7.3-1.3.5-1.9.7-.5.1-1 .2-1.4.2-.3 0-.6 0-.9-.1s-.6-.2-.8-.3c-.3-.2-.5-.4-.7-.6s-.3-.6-.5-1c-.1-.3-.2-.6-.2-.9-.1-.3-.1-.6-.2-.9-.2.3-.4.5-.5.7s-.2.4-.4.6c-.6.9-1.1 1.5-1.5 1.9s-.9.6-1.5.6c-.4 0-.8-.1-1.1-.4s-.5-.7-.5-1.2c0-.6.2-1.2.6-1.5.4-.4.9-.6 1.5-.6.3 0 .7 0 1 .1s.6.2.9.3c.1-.1.3-.3.5-.5.2-.3.5-.6.7-.9-.2-1-.4-1.7-.6-2.2s-.3-1-.5-1.5c-.1-.4-.3-.7-.6-.9-.2-.2-.6-.3-1.1-.3-.2 0-.5 0-.7.1-.3.1-.5.1-.7.2l.2-1c.7-.3 1.3-.5 1.9-.7.6-.1 1.1-.2 1.5-.2s.7 0 .9.1c.3.1.5.2.8.3.3.2.5.4.7.6s.3.6.5 1c.1.3.2.6.2.9.1.3.1.6.2.8.3-.3.4-.5.6-.7s.3-.5.4-.7c.6-.9 1.1-1.5 1.5-1.9s.9-.6 1.5-.6c.5 0 .8.2 1.1.5s.5.7.5 1.2z'
        fill='#f15a29'
      />
    </g>
  </svg>
);
