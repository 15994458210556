import React from "react";
import { Table } from "semantic-ui-react";
import Link from "../../../hyperlinks/link";

const NewsletterTemplateComponent = ({ data }) => {
    return (
        (data.template_uuid === "N/A" || data.template_uuid === "") ?
            <Table.Cell className="space_height"> {"N/A"}</Table.Cell> 
            :
            <Table.Cell title={data.template} className="blogs_link space_height">
                {data.template_uuid ? (
                    <Link
                        type={"groups"}
                        urlParam={data.template_uuid}
                        refValue={data.template}
                    ></Link>
                ) : (
                    ""
                )}
            </Table.Cell>
    );
};

export default NewsletterTemplateComponent;
