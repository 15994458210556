import React from "react";
import { connect } from "react-redux";
import AdsPage from "./../adsPage";
import * as constName from "./../../components/config/const";
import { getReportsByElement } from "../../utilities/common";
import { ROLEBASED_ELEMENT_SIDEBAR_ADS } from "../../components/config/rolebasedReportNames";

const AdsSidebar = (props) => {
    const sidebarAdsReports = getReportsByElement(props?.roleFilterData?.currentUserReportRoleData, props?.roleFilterData?.getAllReports, ROLEBASED_ELEMENT_SIDEBAR_ADS);

    return (
        <AdsPage
            reportActionType={constName.MODULE_SIDEBAR_ADS}
            reportName={constName.SIDEBAR_ADS_REPORT_NAME}
            filterData={props.filterData}
            label={constName.SIDEBAR_ADS_LABEL}
            roleReports={sidebarAdsReports}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        filterData: state.commonFilterData[constName.MODULE_SIDEBAR_ADS],
        roleFilterData: state.allFilterReportData,
    };
};

export default connect(mapStateToProps)(AdsSidebar);