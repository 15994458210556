import React, { useEffect} from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import * as actionTypes from "../../../actions/actionTypes";
import { fetchSegments, selectValue, addSelectedValue, clearSearchForAllReport } from "../../../actions";
import MultipleSelectDropDown from "../../base/multiSelectDropdown";
import _ from "lodash";

const Segements = (props) => {

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    useEffect(() => { 
        if (!_.isEmpty(props.filterData.organizationSingleSelect) || !_.isEmpty(props.filterData.campaign)
            || !_.isEmpty(props.filterData.order)
        ) {
            props.fetchSegments(
                props.filterData.organizationSingleSelect,
                props.filterData.campaign,
                props.filterData.order,
                actionTypes[props.reportActionType].FETCH_SEGMENTS,
                props.reportActionType
            );
        } else { 
            dispatchDynamicSelectAction(props, "segmentsArray", []);
            props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].CLEAR_SEGMENTS, reportType: props.reportActionType });  
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filterData.organizationSingleSelect, props.filterData.campaign, props.filterData.order]);

    const onSearchHandler = (e) => {
        if (e.target.value.length > 1) {
            const filteredSegments = props.filterData.segmentsArray.filter(segment =>
                segment.name.toLowerCase().includes(e.target.value.toLowerCase()) // Filter based on the 'name' field
            );

            dispatchDynamicSelectAction(props, "segmentsArray", filteredSegments);
        }
    };

    const onSelectHandler = (e, { value }) => {
        dispatchDynamicSelectAction(props, "segmentsArray", value);
        if (!_.isEmpty(value)) {
            props.addSelectedValue({ dispatchType: actionTypes[props.reportActionType].ADD_SEGMENTS, reportType: props.reportActionType, value });
        } else {
            dispatchDynamicSelectAction(props, "segmentsArr", props?.commonSelectData?.segmentsArr);
        }
    };

    return (
        <MultipleSelectDropDown
            label='Segments'
            placeholder='Segments'
            options={props?.commonSelectData?.segmentsArr?.map((org, index) => ({
                key: index,
                text: org.name,
                value: org.uuid,
            }))}
            onSearchChange={onSearchHandler}
            value={props.filterData.segmentsArray}
            onChange={onSelectHandler}
            // onClick={onClickHandler}
        />
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        commonSelectData: state.allReportCommonReducer[ownProps.reportActionType],
    };
};

export default compose(connect(mapStateToProps, {
    fetchSegments,
    selectValue,
    addSelectedValue,
    clearSearchForAllReport
}))(Segements);