import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { DELETE_CONFIRMATION_POPUP_TEXT } from '../config/const';

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});

export const PopupMessage = (props) => {
   const {
      isOpen,
      handleAction,
      handleCancel
   } = props;
   return (
      <div>
         <Dialog
            open={isOpen}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
         >
            <DialogContent>
               <div className='set_font-for_button set_text'>{DELETE_CONFIRMATION_POPUP_TEXT}</div>
            </DialogContent>
            <DialogActions>
               <button className='set_confirmation-cancel' onClick={handleCancel}>No</button>
               <button className='set_confirmation-submit' onClick={handleAction}>Yes</button>
            </DialogActions>
         </Dialog>
      </div>
   );
}
