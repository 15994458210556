import React from 'react';
import MultipleSelectDropDown from '../base/multiSelectDropdown';

const CampaignPicker = (props) => {
  return (
	<MultipleSelectDropDown
    options={props.campaigns.map((org, index) => ({
      key: index,
      text: org.name,
      value: org.uuid,
    }))}
    label='Campaign'
    placeholder='Campaign'
    onSearchChange={props.onSearchChange}
    value={props.value}
    onChange={props.onChange}
    onClick={props.onClick}
  />
  )
};

export default CampaignPicker;
