import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import * as actionTypes from "../../../actions/actionTypes";
import { selectValue, fetchUrlParams, clearSearchForAllReport } from "../../../actions";
import SingleSelectDropDown from "../../base/singleSelectDropDown";

const UTMSourceSelection = (props) => {

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    const onSearchHandler = (e) => {
		if (e.target.value.length > 1) {
            props.fetchUrlParams(
                e.target.value,
                "source",
                actionTypes[props.reportActionType].FETCH_UTM_SOURCES,
                "",
                "pageview",
                props.reportActionType
            );
		} else {
			props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].RESET_UTM_SOURCES, reportType: props.reportActionType });
		}
	};

    return (
        <SingleSelectDropDown
            className="no-blink"
            clearable
            options={props?.commonSelectData?.utmSources.map((s, index) => ({
                key: index,
                text: s.source,
                value: s.source,
            }))}
            onSearchChange={onSearchHandler}
            onChange={(e, { value }) => { dispatchDynamicSelectAction(props, "selUrlSource", value); }}
            value={props.filterData.selUrlSource}
            label="UTM Source"
            placeholder="UTM Source"
        />
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        commonSelectData: state.allReportCommonReducer[ownProps.reportActionType],
    };
};

export default compose(connect(mapStateToProps, {
    selectValue,
    fetchUrlParams,
    clearSearchForAllReport
}))(UTMSourceSelection);