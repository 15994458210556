import React from "react";
import { Loader } from "semantic-ui-react";

const Loding = () => {
    return (
        <div className="show-loader">
            <Loader active inline="centered" />
        </div>
    );
};

export default Loding;