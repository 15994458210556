import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import DateComponent from "../CommonFilter/DateComponent";
import * as actionTypes from "../../actions/actionTypes";
import { selectValue, clearSearchForAllReport, fetchContentReportTableData } from "../../actions";
import { url } from "../../api/urlHelper";
import { Form } from "semantic-ui-react";
import Toggle from "../base/toggle";
import WpDateComponent from "../CommonFilter/WpDateComponent";
import PageTitleSelection from "../common/FilterSection/PageTitleSelection";
import moment from "moment";
import PrimaryTopicSelection from "../common/FilterSection/PrimaryTopicSelection";
import Site from "../common/site";
import TopicTagInSelection from "../common/FilterSection/TopicTagInSelection";
import TopicTagExSelection from "../common/FilterSection/TopicTagExSelection";
import PlacementTagInSelection from "../common/FilterSection/PlacementTagInSelection";
import PlacementTagExSelection from "../common/FilterSection/PlacementTagExSelection";
import OrganizationTagInSelection from "../common/FilterSection/OrganizationTagInSelection";
import OrganizationTagExSelection from "../common/FilterSection/OrganizationTagExSelection";
import PageviewType from "../common/PageviewType";
import DayOfWeek from "../common/DayOfWeek";
import MultipleSelectDropDown from "../base/multiSelectDropdown";
import TimeOfDay from "../common/TimeOfDay";
import EngagedOrgSelection from "../common/FilterSection/EngagedOrgSelection";
import EngagedMemberSelection from "../common/FilterSection/EngagedMemberSelection";
import EngagedCommonToggleFilter from "../ads/common/EngagedCommonToggleFilter";
import FirmographicsFilterComponent from "../ads/common/FirmographicsFilterComponent";
import PersonaFilterComponent from "../ads/common/PersonaFilterComponent";
import EventFilterToggle from "../ads/common/EventFilterToggle";
import { PAGEVIEW_RANGE_LABEL, PAGE_PARAMETER_LABEL, PUBLISHED_DATE_RANGE, VIEW } from "../config/const";
import AuthorsSelection from "../common/FilterSection/AuthorsSelection";
import { CONTENT_PERFORMANCE_DETAILS, CONTENT_PERFORMANCE_SUMMARY_IPA, CONTENT_PERFORMANCE_SUMMARY_SECTOR, CONTENT_PERFORMANCE_SUMMARY_SENORITY } from "../config/rolebasedReportNames";

const DateAndFilter = (props) => {
    const [clear, setClear] = useState(false);
    const [filterPopup, setFilterPopup] = useState("filter-popup-close");
    const [filterSelectFocus, setFilterSelectFocus] = useState("filter-icon");
    const [filterIconColor, setFilterIconColor] = useState("material-icons grey");
    const [checkSelectState, setCheckSelectState] = useState(false);

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    const callAPI =(url, data, actionType)=> {
        props.fetchContentReportTableData(
          url,
          data,
          1,
          "",
          actionType,
          null,
          null,
          "content-performance",
          props.reportActionType,
        );
    }

    const applyFilters = (startDate, endDate, appDate, isCustomCheckbox) => {
        setCheckSelectState(isCustomCheckbox);
        props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].APPLY_FILTERS, reportType: props.reportActionType });
        let tempAdFilterData = props.filterData;
        tempAdFilterData.startDate = startDate;
        tempAdFilterData.endDate = endDate;
        tempAdFilterData.prevStartDate = "";
        tempAdFilterData.prevEndDate = "";
        tempAdFilterData.appliedDate = appDate;
        tempAdFilterData.selCustomRangeCheckBox = isCustomCheckbox;

        if (props?.roleReports?.some((report) => [CONTENT_PERFORMANCE_SUMMARY_IPA].includes(report.element) && report.permission === VIEW)) {
            callAPI(url.contentPerformanceSummaryIPA, tempAdFilterData, actionTypes[props.reportActionType].CONTENT_PERFORMANCE_SUMMARY_IPA);
        }
        if (props?.roleReports?.some((report) => [CONTENT_PERFORMANCE_SUMMARY_SENORITY].includes(report.element) && report.permission === VIEW)) {
            callAPI(url.contentPerformanceSummarySenority, tempAdFilterData, actionTypes[props.reportActionType].CONTENT_PERFORMANCE_SUMMARY_SENORITY);
        }
        if (props?.roleReports?.some((report) => [CONTENT_PERFORMANCE_SUMMARY_SECTOR].includes(report.element) && report.permission === VIEW)) {
            callAPI(url.contentPerformanceSummarySector, tempAdFilterData, actionTypes[props.reportActionType].CONTENT_PERFORMANCE_SUMMARY_SECTOR);
        }
        if (props?.roleReports?.some((report) => [CONTENT_PERFORMANCE_DETAILS].includes(report.element) && report.permission === VIEW)) {
            callAPI(url.contentPerformanceDetails, tempAdFilterData, actionTypes[props.reportActionType].CONTENT_PERFORMANCE_DETAILS);
        }

        setFilterPopup("filter-popup-close");
        setFilterSelectFocus("filter-icon");
        setFilterIconColor("material-icons grey");
    }

    const clearFilters = () => {
        setClear(true);
        setTimeout(() => {
            setClear(false);
        }, 1500);
        props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].CLEARCOMMONFILTER, reportType: props.reportActionType });
    }

    return (
        <div>
            <DateComponent {...props} applyFilters={applyFilters} isClear={clear} />
            <div className="popup-component">
                <div
                    className={filterSelectFocus}
                    style={{ marginTop: "-50px" }}
                    onClick={() => {
                        setFilterSelectFocus("filter-icon-selected");
                        setFilterPopup("filter-popup-open");
                        setFilterIconColor("material-icons orange");
                    }}
                >
                    <i className={filterIconColor}>filter_list</i>
                </div>
                <div className={filterPopup}>
                    <div className="filter-header">
                        <div className="filter-text"><p>Filters</p></div>
                        <div
                            className="filter-close-icon"
                            onClick={() => {
                                setFilterSelectFocus("filter-icon");
                                setFilterPopup("filter-popup-close");
                            }}
                        >
                            <i className="material-icons grey" onClick={() => setFilterIconColor("material-icons grey")}>clear</i>
                        </div>
                    </div>
                    <Form>
                        <div className="ad-search-filter">
                            <Toggle
                                label={PAGE_PARAMETER_LABEL}
                                onChange={() => {
                                    dispatchDynamicSelectAction(
                                        props,
                                        "isPageParameter",
                                        !props.filterData.isPageParameter
                                    );
                                }}
                                checked={props.filterData.isPageParameter}
                            />
                            {props.filterData.isPageParameter && (
                                <>
                                    <label className="job-Titles" style={{ marginLeft: "5px", lineHeight: "2em" }}>{PUBLISHED_DATE_RANGE}</label>
                                    <Form.Group widths="equal">
                                        <WpDateComponent {...props} isClear={props.clear} />
                                        <span style={{ width: "25em" }}>
                                            <div className='ad-graph-date'>
                                                {props.filterData.wp_startDate && props.filterData.wp_endDate &&
                                                    <p>{moment(props.filterData.wp_startDate).format("MM/DD/YYYY")} - {moment(props.filterData.wp_endDate).format("MM/DD/YYYY")}</p>}
                                            </div>
                                        </span>
                                    </Form.Group>
                                    <Form.Group widths="equal">
                                        <PageTitleSelection {...props} />
                                        <PrimaryTopicSelection {...props} />
                                    </Form.Group>
                                </>
                            )}
                            <Form.Group widths="equal">
                                <AuthorsSelection {...props} />
                                <Site
                                    value={props.filterData.site}
                                    onChange={(e, { value }) => dispatchDynamicSelectAction(props, "site", value)}
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <TopicTagInSelection {...props} />
                                <TopicTagExSelection {...props} />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <PlacementTagInSelection {...props} />
                                <PlacementTagExSelection {...props} />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <OrganizationTagInSelection {...props} />
                                <OrganizationTagExSelection {...props} />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <DayOfWeek
                                    weekDays={props?.filterData?.dayOfWeekOptions?.days_of_week}
                                    onChange={(e, { value }) => { dispatchDynamicSelectAction(props, "selDayOfWeeks", value); }}
                                    value={props?.filterData?.selDayOfWeeks}
                                />
                                <PageviewType
                                    value={props.filterData.selPageviewType}
                                    onChange={(e, { value }) => { dispatchDynamicSelectAction(props, "selPageviewType", value); }}
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <TimeOfDay
                                    ranges={props?.filterData?.dayOfWeekOptions?.time_of_day}
                                    onChange={(e, { value }) => { dispatchDynamicSelectAction(props, "selTimeOfDay", value); }}
                                />
                                <MultipleSelectDropDown
                                    options={props?.filterData?.dayOfWeekOptions?.ranges?.map((day, index) => ({
                                        key: index,
                                        text: day.label,
                                        value: day.value,
                                    }))}
                                    value={props?.filterData?.selRange}
                                    onChange={(e, { value }) => { dispatchDynamicSelectAction(props, "selRange", value); }}
                                    label={PAGEVIEW_RANGE_LABEL}
                                    placeholder={PAGEVIEW_RANGE_LABEL}
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <EngagedOrgSelection {...props} />
                                <EngagedMemberSelection {...props} />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <EngagedCommonToggleFilter {...props} />
                            </Form.Group>
                            <FirmographicsFilterComponent {...props} isApplicable={true} />
                            <PersonaFilterComponent {...props} isApplicable={false} />
                            <Form.Group>
                                <div className="engagements-search-filter">
                                    <Form className="search-filter-column-1"></Form>
                                    <Form className="search-filter-column-2">
                                        <EventFilterToggle {...props} />
                                    </Form>
                                </div>
                            </Form.Group>
                        </div>
                    </Form>
                    <div className="filter-buttons">
                        <button className="cancel-date-button extra-space" onClick={clearFilters}>Clear Filters</button>
                        <button
                            type="submit"
                            className="apply-filter-button"
                            onClick={() => {
                                applyFilters(
                                    props.filterData.startDate,
                                    props.filterData.endDate,
                                    props.filterData.appliedDate,
                                    checkSelectState
                                );
                            }}
                        >
                            Apply Filters
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default compose(connect(null, {
    selectValue,
    clearSearchForAllReport,
    fetchContentReportTableData,
}))(DateAndFilter);