import React from "react";
import { Table } from "semantic-ui-react";
import { connect } from "react-redux";
import { sortEditorialDetailsTable } from "../../../actions";

const TableHeader = (props) => {
  return props.headerData?.map((hData, idx) => {
    return (
      <Table.HeaderCell
        key={idx}
        className={hData?.className}
        sorted={props.propData?.pageviewDetailedColumn === hData?.sorted ? props.propData?.pageviewDetailedDirection : null}
        onClick={() => props.propData?.sortEditorialDetailsTable(hData?.sorted)}
        textAlign = {hData?.textAlign}
        title = {hData?.title}
      >
        {hData?.headerName}
      </Table.HeaderCell>
    );
  });
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { sortEditorialDetailsTable })(TableHeader);
