import { combineReducers } from "redux";
import {
  topicOrganizationsReducer,
  topicOrderReducer,
  topicCampaignsReducer,
  leadOrganizationsReducer,
  leadCampaignsReducer,
  leadPageTitleReducer,
  assetTypesReducer,
  sitesReducer,
  adorderstatusReducer,
  leadPartnerReducer,
  locationsReducer,
  leadEngOrgReducer,
  leadEngOrderReducer,
  topicEngOrgReducer,
  topicCountryReducer,
  clientLeadOrganizationsReducer,
  clientLeadCampaignsReducer,
  clientLeadEngOrgReducer,
  clientLeadOrderReducer,
  senioritiesReducer,
  jobFunctionReducer,
  clinetLeadPageTitleReducer,
  sectorReducer,
  industryGroupReducer,
  industryReducer,
  subIndustryReducer,
  orgTypeReducer,
  orgSizeReducer,
  orgRevenueReducer,
  sidebarEngMemberReducer,
} from "./searchFilterReducer";
import { authReducer, wpLinkReducer } from "./authReducer";
import { errorReducer } from "./errorReducer";
import {
  leadFilterDataReducer,
  leadFetchDataReducer,
  urlLeadEngSourceReducer,
  urlLeadEngMediumReducer,
  urlLeadEngCampaignsReducer,
  urlLeadEngTermReducer,
  urlLeadEngContentReducer,
  engAuthorReducer,
  reguationManagerEngReducer,
} from "./LeadEngagementReducers";
import { sidebarVisibiltyReducer } from "./cssReducers";
import {
  topicFilterDataReducer,
  topicFetchDataReducer,
  firmographicSidebarOrgsTypeReducer,
  firmographicSidebarOrgsSizeReducer,
  firmographicSidebarOrgsRevenueReducer,
  firmographicSidebarSectorReducer,
  firmographicSidebarIndustryGroupReducer,
  firmographicSidebarIndustryReducer,
  firmographicSidebarSubIndustryReducer,
  senioritiesInSidebarReducer,
  senioritiesExSidebarReducer,
  jobfunctionsInSidebarReducer,
  jobfunctionsExSidebarReducer,
  sidebarAuthorReducer
} from "./topicSponsorshipReducer";
import {
  clientLeadFilterDataReducer,
  clientLeadFetchDataReducer,
  urlClientLeadSourceReducer,
  urlClientLeadMediumReducer,
  urlClientLeadCampaignsReducer,
  urlClientLeadTermReducer,
  urlClientLeadContentReducer,
  leadAuthorReducer,
  reguationManagerReducer,
} from "./clientLeadEngReducer";

import {
	allFilterReportDataReducer,
  userListFilterReducer,
} from "./allReportFilterDataReducer";

import {
  sponsoredContentDataReducer,
  sponsoredContentOrganizationsReducer,
  sponsoredContentOrderReducer,
  sponsoredContentAuthorReducer,
  sponsoredContentTopicTagsInReducer,
  sponsoredContentTopicTagsExReducer,
  sponsoredContentPlacementTagsInReducer,
  sponsoredContentPlacementTagsExReducer,
  sponsoredContentOrgTagsInReducer,
  sponsoredContentOrgTagsExReducer,
  sponsoredContentCountryReducer,
  sponsoredContentEngOrgReducer,
  sponsoredContentFirmographicOrgsTypeReducer,
  sponsoredContentFirmographicOrgsSizeReducer,
  sponsoredContentFirmographicOrgsRevenueReducer,
  sponsoredContentFirmographicSectorReducer,
  sponsoredContentFirmographicIndustryGroupReducer,
  sponsoredContentFirmographicIndustryReducer,
  sponsoredContentFirmographicSubIndustryReducer,
  sponsoredContentSenioritiesInReducer,
  sponsoredContentSenioritiesExReducer,
  sponsoredContentJobfunctionsInReducer,
  sponsoredContentJobfunctionsExReducer,
  ScPageviewTitleReducer,
  ScUrlSourceReducer,
  ScUrlMediumReducer,
  ScUrlCampaignsReducer,
  ScUrlTermReducer,
  ScUrlContentReducer,
  scEngMemberReducer,
  scPrimaryTopicReducer,
  sponsoredContentFetchDataReducer,
  sponsoredContentCampaignsReducer,
  spnosoredCSMReducer,
} from "./sponsoredContentReducers";

import {
  editorialFilterDataReducer,
  editorialOrganizationsReducer,
  editorialCampaignsReducer,
  editorialOrderReducer,
  editorialAuthorReducer,
  editorialTopicTagsInReducer,
  editorialTopicTagsExReducer,
  editorialPlacementTagsInReducer,
  editorialPlacementTagsExReducer,
  editorialOrgTagsInReducer,
  editorialOrgTagsExReducer,
  editorialCountryReducer,
  editorialEngOrgReducer,
  editorialFirmographicOrgsTypeReducer,
  editorialFirmographicOrgsSizeReducer,
  editorialFirmographicOrgsRevenueReducer,
  editorialFirmographicSectorReducer,
  editorialFirmographicIndustryGroupReducer,
  editorialFirmographicIndustryReducer,
  editorialFirmographicSubIndustryReducer,
  editorialSenioritiesInReducer,
  editorialSenioritiesExReducer,
  editorialJobfunctionsInReducer,
  editorialJobfunctionsExReducer,
  editorialPageviewTitleReducer,
  editorialUrlSourceReducer,
  editorialUrlMediumReducer,
  editorialUrlCampaignsReducer,
  editorialUrlTermReducer,
  editorialUrlContentReducer,
  editorialEngMemberReducer,
  editorialPrimaryTopicReducer,
  editorialDetailReducer
} from "./editorialReducers";

import {
  editorialDetailsDataReducer,
  editorialDetailsOrganizationsReducer,
  editorialDetailsOrderReducer,
  editorialDetailsCampaignsReducer,
  editorialDetailsAuthorReducer,
  editorialDetailsTopicTagsInReducer,
  editorialDetailsTopicTagsExReducer,
  editorialDetailsPlacementTagsInReducer,
  editorialDetailsPlacementTagsExReducer,
  editorialDetailsOrgTagsInReducer,
  editorialDetailsOrgTagsExReducer,
  editorialDetailsCountryReducer,
  editorialDetailsEngOrgReducer,
  editorialDetailsFirmographicSectorReducer,
  editorialDetailsFirmographicIndustryGroupReducer,
  editorialDetailsFirmographicIndustryReducer,
  editorialDetailsFirmographicSubIndustryReducer,
  editorialDetailsSenioritiesInReducer,
  editorialDetailsSenioritiesExReducer,
  editorialDetailsJobfunctionsInReducer,
  editorialDetailsJobfunctionsExReducer,
  editorialDetailsFirmographicOrgsTypeReducer,
  editorialDetailsFirmographicOrgsSizeReducer,
  editorialDetailsFirmographicOrgsRevenueReducer,
  EdPageviewTitleReducer,
  EdUrlSourceReducer,
  EdUrlMediumReducer,
  EdUrlCampaignsReducer,
  EdUrlTermReducer,
  EdUrlContentReducer,
  edEngMemberReducer,
  edPrimaryTopicReducer,
  editorialDetailsFetchDataReducer
} from "./editorialDetailReducers";

import {
  siteReportDataReducer
} from "./siteReportReducers";

import {
  filterDataReducer,
  activityOrgReducer,
  activityEngOrgReducer,
  activityFetchDataReducer,
  activityOrderReducer,
  activityMemberReducer,
  activityCampaignReducer,
  activityTitleReducer,
  urlActivitySourceReducer,
  urlActivityMediumReducer,
  urlActivityCampaignsReducer,
  urlActivityTermReducer,
  urlActivityContentReducer,
  activityTopicTagsInReducer,
  activityTopicTagsExReducer,
  activityPlacementTagsInReducer,
  activityPlacementTagsExReducer,
  activityOrganizationTagsInReducer,
  activityOrganizationTagsExReducer,
  activityActionsReducer,
  activityIncludeActionsReducer,
  activityExcludeActionsReducer,
} from "./ActivityReducers";

import {
  DownloadFilterDataReducer,
  DownloadFetchDataReducer,
  downloadOrgReducer,
  downloadOrderReducer,
  downloadCampaignReducer,
  downloadReqestedByReducer,
} from "./downloadReducers";

import {
  ProductivityFilterDataReducer
} from "./ProductivityReducer";

import {
  commonFilterDataReducer,
  allReportCommonReducers,
} from "./commonFilterReducer";

export default combineReducers({
  links: wpLinkReducer,
  assetTypes: assetTypesReducer,
  sites: sitesReducer,
  seniorities: senioritiesReducer,
  jobfunctions: jobFunctionReducer,
  orgTypesList: orgTypeReducer,
  orgSizeList: orgSizeReducer,
  orgRevenueList: orgRevenueReducer,
  sectorList: sectorReducer,
  industryGroupList: industryGroupReducer,
  industryList: industryReducer,
  subIndustryList: subIndustryReducer,
  adorderStatus: adorderstatusReducer,
  leadPartner: leadPartnerReducer,
  locations: locationsReducer,
  authorized: authReducer,
  errorCode: errorReducer,

  leadOrganizations: leadOrganizationsReducer,
  leadCampaigns: leadCampaignsReducer,
  leadEngOrgs: leadEngOrgReducer,
  leadFilterData: leadFilterDataReducer,
  leadFetchData: leadFetchDataReducer,
  leadOrder: leadEngOrderReducer,
  leadPageTitle: leadPageTitleReducer,
  leadEngUrlSource: urlLeadEngSourceReducer,
  leadEngUrlMedium: urlLeadEngMediumReducer,
  leadEngUrlCampaign: urlLeadEngCampaignsReducer,
  leadEngUrlTerm: urlLeadEngTermReducer,
  leadEngUrlContent: urlLeadEngContentReducer,
  engAuthor: engAuthorReducer,
  reguationManagerEng: reguationManagerEngReducer,

  topicOrganizations: topicOrganizationsReducer,
  topicOrder: topicOrderReducer,
  topicCampaigns: topicCampaignsReducer,
  topicEngOrgs: topicEngOrgReducer,
  topicFilterData: topicFilterDataReducer,
  topicFetchData: topicFetchDataReducer,
  topicCountry: topicCountryReducer,
  firmographicSidebarOrgsType: firmographicSidebarOrgsTypeReducer,
  firmographicSidebarOrgsSize: firmographicSidebarOrgsSizeReducer,
  firmographicSidebarOrgsRevenue: firmographicSidebarOrgsRevenueReducer,
  firmographicSidebarSector: firmographicSidebarSectorReducer,
  firmographicSidebarIndustryGroup: firmographicSidebarIndustryGroupReducer,
  firmographicSidebarIndustry: firmographicSidebarIndustryReducer,
  firmographicSidebarSubIndustry: firmographicSidebarSubIndustryReducer,
  senioritiesInSidebar: senioritiesInSidebarReducer,
  senioritiesExSidebar: senioritiesExSidebarReducer,
  jobfunctionsInSidebar: jobfunctionsInSidebarReducer,
  jobfunctionsExSidebar: jobfunctionsExSidebarReducer,
  sidebarEngMember: sidebarEngMemberReducer,
  sidebarAuthor: sidebarAuthorReducer,

  menuVisible: sidebarVisibiltyReducer,

  clientLeadOrganizations: clientLeadOrganizationsReducer,
  clientLeadCampaigns: clientLeadCampaignsReducer,
  clientLeadEngOrgs: clientLeadEngOrgReducer,
  clientLeadFilterData: clientLeadFilterDataReducer,
  clientLeadFetchData: clientLeadFetchDataReducer,
  clientLeadOrder: clientLeadOrderReducer,
  clinetLeadPageTitle: clinetLeadPageTitleReducer,
  clientLeadUrlSource: urlClientLeadSourceReducer,
  clientLeadUrlMedium: urlClientLeadMediumReducer,
  clientLeadUrlCampaign: urlClientLeadCampaignsReducer,
  clientLeadUrlTerm: urlClientLeadTermReducer,
  clientLeadUrlContent: urlClientLeadContentReducer,
  leadAuthor: leadAuthorReducer,
  reguationManager: reguationManagerReducer,

	allFilterReportData: allFilterReportDataReducer,
  userListFilter: userListFilterReducer,

  sponsoredContentFilterData: sponsoredContentDataReducer,
  sponsoredContentOrganizations: sponsoredContentOrganizationsReducer,
  sponsoredContentOrder: sponsoredContentOrderReducer,
  sponsoredContentAuthor: sponsoredContentAuthorReducer,
  sponsoredContentTopicTagsIn: sponsoredContentTopicTagsInReducer,
  sponsoredContentTopicTagsEx: sponsoredContentTopicTagsExReducer,
  sponsoredContentPlacementTagsIn: sponsoredContentPlacementTagsInReducer,
  sponsoredContentPlacementTagsEx: sponsoredContentPlacementTagsExReducer,
  sponsoredContentOrgTagsIn: sponsoredContentOrgTagsInReducer,
  sponsoredContentOrgTagsEx: sponsoredContentOrgTagsExReducer,
  sponsoredContentCountry: sponsoredContentCountryReducer,
  sponsoredContentEngOrg: sponsoredContentEngOrgReducer,
  sponsoredContentFirmographicOrgsType: sponsoredContentFirmographicOrgsTypeReducer,
  sponsoredContentFirmographicOrgsSize: sponsoredContentFirmographicOrgsSizeReducer,
  sponsoredContentFirmographicOrgsRevenue: sponsoredContentFirmographicOrgsRevenueReducer,
  sponsoredContentFirmographicSector: sponsoredContentFirmographicSectorReducer,
  sponsoredContentFirmographicIndustryGroup: sponsoredContentFirmographicIndustryGroupReducer,
  sponsoredContentFirmographicIndustry: sponsoredContentFirmographicIndustryReducer,
  sponsoredContentFirmographicSubIndustry: sponsoredContentFirmographicSubIndustryReducer,
  sponsoredContentSenioritiesIn: sponsoredContentSenioritiesInReducer,
  sponsoredContentSenioritiesEx: sponsoredContentSenioritiesExReducer,
  sponsoredContentJobfunctionsIn: sponsoredContentJobfunctionsInReducer,
  sponsoredContentJobfunctionsEx: sponsoredContentJobfunctionsExReducer,
  ScPageviewTitle: ScPageviewTitleReducer,
  ScUrlSource: ScUrlSourceReducer,
  ScUrlMedium: ScUrlMediumReducer,
  ScUrlCampaigns: ScUrlCampaignsReducer,
  ScUrlTerm: ScUrlTermReducer,
  ScUrlContent: ScUrlContentReducer,
  scEngMember: scEngMemberReducer,
  scPrimaryTopic: scPrimaryTopicReducer,
  sponsoredContentFetchData: sponsoredContentFetchDataReducer,
  sponsoredContentCampaigns: sponsoredContentCampaignsReducer,
  spnosoredCSM: spnosoredCSMReducer,

  editorialDetailsFilterData: editorialDetailsDataReducer,
  editorialDetailsOrganizations: editorialDetailsOrganizationsReducer,
  editorialDetailsOrder: editorialDetailsOrderReducer,
  editorialDetailsCampaigns: editorialDetailsCampaignsReducer,
  editorialDetailsAuthor: editorialDetailsAuthorReducer,
  editorialDetailsTopicTagsIn: editorialDetailsTopicTagsInReducer,
  editorialDetailsTopicTagsEx: editorialDetailsTopicTagsExReducer,
  editorialDetailsPlacementTagsIn: editorialDetailsPlacementTagsInReducer,
  editorialDetailsPlacementTagsEx: editorialDetailsPlacementTagsExReducer,
  editorialDetailsOrgTagsIn: editorialDetailsOrgTagsInReducer,
  editorialDetailsOrgTagsEx: editorialDetailsOrgTagsExReducer,
  editorialDetailsCountry: editorialDetailsCountryReducer,
  editorialDetailsEngOrg: editorialDetailsEngOrgReducer,
  editorialDetailsFirmographicSector: editorialDetailsFirmographicSectorReducer,
  editorialDetailsFirmographicIndustryGroup: editorialDetailsFirmographicIndustryGroupReducer,
  editorialDetailsFirmographicIndustry: editorialDetailsFirmographicIndustryReducer,
  editorialDetailsFirmographicSubIndustry: editorialDetailsFirmographicSubIndustryReducer,
  editorialDetailsSenioritiesIn: editorialDetailsSenioritiesInReducer,
  editorialDetailsSenioritiesEx: editorialDetailsSenioritiesExReducer,
  editorialDetailsJobfunctionsIn: editorialDetailsJobfunctionsInReducer,
  editorialDetailsJobfunctionsEx: editorialDetailsJobfunctionsExReducer,
  editorialDetailsFirmographicOrgsType: editorialDetailsFirmographicOrgsTypeReducer,
  editorialDetailsFirmographicOrgsSize: editorialDetailsFirmographicOrgsSizeReducer,
  editorialDetailsFirmographicOrgsRevenue: editorialDetailsFirmographicOrgsRevenueReducer,
  EdPageviewTitle: EdPageviewTitleReducer,
  EdUrlSource: EdUrlSourceReducer,
  EdUrlMedium: EdUrlMediumReducer,
  EdUrlCampaigns: EdUrlCampaignsReducer,
  EdUrlTerm: EdUrlTermReducer,
  EdUrlContent: EdUrlContentReducer,
  edEngMember: edEngMemberReducer,
  edPrimaryTopic: edPrimaryTopicReducer,
  editorialDetailsFetchData: editorialDetailsFetchDataReducer,

  editorialFilterData: editorialFilterDataReducer,
  editorialOrganizations: editorialOrganizationsReducer,
  editorialCampaigns: editorialCampaignsReducer,
  editorialOrder: editorialOrderReducer,
  editorialAuthor: editorialAuthorReducer,
  editorialTopicTagsIn: editorialTopicTagsInReducer,
  editorialTopicTagsEx: editorialTopicTagsExReducer,
  editorialPlacementTagsIn: editorialPlacementTagsInReducer,
  editorialPlacementTagsEx: editorialPlacementTagsExReducer,
  editorialOrgTagsIn: editorialOrgTagsInReducer,
  editorialOrgTagsEx: editorialOrgTagsExReducer,
  editorialCountry: editorialCountryReducer,
  editorialEngOrg: editorialEngOrgReducer,
  editorialFirmographicOrgsType: editorialFirmographicOrgsTypeReducer,
  editorialFirmographicOrgsSize: editorialFirmographicOrgsSizeReducer,
  editorialFirmographicOrgsRevenue: editorialFirmographicOrgsRevenueReducer,
  editorialFirmographicSector: editorialFirmographicSectorReducer,
  editorialFirmographicIndustryGroup: editorialFirmographicIndustryGroupReducer,
  editorialFirmographicIndustry: editorialFirmographicIndustryReducer,
  editorialFirmographicSubIndustry: editorialFirmographicSubIndustryReducer,
  editorialSenioritiesIn: editorialSenioritiesInReducer,
  editorialSenioritiesEx: editorialSenioritiesExReducer,
  editorialJobfunctionsIn: editorialJobfunctionsInReducer,
  editorialJobfunctionsEx: editorialJobfunctionsExReducer,
  editorialPageviewTitle: editorialPageviewTitleReducer,
  editorialUrlSource: editorialUrlSourceReducer,
  editorialUrlMedium: editorialUrlMediumReducer,
  editorialUrlCampaigns: editorialUrlCampaignsReducer,
  editorialUrlTerm: editorialUrlTermReducer,
  editorialUrlContent: editorialUrlContentReducer,
  editorialEngMember: editorialEngMemberReducer,
  editorialPrimaryTopic: editorialPrimaryTopicReducer,
  editorialFetchDetailData: editorialDetailReducer,

  siteReportFilterData: siteReportDataReducer,

  filterData: filterDataReducer,
  activityOrganizations: activityOrgReducer,
  activityEngOrg: activityEngOrgReducer,
  activityFetchData: activityFetchDataReducer,
  activityOrder: activityOrderReducer,
  activityMember: activityMemberReducer,
  activityCampaigns: activityCampaignReducer,
  activityTitle: activityTitleReducer,
  urlActivitySource: urlActivitySourceReducer,
  urlActivityMedium: urlActivityMediumReducer,
  urlActivityCampaigns: urlActivityCampaignsReducer,
  urlActivityTerm: urlActivityTermReducer,
  urlActivityContent: urlActivityContentReducer,
  activityTopicTagsIn: activityTopicTagsInReducer,
  activityTopicTagsEx: activityTopicTagsExReducer,
  activityPlacementTagsIn: activityPlacementTagsInReducer,
  activityPlacementTagsEx: activityPlacementTagsExReducer,
  activityOrganizationTagsIn: activityOrganizationTagsInReducer,
  activityOrganizationTagsEx: activityOrganizationTagsExReducer,
  activityActions: activityActionsReducer,
  activityIncludeActions: activityIncludeActionsReducer,
  activityExcludeActions: activityExcludeActionsReducer,

  downloadFilterData: DownloadFilterDataReducer,
  downloadFetchData: DownloadFetchDataReducer,
  downloadOrg: downloadOrgReducer,
  downloadOrder: downloadOrderReducer,
  downloadCampaign: downloadCampaignReducer,
  downloadReqestedBy: downloadReqestedByReducer,

  ProductivityFilterData: ProductivityFilterDataReducer,

  commonFilterData: commonFilterDataReducer,
  allReportCommonReducer: allReportCommonReducers,
});
