import React from "react";
import { Dropdown } from "semantic-ui-react";

const LeadValidationDropDown = (props) => {

  return (
    <>
      <Dropdown
        floating
        show
        onChange={props.onChange}
        value={props.value}
        options={props.options}
        upward={false}
        scrolling={true}
      />
    </>
  );
};

export default LeadValidationDropDown