import _ from "lodash";
import React, { useRef } from "react";
import { connect } from "react-redux";
import { Checkbox, Loader } from "semantic-ui-react";
import { selClientLeadToggles } from "../../../actions";
import * as actionTypes from "../../../actions/actionTypes";
import { getCapital, getDisplayDateChart } from "../../../utilities/common";
import DownloadChart from "../../common/downloadChart";
import ClientLeadProgressChart from "./clientLeadProgressChart";

const LeadStatusChart = (props) => {
    const chartNameDiv = useRef(null);
    let loading;
    if (props.loading) {
        loading = (
            <div className="show-loader">
                <Loader active inline="centered" />
            </div>
        );
    }

    return (
        <>
            <div className="ad-graph" id="charts-gap">
                <div ref={chartNameDiv}>
                    {!_.isEmpty(props.leadValidationBarChart) && props.clientLeadFetchData.leadStatusChart &&
                        <DownloadChart
                            chartNameDiv={chartNameDiv.current}
                            chartName={(`Lead-Status-${getDisplayDateChart(new Date())}.jpg`)}
                            style={{ width: "36px", height: "36px", marginTop: 19, marginRight: 56, color: "#838486" }}
                        />
                    }
                    <p className="ad-chart-name">
                        {getCapital("Lead Status")}
                        <Checkbox
                            className="checkbox-label"
                            data-html2canvas-ignore="true"
                            // slider
                            toggle
                            style={{
                                color: "white",
                                fontFamily: "Roboto",
                                fontSize: "16px",
                                paddingLeft: "20px",
                                minHeight: 15,
                            }}
                            label={props.clientLeadFetchData.leadStatusChart ? "Hide" : "Show"}
                            onChange={() => props.selClientLeadToggles(actionTypes.SELLEADSTATUSCHART)}
                            checked={props.clientLeadFetchData.leadStatusChart}
                            disabled={!props.isFilApplied}
                        />
                       
                        {
                            props.isFilApplied &&  props.clientLeadFetchData.leadStatusChart &&
                            loading
                        }
                        {
                            !_.isEmpty(props.leadValidationBarChart) && props.clientLeadFetchData.leadStatusChart && 
                            <ClientLeadProgressChart />
                        }
                    </p>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        leadValidationBarChart: state.clientLeadFetchData.leadValidationBarChart,
        isFilApplied: state.clientLeadFetchData.isFilApplied,
        clientLeadFetchData: state.clientLeadFetchData,
        loading: state.clientLeadFetchData.isLoadingStatusChart,
        leadFilterData: state.clientLeadFilterData,
    };
};

export default connect(mapStateToProps, { selClientLeadToggles })(
    LeadStatusChart
);
