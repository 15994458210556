import { 
    ENGAGEMENT_STATUS, 
    VERIFICATION_STATUS,
    ORDER,
    CAMPAIGN,
    ENGAGEMENT_SOURCE,
    UTM_TERM,
    UTM_CONTENT,
    ASSET_TYPE,
    COUNTRY,
    ORGANIZATION,
    MEMBER_SENIORITY,
    JOB_FUNCTION,
    SECTOR,
    INDUSTRY_GROUP,
    INDUSTRY,
    SUB_INDUSTRY,
    UTM_SOURCE,
    UTM_MEDIUM,
    UTM_CAMPAIGN,
    CUSTOM_QUESTION_RESPONSES,
    PHONE_NUMBER,
    GROUP_ID,
    ORGANIZATION_TYPE,
    ORGANIZATION_REVENUE,
    ORGANIZATION_SIZE,
    OPT_IN_DATE,
    OPT_IN_TIME,
    ENGAGEMENT_PROCESSED_DATE,
    ENGAGEMENT_PROCESSED_TIME,
    ACCEPTED_DATE,
    ACCEPTED_TIME,
    DELIVERY_DATE,
    DELIVERY_TIME,
    PURPOSE_CONSENT,
    MARKETING_CONSENT,
    RIGHT_TO_BE_FORGOTTEN,
    DATE,
    TIME,
    IP_ADDRESS,
    PAGE_TITLE,
    WP_ID,
    FIRST_NAME,
    LAST_NAME,
    JOB_TITLE,
    EMAIL_ADDRESS,
    ADDRESS1,
    ADDRESS2,
    POSTAL_CODE_OR_ZIP,
    STATE_OR_PROVINCE,
    TIMEZONE,
    DOUBLE_OPT_IN,
    OPT_OUT_OF_SALE,
    CONDITIONING_CONSENT,
    CITY,
    AGE_VERIFICATION,
    ACTION,
    REGULATION_NAME,
} from "../config/TableHeaderNames";

const EngsTableData=[
    { headerName: ENGAGEMENT_STATUS, sorted: "verification_status", name: "Engagement Status" },
    { headerName: VERIFICATION_STATUS, sorted: "verification_state", className: "custom-column-verification-state", name: "Verification Status" },
    { headerName: ORDER, sorted: "order_name", className: "custom-column-large", name: "Order" },
    { headerName: CAMPAIGN, sorted: "campaign_name", className: "custom-column-large", name: "Campaign" },
    { headerName: DATE, sorted: "date", name: "Date" },
    { headerName: TIME, sorted: "time", name: "Time" },
    { headerName: OPT_IN_DATE, sorted: "opt_in_date", name: "Opt-In date" },
    { headerName: OPT_IN_TIME, sorted: "opt_in_time", name: "Opt-In time" },
    { headerName: ENGAGEMENT_PROCESSED_DATE, sorted: "processing_date", name: "Engagement Processed Date" },
    { headerName: ENGAGEMENT_PROCESSED_TIME, sorted: "processing_time", name: "Engagement Processed Time" },
    { headerName: ACCEPTED_DATE, sorted: "accepted_at_date", name: "Accepted Date" },
    { headerName: ACCEPTED_TIME, sorted: "accepted_at_time", name: "Accepted Time" },
    { headerName: DELIVERY_DATE, sorted: "delivered_at_date", name: "Delivery Date" },
    { headerName: DELIVERY_TIME, sorted: "delivered_at_time", name: "Delivery Time" },
    { headerName: GROUP_ID, sorted: "group_uuid", className: "custom-column-extra-large", name: "Group ID" },
    { headerName: ACTION, sorted: "action", name: "Action" },
    { headerName: IP_ADDRESS, sorted: "ip_address", className: "custom-column-large", name: "IP Address" },
    { headerName: ASSET_TYPE, sorted: "new_asset_type", name: "Asset Type" },
    { headerName: PAGE_TITLE, sorted: "page_title", name: "Page Title" },
    { headerName: WP_ID, sorted: "wp_page_id", name: "WP ID" },
    { headerName: ORGANIZATION, sorted: "organization_name", className: "custom-column-large", name: "Organization" },
    { headerName: ORGANIZATION_TYPE, sorted: "type", name: "Organization Type" },
    { headerName: ORGANIZATION_REVENUE, sorted: "estimated_revenue", name: "Organization Revenue" },
    { headerName: ORGANIZATION_SIZE, sorted: "organization_size", className: "right aligned text_right_align", name: "Organization Size" },
    { headerName: SECTOR, sorted: "organization_sector", name: "Sector" },
    { headerName: INDUSTRY_GROUP, sorted: "organization_industry_group", name: "Industry Group" },
    { headerName: INDUSTRY, sorted: "organization_industry", name: "Industry" },
    { headerName: SUB_INDUSTRY, sorted: "organization_sub_industry", name: "Sub Industry" },
    { headerName: FIRST_NAME, sorted: "first_name", name: "First Name" },
    { headerName: LAST_NAME, sorted: "last_name", name: "Last Name" },
    { headerName: MEMBER_SENIORITY, sorted: "seniority", name: "Member Seniority" },
    { headerName: JOB_FUNCTION, sorted: "primary_role", name: "Job Function" },
    { headerName: JOB_TITLE, sorted: "job_title", name: "Job Title" },
    { headerName: PHONE_NUMBER, sorted: "phone_number", className: "custom-column-medium", name: "Phone Number" },
    { headerName: EMAIL_ADDRESS, sorted: "email_address", className: "custom-column-large", name: "Email Address" },
    { headerName: ADDRESS1, sorted: "address_1", className: "custom-column-large", name: "Address 1" },
    { headerName: ADDRESS2, sorted: "address_2", className: "custom-column-large", name: "Address 2" },
    { headerName: CITY, sorted: "city", name: "City" },
    { headerName: STATE_OR_PROVINCE, sorted: "state", name: "State/Province" },
    { headerName: COUNTRY, sorted: "member_country", name: "Country" },
    { headerName: POSTAL_CODE_OR_ZIP, sorted: "postal_code", name: "Postal Code" },
    { headerName: TIMEZONE, sorted: "member_timezone", name: "Timezone" },
    { headerName: UTM_SOURCE, sorted: "source", name: "UTM Source" },
    { headerName: UTM_MEDIUM, sorted: "utm_medium", name: "UTM Medium" },
    { headerName: UTM_CAMPAIGN, sorted: "utm_campaign", name: "UTM Campaign" },
    { headerName: UTM_TERM, sorted: "utm_term", name: "UTM Term" },
    { headerName: UTM_CONTENT, sorted: "utm_content", name: "UTM Content" },
    { headerName: CUSTOM_QUESTION_RESPONSES, sorted: "custom_question", className: "custom-column-medium", name: "Custom Question Responses" },
    { headerName: REGULATION_NAME , sorted: "regulation_name", name: "Regulation Name" },
    { headerName: PURPOSE_CONSENT, sorted: "purpose_consent", name: "Purpose Consent" },
    { headerName: MARKETING_CONSENT, sorted: "marketing_consent", name: "Marketing Consent" },
    { headerName: DOUBLE_OPT_IN , sorted: "double_optin_pending", name: "Double Opt-In" },
    { headerName: OPT_OUT_OF_SALE, sorted: "dnsmpi", name: "Opt-out of sale" },
    { headerName: CONDITIONING_CONSENT, sorted: "conditioning_consent", name: "Conditioning Consent" },
    { headerName: AGE_VERIFICATION, sorted: "age_verification", name: "Age Verification" },
    { headerName: RIGHT_TO_BE_FORGOTTEN, sorted: "deletion_policy", name: "Right To Be Forgotten" },
    { headerName: ENGAGEMENT_SOURCE, sorted: "lead_partner", name: "Engagement Source" }, 
]
export default EngsTableData;