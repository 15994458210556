import React from "react";
import { connect } from "react-redux";
import AdsPage from "./../adsPage";
import * as constName from "./../../components/config/const";
import { ROLEBASED_ELEMENT_NEWSLETTER_BODY_ADS } from "../../components/config/rolebasedReportNames";
import { getReportsByElement } from "../../utilities/common";

const NewsletterBody = (props) => {
    const newsletterBodyAdsReports = getReportsByElement(props?.roleFilterData?.currentUserReportRoleData, props?.roleFilterData?.getAllReports, ROLEBASED_ELEMENT_NEWSLETTER_BODY_ADS);

    return (
        <AdsPage
            reportActionType={constName.MODULE_NEWSLETTER_BODY}
            reportName={constName.NEWSLETTER_BODY_REPORT_NAME}
            filterData={props.filterData}
            reportDescription={props.reportDescription.find(report => report.name === "Ads - Newsletter-Body")?.description}
            label={constName.NEWSLETTER_BODY_LABEL}
            roleReports={newsletterBodyAdsReports}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        filterData: state.commonFilterData[constName.MODULE_NEWSLETTER_BODY],
        reportDescription: state.allFilterReportData.reportDescription,
        roleFilterData: state.allFilterReportData,
    };
};

export default connect(mapStateToProps)(NewsletterBody);