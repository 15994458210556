import React from "react";
import { Card } from "semantic-ui-react";

const cardStyle = {
  boxShadow:
    " rgba(9, 30, 66, 0.25) 0px 0px 1px 0px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
  boxSizing: "border-box",
  display: "inline-block",
  marginRight: "0px",
  marginTop: "0px",
  width: "12.25rem",
  borderRadius: 0,
  backgroundColor:"#ffffff"
};
const GridIcons = (props) => {
  return (
    <div style={{ paddingTop: 20 }}>
      <Card.Group
        className="ui two column stretched grid padded"
        itemsPerRow={2}
      >
        {props.data.map((card) => (
          <Card className="icon-box" style={cardStyle}>
            <Card.Content>
              <div className="icons">{card.icon}</div>
              <div className="icon-value">
                <Card.Content>{card.value}</Card.Content>
              </div>
              <Card.Content>
                <label className="icon-text">{card.title}</label>
              </Card.Content>
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
    </div>
  );
};

export default GridIcons;
