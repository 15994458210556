import React from "react";
import SingleSelectDropDown from "../base/singleSelectDropDown";
import * as configName from "../../components/config/names";

const PageviewType = (props) => {
  return (
    <SingleSelectDropDown
      className="no-blink"
      options={[
        { key: "0", text: configName.ALL, value: configName.ALL_VALUE },
        { key: "1", text: configName.NON_SPONSORED, value: configName.NON_SPONSORED_VALUE },
        { key: "2", text: configName.DEFINITION, value: configName.DEFINITION_VALUE },
        { key: "3", text: configName.ORGANIZATIONS, value: configName.ORGANIZATIONS_VALUE },
        { key: "4", text: configName.PROJECTS, value: configName.PROJECTS_VALUE },
        { key: "5", text: configName.EDITORIAL, value: configName.EDITORIAL_VALUE },
        { key: "6", text: configName.EVENTS, value: configName.EVENTS_VALUE },
        { key: "7", text: configName.GLOSSARY, value: configName.GLOSSARY_VALUE },
        { key: "8", text: configName.LEGAL, value: configName.LEGAL_VALUE },
        { key: "9", text: configName.PRESS_RELEASE, value: configName.PRESS_RELEASE_VALUE },
        { key: "10", text: configName.SPONSORED, value: configName.SPONSORED_VALUE },
        { key: "11", text: configName.SPONSORED_SYNDICATED, value: configName.SPONSORED_SYNDICATED_VALUE },
        { key: "12", text: configName.SYNDICATED, value: configName.SYNDICATED_VALUE },
        { key: "13", text: configName.SECURITY_ADVISORIES, value: configName.SECURITY_ADVISORIES_VALUE },
        { key: "14", text: configName.TOPIC, value: configName.TOPIC_VALUE },
        { key: "15", text: configName.OTHER, value: configName.OTHER_VALUE },
      ]}
      label={configName.PAGEVIEW_TYPE}
      placeholder={configName.PAGEVIEW_TYPE}
      {...props}
    />
  );
};

export default PageviewType;