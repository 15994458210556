import React from "react";
import SingleSelectDropDown from "../base/singleSelectDropDown";
import "../../css/common.css";

const TrafficSource = (props) => {
	return (
		<SingleSelectDropDown 
			placeholder="Traffic Source"
			options={[
                {
					key: "0",
					text: "All",
					value: "All",
				},
				{
					key: "1",
					text: "Google",
					value: "Google",
				},
				{
					key: "2",
					text: "Facebook",
					value: "Facebook",
				},
				{
					key: "3",
					text: "LinkedIn",
					value: "LinkedIn",
				},
				{
					key: "4",
					text: "Twitter",
					value: "Twitter",
				},
				{
					key: "5",
					text: "Instagram",
					value: "Instagram",
				},
				{
					key: "6",
					text: "Youtube",
					value: "Youtube",
				},
                {
					key: "7",
					text: "Pinterest",
					value: "Pinterest",
				},
                {
					key: "8",
					text: "Reddit",
					value: "Reddit",
				},
                {
					key: "9",
					text: "Quora",
					value: "Quora",
				},

			]}
			// className="no-blink"
			// upward={false}
			value={props.value}
			onChange={props.onChange}
            label="Traffic Source"
		></SingleSelectDropDown>
	);
};

export default TrafficSource   ;
