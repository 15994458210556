import _ from "lodash";
import React, { useState } from "react";
import { Table } from "semantic-ui-react";
import {
	numFormat,
	getDisplayDate,
	capitalize,
} from "../../../utilities/common";
import Link from "../../hyperlinks/link";
import Sidebar from "./sidebar";
import { connect } from "react-redux";

const TopicDetailTableBody = (props) => {
	const [campUuid, setCampUuid] = useState([]);
	const [titleUuid, setTitleUuid] = useState([]);
	let conditionChkboxAppFilter = props.filterData.selCustomRangeCheckBox && props.isFilApplied && props.filterData.appliedDate ==="Custom Date Range";
	
	const campExpand = (id) => {
		setCampUuid(campUuid.concat(id));
	};

	const tableRow = [];

	const appendRow = (rows) => {
		tableRow.push(...rows)
	}
	const campCompress = (id) => {
		const arr = campUuid.filter(function (item) {
			return item !== id;
		});
		setCampUuid(arr);
		setTitleUuid([]);
	};

	const pageTitleExpand = (id) => {
		setTitleUuid(titleUuid.concat(id));
	};

	const pageTitleCompress = (id) => {
		const arr = titleUuid.filter(function (i) {
			return i !== id;
		});
		setTitleUuid(arr);
	};

	const createRow = (data) => {
		if (data.length > 0) {
			data.forEach((e) => {
				let classes = {};
				let campTableCell = [];
				let titleTableCell = [];
				let cTableCell = [];
				//Campaign
				classes.campUuid = e.uuid;
				if(	!_.isNull(e.sidebars) && Object.keys(e.sidebars).length > 0){
					if (campUuid.indexOf(classes.campUuid) !== -1) {
						campTableCell.push(
							<Table.Cell>
								<i
									className="material-icons"
									onClick={() => campCompress(e.uuid)}
								>
									remove
								</i>
							</Table.Cell>							
						);
					} else {
						campTableCell.push(
							<Table.Cell>
								<i className="material-icons" onClick={() => campExpand(e.uuid)}>
									add
								</i>
							</Table.Cell>						
						);
					}
				} 
			(e.sidebars === null) &&
				campTableCell.push(
					<Table.Cell></Table.Cell>
				)
				campTableCell.push(
					<Table.Cell title={e.order.name} className="blogs_link">
						{e.order.name ? (
							<Link
								type={"orders"}
								urlParam={e.order.uuid}
								refValue={e.order.name}
							></Link>
						) : (
							""
						)}
					</Table.Cell>
				);
				campTableCell.push(
					<Table.Cell className="blogs_link" title={e.campaign_name}>
						{e.campaign_name ? (
							<Link
								type={"campaigns"}
								urlParam={e.uuid}
								refValue={e.campaign_name}
							></Link>
						) : (
							""
						)}
					</Table.Cell>
				);
				campTableCell.push(
					<Table.Cell title={getDisplayDate(e.start_date)}>{getDisplayDate(e.start_date)}</Table.Cell>
				);
				campTableCell.push(
					<Table.Cell  title={getDisplayDate(e.end_date)}>{getDisplayDate(e.end_date)}</Table.Cell>
				);
				campTableCell.push(
					<Table.Cell></Table.Cell>
				);
				campTableCell.push(
					<Table.Cell></Table.Cell>
				);
				// campTableCell.push(<Table.Cell  title={e.page_title}>{e.page_title}</Table.Cell>);
				campTableCell.push(
					<Table.Cell></Table.Cell>
				);
				(conditionChkboxAppFilter)  ? 
				campTableCell.push(
					<Table.Cell textAlign="right"  title={numFormat(e.impressions)}>
						{numFormat(e.impressions)}
					</Table.Cell>,
					<Table.Cell textAlign="right" title={e.prev_impressions}>
					{numFormat(e.prev_impressions)}
				</Table.Cell>, 
				) :
				campTableCell.push(
					<Table.Cell textAlign="right"  title={numFormat(e.impressions)}>{numFormat(e.impressions)}</Table.Cell>,
				);
				(conditionChkboxAppFilter)  ? 
				campTableCell.push(
					<Table.Cell textAlign="right"  title={numFormat(e.unique_impressions)}>
						{numFormat(e.unique_impressions)}
					</Table.Cell>,
					<Table.Cell textAlign="right" title={numFormat(e.prev_unique_impressions)}>
					{numFormat(e.prev_unique_impressions)}
				</Table.Cell>, 
				) :
				campTableCell.push(
					<Table.Cell textAlign="right"  title={numFormat(e.unique_impressions)}>{numFormat(e.unique_impressions)}</Table.Cell>,
				);
				// campTableCell.push(<Table.Cell>{capitalize(e.asset_type)}</Table.Cell>);
				// campTableCell.push(<Table.Cell></Table.Cell>);
				(conditionChkboxAppFilter)  ? 
				campTableCell.push(
					<Table.Cell textAlign="right"  title={numFormat(e.clicks)}>
						{numFormat(e.clicks)}
					</Table.Cell>,
					<Table.Cell textAlign="right" title={numFormat(e.prev_clicks)}>
					{numFormat(e.prev_clicks)}
				</Table.Cell>, 
				) :
				campTableCell.push(
					<Table.Cell textAlign="right"  title={numFormat(e.clicks)}>{numFormat(e.clicks)}</Table.Cell>,
				);
				(conditionChkboxAppFilter)  ? 
				campTableCell.push(
					<Table.Cell textAlign="right"  title={numFormat(e.unique_clicks)}>
						{numFormat(e.unique_clicks)}
					</Table.Cell>,
					<Table.Cell textAlign="right" title={numFormat(e.prev_unique_clicks)}>
					{numFormat(e.prev_unique_clicks)}
				</Table.Cell>, 
				) :
				campTableCell.push(
					<Table.Cell textAlign="right"  title={numFormat(e.unique_clicks)}>{numFormat(e.unique_clicks)}</Table.Cell>,
				);
				campTableCell.push(<Table.Cell textAlign="right">{e.ctr}</Table.Cell>);
				if (campUuid.indexOf(classes.campUuid) !== -1) {		
					classes.campSelected = "topic-camp-selected";
				} else if (campUuid.indexOf(classes.campUuid) === -1) {
					classes.campSelected = "";
				}
				tableRow.push(
					<Table.Row
						className={classes.campSelected}
						children={campTableCell}
						onDoubleClick={() => {
							if (campUuid.indexOf(e.uuid) !== -1) {
								campCompress(e.uuid);
							} else if (campUuid.indexOf(e.uuid) === -1) {
								campExpand(e.uuid);
							}
						}}
					/>
				);
					
				
				campTableCell = [];
				//Title Row
		if (campUuid.indexOf(classes.campUuid) !== -1) {
            if (e.sidebars) {
              e.sidebars.map((sidebar) => {
                tableRow.push(
                //   <Table.Row>
                    <Sidebar data={sidebar} appendRow = {appendRow} />
                //   </Table.Row>
                );
				return "";
              });
            }
          }
				if (e.pages && e.pages.length > 0) {
					if (campUuid.indexOf(classes.campUuid) !== -1) {
						// code to be used from docs and paste here 
						classes.titleChildren = "title-children";
						classes.titleVisible = "";
					} else if (campUuid.indexOf(classes.campUuid) === -1) {
						classes.titleVisible = "title-hide";
					}
					e.pages.forEach((item, idx) => {
						classes.titleUuid = item.wp_page_id;
						titleTableCell.push(<Table.Cell></Table.Cell>);
						if (item.clicks > 0) {
							if (titleUuid.indexOf(classes.titleUuid) !== -1) {
								titleTableCell.push(
									<Table.Cell>
										<i
											className="material-icons"
											onClick={() => pageTitleCompress(item.wp_page_id)}
										>
											remove
										</i>
									</Table.Cell>
								);
							} else if (titleUuid.indexOf(classes.titleUuid) === -1) {
								titleTableCell.push(
									<Table.Cell>
										<i
											className="material-icons"
											onClick={() => pageTitleExpand(item.wp_page_id)}
										>
											add
										</i>
									</Table.Cell>
								);
							}
						} else {
							titleTableCell.push(<Table.Cell></Table.Cell>);
						}
						titleTableCell.push(<Table.Cell></Table.Cell>);
						titleTableCell.push(<Table.Cell></Table.Cell>);
						titleTableCell.push(<Table.Cell></Table.Cell>);
						titleTableCell.push(<Table.Cell></Table.Cell>);
						titleTableCell.push(<Table.Cell></Table.Cell>);
						titleTableCell.push(<Table.Cell></Table.Cell>);
						// titleTableCell.push(
						// 	<Table.Cell title={item.page_title}>
						// 		{capitalize(item.page_title)}
						// 	</Table.Cell>
						// );
						titleTableCell.push(<Table.Cell></Table.Cell>);
						// titleTableCell.push(<Table.Cell></Table.Cell>);
						(conditionChkboxAppFilter)  ? 
							titleTableCell.push(
								<Table.Cell title={numFormat(item.impressions)}>
									{numFormat(item.impressions)}
								</Table.Cell>,
								<Table.Cell title={numFormat(item.prev_impressions)}>
								{numFormat(item.prev_impressions)}
							</Table.Cell>, 
							) :
							titleTableCell.push(
								<Table.Cell title={numFormat(item.impressions)}>{numFormat(item.impressions)}</Table.Cell>,
						);
						(conditionChkboxAppFilter)  ? 
							titleTableCell.push(
								<Table.Cell></Table.Cell>,
								<Table.Cell></Table.Cell>, 
							) :
							titleTableCell.push(
								<Table.Cell></Table.Cell>,
						);
						// titleTableCell.push(<Table.Cell></Table.Cell>);
						(conditionChkboxAppFilter)  ? 
							titleTableCell.push(
								<Table.Cell title={numFormat(item.clicks)}>
									{numFormat(item.clicks)}
								</Table.Cell>,
								<Table.Cell title={numFormat(item.prev_clicks)}>
								{numFormat(item.prev_clicks)}
							</Table.Cell>, 
							) :
							titleTableCell.push(
								<Table.Cell textAlign="right" title={numFormat(item.clicks)}>{numFormat(item.clicks)}</Table.Cell>,
								<Table.Cell></Table.Cell>
						);
						(conditionChkboxAppFilter)  ? 
						titleTableCell.push(
							<Table.Cell></Table.Cell>,
							<Table.Cell></Table.Cell>, 
						) :
						titleTableCell.push(
							<Table.Cell></Table.Cell>,
					);
						titleTableCell.push(
							<Table.Cell textAlign="right" title={item.ctr}>{item.ctr}</Table.Cell>
						);
						if (titleUuid.indexOf(classes.titleUuid) !== -1) {
							classes.titleChildren = "title-children-selected";
						} else if (titleUuid.indexOf(classes.titleUuid) === -1) {
							classes.titleChildren = "title-children";
						}
						tableRow.push(
							<Table.Row
								className={`${classes.titleChildren} ${classes.titleVisible}`}
								children={titleTableCell}
								onDoubleClick={() => {
									if (titleUuid.indexOf(item.wp_page_id) !== -1) {
										pageTitleCompress(item.wp_page_id);
									} else if (titleUuid.indexOf(item.wp_page_id) === -1) {
										pageTitleExpand(item.wp_page_id);
									}
								}}
							/>
						);
						titleTableCell = [];
						//Creative row
						// const cArr = ['images', 'directory', 'rss', 'links'];
						if (item.topic_creatives) {
							if (item.topic_creatives.length > 0) {
								if (titleUuid.indexOf(classes.titleUuid) !== -1) {
									classes.cChildren = "c-title-children";
									classes.cVisible = "";
								} else if (titleUuid.indexOf(classes.titleUuid) === -1) {
									classes.cVisible = "c-title-hide";
								}
								item.topic_creatives.forEach((c) => {
									cTableCell.push(<Table.Cell></Table.Cell>);
									cTableCell.push(<Table.Cell></Table.Cell>);
									cTableCell.push(<Table.Cell></Table.Cell>);
									cTableCell.push(<Table.Cell></Table.Cell>);
									cTableCell.push(<Table.Cell></Table.Cell>);
									cTableCell.push(<Table.Cell></Table.Cell>);
									cTableCell.push(<Table.Cell></Table.Cell>);
									cTableCell.push(<Table.Cell></Table.Cell>);
									(conditionChkboxAppFilter)  ? 
									cTableCell.push(
										<Table.Cell title={numFormat(c.impressions)}>
											{numFormat(c.impressions)}
										</Table.Cell>,
										<Table.Cell title={c.prev_impressions}>
											{numFormat(c.prev_impressions)}
										</Table.Cell>
									) :
									cTableCell.push(
										<Table.Cell title={numFormat(c.impressions)}>
											{numFormat(c.impressions)}
										</Table.Cell>,
									);
									(conditionChkboxAppFilter)  ? 
									cTableCell.push(
										<Table.Cell></Table.Cell>,
										<Table.Cell></Table.Cell>, 
									) :
									cTableCell.push(
										<Table.Cell></Table.Cell>
									);
									if (c.link_title === "") {
										cTableCell.push(
											<Table.Cell title={c.customer_link}>
												<a
													href={c.customer_link}
													target="_blank"
													rel="noreferrer noopener"
												>
													{c.customer_link}
												</a>
											</Table.Cell>
										);
									} else {
										cTableCell.push(
											<Table.Cell title={c.link_title}>
												<a
													href={c.customer_link}
													target="_blank"
													rel="noreferrer noopener"
												>
													{capitalize(c.link_title)}
												</a>
											</Table.Cell>
										);
									}
									// cTableCell.push(
									//   <Table.Cell title={c.customer_link}>
									//     {c.customer_link}
									//   </Table.Cell>
									// );
									(conditionChkboxAppFilter)  ? 
									cTableCell.push(
										<Table.Cell  title={numFormat(c.clicks)}>
											{numFormat(c.clicks)}
										</Table.Cell>,
										<Table.Cell title={numFormat(c.prev_clicks)}>
											{numFormat(c.prev_clicks)}
										</Table.Cell>, 
									) :
									cTableCell.push(
										<Table.Cell  title={numFormat(c.clicks)}>
											{numFormat(c.clicks)}
										</Table.Cell>,
									);

									cTableCell.push(
										<Table.Cell textAlign="right">{}</Table.Cell>
									);
									(conditionChkboxAppFilter)  ? 
									cTableCell.push(
										<Table.Cell></Table.Cell>,
										<Table.Cell></Table.Cell>, 
									) :
									cTableCell.push(
										<Table.Cell></Table.Cell>
									);
									cTableCell.push(
										<Table.Cell textAlign="right" title={c.ctr}>{c.ctr}</Table.Cell>
									);
									tableRow.push(
										<Table.Row
											className={`${classes.cChildren} ${classes.cVisible}`}
											children={cTableCell}
										/>
									);
									cTableCell = [];
								});
							}
						}
					});
				}
			});
			return tableRow;
		}
	};
	return <Table.Body>{createRow(props.data)}</Table.Body>;
};

const mapStateToProps = (state) => {
	return {
		isFilApplied: state.topicFetchData.isFilApplied,
		filterData: state.topicFilterData,
	};
};

export default connect(mapStateToProps)(TopicDetailTableBody); 

