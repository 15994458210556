import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import RequestUser from "./RequestUser";
import { downloadClearSearch, downloadSelectValue, fetchRequestedUsers } from "../../actions";
import _ from "lodash";

const CommonRequestUserPicker = (props) => {

	const onClickHandler = (e, { value }) => {
		if (_.isEmpty(props.filterData.requestedUser)) {
			props.downloadClearSearch(props.clearAction);
		 }
	}

	const onChangeHandler = (e, { value }) =>{
		props.downloadSelectValue({ key: "requestedUser", value });
	}

	const onSearchHandler = (e, { value }) => {
		if (e.target.value.length > 1) {
			props.fetchRequestedUsers(e.target.value, props.fetchAction);
		} else {
			props.downloadClearSearch(props.clearAction);
		}
	};

	return (
		<RequestUser
			requestdata={props.data}
			value={props.value}
			onClick={onClickHandler}
			onChange={onChangeHandler}
			onSearchChange={onSearchHandler}
		/>
	);
};

const mapStateToProps = (state) => {
	return {};
 };

 export default compose( connect(mapStateToProps, {
	downloadClearSearch,
	downloadSelectValue,
	fetchRequestedUsers,
})
)(CommonRequestUserPicker);