import React from "react";
export const RequestUsersComponent = (props) => {
   const { requesters } = props;
   return (
      <React.Fragment>
         {
            requesters.map((item)=> {
               return (
                  <div key={item.users.id} title={item.users.name}>{item.users.name}</div>
               )
            })
         }
      </React.Fragment>
   )
}