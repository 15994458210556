import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Checkbox,
  Divider,
  Form,
  Icon,
  Loader,
  Menu,
  Message,
  Table,
} from "semantic-ui-react";
import {
  sortEditorialTableData,
  fetchEditorialTableData,
  sendTableHeaders,
  getTableHeaders,
  clearEditorialSort,
  showMoreEditorialTableData,
  clearEditorialSerchedValue,
  selEditorialFilterValue
} from "../../../actions";
import * as actionTypes from "../../../actions/actionTypes";
import _ from "lodash";
import { url } from "../../../api/urlHelper";
import IndependentPageSummaryRow from "./pageSummaryRow";
import PageSummaryTableData from "./pageSummaryTableData";
import { calculateTotalCount, numFormat } from "../../../utilities/common";
import PageviewIndependentTagsTableHeaderRow from "../PageviewIndependentTagsTableHeaderRow";
 
const EditorialPageSummary = (props) => {
  const [selectHeaders, setSelectHeaders] = useState();
  const [finalHeaderData, setFinalHeaderData] = useState();
  const [headerColumn, setHeaderColumn] = useState([]);
  const [isSortIcon, setSortIcon] = useState(false);

  let tableBody;
  let showMoreButton;
  let loading;
  let noresult;
  let conditionPrevious =
    props.filterData.selCutomRangeChkBox === true &&
    props.isFilApplied &&
    props.filterData.appliedDate === "Custom Date Range";

  useEffect(() => {
    props.getTableHeaders(
      "independent_page_summary",
      actionTypes.GETEDITORIALPAGESUMMARYDYNAMICTABLEHEADER
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const headerDataVal = props.getDynamicHeaderData;
    if (!_.isEmpty(headerDataVal)) {
      setSelectHeaders(headerDataVal);
      setFinalHeaderData(headerDataVal);
    } else if (headerDataVal === undefined || _.size(headerDataVal) === 0 ) {
      const constantHeadersArr = [...PageSummaryTableData];
      setSelectHeaders(constantHeadersArr);
      setFinalHeaderData(constantHeadersArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getDynamicHeaderData]);

  useEffect(() => {
    const headerName = [];
    finalHeaderData &&
      finalHeaderData.map((name) => {
        headerName.push(name.headerName);
        return "";
      });
    setHeaderColumn(headerName);
  }, [finalHeaderData]);

  const callAPI =(url, actionType)=> {
    props.fetchEditorialTableData(
      url,
      props.filterData,
      1,
      "",
      actionType,
      null,
      null,
      "independent"
    );
  }

  useEffect(() => {
    if(!props.filterData.pageSummaryToggleValue  &&  props.filterData.selectPageSummaryTable){
      // API calling for page summary and it's count
      callAPI(url.pageviewDetails, actionTypes.EDITORIALPAGEVIEWSUMMARY);
      callAPI(url.independentPageSummary, actionTypes.PAGESUMMARYCOUNT);

      props.selEditorialFilterValue(true, actionTypes.PAGESUMMARYTOGGLEVALUE);
      props.clearEditorialSerchedValue(actionTypes.UPDATEPAGESUMMARYLOADER)
    } else if(! props.filterData.selectPageSummaryTable){
      props.clearEditorialSerchedValue(actionTypes.RESETSTATESPAGESUMMARY)
    }
    if(!props.filterData.selectPageSummaryTable){
      props.selEditorialFilterValue(false, actionTypes.PAGESUMMARYTOGGLEVALUE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterData.selectPageSummaryTable, props.isFilApplied]);

  if (props.loading && props.filterData.selectPageSummaryTable) {
    loading = (
      <div className="show-loader">
        <Loader active inline="centered" />
      </div>
    );
  }

  const showMore = async () => {
    props.clearEditorialSort();
    props.showMoreEditorialTableData(
      actionTypes.MOREEDITORIALPAGEVIEWSUMMARYLOADING
    );
    await props.fetchEditorialTableData(
      url.pageviewDetails,
      props.filterData,
      props.PageCount,
      "",
      actionTypes.MOREEDITORIALPAGEVIEWSUMMARY,
      null,
      null,
      "independent"
    );
    props.showMoreEditorialTableData(
      actionTypes.SETEDITORIALPAGEVIEWSUMMARYPGCOUNT
    );
    //setPageCount(pageCount + 1);
  };

  const createRow = (data) => {
    if (data.length > 0) {
      let tableRow = [];
      let totalTableRow = [];
      let grandTotalTableRow = [];
      let totalCount = [];
      let grandTotalCount = [];
      
      const totalPageviewsCount = () => {
        return calculateTotalCount(data, 'page_views_count');
      };

      const totalPrePageviewsCount = () => {
        return calculateTotalCount(data, 'previous_page_views');
      }

      const totalUniquePageviewsCount = () => {
        return calculateTotalCount(data, 'unique_impressions_count');
      };

      const totalPreUniquePageviewsCount = () => {
        return calculateTotalCount(data, 'prev_unique_impressions_count');
      }

      data.forEach((campaignValues, id) => {
        const htmlData = (
          <IndependentPageSummaryRow
            pageSummaryRow={campaignValues}
            headerName={headerColumn}
            conditionPrevious={conditionPrevious}
          />
        );
        tableRow.push(<Table.Row key={id} children={htmlData} />);
      });

      headerColumn && headerColumn.map((name, index) => {
        if(index === 0 && !_.includes(["PAGEVIEWS", "UNIQUE PAGEVIEWS"], name) ) {
          totalTableRow.push(<Table.Cell key={index}>Total: </Table.Cell>)
          && grandTotalTableRow.push(<Table.Cell key={index}>Grand Total: </Table.Cell>);
        } else if(name === "PAGEVIEWS") {
          totalTableRow.push(<Table.Cell key={index} textAlign={conditionPrevious ? "center" : "right"} title={numFormat(totalPageviewsCount())}>{numFormat(totalPageviewsCount())}</Table.Cell>)
          && grandTotalTableRow.push(<Table.Cell textAlign={conditionPrevious ? "center" : "right"}  key={index}>{numFormat(props?.pageSummaryCount?.sum_page_views_count)}</Table.Cell>);
          if(conditionPrevious) {
            totalTableRow.push(<Table.Cell key={index} textAlign="center" title={numFormat(totalPrePageviewsCount())}>{numFormat(totalPrePageviewsCount())}</Table.Cell>)
            && grandTotalTableRow.push(<Table.Cell textAlign="center"  key={index}>{numFormat(props?.pageSummaryCount?.sum_prev_page_views_count)}</Table.Cell>);
          }
        } else if(name === "UNIQUE PAGEVIEWS") {
          totalTableRow.push(<Table.Cell key={index} textAlign={conditionPrevious ? "center" : "right"} title={numFormat(totalUniquePageviewsCount())}>{numFormat(totalUniquePageviewsCount())}</Table.Cell>)
          && grandTotalTableRow.push(<Table.Cell textAlign={conditionPrevious ? "center" : "right"} key={index}>{numFormat(props?.pageSummaryCount?.sum_unique_impressions_count)}</Table.Cell>);
          if(conditionPrevious) {
            totalTableRow.push(<Table.Cell key={index} textAlign="center" title={numFormat(totalPreUniquePageviewsCount())}>{numFormat(totalPreUniquePageviewsCount())}</Table.Cell>)
            && grandTotalTableRow.push(<Table.Cell textAlign="center" key={index}>{numFormat(props?.pageSummaryCount?.sum_prev_unique_impressions_count)}</Table.Cell>);
          }
        } else {
          totalTableRow.push(<Table.Cell key={index}>-</Table.Cell>)
          && grandTotalTableRow.push(<Table.Cell key={index}>-</Table.Cell>);
        }
        return null;
      })

      totalCount.push(<Table.Row key={1} children={totalTableRow} />);
      grandTotalCount.push(<Table.Row key={2} children={grandTotalTableRow} />);
      const finalData = _.concat(tableRow, totalCount, grandTotalCount);

      return finalData;
    }
  };

  if (
    !_.isEmpty(props.editorialPageSummary) &&
    props.filterData.selectPageSummaryTable
  ) {
    tableBody = (
      <Table.Body>{createRow(props.editorialPageSummary)}</Table.Body>
    );
    showMoreButton = (
      <div className="table-divider">
        <Divider>
          <div className="show-more-component">
            {!props.editorialFetchDetailData.resPageSummary ? 
            (
            <button className="show-more-button" onClick={showMore}>
              Show More
            </button>
            ):(
            <button className="show-more-button-disable">
              Show More
            </button>
            )}
            
          </div>
        </Divider>
      </div>
    );
  } else if (
    props.isFilApplied &&
    !props.loading &&
    _.isEmpty(props.editorialPageSummary) &&
    props.filterData.selectPageSummaryTable
  ) {
    noresult = <Message>No results found.</Message>;
  }

  const clickOnSortIcon = () => {
    setSortIcon(!isSortIcon);
  };

  const clickOnSortHeader = (e, extraData, headerData) => {
    if (extraData.checked) {
      setSelectHeaders([...selectHeaders, headerData]);
    } else {
      const removeData = selectHeaders.filter(
        (hName) => hName.headerName !== headerData.headerName
      );
      setSelectHeaders(removeData);
    }
  };

  const applyButtonClick = () => {
    const removeSortIconVal = selectHeaders.filter(
      (rowData) => rowData.headerName !== "SORT_ICON"
    );
    const addLastArr = [
      { headerName: "SORT_ICON", sorted: "", className: "icon" },
    ];
    const allElements = [].concat(removeSortIconVal, addLastArr);

    const constantHeadersArr = [...PageSummaryTableData];
    let finalHeaderArray = [];
    constantHeadersArr.map((mainHeader) => {
      return _.map(allElements, function (selectHeader) {
        if (mainHeader.headerName === selectHeader.headerName) {
          finalHeaderArray.push(selectHeader);
        }
      });
    });

    setFinalHeaderData(finalHeaderArray);
    props.sendTableHeaders(
      "independent_page_summary",
      finalHeaderArray,
      actionTypes.SENDEDITORIALPAGESUMMARYDYANMICTABLEHEADER
    );
    setSortIcon(!isSortIcon);
  };

  return (
    <div>
      <>
        <Form>
          <div className="sorted_icon">
            <Table.HeaderCell style={{ paddingBottom: "20px" }}>
              <Icon
                name="edit"
                style={{ color: `${isSortIcon ? "#f15a29" : "#b2b3b3"}` }}
                size="large"
                onClick={clickOnSortIcon}
              />
            </Table.HeaderCell>
            {isSortIcon && (
              <div
                className="menu-list-items"
                style={{ height: "0px", float: "right" }}
              >
                <Menu vertical className="dynamic_table--list">
                  {Object.values(PageSummaryTableData).map(
                    (tableHeader, index) => (
                      <div key={index}>
                        <Checkbox
                          className="table_header--checkbox"
                          style={{ color: "#838486" }}
                          label={tableHeader.name}
                          checked={
                            selectHeaders &&
                            selectHeaders.some(
                              (el) => el.headerName === tableHeader.headerName
                            )
                              ? true
                              : false
                          }
                          onClick={(e, data) =>
                            clickOnSortHeader(e, data, tableHeader)
                          }
                        />
                      </div>
                    )
                  )}
                  <button
                    className="apply_menu--btn"
                    onClick={applyButtonClick}
                  >
                    Apply
                  </button>
                </Menu>
              </div>
            )}
          </div>
        </Form>
      </>
      <div className="sortable-table-component" style={{ paddingTop: "30px" }}>
        <Table sortable fixed singleLine>
          {!_.isEmpty(finalHeaderData) && (
            <PageviewIndependentTagsTableHeaderRow
              propData={props}
              headerData={finalHeaderData}
              conditionPrevious={conditionPrevious}
              column={props.column}
              direction={props.direction}
              click={props.sortEditorialTableData}
              actionType={actionTypes.SORTEDITORIALPAGESUMMARY}
            />
          )}
          {tableBody}
        </Table>
      </div>
      {loading}
      {showMoreButton}
      {noresult}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    filterData: state.editorialFilterData,
    editorialPageSummary: state.editorialFetchDetailData.editorialPageSummary,
    loading: state.editorialFetchDetailData.isLoadingEditorialPageSummary,
    filStartDate: state.editorialFilterData.startDate,
    filEndDate: state.editorialFilterData.endDate,
    column: state.editorialFetchDetailData.column,
    direction: state.editorialFetchDetailData.direction,
    isFilApplied: state.editorialFetchDetailData.isFilApplied,
    PageCount: state.editorialFetchDetailData.editorialPageSummaryPageCount,
    editorialFilterData: state.editorialFilterData,
    getDynamicHeaderData: state.editorialFetchDetailData.getDynamicEditorialPageSummaryHeaderData,
    editorialFetchDetailData: state.editorialFetchDetailData,
    pageSummaryCount: state.editorialFilterData.pageSummaryCount
  };
};

export default connect(mapStateToProps, {
  sortEditorialTableData,
  fetchEditorialTableData,
  sendTableHeaders,
  getTableHeaders,
  clearEditorialSort,
  showMoreEditorialTableData,
  clearEditorialSerchedValue,
  selEditorialFilterValue
})(EditorialPageSummary);
