import React from "react";
import { Form } from "semantic-ui-react";
import DatePicker from "./datePicker";
import DateRange from "./DateRange";
import * as actionTypes from "../../actions/actionTypes";

const SelectDatePicker = (props) => {
    return (
        <div>
            <Form>
                <Form.Group widths="equal">
                    <DatePicker
                        floating
                        value={props && props.data && props.data.appliedDate}
                        onChange={props && props.selectDateRange}
                        openDateRange={(e, { value }) => {
                            if (value === "Custom Date Range") {
                                document.getElementsByClassName(
                                    "rdrDateInput"
                                )[0].style.display = "none";
                                document.getElementsByClassName(
                                    "rdrDateInput"
                                )[1].style.display = "none";
                                
                                if (props.commonForSelect) {
                                    props.applyDate({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key: "appliedDate", value: "Custom Date Range" });
                                } else {
                                    props && props.applyDate("Custom Date Range");
                                }
                                props && props.setDatePopup("custom-date-popup-open");
                            }
                        }}
                    />
                    <DateRange
                        dateRange={props && props.dateRange}
                        checkSelectState={props && props.checkSelectState}
                        startDate={props && props.data && props.data.startDate}
                        endDate={props && props.data && props.data.endDate}
                    />
                </Form.Group>
            </Form>
        </div>
    )
}

export default SelectDatePicker;