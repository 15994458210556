import React, { useEffect, useState } from "react";
import { Table } from "semantic-ui-react";
import { numFormat } from "../../../utilities/common";
import MultiTagLinks from "../../hyperlinks/multiTagLinks";

const IndependentPageSummaryRow = (props) => {
  const [campaignValues, setCampaignValues] = useState([]);
  const [tableCellData, setTableCellData] = useState([]);

  useEffect(() => {
    setCampaignValues(props.pageSummaryRow);
  }, [props.pageSummaryRow]);

  useEffect(() => {
    getTableCellRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignValues, props.headerName]);

  const getTableCellRows = () => {
    let tableCell = [];
    if (props.headerName.indexOf("PAGE") !== -1) {
      tableCell.push(
        <Table.Cell title={campaignValues.page_title} className="blogs_link space_height">
          <a
            href={`https://${campaignValues.domain}${campaignValues.page_path}`}
            target="blank"
          >
            {campaignValues.page_title}
          </a>
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("PUBLISH DATE") !== -1) {
      tableCell.push(<Table.Cell title={campaignValues.published_date} className="space_height">{campaignValues.published_date}</Table.Cell>);
    }

    if (props.headerName.indexOf("DAY OF WEEK") !== -1) {
      const weekDay = campaignValues.published_weekday?.trim() ?? '';
      tableCell.push(<Table.Cell title={weekDay} className="space_height">{weekDay}</Table.Cell>);
    }

    if (props.headerName.indexOf("DAY SINCE PUBLISHED") !== -1) {
      tableCell.push(<Table.Cell title={campaignValues?.days_since_published?.days} className="space_height">{campaignValues?.days_since_published?.days}</Table.Cell>);
    }

    if (props.headerName.indexOf("DAY SINCE UPDATED") !== -1) {
      tableCell.push(<Table.Cell title={campaignValues?.days_since_updated?.days} className="space_height">{campaignValues?.days_since_updated?.days}</Table.Cell>);
    }

    if (props.headerName.indexOf("AUTHOR") !== -1) {
      tableCell.push(<Table.Cell title={campaignValues.author} className="space_height">{campaignValues.author}</Table.Cell>);
    }
    
    if (props.headerName.indexOf("PAGEVIEWS") !== -1) {
      props.conditionPrevious ?
      tableCell.push(
        <Table.Cell className="space_height" textAlign="center" title={numFormat(campaignValues.page_views_count)}>
          {numFormat(campaignValues.page_views_count)}
        </Table.Cell>,
        <Table.Cell className="space_height" textAlign="center" title={numFormat(campaignValues.previous_page_views)}>
        {numFormat(campaignValues.previous_page_views)}
      </Table.Cell>
      )
      :
      tableCell.push(
        <Table.Cell className="space_height" textAlign="right" title={numFormat(campaignValues.page_views_count)}>
          {numFormat(campaignValues.page_views_count)}
        </Table.Cell>
      );
    }
    if (props.headerName.indexOf("UNIQUE PAGEVIEWS") !== -1) {
      props.conditionPrevious ?
      tableCell.push(
        <Table.Cell className="space_height"  textAlign="center" title={numFormat(campaignValues.unique_impressions_count)}>
          {numFormat(campaignValues.unique_impressions_count)}
        </Table.Cell>,
        <Table.Cell className="space_height"  textAlign="center" title={numFormat(campaignValues.prev_unique_impressions_count)}>
          {numFormat(campaignValues.prev_unique_impressions_count)}
        </Table.Cell>
      )
      :
      tableCell.push(
        <Table.Cell className="space_height"  textAlign="right" title={numFormat(campaignValues.unique_impressions_count)}>
          {numFormat(campaignValues.unique_impressions_count)}
        </Table.Cell>
      );
    }
    if (props.headerName.indexOf("PRIMARY TOPIC") !== -1) {
      tableCell.push(
        campaignValues.primary_topic !== "N/A" ? (
          <Table.Cell title={campaignValues.primary_topic} className="blogs_link">
              <MultiTagLinks
                type="primary"
                object={campaignValues.tags_data}
                refValue={campaignValues.primary_topic}
              ></MultiTagLinks>
            </Table.Cell>
            ) : (
              <Table.Cell title={campaignValues.primary_topic}>{campaignValues.primary_topic}</Table.Cell>
            )
      );
    }

    if (props.headerName.indexOf("TOPIC TAGS") !== -1) {
     // tableCell.push(<Table.Cell className="space_height">{campaignValues.topic_tags}</Table.Cell>);
     tableCell.push(
       campaignValues.topic_tags !== "N/A" ? (
          <Table.Cell title={campaignValues.topic_tags} className="blogs_link">
          <MultiTagLinks
            type="topic"
            object={campaignValues.tags_data}
            refValue={campaignValues.topic_tags}
          ></MultiTagLinks>
          </Table.Cell>
        ) : 
        <Table.Cell title={campaignValues.topic_tags}>{campaignValues.topic_tags}</Table.Cell>
        );
    }
    if (props.headerName.indexOf("ORGANIZATION TAGS") !== -1) {
      tableCell.push(
          campaignValues.organization_tags !== "N/A" ? (
        <Table.Cell title={campaignValues.organization_tags} className="blogs_link">
            <MultiTagLinks
              type="orgs"
              object={campaignValues.tags_data}
              refValue={campaignValues.organization_tags}
            ></MultiTagLinks>
        </Table.Cell>
          ) : (
            <Table.Cell title={campaignValues.organization_tags} >{campaignValues.organization_tags}</Table.Cell>
          )
      );

    }
    if (props.headerName.indexOf("PLACEMENT TAGS") !== -1) {
      //tableCell.push(<Table.Cell className="space_height">{campaignValues.placement_tags}</Table.Cell>);
      tableCell.push(
        campaignValues.placement_tags !== "N/A" ? (
        <Table.Cell title={campaignValues.placement_tags} className="blogs_link">
            <MultiTagLinks
              type="placement"
              object={campaignValues.tags_data}
              refValue={campaignValues.placement_tags}
            ></MultiTagLinks>
        </Table.Cell>
          ) : (
            <Table.Cell title={campaignValues.placement_tags}>{campaignValues.placement_tags}</Table.Cell>
          )
   
      );
    }
    setTableCellData(tableCell);
  };
  return tableCellData;
};

export default IndependentPageSummaryRow;
