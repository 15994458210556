import React from "react";
import { connect } from "react-redux";

const DateRange = (props) => {
  return (
    <div className="ad-graph-date">
        <p>
          {props.filStartDate} - {props.filEndDate}
        </p>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    filStartDate: state.siteReportFilterData.startDate,
    filEndDate: state.siteReportFilterData.endDate,
  };
};

export default connect(mapStateToProps)(DateRange);
