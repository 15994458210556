import * as actionTypes from "../actions/actionTypes";
import {
	getDisplayDate,
	getYesterdaysDate,
	getLastDays,
	getStartEndDates,
	combineIdAndSite
} from "../utilities/common";
import { siteValue } from "../constants";
import _ from "lodash";
import urlParamsReducer from "./urlParamsReducer";
import { TagsReducer } from "./TopicTagsHelper";
import { filterByActions } from "./ActivityCommon";
import createCommonReducer from "./createCommonReducer";
import { getCookie } from "../utilities/envCommon";

const activitySelData = {
	orgs: [],
	campaigns: [],
	order: [],
	engOrgs: [],
	titles: [],
	engMember: [],
	//tags
	orgTagsIn: [],
	orgTagsEx: [],
	topicTagsIn: [],
	topicTagsEx: [],
	placementTagsIn: [],
	placementTagsEx: [],
	//dropdowns
	filterDropdowns: [],
	excludeActions: [],
	includeActions: [],
};

const filterData = {
	startDate: getDisplayDate(getLastDays(0)),
	endDate: getDisplayDate(getYesterdaysDate()),
	appliedDate: "2",
	prevendDate: getDisplayDate(getYesterdaysDate()),
	prevstartDate: getDisplayDate(getLastDays(7)),
	prevAppliedDate: "",
	organization: [],
	campaign: [],
	order: [],
	title: '',
	site: siteValue,
	engagedOrganizations: [],
	globalActivityEngsStatus: "",
	globalActivityEngsVerificationState: "",
	applyDateCalendarState: false,
	activityEngsStatusRowWise: [],
	verificationStateRowWise: [],
	activityEngsCheckboxState: [],
	emailSupression: false,
	overrideActivityReportFilters: true,
	overrideReportingAttributes: false,
	selActivityEngChart: false,
	excludeGatedPageviews: false,
	selUrlSource: "",
	selUrlMedium: "",
	selUrlCampaign: "",
	selUrlTerm: "",
	selUrlContent: "",
	clientDeliverdFilterStateOptions: [],
	activityIsIncludeParent: false,
	activityIsIncludeChild: false,
	selHeaderDropdownOptionValue: "",
	showMoreClicked: false,
	utmParams: false,
	messageBox: true,
	messageBoxRefresh: true,
	trafficType: "all",
	selEnagedMember: [],
	refreshCampaign: "",
	refreshCampaignStatus: false,
	clearColumns: "",
	clearColumnsStatus: false,
	exAnonymousOrgs: false,
	exAnonymousMember: true,
	exInternalEvents: true,
	selTrafficSource: "All",
	selOrgTagsIN: [],
	selOrgTagsEX: [],
	selTopicTagsIN: [],
	selTopicTagsEX: [],
	selPlacementTagsIN: [],
	selPlacementTagsEX: [],
	filterDropdowns: [],
	excludeActions: [],
	includeActions: [],
	diPopulateFilterData: [],
};

const activityFetchData = {
	activityData: {},
	getDynamicHeaderData: [],
	dynamicHeaderArray: [],
	isLoading: false,
	isFilApplied: false,
	postEmailUpdateActivitySummary: "",
	activityCsv: "",
	isLoadingActivityCsv: false,
	column: null,
	direction: null,
	backupActivityData: {},
	postEmailUpdate: "",
	postEmailDetailCsvUpdate: "",
	activityEngActions: [],
	lightPostEmailUpdate: "",
	lightPostEmailDetailCsvUpdate: "",
}

const sortUserActivities = (organization) => {
	return _.map(organization.user_activities, (activity, key) => ({
		key,
		...activity,
	}));
};

const sortedByUserActions = (data, direction, sortKey) => {
	return _.map(data, (organization) => ({
		...organization,
		user_activities: _.orderBy(sortUserActivities(organization), sortKey, direction),
	}));
}
const updateEmailState = (state, action, emailField, emailDetailField) => {
	if (action.payload === "/reports/activity/details") {
	  return {
		...state,
		[emailField]: action.email,
		[emailDetailField]: action.email
	  };
	}
	return state;
};

export const filterDataReducer = (state = filterData, action) => {
	switch (action.type) {
		case actionTypes.SELVALUES:
			return { ...state, [action.payload.key]: action.payload.value };
		case actionTypes.FETCHDELIVERYSTATE:
			return { ...state, clientDeliverdFilterStateOptions: action.payload.delivery_states };
		case actionTypes.SELECTED_TOGGLE:
			return { ...state, [action.payload.key]: action.payload.value };
		case actionTypes.FETCHACTIVITYFILTER:
			let data = action.payload;
			const tags = action.tags
			let dates = data.applied_date_filter
				? getStartEndDates(data.applied_date_filter)
				: state;
			const diDataFilter = getCookie("di_filter_data");
			const element = JSON.parse(diDataFilter);
			const orgUuids = _.map(element?.org_filter, "uuid");
			const engUuids = _.map(element?.eng_filter, "uuid");

			return {
				...state,
				prevstartDate:
					data.applied_date_filter === "Custom Date Range"
						? getDisplayDate(data.start_date)
						: dates.startDate,
				prevendDate:
					data.applied_date_filter === "Custom Date Range"
						? getDisplayDate(data.end_date)
						: dates.endDate,
				campaign: data.campaigns ? data.campaigns : [],
				selUrlSource: data.utm_source,
				selUrlMedium: data.utm_medium,
				selUrlCampaign: data.utm_campaign,
				selUrlTerm: data.utm_term,
				selUrlContent: data.utm_content,
				utmParams: data.utm_filters,
				order: data.orders ? data.orders : [],
				trafficType: data.traffic_filter ? data.traffic_filter : "all",
				selTrafficSource: data.traffic_source ? data.traffic_source : "All",
				selEnagedMember: data?.engaged_members ?? [],
				title: data?.page_title ?? '',
				
				...(!diDataFilter && {
					startDate:
						data.applied_date_filter === "Custom Date Range"
							? getDisplayDate(data.start_date)
							: dates.startDate,
					endDate:
						data.applied_date_filter === "Custom Date Range"
							? getDisplayDate(data.end_date)
							: dates.endDate,
					selTopicTagsIN: combineIdAndSite(tags, data.topic_tags.included_tags),
					selTopicTagsEX: combineIdAndSite(tags, data.topic_tags.excluded_tags),
					selPlacementTagsIN: combineIdAndSite(tags, data.placement_tags.included_tags),
					selPlacementTagsEX: combineIdAndSite(tags, data.placement_tags.excluded_tags),
					selOrgTagsIN: combineIdAndSite(tags, data.organization_tags.included_tags),
					selOrgTagsEX: combineIdAndSite(tags, data.organization_tags.excluded_tags),
					organization: data.organizations ? data.organizations : [],
					site: data.site ? data.site : "all",
					appliedDate: data.applied_date_filter ? data.applied_date_filter : state.appliedDate,
					engagedOrganizations: data.engaged_organizations ? data.engaged_organizations : [],
				}),

				...(diDataFilter && {
					startDate: element.applied_date_filter === "Custom Date Range" ? getDisplayDate(element.start_date) : dates.startDate,
					endDate: element.applied_date_filter === "Custom Date Range" ? getDisplayDate(element.end_date) : dates.endDate,
					selTopicTagsIN: combineIdAndSite(element?.tags, element?.populate_tags_filter?.topic_tags?.included_tags || []),
					selTopicTagsEX: combineIdAndSite(element?.tags, element?.populate_tags_filter?.topic_tags?.excluded_tags || []),
					selPlacementTagsIN: combineIdAndSite(element?.tags, element?.populate_tags_filter?.placement_tags?.included_tags || []),
					selPlacementTagsEX: combineIdAndSite(element?.tags, element?.populate_tags_filter?.placement_tags?.excluded_tags || []),
					selOrgTagsIN: combineIdAndSite(element?.tags, element?.populate_tags_filter?.organization_tags?.included_tags || []),
					selOrgTagsEX: combineIdAndSite(element?.tags, element?.populate_tags_filter?.organization_tags?.excluded_tags || []),
					organization: orgUuids ?? [],
					site: element.site ? element.site : "all",
					appliedDate: element.applied_date_filter ? element.applied_date_filter : state.appliedDate,
					engagedOrganizations: engUuids ?? [],
				}),

				
			};
		case actionTypes.CLEARACTIVITYFILTER:
			return {
				startDate: getDisplayDate(getLastDays(7)),
				endDate: getDisplayDate(getYesterdaysDate()),
				appliedDate: "7",
				prevendDate: getDisplayDate(getYesterdaysDate()),
				prevstartDate: getDisplayDate(getLastDays(7)),
				prevAppliedDate: "",
				organization: [],
				campaign: [],
				order: [],
				title: '',
				site: siteValue,
				engagedOrganizations: [],
				globalActivityEngsVerificationState: "All",
				emailSupression: false,
				excludeGatedPageviews: false,
				selUrlSource: "",
				selUrlMedium: "",
				selUrlCampaign: "",
				selUrlTerm: "",
				selUrlContent: "",
				clientDeliverdFilterStateOptions: action.payload,
				utmParams: false,
				trafficType: "all",
				exAnonymousOrgs: false,
				exAnonymousMembers: true,
				exInternalEvents: true,
				selTrafficSource: "All",
				selEnagedMember: [],
				selOrgTagsIN: [],
				selOrgTagsEX: [],
				selTopicTagsIN: [],
				selTopicTagsEX: [],
				selPlacementTagsIN: [],
				selPlacementTagsEX: [],
				excludeActions: [],
				includeActions: [],
			};
		case actionTypes.REFRESHCAMMPAIGNS:
			return { ...state, refreshCampaign: action.payload, refreshCampaignStatus: action.status };
		case actionTypes.CLEAR_COLUMNS:
			return { ...state, clearColumns: action.payload, clearColumnsStatus: action.status };
		case actionTypes.DI_POPULATE_FILTER_DATA:
			return { ...state, diPopulateFilterData: action.payload };
		default:
			return state;
	}
};

export const activityFetchDataReducer = (state = activityFetchData, action) => {
	switch (action.type) {
		case actionTypes.APPLYACTIVITYFILTER:
			return {
				...state,
				isLoading: true,
				activityData: {},
				isFilApplied: true,
				column: null,
				direction: null,
				backupActivityData: {},
			};
		case actionTypes.ACTIVITYTABLE:
			if (action.payload === null) {
				return { ...state, isLoading: false, activityData: {} };
			} else {
				const data = _.orderBy(action.payload, [(item) => item['organization_other_actions']], ['desc']);
				return {
					...state,
					backupActivityData: sortedByUserActions(data, 'desc', 'user_other_actions'),
					activityData: sortedByUserActions(data, 'desc', 'user_other_actions'),
					isLoading: false,
				};
			}
		case actionTypes.SET_ACTIVITY_ENGAGEMENT:
			return { ...state, activityEngActions: action.payload };
		case actionTypes.RESETACTUALACTIVITYDATA:
			return {
				...state,
				activityData: action.payload,
			}
		case actionTypes.GETHEADER:
			return { ...state, getDynamicHeaderData: action.payload }
		case actionTypes.SETHEADER:
			return { ...state, dynamicHeaderArray: action.payload }
		case actionTypes.ACTIVITYDETAILEXCEL:
			if (_.includes(["/reports/enagements/workbook"], action.payload)) {
				return { ...state, postEmailUpdateActivitySummary: action.email };
			}
		/* falls through */
		case actionTypes.ACTIVITYCSVLOADING:
			return { ...state, isLoadingActivityCsv: true };
		case actionTypes.ACTIVITYCSV:
			return { ...state, activityCsv: action.csv, isLoadingActivityCsv: false };
		case actionTypes.SORTACTIVITYREPORT:
			const dir = state.direction === "ascending" ? "desc" : "asc";
			if (
				action.column === "organization_other_actions" || 
				action.column === "organization_session_count" || 
				action.column === "organization_pageviews" ||
				action.column === "organization_engagement_count"
			) {
				return {
					...state,
					column: action.column,
					activityData: _.orderBy(state.activityData, [(item) => item[action.column]], [dir]),
					direction: state.direction === "ascending" ? "descending" : "ascending"
				}
			} else if (
					action.column === "user_other_actions" ||
					action.column === "user_session_count" ||
					action.column === "user_pageviews" ||
					action.column === "user_engagement_count"
				) {
				return {
					...state,
					column: action.column,
					activityData: sortedByUserActions(state.activityData, dir, action.column),
					direction: state.direction === "ascending" ? "descending" : "ascending"
				}
			}
			return {
				...state,
				column: action.column,
				activityData: state.activityData,
				direction: state.direction === "ascending" ? "descending" : "ascending",
			};
		case actionTypes.APPLYACTIVITACTIONS:
			const { actionValue, numberValue, multiSelectedValue } = action;
			return {
				...state,
				activityData: filterByActions(state.backupActivityData, actionValue, numberValue, multiSelectedValue, state.activityEngActions),
			}
		case actionTypes.ACTIVITYEXCEL:
			return updateEmailState(state, action, 'postEmailUpdate', 'postEmailDetailCsvUpdate');
		case actionTypes.APPLY_LIGHT_ACTIVITY_XLSX:
			return updateEmailState(state, action, 'lightPostEmailUpdate', 'lightPostEmailDetailCsvUpdate');
		case actionTypes.SETACTIVITYPOPUPPOSTEMAIL:
			return{
				...state,
				postEmailUpdate: action.payload
			}
		case actionTypes.SET_LIGHT_ACTIVITY_POPUP_POST_EMAIL:
			return { ...state, lightPostEmailUpdate: action.payload }
		default:
			return state;
	}
}

export const activityTitleReducer = (state = [], action) => {
	switch (action.type) {
	  case actionTypes.FETCHACTIVITYPAGETITLE:
		const orderArray = action.payload.map(item => item.id);
		const mappedObject = _.mapKeys(action.payload, "id");
		return orderArray.map(id => mappedObject[id]);
	  case actionTypes.CLEARACTIVITYPAGETITLE:
		return (state = []);
	  default:
		return state;
	}
  };

export const activityOrgReducer = createCommonReducer(
	activitySelData.orgs,
	{
		FETCH: actionTypes.FETCHACTIVITYORGS,
		ADD: actionTypes.ADDACTIVITYORGS,
		CLEARSEARCH: actionTypes.CLEARACTIVITYORGS,
		SELECTINITIAL: actionTypes.SELINIACTIVITYORGS,
		RESET: actionTypes.RESETACTIVITYORGS,
	},
	"uuid"
);

export const activityCampaignReducer = createCommonReducer(
	activitySelData.campaigns,
	{
		FETCH: actionTypes.FETCHACTIVITYCAMPAIGNS,
		ADD: actionTypes.ADDACTIVITYCAMPAIGNS,
		CLEARSEARCH: actionTypes.CLEARACTIVITYCAMPAIGNS,
		SELECTINITIAL: actionTypes.SELINIACTIVITYCAMPAIGNS,
		RESET: actionTypes.RESETACTIVITYCAMPAIGNS,
	},
	"uuid"
);

export const activityEngOrgReducer = createCommonReducer(
	activitySelData.engOrgs, {
	FETCH: actionTypes.FETCHACTIVITYENGORGS,
	ADD: actionTypes.ADDACTIVITYENGORGS,
	CLEARSEARCH: actionTypes.CLEARACTIVITYENGORGS,
	SELECTINITIAL: actionTypes.SELINIACTIVITYENGORGS,
	RESET: actionTypes.RESETACTIVITYENGORGS,
},
	"uuid"
);

export const activityOrderReducer = createCommonReducer(
	activitySelData.order,
	{
		FETCH: actionTypes.FETCHACTIVITYORDER,
		ADD: actionTypes.ADDACTIVITYORDER,
		CLEARSEARCH: actionTypes.CLEARACTIVITYORDER,
		SELECTINITIAL: actionTypes.SELINIACTIVITYORDER,
		RESET: actionTypes.RESETACTIVITYORDER,
	},
	"uuid"
);

export const activityMemberReducer = createCommonReducer(
	activitySelData.engOrgs, {
	FETCH: actionTypes.FETCHACTIVITYENGMEMBER,
	ADD: actionTypes.ADDACTIVITYMEMBER,
	CLEARSEARCH: actionTypes.CLEARACTIVITYMEMBER,
	SELECTINITIAL: actionTypes.SELINIACTIVITYENGAGEDMEMBER,
	RESET: actionTypes.RESETACTIVITYMEMBER,
},
	"user_uuid"
);

export const activityActionsReducer = createCommonReducer(
	activitySelData.order,
	{
		FETCH: actionTypes.FETCHACTIVITYACTION,
		ADD: actionTypes.ADDACTIVITYACTIONS,
		CLEARSEARCH: actionTypes.CLEARACTIVITYACTIONS,
		// RESET: actionTypes.RESETACTIVITYORDER,
	},
	"value"
);

export const activityIncludeActionsReducer = createCommonReducer(
	activitySelData.order,
	{
		FETCH: actionTypes.FETCH_INCLUDE_ACTION_ACTIVITY,
		ADD: actionTypes.ADD_INCLUDE_ACTION_ACTIVITY,
		SELECT: actionTypes.SELECT_INCLUDE_ACTION_ACTIVITY,
		CLEARSEARCH: actionTypes.CLEAR_INCLUDE_ACTION_ACTIVITY,
	},
	"value"
);

export const activityExcludeActionsReducer = createCommonReducer(
	activitySelData.order,
	{
		FETCH: actionTypes.FETCH_EXCLUDE_ACTION_ACTIVITY,
		ADD: actionTypes.ADD_EXCLUDE_ACTION_ACTIVITY,
		SELECT: actionTypes.SELECT_EXCLUDE_ACTION_ACTIVITY,
		CLEARSEARCH: actionTypes.CLEAR_EXCLUDE_ACTION_ACTIVITY,
	},
	"value"
);

// URL params reducer
export const urlActivitySourceReducer = urlParamsReducer(
	{
		FETCH: actionTypes.FETCH_ACTIVITY_SOURCE,
		SELECT: actionTypes.SELINI_ACTIVITY_UTMSOURCE,
		CLEAR: actionTypes.CLEARSEARCH_ACTIVITY_URLSOURCE
	},
	'utm_source'
);

export const urlActivityMediumReducer = urlParamsReducer(
	{
		FETCH: actionTypes.FETCH_ACTIVITY_MEDIUM,
		SELECT: actionTypes.SELINI_ACTIVITY_MEDIUM,
		CLEAR: actionTypes.CLEARSEARCH_ACTIVITY_MEDIUM
	},
	'utm_medium'
);

export const urlActivityCampaignsReducer = urlParamsReducer(
	{
		FETCH: actionTypes.FETCH_ACTIVITY_CAMPAIGN,
		SELECT: actionTypes.SELINI_ACTIVITY_CAMPAIGN,
		CLEAR: actionTypes.CLEARSEARCH_ACTIVITY_CAMPAIGN
	},
	'utm_campaign'
);

export const urlActivityTermReducer = urlParamsReducer(
	{
		FETCH: actionTypes.FETCH_ACTIVITY_TERM,
		SELECT: actionTypes.SELINI_ACTIVITY_TERM,
		CLEAR: actionTypes.CLEARSEARCH_ACTIVITY_TERM
	},
	'utm_term'
);

export const urlActivityContentReducer = urlParamsReducer(
	{
		FETCH: actionTypes.FETCH_ACTIVITY_CONTENT,
		SELECT: actionTypes.SELINI_ACTIVITY_CONTENT,
		CLEAR: actionTypes.CLEARSEARCH_ACTIVITY_CONTENT
	},
	'utm_content'
);

export const activityTopicTagsInReducer = TagsReducer(
	activitySelData.topicTagsIn,
	{
		FETCH: actionTypes.FETCHACTIVITYTOPICTAGSIN,
		ADD: actionTypes.ADDACTIVITYTOPICTAGSIN,
		CLEARSEARCH: actionTypes.CLEARACTIVITYTOPICTAGSIN,
		SELECTINITIAL: actionTypes.SELINIACTIVITYTOPICTAGSIN,
		RESET: actionTypes.RESETACTIVITYTOPICTAGSIN,
	}
);

export const activityTopicTagsExReducer = TagsReducer(
	activitySelData.topicTagsEx,
	{
		FETCH: actionTypes.FETCHACTIVITYTOPICTAGSEX,
		ADD: actionTypes.ADDACTIVITYTOPICTAGSEX,
		CLEARSEARCH: actionTypes.CLEARACTIVITYTOPICTAGSEX,
		SELECTINITIAL: actionTypes.SELINIACTIVITYTOPICTAGSEX,
		RESET: actionTypes.RESETACTIVITYTOPICTAGSEX,
	}
);

export const activityPlacementTagsInReducer = TagsReducer(
	activitySelData.placementTagsIn,
	{
		FETCH: actionTypes.FETCHACTIVITYPLACEMENTTAGSIN,
		ADD: actionTypes.ADDACTIVITYPLACEMENTTAGSIN,
		CLEARSEARCH: actionTypes.CLEARACTIVITYPLACEMENTTAGSIN,
		SELECTINITIAL: actionTypes.SELINIACTIVITYPLACEMENTTAGSIN,
		RESET: actionTypes.RESETACTIVITYPLACEMENTTAGSIN,
	}
);

export const activityPlacementTagsExReducer = TagsReducer(
	activitySelData.placementTagsEx,
	{
		FETCH: actionTypes.FETCHACTIVITYPLACEMENTTAGSEX,
		ADD: actionTypes.ADDACTIVITYPLACEMENTTAGSEX,
		CLEARSEARCH: actionTypes.CLEARACTIVITYPLACEMENTTAGSEX,
		SELECTINITIAL: actionTypes.SELINIACTIVITYPLACEMENTTAGSEX,
		RESET: actionTypes.RESETACTIVITYPLACEMENTTAGSEX,
	}
);

export const activityOrganizationTagsInReducer = TagsReducer(
	activitySelData.orgTagsIn,
	{
		FETCH: actionTypes.FETCHACTIVITYORGANIZATIONTAGSIN,
		ADD: actionTypes.ADDACTIVITYORGANIZATIONTAGSIN,
		CLEARSEARCH: actionTypes.CLEARACTIVITYORGANIZATIONTAGSIN,
		SELECTINITIAL: actionTypes.SELINIACTIVITYORGANIZATIONTAGSIN,
		RESET: actionTypes.RESETACTIVITYORGANIZATIONTAGSIN,
	}
);

export const activityOrganizationTagsExReducer = TagsReducer(
	activitySelData.orgTagsEx,
	{
		FETCH: actionTypes.FETCHACTIVITYORGANIZATIONTAGSEX,
		ADD: actionTypes.ADDACTIVITYORGANIZATIONTAGSEX,
		CLEARSEARCH: actionTypes.CLEARACTIVITYORGANIZATIONTAGSEX,
		SELECTINITIAL: actionTypes.SELINIACTIVITYORGANIZATIONTAGSEX,
		RESET: actionTypes.RESETACTIVITYORGANIZATIONTAGSEX,
	}
);
