const pageFirmoGraphic = (
    filterData,
    firmographicSector,
    firmographicIndustryGroup,
    firmographicIndustry,
    firmographicSubIndustry,
    firmographicOrgsType,
    firmographicOrgSize,
    firmographicOrgsRevenue,
    isRevenueVisible
) => {
    const firmographicFilters = [
        {
            value: filterData.selSector,
            label: "Sector",
            placeholder: "Sector",
            options: firmographicSector.map((sector, index) => ({
                key: index,
                text: sector.sector,
                value: sector.sector,
            })),
            answerKey: "sector",
        },
        {
            value: filterData.selIndustryGroup,
            label: "Industry Group",
            placeholder: "Industry Group",
            options: firmographicIndustryGroup.map((industryGroup, index) => ({
                key: index,
                text: industryGroup.industry_group,
                value: industryGroup.industry_group,
            })),
            answerKey: "industry_group",
        },
        {
            value: filterData.selIndustry,
            label: "Industry",
            placeholder: "Industry",
            options: firmographicIndustry.map((industry, index) => ({
                key: index,
                text: industry.industry,
                value: industry.industry,
            })),
            answerKey: "industry",
        },
        {
            value: filterData.selSubIndustry,
            label: "Sub Industry",
            placeholder: "Sub Industry",
            options: firmographicSubIndustry.map((subIndustry, index) => ({
                key: index,
                text: subIndustry.sub_industry,
                value: subIndustry.sub_industry,
            })),
            answerKey: "sub_industry",
        },
        {
            value: filterData.selOrgType,
            label: "Organization Type",
            placeholder: "Organization Type",
            options: firmographicOrgsType.map((orgType, index) => ({
                key: index,
                text: orgType.label,
                value: orgType.value,
            })),
            answerKey: "org_type",
        },
        {
            classname: "empty_dropdown",
            isEmpty: true
        },
        {
            value: filterData.selOrgSize,
            label: "Organization Size Range",
            placeholder: "Organization Size Range",
            options: firmographicOrgSize.map((orgSize, index) => ({
                key: index,
                text: orgSize.label,
                value: orgSize.value,
            })),
            answerKey: "org_size",
        }
    ];

    // Conditionally add Organization Revenue Range if isRevenueVisible is true
    if (isRevenueVisible) {
        firmographicFilters.push({
            value: filterData.selOrgRevenue,
            label: "Organization Revenue Range",
            placeholder: "Organization Revenue Range",
            options: firmographicOrgsRevenue.map((orgRevenue, index) => ({
                key: index,
                text: orgRevenue.label,
                value: orgRevenue.value,
            })),
            answerKey: "org_revenue_range",
        });
    }

    return firmographicFilters;
};

export default pageFirmoGraphic;
