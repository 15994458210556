import React from "react";
import Chart from "react-google-charts";
import { connect } from "react-redux";
import { decimalFormat } from "../../../utilities/common";

const ClientLeadQualificationChart = (props) => {
  const colors = ["#33AC2E", "#D63649"];
  const keyNames = ["Accepted", "Rejected"];

  const getSeries = () => {
    let series = {};
    for (let i = 0; i < keyNames.length; i++) {
      let j = i;
      let color;
      color = colors[i];
      series[j] = { color: color };
    }
    return series;
  };

  const leadAcceptanceBarChart = () => {
    var data = [];
    let d = [];
    let acceptedPercentage =
      props.leadAcceptanceBarChart?.leads_qualification?.accepted_percentage;
    let rejectedPercentage =
      props.leadAcceptanceBarChart?.leads_qualification?.rejected_percentage;
    data.push([
      "",
      { type: "string", role: "tooltip", p: { html: true } },
      "Accepted",
      "Rejected",
    ]);
    d = [];
    d.push("");
    d.push(
      `<div class="tooltip-pageview-values" style='width: 180px; padding-top: 20px; padding-left: 10px;'>` +
        "<span style='height: 6px; width: 6px; margin-right: 5px; background-color:#33AC2E;border-radius: 50%; display: inline-block;'></span>" +
        decimalFormat(acceptedPercentage) +
        "% " +
        ` - Accepted` +
        `<br />` +
        "<span style='height: 6px; width: 6px; margin-right: 5px; background-color: #D63649;border-radius: 50%; display: inline-block;'></span>" +
        decimalFormat(rejectedPercentage) +
        "% " +
        ` - Rejected` +
        `</div>`
    );
    d.push(
      props.leadAcceptanceBarChart?.leads_qualification?.accepted_percentage
    );
    d.push(
      props.leadAcceptanceBarChart?.leads_qualification?.rejected_percentage
    );
    data.push(d);

    return data;
  };

  const options = {
    // colors: ["#33AC2E", "#D63649"],
    legend: "none",
    chartArea: { left: "2%", top: "15%", width: "92%", height: "26%"},
    vAxis: {
      format: "short",
      minValue: 0,
      baselineColor: "#B6B7B8",
    },
    isStacked: true,
    hAxis: {
      baselineColor: "#fff",
      gridlineColor: "#fff",
    },
    crosshair: {
      orientation: "vertical",
      color: "grey",
      opacity: 2,
      trigger: "both",
    },
    tooltip: { isHtml: true },
    focusTarget: "category",
    series: getSeries(),
  };
  return (
    <div
      className="lead-validation-barchart-qualification bar-chart-qualification-fill"
      style={{ height: "201px" }}
    >
      <div>
        <div>
          <div
            style={{
              position: "relative",
              marginLeft: "-4rem",
              marginTop: "20px",
            }}
          >
            <div className="legend-item" style={{ paddingLeft: 30 }}>
              <span
                style={{ backgroundColor: "#33AC2E"}}
                className="legend-circle"
              />
              <text
                style={{ color: "#656669", marginRight: 40 }}
                className="topic-text"
              >
                Accepted ({props.leadAcceptanceBarChart?.leads_qualification?.total_accepted_count})
              </text>
            </div>

            <div className="legend-item" style={{ marginLeft: -35 }}>
              <span
                style={{ backgroundColor:"#D63649" }}
                className="legend-circle"
              />
              <text
                style={{ color: "#656669", marginRight: 50 }}
                className="topic-text"
              >
                Rejected ({props.leadAcceptanceBarChart?.leads_qualification?.total_rejected_count})
              </text>
            </div>
          </div>
        </div>
        <Chart
          chartType="BarChart"
          width="100%"
          height="170px"
          data={leadAcceptanceBarChart()}
          options={options}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    filStartDate: state.clientLeadFilterData.startDate,
    filEndDate: state.clientLeadFilterData.endDate,
    leadAcceptanceBarChart: state.clientLeadFetchData.leadAcceptanceBarChart,
    isFilApplied: state.clientLeadFetchData.isFilApplied,
  };
};

export default connect(mapStateToProps)(ClientLeadQualificationChart);
