import _ from "lodash";

export const TagsReducer = (initialData, actionTypes) => (state = initialData, action) => {
    switch (action.type) {
		case actionTypes.FETCH:
			action.selected.forEach(function (id) {
				action.payload.forEach((tag) => {
					if (tag.key === id) {
						tag.disabled = true;
					}
				});
			});
			return { ...state, ..._.mapKeys(action.payload, "key") };

		case actionTypes.ADD:
			initialData = [];
			const ids = action.payload;
			ids.forEach(function (id) {
				initialData.push(state[id]);
			});
			return {...state, ..._.mapKeys(initialData, "key") };

		case actionTypes.CLEARSEARCH:
			return { ...state, ..._.mapKeys(initialData, "key") };

		case actionTypes.SELECTINITIAL:
			initialData = action.data.map(tag => ({
				...tag,
				key: `${tag.id}|${tag.domain}`
			}));
			return { ..._.mapKeys(initialData, "key") };

		case actionTypes.RESET:
			initialData = [];
			return initialData;

		default:
			return state;
	}
}