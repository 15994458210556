import _ from "lodash";
import React, {useRef} from "react";
import { connect } from "react-redux";
import { Checkbox, Loader } from "semantic-ui-react";
import { selEditorialToggles } from "../../actions";
import * as actionTypes from "../../actions/actionTypes";
import { getCapital, getDisplayDateChart } from "../../utilities/common";
import DownloadChart from "../common/downloadChart";
import EditorialGraph from "./editorialGraph";

const EditorialChart = (props) => {
  const chartNameDiv = useRef(null);
  let loading;
  if (props.loading) {
    loading = (
      <div className="show-loader">
        <Loader active inline="centered" />
      </div>
    );
  }
  return (
    <>
      <div className="ad-graph">
        <div ref={chartNameDiv}>
        {!_.isEmpty(props.editorialCharts) && props.editorialFilterData.showEditorialChart &&
          <DownloadChart
            chartNameDiv= {chartNameDiv.current}
            chartName = {(`Independent-Chart-${getDisplayDateChart(new Date())}.jpg`)}
            style={{width:"36px", height: "36px", marginTop: 19, marginRight: 56, color: "#838486" }}
          />
        }
          <p className="ad-chart-name">
            {getCapital("Total Pageviews Per Day")}
            <Checkbox
              className="checkbox-label"
              data-html2canvas-ignore="true"
              // slider
              toggle
              style={{
                color: "white",
                fontFamily: "Roboto",
                fontSize: "16px",
                paddingLeft: "20px",
                minHeight: 15,
              }}
              label={
                props.editorialFilterData.showEditorialChart ? "Hide" : "Show"
              }
              onChange={() => {
                props.selEditorialToggles(actionTypes.SHOWEDITORIALCHARTS);
              }}
              checked={props.editorialFilterData.showEditorialChart}
              disabled={!props.isFilApplied}
            />

            {props.isFilApplied && props.editorialFilterData.showEditorialChart &&
              loading}
            {!_.isEmpty(props.editorialCharts) && props.editorialFilterData.showEditorialChart && (
                <EditorialGraph />
              )}
          </p>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    editorialPageSummary: state.editorialFetchDetailData.editorialPageSummary,
    isFilApplied: state.editorialFetchDetailData.isFilApplied,
    filStartDate: state.editorialFilterData,

    editorialFilterData: state.editorialFilterData,
    editorialCharts: state.editorialFetchDetailData.editorialCharts,
    sponsoredContentCharts: state.editorialFetchDetailData
      .sponsoredContentCharts
      ? state.editorialFetchDetailData.sponsoredContentCharts
      : [],
    loading: state.editorialFetchDetailData.isLoadingChart,
  };
};

export default connect(mapStateToProps, { selEditorialToggles })(
  EditorialChart
);
