import _ from "lodash";
import { connect } from "react-redux";
import { compose } from "redux";

import { getCSMAuthors, selectValue, addFilterById, clearEditorialSerchedValue } from "../../actions";
import MultipleSelectDropDown from "../base/multiSelectDropdown";

const CSMSelection = (props) => {

    const onSearchHandler = (e) => {
        if (e.target.value.length > 1) {
            props.getCSMAuthors(e.target.value, props.FETCH);
        }
    };

    const onChangeHandler = (e, { value }) => {
        props.selectValue({ dispatchType: props.SELECT, key: "setCSM", value });
        props.addFilterById(value, props.ADD);
    };

    const onClickHandler = () => {
        if (_.isEmpty(props.value)) {
            props.clearEditorialSerchedValue(props.CLEAR);
        }
    };

    return (
        <MultipleSelectDropDown
            {...props}
            options={props?.authors.map((csm, index) => ({
                key: index,
                text: csm.name,
                value: csm.id
            }))}
            onSearchChange={onSearchHandler}
            onChange={onChangeHandler}
            onClick={onClickHandler}
            value={props.value}
            label="CSM"
            placeholder="CSM"
        />
    );
};

export default compose(connect(null, {
    getCSMAuthors,
    selectValue,
    addFilterById,
    clearEditorialSerchedValue,
}))(CSMSelection);
