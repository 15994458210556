import React from "react";
import { Table } from "semantic-ui-react";
import Link from "../../../hyperlinks/link";

const AdOrderComponent = ({ data }) => {
    return (
        data.ad_order_uuid === "N/A" ?
            <Table.Cell className="space_height"> {"N/A"}</Table.Cell> 
            :
            <Table.Cell title={data.ad_order} className="blogs_link space_height">
                {data.ad_order_uuid ? (
                    <Link
                        type={"ads"}
                        urlParam={data.ad_order_uuid}
                        refValue={data.ad_order}
                    ></Link>
                ) : (
                    ""
                )}
            </Table.Cell>
    );
};

export default AdOrderComponent;
