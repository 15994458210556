import React, { useEffect, useRef, useState } from "react";
import { Form } from "semantic-ui-react";
import DatePicker from "../common/datePicker";
import * as actionTypes from "../../actions/actionTypes";
import { connect } from "react-redux";
import {
  selEditorialFilterValue,
  clearSearchOrder,
  fetchOrder,
  fetchCampaigns,
  addOrganization,
  selectedEditorialFilterValue,
  fetchOrganizations,
  clearSearchOrgs,
  addOrder,
  addCampaigns,
  clearSearchCampaigns,
  resetOrgsOrCampaigns,
  clearEditorialSerchedValue,
  fetchPgAuthors,
  fetchTags,
  addCountries,
  fetchPgCountry,
  firmographicOrgsTypeCall,
  selEditorialToggles,
  SenioritiesList,
  fetchPageTitle,
  fetchUrlParams,
  fetchPrimaryTopic,
  fetchEngagedMember,
  applyEditorialFilter,
  fetchEditorialTableData,
  fetchEngagedOrg,
  checkToggle,
  applyIndependentDate,
  fetchPageviewTableData,
  updateCommonData
} from "../../actions";
import { getDisplayDate, getStartEndDates, getYesterdaysDate } from "../../utilities/common";
import { differenceInDays, subDays, subYears } from "date-fns";
import { DateRangePicker } from "react-date-range";
// import OrganizationPicker from "../common/organization";
// import OrderPicker from "../common/order";
import _ from "lodash";
// import StatusPicker from "../common/status";
// import CampaignPicker from "../common/campaign";
import AuthorPicker from "../common/author";
import Site from "../common/site";
import TagsPicker from "../common/tags";
import CountryMultiSelectPicker from "../common/countryPicker";
import EngagedOrganization from "../common/engagedOrganization";
import Toggle from "../base/toggle";
import FirmographicFilter from "../common/firmographicFilter";
import PageFirmoGraphic from "../config/FilterFirmogaphicData";
import PersonaFilter from "../common/personaFilter";
import FilterDateRange from "./filterDateRange";
import SingleSelectDropDown from "../base/singleSelectDropDown";
import UrlParamsPicker from "../common/urlParams";
import PrimaryTopic from "../common/primaryTopic";
import EngagedMember from "../common/engagedMember";
import { url } from "../../api/urlHelper";
import TrafficSource from "../common/trafficSource";
import TrafficFilterPicker from "../common/trafficeFilter";
import PageviewType from "../common/PageviewType";
import CustomDatePickerWithCompare from "../common/CustomDatePickerWithCompare";
import SearchFilterIcon from "../common/SearchFilterIcon";
import SelectDatePicker from "../common/SelectDatePicker";
import TimeOfDay from "../common/TimeOfDay";
import DayOfWeek from "../common/DayOfWeek";
import MultipleSelectDropDown from "../base/multiSelectDropdown";
import { getCompareDateWithOtherFunction } from "../../utilities/Calendar";
import { PUBLISHED_DATE_RANGE, VIEW } from "../config/const";
import { ORGANIZATION_TAG_SUMMARY, PAGE_SUMMARY, PAGEVIEW_DETAILS_TABLE, PLACEMENT_TAG_SUMMARY, PRIMARY_TOPIC_SUMMARY, TOPIC_TAG_SUMMARY } from "../config/rolebasedReportNames";

const SearchFilter = (props) => {
  const [datePopup, setDatePopup] = useState("custom-date-popup-close");
  const [filterPopup, setFilterPopup] = useState("filter-popup-close");
  const [filterSelectFocus, setFilterSelectFocus] = useState("filter-icon");
  const [filterIconColor, setFilterIconColor] = useState("material-icons grey");
  const [senioritiesIN, setSenioritiesIn] = useState([]);
  const [senioritiesEX, setSenioritiesEX] = useState([]);
  const [JobFunctionsIN, setJobFunctionsIN] = useState([]);
  const [JobFunctionsEX, setJobFunctionsEX] = useState([]);
  const [checkSelectState, setCheckSelectState] = useState(false);
  const [comparePeriod, setComparePeriod] = useState("Previous Period");
  const [orgType, setOrgType] = useState([]);
  const [orgSize, setOrgSize] = useState([]);
  const [orgRevenue, setOrgRevenue] = useState([]);
  const [sector, setSector] = useState([]);
  const [industryGroup, setIndustryGroup] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [subIndustry, setSubIndustry] = useState([]);
  const tempSelection = useRef({});
  const clearfilterClick = useRef(false);
  const [selectDayOfWeek, setSelectDayOfWeek] = useState([]);
  const [selectPageviewRanges, setSelectPageviewRanges] = useState([]);
  const [datePopupFilter, setDatePopupFilter] = useState(
    "custom-date-popup-close"
  );

  //Date range event handling and state management
  const [dateRange, setDateRange] = useState({
    selection: {
      startDate: getYesterdaysDate(),
      endDate: getYesterdaysDate(),
      key: "selection",
      //color:"#e5582b",
    },
  });

  const [dateRangeFilter, setDateRangeFilter] = useState({
    selection: {
      startDate: getYesterdaysDate(),
      endDate: getYesterdaysDate(),
      key: "selection",
      //color:"#e5582b",
    },
  });

  useEffect(() => {
    if (
      document.getElementsByClassName("rdrDateDisplayWrapper")?.length !== 0
    ) {
      document.getElementsByClassName(
        "rdrDateDisplayWrapper"
      )[0].style.display = "none";
    }
  }, [checkSelectState]);

  useEffect(()=>{
    if (props.isFilApplied && props.editorialFilterData.showEditorialChart) {
      props.fetchEditorialTableData(
        url.pageviewReportCharts,
        props.editorialFilterData,
        1,
        "",
        actionTypes.EDITORIALCHARTS,
        null,
        null,
        "independent"
      );
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.isFilApplied, props.editorialFilterData.showEditorialChart]);

  const previousPeriodData = (startDate, endDate, comparePeriod) => {
    props.selEditorialFilterValue(
      checkSelectState,
      actionTypes.EDITORIALCUSTOMRANGE
    );
    let prevStartDate, prevEndDate;
    
    if (comparePeriod === "Previous Year") {
      prevStartDate = subYears(new Date(startDate), 1);
      prevEndDate = subYears(new Date(endDate), 1);
    } else if (_.includes(["7", "30", "90"], comparePeriod)) {
      const result = getCompareDateWithOtherFunction(updateCommonData, actionTypes.SET_PAGEVIEW_VALUES, comparePeriod);
      startDate = new Date(result.startDate);
      endDate = new Date(result.endDate);
      prevStartDate = new Date(result.prevStartDate);
      prevEndDate = new Date(result.prevEndDate);
  } else if (comparePeriod === "Previous Day of Week") {
      endDate = startDate;
      prevStartDate = subDays(startDate, 7); // Subtract 7 days to get the previous occurrence of the same day
      prevEndDate = subDays(startDate, 7); // Similarly, adjust end date for consistency
  } else {
      let differenceinDays = differenceInDays(endDate, startDate);
      prevEndDate = subDays(new Date(startDate), 1);
      prevStartDate = subDays(new Date(prevEndDate), differenceinDays);
    }
    setDateRange({
      selection1: {
        startDate: startDate,
        endDate: endDate,
        key: "selection1",
        color: "#e5582b",
      },
      selection2: getCompareState(prevStartDate, prevEndDate),
    });
    return cutomDate(prevStartDate, prevEndDate);
  };

  const getCompareState = (prevStartDate, prevEndDate) => {
    return {
      startDate: prevStartDate,
      endDate: prevEndDate,
      key: "selection2",
      color: "#CC167F",
    };
  };

  const getClearDate = () => {
    setCheckSelectState(false);
    let sel = dateRange.selection1;
    sel.key = "selection";
    setDateRange({
      selection: sel,
    });
  };

  const cutomDate = (startDate, endDate) => {
    return getDisplayDate(startDate) + " - " + getDisplayDate(endDate);
  };

  const selectDateRange = (e, { value }) => {
    props.selEditorialFilterValue(false, actionTypes.EDITORIALCUSTOMRANGE);
    props.selEditorialFilterValue(value, actionTypes.APPLYEDITORIALDATE);
    setDatePopup("custom-date-popup-close");
    if (value === "Custom Date Range") {
      setDatePopup("custom-date-popup-open");
    } else {
      let dates = getStartEndDates(value);
      props.selEditorialFilterValue(
        dates.startDate,
        actionTypes.SELEDITORIALSTARTDATE
      );
      props.selEditorialFilterValue(
        dates.endDate,
        actionTypes.SELEDITORIALENDDATE
      );
      cancelDate();
      if (props.isFilApplied) {
        applyFilters(dates.startDate, dates.endDate, false);
      }
    }
  };

  const applyDate = () => {
    props.selEditorialFilterValue(checkSelectState, actionTypes.EDITORIALCUSTOMRANGE);
    if (dateRange.hasOwnProperty("selection")) {
      tempSelection.current = dateRange.selection;
      props.selEditorialFilterValue(
        getDisplayDate(tempSelection.current?.startDate),
        actionTypes.SELEDITORIALSTARTDATE
      );
      props.selEditorialFilterValue(
        getDisplayDate(tempSelection.current?.endDate),
        actionTypes.SELEDITORIALENDDATE
      );
    } else if (dateRange.hasOwnProperty("selection1")) {
      tempSelection.current = dateRange.selection1;
      props.selEditorialFilterValue(
        getDisplayDate(dateRange?.selection1?.startDate),
        actionTypes.SELEDITORIALSTARTDATE
      );
      props.selEditorialFilterValue(
        getDisplayDate(dateRange?.selection1?.endDate),
        actionTypes.SELEDITORIALENDDATE
      );
      props.selEditorialFilterValue(
        getDisplayDate(dateRange?.selection2?.startDate),
        actionTypes.SELEDITORIALPREVSTARTDATE
      );
      props.selEditorialFilterValue(
        getDisplayDate(dateRange?.selection2?.endDate),
        actionTypes.SELEDITORIALPREVENDDATE
      );
    }
    props.editorialFilterData.previousStartDate = getDisplayDate(
      dateRange?.selection2?.startDate
    );
    props.editorialFilterData.previousEndDate = getDisplayDate(
      dateRange?.selection2?.endDate
    );
    props.editorialFilterData.previousStartDate = getDisplayDate(
      dateRange?.selection2?.startDate
    );
    props.editorialFilterData.previousEndDate = getDisplayDate(
      dateRange?.selection2?.endDate
    );
    setDatePopup("custom-date-popup-close");

    if (props.isFilApplied) {
      applyFilters(
        tempSelection.current.startDate,
        tempSelection.current.endDate,
        checkSelectState
      );
    }
  };

  const cancelDate = () => {
    setCheckSelectState(false);
    setDateRange({
      selection: {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    });
    setDatePopup("custom-date-popup-close");
  };

  const onClickAuthor = () => {
    if (_.isEmpty(props.editorialFilterData.selAuthor)) {
      props.clearEditorialSerchedValue(actionTypes.CLEARSEARCHEDITORIALAUTHOR);
    }
  };

  const searchAuthor = (e, { value }) => {
    if (e.target.value.length > 1) {
      props.fetchPgAuthors(e.target.value, actionTypes.FETCHEDITORIALAUTHOR);
    } else {
      props.clearEditorialSerchedValue(actionTypes.CLEARSEARCHEDITORIALAUTHOR);
    }
  };

  const onSelectAuthor = (e, { value }) => {
    props.selectedEditorialFilterValue({ key: "selAuthor", value });
  };

  const onSelectSite = (e, { value }) => {
    props.selectedEditorialFilterValue({ key: "site", value });
  };

  //Topic tags event handling and state management

  const onClickTopicTagIn = () => {
    props.clearEditorialSerchedValue(
      actionTypes.CLEARSEARCHEDITORIALTOPICTAGIN
    );
  };

  const searchTopicTagsIn = (e) => {
    if (e.target.value.length > 1) {
      props.fetchTags(
        e.target.value,
        "topic",
        actionTypes.FETCHEDITORIALTOPICTAGSIN,
        props.editorialFilterData.selTopicTagsEX,
        props.editorialFilterData.site
      );
    }
  };

  const onSelectTopicTagsIn = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.editorialFilterData.selTopicTagsEX.indexOf(selectedValue) === -1){
    props.selEditorialFilterValue(value, actionTypes.APPLYEDITORIALTOPICTAGSIN);
    props.selectedEditorialFilterValue({ key: "selTopicTagsIN", value });
    }
  };

  const onClickTopicTagEx = () => {
    props.clearEditorialSerchedValue(
      actionTypes.CLEARSEARCHEDITORIALTOPICTAGEX
    );
  };

  const searchTopicTagsEx = (e) => {
    if (e.target.value.length > 1) {
      props.fetchTags(
        e.target.value,
        "topic",
        actionTypes.FETCHEDITORIALTOPICTAGSEX,
        props.editorialFilterData.selTopicTagsIN,
        props.editorialFilterData.site
      );
    }
  };

  const onSelectTopicTagsEx = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.editorialFilterData.selTopicTagsIN.indexOf(selectedValue) === -1){
    props.selEditorialFilterValue(value, actionTypes.APPLYEDITORIALTOPICTAGSEX);
    props.selectedEditorialFilterValue({ key: "selTopicTagsEX", value });
    }
  };

  //Placement tags event handling and state management

  const onClickPlacementTagIn = () => {
    props.clearEditorialSerchedValue(
      actionTypes.CLEARSEARCHEDITORIALPLACEMENTTAGIN
    );
  };

  const searchPlacementTagsIn = (e) => {
    if (e.target.value.length > 1) {
      props.fetchTags(
        e.target.value,
        "placement",
        actionTypes.FETCHEDITORIALPLACEMENTTAGSIN,
        props.editorialFilterData.selPlacementTagsEX,
        props.editorialFilterData.site
      );
    }
  };

  const onSelectPlacementTagsIn = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.editorialFilterData.selPlacementTagsEX.indexOf(selectedValue) === -1){
    props.selEditorialFilterValue(value,actionTypes.APPLYEDITORIALPLACEMENTTAGSIN);
    props.selectedEditorialFilterValue({ key: "selPlacementTagsIN", value });
    }
  };

  const onClickPlacementTagEx = () => {
    props.clearEditorialSerchedValue(
      actionTypes.CLEARSEARCHEDITORIALPLACEMENTTAGEX
    );
  };

  const searchPlacementTagsEx = (e) => {
    if (e.target.value.length > 1) {
      props.fetchTags(
        e.target.value,
        "placement",
        actionTypes.FETCHEDITORIALPLACEMENTTAGSEX,
        props.editorialFilterData.selPlacementTagsIN,
        props.editorialFilterData.site
      );
    }
  };

  const onSelectPlacementTagsEx = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.editorialFilterData.selPlacementTagsIN.indexOf(selectedValue) === -1){
    props.selEditorialFilterValue(value,actionTypes.APPLYEDITORIALPLACEMENTTAGSEX);
    props.selectedEditorialFilterValue({ key: "selPlacementTagsEX", value });
    }
  };

  //Org tags event handling and state management

  const onClickOrgTagIn = () => {
    props.clearEditorialSerchedValue(actionTypes.CLEARSEARCHEDITORIALORGTAGIN);
  };

  const searchOrgTagsIn = (e) => {
    if (e.target.value.length > 1) {
      props.fetchTags(
        e.target.value,
        "organization",
        actionTypes.FETCHEDITORIALORGTAGSIN,
        props.editorialFilterData.selOrgTagsEX,
        props.editorialFilterData.site
      );
    }
  };

  const onSelectOrgTagsIn = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.editorialFilterData.selOrgTagsEX.indexOf(selectedValue) === -1){
    props.selEditorialFilterValue(value, actionTypes.APPLYEDITORIALORGTAGSIN);
    props.selectedEditorialFilterValue({ key: "selOrgTagsIN", value });
    }
  };

  const onClickOrgTagEx = () => {
    props.clearEditorialSerchedValue(actionTypes.CLEARSEARCHEDITORIALORGTAGEX);
  };

  const searchOrgTagsEx = (e) => {
    if (e.target.value.length > 1) {
      props.fetchTags(
        e.target.value,
        "organization",
        actionTypes.FETCHEDITORIALORGTAGSEX,
        props.editorialFilterData.selOrgTagsIN,
        props.editorialFilterData.site
      );
    }
  };

  const onSelectOrgTagsEx = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.editorialFilterData.selOrgTagsIN.indexOf(selectedValue) === -1){
    props.selEditorialFilterValue(value, actionTypes.APPLYEDITORIALORGTAGSEX);
    props.selectedEditorialFilterValue({ key: "selOrgTagsEX", value });
    }
  };
  //Country event handling and state management

  const onClickCountry = () => {
    if (_.isEmpty(props.editorialFilterData.selCountry)) {
      props.clearEditorialSerchedValue(actionTypes.CLEARSEARCHEDITORIALCOUNTRY);
    }
  };

  const searchCountry = (e) => {
    if (e.target.value.length > 1) {
      props.fetchPgCountry(e.target.value, actionTypes.FETCHEDITORIALCOUNTRY);
    }
  };

  const onSelectCountry = (e, { value }) => {
    props.selectedEditorialFilterValue({ key: "selCountry", value });
    props.addCountries(value, actionTypes.ADDEDITORIALCONTRIES);
  };

  //Enaged orgs event handlers
  const onClickPageViewByOrgs = () => {
    // if (_.isEmpty(props.editorialFilterData.organization)) {
    //   props.clearEditorialSerchedValue(actionTypes.CLEARSEARCHEDITORIALENGORGS);
    // } else 
    if (!_.isEmpty(props.editorialFilterData.organization) && ( props.editorialFilterData.editorialIsIncludeParent ||  props.editorialFilterData.editorialIsIncludeChild)) {
      props.fetchEngagedOrg(
        props.editorialFilterData.excludeInternalEvents,
        "",
        actionTypes.FETCHEDITORIALENGORGS,
        true,
        props.editorialFilterData.organization,
        props.editorialFilterData.editorialIsIncludeParent,
        props.editorialFilterData.editorialIsIncludeChild,
      );
    }
  };

  const searchPageViewByOrg = (e, { value }) => {
    if (e.target.value.length > 1) {
      props.fetchEngagedOrg(
        props.editorialFilterData.excludeInternalEvents,
        e.target.value,
        actionTypes.FETCHEDITORIALENGORGS,
        true,
        props.editorialFilterData.organization,
        props.editorialFilterData.editorialIsIncludeParent,
        props.editorialFilterData.editorialIsIncludeChild,
      );
    }
  };

  const onSelectPageViewByOrg = (e, { value }) => {
    props.selectedEditorialFilterValue({ key: "selPageViewsByOrg", value });
    props.selEditorialFilterValue(value, actionTypes.ADDEDITORIALENGORGS);
  };

  //Firmographic event handler
  const firmogaphicFilterData = PageFirmoGraphic(
    props.editorialFilterData,
    props.firmographicSector,
    props.firmographicIndustryGroup,
    props.firmographicIndustry,
    props.firmographicSubIndustry,
    props.firmographicOrgsType,
    props.firmographicOrgSize,
    props.firmographicOrgsRevenue
  );

  const onSearchChangeFirmographic = (e, answerKey, value) => {
    if (answerKey === "org_type") {
      setOrgType([]);
      if (e.target.value?.length > 0) {
        setOrgType(
          props.orgTypes
            .filter((orgType) => {
              return orgType.value
                .toLowerCase()
                .includes(e.target.value.toLowerCase());
            })
            .map((orgType) => {
              orgType.disabled = false;
              return orgType;
            })
        );
        props.firmographicOrgsTypeCall(
          orgType,
          actionTypes.FETCHEDITORIALFIRMOGRAPHICORGSTYPE,
          ""
        );
      }
    } else if (answerKey === "org_size") {
      setOrgSize([]);
      if (e.target.value?.length > 1) {
        setOrgSize(
          props.orgSize
            .filter((orgSize) => {
              return orgSize.value
                .toLowerCase()
                .includes(e.target.value.toLowerCase());
            })
            .map((orgSize) => {
              orgSize.disabled = false;
              return orgSize;
            })
        );
        props.firmographicOrgsTypeCall(
          orgSize,
          actionTypes.FETCHEDITORIALORGSSIZE,
          ""
        );
      }
    } else if (answerKey === "org_revenue_range") {
      setOrgRevenue([]);
      if (e.target.value?.length > 1) {
        setOrgRevenue(
          props.orgRevenue
            .filter((orgRevenue) => {
              return orgRevenue.value
                .toLowerCase()
                .includes(e.target.value.toLowerCase());
            })
            .map((orgRevenue) => {
              orgRevenue.disabled = false;
              return orgRevenue;
            })
        );
        props.firmographicOrgsTypeCall(
          orgRevenue,
          actionTypes.FETCHEDITORIALORGSREVENUE,
          ""
        );
      }
    } else if (answerKey === "sector") {
      setSector([]);
      if (e.target.value?.length > 1) {
        setSector(
          props.sector
            .filter((sector) => {
              return sector;
            })
            .map((sector) => {
              sector.disabled = false;
              return sector;
            })
        );
        props.firmographicOrgsTypeCall(
          sector,
          actionTypes.FETCHEDITORIALSECTOR,
          ""
        );
      }
    } else if (answerKey === "industry_group") {
      setIndustryGroup([]);
      if (e.target.value?.length > 1) {
        setIndustryGroup(
          props.industryGroup
            .filter((industryGroup) => {
              return industryGroup;
            })
            .map((industryGroup) => {
              industryGroup.disabled = false;
              return industryGroup;
            })
        );
        props.firmographicOrgsTypeCall(
          industryGroup,
          actionTypes.FETCHEDITORIALINDUSTRYGROUP,
          ""
        );
      }
    } else if (answerKey === "industry") {
      setIndustry([]);
      if (e.target.value?.length > 1) {
        setIndustry(
          props.industry
            .filter((industry) => {
              return industry;
            })
            .map((industry) => {
              industry.disabled = false;
              return industry;
            })
        );
        props.firmographicOrgsTypeCall(
          industry,
          actionTypes.FETCHEDITORIALINDUSTRY,
          ""
        );
      }
    } else if (answerKey === "sub_industry") {
      setSubIndustry([]);
      if (e.target.value?.length > 1) {
        setSubIndustry(
          props.subIndustry
            .filter((subIndustry) => {
              return subIndustry;
            })
            .map((subIndustry) => {
              subIndustry.disabled = false;
              return subIndustry;
            })
        );
        props.firmographicOrgsTypeCall(
          subIndustry,
          actionTypes.FETCHEDITORIALSUBINDUSTRY,
          ""
        );
      }
    }
  };

  const onSelectFirmographic = (e, answerKey, value) => {
    if (answerKey === "org_type") {
      props.selEditorialFilterValue(value, actionTypes.APPLYEDITORIALORGSTYPE);
      props.selectedEditorialFilterValue({ key: "selOrgType", value });
    } else if (answerKey === "org_size") {
      props.selEditorialFilterValue(value, actionTypes.APPLYEDITORIALORGSSIZE);
      props.selectedEditorialFilterValue({ key: "selOrgSize", value });
    } else if (answerKey === "org_revenue_range") {
      props.selEditorialFilterValue(value, actionTypes.APPLYEDITORIALREVENUE);
      props.selectedEditorialFilterValue({ key: "selOrgRevenue", value });
    } else if (answerKey === "sector") {
      props.selEditorialFilterValue(value, actionTypes.APPLYEDITORIALSECTOR);
      props.selectedEditorialFilterValue({ key: "selSector", value });
    } else if (answerKey === "industry_group") {
      props.selEditorialFilterValue(
        value,
        actionTypes.APPLYEDITORIALINDUSTRYGROUP
      );
      props.selectedEditorialFilterValue({ key: "selIndustryGroup", value });
    } else if (answerKey === "industry") {
      props.selEditorialFilterValue(value, actionTypes.APPLYEDITORIALINDUSTRY);
      props.selectedEditorialFilterValue({ key: "selIndustry", value });
    } else if (answerKey === "sub_industry") {
      props.selEditorialFilterValue(
        value,
        actionTypes.APPLYEDITORIALSUBINDUSTRY
      );
      props.selectedEditorialFilterValue({ key: "selSubIndustry", value });
    }
  };

  const onClickFirmographic = (e, answerKey) => {
    if (answerKey === "org_type") {
      props.clearEditorialSerchedValue(
        actionTypes.CLEARSEARCHEDITORIALORGSTYPE
      );
    } else if (answerKey === "org_size") {
      props.clearEditorialSerchedValue(
        actionTypes.CLEARSEARCHEDITORIALORGSSIZE
      );
    } else if (answerKey === "org_revenue_range") {
      props.clearEditorialSerchedValue(
        actionTypes.CLEARSEARCHEDITORIALORGSREVENUE
      );
    } else if (answerKey === "sector") {
      props.clearEditorialSerchedValue(actionTypes.CLEARSEARCHEDITORIALSECTOR);
    } else if (answerKey === "industry_group") {
      props.clearEditorialSerchedValue(
        actionTypes.CLEARSEARCHEDITORIALINDUSTRYGROUP
      );
    } else if (answerKey === "industry") {
      props.clearEditorialSerchedValue(
        actionTypes.CLEARSEARCHEDITORIALINDUSTRY
      );
    } else if (answerKey === "sub_industry") {
      props.clearEditorialSerchedValue(
        actionTypes.CLEARSEARCHEDITORIALSUBINDUSTRY
      );
    }
  };

  //Seniorities Include

  const onClickSenioritiesIn = () => {
    props.clearEditorialSerchedValue(
      actionTypes.CLEARSEARCHEDITORIALSENIORITIESIN
    );
  };

  const searchSenioritiesIn = (e) => {
    setSenioritiesIn([]);
    if (e.target.value.length > 1) {
      setSenioritiesIn(
        props.seniorities
          .filter((seniority) => {
            return seniority.name
              .toLowerCase()
              .includes(e.target.value.toLowerCase());
          })
          .map((seniority) => {
            seniority.disabled = false;
            return seniority;
          })
      );
      props.SenioritiesList(
        senioritiesIN,
        actionTypes.FETCHEDITORIALSENIORITIESIN,
        props.editorialFilterData.selSenioritiesEX
      );
    }
  };

  const onSelectSenioritiesIn = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.editorialFilterData.selSenioritiesEX.indexOf(selectedValue) === -1){
      props.selEditorialFilterValue(value,actionTypes.APPLYEDITORIALSENIORITIESIN);
      props.selectedEditorialFilterValue({ key: "selSenioritiesIN", value });
    }
  };

  //Seniority Ex

  const onClickSenioritiesEx = () => {
    props.clearEditorialSerchedValue(
      actionTypes.CLEARSEARCHEDITORIALSENIORITIESEX
    );
  };

  const searchSenioritiesEx = (e) => {
    setSenioritiesEX([]);
    if (e.target.value.length > 1) {
      setSenioritiesEX(
        props.seniorities
          .filter((seniority) => {
            return seniority.name
              .toLowerCase()
              .includes(e.target.value.toLowerCase());
          })
          .map((seniority) => {
            seniority.disabled = false;
            return seniority;
          })
      );
      props.SenioritiesList(
        senioritiesEX,
        actionTypes.FETCHEDITORIALSENIORITIESEX,
        props.editorialFilterData.selSenioritiesIN
      );
    }
  };

  const onSelectSenioritiesEx = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.editorialFilterData.selSenioritiesIN.indexOf(selectedValue) === -1){
      props.selEditorialFilterValue(value,actionTypes.APPLYEDITORIALSENIORITIESEX);
      props.selectedEditorialFilterValue({ key: "selSenioritiesEX", value });
    }
  };

  let personaSeniority = [
    {
      value: props.editorialFilterData.selSenioritiesIN,
      label: "Seniority (Include)",
      placeholder: "Seniority (Include)",
      options: props.senioritiesIn,
      onClick: onClickSenioritiesIn,
      onChange: onSelectSenioritiesIn,
      onSearchChange: searchSenioritiesIn,
    },
    {
      value: props.editorialFilterData.selSenioritiesEX,
      label: "Seniority (Exclude)",
      placeholder: "Seniority (Exclude)",
      options: props.senioritiesEx,
      onClick: onClickSenioritiesEx,
      onChange: onSelectSenioritiesEx,
      onSearchChange: searchSenioritiesEx,
    },
  ];

  //JobFunctions Include

  const onClickJobFunctionsIn = () => {
    props.clearEditorialSerchedValue(
      actionTypes.CLEARSEARCHEDITORIALJOBFUNCTIONSIN
    );
  };

  const searchJobFunctionsIn = (e) => {
    setJobFunctionsIN([]);
    if (e.target.value.length > 1) {
      setJobFunctionsIN(
        props.jobfunctions
          .filter((jobfunction) => {
            return jobfunction.function
              .toLowerCase()
              .includes(e.target.value.toLowerCase());
          })
          .map((jobfunction) => {
            jobfunction.disabled = false;
            return jobfunction;
          })
      );
      props.SenioritiesList(
        JobFunctionsIN,
        actionTypes.FETCHEDITORIALJOBFUNCTIONSIN,
        props.editorialFilterData.selJobFunctionsEX
      );
    }
  };

  const onSelectJobFunctionIn = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.editorialFilterData.selJobFunctionsEX.indexOf(selectedValue) === -1){
      props.selEditorialFilterValue(value,actionTypes.APPLYEDITORIALJOBFUNCTIONSIN);
      props.selectedEditorialFilterValue({ key: "selJobFunctionsIN", value });
    }
  };

  //JobFunctions Ex

  const onClickJobFunctionsEx = () => {
    props.clearEditorialSerchedValue(
      actionTypes.CLEARSEARCHEDITORIALJOBFUNCTIONSEX
    );
  };

  const searchJobFunctionsEx = (e) => {
    setJobFunctionsEX([]);
    if (e.target.value.length > 1) {
      setJobFunctionsEX(
        props.jobfunctions
          .filter((jobfunction) => {
            return jobfunction.function
              .toLowerCase()
              .includes(e.target.value.toLowerCase());
          })
          .map((jobfunction) => {
            jobfunction.disabled = false;
            return jobfunction;
          })
      );
      props.SenioritiesList(
        JobFunctionsEX,
        actionTypes.FETCHEDITORIALJOBFUNCTIONSEX,
        props.editorialFilterData.selJobFunctionsIN
      );
    }
  };

  const onSelectJobFunctionsEx = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.editorialFilterData.selJobFunctionsIN.indexOf(selectedValue) === -1){
      props.selEditorialFilterValue(value,actionTypes.APPLYEDITORIALJOBFUNCTIONSEX);
      props.selectedEditorialFilterValue({ key: "selJobFunctionsEX", value });
    }
  };

  let personaJobFunction = [
    {
      value: props.editorialFilterData.selJobFunctionsIN,
      label: "Job Function (Include)",
      placeholder: "Job Function (Include)",
      options: props.JobFunctionsIN,
      tags: props.JobFunctionsIN,
      onClick: onClickJobFunctionsIn,
      onChange: onSelectJobFunctionIn,
      onSearchChange: searchJobFunctionsIn,
    },
    {
      tags: props.JobFunctionsEX,
      value: props.editorialFilterData.selJobFunctionsEX,
      label: "Job Function (Exclude)",
      placeholder: "Job Function (Exclude)",
      options: props.JobFunctionsEX,
      onClick: onClickJobFunctionsEx,
      onChange: onSelectJobFunctionsEx,
      onSearchChange: searchJobFunctionsEx,
    },
  ];

  const onTypeJobTitleIN = (e, { value }) => {
    props.selectedEditorialFilterValue({ key: "selJobTitlesIN", value });
  };
  const onTypeJobTitleEX = (e, { value }) => {
    props.selectedEditorialFilterValue({ key: "selJobTitlesEX", value });
  };

  let personaJobTitleIn = [
    {
      onChange: onTypeJobTitleIN,
      placeholder: "Job Title (Include)",
      value: props.editorialFilterData.selJobTitlesIN,
    },
  ];

  let personaJobTitleEx = [
    {
      onChange: onTypeJobTitleEX,
      placeholder: "Job Title (Exclude)",
      value: props.editorialFilterData.selJobTitlesEX,
    },
  ];

  //publish dates event handler
  const cancelDateFilter = () => {
    setDateRangeFilter({
      selection: {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    });
    setDatePopupFilter("custom-date-popup-close");
  };

  const applyDateFilter = () => {
    props.selEditorialFilterValue(
      getDisplayDate(dateRangeFilter.selection.startDate),
      actionTypes.SELEDITORIALWPSTARTDATE
    );
    props.selEditorialFilterValue(
      getDisplayDate(dateRangeFilter.selection.endDate),
      actionTypes.SELEDITORIALWPENDDATE
    );
    setDatePopupFilter("custom-date-popup-close");
  };

  const selectDateRangeFilter = (e, { value }) => {
    props.selEditorialFilterValue(value, actionTypes.APPLYEDITORIALDATEFILTER);
    setDatePopupFilter("custom-date-popup-close");
    if (value === "Custom Date Range") {
      setDatePopupFilter("custom-date-popup-open");
    } else {
      let dates = getStartEndDates(value);
      props.selEditorialFilterValue(
        getDisplayDate(dates.startDate),
        actionTypes.SELEDITORIALWPSTARTDATE
      );
      props.selEditorialFilterValue(
        getDisplayDate(dates.endDate),
        actionTypes.SELEDITORIALWPENDDATE
      );
    }
  };

  //Page title event handling and state management

  const onClickPageTitle = () => {
    if (_.isEmpty(props.editorialFilterData.selPageTitle)) {
      props.clearEditorialSerchedValue(
        actionTypes.CLEARSEARCHEDITORIALPAGETITLE
      );
    }
  };

  const searchPageTitle = (e) => {
    if (e.target.value.length > 1) {
      props.fetchPageTitle(
        e.target.value, 
        actionTypes.FETCHEDITORIALTITLE, 
        "", 
        props.editorialFilterData.wp_startDate, 
        props.editorialFilterData.wp_endDate
      );
    } else {
      props.clearEditorialSerchedValue(
        actionTypes.CLEARSEARCHEDITORIALPAGETITLE
      );
    }
  };

  const onSelectPageTitle = (e, { value }) => {
    props.selectedEditorialFilterValue({ key: "selPageTitle", value });
  };

  //Url parameters source event handling

  const onClickUrlSource = () => {
    if (_.isEmpty(props.editorialFilterData.selUrlSource)) {
      props.clearEditorialSerchedValue(
        actionTypes.CLEARSEARCHEDITORIALURLSOURCE
      );
    }
  };

  const searchUrlSource = (e) => {
    if (e.target.value.length > 1) {
      props.fetchUrlParams(
        e.target.value,
        "source",
        actionTypes.FETCHEDITORIALURLSOURCE,
        "",
        "pageview"
      );
    } else {
      props.clearEditorialSerchedValue(
        actionTypes.CLEARSEARCHEDITORIALURLSOURCE
      );
    }
  };

  const onSelectUrlSource = (e, { value }) => {
    props.clearEditorialSerchedValue(
      actionTypes.RESETSELEDITORIALURLCAMPANDMEDIUM
    );
    props.clearEditorialSerchedValue(
      actionTypes.CLEARSEARCHEDITORIALURLCAMPAIGNS
    );
    props.clearEditorialSerchedValue(actionTypes.CLEARSEARCHEDITORIALURLMEDIUM);
    props.selectedEditorialFilterValue({ key: "selUrlSource", value });
  };
  //Url parameters medium event handling

  const onClickUrlMedium = () => {
    if (_.isEmpty(props.editorialFilterData.selUrlMedium)) {
      props.clearEditorialSerchedValue(
        actionTypes.CLEARSEARCHEDITORIALURLMEDIUM
      );
    }
  };

  const searchUrlMedium = (e) => {
    if (e.target.value.length > 1) {
      props.fetchUrlParams(
        e.target.value,
        "medium",
        actionTypes.FETCHEDITORIALURLMEDIUM,
        props.editorialFilterData.selUrlSource,
        "pageview"
      );
    } else {
      props.clearEditorialSerchedValue(
        actionTypes.CLEARSEARCHEDITORIALURLMEDIUM
      );
    }
  };

  const onSelectUrlMedium = (e, { value }) => {
    props.selectedEditorialFilterValue({ key: "selUrlMedium", value });
  };

  //Url parameters campaign event handling

  const onClickUrlCampaign = () => {
    if (_.isEmpty(props.editorialFilterData.selUrlCampaign)) {
      props.clearEditorialSerchedValue(
        actionTypes.CLEARSEARCHEDITORIALURLCAMPAIGNS
      );
    }
  };

  const searchUrlCampaigns = (e) => {
    if (e.target.value.length > 1) {
      props.fetchUrlParams(
        e.target.value,
        "campaign",
        actionTypes.FETCHEDITORIALURLCAMPAIGNS,
        props.editorialFilterData.selUrlSource,
        "pageview"
      );
    } else {
      props.clearEditorialSerchedValue(
        actionTypes.CLEARSEARCHEDITORIALURLCAMPAIGNS
      );
    }
  };

  const onSelectUrlCampaigns = (e, { value }) => {
    props.selectedEditorialFilterValue({ key: "selUrlCampaign", value });
  };

  // URL Term event handlers
  const onClickUrlTerm = () => {
    if (_.isEmpty(props.editorialFilterData.selUrlTerm)) {
      props.clearEditorialSerchedValue(actionTypes.CLEARSEARCHEDITORIALURLTERM);
    }
  };

  const searchUrlTerm = (e) => {
    if (e.target.value.length > 1) {
      props.fetchUrlParams(
        e.target.value,
        "term",
        actionTypes.FETCHEDITORIALURLTERM,
        props.editorialFilterData.selUrlSource,
        "pageview"
      );
    } else {
      props.clearEditorialSerchedValue(actionTypes.CLEARSEARCHEDITORIALURLTERM);
    }
  };

  const onSelectUrlTerm = (e, { value }) => {
    props.selectedEditorialFilterValue({ key: "selUrlTerm", value });
  };

  // URL Content event handlers
  const onClickUrlContent = () => {
    if (_.isEmpty(props.editorialFilterData.selUrlContent)) {
      props.clearEditorialSerchedValue(
        actionTypes.CLEARSEARCHEDITORIALURLCONTENT
      );
    }
  };

  const searchUrlContent = (e) => {
    if (e.target.value.length > 1) {
      props.fetchUrlParams(
        e.target.value,
        "content",
        actionTypes.FETCHEDITORIALURLCONTENT,
        props.editorialFilterData.selUrlSource,
        "pageview"
      );
    } else {
      props.clearEditorialSerchedValue(
        actionTypes.CLEARSEARCHEDITORIALURLCONTENT
      );
    }
  };

  const onSelectUrlContent = (e, { value }) => {
    props.selectedEditorialFilterValue({ key: "selUrlContent", value });
  };

  //Engaged member eventy Handler

  const onClickEngagedMember = () => {
    if (_.isEmpty(props.editorialFilterData.selEnagedMember)) {
      props.clearEditorialSerchedValue(
        actionTypes.CLEARSEARCHEDITORIALENGMEMBER
      );
    }
  };

  const searchEngagedMember = (e) => {
    if (e.target.value.length > 1) {
      props.fetchEngagedMember(
        e.target.value,
        actionTypes.FETCHEDITORIALENGMEMBER
      );
    }
  };

  const onSelectEngagedMember = (e, { value }) => {
    props.selectedEditorialFilterValue({ key: "selEnagedMember", value });
    props.selEditorialFilterValue(
      value,
      actionTypes.ADDEDITORIALENGMEMBER
    );
  };

  const onClickPrimaryTopic = (e, { value }) => {
    if (_.isEmpty(props.editorialFilterData.selPrimaryTopic)) {
      props.clearEditorialSerchedValue(
        actionTypes.CLEARSEARCHEDITORIAALPRIMARYTOPIC
      );
    }
  };

  const onSelectPrimaryTopic = (e, { value }) => {
    props.selectedEditorialFilterValue({ key: "selPrimaryTopic", value });
    props.selEditorialFilterValue(
      value,
      actionTypes.ADDEDITORIALPRIMARYTOPIC
    );
  };

  const searchPrimaryTopic = (e) => {
    if (e.target.value.length > 1) {
      props.fetchPrimaryTopic(
        e.target.value,
        actionTypes.FETCHEDITORIALPRIMARYTOPIC
      );
    }
  };

  const onSelectTrafficSource = (e, { value }) => {
    props.selectedEditorialFilterValue({ key: "selTrafficSource", value });
  };

  const clearFilters = () => {
    clearfilterClick.current = true;
    if (
      clearfilterClick.current &&
      props.editorialFilterData.appliedDate === "Custom Date Range"
    ) {
      setDateRange({
        selection: {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      });
    }
    setCheckSelectState(false);
    props.clearEditorialSerchedValue(actionTypes.CLEAREDITORIALFILTER);
    const actionTypesToClear = [
      actionTypes.RESETEDITORIALORGS,
      actionTypes.RESETEDITORIALCAMPAIGNS,
      actionTypes.RESETEDITORIALORDER,
      actionTypes.RESETEDITORIALTOPICTAGIN,
      actionTypes.RESETEDITORIALTOPICTAGEX,
      actionTypes.RESETEDITORIALPLACEMENTTAGIN,
      actionTypes.RESETEDITORIALPLACEMENTTAGEX,
      actionTypes.RESETEDITORIAORGTAGIN,
      actionTypes.RESETEDITORIAORGTAGEX,
      actionTypes.RESETEDITORIAENGORGS,
      actionTypes.RESETEDITORIACOUNTRY,
      actionTypes.RESETEDITORIALORGSTYPE,
      actionTypes.RESETEDITORIALORGSIZE,
      actionTypes.RESETEDITORIALORGSREVENUE,
      actionTypes.RESETEDITORIALSECTOR,
      actionTypes.RESETEDITORIALINDUSTRYGROUP,
      actionTypes.RESETEDITORIALINDUSTRY,
      actionTypes.RESETEDITORIALSUBINDUSTRY,
      actionTypes.RESETEDITORIALSENIORITIESIN,
      actionTypes.RESETEDITORIALSENIORITIESEX,
      actionTypes.RESETEDITORIALJOBFUNCTIONSIN,
      actionTypes.RESETEDITORIALJOBFUNCTIONSEX,
      actionTypes.RESETEDITORIALENGMEMBER,
      actionTypes.RESETEDITORIALPRIMARYTOPIC,
    ];

    actionTypesToClear.forEach((actionType) => {
      props.clearEditorialSerchedValue(actionType);
    });
    setDatePopup("custom-date-popup-close");
  };

  const callAPI =(url, data, actionType, extraPerameters)=> {
    props.fetchEditorialTableData(
      url,
      data,
      1,
      "",
      actionType,
      null,
      null,
      "independent",
      extraPerameters
    );
  }

  const applyFilters = (startDate, endDate, isCustomCheckbox) => {
    let tempPgFilterDAta = props.editorialFilterData;
    tempPgFilterDAta.startDate = startDate;
    tempPgFilterDAta.endDate = endDate;
    tempPgFilterDAta.prevStartDate = "";
    tempPgFilterDAta.prevEndDate = "";
    tempPgFilterDAta.selCutomRangeChkBox = isCustomCheckbox;
    tempPgFilterDAta.dayOfWeek = selectDayOfWeek;
    tempPgFilterDAta.pageviewRanges = selectPageviewRanges;

    let resPageSummary;
    let resIndependantPrimaryTopic;

    props.applyEditorialFilter();
    // check toggle value for topic, organization and place tag summary report
    if (
      (props.editorialFilterData.selectTopicSummaryTable &&
      (props?.roleReports?.some((report) => [TOPIC_TAG_SUMMARY].includes(report.element) && report.permission === VIEW)))||
      (props.editorialFilterData.selectPlaceSummaryTable &&
      (props?.roleReports?.some((report) => [PLACEMENT_TAG_SUMMARY].includes(report.element) && report.permission === VIEW))) ||
      (props.editorialFilterData.selectOrgSummaryTable &&
      (props?.roleReports?.some((report) => [ORGANIZATION_TAG_SUMMARY].includes(report.element) && report.permission === VIEW)))
    ) {
      callAPI(url.topicOrganizationPlacementReport, tempPgFilterDAta, actionTypes.COMMONINDEPENDENTPAGESUMMARY, "");
    }

    //check toggle value for page summary report
    if (props.editorialFilterData.selectPageSummaryTable &&
      (props?.roleReports?.some((report) => [PAGE_SUMMARY].includes(report.element) && report.permission === VIEW))) {
      callAPI(url.pageviewDetails, tempPgFilterDAta, actionTypes.EDITORIALPAGEVIEWSUMMARY, resPageSummary);
      callAPI(url.independentPageSummary, tempPgFilterDAta, actionTypes.PAGESUMMARYCOUNT, "");
    }
    
    //check toggle value for primary topic summary report
    if (
      props.editorialFilterData.isTopicSummary &&
      (
        props?.roleReports?.some(
          (report) =>
            [PRIMARY_TOPIC_SUMMARY].includes(report.element) &&
            report.permission === VIEW
        )
      )
    ) {
      callAPI(url.pageviewPrimaryTopic, tempPgFilterDAta, actionTypes.EDITORIALPRIMARYTOPIC, resIndependantPrimaryTopic);
      callAPI(url.independentTopicSummaryCount, tempPgFilterDAta, actionTypes.INDPRIMARYTOPICCOUNT, "");
    }


    if(props.editorialFilterData.isAuthorToggle) {
      callAPI(url.pageviewAuthorSummary, tempPgFilterDAta, actionTypes.EDITORIALAUTHORSUMMARY, "");
    }

    if (props.editorialFilterData.selectPageDetailTable && props?.roleReports?.some((report) => [PAGEVIEW_DETAILS_TABLE].includes(report.element) && report.permission === VIEW)) {
      props.fetchPageviewTableData(
        url.pageviewDetailed,
        tempPgFilterDAta,
        1,
        "",
        actionTypes.EDITORIALDETAILS,
        null,
        null,
        "independent-details",
        ""
      );
    }

    if (props.isFilApplied && props.editorialFilterData.showEditorialChart) {
      props.fetchEditorialTableData(
        url.pageviewReportCharts,
        props.editorialFilterData,
        1,
        "",
        actionTypes.EDITORIALCHARTS,
        null,
        null,
        "independent"
      );
    }
    
    
    setFilterPopup("filter-popup-close");
    setFilterIconColor("material-icons grey");
    setFilterSelectFocus("filter-icon");
    setDatePopup("custom-date-popup-close");
  };

  // const onChangeIncludeParent = (e) => {
  //   if(props.editorialFilterData.editorialIsIncludeChild){
  //     props.selEditorialToggles(actionTypes.SELEDITORIALPARENTTOOGLE);
  //     props.selEditorialToggles(actionTypes.SELEDITORIALCHILDTOOGLE);
  //   } else {
  //     props.selEditorialToggles(actionTypes.SELEDITORIALPARENTTOOGLE);
  //   }
  // }

  // const onChangeIncludeChild = (e) => {
  //   if(props.editorialFilterData.editorialIsIncludeParent){
  //     props.selEditorialToggles(actionTypes.SELEDITORIALPARENTTOOGLE);
  //     props.selEditorialToggles(actionTypes.SELEDITORIALCHILDTOOGLE);
  //   } else {
  //     props.selEditorialToggles(actionTypes.SELEDITORIALCHILDTOOGLE);
  //   }
  // }
  
  const onSelectTrafficFilter = (e, { value }) => {
    props.selectedEditorialFilterValue({ key: "trafficType", value });
  }

  const onSelectPageViewType = (e, { value }) => {
    props.selectedEditorialFilterValue({ key: "pageviewType", value });
  }

  const onSelectPageViewRanges = (e, { value }) => {
    setSelectPageviewRanges(value);
  }

  const onSelectDayOfWeek = (e, { value }) => {
    setSelectDayOfWeek(value);
  }

  const onSelectTimeOfDay = (e, { value }) => {
    props.selectedEditorialFilterValue({ key: "timeOfDay", value });
  }

  return (
    <div>
      <div className="date-picker">
        {/* Date picker */}
        <SelectDatePicker
          props={props}
          selectDateRange={selectDateRange}
          data={props.editorialFilterData}
          applyDate={props.applyIndependentDate}
          setDatePopup={setDatePopup}
          dateRange={dateRange}
          checkSelectState={checkSelectState}
        />
        {/* Filter icon */}
        <SearchFilterIcon
          filterSelectFocus={filterSelectFocus}
          setFilterSelectFocus={setFilterSelectFocus}
          setFilterPopup={setFilterPopup}
          setFilterIconColor={setFilterIconColor}
          filterIconColor={filterIconColor}
        />
      </div>
      <div className="popup-component">
        {/* Custom date picker component */}
        <CustomDatePickerWithCompare
          datePopup={datePopup}
          setDatePopup={setDatePopup}
          checkSelectState={checkSelectState}
          cutomDate={cutomDate}
          dateRange={dateRange}
          setCheckSelectState={setCheckSelectState}
          setComparePeriod={setComparePeriod}
          previousPeriodData={previousPeriodData}
          comparePeriod={comparePeriod}
          getClearDate={getClearDate}
          setDateRange={setDateRange}
          cancelDate={cancelDate}
          applyDate={applyDate}
          isComparePeriod={true}
        />
        {/* Search filter popup */}
        <div
          className={filterPopup}
          // style={{
          //   overflowX: "scroll",
          //   width: "auto",
          //   height: "62rem",
          //   scrollbarWidth: "thin",
          // }}
        >
          <div className="filter-header">
            <div className="filter-text">
              <p>Filters</p>
            </div>
            <div
              className="filter-close-icon"
              onClick={() => {
                setFilterSelectFocus("filter-icon");
                setFilterPopup("filter-popup-close");
              }}
            >
              <i
                className="material-icons grey"
                onClick={() => setFilterIconColor("material-icons grey")}
              >
                clear
              </i>
            </div>
          </div>
          <Form>
            <div className="pg-search-filter">
              <Toggle
                label="Page Parameters"
                onChange={() => {
                  props.selEditorialToggles(
                    actionTypes.SELEDITORIALPAGEPARAMSTOGGLE
                  );
                }}
                checked={props.editorialFilterData.pageParams}
              />
              {props.editorialFilterData.pageParams && (
                <React.Fragment>
                  <label className="job-Titles"style={{marginLeft: "5px"}}>{PUBLISHED_DATE_RANGE}</label> 
                  <Form.Group widths="equal">
                    <DatePicker
                      value={props.editorialFilterData.appliedDateFilter}
                      onChange={selectDateRangeFilter}
                      showAllDates={true}
                      openDateRange={(e, { value }) => {
                        if (value === "Custom Date Range") {
                          document.getElementsByClassName(
                            "rdrDateInput"
                          )[2].style.display = "none";
                          document.getElementsByClassName(
                            "rdrDateInput"
                          )[3].style.display = "none";
                          document.getElementsByClassName(
                            "rdrDateDisplayWrapper"
                          )[1].style.display = "none";
                          props.selEditorialFilterValue(
                            "Custom Date Range",
                            actionTypes.APPLYEDITORIALDATEFILTER
                          );
                          setDatePopupFilter("custom-date-popup-open");
                        }
                      }}
                    />
                    <span style={{ width: "25em" }}>
                      <FilterDateRange style={{ paddingBottom: "0px" }} />
                    </span>
                  </Form.Group>
                </React.Fragment>
              )}
              {props.editorialFilterData.pageParams && (
                <Form>
                  <div className={datePopupFilter}>
                    <div
                      className="date-picker-close-icon"
                      onClick={() => {
                        setDatePopupFilter("custom-date-popup-close");
                      }}
                    >
                      <i className="material-icons grey">clear</i>
                    </div>
                    <div>
                      <DateRangePicker
                        minDate={new Date("2019-12-13")}
                        maxDate={
                          props.editorialFilterData.endDate
                            ? new Date(props.editorialFilterData.endDate)
                            : new Date()
                        }
                        onChange={(item) => {
                          setDateRangeFilter({ ...dateRangeFilter, ...item });
                        }}
                        months={2}
                        moveRangeOnFirstSelection={false}
                        direction="horizontal"
                        ranges={[dateRangeFilter.selection]}
                        rangeColors={["#e5582b"]}
                      />
                    </div>
                    <div className="date-buttons">
                      <button
                        type="button"
                        className="cancel-date-button"
                        onClick={cancelDateFilter}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="apply-date-button"
                        onClick={applyDateFilter}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                  <Form.Group widths="equal">
                    <SingleSelectDropDown
                      clearable
                      options={props.pageTitle.map((org, index) => ({
                        key: index,
                        text: org.title,
                        value: org.title,
                      }))}
                      value={props.editorialFilterData.selPageTitle}
                      label="Page Title"
                      placeholder="Page Title"
                      onClick={onClickPageTitle}
                      onChange={onSelectPageTitle}
                      onSearchChange={searchPageTitle}
                      search={(options) => options}
                    />
                    <UrlParamsPicker
                      style={{ width: "24em" }}
                      options={props.urlSource?.map((url, index) => ({
                        key: index,
                        text: url.source,
                        value: url.source,
                      }))}
                      value={props.editorialFilterData.selUrlSource}
                      label="UTM Source"
                      placeholder="UTM Source"
                      onClick={onClickUrlSource}
                      onChange={onSelectUrlSource}
                      onSearchChange={searchUrlSource}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <UrlParamsPicker
                      options={props.urlMedium?.map((url, index) => ({
                        key: index,
                        text: url.medium,
                        value: url.medium,
                      }))}
                      value={props.editorialFilterData.selUrlMedium}
                      label="UTM Medium"
                      placeholder=" UTM Medium"
                      onClick={onClickUrlMedium}
                      onChange={onSelectUrlMedium}
                      onSearchChange={searchUrlMedium}
                    />
                    <UrlParamsPicker
                      options={props.urlCampaigns?.map((url, index) => ({
                        key: index,
                        text: url.campaign,
                        value: url.campaign,
                      }))}
                      value={props.editorialFilterData.selUrlCampaign}
                      label="UTM Campaign"
                      placeholder="UTM Campaign"
                      onClick={onClickUrlCampaign}
                      onChange={onSelectUrlCampaigns}
                      onSearchChange={searchUrlCampaigns}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <UrlParamsPicker
                      label="UTM Term"
                      placeholder="UTM Term"
                      options={props.urlTerm?.map((url, index) => ({
                        key: index,
                        text: url.term,
                        value: url.term,
                      }))}
                      value={props.editorialFilterData.selUrlTerm}
                      onClick={onClickUrlTerm}
                      onChange={onSelectUrlTerm}
                      onSearchChange={searchUrlTerm}
                    />
                    <UrlParamsPicker
                      label="UTM Content"
                      placeholder="UTM Content"
                      options={props.urlContent?.map((url, index) => ({
                        key: index,
                        text: url.content,
                        value: url.content,
                      }))}
                      value={props.editorialFilterData.selUrlContent}
                      onClick={onClickUrlContent}
                      onChange={onSelectUrlContent}
                      onSearchChange={searchUrlContent}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                  <div className="field">   
                    <PrimaryTopic
                      label="Primary Topic"
                      placeholder="Primary Topic"
                      value={props.editorialFilterData.selPrimaryTopic}
                      primaryTopic={props.editorialPrimaryTopic}
                      onClick={onClickPrimaryTopic}
                      onChange={onSelectPrimaryTopic}
                      onSearchChange={searchPrimaryTopic}
                    />
                    </div>
                    <div className="field"></div>
                  </Form.Group>
                </Form>
              )}
              <Form.Group widths="equal">
                <CountryMultiSelectPicker
                    countries={props.countries}
                    value={props.editorialFilterData.selCountry}
                    onClick={onClickCountry}
                    onChange={onSelectCountry}
                    onSearchChange={searchCountry}
                    label="Pageviews by Country"
                  />
                <Site
                  value={props.editorialFilterData.site}
                  onChange={onSelectSite}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <TagsPicker
                  sites={props.sites}
                  tags={props.topicTagsIn}
                  value={props.editorialFilterData.selTopicTagsIN}
                  label="Topic Tags (Include)"
                  placeholder="Topic Tags (Include)"
                  onClick={onClickTopicTagIn}
                  onChange={onSelectTopicTagsIn}
                  onSearchChange={searchTopicTagsIn}
                />
                <TagsPicker
                  sites={props.sites}
                  tags={props.topicTagsEx}
                  value={props.editorialFilterData.selTopicTagsEX}
                  label="Topic Tags (Exclude)"
                  placeholder="Topic Tags (Exclude)"
                  onClick={onClickTopicTagEx}
                  onChange={onSelectTopicTagsEx}
                  onSearchChange={searchTopicTagsEx}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <TagsPicker
                  sites={props.sites}
                  tags={props.placementTagsIn}
                  value={props.editorialFilterData.selPlacementTagsIN}
                  label="Placement Tags (Include)"
                  placeholder="Placement Tags (Include)"
                  onClick={onClickPlacementTagIn}
                  onChange={onSelectPlacementTagsIn}
                  onSearchChange={searchPlacementTagsIn}
                />
                <TagsPicker
                  sites={props.sites}
                  tags={props.placementTagsEx}
                  value={props.editorialFilterData.selPlacementTagsEX}
                  label="Placement Tags (Exclude)"
                  placeholder="Placement Tags (Exclude)"
                  onClick={onClickPlacementTagEx}
                  onChange={onSelectPlacementTagsEx}
                  onSearchChange={searchPlacementTagsEx}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <TagsPicker
                  sites={props.sites}
                  tags={props.orgTagsIn}
                  value={props.editorialFilterData.selOrgTagsIN}
                  label="Organization Tags (Include)"
                  placeholder="Organization Tags (Include)"
                  onClick={onClickOrgTagIn}
                  onChange={onSelectOrgTagsIn}
                  onSearchChange={searchOrgTagsIn}
                />
                <TagsPicker
                  sites={props.sites}
                  tags={props.orgTagsEx}
                  value={props.editorialFilterData.selOrgTagsEX}
                  label="Organization Tags (Exclude)"
                  placeholder="Organization Tags (Exclude)"
                  onClick={onClickOrgTagEx}
                  onChange={onSelectOrgTagsEx}
                  onSearchChange={searchOrgTagsEx}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <AuthorPicker
                  authors={props.author}
                  value={props.editorialFilterData.selAuthor}
                  onClick={onClickAuthor}
                  onChange={onSelectAuthor}
                  onSearchChange={searchAuthor}
                />
                <TrafficSource
                  value={props.editorialFilterData.selTrafficSource}
                  onChange={onSelectTrafficSource}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <TrafficFilterPicker
                  value={props.editorialFilterData.trafficType}
                  onChange={onSelectTrafficFilter}
                />
                <PageviewType
                  value={props.editorialFilterData.pageviewType}
                  onChange={onSelectPageViewType}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <DayOfWeek
                  weekDays={props?.pageviewList?.days_of_week}
                  onChange={onSelectDayOfWeek}
                  value={selectDayOfWeek}
                />
                <MultipleSelectDropDown
                  options={props?.pageviewList?.ranges?.map((day, index) => ({
                    key: index,
                    text: day.label,
                    value: day.value,
                  }))}
                  label="Pageview Range"
                  placeholder="Pageview Range"
                  value={selectPageviewRanges}
                  onChange={onSelectPageViewRanges}
                />
              </Form.Group>
              <Form.Group widths="equal" style={{ width: "24.8em" }}>
                <TimeOfDay 
                  ranges={props?.pageviewList?.time_of_day} 
                  onChange={onSelectTimeOfDay}
                />
              </Form.Group>
              <Form.Group widths="equal">
               <Form className="search-filter-column-1" style={{paddingLeft: "10px"}}>
                  <EngagedOrganization
                    organizations={props.editorialEngOrg}
                    value={props.editorialFilterData.selPageViewsByOrg}
                    label="Engaged Organizations"
                    placeholder="Engaged Organizations"
                    onClick={onClickPageViewByOrgs}
                    onChange={onSelectPageViewByOrg}
                    onSearchChange={searchPageViewByOrg}
                    type="addOrgLink"
                  />
              {/* <div className="engagements-search-filter">
                <Form className="search-filter-column-1">
                <div style={{marginTop: "20px"}}>
                    <Toggle
                          label="Include Parent"
                          onChange={onChangeIncludeParent}
                          checked={props.editorialFilterData.editorialIsIncludeParent}
                        />
                </div>
                <div style={{marginTop: "12px"}}>
                    <Toggle
                          label="Include Children"
                          onChange={onChangeIncludeChild}
                          checked={props.editorialFilterData.editorialIsIncludeChild}
                        />
                </div>
                </Form>
              </div> */}
              <Form className="search-filter-column-1">
                <div style={{marginTop: "20px"}}>
                  <Toggle
                    label="Exclude Anonymous Organizations"
                    onChange={(e)=>{ props.checkToggle({ key: "exAnonymousOrgs", value: props.editorialFilterData.exAnonymousOrgs }); }}
                    checked={props.editorialFilterData.exAnonymousOrgs}
                  />
                </div>
              </Form>
            </Form>
                <Form className="search-filter-column-2">
                    <EngagedMember
                      label="Engagement Member"
                      placeholder="Engagement Member"
                      engMember={props.editorialEngMember}
                      value={props.editorialFilterData.selEnagedMember}
                      onClick={onClickEngagedMember}
                      onChange={onSelectEngagedMember}
                      onSearchChange={searchEngagedMember}
                      type="addMemberLink"
                    />
                    <div className="engagements-search-filter">
                      <Form className="search-filter-column-1" style={{marginLeft: "10px", paddingRight: "6px"}}>
                        <div style={{marginTop: "20px"}}>
                          <Toggle
                            label="Exclude Anonymous Members"
                            onChange={(e)=>{ props.checkToggle({ key: "exAnonymousMember", value: props.editorialFilterData.exAnonymousMember }); }}
                            checked={props.editorialFilterData.exAnonymousMember}
                          />
                        </div>
                      </Form>
                    </div>
                </Form>
              </Form.Group>
            <br />
              <div>
                <Toggle
                  label="Firmographic (Only applicable for xlsx)"
                  onChange={() => {
                    props.selEditorialToggles(
                      actionTypes.SELEDITORIALFIRMOGRAPHICTOGGLE
                    );
                  }}
                  checked={props.editorialFilterData.firmographicToggle}
                />
                {props.editorialFilterData.firmographicToggle && (
                  <FirmographicFilter
                    rowGap="0px"
                    firmogaphicFilterData={firmogaphicFilterData}
                    onSearchChange={onSearchChangeFirmographic}
                    onChange={onSelectFirmographic}
                    onClick={onClickFirmographic}
                  />
                )}
              </div>
              <br />
              <Toggle
                label="Persona"
                onChange={() => {
                  props.selEditorialToggles(
                    actionTypes.SELEDITORIALPERSONATOGGLE
                  );
                }}
                checked={props.editorialFilterData.personaToggle}
              />
              {props.editorialFilterData.personaToggle && (
                <PersonaFilter
                  personaSenority={personaSeniority}
                  personaJobFunction={personaJobFunction}
                  personaJobTitleIn={personaJobTitleIn}
                  personaJobTitleEx={personaJobTitleEx}
                  labelJobTitleIn="Job Title (Include)"
                  labelJobTitleEx="Job Title (Exclude)"
                />
              )}

              <Form.Group widths="equal">


                   </Form.Group>
              <Form.Group>
                <div className="engagements-search-filter">
                <Form className="search-filter-column-1"></Form>
                <Form className="search-filter-column-2">
                    <div className="ad-toggle-label">
                      <p>Event Filters</p>
                    </div>
                    <Toggle
                      className="ad-child-toggle"
                      label="Exclude Internal Events"
                      onChange={() => {
                        props.selEditorialToggles(
                          actionTypes.SELINDINTERNALTOGGLE
                        );
                      }}
                      checked={props.editorialFilterData.excludeInternalEvents}
                    />
                    </Form>
                  </div>
              </Form.Group>
            </div>
          </Form>
          <div className="filter-buttons">
            <button className="cancel-date-button extra-space" onClick={clearFilters}>
              Clear Filters
            </button>
            <button
              type="submit"
              className="apply-filter-button"
              onClick={() => {
                applyFilters(
                  props.editorialFilterData.startDate,
                  props.editorialFilterData.endDate,
                  checkSelectState
                );
              }}
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    editorialFilterData: state.editorialFilterData,
    campaigns: Object.values(state.editorialCampaigns),
    organizations: Object.values(state.editorialOrganizations),
    order: Object.values(state.editorialOrder),
    author: Object.values(state.editorialAuthor),
    sites: Object.values(state.sites),
    topicTagsIn: Object.values(state.editorialTopicTagsIn),
    topicTagsEx: Object.values(state.editorialTopicTagsEx),
    placementTagsIn: Object.values(state.editorialPlacementTagsIn),
    placementTagsEx: Object.values(state.editorialPlacementTagsEx),
    orgTagsIn: Object.values(state.editorialOrgTagsIn),
    orgTagsEx: Object.values(state.editorialOrgTagsEx),
    countries: Object.values(state.editorialCountry),
    editorialEngOrg: Object.values(state.editorialEngOrg),
    orgTypes: Object.values(state.orgTypesList),
    firmographicOrgsType: Object.values(state.editorialFirmographicOrgsType),
    orgSize: Object.values(state.orgSizeList),
    firmographicOrgSize: Object.values(state.editorialFirmographicOrgsSize),
    orgRevenue: Object.values(state.orgRevenueList),
    firmographicOrgsRevenue: Object.values(
      state.editorialFirmographicOrgsRevenue
    ),
    firmographicSector: Object.values(state.editorialFirmographicSector),
    sector: Object.values(state.sectorList),
    firmographicIndustryGroup: Object.values(
      state.editorialFirmographicIndustryGroup
    ),
    industryGroup: Object.values(state.industryGroupList),
    firmographicIndustry: Object.values(state.editorialFirmographicIndustry),
    industry: Object.values(state.industryList),
    firmographicSubIndustry: Object.values(
      state.editorialFirmographicSubIndustry
    ),
    subIndustry: Object.values(state.subIndustryList),
    seniorities: Object.values(state.seniorities),
    senioritiesIn: Object.values(state.editorialSenioritiesIn),
    senioritiesEx: Object.values(state.editorialSenioritiesEx),
    jobfunctions: Object.values(state.jobfunctions),
    JobFunctionsEX: Object.values(state.editorialJobfunctionsEx),
    JobFunctionsIN: Object.values(state.editorialJobfunctionsIn),
    urlSource: Object.values(state.editorialUrlSource),
    urlMedium: Object.values(state.editorialUrlMedium),
    urlCampaigns: Object.values(state.editorialUrlCampaigns),
    urlTerm: Object.values(state.editorialUrlTerm),
    urlContent: Object.values(state.editorialUrlContent),
    pageTitle: Object.values(state.editorialPageviewTitle),
    editorialEngMember: Object.values(state.editorialEngMember),
    editorialPrimaryTopic: Object.values(state.editorialPrimaryTopic),
    isFilApplied: state.editorialFetchDetailData.isFilApplied,
    topicEditorialSummary: state.editorialFetchDetailData.topicEditorialSummary,
    editorialPageSummary:state.editorialFetchDetailData.editorialPageSummary,
    placementEditorialSummary: state.editorialFetchDetailData.placementEditorialSummary,
    editorialPrimaryTopicSummary: state.editorialFetchDetailData.editorialPrimaryTopicSummary,
    pageviewList: state.editorialFilterData.pageview_config_data,
  };
};

export default connect(mapStateToProps, {
  selEditorialFilterValue,
  clearSearchOrder,
  fetchOrder,
  fetchCampaigns,
  addOrganization,
  selectedEditorialFilterValue,
  fetchOrganizations,
  clearSearchOrgs,
  addOrder,
  addCampaigns,
  clearSearchCampaigns,
  resetOrgsOrCampaigns,
  clearEditorialSerchedValue,
  fetchPgAuthors,
  fetchTags,
  addCountries,
  fetchPgCountry,
  firmographicOrgsTypeCall,
  selEditorialToggles,
  SenioritiesList,
  fetchPageTitle,
  fetchUrlParams,
  fetchPrimaryTopic,
  fetchEngagedMember,
  applyEditorialFilter,
  fetchEditorialTableData,
  fetchEngagedOrg,
  checkToggle,
  applyIndependentDate,
  fetchPageviewTableData,
  updateCommonData,
})(SearchFilter);