export const HeaderData = [
    { headerName: "", headerKey: "blank"},
    { headerName: "AGENCY", headerKey: "agency"},
    { headerName: "ACCOUNT", headerKey: "order_account" },
    { headerName: "STATUS", headerKey: "order_status"},
    { headerName: "ORDER", headerKey: "order"},
    { headerName: "CATEGORY", headerKey: "category"},
    { headerName: "CAMPAIGN", headerKey: "campaign"},
    { headerName: "LINE ITEM/PAGE", headerKey: "line_item_page"},
    { headerName: "TYPE", headerKey: "type"},
    { headerName: "START DATE", headerKey: "start_date"},
    { headerName: "ACTUAL START DATE", headerKey: "actual_start_date"},
    { headerName: "END DATE", headerKey: "end_date"},
    { headerName: "PROJECTED END DATE", headerKey: "projected_end_date"},
    { headerName: "% ELAPSED TIME (a)", headerKey: "percent_time_elapsed"},
    { headerName: "KPI ROADBLOCK", headerKey: "kpi_roadblock"},
    { headerName: "KPI TYPE", headerKey: "kpi"},
    { headerName: "KPI COMMITTED", headerKey: "kpi_commited"},
    { headerName: "KPI DELIVERED OVERALL (STANDARD)", headerKey: "kpi_delivered_total"},
    { headerName: "KPI DELIVERED OVERALL (ROADBLOCK)", headerKey: "kpi_delivered_roadblock"},
    { headerName: "KPI DELIVERED IN RANGE", headerKey: "kpi_delivered"},
    { headerName: "KPI % COMPLETE", headerKey: "complete_percent"},
    { headerName: "CLICKS", headerKey: "order_clicks"},
    { headerName: "CLICK THROUGH RATE", headerKey: "order_ctr"},
    { headerName: "% ON PACE", headerKey: "pace", textAlign: "right"},
];