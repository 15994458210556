import React from 'react';
import { Form, Dropdown } from 'semantic-ui-react';

const SingleSelectDropDown = (props) => {
    return (
        <Form.Field 
            floating 
            selection 
            search 
            control={Dropdown} 
            {...props} 
        />
    );
};

export default SingleSelectDropDown;
