import axios from "../api/axios";
import { url } from "../api/urlHelper";
import * as actionTypes from "./actionTypes";
import { headers } from "../api/apiConstants";
import { ApiPayloadData, AdSummaryExtraDataHandler, AdDataExtraDataHandler } from "../utilities/ApiPayloadHandler";
import { LIMIT_20, PAGE_SIZE } from "../components/config/TableHeaderNames";
import { extractFileName } from "../utilities/common";

export const seladFilterValue = (value, dispatchType) => {
	return {
		type: dispatchType,
		payload: value,
	};
};

export const adsSetPageCount = (dispatchType) => {
	return {
		type: dispatchType,
	};
};

export const fetchAdCampaignSummary = (filterData, page, type = "", dispatchType, reportType, setReportType = "") => async (dispatch) => {

	const data = {
		...ApiPayloadData(filterData, page, type, reportType, LIMIT_20, url.adCampaignReport),
		...AdSummaryExtraDataHandler(filterData),
	};
	await axios
		.post(url.adCampaignReport, data, 
		{
			headers: headers(),
		})
		.then((res) => {
			if (type) {
				// Access specific header
				const fileName = extractFileName(res.headers)
				if(setReportType !== "") {
					dispatch({
						type: actionTypes[setReportType].CAMPAIGN_SUMMARY_CSV,
						payload: res.data,
						downloadFileName: fileName,
						reportType: setReportType,
					})
				} else {
					dispatch({
						type: actionTypes.ADCAMPAIGNSUMMARYCSV,
						payload: res.data,
						downloadFileName: fileName,
						reportType: setReportType,
					});
				}
			} else {
				dispatch({
					type: dispatchType,
					payload: res.data,
					resCampaignSummaryValue: res.data === null && true,
					reportType: setReportType,
				});
			}
			if(res.status === 200){
				setTimeout(() => {
					dispatch({
						type: actionTypes.UPDATEDCHARTSTATUS,
						payload: {
							adCampaignLoaded: true,
						},
					})
				}, 1500);
				if(setReportType !== ""){
					setTimeout(() => {
						dispatch({
							type: actionTypes[setReportType].UPDATED_CHART_STATUS,
							reportType: setReportType,
							payload: {
								campaignLoaded: true,
							},
						})
					}, 1500);
				}
			}
		})
		.catch((err) => {
			if (err.response) {
				dispatch({
					type: actionTypes.ERRORCODE,
					payload: err.response.status,
				});
			}
		});
	};


export const fetchAdImpressions = (filterData, page, type = "", dispatchType, reportType, setReportType = "") => async (dispatch) => {
	const data = ApiPayloadData(filterData, page, type, reportType, LIMIT_20, url.adImpressionReport);
	await axios
		.post(url.adImpressionReport, data, 
		{
			headers: headers(),
		})
		.then((res) => {
			if (type) {
				// Access specific header
				const fileName = extractFileName(res.headers);
				if(setReportType !== "") {
					dispatch({
						type: actionTypes[setReportType].CAMPAIGN_IMPRESSION_DETAIL_CSV,
						payload: res.data,
						downloadFileName: fileName,
						reportType: setReportType,
					})
				} else {
					dispatch({
						type: actionTypes.ADIMPRESSIONCSV,
						payload: res.data,
						downloadFileName: fileName,
						reportType: setReportType,
					});
				}
				
			} else {
				dispatch({
					type: dispatchType,
					payload: res.data,
					resCampaignDetailsValue: res.data === null && true,
					reportType: setReportType,
				});
				if(res.status === 200){
					if(setReportType !== "") {
						setTimeout(() => {
							dispatch({
								type: actionTypes[setReportType].UPDATED_CHART_STATUS,
								reportType: setReportType,
								payload: {
									detailLoaded: true,
								},
							})
						}, 1500);
					}
					setTimeout(() => {
						dispatch({
							type: actionTypes.UPDATEDCHARTSTATUS,
							payload: {
								adDetailLoaded: true,
							},
						})
					}, 1500);
				}
			}
		})
		.catch((err) => {
			if (err.response) {
				dispatch({
					type: actionTypes.ERRORCODE,
					payload: err.response.status,
				});
			}
		});
	};

export const fetchChart = (filterData, page, type = "", dispatchType, reportType, reportAction) => async (dispatch) => {
	const data = ApiPayloadData(filterData, page, type, reportType, LIMIT_20, url.adReportCharts);
	await axios
		.post(url.adReportCharts, data, 
		{
			headers: headers(),
		})
		.then((res) => {
			dispatch({
				type: dispatchType,
				payload: res.data,
				reportType: reportAction,
			});
		})
		.catch((err) => {
			if (err.response) {
				dispatch({
					type: actionTypes.ERRORCODE,
					payload: err.response.status,
				});
			}
		})
		.finally(()=>{
			dispatch({
				type: actionTypes[reportAction].UPDATED_CHART_STATUS,
				reportType: reportAction,
				payload: {
					chartApiCompleted: true,
					campaignLoaded: false,
					detailLoaded: false,
					chartApiCalled: false,
				},
			})
	})
};

export const fetchCharts = (filterData, page, type = "", dispatchType, reportType) => async (dispatch) => {
	const data = ApiPayloadData(filterData, page, type, reportType, LIMIT_20, url.adReportCharts);
	await axios
		.post(url.adReportCharts, data, 
		{
			headers: headers(),
		})
		.then((res) => {
			dispatch({
				type: dispatchType,
				payload: res.data,
			});
		})
		.catch((err) => {
			if (err.response) {
				dispatch({
					type: actionTypes.ERRORCODE,
					payload: err.response.status,
				});
			}
		})
		.finally(()=>{
			dispatch({
				type: actionTypes.UPDATEDCHARTSTATUS,
				payload: {
					chartApiCompleted: true,
					adCampaignLoaded: false,
					adDetailLoaded: false,
					chartApiCalled: false,
				},
			})
	})
};
export const fetchAdData = (uri, filterData, page, type = "", dispatchType, csvType = "", newEmail, idForShowMore=0, reportType, setReportType = "") =>
	async (dispatch) => {
		const data = {
			...ApiPayloadData(filterData, page, type, reportType, PAGE_SIZE, uri),
			...AdDataExtraDataHandler(filterData, newEmail, idForShowMore),
		};
		await axios
			.post(uri, data, 
			{
				headers: headers(),
			})
			.then((res) => {
				if (res.status === 200) {
					if (type) {
						dispatch({
							type: csvType,
							payload: uri,
							email: newEmail,
							csv: res.data,
							reportType: setReportType,
						});
					} else {
						dispatch({
							type: dispatchType,
							payload: res?.data ? res?.data : null,
							filterData: filterData,
							resAdDetailsValue: res.data === null && true,
							reportType: setReportType,
						});
					}
				} else if (res.status === 202) {
					if (type) {
						dispatch({
							type: csvType,
							payload: uri,
							email: newEmail,
							reportType: setReportType,
						});
					}
				}
			})
			.catch((err) => {
				if (err.response) {
					dispatch({
						type: actionTypes.ERRORCODE,
						payload: err.response.status,
					});
				}
			});
	};

export const openAdMenus = (value, dispatchType) => {
	return {
		type: dispatchType,
		payload: value,
	};
};

export const filterProfile = (value) => ({
	type: actionTypes.FILTER_PROFILE,
	payload: value
});
