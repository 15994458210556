import React, { useState, useEffect } from "react";
import _ from "lodash";

import * as downloadHelper from "../../components/CommonFilter/downloadHelper";
import { getDays } from "../../utilities/common";
import { url } from "../../api/urlHelper";

const WithReportHandler = (WrappedComponent, config) => {
    const {
        initialHeader,
        getTableHeadersAction,
        sendTableHeadersAction,
        table_name,
        hName,
        hKey
    } = config;

    const HOC = (props) => {
        const [isSortIcon, setSortIcon] = useState(false);
        const [selectHeaders, setSelectHeaders] = useState([]);
        const [finalHeaderData, setFinalHeaderData] = useState([]);
        const [popup, setPopup] = useState(false);

        useEffect(() => {
            props.getTableHeaders(
                table_name,
                getTableHeadersAction,
                props.reportActionType,
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.getTableHeaders, getTableHeadersAction, props.reportActionType]);

        useEffect(() => {
            props.getTableHeaders(
                table_name,
                getTableHeadersAction,
                props.reportActionType,
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.getTableHeaders, table_name, getTableHeadersAction, props.reportActionType]);

        useEffect(() => {
            const headerDataVal = props.filterData.getDynamicHeaderData;
            if (!_.isEmpty(headerDataVal)) {
                setSelectHeaders(headerDataVal);
                setFinalHeaderData(headerDataVal);
            } else if (headerDataVal === undefined || _.size(headerDataVal) === 0) {
                const constantHeadersArr = hKey !== "" 
                ? [{ headerName: hName, headerKey: hKey }, ...initialHeader] 
                : [...initialHeader];
                
                setSelectHeaders(constantHeadersArr);
                setFinalHeaderData(constantHeadersArr);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.filterData.getDynamicHeaderData, initialHeader]);

        const clickOnSortHeader = (e, extraData, tableData) => {
            if (extraData.checked) {
                setSelectHeaders([...selectHeaders, { headerName: tableData.headerName, headerKey: tableData.headerKey }]);
            } else {
                const removeData = selectHeaders.filter(
                    (hName) => hName.headerKey !== tableData.headerKey
                );
                setSelectHeaders(removeData);
            }
        };

        const applyButtonClick = () => {
            let finalHeaderArray = [];

            const constantHeadersArr = hKey !== "" 
                ? [{ headerName: hName, headerKey: hKey }, ...initialHeader] 
                : [...initialHeader];

            constantHeadersArr.forEach((mainHeader) => {
                selectHeaders.forEach((selectHeader) => {
                    if (mainHeader.headerKey === selectHeader.headerKey) {
                        finalHeaderArray.push(selectHeader);
                    }
                });
            });
            setFinalHeaderData(finalHeaderArray);
            props.sendTableHeaders(
                table_name,
                finalHeaderArray,
                sendTableHeadersAction,
                props.reportActionType,
            );
            setSortIcon(!isSortIcon);
        };

        const handleCampaignSummaryXlsx = async (tableName) => {
            let days = getDays(props.filterData.startDate, props.filterData.endDate);
            if (days > 90) {
                setPopup(true);
                setTimeout(() => {
                    setPopup(false);
                }, 5000);
            } else {
                downloadHelper.getCampaignExcel(props, url.adDetail, props.userEmail);
            }
        };

        return (
            <div>
                <WrappedComponent
                    {...props}
                    selectHeaders={selectHeaders}
                    finalHeaderData={finalHeaderData}
                    isSortIcon={isSortIcon}
                    clickOnSortHeader={clickOnSortHeader}
                    applyButtonClick={applyButtonClick}
                    setSortIcon={setSortIcon}
                    handleCampaignSummaryXlsx={handleCampaignSummaryXlsx}
                    popup={popup}
                    setPopup={setPopup}
                />
            </div>
        );
    };

    return HOC;
};

export default WithReportHandler;
