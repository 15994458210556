import React from "react";
import GridIcons from "./gridIcons";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import StoreIcon from "@mui/icons-material/Store";
import CampaignReport from "./campaignReport";
import {getDisplayDate, numFormat} from "../../utilities/common";
import Link from "../hyperlinks/link";
import MultiLinks from "../hyperlinks/multiLinks";
//import TopicCampaign from "./topicCampaign";
import { isEmpty } from "lodash";
import { List } from "semantic-ui-react";

const iconsDesign = {
  height: "1.9rem",
  textAlign: "centre",
};
const TopichubCampaign = (props) => {
  let topichubCampaign = props.data;
  const Cards = [
    {
      title: "Impressions",
      value: numFormat(topichubCampaign.impressions),
      icon: <StoreIcon style={iconsDesign} />,
    },
    {
      title: "Clicks",
      value: numFormat(topichubCampaign.clicks),
      icon: <TouchAppIcon style={iconsDesign} />,
    },
    {
      title: "Click Through Rate",
      value: topichubCampaign.ctr,
      icon: <CallSplitIcon style={iconsDesign} />,
    },
  ];
  return (
    <div>
      <GridIcons data={Cards} />
      <CampaignReport
        object={
          topichubCampaign.organizations ? (
            <MultiLinks
              type="organizations"
              object={topichubCampaign.organizations}
            ></MultiLinks>
          ) : (
            topichubCampaign.account
          )
        }
        label={"ACCOUNT"}
      />
      <CampaignReport
        object={
          topichubCampaign.order.name ? (
            <Link
              type={"orders"}
              urlParam={topichubCampaign.order.uuid}
              refValue={topichubCampaign.order.name}
            ></Link>
          ) : (
            " - "
          )
        }
        label={"ORDER"}
      />
      <CampaignReport
        object={
          topichubCampaign.campaign_name ? (
            <Link
              type={"campaigns"}
              urlParam={topichubCampaign.uuid}
              refValue={topichubCampaign.campaign_name}
            ></Link>
          ) : (
            " - "
          )
        }
        label={"CAMPAIGN NAME"}
      />
      <CampaignReport
        object={
          topichubCampaign.start_date
            ? getDisplayDate(topichubCampaign.start_date)
            : " - "
        }
        label={"START DATE"}
      />
      <CampaignReport
        object={
          topichubCampaign.end_date
            ? getDisplayDate(topichubCampaign.end_date)
            : " - "
        }
        label={"END DATE"}
      />
      <h5 className="drawer-heading-text" style={{marginBottom: "-1rem"}}>Sidebars</h5>
        <List>
          <List.Item className="drawer-value">
            <List.Item className="drawer-text">{ 
            topichubCampaign.sidebars && !isEmpty(topichubCampaign.sidebars) ? 
            <MultiLinks
                type="sidebars"
                object={topichubCampaign.sidebars}
              ></MultiLinks> : "-"}
            </List.Item>
          </List.Item>
        </List>
      {/* <TopicCampaign object={topichubCampaign.custom_sites ? topichubCampaign.custom_sites : ""} /> */}
     
    </div>
  );
};

export default TopichubCampaign;
