import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";
import {
  getDisplayDate,
  getYesterdaysDate,
  getLastDays,
} from "../utilities/common";
import { siteValue } from "../constants";

const editorialDetailsFilterData = {
    startDate: getDisplayDate(getLastDays(0)),
    endDate: getDisplayDate(getYesterdaysDate()),
    appliedDate: "2",
    previousStartDate: "",
    previousEndDate: "",
    prevYearEndDate: "",
    selCutomRangeChkBox: false,
    checkboxState: false,
    organization: [],
    campaign: [],
    order: [],
    site: siteValue,
    campaignStatus: "",
    selPageTitle: "",
    selAuthor: "",
    selCountry: [],
    selOrgTagsIN: [],
    selOrgTagsEX: [],
    selTopicTagsIN: [],
    selTopicTagsEX: [],
    selPlacementTagsIN: [],
    selPlacementTagsEX: [],
    selSenioritiesIN: [],
    selSenioritiesEX: [],
    selJobFunctionsEX: [],
    selJobFunctionsIN: [],
    selJobTitlesIN: "",
    selJobTitlesEX: "",
    selSector: [],
    selIndustryGroup: [],
    selIndustry: [],
    selSubIndustry: [],
    selOrgType: [],
    selOrgSize: [],
    selOrgRevenue: [],
    selUrlSource: "",
    selUrlMedium: "",
    selUrlCampaign: "",
    selUrlTerm: "",
    selUrlContent: "",
    selPageViewsByOrg: [],
    excludeQACampaign: false,
    excludeInternalEvents: false,
    excludeInternalCampaign: false,
    showPageviewChart: false,
    selectAdDetailTable: false,
    firmographicToggle: false,
    email: "",
    includedVerticals: [],
    personaToggle: false,
    pageParams: false,
    selEnagedMember: [],
    appliedDateFilter: "2",
    wp_startDate: getDisplayDate(getLastDays(0)),
    wp_endDate: getDisplayDate(getYesterdaysDate()),
    selPrimaryTopic: [],
    openPageviewMenus: false,
    selTrafficSource: "",
    edIsIncludeParent: false,
    edIsIncludeChild: false,
    exAnonymousMember: false,
		exAnonymousOrgs: false,
    trafficType: "all",
    pageviewType: "all",
  };

  const editorialDetailsSelData = {
    orgs: [],
    campaigns: [],
    order: [],
    engOrgs: [],
    country: [],
    engMember: [],
    primaryTopic: [],
  };
  
  const selectedTags = {
    orgTagsIn: [],
    orgTagsEx: [],
    topicTagsIn: [],
    topicTagsEx: [],
    placementTagsIn: [],
    placementTagsEx: [],
    senioritiesIN: [],
    senioritiesEX: [],
    jobfunctionsIN: [],
    jobfunctionsEX: [],
    sector: [],
    industry_group: [],
    industry: [],
    sub_industry: [],
    orgsType: [],
    orgsSize: [],
    orgsRevenue: [],
  };
  
  const editorialDetailsFetchData = {
    isFilApplied: false,
    isLoadingChart: false,
    scCampaignDetail: {},
    isLoadingEdCampaignDetail: false,
    pageviewDetail: {},
    pageviewDetailed: {},
    pageCount: {},
    pageviewCharts: [],
    countAPI: false,
    isLoadingEdSummary: false,
    isLoadingPageviewDetailed: false,
    pageviewDetailCsv: "",
    emailPageSummary: "",
    emailPageDetail: "",
    cardPopupPageSummary: "",
    cardPopupPageDetail: "",
    postEmailUpdate: "",
    postEmailUpdatePageSummary: "",
    confirmEmail: false,
    isLoadingPageviewDetailCsv: false,
    topicPageviewSummarycolumn: null,
    placementPageviewSummarycolumn: null,
    pageviewDetailedColumn: null,
    column: null,
    topicPageviewSummarydirection: null,
    placementPageviewSummarydirection: null,
    pageviewDetailedDirection: null,
    direction: null,
    placementPageviewSummarytype: "",
    topicPageviewSummarytype: "",
    type: "",
    topicPageCount: "",
    detailPageCount: "",
    detailedPageCount: "",
    placementPageCount: "",
    placementPageviewSummaryLoad: false,
    topicPageviewSummaryLoad: false,
    edSummary: false,
    isLoadingDetailCsv: false,
    edDetailCsv: '',
    resEDetails: null,
    grandTotal: 0,
    postEmailUpdateCSV: "",
    getDynamicEditorialPageSummaryHeaderData: [],
    dynamicEditorialPageSummaryHeaderArray: [],
  };

  export const editorialDetailsDataReducer = ( state = editorialDetailsFilterData, action ) => {
    //SPONSOREDCONTENT
    switch (action.type) {
        case actionTypes.APPLYEDITORIALDETAILSDATE:
            return { ...state, appliedDate: action.payload };
        case actionTypes.SELEDITORIALDETAILSSTARTDATE:
            return { ...state, startDate: action.payload };
        case actionTypes.SELEDITORIALDETAILSENDDATE:
            return { ...state, endDate: action.payload };
        case actionTypes.SELEDITORIALDETAILSCUSTOMRANGE:
            return { ...state, selCutomRangeChkBox: action.payload };
        case actionTypes.SELEDITORIALDETAILSPREVSTARTDATE:
            return { ...state, previousStartDate: action.payload };
        case actionTypes.SELEDITORIALDETAILSPREVENDDATE:
            return { ...state, previousEndDate: action.payload };
        case actionTypes.SELEDITORIALDETAILSORGS:
            return { ...state, organization: action.payload };
        case actionTypes.SELEDITORIALDETAILSORDER:
            return { ...state, order: action.payload };
        case actionTypes.SELEDITORIALDETAILSSTATUS:
            return { ...state, campaignStatus: action.payload };
        case actionTypes.SELEDITORIALDETAILSCAMPAIGNS:
            return { ...state, campaign: action.payload };
        case actionTypes.SELEDITORIALDETAILSAUTHOR:
            return { ...state, selAuthor: action.payload };
        case actionTypes.SELEDITORIALDETAILSSITE:
            return { ...state, site: action.payload };
        case actionTypes.SELPGEDITORIALDETAILSTAGSIN:
            return { ...state, [action.payload.key]: action.payload.value };
        case actionTypes.SELEDITORIALDETAILSCOUNTRY:
            return { ...state, selCountry: action.payload };
        case actionTypes.SELEDITORIALDETAILSENGORGS:
            return { ...state, selPageViewsByOrg: action.payload };
        case actionTypes.SELEDFIRMOGRAPHICTOGGLE:
            return { ...state, firmographicToggle: !state.firmographicToggle };
        case actionTypes.SELEDPERSONATOGGLE:
            return { ...state, personaToggle: !state.personaToggle };
        case actionTypes.SELEDPAGEPARAMSTOGGLE:
            return { ...state, pageParams: !state.pageParams };
        case actionTypes.APPLYEDDATEFILTER:
            return { ...state, appliedDateFilter: action.payload };
        case actionTypes.SELEDWPSTARTDATE:
            return { ...state, wp_startDate: action.payload };
        case actionTypes.SELEDWPENDDATE:
            return { ...state, wp_endDate: action.payload };
        case actionTypes.RESETSELEDURLCAMPANDMEDIUM:
            return { ...state, selUrlMedium: "", selUrlCampaign: "" };
        case actionTypes.SELEDQATOGGLE:
            return { ...state, excludeQACampaign: !state.excludeQACampaign };
        case actionTypes.SELEDINTERNALEVENTSTOGGLE:
          return { ...state, excludeInternalEvents: !state.excludeInternalEvents };    
        case actionTypes.SELEDINTOGGLE:
            return { ...state, excludeInternalCampaign: !state.excludeInternalCampaign };
        case actionTypes.SELEDPARENTTOOGLE:
          return { ...state, edIsIncludeParent: !state.edIsIncludeParent };
        case actionTypes.SELEDCHILDTOOGLE:
          return { ...state, edIsIncludeChild: !state.edIsIncludeChild };
        case actionTypes.SELEDEXANONYMOUSORGS:
          return { ...state, exAnonymousOrgs: !state.exAnonymousOrgs };
        case actionTypes.SELEDEXANONYMOUSMEMBERS:
          return { ...state, exAnonymousMember: !state.exAnonymousMember };
        case actionTypes.CLEAREDFILTER:
        return {
          startDate: getDisplayDate(getLastDays(7)),
          endDate: getDisplayDate(getYesterdaysDate()),
          appliedDate: "7",
          previousStartDate: "",
          previousEndDate: "",
          prevYearEndDate: "",
          selCutomRangeChkBox: false,
          checkboxState: false,
          organization: [],
          campaign: [],
          order: [],
          site: siteValue,
          campaignStatus: "",
          selPageTitle: "",
          selAuthor: "",
          selCountry: [],
          selOrgTagsIN: [],
          selOrgTagsEX: [],
          selTopicTagsIN: [],
          selTopicTagsEX: [],
          selPlacementTagsIN: [],
          selPlacementTagsEX: [],
          selSenioritiesIN: [],
          selSenioritiesEX: [],
          selJobFunctionsEX: [],
          selJobFunctionsIN: [],
          selJobTitlesIN: "",
          selJobTitlesEX: "",
          selSector: [],
          selIndustryGroup: [],
          selIndustry: [],
          selSubIndustry: [],
          selOrgType: [],
          selOrgSize: [],
          selOrgRevenue: [],
          selUrlSource: "",
          selUrlMedium: "",
          selUrlCampaign: "",
          selUrlTerm: "",
          selUrlContent: "",
          selPageViewsByOrg: [],
          excludeQACampaign: false,
          excludeInternalEvents: false,
          excludeInternalCampaign: false,
          showPageviewChart: false,
          selectAdDetailTable: false,
          firmographicToggle: false,
          email: "",
          includedVerticals: [],
          personaToggle: false,
          pageParams: false,
          selEnagedMember: [],
          appliedDateFilter: "2",
          wp_startDate: getDisplayDate(getLastDays(0)),
          wp_endDate: getDisplayDate(getYesterdaysDate()),
          selPrimaryTopic: [],
          openPageviewMenus: false,
          selTrafficSource: "",
          exAnonymousMember: false,
          exAnonymousOrgs: false,
          trafficType: "all",
          pageviewType: "all",
        };
        default:
            return state;
    }
  };

  export const editorialDetailsOrganizationsReducer = (state = [], action) => {
    switch (action.type) {
      case actionTypes.FETCHEDITORIALDETAILSORGS:
        return {
          ..._.mapKeys(editorialDetailsSelData.orgs, "uuid"),
          ..._.mapKeys(action.payload, "uuid"),
        };
      case actionTypes.ADDEDITORIALDETAILSORGS:
        editorialDetailsSelData.orgs = [];
        const orgIds = action.payload;
        orgIds.forEach(function (orgId) {
            editorialDetailsSelData.orgs.push(state[orgId]);
        });
        return { ..._.mapKeys(editorialDetailsSelData.orgs, "uuid") };
      case actionTypes.CLEAREDITORIALDETAILSORGS:
        return { ..._.mapKeys(editorialDetailsSelData.orgs, "uuid") };
      case actionTypes.RESETEDITORIALDETAILORGS:
        editorialDetailsSelData.campaigns = [];
        return editorialDetailsSelData.campaigns;
      default:
        return state;
    }
  };

  export const editorialDetailsOrderReducer = (state = [], action) => {
    switch (action.type) {
      case actionTypes.FETCHEDITORIALDETAILSORDER:
        return {
          ..._.mapKeys(editorialDetailsSelData.order, "uuid"),
          ..._.mapKeys(action.payload, "uuid"),
        };
      case actionTypes.ADDEDITORIALDETAILSORDER:
        editorialDetailsSelData.order = [];
        const orderIds = action.payload;
        orderIds.forEach(function (orderId) {
          editorialDetailsSelData.order.push(state[orderId]);
        });
        return { ..._.mapKeys(editorialDetailsSelData.order, "uuid") };
      case actionTypes.RESETEDORDER:
        editorialDetailsSelData.order = [];
        return editorialDetailsSelData.order;
      case actionTypes.CLEAREDITORIALDETAILSORDER:
        return { ..._.mapKeys(editorialDetailsSelData.order, "uuid") };
      default:
        return state;
    }
  };

  export const editorialDetailsCampaignsReducer = (state = [], action) => {
    switch (action.type) {
      case actionTypes.FETCHEDITORIALDETAILSCAMPAIGNS:
        return {
          ...state,
          ..._.mapKeys(editorialDetailsSelData.campaigns),
          ..._.mapKeys(action.payload, "uuid"),
        };
      case actionTypes.ADDEDITORIALDETAILSCAMPAIGNS:
        editorialDetailsSelData.campaigns = [];
        const campaignIds = action.payload;
        campaignIds.forEach(function (campaignId) {
          editorialDetailsSelData.campaigns.push(state[campaignId]);
        });
        return { ..._.mapKeys(editorialDetailsSelData.campaigns, "uuid") };
    //   case actionTypes.FETCHSELSPONSOREDCONTENTCAMPAIGNS:
    //     editorialDetailsSelData.campaigns = action.payload;
    //     return { ..._.mapKeys(editorialDetailsSelData.campaigns, "uuid") };
      case actionTypes.RESETEDITORIALDETAILSCAMPAIGNS:
        editorialDetailsSelData.campaigns = [];
        return editorialDetailsSelData.campaigns;
      case actionTypes.CLEAREDITORIALDETAILSCAMPAIGNS:
        return { ..._.mapKeys(editorialDetailsSelData.campaigns, "uuid") };
      default:
        return state;
    }
  };

  export const editorialDetailsAuthorReducer = (state = [], action) => {
    switch (action.type) {
      case actionTypes.FETCHEDITORIALDETAILSAUTHOR:
        return { ..._.mapKeys(action.payload, "id") };
      case actionTypes.CLEAREDITORIALDETAILSAUTHOR:
        return (state = []);
      default:
        return state;
    }
  };

  export const editorialDetailsTopicTagsInReducer = (state = [], action) => {
    switch (action.type) {
      case actionTypes.FETCHEDITORIALDETAILSTOPICTAGSIN:
        action.selected.forEach(function (id) {
          action.payload.forEach((tag) => {
            if (tag.key === id) {
              tag.disabled = true;
            }
          });
        });
        return { ...state, ..._.mapKeys(action.payload, "key") };
      case actionTypes.APPLYEDITORIALDETAILSTOPICTAGSIN:
        selectedTags.topicTagsIn = [];
        const ids = action.payload;
        ids.forEach(function (id) {
          selectedTags.topicTagsIn.push(state[id]);
        });
        return { ..._.mapKeys(selectedTags.topicTagsIn, "key") };
      case actionTypes.CLEAREDITORIALDETAILSTOPICTAGIN:
        return { ..._.mapKeys(selectedTags.topicTagsIn, "key") };
      case actionTypes.RESETEDTOPICTAGIN:
        selectedTags.topicTagsIn = [];
        return selectedTags.topicTagsIn;
      default:
        return state;
    }
  };

  export const editorialDetailsTopicTagsExReducer = (state = [], action) => {
    switch (action.type) {
      case actionTypes.FETCHEDITORIALDETAILSTOPICTAGSEX:
        action.selected.forEach(function (id) {
          action.payload.forEach((tag) => {
            if (tag.key === id) {
              tag.disabled = true;
            }
          });
        });
        return { ...state, ..._.mapKeys(action.payload, "key") };
      case actionTypes.APPLYEDITORIALDETAILSTOPICTAGSEX:
        selectedTags.topicTagsEx = [];
        const ids = action.payload;
        ids.forEach(function (id) {
          selectedTags.topicTagsEx.push(state[id]);
        });
        return { ..._.mapKeys(selectedTags.topicTagsEx, "key") };
      case actionTypes.CLEAREDITORIALDETAILSTOPICTAGEX:
        return { ..._.mapKeys(selectedTags.topicTagsEx, "key") };
      case actionTypes.RESETEDTOPICTAGEX:
        selectedTags.topicTagsEx = [];
        return selectedTags.topicTagsEx;
      default:
        return state;
    }
  };

  export const editorialDetailsPlacementTagsInReducer = (state = [], action) => {
    switch (action.type) {
      case actionTypes.FETCHEDITORIALDETAILSPLACEMENTTAGSIN:
        action.selected.forEach(function (id) {
          action.payload.forEach((tag) => {
            if (tag.key === id) {
              tag.disabled = true;
            }
          });
        });
        return { ...state, ..._.mapKeys(action.payload, "key") };
      case actionTypes.APPLYEDITORIALDETAILSPLACEMENTTAGSIN:
        selectedTags.placementTagsIn = [];
        const ids = action.payload;
        ids.forEach(function (id) {
          selectedTags.placementTagsIn.push(state[id]);
        });
        return { ..._.mapKeys(selectedTags.placementTagsIn, "key") };
      case actionTypes.CLEAREDITORIALDETAILSPLACEMENTTAGIN:
        return { ..._.mapKeys(selectedTags.placementTagsIn, "key") };
      case actionTypes.RESETEDPLACEMENTTAGIN:
        selectedTags.placementTagsIn = [];
        return selectedTags.placementTagsIn;
      default:
        return state;
    }
  };

  export const editorialDetailsPlacementTagsExReducer = (state = [], action) => {
    switch (action.type) {
      case actionTypes.FETCHEDITORIALDETAILSPLACEMENTTAGSEX:
        action.selected.forEach(function (id) {
          action.payload.forEach((tag) => {
            if (tag.key === id) {
              tag.disabled = true;
            }
          });
        });
        return { ...state, ..._.mapKeys(action.payload, "key") };
      case actionTypes.APPLYEDITORIALDETAILSPLACEMENTTAGSEX:
        selectedTags.placementTagsEx = [];
        const ids = action.payload;
        ids.forEach(function (id) {
          selectedTags.placementTagsEx.push(state[id]);
        });
        return { ..._.mapKeys(selectedTags.placementTagsEx, "key") };
      case actionTypes.CLEAREDITORIALDETAILSPLACEMENTTAGEX:
        return { ..._.mapKeys(selectedTags.placementTagsEx, "key") };
      case actionTypes.RESETEDPLACEMENTTAGEX:
        selectedTags.placementTagsEx = [];
        return selectedTags.placementTagsEx;
      default:
        return state;
    }
  };

  export const editorialDetailsOrgTagsInReducer = (state = [], action) => {
    switch (action.type) {
      case actionTypes.FETCHEDITORIALDETAILSORGTAGSIN:
        action.selected.forEach(function (id) {
          action.payload.forEach((tag) => {
            if (tag.key === id) {
              tag.disabled = true;
            }
          });
        });
        return { ...state, ..._.mapKeys(action.payload, "key") };
      case actionTypes.APPLYEDITORIALDETIALSORGTAGSIN:
        selectedTags.orgTagsIn = [];
        const ids = action.payload;
        ids.forEach(function (id) {
          selectedTags.orgTagsIn.push(state[id]);
        });
        return { ..._.mapKeys(selectedTags.orgTagsIn, "key") };
      case actionTypes.CLEAREDITORIALDETAILSORGTAGIN:
        return { ..._.mapKeys(selectedTags.orgTagsIn, "key") };
      case actionTypes.RESETEDORGTAGIN:
        selectedTags.orgTagsIn = [];
        return selectedTags.orgTagsIn;
      default:
        return state;
    }
  };

  export const editorialDetailsOrgTagsExReducer = (state = [], action) => {
    switch (action.type) {
      case actionTypes.FETCHEDITORIALDETAILSORGTAGSEX:
        action.selected.forEach(function (id) {
          action.payload.forEach((tag) => {
            if (tag.key === id) {
              tag.disabled = true;
            }
          });
        });
        return { ...state, ..._.mapKeys(action.payload, "key") };
      case actionTypes.APPLYEDITORIALDETAILSORGTAGSEX:
        selectedTags.orgTagsEx = [];
        const ids = action.payload;
        ids.forEach(function (id) {
          selectedTags.orgTagsEx.push(state[id]);
        });
        return { ..._.mapKeys(selectedTags.orgTagsEx, "key") };
      case actionTypes.CLEAREDITORIALDETAILSORGTAGEX:
        return { ..._.mapKeys(selectedTags.orgTagsEx, "key") };
      case actionTypes.RESETEDORGTAGEX:
        selectedTags.orgTagsEx = [];
        return selectedTags.orgTagsEx;
      default:
        return state;
    }
  };

  export const editorialDetailsCountryReducer = (state = [], action) => {
    switch (action.type) {
      case actionTypes.FETCHEDITORIALDETAILSCOUNTRY:
        return {
          ...state,
          ..._.mapKeys(action.payload, "name"),
        };
      case actionTypes.ADDEDITORIALDETAILSCONTRIES:
        editorialDetailsSelData.country = [];
        const countryData = action.payload;
        countryData.forEach(function (countryId) {
          editorialDetailsSelData.country.push(state[countryId]);
        });
        return { ..._.mapKeys(editorialDetailsSelData.country, "name") };
      case actionTypes.CLEAREDITORIALDETAILSCOUNTRY:
        return (state = []);
    //     return { ...state, ..._.mapKeys(editorialDetailsSelData.country, "id") };
      case actionTypes.RESETEDCOUNTRY:
        editorialDetailsSelData.country = [];
        return editorialDetailsSelData.country;
      default:
        return state;
    }
  };

  export const editorialDetailsEngOrgReducer = (state = [], action) => {
    switch (action.type) {
      case actionTypes.FETCHEDITORIALDETAILSENGORGS:
        return {
          ..._.mapKeys(editorialDetailsSelData.engOrgs, "uuid"),
          ..._.mapKeys(action.payload, "uuid"),
        };
      case actionTypes.ADDEDITORIALDETAILSENGORGS:
        editorialDetailsSelData.engOrgs = [];
        const pgengorgIds = action.payload;
        pgengorgIds.forEach(function (pgengorgId) {
          editorialDetailsSelData.engOrgs.push(state[pgengorgId]);
        });
        return { ..._.mapKeys(editorialDetailsSelData.engOrgs, "uuid") };
      case actionTypes.CLEAREDITORIALDETIALSENGORGS:
        return (state = []);
      case actionTypes.RESETEDENGORGS:
        editorialDetailsSelData.engOrgs = [];
        return editorialDetailsSelData.engOrgs;
      default:
        return state;
    }
  };


  export const editorialDetailsFirmographicSectorReducer = (
    state = [],
    action
  ) => {
    switch (action.type) {
      case actionTypes.FETCHEDSECTOR:
        return { ...state, ..._.mapKeys(action.payload, "sector") };
      case actionTypes.APPLYEDSECTOR:
        return { ...state, ..._.mapKeys(selectedTags.sector, "sector") };
      case actionTypes.CLEARSEARCHEDSECTOR:
        return { ...state, ..._.mapKeys(selectedTags.sector, "sector") };
      case actionTypes.RESETEDSECTOR:
        selectedTags.sector = [];
        return selectedTags.sector;
      default:
        return state;
    }
  };
  
  export const editorialDetailsFirmographicIndustryGroupReducer = (
    state = [],
    action
  ) => {
    switch (action.type) {
      case actionTypes.FETCHEDINDUSTRYGROUP:
        return { ...state, ..._.mapKeys(action.payload, "industry_group") };
      case actionTypes.APPLYEDINDUSTRYGROUP:
        return { ...state, ..._.mapKeys(action.payload, "industry_group") };
      case actionTypes.CLEARSEARCHEDINDUSTRYGROUP:
        return {
          ...state,
          ..._.mapKeys(selectedTags.industry_group, "industry_group"),
        };
      case actionTypes.RESETEDINDUSTRYGROUP:
        selectedTags.industry_group = [];
        return selectedTags.industry_group;
      default:
        return state;
    }
  };
  
  export const editorialDetailsFirmographicIndustryReducer = (
    state = [],
    action
  ) => {
    switch (action.type) {
      case actionTypes.FETCHEDINDUSTRY:
        return { ...state, ..._.mapKeys(action.payload, "industry") };
      case actionTypes.APPLYEDINDUSTRY:
        return { ...state, ..._.mapKeys(action.payload, "industry") };
      case actionTypes.CLEARSEARCHEDINDUSTRY:
        return { ...state, ..._.mapKeys(selectedTags.industry, "industry") };
      case actionTypes.RESETEDINDUSTRY:
        selectedTags.industry = [];
        return selectedTags.industry;
      default:
        return state;
    }
  };
  
  export const editorialDetailsFirmographicSubIndustryReducer = (
    state = [],
    action
  ) => {
    switch (action.type) {
      case actionTypes.FETCHEDSUBINDUSTRY:
        return { ...state, ..._.mapKeys(action.payload, "sub_industry") };
      case actionTypes.APPLYEDSUBINDUSTRY:
        return { ...state, ..._.mapKeys(action.payload, "sub_industry") };
      case actionTypes.CLEARSEARCHEDSUBINDUSTRY:
        return {
          ...state,
          ..._.mapKeys(selectedTags.sub_industry, "sub_industry"),
        };
      case actionTypes.RESETEDSUBINDUSTRY:
        selectedTags.sub_industry = [];
        return selectedTags.sub_industry;
      default:
        return state;
    }
  };
  
  export const editorialDetailsSenioritiesInReducer = (state = [], action) => {
    switch (action.type) {
      case actionTypes.FETCHEDSENIORITIESIN:
        action.selected.forEach(function (id) {
          action.payload.forEach((tag) => {
            if (tag.id === id) {
              tag.disabled = true;
            }
          });
        });
        return { ...state, ..._.mapKeys(action.payload, "id") };
      case actionTypes.APPLYEDSENIORITIESIN:
        selectedTags.senioritiesIN = [];
        const ids = action.payload;
        ids.forEach(function (id) {
          selectedTags.senioritiesIN.push(state[id]);
        });
        return { ..._.mapKeys(selectedTags.senioritiesIN, "id") };
      case actionTypes.CLEARSEARCHEDSENIORITIESIN:
        return { ..._.mapKeys(selectedTags.senioritiesIN, "id") };
      case actionTypes.RESETEDSENIORITIESIN:
        selectedTags.senioritiesIN = [];
        return selectedTags.senioritiesIN;
      default:
        return state;
    }
  };
  
  export const editorialDetailsSenioritiesExReducer = (state = [], action) => {
    switch (action.type) {
      case actionTypes.FETCHEDSENIORITIESEX:
        action.selected.forEach(function (id) {
          action.payload.forEach((tag) => {
            if (tag.id === id) {
              tag.disabled = true;
            }
          });
        });
        return { ...state, ..._.mapKeys(action.payload, "id") };
      case actionTypes.APPLYEDSENIORITIESEX:
        selectedTags.senioritiesEX = [];
        const ids = action.payload;
        ids.forEach(function (id) {
          selectedTags.senioritiesEX.push(state[id]);
        });
        return { ..._.mapKeys(selectedTags.senioritiesEX, "id") };
      case actionTypes.CLEARSEARCHEDSENIORITIESEX:
        return { ..._.mapKeys(selectedTags.senioritiesEX, "id") };
      case actionTypes.RESETEDSENIORITIESEX:
        selectedTags.senioritiesEX = [];
        return selectedTags.senioritiesEX;
      default:
        return state;
    }
  };

  export const editorialDetailsFirmographicOrgsTypeReducer = (
    state = [],
    action
  ) => {
    switch (action.type) {
      case actionTypes.FETCHEDITORIALDETAILSFIRMOGRAPHICORGSTYPE:
        return { ...state, ..._.mapKeys(action.payload, "value") };
      case actionTypes.APPLYEDITORIALDETAILSORGSTYPE:
        selectedTags.orgsType = [];
        const ids = action.payload;
        ids.forEach(function (id) {
          selectedTags.orgsType.push(state[id]);
        });
        return { ..._.mapKeys(selectedTags.orgsType, "value") };
      case actionTypes.CLEAREDITORIALDETAILSORGSTYPE:
        return { ..._.mapKeys(selectedTags.orgsType, "value") };
      case actionTypes.RESETEDORGSTYPE:
        selectedTags.orgsType = [];
        return selectedTags.orgsType;
      default:
        return state;
    }
  };

  export const editorialDetailsFirmographicOrgsSizeReducer = (
    state = [],
    action
  ) => {
    switch (action.type) {
      case actionTypes.FETCHEDITORIALDETAILSORGSSIZE:
        return { ...state, ..._.mapKeys(action.payload, "value") };
      case actionTypes.APPLYEDITORIALDETAILSORGSSIZE:
        selectedTags.orgsSize = [];
        const ids = action.payload;
        ids.forEach(function (id) {
          selectedTags.orgsSize.push(state[id]);
        });
        return { ..._.mapKeys(selectedTags.orgsSize, "value") };
      case actionTypes.CLEAREDITORIALDETAILSORGSSIZE:
        return { ..._.mapKeys(selectedTags.orgsSize, "value") };
      case actionTypes.RESETEDORGSSIZE:
        selectedTags.orgsSize = [];
        return selectedTags.orgsSize;
      default:
        return state;
    }
  };
  
  export const editorialDetailsFirmographicOrgsRevenueReducer = (
    state = [],
    action
  ) => {
    switch (action.type) {
      case actionTypes.FETCHEDITORIALDETAILSORGSREVENUE:
        return { ...state, ..._.mapKeys(action.payload, "value") };
      case actionTypes.APPLYEDITORIALDETAILSREVENUE:
        selectedTags.orgsRevenue = [];
        const ids = action.payload;
        ids.forEach(function (id) {
          selectedTags.orgsRevenue.push(state[id]);
        });
        return { ..._.mapKeys(selectedTags.orgsRevenue, "value") };
      case actionTypes.CLEAREDITORIALDETAILSORGSREVENUE:
        return { ..._.mapKeys(selectedTags.orgsRevenue, "value") };
      case actionTypes.RESETEDORGSREVENUE:
        selectedTags.orgsRevenue = [];
        return selectedTags.orgsRevenue;
      default:
        return state;
    }
  };
  
  export const editorialDetailsJobfunctionsInReducer = (state = [], action) => {
    switch (action.type) {
      case actionTypes.FETCHEDJOBFUNCTIONSIN:
        action.selected.forEach(function (id) {
          action.payload.forEach((tag) => {
            if (tag.id === id) {
              tag.disabled = true;
            }
          });
        });
        return { ...state, ..._.mapKeys(action.payload, "id") };
      case actionTypes.APPLYEDJOBFUNCTIONSIN:
        selectedTags.jobfunctionsIN = [];
        const ids = action.payload;
        ids.forEach(function (id) {
          selectedTags.jobfunctionsIN.push(state[id]);
        });
        return { ..._.mapKeys(selectedTags.jobfunctionsIN, "id") };
      case actionTypes.CLEARSEARCHEDJOBFUNCTIONSIN:
        return { ..._.mapKeys(selectedTags.jobfunctionsIN, "id") };
      case actionTypes.RESETEDJOBFUNCTIONSIN:
        selectedTags.jobfunctionsIN = [];
        return selectedTags.jobfunctionsIN;
      default:
        return state;
    }
  };
  
  export const editorialDetailsJobfunctionsExReducer = (state = [], action) => {
    switch (action.type) {
      case actionTypes.FETCHEDJOBFUNCTIONSEX:
        action.selected.forEach(function (id) {
          action.payload.forEach((tag) => {
            if (tag.id === id) {
              tag.disabled = true;
            }
          });
        });
        return { ...state, ..._.mapKeys(action.payload, "id") };
      case actionTypes.APPLYEDJOBFUNCTIONSEX:
        selectedTags.jobfunctionsEX = [];
        const ids = action.payload;
        ids.forEach(function (id) {
          selectedTags.jobfunctionsEX.push(state[id]);
        });
        return { ..._.mapKeys(selectedTags.jobfunctionsEX, "id") };
      case actionTypes.CLEARSEARCHEDJOBFUNCTIONSEX:
        return { ..._.mapKeys(selectedTags.jobfunctionsEX, "id") };
      case actionTypes.RESETEDJOBFUNCTIONSEX:
        selectedTags.jobfunctionsEX = [];
        return selectedTags.jobfunctionsEX;
      default:
        return state;
    }
  };

  export const EdPageviewTitleReducer = (state = [], action) => {
    switch (action.type) {
      case actionTypes.FETCHEDTITLE:
        return { ..._.mapKeys(action.payload, "id") };
      case actionTypes.CLEARSEARCHEDPAGETITLE:
        return (state = []);
      default:
        return state;
    }
  };
  
  export const EdUrlSourceReducer = (state = [], action) => {
    switch (action.type) {
      case actionTypes.FETCHEDURLSOURCE:
        return action.payload;
      case actionTypes.CLEARSEARCHEDURLSOURCE:
        return (state = []);
      default:
        return state;
    }
  };
  
  export const EdUrlMediumReducer = (state = [], action) => {
    switch (action.type) {
      case actionTypes.FETCHEDURLMEDIUM:
        return action.payload;
      case actionTypes.CLEARSEARCHEDURLMEDIUM:
        return (state = []);
      default:
        return state;
    }
  };
  
  export const EdUrlCampaignsReducer = (state = [], action) => {
    switch (action.type) {
      case actionTypes.FETCHEDURLCAMPAIGNS:
        return action.payload;
      case actionTypes.CLEARSEARCHEDURLCAMPAIGNS:
        return (state = []);
      default:
        return state;
    }
  };
  
  export const EdUrlTermReducer = (state = [], action) => {
    switch (action.type) {
      case actionTypes.FETCHEDURLTERM:
        return action.payload;
      case actionTypes.CLEARSEARCHEDURLTERM:
        return (state = []);
      default:
        return state;
    }
  };
  
  export const EdUrlContentReducer = (state = [], action) => {
    switch (action.type) {
      case actionTypes.FETCHEDURLCONTENT:
        return action.payload;
      case actionTypes.CLEARSEARCHEDURLCONTENT:
        return (state = []);
      default:
        return state;
    }
  };
  
  export const edEngMemberReducer = (state = [], action) => {
    switch (action.type) {
      case actionTypes.FETCHEDENGMEMBER:
        return {
          ..._.mapKeys(editorialDetailsSelData.engMember, "user_uuid"),
          ..._.mapKeys(action.payload, "user_uuid"),
        };
      case actionTypes.ADDEDENGMEMBER:
        editorialDetailsSelData.engMember = [];
        const pgengorgIds = action.payload;
        pgengorgIds.forEach(function (pgengorgId) {
          editorialDetailsSelData.engMember.push(state[pgengorgId]);
        });
        return { ..._.mapKeys(editorialDetailsSelData.engMember, "user_uuid") };
    //   case actionTypes.CLEARSEARCHSCENGMEMBER:
    //     return (state = []);
    case actionTypes.RESETEDENGMEMBER:
      editorialDetailsSelData.engMember = [];
      return editorialDetailsSelData.engMember;

      default:
        return state;
    }
  };
  
  export const edPrimaryTopicReducer = (state = [], action) => {
    switch (action.type) {
      case actionTypes.FETCHEDPRIMARYTOPIC:
        return {
          ..._.mapKeys(editorialDetailsSelData.primaryTopic, "tag_id"),
          ..._.mapKeys(action.payload, "tag_id"),
        };
      case actionTypes.ADDEDPRIMARYTOPIC:
        editorialDetailsSelData.primaryTopic = [];
        const pgengorgIds = action.payload;
        pgengorgIds.forEach(function (pgengorgId) {
          editorialDetailsSelData.primaryTopic.push(state[pgengorgId]);
        });
        return { ..._.mapKeys(editorialDetailsSelData.primaryTopic, "tag_id") };
      case actionTypes.CLEARSEARCHEDPRIMARYTOPIC:
        return (state = []);
      case actionTypes.RESETEDPRIMARYTOPIC:
        editorialDetailsSelData.primaryTopic = [];
        return editorialDetailsSelData.primaryTopic;
      default:
        return state;
    }
  };

  export const editorialDetailsFetchDataReducer = (state = editorialDetailsFetchData, action) => {
    switch (action.type) {
      case actionTypes.APPLYEDITORIALDETAILSFILTER:
        return {
          ...state,
            isLoadingEdCampaignDetail: false,
            countAPI: false,
            isLoadingEdSummary: false,
            pageviewDetailCsv: "",
            emailPageSummary: "",
            emailPageDetail: "",
            cardPopupPageSummary: "",
            cardPopupPageDetail: "",
            postEmailUpdate: "",
            postEmailUpdateCSV: "",
            postEmailUpdatePageSummary: "",
            confirmEmail: false,
            isLoadingPageviewDetailCsv: false,
            pageviewDetailedColumn: null,
            pageviewDetailedDirection: null,
            type: "",
            filterData: action.payload,
            isFilApplied: true,
            isLoadingChart: true,
            scCampaignDetail: {},
            pageviewDetail: {},
            pageviewDetailed: {},
            pageCount: {},
            isLoadingPageviewDetailed: true,
            column: null,
            direction: null,
            topicPageCount: 2,
            detailPageCount: 2,
            detailedPageCount: 2,
            isLoadingDetailCsv: false,
            edDetailCsv: '',
            resEDetails: null,
            grandTotal: 0,
        };
        // case actionTypes.MOREEDITORIALDETAILS:
        // if (action.payload === null) {
        //     return { ...state, isLoadingPageviewDetailed: false, 
        //       resEDetails: action.resEDetails
        //     };
        // } else {
        //     return { ...state, pageviewDetailed: [...state.pageviewDetailed, ...action.payload], isLoadingPageviewDetailed: false };
        // }
        // case actionTypes.EDITORIALDETAILS: 
        //     return { 
        //       ...state,
        //       pageviewDetailed: action.payload ? action.payload : [],
        //       grandTotal: action.grandTotal ? action.grandTotal : 0,
        //       isLoadingPageviewDetailed: false 
        //     };
        // case actionTypes.MOREEDITORIALDETAILSLOADING:
        //     return { ...state, isLoadingPageviewDetailed: true };
        // case actionTypes.SETDETAILEDEDCOUNT:
        //     return { ...state, detailedPageCount: state.detailedPageCount + 1 };
        // case actionTypes.SORTEDDETAILS:
        //   const directionValueDetails = state.pageviewDetailedDirection === "ascending" ? "desc" : "asc";
        //   const pageviewDetailed= _.orderBy(state.pageviewDetailed, [(item) => typeof(item[action.column]) === "string" ? item[action.column].toLowerCase() : item[action.column]], [directionValueDetails])
        //   const pageviewDetailedDirection= state.pageviewDetailedDirection === "ascending" ? "descending" : "ascending"

        //     if (state.pageviewDetailedColumn === action.column) {
        //         return {
        //           ...state,
        //           // pageviewDetailed: state.pageviewDetailed.reverse(),
        //           pageviewDetailed,
        //           pageviewDetailedDirection,
        //         };
        //     }
        //     return {
        //         ...state,
        //         pageviewDetailedColumn: action.column,
        //         pageviewDetailed,
        //         pageviewDetailedDirection,
        //     };
        case actionTypes.EDITORIALDETAILSEXCEL:
            if (action.payload === "/reports/pageviews/detail") {
                return {
                    ...state,
                    postEmailUpdatePageSummary: action.email,
                    isLoadingPageviewDetailCsv: false,
                };
            } else if (action.payload === "/reports/pageviews/details") {
                return {
                    ...state,
                    postEmailUpdate: action.email,
                    isLoadingPageviewDetailCsv: false,
                    // postEmailUpdateCSV: action.email,
                    // isLoadingDetailCsv: false
                };
            }
            /* falls through */
        case actionTypes.EDEXCEL:
          if (action.payload === "/reports/pageviews/details") {
            return {
                ...state,
                isLoadingDetailCsv: false,
                postEmailUpdateCSV: action.email,
            };
        }
        /* falls through */
        case actionTypes.EDCSVLOADING:
            return { ...state, isLoadingPageviewDetailCsv: true };
        case actionTypes.EDEMAILPAGEDETAIL:
			return { ...state, emailPageDetail: action.payload.email, cardPopupPageDetail: action.payload.class };
        case actionTypes.SETEDITORIALDETAILSPOPUPCLOSE:
            return { ...state, cardPopupPageDetail: action.payload };
        case actionTypes.SETEDPOSTEMAILUPDATE:
            return { ...state, postEmailUpdate: action.payload };
        case actionTypes.EDITORIALDETAILCSVLOADING:
            return { ...state, isLoadingDetailCsv: true };
        case actionTypes.EDVIEWDETAILSCSV:
            return { ...state, edDetailCsv: action.csv, isLoadingDetailCsv: false };
        case actionTypes.CLEAREDSORT:
            return {
              ...state,
              column: null,
              direction: null,
            };
        case actionTypes.EDEMAILCSVPAGEDETAIL:
          return { 
            ...state,
             emailPageDetailCSV: action.payload.email, 
             cardPopupPageDetailCSV: action.payload.class 
          };
        case actionTypes.SETEDPOSTEMAILUPDATECSV:
        return { ...state, postEmailUpdateCSV: action.payload };
        case actionTypes.EDGENERATECSVLOADING:
          return { ...state, isLoadingDetailCsv: true };
        // case actionTypes.GETEDITORIALDETAILSPAGESUMMARYDYNAMICTABLEHEADER:
        //   return {
        //     ...state, getDynamicEditorialPageSummaryHeaderData: action.payload
        //   }
        // case actionTypes.SENDEDITORIALDETAILSPAGESUMMARYDYANMICTABLEHEADER:
        //   return {
        //     ...state, dynamicEditorialPageSummaryHeaderArray: action.payload
        //   }
      default:
        return state;
    }
  };