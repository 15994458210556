import axios from "../api/axios";
import * as actionTypes from "./actionTypes";
import { headers } from "../api/apiConstants";
import { url } from "../api/urlHelper";
import { extractFileName, getApiDate } from "../utilities/common";
import _ from "lodash";
import { findReportFromURL } from "../components/config/FindReportFromURL";
import { getCookie } from "../utilities/envCommon";
import { PrivacyConsentDataHandler } from "../utilities/ApiPayloadHandler";

export const clientSetPageCount = (dispatchType) => {
  return {
    type: dispatchType,
  };
};

export const selClientLeadStartDate = (date) => (dispatch) => {
  dispatch({
    type: actionTypes.SELCLIENTLEADSTARTDATE,
    payload: date,
  });
};

export const selClientLeadEndDate = (date) => async (dispatch) => {
  await dispatch({
    type: actionTypes.SELCLIENTLEADENDDATE,
    payload: date,
  });
};

export const prevClientAppliedDate = (date) => (dispatch) => {
  dispatch({
    type: actionTypes.SELCLIENTPREVAPPLIEDDATE,
    payload: date,
  });
};

export const prevClientSelStartDate = (date) => (dispatch) => {
  dispatch({
    type: actionTypes.SELCLIENTSTARTDATEPREV,
    payload: date,
  });
};

export const prevClientSelEndDate = (date) => (dispatch) => {
  dispatch({
    type: actionTypes.SELCLIENTENDDATEPREV,
    payload: date,
  });
};

export const applyClientLeadDate = (val) => (dispatch) => {
  dispatch({
    type: actionTypes.APPLYCLIENTLEADDATE,
    payload: val,
  });
};

export const selClientLeadOrgs = (orgs) => (dispatch) => {
  dispatch({
    type: actionTypes.SELCLIENTLEADORGS,
    payload: orgs,
  });
};

export const selClientLeadOrder = (order) => (dispatch) => {
  dispatch({
    type: actionTypes.SELCLIENTLEADORDER,
    payload: order,
  });
};

export const selClientLeadCampaigns = (campaigns) => (dispatch) => {
  dispatch({
    type: actionTypes.SELCLIENTLEADCAMPAIGNS,
    payload: campaigns,
  });
};

export const selClientLeadStatus = (site) => (dispatch) => {
  dispatch({
    type: actionTypes.SELCLIENTLEADSTATUS,
    payload: site,
  });
};

export const selClientLeadPartner = (leadPartner) => (dispatch) => {
  dispatch({
    type: actionTypes.SELCLIENTLEADPARTNER,
    payload: leadPartner,
  });
};

export const selClientLeadSite = (status) => (dispatch) => {
  dispatch({
    type: actionTypes.SELCLIENTLEADSITE,
    payload: status,
  });
};

export const selClientLeadType = (type) => (dispatch) => {
  dispatch({
    type: actionTypes.SELCLIENTLEADTYPE,
    payload: type,
  });
};

export const selClientLeadEngOrgs = (engOrg) => (dispatch) => {
  dispatch({
    type: actionTypes.SELCLIENTLEADENGORGS,
    payload: engOrg,
  });
};

export const applyClientLeadFilter = () => (dispatch) => {
  dispatch({
    type: actionTypes.APPLYCLIENTLEADFILTER,
  });
};

export const selClientLeadPageTitle = (title) => (dispatch) => {
  dispatch({
    type: actionTypes.SELCLIENTLEADPAGETITLE,
    payload: title,
  });
};

export const clearClientLeadFilter = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.CLEARCLIENTLEADFILTER,
    payload: data
  });
};

export const sortClientLeadEngSummary = (column) => {
  return {
    type: actionTypes.SORTCLIENTLEADENGSUMMARY,
    column: column,
  };
};

export const showMoreClientLeadEngSummaryLoading = () => {
  return {
    type: actionTypes.MORECLIENTLEADENGSUMMARYLOADING,
  };
};

export const isTotalCountShowMore = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.ISTOTALLEADCOUNT,
    payload: data
  });
};

export const clientSelectDropDown = (data, dispatchType) => (dispatch) => {
  dispatch({
    type: dispatchType,
    payload: data
  });
}

export const fetchClientLeadEngs =
  (
    filterData,
    page,
    type = "",
    dispatchType,
    csvType = "",
    newEmail,
    isLeadClient = false
  ) =>
  async (dispatch) => {
    let leadState = filterData.clientLeadFilterVerificationState;
    if (filterData.clientLeadFilterVerificationState === "All" ||filterData.clientLeadFilterVerificationState === "all") {
      leadState = "";
    }
    let verificationStatus = filterData.clientLeadFilterStatus;
    if (filterData.clientLeadFilterStatus === "All" || filterData.clientLeadFilterStatus === "all") {
      verificationStatus = "";
    }
    if(filterData.clientLeadFilterStatus === "Received"){
      leadState = "Received";
    }
    let siteValue = filterData.site;
    if (filterData.site === "all") {
      siteValue = "";
    }
    let assetType = filterData.assetType;
    if (filterData.assetType === "all") {
      assetType = "";
    }
    let status = filterData.campaignStatus;
    let IncludeDeleted = false;
    if (filterData.campaignStatus === "all") {
      status = "";
      IncludeDeleted = true;
    } else if (filterData.campaignStatus === "archived") {
      status = "archived";
    }
    let report = "lead";
    if (newEmail) {
      filterData.email = newEmail;
    }
    const reportData = findReportFromURL(url.clientLeadEngsReport, "");
    const consentPayload = PrivacyConsentDataHandler(filterData);
    await axios
      .post(
        url.clientLeadEngsReport,
        {
          organizations: filterData.organization,
          campaigns: filterData.campaign,
          orders: filterData.order,
          engaged_organizations: filterData.engagedOrganizations,
          lead_partner: filterData.leadPartner,
          site: siteValue,
          status: status,
          asset_type: assetType,
          start_date: getApiDate(filterData.startDate).concat(" 00:00:00"),
          end_date: getApiDate(filterData.endDate).concat(" 23:59:59"),
          override_lead_report_filters: filterData.overrideLeadReportFilters,
          exclude_internal_events: filterData.excludeInternalEvents,
          exclude_organization_events: filterData.excludeOrganizationEvents,
          exclude_competitors: filterData.excludeCompetitorEvents,
          exclude_free_email_registrations: filterData.excludeFreeEmailEvents,
          order: "ASC",
          order_by: "email",
          include_deleted: IncludeDeleted,
          limit: 50,
          page: page,
          export: type,
          Is_lead_client: isLeadClient,
          applied_date_filter: filterData.appliedDate,
          exclude_qa: filterData.excludeQACampaign,
          exclude_internal: filterData.excludeInternalCampaign,
          override_account_filters: filterData.account,
          override_vertical_filters: filterData.vertical,
          override_geo_filters: filterData.geo,
          override_custom_questions: filterData.customQuestions,
          override_firmographic_filters: filterData.firmoGraphic,
          override_organization_types_filters: filterData.types,
          override_organization_sizes_filters: filterData.size,
          override_estimate_revenues_filters: filterData.revenue,
          override_personas_filters: filterData.personas,
          override_seniority_filters: filterData.seniority,
          override_job_functions_filters: filterData.jobFunctions,
          override_job_titles_filters: filterData.jobTitles,
          exclude_email_suppression: filterData.excludeEmailSupression,
          page_title: filterData.title,
          utm_source: filterData.selUrlSource,
          utm_medium: filterData.selUrlMedium,
          utm_campaign: filterData.selUrlCampaign,
          utm_term: filterData.selUrlTerm,
          utm_content: filterData.selUrlContent,
          report: report,
          verification_state: leadState,
          verification_status: verificationStatus,
          email: filterData.email,
          delivery_state: (filterData.clientDeliverdFilterState).trim(),
          utm_filters: filterData.utmParams,
          report_name: reportData ? reportData.report : "",
          report_table_name: reportData ? reportData.report_table_name : "",
          domain_filter: filterData?.domainFilter,
          session_id: getCookie("session_id"),
          exclude_competitor_orgs: filterData?.competitorFiltering,
          csm: filterData?.setCSM,
          ...consentPayload
        },
        {
          headers: headers(),
        }
      )
      .then((res) => {
        const fileName = extractFileName(res.headers);
        if (res.status === 200) {
          if (type) {
            dispatch({
              type: csvType,
              payload: url.clientLeadEngsReport,
              email: filterData.email,
              csv: res.data && res.data,
              downloadFileName: fileName
            });
          } else {
            dispatch({
              type: dispatchType,
              payload: res.data.report,
              filterData: filterData,
              totalLeadCount: res.data.count,
              resLeadDetailsValue: res.data.report === null && true
            });
          }
        } else if (res.status === 202) {
          if (type) {
            dispatch({
              type: csvType,
              payload: url.clientLeadEngsReport,
              email: filterData.email,
              downloadFileName: fileName
            });
          }
        } else if (res.status === 425) {
          // dispatch({
          // 	type: actionTypes.PAGEVIEWREPORTLOADING,
          // 	payload: uri,
          // 	filterData,
          // });
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: actionTypes.ERRORCODE,
            payload: err.response.status,
          });
        }
      });
  };

export const fetchLeadValidationChart =
  (filterData, page, type = "", dispatchType, isLeadClient = false) =>
  async (dispatch) => {
    let siteValue = filterData.site;
    if (filterData.site === "all") {
      siteValue = "";
    }
    let assetType = filterData.assetType;
    if (filterData.assetType === "all") {
      assetType = "";
    }
    let status = filterData.campaignStatus;
    let IncludeDeleted = false;
    if (filterData.campaignStatus === "all") {
      status = "";
      IncludeDeleted = true;
    } else if (filterData.campaignStatus === "archived") {
      status = "archived";
    }
    let report = "engagement";
    if (isLeadClient) {
      report = "lead";
    }
    if(filterData.trafficType === "all"){
       filterData.trafficType = ""
    }
    const consentPayload = PrivacyConsentDataHandler(filterData);
    await axios
      .post(
        url.leadValidationReport,
        {
          organizations: filterData.organization,
          campaigns: filterData.campaign,
          orders: filterData.order,
          engaged_organizations: filterData.engagedOrganizations,
          lead_partner: filterData.leadPartner,
          site: siteValue,
          status: status,
          asset_type: assetType,
          start_date: getApiDate(filterData.startDate).concat(" 00:00:00"),
          end_date: getApiDate(filterData.endDate).concat(" 23:59:59"),
          override_lead_report_filters: filterData.overrideLeadReportFilters,
          exclude_internal_events: filterData.excludeInternalEvents,
          exclude_organization_events: filterData.excludeOrganizationEvents,
          exclude_competitors: filterData.excludeCompetitorEvents,
          exclude_free_email_registrations: filterData.excludeFreeEmailEvents,
          // SDSFT-1105 fix
          // override_campaign_report_setup:
          //   filterData.overrideReportingAttributes,
          order: "ASC",
          order_by: "email",
          include_deleted: IncludeDeleted,
          limit: 50,
          page: page,
          export: type,
          Is_lead_client: isLeadClient,
          applied_date_filter: filterData.appliedDate,
          exclude_qa: filterData.excludeQACampaign,
          exclude_internal: filterData.excludeInternalCampaign,
          override_account_filters: filterData.account,
          override_vertical_filters: filterData.vertical,
          override_geo_filters: filterData.geo,
          override_custom_questions: filterData.customQuestions,
          override_firmographic_filters: filterData.firmoGraphic,
          override_organization_types_filters: filterData.types,
          override_organization_sizes_filters: filterData.size,
          override_estimate_revenues_filters: filterData.revenue,
          override_personas_filters: filterData.personas,
          override_seniority_filters: filterData.seniority,
          override_job_functions_filters: filterData.jobFunctions,
          override_job_titles_filters: filterData.jobTitles,
          exclude_email_suppression: filterData.excludeEmailSupression,
          page_title: filterData.title,
          utm_source: filterData.selUrlSource,
          utm_medium: filterData.selUrlMedium,
          utm_campaign: filterData.selUrlCampaign,
          utm_term: filterData.selUrlTerm,
          utm_content: filterData.selUrlContent,
          report: report,
          traffic_type: filterData?.trafficType,
          domain_filter: filterData?.domainFilter,
          session_id: getCookie("session_id"),
          exclude_competitor_orgs: filterData?.competitorFiltering,
          csm: filterData?.setCSM,
          ...consentPayload
          //"organization limits":filterData.organizationLimits
          //Need to bind  Organization Limits key when backend completes
        },
        {
          headers: headers(),
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: dispatchType,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: actionTypes.ERRORCODE,
            payload: err.response.status,
          });
        }
      });
  };

export const sortClientLeadEngs = (column) => {
  return {
    type: actionTypes.SORTCLIENTLEADENGS,
    column: column,
  };
};

export const showMoreClientLeadEngsLoading = () => {
  return {
    type: actionTypes.MORECLIENTLEADENGSLOADING,
  };
};

export const clearClientLeadSort = () => {
  return {
    type: actionTypes.CLEARCLIENTLEADSORT,
  };
};

export const clientLeadCsvLoading = () => {
  return {
    type: actionTypes.CLIENTLEADCSVLOADING,
  };
};

export const selClientLeadToggles = (dispatchType) => {
  return {
    type: dispatchType,
  };
};

export const resetClientToggles = (dispatchType) => {
  return {
    type: dispatchType,
  };
};

export const clearClientLeadSerchedValue = (dispatchType) => {
  return {
    type: dispatchType,
  };
};

export const selClientLeadFilterValue = (value, dispatchType) => {
  return {
    type: dispatchType,
    payload: value,
  };
};
export const selFilterStatus = (status) => (dispatch) => {
  dispatch({
    type: actionTypes.SELCLIENTLEADFILTERSTATUS,
    payload: status,
  });
};
export const selFilterState = (state) => (dispatch) => {
  dispatch({
    type: actionTypes.SELCLIENTLEADFILTERSTATE,
    payload: state,
  });
};
export const selGlobalLeadStatus = (globalStatus) => (dispatch) => {
  dispatch({
    type: actionTypes.SELGLOBALLEADSTATUS,
    payload: globalStatus,
  });
};
export const applyDateState = (applyDateState) => (dispatch) => {
  dispatch({
    type: actionTypes.APPLYDATESTATE,
    payload: applyDateState,
  });
};
export const selGlobalVerificationState = (globalState) => (dispatch) => {
  dispatch({
    type: actionTypes.SELGLOBALVERIFICATIONSTATE,
    payload: globalState,
  });
};
export const selLeadStatusRowWise =
  (leadStatusRowWise, value) => (dispatch) => {
    dispatch({
      type: actionTypes.SELLEADSTATUSROWWISE,
      payload: leadStatusRowWise,
      value: value,
    });
  };

export const selLeadCheckBoxState = (leadCheckboxState) => (dispatch) => {
  dispatch({
    type: actionTypes.SELLEADCHECKBOXSTATE,
    payload: leadCheckboxState,
  });
};

export const selGlobalVerificationStateRowWise =
  (verificationStateRowWise) => (dispatch) => {
    dispatch({
      type: actionTypes.SELVERIFICATIONSTATEROWWISE,
      payload: verificationStateRowWise,
    });
  };
export const selShowDropDown = (value, dispatchType) => {
  return {
    type: dispatchType,
    payload: value,
  };
};

export const selClientLeadState = (status) => (dispatch) => {
  dispatch({
    type: actionTypes.SELDELIVERYSTATE,
    payload: status,
  });
};

export const fetchLeadValidationBarChart =
  (filterData, page, type = "", dispatchType, isLeadClient = false) =>
  async (dispatch) => {
    let siteValue = filterData.site;
    if (filterData.site === "all") {
      siteValue = "";
    }
    let assetType = filterData.assetType;
    if (filterData.assetType === "all") {
      assetType = "";
    }
    let status = filterData.campaignStatus;
    let IncludeDeleted = false;
    if (filterData.campaignStatus === "all") {
      status = "";
      IncludeDeleted = true;
    } else if (filterData.campaignStatus === "archived") {
      status = "archived";
    }
    let report = "engagement";
    if (isLeadClient) {
      report = "lead";
    }
    if(filterData.trafficType === "all") {
      filterData.trafficType = ""
    }
    const consentPayload = PrivacyConsentDataHandler(filterData);
    await axios
      .post(
        url.leadValidationBarCharts,
        {
          organizations: filterData.organization,
          campaigns: filterData.campaign,
          orders: filterData.order,
          engaged_organizations: filterData.engagedOrganizations,
          lead_partner: filterData.leadPartner,
          site: siteValue,
          status: status,
          asset_type: assetType,
          start_date: getApiDate(filterData.startDate).concat(" 00:00:00"),
          end_date: getApiDate(filterData.endDate).concat(" 23:59:59"),
          override_lead_report_filters: filterData.overrideLeadReportFilters,
          exclude_internal_events: filterData.excludeInternalEvents,
          exclude_organization_events: filterData.excludeOrganizationEvents,
          exclude_competitors: filterData.excludeCompetitorEvents,
          exclude_gated_pageviews: filterData.excludeGatedPageviews,
          exclude_free_email_registrations: filterData.excludeFreeEmailEvents,
          // SDSFT-1105 fix
          // override_campaign_report_setup:
          //   filterData.overrideReportingAttributes,
          order: "ASC",
          order_by: "id",
          include_deleted: IncludeDeleted,
          limit: 50,
          page: page,
          export: type,
          Is_lead_client: isLeadClient,
          applied_date_filter: filterData.appliedDate,
          exclude_qa: filterData.excludeQACampaign,
          exclude_internal: filterData.excludeInternalCampaign,
          override_account_filters: filterData.account,
          override_vertical_filters: filterData.vertical,
          override_geo_filters: filterData.geo,
          override_custom_questions: filterData.customQuestions,
          override_firmographic_filters: filterData.firmoGraphic,
          override_organization_types_filters: filterData.types,
          override_organization_sizes_filters: filterData.size,
          override_estimate_revenues_filters: filterData.revenue,
          override_personas_filters: filterData.personas,
          override_seniority_filters: filterData.seniority,
          override_job_functions_filters: filterData.jobFunctions,
          override_job_titles_filters: filterData.jobTitles,
          exclude_email_suppression: filterData.excludeEmailSupression,
          page_title: filterData.title,
          utm_source: filterData.selUrlSource,
          utm_medium: filterData.selUrlMedium,
          utm_campaign: filterData.selUrlCampaign,
          utm_term: filterData.selUrlTerm,
          utm_content: filterData.selUrlContent,
          report: report,
          delivery_state: (filterData.clientDeliverdFilterState).trim(),
          traffic_type: filterData?.trafficType,
          domain_filter: filterData?.domainFilter,
          session_id: getCookie("session_id"),
          exclude_competitor_orgs: filterData?.competitorFiltering,
          csm: filterData?.setCSM,
          ...consentPayload,
          //"organization limits":filterData.organizationLimits
          //Need to bind Organization Limits key when backend completes
        },
        {
          headers: headers(),
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: dispatchType,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: actionTypes.ERRORCODE,
            payload: err.response.status,
          });
        }
      });
  };

export const fetchSyncValidate =
  (
    filterData,
    page,
    type = "",
    dispatchType,
    isLeadClient = false,
    checkedItemIds,
    checkboxCheckedCampaignUuid,
    optionValue,
    considerRowSelected,
    shownValue,
    updatedUniqueUserIds
  ) =>
  async (dispatch) => {
    let siteValue = filterData.site;
    if (filterData.site === "all") {
      siteValue = "";
    }
    let assetType = filterData.assetType;
    if (filterData.assetType === "all") {
      assetType = "";
    }

    let status = filterData.campaignStatus;
    let IncludeDeleted = false;
    if (filterData.campaignStatus === "all") {
      status = "";
      IncludeDeleted = true;
    } else if (filterData.campaignStatus === "archived") {
      status = "archived";
    }
    let report = "engagement";
    if (isLeadClient) {
      report = "lead";
    }
    let campaignUuid = filterData.campaign;
    if (checkedItemIds.length > 0) {
      campaignUuid = checkboxCheckedCampaignUuid;
    }
        let leadState = filterData?.clientLeadFilterVerificationState;
    if (
      filterData?.clientLeadFilterVerificationState === "All" ||
      filterData?.clientLeadFilterVerificationState === "all"
    ) {
      leadState = "";
    }
    let verificationStatus = filterData?.clientLeadFilterStatus;
    if (
      filterData?.clientLeadFilterStatus === "All" ||
      filterData?.clientLeadFilterStatus === "all"
    ) {
      verificationStatus = "";
    }
    const consentPayload = PrivacyConsentDataHandler(filterData);
    await axios
      .post(
        url.syncValidate,
        {
          organizations: filterData.organization,
          campaigns: optionValue === "all" ? filterData.campaign : campaignUuid,
          orders: filterData.order,
          engaged_organizations: filterData.engagedOrganizations,
          lead_partner: filterData.leadPartner,
          site: siteValue,
          status: status,
          asset_type: assetType,
          start_date: getApiDate(filterData.startDate).concat(" 00:00:00"),
          end_date: getApiDate(filterData.endDate).concat(" 23:59:59"),
          override_lead_report_filters: filterData.overrideLeadReportFilters,
          exclude_internal_events: filterData.excludeInternalEvents,
          exclude_organization_events: filterData.excludeOrganizationEvents,
          exclude_competitors: filterData.excludeCompetitorEvents,
          exclude_free_email_registrations: filterData.excludeFreeEmailEvents,
          // SDSFT-1105 fix
          // override_campaign_report_setup:
          //   filterData.overrideReportingAttributes,
          order: "ASC",
          order_by: "email",
          include_deleted: IncludeDeleted,
          limit: 50,
          page: page,
          export: type,
          Is_lead_client: isLeadClient,
          applied_date_filter: filterData.appliedDate,
          exclude_qa: filterData.excludeQACampaign,
          exclude_internal: filterData.excludeInternalCampaign,
          override_account_filters: filterData.account,
          override_vertical_filters: filterData.vertical,
          override_geo_filters: filterData.geo,
          override_custom_questions: filterData.customQuestions,
          override_firmographic_filters: filterData.firmoGraphic,
          override_organization_types_filters: filterData.types,
          override_organization_sizes_filters: filterData.size,
          override_estimate_revenues_filters: filterData.revenue,
          override_personas_filters: filterData.personas,
          override_seniority_filters: filterData.seniority,
          override_job_functions_filters: filterData.jobFunctions,
          override_job_titles_filters: filterData.jobTitles,
          exclude_email_suppression: filterData.excludeEmailSupression,
          page_title: filterData.title,
          utm_source: filterData.selUrlSource,
          utm_medium: filterData.selUrlMedium,
          utm_campaign: filterData.selUrlCampaign,
          utm_term: filterData.selUrlTerm,
          utm_content: filterData.selUrlContent,
          report: report,
          item_ids: optionValue === "all" ? [] : checkedItemIds && checkedItemIds,
          consider_all_members: optionValue === "all" ? true : false,
          verification_state: leadState,
          verification_status: verificationStatus,
          consider_selected: considerRowSelected,
          consider_shown: shownValue,
          selected_user_uuids: updatedUniqueUserIds ? _.filter(updatedUniqueUserIds, u => u !== "") : [],
          domain_filter: filterData?.domainFilter,
          session_id: getCookie("session_id"),
          exclude_competitor_orgs: filterData?.competitorFiltering,
          csm: filterData?.setCSM,
          ...consentPayload
        },
        {
          headers: headers(),
        }
      )
      .then((res) => {
        if (res.status === 202) {
          dispatch({
            type: dispatchType,
            payload: true,
            messageBoxRefresh: false
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: actionTypes.ERRORCODE,
            payload: err.response.status,
          });
        }
      });
  };

export const fetchClientLeadData =
  (
    uri,
    filterData,
    page,
    type = "",
    dispatchType,
    csvType = "",
    newEmail,
    isLeadClient = false,
    mailDelivery = false
  ) =>
  async (dispatch) => {
    let leadState = filterData.clientLeadFilterVerificationState;
    if (
      filterData.clientLeadFilterVerificationState === "All" ||
      filterData.clientLeadFilterVerificationState === "all"
    ) {
      leadState = "";
    }
    let verificationStatus = filterData.clientLeadFilterStatus;
    if (
      filterData.clientLeadFilterStatus === "All" ||
      filterData.clientLeadFilterStatus === "all"
    ) {
      verificationStatus = "";
    }
    let siteValue = filterData.site;
    if (filterData.site === "all") {
      siteValue = "";
    }
    let assetType = filterData.assetType;
    if (filterData.assetType === "all") {
      assetType = "";
    }
    let status = filterData.campaignStatus;
    let IncludeDeleted = false;
    if (filterData.campaignStatus === "all") {
      status = "";
      IncludeDeleted = true;
    } else if (filterData.campaignStatus === "archived") {
      status = "archived";
    }
    let report = "lead";
    if (newEmail) {
      filterData.email = newEmail;
    }
    const consentPayload = PrivacyConsentDataHandler(filterData);
    await axios
      .post(
        uri,
        {
          organizations: filterData.organization,
          campaigns: filterData.campaign,
          orders: filterData.order,
          engaged_organizations: filterData.engagedOrganizations,
          lead_partner: filterData.leadPartner,
          site: siteValue,
          status: status,
          asset_type: assetType,
          start_date: getApiDate(filterData.startDate).concat(" 00:00:00"),
          end_date: getApiDate(filterData.endDate).concat(" 23:59:59"),
          override_lead_report_filters: filterData.overrideLeadReportFilters,
          exclude_internal_events: filterData.excludeInternalEvents,
          exclude_organization_events: filterData.excludeOrganizationEvents,
          exclude_competitors: filterData.excludeCompetitorEvents,
          exclude_free_email_registrations: filterData.excludeFreeEmailEvents,
          // SDSFT-1105 fix
          // override_campaign_report_setup:
          //   filterData.overrideReportingAttributes,
          order: "ASC",
          order_by: "email",
          include_deleted: IncludeDeleted,
          limit: 50,
          page: page,
          export: type,
          Is_lead_client: isLeadClient,
          applied_date_filter: filterData.appliedDate,
          exclude_qa: filterData.excludeQACampaign,
          exclude_internal: filterData.excludeInternalCampaign,
          override_account_filters: filterData.account,
          override_vertical_filters: filterData.vertical,
          override_geo_filters: filterData.geo,
          override_custom_questions: filterData.customQuestions,
          override_firmographic_filters: filterData.firmoGraphic,
          override_organization_types_filters: filterData.types,
          override_organization_sizes_filters: filterData.size,
          override_estimate_revenues_filters: filterData.revenue,
          override_personas_filters: filterData.personas,
          override_seniority_filters: filterData.seniority,
          override_job_functions_filters: filterData.jobFunctions,
          override_job_titles_filters: filterData.jobTitles,
          exclude_email_suppression: filterData.excludeEmailSupression,
          page_title: filterData.title,
          utm_source: filterData.selUrlSource,
          utm_medium: filterData.selUrlMedium,
          utm_campaign: filterData.selUrlCampaign,
          utm_term: filterData.selUrlTerm,
          utm_content: filterData.selUrlContent,
          report: report,
          verification_state: leadState,
          verification_status: verificationStatus,
          email: filterData.email,
          send_mail_delivery: mailDelivery,
          domain_filter: filterData?.domainFilter,
          session_id: getCookie("session_id"),
          exclude_competitor_orgs: filterData?.competitorFiltering,
          csm: filterData?.setCSM,
          ...consentPayload,
          //"organization limits":filterData.organizationLimits
          //Need to bind  Organization Limits key when backend completes
        },
        {
          headers: headers(),
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (type) {
            dispatch({
              type: csvType,
              payload: uri,
              email: filterData.email,
              csv: res.data,
            });
          } else {
            dispatch({
              type: dispatchType,
              payload: res.data,
              filterData: filterData,
            });
          }
        } else if (res.status === 425) {
          // dispatch({
          // 	type: actionTypes.PAGEVIEWREPORTLOADING,
          // 	payload: uri,
          // 	filterData,
          // });
        // } else if (url.leadValidationReport === "reports/leadengagement/validate" && res.status === 202) {
        // }else if (url.leadValidationUpdate === "reports/leadengagement/update" && res.status === 200) {

         }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: actionTypes.ERRORCODE,
            payload: err.response.status,
          });
        }
      });
  };

  export const sendTableHeaders = (
    headerType,
    headerNameArr,
    dispatchType,
    reportType,
  ) =>
  async (dispatch) => {
    await axios
      .post(
        url.saveHeaderNames,
        {
          "table_name": headerType,
          "headers": headerNameArr
        }, { headers: headers() }
      )
      .then((res) => {
        dispatch({
          type: dispatchType,
          payload: res.data,
          reportType
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: actionTypes.ERRORCODE,
            payload: err.response.status,
          });
        }
      });
  };

  export const getTableHeaders = ( tableName, dispatchType, reportType ) =>
  async (dispatch) => {
    await axios
      .post(
        url.getHeaderNames,
        {
          "table_name": tableName
        },
        { headers: headers() }
      )
      .then((res) => {
        dispatch({
          type: dispatchType,
          payload: res.data,
          reportType
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: actionTypes.ERRORCODE,
            payload: err.response.status,
          });
        }
      });
  };

export const selPacingFilterValue = (state) => (dispatch) => {
  dispatch({
    type: actionTypes.SELCLIENTLEADPACINGFILTERVALUE,
    payload: state,
  });
};

export const clientLeadDetailCsvLoading = () => {
  return {
    type: actionTypes.CLIENTLEADDETAILCSVLOADING,
  };
};

export const fetchClientLeadEngsSummaryDownloadCsvXlsx =
  (
    filterData,
    page,
    type = "",
    dispatchType,
    csvType = "",
    newEmail,
    isLeadClient = false
  ) =>
  async (dispatch) => {
    let leadState = filterData.clientLeadFilterVerificationState;
    if (
      filterData.clientLeadFilterVerificationState === "All" ||
      filterData.clientLeadFilterVerificationState === "all"
    ) {
      leadState = "";
    }
    let verificationStatus = filterData.clientLeadFilterStatus;
    if (
      filterData.clientLeadFilterStatus === "All" ||
      filterData.clientLeadFilterStatus === "all"
    ) {
      verificationStatus = "";
    }
    let siteValue = filterData.site;
    if (filterData.site === "all") {
      siteValue = "";
    }
    let assetType = filterData.assetType;
    if (filterData.assetType === "all") {
      assetType = "";
    }
    let status = filterData.campaignStatus;
    let IncludeDeleted = false;
    if (filterData.campaignStatus === "all") {
      status = "";
      IncludeDeleted = true;
    } else if (filterData.campaignStatus === "archived") {
      status = "archived";
    }
    let report = "lead";
    if (newEmail) {
      filterData.email = newEmail;
    }
    const mainUrl = isLeadClient ? url.leadEngagementSummaryReport : url.engagementSummary;
    const reportData = findReportFromURL(mainUrl, "");
    const consentPayload = PrivacyConsentDataHandler(filterData);
    await axios
      .post(
        mainUrl,
        {
          organizations: filterData.organization,
          campaigns: filterData.campaign,
          orders: filterData.order,
          engaged_organizations: filterData.engagedOrganizations,
          lead_partner: filterData.leadPartner,
          site: siteValue,
          status: status,
          asset_type: assetType,
          start_date: getApiDate(filterData.startDate).concat(" 00:00:00"),
          end_date: getApiDate(filterData.endDate).concat(" 23:59:59"),
          override_lead_report_filters: filterData.overrideLeadReportFilters,
          exclude_internal_events: filterData.excludeInternalEvents,
          exclude_organization_events: filterData.excludeOrganizationEvents,
          exclude_competitors: filterData.excludeCompetitorEvents,
          exclude_free_email_registrations: filterData.excludeFreeEmailEvents,
          order: "ASC",
          order_by: "email",
          include_deleted: IncludeDeleted,
          limit: 50,
          page: page,
          export: type,
          Is_lead_client: isLeadClient,
          applied_date_filter: filterData.appliedDate,
          exclude_qa: filterData.excludeQACampaign,
          exclude_internal: filterData.excludeInternalCampaign,
          override_account_filters: filterData.account,
          override_vertical_filters: filterData.vertical,
          override_geo_filters: filterData.geo,
          override_custom_questions: filterData.customQuestions,
          override_firmographic_filters: filterData.firmoGraphic,
          override_organization_types_filters: filterData.types,
          override_organization_sizes_filters: filterData.size,
          override_estimate_revenues_filters: filterData.revenue,
          override_personas_filters: filterData.personas,
          override_seniority_filters: filterData.seniority,
          override_job_functions_filters: filterData.jobFunctions,
          override_job_titles_filters: filterData.jobTitles,
          exclude_email_suppression: filterData.excludeEmailSupression,
          page_title: filterData.title,
          utm_source: filterData.selUrlSource,
          utm_medium: filterData.selUrlMedium,
          utm_campaign: filterData.selUrlCampaign,
          utm_term: filterData.selUrlTerm,
          utm_content: filterData.selUrlContent,
          report: report,
          verification_state: leadState,
          verification_status: verificationStatus,
          email: filterData.email,
          delivery_state: (filterData.clientDeliverdFilterState).trim(),
          utm_filters: filterData.utmParams,
          report_name: reportData ? reportData.report : "",
					report_table_name: reportData ? reportData.report_table_name : "",
          domain_filter: filterData?.domainFilter,
          session_id: getCookie("session_id"),
          exclude_competitor_orgs: filterData?.competitorFiltering,
          csm: filterData?.setCSM,
          ...consentPayload
        },
        {
          headers: headers(),
        }
      )
      .then((res) => {
        const fileName = extractFileName(res.headers)
        if (res.status === 200) {
          if (type) {
            dispatch({
              type: csvType,
              payload: mainUrl,
              email: filterData.email,
              csv: res.data && res.data,
              downloadFileName: fileName
            });
          } else {
            dispatch({
              type: dispatchType,
              payload: res.data.report,
              filterData: filterData,
              totalLeadCount: res.data.count
            });
          }
        } else if (res.status === 202) {
          if (type) {
            dispatch({
              type: csvType,
              payload: mainUrl,
              email: filterData.email,
              downloadFileName: fileName
            });
          }
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: actionTypes.ERRORCODE,
            payload: err.response.status,
          });
        }
      });
  };


  export const fetchRegulations = (queryString, dispatchType) => async (dispatch) => {
    try {
      const response = await axios.post(
        url.regulationSearch, 
        {
          query: queryString,
          limit: 20,
        }, 
        { headers: headers() }
      );

      dispatch({ type: dispatchType, payload: response.data });
    } catch (err) {
      if (err.response) {
        dispatch({ type: actionTypes.ERRORCODE, payload: err.response.status, });
      }
    }
  };