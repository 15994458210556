import React from 'react';
import { Table } from 'semantic-ui-react';

const CurrentPreviousHeader = (props) => (
  <Table>
    <Table.Row>
      <Table.HeaderCell style={{ borderBottom: "none" }} 
        textAlign="center"
        sorted={props?.props?.column === props?.currentVal ? props?.props?.direction : null}
        onClick={ () => props?.props?.sortEditorialPrimaryTopic(props?.currentVal) }
        >
        Current
      </Table.HeaderCell>
      <Table.HeaderCell style={{ borderBottom: "none" }} 
        sorted={props?.props?.column === props?.previousVal ? props?.props?.direction : null}
        onClick={ () => props?.props?.sortEditorialPrimaryTopic(props?.previousVal) }
        textAlign="center"
      >
        Previous
      </Table.HeaderCell>
    </Table.Row>
  </Table>
);

export default CurrentPreviousHeader;