import React from "react";
import CampaignReport from "./campaignReport";
import { getCapital, getComma, getDateTime, numFormat } from "../../utilities/common";
import Link from "../hyperlinks/link";

const ClientLeadDetailCampaign = (props) => {
	let clientLeadDetailCampaign = props.data;
	let OrgVert = {};
	OrgVert.sector = clientLeadDetailCampaign.sector && clientLeadDetailCampaign.sector + ", ";
	OrgVert.industry_group = clientLeadDetailCampaign.industry_group && clientLeadDetailCampaign.industry_group + ", ";
	OrgVert.industry = clientLeadDetailCampaign.industry && clientLeadDetailCampaign.industry + ", ";
	OrgVert.sub_industry = clientLeadDetailCampaign.sub_industry;
	return (
		<div>
			<CampaignReport
				object={clientLeadDetailCampaign.lead_partner?.String}
				label={"LEAD SOURCE"}
			/>
			<h5 className="drawer-heading-text">MEMBER DETAILS</h5>
			<CampaignReport
				object={clientLeadDetailCampaign.seniority}
				label={"SENIORITY LEVEL"}
			/>
			<CampaignReport
				object={clientLeadDetailCampaign.primary_role}
				label={"JOB FUNCTION"}
			/>
			<CampaignReport
				object={clientLeadDetailCampaign.member_title}
				label={"JOB TITLE"}
			/>
			<CampaignReport object={clientLeadDetailCampaign.email} label={"EMAIL"} />
			<CampaignReport
				object={clientLeadDetailCampaign.phone_number}
				label={"PHONE NUMBER"}
			/>
			<CampaignReport
				object={getComma(clientLeadDetailCampaign.member_address)}
				label={"ADDRESS"}
			/>
			<CampaignReport
				object={clientLeadDetailCampaign.member_country}
				label={"COUNTRY"}
			/>
			<CampaignReport
				object={clientLeadDetailCampaign.member_timezone}
				label={getCapital("timezone")}
			/>
			<h5 className="drawer-heading-text">ACCOUNT DETAILS</h5>
			<CampaignReport
				object={
					clientLeadDetailCampaign.organization_uuid ? (
						<Link
							type="organizations"
							urlParam={clientLeadDetailCampaign.organization_uuid}
							refValue={clientLeadDetailCampaign.organization_name}
						></Link>
					) : (
						clientLeadDetailCampaign.organization_name
					)
				}
				label={"ORGANIZATION NAME"}
			/>
			<CampaignReport
				object={Object.values(OrgVert)}
				label={"ORGANIZATION VERTICAL"}
			/>

			<CampaignReport
				object={numFormat(clientLeadDetailCampaign.employees)}
				label={"ORGANIZATION SIZE"}
			/>
			<CampaignReport
				object={clientLeadDetailCampaign.estimated_revenue}
				label={"ORGANIZATION REVENUE"}
			/>
			<CampaignReport
				object={clientLeadDetailCampaign.type}
				label={"ORGANIZATION TYPE"}
			/>
			<h5 className="drawer-heading-text">PRIVACY DETAILS</h5>
			<CampaignReport
				object={
					clientLeadDetailCampaign.purpose_consent === true ? "Yes" : "No"
				}
				label={"PURPOSE CONSENT"}
			/>
			<CampaignReport
				object={
					clientLeadDetailCampaign.marketing_consent === true ? "Yes" : "No"
				}
				label={"MARKETING CONSENT"}
			/>
			<CampaignReport
				object={
					clientLeadDetailCampaign.deletion_policy === "dnsmpi"
						? "Yes"
						: clientLeadDetailCampaign.deletion_policy
				}
				label={"DO NOT SELL MY PERSONAL INFO"}
			/>
			<CampaignReport
				object={
					clientLeadDetailCampaign.deletion_policy === "rtbf"
						? "Yes"
						: clientLeadDetailCampaign.deletion_policy
				}
				label={"RIGHT TO BE FORGOTTEN"}
			/>
			<h5 className="drawer-heading-text">VIDEO ENGAGEMENT DETAILS</h5>
			<CampaignReport
				object={clientLeadDetailCampaign.attended}
				label={"ATTENDED WEBINAR"}
			/>
			<CampaignReport
				object={getDateTime(clientLeadDetailCampaign.webinar_join_time)}
				label={"WEBINAR JOIN TIME"}
			/>
			<CampaignReport
				object={getDateTime(clientLeadDetailCampaign.webinar_leave_time)}
				label={"WEBINAR LEAVE TIME"}
			/>
			<CampaignReport
				object={clientLeadDetailCampaign.webinar_time_in_min}
				label={"WEBINAR DURATION (MINIUTES IN SESSION)"}
			/>
			<h5 className="drawer-heading-text">UTM DETAILS</h5>
			<CampaignReport
				object={clientLeadDetailCampaign.source?.String}
				label={"UTM SOURCE"}
			/>
			<CampaignReport
				object={clientLeadDetailCampaign.utm_medium?.String}
				label={"UTM MEDIUM"}
			/>
			<CampaignReport
				object={clientLeadDetailCampaign.utm_campaign?.String}
				label={"UTM CAMPAIGN"}
			/>
			<CampaignReport
				object={clientLeadDetailCampaign.utm_term?.String}
				label={"UTM TERM"}
			/>
			<CampaignReport
				object={clientLeadDetailCampaign.utm_content?.String}
				label={"UTM CONTENT"}
			/>
			<CampaignReport
				object={clientLeadDetailCampaign.ip_address}
				label={"IP ADDRESS"}
			/>
			<CampaignReport
				object={clientLeadDetailCampaign.user_agent}
				label={"USER AGENT"}
			/>
		</div>
	);
};
export default ClientLeadDetailCampaign;
