import React from "react";
import { Form, Dropdown } from "semantic-ui-react";

const MultipleSelectDropDown = (props) => {
	return (
		<Form.Field
			fluid
			floating
			selection
			multiple
			closeOnChange
			search
			control={Dropdown}
			{...props}
			renderLabel={(label) => ({
				content: label.text,
				className: label?.className || "",
			})}
		></Form.Field>
	);
};

export default MultipleSelectDropDown;
