import React from "react";
import * as html2canvas from "html2canvas";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const DownloadChart = (props) => {

    const getDownloadImage = async (chartName) =>{
        const element = props.chartNameDiv,
        canvas = await html2canvas(element),
        data = canvas.toDataURL('image/jpg') ,
        link = document.createElement('a');
      
        link.href = data;
        link.download =  chartName;
      
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

  return (
    <>
    <div id= "arrow-icon">
      <ArrowDownwardIcon
        style = {props.style}
        className="download-chart-icon" 
        data-html2canvas-ignore="true"
        onClick={() => {
          getDownloadImage(props.chartName);
        }}
      />
    </div>
    </>
  );
};

export default DownloadChart;
