import axios from "../api/axios";
import { url } from "../api/urlHelper";
import * as actionTypes from "./actionTypes";
import { headers } from "../api/apiConstants";
import { extractFileName } from "../utilities/common";
import { LIMIT_20, LIMIT_50 } from "../components/config/TableHeaderNames";
import { ApiPayloadData, NewslettersExtraDataHandler } from "../utilities/ApiPayloadHandler";

// Helper function to handle dispatching actions
const handleResponse = (dispatch, res, type, dispatchType, setReportType, email="", url="") => {
    if (type) {
        const fileName = extractFileName(res.headers);
        dispatch({
            type: dispatchType,
            payload: url,
            downloadFileName: fileName,
            reportType: setReportType,
            email,
        });
    } else {
        dispatch({
            type: dispatchType,
            payload: res.data,
            reportType: setReportType,
        });
    }
};

// Helper function to handle errors
const handleError = (dispatch, err) => {
    if (err.response) {
        dispatch({
            type: actionTypes.ERRORCODE,
            payload: err.response.status,
        });
    }
};

// Fetch newsletters report data
export const fetchNewslettersReportData = (filterData, page, type = "", dispatchType, reportType, setReportType = "") => async (dispatch) => {
    const data = {
        ...ApiPayloadData(filterData, page, type, reportType, LIMIT_20, url.newslettersSummary, false),
        ...NewslettersExtraDataHandler(filterData),
    };

    try {
        const res = await axios.post(url.newslettersSummary, data, { headers: headers() });

        if (type) {
            const fileName = extractFileName(res.headers);
            dispatch({
                type: dispatchType,
                payload: res.data,
                downloadFileName: fileName,
                reportType: setReportType,
            });
        } else {
            dispatch({
                type: dispatchType,
                payload: res.data,
                reportType: setReportType,
            });
        }

    } catch (err) {
        handleError(dispatch, err);
    }
};

export const fetchNewslettersDetailsData = (filterData, page, type = "", dispatchType, reportType, setReportType = "", email="") => async (dispatch) => {
    const setLimit = filterData?.selectNewsletterAction === "all" ? LIMIT_20 : LIMIT_50;
    const data = {
        ...ApiPayloadData(filterData, page, type, reportType, setLimit, url.newslettersDetails, false),
        ...NewslettersExtraDataHandler(filterData),
    };
    try {
        const res = await axios.post(url.newslettersDetails, data, { headers: headers() });
        handleResponse(dispatch, res, type, dispatchType, setReportType, email, url.newslettersDetails);
    } catch (err) {
        handleError(dispatch, err);
    }
};

// Fetch newsletter authors
export const fetchNewsletterAuthors = (queryString, dispatchType, reportType = "") => async (dispatch) => {
    try {
        const res = await axios.post(
            url.newsletterAuthors,
            { q: queryString, limit: 20, page: 1 },
            { headers: headers() }
        );
        dispatch({
            type: dispatchType,
            payload: res.data,
            reportType,
        });
    } catch (err) {
        handleError(dispatch, err);
    }
};