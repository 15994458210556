import React from "react";
import CreateTableRowComponent from "./CreateTableRowComponent";
import { Table } from "semantic-ui-react";

const PageviewSection = ({ data }) => {
   const userInfo = data.search_query;

   const createTableRowsWithoutLink = (data, label) => {
      if (data && data.length > 0) {
         let tableRows = [];
         
         tableRows.push(<Table.Row key={0}><Table.Cell rowSpan={data.length+1}>{label}</Table.Cell></Table.Row>);
         data.forEach((val, index) => {
            tableRows.push(<Table.Row key={index}><Table.Cell key={index + "_name"}>{val.name}</Table.Cell></Table.Row>);
         });
     
         return tableRows;
      }
   };
   
   const renderTableBody = (dataProperty, label) => {
      const rowData = data[dataProperty];
      return createTableRowsWithoutLink(rowData, label);
   };

   return (
      <React.Fragment>
         {<CreateTableRowComponent name="PAGE TITLE" value={userInfo.page_title}/>}
         {renderTableBody("primary_topic", "PRIMARY TOPIC")}
         {renderTableBody("topic_tags.included_tags", "TOPIC TAG INCLUDE")}
         {renderTableBody("topic_tags.excluded_tags", "TOPIC TAG EXCLUDE")}
         {renderTableBody("placement_tags.included_tags", "PLACEMENT TAG INCLUDE")}
         {renderTableBody("placement_tags.excluded_tags", "PLACEMENT TAG EXCLUDE")}
         {renderTableBody("organization_tags.included_tags", "ORGANIZATION TAG INCLUDE")}
         {renderTableBody("organization_tags.excluded_tags", "ORGANIZATION TAG EXCLUDE")}
         {<CreateTableRowComponent name="AUTHOR" value={userInfo.author}/>}
      </React.Fragment>
   );
};

export default PageviewSection;