import { useEffect } from 'react';
import { getFirstElementLeftBound } from './common';

export function useStickyColumnsEffect(freezeColPos) {
    useEffect(() => {
        if (freezeColPos >= 0) {
            var cellElements = []
            const stickyColStickey = document.querySelectorAll('.sticky-cols-sticky');   
            const stickyCols = document.querySelectorAll('.sticky-cols');
            const stickyColsHeader = document.querySelectorAll('.sticky-cols-header');

            // Get the left bound of the first element in each NodeList
            const leftBoundSticky = getFirstElementLeftBound(stickyColStickey);
            const leftBoundCols = getFirstElementLeftBound(stickyCols);
            const leftBoundHeader = getFirstElementLeftBound(stickyColsHeader);

            // Find the smallest left bound among the three values
            const nonZeroValues = [leftBoundSticky, leftBoundCols, leftBoundHeader].filter(value => value !== 0);
            const smallestLeftBound = Math.min(...nonZeroValues);
                
            cellElements.push(...stickyColStickey, ...stickyCols, ...stickyColsHeader)
                
            cellElements.forEach(cellElement => {
                var bounds = cellElement.getBoundingClientRect()
                const leftValue = bounds.left - smallestLeftBound;
                cellElement.style.left = `${leftValue}px`;
            });
        }
    }, [freezeColPos]);
}

 // Function to reset the left CSS property to its default value
export function resetLeftStyles() {
    const cellElements = document.querySelectorAll('.sticky-cols-sticky, .sticky-cols, .sticky-cols-header');
    cellElements.forEach(cellElement => {
        cellElement.style.left = ''; // Reset left CSS property
        cellElement.style.backgroundColor = '';
    });
}

export default useStickyColumnsEffect;
