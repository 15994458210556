import {
  PAGE,
  PRIMARY_TOPIC,
  TOPIC_TAGS,
  PLACEMENT_TAGS,
  PAGEVIEWS,
  UNIQUE_PAGEVIEWS,
  AUTHOR,
  ORGANIZATION_TAGS,
  PUBLISH_DATE,
  DAY_SINCE_PUBLISHED,
  DAY_SINCE_UPDATED,
  DAY_OF_WEEK,
} from "../../config/TableHeaderNames";

const PageSummaryTableData = [
  { headerName: PAGE, sorted: "page_title", className: "custom-column-xlarge", name: "Page",},
  { headerName: PUBLISH_DATE, sorted: "published_date", className: "custom-column-author", name: "Publish Date" },
  { headerName: DAY_OF_WEEK, sorted: "published_weekday", name: "Day of Week" },
  { headerName: DAY_SINCE_PUBLISHED, sorted: "days_since_published", className: "custom-column-xlarge", name: "Day Since Published" },
  { headerName: DAY_SINCE_UPDATED, sorted: "days_since_updated", className: "custom-column-xlarge", name: "Day Since Updated" },
  { headerName: AUTHOR, sorted: "author", className: "custom-column-author", name: "Author" },
  {
    headerName: PAGEVIEWS,
    sorted: "page_views_count",
    name: "Pageviews",
    textAlign: "right",
    className: "custom-column-author",
    currentKey: "page_views_count",
    previousKey: "previous_page_views",
  },
  {
    headerName: UNIQUE_PAGEVIEWS,
    sorted: "unique_impressions_count",
    // className: "custom-column-large",
    name: "Unique Pageviews",
    textAlign: "right",
    className: "custom-column-author",
    currentKey: "unique_impressions_count",
    previousKey: "prev_unique_impressions_count",
  },
  {
    headerName: PRIMARY_TOPIC,
    sorted: "primary_topic",
    className: "custom-column-large",
    name: "Primary Topic",
  },
  {
    headerName: TOPIC_TAGS,
    sorted: "topic_tags",
    className: "custom-column-large",
    name: "Topic Tags",
  },
  {
    headerName: ORGANIZATION_TAGS,
    sorted: "organization_tags",
    className: "custom-column-large",
    name: "Organization Tags",
  },
  {
    headerName: PLACEMENT_TAGS,
    sorted: "placement_tags",
    className: "custom-column-large",
    name: "Placement Tags",
  },
];
export default PageSummaryTableData;
