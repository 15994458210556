import React from "react";
import CreateTableRowComponent from "./CreateTableRowComponent";

const OverrideFilterToggle = ({ data }) => {
   const userInfo = data.search_query;
   const overrideData = [
      {
         name: "ACCOUNT",
         value: userInfo.override_account_filters
      },
      {
         name: "VERTICAL",
         value: userInfo.override_vertical_filters
      },
      {
         name: "GEO",
         value: userInfo.override_geo_filters
      },
      {
         name: "CUSTOM QUESTIONS",
         value: userInfo.override_custom_questions
      },
      {
         name: "EMAIL SUPPRESSION",
         value: userInfo.override_lead_report_filters
      },
      {
         name: "REVENUE",
         value: userInfo.override_estimate_revenues_filters
      },
      {
         name: "SIZE",
         value: userInfo.override_organization_sizes_filters
      },
      {
         name: "SENORITY",
         value: userInfo.override_seniority_filters
      },
      {
         name: "JOB FUNCTIONS",
         value: userInfo.override_job_functions_filters
      },
      {
         name: "JOB TITLES",
         value: userInfo.override_job_titles_filters
      }
   ]
   return (
      <React.Fragment>
         {
            overrideData.map((override, index) => {
               return <CreateTableRowComponent key={index} name={override.name} value={override.value  ? "Yes" : "No"} />
            })
         }
      </React.Fragment>
   );
};

export default OverrideFilterToggle;
