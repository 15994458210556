import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Loader,
  Message,
  Checkbox,
  Icon,
  Menu,
} from "semantic-ui-react";
import "../../../css/common.css";
import { connect } from "react-redux";
import {
  setPageCount,
  fetchPageviewTableData,
  sortEditorialPlacementTableData,
  showMorePageviewTableData,
  clearEditorialSort,
  showMoreEditorialTableData,
  fetchEditorialTableData,
  sendTableHeaders,
  getTableHeaders,
  clearEditorialSerchedValue,
  selEditorialFilterValue
} from "../../../actions";
import * as actionTypes from "../../../actions/actionTypes";
import { url } from "../../../api/urlHelper";
import { PlacementTableData } from "../commonHeaders";
import createTableRow from "../commonGenerateTableRows";
import { PAGE_SIZE } from "../../config/TableHeaderNames";
import ShowMoreButton from "../../common/showMoreComponent";
import PageviewIndependentTagsTableHeaderRow from "../PageviewIndependentTagsTableHeaderRow";

const EditorialPlacementSummary = (props) => {
  const [selectHeaders, setSelectHeaders] = useState();
  const [finalHeaderData, setFinalHeaderData] = useState();
  const [headerColumn, setHeaderColumn] = useState([]);
  const [isSortIcon, setSortIcon] = useState(false);
  const [displayRows, setDisplayRows] = useState(PAGE_SIZE);

  let tableBody;
  let showMoreButton;
  let loading;
  let message;
  let conditionPrevious =
    props.filterData.selCutomRangeChkBox === true &&
    props.isFilApplied &&
    props.filterData.appliedDate === "Custom Date Range";

  useEffect(() => {
    props.getTableHeaders(
      "independent_placement_summary",
      actionTypes.GETEDITORIALPLACEMENTSUMMARYDYNAMICTABLEHEADER
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const headerDataVal = props.getDynamicHeaderData;
    if (!_.isEmpty(headerDataVal)) {
      setSelectHeaders(headerDataVal);
      setFinalHeaderData(headerDataVal);
    } else if (headerDataVal === undefined || _.size(headerDataVal) === 0 ) {
      const constantHeadersArr = [...PlacementTableData];
      setSelectHeaders(constantHeadersArr);
      setFinalHeaderData(constantHeadersArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getDynamicHeaderData]);

  useEffect(() => {
    const headerName = [];
    finalHeaderData &&
      finalHeaderData.map((name) => {
        headerName.push(name.headerName);
        return "";
      });
    setHeaderColumn(headerName);
  }, [finalHeaderData]);

  useEffect(() => {
    setDisplayRows(PAGE_SIZE);
  }, [props.loading])

  const savedCallback = useRef();
  savedCallback.current = props.reCall;

  if (props.loading && props.filterData.selectPlaceSummaryTable) {
    loading = (
      <div className="show-loader">
        <Loader active inline="centered" />
      </div>
    );
  }

  useEffect(() => {
    if(!props.filterData.placementSummaryToggleValue  && props.filterData.selectPlaceSummaryTable && _.isEmpty(props.placementEditorialSummary)){
      if(!props.filterData.selectTopicSummaryTable && !props.filterData.selectOrganizationSummaryTable) {
        props.fetchEditorialTableData(
          url.topicOrganizationPlacementReport,
          props.filterData,
          1,
          "",
          actionTypes.COMMONINDEPENDENTPAGESUMMARY,
          null,
          null,
          "independent",
          ""
        );
        props.selEditorialFilterValue(true, actionTypes.PLACEMENTSUMMARYTOGGLEVALUE);
      }
    }
    if(!props.filterData.selectPlaceSummaryTable){
      props.selEditorialFilterValue(false, actionTypes.PLACEMENTSUMMARYTOGGLEVALUE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterData.selectPlaceSummaryTable, props.isFilApplied, props.placementEditorialSummary]);

  if (!_.isEmpty(props.placementEditorialSummary) && props.filterData.selectPlaceSummaryTable) {
    const isShowMoreDisabled = displayRows >= props.placementEditorialSummary.length;
    tableBody = (
      <Table.Body>{createTableRow(props.placementEditorialSummary, finalHeaderData, conditionPrevious, displayRows, headerColumn, "placement")}</Table.Body>
    );
    showMoreButton = (<ShowMoreButton onClick={()=> setDisplayRows(displayRows + PAGE_SIZE)} isDisabled={isShowMoreDisabled}/>);
  } else if (props.isFilApplied && !props.loading && _.isEmpty(props.placementEditorialSummary) && props.filterData.selectPlaceSummaryTable) {
    message = <Message>No results found.</Message>;
  }

  const clickOnSortIcon = () => {
    setSortIcon(!isSortIcon);
  };

  const clickOnSortHeader = (e, extraData, headerData) => {
    if (extraData.checked) {
      setSelectHeaders([...selectHeaders, headerData]);
    } else {
      const removeData = selectHeaders.filter(
        (hName) => hName.headerName !== headerData.headerName
      );
      setSelectHeaders(removeData);
    }
  };

  const applyButtonClick = () => {
    let finalHeaderArray = [];
    PlacementTableData.map((mainHeader) => {
      return _.map(selectHeaders, function (selectHeader) {
        if (mainHeader.headerName === selectHeader.headerName) {
          finalHeaderArray.push(mainHeader);
        }
      });
    });
    
    setFinalHeaderData(finalHeaderArray);
    props.sendTableHeaders(
      "independent_placement_summary",
      finalHeaderArray,
      actionTypes.SENDEDITORIALPLACEMENTSUMMARYDYANMICTABLEHEADER
    );
    setSortIcon(!isSortIcon);
  };

  return (
    <div style={{padding : "1px"}}>
      <div className="sorted_icon_for_ads">
            <Table.HeaderCell style={{ paddingBottom: "20px" }}>
              <Icon
                name="edit"
                style={{ color: `${isSortIcon ? "#f15a29" : "#b2b3b3"}` }}
                size="large"
                onClick={clickOnSortIcon}
              />
            </Table.HeaderCell>
            {isSortIcon && (
              <div
                className="menu-list-items1"
                style={{ height: "0px", float: "right" }}
              >
                <Menu vertical className="dynamic_table--list tag_summary">
                  {Object.values(PlacementTableData).map(
                    (tableHeader, index) => (
                      <div key={index}>
                        <Checkbox
                          className="table_header--checkbox"
                          style={{ color: "#838486" }}
                          label={tableHeader.name}
                          checked={
                            selectHeaders &&
                            selectHeaders.some(
                              (el) => el.headerName === tableHeader.headerName
                            )
                              ? true
                              : false
                          }
                          onClick={(e, data) =>
                            clickOnSortHeader(e, data, tableHeader)
                          }
                        />
                      </div>
                    )
                  )}
                  <button
                    className="apply_menu--btn"
                    onClick={applyButtonClick}
                  >
                    Apply
                  </button>
                </Menu>
              </div>
            )}
          </div>
      <div className="sortable-table-component" style={{ paddingTop: "30px" }}>
        <Table sortable fixed singleLine>
          {!_.isEmpty(finalHeaderData) && (
            <PageviewIndependentTagsTableHeaderRow
              propData={props}
              headerData={finalHeaderData}
              conditionPrevious={conditionPrevious}
              column={props.placementPageviewSummarycolumn}
              direction={props.placementPageviewSummarydirection}
              click={props.sortEditorialPlacementTableData}
              actionType={actionTypes.SORTPLACEMENTEDITORIALSUMMARY}
            />
          )}
          {tableBody}
        </Table>
      </div>
      {message}
      {loading}
      {showMoreButton}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    filterData: state.editorialFilterData,
    reCall: state.editorialFetchDetailData.filterData,
    isFilApplied: state.editorialFetchDetailData.isFilApplied,
    placementEditorialSummary:
      state.editorialFetchDetailData.placementEditorialSummary,
    loading: state.editorialFetchDetailData.isLoadingPlacementEditorialSummary,
    placementPageviewSummarycolumn: state.editorialFetchDetailData.placementPageviewSummarycolumn,
    placementPageviewSummarydirection: state.editorialFetchDetailData.placementPageviewSummarydirection,
    type: state.editorialFetchDetailData.placementPageviewSummarytype,
    PageCount:
      state.editorialFetchDetailData.editorialPlacementSummaryPageCount,
    getDynamicHeaderData:
      state.editorialFetchDetailData
        .getDynamicEditorialPlacementSummaryHeaderData,
    editorialFetchDetailData: state.editorialFetchDetailData
  };
};

export default connect(mapStateToProps, {
  setPageCount,
  fetchPageviewTableData,
  sortEditorialPlacementTableData,
  showMorePageviewTableData,
  clearEditorialSort,
  showMoreEditorialTableData,
  fetchEditorialTableData,
  sendTableHeaders,
  getTableHeaders,
  clearEditorialSerchedValue,
  selEditorialFilterValue
})(EditorialPlacementSummary);
