import React from 'react';
import { Divider } from 'semantic-ui-react';

const ShowMoreButton = ({ onClick, isDisabled }) => {
  return (
    <div className="table-divider">
      <Divider>
        <div className="show-more-component">
          <button className={isDisabled ? "show-more-button-disable" : "show-more-button"} onClick={onClick} disabled={isDisabled}>
            Show More
          </button>
        </div>
      </Divider>
    </div>
  );
};

export default ShowMoreButton;