import React from "react";
import MultipleSelectDropDown from "../base/multiSelectDropdown";
import { getMemberLink } from "../../utilities/common";
import { connect } from "react-redux";

const EngagedMember = (props) => {
  return (
    <MultipleSelectDropDown
      options={props.engMember.map((username, index) => ({
        key: index,
        text: username?.user_name,
        value: username?.user_uuid,
      }))}
      label={props.label}
      placeholder={props.placeholder}
      onSearchChange={props.onSearchChange}
      value={props.value}
      onChange={props.onChange}
      onClick={props.onClick}
      onLabelClick={(e, sel) => {
        if (props.type === "addMemberLink") {
          getMemberLink(props.engMember, sel.value, props.wpLink);
        }
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    wpLink: state.links.wpLink,
  };
};

export default connect(mapStateToProps, {})(EngagedMember);
