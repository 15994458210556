import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from "lodash";

import CampaignPicker from "../campaign";
import { fetchCampaigns, selectValue, addSelectedValue } from "../../../actions";
import * as actionTypes from "../../../actions/actionTypes";
import { CAMPAIGN } from "../../config/const";

const CampaignSelection = (props) => {

    const searchCampaign = (e) => {
        if (e.target.value.length > 1) {
            props.fetchCampaigns(
                "",
                props.filterData.site,
                props.filterData.selTagsIN,
                props.filterData.selTagsEX,
                props.filterData.excludeQACampaign,
                e.target.value,
                props.filterData.organization,
                props.filterData.campaignStatus,
                "start_date",
                actionTypes[props.reportActionType].FETCH_CAMPAIGNS,
                undefined,
                undefined,
                undefined,
                props.reportActionType,
            );
        }
    };

    const onSelectCampaigns = (e, { value }) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key: CAMPAIGN, value });
        props.addSelectedValue({ dispatchType: actionTypes[props.reportActionType].ADD_CAMPAIGNS, reportType: props.reportActionType, value });
    };

    const onClickCampaign = () => {
        if ((!props.singleSelectForOrg && _.isEmpty(props.filterData.organization)) || (props.singleSelectForOrg && props.filterData.organizationSingleSelect === "")) {
            props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].CLEAR_CAMPAIGNS, reportType: props.reportActionType });
        } else if (!_.isEmpty(props.filterData.organization) || (props.filterData.organizationSingleSelect !== "")) {
            props.fetchCampaigns(
                "",
                props.filterData.site,
                props.filterData.selTagsIN,
                props.filterData.selTagsEX,
                props.filterData.excludeQACampaign,
                "",
                props.singleSelectForOrg ? [props.filterData.organizationSingleSelect] : props.filterData.organization,
                props.filterData.campaignStatus,
                "start_date",
                actionTypes[props.reportActionType].FETCH_CAMPAIGNS,
                undefined,
                undefined,
                undefined,
                props.reportActionType,
            );
        }
    };

    return (
        <CampaignPicker
            onSearchChange={searchCampaign}
            value={props.filterData.campaign}
            onChange={onSelectCampaigns}
            campaigns={props.commonSelectData.campaigns}
            onClick={onClickCampaign}
        />
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        commonSelectData: state.allReportCommonReducer[ownProps.reportActionType],
    };
};

export default compose(connect(mapStateToProps, {
    fetchCampaigns,
    selectValue,
    addSelectedValue,
}))(CampaignSelection);