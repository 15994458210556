import React from "react";
import CommonUserInfo from "./FilterSections/CommonUserInfo";
import { Table } from "semantic-ui-react";
import CreateTableRowComponent from "./FilterSections/CreateTableRowComponent";
import LeadEngagementSection from "./FilterSections/LeadEngagementSection";
import EventFilterSection from "./FilterSections/EventFilterSection";
import PageviewSection from "./FilterSections/PageviewSection";
import { getDisplayDate } from "../../utilities/common";

const FilterDetails = (props) => {
   const userData = props.data.user_filter
   const userInfo = userData.search_query
   return (
      <div className="sortable-table-component">
         <Table sortable fixed singleLine>
            <Table.Header>
               <Table.Row>
                  <Table.HeaderCell style={{ width: '200px' }}>LABEL</Table.HeaderCell>
                  <Table.HeaderCell style={{ width: '500px' }}>VALUE</Table.HeaderCell>
               </Table.Row>
            </Table.Header>

            {/* Date section */}
            {<CreateTableRowComponent name="START DATE" value={getDisplayDate(userInfo.start_date)}/>}
            {<CreateTableRowComponent name="END DATE" value={getDisplayDate(userInfo.end_date)}/>}
            {
               userInfo.compare &&
               <React.Fragment>
                  <CreateTableRowComponent name="PREVIOUS START DATE" value={getDisplayDate(userInfo.previous_start_date)}/>
                  <CreateTableRowComponent name="PREVIOUS END DATE" value={getDisplayDate(userInfo.previous_end_date)}/>
               </React.Fragment>
            }

            {/* User section */}
            <CommonUserInfo data={userData}/>
            
            {/* common section */}
            {<CreateTableRowComponent name="EXCLUDE ANONYMOUS ORGANIZATIONS" value={userInfo.exclude_anonymous_organization ? "Yes" : "No"}/>}
            {<CreateTableRowComponent name="EXCLUDE ANONYMOUS MEMBERS" value={userInfo.exclude_anonymous_member ? "Yes" : "No"}/>}
            {<CreateTableRowComponent name="EXCLUDE QA/TEST CAMPAIGNS" value={userInfo.exclude_qa ? "Yes" : "No"}/>}
            {<CreateTableRowComponent name="EXCLUDE INTERNAL CAMPAIGNS" value={userInfo.exclude_internal ? "Yes" : "No"}/>}
            
            {/* Lead engagement section */}
            <LeadEngagementSection data={userData}/>
            <EventFilterSection data={userData}/>
            <PageviewSection data={userData}/>
         </Table>
      </div>
   );
};

export default FilterDetails;