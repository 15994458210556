import React, { useState } from "react";
import { Table } from "semantic-ui-react";
import _ from "lodash";
import ExpandCollapse from "../../common/ExpandCollapse";
import { AD_ORDER, AD_TYPE, CAMPAIGN, IP_ADDRESS, NEWSLETTER_NAME, ORDER, PAGE_TITLE, SESSION_ID, TARGET_NAME, UTM_CAMPAIGN, UTM_CONTENT, UTM_MEDIUM, UTM_SOURCE, UTM_TERM } from "../../config/TableHeaderNames";
import { getCommaSeparatedVal } from "../../../utilities/common";
import MultiLinks from "../../hyperlinks/multiLinks";
import useStickyColumnsEffect from "../../../utilities/stickeyStyle";
import AdActivity2 from "./AdActivity2";
import Link from "../../hyperlinks/link";

const Pages = ({  props, data, count }) => {
    const {freezeCols, isFreeze} = props
    const stickeyClassName = freezeCols >= 0 ? (isFreeze ? "sticky-cols-sticky" : "sticky-cols" ) : ""
    const [pageExpand, setPageExpand] = useState(false);

    useStickyColumnsEffect(freezeCols)

    return (
        <React.Fragment>
            <Table.Row
                className="li-children-v2"
                onDoubleClick={() => { setPageExpand(!pageExpand) }}
            >
                <Table.Cell className={stickeyClassName}>
                {
                    !_.isEmpty(data.on_page_activity) &&
                    <ExpandCollapse data={data.on_page_activity} expanded={pageExpand} setExpanded={setPageExpand} marginLeft={"70px"} />
                }
                </Table.Cell>
                {
                    props && props.finalHeaderData.map((header, cellIndex) => {
                        const freezeClassName = freezeCols >= 0 ? (cellIndex <= freezeCols ? (isFreeze  ? "sticky-cols-sticky" : "sticky-cols") : "" ) : ""
                        let cellContent = "";
                        if(!_.includes([SESSION_ID, TARGET_NAME, AD_ORDER, AD_TYPE], header.headerName)) {
                            const campaignData = data?.campaign_data ?? [];
                            if (header.headerName === PAGE_TITLE) {
                                cellContent = ( <Table.Cell key={cellIndex} title={data?.page_title} className={`blogs_link space_height ${freezeClassName}`}>
                                                    <a href={`https://${data?.domain}${data?.page_path}`} target="_blank" rel="noreferrer noopener">
                                                        {data?.page_title}
                                                    </a>
                                                </Table.Cell>
                                )
                            } else if (header.headerName === CAMPAIGN) {
                                if(campaignData) {
                                    cellContent = (<Table.Cell key={cellIndex} className={`blogs_link ${freezeClassName}`}>
                                                        <MultiLinks
                                                            type="campaigns"
                                                            object={campaignData}
                                                        />
                                                </Table.Cell>);
                                } else {
                                    cellContent = <Table.Cell key={cellIndex} className={freezeClassName}/>
                                }
                             } else if (header.headerName === ORDER) {
                                if(campaignData) {
                                    cellContent = (<Table.Cell key={cellIndex} className={`blogs_link ${freezeClassName}`}>
                                                        <MultiLinks
                                                            type="orders"
                                                            object={campaignData.map((c)=> c.order)}
                                                        />
                                                </Table.Cell>);
                                } else {
                                    cellContent = <Table.Cell key={cellIndex} className={freezeClassName}/>
                                }
                             } else if(header.headerName === NEWSLETTER_NAME) {
                                cellContent = data?.newsletter_info?.uuid ?
                                                (<Table.Cell title={data?.newsletter_info?.name} className={`blogs_link space_height ${freezeClassName}`}>
                                                    <Link type="newsletters" urlParam={data?.newsletter_info?.uuid} refValue={data?.newsletter_info?.name}></Link>
                                                </Table.Cell>) :
                                                (<Table.Cell key={cellIndex} className={freezeClassName}/>);
                             } else if(_.includes([UTM_SOURCE, UTM_MEDIUM, UTM_CAMPAIGN, UTM_TERM, UTM_CONTENT], header.headerName) && data.query_params) {
                                const utmData = data?.query_params !== null ? data?.query_params : [];
                                cellContent = <Table.Cell key={cellIndex} className={freezeClassName}>{getCommaSeparatedVal(utmData[header.sorted])}</Table.Cell>
                             } else if(header.headerName === IP_ADDRESS) {
                                cellContent =   data.ip_address ? 
                                                <Table.Cell title={data.ip_address} className={`blogs_link space_height ${freezeClassName}`} key={cellIndex}>
                                                    <Link type={"ip_address"} urlParam={data.ip_address} refValue={data.ip_address} />
                                                </Table.Cell> : 
                                                <Table.Cell key={cellIndex} className={freezeClassName}/>
                             } else {
                                cellContent = <Table.Cell key={cellIndex} className={freezeClassName}>{data[header.sorted]}</Table.Cell>
                             }
                        } else {
                            cellContent = <Table.Cell key={cellIndex} className={freezeClassName}/>
                        }
                        
                        return cellContent;
                    })
                }
            </Table.Row>

            {!_.isEmpty(data.on_page_activity) && data.on_page_activity.map(
                (pageActivity, index) => pageExpand && <AdActivity2 props={props} data={pageActivity} key={index} freezeCols={freezeCols} isFreeze = {isFreeze}/>
            )}

        </React.Fragment>
    );
};

export default Pages;