import React from "react";
import SingleSelectDropDown from "../base/singleSelectDropDown";

const PaidPicker = (props) => {
	return (
		<SingleSelectDropDown
			className="no-blink"
			options={[
				{
					key: "0",
					text: "All Ad Orders",
					value: "all",
				},
				{
					key: "1",
					text: "Only Paid Ad Orders",
					value: "paid",
				},
				{
					key: "2",
					text: "Only Unpaid Ad Orders",
					value: "unpaid",
				},
			]}
			label="Ad Order Type"
			placeholder="Ad Order Type"
			{...props}
		/>
	);
};

export default PaidPicker;
