import { getCookie } from "../utilities/envCommon";
import moment from "moment";

export const auth = {
	username: "reports@sdxcentral.com",
	password:
		"jmwgmwfijbldedfbnqynbmkeyvlrpiwmztwryvneynkamydemoibuholxfcwosnp==",
};

export const headers = () => {
	const headers = {
		"Content-Type": "application/json",
		"X-WP-User-ID": getCookie("userId"),
		"Access-Control-Allow-Origin": "*",
		Authorization: `Bearer ${getCookie("token")}`,
		"X-REQUEST-DATE": `${moment().format("YYYY-MM-DDTHH:mm:ssZ")}`,
		"X-SESSION_ID": getCookie("session_id"),
	};
	return headers;
};

// export const loginUri = `${wpLoginUri}/?client_id=${clientId}&redirect_uri=${encodeURIComponent(
//   callBackUri
// )}&response_type=${responseType}&scope=&staging_key=${stagingKey}&state=${authState}`;
