import React from "react";
import SingleSelectDropDown from "../base/singleSelectDropDown";
import "../../css/common.css";

const PacingFilter = (props) => {
	return (
		<SingleSelectDropDown 
			placeholder="All"
			options={[
				{
					key: "1",
					text: "All",
					value: "all",
				},
				{
					key: "2",
					text: "Red",
					value: "red",
				},
				{
					key: "3",
					text: "Yellow",
					value: "yellow",
				},
				{
					key: "4",
					text: "Green",
					value: "green",
				},
			]}
			// className="no-blink"
			// upward={false}
			value={props.value}
			onChange={props.onChange}
            label="Pacing"
		></SingleSelectDropDown>
	);
};

export default PacingFilter   ;
