import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { selectValue } from "../../actions/commonFilterActions";
import * as actionTypes from "../../actions/actionTypes";
import DatePicker from "../common/datePicker";
import { getDisplayDate, getStartEndDates } from "../../utilities/common";
import { DateRangePicker } from "react-date-range";

const WpDateComponent = (props) => {
    const [datePopupFilter, setDatePopupFilter] = useState("custom-date-popup-close");
    const [dateRangeFilter, setDateRangeFilter] = useState({
        selection: {
          startDate: new Date(props.filterData.startDate),
          endDate: new Date(props.filterData.endDate),
          key: "selection",
        },
    });

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    const selectDateRangeFilter = (e, { value }) => {
        dispatchDynamicSelectAction(props, "appliedDateFilter", value);
        setDatePopupFilter("custom-date-popup-close");
        if (value === "Custom Date Range") {
            setDatePopupFilter("custom-date-popup-open");
        } else {
            let dates = getStartEndDates(value);
            dispatchDynamicSelectAction(props, "wp_startDate", getDisplayDate(dates.startDate));
            dispatchDynamicSelectAction(props, "wp_endDate", getDisplayDate(dates.endDate));
        }
    };

    const cancelDateFilter = () => {
        setDateRangeFilter({
          selection: { startDate: new Date(), endDate: new Date(), key: "selection" },
        });
        setDatePopupFilter("custom-date-popup-close");
    };

    const onApplyClick = () => {
        dispatchDynamicSelectAction(props, "wp_startDate", getDisplayDate(dateRangeFilter.selection.startDate));
        dispatchDynamicSelectAction(props, "wp_endDate", getDisplayDate(dateRangeFilter.selection.endDate));
        setDatePopupFilter("custom-date-popup-close");
    };

    return (
        <React.Fragment>
            <DatePicker
                value={props.filterData.appliedDateFilter}
                onChange={selectDateRangeFilter}
                showAllDates={true}
                openDateRange={(e, { value }) => {
                    if (value === "Custom Date Range") {
                        document.getElementsByClassName("rdrDateInput")[2].style.display = "none";
                        document.getElementsByClassName("rdrDateInput")[3].style.display = "none";
                        document.getElementsByClassName("rdrDateDisplayWrapper")[1].style.display = "none";
                        dispatchDynamicSelectAction(props, "appliedDateFilter", "Custom Date Range");
                        setDatePopupFilter("custom-date-popup-open");
                    }
                }}
            />
    
            <div className={datePopupFilter}>
                <div className="date-picker-close-icon" onClick={() => { setDatePopupFilter("custom-date-popup-close"); }}>
                    <i className="material-icons grey">clear</i>
                </div>
                <div>
                    <DateRangePicker
                        minDate={new Date("2019-12-13")}
                        maxDate={ props.filterData.endDate ? new Date(props.filterData.endDate) : new Date() }
                        onChange={(item) => { setDateRangeFilter({ ...dateRangeFilter, ...item }); }}
                        months={2}
                        moveRangeOnFirstSelection={false}
                        direction="horizontal"
                        ranges={[dateRangeFilter.selection]}
                        rangeColors={["#e5582b"]}
                    />
                </div>
                <div className="date-buttons">
                    <button type="button" className="cancel-date-button" onClick={cancelDateFilter}>Cancel</button>
                    <button type="button" className="apply-date-button" onClick={onApplyClick}>Apply</button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default compose(connect(null, {
    selectValue,
}))(WpDateComponent);