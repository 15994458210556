import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { useLocation } from "react-router-dom";

import DateComponent from "../../CommonFilter/DateComponent";
import CollectiveCommonFilter from "./CollectiveCommonFilter";
import EngagedCommonToggleFilter from "./EngagedCommonToggleFilter";
import FirmographicsFilterComponent from "./FirmographicsFilterComponent";
import PersonaFilterComponent from "./PersonaFilterComponent";
import CampaignToggleComponent from "./CampaignToggleComponent";
import { clearSearchForAllReport, fetchAdCampaignSummary, fetchAdImpressions, fetchAdData, selectValue, fetchChart } from "../../../actions";
import * as actionTypes from "../../../actions/actionTypes";
import { url } from "../../../api/urlHelper";
import { Form } from "semantic-ui-react";
import CompetitorFilterToggle from "../../common/FilterSection/CompetitorFilterToggle";
import { AllAdsLocation } from "../../config/PathNames";
import EngagedOrgSelection from "../../common/FilterSection/EngagedOrgSelection";
import EngagedMemberSelection from "../../common/FilterSection/EngagedMemberSelection";
import * as roleReport from "../../config/rolebasedReportNames";
import { CHART, VIEW } from "../../config/const";

const GlobalFilter = (props) => {
    const [filterSelectFocus, setFilterSelectFocus] = useState("filter-icon");
    const [filterPopup, setFilterPopup] = useState("filter-popup-close");
    const [filterIconColor, setFilterIconColor] = useState("material-icons grey");
    const [clear, setClear] = useState(false);

    const dispatchAction = useDispatch();
    const location = useLocation();

    const applyFilters = (startDate, endDate, appDate, isCustomCheckbox) => {
        props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].APPLY_FILTERS, reportType: props.reportActionType });
        let tempAdFilterData = props.filterData;
        tempAdFilterData.startDate = startDate;
        tempAdFilterData.endDate = endDate;
        tempAdFilterData.prevStartDate = "";
        tempAdFilterData.prevEndDate = "";
        tempAdFilterData.appliedDate = appDate;
        tempAdFilterData.selCustomRangeCheckBox = isCustomCheckbox;

        if(location.pathname !== AllAdsLocation) {
            if(props?.roleReports?.some((report) => roleReport.allowedAdSummaryElements.includes(report.element) && report.permission === VIEW)) {
                props.fetchAdCampaignSummary(
                    tempAdFilterData,
                    1,
                    "",
                    actionTypes[props.reportActionType].CAMPAIGN_SUMMARY,
                    props.reportName,
                    props.reportActionType,
                );
            }
            
            if(props?.roleReports?.some((report) => roleReport.allowedCampaignDetailElements.includes(report.element) && report.permission === VIEW)) {
                props.fetchAdImpressions(
                    tempAdFilterData,
                    1,
                    "",
                    actionTypes[props.reportActionType].CAMPAIGN_AD_DETAILS,
                    props.reportName,
                    props.reportActionType,
                );
            }
            
            if(props?.filterData?.showChart && props?.roleReports?.some((report) => roleReport.allowedAdChart.includes(report.element) && report.permission === CHART)) {
                dispatchAction({
                    type: actionTypes[props.reportActionType].UPDATED_CHART_STATUS,
                    reportType: props.reportActionType,
                    payload: {
                        chartApiCalled: true,
                    },
                });
                props.fetchChart(
                    tempAdFilterData,
                    1,
                    "",
                    actionTypes[props.reportActionType].DISPLAYING_CHARTS,
                    props.reportName,
                    props.reportActionType,
                );
            }
        }
        (props.filterData.isSelectDetailToggle || location.pathname === AllAdsLocation) &&
            props.fetchAdData(
                url.adDetail,
                tempAdFilterData,
                1,
                "",
                actionTypes[props.reportActionType].AD_DETAILS,
                null,
                null,
                null,
                props.reportName,
                props.reportActionType,
            );
        if(location.pathname === AllAdsLocation) {
            props.selectValue({
                dispatchType: actionTypes[props.reportActionType].SELECTVALUE,
                reportType: props.reportActionType,
                key: 'isSelectDetailToggle',
                value: true
            });
        }
        // dispatchAction({
        //     type: actionTypes[props.reportActionType].UPDATED_CHART_STATUS,
        //     reportType: props.reportActionType,
        //     payload: {
        //         campaignLoaded: false,
        //         detailLoaded: false,
        //     },
        // });
        setFilterPopup("filter-popup-close");
        setFilterIconColor("material-icons grey");
        setFilterSelectFocus("filter-icon");
    }

    const clearFilters = () => {
        setClear(true);
        setTimeout(() => {
            setClear(false);
        }, 1500);
        props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].CLEARCOMMONFILTER, reportType: props.reportActionType });
    }

    return (
        <div>
            <DateComponent {...props} applyFilters={applyFilters} isClear={clear} />
            <div className="popup-component">
                <div
                    className={filterSelectFocus}
                    style={{ marginTop: "-50px" }}
                    onClick={() => {
                        setFilterSelectFocus("filter-icon-selected");
                        setFilterPopup("filter-popup-open");
                        setFilterIconColor("material-icons orange");
                    }}
                >
                    <i className={filterIconColor}>filter_list</i>
                </div>
                <div className={filterPopup}>
                    <div className="filter-header">
                        <div className="filter-text">
                            <p>Filters</p>
                        </div>
                        <div
                            className="filter-close-icon"
                            onClick={() => {
                                setFilterSelectFocus("filter-icon");
                                setFilterPopup("filter-popup-close");
                            }}
                        >
                            <i
                                className="material-icons grey"
                                onClick={() => setFilterIconColor("material-icons grey")}
                            >
                                clear
                            </i>
                        </div>
                    </div>
                    {/* common filter for all report*/}
                    <CollectiveCommonFilter {...props} />
                    <Form>
                        <div className="ad-search-filter">
                            <Form.Group widths="equal">
                                <EngagedOrgSelection {...props} />
                                <EngagedMemberSelection {...props} />
                            </Form.Group>
                        </div>
                    </Form>
                    <Form>
                        <div className="ad-search-filter">
                            <Form.Group widths="equal">
                                <EngagedCommonToggleFilter {...props} />
                            </Form.Group>
                        </div>
                    </Form>
                    <Form><div className="ad-search-filter"><FirmographicsFilterComponent {...props} isApplicable={true}/></div></Form>
                    <div className="ad-search-filter"><PersonaFilterComponent {...props} isApplicable={true}/></div>

                    <Form>
                        <div className="ad-search-filter">
                            <Form.Group widths="equal">
                                <CompetitorFilterToggle {...props} style={{ marginTop: "10px" }} />
                                <CampaignToggleComponent {...props} />
                            </Form.Group>
                        </div>
                    </Form>

                    <div className="filter-buttons">
                        <button className="cancel-date-button extra-space" onClick={clearFilters}>Clear Filters</button>
                        <button
                            type="submit"
                            className="apply-filter-button"
                            onClick={() => {
                                applyFilters(
                                    props.filterData.startDate,
                                    props.filterData.endDate,
                                    props.filterData.appliedDate,
                                    props.filterData.selCustomRangeCheckBox
                                );
                                if (props.filterData.isFilApplied) {
                                    dispatchAction({
                                        type: actionTypes[props.reportActionType].UPDATED_CHART_STATUS,
                                        reportType: props.reportActionType,
                                        payload: {
                                            chartApiCompleted: false,
                                        },
                                    });
                                }
                            }}
                        >
                            Apply Filters
                        </button>
                    </div>

                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {};
};

export default compose(connect(mapStateToProps, {
    clearSearchForAllReport,
    fetchAdCampaignSummary,
    fetchAdImpressions,
    fetchAdData,
    selectValue,
    fetchChart,
}))(GlobalFilter);