import React from "react";

import DownloadCsvXlsx from "../common/DownloadCsvXlsx";
import EmailPopUp from "../common/EmailPopUp";
import { GENERATE_CSV } from "../config/const";
import * as downloadHelper from "../../components/CommonFilter/downloadHelper";

const CSVDownload = ({ props, data, csvField, filterData, reportActionType }) => {
    return (
        <div className="class-relative">
            <DownloadCsvXlsx
                onClick={() => downloadHelper.decisionInsightsCSVData(props)}
                label={GENERATE_CSV}
                disabled={!filterData.isFilApplied}
            />
            <div className="pg popup">
                {filterData[csvField] && (
                    <EmailPopUp
                        email={filterData[csvField]}
                        table="ads_common"
                        cardPopup={filterData[csvField]}
                        csvExcel={true}
                        emailKey={csvField}
                        reportActionType={reportActionType}
                    />
                )}
            </div>
        </div>
    );
};

export default CSVDownload;
