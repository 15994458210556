import _ from "lodash";
import React, {useRef} from "react";
import { connect } from "react-redux";
import { Checkbox, Loader } from "semantic-ui-react";
import { selLeadToggles } from "../../../actions";
import * as actionTypes from "../../../actions/actionTypes";
import { getCapital, getDisplayDateChart } from "../../../utilities/common";
import DownloadChart from "../../common/downloadChart";
import LeadEngsProgressChart from "./leadEngsProgressChart";

const EngagementStatusChart = (props) => {
  const chartNameDiv = useRef(null);
  let loading;
  if (props.loading) {
    loading = (
      <div className="show-loader">
        <Loader active inline="centered" />
      </div>
    );
  }

  return (
    <>
      <div className="ad-graph" id="charts-gap">
        <div ref={chartNameDiv}>
          {!_.isEmpty(props.leadEngsValidationBarChart) && props.leadFetchData.engsStatusChart && 
            <DownloadChart
                chartNameDiv= {chartNameDiv.current}
                chartName = {(`Engagement-Status-Chart-${getDisplayDateChart(new Date())}.jpg`)}
                style={{fontSize:"35px", marginTop: 19, marginRight: 56, color: "#838486" }}
          />
          }
          <p className="ad-chart-name">
            {getCapital("Engagement Status")}
            <Checkbox
              className="checkbox-label"
              data-html2canvas-ignore="true"
              // slider
              toggle
              style={{
                color: "white",
                fontFamily: "Roboto",
                fontSize: "16px",
                paddingLeft: "20px",
                minHeight: 15,
              }}
              label={props.leadFetchData.engsStatusChart ? "Hide" : "Show"}
              onChange={() =>
                props.selLeadToggles(actionTypes.SELENGSSTATUSCHART)
              }
              checked={props.leadFetchData.engsStatusChart}
              disabled={!props.isFilApplied}
            />
            {
              props.isFilApplied &&
              props.leadFetchData.engsStatusChart && loading
            }
          </p>

          {
            !_.isEmpty(props.leadEngsValidationBarChart) && props.leadFetchData.engsStatusChart && 
            <LeadEngsProgressChart />
          }
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    leadEngsValidationBarChart: state.leadFetchData.leadEngsValidationBarChart,
    isFilApplied: state.leadFetchData.isFilApplied,
    leadFetchData: state.leadFetchData,
    loading: state.leadFetchData.isLoadingStatusChart,
  };
};

export default connect(mapStateToProps, { selLeadToggles })(
  EngagementStatusChart
);
