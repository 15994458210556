import React from "react";
import { Table } from "semantic-ui-react";
import { connect } from "react-redux";
import { sortSponsoredContentCampaignDetail } from "../../../actions";

const TableHeader = (props) => {
  return props.headerData?.map((hData, idx) => {
    return (
      <Table.HeaderCell
        key={idx}
        className={hData?.className}
        sorted={props.propData?.column === hData?.sorted ? props.propData?.direction : null}
        onClick={() => props.propData?.sortSponsoredContentCampaignDetail(hData?.sorted)}
        textAlign = {hData?.textAlign}
      >
        {hData?.headerName}
      </Table.HeaderCell>
    );
  });
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { sortSponsoredContentCampaignDetail })(TableHeader);
