import React from "react";
import { connect } from "react-redux";
import * as constName from "../../components/config/const";
import AllAds from "../AllAds";
import { ROLEBASED_ELEMENT_ALL_ADS } from "../../components/config/rolebasedReportNames";
import { getReportsByElement } from "../../utilities/common";

const AllAdsReport = (props) => {
    const allAdsReports = getReportsByElement(props?.roleFilterData?.currentUserReportRoleData, props?.roleFilterData?.getAllReports, ROLEBASED_ELEMENT_ALL_ADS);

    return (
        <AllAds
            reportActionType={constName.MODULE_ALL_ADS_REPORT}
            reportName={constName.ALL_ADS_REPORT_NAME}
            filterData={props.filterData}
            reportDescription={props.reportDescription.find(report => report.name === "Ads - All")?.description}
            label={constName.ALL_ADS_REPORT_LABEL}
            roleReports={allAdsReports}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        filterData: state.commonFilterData[constName.MODULE_ALL_ADS_REPORT],
        reportDescription: state.allFilterReportData.reportDescription,
        roleFilterData: state.allFilterReportData,
    };
};

export default connect(mapStateToProps)(AllAdsReport);