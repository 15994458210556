import React from 'react';
import { connect } from 'react-redux';

class AdminLogin extends React.Component{

	componentDidUpdate(){
		if(this.props.loginLink){
			window.open(this.props.loginLink,"_self")
		}
	}

	render(){

		return null
	}
}

const mapStateToProps = (state) => {
	return {
		loginLink: state.links.loginLink
	};
  };

export default connect(mapStateToProps)(AdminLogin)