import React from "react";
import SearchFilter from "../components/siteReport/searchFilter";
import SiteChart from "../components/siteReport/siteChart";
import SiteAllPGSummaryReport from "../components/siteReport/siteAllPGSummaryReport";
import SiteEditorialPGSummaryReport from "../components/siteReport/siteEditorialPGSummaryReport";
import SiteSponsoredPGSummaryReport from "../components/siteReport/siteSponsoredPGSummaryReport";
import { connect } from "react-redux";
import { Message } from "semantic-ui-react"
import { ALL_PAGEVIEW_SUMMARY, INDEPENDENT_PAGEVIEW_SUMMARY, ROLEBASED_ELEMENT_SITE, SPONSORED_PAGEVIEW_SUMMARY } from "../components/config/rolebasedReportNames";
import { VIEW } from "../components/config/const";
import { getReportsByElement } from "../utilities/common";


const Site = (props) =>{
    let tableView;

    if (!props.isFilter) {
        tableView = <Message>Please proceed with applying filter.</Message>;
    } 

    const roleReports = getReportsByElement(props?.roleFilterData?.currentUserReportRoleData, props?.roleFilterData?.getAllReports, ROLEBASED_ELEMENT_SITE);

    return(
        <div>
            <div className="page-name-comp">
                <p className="page-name">Site</p>
                <p>
                    {props.reportDescription.find(report => report.name === "Site Performance")?.description}
                </p>
            </div>
            <SearchFilter roleReports={roleReports}/>
            <br />
            <SiteChart />

            {
                (roleReports?.some((report) => [ALL_PAGEVIEW_SUMMARY].includes(report.element) && report.permission === VIEW)) && 
                <div>
                    <div className="table-heading-block">
                        <div className="table-name">
                            <p> All Pageview Summary</p>
                        </div>
                    </div>
                    <SiteAllPGSummaryReport />
                </div>
            }
            {
                (roleReports?.some((report) => [INDEPENDENT_PAGEVIEW_SUMMARY].includes(report.element) && report.permission === VIEW)) &&
                <div>
                    <div className="table-heading-block">
                        <div className="table-name">
                            <p> Independent Pageview Summary</p>
                        </div>
                    </div>
                    <SiteEditorialPGSummaryReport />
                </div>
            }
            {
                (roleReports?.some((report) => [SPONSORED_PAGEVIEW_SUMMARY].includes(report.element) && report.permission === VIEW)) &&
                <div>
                    <div className="table-heading-block">
                        <div className="table-name">
                            <p> Sponsored Pageview Summary</p>
                        </div>
                    </div>
                    <SiteSponsoredPGSummaryReport />
                </div>
            }
            {tableView}
        </div>
    )
}

const mapStateToProps = (state) => {
	return {
		siteAllPgSummaryData: state.siteReportFilterData.siteAllPgData,
		siteEDPgSummaryData: state.siteReportFilterData.siteEditorialData,
        siteSponsoredPgSummaryData: state.siteReportFilterData.siteSponsoredData,
        isFilter: state.siteReportFilterData.isApplied,
        reportDescription: state.allFilterReportData.reportDescription,
        roleFilterData: state.allFilterReportData,
	};
};

export default connect(mapStateToProps, {

})(Site);