import React from "react";
import MultipleSelectDropDown from "../base/multiSelectDropdown";
import { connect } from "react-redux";

const PrimaryTopic = (props) => {
  return (
    <MultipleSelectDropDown
      options={props.primaryTopic.map((username, index) => ({
        key: index,
        text: username?.tag_name,
        value: username?.tag_id,
      }))}
      label={props.label}
      placeholder={props.placeholder}
      onSearchChange={props.onSearchChange}
      value={props.value}
      onChange={props.onChange}
      onClick={props.onClick}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    wpLink: state.links.wpLink,
  };
};

export default connect(mapStateToProps, {})(PrimaryTopic);
