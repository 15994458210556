import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import Link from "./link";
import { getUnescapeStr } from "../../utilities/common";

const MultiTagLinks = (props) => {
	const { type, object, refValue } = props;
	let links = [];
	let separator = 1;

	if (props.object && _.isArray(object)) {
		const tagsNames = refValue.split(", ");
		object.forEach((data, index) => {
			if (tagsNames.includes(data.name)) {
				if (type === "topic" || type === "placement" || type === "orgs" || type === "primary") {
					links.push(
						<Link
							key={index}
							type={type === "orgs" ? "orgs" : type}
							urlParam={data.slug}
							refValue={getUnescapeStr(data.name)}
							domain={data.domain}
						/>,
						separator !== _.size(tagsNames) ? ", " : ""
					);
					separator++;
				} else {
					links.push(
						<Link
							key={index}
							type={type}
							urlParam={data.organization_uuid}
							refValue={getUnescapeStr(data.name)}
							domain={data.domain}
						/>,
						separator !== _.size(tagsNames) ? ", " : ""
					);
					separator++;
				}
			}
		});
	}
	return links;
};

const mapStateToProps = (state) => {
	return {
		wpLink: state.links.wpLink,
	};
};

export default connect(mapStateToProps)(MultiTagLinks);
