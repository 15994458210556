import React from "react";
import { Checkbox, Dropdown } from "semantic-ui-react";
import { DateRangePicker } from "react-date-range";
import { isSameDay, differenceInDays } from "date-fns";

const CustomDatePickerWithCompare = (props) => {
    let today = new Date();
    let yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return (
        <div className={props?.datePopup}>
            <div
                className="date-picker-close-icon"
                onClick={(e) => { props && props.setDatePopup("custom-date-popup-close") }}
            >
                <i className="material-icons grey">clear</i>
            </div>
            <div className="custom-date">
                <span
                    style={{ color: "#838486", fontFamily: "Roboto", fontSize: "16px" }}
                >
                    {" "}
                    Custom Date{" "}
                </span>
                <span
                    style={{
                        paddingLeft: "14.2rem",
                        color: "#e5582b",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                    }}
                >
                    {props.checkSelectState
                        ? props.cutomDate(
                            props?.dateRange?.selection1?.startDate,
                            props?.dateRange?.selection1?.endDate
                        )
                        : props?.cutomDate(
                            props?.dateRange?.selection?.startDate,
                            props?.dateRange?.selection?.endDate
                        )}
                </span>
            </div>
            <hr />
            {
                props?.isComparePeriod && 
                <div className="custom-chkbox">
                    <Checkbox
                        style={{ color: "white", fontFamily: "Roboto", fontSize: "16px" }}
                        className="calcheckbox"
                        checked={props?.checkSelectState}
                        onChange={(e, { checked }) => {
                            props && props.setCheckSelectState(checked);
                            if (checked) {
                                props && props.setComparePeriod("Previous Period");
                                props && props.previousPeriodData(
                                    props?.dateRange?.selection?.startDate,
                                    props?.dateRange?.selection?.endDate,
                                    props?.comparePeriod
                                );
                            } else {
                                props && props.getClearDate(props?.dateRange); //cancel functionality
                            }
                        }}
                        label="Compare to:"
                    />
                    {props?.checkSelectState && (
                        <Dropdown
                            style={{
                                borderColor: "#e5582b",
                                padding: "0.67857143em 2.1em 0.67857143em 1em",
                                color: "#838486",
                                fontFamily: "Roboto",
                                fontSize: "16px",
                                letterSpacing: 0,
                                lineHeight: "16px",
                            }}
                            options={[
                                {
                                    key: "1",
                                    text: "Previous Period",
                                    value: "Previous Period",
                                },
                                {
                                    key: "3",
                                    text: "Previous 7 Days",
                                    value: "7",
                                },
                                {
                                    key: "4",
                                    text: "Previous 30 Days",
                                    value: "30",
                                },
                                {
                                    key: "5",
                                    text: "Previous 90 Days",
                                    value: "90",
                                },
                                {
                                    key: "6",
                                    text: "Previous Day of Week",
                                    value: "Previous Day of Week",
                                },
                                {
                                    key: "2",
                                    text: "Previous Year",
                                    value: "Previous Year",
                                },
                            ]}
                            value={props?.comparePeriod}
                            onChange={(e, { value }) => {
                                props && props.setComparePeriod(value);
                                props && props.previousPeriodData(
                                    props?.dateRange?.selection1?.startDate,
                                    props?.dateRange?.selection1?.endDate,
                                    value
                                );
                            }}
                        />
                    )}
                    {props?.checkSelectState && (
                        <span style={{ color: "#CC167F", fontFamily: "Roboto", fontSize: "16px" }}>
                            {props?.comparePeriod === "Previous Period"
                                ? props?.cutomDate(
                                    props?.dateRange?.selection2?.startDate,
                                    props?.dateRange?.selection2?.endDate
                                )
                                : props?.cutomDate(
                                    props?.dateRange?.selection2?.startDate,
                                    props?.dateRange?.selection2?.endDate
                                )}
                        </span>
                    )}
                </div>
            }
            {props?.isComparePeriod && <hr />}
            <div></div>
            <div>
                <DateRangePicker
                    minDate={new Date("2019-12-13")}
                    maxDate={yesterday}
                    onChange={(item) => {
                        if (props?.checkSelectState) {
                            let selection = null;
                            if (item.hasOwnProperty("selection1")) {
                                selection = item.selection1;
                                if (props?.comparePeriod === "Previous Day of Week") {
                                    selection.endDate = selection.startDate;
                                }
                            } else if (item.hasOwnProperty("selection2")) {
                                selection = item.selection2;
                                if (props?.comparePeriod === "Previous Day of Week") {
                                    selection.endDate = selection.startDate;
                                } else {
                                    if (!isSameDay(new Date(item.selection2.startDate), new Date(item.selection2.endDate))) {
                                        if (differenceInDays(new Date(item.selection2.startDate), new Date(props?.dateRange.selection1.endDate)) < 0
                                            && differenceInDays(new Date(props?.dateRange.selection1.endDate), new Date(item.selection2.endDate)) > 0) {
                                            selection.endDate = props?.dateRange.selection1.startDate
                                        } else {
                                            selection.startDate = props?.dateRange.selection1.startDate
                                        }
                                    }
                                }
                                selection.key = "selection1";
                                selection.color = "#e5582b";
                            }
                            props && props.previousPeriodData(
                                selection.startDate,
                                selection.endDate,
                                props?.comparePeriod
                            );
                        } else {
                            props && props.setDateRange({ ...props?.dateRange, ...item });
                        }
                    }}
                    months={2}
                    moveRangeOnFirstSelection={false}
                    direction="horizontal"
                    ranges={
                        props?.checkSelectState
                            ? [props?.dateRange?.selection1, props?.dateRange?.selection2]
                            : [props?.dateRange?.selection]
                    }
                    rangeColors={["#e5582b", "#e5582b"]}
                />
            </div>
            <div className="date-buttons">
                <button type="button" className="cancel-date-button" onClick={props?.cancelDate}>
                    Cancel
                </button>
                <button type="button" className="apply-date-button" onClick={props?.applyDate}>
                    Apply
                </button>
            </div>
        </div>
    );
};

export default CustomDatePickerWithCompare;
