import React from "react";
import SingleSelectDropDown from "../base/singleSelectDropDown";
import { titleCase } from "../../utilities/common";
import { connect } from "react-redux";

const AdorderStatusPicker = (props) => {
	return (
		<SingleSelectDropDown
			clearable
			className="no-blink"
			options={props.adorderstatus.map((type, index) => ({
				key: index,
				text: titleCase(type.label),
				value: type.value,
			}))}
			label={props.label}
			placeholder={props.placeholder}
			value={props.value}
			onChange={props.onChange}
			{...props}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		adorderstatus: Object.values(state.adorderStatus),
	};
};

export default connect(mapStateToProps, {})(AdorderStatusPicker);
