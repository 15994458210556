import React from "react";
import { Table } from "semantic-ui-react";
import SortIcon from "@mui/icons-material/Sort";
import { MEMBER_ACTIONS_TAKEN, MEMBER_ENGAGGEMENTS, MEMBER_PAGEVIEWS, MEMBER_SESSIONS, ORGANIZATION_ACTIONS_TAKEN, ORGANIZATION_ENGAGGEMENTS, ORGANIZATION_PAGEVIEWS, ORGANIZATION_SESSIONS, SORT_ICON } from "../config/TableHeaderNames";
import _ from "lodash";

const HeadersWithoutSortable = (props) => {
   const {
      column,
      direction,
      action,
      freezeCols,
   } = props;
   return props.headerData.map((hData, idx) => {
      const isSorted = _.includes([ORGANIZATION_ACTIONS_TAKEN, ORGANIZATION_SESSIONS, ORGANIZATION_PAGEVIEWS, ORGANIZATION_ENGAGGEMENTS, MEMBER_ACTIONS_TAKEN, MEMBER_SESSIONS, MEMBER_PAGEVIEWS, MEMBER_ENGAGGEMENTS], hData.headerName)
      const freezeClassName = freezeCols >= 0 ? (idx <= freezeCols ? "sticky-cols-header" : "") :""

      return (
         hData.headerName === SORT_ICON ?
         <Table.HeaderCell key={idx} textAlign="left" className={freezeClassName.length >1 ? freezeClassName: "icon"}>
            <SortIcon />
         </Table.HeaderCell> 
         :
         <Table.HeaderCell
            key={idx}
            className={freezeClassName.length > 1 ? freezeClassName: hData.className}
            sorted={isSorted && (column === hData.sorted ? direction : null)}
            onClick={() => isSorted && action(hData.sorted)}
            onContextMenu={props.handleRightClickColumn(idx)}
         >
            {hData.headerName}
         </Table.HeaderCell>
      )
   });
};

export default HeadersWithoutSortable;
