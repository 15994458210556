import React from "react";
import SingleSelectDropDown from "../base/singleSelectDropDown";

const activityOptions = [
    { key: "0", text: "All", value: "all" },
    // { key: "1", text: "Low Engagement", value: "low_engagement" },
    { key: "2", text: "Fading", value: "fading" },
    { key: "3", text: "Stable", value: "stable" },
    { key: "4", text: "Rising", value: "rising" }
];

const AccountActivityTrend = (props) => (
	<SingleSelectDropDown
		className="no-blink"
		options={activityOptions}
		label="Account Activity Trend"
		placeholder="Account Activity Trend"
		{...props}
	/>
);

export default AccountActivityTrend;