import React from "react";
import { List } from "semantic-ui-react";
import { getName } from "../../utilities/common";

const labelStyle = {
  paddingLeft: "20px",
};
const Contextual = (props) => {
  return (
    <div className="main-div">
      <label style={labelStyle} className="filter-text">
        {props.label}
      </label>
      {props.object.length > 0 ? (
     
          <List>
            <List.Item className="drawer-value">
              <List.Item className="drawer-text">{getName(props.object, "name")}</List.Item>
            </List.Item>
          </List>
       
      ) : (
        <List>
          <List.Item className="drawer-value">
            <List.Item className="drawer-text">-</List.Item>
          </List.Item>
        </List>
      )}
    </div>
  );
};
export default Contextual;
