import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Checkbox, Divider, Form, Icon, Loader, Menu, Message, Table } from 'semantic-ui-react';
import {
    sortSponsoredContentCampaign,
    clearScSort,
    showMoreSponsoredTableData,
    fetchPageviewTableData,
    sendTableHeaders,
    getTableHeaders,
} from "../../../actions";
import * as actionTypes from "../../../actions/actionTypes"
import _ from 'lodash';
import { url } from "../../../api/urlHelper";
import SCCampaignSummaryRow from './scSummaryRow';
import TableHeader from "./tableHeader";
import SCSummaryTableData from "./scSummaryTableData";


const SponsoredCampaignSummary = (props) => {
    const [selectHeaders, setSelectHeaders] = useState();
    const [finalHeaderData, setFinalHeaderData] = useState();
    const [headerColumn, setHeaderColumn] = useState([]);
    const [isSortIcon, setSortIcon] = useState(false);

    let tableBody;
    let showMoreButton;
    let loading;
    let noresult;

    useEffect(() => {
        props.getTableHeaders("sponsored_campaign_summary", actionTypes.GETSCSUMMARYDYNAMICTABLEHEADER)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const headerDataVal = props.getDynamicHeaderData;
        if (!_.isEmpty(headerDataVal)) {
            setSelectHeaders(headerDataVal)
            setFinalHeaderData(headerDataVal)
        } else if (headerDataVal === undefined || _.size(headerDataVal) === 0) {
            const constantHeadersArr = [...SCSummaryTableData]
            setSelectHeaders(constantHeadersArr)
            setFinalHeaderData(constantHeadersArr)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.getDynamicHeaderData])

    useEffect(() => {
        const headerName = []
        finalHeaderData && finalHeaderData.map((name) => {
            headerName.push(name.headerName);
            return ""
        });
        setHeaderColumn(headerName);
    }, [finalHeaderData])

    if (props.loading) {
        loading = (
            <div className="show-loader">
                <Loader active inline="centered" />
            </div>
        );
    }

    const showMore = async () => {
        props.clearScSort()
        props.showMoreSponsoredTableData(actionTypes.MORESCCAMPAIGNSUMMARYLOADING);
        await props.fetchPageviewTableData(
            url.sponsoredSummary,
            props.sponsoredContentFilterData,
            props.PageCount,
            "",
            actionTypes.MORESCSUMMARY,
            null,
            null,
            "sponsored-content",
            "resSponsoredSummary"
        );
        props.showMoreSponsoredTableData(actionTypes.SETSCCAMPAIGNSUMMARYPGCOUNT);
    };

    const createRow = (data) => {
        if (data.length > 0) {
            let tableRow = [];
            data.forEach((campaignValues, id) => {
                const htmlData = (
                    <SCCampaignSummaryRow
                        summaryRow={campaignValues}
                        headerName={headerColumn}
                    />
                );
                tableRow.push(<Table.Row key={id} children={htmlData} />);
            });
            return tableRow;
        }
    };

    if (!_.isEmpty(props.scSummary)) {
        tableBody = (
            <Table.Body>{createRow(props.scSummary)}</Table.Body>
        );
        showMoreButton = (
            <div className="table-divider">
                <Divider>
                    <div className="show-more-component">
                        {!props.sponsoredContentFetchData.resSponsoredSummary ?
                            (
                                <button className="show-more-button" onClick={showMore}>
                                    Show More
                                </button>
                            ) : (
                                <button className="show-more-button-disable">
                                    Show More
                                </button>
                            )}

                    </div>
                </Divider>
            </div>
        );
    } else if (
        props.isFilApplied &&
        !props.loading &&
        _.isEmpty(props.scSummary)
    ) {
        noresult = <Message>No results found.</Message>;
    }

    const clickOnSortIcon = () => {
        setSortIcon(!isSortIcon);
    };

    const clickOnSortHeader = (e, extraData, headerData) => {
        if (extraData.checked) {
            setSelectHeaders([...selectHeaders, headerData]);
        } else {
            const removeData = selectHeaders.filter(
                (hName) => hName.headerName !== headerData.headerName
            );
            setSelectHeaders(removeData);
        }
    };

    const applyButtonClick = () => {
        const removeSortIconVal = selectHeaders.filter(rowData => rowData.headerName !== "SORT_ICON");
        const addLastArr = [{ headerName: "SORT_ICON", sorted: "", className: "icon" }];
        const allElements = [].concat(removeSortIconVal, addLastArr);

        const constantHeadersArr = [...SCSummaryTableData]
        let finalHeaderArray = []
        constantHeadersArr.map((mainHeader) => {
            return _.map(allElements, function (selectHeader) {
                if (mainHeader.headerName === selectHeader.headerName) {
                    finalHeaderArray.push(selectHeader)
                }
            })
        })

        setFinalHeaderData(finalHeaderArray);
        props.sendTableHeaders("sponsored_campaign_summary", finalHeaderArray, actionTypes.SENDSCSUMMARYDYANMICTABLEHEADER)
        setSortIcon(!isSortIcon);
    };

    return (
        <div>
            <>
                <Form>
                    <div className="sorted_icon">
                        <Table.HeaderCell style={{ paddingBottom: "20px" }}>
                            <Icon name="edit" style={{ color: `${isSortIcon ? "#f15a29" : "#b2b3b3"}` }} size="large" onClick={clickOnSortIcon} />
                        </Table.HeaderCell>
                        {isSortIcon && (
                            <div
                                className="menu-list-items"
                                style={{ height: "0px", float: "right" }}
                            >
                                <Menu vertical className="dynamic_table--list">
                                    {Object.values(SCSummaryTableData).map((tableHeader, index) => (
                                        <div
                                            key={index}
                                        >
                                            <Checkbox
                                                className="table_header--checkbox"
                                                style={{ color: "#838486" }}
                                                label={tableHeader.name}
                                                checked={
                                                    selectHeaders && selectHeaders.some(
                                                        (el) => el.headerName === tableHeader.headerName
                                                    )
                                                        ? true
                                                        : false
                                                }
                                                onClick={(e, data) =>
                                                    clickOnSortHeader(e, data, tableHeader)
                                                }
                                            />
                                        </div>
                                    ))}
                                    <button className="apply_menu--btn" onClick={applyButtonClick}>
                                        Apply
                                    </button>
                                </Menu>
                            </div>
                        )}
                    </div>
                </Form>
            </>
            <div className="sortable-table-component" style={{ paddingTop: "30px" }}>
                <Table sortable fixed singleLine>
                    <Table.Header>
                        <Table.Row>
                            {
                                (!_.isEmpty(finalHeaderData)) &&
                                <TableHeader
                                    propData={props}
                                    headerData={finalHeaderData}
                                ></TableHeader>
                            }
                        </Table.Row>
                    </Table.Header>
                    {tableBody}
                </Table>
            </div>
            {loading}
            {showMoreButton}
            {noresult}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        scSummary: Object.values(state.sponsoredContentFetchData.scSummary),
        loading: state.sponsoredContentFetchData.isLoadingScSummary,
        filStartDate: state.sponsoredContentFilterData.startDate,
        filEndDate: state.sponsoredContentFilterData.endDate,
        column: state.sponsoredContentFetchData.column,
        summaryDirection: state.sponsoredContentFetchData.summaryDirection,
        isFilApplied: state.sponsoredContentFetchData.isFilApplied,
        PageCount: state.sponsoredContentFetchData.scCamapignSummaryPageCount,
        sponsoredContentFilterData: state.sponsoredContentFilterData,
        getDynamicHeaderData: state.sponsoredContentFetchData.getDynamicSummaryHeaderData,
        sponsoredContentFetchData: state.sponsoredContentFetchData
    };
};

export default connect(mapStateToProps, {
    sortSponsoredContentCampaign,
    clearScSort,
    showMoreSponsoredTableData,
    fetchPageviewTableData,
    sendTableHeaders,
    getTableHeaders,
})(SponsoredCampaignSummary);