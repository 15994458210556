import React from "react";
import { Table } from "semantic-ui-react";
import { getDateEng } from "../../../../utilities/common";

const DateComp = ({ data }) => {
    return (
        <Table.Cell className="space_height" title={getDateEng(data.created_at)}>
            {getDateEng(data.created_at)}
        </Table.Cell>
    );
};

export default DateComp;
