export const AdSNewsletterHeader = [
    { headerName: "AGENCY", headerKey: "agency_name"}, 
    { headerName: "ACCOUNT", headerKey: "account"}, 
    { headerName: "ORDER", headerKey: "order"}, 
    { headerName: "CAMPAIGN", headerKey: "campaign"}, 
    { headerName: "START DATE", headerKey: "start_date"}, 
    { headerName: "END DATE", headerKey: "end_date"},
    { headerName: "CAMPAIGN STATUS", headerKey: "status"}, 
    { headerName: "DELIVERY STATUS", headerKey: "delivery_state"}, 
    { headerName: "EMAIL SENDS", headerKey: "email_sent"},
    { headerName: "EMAILS SENT", headerKey: "emails_sent"}, 
    { headerName: "TOTAL EMAIL IMPRESSIONS DELIVERED", headerKey: "impressions"},
    { headerName: "OPEN RATE", headerKey: "percentage_served_to_date"},
    { headerName: "CLICKS", headerKey: "clicks"}, 
    { headerName: "CLICK THROUGH RATE", headerKey: "ctr"}, 
    { headerName: "PERCENTAGE COMPLETE", headerKey: "percent_complete"}, 
    { headerName: "PACE", headerKey: "pace"},
];