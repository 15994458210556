import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";

const allFilterReportData = {
	openAdSidebarMenus: false,
	filterProfile: "",
	userFilterProfileData: [],
	requestUserList: [],
	openPageviewMenus: false,
	openPageviewSidebarMenus: false,
	openDIMenus: false,
	openDISidebarMenus: false,
	nestedMenus: {},
	getAllReports: [],
	isLoadingForAllRepport: true,
	currentUserRole: [],
	getAllRoles: [],
	selectedRoles: [],
	saveRoleBaseReportData: [],
	currentUserReportRoleData: [],
	reportDescription: [],
	saveReportDescriptionStatus: null
};

const selectedUsers = {
	userList: [],
};

export const allFilterReportDataReducer = (state = allFilterReportData, action) => {
	switch (action.type) {
		case actionTypes.OPENADMENUS:
			return {
				...state,
				openAdMenus: action.payload,
			};
		case actionTypes.OPENADSIDEBARMENUS:
			return {
				...state,
				openAdSidebarMenus: action.payload,
			};
		case actionTypes.OPENPAGEVIEWMENUS:
			return {
				...state,
				openPageviewMenus: action.payload,
			}; 
		case actionTypes.OPENPAGEVIEWSIDEBARMENUS:
			return {
				...state,
				openPageviewSidebarMenus: action.payload,
			};
		case actionTypes.OPENDIMENUS:
			return {
				...state,
				openDIMenus: action.payload,
			}
		case actionTypes.OPENDISIDEBARMENUS:
			return {
				...state,
				openDISidebarMenus: action.payload,
			}
		case actionTypes.FILTER_PROFILE:
			return { ...state, filterProfile: action.payload }
		case actionTypes.GET_ALL_FILTER_PROFILES:
			return { ...state, userFilterProfileData: action.payload }
		case actionTypes.PROFILE_ACTIONS:
        	return { ...state, [action.payload.key]: action.payload.value };
		case actionTypes.SET_SHARED_USERS:
			const ids = _.map(action.payload, 'id');
			return { ...state, requestUserList: ids }

		case actionTypes.TOGGLE_NESTED_MENU:
            return {
                ...state,
                nestedMenus: action.payload,
            };
		case actionTypes.GET_ALL_REPORTS:
			return { ...state, getAllReports: action.payload };
		case actionTypes.GET_CURRENT_USER_ROLE:
			return { ...state, currentUserRole: action.payload };
		case actionTypes.GET_ALL_ROLES:
			return { ...state, getAllRoles: action.payload };
		case actionTypes.SELECT_ROLE_VALUES:
			return { ...state, [action.payload.key]: action.payload.value };
		case actionTypes.SAVE_ROLE_BASED_REPORT:
			return { ...state, saveRoleBaseReportData: action.payload, isLoadingForAllRepport: false };
		case actionTypes.GET_ROLE_REPORT_DETAILS:
			return { ...state, currentUserReportRoleData: action.payload, isLoadingForAllRepport: false };

		case actionTypes.GET_REPORT_DESCRIPTION:
			return { ...state, reportDescription: action.payload };
		case actionTypes.POST_REPORT_DESCRIPTION:
			return { ...state, saveReportDescriptionStatus: action.payload };
		default:
			return state;
	}
};


export const userListFilterReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCH_USER_LIST:
			return { ...state, ..._.mapKeys(action.payload, "id") };

		case actionTypes.ADD_USER_LIST:
			selectedUsers.userList = [];
			const ids = action.payload;
			ids.forEach(function (id) {
				selectedUsers.userList.push(state[id]);
			});
			return { ...state, ..._.mapKeys(selectedUsers.userList, "id") };
		case actionTypes.SELECTED_SHARED_USER_LIST:
			selectedUsers.userList = action.payload;
        	return {...state, ..._.mapKeys(selectedUsers.userList, "id") };
		case actionTypes.CLEAR_USER_LIST:
			return (state = []);

		default:
			return state;
	}
};