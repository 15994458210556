import React from "react";
import { Message } from "semantic-ui-react";

const MessageComponent = (props) => {
    return (
        <div className='error-message-block'>
            <Message positive compact>
                <Message.Header>{props.msgHeader}</Message.Header>
                <p>{props.msgBody}</p>
            </Message>
        </div>
    );
};

export default MessageComponent;