import React from "react";
import SingleSelectDropDown from "../base/singleSelectDropDown";

const DomainFilter = (props) => {
    return (
        <SingleSelectDropDown
        clearable
        className="no-blink"
        options={[
            { key: '0', text: 'All', value: 'all' },
            { key: '1', text: 'Valid', value: 'valid' },
            { key: '2', text: 'Invalid', value: 'invalid' }
        ]}
        label="Domain"
        placeholder="Domain"
        value={props.value}
        onChange={props.onChange}
        {...props}
    />
    )
}

export default DomainFilter;