import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import * as actionTypes from "../../../actions/actionTypes";
import { selectValue, fetchUrlParams, clearSearchForAllReport } from "../../../actions";
import SingleSelectDropDown from "../../base/singleSelectDropDown";

const UTMTermSelection = (props) => {

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    const onSearchHandler = (e) => {
		if (e.target.value.length > 1) {
            props.fetchUrlParams(
                e.target.value,
                "term",
                actionTypes[props.reportActionType].FETCH_UTM_TERMS,
                "",
                "pageview",
                props.reportActionType
            );
		} else {
			props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].RESET_UTM_TERMS, reportType: props.reportActionType });
		}
	};

    return (
        <SingleSelectDropDown
            className="no-blink"
            clearable
            options={props?.commonSelectData?.utmTerms.map((s, index) => ({
                key: index,
                text: s.term,
                value: s.term,
            }))}
            onSearchChange={onSearchHandler}
            onChange={(e, { value }) => { dispatchDynamicSelectAction(props, "selUrlTerm", value); }}
            value={props.filterData.selUrlTerm}
            label="UTM Term"
            placeholder="UTM Term"
        />
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        commonSelectData: state.allReportCommonReducer[ownProps.reportActionType],
    };
};

export default compose(connect(mapStateToProps, {
    selectValue,
    fetchUrlParams,
    clearSearchForAllReport
}))(UTMTermSelection);