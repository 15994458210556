import React from "react";
import SingleSelectDropDown from "../base/singleSelectDropDown";

const ActionsSelector = (props) => {
	return (
		<SingleSelectDropDown
			style={{ width: "17.50em" }}
			options={[
                {
					key: "0",
					text: "Select Actions",
					value: "",
				},
				{
					key: "1",
					text: "Actions",
					value: "actions",
				},
                {
					key: "2",
					text: "Organization Brand Impressions",
					value: "organization_actions_taken",
				},
                {
					key: "3",
					text: "Member Brand Impressions",
					value: "member_actions_taken",
				},
				
			]}
			placeholder="Select Actions"
			{...props}
		/>
	);
};

export default ActionsSelector;
