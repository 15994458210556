import React from "react";
import MultipleSelectDropDown from "../base/multiSelectDropdown";

const OrderPicker = (props) => {
	return (
		<MultipleSelectDropDown
			options={props.order.map((org, index) => ({
				key: index,
				text: org && org.name,
				value: org && org.uuid,
				className: org && org.deleted_at ? 'deleted' : ''
			}))}
			label="Order"
			placeholder="Order"
			onSearchChange={props.onSearchChange}
			value={props.value}
			onChange={props.onChange}
			onClick={props.onClick}
		/>
	);
};

export default OrderPicker;
