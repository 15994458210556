import React from "react";
import FilterDetails from "./FilterDetails";
import RequesterDetail from "./RequesterDetail";
import { Tab } from "semantic-ui-react";

const DownloadDrawer = (props) => {
  const panes = [
    { menuItem: 'FILTER DETAILS', render: () => <Tab.Pane> <FilterDetails data={props.data} /></Tab.Pane> },
    { menuItem: 'REQUESTERS', render: () => <Tab.Pane> <RequesterDetail data={props.data} /></Tab.Pane> },
  ]

  return (
    <>
      <Tab style={{ marginTop: 20, paddingLeft:5 }} panes={panes} />
    </>
  );
};

export default DownloadDrawer;
