import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import EngagedOrganization from "../engagedOrganization";
import * as actionTypes from "../../../actions/actionTypes";
import { fetchEngagedOrg, addSelectedValue, clearSearchForAllReport } from "../../../actions";

const EngagedOrgSelection = (props) => {
    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    const searchAdByOrg = (e, { value }) => {
        if (e.target.value.length > 1) {
            props.fetchEngagedOrg(
                props.filterData.excludeQACampaign,
                e.target.value,
                actionTypes[props.reportActionType].FETCH_ENG_ORGANIZATIONS,
                true,
                props.filterData.organization,
                props.filterData.displayIsIncludeParent,
                props.filterData.displayIsIncludeChild,
                props.reportActionType
            );
        }
    };

    const onSelectAdByOrg = (e, { value }) => {
        dispatchDynamicSelectAction(props, "engagedOrganizations", value);
        props.addSelectedValue({ dispatchType: actionTypes[props.reportActionType].ADD_ENG_ORGANIZATIONS, reportType: props.reportActionType, value });
    };

    const onClickAdByOrg = () => {
        props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].CLEAR_ENG_ORGANIZATIONS, reportType: props.reportActionType });
    };
    return (
        <EngagedOrganization
            value={props.filterData.engagedOrganizations}
            onSearchChange={searchAdByOrg}
            onChange={onSelectAdByOrg}
            onClick={onClickAdByOrg}
            type="addOrgLink"
            organizations={props.commonSelectData.engOrgs}
        />
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        commonSelectData: state.allReportCommonReducer[ownProps.reportActionType],
    };
};

export default compose(connect(mapStateToProps, {
    fetchEngagedOrg,
    addSelectedValue,
    clearSearchForAllReport,
}))(EngagedOrgSelection);