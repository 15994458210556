import React from "react";
import { Table } from "semantic-ui-react";

const TableHeaderComponent = (props) => {
  return (
    <Table.HeaderCell
      sorted={props.sorted}
      onClick={props.onClick}
      className={props.className}
      textAlign={props.textAlign}
    >
      {props.label}
    </Table.HeaderCell>
  );
};

export default TableHeaderComponent;
