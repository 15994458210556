import _ from "lodash";
import React, { useEffect } from "react";
import { Form } from "semantic-ui-react";
import { useLocation } from "react-router-dom";
import DatePicker from "../common/datePicker";
import { useState } from "react";
// import { addDays } from 'date-fns';
import {
	fetchOrganizations,
	fetchTitle,
	fetchOrder,
	addOrganization,
	addCampaigns,
	addOrder,
	fetchCampaigns,
	resetOrgsOrCampaigns,
	resetOrder,
	clearSearchOrgs,
	clearSearchCampaigns,
	clearSearchOrder,
	selClientLeadPageTitle,
	selClientLeadStartDate,
	selClientLeadEndDate,
	selClientLeadPartner,
	applyClientLeadDate,
	selClientLeadOrgs,
	selClientLeadOrder,
	selClientLeadCampaigns,
	selClientLeadEngOrgs,
	selClientLeadStatus,
	selFilterStatus,
	selFilterState,
	selClientLeadSite,
	selClientLeadType,
	selClientLeadToggles,
	applyClientLeadFilter,
	clearClientLeadFilter,
	fetchLeadEngSummary,
	fetchClientLeadEngs,
	//fetchLeadEngCampaignSummary,
	prevClientAppliedDate,
	prevClientSelStartDate,
	prevClientSelEndDate,
	resetClientToggles,
	fetchUrlParams,
	clearClientLeadSerchedValue,
	selClientLeadFilterValue,
	applyDateState,
	selShowDropDown,
	fetchLeadValidationChart,
	selGlobalLeadStatus,
	selGlobalVerificationState,
	fetchLeadValidationBarChart,
	selClientLeadState,
	selPacingFilterValue,
	fetchEngagedOrg,
	isTotalCountShowMore,
	clientSelectDropDown,
	selectToggleValue
} from "../../actions";
import { connect } from "react-redux";
import "../../css/common.css";
import { DateRangePicker } from "react-date-range";
import {
	getDisplayDate,
	getId,
	getYesterdaysDate,
	getStartEndDates,
	isUUID,
} from "../../utilities/common";
import OrganizationPicker from "../common/organization";
import CampaignPicker from "../common/campaign";
import OrderPicker from "../common/order";
import SitePicker from "../common/site";
import DeliveryStatePicker from "../common/deliveryState";
import StatusPicker from "../common/status";
import TypePicker from "../common/assetType";
import EngagedOrgPicker from "../common/engagedOrganization";
import LeadPartnerPicker from "../common/leadpartner";
import PageTitlePicker from "../common/pageTitle";
import * as actionTypes from "../../actions/actionTypes";
import Toggle from "../base/toggle";
import { url } from "../../api/urlHelper";
import { compose } from "redux";
import UrlParamsPicker from "../common/urlParams";
import ClientLeadGraph from "./clientLeadGraph";
import SingleSelectDropDown from "../base/singleSelectDropDown";
import { multipleOptions, options } from "../common/filterOptions";
import PacingFilter from "../common/pacingFilter";
import SearchFilterIcon from "../common/SearchFilterIcon";
import DomainFilter from "../common/DomainFilter";
import CompetitorFilterToggleForOthers from "../common/CompetitorFilterToggleForOthers";
import CSMSelection from "../common/CSMSelection";
import PrivacyParametersComponent from "../common/PrivacyParametersComponent";
import { LEAD_CAMPAIGN_SUMMARY, LEAD_DETAILS } from "../config/rolebasedReportNames";
import { VIEW } from "../config/const";

const SearchFilter = (props) => {
	const [datePopup, setDatePopup] = useState("custom-date-popup-close");
	const [filterPopup, setFilterPopup] = useState("filter-popup-close");
	const [filterSelectFocus, setFilterSelectFocus] = useState("filter-icon");
	const [filterIconColor, setFilterIconColor] = useState("material-icons grey");
	const [verificationStateOptions, setVerificationStateOptions] = useState([
		multipleOptions,
	  ]);

	const [dateRange, setDateRange] = useState({
		selection: {
			startDate: getYesterdaysDate(),
			endDate: getYesterdaysDate(),
			key: "selection",
		},
	});
	const location = useLocation();
	const paramId = getId(location.hash);
	const urlId = location.pathname + location.hash;

	useEffect(() => {
		initialLoad();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	useEffect(()=>{
		const updateState = multipleOptions.filter((name) => (props.leadFilterData.clientLeadFilterStatus === "All") ? name.allkey === props.leadFilterData.clientLeadFilterStatus : name.keyvalue === props.leadFilterData.clientLeadFilterStatus)
		setVerificationStateOptions(updateState);
		//props.leadFilterData.clientLeadFilterVerificationState === "All" && props.selFilterState("")
		// eslint-disable-next-line react-hooks/exhaustive-deps

	},[props.leadFilterData.clientLeadFilterStatus,props.leadFilterData.clientLeadFilterVerificationState]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(()=>{
		if(props.leadFilterData.selClientLeadChart){
			props.fetchLeadValidationChart(
				props.leadFilterData,
				1,
				"",
				actionTypes.CLIENTLEADCHART,
				true
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [props.isFilApplied, props.leadFilterData.selClientLeadChart ])

	  useEffect(()=>{
		if(props.clientLeadFetchData.leadStatusChart ){
			props.fetchLeadValidationBarChart(
				props.leadFilterData,
				1,
				"",
				actionTypes.CLIENTLEADBARCHART,
				true
			);	
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [props.isFilApplied, props.clientLeadFetchData.leadStatusChart ])

	  useEffect(()=>{
		if(props.clientLeadFetchData.leadAcceptanceChart){
			props.fetchLeadValidationBarChart(
				props.leadFilterData,
				1,
				"",
				actionTypes.LEADACCEPTANCEBARCHART,
				true
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [props.isFilApplied, props.clientLeadFetchData.leadAcceptanceChart])

	const initial = () => {
		props.applyClientLeadFilter();
		props.fetchLeadEngSummary(
			url.leadEngagementSummaryReport,
			props.leadFilterData,
			1,
			"",
			actionTypes.CLIENTLEADENGSUMMARY,
			true
		);
		// props.fetchLeadEngSummary(
		// 	url.leadEngagementCount,
		// 	props.leadFilterData,
		// 	1,
		// 	"",
		// 	actionTypes.CLIENTLEADENGSUMMARYCOUNT,
		// 	true
		// );
		props.fetchClientLeadEngs(
			props.leadFilterData,
			1,
			"",
			actionTypes.CLIENTLEADENGS,
			"",
			"",
			true
		);
	};

	const initialLoad = () => {
		if (urlId === `/leads/#/?campaign_uuid=${paramId}` && isUUID(paramId)) {
			props.fetchCampaigns(
				"",
				props.leadFilterData.site,
				props.leadFilterData.selTagsIN,
				props.leadFilterData.selTagsEX,
				props.leadFilterData.excludeQACampaign,
				"",
				props.leadFilterData.organization,
				props.leadFilterData.campaignStatus,
				//props.leadFilterData.assetType,
				"name",
				actionTypes.READLEADCAMPSID,
				[paramId],
				"",
				""
			);
			props.selClientLeadCampaigns([paramId]);
			if (props.leadFilterData.campaign.includes(paramId)) {
				initial();
			}
		}
	};

	const selectDateRange = async (e, { value }) => {
		props.applyClientLeadDate(value);
		setDatePopup("custom-date-popup-close");
		if (value === "Custom Date Range") {
			setDatePopup("custom-date-popup-open");
		} else {
			let dates = getStartEndDates(value);
			props.selClientLeadStartDate(dates.startDate);
			props.selClientLeadEndDate(dates.endDate);
			props.prevClientSelStartDate(dates.startDate);
			props.prevClientSelEndDate(dates.endDate);
			props.prevClientAppliedDate(value);
			if (props.isFilApplied) {
				applyFilters(dates.startDate, dates.endDate, value);
			}
		}
	};

	const applyDate = () => {
		props.selClientLeadStartDate(getDisplayDate(dateRange.selection.startDate));
		props.selClientLeadEndDate(getDisplayDate(dateRange.selection.endDate));
		props.prevClientSelStartDate(dateRange.selection.startDate);
		props.prevClientSelEndDate(dateRange.selection.endDate);
		props.prevClientAppliedDate("Custom Date Range");
		setDatePopup("custom-date-popup-close");
		if (props.isFilApplied) {
			props.applyDateState(true);
			applyFilters(
				dateRange.selection.startDate,
				dateRange.selection.endDate,
				props.leadFilterData.appliedDate
			);
		}
	};

	const cancelDate = () => {
		setDateRange({
			selection: {
				startDate: new Date(),
				endDate: new Date(),
				key: "selection",
			},
		});
		props.selClientLeadStartDate(
			getDisplayDate(props.leadFilterData.prevstartDate)
		);
		props.selClientLeadEndDate(
			getDisplayDate(props.leadFilterData.prevendDate)
		);
		if (props.leadFilterData.prevAppliedDate === "Custom Date Range") {
			props.applyClientLeadDate("Custom Date Range");
		} else {
			props.applyClientLeadDate(props.leadFilterData.prevAppliedDate);
		}
		setDatePopup("custom-date-popup-close");
	};

	//Organization event handlers

	const onClickOrg = () => {
		props.clearSearchOrgs(actionTypes.CLEARSEARCHCLIENTLEADORGS);
	};

	const searchOrg = (e) => {
		if (e.target.value.length > 1) {
			props.fetchOrganizations(
				props.leadFilterData.excludeQACampaign,
				e.target.value,
				actionTypes.FETCHCLIENTLEADORGS,
				props.organizations,
			);
		}
	};

	const onSelectOrg = (e, { value }) => {
		props.selClientLeadOrgs(value);
		props.addOrganization(value, actionTypes.ADDCLIENTLEADORGS);
		if (!_.isEmpty(props.leadFilterData.campaign)) {
			props.fetchCampaigns(
				"",
				props.leadFilterData.site,
				props.leadFilterData.selTagsIN,
				props.leadFilterData.selTagsEX,
				props.leadFilterData.excludeQACampaign,
				"",
				value,
				props.leadFilterData.campaignStatus,
				// props.leadFilterData.assetType,
				"start_date",
				actionTypes.FETCHSELCLIENTLEADCAMPAIGNS,
				props.leadFilterData.campaign,
				true,
				actionTypes.RESETSELCLIENTLEADCAMPAIGNS
			);
		}
	};

	//Campaign status event handlers

	const onSelectCampaignStatus = (e, { value }) => {
		props.selClientLeadStatus(value);
		props.resetOrgsOrCampaigns(actionTypes.RESETCLIENTLEADCAMPAIGN);
		props.selClientLeadCampaigns([]);
	};
	const onSelectLeadPartner = (e, { value }) => {
		props.selClientLeadPartner(value);
	};

	//Campaign event handlers

	const onClickCampaign = () => {
		if (_.isEmpty(props.leadFilterData.organization)) {
			props.clearSearchCampaigns(actionTypes.CLEARSEARCHCLIENTLEADCAMPAIGN);
		} else if (!_.isEmpty(props.leadFilterData.organization)) {
			props.fetchCampaigns(
				"",
				props.leadFilterData.site,
				props.leadFilterData.selTagsIN,
				props.leadFilterData.selTagsEX,
				props.leadFilterData.excludeQACampaign,
				"",
				props.leadFilterData.organization,
				props.leadFilterData.campaignStatus,
				// props.leadFilterData.assetType,
				"start_date",
				actionTypes.FETCHCLIENTLEADCAMPAIGNS
			);
		}
	};

	const searchCampaign = (e, { value }) => {
		if (e.target.value.length > 1) {
			props.fetchCampaigns(
				"",
				props.leadFilterData.site,
				props.leadFilterData.selTagsIN,
				props.leadFilterData.selTagsEX,
				props.leadFilterData.excludeQACampaign,
				e.target.value,
				props.leadFilterData.organization,
				props.leadFilterData.campaignStatus,
				//props.leadFilterData.assetType,
				"start_date",
				actionTypes.FETCHCLIENTLEADCAMPAIGNS
			);
		}
	};

	const onSelectCampaigns = (e, { value }) => {
		props.addCampaigns(value, actionTypes.ADDCLIENTLEADCAMPAIGNS);
		props.selClientLeadCampaigns(value);
		// if (value.length === 1) {
		//   let obj = props.campaigns.find((o) => o.uuid === value[0]);
		//   const campaignStartDate = new Date(obj.start_date);
		//   props.selClientLeadStartDate(getDisplayDate(campaignStartDate));
		//   props.selClientLeadEndDate(getDisplayDate(addDays(campaignStartDate, 30)));
		//   props.applyClientLeadDate('Custom Date Range');
		// } else {
		// props.applyClientLeadDate(getDisplayDate(getLastDays(7)));
		// props.selClientLeadStartDate(getDisplayDate(getLastDays(7)));
		// props.selClientLeadEndDate(getDisplayDate(getYesterdaysDate()));
		//}
	};

	//Asset type event handlers

	const onSelectType = (e, { value }) => {
		props.selClientLeadType(value);
		if (value !== "all") {
			props.resetOrgsOrCampaigns(actionTypes.RESETCLIENTLEADCAMPAIGN);
			props.selClientLeadCampaigns([]);
		}
	};

	//Site event handlers

	const onSelectSite = (e, { value }) => {
		props.selClientLeadSite(value);
	};

	//Organization event handlers
	const onClickEngOrg = () => {
		// if (_.isEmpty(props.leadFilterData.organization)) {
		// 	props.clearSearchOrgs(actionTypes.CLEARSEARCHCLIENTLEADENGORGS);
		// } else 
		if (!_.isEmpty(props.leadFilterData.organization) && (props.leadFilterData.clIsIncludeParent || props.leadFilterData.clIsIncludeChild)) {
			props.fetchEngagedOrg(
				props.leadFilterData.excludeQACampaign,
				"",
				actionTypes.FETCHCLIENTLEADENGORGS,
				true,
				props.leadFilterData.organization,
				props.leadFilterData.clIsIncludeParent,
				props.leadFilterData.clIsIncludeChild,
			);
		}
	  };

	const searchEngOrg = (e, { value }) => {
		if (e.target.value.length > 1) {
		  props.fetchEngagedOrg(
			props.leadFilterData.excludeQACampaign,
			e.target.value,
			actionTypes.FETCHCLIENTLEADENGORGS,
			true,
			props.leadFilterData.organization,
			props.leadFilterData.clIsIncludeParent,
			props.leadFilterData.clIsIncludeChild,
		  );
		}
	  };

	const onSelectEngOrg = (e, { value }) => {
		props.selClientLeadEngOrgs(value);
		props.addOrganization(value, actionTypes.ADDCLIENTLEADENGORGS);
	};

	//Order event handlers

	const onSelectOrder = (e, { value }) => {
		props.selClientLeadOrder(value);
		props.addOrder(value, actionTypes.ADDCLIENTLEADORDER);
	};
	const searchOrder = (e) => {
		if (e.target.value.length > 1) {
			props.fetchOrder(
				e.target.value,
				"name",
				actionTypes.FETCHCLIENTLEADORDER
			);
		}
	};

	const onClickOrder = () => {
		props.clearSearchOrder(actionTypes.CLEARSEARCHCLIENTLEADORDER);
	};
	//URL Source event Handlers
	const onClickUrlSource = () => {
		if (_.isEmpty(props.leadFilterData.selUrlSource)) {
			props.clearClientLeadSerchedValue(
				actionTypes.CLEARSEARCHCLIENTLEADURLSOURCE
			);
		}
	};

	const searchUrlSource = (e) => {
		if (e.target.value.length > 1) {
			props.fetchUrlParams(
				e.target.value,
				"source",
				actionTypes.FETCHCLIENTLEADURLSOURCE,
				"",
				"lead"
			);
		} else {
			props.clearClientLeadSerchedValue(
				actionTypes.CLEARSEARCHCLIENTLEADURLSOURCE
			);
		}
	};
	const onSelectUrlSource = (e, { value }) => {
		// props.resetLeadEngDropdownValue(actionTypes.RESETSELPGURLCAMPANDMEDIUM);
		// props.clearPgSerchedValue(actionTypes.CLEARSEARCHPGURLCAMPAIGNS);
		// props.clearPgSerchedValue(actionTypes.CLEARSEARCHPGURLMEDIUM);
		props.selClientLeadFilterValue(value, actionTypes.SELCLIENTLEADURLSOURCE);
	};

	//URL medium event handlers
	const searchUrlMedium = (e) => {
		if (e.target.value.length > 1) {
			props.fetchUrlParams(
				e.target.value,
				"medium",
				actionTypes.FETCHCLIENTLEADURLMEDIUM,
				props.leadFilterData.selUrlSource,
				"lead"
			);
		} else {
			props.clearClientLeadSerchedValue(
				actionTypes.CLEARSEARCHCLIENTLEADURLMEDIUM
			);
		}
	};
	const onClickUrlMedium = () => {
		if (_.isEmpty(props.leadFilterData.selUrlMedium)) {
			props.clearClientLeadSerchedValue(
				actionTypes.CLEARSEARCHCLIENTLEADURLMEDIUM
			);
		}
	};
	const onSelectUrlMedium = (e, { value }) => {
		props.selClientLeadFilterValue(value, actionTypes.SELCLIENTLEADURLMEDIUM);
	};

	// URL campaign event handlers
	const onClickUrlCampaign = () => {
		if (_.isEmpty(props.leadFilterData.selUrlCampaign)) {
			props.clearClientLeadSerchedValue(
				actionTypes.CLEARSEARCHCLIENTLEADURLCAMPAIGNS
			);
		}
	};

	const searchUrlCampaigns = (e) => {
		if (e.target.value.length > 1) {
			props.fetchUrlParams(
				e.target.value,
				"campaign",
				actionTypes.FETCHCLIENTLEADURLCAMPAIGNS,
				props.leadFilterData.selUrlSource,
				"lead"
			);
		} else {
			props.clearClientLeadSerchedValue(
				actionTypes.CLEARSEARCHCLIENTLEADURLCAMPAIGNS
			);
		}
	};

	const onSelectUrlCampaign = (e, { value }) => {
		props.selClientLeadFilterValue(
			value,
			actionTypes.SELCLIENTLEADURLCAMPAIGNS
		);
	};

	// URL Term event handlers
	const onClickUrlTerm = () => {
		if (_.isEmpty(props.leadFilterData.selUrlTerm)) {
			props.clearClientLeadSerchedValue(
				actionTypes.CLEARSEARCHCLIENTLEADURLTERM
			);
		}
	};

	const searchUrlTerm = (e) => {
		if (e.target.value.length > 1) {
			props.fetchUrlParams(
				e.target.value,
				"term",
				actionTypes.FETCHCLIENTLEADURLTERM,
				props.leadFilterData.selUrlSource,
				"lead"
			);
		} else {
			props.clearClientLeadSerchedValue(
				actionTypes.CLEARSEARCHCLIENTLEADURLTERM
			);
		}
	};

	const onSelectUrlTerm = (e, { value }) => {
		props.selClientLeadFilterValue(value, actionTypes.SELCLIENTLEADURLTERM);
	};

	// URL Content event handlers
	const onClickUrlContent = () => {
		if (_.isEmpty(props.leadFilterData.selUrlContent)) {
			props.clearClientLeadSerchedValue(
				actionTypes.CLEARSEARCHCLIENTLEADURLCONTENT
			);
		}
	};

	const searchUrlContent = (e) => {
		if (e.target.value.length > 1) {
			props.fetchUrlParams(
				e.target.value,
				"content",
				actionTypes.FETCHCLIENTLEADURLCONTENT,
				props.leadFilterData.selUrlSource,
				"lead"
			);
		} else {
			props.clearClientLeadSerchedValue(
				actionTypes.CLEARSEARCHCLIENTLEADURLCONTENT
			);
		}
	};

	const onSelectUrlContent = (e, { value }) => {
		props.selClientLeadFilterValue(value, actionTypes.SELCLIENTLEADURLCONTENT);
	};

	//PageTitlePicker
	// orgValues,
	// 	orders,
	// 	campValues,
	// 	campaignStatus,
	// 	site,
	// 	queryString,
	// 	isLeadClinet,
	// 	assetType,
	// dispatchType;
	// orders: filterData.order;
	const onClickPageTitle = () => {
		if (
			_.isEmpty(
				props.leadFilterData.organization &&
					props.leadFilterData.campaign &&
					props.leadFilterData.order
			)
		) {
			props.clearSearchCampaigns(actionTypes.CLEARSEARCHCLIENTLEADPAGETITLE);
		} else {
			props.fetchTitle(
				props.leadFilterData.organization,
				props.leadFilterData.order,
				props.leadFilterData.campaign,
				props.leadFilterData.campaignStatus,
				props.leadFilterData.site,
				"",
				true,
				props.leadFilterData.assetType,
				actionTypes.FETCHCLIENTLEADPAGETITLE
			);
		}
	};

	const searchPageTitle = (e, { value }) => {
		if (e.target.value.length > 1) {
			props.fetchTitle(
				props.leadFilterData.organization,
				props.leadFilterData.order,
				props.leadFilterData.campaign,
				props.leadFilterData.campaignStatus,
				props.leadFilterData.site,
				e.target.value,
				true,
				props.leadFilterData.assetType,
				actionTypes.FETCHCLIENTLEADPAGETITLE
			);
		}
	};

	const onSelectPageTitle = (e, { value }) => {
		props.addCampaigns(value, actionTypes.ADDCLIENTLEADPAGETITLE);
		props.selClientLeadPageTitle(value);
	};

	const onSelectFilterStatus = (e, { value }) =>{
		const updateState = multipleOptions.filter((name) => (value === "All") ? name.allkey === value : name.keyvalue === value)
		setVerificationStateOptions(updateState);
		props.selFilterState("");
		if (props.leadFilterData.clientLeadFilterStatus !== value) {
			props.selFilterStatus(value);
		}
	}

	const onSelectDeliveryStatus = (e, { value }) => {
		props.selClientLeadState(value);
	};

	const onSelectFilterState = (e, {value}) =>{
		props.selFilterState(value);
	}

	//Pacing filter event handler
	const onSelectPacingFilter = (e, { value }) => {
		props.selPacingFilterValue(value);
	};
	const applyFilters = (startDate, endDate, appDate) => {
		props.isTotalCountShowMore(false);
		props.selShowDropDown(false, actionTypes.SHOWDROPDOWNSTATE);
		props.selShowDropDown(false, actionTypes.SHOWDROPDOWNSTATUS);
		props.selGlobalLeadStatus("Select");
		props.selGlobalVerificationState("Select");
		props.applyClientLeadFilter();
		props.leadFilterData.startDate = startDate;
		props.leadFilterData.endDate = endDate;
		props.leadFilterData.appliedDate = appDate;

		if(props?.roleReports?.some((report) => [LEAD_CAMPAIGN_SUMMARY].includes(report.element) && report.permission === VIEW)) {
			props.fetchLeadEngSummary(
				url.leadEngagementSummaryReport,
				props.leadFilterData,
				1,
				"",
				actionTypes.CLIENTLEADENGSUMMARY,
				true
			);
		}

		if(props?.roleReports?.some((report) => [LEAD_DETAILS].includes(report.element) && report.permission === VIEW)) {
			props.fetchClientLeadEngs(
				props.leadFilterData,
				1,
				"",
				actionTypes.CLIENTLEADENGS,
				"",
				"",
				true
			);
		}
		
		if(props.leadFilterData.selClientLeadChart) {
			props.fetchLeadValidationChart(
				props.leadFilterData,
				1,
				"",
				actionTypes.CLIENTLEADCHART,
				true
			);
		}
		if(props.clientLeadFetchData.leadStatusChart) {
			props.fetchLeadValidationBarChart(
				props.leadFilterData,
				1,
				"",
				actionTypes.CLIENTLEADBARCHART,
				true
			);	
		}
		if(props.clientLeadFetchData.leadAcceptanceChart){
			props.fetchLeadValidationBarChart(
				props.leadFilterData,
				1,
				"",
				actionTypes.LEADACCEPTANCEBARCHART,
				true
			)
		}
			
		
		selShowDropDown(true, actionTypes.SHOWDOWNLOADBUTTON)
		props.selShowDropDown("", actionTypes.SELHEADERDROPDOWNOPTIONVALUE);
		props.selShowDropDown(false, actionTypes.SHOWMORECLICKED)
		props.leadFilterData.showMoreClicked = false
		setFilterPopup("filter-popup-close");
		setFilterIconColor("material-icons grey");
		setFilterSelectFocus("filter-icon");
		setDatePopup("custom-date-popup-close");
	};

	const clearFilters = () => {
		props.resetOrgsOrCampaigns(actionTypes.RESETCLIENTLEADCAMPAIGN);
		props.resetOrgsOrCampaigns(actionTypes.RESETCLIENTLEADORGS);
		props.resetOrder(actionTypes.RESETCLIENTLEADORDER);
		props.clearClientLeadFilter(props.delivery_states);
		props.clearSearchCampaigns(actionTypes.CLEARSEARCHCLIENTLEADPAGETITLE);
		setDateRange({
			selection: {
				startDate: new Date(),
				endDate: new Date(),
				key: "selection",
			},
		});
		setDatePopup("custom-date-popup-close");
		props.selFilterState("");

		const actionTypesToClear = [
			actionTypes.RESETCLIENTLEADORGS,
			actionTypes.RESETCLIENTLEADCAMPAIGN,
			actionTypes.RESETCLIENTLEADORDER,
			actionTypes.RESETCLIENTLEADENGEDORGS,
			actionTypes.RESETCLIENTLEADPAGETITLE,
		  ];
	  
		  actionTypesToClear.forEach((actionType) => {
			props.clearClientLeadSerchedValue(actionType);
		  });
	};

	// const onChangeIncludeParent = (e) => {
	// 	if(props.leadFilterData.clIsIncludeChild){
	// 	  props.selClientLeadToggles(actionTypes.SELCLPARENTTOOGLE);
	// 	  props.selClientLeadToggles(actionTypes.SELCLCHILDTOOGLE);
	// 	} else {
	// 	  props.selClientLeadToggles(actionTypes.SELCLPARENTTOOGLE);
	// 	}
	// }
	
	// const onChangeIncludeChild = (e) => {
	// 	if(props.leadFilterData.clIsIncludeParent){
	// 	  props.selClientLeadToggles(actionTypes.SELCLPARENTTOOGLE);
	// 	  props.selClientLeadToggles(actionTypes.SELCLCHILDTOOGLE);
	// 	} else {
	// 	  props.selClientLeadToggles(actionTypes.SELCLCHILDTOOGLE);
	// 	}
	// }

	return (
		<div>
			<div className="date-picker">
				{/* Date picker */}
				<div>
				<Form>
					<Form.Group widths="equal">
					<DatePicker
						floating
						value={props.leadFilterData.appliedDate}
						onChange={selectDateRange}
						openDateRange={(e, { value }) => {
							if (value === "Custom Date Range") {
								props.applyClientLeadDate("Custom Date Range");
								setDatePopup("custom-date-popup-open");
							}
						}}
					/>
					 <ClientLeadGraph />
				</Form.Group>
				</Form>
							 
				</div>
				{/* Filter icon */}
				<SearchFilterIcon
					filterSelectFocus={filterSelectFocus}
					setFilterSelectFocus={setFilterSelectFocus}
					setFilterPopup={setFilterPopup}
					setFilterIconColor={setFilterIconColor}
					filterIconColor={filterIconColor}
				/>
			</div>
			<div className="popup-component">
				{/* Custom date picker component */}
				<div className={datePopup}>
					<div
						className="date-picker-close-icon"
						onClick={() => {
							setDatePopup("custom-date-popup-close");
						}}
					>
						<i className="material-icons grey">clear</i>
					</div>
					<div>
						<DateRangePicker
							minDate={new Date("2019-12-13")}
							maxDate={new Date()}
							onChange={(item) => {
								setDateRange({ ...dateRange, ...item });
							}}
							months={2}
							moveRangeOnFirstSelection={false}
							direction="horizontal"
							ranges={[dateRange.selection]}
							rangeColors={["#e5582b"]}
						/>
					</div>
					<div className="date-buttons">
						<button type="button" className="cancel-date-button" onClick={cancelDate}>
							Cancel
						</button>
						<button type="button" className="apply-date-button" onClick={applyDate}>
							Apply
						</button>
					</div>
				</div>
				{/* Search filter popup */}
				<div className={filterPopup}>
					<div className="filter-header">
						<div className="filter-text">
							<p>Filters</p>
						</div>
						<div
							className="filter-close-icon"
							onClick={() => {
								setFilterSelectFocus("filter-icon");
								setFilterPopup("filter-popup-close");
							}}
						>
							<i
								className="material-icons grey"
								onClick={() => setFilterIconColor("material-icons grey")}
							>
								clear
							</i>
						</div>
					</div>
					<div className="leads-search-filter">
						<Form className="search-filter-column-1">
							<OrganizationPicker
								onSearchChange={searchOrg}
								value={props.leadFilterData.organization}
								onChange={onSelectOrg}
								onClick={onClickOrg}
								organizations={props.organizations}
							/>
							<StatusPicker
								value={props.leadFilterData.campaignStatus}
								onChange={onSelectCampaignStatus}
							/>
							<DeliveryStatePicker
								value={props.leadFilterData.clientDeliverdFilterState}
								onChange={onSelectDeliveryStatus}
								delivery_states={props.delivery_states}
							/>
							<div style={{width:"24rem", height: "71px"}}></div>
							<TypePicker
								value={props.leadFilterData.assetType}
								onChange={onSelectType}
							/>
							<LeadPartnerPicker
								value={props.leadFilterData.leadPartner}
								onChange={onSelectLeadPartner}
								label="Lead Source"
								placeholder="Lead Source"
							/>
							<SingleSelectDropDown
								onChange={onSelectFilterStatus}
								options={options}
								placeholder="All"
								label="Lead Status"
								value={props.leadFilterData.clientLeadFilterStatus}
							/>
							<SingleSelectDropDown
								label="Verification Status"
								onChange={onSelectFilterState}
								options={verificationStateOptions}
								placeholder={props.leadFilterData.clientLeadFilterStatus === "Received" ? "Received" : "All"}
								value={props.leadFilterData.clientLeadFilterVerificationState}
							/>
							<EngagedOrgPicker
								value={props.leadFilterData.engagedOrganizations}
								onChange={onSelectEngOrg}
								onClick={onClickEngOrg}
								onSearchChange={searchEngOrg}
								organizations={props.engOrg}
							/>
							<CSMSelection
								authors={props.author}
								value={props.leadFilterData.setCSM}
								FETCH={actionTypes.FETCH_LEAD_CSM}
								SELECT={actionTypes.SELECTED_LEAD_TOGGLE}
								ADD={actionTypes.ADD_LEAD_CSM}
								CLEAR={actionTypes.CLEAR_LEAD_AUTHOR}
							/>
							{/* <Toggle
								label="Include Parent"
								// className="inner-child-toggle"
								style={{ paddingLeft: "19px" }}
								onChange={onChangeIncludeParent}
								checked={props.leadFilterData.clIsIncludeParent}
							/>
							<div style={{ marginBottom: "18px", paddingLeft: "19px" }}>
								<Toggle
									label="Include Children"
									// className="inner-child-toggle"
									onChange={onChangeIncludeChild}
									checked={props.leadFilterData.clIsIncludeChild}
								/>
							</div> */}
							<Toggle
								label="Override Report Filters"
								style={{ marginTop: "11px"}}
								onChange={() => {
									props.selClientLeadToggles(actionTypes.SELCLIENTORFTOGGLE);
									props.resetClientToggles(actionTypes.RESETCLIENTORATOGGLE);
								}}
								checked={props.leadFilterData.overrideLeadReportFilters}
							/>
							{props.leadFilterData.overrideLeadReportFilters ? (
								<div className="field">
									<div className="toggle-label">
										<p>Standard</p>
									</div>
									<Toggle
										className="inner-child-toggle"
										label="Account"
										onChange={() =>
											props.selClientLeadToggles(actionTypes.SELCLIENTATOGGLE)
										}
										checked={props.leadFilterData.account}
									/>
									<Toggle
										className="inner-child-toggle"
										label="Vertical"
										onChange={() =>
											props.selClientLeadToggles(actionTypes.SELCLIENTVTOGGLE)
										}
										checked={props.leadFilterData.vertical}
									/>
									<Toggle
										className="inner-child-toggle"
										label="Geo"
										onChange={() =>
											props.selClientLeadToggles(actionTypes.SELCLIENTGTOGGLE)
										}
										checked={props.leadFilterData.geo}
									/>
									<Toggle
										className="inner-child-toggle"
										label="Custom Questions"
										onChange={() =>
											props.selClientLeadToggles(actionTypes.SELCLIENTCQTOGGLE)
										}
										checked={props.leadFilterData.customQuestions}
									/>
									<Toggle
										className="inner-child-toggle"
										label="Email Suppression"
										onChange={() => {
											props.selClientLeadToggles(
												actionTypes.SELCLIENTEESTOGGLE
											);
										}}
										checked={props.leadFilterData.excludeEmailSupression}
									/>
									<Toggle
										label="FirmoGraphic"
										onChange={() => {
											props.selClientLeadToggles(actionTypes.SELCLIENTFTOGGLE);
											props.resetClientToggles(actionTypes.RESETCLIENTFTOGGLE);
										}}
										checked={props.leadFilterData.firmoGraphic}
									/>
									{props.leadFilterData.firmoGraphic ? (
										<div className="firmographic-toggles">
											<Toggle
												className="inner-child-toggle"
												label="Revenue"
												onChange={() =>
													props.selClientLeadToggles(
														actionTypes.SELCLIENTRTOGGLE
													)
												}
												checked={props.leadFilterData.revenue}
											/>
											<Toggle
												className="inner-child-toggle"
												label="Size"
												onChange={() =>
													props.selClientLeadToggles(
														actionTypes.SELCLIENTSIZETOGGLE
													)
												}
												checked={props.leadFilterData.size}
											/>
											<Toggle
												className="inner-child-toggle"
												label="Type"
												onChange={() =>
													props.selClientLeadToggles(
														actionTypes.SELCLIENTTTOGGLE
													)
												}
												checked={props.leadFilterData.types}
											/>
										</div>
									) : (
										""
									)}
									<Toggle
										label="Personas"
										onChange={() => {
											props.selClientLeadToggles(actionTypes.SELCLIENTPTOGGLE);
											props.resetClientToggles(actionTypes.RESETCLIENTPTOGGLE);
										}}
										checked={props.leadFilterData.personas}
									/>
									{props.leadFilterData.personas ? (
										<div className="personas-toggles">
											<Toggle
												className="inner-child-toggle"
												label="Seniority"
												onChange={() => {
													props.selClientLeadToggles(
														actionTypes.SELCLIENTSTOGGLE
													);
												}}
												checked={props.leadFilterData.seniority}
											/>
											<Toggle
												className="inner-child-toggle"
												label="Job Functions"
												onChange={() => {
													props.selClientLeadToggles(
														actionTypes.SELCLIENTJFTOGGLE
													);
												}}
												checked={props.leadFilterData.jobFunctions}
											/>
											<Toggle
												className="inner-child-toggle"
												label="Job Titles"
												onChange={() => {
													props.selClientLeadToggles(
														actionTypes.SELCLIENTJTTOGGLE
													);
												}}
												checked={props.leadFilterData.jobTitles}
											/>
										</div>
									) : (
										""
									)}
								</div>
							) : (
								""
							)}
							<Form.Group widths="equal">
								<CompetitorFilterToggleForOthers
									filterData={props.leadFilterData}
									action={actionTypes.SELECTED_LEAD_TOGGLE}
									style={{ marginTop: "3px" }}
								/>
							</Form.Group>
						</Form>
						<Form className="search-filter-column-2">
							<OrderPicker
								onSearchChange={searchOrder}
								value={props.leadFilterData.order}
								onChange={onSelectOrder}
								onClick={onClickOrder}
								order={props.order}
							/>
							<CampaignPicker
								onSearchChange={searchCampaign}
								value={props.leadFilterData.campaign}
								onChange={onSelectCampaigns}
								campaigns={props.campaigns}
								onClick={onClickCampaign}
							/>
							<PacingFilter
								value={props.leadFilterData.pacingFilter}
								onChange={onSelectPacingFilter}
						 	/>
							<SitePicker
								value={props.leadFilterData.site}
								onChange={onSelectSite}
							/>
							<PageTitlePicker
								onSearchChange={searchPageTitle}
								value={props.leadFilterData.title}
								onChange={onSelectPageTitle}
								onClick={onClickPageTitle}
								titles={props.titles}
							/>
							<DomainFilter 
								value={props.leadFilterData.domainFilter}
								onChange={(e, {value})=> props.clientSelectDropDown(value, actionTypes.SELECTCLIENTDOMAINFILTER) }
							/>
							<PrivacyParametersComponent
								selectValue={props.selectToggleValue}
								leadFilterData={props.leadFilterData}
								action={actionTypes.SELECTED_LEAD_TOGGLE}
								regulationData={props.regulationData}
								FETCH={actionTypes.FETCH_LEAD_REGULATION}
								ADD={actionTypes.ADD_LEAD_REGULATION}
								CLEAR={actionTypes.CLEAR_LEAD_REGULATION}
							/>
							<Toggle 
								label="UTM Parameters"
								onChange={() =>
									props.selClientLeadToggles(actionTypes.SELCLIENTUTMPARAMSTOGGLE)
								}
								checked={props.leadFilterData.utmParams}
								style = {{marginBottom: "10px", marginTop: "30px", paddingLeft: "5px"}}
							/>
							{props.leadFilterData.utmParams && 
							<>
							<UrlParamsPicker
								style={{ width: "24em" }}
								options={props.urlSource?.map((url, index) => ({
									key: index,
									text: url.source,
									value: url.source,
								}))}
								value={props.leadFilterData.selUrlSource}
								label="UTM Source"
								placeholder="UTM Source"
								onClick={onClickUrlSource}
								onChange={onSelectUrlSource}
								onSearchChange={searchUrlSource}
							/>
							<UrlParamsPicker
								options={props.urlMedium?.map((url, index) => ({
									key: index,
									text: url.medium,
									value: url.medium,
								}))}
								value={props.leadFilterData.selUrlMedium}
								label="UTM Medium"
								placeholder=" UTM Medium"
								onClick={onClickUrlMedium}
								onChange={onSelectUrlMedium}
								onSearchChange={searchUrlMedium}
							/>
							<UrlParamsPicker
								options={props.urlCampaign?.map((url, index) => ({
									key: index,
									text: url.campaign,
									value: url.campaign,
								}))}
								value={props.leadFilterData.selUrlCampaign}
								label="UTM Campaign"
								placeholder="UTM Campaign"
								onClick={onClickUrlCampaign}
								onChange={onSelectUrlCampaign}
								onSearchChange={searchUrlCampaigns}
							/>
							<UrlParamsPicker
								label="UTM Term"
								placeholder="UTM Term"
								options={props.urlTerm?.map((url, index) => ({
									key: index,
									text: url.term,
									value: url.term,
								}))}
								value={props.leadFilterData.selUrlTerm}
								onClick={onClickUrlTerm}
								onChange={onSelectUrlTerm}
								onSearchChange={searchUrlTerm}
							/>
							<UrlParamsPicker
								options={props.urlContent?.map((url, index) => ({
									key: index,
									text: url.content,
									value: url.content,
								}))}
								value={props.leadFilterData.selUrlContent}
								label="UTM Content"
								placeholder="UTM Content"
								onClick={onClickUrlContent}
								onChange={onSelectUrlContent}
								onSearchChange={searchUrlContent}
							/>
							</>
							}
							<div className="toggle-label" style={{ paddingTop: "20px" }}>
								<p>Campaign Filters</p>
							</div>
							<Toggle
								label="Exclude Internal Campaign"
								onChange={() => {
									props.selClientLeadToggles(actionTypes.SELCLIENTINTOGGLE);
								}}
								checked={props.leadFilterData.excludeInternalCampaign}
							/>
							<Toggle
								label="Exclude QA Campaigns"
								onChange={() => {
									props.selClientLeadToggles(actionTypes.SELCLIENTQATOGGLE);
								}}
								checked={props.leadFilterData.excludeQACampaign}
							/>
							<div className="toggle-label">
								<p>Event Filters</p>
							</div>
							<Toggle
								label="Exclude Internal Events"
								onChange={() =>
									props.selClientLeadToggles(actionTypes.SELCLIENTEIETOGGLE)
								}
								checked={props.leadFilterData.excludeInternalEvents}
							/>
							<Toggle
								label="Exclude Organization Events"
								onChange={() =>
									props.selClientLeadToggles(actionTypes.SELCLIENTEOETOGGLE)
								}
								checked={props.leadFilterData.excludeOrganizationEvents}
							/>
							<Toggle
								label="Exclude Free Email Events"
								onChange={() =>
									props.selClientLeadToggles(actionTypes.SELCLIENTEFEETOGGLE)
								}
								checked={props.leadFilterData.excludeFreeEmailEvents}
							/>
							<Toggle
								label="Exclude Competitor Events"
								onChange={() => {
									props.selClientLeadToggles(actionTypes.SELCLIENTECTOGGLE);
								}}
								checked={props.leadFilterData.excludeCompetitorEvents}
							/>
							{/* <Toggle
								label="Organization Limits"
								onChange={() => {
									props.selClientLeadToggles(actionTypes.SELCLIENTOLTOGGLE);
								}}
								checked={props.leadFilterData.organizationLimits}
							/> */}
						</Form>
					</div>
					<div className="filter-buttons">
						<button className="cancel-date-button extra-space" onClick={clearFilters}>
							Clear Filters
						</button>
						<button
							type="submit"
							className="apply-filter-button"
							onClick={() =>
								applyFilters(
									props.leadFilterData.startDate,
									props.leadFilterData.endDate,
									props.leadFilterData.appliedDate
								)
							}
						>
							Apply Filters
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		order: Object.values(state.clientLeadOrder),
		campaigns: Object.values(state.clientLeadCampaigns),
		organizations: Object.values(state.clientLeadOrganizations),
		titles: Object.values(state.clinetLeadPageTitle),
		engOrg: Object.values(state.clientLeadEngOrgs),
		leadFilterData: state.clientLeadFilterData,
		isFilApplied: state.clientLeadFetchData.isFilApplied,
		urlSource: Object.values(state.clientLeadUrlSource),
		urlMedium: Object.values(state.clientLeadUrlMedium),
		urlCampaign: Object.values(state.clientLeadUrlCampaign),
		urlTerm: Object.values(state.clientLeadUrlTerm),
		urlContent: Object.values(state.clientLeadUrlContent),
		leadEngSummary: state.clientLeadFetchData.leadEngSummary,
		leadEngs: state.clientLeadFetchData.leadEngs,
		delivery_states: state.clientLeadFilterData.clientDeliverdFilterStateOptions,
		clientLeadFetchData: state.clientLeadFetchData,
		author: Object.values(state.leadAuthor),
		regulationData: Object.values(state.reguationManager),
	};
};

export default compose(
	connect(mapStateToProps, {
		fetchOrganizations,
		fetchTitle,
		fetchOrder,
		addOrganization,
		addCampaigns,
		addOrder,
		fetchCampaigns,
		resetOrgsOrCampaigns,
		resetOrder,
		clearSearchOrgs,
		clearSearchCampaigns,
		clearSearchOrder,
		selClientLeadStartDate,
		selClientLeadEndDate,
		applyClientLeadDate,
		selClientLeadPageTitle,
		selClientLeadOrgs,
		selClientLeadOrder,
		selClientLeadCampaigns,
		selClientLeadEngOrgs,
		selClientLeadStatus,
		selFilterStatus,
		selFilterState,
		selClientLeadSite,
		selClientLeadType,
		selClientLeadToggles,
		selClientLeadPartner,
		applyClientLeadFilter,
		clearClientLeadFilter,
		fetchLeadEngSummary,
		fetchClientLeadEngs,
		//fetchLeadEngCampaignSummary,
		prevClientAppliedDate,
		prevClientSelStartDate,
		prevClientSelEndDate,
		resetClientToggles,
		fetchUrlParams,
		selClientLeadFilterValue,
		clearClientLeadSerchedValue,
		applyDateState,
		selShowDropDown,
		selGlobalLeadStatus,
		selGlobalVerificationState,
		fetchLeadValidationChart,
		fetchLeadValidationBarChart,
		selClientLeadState,
		selPacingFilterValue,
		fetchEngagedOrg,
		isTotalCountShowMore,
		clientSelectDropDown,
		selectToggleValue
	})
)(SearchFilter);
