import axios from "../api/axios";
import { url } from "../api/urlHelper";
import * as actionTypes from "./actionTypes";
import { headers } from "../api/apiConstants";
import { getCookie, removeCookies } from "../utilities/envCommon";
import { getSelectedIds, sanitizePageviewRequestTag } from "../utilities/common";
import _ from "lodash"
import { getApiDate } from "../utilities/common";
import * as constName from "../components/config/const";

export const fetchOrganizations =
  (excludeQACampaign, queryString, dispatchType, engOrg = "", organizationId, reportType = "") =>
  async (dispatch) => {
    let hasCampaigns = true;
    let includeDeleted = false;

    if (engOrg) {
      hasCampaigns = false;
      includeDeleted = true;
    }
    
    await axios
      .post(
        url.searchOrganization,
        {
          q: queryString,
          order: "ASC",
          order_by: "Name",
          has_campaigns: hasCampaigns,
          include_deleted: includeDeleted,
          limit: 20,
          page: 1,
          exclude_qa: excludeQACampaign,
          organizations: organizationId,
          // include_parent_organization: parentValue ? parentValue : false,
          // include_child_organization: childValue ? childValue : false
        },
        {
          headers: headers(),
        }
      )
      .then((res) => {
        dispatch({
          type: dispatchType,
          payload: res.data,
          reportType
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: actionTypes.ERRORCODE,
            payload: err.response.status,
          });
        }
      });
  };
export const fetchEngagedOrg =
  (excludeQACampaign, queryString, dispatchType, engOrg = "", organizationId, parentVal, childVal, reportType = "") =>
  async (dispatch) => {
    let hasCampaigns = true;
    let includeDeleted = false;
    let orgArr = []

    if (engOrg) {
      hasCampaigns = false;
      includeDeleted = true;
    }
    
    if(_.size(organizationId) > 0){
      organizationId && organizationId.map((data) => {
        return orgArr.push(data.uuid)
      })
    }
    await axios
      .post(
        url.engagedOrganization,
        {
          q: queryString,
          order: "ASC",
          order_by: "Name",
          has_campaigns: hasCampaigns,
          include_deleted: includeDeleted,
          limit: 20,
          page: 1,
          exclude_qa: excludeQACampaign,
          organizations: organizationId,
          include_parent_organization: parentVal ? parentVal : false,
          include_child_organization: childVal ? childVal : false
        },
        {
          headers: headers(),
        }
      )
      .then((res) => {
        dispatch({
          type: dispatchType,
          payload: res.data,
          reportType,
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: actionTypes.ERRORCODE,
            payload: err.response.status,
          });
        }
      });
  };

const pathReportTypeMap = {
  "/ads-sidebar": constName.MODULE_SIDEBAR_ADS,
  "/ads-display": constName.MODULE_DISPLAY_ADS,
  "/ads-content-body": constName.MODULE_CONTENT_BODY_ADS,
  "/ads-interstitial": constName.MODULE_INTERSTITIAL_ADS,
  "/ads-newsletter": constName.MODULE_NEWSLETTER_ADS,
  "/ads-roadblock": constName.MODULE_ROADBLOCK_ADS,
  "/ads-text": constName.MODULE_TEXT_ADS,
  "/ads-announcement": constName.MODULE_ANNOUNCEMENT_ADS,
  "/ads-atf-homepage": constName.MODULE_ATF_HOMEPAGE,
  "/ads-instant-message": constName.MODULE_INSTANT_MESSAGE,
  "/ads-newsletter-body": constName.MODULE_NEWSLETTER_BODY,
  "/reports/newsletter/summary": constName.MODULE_NEWSLETTERS,
  "/ads": constName.MODULE_ALL_ADS_REPORT,
};

export const fetchappliedFilter = (sessionId) => async (dispatch) => {
  await axios
    .post(
      url.appliedfilter,
      { session_id: sessionId },
      {
        headers: headers(),
      }
    )
    .then((res) => {
      res.data.forEach((element) => {
        const reportActionType = pathReportTypeMap[element.url];
        if (reportActionType) {
          dispatchActions(dispatch, element, reportActionType);
        } 

        switch (element.url) {
          case "/reports/activity/details":
            const diDataFilter = getCookie("di_filter_data");
            dispatch({
              type: actionTypes.FETCHACTIVITYFILTER,
              payload: element.search_query,
              tags: element.tags,
            });
            if(!diDataFilter) {
              dispatch({
                type: actionTypes.SELINIACTIVITYENGORGS,
                data: element?.engaged_organizations ?? [],
              });
              dispatch({
                type: actionTypes.SELINIACTIVITYORDER,
                data: element?.orders ?? [],
              });
              dispatch({
                type: actionTypes.SELINIACTIVITYORGS,
                data: element?.organizations ?? [],
              });
              dispatch({
                type: actionTypes.SELINIACTIVITYCAMPAIGNS,
                data: element?.campaigns ?? [],
              });
              dispatch({
                type: actionTypes.SELINIACTIVITYENGAGEDMEMBER,
                data: element?.engaged_members ?? [],
              });
              dispatch({
                type: actionTypes.SELINIACTIVITYPAGETITLE,
                data: element?.page_title ?? [],
              });
              dispatch({
                type: actionTypes.SELINIACTIVITYTOPICTAGSIN,
                data: getSelectedIds(element?.tags, element.search_query.topic_tags.included_tags),
              });
              dispatch({
                type: actionTypes.SELINIACTIVITYTOPICTAGSEX,
                data: getSelectedIds(element?.tags, element.search_query.topic_tags.excluded_tags),
              });
  
              dispatch({
                type: actionTypes.SELINIACTIVITYPLACEMENTTAGSIN,
                data: getSelectedIds(element?.tags, element.search_query.placement_tags.included_tags),
              });
              dispatch({
                type: actionTypes.SELINIACTIVITYPLACEMENTTAGSEX,
                data: getSelectedIds(element?.tags, element.search_query.placement_tags.excluded_tags),
              });
  
              dispatch({
                type: actionTypes.SELINIACTIVITYORGANIZATIONTAGSIN,
                data: getSelectedIds(element?.tags, element.search_query.organization_tags.included_tags),
              });
              dispatch({
                type: actionTypes.SELINIACTIVITYORGANIZATIONTAGSEX,
                data: getSelectedIds(element?.tags, element.search_query.organization_tags.excluded_tags),
              });
            }
            break;

          case "/leadengagements/search":
            dispatch({
              type: actionTypes.FETCHINILEADFILTER,
              payload: element.search_query,
            });
            dispatch({
              type: actionTypes.SELINILEADCAMPAIGNS,
              campaigns: element.campaigns ? element.campaigns : [],
            });
            dispatch({
              type: actionTypes.SELINILEADORGS,
              organizations: element.organizations ? element.organizations : [],
            });
            dispatch({
              type: actionTypes.SELINILEADENGORDER,
              order: element.orders ? element.orders : [],
            });
            dispatch({
              type: actionTypes.SELINILEADENGEDORGS,
              engaged_organizations: element.engaged_organizations
                ? element.engaged_organizations
                : [],
            });
            dispatch({
              type: actionTypes.SELINILEADPAGETITLE,
              page_title: element.page_title ? element.page_title : [],
            });
            dispatch({
              type: actionTypes.SELINILEADENGUTMSOURCE,
              utm_source: element.utm_source ? element.utm_source : [],
            });
            dispatch({
              type: actionTypes.SELINILEADENGUTMMEDIUM,
              utm_medium: element.utm_medium ? element.utm_medium : [],
            });
            dispatch({
              type: actionTypes.SELINILEADENGUTMCAMPAIGN,
              utm_campaign: element.utm_campaign ? element.utm_campaign : [],
            });
            dispatch({
              type: actionTypes.SELINILEADENGUTMTERM,
              utm_term: element.utm_term ? element.utm_term : [],
            });
            dispatch({
              type: actionTypes.SELINILEADENGUTMCONTENT,
              utm_content: element.utm_content ? element.utm_content : [],
            });
            dispatch({
              type: actionTypes.SELENGAGEMENTDELIVERYSTATE,
              payload: element.search_query.delivery_state
                ? element.search_query.delivery_state
                : "",
            });
            dispatch({
              type: actionTypes.SELECT_ENG_CSM,
              data: element.csm ? element.csm : [],
            });
            dispatch({
              type: actionTypes.SEL_ENG_REGULATION,
              data: element.regulation_names ? element.regulation_names : [],
            });
            break;

          case "/reports/sidebar/detail":
            dispatch({
              type: actionTypes.FETCHINITOPICFILTER,
              payload: element.search_query,
            });
            dispatch({
              type: actionTypes.SELINITOPICCAMPAIGNS,
              campaigns: element.campaigns ? element.campaigns : [],
            });
            dispatch({
              type: actionTypes.SELINITOPICORDER,
              order: element.orders ? element.orders : [],
            });
            dispatch({
              type: actionTypes.SELINITOPICORGS,
              organizations: element.organizations ? element.organizations : [],
            });
            dispatch({
              type: actionTypes.SELINITOPICENGORGS,
              engaged_organizations: element.engaged_organizations
                ? element.engaged_organizations
                : [],
            });
            dispatch({
              type: actionTypes.SELINITOPICCOUNTRY,
              country: element.country ? element.country : [],
            });
            dispatch({
              type: actionTypes.SELINISIDEBARSECTOR,
              sector: element.sector ? element.sector : [],
            });
            dispatch({
              type: actionTypes.SELINISIDEBARINDUSTRYGROUP,
              industry_group: element.industry_group
                ? element.industry_group
                : [],
            });
            dispatch({
              type: actionTypes.SELINISIDEBARINDUSTRY,
              industry: element.industry ? element.industry : [],
            });
            dispatch({
              type: actionTypes.SELINISIDEBARSUBINDUSTRY,
              sub_industry: element.sub_industry ? element.sub_industry : [],
            });
            dispatch({
              type: actionTypes.SELINISIDEBARORGSTYPE,
              organization_types: element.organization_types
                ? element.organization_types
                : [],
            });
            dispatch({
              type: actionTypes.SELINISIDEBARORGSSIZE,
              organization_sizes: element.organization_sizes
                ? element.organization_sizes
                : [],
            });
            dispatch({
              type: actionTypes.SELINISIDEBARORGREVENUE,
              estimated_revenues: element.estimated_revenues
                ? element.estimated_revenues
                : [],
            });
            dispatch({
              type: actionTypes.SELINISIDEBARSENORITIESIN,
              senoritiesIn: element.seniorities_included
                ? element.seniorities_included
                : [],
            });
            dispatch({
              type: actionTypes.SELINISIDEBARSENORITIESEX,
              senoritiesEx: element.seniorities_excluded
                ? element.seniorities_excluded
                : [],
            });
            dispatch({
              type: actionTypes.SELINISIDEBARJOBFUNCTIONIN,
              jobFunctionIn: element.job_functions_included
                ? element.job_functions_included
                : [],
            });
            dispatch({
              type: actionTypes.SELINISIDEBARJOBFUNCTIONEX,
              jobFunctionEx: element.job_functions_excluded
                ? element.job_functions_excluded
                : [],
            });
            dispatch({
              type: actionTypes.SELINISIDEBARENGAGEDMEMBER,
              engaged_members: element.engaged_members
                ? element.engaged_members
                : [],
            });
            dispatch({
              type: actionTypes.SELECT_SPONSORED_CSM,
              data: element.csm ? element.csm : [],
            });

            break;
          
          case "/lead_engagement_client/search":
            dispatch({
              type: actionTypes.FETCHINICLIENTLEADFILTER,
              payload: element.search_query,
            });
            dispatch({
              type: actionTypes.SELINICLIENTLEADCAMPAIGNS,
              campaigns: element.campaigns ? element.campaigns : [],
            });
            dispatch({
              type: actionTypes.SELINICLIENTLEADORGS,
              organizations: element.organizations ? element.organizations : [],
            });
            dispatch({
              type: actionTypes.SELINICLIENTLEADORDER,
              order: element.orders ? element.orders : [],
            });
            dispatch({
              type: actionTypes.SELINICLIENTLEADENGEDORGS,
              engaged_organizations: element.engaged_organizations
                ? element.engaged_organizations
                : [],
            });
            dispatch({
              type: actionTypes.SELINICLIENTLEADPAGETITLE,
              page_title: element.page_title ? element.page_title : [],
            });
            dispatch({
              type: actionTypes.SELINICLIENTLEADENGUTMSOURCE,
              utm_source: element.utm_source ? element.utm_source : [],
            });
            dispatch({
              type: actionTypes.SELINICLIENTLEADENGUTMMEDIUM,
              utm_medium: element.utm_medium ? element.utm_medium : [],
            });
            dispatch({
              type: actionTypes.SELINICLIENTLEADENGUTMCAMPAIGN,
              utm_campaign: element.utm_campaign ? element.utm_campaign : [],
            });
            dispatch({
              type: actionTypes.SELINICLIENTLEADENGUTMTERM,
              utm_term: element.utm_term ? element.utm_term : [],
            });
            dispatch({
              type: actionTypes.SELINICLIENTLEADENGUTMCONTENT,
              utm_content: element.utm_content ? element.utm_content : [],
            });
            dispatch({
              type: actionTypes.SELDELIVERYSTATE,
              payload: element.search_query.delivery_state
                ? element.search_query.delivery_state
                : "",
            });
            dispatch({
              type: actionTypes.SELECT_LEAD_CSM,
              data: element.csm ? element.csm : [],
            });
            dispatch({
              type: actionTypes.SEL_LEAD_REGULATION,
              data: element?.regulations ? element.regulations : [],
            });

            break;
          
          default:
        }
      });

    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: actionTypes.ERRORCODE, payload: err.response.status });
      }
    });
};

const dispatchActions = (dispatch, element, reportActionType) => {
  dispatch({
    type: actionTypes[reportActionType].FETCH_INITIAL_DATA,
    payload: element.search_query,
    tag: element.tags_included,
    tagEx: element.tags_excluded,
    reportType: reportActionType,
  });

  const actionsToDispatch = [
    { type: actionTypes[reportActionType].SELECT_CAMPAIGNS, data: element.campaigns ? element.campaigns : [] },
    { type: actionTypes[reportActionType].SELECT_ORDERS, data: element.orders ? element.orders : [] },
    { type: actionTypes[reportActionType].SELECT_AD_ORDERS, data: element.ads ? element.ads : [] },
    { type: actionTypes[reportActionType].SELECT_ORGANIZATIONS, data: element.organizations ? element.organizations : [] },
    { type: actionTypes[reportActionType].SELECT_ENG_ORGANIZATIONS, data: element.engaged_organizations ? element.engaged_organizations : [] },
    { type: actionTypes[reportActionType].SELECT_ENG_MEMBERS, data: element.engaged_members ? element.engaged_members : [] },
    { type: actionTypes[reportActionType].SELECT_COUNTRIES, data: element.country ? element.country : [] },
    { type: actionTypes[reportActionType].SELECT_ORGANIZATION_SIZES, data: element.organization_sizes ? element.organization_sizes : [] },
    { type: actionTypes[reportActionType].SELECT_ORGANIZATION_TYPES, data: element.organization_types ? element.organization_types : [] },
    { type: actionTypes[reportActionType].SELECT_ORGANIZATION_REVENUES, data: element.estimated_revenues ? element.estimated_revenues : [] },
    { type: actionTypes[reportActionType].SELECT_SECTORS, data: element.sector ? element.sector : [] },
    { type: actionTypes[reportActionType].SELECT_INDUSTRY_GROUPS, data: element.industry_group ? element.industry_group : [] },
    { type: actionTypes[reportActionType].SELECT_INDUSTRIES, data: element.industry ? element.industry : [] },
    { type: actionTypes[reportActionType].SELECT_SUB_INDUSTRIES, data: element.sub_industry ? element.sub_industry : [] },
    { type: actionTypes[reportActionType].SELECT_SENORITY_IN, data: element.seniorities_included ? element.seniorities_included : [] },
    { type: actionTypes[reportActionType].SELECT_SENORITY_EX, data: element.seniorities_excluded ? element.seniorities_excluded : [] },
    { type: actionTypes[reportActionType].SELECT_JOB_FUNCTION_IN, data: element.job_functions_included ? element.job_functions_included : [] },
    { type: actionTypes[reportActionType].SELECT_JOB_FUNCTION_EX, data: element.job_functions_excluded ? element.job_functions_excluded : [] },
    { type: actionTypes[reportActionType].SELECT_AD_UNIT, data: element.ad_unit ? element.ad_unit : [] },
    { type: actionTypes[reportActionType].SELECT_NEWSLETTERS_AUTHORS, data: element.authors ? element.authors : [] },
    { type: actionTypes[reportActionType].SELECT_CSM, data: element.csm ? element.csm : [] }
  ];

  actionsToDispatch.forEach(action => {
    dispatch({
      ...action,
      reportType: pathReportTypeMap[element.url]
    });
  });
};


export const fetchOrder =
  (queryString, sortBy, dispatchType, reset = false, resetType, reportType = "", uuids =[]) =>
  async (dispatch) => {
    let order = "ASC";
    if (sortBy === "start_date") {
      order = "DESC";
    }
    let includeDeleted = false;
    await axios
      .post(
        url.searchOrders,
        {
          q: queryString,
          include_deleted: includeDeleted,
          order: order,
          order_by: sortBy,
          limit: 20,
          page: 1,
          uuids,
        },
        {
          headers: headers(),
        }
      )
      .then((res) => {
        dispatch({
          type: dispatchType,
          payload: res.data,
          reportType
        });
        if (reset) {
          let orderIds = [];
          if (res.data) {
            res.data.forEach((order) => {
              orderIds.push(order.uuid);
            });
          } else {
            orderIds = [];
          }
          dispatch({
            type: resetType,
            payload: orderIds,
            reportType
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: actionTypes.ERRORCODE,
            payload: err.response.status,
          });
        }
      });
  };

export const fetchAdorders =
  (
    filterData,
    queryString,
    sortBy,
    dispatchType,
    reset = false,
    resetType,
    reportType,
    reportT = "",
  ) =>
  async (dispatch) => {
    let order = "ASC";
    if (sortBy === "start_date") {
      order = "DESC";
    }
    let includeDeleted = false;
    let locations = [];

    if (reportType) {
      locations.push();
    } else {
      if (filterData.location?.length !== 0) {
        locations.push(filterData.location);
      }
    }
    let adstatus = [];
    if (filterData.adorderStatus !== "all") {
      adstatus.push(filterData.adorderStatus);
      if (adstatus[0] === "") {
        adstatus.pop();
      }
    }
    if (filterData.site === "all") {
      filterData.site = "";
    }
    await axios
      .post(
        url.searchAdOrders,
        {
          q: queryString,
          ad_statuses: adstatus,
          organization_uuids: filterData.organization,
          campaign_uuids: filterData.campaign,
          locations: locations,
          include_deleted: includeDeleted,
          include_qa: filterData.excludeQACampaign,
          order: order,
          order_by: sortBy,
          limit: 20,
          page: 1,
          site: filterData.site,
          included_tags: filterData.selTagsIN,
          excluded_tags: filterData.selTagsEX,
          ad_type: filterData.adType,
          type: reportType,
        },
        {
          headers: headers(),
        }
      )
      .then((res) => {
        dispatch({
          type: dispatchType,
          payload: res.data,
          reportType: reportT,
        });
        if (reset) {
          let adorderIds = [];
          if (res.data) {
            res.data.forEach((adorder) => {
              adorderIds.push(adorder.uuid);
            });
          } else {
            adorderIds = [];
          }
          dispatch({
            type: resetType,
            payload: adorderIds,
            reportType: reportT
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: actionTypes.ERRORCODE,
            payload: err.response.status,
          });
        }
      });
  };

export const fetchCountry = (queryString, dispatchType, reportType = "") => async (dispatch) => {
  await axios
    .post(
      url.searchCountry,
      {
        q: queryString,
        limit: 20,
        page: 1,
      },
      {
        headers: headers(),
      }
    )
    .then((res) => {
      dispatch({
        type: dispatchType,
        payload: res.data,
        reportType
      });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: actionTypes.ERRORCODE, payload: err.response.status });
      }
    });
};

export const fetchCampaigns =
  (
    isPaid,
    site,
    selTagsIN,
    selTagsEX,
    excludeQACampaign,
    queryString,
    orgValues,
    campaignStatus,
    //assetType,
    sortBy,
    dispatchType,
    campValues = [],
    reset = false,
    resetType,
    reportType = "",
  ) =>
  async (dispatch) => {
    let order = "ASC";
    if (sortBy === "start_date") {
      order = "DESC";
    }
    let includeDeleted = false;
    let status = campaignStatus;
    if (
      campaignStatus === "all"
    ) {
      status = "";
      includeDeleted = true;
    } else if (campaignStatus === "archived") {
      status = "archived";
    }
    let is_Paid = false;
    if (isPaid) {
      is_Paid = true;
    }
    if (site === "all") {
      site = "";
    }
    // let type = assetType
    // if(assetType === "all"){
    //   type = ""
    // }
    await axios
      .post(
        url.searchCampaigns,
        {
          q: queryString,
          organizations: orgValues,
          campaigns: campValues,
          status: status,
          //asset_type: type,
          order: order,
          order_by: sortBy,
          include_deleted: includeDeleted,
          limit: 20,
          page: 1,
          exclude_qa: excludeQACampaign,
          site: site,
          included_tags: sanitizePageviewRequestTag(selTagsIN),
          excluded_tags: sanitizePageviewRequestTag(selTagsEX),
          is_paid: is_Paid,
        },
        {
          headers: headers(),
        }
      )
      .then((res) => {
        dispatch({
          type: dispatchType,
          payload: res.data,
          reportType,
        });
        if (reset) {
          let campaignIds = [];
          if (res.data) {
            res.data.forEach((campaign) => {
              campaignIds.push(campaign.uuid);
            });
          } else {
            campaignIds = [];
          }
          dispatch({
            type: resetType,
            payload: campaignIds,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: actionTypes.ERRORCODE,
            payload: err.response.status,
          });
        }
      });
  };

export const fetchTitle =
  (
    orgValues,
    orders,
    campValues,
    campaignStatus,
    site,
    queryString,
    isLeadClinet,
    assetType,
    dispatchType
  ) =>
  async (dispatch) => {
    let includeDeleted = false;
    let status = campaignStatus;
    if (campaignStatus === "all") {
      status = "";
      includeDeleted = true;
    } else if (campaignStatus === "archived") {
      status = "archived";
    }
    if (site === "all") {
      site = "";
    }
    let type = assetType;
    if (assetType === "all") {
      type = "";
    }
    await axios
      .post(
        url.searchPageTitles,
        {
          q: queryString,
          organizations: orgValues,
          campaigns: campValues,
          status: status,
          asset_type: type,
          orders: orders,
          Is_lead_client: isLeadClinet,
          include_deleted: includeDeleted,
          limit: 20,
          page: 1,
          site: site,
        },
        {
          headers: headers(),
        }
      )
      .then((res) => {
        dispatch({
          type: dispatchType,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: actionTypes.ERRORCODE,
            payload: err.response.status,
          });
        }
      });
  };
export const addOrganization = (id, dispatchType) => {
  return {
    type: dispatchType,
    payload: id,
  };
};

export const adUnit = (id, dispatchType) => {
  return {
    type: dispatchType,
    payload: id,
  };
};

export const clearCommonSerchedValue = (dispatchType) => {
  return {
    type: dispatchType,
  };
};

export const addOrder = (id, dispatchType) => {
  return {
    type: dispatchType,
    payload: id,
  };
};

export const addCampaigns = (id, dispatchType) => {
  return {
    type: dispatchType,
    payload: id,
  };
};

export const addAdorders = (id, dispatchType) => {
  return {
    type: dispatchType,
    payload: id,
  };
};

export const resetOrgsOrCampaigns = (dispatchType) => {
  return {
    type: dispatchType,
  };
};

export const resetOrder = (dispatchType) => {
  return {
    type: dispatchType,
  };
};

export const resetCampaignsOrAdorder = (dispatchType) => {
  return {
    type: dispatchType,
  };
};

export const clearSearchOrgs = (dispatchType) => {
  return {
    type: dispatchType,
  };
};

export const clearSearchOrder = (dispatchType) => {
  return {
    type: dispatchType,
  };
};

export const clearSearchCampaigns = (dispatchType) => {
  return {
    type: dispatchType,
  };
};

export const clearSearchAdorders = (dispatchType) => {
  return {
    type: dispatchType,
  };
};

export const heartbeat = () => async (dispatch) => {
  await axios
    .get(url.heartbeat, {
      headers: headers(),
    })
    .then((res) => {
      if (res.status === 401) {
        removeCookies();
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: actionTypes.ERRORCODE, payload: err.response.status });
      }
    });
};

export const updateLeadValidation =
  (checkBoxIds, verificationStatus, leadState, dispatchType, filterData, optionValue, isLeadClient) =>
  async (dispatch) => {
    let leadStateFilter = filterData?.clientLeadFilterVerificationState;
    if (
      filterData?.clientLeadFilterVerificationState === "All" ||
      filterData?.clientLeadFilterVerificationState === "all"
    ) {
      leadStateFilter = "";
    }
    let verificationStatusFilter = filterData?.clientLeadFilterStatus;
    if (
      filterData?.clientLeadFilterStatus === "All" ||
      filterData?.clientLeadFilterStatus === "all"
    ) {
      verificationStatusFilter = "";
    }
    let siteValue = filterData?.site;
    if (filterData?.site === "all") {
      siteValue = "";
    }
    let assetType = filterData?.assetType;
    if (filterData?.assetType === "all") {
      assetType = "";
    }
    let status = filterData?.campaignStatus;
    let IncludeDeleted = false;
    if (filterData?.campaignStatus === "all") {
      status = "";
      IncludeDeleted = true;
    } else if (filterData?.campaignStatus === "archived") {
      status = "archived";
    }
    let report = "engagement";
    if (isLeadClient) {
      report = "lead";
    }

    const payloadArrData = {
      organizations: filterData?.organization,
      campaigns: filterData?.campaign,
      orders: filterData?.order,
      engaged_organizations: filterData?.engagedOrganizations,
      lead_partner: filterData?.leadPartner,
      site: siteValue,
      status: status,
      asset_type: assetType,
      start_date: getApiDate(filterData?.startDate).concat(" 00:00:00"),
      end_date: getApiDate(filterData?.endDate).concat(" 23:59:59"),
      override_lead_report_filters: filterData?.overrideLeadReportFilters,
      exclude_internal_events: filterData?.excludeInternalEvents,
      exclude_organization_events: filterData?.excludeOrganizationEvents,
      exclude_competitors: filterData?.excludeCompetitorEvents,
      exclude_free_email_registrations: filterData?.excludeFreeEmailEvents,
      // SDSFT-1105 fix
      // override_campaign_report_setup:
      //   filterData?.overrideReportingAttributes,
      order: "ASC",
      order_by: "email",
      include_deleted: IncludeDeleted,
      limit: 50,
      Is_lead_client: isLeadClient,
      applied_date_filter: filterData?.appliedDate,
      exclude_qa: filterData?.excludeQACampaign,
      exclude_internal: filterData?.excludeInternalCampaign,
      override_account_filters: filterData?.account,
      override_vertical_filters: filterData?.vertical,
      override_geo_filters: filterData?.geo,
      override_custom_questions: filterData?.customQuestions,
      override_firmographic_filters: filterData?.firmoGraphic,
      override_organization_types_filters: filterData?.types,
      override_organization_sizes_filters: filterData?.size,
      override_estimate_revenues_filters: filterData?.revenue,
      override_personas_filters: filterData?.personas,
      override_seniority_filters: filterData?.seniority,
      override_job_functions_filters: filterData?.jobFunctions,
      override_job_titles_filters: filterData?.jobTitles,
      exclude_email_suppression: filterData?.excludeEmailSupression,
      page_title: filterData?.title,
      utm_source: filterData?.selUrlSource,
      utm_medium: filterData?.selUrlMedium,
      utm_campaign: filterData?.selUrlCampaign,
      utm_term: filterData?.selUrlTerm,
      utm_content: filterData?.selUrlContent,
      report: report,
      verification_state: leadStateFilter,
      verification_status: verificationStatusFilter,
      verification_state_to_be_updated: leadState,
      verification_status_to_be_updated: verificationStatus,
      email: filterData?.email,
      delivery_state: (filterData?.clientDeliverdFilterState)?.trim(),
      item_ids: checkBoxIds,
      consider_all_leads: optionValue === "all" ? true : false
    }
    let finalArr = {
      item_ids: checkBoxIds,
      verification_state: leadStateFilter,
      verification_status: verificationStatusFilter,
      verification_state_to_be_updated: leadState,
      verification_status_to_be_updated: verificationStatus,
      consider_all_leads: optionValue === "all" ? true : false,
      Is_lead_client: isLeadClient
    }
    if(optionValue === "all"){
      finalArr = payloadArrData
    } 
    await axios
      .post(
        url.leadValidationUpdate,
        finalArr,
        {
          headers: headers(),
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: dispatchType,
            payload: true,
            messageBox: false
          });
          setTimeout(() => {
            dispatch({
              type: dispatchType,
              payload: false,
            });
          }, 3000);
        } else if (res.status !== 200) {
          dispatch({
            type: dispatchType,
            payload: "",
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: dispatchType,
            payload: "",
          });
          setTimeout(() => {
            dispatch({
              type: dispatchType,
              payload: false,
            });
          }, 3000);
        }
      });
  };

export const addCountries = (id, dispatchType) => {
  return {
    type: dispatchType,
    payload: id,
  };
};

export const fetchEngagedMember =
  (queryString, dispatchType, reportType = "") => async (dispatch) => {
    await axios
      .post(
        url.engagedMembers,
        {
          q: queryString,
          limit: 20,
          page: 1,
        },
        {
          headers: headers(),
        }
      )
      .then((res) => {
        dispatch({
          type: dispatchType,
          payload: res.data,
          reportType
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: actionTypes.ERRORCODE,
            payload: err.response.status,
          });
        }
      });
  };

export const fetchPrimaryTopic =
  (queryString, dispatchType, reportType = "") => async (dispatch) => {
    await axios
      .post(
        url.primaryTopic,
        {
          q: queryString,
          limit: 20,
          page: 1,
        },
        {
          headers: headers(),
        }
      )
      .then((res) => {
        dispatch({
          type: dispatchType,
          payload: res.data,
          reportType,
        });
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: actionTypes.ERRORCODE,
            payload: err.response.status,
          });
        }
      });
  };

export const getCSMAuthors = (queryString, dispatchType, reportType = "") => async (dispatch) => {
  await axios
    .post(url.authorDataSearch,
      {
        "name": queryString,
        "limit": 20,
        "page": 1
      },
      { headers: headers() }
    )
    .then((res) => {
      dispatch({
        type: dispatchType,
        payload: res.data,
        reportType,
      });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({ type: actionTypes.ERRORCODE, payload: err.response.status });
      }
    });
};


export const fetchSegments = (organization, campaign, order, dispatchType,  reportType = "") =>
    async (dispatch) => {
      await axios
        .post(
          url.searchSegments,
          {
            account_uuid: organization,
            campaigns: campaign,
            orders: order,
          },
          {
            headers: headers(),
          }
        )
        .then((res) => {
          dispatch({
            type: dispatchType,
            payload: res.data,
            reportType
          });
        })
        .catch((err) => {
          if (err.response) {
            dispatch({
              type: actionTypes.ERRORCODE,
              payload: err.response.status,
            });
          }
        });
    };
