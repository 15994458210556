import React from "react";
import { Table } from "semantic-ui-react";
import Link from "../../../hyperlinks/link";

const IpAddressComponent = ({ data }) => {
    const ipAddress = data && data.ip_address && (data.ip_address).split('/');
    return (
        <Table.Cell title={data.ip_address} className="blogs_link space_height">
            {
                data.ip_address ? <Link type={"ip_address"} urlParam={ipAddress[0]} refValue={data.ip_address} /> : ""
            }
        </Table.Cell>
    );
};

export default IpAddressComponent;
