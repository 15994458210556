import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import TagsPicker from "./tags";
import { clearSearch, fetchTags, commonSelectedValue, addFilterById } from "../../actions";

const TagsComponent = (props) => {

	const onClickTopicInHandler = () => {
		props.clearSearch(props.tagClearActionsType);
   };

	const onSearchTopicInHandler = (e) => {
      if (e.target.value.length > 1) {
         props.fetchTags(
            e.target.value,
            props.type,
            props.tagFetchActionType,
            props.exValue,
            props.filterData.site
         );
      }
   };

	const onChangeTopicInHandler = (e, { value }) => {
		const selectedValue = value[value.length - 1];
		if (props.exValue.indexOf(selectedValue) === -1) {
			props.commonSelectedValue({ dispatchType: props.selectDispatchType, key: props.keyValue, value });
			props.addFilterById(value, props.tagAddActionsType);
		}
	};

	return (
		<React.Fragment>
			<TagsPicker
				sites={props.sites}
				tags={props.tags}
				value={props.value}
				label={props.label}
				placeholder={props.label}
				onClick={onClickTopicInHandler}
				onSearchChange={onSearchTopicInHandler}
				onChange={onChangeTopicInHandler}
			/>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		 sites: Object.values(state.sites),
	};
 };

 export default compose( connect(mapStateToProps, {
	clearSearch,
	fetchTags,
	commonSelectedValue,
	addFilterById
})
)(TagsComponent);