// SortIconMenuComponent.js

import React from "react";
import { Icon, Table, Menu, Checkbox } from "semantic-ui-react";
import _ from "lodash";

const SortMenuComponent = ({
   isSortIcon,
   clickOnSortIcon,
   EngsTableData,
   selectHeaders,
   clickOnSortHeader,
   applyButtonClick,
   notClickableHeaders
}) => (
   <div className="sorted_icon">
      <Table.HeaderCell style={{ paddingBottom: "20px" }}>
         <Icon
            name="edit"
            style={{ color: `${isSortIcon ? "#f15a29" : "#b2b3b3"}` }}
            size="large"
            onClick={clickOnSortIcon}
         />
      </Table.HeaderCell>
      {isSortIcon && (
         <div
            className="menu-list-items"
            style={{ height: "0px", float: "right" }}
         >
            <Menu vertical className="dynamic_table--list">
               {Object.values(EngsTableData).map((tableHeader, index) => (
                  <div
                     key={index}
                     className={ _.includes(notClickableHeaders, tableHeader.headerName) ? "sort__header-table-list" : "" }
                  >
                     <Checkbox
                        className="table_header--checkbox"
                        style={{ color: "#838486" }}
                        label={tableHeader.name}
                        checked={
                           selectHeaders &&
                              selectHeaders.some(
                                 (el) => el.headerName === tableHeader.headerName
                              )
                              ? true
                              : false
                        }
                        onClick={(e, data) => clickOnSortHeader(e, data, tableHeader)}
                     />
                  </div>
               ))}
               <button className="apply_menu--btn" onClick={applyButtonClick}>
                  Apply
               </button>
            </Menu>
         </div>
      )}
   </div>
);

export default SortMenuComponent;
