import React from "react";
import SingleSelectDropDown from "../base/singleSelectDropDown";

const UrlParamsPicker = (props) => {
	return (
		<SingleSelectDropDown
			clearable
			options={props.options}
			label={props.label}
			placeholder={props.placeholder}
			onSearchChange={props.onSearchChange}
			value={props.value}
			onChange={props.onChange}
			onClick={props.onClick}
			style={props.style}
		/>
	);
};

export default UrlParamsPicker;
