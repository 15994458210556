import { subDays } from "date-fns";
import moment from "moment";
import _ from "lodash";

// export const DateTime = (date) => {
//   if (date) {
//     let d = date.toString();
//     let l = d.split(' ').splice(5, 8).join(' ')
//     return moment(date).utc().format("YYYY-MM-DD") + " " + date.toLocaleTimeString() + " " + l;
//   }
//   return date;
// };

export const getDays = (startDate, endDate)=>{
const date1 = new Date(startDate);
const date2 = new Date(endDate);
const diffTime = Math.abs(date2 - date1);
const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
return diffDays
}

export const getDateTime = (datetime) => {
	if (datetime) {
		return (
			moment(datetime.substr(0, 10)).format("MM/DD/YYYY") +
			" " +
			datetime.substr(11, 8)
		);
	}
};

export const getTimeEng = (date) => {
	if (date) {
		return date.substr(11, 8);
	}
};

export const getDateEng = (date) => {
	if (date) {
		return moment(date.substr(0, 10)).format("MM/DD/YYYY");
	}
};

export const getApiDate = (date) =>
	moment(date, "MM/DD/YYYY").format("YYYY-MM-DD");

export const getDisplayDate = (date) => {
	if (date) {
		return moment(date).format("MM/DD/YYYY");
	}
	return date;
};

export const capitalizeFirstLetter = (string) => {
	if (string) {
		return string?.charAt(0).toUpperCase() + string?.slice(1);	
	}
	return "";
}

export const getTimestampToDisplayDate = (date) => {
	if (date) {
		return moment(date).utc().format("MM/DD/YYYY");
	}
	return date;
};

export const getAssetType = (assetTypes, type) => {
	if (type) {
		const test = assetTypes.find((assetTypes) => assetTypes.value === type);
		if (test) {
			return test.label;
		}
	}
	return capitalize(type);
};

export const getAttachedPercentage = (val) => {
	let numVal = _.toNumber(val)
	if (numVal) {
		return numVal.toFixed(1) + "%";
	} else {
		return (val = "0.0%");
	}
};

// Ad Impression Table Utility

export const capitalize = (string) => {
	if (string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
	return string;
};

export const toFloat = (val) => {
	if (val) {
		return val.toFixed(2);
	}
	return val;
};

export const numFormat = (num) => {
	if (isNaN(num)) {
		return 0;
	} else {
		if (num) {
			return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
		}
	}
	return num;
};

export const decimalFormat = (num) => {
	let convertInNumber = _.toNumber(num)
	if (convertInNumber || convertInNumber === 0) {
		return convertInNumber.toFixed(1);
	}
	return num;
};

export const impressionNumFormat = (num) => {
	if (num) {
		return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	} else {
		return "0";
	}
};

export const getTotalCtr = (totalImpressions, totalClicks) => {
	let totalCtr;
	if (totalImpressions === 0 || totalClicks === 0) {
		return "0.00";
	}
	totalCtr = (100 / totalImpressions) * totalClicks;
	return totalCtr.toFixed(2);
};

export const getInitials = (name) => {
	if (name) {
		let parts = name.split(" ");
		let initials = "";
		for (let i = 0; i < parts.length; i++) {
			if (parts[i].length > 0 && parts[i] !== "") {
				initials += parts[i][0];
			}
		}
		return initials;
	} else {
		return "";
	}
};

export const getCreativeType = (creativeType) => {
	let type;
	if (creativeType === "header") {
		type = "Leaderboard";
		return type;
	} else {
		type = creativeType.charAt(0).toUpperCase() + creativeType.slice(1);
		return type;
	}
};

export const getCreativeName = (name, text) => {
	if (name) {
		return name;
	} else if (text) {
		return text;
	} else {
		return "Creative";
	}
};

export const getCommaSeparatedVal = (array) => {
	if (array) {
		return array.join(", ");
	}
	return array;
};

export const getSortDirAbbr = (val) => {
	if (val === "descending") {
		return "desc";
	}
	return "asc";
};

export const getYesterdaysDate = (tMinusDays = -1) => {
	let today = new Date();
	let yesterday = new Date(today);
	yesterday.setDate(today.getDate() + tMinusDays);
	// let dd = yesterday.getDate();
	// let mm = yesterday.getMonth() + 1; //January is 0!

	// let yyyy = yesterday.getFullYear();
	// if (dd < 10) {
	//   dd = "0" + dd;
	// }
	// if (mm < 10) {
	//   mm = "0" + mm;
	// }
	// yesterday = yyyy + "-" + mm + "-" + dd;
	return new Date(yesterday);
};

export const getLastDays = (days, tMinusDays = -1) => {
	return subDays(getYesterdaysDate(tMinusDays), days);
};

const getLastTwelveMonths = () => {
	var d = new Date();
	d.setMonth(d.getMonth() - 12);
	return d;
};

const getTrueYearDate = (year) => {
	var d = new Date(year, 0, 1);
	return d;
};

export const getStartEndDates = (val, tMinusDays = -1) => {
	switch (val) {
		case "7":
			return {
				startDate: getDisplayDate(getLastDays(6, tMinusDays)),
				endDate: getDisplayDate(getYesterdaysDate(tMinusDays)),
			};
		case "30":
			return {
				startDate: getDisplayDate(getLastDays(29, tMinusDays)),
				endDate: getDisplayDate(getYesterdaysDate(tMinusDays)),
			};
		case "90":
			return {
				startDate: getDisplayDate(getLastDays(89, tMinusDays)),
				endDate: getDisplayDate(getYesterdaysDate(tMinusDays)),
			};
		case "1":
			return {
				startDate: getDisplayDate(getLastTwelveMonths()),
				endDate: getDisplayDate(getYesterdaysDate(tMinusDays)),
			};
		case "01":
			let year = new Date().getFullYear();
			return {
				startDate: getDisplayDate(getTrueYearDate(year)),
				endDate: getDisplayDate(getYesterdaysDate(tMinusDays)),
			};
		case "2":
			return {
				startDate: getDisplayDate(getLastDays(0)),
				endDate: getDisplayDate(getYesterdaysDate()),
			}
		case "all":
			return {
				startDate: "",
				endDate: "",
			}

		default:
			return {};
	}
};

export const getOrgsInCampaigns = (camp) => {
	let orgUuid = [];
	camp.forEach((e) => {
		e && e.organizations && e.organizations.forEach(({ name, uuid }) => {
			orgUuid.push({
				name: name,
				uuid: uuid,
			});
		});
	});
	return orgUuid;
};

export const titleCase = (str) => {
	if(str){
		return str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
	}
	return "";
};

export const getOrderInCampaigns = (orders) => {
	let orderUuid = [];
	orders &&
		orders.forEach((e) => {
			if (e.order.name) {
				orderUuid.push({
					name: e.order.name,
					uuid: e.order.uuid,
				});
			}
		});
	return orderUuid;
};

export const getPercentDelivered = (
	totalUniquePageImpressions,
	totalImpressionPurchased
) => {
	let totalPercentDelivered;
	if (totalUniquePageImpressions === 0 || totalImpressionPurchased === 0) {
		return totalPercentDelivered = "0.00";
	} 
		totalPercentDelivered =
			(totalUniquePageImpressions / totalImpressionPurchased) * 100;
		if(totalPercentDelivered > 0.00){
			return totalPercentDelivered.toFixed(2);
		} else{
			return  "0.00"
		}
};

export const getUnescapeStr = (str) => {
	if (str) {
		return str

			.replace(/&amp;/g, "&")

			.replace(/&gt;/g, ">;")

			.replace(/&lt;/g, "<");
	}
	return str;
};

export const getOrgLink = (orgObj, uuid, link) => {
	if (orgObj) {
		orgObj.forEach((obj) => {
			if (obj?.uuid === uuid) {
				window.open(`${link}/admin/organizations/#/${uuid}`);
			}
		});
	}
};

export const getName = (data, key) => {
	let names = data?.map((e) => {
		return e[key];
	});
	// return names.join(", ");
	names = names?.filter((name) => name !== "");
  	return names.length > 0 ? names?.join(", ") : "";
};

export const capitalizeName = (string) => {
	if (string) {
		const arr = string.split(" ");
		for (let i = 0; i < arr.length; i++) {
			arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
		}
		const str = arr.join(" ");
		return str;
	} else {
		return "";
	}
};
export const getNameJob = (data) => {
	let names = data.map((e) => {
		if (!_.isEmpty(e.sub_function && e.function)) {
			return e.function + "/" + e.sub_function;
		} else {
			return e.function;
		}
	});
	return names.join(", ");
};
export const getCapital = (str) => {
	return str.toUpperCase();
};
export const getId = (url) => {
	if (url) {
		let parts = url.split("=");
		let values = "";
		for (let i = 0; i < parts.length; i++) {
			if (i === parts.length - 1) {
				values = parts[i];
			}
		}
		return values;
	}
};
export const isUUID = (uuid) => {
	let s = "" + uuid;
	s = s.match(
		"^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
	);
	if (s === null) {
		return false;
	}
	return true;
};
export const getComma = (str) => {
	if(str) {
		return str.split(",").join(", ");
	}
	return str;
};
export const displayWidgetType = (string) => {
	if (string) {
		let strSplit = string.split("_");
		if(strSplit.length === 1){
			return capitalize(strSplit[0]);
		} else{
			return capitalize(strSplit[0]) + " " + capitalize(strSplit[1]);
		}
	}
	return string;
};

export const sanitizeTagValue = (tagValue) =>{
	if(tagValue === parseInt(tagValue)){
		return tagValue;
	}
	return parseInt(tagValue.split("|")[0]);
}

export const sanitizePageviewRequestTag = (fd) => {
  let tags = fd?.map((tag) => {
    return sanitizeTagValue(tag);
  });
  return tags;
};

export const getMemberLink = (orgObj, uuid, link) => {
	if (orgObj) {
		window.open(`${link}/admin/members/#/${uuid}`);
	 }
};

export const getDisplayDateChart = (date) => {
	if (date) {
		return moment(date).format("MM-DD-YYYY");
	}
	return date;
};

export const getCommaValues = (string) => {
	
	const parts = string.split(',').map(part => part.trim());

	// Filter out empty strings
	const nonEmptyParts = parts.filter(part => part !== '""');

	// Join the non-empty parts into a new string
	const output = nonEmptyParts.join(', ');

	return output ? output : "";
}

export const isDateValid =(dateStr) => {
    const dateObject = new Date(dateStr);
    return isNaN(dateObject) && dateObject.toString() !== "Invalid Date";
}

export const removeDuplicateVal = (input) => {

  if (input) {
    const inputArray = input.split(', ');
    const uniqueArray = [...new Set(inputArray)];
    
    return uniqueArray.join(', ');
  }
  
  return "";
}

export const removeSquareBrackets = (str) => {
	let result = "";
	if(str === "{\"\"}") {
		return ""
	} else if(str) {
		result = str.replace(/^{(.*)}$/, '$1')
	}
	return result;
}

export const downloadCSVData = (csvData, csvFileName) => {
	const url = window.URL.createObjectURL(new Blob([csvData])) 
	const link = document.createElement('a')
	link.href = url
	link.setAttribute('download', csvFileName)
	document.body.appendChild(link)
	link.click()
	link.remove()
}

export const getHeaderIndices = (data, headerName) => {
	if(_.size(data) > 0) {
		const index = data.findIndex(hData => hData.headerName === headerName);
		if (index !== -1) {
			return index;
		}
	}
	
	return -1;
};

export const calculateTotalCount = (data, key) => {
	return _.sumBy(data, val => val[key] || 0);
};

export const formatDate = (date, hms) => {
	return date ? getApiDate(date).concat(hms): ""	
}

export const combineIdAndSite = (data, valArr) => {
	if(data) {
		const output = data
						.filter(item => valArr.includes(item.id))
						.map(item => `${item.id}|${item.domain}`);
		return output;
	}

	return [];
}

export const getSelectedIds = (data, valArr) => {
	if(data) {
		return data.filter(item => valArr.includes(item.id));;
	}
	
	return [];
}

export const downloadWithURL = (data, fName) => {
	const link = document.createElement('a')
	link.href = data
	const fileName = `${fName}.csv`;
	link.setAttribute('download', fileName)
	document.body.appendChild(link)
	link.click()
	link.remove()
}

export const getDisplayDateTime = (date) => {
	if (date) {
		return moment(date).format("MM/DD/YYYY hh:mm:ss");
	}
	return date;
};

export const extractFileName = (headers) => {
  const data =  headers['content-disposition'];
  // Extract filename using regular expression
  const regex = /filename=(.*?)(?:;|$)/;
  const match = regex.exec(data);
  const fileName = match ? match[1] : null;

  return fileName
}

export const getFirstElementLeftBound = (elements) => {
  if (elements.length > 0) {
      const bounds = elements[0].getBoundingClientRect();
      return bounds.left;
  } else {
      return 0;
  }
};

export const getDomainStatus = (status) => {
	const formattedStatus = status.replace(/_/g, ' '); // Replace underscores with spaces
    const capitalizedStatus = formattedStatus.charAt(0).toUpperCase() + formattedStatus.slice(1); // Capitalize first letter
    return `Unresolved (${capitalizedStatus})`;
};

export const cleanPayload = (payload) => {
    const cp = Object.keys(payload)
        .filter(key => {
            const value = payload[key];
            return value !== undefined && value !== null && value !== "" && (!Array.isArray(value) || value.length > 0);
        })
        .reduce((obj, key) => {
            obj[key] = payload[key];
            return obj;
        }, {});

    return cp;
};

export const toTitleCase = (str) => {
    return str
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export const dataToCSVConversion = (data, fileName) => {
    if (!data || data.length === 0) {
        alert("No data available for CSV conversion.");
        return;
    }

    const headers = Object.keys(data[0]).map(toTitleCase);
    const rows = data.map(row => 
        Object.values(row).map(value => 
            `"${String(value).replace(/"/g, '""')}"`
        )
    );

    const csvContent = [
        headers.join(','),
        ...rows.map(row => row.join(','))
    ].join('\n');

    downloadCSVData(csvContent, fileName);
}

export const dataToCSVConversionForDI = (data, fileName, flattenData) => {
    if (!data || data.length === 0) {
        alert("No data available for CSV conversion.");
        return;
    }

    const flattenedData = data.map(flattenData);
    const headers = Object.keys(flattenedData[0]);

    const rows = flattenedData.map(row => 
        Object.values(row).map(value => 
            `"${String(value).replace(/"/g, '""')}"`
        )
    );

    const csvContent = [
        headers.join(','),
        ...rows.map(row => row.join(',')) 
    ].join('\n');

    downloadCSVData(csvContent, fileName);
}

export const getAccessibleRoutes = (data, contentRoutes) => {
    return contentRoutes.filter(route => {
        return data.some(item => item.report === route.roleReport);
    });
};

export const getRolebasedReportFunction = (data) => {
	return _.flatMap(data, (item) => _.map(item.RoleReports, 'report_uuid'));
}

export const getReportsByElement = (currentUserRoleReportData, allReportData, reportElement) => {
    const reportUuids = _.flatMap(currentUserRoleReportData, (item) => _.map(item.RoleReports, 'report_uuid'));
    const matchedData = allReportData?.filter(item => reportUuids.includes(item.uuid));
    return matchedData.filter(item => item.report === reportElement);
};