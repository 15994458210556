import _ from "lodash";
import React, { useRef } from "react";
import { connect } from "react-redux";
import { Checkbox, Loader } from "semantic-ui-react";
import { selClientLeadToggles } from "../../actions";
import * as actionTypes from "../../actions/actionTypes";
import { getCapital, getDisplayDateChart } from "../../utilities/common";
import DownloadChart from "../common/downloadChart";
import LeadValidationChart from "./leadValidationChart";

const ClientLeadChart = (props) => {
    const chartNameDiv = useRef(null);
    let loading;
    if (props.loading) {
        loading = (
            <div className="show-loader">
                <Loader active inline="centered" />
            </div>
        );
    }

    return (
        <div className="ad-graph">
            <div ref={chartNameDiv}>
                {!_.isEmpty(props.leadValidationChart) && props.clientLeadFilterData.selClientLeadChart &&
                    <DownloadChart
                        chartNameDiv={chartNameDiv.current}
                        chartName={(`Lead-Chart-${getDisplayDateChart(new Date())}.jpg`)}
                        style={{ width: "36px", height: "36px", marginTop: 19, marginRight: 56, color: "#838486" }}
                    />
                }
                <p className="ad-chart-name">
                    {getCapital("Pacing")}
                    <Checkbox
                        toggle
                        style={{
                            color: "white",
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            paddingLeft: "20px",
                            minHeight: 15,
                        }}
                        data-html2canvas-ignore="true"
                        disabled={!props.isFilApplied}
                        label={props.clientLeadFilterData.selClientLeadChart ? "Hide" : "Show"}
                        className="checkbox-label"
                        onChange={() => props.selClientLeadToggles(actionTypes.SELCLIENTCHARTTOGGLE)}
                        checked={props.clientLeadFilterData.selClientLeadChart}
                    />
                    {
                        props.isFilApplied && props.clientLeadFilterData.selClientLeadChart &&
                        loading
                    }
                    {!_.isEmpty(props.leadValidationChart) && props.clientLeadFilterData.selClientLeadChart && (
                        <LeadValidationChart />
                    )}
                </p>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        clientLeadFilterData: state.clientLeadFilterData,
        isFilApplied: state.clientLeadFetchData.isFilApplied,
        leadValidationChart: state.clientLeadFetchData.leadValidationChart,
        loading: state.clientLeadFetchData.isLoadingChart,
    };
};

export default connect(mapStateToProps, {
    selClientLeadToggles,
})(ClientLeadChart);
