import React, { useEffect, useState } from "react";
import { Table } from "semantic-ui-react";
import {
  getOrderInCampaigns,
  numFormat,
  titleCase,
} from "../../../utilities/common";
import {
    MEMBER_SENIORITY,
    ORGANIZATION_TYPE,
    DATE,
    TIME,
    ORGANIZATION,
    SECTOR,
    INDUSTRY_GROUP,
    INDUSTRY,
    SUB_INDUSTRY,
    COUNTRY,
    JOB_FUNCTION,
    AUTHOR,
    PLACEMENT_TAGS,
    ORGANIZATION_TAGS,
    TOPIC_TAGS,
    PRIMARY_TOPIC,
    IP_ADDRESS,
    TRAFFIC_SOURCE,
    UTM_TERM,
    UTM_CONTENT,
    ORGANIZATION_SIZE,
    ORGANIZATION_REVENUE,
    UTM_SOURCE,
    UTM_MEDIUM,
    UTM_CAMPAIGN,
    JOB_TITLE,
    ACTION,
    SESSION_ID,
    GROUP_ID,
    ASSET_TYPE,
    PAGE_TITLE,
    WP_ID,
    FIRST_NAME,
    LAST_NAME,
    CITY,
    POSTAL_CODE_OR_ZIP,
    TIMEZONE,
    EMAIL_ADDRESS,
    STATE_OR_PROVINCE
} from "../../config/TableHeaderNames";
import Link from "../../hyperlinks/link";
import MultiTagLinks from "../../hyperlinks/multiTagLinks";

const renderTableCell = (value, userUuid) => {
  const isAnonymousOrNA = value === "Anonymous" || value === "N/A";
  const displayValue = isAnonymousOrNA ? "Anonymous" : value;

  return (
    !isAnonymousOrNA ?
      <Table.Cell title={value} className="blogs_link space_height">
        <Link type="members" urlParam={userUuid} refValue={value}></Link>
      </Table.Cell> :
      <Table.Cell title={value} className="space_height">{displayValue}</Table.Cell>
  );
};

const EDDetailRow = (props) => {
  const [campaignValues, setCampaignValues] = useState([]);

  useEffect(() => {
    setCampaignValues(props.detailRow);
  }, [props.detailRow]);

    let OrderData;
    let orders = [];
    let order = {};
    if (campaignValues.campaign_data !== null) {
      OrderData = getOrderInCampaigns(campaignValues.campaign_data);
      OrderData.map((order) => {
        orders.push(order.name);
        return orders;
      });
    }
    order.name = orders;
    if (props.headerName === PAGE_TITLE) {
      return(
        <>
          {campaignValues.page_title === "N/A" ? (
            <Table.Cell title={campaignValues.page_title}>
              {campaignValues.page_title}
            </Table.Cell>
          ) : (
            <Table.Cell
              title={campaignValues.page_title}
              className="blogs_link"
            >
              <a
                href={`https://${campaignValues.domain}${campaignValues.page_path}`}
                target="blank"
              >
                {campaignValues.page_title}
              </a>
            </Table.Cell>
          )}
        </>
      );
    }

    if (props.headerName === AUTHOR) {
      return(
        <Table.Cell title={campaignValues.author}>
          {campaignValues.author}
        </Table.Cell>
      );
    }

    if (props.headerName === PRIMARY_TOPIC) {
      return(
        campaignValues.primary_topic !== "N/A" ? (
          <Table.Cell title={campaignValues.primary_topic} className="blogs_link">
              <MultiTagLinks
                type="primary"
                object={campaignValues.tags_data}
                refValue={campaignValues.primary_topic}
              ></MultiTagLinks>
            </Table.Cell>
            ) : (
              <Table.Cell title={campaignValues.primary_topic} >{campaignValues.primary_topic}</Table.Cell>
            )
      );
    }

    if (props.headerName === TOPIC_TAGS) {
      return(
						campaignValues.str_topic_tags !==  "N/A" ? (
					<Table.Cell title={campaignValues.str_topic_tags} className="blogs_link">
							<MultiTagLinks
								type="topic"
								object={campaignValues.tags_data}
								refValue={campaignValues.str_topic_tags}
							></MultiTagLinks>
          </Table.Cell>
						) : (
              <Table.Cell>
                    {campaignValues.str_topic_tags}
              </Table.Cell>
						)

      );
    }

    if (props.headerName === ORGANIZATION_TAGS) {
      return(
        campaignValues.str_organization_tags !==  "N/A" ? (
        <Table.Cell title={campaignValues.str_organization_tags} className="blogs_link">
          <MultiTagLinks
            type="orgs"
            object={campaignValues.tags_data}
            refValue={campaignValues.str_organization_tags}
          ></MultiTagLinks>
        </Table.Cell>
        ) : (
          <Table.Cell>{campaignValues.str_organization_tags}</Table.Cell>  
        )

      );
    }

    if (props.headerName === PLACEMENT_TAGS) {
      return(
        campaignValues.str_placement_tags !==  "N/A" ? (
        <Table.Cell title={campaignValues.str_placement_tags} className="blogs_link">
          <MultiTagLinks
            type="placement"
            object={campaignValues.tags_data}
            refValue={campaignValues.str_placement_tags}
          ></MultiTagLinks>
        </Table.Cell>
        ) : (
          <Table.Cell>{campaignValues.str_placement_tags}</Table.Cell>  
        )
      );
    }

    if (props.headerName === DATE) {
      return(
        <Table.Cell title={campaignValues.created_at}>
          {campaignValues.created_at}
        </Table.Cell>
      );
    }

    if (props.headerName === TIME) {
      return(
        <Table.Cell title={campaignValues.time}>
          {campaignValues.time}
        </Table.Cell>
      );
    }

    if (props.headerName === ACTION) {
      return (
        <Table.Cell className="space_height" title={titleCase(campaignValues.action)}>
          {titleCase(campaignValues.action)}
        </Table.Cell>
      );
    }

    if (props.headerName === IP_ADDRESS) {
      const ipAddress = campaignValues.ip_address && (campaignValues.ip_address).split('/');
      return(
        <Table.Cell title={campaignValues.ip_address} className="blogs_link space_height">
          {campaignValues.ip_address ? (
            <Link
              type={"ip_address"}
              urlParam={ipAddress[0]}
              refValue={campaignValues.ip_address}
            ></Link>
          ) : (
            ""
          )}
        </Table.Cell>
      );
    }

    if (props.headerName === COUNTRY ) {
      return(
        <Table.Cell title={campaignValues.member_country}>
          {campaignValues.member_country}
        </Table.Cell>
      );
    }

    if (props.headerName === TRAFFIC_SOURCE ) {
      return(
        <Table.Cell title={campaignValues.traffic_source}>
          {campaignValues.traffic_source}
        </Table.Cell>
      );
    }

    if (props.headerName === UTM_SOURCE) {
      return(
        <Table.Cell title={campaignValues.source}>
          {campaignValues.source}
        </Table.Cell>
      );
    }

    if (props.headerName === UTM_MEDIUM) {
      return(
        <Table.Cell title={campaignValues.utm_medium}>
          {campaignValues.utm_medium}
        </Table.Cell>
      );
    }

    if (props.headerName === UTM_CAMPAIGN) {
      return(
        <Table.Cell title={campaignValues.utm_campaign}>
          {campaignValues.utm_campaign}
        </Table.Cell>
      );
    }

    if (props.headerName === UTM_TERM) {
      return(
        <Table.Cell title={campaignValues.utm_term}>
          {campaignValues.utm_term}
        </Table.Cell>
      );
    }

    if (props.headerName === UTM_CONTENT) {
      return(
        <Table.Cell title={campaignValues.utm_content}>
          {campaignValues.utm_content}
        </Table.Cell>
      );
    }

    if (props.headerName === ORGANIZATION ) {
      return(
        <>
          {campaignValues.organization_uuid ? (
            <Table.Cell
              title={campaignValues.organization_name}
              className="blogs_link"
            >
              <Link
                type="organizations"
                urlParam={campaignValues.organization_uuid}
                refValue={campaignValues.organization_name}
              ></Link>
            </Table.Cell>
          ) : (
            <Table.Cell
              title={campaignValues.organization_name}
              className="space_height"
            >
              {campaignValues.organization_name}
            </Table.Cell>
          )}
        </>
      );
    }

    if (props.headerName === SECTOR ) {
      return(
        <Table.Cell title={campaignValues.sector}>
          {campaignValues.sector}
        </Table.Cell>
      );
    }

    if (props.headerName === INDUSTRY_GROUP) {
      return(
        <Table.Cell title={campaignValues.industry_group}>
          {campaignValues.industry_group}
        </Table.Cell>
      );
    }

    if (props.headerName === INDUSTRY ) {
      return(
        <Table.Cell title={campaignValues.industry}>
          {campaignValues.industry}
        </Table.Cell>
      );
    }

    if (props.headerName === SUB_INDUSTRY ) {
      return(
        <Table.Cell title={campaignValues.sub_industry}>
          {campaignValues.sub_industry}
        </Table.Cell>
      );
    }

    if (props.headerName === ORGANIZATION_REVENUE) {
      return(
        <Table.Cell title={campaignValues.estimated_revenue}>
          {campaignValues.estimated_revenue}
        </Table.Cell>
      );
    }

    if (props.headerName === ORGANIZATION_SIZE ) {
      return(
        <Table.Cell textAlign="right" title={campaignValues.size}>
          {numFormat(campaignValues.size)}
        </Table.Cell>
      );
    }


    if (props.headerName === ORGANIZATION_TYPE) {
      return(
        <Table.Cell title={campaignValues.type}>
          {campaignValues.type}
        </Table.Cell>
      );
    }

    if (props.headerName === MEMBER_SENIORITY) {
      return(
        <Table.Cell title={campaignValues.seniority}>
          {campaignValues.seniority}
        </Table.Cell>
      );
    }

    if (props.headerName === JOB_FUNCTION) {
      return(
        <Table.Cell title={campaignValues.job_function}>
          {campaignValues.job_function}
        </Table.Cell>
      );
    }

    if (props.headerName === JOB_TITLE) {
      return(
        <Table.Cell title={campaignValues.job_title}>
          {campaignValues.job_title}
        </Table.Cell>
      );
    }

    if(props.headerName === SESSION_ID) {
      return (
        <Table.Cell className="space_height" title={campaignValues.session_uuid}>
          {campaignValues.session_uuid}
        </Table.Cell>
      );
    }

    if(props.headerName === GROUP_ID) {
      return (
        <Table.Cell className="space_height" title={campaignValues.group_uuid}>
          {campaignValues.group_uuid}
        </Table.Cell>
      );
    }

    if(props.headerName === ASSET_TYPE) {
      return (
        <Table.Cell title={campaignValues.asset_type}>{campaignValues.asset_type}</Table.Cell>
      )
    }

    if(props.headerName === WP_ID) {
      return (
        <Table.Cell className="space_height" title={campaignValues.wp_page_id}>
          {campaignValues.wp_page_id}
        </Table.Cell>
      );
    }

    if (props.headerName === FIRST_NAME) {
      return renderTableCell(campaignValues.first_name, campaignValues.user_uuid);
    }

    if (props.headerName === LAST_NAME) {
      return renderTableCell(campaignValues.last_name, campaignValues.user_uuid);
    }

    if(props.headerName === EMAIL_ADDRESS) {
      return (
        <Table.Cell className="space_height" title={campaignValues.email}>{campaignValues.email}</Table.Cell>
      );
    }

    if (props.headerName === CITY) {
      return (
        <Table.Cell className="space_height" title={campaignValues.city}>{campaignValues.city}</Table.Cell>
      );
    }
    if (props.headerName === STATE_OR_PROVINCE) {
      return (
        <Table.Cell className="space_height" title={campaignValues.state}>{campaignValues.state}</Table.Cell>
      );
    }

    if(props.headerName === POSTAL_CODE_OR_ZIP) {
      return (
        <Table.Cell className="space_height" title={campaignValues.postal_code}>{campaignValues.postal_code}</Table.Cell>
      );
    }
    if(props.headerName === TIMEZONE) {
      return (
        <Table.Cell className="space_height" title={campaignValues.member_timezone}>{campaignValues.member_timezone}</Table.Cell>
      );
    }


  return null;
};
export default EDDetailRow;
