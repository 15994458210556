import React from "react";
import { Table } from "semantic-ui-react";

const NewsletterDateComponent = ({ data }) => {
    return (
        <Table.Cell className="space_height" title={data.date}>{data.date}</Table.Cell>
    );
};

export default NewsletterDateComponent;
