import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Chart } from "react-google-charts";
//import { rawData } from "../../components/pageview/data";
import { getDateEng, numFormat } from "../../utilities/common";
import _ from "lodash";

let tickSize = [];
const SiteReportComparedChart = (props) => {
  const [legendClicked, setLegendClicked] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    let siteChartPreparedData = siteReportData();
    setChartData(siteChartPreparedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legendClicked]);

  const onLegendClick = (e) => {
    if (legendClicked.includes(e)) {
      if (e === "all pageviews") {
        setLegendClicked((prev) => prev.filter((i) => i !== e));
      } else {
        setLegendClicked((prev) => prev.filter((i) => i !== e));
      }
    } else {
      if (e === "all pageviews") {
        setLegendClicked((prev) => [...prev, e]);
      } else {
        setLegendClicked((prev) => [...prev, e]);
      }
    }
  };

  //   let comparedColors = ["#CC167F","transparent", "#FED78B", "#FA911D"];
  let keyNames = [
    "all pageviews",
    "all pageviews",
    "",
    "",
    "independent pageviews",
    "independent pageviews",
    "sponsored pageviews",
    "sponsored pageviews",
  ];

  const getSeries = () => {
    let series = {};
    for (let i = 0; i < keyNames.length; i++) {
      let j = i;
      let color;
      color =
        legendClicked.indexOf(keyNames[i]) === -1
          ? comparedColors[i]
          : "transparent";
      series[j] = { axis: "pageviews", targetAxisIndex: 0, color: color };
    }
    return series;
  };
  let d1 = [];
  const siteReportData = () => {
    let data = [];
    let d = [];
    d.push({ type: "date" });
    d.push({
      type: "string",
      role: "tooltip",
      p: { html: true },
    });
    d.push("number");
    d.push("number");
    d.push({
      type: "boolean",
      role: "certainty",
    });
    d.push("number");
    d.push("number");
    d.push({
      type: "boolean",
      role: "certainty",
    });
    d.push("number");
    d.push("number");
    d.push({
      type: "boolean",
      role: "certainty",
    });
    d.push("number");
    d.push("number");
    d.push({
      type: "boolean",
      role: "certainty",
    });
    data.push(d);
    tickSize = [];
    // if (props.siteReportCharts) {
    props.siteReportCharts.forEach((e) => {
      //rawData.forEach((e) => {
      let totalPageview = e.editorial_pageviews + e.sponsored_pageviews
      let totalPageviewPrev = e.editorial_pageviews_prev + e.sponsored_pageviews_prev
      d = [];
      d1.push(new Date(e.date));

      d.push(new Date(e.date));
      d.push(
        `<div class="tooltip-pageview-values"  style='padding-top: 15px; width: 220px;'>` +
          getDateEng(e.date) +
          `<hr></hr>` +
          " <span style='height: 6px; width: 6px; margin-right: 5px; background-color: #CC167F;border-radius: 50%; display: inline-block;'></span>" +
          numFormat(totalPageview) +
          ` - All Pageviews <br />` +
          " <span style='height: 6px; width: 6px; margin-right: 5px; background-color: #CC167F;border-radius: 50%; display: inline-block;'></span>" +
          numFormat(totalPageviewPrev) +
          ` - Prev All Pageviews <br />` +
          " <span style='height: 6px; width: 6px; margin-right: 5px; background-color: #FED78B;border-radius: 50%; display: inline-block;'></span>" +
          numFormat(e.editorial_pageviews) +
          ` - Independent Pageviews <br />` +
          " <span style='height: 6px; width: 6px; margin-right: 5px; background-color: #FED78B;border-radius: 50%; display: inline-block;'></span>" +
          numFormat(e.editorial_pageviews_prev) +
          ` - Prev Independent Pageviews <br />` +
          " <span style='height: 6px; width: 6px; margin-right: 5px; background-color: #FA911D;border-radius: 50%; display: inline-block;'></span>" +
          numFormat(e.sponsored_pageviews) +
          ` - Sponsored Pageviews <br />` +
          " <span style='height: 6px; width: 6px; margin-right: 5px; background-color: #FA911D;border-radius: 50%; display: inline-block;'></span>" +
          numFormat(e.sponsored_pageviews_prev) +
          ` - Prev Sponsored Pageviews <br />` +
          `</div>`
      );
      d.push(totalPageview);
      d.push(totalPageviewPrev);
      d.push(false);
      d.push(0);
      d.push(0);
      d.push(false);
      d.push(e.editorial_pageviews);
      d.push(e.editorial_pageviews_prev);
      d.push(false);
      d.push(e.sponsored_pageviews);
      d.push(e.sponsored_pageviews_prev);
      d.push(false);
      data.push(d);
      tickSize.push(new Date(e.date));
    });
    //}
    return data;
  };

  // let comparedColors= ["#CC167F", "grey",  "#FED78B", "#FA911D"];
  let comparedColors = [
    "#CC167F",
    "#CC167F",
    "transparent",
    "transparent",
    "#FED78B",
    "#FED78B",
    "#FA911D",
    "#FA911D",
  ];

  const options = {
    // comparedColors: conditionChecked ? comparedColors :  comparedColors,
    legend: "none",
    chartArea: {
      left: "3%",
      top: "13%",
      width: "90%",
      height: "80%",
      right: "3%",
    },
    intervals: { style: "line" },
    vAxis: {
      format: "short",
      minValue: 0,
      baselineColor: "#B6B7B8",
      minorGridlines: {
        count: 0,
      },
    },
    hAxis: {
      baselineColor: "#fff",
      gridlineColor: "#fff",
      format: "MMM dd",
      ticks: tickSize,
      // gridlines: {
      // 	minSpacing: 0,
      // },
      minorGridlines: {
        count: 0,
      },
      //viewWindowMode: "pretty",
    },
    explorer: {
      actions: ["dragToZoom", "rightClickToReset"],
      axis: "horizontal",
      keepInBounds: true,
      maxZoomIn: 2,
      //  zoomDelta: 2,
    },
    crosshair: {
      orientation: "vertical",
      color: "grey",
      opacity: 2,
      trigger: "both",
    },
    focusTarget: "category",
    //focusTarget: "category",
    tooltip: { isHtml: true },
    series: getSeries(),
  };
  if (!_.isEmpty(props.siteReportCharts)) {
    //	if(props.siteReportCharts){
    return (
      <>
        <div
          className="pageview-graph"
          id="chart-div"
          style={{ height: "430px", marginBottom: 30 }}
        >
          <div>
            <div
              style={{
                zIndex: 1,
                marginLeft: -60,
                marginTop: 27,
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                className="legend-item"
                onClick={() => {
                  onLegendClick("all pageviews");
                }}
              >
                <span
                  style={{
                    backgroundColor: legendClicked.includes("all pageviews")
                      ? "grey"
                      : comparedColors[0],
                    marginLeft: 50,
                  }}
                  className="legend-circle"
                />
                <text
                  style={{ color: "#656669"}}
                  className="pageview-text"
                >
                  All Pageviews
                </text>
              </div>
              <div
                className="legend-item"
                style={{ marginLeft: "1rem", cursor: "pointer" }}
                onClick={() => {
                  onLegendClick("independent pageviews");
                }}
              >
                <span
                  style={{
                    backgroundColor: legendClicked.includes(
                      "independent pageviews"
                    )
                      ? "grey"
                      : comparedColors[4],
                    marginLeft: 0,
                  }}
                  className="legend-circle"
                />
                <text
                  style={{ color: "#656669" }}
                  className="pageview-text"
                >
                  Independent Pageviews
                </text>
              </div>
              <div
                className="legend-item"
                style={{ marginLeft: "1rem", cursor: "pointer" }}
                onClick={() => {
                  onLegendClick("sponsored pageviews");
                }}
              >
                <span
                  style={{
                    backgroundColor: legendClicked.includes(
                      "sponsored pageviews"
                    )
                      ? "grey"
                      : comparedColors[6],
                    marginLeft: 0,
                  }}
                  className="legend-circle"
                />
                <text
                  style={{ color: "#656669", marginRight: 80 }}
                  className="pageview-text"
                >
                  Sponsored Pageviews
                </text>
              </div>
            </div>
          </div>
          <Chart
            chartType="LineChart"
            data={chartData}
            width="100%"
            height="400px"
            options={options}
          />
        </div>
      </>
    );
  }
  return <></>;
};

const mapStateToProps = (state) => {
  return {
    siteReportCharts: state.siteReportFilterData.siteReportCharts,
    siteReportFilterData: state.siteReportFilterData,
    siteAllPgSummaryData: state.siteReportFilterData.siteAllPgData,
    siteEDPgSummaryData: state.siteReportFilterData.siteEditorialData,
    siteSponsoredPgSummaryData: state.siteReportFilterData.siteSponsoredData,
    isFilter: state.siteReportFilterData.isApplied,
  };
};

export default connect(mapStateToProps)(SiteReportComparedChart);
