import axios from "../api/axios";
import { url } from "../api/urlHelper";
import * as actionTypes from "./actionTypes";
import { headers } from "../api/apiConstants";
import { extractFileName } from "../utilities/common";
import { SidebarPayload } from "../utilities/SidebarPayload";

export const topicSetPageCount = (dispatchType) => {
	return {
		type: dispatchType,
	};
};

export const selTopicStartDate = (date) => {
	return {
		type: actionTypes.SELTOPICSTARTDATE,
		payload: date,
	};
};

export const selTopicEndDate = (date) => {
	return {
		type: actionTypes.SELTOPICENDDATE,
		payload: date,
	};
};

export const prevTopicAppliedDate = (date) => (dispatch) => {
	dispatch({
		type: actionTypes.SELTOPICPREVAPPLIEDDATE,
		payload: date,
	});
};

export const prevTopicSelStartDate = (date) => (dispatch) => {
	dispatch({
		type: actionTypes.SELTOPICSTARTDATEPREV,
		payload: date,
	});
};

export const prevTopicSelEndDate = (date) => (dispatch) => {
	dispatch({
		type: actionTypes.SELTOPICENDDATEPREV,
		payload: date,
	});
};

export const applyTopicDate = (val) => {
	return {
		type: actionTypes.APPLYTOPICDATE,
		payload: val,
	};
};

export const selTopicOrgs = (orgs) => {
	return {
		type: actionTypes.SELTOPICORGS,
		payload: orgs,
	};
};

export const selTopicCampaigns = (campaigns) => {
	return {
		type: actionTypes.SELTOPICCAMPAIGNS,
		payload: campaigns,
	};
};

export const selTopicStatus = (site) => {
	return {
		type: actionTypes.SELTOPICSTATUS,
		payload: site,
	};
};

export const selTopicSite = (status) => {
	return {
		type: actionTypes.SELTOPICSITE,
		payload: status,
	};
};

export const selTopicEngOrgs = (engOrg) => (dispatch) => {
	dispatch({
		type: actionTypes.SELTOPICENGORGS,
		payload: engOrg,
	});
};

export const applyTopicFilter = () => {
	return {
		type: actionTypes.APPLYTOPICFILTER,
	};
};

export const clearTopicFilter = () => {
	return {
		type: actionTypes.CLEARTOPICFILTER,
	};
};

export const clearTopicSearchedValue = (dispatchType) => {
	return {
		type: dispatchType,
	};
};

export const selTopicFilterValue = (value, dispatchType) => (dispatch) => {
	dispatch({
		type: dispatchType,
		payload: value,
	});
};

export const fetchTopicDetail = (filterData, page, type = "", dispatchType, csvType = "", newEmail) =>
	async (dispatch) => {
		const data = { ...SidebarPayload(filterData, page, type, url.topicHubDetail) };
		await axios
			.post( url.topicHubDetail, data,
				{
					headers: headers(),
				}
			)
			.then((res) => {
				const fileName = extractFileName(res.headers)
				if (res.status === 200) {
					if (type) {
						dispatch({
							type: csvType,
							payload: url.topicHubDetail,
							email: filterData.email,
							csv: res.data,
							downloadFileName: fileName
						});
					} else {
						dispatch({
							type: dispatchType,
							payload: res.data,
							filterData: filterData,
							resSidebarCampaignDetailsValue: res.data === null && true
						});
					}
				} else if (res.status === 202) {
					if (type) {
						dispatch({
							type: csvType,
							payload: url.topicHubDetail,
							email: filterData.email,
							downloadFileName: fileName
						});
					} 
				}
			})
			.catch((err) => {
				if (err.response) {
					dispatch({
						type: actionTypes.ERRORCODE,
						payload: err.response.status,
					});
				}
			});
	};

export const fetchTopicSummary = (filterData, page, type = "", dispatchType, csvType = "", newEmail) => async (dispatch) => {
		const data = { ...SidebarPayload(filterData, page, type, url.topicHubSummary),
			delivery_state: filterData?.deliverdFilterState && (filterData?.deliverdFilterState).trim()
		};
		await axios
			.post( url.topicHubSummary, data,
				{
					headers: headers(),
				}
			)
			.then((res) => {
				const fileName = extractFileName(res.headers)
				if (res.status === 200) {
					if (type) {
						dispatch({
							type: csvType,
							payload: url.topicHubSummary,
							email: filterData.email,
							csv: res.data,
							downloadFileName: fileName
						});
					} else {
						dispatch({
							type: dispatchType,
							payload: res.data,
							filterData: filterData,
							resSidebarCampaignSummaryValue: res.data === null && true
						});
					}
				} else if (res.status === 202) {
					if (type) {
						dispatch({
							type: csvType,
							payload: url.topicHubSummary,
							email: filterData.email,
							downloadFileName: fileName
						});
					} 
				}
			})
			.catch((err) => {
				if (err.response) {
					dispatch({
						type: actionTypes.ERRORCODE,
						payload: err.response.status,
					});
				}
			});
	};

export const showMoreTopicLoading = (dispatchType) => {
	return {
		type: dispatchType,
	};
};

export const sortTopicDetail = (column) => {
	return {
		type: actionTypes.SORTTOPICDETAIL,
		column: column,
	};
};

export const sortTopicSummary = (column) => {
	return {
		type: actionTypes.SORTTOPICSUMMARY,
		column: column,
	};
};

export const sortSidebarDetail = (column)=>{
	return {
		type:actionTypes.SIDEBARDETAILSORT,
		column: column,
}
};

export const clearTopicSort = (dispatchType) => {
	return {
		type: dispatchType,
	};
};

export const topicCsvLoading = () => {
	return {
		type: actionTypes.TOPICCSVLOADING,
	};
};

export const fetchTopicCampaignSummaryCsvLoading = () => {
	return {
		type: actionTypes.TOPICCAMPAIGNCSVLOADING,
	};
};

export const selTopicToggles = (dispatchType) => (dispatch) => {
	dispatch({
		type: dispatchType,
	});
};

export const selTopicOrder = (order) => (dispatch) => {
	dispatch({
		type: actionTypes.SELTOPICORDER,
		payload: order,
	});
};

export const fetchTopicData =
	(uri, filterData, page, type = "", dispatchType, csvType = "", newEmail) =>
	async (dispatch) => {
		const data = { ...SidebarPayload(filterData, page, type, uri) };
		await axios
			.post( uri, data,
				{
					headers: headers(),
				}
			)
			.then((res) => {
				if (res.status === 200) {
					if (type) {
						dispatch({
							type: csvType,
							payload: uri,
							email: filterData.email,
							csv: res.data,
						});
					} else {
						dispatch({
							type: dispatchType,
							payload: res.data,
							filterData: filterData,
							resSidebarDetailsValue: res.data === null && true
						});
					}
				} else if (res.status === 202) {
					if (type) {
						dispatch({
							type: csvType,
							payload:uri,
							email: filterData.email,
						});
					} 
				}
			})
			.catch((err) => {
				if (err.response) {
					dispatch({
						type: actionTypes.ERRORCODE,
						payload: err.response.status,
					});
				}
			});
	};
export const selTopicCustomRangeChkBox =  (value, dispatchType) =>{
		return{
			type:dispatchType,
			payload:value
		}
	}

export const firmographicSidebarTypeCall = (list, dispatchType, selected) => (dispatch) => {
	dispatch({
		type: dispatchType,
		payload: list,
		selected,
	});
};