import React from "react";
import SearchFilter from "../components/EditorialDetails/searchFilter";
import EditorialDetailsDetail from "../components/EditorialDetails/editoarialDetailsTable/editorialDetailsDetail";
import { connect } from "react-redux";
import _ from "lodash"
import { getDays } from "../utilities/common";
import {
	editorialDetailsCsvLoading,
    selEditorialDetailsFilterValue,
    fetchPageviewTableData,
    fetchEDDetailCsvLoading
} from "../actions";
import { Confirm } from "semantic-ui-react";
import * as actionTypes from "../actions/actionTypes";
// import Email from "../components/common/email";
import NotifyEmail from "../components/common/notifyEmailConfirmation";
import { url } from "../api/urlHelper";
import DownloadXLSX from "../components/common/downloadXLSX";

class EditorialDetails extends React.Component {
    edCsvLink = React.createRef();
    
    state = {
		popup: false,
	}

    getDetailedPageviewExcel = () => {
		let days = getDays(this.props.edFilterData.startDate, this.props.edFilterData.endDate)
		if(days > 90) {
            this.setState({ popup: true })
            setTimeout(() => {
                this.setState({ popup: false })
            }, 5000)
		} else {
			this.props.editorialDetailsCsvLoading(actionTypes.EDCSVLOADING);
			// this.props.selEditorialDetailsFilterValue({ email: this.props.userEmail, class: true }, actionTypes.EDEMAILPAGEDETAIL);
            DownloadXLSX(
                actionTypes.EDEMAILPAGEDETAIL, 
                "editorial_detail_table", 
                "independent-details",
                this.props.edFilterData,
                this.props.userEmail,
                actionTypes.EDITORIALDETAILSEXCEL,
                this.props.fetchPageviewTableData
              );
	    }
	};

    getDetailedPageviewExcelCSV = () => {
		let days = getDays(this.props.edFilterData.startDate, this.props.edFilterData.endDate)
		if(days > 90) {
            this.setState({ popup: true })
            setTimeout(() => {
                this.setState({ popup: false })
            }, 5000)
		} else {
			this.props.editorialDetailsCsvLoading(actionTypes.EDGENERATECSVLOADING);
			// this.props.selEditorialDetailsFilterValue({ email: this.props.userEmail, class: true }, actionTypes.EDEMAILPAGEDETAIL);
            DownloadXLSX(
                actionTypes.EDEMAILCSVPAGEDETAIL, 
                "editorial_detail_table_CSV", 
                "independent-details",
                this.props.edFilterData,
                this.props.userEmail,
                actionTypes.EDEXCEL,
                this.props.fetchPageviewTableData
            );
	    }
	};

    handleConfirm = () => this.setState({ popup: false });

    getEDCsvData = async () => {
        this.props.editorialDetailsCsvLoading(actionTypes.EDITORIALDETAILCSVLOADING);
          await this.props.fetchPageviewTableData(
            url.pageviewDetailed,
            this.props.edFilterData,
            1,
            "CSV",
            "",
            actionTypes.EDVIEWDETAILSCSV,
            null,
            null,
            "independent-details",
          );
          if (!_.isEmpty(this.props.edDetailCsv)) {
            this.edCsvLink.current.link.click();
          }
    };

    render() {
        let detailDownloadButton;
        //let edDetailDownload;
        let csvDetailDownloadButton

        if (this.state.popup){
            return  <Confirm open={true}
                        cancelButton = {null}
                        content={"Please select Date Range which should not exceed day count more than 90 days"}
                        onConfirm={this.handleConfirm}
                    >
                    </Confirm>
        }

        if (this.props.isFilApplied && !_.isEmpty(this.props.pageviewDetailed)) {
            // detailDownloadButton = (
            //     <div>
            //         <button
            //             className="download-button"
            //             disabled={false}
            //             onClick={() => this.getDetailedPageviewExcel()}
            //         >
            //             Generate XLSX
            //         </button>
            //     </div>
            // );
            // edDetailDownload = (
            //     <div>
            //       <button
            //         className="download-button"
            //         disabled={false}
            //         onClick={() => this.getEDCsvData()}
            //       >
            //         Download CSV
            //       </button>
            //     </div>
            //   );
            csvDetailDownloadButton = (
                <div>
                  <button
                    className="download-button"
                    disabled={false}
                    onClick={() => this.getDetailedPageviewExcelCSV()}
                  >
                    Generate CSV
                  </button>
                </div>
              );   
        }

        return(
            <>
                <div className="page-name-comp">
                    <p className="page-name">Pageview Details</p>
                </div>
                <SearchFilter />
                <div>
                    <div className="table-heading-block">
                        <div className="table-name">
                        <p>Pageview Details Table</p>
                        </div>
                        {/* <div className="csv-button">
                            {edDetailDownload}
                            <CSVLink
                                data={this.props.edDetailCsv}
                                filename={`Editorial Detail ${new Date()}.csv`}
                                ref={this.edCsvLink}
                                target="_blank"
                            />
                        </div> */}
                        <div className="class-relative">
                            <div className="pg detail-excel-button">
                                {csvDetailDownloadButton}
                            </div>
                            <div className="pg popup">
                                {this.props.postEmailUpdateCSV ? (
                                    <NotifyEmail
                                        email={this.props.postEmailUpdateCSV}
                                        table="independent-detail-CSV"
                                        cardPopup={this.props.postEmailUpdateCSV}
                                        csvExcel={true}
                                    />
                                ) : null}
                            </div>
                        </div>       
                        <div className="class-relative">
                            <div className="pg detail-excel-button">
                                {detailDownloadButton}
                            </div>
                            <div className="pg popup">
                                {/* {this.props.emailPageDetail ? (
                                    <Email
                                        email={this.props.emailPageDetail}
                                        table="independent-detail"
                                        cardPopup={this.props.cardPopupPageDetail}
                                    />
                                ) : null} */}
                                {this.props.postEmailUpdate ? (
                                    <NotifyEmail
                                        email={this.props.postEmailUpdate}
                                        table="independent-detail"
                                        cardPopup={this.props.postEmailUpdate}
                                    />
                                ) : null}
                            </div>
                        </div>

                    </div>
                    <EditorialDetailsDetail />
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
	return {
        edFilterData: state.editorialDetailsFilterData,
        isFilApplied: state.editorialDetailsFetchData.isFilApplied,
        pageviewDetailed: Object.values(state.editorialDetailsFetchData.pageviewDetailed),
        userEmail: state.authorized.userEmail,
        emailPageDetail: state.editorialDetailsFetchData.emailPageDetail,
        postEmailUpdate: state.editorialDetailsFetchData.postEmailUpdate,
        cardPopupPageDetail: state.editorialDetailsFetchData.cardPopupPageDetail,
        edDetailCsv: state.editorialDetailsFetchData.edDetailCsv,
        postEmailUpdateCSV:  state.editorialDetailsFetchData.postEmailUpdateCSV,
	};
};

export default connect(mapStateToProps, {
    editorialDetailsCsvLoading,
    selEditorialDetailsFilterValue,
    fetchPageviewTableData,
    fetchEDDetailCsvLoading
})(EditorialDetails);