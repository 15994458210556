import React from "react";
import GridIcons from "./gridIcons";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import TrendingUpSharpIcon from "@mui/icons-material/TrendingUpSharp";
import DemandGeneration from "./demandGeneration";
import {
  getDisplayDate,
  numFormat,
  getOrgsInCampaigns,
  getOrderInCampaigns,
  getCommaSeparatedVal,
  getAttachedPercentage
} from "../../utilities/common";
import Link from "../hyperlinks/link";
import MultiLinks from "../hyperlinks/multiLinks";
import CampaignReport from "./campaignReport";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import StoreIcon from "@mui/icons-material/Store";

const iconsDesign = {
  height: "1.9rem", 
  textAlign: "centre"
}
const LeadsCampaign = (props) => {
  let clientLeadsCampaign = props.data;
  let percentDelivered = getAttachedPercentage(clientLeadsCampaign.percent_delivered);
  const Cards = [
    {
      title: "Leads Delivered",
      value: clientLeadsCampaign.total_leads_delivered,
      icon: <TouchAppIcon style={iconsDesign} />,
    },
    {
      title: "Completion %",
      value: percentDelivered !== "" ? percentDelivered : "-",
      icon: (
        <TrendingUpSharpIcon style={iconsDesign} />
      ),
    },
    {
      title: "Leads Purchased",
      value: numFormat(clientLeadsCampaign.leads_purchased),
      icon: <StoreIcon style={iconsDesign} />,
    },
    {
      title: "Leads Outstandings",
      value: numFormat(clientLeadsCampaign.leads_outstanding),
      icon: <CallSplitIcon style={iconsDesign} />,
    },
  ];
  let OrderData;
  let orders = [];
  let order = {};
  clientLeadsCampaign.campaigns.forEach((e) => {
    if (e.campaigns !== null) {
      OrderData = getOrderInCampaigns(clientLeadsCampaign.campaigns);
      OrderData.map((order) => {
        orders.push(order.name);
        return orders;
      });
    }
  });
  order.name = orders;
  return (
    <div>
      <GridIcons data={Cards} />
      <CampaignReport
        object={
          clientLeadsCampaign.campaigns ? (
            <MultiLinks
              type="organizations"
              object={getOrgsInCampaigns(clientLeadsCampaign.campaigns)}
            ></MultiLinks>
          ) : (
            clientLeadsCampaign.organization_name
          )
        }
        label={"ACCOUNT"}
      />
          <CampaignReport
        object={
          clientLeadsCampaign.campaigns[0].order.name ? (
            OrderData ? (
              <MultiLinks type="orders" object={OrderData}></MultiLinks>
            ) : (
              order.name
            )
          ) : (
            "-"
          )
        }
        label={"ORDER"}
      />

      <CampaignReport
        object={
          clientLeadsCampaign.campaign_name ? (
            <Link
              type="campaigns"
              urlParam={clientLeadsCampaign.campaign_uuid}
              refValue={clientLeadsCampaign.campaign_name}
            ></Link>
          ) : (
            ""
          )
        }
        label={"CAMPAIGN NAME"}
      />

      <CampaignReport
        object={clientLeadsCampaign.start_date ? getDisplayDate(clientLeadsCampaign.start_date) : " - "}
        label={"START DATE"}
      />
      <CampaignReport
        object={clientLeadsCampaign.end_date ? getDisplayDate(clientLeadsCampaign.end_date) : " - "}
        label={"END DATE"}
      />

      {clientLeadsCampaign.demand_generation ? (
        <DemandGeneration data={clientLeadsCampaign.demand_generation} />
      ) : (
        ""
      )}
       <CampaignReport
        object={
          clientLeadsCampaign.excluded_competitors
            ? getCommaSeparatedVal(clientLeadsCampaign.excluded_competitors)
            : " - "
        }
        label={"EXCLUDED COMPETITORS"}
      />
    </div>
  );
};

export default LeadsCampaign;
