import React from "react";
import { Confirm, Message } from "semantic-ui-react";
import SearchFilter from "../components/sponsoredContent/searchFilter";
import MainSCPageDetails from "../components/sponsoredContent/scPageDetails/MainSCPageDetails";
import SponsoredCampaignPageviewDetails from "../components/sponsoredContent/scPageviewDetails/sponsoredCampaignPageviewDetails";
import SponsoredcampaignSummary from "../components/sponsoredContent/scSummaryTable/sponsoredcampaignSummary";
import { url } from "../api/urlHelper";
import _ from "lodash";
import * as actionTypes from "../actions/actionTypes";
import SponsoredChart from "../components/sponsoredContent/sponsoredChart";
import { sponsoredContentCsvLoading, fetchPageviewTableData, selSponsoredContentFilterValue, selSCToggles } from "../actions";
import { connect } from "react-redux";
import NotifyEmailConfirmation from "../components/common/notifyEmailConfirmation";
import Toggle from "../components/base/toggle";
import DownloadXLSX from "../components/common/downloadXLSX";
import { getDays, getReportsByElement } from "../utilities/common";
import { downloadCSVData } from "../utilities/common";
import { ROLEBASED_ELEMENT_SPONSORED, SPONSORED_CAMPAIGN_PAGE_DETAILS, SPONSORED_CAMPAIGN_PAGE_VIEW_DETAILS, SPONSORED_CAMPAIGN_SUMMARY, SPONSORED_CHART } from "../components/config/rolebasedReportNames";
import { CHART, DOWNLOAD_CSV, GENERATE_XLSX, VIEW } from "../components/config/const";

class SponsoredContent extends React.Component {
  state = {
    popup: false,
  };

  getCamppaignSummaryCsv = async () => {
    this.props.sponsoredContentCsvLoading(
      actionTypes.CAMPAIGNSUMMARYCSVLOADING
    );
    await this.props.fetchPageviewTableData(
      url.sponsoredSummary,
      this.props.filterData,
      1,
      "CSV",
      "",
      actionTypes.SCCAMPAIGNSUMMARYCSV
    );
    if (!_.isEmpty(this.props.scCampaignSummaryCsv)) {
      downloadCSVData(this.props.scCampaignSummaryCsv, this.props.downloadFileName)
    }
  };

  getCampaignPageDetailsCsv = async () => {
    this.props.sponsoredContentCsvLoading(
      actionTypes.CAMPAIGNPAGEDETAILSCSVLOADING
    );
    await this.props.fetchPageviewTableData(
      url.sponsoredPageDetails,
      this.props.filterData,
      1,
      "CSV",
      "",
      actionTypes.SCCAMPAIGNPAGEVIEWDETAILSCSV
    );
    if (!_.isEmpty(this.props.scCampaignPageviewDetailsCsv)) {
      downloadCSVData(this.props.scCampaignPageviewDetailsCsv, this.props.downloadFileName)
    }
  };

  getDetailedPageviewExcel = () => {
		this.props.sponsoredContentCsvLoading(actionTypes.CAMPAIGNSUMMARYCSVLOADING);
    DownloadXLSX(
      actionTypes.SCEMAILCAMAPIGNSUMMARY, 
      "sponsored_summary_report", 
      "sponsored_summary",
      this.props.filterData,
      this.props.userEmail,
      actionTypes.SCDETAILEXCEL,
      this.props.fetchPageviewTableData
    );
};

getCampaignPageviewDetailsExcel = () => {
		this.props.sponsoredContentCsvLoading(actionTypes.CAMPAIGNPAGEDETAILSCSVLOADING);
    DownloadXLSX(
      actionTypes.SCEMAILCAMAPIGNSUMMARY, 
      "campaign_pageview_details", 
      "sponsored_pageview",
      this.props.filterData,
      this.props.userEmail,
      actionTypes.SCDETAILEXCEL,
      this.props.fetchPageviewTableData
    );
};

getExcelReport = (btnSpecificAction, table_name) => {
  let days = getDays(this.props.filterData.startDate, this.props.filterData.endDate);
  if (btnSpecificAction === actionTypes.SCEMAILCAMPAIGNPAGEDETAILS && days > 90) {
    this.setState({
      popup: true,
    });
    setTimeout(() => {
      this.setState({
        popup: false,
      });
    }, 5000);
  } else {
    DownloadXLSX(
      btnSpecificAction, 
      table_name, 
      "sponsored-content",
      this.props.filterData,
      this.props.userEmail,
      actionTypes.SCDETAILEXCEL,
      this.props.fetchPageviewTableData
    );
  }
};

getCampaignPageDetailsExcel = () => {
  this.props.sponsoredContentCsvLoading(actionTypes.CAMPAIGNPAGEDETAILSCSVLOADING);
  DownloadXLSX(
    actionTypes.SCEMAILCAMPAIGNPAGEDETAILS, 
    "campaign_page_details", 
    "sponsored_page",
    this.props.filterData,
    this.props.userEmail,
    actionTypes.SCDETAILEXCEL,
    this.props.fetchPageviewTableData
  );
};

handleConfirm = () => this.setState({ popup: false });

  render() {
    let tableView;
    let detailDownloadButton;
    let downloadButtonCSV;
    let downloadButtonPageDetailsCSV;
    let detailDownloadButtonPageDetails;
    let csvDetailDownloadButtonPageviewDetails;
		const roleReports = getReportsByElement(this?.props?.roleFilterData?.currentUserReportRoleData, this?.props?.roleFilterData?.getAllReports, ROLEBASED_ELEMENT_SPONSORED);

    if (this.state.popup) {
      return (
        <Confirm
          open={true}
          cancelButton={null}
          content={
            "Please select Date Range which should not exceed day count more than 90 days"
          }
          onConfirm={this.handleConfirm}
        ></Confirm>
      );
    }

    if (!this.props.isFilApplied) {
      tableView = <Message>Please proceed with applying filter.</Message>;
    } 
	 if (this.props.isFilApplied) {
	  
    downloadButtonCSV = (
			<div className="csv-button">
			  <button
				className="download-button"
				disabled={false}
				onClick={() => this.getCamppaignSummaryCsv()}
			  >
				Download CSV
			  </button>
			</div>
		  );
		  detailDownloadButton = (
			<div>
			  <button
				className="download-button"
				disabled={false}
				onClick={() => this.getDetailedPageviewExcel()}
			  >
				Generate XLSX
			  </button>
			</div>
		  );
    
      csvDetailDownloadButtonPageviewDetails = (
        <div>
          <button
          className="download-button"
          disabled={false}
          onClick={() => this.getExcelReport(actionTypes.SCEMAILCAMPAIGNPAGEDETAILS, "sponsored_pageview_details_table")}
          >
          Generate CSV
          </button>
        </div>
      );
    }
    
    downloadButtonPageDetailsCSV = (
      <div className="csv-button">
        <button
          className="download-button"
          disabled={false}
          onClick={() => this.getCampaignPageDetailsCsv()}
        >
          Download CSV
        </button>
      </div>
    );
    detailDownloadButtonPageDetails = (
      <div>
        <button
        className="download-button"
        disabled={false}
        onClick={() => this.getCampaignPageDetailsExcel()}
        >
        Generate XLSX
        </button>
      </div>
      );
    
    return (
      <div>
        <div className="page-name-comp">
          <p className="page-name">Sponsored</p>
          <p>{this.props.reportDescription.find(report => report.name === "Sponsored Content Pageviews")?.description}</p>
        </div>
        <SearchFilter roleReports={roleReports}/>
        {
          (roleReports?.some((report) => [SPONSORED_CHART].includes(report.element) && report.permission === CHART)) &&
          <SponsoredChart />
        }
        
        <div>
          {
            roleReports?.some(
							(report) =>
								[SPONSORED_CAMPAIGN_SUMMARY].includes(report.element) &&
								[VIEW, DOWNLOAD_CSV, GENERATE_XLSX].includes(report.permission)
						) &&
            <div className="table-heading-block">
              <div className="table-name">
                <p> Sponsored Content Summary</p>
              </div>
              {
                this.props.isFilApplied && (roleReports?.some((report) => [SPONSORED_CAMPAIGN_SUMMARY].includes(report.element) && report.permission === DOWNLOAD_CSV)) &&
                <div className="csv-button">
                  {downloadButtonCSV}
                </div>
              }
              {
                this.props.isFilApplied && (roleReports?.some((report) => [SPONSORED_CAMPAIGN_SUMMARY].includes(report.element) && report.permission === GENERATE_XLSX)) &&
                <div className="class-relative">
                  <div className="pg detail-excel-button">
                    {detailDownloadButton}
                  </div>
                  <div className="pg popup">
                    {this.props.postEmailUpdatePageSummary ? (
                      <NotifyEmailConfirmation
                        email={this.props.postEmailUpdatePageSummary}
                        table="campaign_summary"
                        cardPopup={this.props.postEmailUpdatePageSummary}
                      />
                    ) : null}
                  </div>
                </div>
              }
              
            </div>
          }
          {
            (roleReports?.some((report) => [SPONSORED_CAMPAIGN_SUMMARY].includes(report.element) && report.permission === VIEW)) &&
            <SponsoredcampaignSummary />
          }
          
          {
            roleReports?.some(
							(report) =>
								[SPONSORED_CAMPAIGN_PAGE_DETAILS].includes(report.element) &&
								[VIEW, DOWNLOAD_CSV, GENERATE_XLSX].includes(report.permission)
						) &&
            <div className="table-heading-block">
              <div className="table-name">
                <p> Sponsored Content Campaign Details</p>
              </div>
              {
                this.props.isFilApplied && (roleReports?.some((report) => [SPONSORED_CAMPAIGN_PAGE_DETAILS].includes(report.element) && report.permission === DOWNLOAD_CSV)) &&
                <div className="csv-button">
                  {downloadButtonPageDetailsCSV}
                </div>
              }
              {
                this.props.isFilApplied && (roleReports?.some((report) => [SPONSORED_CAMPAIGN_PAGE_DETAILS].includes(report.element) && report.permission === GENERATE_XLSX)) &&
                <div className="class-relative">
                  <div className="pg detail-excel-button">
                    {detailDownloadButtonPageDetails}
                  </div>
                  <div className="pg popup">
                    {this.props.postEmailUpdatePageviewDetail ? (
                      <NotifyEmailConfirmation
                        email={this.props.postEmailUpdatePageviewDetail}
                        table="campaign_page_details"
                        cardPopup={this.props.postEmailUpdatePageviewDetail}
                      />
                    ) : null}
                  </div>
                </div>
              }
            </div>
          }
          {
            (roleReports?.some((report) => [SPONSORED_CAMPAIGN_PAGE_DETAILS].includes(report.element) && report.permission === VIEW)) &&
            <MainSCPageDetails />
          }
          
          {
            roleReports?.some(
							(report) =>
								[SPONSORED_CAMPAIGN_PAGE_VIEW_DETAILS].includes(report.element) &&
								[VIEW, DOWNLOAD_CSV, GENERATE_XLSX].includes(report.permission)
						) &&
            <div className="table-heading-block">
              <div className="table-name">
                <p>Sponsored Content Page Details</p>
              </div>
                <div className="class-relative">
                  {
                    this.props.isFilApplied && (roleReports?.some((report) => [SPONSORED_CAMPAIGN_PAGE_VIEW_DETAILS].includes(report.element) && report.permission === GENERATE_XLSX)) &&
                    <React.Fragment>
                      <div className="pg detail-excel-button">
                        {csvDetailDownloadButtonPageviewDetails}
                        { this.state.popup &&
                          <Confirm 
                              open={true}
                              cancelButton = {null}
                              content={"Please select Date Range which should not exceed day count more than 90 days"}
                              onConfirm={this.handleConfirm}
                          >
                          </Confirm>
                        }
                      </div>
                      <div className="pg popup">
                        {this.props.postEmailCsvUpdatePageviewDetails ? (
                          <NotifyEmailConfirmation
                            email={this.props.postEmailCsvUpdatePageviewDetails}
                            table="campaign_pageview_details_csv_table"
                            cardPopup={this.props.postEmailCsvUpdatePageviewDetails}
                            csvExcel={true}
                          />
                        ) : null}
                      </div>
                    </React.Fragment>
                  }
                  
                </div>
              {
                (roleReports?.some((report) => [SPONSORED_CAMPAIGN_PAGE_VIEW_DETAILS].includes(report.element) && report.permission === VIEW)) &&
                <Toggle
                  label={this.props.filterData.selectCampPageviewDetailsTable ? "Hide" : "Show"}
                  disabled = {!this.props.isFilApplied}
                  style={{ marginBottom: "20px", paddingLeft: "30px", paddingTop: "10px"}}
                      onChange={() => {
                        this.props.selSCToggles(actionTypes.SELSCCAMPPAGEVIEWDETAILSTABLETOGGLE);
                      }}
                  checked={this.props.filterData.selectCampPageviewDetailsTable}
                />
              }
            </div>
          }
          
          {
            (roleReports?.some((report) => [SPONSORED_CAMPAIGN_PAGE_VIEW_DETAILS].includes(report.element) && report.permission === VIEW)) &&
            <SponsoredCampaignPageviewDetails />
          }
          
        </div>
		{tableView}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFilApplied: state.sponsoredContentFetchData.isFilApplied,
    pageviewCharts: state.sponsoredContentFetchData.pageviewCharts,
    pageviewDetail: Object.values(
      state.sponsoredContentFetchData.pageviewDetail
    ),
    pageviewDetailed: Object.values(
      state.sponsoredContentFetchData.pageviewDetailed
    ),
    filterData: state.sponsoredContentFilterData,
    scCampaignSummaryCsv: state.sponsoredContentFetchData.scCampaignSummaryCsv,
    scCampaignPageviewDetailsCsv:
      state.sponsoredContentFetchData.scCampaignPageviewDetailsCsv,
    isLoadingCampaignSummaryCsv:
      state.sponsoredContentFetchData.isLoadingCampaignSummaryCsv,
    isLoadingCampaignPageviewDetailsCsv:
      state.sponsoredContentFetchData.isLoadingCampaignPageviewDetailsCsv,
    emailPageSummary: state.sponsoredContentFetchData.emailPageSummary,
    emailPageDetail: state.sponsoredContentFetchData.emailPageDetail,
    newEmail: state.sponsoredContentFetchData.newEmail,
    confirmEmail: state.sponsoredContentFetchData.confirmEmail,
    cardPopupPageSummary: state.sponsoredContentFetchData.cardPopupPageSummary,
    cardPopupPageDetail: state.sponsoredContentFetchData.cardPopupPageDetail,
    postEmailUpdate: state.sponsoredContentFetchData.postEmailUpdate,
    postEmailUpdatePageSummary:
      state.sponsoredContentFetchData.postEmailUpdatePageSummary,
    userEmail: state.authorized.userEmail,
    sponsoredContentFilterData: state.sponsoredContentFilterData,
    scSummary: state.sponsoredContentFetchData.scSummary,
    scCampaignDetail: state.sponsoredContentFetchData.scCampaignDetail,
    scCampaignPageDetail: state.sponsoredContentFetchData.scCampaignPageDetail,
    postEmailUpdatePageviewDetails: state.sponsoredContentFetchData.postEmailUpdatePageviewDetails,
    emailPageDetails : state.sponsoredContentFetchData.emailPageDetails,
    cardPopupPageDetails: state.sponsoredContentFetchData.cardPopupPageDetails,
    postEmailUpdatePageviewDetail: state.sponsoredContentFetchData.postEmailUpdatePageviewDetail,
    postEmailCsvUpdatePageviewDetails: state.sponsoredContentFetchData.postEmailCsvUpdatePageviewDetails,
    downloadFileName: state.sponsoredContentFetchData.downloadFileName,
    reportDescription: state.allFilterReportData.reportDescription,
    roleFilterData: state.allFilterReportData,
  };
};

export default connect(mapStateToProps, {
  fetchPageviewTableData,
  sponsoredContentCsvLoading,
  selSponsoredContentFilterValue,
  selSCToggles
})(SponsoredContent);
