import React from "react";
import { Table } from "semantic-ui-react";

const NewsletterSlotItemComponent = ({ data }) => {

    // Determine what to render based on conditions
    const renderContent = () => {
        if (data?.domain !== "" && data.page_title !== "" && data.page_title !== "N/A") {
            return (
                <a href={`https://${data?.domain}${data?.page_path}`} target="_blank" rel="noreferrer noopener">
                    {data?.page_title}
                </a>
            );
        } else if (data?.page_title === "" && data?.page_path !== "" && data?.page_path !== "N/A") {
            return (
                <a href={`https://${data?.domain}${data?.page_path}`} target="_blank" rel="noreferrer noopener">
                    {data?.page_path}
                </a>
            );
        } else if (data?.domain === "" && data?.page_path !== "" && data?.domain !== "N/A") {
            return (
                <a href={`${data?.page_path}`} target="_blank" rel="noreferrer noopener">
                    {data?.page_title}
                </a>
            );
        } else if(data?.domain === "" && data?.page_path === "" && data?.page_title !== "") {
            return data?.page_title
        } else if(data?.page_title === "N/A" && data.domain === "N/A") {
            return "N/A";
        }
        return null; // Render nothing if none of the conditions match
    };

    const cellClassNames = (data?.domain === "" || data?.page_path === "" || data?.page_title !== "" && data?.page_title === "N/A") ? "" : "blogs_link space_height";

    return (
        <Table.Cell title={data?.page_title} className={cellClassNames}>
            {renderContent()}
        </Table.Cell>
    );
};

export default NewsletterSlotItemComponent;
