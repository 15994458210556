import _ from "lodash";
import React, { useEffect } from "react";
import { Form } from "semantic-ui-react";
import DatePicker from "../common/datePicker";
import { useState } from "react";
import { useLocation } from "react-router-dom";
//import { addDays } from 'date-fns';
import {
	fetchOrganizations,
	fetchOrder,
	addOrganization,
	addCampaigns,
	addOrder,
	fetchTitle,
	fetchCampaigns,
	resetOrgsOrCampaigns,
	resetOrder,
	clearSearchOrgs,
	clearSearchCampaigns,
	clearSearchOrder,
	selLeadStartDate,
	selLeadEndDate,
	selLeadPartner,
	applyLeadDate,
	selLeadPageTitle,
	selLeadOrgs,
	selLeadCampaigns,
	selLeadOrder,
	selLeadEngOrgs,
	selLeadStatus,
	selLeadSite,
	selLeadType,
	selLeadToggles,
	applyLeadFilter,
	clearLeadFilter,
	fetchLeadEngSummary,
	//fetchLeadEngCampaignSummary,
	fetchLeadEngs,
	prevLeadSelEndDate,
	prevLeadSelStartDate,
	prevLeadAppliedDate,
	resetLeadToggles,
	fetchUrlParams,
	clearLeadEngSerchedValue,
	selLeadEngFilterValue,
	selDropDownFilters,
	fetchLeadValidationChart,
	selGlobalLeadEngsStatus,
	selGlobalLeadEngsVerificationState,
	fetchLeadValidationBarChart,
	selClientLeadEngState,
	selPacingEngsFilterValue,
	fetchEngagedOrg,
	clientSelectDropDown,
	selectToggleValue
} from "../../actions";
import { connect } from "react-redux";
import "../../css/common.css";
import { DateRangePicker } from "react-date-range";
import {
	getDisplayDate,
	getId,
	getYesterdaysDate,
	getStartEndDates,
	isUUID,
} from "../../utilities/common";
import OrganizationPicker from "../common/organization";
import LeadPartnerPicker from "../common/leadpartner";
import CampaignPicker from "../common/campaign";
import OrderPicker from "../common/order";
import SitePicker from "../common/site";
import StatusPicker from "../common/status";
import TypePicker from "../common/assetType";
import EngagedOrgPicker from "../common/engagedOrganization";
import * as actionTypes from "../../actions/actionTypes";
import Toggle from "../base/toggle";
import { url } from "../../api/urlHelper";
import { compose } from "redux";
import PageTitlePicker from "../common/pageTitle";
import UrlParamsPicker from "../common/urlParams";
import LeadGraph from "./leadGraph";
import SingleSelectDropDown from "../base/singleSelectDropDown";
import { multipleOptions, options } from "../common/filterOptions";
import DeliveryStatePicker from "../common/deliveryState";
import PacingFilter from "../common/pacingFilter";
import SearchFilterIcon from "../common/SearchFilterIcon";
import DomainFilter from "../common/DomainFilter";
import CompetitorFilterToggleForOthers from "../common/CompetitorFilterToggleForOthers";
import CSMSelection from "../common/CSMSelection";
import PrivacyParametersComponent from "../common/PrivacyParametersComponent";
import { ENGAGEMENT_CAMPAIGN_SUMMARY, ENGAGEMENT_WORKBOOK } from "../config/rolebasedReportNames";
import { VIEW } from "../config/const";
// import TrafficFilterPicker from "../common/trafficeFilter";

const SearchFilter = (props) => {
	const [datePopup, setDatePopup] = useState("custom-date-popup-close");
	const [filterPopup, setFilterPopup] = useState("filter-popup-close");
	const [filterSelectFocus, setFilterSelectFocus] = useState("filter-icon");
	const [filterIconColor, setFilterIconColor] = useState("material-icons grey");

	const [dateRange, setDateRange] = useState({
		selection: {
			startDate: getYesterdaysDate(),
			endDate: getYesterdaysDate(),
			key: "selection",
		},
	});
	const [verificationStateOptions, setVerificationStateOptions] = useState([
		multipleOptions[0],
	  ]);

	const location = useLocation();
	const paramId = getId(location.hash);
	const urlId = location.pathname + location.hash;
	useEffect(() => {
		initialLoad();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(()=>{
		if(props.leadFilterData.selLeadEngChart ){
			props.fetchLeadValidationChart(
				props.leadFilterData,
				1,
				"",
				actionTypes.ENGSVALIDATIONCHART,
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [props.isFilApplied, props.leadFilterData.selLeadEngChart ])

	  useEffect(()=>{
		if(props.leadFetchData.engsStatusChart){
			props.fetchLeadValidationBarChart(
				props.leadFilterData,
				1,
				"",
				actionTypes.LEADENGSBARCHART,
				false
			);	
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [props.isFilApplied, props.leadFetchData.engsStatusChart ])

	  useEffect(()=>{
		if(props.leadFetchData.engsAcceptanceChart){
			props.fetchLeadValidationBarChart(
				props.leadFilterData,
				1,
				"",
				actionTypes.LEADENGSACCEPTANCEBARCHART,
				false
			);	
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [props.isFilApplied, props.leadFetchData.engsAcceptanceChart])


	  useEffect(()=>{
		const updateState = multipleOptions.filter((name) => (props.leadFilterData.leadEngsFilterStatus === "All") ? name.allkey === props.leadFilterData.leadEngsFilterStatus : name.keyvalue === props.leadFilterData.leadEngsFilterStatus)
		setVerificationStateOptions(updateState);
		//props.leadFilterData.leadEngsFilterVerificationState === "All" && props.selDropDownFilters("", actionTypes.SELLEADENGSFILTERSSTATE);
			// eslint-disable-next-line react-hooks/exhaustive-deps

	  }, [props.leadFilterData.leadEngsFilterStatus]) // eslint-disable-line react-hooks/exhaustive-deps



	const initial = () => {
		props.applyLeadFilter();
		props.fetchLeadEngSummary(
			url.engagementSummary,
			props.leadFilterData,
			1,
			"",
			actionTypes.LEADENGSUMMARY
		);
		// props.fetchLeadEngSummary(
		// 	url.leadEngagementCount,
		// 	props.leadFilterData,
		// 	1,
		// 	"",
		// 	actionTypes.LEADENGSUMMARYCOUNT
		// );
		props.fetchLeadEngs(props.leadFilterData, 1, "", actionTypes.LEADENGS, "","");
	};

	const initialLoad = () => {
		if (
			urlId === `/engagements/#/?campaign_uuid=${paramId}` &&
			isUUID(paramId)
		) {
			props.fetchCampaigns(
				"",
				props.leadFilterData.site,
				props.leadFilterData.selTagsIN,
				props.leadFilterData.selTagsEX,
				props.leadFilterData.excludeQACampaign,
				"",
				props.leadFilterData.organization,
				props.leadFilterData.campaignStatus,
				//props.topicFilterData.assetType,
				"name",
				actionTypes.READENGAGEMENTCAMPID,
				[paramId],
				"",
				""
			);
			props.selLeadCampaigns([paramId]);
			if (props.leadFilterData.campaign.includes(paramId)) {
				initial();
			}
		}
	};

	const selectDateRange = async (e, { value }) => {
		props.applyLeadDate(value);
		setDatePopup("custom-date-popup-close");
		if (value === "Custom Date Range") {
			setDatePopup("custom-date-popup-open");
		} else {
			let dates = getStartEndDates(value);
			props.selLeadStartDate(dates.startDate);
			props.selLeadEndDate(dates.endDate);
			props.prevLeadSelStartDate(dates.startDate);
			props.prevLeadSelEndDate(dates.endDate);
			props.prevLeadAppliedDate(value);
			if (props.isFilApplied) {
				applyFilters(dates.startDate, dates.endDate, value);
			}
		}
	};

	const applyDate = () => {
		props.selLeadStartDate(getDisplayDate(dateRange.selection.startDate));
		props.selLeadEndDate(getDisplayDate(dateRange.selection.endDate));
		setDatePopup("custom-date-popup-close");
		props.prevLeadSelStartDate(dateRange.selection.startDate);
		props.prevLeadSelEndDate(dateRange.selection.endDate);
		props.prevLeadAppliedDate("Custom Date Range");
		if (props.isFilApplied) {
			applyFilters(
				dateRange.selection.startDate,
				dateRange.selection.endDate,
				props.leadFilterData.appliedDate
			);
		}
	};

	const cancelDate = () => {
		setDateRange({
			selection: {
				startDate: new Date(),
				endDate: new Date(),
				key: "selection",
			},
		});
		props.selLeadStartDate(getDisplayDate(props.leadFilterData.prevstartDate));
		props.selLeadEndDate(getDisplayDate(props.leadFilterData.prevendDate));
		if (props.leadFilterData.prevAppliedDate === "Custom Date Range") {
			props.applyLeadDate("Custom Date Range");
		} else {
			props.applyLeadDate(props.leadFilterData.prevAppliedDate);
		}
		setDatePopup("custom-date-popup-close");
	};

	//Organization event handlers

	const onClickOrg = () => {
		props.clearSearchOrgs(actionTypes.CLEARSEARCHLEADORGS);
	};

	const searchOrg = (e) => {
		if (e.target.value.length > 1) {
			props.fetchOrganizations(
				props.leadFilterData.excludeQACampaign,
				e.target.value,
				actionTypes.FETCHLEADORGS,
				props.organizations,
			);
		}
	};

	const onSelectOrg = (e, { value }) => {
		props.selLeadOrgs(value);
		props.addOrganization(value, actionTypes.ADDLEADORGS);
		if (!_.isEmpty(props.leadFilterData.campaign)) {
			props.fetchCampaigns(
				"",
				props.leadFilterData.site,
				props.leadFilterData.selTagsIN,
				props.leadFilterData.selTagsEX,
				props.leadFilterData.excludeQACampaign,
				"",
				value,
				props.leadFilterData.campaignStatus,
				// props.leadFilterData.assetType,
				"start_date",
				actionTypes.FETCHSELLEADCAMPAIGNS,
				props.leadFilterData.campaign,
				true,
				actionTypes.RESETSELLEADCAMPAIGNS
			);
		}
	};

	//Campaign status event handlers

	const onSelectCampaignStatus = (e, { value }) => {
		props.selLeadStatus(value);
		props.resetOrgsOrCampaigns(actionTypes.RESETLEADCAMPAIGN);
		props.selLeadCampaigns([]);
	};

	//Campaign event handlers

	const onClickCampaign = () => {
		if (_.isEmpty(props.leadFilterData.organization)) {
			props.clearSearchCampaigns(actionTypes.CLEARSEARCHLEADCAMPAIGN);
		} else if (!_.isEmpty(props.leadFilterData.organization)) {
			props.fetchCampaigns(
				"",
				props.leadFilterData.site,
				props.leadFilterData.selTagsIN,
				props.leadFilterData.selTagsEX,
				props.leadFilterData.excludeQACampaign,
				"",
				props.leadFilterData.organization,
				props.leadFilterData.campaignStatus,
				// props.leadFilterData.assetType,
				"start_date",
				actionTypes.FETCHLEADCAMPAIGNS
			);
		}
	};

	const searchCampaign = (e, { value }) => {
		if (e.target.value.length > 1) {
			props.fetchCampaigns(
				"",
				props.leadFilterData.site,
				props.leadFilterData.selTagsIN,
				props.leadFilterData.selTagsEX,
				props.leadFilterData.excludeQACampaign,
				e.target.value,
				props.leadFilterData.organization,
				props.leadFilterData.campaignStatus,
				//props.leadFilterData.assetType,
				"start_date",
				actionTypes.FETCHLEADCAMPAIGNS
			);
		}
	};

	const onSelectCampaigns = (e, { value }) => {
		props.addCampaigns(value, actionTypes.ADDLEADCAMPAIGNS);
		props.selLeadCampaigns(value);
		// if (value.length === 1) {
		//   let obj = props.campaigns.find((o) => o.uuid === value[0]);
		//   const campaignStartDate = new Date(obj.start_date);
		//   props.selLeadStartDate(getDisplayDate(campaignStartDate));
		//   props.selLeadEndDate(getDisplayDate(addDays(campaignStartDate, 30)));
		//   props.applyLeadDate('Custom Date Range');
		// } else {
		// props.applyLeadDate(getDisplayDate(getLastDays(7)));
		// props.selLeadStartDate(getDisplayDate(getLastDays(7)));
		// props.selLeadEndDate(getDisplayDate(getYesterdaysDate()));
		// }
	};

	//Asset type event handlers

	const onSelectType = (e, { value }) => {
		props.selLeadType(value);
		if (value !== "all") {
			props.resetOrgsOrCampaigns(actionTypes.RESETLEADCAMPAIGN);
			props.selLeadCampaigns([]);
		}
	};

	//Site event handlers

	const onSelectSite = (e, { value }) => {
		props.selLeadSite(value);
	};

	//Organization event handlers
	const onClickEngOrg = () => {
		// if (_.isEmpty(props.leadFilterData.organization)) {
		// 	props.clearSearchOrgs(actionTypes.CLEARSEARCHLEADENGORGS);
		// } else 
		if (!_.isEmpty(props.leadFilterData.organization) && (props.leadFilterData.leadIsIncludeParent || props.leadFilterData.leadIsIncludeChild)) {
			props.fetchEngagedOrg(
				props.leadFilterData.excludeQACampaign,
				"",
				actionTypes.FETCHLEADENGORGS,
				true,
				props.leadFilterData.organization,
				props.leadFilterData.leadIsIncludeParent,
				props.leadFilterData.leadIsIncludeChild,
			);
		}
	  };

	const searchEngOrg = (e, { value }) => {
		if (e.target.value.length > 1) {
		  props.fetchEngagedOrg(
			props.leadFilterData.excludeQACampaign,
			e.target.value,
			actionTypes.FETCHLEADENGORGS,
			true,
			props.leadFilterData.organization,
			props.leadFilterData.leadIsIncludeParent,
			props.leadFilterData.leadIsIncludeChild,
		  );
		}
	  };

	const onSelectEngOrg = (e, { value }) => {
		props.selLeadEngOrgs(value);
		props.addOrganization(value, actionTypes.ADDLEADENGORGS);
	};

	//Order event handlers

	const onSelectOrder = (e, { value }) => {
		props.selLeadOrder(value);
		props.addOrder(value, actionTypes.ADDLEADENGORDER);
	};
	const searchOrder = (e) => {
		if (e.target.value.length > 1) {
			props.fetchOrder(e.target.value, "name", actionTypes.FETCHLEADENGORDER);
		}
	};

	const onClickOrder = () => {
		props.clearSearchOrder(actionTypes.CLEARSEARCHLEADENGORDER);
	};

	const onSelectLeadPartner = (e, { value }) => {
		props.selLeadPartner(value);
	};

	//PageTitlePicker
	// orgValues,
	// 	orders,
	// 	campValues,
	// 	campaignStatus,
	// 	site,
	// 	queryString,
	// 	isLeadClinet,
	// 	assetType,
	// dispatchType;
	// orders: leadFilterData.order;
	const onClickPageTitle = () => {
		if (
			_.isEmpty(
				props.leadFilterData.organization &&
					props.leadFilterData.campaign &&
					props.leadFilterData.order
			)
		) {
			props.clearSearchCampaigns(actionTypes.CLEARSEARCHLEADPAGETITLE);
		} else {
			props.fetchTitle(
				props.leadFilterData.organization,
				props.leadFilterData.order,
				props.leadFilterData.campaign,
				props.leadFilterData.campaignStatus,
				props.leadFilterData.site,
				"",
				false,
				props.leadFilterData.assetType,
				actionTypes.FETCHLEADPAGETITLE
			);
		}
	};

	const searchPageTitle = (e, { value }) => {
		if (e.target.value.length > 1) {
			props.fetchTitle(
				props.leadFilterData.organization,
				props.leadFilterData.order,
				props.leadFilterData.campaign,
				props.leadFilterData.campaignStatus,
				props.leadFilterData.site,
				e.target.value,
				false,
				props.leadFilterData.assetType,
				actionTypes.FETCHLEADPAGETITLE
			);
		}
	};

	const onSelectPageTitle = (e, { value }) => {
		props.addCampaigns(value, actionTypes.ADDLEADPAGETITLE);
		props.selLeadPageTitle(value);
	};

	//URL source event handlers
	const onClickUrlSource = () => {
		if (_.isEmpty(props.leadFilterData.selUrlSource)) {
			props.clearLeadEngSerchedValue(actionTypes.CLEARSEARCHLEADENGURLSOURCE);
		}
	};

	const searchUrlSource = (e) => {
		if (e.target.value.length > 1) {
			props.fetchUrlParams(
				e.target.value,
				"source",
				actionTypes.FETCHLEADENGURLSOURCE,
				"",
				"engagement"
			);
		} else {
			props.clearLeadEngSerchedValue(actionTypes.CLEARSEARCHLEADENGURLSOURCE);
		}
	};
	const onSelectUrlSource = (e, { value }) => {
		// props.resetLeadEngDropdownValue(actionTypes.RESETSELPGURLCAMPANDMEDIUM);
		// props.clearPgSerchedValue(actionTypes.CLEARSEARCHPGURLCAMPAIGNS);
		// props.clearPgSerchedValue(actionTypes.CLEARSEARCHPGURLMEDIUM);
		props.selLeadEngFilterValue(value, actionTypes.SELLEADENGURLSOURCE);
	};
	//URL medium event handlers
	const searchUrlMedium = (e) => {
		if (e.target.value.length > 1) {
			props.fetchUrlParams(
				e.target.value,
				"medium",
				actionTypes.FETCHLEADENGURLMEDIUM,
				props.leadFilterData.selUrlSource,
				"engagement"
			);
		} else {
			props.clearLeadEngSerchedValue(actionTypes.CLEARSEARCHLEADENGURLMEDIUM);
		}
	};
	const onClickUrlMedium = () => {
		if (_.isEmpty(props.leadFilterData.selUrlMedium)) {
			props.clearLeadEngSerchedValue(actionTypes.CLEARSEARCHLEADENGURLMEDIUM);
		}
	};
	const onSelectUrlMedium = (e, { value }) => {
		props.selLeadEngFilterValue(value, actionTypes.SELLEADENGURLMEDIUM);
	};
	// URL campaign event handlers
	const onClickUrlCampaign = () => {
		if (_.isEmpty(props.leadFilterData.selUrlCampaign)) {
			props.clearLeadEngSerchedValue(
				actionTypes.CLEARSEARCHLEADENGURLCAMPAIGNS
			);
		}
	};

	const searchUrlCampaigns = (e) => {
		if (e.target.value.length > 1) {
			props.fetchUrlParams(
				e.target.value,
				"campaign",
				actionTypes.FETCHLEADENGURLCAMPAIGNS,
				props.leadFilterData.selUrlSource,
				"engagement"
			);
		} else {
			props.clearLeadEngSerchedValue(
				actionTypes.CLEARSEARCHLEADENGURLCAMPAIGNS
			);
		}
	};

	const onSelectUrlCampaigns = (e, { value }) => {
		props.selLeadEngFilterValue(value, actionTypes.SELLEADENGURLCAMPAIGNS);
	};

	// URL Term event handlers
	const onClickUrlTerm = () => {
		if (_.isEmpty(props.leadFilterData.selUrlTerm)) {
			props.clearLeadEngSerchedValue(actionTypes.CLEARSEARCHLEADENGURLTERM);
		}
	};

	const searchUrlTerm = (e) => {
		if (e.target.value.length > 1) {
			props.fetchUrlParams(
				e.target.value,
				"term",
				actionTypes.FETCHLEADENGURLTERM,
				props.leadFilterData.selUrlSource,
				"engagement"
			);
		} else {
			props.clearLeadEngSerchedValue(actionTypes.CLEARSEARCHLEADENGURLTERM);
		}
	};

	const onSelectUrlTerm = (e, { value }) => {
		props.selLeadEngFilterValue(value, actionTypes.SELLEADENGURLTERM);
	};

	// URL Content event handlers
	const onClickUrlContent = () => {
		if (_.isEmpty(props.leadFilterData.selUrlContent)) {
			props.clearLeadEngSerchedValue(actionTypes.CLEARSEARCHLEADENGURLCONTENT);
		}
	};

	const searchUrlContent = (e) => {
		if (e.target.value.length > 1) {
			props.fetchUrlParams(
				e.target.value,
				"content",
				actionTypes.FETCHLEADENGURLCONTENT,
				props.leadFilterData.selUrlSource,
				"engagement"
			);
		} else {
			props.clearLeadEngSerchedValue(actionTypes.CLEARSEARCHLEADENGURLCONTENT);
		}
	};

	const onSelectUrlContent = (e, { value }) => {
		props.selLeadEngFilterValue(value, actionTypes.SELLEADENGURLCONTENT);
	};

	const applyFilters = (startDate, endDate, appDate) => {
		props.applyLeadFilter();
		props.leadFilterData.startDate = startDate;
		props.leadFilterData.endDate = endDate;
		props.leadFilterData.appliedDate = appDate;
		props.selGlobalLeadEngsStatus("Select");
		props.selGlobalLeadEngsVerificationState("Select");
		if (props?.roleReports?.some((report) => [ENGAGEMENT_CAMPAIGN_SUMMARY].includes(report.element) && report.permission === VIEW)) {
			props.fetchLeadEngSummary(
				url.engagementSummary,
				props.leadFilterData,
				1,
				"",
				actionTypes.LEADENGSUMMARY
			);
		}
		if (props?.roleReports?.some((report) => [ENGAGEMENT_WORKBOOK].includes(report.element) && report.permission === VIEW)) {
			props.fetchLeadEngs(props.leadFilterData, 1, "", actionTypes.LEADENGS);
		}
		if(props.leadFilterData.selLeadEngChart){
			props.fetchLeadValidationChart(
				props.leadFilterData,
				1,
				"",
				actionTypes.ENGSVALIDATIONCHART,
			);
		}
		if(props.leadFetchData.engsStatusChart){
			props.fetchLeadValidationBarChart(
				props.leadFilterData,
				1,
				"",
				actionTypes.LEADENGSBARCHART,
				false
			);	
		}
		if(props.leadFetchData.engsAcceptanceChart){
			props.fetchLeadValidationBarChart(
				props.leadFilterData,
				1,
				"",
				actionTypes.LEADENGSACCEPTANCEBARCHART,
				false
			);	
		}
		setFilterPopup("filter-popup-close");
		setFilterIconColor("material-icons grey");
		setFilterSelectFocus("filter-icon");
		setDatePopup("custom-date-popup-close");
	};

	const clearFilters = () => {
		props.clearLeadFilter(props.delivery_states);
		props.clearSearchCampaigns(actionTypes.CLEARSEARCHLEADPAGETITLE);
		setDateRange({
			selection: {
				startDate: new Date(),
				endDate: new Date(),
				key: "selection",
			},
		});
		setDatePopup("custom-date-popup-close");
		props.selDropDownFilters("", actionTypes.SELLEADENGSFILTERSSTATE);
		const actionTypesToClear = [
			actionTypes.RESETLEADORGS,
			actionTypes.RESETLEADCAMPAIGN,
			actionTypes.RESETLEADENGORDER,
			actionTypes.RESETLEADPAGETITLE,
			actionTypes.RESETLEADENGORGS,
		  ];
	  
		  actionTypesToClear.forEach((actionType) => {
			props.clearLeadEngSerchedValue(actionType);
		  });
	};

	const onSelectFilterStatus = (e, { value }) =>{
		const updateState = multipleOptions.filter((name) => (value === "All") ? name.allkey === value : name.keyvalue === value)
		setVerificationStateOptions(updateState);
		props.selDropDownFilters("", actionTypes.SELLEADENGSFILTERSSTATE);
		if(props.leadFilterData.leadEngsFilterStatus !== value){
			props.selDropDownFilters(value, actionTypes.SELLEADENGSFILTERSTATUS);
		}
	}

	const onSelectDeliveryStatus = (e, { value }) => {
		props.selClientLeadEngState(value);
	};

	const onSelectFilterState = (e, {value}) =>{
		props.selDropDownFilters(value, actionTypes.SELLEADENGSFILTERSSTATE);
	}

	// const onChangeIncludeParent = (e) => {
	// 	if(props.leadFilterData.leadIsIncludeChild){
	// 	  props.selLeadToggles(actionTypes.SELLEADPARENTTOOGLE);
	// 	  props.selLeadToggles(actionTypes.SELLEADCHILDTOOGLE);
	// 	} else {
	// 	  props.selLeadToggles(actionTypes.SELLEADPARENTTOOGLE);
	// 	}
	// }
	
	// const onChangeIncludeChild = (e) => {
	// 	if(props.leadFilterData.leadIsIncludeParent){
	// 		props.selLeadToggles(actionTypes.SELLEADPARENTTOOGLE);
	// 		props.selLeadToggles(actionTypes.SELLEADCHILDTOOGLE);
	// 	} else {
	// 		props.selLeadToggles(actionTypes.SELLEADCHILDTOOGLE);
	// 	}
	// }

	//Pacing filter event handler
	const onSelectPacingFilter = (e, { value }) => {
		props.selPacingEngsFilterValue(value);
	};

	// const onSelectTrafficFilter = (e, { value }) => {
	// 	props.selLeadEngFilterValue(value, actionTypes.SELLEADENGTRAFFIC);
	// }

	return (
		<div>
			<div className="date-picker">
				{/* Date picker */}
				<div>
					<Form>
						<Form.Group widths="equal">
						<DatePicker
						floating
						value={props.leadFilterData.appliedDate}
						onChange={selectDateRange}
						openDateRange={(e, { value }) => {
							if (value === "Custom Date Range") {
								props.applyLeadDate("Custom Date Range");
								setDatePopup("custom-date-popup-open");
							}
						}}
					/>
						<LeadGraph />
						</Form.Group>
					</Form>
				</div>
				{/* Filter icon */}
				<SearchFilterIcon
					filterSelectFocus={filterSelectFocus}
					setFilterSelectFocus={setFilterSelectFocus}
					setFilterPopup={setFilterPopup}
					setFilterIconColor={setFilterIconColor}
					filterIconColor={filterIconColor}
				/>
			</div>
			<div className="popup-component">
				{/* Custom date picker component */}
				<div className={datePopup}>
					<div
						className="date-picker-close-icon"
						onClick={() => {
							setDatePopup("custom-date-popup-close");
						}}
					>
						<i className="material-icons grey">clear</i>
					</div>
					<div>
						<DateRangePicker
							minDate={new Date("2019-12-13")}
							maxDate={new Date()}
							onChange={(item) => {
								setDateRange({ ...dateRange, ...item });
							}}
							months={2}
							moveRangeOnFirstSelection={false}
							direction="horizontal"
							ranges={[dateRange.selection]}
							rangeColors={["#e5582b"]}
						/>
					</div>
					<div className="date-buttons">
						<button type="button" className="cancel-date-button" onClick={cancelDate}>
							Cancel
						</button>
						<button type="button" className="apply-date-button" onClick={applyDate}>
							Apply
						</button>
					</div>
				</div>
				{/* Search filter popup */}
				<div className={filterPopup}>
					<div className="filter-header">
						<div className="filter-text">
							<p>Filters</p>
						</div>
						<div
							className="filter-close-icon"
							onClick={() => {
								setFilterSelectFocus("filter-icon");
								setFilterPopup("filter-popup-close");
							}}
						>
							<i
								className="material-icons grey"
								onClick={() => setFilterIconColor("material-icons grey")}
							>
								clear
							</i>
						</div>
					</div>
					<div className="engagements-search-filter">
						<Form className="search-filter-column-1">
							<OrganizationPicker
								onSearchChange={searchOrg}
								value={props.leadFilterData.organization}
								onChange={onSelectOrg}
								onClick={onClickOrg}
								organizations={props.organizations}
							/>
							<StatusPicker
								value={props.leadFilterData.campaignStatus}
								onChange={onSelectCampaignStatus}
							/>
							<DeliveryStatePicker
								value={props.leadFilterData.clientDeliverdFilterState}
								onChange={onSelectDeliveryStatus}
								delivery_states={props.delivery_states}
							/>
							{/* <TrafficFilterPicker
								value={props.leadFilterData.trafficType}
								onChange={onSelectTrafficFilter}
							/> */}
							<TypePicker
								value={props.leadFilterData.assetType}
								onChange={onSelectType}
							/>
							<LeadPartnerPicker
								value={props.leadFilterData.leadPartner}
								onChange={onSelectLeadPartner}
								label="Engagement Source"
								placeholder="Engagement Source"
							/>
							<SingleSelectDropDown
								onChange={onSelectFilterStatus}
								options={options}
								placeholder="All"
								value={props.leadFilterData.leadEngsFilterStatus}
								label="Engagement Status"
							/>
							<SingleSelectDropDown
								label="Verification Status"
								onChange={onSelectFilterState}
								options={verificationStateOptions}
								placeholder={props.leadFilterData.leadEngsFilterStatus === "Received" ? "Received" : "All"}
								// placeholder={props.leadFilterData.leadEngsFilterStatus !== "All" ? "Select State" : "All"}
								value={props.leadFilterData.leadEngsFilterVerificationState}
							/>
							<EngagedOrgPicker
								value={props.leadFilterData.engagedOrganizations}
								label="Engaged Organization"
								placeholder="Engaged Organization"
								onChange={onSelectEngOrg}
								onClick={onClickEngOrg}
								onSearchChange={searchEngOrg}
								organizations={props.engOrg}
							/>
							<CSMSelection
								authors={props.author}
								value={props.leadFilterData.setCSM}
								FETCH={actionTypes.FETCH_ENG_CSM}
								ADD={actionTypes.ADD_ENG_CSM}
								SELECT={actionTypes.SELECTED_ENG_TOGGLE}
								CLEAR={actionTypes.CLEAR_ENG_CSM}
							/>
							{/* <Toggle
								label="Include Parent"
								onChange={onChangeIncludeParent}
								checked={props.leadFilterData.leadIsIncludeParent}
								style={{ paddingLeft: "19px" }}
								// className="inner-child-toggle"
							/>
							<div style={{ marginBottom: "18px", paddingLeft: "19px" }}>
								<Toggle
									label="Include Children"
									onChange={onChangeIncludeChild}
									checked={props.leadFilterData.leadIsIncludeChild}
									// className="inner-child-toggle"
								/>
							</div> */}
							<Toggle
								label="Override Report Filters"
								onChange={() => {
									props.selLeadToggles(actionTypes.SELORFTOGGLE);
									props.resetLeadToggles(actionTypes.RESETLEADORFTOGGLE);
								}}
								checked={props.leadFilterData.overrideLeadReportFilters}
							/>
							{props.leadFilterData.overrideLeadReportFilters ? (
								<div className="field">
									<div className="toggle-label">
										<p>Standard</p>
									</div>
									<Toggle
										className="inner-child-toggle"
										label="Account"
										onChange={() =>
											props.selLeadToggles(actionTypes.SELATOGGLE)
										}
										checked={props.leadFilterData.account}
									/>
									<Toggle
										className="inner-child-toggle"
										label="Vertical"
										onChange={() =>
											props.selLeadToggles(actionTypes.SELVTOGGLE)
										}
										checked={props.leadFilterData.vertical}
									/>
									<Toggle
										className="inner-child-toggle"
										label="Geo"
										onChange={() =>
											props.selLeadToggles(actionTypes.SELGEOTOGGLE)
										}
										checked={props.leadFilterData.geo}
									/>
									<Toggle
										className="inner-child-toggle"
										label="Custom Questions"
										onChange={() =>
											props.selLeadToggles(actionTypes.SELCQTOGGLE)
										}
										checked={props.leadFilterData.customQuestions}
									/>
									<Toggle
										className="inner-child-toggle"
										label="Email Suppression"
										onChange={() => {
											props.selLeadToggles(actionTypes.SELEESTOGGLE);
										}}
										checked={props.leadFilterData.excludeEmailSupression}
									/>
									<Toggle
										label="FirmoGraphic"
										onChange={() => {
											props.selLeadToggles(actionTypes.SELFTOGGLE);
											props.resetLeadToggles(actionTypes.RESETLEADFTOGGLE);
										}}
										checked={props.leadFilterData.firmoGraphic}
									/>
									{props.leadFilterData.firmoGraphic ? (
										<div className="firmographic-toggles">
											<Toggle
												className="inner-child-toggle"
												label="Revenue"
												onChange={() =>
													props.selLeadToggles(actionTypes.SELRTOGGLE)
												}
												checked={props.leadFilterData.revenue}
											/>
											<Toggle
												className="inner-child-toggle"
												label="Size"
												onChange={() =>
													props.selLeadToggles(actionTypes.SELSIZETOGGLE)
												}
												checked={props.leadFilterData.size}
											/>
											<Toggle
												className="inner-child-toggle"
												label="Type"
												onChange={() =>
													props.selLeadToggles(actionTypes.SELOOTTOGGLE)
												}
												checked={props.leadFilterData.types}
											/>
										</div>
									) : (
										""
									)}
									<Toggle
										label="Personas"
										onChange={() => {
											props.selLeadToggles(actionTypes.SELPTOGGLE);
											props.resetLeadToggles(actionTypes.RESETLEADPTOGGLE);
										}}
										checked={props.leadFilterData.personas}
									/>
									{props.leadFilterData.personas ? (
										<div className="personas-toggles">
											<Toggle
												className="inner-child-toggle"
												label="Seniority"
												onChange={() => {
													props.selLeadToggles(actionTypes.SELSTOGGLE);
												}}
												checked={props.leadFilterData.seniority}
											/>
											<Toggle
												className="inner-child-toggle"
												label="Job Functions"
												onChange={() => {
													props.selLeadToggles(actionTypes.SELJFTOGGLE);
												}}
												checked={props.leadFilterData.jobFunctions}
											/>
											<Toggle
												className="inner-child-toggle"
												label="Job Titles"
												onChange={() => {
													props.selLeadToggles(actionTypes.SELJTTOGGLE);
												}}
												checked={props.leadFilterData.jobTitles}
											/>
										</div>
									) : (
										""
									)}
									<Form.Group widths="equal">
										<CompetitorFilterToggleForOthers
											filterData={props.leadFilterData}
											action={actionTypes.SELECTED_ENG_TOGGLE}
											style={{ marginTop: "3px" }}
										/>
									</Form.Group>
								</div>
							) : (
								""
							)}
						</Form>
						<Form className="search-filter-column-2">
							<OrderPicker
								onSearchChange={searchOrder}
								value={props.leadFilterData.order}
								onChange={onSelectOrder}
								onClick={onClickOrder}
								order={props.order}
							/>
							<CampaignPicker
								onSearchChange={searchCampaign}
								value={props.leadFilterData.campaign}
								onChange={onSelectCampaigns}
								campaigns={props.campaigns}
								onClick={onClickCampaign}
							/>
							<PacingFilter
								value={props.leadFilterData.pacingFilter}
								onChange={onSelectPacingFilter}
							 />
							<SitePicker
								value={props.leadFilterData.site}
								onChange={onSelectSite}
							/>
							<PageTitlePicker
								onSearchChange={searchPageTitle}
								value={props.leadFilterData.title}
								onChange={onSelectPageTitle}
								onClick={onClickPageTitle}
								titles={props.titles}
							/>
							<DomainFilter 
								value={props.leadFilterData.domainFilter}
								onChange={(e, {value})=> props.clientSelectDropDown(value, actionTypes.SELECTENGDOMAINFILTER) }
							/>
							<PrivacyParametersComponent
								selectValue={props.selectToggleValue}
								leadFilterData={props.leadFilterData}
								action={actionTypes.SELECTED_ENG_TOGGLE}
								regulationData={props.regulationData}
								FETCH={actionTypes.FETCH_ENG_REGULATION}
								ADD={actionTypes.ADD_ENG_REGULATION}
								CLEAR={actionTypes.CLEAR_ENG_REGULATION}
							/>
							<Toggle
								label="UTM Parameters"
								onChange={() => {
									props.selLeadToggles(actionTypes.SELUTMPARAMENGSTOGGLE);
								}}
								checked={props.leadFilterData.utmParams}
								style = {{marginBottom: "10px", marginTop: "30px", paddingLeft: "5px"}}
							/>
							{props.leadFilterData.utmParams &&
							<>
							<UrlParamsPicker
								style={{ width: "24em" }}
								options={props.urlSource?.map((url, index) => ({
									key: index,
									text: url.source,
									value: url.source,
								}))}
								value={props.leadFilterData.selUrlSource}
								label="UTM Source"
								placeholder="UTM Source"
								onClick={onClickUrlSource}
								onChange={onSelectUrlSource}
								onSearchChange={searchUrlSource}
							/>
							<UrlParamsPicker
								options={props.urlMedium?.map((url, index) => ({
									key: index,
									text: url.medium,
									value: url.medium,
								}))}
								value={props.leadFilterData.selUrlMedium}
								label="UTM Medium"
								placeholder=" UTM Medium"
								onClick={onClickUrlMedium}
								onChange={onSelectUrlMedium}
								onSearchChange={searchUrlMedium}
							/>
							<UrlParamsPicker
								options={props.urlCampaigns.map((url, index) => ({
									key: index,
									text: url.campaign,
									value: url.campaign,
								}))}
								value={props.leadFilterData.selUrlCampaign}
								label="UTM Campaign"
								placeholder="UTM Campaign"
								onClick={onClickUrlCampaign}
								onChange={onSelectUrlCampaigns}
								onSearchChange={searchUrlCampaigns}
							/>
							<UrlParamsPicker
								label="UTM Term"
								placeholder="UTM Term"
								options={props.urlTerm?.map((url, index) => ({
									key: index,
									text: url.term,
									value: url.term,
								}))}
								value={props.leadFilterData.selUrlTerm}
								onClick={onClickUrlTerm}
								onChange={onSelectUrlTerm}
								onSearchChange={searchUrlTerm}
							/>
							<UrlParamsPicker
								label="UTM Content"
								placeholder="UTM Content"
								options={props.urlContent?.map((url, index) => ({
									key: index,
									text: url.content,
									value: url.content,
								}))}
								value={props.leadFilterData.selUrlContent}
								onClick={onClickUrlContent}
								onChange={onSelectUrlContent}
								onSearchChange={searchUrlContent}
							/>
							</>
							}
							<div className="toggle-label" style={{ paddingTop: "16px" }}>
								<p>Content Filters</p>
							</div>
							<Toggle
								className="child-toggle"
								label="Exclude Gated Pageviews"
								onChange={() => {
									props.selLeadToggles(actionTypes.SELEGPTOGGLE);
								}}
								checked={props.leadFilterData.excludeGatedPageviews}
							/>
							<div className="toggle-label">
								<p>Campaign Filters</p>
							</div>
							<Toggle
								className="child-toggle"
								label="Exclude Internal Campaign"
								onChange={() => {
									props.selLeadToggles(actionTypes.SELEICTOGGLE);
								}}
								checked={props.leadFilterData.excludeInternalCampaign}
							/>
							<Toggle
								className="child-toggle"
								label="Exclude QA Campaigns"
								onChange={() => {
									props.selLeadToggles(actionTypes.SELEQACTOGGLE);
								}}
								checked={props.leadFilterData.excludeQACampaign}
							/>
							<div className="toggle-label">
								<p>Event Filters</p>
							</div>
							<Toggle
								className="child-toggle"
								label="Exclude Internal Events"
								onChange={() => props.selLeadToggles(actionTypes.SELEIETOGGLE)}
								checked={props.leadFilterData.excludeInternalEvents}
							/>
							<Toggle
								className="child-toggle"
								label="Exclude Organization Events"
								onChange={() => props.selLeadToggles(actionTypes.SELEOETOGGLE)}
								checked={props.leadFilterData.excludeOrganizationEvents}
							/>
							<Toggle
								className="child-toggle"
								label="Exclude Free Email Events"
								onChange={() => props.selLeadToggles(actionTypes.SELEFEETOGGLE)}
								checked={props.leadFilterData.excludeFreeEmailEvents}
							/>
							<Toggle
								className="child-toggle"
								label="Exclude Competitor Events"
								onChange={() => {
									props.selLeadToggles(actionTypes.SELECETOGGLE);
								}}
								checked={props.leadFilterData.excludeCompetitorEvents}
							/>
							{/* <Toggle
								label="Organization Limits"
								onChange={() => {
									props.selLeadToggles(actionTypes.SELOLTOGGLE);
								}}
								checked={props.leadFilterData.organizationLimits}
							/> */}
						</Form>
					</div>
					<div className="filter-buttons">
						<button className="cancel-date-button extra-space" onClick={clearFilters}>
							Clear Filters
						</button>
						<button
							type="submit"
							className="apply-filter-button"
							onClick={() =>
								applyFilters(
									props.leadFilterData.startDate,
									props.leadFilterData.endDate,
									props.leadFilterData.appliedDate
								)
							}
						>
							Apply Filters
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		order: Object.values(state.leadOrder),
		campaigns: Object.values(state.leadCampaigns),
		titles: Object.values(state.leadPageTitle),
		organizations: Object.values(state.leadOrganizations),
		engOrg: Object.values(state.leadEngOrgs),
		leadFilterData: state.leadFilterData,
		isFilApplied: state.leadFetchData.isFilApplied,
		urlSource: Object.values(state.leadEngUrlSource),
		urlMedium: Object.values(state.leadEngUrlMedium),
		urlCampaigns: Object.values(state.leadEngUrlCampaign),
		urlTerm: Object.values(state.leadEngUrlTerm),
		urlContent: Object.values(state.leadEngUrlContent),
		leadEngSummary: state.leadFetchData.leadEngSummary,
        leadEngs: state.leadFetchData.leadEngs,
		delivery_states: state.leadFilterData.clientDeliverdFilterStateOptions,
        leadFetchData: state.leadFetchData,
		author: Object.values(state.engAuthor),
		regulationData: Object.values(state.reguationManagerEng),
	};
};

export default compose(
	connect(mapStateToProps, {
		fetchOrganizations,
		fetchOrder,
		fetchTitle,
		addOrganization,
		addCampaigns,
		addOrder,
		fetchCampaigns,
		resetOrgsOrCampaigns,
		resetOrder,
		clearSearchOrgs,
		clearSearchCampaigns,
		clearSearchOrder,
		selLeadPageTitle,
		selLeadStartDate,
		selLeadEndDate,
		applyLeadDate,
		selLeadOrgs,
		selLeadOrder,
		selLeadCampaigns,
		selLeadEngOrgs,
		selLeadStatus,
		selLeadSite,
		selLeadType,
		selLeadToggles,
		selLeadPartner,
		applyLeadFilter,
		clearLeadFilter,
		fetchLeadEngSummary,
		//fetchLeadEngCampaignSummary,
		fetchLeadEngs,
		prevLeadSelEndDate,
		prevLeadSelStartDate,
		prevLeadAppliedDate,
		resetLeadToggles,
		fetchUrlParams,
		clearLeadEngSerchedValue,
		selLeadEngFilterValue,
		selDropDownFilters,
		fetchLeadValidationChart,
		selGlobalLeadEngsStatus,
		selGlobalLeadEngsVerificationState,
		fetchLeadValidationBarChart,
		selClientLeadEngState,
		selPacingEngsFilterValue,
		fetchEngagedOrg,
		clientSelectDropDown,
		selectToggleValue,
	})
)(SearchFilter);
