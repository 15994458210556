
const commonHeaders = [ 
    { headerName: "Company Name", headerKey: "company_name" },
    { headerName: "Domain Name", headerKey: "domain_name" },
    { headerName: "Parent", headerKey: "parent" },
    { headerName: "Parent Primary Domain Name", headerKey: "parent_primary_domain_name" },
    { headerName: "Sector", headerKey: "sector" },
    { headerName: "Industry Group", headerKey: "industry_group" },
    { headerName: "Sub Industry", headerKey: "sub_industry" },
    { headerName: "Organization Type", headerKey: "organization_type" },
    { headerName: "Organization Revenue", headerKey: "organization_revenue" },
    { headerName: "Organization Employee", headerKey: "organization_employee" },
    { headerName: "Locations", headerKey: "location" },
    { headerName: "SIC", headerKey: "sic" },
    { headerName: "NAICS", headerKey: "naics" },
    { headerName: "Ticker Symbol", headerKey: "ticker_symbol" },
    { headerName: "Fiscal Year End", headerKey: "fiscal_year_end" },
    { headerName: "Headquarters Country", headerKey: "headquarter_country" },
    { headerName: "Headquarters Province/State", headerKey: "headquarter_region" },
    { headerName: "Headerquarters City", headerKey: "headquarter_city" },
];

export const diBasic = [
    ...commonHeaders,
    // { headerName: "Editorial & Market Views", headerKey: "editorial_and_marketplace_views" },
    { headerName: "Editorial Views", headerKey: "editorial_views" },
    { headerName: "Marketplace Views", headerKey: "marketplace_views" },
    { headerName: "Mentions Views", headerKey: "mentions_views" },
    { headerName: "StringerAI Announcement Views", headerKey: "stringer_ai_announcement_views" },
    { headerName: "StringerAI PR Views", headerKey: "stringer_ai_pr_views" },
    { headerName: "StringerAI Security Alert Views", headerKey: "stringer_ai_security_alert_views" },
    { headerName: "Sponsored Content Views", headerKey: "sponsored_content_views" },
];

export const diProHeaders = [
   ...diBasic,
   { headerName: "Asset Download", headerKey: "asset_downloads" },
   { headerName: "Digital Event / Webinar Registrations", headerKey: "digital_event_webinar_registrations" },
   { headerName: "Video Views", headerKey: "video_views" },
   { headerName: "Ad Views", headerKey: "ad_views" },
   { headerName: "Ad Clicks", headerKey: "ad_clicks" },
   { headerName: "CTR", headerKey: "ctr" },
   { headerName: "Account Interest by your Keywords", headerKey: "account_interest_by_keywords" },
   { headerName: "Account Activity Trend", headerKey: "account_activity_trend" },
   { headerName: "Account Activity Level", headerKey: "account_activity_level" },
//    { headerName: "Competitor Influence", headerKey: "competitor_influence" },
];

export const diProPlusHeaders = [
    ...diProHeaders,
    // { headerName: "Activity Trend for Leaders", headerKey: "activity_trend_for_leaders" },
    // { headerName: "Activity  for Leaders", headerKey: "activity_for_leaders" },
    // { headerName: "Activity Trend for Experts", headerKey: "activity_trend_for_experts" },
];

export const diUpgradeHeaders = [
    ...commonHeaders,
    { headerName: "Competitor Influence #1", headerKey: "competitor_influence_1" },
    { headerName: "Competitor Influence #2", headerKey: "competitor_influence_2" },
    { headerName: "Competitor Influence #3", headerKey: "competitor_influence_3" },
    { headerName: "Competitor Influence #4", headerKey: "competitor_influence_4" },
    { headerName: "Competitor Influence #5", headerKey: "competitor_influence_5" },
    { headerName: "Competitor Influence #6", headerKey: "competitor_influence_6" },
    { headerName: "Competitor Influence #7", headerKey: "competitor_influence_7" },
    { headerName: "Competitor Influence #8", headerKey: "competitor_influence_8" },
    { headerName: "Competitor Influence #9", headerKey: "competitor_influence_9" },
    { headerName: "Competitor Influence #10", headerKey: "competitor_influence_10" },
];