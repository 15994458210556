import { getApiDate, formatDate, cleanPayload } from "./common";
import { findReportFromURL } from "../components/config/FindReportFromURL";
import { getCookie } from "./envCommon";

export const SidebarPayload = (filterData, page, type, url) => {
    const reportData = findReportFromURL(url, "");
    let orgType = (Array.isArray(filterData?.selOrgType) && filterData.selOrgType.length > 0) ? filterData?.selOrgType?.map( a => a.charAt(0).toUpperCase() + a.substr(1)) : [];
    const payload = {
        organizations: filterData.organization,
        campaigns: filterData.campaign,
        orders: filterData.order,
        site: filterData.site === "all" ? "" : filterData.site,
        status: filterData.campaignStatus === "all" ? "" : filterData.campaignStatus,
        start_date: getApiDate(filterData.startDate).concat(" 00:00:00"),
        end_date: getApiDate(filterData.endDate).concat(" 23:59:59"),
        previous_start_date: formatDate(filterData?.previousStartDate, " 00:00:00"),
        previous_end_date: formatDate(filterData?.previousEndDate, " 23:59:59"),
        compare:filterData.selCustomRangeCheckBox,
        order: "ASC",
        order_by: "id",
        include_deleted: filterData?.campaignStatus === "all",
        limit: 50,
        page: page,
        export: type,
        applied_date_filter: filterData.appliedDate,
        exclude_qa: filterData.excludeQACampaign,
        exclude_internal: filterData.excludeInternalCampaign,
        country: filterData?.selCountry,
        engaged_organizations: filterData.engagedOrganizations,
        email: filterData.email,
        ...(filterData?.personaToggle && {
            job_title: {
                included_job_title: filterData?.selJobTitlesIN,
                excluded_job_title: filterData?.selJobTitlesEX,
            },
            seniorities: {
                included_tags: filterData?.selSenioritiesIN,
                excluded_tags: filterData?.selSenioritiesEX,
            },
            job_functions: {
                included_tags: filterData?.selJobFunctionsIN,
                excluded_tags: filterData?.selJobFunctionsEX,
            },
        }),
        engaged_members:filterData?.selEnagedMember,
        organization_types: orgType,
        organization_sizes: filterData?.selOrgSize,
        organization_revenues: filterData?.selOrgRevenue,
        sector:filterData?.selSector,
        industry: filterData?.selIndustry,
        sub_industry: filterData?.selSubIndustry,
        industry_group: filterData?.selIndustryGroup,
        persona_toggle: filterData?.personaToggle,
        firmographic_toggle: filterData?.firmographicToggle,
        traffic_filter: filterData.trafficType === "all" ? "" : filterData.trafficType,
        report_name: reportData ? reportData.report : "",
        report_table_name: reportData ? reportData.report_table_name : "",
        session_id: getCookie("session_id"),
        exclude_competitor_orgs: filterData?.competitorFiltering,
        csm: filterData?.setCSM
    }

    return cleanPayload(payload);
}