import React from "react";
import SingleSelectDropDown from "../base/singleSelectDropDown";

const AuthorPicker = (props) => {
   return (
      <SingleSelectDropDown
         clearable
         options={props.requestdata.map((req, index) => ({
            key: index,
            text: req.name,
            value: req.id,
         }))}
         label="Requested By"
         placeholder="Requested By"
         {...props}
      />
   );
};

export default AuthorPicker;
