import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from "lodash";

import { AuthorSummaryHeader } from "./AuthorSummaryHeader"
import { selectValue, getTableHeaders, sendTableHeaders } from "../../actions";
// import { Loader, Message, Table } from "semantic-ui-react";
import GenericTable from "../ads/common/GenericTable";
import { columnConfigs } from "../ads/common/ColumnConfig";
import { Loader, Message, Table } from "semantic-ui-react";

const AuthorSummary = (props) => {
    const conditionPrevious = props.filterData.selCustomRangeCheckBox === true && props.filterData.isFilApplied && props.filterData.appliedDate === "Custom Date Range";
    
    return (
        <div>
            <div className="sortable-table-component">
                <Table sortable fixed singleLine>
                    <GenericTable 
                        data={props.filterData.authorSummaryData}
                        headerOrder={AuthorSummaryHeader}
                        columnConfigs={columnConfigs}
                        isComparePrevious={conditionPrevious}
                        atLastTotalCount={true}
                    />
                </Table>
            </div>
            {
                props.filterData.isLoadingAuthor &&
                <div className="show-loader">
                    <Loader active inline="centered" />
                </div>
            }
            {
                props.filterData.isFilApplied && !props.filterData.isLoadingAuthor && _.isEmpty(props.filterData.authorSummaryData) &&
                <Message>No results found.</Message>
            }
        </div>
    );
};

export default compose(connect(null, {
    selectValue,
    getTableHeaders,
    sendTableHeaders
}))(AuthorSummary);
