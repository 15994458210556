export const ACCOUNT_NAME = "ACCOUNT NAME";
export const CAMPAIGN_NAME = "CAMPAIGN NAME";
export const CLIENT_SUCCESS_MANAGER = "CLIENT SUCCESS MANAGER";
export const START_DATE = "START DATE";
export const END_DATE = "END DATE";
export const IMPRESSIONS_PURCHASED = "IMPRESSIONS PURCHASED";
export const IMPRESSIONS_DELIVERED = "IMPRESSIONS DELIVERED";
export const IMPRESSIONS_OUTSTANDING = "IMPRESSIONS OUTSTANDING";
export const PERCENT_DELIVERED = "PERCENT DELIVERED";
export const UNIQUE_USER_IMPRESSIONS = "UNIQUE USER IMPRESSIONS";
export const CLICKS = "CLICKS";
export const UNIQUE_USER_CLICKS = "UNIQUE USER CLICKS";

// Lead campaign summary table header names
export const LEAD_STATUS = "LEAD STATUS"
export const VERIFICATION_STATUS = "VERIFICATION STATUS"
export const VERIFICATION_STATE = "VERIFICATION_STATE"
export const CAMPAIGN = "CAMPAIGN"
export const LEAD_DATE = "LEAD DATE"
export const LEAD_TIME = "LEAD TIME"
export const LEAD_SOURCE = "LEAD SOURCE"
export const UTM_TERM = "UTM TERM"
export const UTM_CONTENT = "UTM CONTENT"
export const ASSET_TYPE = "ASSET TYPE"
export const ASSET_TITLE = "ASSET TITLE"
export const COUNTRY = "COUNTRY"
export const CITY = "CITY";
export const STATE_OR_PROVINCE = "STATE/PROVINCE";
export const POSTAL_CODE_OR_ZIP = "POSTAL CODE/ZIP";
export const TIMEZONE = "TIMEZONE";
export const ORGANIZATION = "ORGANIZATION"
export const ADDRESS1 = "ADDRESS1";
export const ADDRESS2 = "ADDRESS2";
export const ORGANIZATION_ACTIONS_TAKEN = "ORGANIZATION BRAND IMPRESSIONS";
export const ORGANIZATION_PAGEVIEWS = "ORGANIZATION PAGEVIEWS";
export const ORGANIZATION_SESSIONS = "ORGANIZATION SESSIONS";
export const ORGANIZATION_ENGAGGEMENTS = "ORGANIZATION ENGAGEMENTS";
export const CHILD_ORGANIZATION = "CHILD ORGANIZATION";
export const AGENCY_NAME = "AGENCY NAME";
export const VERTICAL = "VERTICAL";
export const REVENUE = "REVENUE";
export const SIZE = "SIZE";
export const TYPE = "TYPE";
export const MEMBER_NAME = "MEMBER NAME";
export const UNIQUE_MEMBERS = "UNIQUE MEMBERS";
export const ANONYMOUS_USERS = "ANONYMOUS USERS";
export const FIRST_NAME = "FIRST NAME";
export const LAST_NAME = "LAST NAME";
export const MEMBER_ACTIONS_TAKEN = "MEMBER BRAND IMPRESSIONS";
export const MEMBER_PAGEVIEWS = "MEMBER PAGEVIEWS";
export const MEMBER_SESSIONS = "MEMBER SESSIONS";
export const MEMBER_ENGAGGEMENTS = "MEMBER ENGAGEMENTS";
export const MEMBER_SENIORITY = "MEMBER SENIORITY";
export const JOB_FUNCTION = "JOB FUNCTION";
export const MEMBER_TITLE = "MEMBER TITLE";
export const SECTOR = "SECTOR";
export const INDUSTRY_GROUP = "INDUSTRY GROUP";
export const INDUSTRY = "INDUSTRY";
export const SUB_INDUSTRY = "SUB INDUSTRY";
export const UTM_SOURCE = "UTM SOURCE";
export const UTM_MEDIUM = "UTM MEDIUM";
export const UTM_CAMPAIGN = "UTM CAMPAIGN";
export const PHONE_NUMBER = "PHONE NUMBER";
export const CSM = "CSM";

// common header names
export const ORDER = "ORDER"

//Engagement detail header name
export const ENGAGEMENT_STATUS = "ENGAGEMENT STATUS"
export const ENGAGEMENT_DATE = "ENGAGEMENT DATE"
export const ENGAGEMENT_TIME = "ENGAGEMENT TIME"
export const ENGAGEMENT_SOURCE = "ENGAGEMENT SOURCE"
export const ACCEPTED_DATE = "ACCEPTED DATE"
export const ACCEPTED_TIME = "ACCEPTED TIME"
export const DELIVERY_DATE = "DELIVERY DATE"
export const DELIVERY_TIME = "DELIVERY TIME"
export const DO_NOT_SELL_MY_PERSONAL_INFO = "DO NOT SELL MY PERSONAL INFO"
export const ENGAGEMENT_PROCESSED_DATE = "ENGAGEMENT PROCESSED DATE"
export const ENGAGEMENT_PROCESSED_TIME = "ENGAGEMENT PROCESSED TIME"
export const MARKETING_CONSENT = "MARKETING CONSENT"
export const OPT_IN_DATE = "OPT IN DATE"
export const OPT_IN_TIME = "OPT IN TIME";
export const REGULATION_NAME = "REGULATION NAME";
export const PURPOSE_CONSENT = "PURPOSE CONSENT";
export const RIGHT_TO_BE_FORGOTTEN = "RIGHT TO BE FORGOTTEN";
export const CONDITIONING_CONSENT = "CONDITIONING CONSENT";
export const AGE_VERIFICATION = "AGE VERIFICATION";
export const DOUBLE_OPT_IN = "DOUBLE OPT IN";
export const OPT_OUT_OF_SALE = "OPT-OUT OF SALE";

//Sidebar detail header name
export const PAGE_TITLE = "PAGE TITLE"
export const DATE = "DATE"
export const TIME = "TIME"
export const CLIENT_LINK_TITLE = "CLIENT LINK TITLE"
export const SENIORITY = "SENIORITY"
export const TITLE = "TITLE"

//Sponsored and Independant header name
export const AGENCY = "AGENCY" 
export const ACCOUNT = "ACCOUNT"
export const CAMPAIGN_STATUS = "CAMPAIGN STATUS"
export const DELIVERY_STATUS = "DELIVERY STATUS"
export const PAGEVIEWS_GOAL = "PAGEVIEWS GOAL"
export const PAGEVIEWS_DELIVERED_IN_RANGE = "PAGEVIEWS DELIVERED IN RANGE"
export const PAGEVIEWS_DELIVERED_TOTAL = "PAGEVIEWS DELIVERED TOTAL"
export const PERCENT_COMPLETE = "PERCENT COMPLETE"
export const PACE = "PACE"
export const PAGE = "PAGE"
export const PRIMARY_TOPIC = "PRIMARY TOPIC";
export const TOPIC_TAGS = "TOPIC TAGS"
export const PLACEMENT_TAGS = "PLACEMENT TAGS"
export const PUBLISH_DATE = "PUBLISH DATE";
export const DAY_OF_WEEK = "DAY OF WEEK";
export const PAGEVIEWS = "PAGEVIEWS";
export const PAGE_ELEMENTS = "PAGE ELEMENTS";
export const UNIQUE_PAGEVIEWS = "UNIQUE PAGEVIEWS"
export const AUTHOR = "AUTHOR"
export const ORGANIZATION_TAGS = "ORGANIZATION TAGS"
export const TOTAL_PAGEVIEWS_BY_TAG = "TOTAL PAGEVIEWS BY TAG"
export const UNIQUE_PAGEVIEWS_BY_TAG = "UNIQUE PAGEVIEWS BY TAG"
export const AUTHOR_NAME = "AUTHOR NAME"
export const TOTAL_PAGEVIEWS_BY_AUTHOR = "TOTAL PAGEVIEWS BY AUTHOR";
export const UNIQUE_PAGEVIEWS_BY_AUTHOR = "UNIQUE PAGEVIEWS BY AUTHOR";
export const PUBLISHED_BY_AUTHOR = "PUBLISHED BY AUTHOR";
export const PAGEVIEWS_PER_ARTICLE = "PAGEVIEWS PER ARTICLE";
export const DAY_SINCE_PUBLISHED = "DAY SINCE PUBLISHED";
export const DAY_SINCE_UPDATED = "DAY SINCE UPDATED";
export const AD_TYPE = "AD TYPE";
export const PERCENT_ELAPSED_TIME = "PERCENT ELAPSED TIME";

//ads header names
export const AD_ORDER = "AD ORDER"
export const LINE_ITEM = "LINE ITEM NAME"
export const CREATIVE_NAME = "CREATIVE NAME"
export const MEDIA_TYPE = "MEDIA TYPE"
export const ACTION = "ACTION"
export const SENORITY = "SENORITY"
export const JOB_TITLE = "JOB TITLE"
export const ORGANIZATION_SIZE = "ORGANIZATION SIZE"
export const CREATIVE_SIZE = "CREATIVE SIZE"
export const IP_ADDRESS = "IP Address"
export const ROADBLOCK_ID = "ROADBLOCK ID";
export const GROUP_ID = "GROUP ID"
export const SESSION_ID  = "SESSION ID";
export const SESSION_PAGEVIEWS = "SESSION PAGEVIEWS";
export const SESSION_ENGAGEMENTS = "SESSION ENGAGEMENTS";
export const TARGET_NAME = "TARGET NAME";
export const WP_ID = "WP ID";
export const EMAIL_ADDRESS = "EMAIL_ADDRESS";
//lead and engs detail table
export const CUSTOM_QUESTION_RESPONSES = "CUSTOM QUESTION RESPONSES"
export const AD_UNIT = "AD UNIT"

//Independant detail table
export const SOURCE = "SOURCE"
export const TERM = "TERM"
export const CONTENT = "CONTENT"
export const ORGANIZATION_TYPE = "ORGANIZATION TYPE"
export const SITES = "SITES"

//Sponsored Detail table
export const TRAFFIC_SOURCE = "TRAFFIC SOURCE"
export const ORGANIZATION_REVENUE = "ORGANIZATION REVENUE"

//Sidebar Detail table
export const SIDEBAR = "SIDEBAR"
export const INCLUDED_TOPIC_TAGS = "INCLUDED TOPIC TAGS"
export const EXCLUDED_TOPIC_TAGS = "EXCLUDED TOPIC TAGS"
export const LOCATION = "LOCATION"
export const WIDGET_TYPE = "WIDGET TYPE"
export const WIDGET_TITLE = "WIDGET TITLE"
export const SLOT = "SLOT"
export const CLIENT_LINK = "CLIENT LINK"
export const INCLUDED_ORGANIZATION_TAGS = "INCLUDED ORGANIZATION TAGS"
export const EXCLUDED_ORGANIZATION_TAGS = "EXCLUDED ORGANIZATION TAGS"
export const INCLUDED_PLACEMENT_TAGS = "INCLUDED PLACEMENT TAGS"
export const EXCLUDED_PLACEMENT_TAGS = "EXCLUDED PLACEMENT TAGS";

export const EMAIL_TITLE = "EMAIL TITLE";
export const EMAIL_ID = "EMAIL ID";
export const EMAIL_DATE = "EMAIL DATE";
export const NEWSLETTER_NAME = "NEWSLETTER NAME";

export const PAGE_SIZE = 50
export const SORT_ICON = "SORT_ICON";
export const SHOWN = "SHOWN";
export const LIMIT_20 = 20;
export const LIMIT_50 = 50;
export const LIMIT_100 = 100;
