import * as actionTypes from "../actions/actionTypes";

const filterData = {
	productivityData: [],
    loading: false
};


export const ProductivityFilterDataReducer = (state = filterData, action) => {
    switch (action.type) {
        case actionTypes.APPLY_PRODUCTIVITY_FILTER:
            return {
                ...state,
                loading: true,
                productivityData: [],
            }
        case actionTypes.GET_PRODUCTIVITY_DATA:
            return { ...state, productivityData: action.payload, loading: false };
        default:
            return state;
    }
};