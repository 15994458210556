import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Loader, Message, Table } from "semantic-ui-react";

import GenericTableForExpandable from "../common/GenericTableForExpandable";
import { HeaderData } from "./HeaderNames";
import { columnConfigs } from "../ads/common/ColumnConfig";
import { selectValue, fetchNewslettersReportData, getTableHeaders, sendTableHeaders } from "../../actions";
import * as actionTypes from "../../actions/actionTypes";
import ShowMoreButton from "../common/showMoreComponent";
import _ from "lodash";
import DynamicHeaderColumnSelector from "../common/DynamicHeaderColumnSelector";

const Summary = (props) => {
    const [isSortIcon, setSortIcon] = useState(false);
    const [selectHeaders, setSelectHeaders] = useState([]);
    const [finalHeaderData, setFinalHeaderData] = useState();

    useEffect(() => {
        props.getTableHeaders(
            `newsletter_summary`,
            actionTypes[props.reportActionType].GET_DYNAMIC_TABLE_HEADER,
            props.reportActionType,
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const headerDataVal = props.filterData.getDynamicHeaderData;
        if (!_.isEmpty(headerDataVal)) {
            setSelectHeaders(headerDataVal);
            setFinalHeaderData(headerDataVal);
        } else if (headerDataVal === undefined || _.size(headerDataVal) === 0) {
            const showFirstHeaderVal = { headerName: "", headerKey: "blank"}
            const constantHeadersArr = [showFirstHeaderVal, ...HeaderData]
            setSelectHeaders(constantHeadersArr);
            setFinalHeaderData(constantHeadersArr);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filterData.getDynamicHeaderData]);

    const showMore = async () => {
        props.selectValue({ 
            dispatchType: actionTypes[props.reportActionType].SELECTVALUE, 
            reportType: props.reportActionType, 
            key: "isLoadingNewsletter", 
            value: true
        });
        props.fetchNewslettersReportData(
            props.filterData,
            props.filterData.newsletterPageCount,
            "",
            actionTypes[props.reportActionType].SHOW_MORE_NEWSLETTERS_SUMMARY,
            props.reportName,
            props.reportActionType,
        );
    };

    const clickOnSortHeader = (e, extraData, tableData) => {
        if (extraData.checked) {
            setSelectHeaders([...selectHeaders, { headerName: tableData.headerName, headerKey: tableData.headerKey }]);
        } else {
            const removeData = selectHeaders.filter(
                (hName) => hName.headerKey !== tableData.headerKey
            );
            setSelectHeaders(removeData);
        }
    };

    const applyButtonClick = () => {
        let finalHeaderArray = [];
        const showFirstHeaderVal = { headerName: "", headerKey: "blank" };
        const constantHeadersArr = [showFirstHeaderVal, ...HeaderData];

        constantHeadersArr.forEach((mainHeader) => {
            selectHeaders.forEach((selectHeader) => {
                if (mainHeader.headerKey === selectHeader.headerKey) {
                    finalHeaderArray.push(selectHeader);
                }
            });
        });
        setFinalHeaderData(finalHeaderArray);
        props.sendTableHeaders(
            `newsletter_summary`,
            finalHeaderArray,
            actionTypes[props.reportActionType].SEND_DYANMIC_TABLE_HEADER,
            props.reportActionType,
        );
        setSortIcon(!isSortIcon);
    };

    return (
        <div>
            <div style={{ paddingBottom: "3em" }}>
                <DynamicHeaderColumnSelector
                    isSortIcon={isSortIcon}
                    clickOnSortIcon={() => setSortIcon(!isSortIcon)}
                    TableHeaderData={HeaderData}
                    selectHeaders={selectHeaders}
                    clickOnSortHeader={clickOnSortHeader}
                    applyButtonClick={applyButtonClick}
                />
            </div>
            <div className="sortable-table-component">
                <Table sortable fixed singleLine>
                    <GenericTableForExpandable 
                        data={props.filterData.newsletterSummaryData}
                        headerOrder={finalHeaderData}
                        columnConfigs={columnConfigs}
                    />
                </Table>
            </div>
            {
                props.filterData.isLoadingNewsletter &&
                <div className="show-loader">
                    <Loader active inline="centered" />
                </div>
            }
            {
                !_.isEmpty(props.filterData.newsletterSummaryData) &&
                <ShowMoreButton onClick={showMore} isDisabled={props.filterData.isNewsletterShowMore}/>
            }
            {
                props.filterData.isFilApplied && !props.filterData.isLoadingNewsletter && _.isEmpty(props.filterData.newsletterSummaryData) &&
                <Message>No results found.</Message>
            }
        </div>
    );
};

export default compose(connect(null, {
    selectValue,
    fetchNewslettersReportData,
    getTableHeaders,
    sendTableHeaders
}))(Summary);