import React from "react";
import MultipleSelectDropDown from "../base/multiSelectDropdown";
import { getOrgLink } from "../../utilities/common";
import { connect } from "react-redux";

const EngagedOrgPicker = (props) => {
	return (
		<MultipleSelectDropDown
			options={props.organizations?.map((org, index) => ({
				key: index,
				text: org?.name,
				value: org?.uuid,
			}))}
			label="Engaged Organization"
            placeholder="Organizations"
			onSearchChange={props.onSearchChange}
			value={props.value}
			onChange={props.onChange}
			onClick={props.onClick}
			onLabelClick={(e, sel) => {
				if (props.type === "addOrgLink") {
					getOrgLink(props.organizations, sel.value, props.wpLink);
				}
			}}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		wpLink: state.links.wpLink,
	};
};

export default connect(mapStateToProps, {})(EngagedOrgPicker);
