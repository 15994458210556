import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Table } from "semantic-ui-react";
import Loading from "../common/Loding";

const ProductivityDetails = (props) => {
   const [finalHeaderData, setFinalHeaderData] = useState([]);
   const [prodFinalData, setFinalProd] = useState([])

   useEffect(()=> {
      if(!_.isEmpty(props.productivityData)) {
         const { productivity_data_quarterly } = props.productivityData;
         
         if (productivity_data_quarterly.length > 0 ) {
            const combinedYears = _.map(productivity_data_quarterly, "combined_year");
            const headerData = ["-", "-", ...combinedYears, "total"]
            setFinalHeaderData(headerData)
         } else {
            setFinalHeaderData([])
         }
        
         generateProductivityData(productivity_data_quarterly);
      } else {
         setFinalProd([])
         setFinalHeaderData([])
      }
      // eslint-disable-next-line
   }, [props.productivityData]);

   const generateProductivityData = (prodDetails) => {
      var prodTopics = []
         var prodTypes = []

         prodDetails.forEach((v) => {
            v.productivity_type_details.forEach((p) => {
               let obj = {
                     [v.combined_year]: {
                           "Publishing_goal_set": p.publishing_goal_value,
                           "Pageview_goal_set": p.pageview_goal_value,
                           "Publishing_goal_acheived": p.user_productivity_details.publishing_goal_achieved,
                           "Pageview_goal_acheived": p.user_productivity_details.pageview_goal_achieved,
                     }
               };
      
               if (p.type === "TYPES") {
                  let typeIndex = prodTypes.findIndex(item => Object.keys(item)[0] === p.name);
                  if (typeIndex !== -1) {
                        prodTypes[typeIndex][p.name][v.combined_year] = obj[v.combined_year];
                  } else {
                        prodTypes.push({
                           [p.name]: obj
                        });
                  }
               } else {
                  let topicIndex = prodTopics.findIndex(item => Object.keys(item)[0] === p.name);
                  if (topicIndex !== -1) {
                        prodTopics[topicIndex][p.name][v.combined_year] = obj[v.combined_year];
                  } else {
                        prodTopics.push({
                           [p.name]: obj
                        });
                  }
               }
            });
         });

         var data = [...prodTypes, ...prodTopics]

         var finalProd = []

         _.forEach(data, (prod, key) => {
            _.forEach(prod, (queterlyData, quaterlyKey) => {
               const publishingGoalSetArray = [];
               const pageviewGoalSetArray = [];
               const publishingGoalAcheived = [];
               const pageviewGoalAcheived = [];

               _.forEach(queterlyData, (subQueterlyData, key) => {
                  publishingGoalSetArray.push({
                     "data": subQueterlyData["Publishing_goal_set"],
                     "key" : "Publishing Goal Set",
                     "idx": key
                  });

                  pageviewGoalSetArray.push({
                     "data": subQueterlyData["Pageview_goal_set"],
                     "key" : "Pageview Goal Set",
                     "idx": key
                  });

                  publishingGoalAcheived.push({
                     "data": subQueterlyData["Publishing_goal_acheived"],
                     "key" : "Publishing Goal Acheived",
                     "idx": key
                  });

                  pageviewGoalAcheived.push({
                     "data": subQueterlyData["Pageview_goal_acheived"],
                     "key" : "Pageview Goal Acheived",
                     "idx": key
                  });
               })

               const publishGoalData = generateGoalArray(publishingGoalSetArray, quaterlyKey, true)
               const pageviewGoalData = generateGoalArray(pageviewGoalSetArray, quaterlyKey, false)
               const publishedGoalAchievedData = generateGoalArray(publishingGoalAcheived, quaterlyKey, false)
               const pageviewGoalAcheivedData = generateGoalArray(pageviewGoalAcheived, quaterlyKey, false)

               finalProd.push(publishGoalData)
               finalProd.push(pageviewGoalData)
               finalProd.push(publishedGoalAchievedData)
               finalProd.push(pageviewGoalAcheivedData)
            })
         });

         setFinalProd([...finalProd])
   }

   const generateGoalArray = (queterlyData, goalKey, isValid) => {
      var obj = {}
      var sum = 0
      _.forEach(queterlyData, (v, index) => {
         sum += v.data
         if (isValid){
            obj["name"] = goalKey
         }
         obj["key"] = v.key
         obj[v.idx] = v.data
      })
      obj["total"] = sum
      return obj;
  }


   return (
      <React.Fragment>
         <div className="sortable-table-component" style={{ paddingTop: "30px" }}>
            <div className="sticky-table-container">
               <Table sortable fixed singleLine>
                  <Table.Header>
                     <Table.Row>
                        {finalHeaderData.map((header, index) => (
                           <Table.HeaderCell key={index}>
                              {header}
                           </Table.HeaderCell>
                        ))}
                     </Table.Row>
                  </Table.Header>
                  <Table.Body>
                     {prodFinalData.map((value, index) => {
                        return (
                           <Table.Row key={index}>
                              {Object.keys(value).map((subKey, subIndex) => {
                                 if (subKey === "name") {
                                    return <Table.Cell key={subIndex} rowSpan='4'>{value[subKey]}</Table.Cell>;
                                 } else {
                                    return <Table.Cell key={subIndex}>{value[subKey]}</Table.Cell>;
                                 }
                              })}
                           </Table.Row>
                        );
                     })}
                  </Table.Body>
               </Table>
            </div>
         </div>
         { props.loading && <Loading/> }
      </React.Fragment>
   );
};

const mapStateToProps = (state) => {
   return {
      productivityData: state.ProductivityFilterData.productivityData,
      loading: state.ProductivityFilterData.loading
   };
};

export default connect(mapStateToProps, {
   
})(ProductivityDetails);