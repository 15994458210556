import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import ContentFilterSection from "../common/ContentFilter/ContentFilterSection";
import DateComponent from "../CommonFilter/DateComponent";
import * as actionTypes from "../../actions/actionTypes";
import { clearSearchForAllReport, fetchEditorialTableData } from "../../actions";
import { url } from "../../api/urlHelper";
import { AUTHOR_SUMMARY } from "../config/rolebasedReportNames";
import { VIEW } from "../config/const";

const DateAndFilterSelection = (props) => {
    const [clear, setClear] = useState(false);
    const [filterPopup, setFilterPopup] = useState("filter-popup-close");
    const [filterSelectFocus, setFilterSelectFocus] = useState("filter-icon");
    const [filterIconColor, setFilterIconColor] = useState("material-icons grey");

    const applyFilters = (startDate, endDate, appDate, isCustomCheckbox) => {
        props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].APPLY_FILTERS, reportType: props.reportActionType });
        let data = props.filterData;
        data.startDate = startDate;
        data.endDate = endDate;
        data.prevStartDate = "";
        data.prevEndDate = "";
        data.appliedDate = appDate;
        data.selCustomRangeCheckBox = isCustomCheckbox;
        if (props?.roleReports?.some((report) => [AUTHOR_SUMMARY].includes(report.element) && report.permission === VIEW)) {
            props.fetchEditorialTableData(
                url.pageviewAuthorSummary,
                data,
                1,
                "",
                actionTypes[props.reportActionType].AUTHOR_REPORT_SUMMARY,
                null,
                null,
                "independent",
                "",
                props.reportActionType
            );
        }     
        setFilterPopup("filter-popup-close");
        setFilterSelectFocus("filter-icon");
        setFilterIconColor("material-icons grey");
    }

    const clearFilters = () => {
        setClear(true);
        setTimeout(() => {
            setClear(false);
        }, 1500);
        props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].CLEARCOMMONFILTER, reportType: props.reportActionType });
    }

    return (
        <div>
            <DateComponent {...props} applyFilters={applyFilters} clear={clear} />
            <div className="popup-component">
                <div
                    className={filterSelectFocus}
                    style={{ marginTop: "-50px" }}
                    onClick={() => {
                        setFilterSelectFocus("filter-icon-selected");
                        setFilterPopup("filter-popup-open");
                        setFilterIconColor("material-icons orange");
                    }}
                >
                    <i className={filterIconColor}>filter_list</i>
                </div>
                <div className={filterPopup}>
                    <div className="filter-header">
                        <div className="filter-text"><p>Filters</p></div>
                        <div
                            className="filter-close-icon"
                            onClick={() => {
                                setFilterSelectFocus("filter-icon");
                                setFilterPopup("filter-popup-close");
                            }}
                        >
                            <i className="material-icons grey" onClick={() => setFilterIconColor("material-icons grey")}>clear</i>
                        </div>
                    </div>
                    <ContentFilterSection {...props} clear={clear} />
                    <div className="filter-buttons">
                        <button className="cancel-date-button extra-space" onClick={clearFilters}>Clear Filters</button>
                        <button
                            type="submit"
                            className="apply-filter-button"
                            onClick={() => {
                                applyFilters(
                                    props.filterData.startDate,
                                    props.filterData.endDate,
                                    props.filterData.appliedDate,
                                    props.filterData.selCustomRangeCheckBox
                                );
                            }}
                        >
                            Apply Filters
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default compose(connect(null, {
    clearSearchForAllReport,
    fetchEditorialTableData,
}))(DateAndFilterSelection);