import React from "react";
import Firmographic from "./firmographic";
import Personas from "./personas";

const Advertising = (props) => {
  let advertiseData = props.data;
  return (
    <div>
      <h5 className="drawer-heading-text">ADVERTISING</h5>
      <h5 className="drawer-heading-text"> FIRMOGRAPHIC AD TARGETING</h5>
      {advertiseData.firmographics.map((firmographic)=>(
        <Firmographic object={firmographic} />
      ))}
      <h5 className="drawer-heading-text">PERSONA AD TARGETING</h5>
      <Personas object={advertiseData.personas} />
    </div>
  );
};

export default Advertising;
