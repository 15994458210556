import React, { Component } from "react";
import { connect } from "react-redux";

import NewslettersDateAndFilter from "../components/Newsletters/DateAndFilter";
import * as constName from "../components/config/const";
import Summary from "../components/Newsletters/Summary";
import DownloadCsvXlsx from "../components/common/DownloadCsvXlsx";
import * as downloadHelper from "../components/CommonFilter/downloadHelper";
import { fetchNewslettersReportData, fetchNewslettersDetailsData, selectValue } from "../actions";
import { downloadCSVData, getReportsByElement } from "../utilities/common";
import * as configName from "../components/config/const";
import NewsletterDetails from "../components/Newsletters/NewsletterDetails/Main";
import EmailPopUp from "../components/common/EmailPopUp";
import * as actionTypes from "../actions/actionTypes";
import Toggle from "../components/base/toggle";
import { NEWSLETTERS_DETAILS, NEWSLETTERS_SUMMARY, ROLEBASED_ELEMENT_NEWSLETTERS } from "../components/config/rolebasedReportNames";

class Newsletters extends Component {

    handleNewslettersSummary = async () => {
        await downloadHelper.getNewslettersSummaryCsvData(this.props, actionTypes[constName.MODULE_NEWSLETTERS].NEWSLETTERS_SUMMARY_CSV, constName.MODULE_NEWSLETTERS);
        if (this.props.filterData.newslettersCsvData) {
            downloadCSVData(this.props.filterData.newslettersCsvData, this.props.filterData.downloadFileName);
        }
    };

    handleNewsletterDetails = async () => {
        this.props.fetchNewslettersDetailsData(
            this.props.filterData,
            1,
            "CSV",
            actionTypes[constName.MODULE_NEWSLETTERS].GENERATE_NEWSLETTER_CSV,
            constName.NEWSLETTERS_REPORT_NAME,
            constName.MODULE_NEWSLETTERS,
            this.props.userEmail
        );
    };

    handleToggleChange = () => {
        this.props.selectValue({
            dispatchType: actionTypes[constName.MODULE_NEWSLETTERS].SELECTVALUE,
            reportType: constName.MODULE_NEWSLETTERS,
            key: 'isSelectDetailToggle',
            value: !this.props.filterData.isSelectDetailToggle
        });
    };

    render() {
        const roleReports = getReportsByElement(this?.props?.roleFilterData?.currentUserReportRoleData, this?.props?.roleFilterData?.getAllReports, ROLEBASED_ELEMENT_NEWSLETTERS);

        return (
            <div>
                <div className="page-name-comp">
                    <p className="page-name">Newsletters</p>
                    <p>
                        {this.props.reportDescription.find(report => report.name === "Newsletters")?.description}
                    </p>
                </div>
                <NewslettersDateAndFilter
                    reportActionType={constName.MODULE_NEWSLETTERS}
                    reportName={constName.NEWSLETTERS_REPORT_NAME}
                    filterData={this.props.filterData}
                    label={constName.NEWSLETTERS_LABEL}
                    roleReports={roleReports}
                />
                <div>
                    {
                        roleReports?.some((report) =>
                            [NEWSLETTERS_SUMMARY].includes(report.element) &&
                                            [constName.VIEW, constName.DOWNLOAD_CSV].includes(report.permission)
                        ) &&
                        <div className="table-heading-block">
                            <div className="table-name">
                                <p>{`Newsletters Summary`}</p>
                            </div>
                                <div className="class-relative">
                            { this.props.filterData.isFilApplied && (roleReports?.some((report) => [NEWSLETTERS_SUMMARY].includes(report.element) && report.permission === constName.DOWNLOAD_CSV)) &&
                                    <div className="csv-button">
                                        <DownloadCsvXlsx
                                            onClick={this.handleNewslettersSummary}
                                            label={configName.DOWNLOAD_CSV}
                                            disabled={!this.props.filterData.isFilApplied}
                                        />
                                    </div>
                            }
                                </div>
                        </div>
                    }
                </div>
                { 
                    (roleReports?.some((report) => [NEWSLETTERS_SUMMARY].includes(report.element) && report.permission === constName.VIEW)) &&
                    <Summary
                        reportActionType={constName.MODULE_NEWSLETTERS}
                        reportName={constName.NEWSLETTERS_REPORT_NAME}
                        filterData={this.props.filterData}
                    />
                }
          
                <div>
                    { 
                        roleReports?.some((report) =>
                            [NEWSLETTERS_DETAILS].includes(report.element) &&
                            [constName.VIEW, constName.GENERATE_CSV].includes(report.permission)
                        ) &&
                        <div className="table-heading-block">
                            <div className="table-name">
                                <p>{`Newsletters Details`}</p>
                            </div>
                            { this.props.filterData.isFilApplied && (roleReports?.some((report) => [NEWSLETTERS_DETAILS].includes(report.element) && report.permission === constName.GENERATE_CSV)) &&
                                <div className="class-relative">
                                    <DownloadCsvXlsx
                                        onClick={() => this.handleNewsletterDetails()}
                                        label={configName.GENERATE_CSV}
                                        disabled={!this.props.filterData.isFilApplied}
                                    />
                                    <div className="pg popup">
                                        {this.props.filterData.NewslettersDetailCSV && (
                                            <EmailPopUp
                                                email={this.props.filterData.NewslettersDetailCSV}
                                                table="ads_common"
                                                cardPopup={this.props.filterData.NewslettersDetailCSV}
                                                csvExcel={true}
                                                emailKey="NewslettersDetailCSV"
                                                reportActionType={constName.MODULE_NEWSLETTERS}
                                            />
                                        )}
                                    </div>
                                </div>
                                }
                                {roleReports?.some((report) => [NEWSLETTERS_DETAILS].includes(report.element) && report.permission === constName.VIEW) &&
                                    <Toggle
                                        label={this.props.filterData.isSelectDetailToggle ? "Hide" : "Show"}
                                        disabled={!this.props.filterData.isFilApplied}
                                        style={{ marginBottom: "20px", paddingLeft: "30px", paddingTop: "10px" }}
                                        onChange={this.handleToggleChange}
                                        checked={this.props.filterData.isSelectDetailToggle}
                                    />
                                }
                        </div>
                    }
                 
                </div>
                { roleReports?.some((report) => [NEWSLETTERS_DETAILS].includes(report.element) && report.permission === constName.VIEW) &&
                    <NewsletterDetails
                        reportActionType={constName.MODULE_NEWSLETTERS}
                        reportName={constName.NEWSLETTERS_REPORT_NAME}
                        filterData={this.props.filterData}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        filterData: state.commonFilterData[constName.MODULE_NEWSLETTERS],
        userEmail: state.authorized.userEmail,
        reportDescription: state.allFilterReportData.reportDescription,
        roleFilterData: state.allFilterReportData,
    };
};

export default connect(mapStateToProps, {
    fetchNewslettersReportData,
    fetchNewslettersDetailsData,
    selectValue,
    getReportsByElement
})(Newsletters);