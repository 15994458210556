import React from "react";
import MultipleSelectDropDown from "../base/multiSelectDropdown";

const PageTitlePicker = (props) => {
	return (
		<MultipleSelectDropDown
			options={props.titles?.map((title, index) => ({
				key: index,
				text: title.title,
				value: title.id,
			}))}
			label="Page Title"
			placeholder="Page Title"
			onSearchChange={props.onSearchChange}
			value={props.value}
			onChange={props.onChange}
			onClick={props.onClick}
		/>
	);
};

export default PageTitlePicker;
