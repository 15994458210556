import React, { useState, useEffect } from "react";
import { Icon, Message, Table } from "semantic-ui-react";
import { connect } from "react-redux";
import { saveReportDescription, updateReportDescription, fetchReportDescription } from "../actions";
import "../css/common.css";

const ReportDescription = (props) => {
    const { reportDescription, saveReportDescriptionStatus, updateReportDescription } = props;
    const [data, setData] = useState([]);
    const [editingRowId, setEditingRowId] = useState(null);
    const [editedDescription, setEditedDescription] = useState("");
    const [message, setMessage] = useState(null);

    useEffect(() => {
        props.fetchReportDescription();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setData(reportDescription);
    }, [reportDescription]);

    useEffect(() => {
        if (saveReportDescriptionStatus === 200) {
            updateReportDescription(data);
            setMessage({ header: "Success", body: "Data saved successfully" });
        } else if (saveReportDescriptionStatus >= 400 && saveReportDescriptionStatus < 600) {
            setMessage({ header: "Error", body: "Error Occurred: Data not saved" });
        }
    }, [saveReportDescriptionStatus, data, updateReportDescription]);

    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                setMessage(null);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [message]);

    const handleEdit = (rowId, currentDescription) => {
        setEditingRowId(rowId);
        setEditedDescription(currentDescription);
    };

    const handleSave = (rowId) => {
        const updatedRow = data.find((row) => row.id === rowId);

        if (updatedRow && updatedRow.description !== editedDescription) {
            const updatedData = data.map((row) =>
                row.id === rowId ? { ...row, description: editedDescription } : row
            );
            setData(updatedData);

            props.saveReportDescription([{ ...updatedRow, description: editedDescription }]);
        }

        setEditingRowId(null);
    };

    const handleCancel = () => {
        setEditingRowId(null);
        setEditedDescription("");
    };

    return (
        <React.Fragment>
            <div className="page-name-comp">
                <p className="page-name">Report Description</p>
            </div>
            <div className="sortable-table-component" style={{ overflowX: "hidden", padding: "20px" }}>
                <Table sortable fixed singleLine className="report-description-table">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={4}>Name</Table.HeaderCell>
                            <Table.HeaderCell width={10}>Description</Table.HeaderCell>
                            <Table.HeaderCell width={2} textAlign="center">Edit</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {data
                            ?.slice()
                            .sort((a, b) => a.id - b.id)
                            .map((row) => (
                                <Table.Row key={row.id} style={{ height: "50px", lineHeight: "1.2", padding: "0" }}>
                                    <Table.Cell>{row.name}</Table.Cell>
                                    <Table.Cell>
                                        {editingRowId === row.id ? (
                                            <textarea
                                                value={editedDescription}
                                                onChange={(e) => setEditedDescription(e.target.value)}
                                                style={{ width: "100%", height: "auto", padding: "8px", fontSize: "14px", lineHeight: "1.5", border: "1px solid #ccc", borderRadius: "4px", resize: "vertical", overflow: "auto", boxSizing: "border-box" }}
                                                rows={3}
                                            />
                                        ) : (
                                            <div style={{ padding: "4px", whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                                                {row.description}
                                            </div>
                                        )}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {editingRowId === row.id ? (
                                            <React.Fragment>
                                                <Icon
                                                    name="check"
                                                    color="orange"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => handleSave(row.id)}
                                                />
                                                <Icon
                                                    name="close"
                                                    color="grey"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={handleCancel}
                                                />
                                            </React.Fragment>
                                        ) : (
                                            <Icon
                                                name="edit"
                                                color="orange"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleEdit(row.id, row.description)}
                                            />
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                    </Table.Body>
                </Table>
            </div>

            {message && (
                <div className="error-message-block">
                    <Message
                        compact
                        positive={message.header === "Success"}
                        negative={message.header !== "Success"}
                        style={{
                            borderRadius: "8px",
                            padding: "10px 20px",
                            margin: "20px auto",
                            maxWidth: "500px",
                        }}
                    >
                        <Message.Header>{message.header}</Message.Header>
                        <p>{message.body}</p>
                    </Message>
                </div>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        reportDescription: state.allFilterReportData.reportDescription,
        saveReportDescriptionStatus: state.allFilterReportData.saveReportDescriptionStatus,
    };
};

export default connect(mapStateToProps, {
    saveReportDescription,
    updateReportDescription,
    fetchReportDescription,
})(ReportDescription);
