import * as actionTypes from "../actions/actionTypes";
import {
  getDisplayDate,
  getYesterdaysDate,
  getLastDays,
} from "../utilities/common";
import _ from "lodash";

const siteReportFilterData = {
  startDate: getDisplayDate(getLastDays(0)),
  endDate: getDisplayDate(getYesterdaysDate()),
  appliedDate: "2",
  previousStartDate: "",
  previousEndDate: "",
  selCutomRangeChkBox: false,
  checkboxState: false,
  showSiteChart: false,
  
  siteAllPgData: [],
  allPageCount: 0,
  allColumn: null,
  allDirection: null,
  allPGSummaryDirection: null,
  isLoadingSiteAllPgData: false,

  siteEditorialData: [],
  edPageCount: 0,
  edColumn: null,
  edDirection: null,
  edPGSummaryDirection: null,
  isLoadingSiteEditorialPgData: false,

  siteSponsoredData: [],
  spPageCount: 0,
  spColumn: null,
  spDirection: null,
  spPGSummaryDirection: null,
  isLoadingSiteSponsoredPgData: false,

  isApplied: false,
  siteReportCharts: {},
  isLoadingChart: false,
  resPageviewSummaryValue: null,
  resEditorialPageviewSummaryValue: null
};

export const siteReportDataReducer = (state = siteReportFilterData, action) => {
  switch (action.type) {
    case actionTypes.SET_SITE_VALUES:
			return { ...state, [action.payload.key]: action.payload.value };
    case actionTypes.SELSITEREPORTSTARTDATE:
      return { ...state, startDate: action.payload };
    case actionTypes.SELSITEREPORTENDDATE:
      return { ...state, endDate: action.payload };
    case actionTypes.SELSITEREPORTPREVSTARTDATE:
      return { ...state, previousStartDate: action.payload };
    case actionTypes.SELSITEREPORTPREVENDDATE:
      return { ...state, previousEndDate: action.payload };
    case actionTypes.APPLYSITEREPORTDATE:
      return { ...state, appliedDate: action.payload };
    case actionTypes.SELSITEREPORTCUSTOMRANGE:
      return { ...state, selCutomRangeChkBox: action.payload };
    case actionTypes.SHOWSITECHARTS:
      return {
        ...state,
        showSiteChart: !state.showSiteChart,
      };

    //All PG
    case actionTypes.ISLOADINGSITEALLPGSUMMARYTABLE:
      return { ...state, isLoadingSiteAllPgData: true, siteAllPgData: [], isApplied: true, allPageCount: 1 };
    case actionTypes.SITEALLPGSUMMARYREPORTDATA:
      if (action.payload === null || _.isEmpty(action.payload)) {
        return { ...state, isLoadingSiteAllPgData: false };
      } else {
        return {
          ...state,
          siteAllPgData: action.payload,
          isLoadingSiteAllPgData: false,
        };
      }
    case actionTypes.CLEARALLPGSORT:
      return { ...state, allColumn: null, allDirection: null, allPGSummaryDirection: null };
    case actionTypes.MOREALLPGSUMMARYLOADING:
      return {
        ...state,
        isLoadingSiteAllPgData: true,
        resPageviewSummaryValue: action.resPageviewSummaryValue
      };
    case actionTypes.SETALLPAGEVIEWSUMMARYPGCOUNT:
      return {
        ...state, allPageCount: state.allPageCount + 1,
      };

    // Editorial PG
    case actionTypes.SITEEDITORIALPGSUMMARYREPORTDATA:
        if (action.payload === null || _.isEmpty(action.payload)) {
          return { ...state, isLoadingSiteEditorialPgData: false };
        } else {
          return {
            ...state,
            siteEditorialData: action.payload,
            isLoadingSiteEditorialPgData: false,
          };
        }
    case actionTypes.ISLOADINGSITEEDITORIALPGSUMMARYTABLE:
        return { ...state, isLoadingSiteEditorialPgData: true, siteEditorialData: [], edPageCount: 1 };
    case actionTypes.CLEAREDPGSORT:
      return { ...state, edColumn: null, edDirection: null, edPGSummaryDirection: null };
    case actionTypes.MOREEDITORIALPGSUMMARYLOADING:
      return {
        ...state,
        isLoadingSiteEditorialPgData: true,
        resEditorialPageviewSummaryValue: action.resEditorialPageviewSummaryValue
      };
    case actionTypes.SETEDPAGEVIEWSUMMARYPGCOUNT:
      return {
        ...state, edPageCount: state.edPageCount + 1,
      };
    // Sponsored PG
    case actionTypes.SITESPONSOREDPGSUMMARYREPORTDATA:
        if (action.payload === null || _.isEmpty(action.payload)) {
          return { ...state, isLoadingSiteSponsoredPgData: false };
        } else {
          return {
            ...state,
            siteSponsoredData: action.payload,
            isLoadingSiteSponsoredPgData: false,
          };
        }
    case actionTypes.ISLOADINGSITESPRONSOREDPGSUMMARYTABLE:
        return { ...state, isLoadingSiteSponsoredPgData: true, siteSponsoredData: [], spPageCount: 1 };
    case actionTypes.CLEARSPPGSORT:
      return { ...state, spColumn: null, spDirection: null, spPGSummaryDirection: null };
    case actionTypes.MORESPONSOREDPGSUMMARYLOADING:
      return {
        ...state,
        isLoadingSiteSponsoredPgData: true,
        resSponsoredPageviewSummaryValue: false
      };
    case actionTypes.SETSPONSOREDPAGEVIEWSUMMARYPGCOUNT:
      return {
        ...state, spPageCount: state.spPageCount + 1,
      };
      
    case actionTypes.SITEREPORTCHARTS:
      //let checkedState = action.filterData.selCutomRangeChkBox && action.filterData.appliedDate === "Custom Date Range";
      return {
        ...state,
        //	checkboxState: checkedState,
        siteReportCharts: action.payload,
        isLoadingChart: false,
      };
    case actionTypes.SITEREPORTLOADINGCHARTS:
      return {
        ...state,
        isLoadingChart: true,
      };
    default:
      return state;
  }
};
