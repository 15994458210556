import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import { Icon } from 'semantic-ui-react';
import _ from 'lodash';

import { Logo } from '../assets/logo';
import { getInitials } from '../utilities/common';
import { getCookie } from '../utilities/envCommon';
import { changeSidebarAnimation, changeGridColumn, openAdMenus, openPageviewMenus, filterSelectValue, loginWithWordPressAPI, loginWithOktaAPI, toggleNestedMenu } from '../actions';
import '../hoc/layout.css';
import * as actionTypes from "../actions/actionTypes";

import UserProfile from './UserProfile';

class Header extends Component {
  constructor() {
    super();
    this.state = {
      menuVisible: false,
    };
    this.openMenuBar = this.openMenuBar.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.refreshCampaignStatus) {
      setTimeout(() => {
        this.props.filterSelectValue({ key: "refreshCampaignStatus", value: false });
      }, 5000);
    }
    if (this.props.clearColumnsStatus) {
      setTimeout(() => {
        this.props.filterSelectValue({ key: "clearColumnsStatus", value: false });
        window.location.reload(true);
      }, 5000);
    }
  }

  openMenuBar() {
    this.setState({ menuVisible: !this.state.menuVisible });
  }

  render() {
    let entryMessage;
    let routeRedirect;
    let drawer;
    if (getCookie('token') === null && !_.includes(['/admin', '/callback', '/okta/oauth/callback'], this.props.location.pathname)) {
      routeRedirect = <Navigate to='/' />;
    }
    if (this.props.isAuth && !_.includes(['/admin', '/callback', '/okta/oauth/callback'], this.props.location.pathname)) {
      drawer = (
        <div className='app-menu-open'>
          {this.props.menuVisible ? (
            <ArrowBackIcon className='drawer-icon' onClick={() => {
              this.props.toggleNestedMenu({});
              this.props.changeSidebarAnimation();
              this.props.changeGridColumn(1, 15);
              this.props.openPageviewMenus(false, actionTypes.OPENPAGEVIEWSIDEBARMENUS );
              this.props.openAdMenus(false, actionTypes.OPENADSIDEBARMENUS );
            }} />
          ) : (
            <MenuIcon className='drawer-icon' onClick={() => {
              this.props.toggleNestedMenu({});
              this.props.changeSidebarAnimation();
              this.props.changeGridColumn(2, 14);
              this.props.openPageviewMenus(false, actionTypes.OPENPAGEVIEWMENUS );
              this.props.openAdMenus(false, actionTypes.OPENADMENUS );
            }} />
          )}
        </div>
      )
      entryMessage = (
        <div className='app-user-info'>
          <div className='app-avatar'>
            <div className='app-initials'>{getInitials(this.props.user)}</div>
          </div>
          <div className='app-user-name-block profile_hovor' onClick={this.openMenuBar}>
            <div className='app-message'>
              <p>Welcome</p>
            </div>
            <div className='app-user'>
              <p>{this.props.user}</p>
            </div>
          </div>
          <Icon className="sticky-icons arrow_down profile_hovor" name="angle down" onClick={this.openMenuBar} />
          {
            this.state.menuVisible &&
            <UserProfile {...this.props} openMenuBar={this.openMenuBar} />
          }
        </div>
      );
    }

    return (
      <div className='app-header'>
        {drawer}
        <div className='app-logo'>
          <a href={this.props.wpLink}>
            <Logo></Logo>
          </a>
        </div>
        {entryMessage}
        {routeRedirect}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menuVisible: state.menuVisible.visible,
    refreshCampaignStatus: state.filterData.refreshCampaignStatus,
    clearColumnsStatus: state.filterData.clearColumnsStatus,
  };
};

const HeaderWithRouter = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  return <Header {...props} location={location} navigate={navigate} />;
};

export default compose(
  connect(mapStateToProps, { 
    changeSidebarAnimation, 
    changeGridColumn, 
    openAdMenus, 
    openPageviewMenus, 
    filterSelectValue,
    loginWithWordPressAPI,
    loginWithOktaAPI,
    toggleNestedMenu
  })
)(HeaderWithRouter);