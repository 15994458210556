import React from "react";
import MultiLinks from "../../../hyperlinks/multiLinks";
import { Table } from "semantic-ui-react";

const AccountOrderComponent = ({ data }) => {
    const accountName = data?.account?.[0].name || "";
    return (
        <Table.Cell title={accountName} className="blogs_link">
            {data.account ? (
                <MultiLinks
                    type="organizations"
                    object={data.account}
                />
            ) : (
                accountName
            )}
        </Table.Cell>
    );
};

export default AccountOrderComponent;
