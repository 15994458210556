import React, { useRef, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Form } from "semantic-ui-react";
import DatePicker from "../common/datePicker";
import CommonDateRange from "../common/CommonDateRange";
import {
   downloadSelectValue,
   downloadAddFilterById,
   fetchOrganizations,
   fetchCampaigns,
   downloadClearSearch,
   fetchOrder,
   fetchDownloadData
} from "../../actions";
import { getStartEndDates, getDisplayDate, getYesterdaysDate } from "../../utilities/common";
import { DateRangePicker } from "react-date-range";
import ApplyAndCancelButton from "../common/applyAndCancelButton";
import OrganizationPicker from "../common/organization";
import SitePicker from "../common/site";
import _ from "lodash";
import * as actionTypes from "../../actions/actionTypes";
import OrderPicker from "../common/order";
import CampaignPicker from "../common/campaign";
import DateRange from "../common/DateRange";
import RequesterStatus from "../common/RequesterStatus";
import CommonRequestUserPicker from "../common/CommonRequestUserPicker";
import Toggle from "../base/toggle";

const DateAndFilter = (props) => {
   const [datePopup, setDatePopup] = useState("custom-date-popup-close");
   const [filterPopup, setFilterPopup] = useState("filter-popup-close");
   const [filterSelectFocus, setFilterSelectFocus] = useState("filter-icon");
   const [filterIconColor, setFilterIconColor] = useState("material-icons grey");
   const [datePopupFilter, setDatePopupFilter] = useState("custom-date-popup-close");
   const clearfilterClick = useRef(false);
   const [dateRange, setDateRange] = useState({
      selection: {
         startDate: getYesterdaysDate(0),
         endDate: getYesterdaysDate(0),
         key: "selection",
      },
   });

   const [dateRangeFilter, setDateRangeFilter] = useState({
      selection: {
         startDate: getYesterdaysDate(0),
         endDate: getYesterdaysDate(0),
         key: "selection",
      },
   });

   const selectDateRange = async (e, { value }) => {
      props.downloadSelectValue({ key: "appliedDate", value });
      setDatePopup("custom-date-popup-close");
      if (value === "Custom Date Range") {
         setDatePopup("custom-date-popup-open");
      } else {
         let dates = getStartEndDates(value, 0);
         props.downloadSelectValue({ key: "startDate", value: dates.startDate });
         props.downloadSelectValue({ key: "endDate", value: dates.endDate });
         applyFilters(dates.startDate, dates.endDate, value);
      }
   };

   const applyFilters = (startDate, endDate, appDate) => {
      props.downloadClearSearch(actionTypes.APPLYDOWNLOADFILTER);
      let tempPgFilterDAta = props.filterData;
      tempPgFilterDAta.startDate = startDate;
      tempPgFilterDAta.endDate = endDate;
      props.fetchDownloadData(
         tempPgFilterDAta,
         1,
         "",
         actionTypes.FETCHDOWNLOADDATA,
         null,
      );
      setFilterPopup("filter-popup-close");
      setFilterIconColor("material-icons grey");
      setFilterSelectFocus("filter-icon");
      setDatePopup("custom-date-popup-close");
   }

   const cancelDate = () => {
      setDateRange({
         selection: {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
         },
      });
      props.downloadSelectValue({ key: "startDate", value: getDisplayDate(props.filterData.prevstartDate) });
      props.downloadSelectValue({ key: "endDate", value: getDisplayDate(props.filterData.prevendDate) });
      setDatePopup("custom-date-popup-close");
   }

   const applyDate = () => {
      props.downloadSelectValue({ key: "startDate", value: getDisplayDate(dateRange.selection.startDate) });
      props.downloadSelectValue({ key: "endDate", value: getDisplayDate(dateRange.selection.endDate) });
      setDatePopup("custom-date-popup-close");

      if (props.isFilApplied) {
         applyFilters(
            dateRange.selection.startDate,
            dateRange.selection.endDate,
            props.filterData.appliedDate
         );
      }
   }

   const searchOrg = (e) => {
      if (e.target.value.length > 1) {
         props.fetchOrganizations(
            props.filterData.excludeQACampaign,
            e.target.value,
            actionTypes.FETCHDOWNLOADORGS,
            props.organizations,
         );
      }
   };

   const onSelectOrg = (e, { value }) => {
      props.downloadSelectValue({ key: "organization", value });
      props.downloadAddFilterById(value, actionTypes.ADDDOWNLOADORGS);
      if (!_.isEmpty(props.filterData.campaign)) {
         props.fetchCampaigns(
            "",
            props.filterData.site,
            props.filterData.selTagsIN,
            props.filterData.selTagsEX,
            props.filterData.excludeQACampaign,
            "",
            value,
            "all",
            "start_date",
            actionTypes.FETCHDOWNLOADCAMPAIGNS,
            props.filterData.campaign,
            true,
            actionTypes.RESETDOWNLOADCAMPAIGNS
         );
      }
   };

   const onClickOrg = () => {
      props.downloadClearSearch(actionTypes.CLEARDOWNLOADORGS);
   };
   const searchOrder = (e) => {
      if (e.target.value.length > 1) {
         props.fetchOrder(e.target.value, "name", actionTypes.FETCHDOWNLOADORDER);
      }
   };

   const onSelectOrder = (e, { value }) => {
      props.downloadSelectValue({ key: "order", value });
      props.downloadAddFilterById(value, actionTypes.ADDDOWNLOADORDER);
   };

   const onClickOrder = () => {
      props.downloadClearSearch(actionTypes.CLEARDOWNLOADORDER);
   };

   const searchCampaign = (e, { value }) => {
      if (e.target.value.length > 1) {
         props.fetchCampaigns(
            "",
            props.filterData.site,
            props.filterData.selTagsIN,
            props.filterData.selTagsEX,
            props.filterData.excludeQACampaign,
            e.target.value,
            props.filterData.organization,
            "all",
            "start_date",
            actionTypes.FETCHDOWNLOADCAMPAIGNS
         );
      }
   };

   const onSelectCampaigns = (e, { value }) => {
      props.downloadSelectValue({ key: "campaign", value });
      props.downloadAddFilterById(value, actionTypes.ADDDOWNLOADCAMPAIGNS);
   };

   const onClickCampaign = () => {
      if (_.isEmpty(props.filterData.organization)) {
         props.downloadClearSearch(actionTypes.CLEARDOWNLOADCAMPAIGNS);
      } else if (!_.isEmpty(props.filterData.organization)) {
         props.fetchCampaigns(
            "",
            props.filterData.site,
            props.filterData.selTagsIN,
            props.filterData.selTagsEX,
            props.filterData.excludeQACampaign,
            "",
            props.filterData.organization,
            "all",
            "start_date",
            actionTypes.FETCHDOWNLOADCAMPAIGNS
         );
      }
   };

   const clearFilters = () => {
      clearfilterClick.current = true;
      if (clearfilterClick.current && props.filterData.appliedDate === "Custom Date Range") {
         setDateRange({
            selection: {
               startDate: new Date(),
               endDate: new Date(),
               key: "selection",
            },
         });
      }
      props.downloadClearSearch(actionTypes.CLEARDOWNLOADFILTER);
      const actionTypesToClear = [
         actionTypes.RESETDOWNLOADORGS,
         actionTypes.RESETDOWNLOADCAMPAIGNS,
         actionTypes.RESETDOWNLOADORDER
      ]
      actionTypesToClear.forEach((actionType) => {
         props.downloadClearSearch(actionType);
      });
      setDatePopup("custom-date-popup-close");
   }

   const selectDateRangeFilter = (e, { value }) => {
      props.downloadSelectValue({ key: "appliedDateFilter", value });
      setDatePopupFilter("custom-date-popup-close");
      if (value === "Custom Date Range") {
         setDatePopupFilter("custom-date-popup-open");
      } else {
         let dates = getStartEndDates(value);
         props.downloadSelectValue({ key: "wp_startDate", value: getDisplayDate(dates.startDate) });
         props.downloadSelectValue({ key: "wp_endDate", value: getDisplayDate(dates.endDate) });
      }
   };

   const applyDateFilter = () => {
      props.downloadSelectValue({ key: "wp_startDate", value: getDisplayDate(dateRangeFilter.selection.startDate) });
      props.downloadSelectValue({ key: "wp_endDate", value: getDisplayDate(dateRangeFilter.selection.endDate) });
      setDatePopupFilter("custom-date-popup-close");
    };

   const cancelDateFilter = () => {
      setDateRangeFilter({
        selection: {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      });
      setDatePopupFilter("custom-date-popup-close");
   };

   return (
      <React.Fragment>
         <div>
            <div className="date-picker">
               {/* Date picker */}
               <div>
                  <Form>
                     <Form.Group widths="equal">
                        <DatePicker
                           floating
                           value={props.filterData.appliedDate}
                           onChange={selectDateRange}
                           openDateRange={(e, { value }) => {
                              if (value === "Custom Date Range") {
                                 props.downloadSelectValue({ key: "appliedDate", value: "Custom Date Range" });
                                 setDatePopup("custom-date-popup-open");
                              }
                           }}
                        />
                        <CommonDateRange
                           startDate={props.filterData.startDate}
                           endDate={props.filterData.endDate}
                        />
                     </Form.Group>
                  </Form>
               </div>
               {/* Filter icon */}
               <div
                  className={filterSelectFocus}
                  onClick={() => {
                     setFilterSelectFocus("filter-icon-selected");
                     setFilterPopup("filter-popup-open");
                     setFilterIconColor("material-icons orange");
                  }}
               >
                  <i className={filterIconColor}>filter_list</i>
               </div>
            </div>

            <div className="popup-component">
               {/* Custom date picker component */}
               <div className={datePopup}>
                  <div className="date-picker-close-icon" onClick={() => { setDatePopup("custom-date-popup-close"); }} >
                     <i className="material-icons grey">clear</i>
                  </div>
                  <div>
                     <DateRangePicker
                        minDate={new Date("2019-12-13")}
                        maxDate={new Date()}
                        onChange={(item) => { setDateRange({ ...dateRange, ...item }) }}
                        months={2}
                        moveRangeOnFirstSelection={false}
                        direction={"horizontal"}
                        ranges={[dateRange.selection]}
                        rangeColors={["#e5582b"]}
                     />
                  </div>
                  <ApplyAndCancelButton props={props} cancelDate={cancelDate} applyDate={applyDate} />
               </div>

               {/* Search filter popup */}
               <div className={filterPopup}>
                  <div className="filter-header">
                     <div className="filter-text">
                        <p>Filters</p>
                     </div>
                     <div
                        className="filter-close-icon"
                        onClick={() => {
                           setFilterSelectFocus("filter-icon");
                           setFilterPopup("filter-popup-close");
                        }}
                     >
                        <i className="material-icons grey" onClick={() => setFilterIconColor("material-icons grey")}>clear</i>
                     </div>
                  </div>
                  <Form>
                     <div className="pg-search-filter">
                        <Form.Group widths="equal">
                           {/* filter custom date picker */}
                           <DatePicker
                              style={{ width: "100% !important" }}
                              value={props.filterData.appliedDateFilter}
                              onChange={selectDateRangeFilter}
                              showAllDates={true}
                              openDateRange={(e, { value }) => {
                                 if (value === "Custom Date Range") {
                                    document.getElementsByClassName(
                                       "rdrDateInput"
                                    )[2].style.display = "none";
                                    document.getElementsByClassName(
                                       "rdrDateInput"
                                    )[3].style.display = "none";
                                    document.getElementsByClassName(
                                       "rdrDateDisplayWrapper"
                                    )[1].style.display = "none";
                                    props.downloadSelectValue({ key: "appliedDateFilter", value: "Custom Date Range" });
                                    setDatePopupFilter("custom-date-popup-open");
                                 }
                              }}
                           />
                           <span style={{ width: "25em" }}>
                              {
                                 !_.isEmpty(props.filterData.wp_startDate) && !_.isEmpty(props.filterData.wp_endDate) &&
                                 <DateRange
                                    startDate={props.filterData.wp_startDate}
                                    endDate={props.filterData.wp_endDate}
                                 />
                              }
                           </span>
                        </Form.Group>
                        <div className={datePopupFilter}>
                           <div
                              className="date-picker-close-icon"
                              onClick={() => {setDatePopupFilter("custom-date-popup-close"); }}
                           >
                              <i className="material-icons grey">clear</i>
                           </div>
                           <div>
                              <DateRangePicker
                                 minDate={new Date("2019-12-13")}
                                 maxDate={ props.filterData.endDate ? new Date(props.filterData.endDate) : new Date() }
                                 onChange={(item) => { setDateRangeFilter({ ...dateRangeFilter, ...item }) }}
                                 months={2}
                                 moveRangeOnFirstSelection={false}
                                 direction="horizontal"
                                 ranges={[dateRangeFilter.selection]}
                                 rangeColors={["#e5582b"]}
                              />
                           </div>
                           <div className="date-buttons">
                              <button type="button" className="cancel-date-button" onClick={cancelDateFilter}>Cancel</button>
                              <button type="button" className="apply-date-button" onClick={applyDateFilter}>Apply</button>
                           </div>
                        </div>
                        <Form.Group widths="equal">
                           <OrganizationPicker
                              onSearchChange={searchOrg}
                              value={props.filterData.organization}
                              onChange={onSelectOrg}
                              onClick={onClickOrg}
                              organizations={props.organizations}
                           />
                           <OrderPicker
                              onSearchChange={searchOrder}
                              value={props.filterData.order}
                              onChange={onSelectOrder}
                              onClick={onClickOrder}
                              order={props.order}
                           />
                        </Form.Group>
                        <Form.Group widths="equal">
                           <SitePicker
                              value={props.filterData.site}
                              onChange={(e, { value }) => props.downloadSelectValue({ key: "site", value })}
                           />
                           <CampaignPicker
                              onSearchChange={searchCampaign}
                              value={props.filterData.campaign}
                              onChange={onSelectCampaigns}
                              campaigns={props.campaigns}
                              onClick={onClickCampaign}
                           />
                        </Form.Group>
                        <Form.Group widths="equal">
                           <RequesterStatus
                              value={props.filterData.requestedStatus}
                              onChange={(e, { value }) => props.downloadSelectValue({ key: "requestedStatus", value })}
                           />
                           <CommonRequestUserPicker
                              filterData={props.filterData}
                              value={props.filterData.requestedUser}
                              data={props.requestedUserData}
                              clearAction={actionTypes.CLEARDOWNLOADREQUESTEDBY}
                              fetchAction={actionTypes.FETCHDOWNLOADREQUESTEDBY}
                           />
                        </Form.Group>
                        <Toggle
                           className="eng_toggle"
                           label={"Automated Report"}
                           onChange={(e) => props.downloadSelectValue({ key: "isAutomated", value: !props.filterData.isAutomated })}
                           checked={props?.filterData?.isAutomated}
                        />
                     </div>
                  </Form>
                  <div className="filter-buttons">
                     <button className="cancel-date-button extra-space" onClick={clearFilters}>Clear Filters</button>
                     <button
                        type="submit"
                        className="apply-filter-button"
                        onClick={() =>
                           applyFilters(
                              props.filterData.startDate,
                              props.filterData.endDate,
                              props.filterData.appliedDate
                           )
                        }
                     >
                        Apply Filters
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </React.Fragment>
   );
}

const mapStateToProps = (state) => {
   return {
      isFilApplied: state.downloadFetchData.isFilApplied,
      filterData: state.downloadFilterData,
      organizations: Object.values(state.downloadOrg),
      order: Object.values(state.downloadOrder),
      campaigns: Object.values(state.downloadCampaign),
      requestedUserData: Object.values(state.downloadReqestedBy),
   };
};

export default compose(connect(mapStateToProps, {
   downloadSelectValue,
   downloadAddFilterById,
   fetchOrganizations,
   fetchCampaigns,
   downloadClearSearch,
   fetchOrder,
   fetchDownloadData
}))(DateAndFilter);