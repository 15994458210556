import React from "react";
import MultiLinks from "../../../hyperlinks/multiLinks";
import { Table } from "semantic-ui-react";

const AccountComponent = ({ data }) => {
    return (
        <Table.Cell title={data.account} className="blogs_link">
            {data.organizations ? (
                <MultiLinks
                    type="organizations"
                    object={data.organizations}
                />
            ) : (
                data.account
            )}
        </Table.Cell>
    );
};

export default AccountComponent;
