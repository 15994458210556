import _ from "lodash";
import React, { useRef }from "react";
import { connect } from "react-redux";
import { Checkbox, Loader } from "semantic-ui-react";
import {selTopicToggles} from "../../actions";
import * as actionTypes from "../../actions/actionTypes";
import { getCapital, getDisplayDateChart } from "../../utilities/common";
import DownloadChart from "../common/downloadChart";
import TopicGraph from "./topicGraph";

const TopicChart = (props) => {
  const chartNameDiv = useRef(null);
    let loading;
    if (props.loading) {
            loading = (
              <div className="show-loader">
                <Loader active inline="centered" />
              </div>
            );     
    }

    return (
        <>
        <div className="ad-graph">
            <div ref={chartNameDiv}>{
              !_.isEmpty(props.topicCharts) &&  props.filterData.showTopicChart && 
              <DownloadChart
                chartNameDiv= {chartNameDiv.current}
                chartName = {(`Sidebar-Chart-${getDisplayDateChart(new Date())}.jpg`)}
                style={{width:"36px", height: "36px", marginTop: 19, marginRight: 70, color: "#838486" }}
              />
            }
            <p className="ad-chart-name">
            {getCapital("Sidebar Impressions and Click Through Rate (CTR)")}
           	  <Checkbox
             className="checkbox-label"
             data-html2canvas-ignore="true"
           // slider
             toggle
             style={{
               color: "white",
               fontFamily: "Roboto",
               fontSize: "16px",
               paddingLeft: "20px",
               minHeight: 15,
             }}
             label={props.filterData.showTopicChart ? "Hide" : "Show"}
             onChange={() => {
               props.selTopicToggles(actionTypes.SHOWTOPICCHARTS);
             }}
             checked={props.filterData.showTopicChart}
           />
           {props.isFilApplied && ((_.isEmpty(props.topicSummary) || _.isEmpty(props.topicDetail))) && props.filterData.showTopicChart && 
           <span
           className="message-chart-text"
           style={{ marginLeft: "30px" }}
         >
           The chart will be displayed once the table data is loaded
         </span>
           }
            {props.isFilApplied &&
              props.filterData.showTopicChart && 
                _.size(props.topicSummary) > 0 && _.size(props.topicDetail) > 0 &&
              loading}
            {!_.isEmpty(props.topicCharts) && props.filterData.showTopicChart && 
                <TopicGraph />
            }
            </p>  
            </div>
        </div>
        </>
	);
 
}

const mapStateToProps = (state) => {
	return {
		topicCharts: state.topicFetchData.topichubChart,
		isFilApplied: state.topicFilterData.isFilApplied,
		filStartDate: state.topicFilterData.startDate,
		filEndDate: state.topicFilterData.endDate,
		filterData: state.topicFilterData,
        loading: state.topicFetchData.isLoadingChart,
        topicSummary: state.topicFetchData.topicSummary,
		topicDetail: state.topicFetchData.topicDetail,
        isLoadingTopicDetail: state.topicFetchData.isLoadingTopicDetail,
        isLoadingTopicSummary: state.topicFetchData.isLoadingTopicSummary,
	};
};

export default connect(mapStateToProps, {selTopicToggles})(TopicChart);