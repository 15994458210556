import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";

const topicSelData = {
	orgs: [],
	campaigns: [],
	order: [],
	engOrgs: [],
	country: [],
	engMember: []
};

const leadSelData = {
	orgs: [],
	campaigns: [],
	order: [],
	engOrgs: [],
	titles: [],
};

const clientLeadSelData = {
	orgs: [],
	campaigns: [],
	order: [],
	engOrgs: [],
	titles: [],
};

export const leadPageTitleReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHLEADPAGETITLE:
			return {
				..._.mapKeys(leadSelData.titles, "id"),
				..._.mapKeys(action.payload, "id"),
			};
		case actionTypes.ADDLEADPAGETITLE:
			leadSelData.titles = [];
			const pagetitles = action.payload;
			pagetitles.forEach(function (title) {
				leadSelData.titles.push(state[title]);
			});
			return { ..._.mapKeys(leadSelData.titles, "id") };
		case actionTypes.CLEARSEARCHLEADPAGETITLE:
			return { ..._.mapKeys(leadSelData.titles, "id") };
		case actionTypes.SELINILEADPAGETITLE:
			leadSelData.titles = action.page_title;
			return { ..._.mapKeys(action.page_title, "id") };
		case actionTypes.RESETLEADPAGETITLE:
			leadSelData.titles = [];
			return leadSelData.titles
		default:
			return state;
	}
};

export const leadOrganizationsReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHLEADORGS:
			return {
				..._.mapKeys(leadSelData.orgs, "uuid"),
				..._.mapKeys(action.payload, "uuid"),
			};
		case actionTypes.ADDLEADORGS:
			leadSelData.orgs = [];
			const orgIds = action.payload;
			orgIds.forEach(function (orgId) {
				leadSelData.orgs.push(state[orgId]);
			});
			return { ..._.mapKeys(leadSelData.orgs, "uuid") };
		case actionTypes.RESETLEADORGS:
			leadSelData.orgs = [];
			return leadSelData.orgs;
		case actionTypes.CLEARSEARCHLEADORGS:
			return { ..._.mapKeys(leadSelData.orgs, "uuid") };
		case actionTypes.SELINILEADORGS:
			leadSelData.orgs = action.organizations;
			return { ..._.mapKeys(action.organizations, "uuid") };
		default:
			return state;
	}
};

export const leadCampaignsReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHLEADCAMPAIGNS:
			return {
				..._.mapKeys(leadSelData.campaigns, "uuid"),
				..._.mapKeys(action.payload, "uuid"),
			};
		case actionTypes.READENGAGEMENTCAMPID:
			leadSelData.campaigns = [];
			const campaignNames = action.payload;
			campaignNames.forEach(function (campaignName) {
				leadSelData.campaigns.push(campaignName);
			});
			return { ..._.mapKeys(leadSelData.campaigns) };
		case actionTypes.ADDLEADCAMPAIGNS:
			leadSelData.campaigns = [];
			const campaignIds = action.payload;
			campaignIds.forEach(function (campaignId) {
				leadSelData.campaigns.push(state[campaignId]);
			});
			return { ..._.mapKeys(leadSelData.campaigns, "uuid") };
		case actionTypes.FETCHSELLEADCAMPAIGNS:
			leadSelData.campaigns = action.payload;
			return { ..._.mapKeys(leadSelData.campaigns, "uuid") };
		case actionTypes.RESETLEADCAMPAIGN:
			leadSelData.campaigns = [];
			return leadSelData.campaigns;
		case actionTypes.CLEARSEARCHLEADCAMPAIGN:
			return { ..._.mapKeys(leadSelData.campaigns, "uuid") };
		case actionTypes.SELINILEADCAMPAIGNS:
			leadSelData.orgs = action.campaigns;
			return { ..._.mapKeys(action.campaigns, "uuid") };
		default:
			return state;
	}
};

export const topicOrderReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHTOPICORDER:
			return {
				..._.mapKeys(topicSelData.order, "uuid"),
				..._.mapKeys(action.payload, "uuid"),
			};
		case actionTypes.ADDTOPICORDER:
			topicSelData.order = [];
			const orderIds = action.payload;
			orderIds.forEach(function (orderId) {
				topicSelData.order.push(state[orderId]);
			});
			return { ..._.mapKeys(topicSelData.order, "uuid") };
		case actionTypes.RESETTOPICORDER:
			topicSelData.order = [];
			return topicSelData.order;
		case actionTypes.CLEARSEARCHTOPICORDER:
			return { ..._.mapKeys(topicSelData.order, "uuid") };
		case actionTypes.SELINITOPICORDER:
			topicSelData.order = action.order;
			return { ..._.mapKeys(action.order, "uuid") };
		default:
			return state;
	}
};

export const topicCountryReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHTOPICCOUNTRY:
			return { 
				...state,
				..._.mapKeys(action.payload, "name"),
			};
		case actionTypes.ADDSIDEBARCONTRIES:
			topicSelData.country = [];
			const countryData = action.payload;
			countryData.forEach(function (countryId) {
					topicSelData.country.push(state[countryId]);
			});
		return { ..._.mapKeys(topicSelData.country,"name"),};
		case actionTypes.CLEARSEARCHTOPICCOUNTRY:
			return {...state, ..._.mapKeys(topicSelData.country, "name") };
		case actionTypes.SELINITOPICCOUNTRY:
			topicSelData.country = action.country
			return { ..._.mapKeys(topicSelData.country, "name") };
		case actionTypes.RESETTOPICCOUNTRY:
			topicSelData.country = [];
			return topicSelData.country;
		default:
			return state;
	}
};

export const topicOrganizationsReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHTOPICORGS:
			return {
				..._.mapKeys(topicSelData.orgs, "uuid"),
				..._.mapKeys(action.payload, "uuid"),
			};
		case actionTypes.ADDTOPICORGS:
			topicSelData.orgs = [];
			const orgIds = action.payload;
			orgIds.forEach(function (orgId) {
				topicSelData.orgs.push(state[orgId]);
			});
			return { ..._.mapKeys(topicSelData.orgs, "uuid") };
		case actionTypes.RESETTOPICORGS:
			topicSelData.orgs = [];
			return topicSelData.orgs;
		// case actionTypes.FETCHTOPICENGORGS:
		// 	return { ..._.mapKeys(action.payload, "uuid") };
		case actionTypes.SELINITOPICORGS:
			topicSelData.orgs = action.organizations;
			return { ..._.mapKeys(action.organizations, "uuid") };
		default:
			return state;
	}
};

export const topicCampaignsReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHTOPICCAMPAIGNS:
			return {
				..._.mapKeys(topicSelData.campaigns, "uuid"),
				..._.mapKeys(action.payload, "uuid"),
			};
		case actionTypes.READTOPICORGSID:
			topicSelData.campaigns = [];
			const campaignNames = action.payload;
			campaignNames.forEach(function (campaignName) {
				topicSelData.campaigns.push(campaignName);
			});
			return { ..._.mapKeys(topicSelData.campaigns) };
		case actionTypes.ADDTOPICCAMPAIGNS:
			topicSelData.campaigns = [];
			const campaignIds = action.payload;
			campaignIds.forEach(function (campaignId) {
				topicSelData.campaigns.push(state[campaignId]);
			});
			return { ..._.mapKeys(topicSelData.campaigns, "uuid") };
		case actionTypes.FETCHSELTOPICCAMPAIGNS:
			topicSelData.campaigns = action.payload;
			return { ..._.mapKeys(topicSelData.campaigns, "uuid") };
		case actionTypes.RESETTOPICCAMPAIGN:
			topicSelData.campaigns = [];
			return topicSelData.campaigns;
		case actionTypes.CLEARSEARCHTOPICCAMPAIGN:
			return { ..._.mapKeys(topicSelData.campaigns, "uuid") };
		case actionTypes.SELINITOPICCAMPAIGNS:
			topicSelData.orgs = action.campaigns;
			return { ..._.mapKeys(action.campaigns, "uuid") };
		default:
			return state;
	}
};

export const assetTypesReducer = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.FETCHASSETTYPES:
			return { ...state, ..._.mapKeys(action.payload.asset_types, "label") };
		default:
			return state;
	}
};

export const sitesReducer = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.FETCHSITES:
			return { ...state, ..._.mapKeys(action.payload.sites, "label") };
		default:
			return state;
	}
};

export const adorderstatusReducer = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.FETCHSTATUSES:
			return { ...state, ..._.mapKeys(action.payload.ad_statuses, "label") };
		default:
			return state;
	}
};

export const leadPartnerReducer = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.FETCHLEADPARTNER:
			return { ...state, ..._.mapKeys(action.payload.lead_partners) };
		default:
			return state;
	}
};

export const locationsReducer = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.FETCHLOCATIONS:
			return { ...state, ..._.mapKeys(action.payload.ad_locations, "label") };
		default:
			return state;
	}
};

export const senioritiesReducer = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.FETCHSENIORITIES:
			return { ...state, ..._.mapKeys(action.payload.seniorities, "id") };
		default:
			return state;
	}
};

export const jobFunctionReducer = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.FETCHJOBFUNCTIONS:
			return {
				...state,
				..._.mapKeys(action.payload.job_functions, "id"),
			};
		default:
			return state;
	}
};

export const orgTypeReducer = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.FETCHFIRMOGRAPHICORSTYPE:
			return { ...state, ..._.mapKeys(action.payload.organization_types, "value") };
		default:
			return state;
	}
};

export const orgSizeReducer = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.FETCHFIRMOGRAPHICORSSIZE:
			return { ...state, ..._.mapKeys(action.payload.organization_sizes, "value") };
		default:
			return state;
	}
};

export const orgRevenueReducer = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.FETCHFIRMOGRAPHICORSREVENUE:
			return { ...state, ..._.mapKeys(action.payload.estimated_revenues, "value") };
		default:
			return state;
	}
};

export const sectorReducer = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.FETCHFIRMOGRAPHICSECTOR:
			return { ...state, ..._.mapKeys(action.payload.industry_attributes, "sector") };
		default:
			return state;
	}
};

export const industryGroupReducer = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.FETCHFIRMOGRAPHICINDUSTRYGROUP:
			return { ...state, ..._.mapKeys(action.payload.industry_attributes, "industry_group") };
		default:
			return state;
	}
};

export const industryReducer = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.FETCHFIRMOGRAPHICINDUSTRY:
			return { ...state, ..._.mapKeys(action.payload.industry_attributes, "industry") };
		default:
			return state;
	}
};

export const subIndustryReducer = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.FETCHFIRMOGRAPHICSUBINDUSTRY:
			return { ...state, ..._.mapKeys(action.payload.industry_attributes, "sub_industry") };
		default:
			return state;
	}
};

export const leadEngOrderReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHLEADENGORDER:
			return {
				..._.mapKeys(leadSelData.order, "uuid"),
				..._.mapKeys(action.payload, "uuid"),
			};
		case actionTypes.ADDLEADENGORDER:
			leadSelData.order = [];
			const orderIds = action.payload;
			orderIds.forEach(function (orderId) {
				leadSelData.order.push(state[orderId]);
			});
			return { ..._.mapKeys(leadSelData.order, "uuid") };
		case actionTypes.RESETLEADENGORDER:
			leadSelData.order = [];
			return leadSelData.order;
		case actionTypes.CLEARSEARCHLEADENGORDER:
			return { ..._.mapKeys(leadSelData.order, "uuid") };
		case actionTypes.SELINILEADENGORDER:
			leadSelData.order = action.order;
			return { ..._.mapKeys(action.order, "uuid") };
		default:
			return state;
	}
};

export const leadEngOrgReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHLEADENGORGS:
			return {
				..._.mapKeys(leadSelData.engOrgs, "uuid"),
				..._.mapKeys(action.payload, "uuid"),
			};
		case actionTypes.ADDLEADENGORGS:
			leadSelData.engOrgs = [];
			const engorgIds = action.payload;
			engorgIds.forEach(function (engorgId) {
				leadSelData.engOrgs.push(state[engorgId]);
			});
			return { ..._.mapKeys(leadSelData.engOrgs, "uuid") };
		case actionTypes.CLEARSEARCHLEADENGORGS:
			return (state = []);
		case actionTypes.SELINILEADENGEDORGS:
			leadSelData.engOrgs = action.engaged_organizations
			return { ..._.mapKeys(action.engaged_organizations, "uuid") };
		case actionTypes.RESETLEADENGORGS:
			leadSelData.engOrgs = [];
			return leadSelData.engOrgs 
		default:
			return state;
	}
};

export const topicEngOrgReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHTOPICENGORGS:
			return {
				..._.mapKeys(topicSelData.engOrgs, "uuid"),
				..._.mapKeys(action.payload, "uuid"),
			};
		case actionTypes.ADDTOPICENGORGS:
			topicSelData.engOrgs = [];
			const engorgIds = action.payload;
			engorgIds.forEach(function (engorgId) {
				topicSelData.engOrgs.push(state[engorgId]);
			});
			return { ..._.mapKeys(topicSelData.engOrgs, "uuid") };
		case actionTypes.CLEARSEARCHTOPICENGORGS:
			return (state = []);
		case actionTypes.SELINITOPICENGORGS:
			topicSelData.engOrgs = action.engaged_organizations
			return { ..._.mapKeys(action.engaged_organizations, "uuid") };
		case actionTypes.RESETTOPICENGORGS:
			topicSelData.engOrgs = [];
			return topicSelData.engOrgs;
		default:
			return state;
	}
};

export const clientLeadOrderReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHCLIENTLEADORDER:
			return {
				..._.mapKeys(clientLeadSelData.order, "uuid"),
				..._.mapKeys(action.payload, "uuid"),
			};
		case actionTypes.ADDCLIENTLEADORDER:
			clientLeadSelData.order = [];
			const orderIds = action.payload;
			orderIds.forEach(function (orderId) {
				clientLeadSelData.order.push(state[orderId]);
			});
			return { ..._.mapKeys(clientLeadSelData.order, "uuid") };
		case actionTypes.RESETCLIENTLEADORDER:
			clientLeadSelData.order = [];
			return clientLeadSelData.order;
		case actionTypes.CLEARSEARCHCLIENTLEADORDER:
			return { ..._.mapKeys(clientLeadSelData.order, "uuid") };
		case actionTypes.SELINICLIENTLEADORDER:
			clientLeadSelData.order = action.order;
			return { ..._.mapKeys(action.order, "uuid") };
		default:
			return state;
	}
};

export const clientLeadOrganizationsReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHCLIENTLEADORGS:
			return {
				..._.mapKeys(clientLeadSelData.orgs, "uuid"),
				..._.mapKeys(action.payload, "uuid"),
			};
		case actionTypes.ADDCLIENTLEADORGS:
			clientLeadSelData.orgs = [];
			const orgIds = action.payload;
			orgIds.forEach(function (orgId) {
				clientLeadSelData.orgs.push(state[orgId]);
			});
			return { ..._.mapKeys(clientLeadSelData.orgs, "uuid") };
		case actionTypes.RESETCLIENTLEADORGS:
			clientLeadSelData.orgs = [];
			return clientLeadSelData.orgs;
		case actionTypes.CLEARSEARCHCLIENTLEADORGS:
			return { ..._.mapKeys(clientLeadSelData.orgs, "uuid") };
		case actionTypes.SELINICLIENTLEADORGS:
			clientLeadSelData.orgs = action.organizations;
			return { ..._.mapKeys(action.organizations, "uuid") };
		default:
			return state;
	}
};

export const clinetLeadPageTitleReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHCLIENTLEADPAGETITLE:
			return {
				..._.mapKeys(clientLeadSelData.titles, "id"),
				..._.mapKeys(action.payload, "id"),
			};
		case actionTypes.ADDCLIENTLEADPAGETITLE:
			clientLeadSelData.titles = [];
			const pagetitles = action.payload;
			pagetitles.forEach(function (title) {
				clientLeadSelData.titles.push(state[title]);
			});
			return { ..._.mapKeys(clientLeadSelData.titles, "id") };
		case actionTypes.CLEARSEARCHCLIENTLEADPAGETITLE:
			return { ..._.mapKeys(clientLeadSelData.titles, "id") };
		case actionTypes.SELINICLIENTLEADPAGETITLE:
			clientLeadSelData.titles = action.page_title;
			return { ..._.mapKeys(action.page_title, "id") };
		case actionTypes.RESETCLIENTLEADPAGETITLE:
			clientLeadSelData.titles = [];
			return clientLeadSelData.titles;
		default:
			return state;
	}
};

export const clientLeadCampaignsReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHCLIENTLEADCAMPAIGNS:
			return {
				..._.mapKeys(clientLeadSelData.campaigns, "uuid"),
				..._.mapKeys(action.payload, "uuid"),
			};
		case actionTypes.READLEADCAMPSID:
			clientLeadSelData.campaigns = [];
			const campaignNames = action.payload;
			campaignNames.forEach(function (campaignName) {
				clientLeadSelData.campaigns.push(campaignName);
			});
			return { ..._.mapKeys(clientLeadSelData.campaigns) };
		case actionTypes.ADDCLIENTLEADCAMPAIGNS:
			clientLeadSelData.campaigns = [];
			const campaignIds = action.payload;
			campaignIds.forEach(function (campaignId) {
				clientLeadSelData.campaigns.push(state[campaignId]);
			});
			return { ..._.mapKeys(clientLeadSelData.campaigns, "uuid") };
		case actionTypes.FETCHSELCLIENTLEADCAMPAIGNS:
			clientLeadSelData.campaigns = action.payload;
			return { ..._.mapKeys(clientLeadSelData.campaigns, "uuid") };
		case actionTypes.RESETCLIENTLEADCAMPAIGN:
			clientLeadSelData.campaigns = [];
			return clientLeadSelData.campaigns;
		case actionTypes.CLEARSEARCHCLIENTLEADCAMPAIGN:
			return { ..._.mapKeys(clientLeadSelData.campaigns, "uuid") };
		case actionTypes.SELINICLIENTLEADCAMPAIGNS:
			clientLeadSelData.orgs = action.campaigns;
			return { ..._.mapKeys(action.campaigns, "uuid") };
		default:
			return state;
	}
};

export const clientLeadEngOrgReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHCLIENTLEADENGORGS:
			return {
				..._.mapKeys(clientLeadSelData.engOrgs, "uuid"),
				..._.mapKeys(action.payload, "uuid"),
			};
		case actionTypes.ADDCLIENTLEADENGORGS:
			clientLeadSelData.engOrgs = [];
			const engorgIds = action.payload;
			engorgIds.forEach(function (engorgId) {
				clientLeadSelData.engOrgs.push(state[engorgId]);
			});
			return { ..._.mapKeys(clientLeadSelData.engOrgs, "uuid") };
		case actionTypes.CLEARSEARCHCLIENTLEADENGORGS:
			return (state = []);
		case actionTypes.SELINICLIENTLEADENGEDORGS:
			clientLeadSelData.engOrgs = action.engaged_organizations
			return { ..._.mapKeys(action.engaged_organizations, "uuid") };
		case actionTypes.RESETCLIENTLEADENGEDORGS:
			clientLeadSelData.engOrgs = []
			return clientLeadSelData.engOrgs;
		default:
			return state;
	}
};

export const sidebarEngMemberReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHSIDEBARENGMEMBER:
			return {
				..._.mapKeys(topicSelData.engMember, "user_uuid"),
				..._.mapKeys(action.payload, "user_uuid"),
			};
		case actionTypes.ADDSIDEBARENGMEMBER:
			topicSelData.engMember = [];
			const pgengorgIds = action.payload;
			pgengorgIds.forEach(function (pgengorgId) {
				topicSelData.engMember.push(state[pgengorgId]);
			});
			return { ..._.mapKeys(topicSelData.engMember, "user_uuid") };
		case actionTypes.CLEARSEARCHSIDEBARENGMEMBER:
			return (state = []);
		case actionTypes.SELINISIDEBARENGAGEDMEMBER: 
			topicSelData.engMember = action.engaged_members;
			return { ..._.mapKeys(topicSelData.engMember, "user_uuid")};
		case actionTypes.RESETTOPICENGAGEDMEMBER:
			topicSelData.engMember = [];
			return topicSelData.engMember;
	default:
			return state;
	}
};

