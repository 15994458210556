import React from "react";
import SingleSelectDropDown from "../base/singleSelectDropDown";

const RequesterStatus = (props) => {
	return (
		<SingleSelectDropDown
			className="no-blink"
			options={[
				{
					key: "0",
					text: "All",
					value: "all",
				},
				{
					key: "1",
					text: "Completed",
					value: "completed",
				},
				{
					key: "2",
					text: "Processing",
					value: "inprogress",
				},
				{
					key: "3",
					text: "Queued",
					value: "waiting",
				},
				{
					key: "4",
					text: "Failed",
					value: "failed",
				},
				
			]}
			label="Status"
			placeholder="Status"
			{...props}
		/>
	);
};

export default RequesterStatus;
