export const SCHeaderData = [
    { headerName: "Agency", headerKey: "agency_name"},
    { headerName: "Account", headerKey: "account"},
    { headerName: "Order", headerKey: "order"},
    { headerName: "Campaign", headerKey: "campaign"},
    { headerName: "Page", headerKey: "sponsored_page_title"},
    { headerName: "Primary Topic", headerKey: "sc_primary_topic"},
    { headerName: "Topic Tags", headerKey: "str_topic_tags"},
    { headerName: "Placement Tags", headerKey: "str_placement_tags"},
    { headerName: "Publish Date", headerKey: "publish_date"},
    { headerName: "End Date", headerKey: "end_date"},
    { headerName: "Pageviews Goal", headerKey: "pageview_goal"},
    { headerName: "Pageviews Delivered In Range", headerKey: "pageviews_delivered_in_date_range"},
    { headerName: "Pageviews Delivered Total", headerKey: "total_pageviews_delivered"},
    { headerName: "Percent Delivered", headerKey: "percent_delivered"},
    { headerName: "PERCENT ELAPSED TIME", headerKey: "percent_complete"},
    { headerName: "Pace", headerKey: "pace"},
];