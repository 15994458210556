
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { selectValue } from "../../../actions";
import { EXCLUDE_ANONYMOUS_MEMBERS_LABEL, EXCLUDE_INTERNAL_EVENTS } from "../../config/const";
import InnerToggles from "../../common/InnerToggles";

const EngagedCommonToggleFilter = (props) => {

    const toggleOptions = [
        { label: EXCLUDE_ANONYMOUS_MEMBERS_LABEL, key: 'exAnonymousMember' },
        { label: EXCLUDE_INTERNAL_EVENTS, key: 'exInternalEvents' },
    ];

    return (
        <InnerToggles
            {...props}
            toggleData={toggleOptions}
            applyClass="eng_toggle"
        />
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
    };
};

export default compose(connect(mapStateToProps, {
    selectValue
}))(EngagedCommonToggleFilter);