import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import MultipleSelectDropDown from "../base/multiSelectDropdown";
import { fetchRegulations, addOrganization, clearSearchOrgs } from "../../actions";

const RegulationManagerMultiSelect = (props) => {
    const onSearchHandler = (e, { searchQuery }) => {
        if (searchQuery.length > 1) {
            props.fetchRegulations(searchQuery, props.FETCH);
        }
    };

    const onChangeHandler = (e, { value }) => {
        if (props.selectValue) {
            props.selectValue({ dispatchType: props.action, key: 'regulationName', value });
        }
        props.addOrganization(value, props.ADD);
    };

    const onClickHandler = () => {
        props.clearSearchOrgs(props.CLEAR);
    };

    return (
        <MultipleSelectDropDown
            label="Regulation Name"
            placeholder="Regulation Name"
            options={props?.regulationData?.map((reg) => ({
                key: reg.uuid,
                text: reg.short_name,
                value: reg.uuid,
            }))}
            onSearchChange={onSearchHandler}
            value={props.leadFilterData.regulationName}
            onChange={onChangeHandler}
            onClick={onClickHandler}
        />
    );
};

export default compose(connect(null, {
    fetchRegulations,
    addOrganization,
    clearSearchOrgs,
}))(RegulationManagerMultiSelect);