import React from "react";
import { Table } from "semantic-ui-react";
import MultiTagLinks from "../../../hyperlinks/multiTagLinks";

const PrimaryTopicComponent = ({ data }) => {
    return (
        data.primary_topic !== "N/A" ? (
            <Table.Cell title={data.primary_topic} className="blogs_link">
                <MultiTagLinks
                    type="primary"
                    object={data.tags_data}
                    refValue={data.primary_topic}
                />
            </Table.Cell>
        ) : 
        <Table.Cell>{"N/A"}</Table.Cell>
    );
};

export default PrimaryTopicComponent;