import React from "react";
import SingleSelectDropDown from "../base/singleSelectDropDown";

const TrafficFilterPicker = (props) => {
	return (
		<SingleSelectDropDown
			className="no-blink"
			options={[
				{
					key: "0",
					text: "All",
					value: "all",
				},
                {
					key: "1",
					text: "Bot Only",
					value: "bot_only",
				},
                {
					key: "2",
					text: "Human Only",
					value: "human_only",
				},
				
			]}
			label="Traffic Filter"
			placeholder="Traffic Filter"
			{...props}
		/>
	);
};

export default TrafficFilterPicker;
