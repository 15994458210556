import { headers } from "../api/apiConstants";
import axios from "../api/axios";
import { url } from "../api/urlHelper";
import { findReportFromURL } from "../components/config/FindReportFromURL";
import { cleanPayload, getApiDate } from "../utilities/common";
import { getCookie } from "../utilities/envCommon";
import * as actionTypes from "./actionTypes";

export const fetchDecisionInsightData = (url, filterData, page, type = "", dispatchType, csvType = "", rType, setReportType, newEmail="") => async (dispatch) => {
	const reportData = findReportFromURL(url, "");
	const payload = {
		organization_uuid: filterData.organizationSingleSelect,
		campaigns: filterData.campaign,
		orders: filterData.order,
		site: filterData.site !== "all" ? filterData.site : "",
		start_date: getApiDate(filterData.startDate).concat(" 00:00:00"),
		end_date: getApiDate(filterData.endDate).concat(" 23:59:59"),
		export: type,
		type: rType,
		report_name: reportData ? reportData.report : "",
		report_table_name: reportData?.report_table_name,
		session_id: getCookie("session_id"),
		exclude_internal_events: filterData.isExcludeInternalEvents,
		organization_types: filterData?.selOrgType && filterData?.selOrgType.map( a => a.charAt(0).toUpperCase() + a.substr(1)),
		organization_sizes: filterData?.selOrgSize,
		sector:filterData?.selSector,
		industry: filterData?.selIndustry,
		sub_industry: filterData?.selSubIndustry,
		industry_group: filterData?.selIndustryGroup,
		company_names: filterData?.companyData,
		account_intrested_by_keywords: filterData?.accountInterestedByKeywords,
		parent_company_names: filterData?.ParentCompanyArray,
		account_activity_trend: filterData?.accountActivityTrend !== "all" ? filterData?.accountActivityTrend : "",
		account_activity_level: filterData?.accountActivityLevel !== "all" ? filterData?.accountActivityLevel : "",
		show_competitors: filterData?.showCompetitors,
		segments: filterData?.segmentsArray,
		exclude_anonymous_member: filterData?.excludeAnonymousUsers
	};
	await axios.post(url, 
		cleanPayload(payload),
		{
			headers: headers(),
		}
	)
	.then((res) => {
		if (res.status === 200) {
			if (type) {
				dispatch({
					type: csvType,
					payload: url,
					csv: res?.data,
					email: newEmail,
					typeOfReport: rType,
					reportType: setReportType,
				});
			} else {
				dispatch({
					type: dispatchType,
					payload: res?.data,
					filterData: filterData,
					reportType: setReportType,
				});
			}
		} else if (res.status === 202) {
			if (type) {
				dispatch({
					type: csvType,
					payload: url,
					csv: res?.data,
					email: newEmail,
					typeOfReport: rType,
					reportType: setReportType,
				});
			}
		} else if (res.status === 400) {
			dispatch({
				type: actionTypes.ERRORCODE,
				payload: res.data.status,
			});
		}
	})
	.catch((err) => {
		if (err.response) {
			dispatch({
				type: actionTypes.ERRORCODE,
				payload: err.response.status,
			});
		}
	});
};

export const diPopulateFilterAPI = (filterData) => async (dispatch) => {
    try {
		const payload = {
			organization_uuid: filterData.organizationSingleSelect,
			campaigns: filterData.campaign,
			orders: filterData.order,
			site: filterData.site !== "all" ? filterData.site : "",
			start_date: getApiDate(filterData.startDate).concat(" 00:00:00"),
			end_date: getApiDate(filterData.endDate).concat(" 23:59:59"),
			session_id: getCookie("session_id"),
			exclude_internal_events: filterData.isExcludeInternalEvents,
			organization_types: filterData?.selOrgType && filterData?.selOrgType.map( a => a.charAt(0).toUpperCase() + a.substr(1)),
			organization_sizes: filterData?.selOrgSize,
			sector:filterData?.selSector,
			industry: filterData?.selIndustry,
			sub_industry: filterData?.selSubIndustry,
			industry_group: filterData?.selIndustryGroup,
			company_names: filterData?.companyData,
			account_intrested_by_keywords: filterData?.accountInterestedByKeywords,
			parent_company_names: filterData?.ParentCompanyArray,
			account_activity_trend: filterData?.accountActivityTrend !== "all" ? filterData?.accountActivityTrend : "",
			account_activity_level: filterData?.accountActivityLevel !== "all" ? filterData?.accountActivityLevel : "",
			show_competitors: filterData?.showCompetitors,
			segments: filterData?.segmentsArray,
		};
        const res = await axios.post(url.diPopulateFilter, cleanPayload(payload), { headers: headers() });
        dispatch({ type: actionTypes.DI_POPULATE_FILTER_DATA, payload: res?.data});
    } catch (err) {
		dispatch({
			type: actionTypes.ERRORCODE,
			payload: err?.response?.status,
		});
    }
};