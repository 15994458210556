import _ from "lodash";
import React from "react";
import { Table, Loader, Message, Divider } from "semantic-ui-react";
import "../../../css/common.css";
import { connect } from "react-redux";
import {
	topicSetPageCount,
	fetchTopicDetail,
	showMoreTopicLoading,
	clearTopicSort,
} from "../../../actions";
import * as actionTypes from "../../../actions/actionTypes";
import TopicDetailTableBody from "./topicDetailTableBody";
//props.topicDetail

const TopicDetailTable = (props) => {
	let tableBody;
	let showMoreButton;
	let loading;
	let noResult;
  let conditionChkboxAppFilter = props.filterData.selCustomRangeCheckBox && props.isFilApplied && props.filterData.appliedDate ==="Custom Date Range";
	//const [pageCount, setPageCount] = useState(2);

	const showMore = async () => {
		props.clearTopicSort(actionTypes.CLEARTOPICDETAILSORT);
		props.showMoreTopicLoading(actionTypes.MORETOPICDETAILLOADING);
		await props.fetchTopicDetail(
			props.filterData,
			props.PageCount,
			//pageCount,
			"",
			actionTypes.MORETOPICDETAILDATA
		);
		props.topicSetPageCount(actionTypes.SETTOPDETAILTABLEPGCOUNT);
		//setPageCount(pageCount + 1);
	};

	if (props.loading) {
		loading = (
			<div className="show-loader">
				<Loader active inline="centered" />
			</div>
		);
	}

	if (!_.isEmpty(props.topicDetail)) {
		tableBody = (
			<TopicDetailTableBody data={props.topicDetail}></TopicDetailTableBody>
		);
    showMoreButton = (
      <div className="table-divider">
        <Divider>
          <div className="show-more-component">
            {!props.topicFetchData.resSidebarCampaignDetailsValue ? 
            (
            <button className="show-more-button" onClick={showMore}>
              Show More
            </button>
            ):(
            <button className="show-more-button-disable">
              Show More
            </button>
            )}
            
          </div>
        </Divider>
      </div>
    );
	} else if (
		props.isFilApplied &&
		!props.loading &&
		_.isEmpty(props.topicDetail)
	) {
		noResult = <Message>No results found.</Message>;
	}

	return (
    <div>
      <div className="sortable-table-component">
        <Table sortable fixed singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="toggle-row"></Table.HeaderCell>
              <Table.HeaderCell className="custom-column-large">
                Order
              </Table.HeaderCell>
              <Table.HeaderCell className="custom-column-large">
                Campaign/ Sidebar
              </Table.HeaderCell>
              <Table.HeaderCell>Start Date</Table.HeaderCell>
              <Table.HeaderCell>End Date</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Slot</Table.HeaderCell>
              <Table.HeaderCell>Widget Type</Table.HeaderCell>
              <Table.HeaderCell>Widget Title</Table.HeaderCell>
              {/* <Table.HeaderCell>Asset Type</Table.HeaderCell> */}
              {/* <Table.HeaderCell className="custom-column-large">
                Page Title
              </Table.HeaderCell> */}
              <Table.HeaderCell 
			          colSpan={conditionChkboxAppFilter && "2"}
				       className={conditionChkboxAppFilter && "custom-column-large" }
             //  textAlign={ !conditionChkboxAppFilter && "right"}
             textAlign="right"
			        >
                {conditionChkboxAppFilter ? (
                  <span style={{ paddingRight: "40px" }}>
                    Total Impressions
                  </span>
                ) : (
                  "Total Impressions"
                )}
                {conditionChkboxAppFilter && (
                    <Table.Row>
                      <Table.HeaderCell style={{ borderBottom: "none" }}>
                        Current
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ borderBottom: "none" }}>
                        Previous
                      </Table.HeaderCell>
                    </Table.Row>
                  )}
              </Table.HeaderCell>
              <Table.HeaderCell
			            colSpan={conditionChkboxAppFilter && "2"}
			           className={conditionChkboxAppFilter && "custom-column-large" }
                // textAlign={ !conditionChkboxAppFilter && "right"}
                textAlign="right"
                 >
                      {conditionChkboxAppFilter ? (
                      <span style={{ paddingRight: "30px" }}>
                        Unique Impressions
                      </span>
                    ) : (
                      " Unique Impressions"
                    )}
                {conditionChkboxAppFilter && (
                    <Table.Row>
                      <Table.HeaderCell style={{ borderBottom: "none" }}>
                        Current
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ borderBottom: "none" }}>
                        Previous
                      </Table.HeaderCell>
                    </Table.Row>
                  )}
              </Table.HeaderCell>
              {/* <Table.HeaderCell className="custom-column-large">
                Client Link Title
              </Table.HeaderCell> */}
              {/* <Table.HeaderCell className="custom-column-large">Customer Link</Table.HeaderCell> */}
              <Table.HeaderCell
			           colSpan={conditionChkboxAppFilter && "2"}
			          className={conditionChkboxAppFilter && "custom-column-large" }
               // textAlign={ !conditionChkboxAppFilter && "right"}
               textAlign="right"
               >
                {conditionChkboxAppFilter ? (
                        <span style={{ paddingRight: "90px" }}>
                          Clicks
                        </span>
                      ) : (
                        " Clicks"
                )}
                {conditionChkboxAppFilter && (
                    <Table.Row>
                      <Table.HeaderCell style={{ borderBottom: "none" }}>
                        Current
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ borderBottom: "none" }}>
                        Previous
                      </Table.HeaderCell>
                    </Table.Row>
                  )}
					 </Table.HeaderCell>
              <Table.HeaderCell
			          colSpan={conditionChkboxAppFilter && "2"}
			         className={conditionChkboxAppFilter && "custom-column-large" }
               //textAlign={ !conditionChkboxAppFilter && "right"}
               textAlign="right"
               >
				         {conditionChkboxAppFilter ? (
                  <span style={{ paddingRight: "60px" }}>
                     Unique Clicks
                  </span>
                ) : (
                  " Unique Clicks"
                )}
                {conditionChkboxAppFilter && (
                    <Table.Row>
                      <Table.HeaderCell style={{ borderBottom: "none" }}>
                        Current
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ borderBottom: "none" }}>
                        Previous
                      </Table.HeaderCell>
                    </Table.Row>
                  )}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">CTR</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {tableBody}
        </Table>
      </div>
      {noResult}
      {loading}
      {showMoreButton}
    </div>
  );
};

const mapStateToProps = (state) => {
	return {
		isFilApplied: state.topicFetchData.isFilApplied,
		filterData: state.topicFilterData,
		topicDetail: state.topicFetchData.topicDetail,
		loading: state.topicFetchData.isLoadingTopicDetail,
		column: state.topicFetchData.column,
		direction: state.topicFetchData.direction,
		PageCount: state.topicFetchData.detailPageCount,
    topicFetchData: state.topicFetchData
	};
};

export default connect(mapStateToProps, {
	topicSetPageCount,
	fetchTopicDetail,
	showMoreTopicLoading,
	clearTopicSort,
})(TopicDetailTable);
