import _ from "lodash";
import React from "react";
import { Table, Loader, Message, Divider } from "semantic-ui-react";
import "../../css/common.css";
import { connect } from "react-redux";
import {
	leadEngSetPageCount,
	fetchLeadEngSummary,
	sortLeadEngSummary,
	clearLeadSort,
	showMoreLeadEngSummaryLoading
} from "../../actions";
import {
	getDisplayDate,
	numFormat,
	getOrgsInCampaigns,
	getOrderInCampaigns,
	capitalizeFirstLetter,
	decimalFormat,
	calculateTotalCount
} from "../../utilities/common";
import Link from "../hyperlinks/link";
import MultiLinks from "../hyperlinks/multiLinks";
import * as actionTypes from "../../actions/actionTypes";
import { url } from "../../api/urlHelper";
import SortIcon from "@mui/icons-material/Sort";
import LeadEngagementCampaign from '../campaign/leadEngagementCampaign'
import HocDrawer from "../common/hocDrawer";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const LeadEngagementSummaryTable = (props) => {
	let tableBody;
	let showMoreButton;
	let loading;
	let noresult;

	//const [pageCount, setPageCount] = useState(2);

	const showMore = async () => {
		props.clearLeadSort();
		props.showMoreLeadEngSummaryLoading();
		await props.fetchLeadEngSummary(
			url.engagementSummary,
			props.filterData,
			props.PageCount,
			// pageCount,
			"",
			actionTypes.MORELEADENGSUMMARY
		);
		props.leadEngSetPageCount(actionTypes.SETLEADENGSUMMARYPGCOUNT);
		//setPageCount(pageCount + 1);
	};

	if (props.loading) {
		loading = (
			<div className="show-loader">
				<Loader active inline="centered" />
			</div>
		);
	}

	const createRow = (data) => {
		let tableTotalCell = [];
		// let CountData = props.leadEngSummaryCount;

		const totalEngPurchased = () => {
			return calculateTotalCount(data, 'engagement_purchased');
		};

		const totalNotStarted = () => {
			return calculateTotalCount(data, 'not_started');
		};

		const totalReceived = () => {
			return calculateTotalCount(data, 'received');
		};

		const totalInReview = () => {
			return calculateTotalCount(data, 'in_review');
		};

		const totalAcceptedNotDelivered = () => {
			return calculateTotalCount(data, 'accepted_not_delivered');
		};

		const totalEngDelInDateRange = () => {
			return calculateTotalCount(data, 'engagement_count');
		};

		const totalPreviouslyDelivered = () => {
			return calculateTotalCount(data, 'previously_delivered');
		};

		const totalEngDelivered = () => {
			return calculateTotalCount(data, 'total_engagement_delivered');
		};

		const totalEngOutstanding = () => {
			return calculateTotalCount(data, 'engagement_outstanding');
		};

		const totalRejected = () => {
			return calculateTotalCount(data, 'rejected');
		};

		const totalUniqueEngCount = () => {
			return calculateTotalCount(data, 'unique_engagement_count');
		};

		if (data.length > 0) {
			let tableRow = [];
			data.forEach((e) => {
				let tableCell = [];
				const LeadEngagementCampaignDrawer = HocDrawer(
					ArrowForwardIcon,
					LeadEngagementCampaign,
					"CAMPAIGN HIGHLIGHTS ",
					e
				  )
				let OrderData;
				let orders = [];
				let order = {};
				if (e.campaigns !== null) {
					OrderData = getOrderInCampaigns(e.campaigns);
					OrderData.map((order) => {
						orders.push(order.name);
						return orders;
					});
				}
				order.name = orders;
				tableCell.push(
					<Table.Cell title={e.agency_name}>{e.agency_name}</Table.Cell>
				);
				tableCell.push(
					<Table.Cell title={e.organization_name} className="blogs_link">
						{e.campaigns ? (
							<MultiLinks
								type="organizations"
								object={getOrgsInCampaigns(e.campaigns)}
							></MultiLinks>
						) : (
							e.organization_name
						)}
					</Table.Cell>
				);
				tableCell.push(
					<Table.Cell title={order.name} className="blogs_link">
						{OrderData ? (
							<MultiLinks type="orders" object={OrderData}></MultiLinks>
						) : (
							order.name
						)}
					</Table.Cell>
				);
				tableCell.push(
					<Table.Cell title={e.campaign_name} className="blogs_link">
						{e.campaign_name ? (
							<Link
								type="campaigns"
								urlParam={e.campaign_uuid}
								refValue={e.campaign_name}
							></Link>
						) : (
							""
						)}
					</Table.Cell>
				);
				tableCell.push(<Table.Cell title={e.client_success_manager_name}>{e.client_success_manager_name}</Table.Cell>);
				tableCell.push(<Table.Cell title={getDisplayDate(e.start_date)}>{getDisplayDate(e.start_date)}</Table.Cell>);
				tableCell.push(<Table.Cell title={getDisplayDate(e.end_date)}>{getDisplayDate(e.end_date)}</Table.Cell>);
				tableCell.push(<Table.Cell title={getDisplayDate(e.actual_start_date)}>{getDisplayDate(e.actual_start_date)}</Table.Cell>);
				tableCell.push(<Table.Cell title={getDisplayDate(e.projected_end_date)}>{getDisplayDate(e.projected_end_date)}</Table.Cell>);
				tableCell.push(<Table.Cell title={capitalizeFirstLetter(e.status)}>{capitalizeFirstLetter(e.status)}</Table.Cell>);
				//Lead Delivery State
				tableCell.push(<Table.Cell title={capitalizeFirstLetter(e.delivery_state)}>{capitalizeFirstLetter(e.delivery_state)}</Table.Cell>);
				tableCell.push(
					<Table.Cell textAlign="right" title={numFormat(e.engagement_purchased)}>{numFormat(e.engagement_purchased)}</Table.Cell>
				);
				tableCell.push(
					<Table.Cell textAlign="right" title={numFormat(e.not_started)}>{numFormat(e.not_started)}</Table.Cell>
				);
				tableCell.push(
					<Table.Cell textAlign="right" title={numFormat(e.received)}>{numFormat(e.received)}</Table.Cell>
				);
				tableCell.push(
					<Table.Cell textAlign="right" title={numFormat(e.in_review)}>{numFormat(e.in_review)}</Table.Cell>
				);
				tableCell.push(
					<Table.Cell textAlign="right" title={numFormat(e.accepted_not_delivered)}>{numFormat(e.accepted_not_delivered)}</Table.Cell>
				);
				tableCell.push(
					<Table.Cell textAlign="right" title={numFormat(e.engagement_count)}>{numFormat(e.engagement_count)}</Table.Cell>
				);
				tableCell.push(
					<Table.Cell textAlign="right" title={numFormat(e.previously_delivered)}>{numFormat(e.previously_delivered)}</Table.Cell>
				);
				tableCell.push(
					<Table.Cell textAlign="right" title={numFormat(e.total_engagement_delivered)}>{numFormat(e.total_engagement_delivered)}</Table.Cell>
				);
				tableCell.push(
					<Table.Cell textAlign="right" title={numFormat(e.engagement_outstanding)}>{numFormat(e.engagement_outstanding)}</Table.Cell>
				);
				tableCell.push(
					<Table.Cell textAlign="right" title={numFormat(e.rejected)}>{numFormat(e.rejected)}</Table.Cell>
				);
				tableCell.push(
					<Table.Cell textAlign="right" title={isNaN(e.percent_delivered) ? 0.0 + "%" : decimalFormat(e.percent_delivered) + "%"}>
						{isNaN(e.percent_delivered) ? 0.0 + "%" : decimalFormat(e.percent_delivered) + "%"}
					</Table.Cell>
				);
				tableCell.push(
					<Table.Cell textAlign="right" title={decimalFormat(e.percentage_complete)}>{decimalFormat(e.percentage_complete)}</Table.Cell>
				);
				tableCell.push(
					<>
					{
					  e.pace === "green" ?
						<Table.Cell textAlign="right" title={"GREEN"}> <button className="button_color_green"/> </Table.Cell>
					  :
					  e.pace === "yellow" ?
						<Table.Cell textAlign="right" title={"YELLOW"}> <button className="button_color_yellow"/> </Table.Cell>
					  :
						<Table.Cell textAlign="right" title={"RED"}> <button className="button_color_red"/> </Table.Cell>
					}
					</>
				  );
				tableCell.push(
					<Table.Cell>
						< LeadEngagementCampaignDrawer />		
					</Table.Cell>
				)
				tableRow.push(<Table.Row children={tableCell} />);
			});
			for (let i = 0; i <= 9; i++) {
				tableTotalCell.push(<Table.Cell textAlign="center">-</Table.Cell>);
			}
			tableTotalCell.push(<Table.Cell textAlign="left" title={"Total"}>Total:</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right" title={numFormat(totalEngPurchased())}>{numFormat(totalEngPurchased())}</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right" title={totalNotStarted() <= 0 ? 0 : numFormat(totalNotStarted())}>{totalNotStarted() <= 0 ? 0 : numFormat(totalNotStarted())}</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right" title={numFormat(totalReceived())}>{numFormat(totalReceived())}</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right" title={numFormat(totalInReview())}>{numFormat(totalInReview())}</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right" title={numFormat(totalAcceptedNotDelivered())}>{numFormat(totalAcceptedNotDelivered())}</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right" title={numFormat(totalEngDelInDateRange())}>{numFormat(totalEngDelInDateRange())}</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right" title={numFormat(totalPreviouslyDelivered())}>{numFormat(totalPreviouslyDelivered())}</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right" title={numFormat(totalEngDelivered())}>{numFormat(totalEngDelivered())}</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right" title={numFormat(totalEngOutstanding())}>{numFormat(totalEngOutstanding())}</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right" title={numFormat(totalRejected())}>{numFormat(totalRejected())}</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right" title={numFormat(totalUniqueEngCount())}>{numFormat(totalUniqueEngCount())}</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right" title={"-"}>-</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right" title={"-"}>-</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right" title={"-"}>-</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="center" title={"-"}>-</Table.Cell>);

			tableRow.push(<Table.Row children={tableTotalCell} />);

			return tableRow;
		}
	};

	if (!_.isEmpty(props.leadEngSummaryCount && props.leadEngSummary)) {
		tableBody = <Table.Body>{createRow(props.leadEngSummary)}</Table.Body>;
		//tableBody = <Table.Body>{createRow(dummyData)}</Table.Body>
		showMoreButton = (
			<div className="table-divider">
			  <Divider>
				<div className="show-more-component">
				  {!props.leadFetchData.resCampaignSummaryLeadEngsValue ? 
				  (
				  <button className="show-more-button" onClick={showMore}>
					Show More
				  </button>
				  ):(
				  <button className="show-more-button-disable">
					Show More
				  </button>
				  )}
				  
				</div>
			  </Divider>
			</div>
		  );
	} else if ( props.isFilApplied && !props.loading && _.isEmpty(props.leadEngSummary)) {
		noresult = <Message>No results found.</Message>;
	}

	return (
		<div>
			<div className="sortable-table-component">
				<Table sortable fixed singleLine>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell
								sorted={
								props.column === "agency_name" ? props.direction : null
								}
								onClick={() => props.sortLeadEngSummary("agency_name")}
							>
								Agency
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={
									props.column === "organization_name" ? props.direction : null
								}
								onClick={() => props.sortLeadEngSummary("organization_name")}
							>
								Account
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={props.column === "name" ? props.direction : null}
								onClick={() => props.sortLeadEngSummary("name")}
							>
								Order
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={
									props.column === "campaign_name" ? props.direction : null
								}
								onClick={() => props.sortLeadEngSummary("campaign_name")}
							>
								Campaign
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={
									props.column === "client_success_manager_name" ? props.direction : null
								}
								onClick={() => props.sortLeadEngSummary("client_success_manager_name")}
							>
								CSM
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={props.column === "start_date" ? props.direction : null}
								onClick={() => props.sortLeadEngSummary("start_date")}
							>
								Campaign Start Date
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={props.column === "end_date" ? props.direction : null}
								onClick={() => props.sortLeadEngSummary("end_date")}
							>
								Campaign End Date
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={props.column === "actual_start_date" ? props.direction : null}
								onClick={() => props.sortLeadEngSummary("actual_start_date")}
							>
								Actual Start Date
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={props.column === "projected_end_date" ? props.direction : null}
								onClick={() => props.sortLeadEngSummary("projected_end_date")}
							>
								Projected Start Date
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={props.column === "status" ? props.direction : null}
								onClick={() => props.sortLeadEngSummary("status")}
								className= "custom-column-medium"
							>
								Campaign Status
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={props.column === "delivery_state" ? props.direction : null}
								onClick={() => props.sortLeadEngSummary("delivery_state")}
							>
								Delivery Status
							</Table.HeaderCell>
							<Table.HeaderCell
								textAlign="right"
								sorted={
									props.column === "engagement_purchased"
										? props.direction
										: null
								}
								onClick={() => props.sortLeadEngSummary("engagement_purchased")}
							>
								Engagements Purchased
							</Table.HeaderCell>


							{/* New changes */}
							<Table.HeaderCell
								textAlign="right"
								sorted={ props.column === "not_started" ? props.direction : null }
								onClick={() => props.sortLeadEngSummary("not_started")}
							>
								Not Started
							</Table.HeaderCell>
							<Table.HeaderCell
								textAlign="right"
								sorted={ props.column === "received" ? props.direction : null }
								onClick={() => props.sortLeadEngSummary("received")}
							>
								Received
							</Table.HeaderCell>
							<Table.HeaderCell
								textAlign="right"
								sorted={ props.column === "in_review" ? props.direction : null }
								onClick={() => props.sortLeadEngSummary("in_review")}
							>
								In-Review
							</Table.HeaderCell>
							<Table.HeaderCell
								textAlign="right"
								sorted={ props.column === "accepted_not_delivered" ? props.direction : null }
								onClick={() => props.sortLeadEngSummary("accepted_not_delivered")}
							>
								Accepted Pending Delivery
							</Table.HeaderCell>
							<Table.HeaderCell
								textAlign="right"
								className="counts"
								sorted={
									props.column === "engagement_count"
										? props.direction
										: null
								}
								onClick={() =>
									props.sortLeadEngSummary(
										"engagement_count"
									)
								}
							>
								Engagements Delivered In Date Range
							</Table.HeaderCell>
							<Table.HeaderCell
								textAlign="right"
								sorted={ props.column === "previously_delivered" ? props.direction : null }
								onClick={() => props.sortLeadEngSummary("previously_delivered")}
							>
								Previously Delivered
							</Table.HeaderCell>
							<Table.HeaderCell
								textAlign="right"
								sorted={
									props.column === "engagement_count" ? props.direction : null
								}
								onClick={() => props.sortLeadEngSummary("engagement_count")}
							>
								Total Engagements Delivered
							</Table.HeaderCell>
							<Table.HeaderCell
								textAlign="right"
								sorted={
									props.column === "engagement_outstanding"
										? props.direction
										: null
								}
								onClick={() =>
									props.sortLeadEngSummary("engagement_outstanding")
								}
							>
								Engagements Outstanding
							</Table.HeaderCell>
							<Table.HeaderCell
								textAlign="right"
								sorted={ props.column === "rejected" ? props.direction : null }
								onClick={() => props.sortLeadEngSummary("rejected")}
							>
								Rejected
							</Table.HeaderCell>
							{/* ***************** */}
							
							<Table.HeaderCell
								textAlign="right"
								sorted={
									props.column === "percent_delivered" ? props.direction : null
								}
								onClick={() => props.sortLeadEngSummary("percent_delivered")}
							>
								Percent Delivered
							</Table.HeaderCell>
							<Table.HeaderCell
								textAlign="right"
								sorted={
									props.column === "percentage_complete" ? props.direction : null
								}
								onClick={() => props.sortLeadEngSummary("percentage_complete")}
							>
								Percent Complete
							</Table.HeaderCell>
							<Table.HeaderCell
								textAlign="right"
								sorted={
									props.column === "pace" ? props.direction : null
								}
								onClick={() => props.sortLeadEngSummary("pace")}
							>
								Pace
							</Table.HeaderCell>
							<Table.HeaderCell
								textAlign="left"	
								className="icon"
							>
								<SortIcon />
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					{tableBody}
				</Table>
			</div>
			{noresult}
			{loading}
			{showMoreButton}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		wpLink: state.links.wpLink,
		isFilApplied: state.leadFetchData.isFilApplied,
		assetType: Object.values(state.assetTypes),
		filterData: state.leadFilterData,
		leadEngSummaryCount: state.leadFetchData.leadEngSummaryCount,
		leadEngSummary: state.leadFetchData.leadEngSummary,
		loading: state.leadFetchData.isLoadingLeadEngSummary,
		column: state.leadFetchData.leadEngSummarycolumn,
		direction: state.leadFetchData.leadEngSummarydirection,
		PageCount: state.leadFetchData.summaryPageCount,
		leadFetchData: state.leadFetchData
	};
};

export default connect(mapStateToProps, {
	fetchLeadEngSummary,
	sortLeadEngSummary,
	clearLeadSort,
	showMoreLeadEngSummaryLoading,
	leadEngSetPageCount,
})(LeadEngagementSummaryTable);
