export const PAGEVIEW_TYPE = "Pageview Type";
export const ALL = "All";
export const ALL_VALUE = "all";
export const NON_SPONSORED = "Non Sponsored";
export const NON_SPONSORED_VALUE = "non-sponsored";
export const DEFINITION = "Definition";
export const DEFINITION_VALUE = "definition";
export const ORGANIZATIONS = "Organizations";
export const ORGANIZATIONS_VALUE = "organizations";
export const PROJECTS = "Projects";
export const PROJECTS_VALUE = "projects";
export const EDITORIAL = "Editorial";
export const EDITORIAL_VALUE = "editorial";
export const EVENTS = "Events";
export const EVENTS_VALUE = "events";
export const GLOSSARY = "Glossary";
export const GLOSSARY_VALUE = "glossary";
export const LEGAL = "Legal";
export const LEGAL_VALUE = "legal";
export const PRESS_RELEASE = "Press Release";
export const PRESS_RELEASE_VALUE = "press release";
export const SPONSORED = "Sponsored";
export const SPONSORED_VALUE = "sponsored";
export const SPONSORED_SYNDICATED = "Sponsored Syndicated";
export const SPONSORED_SYNDICATED_VALUE = "sponsored-syndicated";
export const SYNDICATED = "Syndicated";
export const SYNDICATED_VALUE = "syndicated";
export const SECURITY_ADVISORIES = "Security Advisories";
export const SECURITY_ADVISORIES_VALUE = "security-advisories";
export const TOPIC = "Topic";
export const TOPIC_VALUE = "topic";
export const OTHER = "Other";
export const OTHER_VALUE = "other";