import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { fetchPrimaryTopic, addSelectedValue, clearSearchForAllReport, selectValue } from "../../../actions";
import * as actionTypes from "../../../actions/actionTypes";
import _ from "lodash";
import PrimaryTopic from "../primaryTopic";

const PrimaryTopicSelection = (props) => {

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    const onSearchHandler = (e) => {
        if (e.target.value.length > 1) {
            props.fetchPrimaryTopic(
                e.target.value, 
                actionTypes[props.reportActionType].FETCH_PRIMARY_TOPICS,
                props.reportActionType
            );
        }
    };

    const onChangeHandler = (e, { value }) => {
        dispatchDynamicSelectAction(props, "selPrimaryTopic", value);
        props.addSelectedValue({ dispatchType: actionTypes[props.reportActionType].ADD_PRIMARY_TOPICS, reportType: props.reportActionType, value });
    };

    const onClickHandler = () => {
        if (_.isEmpty(props.filterData.selPrimaryTopic)) {
            props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].CLEAR_PRIMARY_TOPICS, reportType: props.reportActionType });
        }
    };

    return (
        <PrimaryTopic
            value={props.filterData.selPrimaryTopic}
            primaryTopic={props.commonSelectData.primaryTopics}
            onClick={onClickHandler}
            onChange={onChangeHandler}
            onSearchChange={onSearchHandler}
            label="Primary Topic"
            placeholder="Primary Topic"
        />
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        commonSelectData: state.allReportCommonReducer[ownProps.reportActionType],
    };
};

export default compose(connect(mapStateToProps, {
    selectValue,
    fetchPrimaryTopic,
    addSelectedValue,
    clearSearchForAllReport,
}))(PrimaryTopicSelection);