export const AdUnitMultipleOptions = [
    { id: 0, key: "sidebar", name: "Sidebar", value: "sidebar" },
    { id: 1, key: 'content', name: 'Content', value: 'content' },
    { id: 2, key: 'display', name: 'Display', value: 'display' },
    { id: 3, key: 'bookend', name: 'Bookend', value: 'bookend' },
    { id: 4, key: 'iab', name: 'Iab', value: 'iab' },
    { id: 5, key: 'popup', name: 'Popup', value: 'popup' },
    { id: 6, key: 'newsletter', name: 'Newsletter', value: 'newsletter' },
    { id: 7, key: 'newsletter-body', name: 'Newsletter Body', value: 'newsletter-body' },
    { id: 8, key: 'instant-message', name: 'Instant Message', value: 'instant-message' },
    { id: 9, key: 'announcement', name: 'Announcement', value: 'announcement' },
];