import React from "react";
import { getCommaSeparatedVal, getName } from "../../utilities/common";
import {  List } from "semantic-ui-react";

const Firmographic = (props) => {
  let firmographicData = props.object;
  const rowLenAcc = firmographicData.accounts.length;
  const rowLenAccEx = firmographicData.excluded_accounts.length;
  const rowLenVer = firmographicData.vertical_profiles.length;
  const rowLenVerEx = firmographicData.excluded_vertical_profiles.length;
  const rowLenOrgSize = firmographicData.organization_sizes.length;
  const rowLenOrgType = firmographicData.organization_types.length;
  const rowLenGeoProfEx = firmographicData.excluded_geo_profiles.length;
  const rowLenGeoProf = firmographicData.geo_profiles.length;
  const rowLenOrgRev = firmographicData.estimated_revenues.length;
  const rowLenDom = firmographicData.domain_filters.length;
  return (
    <div className="main-div">
      <List>
        {firmographicData.firmographic_type === "" ? (
          <List.Item style={{paddingLeft:"10px"}} className="drawer-text">
             -
          </List.Item>
        ) : (
          ""
        )}
      </List>
      <List>
        {firmographicData.firmographic_type === "account" ? (
          <List.Item className="drawer-value">
            <label className="filter-text"> ACCOUNTS </label>
            <List.Item className="drawer-text">
              {rowLenAcc > 0
                ? getName(firmographicData.accounts, "name")
                : " - "}
            </List.Item>
          </List.Item>
        ) : (
          ""
        )}
      </List>
      <List>
        {firmographicData.firmographic_type === "account" ? (
          <List.Item className="drawer-value">
            <label className="filter-text"> DOMAIN FILTERS </label>
            <List.Item className="drawer-text">
              {rowLenDom > 0
                ? getCommaSeparatedVal(firmographicData.domain_filters)
                : " - "}
            </List.Item>
          </List.Item>
        ) : (
          ""
        )}
      </List>
      <List>
        {firmographicData.firmographic_type === "vertical" ? (
          <List.Item className="drawer-value">
            <label className="filter-text"> INCLUDED VERTICALS </label>
            <List.Item className="drawer-text">
              {rowLenVer > 0
                ? getName(firmographicData.vertical_profiles, "name")
                : " - "}
            </List.Item>
          </List.Item>
        ) : (
          ""
        )}
      </List>
      <List>
        {firmographicData.firmographic_type === "vertical" ? (
          <List.Item className="drawer-value">
            <label className="filter-text">EXCLUDED VERTICALS</label>
            <List.Item className="drawer-text">
              {rowLenVerEx > 0
                ? getName(firmographicData.excluded_vertical_profiles, "name")
                : " - "}
            </List.Item>
          </List.Item>
        ) : (
          ""
        )}
      </List>   
      <List>
        {firmographicData.firmographic_type === "vertical" ? (
          <List.Item className="drawer-value">
            <label className="filter-text"> EXCLUDED ACCOUNTS </label>
            <List.Item className="drawer-text">
              {rowLenAccEx > 0
                ? getName(firmographicData.excluded_accounts, "name")
                : " - "}
            </List.Item>
          </List.Item>
        ) : (
          ""
        )}
      </List>
      
      <List>
        {firmographicData.firmographic_type === "vertical" ? (
          <List.Item className="drawer-value">
            <label className="filter-text">INCLUDED GEOS</label>
            <List.Item className="drawer-text">
              {rowLenGeoProf > 0
                ? getName(firmographicData.geo_profiles, "name")
                : " - "}
            </List.Item>
          </List.Item>
        ) : (
          ""
        )}
      </List>
      <List>
        {firmographicData.firmographic_type === "vertical" ? (
          <List.Item className="drawer-value">
            <label className="filter-text"> EXCLUDED GEOS </label>
            <List.Item className="drawer-text">
              {rowLenGeoProfEx > 0
                ? getName(firmographicData.excluded_geo_profiles, "name")
                : " - "}
            </List.Item>
          </List.Item>
        ) : (
          ""
        )}
      </List> 
      <List>
        {firmographicData.firmographic_type === "vertical" ? (
          <List.Item className="drawer-value">
            <label className="filter-text">ORGANIZATION TYPES</label>
            <List.Item className="drawer-text">
              {rowLenOrgType > 0
                ? getCommaSeparatedVal(firmographicData.organization_types)
                : " - "}
            </List.Item>
          </List.Item>
        ) : (
          ""
        )}
      </List>
      <List>
        {firmographicData.firmographic_type === "vertical" ? (
          <List.Item className="drawer-value">
            <label className="filter-text">ORGANIZATION SIZES</label>
            <List.Item className="drawer-text">
              {rowLenOrgSize > 0
                ? getCommaSeparatedVal(firmographicData.organization_sizes)
                : " - "}
            </List.Item>
          </List.Item>
        ) : (
          ""
        )}
      </List>

      <List>
          {firmographicData.firmographic_type === "vertical" ? (
            <List.Item className="drawer-value">
              <label className="filter-text">ORGANIZATION REVENUES</label>
              <List.Item className="drawer-text">
                {rowLenOrgRev > 0
                  ? getCommaSeparatedVal(firmographicData.estimated_revenues)
                  : " - "}
              </List.Item>
            </List.Item>
          ) : (
            ""
          )}
        </List>
    </div>
  );
};

export default Firmographic;
