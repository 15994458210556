import React from "react";
import { Table } from "semantic-ui-react";

const PageTitleComponent = ({ data }) => {
  const { page_title, page_url, domain, page_path } = data;
  const url = page_url || `https://${domain}${page_path}`;
  
  return (
    <Table.Cell title={page_title} className="blogs_link">
      {page_title === "N/A" ? (
        page_title
      ) : (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {page_title}
        </a>
      )}
    </Table.Cell>
  );
};

export default PageTitleComponent;