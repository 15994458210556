import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import CountryMultiSelectPicker from "../../common/countryPicker";
import { fetchCountry, addSelectedValue, clearSearchForAllReport, } from "../../../actions";
import * as actionTypes from "../../../actions/actionTypes";
import _ from "lodash";

const CountrySelection = (props) => {

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    const searchCountry = (e) => {
        if (e.target.value.length > 1) {
            props.fetchCountry(
                e.target.value, 
                actionTypes[props.reportActionType].FETCH_COUNTRIES,
                props.reportActionType
            );
        }
    };

    const onSelectCountry = (e, { value }) => {
        dispatchDynamicSelectAction(props, "selCountry", value);
        props.addSelectedValue({ dispatchType: actionTypes[props.reportActionType].ADD_COUNTRIES, reportType: props.reportActionType, value });
    };

    const onClickCountry = () => {
        if (_.isEmpty(props.filterData.selCountry)) {
            props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].CLEAR_COUNTRIES, reportType: props.reportActionType });
        }
    };

    return (
        <CountryMultiSelectPicker
            value={props.filterData.selCountry}
            onSearchChange={searchCountry}
            onChange={onSelectCountry}
            onClick={onClickCountry}
            label="Country"
            countries={props.commonSelectData.country}
        />
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        commonSelectData: state.allReportCommonReducer[ownProps.reportActionType],
    };
};

export default compose(connect(mapStateToProps, {
    fetchCountry,
    addSelectedValue,
    clearSearchForAllReport,
}))(CountrySelection);