import _ from "lodash";
import React, { useEffect, useRef } from "react";
import { Form } from "semantic-ui-react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import DeliveryPicker from "../common/deliveryState";
import PacingFilter from "../common/pacingFilter";
import {
	fetchOrganizations,
	fetchOrder,
	fetchTopicData,
	addOrganization,
	addCampaigns,
	addOrder,
	fetchCountry,
	fetchCampaigns,
	resetOrgsOrCampaigns,
	resetOrder,
	clearSearchOrgs,
	clearSearchOrder,
	clearSearchCampaigns,
	clearTopicSearchedValue,
	selTopicStartDate,
	selTopicFilterValue,
	selTopicEndDate,
	applyTopicDate,
	selTopicOrgs,
	selTopicEngOrgs,
	selTopicOrder,
	selTopicCampaigns,
	selTopicStatus,
	selTopicSite,
	applyTopicFilter,
	clearTopicFilter,
	fetchTopicDetail,
	fetchTopicSummary,
	selTopicToggles,
	prevTopicSelEndDate,
	prevTopicSelStartDate,
	prevTopicAppliedDate,
	selTopicCustomRangeChkBox,
	firmographicSidebarTypeCall,
	addCountries,
	fetchEngagedMember,
	SenioritiesList,
	fetchappliedFilter,
	fetchEngagedOrg,
} from "../../actions";
import { connect } from "react-redux";
import "../../css/common.css";
import OrganizationPicker from "../common/organization";
import CampaignPicker from "../common/campaign";
import SitePicker from "../common/site";
import StatusPicker from "../common/status";
import OrderPicker from "../common/order";
import EngagedOrgPicker from "../common/engagedOrganization";
import {
	getDisplayDate,
	getId,
	getStartEndDates,
	getYesterdaysDate,
	isUUID,
} from "../../utilities/common";
import * as actionTypes from "../../actions/actionTypes";
import Toggle from "../base/toggle";
import { compose } from "redux";
import { url } from "../../api/urlHelper";
import {differenceInDays, subDays, subYears } from "date-fns";
import FirmographicFilter from "../common/firmographicFilter";
import PageFirmoGraphic from "../config/FilterFirmogaphicData";
import EngagedMember from "../common/engagedMember";
import CountryMultiSelectPicker from "../common/countryPicker";
import PersonaFilter from "../common/personaFilter";
import TrafficFilterPicker from "../common/trafficeFilter";
import CustomDatePickerWithCompare from "../common/CustomDatePickerWithCompare";
import SearchFilterIcon from "../common/SearchFilterIcon";
import SelectDatePicker from "../common/SelectDatePicker";
import CompetitorFilterToggleForOthers from "../common/CompetitorFilterToggleForOthers";
import CSMSelection from "../common/CSMSelection";

const SearchFilter = (props) => {
	const [datePopup, setDatePopup] = useState("custom-date-popup-close");
	const [filterPopup, setFilterPopup] = useState("filter-popup-close");
	const [filterSelectFocus, setFilterSelectFocus] = useState("filter-icon");
	const [filterIconColor, setFilterIconColor] = useState("material-icons grey");
	const [checkSelectState, setCheckSelectState] = useState(false);
	const [comparePeriod, setComparePeriod] = useState("Previous Period");
	const tempSelection = useRef({});
	const clearfilterClick = useRef(false);
	const [senioritiesIN, setSenioritiesIn] = useState([]);
	const [senioritiesEX, setSenioritiesEX] = useState([]);
	const [JobFunctionsIN, setJobFunctionsIN] = useState([]);
	const [JobFunctionsEX, setJobFunctionsEX] = useState([]);
	const [orgType, setOrgType] = useState([]);
	const [orgSize, setOrgSize] = useState([]);
	const [orgRevenue, setOrgRevenue] = useState([]);
	const [sector, setSector] = useState([]);
	const [industryGroup, setIndustryGroup] = useState([]);
	const [industry, setIndustry] = useState([]);
	const [subIndustry, setSubIndustry] = useState([]);
	const [dateRange, setDateRange] = useState({
		selection: {
			startDate: getYesterdaysDate(),
			endDate: getYesterdaysDate(),
			key: "selection",
		},
	});

	const location = useLocation();

	const firmogaphicFilterData = PageFirmoGraphic(
		props.topicFilterData, 
		props.firmographicSector, 
		props.firmographicIndustryGroup, 
		props.firmographicIndustry, 
		props.firmographicSubIndustry, 
		props.firmographicOrgsType, 
		props.firmographicOrgSize, 
		props.firmographicOrgsRevenue
	);

	const onSearchChangeFirmographic = (e, answerKey, value) =>{
		if(answerKey === "org_type"){
			setOrgType([]);
			if (e.target.value?.length > 0) {
				setOrgType(
					props.orgTypes
						.filter((orgType) => {
							return orgType.value
								.toLowerCase()
								.includes(e.target.value.toLowerCase());
						})
						.map((orgType) => {
							orgType.disabled = false;
							return orgType;
						})
				);
			props.firmographicSidebarTypeCall(
					orgType,
					actionTypes.FETCHSIDEBARFIRMOGRAPHICORGSTYPE,
					""
				);
			}
		} else if(answerKey === "org_size"){
			setOrgSize([]);
			if (e.target.value?.length > 1) {
				setOrgSize(
					props.orgSize
						.filter((orgSize) => {
							return orgSize.value
								.toLowerCase()
								.includes(e.target.value.toLowerCase());
						})
						.map((orgSize) => {
							orgSize.disabled = false;
							return orgSize;
						})
				);
			props.firmographicSidebarTypeCall(
					orgSize,
					actionTypes.FETCHSIDEBARORGSSIZE,
					""
				);
			}
		} else if(answerKey === "org_revenue_range"){
			setOrgRevenue([]);
			if (e.target.value?.length > 1) {
				setOrgRevenue(
					props.orgRevenue
						.filter((orgRevenue) => {
							return orgRevenue.value
								.toLowerCase()
								.includes(e.target.value.toLowerCase());
						})
						.map((orgRevenue) => {
							orgRevenue.disabled = false;
							return orgRevenue;
						})
				);
			props.firmographicSidebarTypeCall(
					orgRevenue,
					actionTypes.FETCHSIDEBARORGSREVENUE,
					""
				);
			}
		} else if(answerKey === "sector"){
			setSector([]);
			if (e.target.value?.length > 1) {
				setSector(
					props.sector
						.filter((sector) => {
							return sector;
						})
						.map((sector) => {
							sector.disabled = false;
							return sector;
						})
				);
			props.firmographicSidebarTypeCall(
					sector,
					actionTypes.FETCHSIDEBARSECTOR,
					""
				);
			}
		} else if(answerKey === "industry_group"){
			setIndustryGroup([]);
			if (e.target.value?.length > 1) {
				setIndustryGroup(
					props.industryGroup
						.filter((industryGroup) => {
							return industryGroup;
						})
						.map((industryGroup) => {
							industryGroup.disabled = false;
							return industryGroup;
						})
				);
			props.firmographicSidebarTypeCall(
					industryGroup,
					actionTypes.FETCHSIDEBARINDUSTRYGROUP,
					""
				);
			}
		} else if(answerKey === "industry"){
			setIndustry([]);
			if (e.target.value?.length > 1) {
				setIndustry(
					props.industry
						.filter((industry) => {
							return industry;
						})
						.map((industry) => {
							industry.disabled = false;
							return industry;
						})
				);
			props.firmographicSidebarTypeCall(
					industry,
					actionTypes.FETCHSIDEBARINDUSTRY,
					""
				);
			}
		} else if(answerKey === "sub_industry"){
			setSubIndustry([]);
			if (e.target.value?.length > 1) {
				setSubIndustry(
					props.subIndustry
						.filter((subIndustry) => {
							return subIndustry;
						})
						.map((subIndustry) => {
							subIndustry.disabled = false;
							return subIndustry;
						})
				);
			props.firmographicSidebarTypeCall(
					subIndustry,
					actionTypes.FETCHSIDEBARSUBINDUSTRY,
					""
				);
			}
		}
	}

	const onSelectFirmographic = (e, answerKey, value) => {
		if(answerKey === "org_type"){
			props.selTopicFilterValue(value, actionTypes.APPLYSIDEBARORGSTYPE);
			props.selTopicFilterValue(value, actionTypes.SELTOPICORGTYPE);
		} else if(answerKey === "org_size"){
			props.selTopicFilterValue(value, actionTypes.APPLYSIDEBARORGSSIZE);
			props.selTopicFilterValue(value, actionTypes.SELTOPICORGSIZE);
		} else if(answerKey === "org_revenue_range"){
			props.selTopicFilterValue(value, actionTypes.APPLYSIDEBARREVENUE);
			props.selTopicFilterValue(value, actionTypes.SELTOPICORGSREVENUE);
		} else if(answerKey === "sector"){
			props.selTopicFilterValue(value, actionTypes.APPLYSIDEBARSECTOR);
			props.selTopicFilterValue(value, actionTypes.SELTOPICSECTOR);
		} else if(answerKey === "industry_group"){
			props.selTopicFilterValue(value, actionTypes.APPLYSIDEBARINDUSTRYGROUP);
			props.selTopicFilterValue(value, actionTypes.SELTOPICINDUSTRYGROUP);
		} else if(answerKey === "industry"){
			props.selTopicFilterValue(value, actionTypes.APPLYSIDEBARINDUSTRY);
			props.selTopicFilterValue(value, actionTypes.SELTOPICINDUSTRY);
		} else if(answerKey === "sub_industry"){
			props.selTopicFilterValue(value, actionTypes.APPLYSIDEBARSUBINDUSTRY);
			props.selTopicFilterValue(value, actionTypes.SELTOPICSUBINDUSTRY);
		}
	};

	const onClickFirmographic = (e, answerKey) => {
		if(answerKey === "org_type"){
			props.clearTopicSearchedValue(actionTypes.CLEARSEARCHSIDEBARORGSTYPE);
		} else if(answerKey === "org_size"){
			props.clearTopicSearchedValue(actionTypes.CLEARSEARCHSIDEBARORGSSIZE);
		} else if(answerKey === "org_revenue_range"){
			props.clearTopicSearchedValue(actionTypes.CLEARSEARCHSIDEBARORGSREVENUE);
		} else if(answerKey === "sector"){
			props.clearTopicSearchedValue(actionTypes.CLEARSEARCHSIDEBARSECTOR);
		} else if(answerKey === "industry_group"){
			props.clearTopicSearchedValue(actionTypes.CLEARSEARCHSIDEBARINDUSTRYGROUP);
		} else if(answerKey === "industry"){
			props.clearTopicSearchedValue(actionTypes.CLEARSEARCHSIDEBARINDUSTRY);
		} else if(answerKey === "sub_industry"){
			props.clearTopicSearchedValue(actionTypes.CLEARSEARCHSIDEBARSUBINDUSTRY);
		}
	};

		//Seniorities Include

	const onClickSenioritiesIn = () => {
			props.clearTopicSearchedValue(actionTypes.CLEARSEARCHSIDEBARSENIORITIESIN);
	};
	
	const searchSenioritiesIn = (e) => {
		setSenioritiesIn([]);
			if (e.target.value.length > 1) {
				setSenioritiesIn(
					props.seniorities
						.filter((seniority) => {
							return seniority.name
								.toLowerCase()
								.includes(e.target.value.toLowerCase());
						})
						.map((seniority) => {
							seniority.disabled = false;
							return seniority;
						})
				);
				props.SenioritiesList(
					senioritiesIN,
					actionTypes.FETCHSIDEBARSENIORITIESIN,
					props.topicFilterData.selSenioritiesEX
				);
			}
		};
	
	const onSelectSenioritiesIn = (e, { value }) => {
		const selectedValue = value[value.length-1];
		if(props.topicFilterData.selSenioritiesEX.indexOf(selectedValue) === -1){
			props.selTopicFilterValue(value, actionTypes.APPLYSIDEBARSENIORITIESIN);
			props.selTopicFilterValue(value, actionTypes.SELSIDEBARSENIORITIESIN);
		}
	};
	
		//Seniority Ex
	
	const onClickSenioritiesEx = () => {
		props.clearTopicSearchedValue(actionTypes.CLEARSEARCHSIDEBARSENIORITIESEX);
	};
	
	const searchSenioritiesEx = (e) => {
		setSenioritiesEX([]);
			if (e.target.value.length > 1) {
				setSenioritiesEX(
					props.seniorities
						.filter((seniority) => {
							return seniority.name
								.toLowerCase()
								.includes(e.target.value.toLowerCase());
						})
						.map((seniority) => {
							seniority.disabled = false;
							return seniority;
						})
				);
				props.SenioritiesList(
					senioritiesEX,
					actionTypes.FETCHSIDEBARSENIORITIESEX,
					props.topicFilterData.selSenioritiesIN
				);
			}
	};
	
	const onSelectSenioritiesEx = (e, { value }) => {
		const selectedValue = value[value.length-1];
		if(props.topicFilterData.selSenioritiesIN.indexOf(selectedValue) === -1){
			props.selTopicFilterValue(value, actionTypes.APPLYSIDEBARSENIORITIESEX);
			props.selTopicFilterValue(value, actionTypes.SELSIDEBARSENIORITIESEX);
		}
	};
		
	let personaSeniority = [
		{
			value: props.topicFilterData.selSenioritiesIN,
			label: "Seniority (Include)",
			placeholder: "Seniority (Include)",
			options: props.senioritiesIn,
			onClick: onClickSenioritiesIn,
			onChange: onSelectSenioritiesIn,
			onSearchChange: searchSenioritiesIn
		},
		{
			value: props.topicFilterData.selSenioritiesEX,
			label: "Seniority (Exclude)",
			placeholder: "Seniority (Exclude)",
			options: props.senioritiesEx,
			onClick: onClickSenioritiesEx,
			onChange: onSelectSenioritiesEx,
			onSearchChange: searchSenioritiesEx,
		},	
	];

		//JobFunctions Include

		const onClickJobFunctionsIn = () => {
			props.clearTopicSearchedValue(actionTypes.CLEARSEARCHSIDEBARJOBFUNCTIONSIN);
		};
	
		const searchJobFunctionsIn = (e) => {
			setJobFunctionsIN([]);
			if (e.target.value.length > 1) {
				setJobFunctionsIN(
					props.jobfunctions
						.filter((jobfunction) => {
							return jobfunction.function
								.toLowerCase()
								.includes(e.target.value.toLowerCase());
						})
						.map((jobfunction) => {
							jobfunction.disabled = false;
							return jobfunction;
						})
				);
				props.SenioritiesList(
					JobFunctionsIN,
					actionTypes.FETCHSIDEBARJOBFUNCTIONSIN,
					props.topicFilterData.selJobFunctionsEX
				);
			}
		};
	
		const onSelectJobFunctionIn = (e, { value }) => {
			const selectedValue = value[value.length-1];
			if(props.topicFilterData.selJobFunctionsEX.indexOf(selectedValue) === -1){
				props.selTopicFilterValue(value, actionTypes.APPLYSIDEBARJOBFUNCTIONSIN);
				props.selTopicFilterValue(value, actionTypes.SELSIDEBARJOBFUNCTIONSIN);
			}
		};
	
		//JobFunctions Ex
	
		const onClickJobFunctionsEx = () => {
			props.clearTopicSearchedValue(actionTypes.CLEARSEARCHSIDEBARJOBFUNCTIONSEX);
		};
	
		const searchJobFunctionsEx = (e) => {
			setJobFunctionsEX([]);
			if (e.target.value.length > 1) {
				setJobFunctionsEX(
					props.jobfunctions
						.filter((jobfunction) => {
							return jobfunction.function
								.toLowerCase()
								.includes(e.target.value.toLowerCase());
						})
						.map((jobfunction) => {
							jobfunction.disabled = false;
							return jobfunction;
						})
				);
				props.SenioritiesList(
					JobFunctionsEX,
					actionTypes.FETCHSIDEBARJOBFUNCTIONSEX,
					props.topicFilterData.selJobFunctionsIN
				);
			}
		};
	
		const onSelectJobFunctionsEx = (e, { value }) => {
			const selectedValue = value[value.length-1];
			if(props.topicFilterData.selJobFunctionsIN.indexOf(selectedValue) === -1){
				props.selTopicFilterValue(value, actionTypes.APPLYSIDEBARJOBFUNCTIONSEX);
				props.selTopicFilterValue(value, actionTypes.SELSIDEBARJOBFUNCTIONSEX);
			}
		};
	
		let personaJobFunction = [
			{
				value: props.topicFilterData.selJobFunctionsIN,
				label: "Job Function (Include)",
				placeholder: "Job Function (Include)",
				options: props.JobFunctionsIN,
				tags: props.JobFunctionsIN,
				onClick: onClickJobFunctionsIn,
				onChange: onSelectJobFunctionIn,
				onSearchChange: searchJobFunctionsIn,
			},
			{
				tags: props.JobFunctionsEX, 
				value: props.topicFilterData.selJobFunctionsEX,
				label: "Job Function (Exclude)",
				placeholder: "Job Function (Exclude)",
				options: props.JobFunctionsEX,
				onClick: onClickJobFunctionsEx,
				onChange: onSelectJobFunctionsEx,
				onSearchChange: searchJobFunctionsEx,
			},	
		];

		const onTypeJobTitleIN = (e, { value }) =>{
			props.selTopicFilterValue(value, actionTypes.SELSIDEBARJOBTITLESIN);
		}
		const onTypeJobTitleEX = (e, { value }) =>{
			props.selTopicFilterValue(value, actionTypes.SELSIDEBARJOBTITLESEX);
		}
	
		let personaJobTitleIn = [
			{
				onChange: onTypeJobTitleIN,
				placeholder: "Job Title (Include)",
				value: props.topicFilterData.selJobTitlesIN,
			},
		];
	
		let personaJobTitleEx = [
			{
				onChange: onTypeJobTitleEX,
				placeholder: "Job Title (Exclude)",
				value: props.topicFilterData.selJobTitlesEX,
			}
		]

	useEffect(()=>{
		if(props.topicFilterData.showTopicChart && props.topicSummary.length > 0 && props.topicDetail.length > 0){
			props.topicFilterData.selCustomRangeCheckBox = checkSelectState;
			props.fetchTopicData(
				url.topichubReportChart,
				props.topicFilterData,
				1,
				"",
				actionTypes.TOPICHUBCHART
			);					
		}
				// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.isFilApplied && props.topicFilterData.showTopicChart && props.topicSummary.length > 0,props.topicDetail.length > 0])

	const previousPeriodData = (startDate, endDate, comparePeriod) =>{
		props.selTopicCustomRangeChkBox(checkSelectState, actionTypes.SELTOPICCUSTOMRANGECHXBOX)
		let differenceinDays = differenceInDays( endDate, startDate);
		let prevEndDate = subDays(startDate, 1);
		let prevStartDate = subDays(new Date (prevEndDate), differenceinDays)
		if(comparePeriod === "Previous Year"){
			prevStartDate = subYears(new Date(startDate), 1);
			prevEndDate = subYears(new Date(endDate), 1)
		}
		setDateRange({
			selection1: {
				startDate: startDate,
				endDate: endDate,
				key: "selection1",
				color:"#e5582b"
			},
			selection2 : getCompareState(prevStartDate, prevEndDate),
		});
		return cutomDate(prevStartDate, prevEndDate);
	}
	const getCompareState = (prevStartDate, prevEndDate) =>{
		return {
			startDate: prevStartDate,
			endDate: prevEndDate,
			key: "selection2",
			color: "#CC167F",
		}
	}
	const getClearDate =()=>{
		setCheckSelectState(false)
		let sel = dateRange.selection1
		sel.key = "selection";
		setDateRange({
				selection: sel,			
			});
		}
	const cutomDate = (startDate, endDate) =>{
		return getDisplayDate(startDate) + " - " + getDisplayDate(endDate);
	}
	useEffect(()=>{
		if(document.getElementsByClassName("rdrDateDisplayWrapper")?.length !== 0){
			document.getElementsByClassName("rdrDateDisplayWrapper")[0].style.display="none";
		}
	}, [checkSelectState]);

	const paramId = getId(location.hash);
	const urlId = location.pathname + location.hash;

	useEffect(() => {
		initialLoad();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const initial = () => {
		props.applyTopicFilter();
		props.fetchTopicSummary(
			props.topicFilterData,
			1,
			"",
			actionTypes.TOPICSUMMARY
		);
		props.fetchTopicDetail(
			props.topicFilterData,
			1,
			"",
			actionTypes.TOPICDETAIL
		);
	};

	const initialLoad = () => {
		if (
			urlId === `/sidebars/#/?campaign_uuid=${paramId}` &&
			isUUID(paramId)
		) {
			props.fetchCampaigns(
				props.topicFilterData.excludePaidAds,
				props.topicFilterData.site,
				props.topicFilterData.selTagsIN,
				props.topicFilterData.selTagsEX,
				props.topicFilterData.excludeQACampaign,
				"",
				props.topicFilterData.organization,
				props.topicFilterData.campaignStatus,
				//props.topicFilterData.assetType,
				"name",
				actionTypes.READTOPICORGSID,
				[paramId],
				"",
				""
			);
			props.selTopicCampaigns([paramId]);
			if (props.topicFilterData.campaign.includes(paramId)) {
				initial();
			}
		}
	};

	const selectDateRange = async (e, { value }) => {
		props.applyTopicDate(value);
		setDatePopup("custom-date-popup-close");
		if (value === "Custom Date Range") {
			setDatePopup("custom-date-popup-open");
		} else {
			let dates = getStartEndDates(value);
			props.selTopicStartDate(dates.startDate);
			props.selTopicEndDate(dates.endDate);
			props.prevTopicSelStartDate(dates.startDate);
			props.prevTopicSelEndDate(dates.endDate);
			props.prevTopicAppliedDate(value);
			if (props.isFilApplied) {
				applyFilters(dates.startDate, dates.endDate, value, false);
			}
		}
	};

	const applyDate = () => {
		props.topicFilterData.selCustomRangeCheckBox = checkSelectState;
		if(dateRange.hasOwnProperty("selection")){
			tempSelection.current = dateRange.selection;
			props.selTopicStartDate(getDisplayDate(tempSelection.current?.startDate));
			props.selTopicEndDate(getDisplayDate(tempSelection.current?.endDate));
		} else if(dateRange.hasOwnProperty("selection1")){
			tempSelection.current = dateRange.selection1;
			props.selTopicStartDate(getDisplayDate(dateRange?.selection1?.startDate));
			props.selTopicEndDate(getDisplayDate(dateRange?.selection1?.endDate));
			props.selTopicFilterValue(getDisplayDate(dateRange?.selection2?.startDate), actionTypes.SELTOPICPREVSTARTDATE);
			props.selTopicFilterValue(getDisplayDate(dateRange?.selection2?.endDate), actionTypes.SELTOPICPREVENDDATE);
		}
		props.topicFilterData.previousStartDate = getDisplayDate(dateRange?.selection2?.startDate);
		props.topicFilterData.previousEndDate = getDisplayDate(dateRange?.selection2?.endDate);
		props.prevTopicAppliedDate("Custom Date Range");
		setDatePopup("custom-date-popup-close");
		if (props.isFilApplied) {
			applyFilters(
				tempSelection.current.startDate,
				tempSelection.current.endDate,
				"Custom Date Range",
				checkSelectState
			);
		}
	};

	const cancelDate = () => {
		setCheckSelectState(false);
		setDateRange({
			selection: {
				startDate: new Date(),
				endDate: new Date(),
				key: "selection",
			},
		});
		// props.selTopicStartDate(
		// 	getDisplayDate(props.topicFilterData.prevstartDate)
		// );
		// props.selTopicEndDate(getDisplayDate(props.topicFilterData.prevendDate));
		// props.applyTopicDate(getDisplayDate(props.topicFilterData.prevstartDate));
		// if (props.topicFilterData.prevAppliedDate === "Custom Date Range") {
		// 	props.applyTopicDate("Custom Date Range");
		// } else {
		// 	props.applyTopicDate(props.topicFilterData.prevAppliedDate);
		// }
		setDatePopup("custom-date-popup-close");
	};

	//Organization event handlers

	const onClickOrg = () => {
		props.clearSearchOrgs(actionTypes.CLEARSEARCHTOPICORGS);
	};

	const searchOrg = (e) => {
		if (e.target.value.length > 1) {
			props.fetchOrganizations(
				props.topicFilterData.excludeQACampaign,
				e.target.value,
				actionTypes.FETCHTOPICORGS,
				props.organizations,
			);
		}
	};

	const onSelectOrg = (e, { value }) => {
		props.selTopicOrgs(value);
		props.addOrganization(value, actionTypes.ADDTOPICORGS);
		if (!_.isEmpty(props.topicFilterData.campaign)) {
			props.fetchCampaigns(
				"",
				props.topicFilterData.site,
				props.topicFilterData.selTagsIN,
				props.topicFilterData.selTagsEX,
				props.topicFilterData.excludeQACampaign,
				"",
				value,
				props.topicFilterData.campaignStatus,
				//props.topicFilterData.assetType,
				"start_date",
				actionTypes.FETCHSELTOPICCAMPAIGNS,
				props.topicFilterData.campaign,
				true,
				actionTypes.RESETSELTOPICCAMPAIGNS
			);
		}
	};

	//Campaign status event handlers

	const onSelectCampaignStatus = (e, { value }) => {
		props.selTopicStatus(value);
		props.resetOrgsOrCampaigns(actionTypes.RESETTOPICCAMPAIGN);
		props.selTopicCampaigns([]);
	};

	//Campaign event handlers

	const onClickCampaign = () => {
		if (_.isEmpty(props.topicFilterData.organization)) {
			props.clearSearchCampaigns(actionTypes.CLEARSEARCHTOPICCAMPAIGN);
		} else if (!_.isEmpty(props.topicFilterData.organization)) {
			props.fetchCampaigns(
				"",
				props.topicFilterData.site,
				props.topicFilterData.selTagsIN,
				props.topicFilterData.selTagsEX,
				props.topicFilterData.excludeQACampaign,
				"",
				props.topicFilterData.organization,
				props.topicFilterData.campaignStatus,
				//props.topicFilterData.assetType,
				"start_date",
				actionTypes.FETCHTOPICCAMPAIGNS
			);
		}
	};

	const searchCampaign = (e, { value }) => {
		if (e.target.value.length > 1) {
			props.fetchCampaigns(
				"",
				props.topicFilterData.site,
				props.topicFilterData.selTagsIN,
				props.topicFilterData.selTagsEX,
				props.topicFilterData.excludeQACampaign,
				e.target.value,
				props.topicFilterData.organization,
				props.topicFilterData.campaignStatus,
				//props.topicFilterData.assetType,
				"name",
				actionTypes.FETCHTOPICCAMPAIGNS
			);
		}
	};

	const onSelectCampaigns = (e, { value }) => {
		props.addCampaigns(value, actionTypes.ADDTOPICCAMPAIGNS);
		props.selTopicCampaigns(value);
		// if (value.length === 1) {
		//   let obj = props.campaigns.find((o) => o.uuid === value[0]);
		//   const campaignStartDate = new Date(obj.start_date);
		//   props.selTopicStartDate(getDisplayDate(campaignStartDate));
		//   props.selTopicEndDate(getDisplayDate(addDays(campaignStartDate, 30)));
		//   props.applyTopicDate("Custom Date Range");
		// } else {
		// props.applyTopicDate(getDisplayDate(getLastDays(7)));
		// props.selTopicStartDate(getDisplayDate(getLastDays(7)));
		// props.selTopicEndDate(getDisplayDate(getYesterdaysDate()));
		//}
	};

	//Site event handlers

	const onSelectSite = (e, { value }) => {
		props.selTopicSite(value);
	};
	//Order event handlers
	const onSelectOrder = (e, { value }) => {
		props.selTopicOrder(value);
		props.addOrder(value, actionTypes.ADDTOPICORDER);
	};
	const searchOrder = (e) => {
		if (e.target.value.length > 1) {
			props.fetchOrder(e.target.value, "name", actionTypes.FETCHTOPICORDER);
		}
	};

	const onClickOrder = () => {
		props.clearSearchOrder(actionTypes.CLEARSEARCHTOPICORDER);
	};

	// Country Filter

	const onClickCountry = () => {
		if (_.isEmpty(props.topicFilterData.selCountry)) {
			props.clearTopicSearchedValue(actionTypes.CLEARSEARCHTOPICCOUNTRY);
		}
	};

	const searchCountry = (e) => {
		if (e.target.value.length > 1) {
			props.fetchCountry(e.target.value, actionTypes.FETCHTOPICCOUNTRY);
		} else {
			props.clearTopicSearchedValue(actionTypes.CLEARSEARCHTOPICCOUNTRY);
		}
	};

	const onSelectCountry = (e, { value }) => {
		props.selTopicFilterValue(value, actionTypes.SELTOPICCOUNTRY);
		props.addCountries(value, actionTypes.ADDSIDEBARCONTRIES);
	};

	// Engaged Organizations
	const onClickTopicByOrg = () => {
		// if (_.isEmpty(props.topicFilterData.organization)) {
		// 	props.clearTopicSearchedValue(actionTypes.CLEARSEARCHTOPICENGORGS);
		// } else 
		if (!_.isEmpty(props.topicFilterData.organization) && (props.topicFilterData.topicIsIncludeParent || props.topicFilterData.topicIsIncludeChild)) {
			props.fetchEngagedOrg(
				props.topicFilterData.excludeQACampaign,
				"",
				actionTypes.FETCHTOPICENGORGS,
				true,
				props.topicFilterData.organization,
				props.topicFilterData.topicIsIncludeParent,
				props.topicFilterData.topicIsIncludeChild,
			);
		}
	  };

	const searchTopicByOrg = (e, { value }) => {
		if (e.target.value.length > 1) {
		  props.fetchEngagedOrg(
			props.topicFilterData.excludeQACampaign,
			e.target.value,
			actionTypes.FETCHTOPICENGORGS,
			true,
			props.topicFilterData.organization,
			props.topicFilterData.topicIsIncludeParent,
			props.topicFilterData.topicIsIncludeChild,
		  );
		}
	  };

	const onSelectTopicByOrg = (e, { value }) => {
		props.selTopicEngOrgs(value);
		props.addOrganization(value, actionTypes.ADDTOPICENGORGS);
	};

	const applyFilters = (startDate, endDate, appDate, isCustomCheckbox) => {
		let tempTopicFilterData = props.topicFilterData;
		tempTopicFilterData.startDate = startDate;
		tempTopicFilterData.endDate = endDate;
		tempTopicFilterData.prevStartDate = "";
		tempTopicFilterData.prevEndDate = "";
		tempTopicFilterData.appliedDate = appDate;
		tempTopicFilterData.selCustomRangeCheckBox = isCustomCheckbox;

		props.applyTopicFilter();
		props.fetchTopicSummary(
			props.topicFilterData,
			1,
			"",
			actionTypes.TOPICSUMMARY
		);
		props.fetchTopicDetail(
			props.topicFilterData,
			1,
			"",
			actionTypes.TOPICDETAIL
		);
		props.topicFilterData.selectSidebarDetailTable && 
			props.fetchTopicData(
			url.sidebarDetail,
			props.topicFilterData,
			1,
			"",
			actionTypes.SIDEBARDETAIL
		)
		setFilterPopup("filter-popup-close");
		setFilterIconColor("material-icons grey");
		setFilterSelectFocus("filter-icon");
		setDatePopup("custom-date-popup-close");
	};

		//Engaged member eventy Handler

		const onClickEngagedMember = () => {
			if (_.isEmpty(props.topicFilterData.selEnagedMember)) {
				props.clearTopicSearchedValue(actionTypes.CLEARSEARCHSIDEBARENGMEMBER);
			}
		};
	
		const searchEngagedMember = (e) => {
			if (e.target.value.length > 1) {
				props.fetchEngagedMember(
					e.target.value,
					actionTypes.FETCHSIDEBARENGMEMBER,
				);
			}
		};
	
		const onSelectEngagedMember = (e, { value }) => {
			props.selTopicFilterValue(value, actionTypes.SELSIDEBARENGAGEDMEMBER);
			props.selTopicFilterValue(value, actionTypes.ADDSIDEBARENGMEMBER);
		};

	const clearFilters = () => {
		clearfilterClick.current=true;
		if(clearfilterClick.current && props.topicFilterData.appliedDate === "Custom Date Range"){
			setDateRange({
				selection: {
					startDate: new Date(),
					endDate: new Date(),
					key: "selection",
				},
			});
		}
		setCheckSelectState(false); 
		props.clearTopicFilter();
		setDateRange({
			selection: {
				startDate: new Date(),
				endDate: new Date(),
				key: "selection",
			},
		});
		props.selTopicFilterValue(props.delivery_states, actionTypes.CLEARTOPICFILTER);
		setDatePopup("custom-date-popup-close");
		const actionTypesToClear = [
			actionTypes.RESETTOPICORGS,
			actionTypes.RESETTOPICCAMPAIGN,
			actionTypes.RESETTOPICORDER,
			actionTypes.RESETTOPICENGORGS,
			actionTypes.RESETTOPICCOUNTRY,
			actionTypes.RESETTOPICENGAGEDMEMBER,
			actionTypes.RESETSIDEBARORGSTYPE,
			actionTypes.RESETSIDEBARORGSSIZE,
			actionTypes.RESETSIDEBARORGREVENUE,
			actionTypes.RESETSIDEBARSECTOR,
			actionTypes.RESETSIDEBARINDUSTRYGROUP,
			actionTypes.RESETSIDEBARINDUSTRY,
			actionTypes.RESETSIDEBARSUBINDUSTRY,
			actionTypes.RESETSIDEBARSENORITIESIN,
			actionTypes.RESETSIDEBARSENORITIESEX,
			actionTypes.RESETSIDEBARJOBFUNCTIONIN,
			actionTypes.RESETSIDEBARJOBFUNCTIONEX
		  ];
	  
		  actionTypesToClear.forEach((actionType) => {
			props.clearTopicSearchedValue(actionType);
		  });
	};

	// const onChangeIncludeParent = (e) => {
	// 	if(props.topicFilterData.topicIsIncludeChild){
	// 	  props.selTopicToggles(actionTypes.SELTOPICPARENTTOOGLE);
	// 	  props.selTopicToggles(actionTypes.SELTOPICCHILDTOOGLE);
	// 	} else {
	// 	  props.selTopicToggles(actionTypes.SELTOPICPARENTTOOGLE);
	// 	}
	//   }
	
	//   const onChangeIncludeChild = (e) => {
	// 	if(props.topicFilterData.topicIsIncludeParent){
	// 	  props.selTopicToggles(actionTypes.SELTOPICPARENTTOOGLE);
	// 	  props.selTopicToggles(actionTypes.SELTOPICCHILDTOOGLE);
	// 	} else {
	// 	  props.selTopicToggles(actionTypes.SELTOPICCHILDTOOGLE);
	// 	}
	//   }

	const onSelectDeliveryStatus = (e, { value }) => {
		props.selTopicFilterValue(value, actionTypes.SELSIDEBARDELIVERYSTATE);
	};

	//Pacing filter event handler
	const onSelectPacingFilter = (e, { value }) => {
		props.selTopicFilterValue(value, actionTypes.SELSIDEBARPACINGFILTERVALUE);
	};

	const onSelectTrafficFilter = (e, { value }) => {
		props.selTopicFilterValue(value, actionTypes.SELSIDEBARTRAFFICFILTERVALUE);
	}

	return (
		<div>
			<div className="date-picker">
				{/* Date picker */}
				<SelectDatePicker
					props={props}
					selectDateRange={selectDateRange}
					data={props.topicFilterData}
					applyDate={props.applyTopicDate}
					setDatePopup={setDatePopup}
					dateRange={dateRange}
					checkSelectState={checkSelectState}
				/>
				{/* Filter icon */}
				<SearchFilterIcon
					filterSelectFocus={filterSelectFocus}
					setFilterSelectFocus={setFilterSelectFocus}
					setFilterPopup={setFilterPopup}
					setFilterIconColor={setFilterIconColor}
					filterIconColor={filterIconColor}
				/>
			</div>
			<div className="popup-component">
				{/* Custom date picker component */}
				<CustomDatePickerWithCompare
					datePopup={datePopup}
					setDatePopup={setDatePopup}
					checkSelectState={checkSelectState}
					cutomDate={cutomDate}
					dateRange={dateRange}
					setCheckSelectState={setCheckSelectState}
					setComparePeriod={setComparePeriod}
					previousPeriodData={previousPeriodData}
					comparePeriod={comparePeriod}
					getClearDate={getClearDate}
					setDateRange={setDateRange}
					cancelDate={cancelDate}
					applyDate={applyDate}
					isComparePeriod={true}
				/>
				{/* Search filter popup */}
				<div className={filterPopup}>
					<div className="filter-header">
						<div className="filter-text">
							<p>Filters</p>
						</div>
						<div
							className="filter-close-icon"
							onClick={() => {
								setFilterSelectFocus("filter-icon");
								setFilterPopup("filter-popup-close");
							}}
						>
							<i
								className="material-icons grey"
								onClick={() => setFilterIconColor("material-icons grey")}
							>
								clear
							</i>
						</div>
					</div>
					<Form>
						<div className="pg-search-filter">
							<Form.Group widths="equal">
								<OrganizationPicker
									onSearchChange={searchOrg}
									value={props.topicFilterData.organization}
									onChange={onSelectOrg}
									onClick={onClickOrg}
									organizations={props.organizations}
								/>
								<OrderPicker
									onSearchChange={searchOrder}
									value={props.topicFilterData.order}
									onChange={onSelectOrder}
									onClick={onClickOrder}
									order={props.order}
								/>
							</Form.Group>
							<Form.Group widths="equal">
								<StatusPicker
									value={props.topicFilterData.campaignStatus}
									onChange={onSelectCampaignStatus}
								/>
								<CampaignPicker
									onSearchChange={searchCampaign}
									value={props.topicFilterData.campaign}
									onChange={onSelectCampaigns}
									campaigns={props.campaigns}
									onClick={onClickCampaign}
								/>
							</Form.Group>
							<Form.Group widths="equal">
								<DeliveryPicker 
									style={{ width: "24rem" }}
									value={props.topicFilterData.deliverdFilterState}
									onChange={onSelectDeliveryStatus}
									delivery_states={props.delivery_states}
								/>
								<PacingFilter
									value={props.topicFilterData.pacingFilter}
									onChange={onSelectPacingFilter}
						 		/>
							</Form.Group>
							<Form.Group widths="equal">
								<CountryMultiSelectPicker
									countries={props.countries}
									value={props.topicFilterData.selCountry}
									onClick={onClickCountry}
									onChange={onSelectCountry}
									onSearchChange={searchCountry}
									label="Country"
								/>
								<SitePicker
									value={props.topicFilterData.site}
									onChange={onSelectSite}
								/>
							</Form.Group>
							<Form.Group widths="equal">
								<TrafficFilterPicker
									value={props.topicFilterData.trafficType}
									onChange={onSelectTrafficFilter}
								/>
								<CSMSelection
									authors={props.author}
									value={props.topicFilterData.setCSM}
									FETCH={actionTypes.FETCH_SIDEBAR_CSM}
									ADD={actionTypes.ADD_SIDEBAR_CSM}
									SELECT={actionTypes.SELECTED_SIDEBAR_TOGGLE}
									CLEAR={actionTypes.CLEAR_SIDEBAR_CSM}
								/>
							</Form.Group>
							<Form.Group widths="equal">
                				<Form className="search-filter-column-1" style={{paddingLeft: "10px"}}>
									<EngagedOrgPicker
										organizations={props.topicEngOrgs}
										value={props.topicFilterData.engagedOrganizations}
										onClick={onClickTopicByOrg}
										onChange={onSelectTopicByOrg}
										onSearchChange={searchTopicByOrg}
										type="addOrgLink"
									/>
									<div className="engagements-search-filter">
										{/* <Form className="search-filter-column-1"  style={{marginLeft: "10px", paddingRight: "6px"}}>
										<div style={{marginTop: "20px"}}>
											<Toggle
												label="Include Parent"
												onChange={onChangeIncludeParent}
												checked={props.topicFilterData.topicIsIncludeParent}
											/>
										</div>
										<div style={{marginTop: "12px"}}>
											<Toggle
												label="Include Children"
												onChange={onChangeIncludeChild}
												checked={props.topicFilterData.topicIsIncludeChild}
											/>
										</div>	
										</Form> */}
										</div>
										</Form>
										<Form className="search-filter-column-2">
											<EngagedMember
												label="Engagement Member"
												placeholder="Engagement Member"
												engMember={props.sidebarEngMember}	
												value={props.topicFilterData.selEnagedMember}
												onClick={onClickEngagedMember}
												onChange={onSelectEngagedMember}
												onSearchChange={searchEngagedMember}
												type="addMemberLink"
											/>
										</Form>
									</Form.Group>
									<br />
							<div>
								<Toggle
									// className="filters-checkbox"
									label="Firmographic  (Only applicable for xlsx)"
									onChange={() => {
									props.selTopicToggles(actionTypes.SELTOPICFIRMOGRAPHIC);
									}}
									checked={props.topicFilterData.firmographicToggle}
									style={{paddingLeft: "10px"}}
								/>
								{props.topicFilterData.firmographicToggle && (
									<FirmographicFilter
									//  rowGap="10px"
									firmogaphicFilterData={firmogaphicFilterData}
									onSearchChange = {onSearchChangeFirmographic}
									onChange = {onSelectFirmographic}
									onClick = {onClickFirmographic}
									//style={{paddingLeft: props.topicFilterData.firmographicToggle && "10px"}}
									/>
								)}
               				</div>
						<br />
								<Toggle
									label="Persona (Only applicable for xlsx)"
									onChange={() => {
									props.selTopicToggles(actionTypes.SELTOPICPERSONA);
									}}
									checked={props.topicFilterData.personaToggle}
									style={{paddingLeft: "10px"}}
								/>
								{props.topicFilterData.personaToggle && (
									<PersonaFilter
										personaSenority = {personaSeniority}
										personaJobFunction = {personaJobFunction}
										personaJobTitleIn = {personaJobTitleIn}
										personaJobTitleEx = {personaJobTitleEx}
										labelJobTitleIn = "Job Title (Include)"
										labelJobTitleEx = "Job Title (Exclude)"
									/>
								)}
								<CompetitorFilterToggleForOthers
									filterData={props.topicFilterData}
									action={actionTypes.SELECTED_SIDEBAR_TOGGLE}
									style={{paddingLeft: "10px", marginTop: "8px"}}
								/>
						</div>
						<div className="engagements-search-filter">
						<Form className="search-filter-column-1"></Form>
							<Form className="search-filter-column-2">

								<div className="ad-toggle-label">
									<p>Campaign Filters</p>
								</div>
								<Toggle
									//className="ad-child-toggle"
									style={{paddingLeft: "20px"}}
									label="Exclude QA Campaign"
									onChange={() => {
										props.selTopicToggles(actionTypes.SELTOPQATOGGLE);
									}}
									checked={props.topicFilterData.excludeQACampaign}
								/>
								<Toggle
									//className="ad-child-toggle"
									style={{paddingLeft: "20px"}}
									label="Exclude Internal Campaign"
									onChange={() => {
										props.selTopicToggles(actionTypes.SELTOPINTOGGLE);
									}}
									checked={props.topicFilterData.excludeInternalCampaign}
								/>
							</Form>
						</div>
					</Form>
					<div className="filter-buttons">
						<button className="cancel-date-button extra-space" onClick={clearFilters}>
							Clear Filters
						</button>
						<button
							type="submit"
							className="apply-filter-button"
							onClick={() =>{
								applyFilters(
									props.topicFilterData.startDate,
									props.topicFilterData.endDate,
									props.topicFilterData.appliedDate,
									checkSelectState
								)
							}}
						>
							Apply Filters
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		order: Object.values(state.topicOrder),
		campaigns: Object.values(state.topicCampaigns),
		organizations: Object.values(state.topicOrganizations),
		countries: Object.values(state.topicCountry),
		topicFilterData: state.topicFilterData,
		isFilApplied: state.topicFetchData.isFilApplied,
		topicEngOrgs: Object.values(state.topicEngOrgs),
		topicSummary: state.topicFetchData.topicSummary,
		topicDetail: state.topicFetchData.topicDetail,
		seniorities: Object.values(state.seniorities),
		senioritiesIn: Object.values(state.senioritiesInSidebar),
		senioritiesEx: Object.values(state.senioritiesExSidebar),
		jobfunctions: Object.values(state.jobfunctions),
		JobFunctionsEX: Object.values(state.jobfunctionsExSidebar),
		JobFunctionsIN: Object.values(state.jobfunctionsInSidebar),
		orgTypes: Object.values(state.orgTypesList),
		firmographicOrgsType: Object.values(state.firmographicSidebarOrgsType),	
		orgSize: Object.values(state.orgSizeList),
		firmographicOrgSize: Object.values(state.firmographicSidebarOrgsSize),
		orgRevenue: Object.values(state.orgRevenueList),
		firmographicOrgsRevenue: Object.values(state.firmographicSidebarOrgsRevenue),
		firmographicSector: Object.values(state.firmographicSidebarSector),
		sector: Object.values(state.sectorList),
		firmographicIndustryGroup: Object.values(state.firmographicSidebarIndustryGroup),
		industryGroup: Object.values(state.industryGroupList),
		firmographicIndustry: Object.values(state.firmographicSidebarIndustry),
		industry: Object.values(state.industryList),
		firmographicSubIndustry: Object.values(state.firmographicSidebarSubIndustry),
		subIndustry: Object.values(state.subIndustryList),
		sidebarEngMember: Object.values(state.sidebarEngMember),
		isAuthorized: state.authorized.token != null,
		delivery_states: state?.topicFilterData?.deliverdFilterStateOptions,
		author: Object.values(state.sidebarAuthor),
	};
};

export default compose(
	connect(mapStateToProps, {
		fetchOrganizations,
		addOrganization,
		addCampaigns,
		addOrder,
		fetchCountry,
		fetchOrder,
		fetchTopicData,
		fetchCampaigns,
		resetOrgsOrCampaigns,
		resetOrder,
		clearSearchOrgs,
		clearSearchOrder,
		clearTopicSearchedValue,
		clearSearchCampaigns,
		selTopicFilterValue,
		selTopicStartDate,
		selTopicEndDate,
		applyTopicDate,
		selTopicOrgs,
		selTopicOrder,
		selTopicEngOrgs,
		selTopicCampaigns,
		selTopicStatus,
		selTopicSite,
		applyTopicFilter,
		clearTopicFilter,
		fetchTopicDetail,
		fetchTopicSummary,
		selTopicToggles,
		prevTopicSelEndDate,
		prevTopicSelStartDate,
		prevTopicAppliedDate,
		selTopicCustomRangeChkBox,
		firmographicSidebarTypeCall,
		addCountries,
		fetchEngagedMember,
		SenioritiesList,
		fetchappliedFilter,
		fetchEngagedOrg,
	})
)(SearchFilter);
