import React, { useRef } from "react";
import { connect } from "react-redux";
import { Checkbox, Loader } from "semantic-ui-react";
import EngagementValidationChart from "./engagementValidationChart";
import { selLeadToggles } from "../../actions";
import { getCapital, getDisplayDateChart } from "../../utilities/common";
import * as actionTypes from "../../actions/actionTypes";
import _ from "lodash";
import DownloadChart from "../common/downloadChart";

const LeadEngsChart = (props) => {
  const chartNameDiv = useRef(null);
  let loading;
  if (props.loading) {
    loading = (
      <div className="show-loader">
        <Loader active inline="centered" />
      </div>
    );
  }

  return (
    <div className="ad-graph">
      <div ref={chartNameDiv}>
      {!_.isEmpty(props.engagementValidationChart) && props.leadFilterData.selLeadEngChart && 
          <DownloadChart
          chartNameDiv= {chartNameDiv.current}
          chartName = {(`Engagement-Chart-${getDisplayDateChart(new Date())}.jpg`)}
          style={{width:"36px", height: "36px", marginTop: 19, marginRight: 56, color: "#838486" }}
        />
      }
        <p className="ad-chart-name">
          {getCapital("Pacing")}
          <Checkbox
            toggle
            data-html2canvas-ignore="true"
            style={{
              color: "white",
              fontFamily: "Roboto",
              fontSize: "16px",
              paddingLeft: "20px",
              minHeight: 15,
            }}
            disabled={!props.isFilApplied}
            label={props.leadFilterData.selLeadEngChart ? "Hide" : "Show"}
            className="checkbox-label"
            onChange={() =>
              props.selLeadToggles(actionTypes.SELLEADENGSCHARTTOGGLE)
            }
            checked={props.leadFilterData.selLeadEngChart}
          />
          {
            props.isFilApplied && props.leadFilterData.selLeadEngChart && 
            loading
          }
          {!_.isEmpty(props.engagementValidationChart) && props.leadFilterData.selLeadEngChart && (
              <EngagementValidationChart />
            )}
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    leadFilterData: state.leadFilterData,
    isFilApplied: state.leadFetchData.isFilApplied,
    engagementValidationChart: state.leadFetchData.engagementValidationChart,
    loading: state.leadFetchData.isLoadingChart,
  };
};

export default connect(mapStateToProps, {
  selLeadToggles,
})(LeadEngsChart);
