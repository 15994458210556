import React from "react";
import { Table, Popup } from "semantic-ui-react";

const AccountInterestByKeywordsComponent = ({ data }) => {
    const getTags = (keys) => {
        return keys
            .map(key => data.account_interest_by_your_keywords?.[key]?.map(tag => tag.TagName).join(', ') || '')
            .filter(Boolean)
            .join(', ') || '';
    };

    const allTags = getTags(['CombinedTags', 'CoreTags', 'TopicTags']);

    return (
        <Table.Cell className="space_height">
            <Popup
                trigger={<span>{allTags.length > 30 ? `${allTags.substring(0, 30)}...` : allTags}</span>}
                content={allTags} // Full text on hover
                on='hover'
                position='top center'
            />
        </Table.Cell>
    );
};

export default AccountInterestByKeywordsComponent;
