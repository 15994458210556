import React from "react";
import { connect } from "react-redux";
import CampaignReport from "./campaignReport";
import Link from "../hyperlinks/link";
import { getCapital, getDateTime, numFormat } from "../../utilities/common";

const LeadEngagementDetailCampaign = (props) => {
	let leadEngagementDetailCampaign = props.data;
	let IsWebinar = true;
	let OrgVert = {};
	OrgVert.sector = leadEngagementDetailCampaign.organization_sector && leadEngagementDetailCampaign.organization_sector + ", ";
	OrgVert.industry_group =
		leadEngagementDetailCampaign.organization_industry_group && leadEngagementDetailCampaign.organization_industry_group  + ", ";
	OrgVert.industry = leadEngagementDetailCampaign.organization_industry &&  leadEngagementDetailCampaign.organization_industry  + ", ";
	OrgVert.sub_industry = leadEngagementDetailCampaign.organization_sub_industry;
	if (
		props.filterData.assetType === "all" ||
		props.filterData.assetType === "webinar" ||
		props.filterData.assetType === ""
	) {
		IsWebinar = true;
	} else {
		IsWebinar = false;
	}

	return (
		<div>
			<CampaignReport
				object={leadEngagementDetailCampaign.lead_partner}
				label={"ENGAGEMENT SOURCE"}
			/>
			<h5 className="drawer-heading-text">MEMBER DETAILS</h5>
			<CampaignReport
				object={leadEngagementDetailCampaign.seniority}
				label={"SENIORITY LEVEL"}
			/>
			<CampaignReport
				object={leadEngagementDetailCampaign.primary_role}
				label={"JOB FUNCTION"}
			/>
			<CampaignReport
				object={leadEngagementDetailCampaign.member_title}
				label={"JOB TITLE"}
			/>
			<CampaignReport
				object={leadEngagementDetailCampaign.email}
				label={"EMAIL"}
			/>
			<CampaignReport
				object={leadEngagementDetailCampaign.phone_number}
				label={"PHONE NUMBER"}
			/>
			<CampaignReport
				object={leadEngagementDetailCampaign.member_address}
				label={"ADDRESS"}
			/>
			<CampaignReport
				object={leadEngagementDetailCampaign.member_country}
				label={"COUNTRY"}
			/>
			<CampaignReport
				object={leadEngagementDetailCampaign.member_timezone}
				label={getCapital("Timezone")}
			/>
			<h5 className="drawer-heading-text">ACCOUNT DETAILS</h5>
			<CampaignReport
				object={
					leadEngagementDetailCampaign.organization_uuid ? (
						<Link
							type="organizations"
							urlParam={leadEngagementDetailCampaign.organization_uuid}
							refValue={leadEngagementDetailCampaign.organization_name}
						></Link>
					) : (
						leadEngagementDetailCampaign.organization_name
					)
				}
				label={"ORGANIZATION NAME"}
			/>
			<CampaignReport
				object={Object.values(OrgVert)}
				label={"ORGANIZATION VERTICAL"}
			/>
			<CampaignReport
				object={numFormat(leadEngagementDetailCampaign.employees)}
				label={"ORGANIZATION SIZE"}
			/>
			<CampaignReport
				object={leadEngagementDetailCampaign.estimated_revenue}
				label={"ORGANIZATION REVENUE"}
			/>
			<CampaignReport
				object={leadEngagementDetailCampaign.type}
				label={"ORGANIZATION TYPE"}
			/>
			<h5 className="drawer-heading-text">PRIVACY DETAILS</h5>
			<CampaignReport
				object={
					leadEngagementDetailCampaign.purpose_consent === true ? "Yes" : "No"
				}
				label={"PURPOSE CONSENT"}
			/>
			<CampaignReport
				object={
					leadEngagementDetailCampaign.marketing_consent === true ? "Yes" : "No"
				}
				label={"MARKETING CONSENT"}
			/>
			<CampaignReport
				object={
					leadEngagementDetailCampaign.deletion_policy === "dnsmpi"
						? "Yes"
						: leadEngagementDetailCampaign.deletion_policy
				}
				label={"DO NOT SELL MY PERSONAL INFO"}
			/>
			<CampaignReport
				object={
					leadEngagementDetailCampaign.deletion_policy === "rtbf"
						? "Yes"
						: leadEngagementDetailCampaign.deletion_policy
				}
				label={"RIGHT TO BE FORGOTTEN"}
			/>
			<h5 className="drawer-heading-text">VIDEO ENGAGEMENT DETAILS</h5>
			<CampaignReport
				className={`${IsWebinar ? "" : "zoom-webinar"}`}
				object={leadEngagementDetailCampaign.attended}
				label={"ATTENDED WEBINAR"}
			/>
			<CampaignReport
				className={`${IsWebinar ? "" : "zoom-webinar"}`}
				object={getDateTime(leadEngagementDetailCampaign.webinar_join_time)}
				label={"WEBINAR JOIN TIME"}
			/>
			<CampaignReport
				className={`${IsWebinar ? "" : "zoom-webinar"}`}
				object={getDateTime(leadEngagementDetailCampaign.webinar_leave_time)}
				label={"WEBINAR LEAVE TIME"}
			/>
			<CampaignReport
				className={`${IsWebinar ? "" : "zoom-webinar"}`}
				object={leadEngagementDetailCampaign.webinar_time_in_min}
				label={"WEBINAR DURATION (MINIUTES IN SESSION)"}
			/>
			<h5 className="drawer-heading-text">UTM DETAILS</h5>
			<CampaignReport
				object={leadEngagementDetailCampaign.source}
				label={"UTM SOURCE"}
			/>
			<CampaignReport
				object={leadEngagementDetailCampaign.utm_medium}
				label={"UTM MEDIUM"}
			/>
			<CampaignReport
				object={leadEngagementDetailCampaign.utm_campaign}
				label={"UTM CAMPAIGN"}
			/>
			<CampaignReport
				object={leadEngagementDetailCampaign.utm_term}
				label={"UTM TERM"}
			/>
			<CampaignReport
				object={leadEngagementDetailCampaign.utm_content}
				label={"UTM CONTENT"}
			/>
			<CampaignReport
				object={leadEngagementDetailCampaign.user_agent}
				label={"USER AGENT"}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		filterData: state.leadFilterData,
	};
};

export default connect(mapStateToProps)(LeadEngagementDetailCampaign);
