import React, { useState } from "react";
import Toggle from "../../base/toggle";
import { FIRMOGRAPHIC_TOGGLE_LABEL, FIRMOGRAPHIC_TOGGLE_LABEL2 } from "../../config/const";
import * as actionTypes from "../../../actions/actionTypes";
import { connect } from "react-redux";
import { compose } from "redux";
import { selectValue, addSelectedValue, clearSearchForAllReport } from "../../../actions";
import FirmographicFilter from "../../common/firmographicFilter";
import pageFirmoGraphic from "../../config/FilterFirmogaphicData";

const FiromographicsFilterComponent = (props) => {
    const [orgType, setOrgType] = useState([]);
    const [orgSize, setOrgSize] = useState([]);
    const [orgRevenue, setOrgRevenue] = useState([]);
    const [sector, setSector] = useState([]);
    const [industryGroup, setIndustryGroup] = useState([]);
    const [industry, setIndustry] = useState([]);
    const [subIndustry, setSubIndustry] = useState([]);

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    const firmogaphicFilterData = pageFirmoGraphic(
        props.filterData,
        props.commonSelectData.sectors,
        props.commonSelectData.industryGroups,
        props.commonSelectData.industries,
        props.commonSelectData.subIndustries,
        props.commonSelectData.organizationTypes,
        props.commonSelectData.organizationSizes,
        props.commonSelectData.organizationRevenues,
        props.isRevenueVisible,
    );

    const filterAndSet = (e, answerKey, data, setData, actionType, stateValue) => {
        setData([]);
        if (e.target.value?.length > (answerKey === "org_type" ? 0 : 1)) {
            setData(
                data
                    .filter((stateValue) => {
                        return stateValue;
                    })
                    .map((stateValue) => {
                        stateValue.disabled = false;
                        return stateValue;
                    })
            );
            props.addSelectedValue({ dispatchType: actionType, reportType: props.reportActionType, value: stateValue });
        }
    };

    const onSearchChangeFirmographic = (e, answerKey) => {
        switch (answerKey) {
            case "org_type":
                filterAndSet(e, answerKey, props.orgTypes, setOrgType, actionTypes[props.reportActionType].FETCH_ORGANIZATION_TYPES, orgType);
                break;
            case "org_size":
                filterAndSet(e, answerKey, props.orgSize, setOrgSize, actionTypes[props.reportActionType].FETCH_ORGANIZATION_SIZES, orgSize);
                break;
            case "org_revenue_range":
                filterAndSet(e, answerKey, props.orgRevenue, setOrgRevenue, actionTypes[props.reportActionType].FETCH_ORGANIZATION_REVENUES, orgRevenue);
                break;
            case "sector":
                filterAndSet(e, answerKey, props.sector, setSector, actionTypes[props.reportActionType].FETCH_SECTORS, sector);
                break;
            case "industry_group":
                filterAndSet(e, answerKey, props.industryGroup, setIndustryGroup, actionTypes[props.reportActionType].FETCH_INDUSTRY_GROUPS, industryGroup);
                break;
            case "industry":
                filterAndSet(e, answerKey, props.industry, setIndustry, actionTypes[props.reportActionType].FETCH_INDUSTRIES, industry);
                break;
            case "sub_industry":
                filterAndSet(e, answerKey, props.subIndustry, setSubIndustry, actionTypes[props.reportActionType].FETCH_SUB_INDUSTRIES, subIndustry);
                break;
            default:
                break;
        }
    };

    const selectKeyMapping = {
        "org_type": "selOrgType",
        "org_size": "selOrgSize",
        "org_revenue_range": "selOrgRevenue",
        "sector": "selSector",
        "industry_group": "selIndustryGroup",
        "industry": "selIndustry",
        "sub_industry": "selSubIndustry"
    };
    
    const addActionMapping = {
        "org_type": "ADD_ORGANIZATION_TYPES",
        "org_size": "ADD_ORGANIZATION_SIZES",
        "org_revenue_range": "ADD_ORGANIZATION_REVENUES",
        "sector": "ADD_SECTORS",
        "industry_group": "ADD_INDUSTRY_GROUPS",
        "industry": "ADD_INDUSTRIES",
        "sub_industry": "ADD_SUB_INDUSTRIES"
    };
    
    const onSelectFirmographic = (e, answerKey, value) => {
        const selectKey = selectKeyMapping[answerKey];
        const addAction = addActionMapping[answerKey];
    
        if (selectKey && addAction) {
            dispatchDynamicSelectAction(props, selectKey, value);
            props.addSelectedValue({ dispatchType: actionTypes[props.reportActionType][addAction], reportType: props.reportActionType, value });
        }
    };

    const clearActionMapping = {
        "org_type": "CLEAR_ORGANIZATION_TYPES",
        "org_size": "CLEAR_ORGANIZATION_SIZES",
        "org_revenue_range": "CLEAR_ORGANIZATION_REVENUES",
        "sector": "CLEAR_SECTORS",
        "industry_group": "CLEAR_INDUSTRY_GROUPS",
        "industry": "CLEAR_INDUSTRIES",
        "sub_industry": "CLEAR_SUB_INDUSTRIES"
    };
    
    const onClickFirmographic = (e, answerKey) => {
        const clearAction = clearActionMapping[answerKey];

        if (clearAction) {
            props.clearSearchForAllReport({
                dispatchType: actionTypes[props.reportActionType][clearAction],
                reportType: props.reportActionType
            });
        }
    };

    return (
        <React.Fragment>
            <Toggle
                label={props.isApplicable ? FIRMOGRAPHIC_TOGGLE_LABEL : FIRMOGRAPHIC_TOGGLE_LABEL2 }
                onChange={() => dispatchDynamicSelectAction(props, "firmographicToggle", !props.filterData.firmographicToggle)}
                checked={props.filterData.firmographicToggle}
                className="firmographic_toggle"
            />
            {
                props.filterData.firmographicToggle &&
                <FirmographicFilter
                    firmogaphicFilterData={firmogaphicFilterData}
                    onSearchChange={onSearchChangeFirmographic}
                    onChange={onSelectFirmographic}
                    onClick={onClickFirmographic}
                />
            }
        </React.Fragment>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        commonSelectData: state.allReportCommonReducer[ownProps.reportActionType],
        sector: Object.values(state.sectorList),
        industryGroup: Object.values(state.industryGroupList),
        industry: Object.values(state.industryList),
        subIndustry: Object.values(state.subIndustryList),
        orgTypes: Object.values(state.orgTypesList),
        orgSize: Object.values(state.orgSizeList),
        orgRevenue: Object.values(state.orgRevenueList),
    };
};

export default compose(connect(mapStateToProps, {
    selectValue,
    addSelectedValue,
    clearSearchForAllReport,
}))(FiromographicsFilterComponent);