export const DisplayReportLocation = '/ads-display';
export const ContentBodyReportLocation = '/ads-content-body';
export const InterstitialReportLocation = '/ads-interstitial';
export const NewsletterReportLocation = '/ads-newsletter';
export const RoadblockReportLocation = '/ads-roadblock';
export const SidebarAdReportLocation = '/ads-sidebar';
export const SidebarReportLocation = '/sidebars';
export const EngagementReportLocation = '/engagements';
export const ActivityReportLocation = '/activity';
export const LeadReportLocation = '/leads';
export const SponsoredReportLocation = '/sponsored';
export const PageviewReportLocation = '/pageview';
export const PageviewDetailReportLocation = '/pageview-details';
export const NewslettersLocation = '/newsletters';
export const AllAdsLocation = '/ads';
export const ContentPerformance = '/content-performance';
export const AuthorsReportLocation = '/author';
export const DIEarnedMedia = '/decision-insights-basic';
export const DIBasic = '/decision-insights-pro';
export const DIPro = '/decision-insights-pro+';
export const DIUpgrade = '/decision-insights-upgrade';
export const OrderSummaryLocation = '/orders';