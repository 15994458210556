import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import Link from "./link";

const MultiLinks = (props) => {
    const { type, object } = props;
    let links = [];
    if (props.object && _.isArray(object)) {
        object.forEach((data, i) => {
            if (type === "sidebar") {
                links.push(<Link key={i} type={type} urlParam={data.url} refValue={data.text}></Link>);
            } else {
                links.push(<Link key={i} type={type} urlParam={data.uuid} refValue={data.name}></Link>);
            }
            if (i < object.length - 1) {
                links.push(<span key={`comma-${i}`}>, </span>);
            }
        });
    }
    return links.length > 0 ? <>{links}</> : null;
};

const mapStateToProps = (state) => {
    return {
        wpLink: state.links.wpLink,
    };
};

export default connect(mapStateToProps)(MultiLinks);
