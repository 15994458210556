import { sanitizePageviewRequestTag, getApiDate, formatDate, cleanPayload } from "./common";
import { findReportFromURL } from "../components/config/FindReportFromURL";

export const PageviewPayloadData = (filterData, page, type, reportType, limit, url) => {
    const reportData = findReportFromURL(url, "");

    const payload = {
        organizations: filterData?.organization,
        campaigns: filterData?.campaign,
        orders: filterData?.order,
        site: filterData?.site === "all" ? "" : filterData?.site,
        status: filterData?.campaignStatus === "all" ? "" : filterData?.campaignStatus,
        include_deleted: filterData?.campaignStatus === "all" || filterData?.campaignStatus === "archived",
        topic_tags: {
            included_tags: sanitizePageviewRequestTag(filterData?.selTopicTagsIN),
            excluded_tags: sanitizePageviewRequestTag(filterData?.selTopicTagsEX),
        },
        organization_tags: {
            included_tags: sanitizePageviewRequestTag(filterData?.selOrgTagsIN),
            excluded_tags: sanitizePageviewRequestTag(filterData?.selOrgTagsEX),
        },
        placement_tags: {
            included_tags: sanitizePageviewRequestTag(filterData?.selPlacementTagsIN),
            excluded_tags: sanitizePageviewRequestTag(filterData?.selPlacementTagsEX),
        },
        page_title: filterData?.selPageTitle,
        utm_source: filterData?.selUrlSource,
        utm_medium: filterData?.selUrlMedium,
        utm_campaign: filterData?.selUrlCampaign,
        utm_term: filterData?.selUrlTerm,
        utm_content: filterData?.selUrlContent,
        pageviews_by_organizations: filterData?.selPageViewsByOrg,
        author: filterData?.selAuthor,
        country: filterData?.selCountry,
        start_date: getApiDate(filterData?.startDate).concat(" 00:00:00"),
        end_date: getApiDate(filterData?.endDate).concat(" 23:59:59"),  
        previous_start_date: filterData.selCustomRangeCheckBox ? formatDate(filterData?.previousStartDate, " 00:00:00") : "",
        previous_end_date: filterData.selCustomRangeCheckBox ? formatDate(filterData?.previousEndDate, " 23:59:59") : "",
        compare:filterData?.selCutomRangeChkBox,
        applied_date_filter: filterData?.appliedDate,
        order: "DESC",
        order_by: "page_views_count",
        limit: limit,
        page: page,
        export: type,
        exclude_qa: filterData?.excludeQACampaign,
        exclude_internal: filterData?.excludeInternalCampaign,
        ...(filterData?.personaToggle && {
            job_title: {
                included_job_title: filterData?.selJobTitlesIN,
                excluded_job_title: filterData?.selJobTitlesEX,
            },
            seniorities: {
                included_tags: filterData?.selSenioritiesIN,
                excluded_tags: filterData?.selSenioritiesEX,
            },
            job_functions: {
                included_tags: filterData?.selJobFunctionsIN,
                excluded_tags: filterData?.selJobFunctionsEX,
            },
        }),
        email: filterData?.email,
        engaged_members:filterData?.selEnagedMember,
        organization_types: filterData && filterData.selOrgType ? (filterData.selOrgType.map( a => a.charAt(0).toUpperCase() + a.substr(1))) : [],
        organization_sizes: filterData?.selOrgSize,
        organization_revenues: filterData?.selOrgRevenue,
        sector:filterData?.selSector,
        industry: filterData?.selIndustry,
        sub_industry: filterData?.selSubIndustry,
        industry_group: filterData?.selIndustryGroup,
        wp_page_start_date: filterData?.isPageParameter ? formatDate(filterData.wp_startDate, " 00:00:00") : "",
        wp_page_end_date: filterData?.isPageParameter ? formatDate(filterData?.wp_endDate, " 23:59:59") : "",
        primary_topic_ids: {
            tag_ids: filterData?.selPrimaryTopic
        },
        type: reportType,
        delivery_state: filterData?.scDeliverdFilterState && filterData?.scDeliverdFilterState.trim(),
        traffic_source: filterData?.selTrafficSource === "All" ? "" : filterData?.selTrafficSource,
        pace: filterData?.pacingFilter === "all" ? "" : filterData.pacingFilter,
        exclude_internal_events: filterData?.excludeInternalEvents && filterData?.excludeInternalEvents,
        exclude_anonymous_organization: filterData?.exAnonymousOrgs && filterData?.exAnonymousOrgs,
        exclude_anonymous_member: filterData?.exAnonymousMember && filterData?.exAnonymousMember,
        traffic_filter: filterData.trafficType === "all" ? "" : filterData.trafficType,
        page_type: filterData?.pageviewType === "all" ? "" : filterData?.pageviewType,
        report_name: reportData ? reportData.report : "",
        report_table_name: reportData ? reportData.report_table_name : "",
        exclude_competitor_orgs: filterData?.competitorFiltering,
    }

    return cleanPayload(payload);
}

export const addExtraContentPerformanceData = (filterData) => {
    const payload = {
        days_of_week: filterData?.selDayOfWeeks,
        pageview_ranges: filterData?.selRange,
        time_of_day: filterData?.selTimeOfDay,
    }
 
    return cleanPayload(payload);
}