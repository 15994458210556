import React from "react";
import { Table } from "semantic-ui-react";
import Link from "../../hyperlinks/link";

const GenerateCellContent = (name, uuid, type, cellIndex, className = "") => {
    return (!uuid) ? 
         (<Table.Cell key={cellIndex} className={`${className}`}>{name}</Table.Cell>)
      :
      name ? (
         <Table.Cell key={cellIndex} title={name} className={`blogs_link ${className}`}>
            <Link type={type} urlParam={uuid} refValue={name}></Link>
         </Table.Cell>
      ) : (
       <Table.Cell key={cellIndex} className={`${className}`} ></Table.Cell>
    );
 };

 export default GenerateCellContent;