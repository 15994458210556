import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Loader, Message, Table } from "semantic-ui-react";
import _ from "lodash";

import DateAndFilters from "../DateAndFilters";
import GenericTable from "../../ads/common/GenericTable";
import { diBasic } from "../DIHeaders";
import { columnConfigs } from "../../ads/common/ColumnConfig";
import * as actionTypes from "../../../actions/actionTypes";
import { fetchDecisionInsightData, getTableHeaders, sendTableHeaders } from "../../../actions";
import CSVDownload from "../CSVDownload";
import DynamicHeaderColumnSelector from "../../common/DynamicHeaderColumnSelector";
import { getReportsByElement } from "../../../utilities/common";
import { DECISION_INSIGHTS_BASIC, ROLEBASED_ELEMENT_DECISION_INSIGHTS_BASE } from "../../config/rolebasedReportNames";
import { GENERATE_CSV, VIEW } from "../../config/const";

const Main = (props) => {
    const [isSortIcon, setSortIcon] = useState(false);
    const [selectHeaders, setSelectHeaders] = useState([]);
    const [finalHeaderData, setFinalHeaderData] = useState();

    useEffect(() => {
        props.getTableHeaders(
            `di_basic_table`,
            actionTypes[props.reportActionType].GET_DYNAMIC_TABLE_HEADER,
            props.reportActionType,
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const headerDataVal = props.filterData.getDynamicHeaderData;
        if (!_.isEmpty(headerDataVal)) {
            setSelectHeaders(headerDataVal);
            setFinalHeaderData(headerDataVal);
        } else if (headerDataVal === undefined || _.size(headerDataVal) === 0) {
            setSelectHeaders(diBasic);
            setFinalHeaderData(diBasic);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filterData.getDynamicHeaderData]);

    const clickOnSortHeader = (e, extraData, tableData) => {
        if (extraData.checked) {
            setSelectHeaders([...selectHeaders, { headerName: tableData.headerName, headerKey: tableData.headerKey }]);
        } else {
            const removeData = selectHeaders.filter(
                (hName) => hName.headerKey !== tableData.headerKey
            );
            setSelectHeaders(removeData);
        }
    };

    const applyButtonClick = () => {
        let finalHeaderArray = [];

        diBasic.forEach((mainHeader) => {
            selectHeaders.forEach((selectHeader) => {
                if (mainHeader.headerKey === selectHeader.headerKey) {
                    finalHeaderArray.push(selectHeader);
                }
            });
        });
        setFinalHeaderData(finalHeaderArray);
        props.sendTableHeaders(
            `di_basic_table`,
            finalHeaderArray,
            actionTypes[props.reportActionType].SEND_DYANMIC_TABLE_HEADER,
            props.reportActionType,
        );
        setSortIcon(!isSortIcon);
    };

    const roleReports = getReportsByElement(props?.roleFilterData?.currentUserReportRoleData, props?.roleFilterData?.getAllReports, ROLEBASED_ELEMENT_DECISION_INSIGHTS_BASE);

    return (
        <div>
            <div className="page-name-comp">
                <p className="page-name">Basic</p>
                <p>
                    {props.reportDescription.find(report => report.name === "Decision Insights - Basic")?.description }
                </p>
            </div>
            <DateAndFilters {...props} getActionType={actionTypes[props.reportActionType].GET_DI_EARNED_MEDIA}/>
            {
                roleReports?.some(
                    (report) =>
                        [DECISION_INSIGHTS_BASIC].includes(report.element) &&
                        [VIEW, GENERATE_CSV].includes(report.permission)
                ) &&
                <div className="table_gap">
                    <div className="table-heading-block">
                        <div className="table-name">
                            <p>{`Decision Insights Basic`}</p>
                        </div>
                        {
                            props.filterData.isFilApplied && (roleReports?.some((report) => [DECISION_INSIGHTS_BASIC].includes(report.element) && report.permission === GENERATE_CSV)) &&
                            <CSVDownload
                                props={props}
                                data={props.filterData.diEarnedMediaData}
                                csvField="DIEarnedMediaCSV"
                                filterData={props.filterData}
                                reportActionType={props.reportActionType}
                                apiCallMethod={props.fetchDecisionInsightData}
                            />
                        }
                    </div>
                    {
                        (roleReports?.some((report) => [DECISION_INSIGHTS_BASIC].includes(report.element) && report.permission === VIEW)) &&
                        <React.Fragment>
                            <div style={{ paddingBottom: "3em" }}>
                                <DynamicHeaderColumnSelector
                                    isSortIcon={isSortIcon}
                                    clickOnSortIcon={() => setSortIcon(!isSortIcon)}
                                    TableHeaderData={diBasic}
                                    selectHeaders={selectHeaders}
                                    clickOnSortHeader={clickOnSortHeader}
                                    applyButtonClick={applyButtonClick}
                                />
                            </div>
                            <div className="sortable-table-component">
                                <Table sortable fixed singleLine>
                                    <GenericTable
                                        data={props.filterData.diEarnedMediaData}
                                        headerOrder={finalHeaderData}
                                        columnConfigs={columnConfigs}
                                    />
                                </Table>
                            </div>
                        </React.Fragment>
                    }
                </div>
            }
            {
                props.filterData.isLoadingDIEarnedMedia && roleReports?.some((report) => [DECISION_INSIGHTS_BASIC].includes(report.element) && report.permission === VIEW) &&
                <div className="show-loader">
                    <Loader active inline="centered" />
                </div>
            }
            {
                (props.filterData.isFilApplied && !props.filterData.isLoadingDIEarnedMedia && _.isEmpty(props.filterData.diEarnedMediaData) && roleReports?.some((report) => [DECISION_INSIGHTS_BASIC].includes(report.element) && report.permission === VIEW)) &&
                <Message>No results found.</Message>
            }

            {!props.filterData.isFilApplied && <Message>Please proceed with applying filter.</Message>}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userEmail: state.authorized.userEmail,
        reportDescription: state.allFilterReportData.reportDescription,
        roleFilterData: state.allFilterReportData,
    };
};

export default connect(mapStateToProps, {
    fetchDecisionInsightData,
    getTableHeaders,
    sendTableHeaders,
})(Main);