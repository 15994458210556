import _ from "lodash";
const genericFilterReducer = (initialData, actionTypes, id, picker) => (state = initialData, action) => {
    let fetchData = { ...initialData }; // Clone the initialData object
    switch (action.type) {
        case actionTypes.FETCH:
            const combinedArray = [...(state[action.reportType][picker]), ...action.payload];

            // Mark selected items as disabled (mainly used in include and exclude functionality)
            if (_.size(action.selected) > 0) {
                action.selected.forEach(id => {
                    combinedArray.forEach(tag => {
                        const matchTagsValue = tag?.id + "|" + tag?.domain
                        if (tag.id === id || matchTagsValue === id) {
                            tag.disabled = true;
                        }
                    });
                });
            }

            const uniqueArray = _.uniqBy(combinedArray, id);
            const updatedData = {
                ...initialData,
                [action.reportType]: {
                    ...initialData[action.reportType],
                    [picker]: [...uniqueArray],
                }
            };
            
            return updatedData;
        case actionTypes.ADD:
            const st = [...state[action.reportType][picker]];
            fetchData[action.reportType] = {
                ...initialData[action.reportType],
                [picker]: _.uniqBy(
                    st.filter(item => _.includes(action.payload, item[id])),
                    id
                )
            }
            return fetchData;
        case actionTypes.CLEARSEARCH:
            return {
                ...state,
                [action.reportType]: {
                    ...initialData[action.reportType],
                    // [picker]: [...initialData[action.reportType][picker]] // keeping this old code for future reference
                    [picker]: []
                }
            };
        case actionTypes.SELECTINITIAL:
            return {
                ...state,
                [action.reportType]: {
                    ...initialData[action.reportType],
                    [picker]: [...action.data]
                }
            };
        case actionTypes.RESET:
            return {
                ...state,
                [action.reportType]: {
                    ...initialData[action.reportType],
                    [picker]: []
                }
            };
        default:
            return state;
    }
};
export default genericFilterReducer;