import React from 'react';
import MultipleSelectDropDown from '../base/multiSelectDropdown';

const CommonMultiSelector = (props) => {
    return (
        <MultipleSelectDropDown
            options={props.optionsValue.map((val, index) => ({
                key: index,
                text: val?.name,
                value: val?.value,
            }))}
            label={props.label}
            placeholder={props.label}
            onSearchChange={props.onSearchChange}
            value={props.value}
            onChange={props.onChange}
            onClick={props.onClick}
        />
    )
};

export default CommonMultiSelector;
