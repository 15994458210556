import React, { useEffect, useState } from "react";
import { Table } from "semantic-ui-react";
import {
  numFormat, titleCase,
} from "../../../utilities/common";
import Link from "../../hyperlinks/link";
import MultiTagLinks from "../../hyperlinks/multiTagLinks";
import MultiLinks from "../../hyperlinks/multiLinks";

const SCPageviewDetailsRow = (props) => {
  const [campaignValues, setCampaignValues] = useState([]);
  const [tableCellData, setTableCellData] = useState([]);

  useEffect(() => {
    setCampaignValues(props.pageviewDetailsRow);
  }, [props.pageviewDetailsRow]);

  useEffect(() => {
    getTableCellRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignValues, props.headerName]);

  const renderTableCell = (value, userUuid) => {
    const isAnonymousOrNA = value === "Anonymous" || value === "N/A";
    const displayValue = isAnonymousOrNA ? "Anonymous" : value;
  
    return (
      !isAnonymousOrNA ?
        <Table.Cell title={value} className="blogs_link space_height">
          <Link type="members" urlParam={userUuid} refValue={value}></Link>
        </Table.Cell> :
        <Table.Cell title={value} className="space_height">{displayValue}</Table.Cell>
    );
  }
  
  const getTableCellRows = () => {

    let tableCell = [];
  
    if(props.headerName.indexOf("ORDER") !== -1) {
      const order = campaignValues?.campaign_data?.[0]?.order || "N/A";
      tableCell.push(
        campaignValues.campaign_data?.length > 0 ? (
          <Table.Cell title={order.name} className="blogs_link space_height">
            <Link type="orders" urlParam={order.uuid} refValue={order.name}></Link>
          </Table.Cell>
        ) : (
          <Table.Cell className="space_height"> {"N/A"}</Table.Cell>
        )
      )
    }

    if (props.headerName.indexOf("CAMPAIGN") !== -1) {
      tableCell.push(
        <>
        {
          campaignValues.campaign_data ?
           <Table.Cell title={campaignValues.campaign_name} className="blogs_link space_height">
            {campaignValues.campaign_data ? (
              <MultiLinks type="campaigns" object={campaignValues.campaign_data}></MultiLinks>
            ) : (
              campaignValues.campaign_name
            )}
          </Table.Cell>
          :
          <Table.Cell>N/A</Table.Cell>
        }
        </>
      );
    }

    if (props.headerName.indexOf("DATE") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={campaignValues.date}>{campaignValues.date}</Table.Cell>
      );
    }

    if (props.headerName.indexOf("TIME") !== -1) {
      tableCell.push(<Table.Cell className="space_height" title={campaignValues.time}>{campaignValues.time}</Table.Cell>);
    }

    if (props.headerName.indexOf("SESSION ID") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={campaignValues.session_uuid}>{campaignValues.session_uuid}</Table.Cell>
      );
    }

    if (props.headerName.indexOf("GROUP ID") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={campaignValues.group_uuid}>{campaignValues.group_uuid}</Table.Cell>
      );
    }

    if (props.headerName.indexOf("ACTION") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={titleCase(campaignValues.action)}>{titleCase(campaignValues.action)}</Table.Cell>
      );
    }

    if (props.headerName.indexOf("IP Address") !== -1) {
      const ipAddress = campaignValues.ip_address && (campaignValues.ip_address).split('/');
      tableCell.push(
        <Table.Cell title={campaignValues.ip_address} className="blogs_link space_height">
        {campaignValues.ip_address ? (
          <Link
            type={"ip_address"}
            urlParam={ipAddress[0]}
            refValue={campaignValues.ip_address}
          ></Link>
        ) : (
          ""
        )}
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("PAGE TITLE") !== -1) {
      tableCell.push(
        <Table.Cell id="space_height-row" title={campaignValues.page_title} className="blogs_link">
          <a
            href={`https://${campaignValues.domain}${campaignValues.page_path}`}
            target="blank"
          >
            {campaignValues.page_title}
          </a>
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("WP ID") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={campaignValues.wp_page_id}>{campaignValues.wp_page_id}</Table.Cell>
      );
    }    

    if (props.headerName.indexOf("PRIMARY TOPIC") !== -1) {
      tableCell.push(
        campaignValues.primary_topic !== "N/A" ? (
          <Table.Cell title={campaignValues.primary_topic} className="blogs_link">
            <MultiTagLinks
              type="primary"
              object={campaignValues.tags_data}
              refValue={campaignValues.primary_topic}
            ></MultiTagLinks>
          </Table.Cell>
        ) : (
          <Table.Cell title={campaignValues.primary_topic} >{campaignValues.primary_topic}</Table.Cell>
        )
      );
    }

    if (props.headerName.indexOf("TOPIC TAGS") !== -1) {
      tableCell.push(
        campaignValues.str_topic_tags !==  "N/A" ? (
          <Table.Cell id="space_height-row" title={campaignValues.str_topic_tags} className="blogs_link">
            <MultiTagLinks
              type="topic"
              object={campaignValues.tags_data}
              refValue={campaignValues.str_topic_tags}
            />
          </Table.Cell>) 
          : 
          <Table.Cell title={campaignValues.str_topic_tags} >{campaignValues.str_topic_tags}</Table.Cell>
      );
    }

    if (props.headerName.indexOf("ORGANIZATION TAGS") !== -1) {
      tableCell.push(
        campaignValues.str_organization_tags !==  "N/A" ? (
        <Table.Cell id="space_height-row" title={campaignValues.str_organization_tags} className="blogs_link">
          <MultiTagLinks
            type="orgs"
            object={campaignValues.tags_data}
            refValue={campaignValues.str_organization_tags}
          ></MultiTagLinks>
        </Table.Cell>
        ) : (
          <Table.Cell title={campaignValues.str_organization_tags} >{campaignValues.str_organization_tags}</Table.Cell> 
        )
      );
    }

    if (props.headerName.indexOf("PLACEMENT TAGS") !== -1) {
      tableCell.push(
        campaignValues.str_placement_tags !==  "N/A" ? (
          <Table.Cell id="space_height-row" title={campaignValues.str_placement_tags} className="blogs_link">
            <MultiTagLinks
              type="placement"
              object={campaignValues.tags_data}
              refValue={campaignValues.str_placement_tags}
            ></MultiTagLinks>
          </Table.Cell>
          ) : (
          <Table.Cell title={campaignValues.str_placement_tags}>{campaignValues.str_placement_tags}</Table.Cell> 
        )
      );
    }

    if (props.headerName.indexOf("ORGANIZATION") !== -1) {
      tableCell.push(
        campaignValues.organization_uuid ? (
          <Table.Cell id="space_height-row"
            title={campaignValues.organization}
            className="blogs_link"
          >
            <Link
              type="organizations"
              urlParam={campaignValues.organization_uuid}
              refValue={campaignValues.organization}
            ></Link>
          </Table.Cell>
        ) : (
          <Table.Cell id="space_height-row"
            title={campaignValues.organization}
            className="space_height"
          >
            {campaignValues.organization}
          </Table.Cell>
        )
      );
    }

    if (props.headerName.indexOf("ORGANIZATION TYPE") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={campaignValues.organization_type}>
          {campaignValues.organization_type}
        </Table.Cell>
      );
    }
    
    if (props.headerName.indexOf("ORGANIZATION REVENUE") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={campaignValues.revenue}>
          {campaignValues.revenue}
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("ORGANIZATION SIZE") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" textAlign="right" title={numFormat(campaignValues.size)}>
          {numFormat(campaignValues.size)}
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("SECTOR") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={campaignValues.sector}>
          {campaignValues.sector}
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("INDUSTRY GROUP") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={campaignValues.industry_group}>
          {campaignValues.industry_group}
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("INDUSTRY") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={campaignValues.industry}>
          {campaignValues.industry}
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("SUB INDUSTRY") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={campaignValues.sub_industry}>
          {campaignValues.sub_industry}
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("FIRST NAME") !== -1) {
      tableCell.push(renderTableCell(campaignValues.first_name, campaignValues.user_uuid));
    }

    if (props.headerName.indexOf("LAST NAME") !== -1) {
      tableCell.push(renderTableCell(campaignValues.last_name, campaignValues.user_uuid));
    }

    if (props.headerName.indexOf("MEMBER SENIORITY") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={campaignValues.senority}>
          {campaignValues.senority}
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("JOB FUNCTION") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={campaignValues.job_function}>
          {campaignValues.job_function}
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("JOB TITLE") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={campaignValues.title}>
          {campaignValues.title}
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("CITY") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={campaignValues.city}>{campaignValues.city}</Table.Cell>
      );
    }

    if (props.headerName.indexOf("STATE/PROVINCE") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={campaignValues.state}>{campaignValues.state}</Table.Cell>
      );
    }

    if (props.headerName.indexOf("COUNTRY") !== -1) {
      tableCell.push(<Table.Cell className="space_height" title={campaignValues.member_country}>{campaignValues.member_country}</Table.Cell>);
    }

    if(props.headerName.indexOf("POSTAL CODE/ZIP") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={campaignValues.postal_code}>{campaignValues.postal_code}</Table.Cell>
      );
    }

    if(props.headerName.indexOf("TIMEZONE") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={campaignValues.member_timezone}>{campaignValues.member_timezone}</Table.Cell>
      );
    }

    if (props.headerName.indexOf("TRAFFIC SOURCE") !== -1) {
      tableCell.push(<Table.Cell className="space_height" title={campaignValues.traffic_source}>{campaignValues.traffic_source}</Table.Cell>);
    }

    if (props.headerName.indexOf("UTM SOURCE") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={campaignValues.source}>
          {campaignValues.source}
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("UTM MEDIUM") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={campaignValues.utm_medium}>
          {campaignValues.utm_medium}
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("UTM CAMPAIGN") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={campaignValues.utm_campaign}>
          {campaignValues.utm_campaign}
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("UTM TERM") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={campaignValues.utm_term}>
          {campaignValues.utm_term}
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("UTM CONTENT") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={campaignValues.utm_content}>
          {campaignValues.utm_content}
        </Table.Cell>
      );
    }

    
    setTableCellData(tableCell);
  };
  return tableCellData;
};

export default SCPageviewDetailsRow;
