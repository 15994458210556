import React from "react";
import { Table } from "semantic-ui-react";

const CSMComponent = ({ data }) => {
    return (
        <Table.Cell className="space_height" title={data?.client_success_manager?.name}>{data?.client_success_manager?.name}</Table.Cell>
    );
};

export default CSMComponent;
