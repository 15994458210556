import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import DateComponent from "../CommonFilter/DateComponent";
import * as actionTypes from "../../actions/actionTypes";
import { selectValue, clearSearchForAllReport, fetchDecisionInsightData, diPopulateFilterAPI } from "../../actions";
// import { url } from "../../api/urlHelper";
import { Form } from "semantic-ui-react";
import OrgSection from "../common/FilterSection/OrgSection";
import OrderMultiSelect from "../common/FilterSection/OrderMultiSelect";
import CampaignSelection from "../common/FilterSection/CampaignSelection";
import Site from "../common/site";
import { url } from "../../api/urlHelper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EXCLUDE_ANONYMOUS_USERS, EXCLUDE_INTERNAL_EVENTS, SHOW_COMPETITOR, } from "../config/const";
import Toggle from "../base/toggle";
import AccountActivityTrend from "../common/AccountActivityTrend";
import AccountActivityLevel from "../common/AccountActivityLevel";
import FirmographicsFilterComponent from "../ads/common/FirmographicsFilterComponent";
import CompanySelection from "../common/FilterSection/CompanySelection";
import AccountIntrestedByKeywordSelection from "../common/FilterSection/AccountIntrestedByKeywordSelection";
import ParentCompanySelection from "../common/FilterSection/ParentCompanySelection";
import Segments from "../common/FilterSection/Segements";

const DateAndFilter = (props) => {
    const [clear, setClear] = useState(false);
    const [filterPopup, setFilterPopup] = useState("filter-popup-close");
    const [filterSelectFocus, setFilterSelectFocus] = useState("filter-icon");
    const [filterIconColor, setFilterIconColor] = useState("material-icons grey");
    const [checkSelectState, setCheckSelectState] = useState(false);

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    const applyFilters = (startDate, endDate, appDate, isCustomCheckbox) => {
        if(props.filterData.organizationSingleSelect === "") {
            toast.error("Please select an Account")
        } else {
            setCheckSelectState(isCustomCheckbox);
            props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].APPLY_FILTERS, reportType: props.reportActionType });
            let tempAdFilterData = props.filterData;
            tempAdFilterData.startDate = startDate;
            tempAdFilterData.endDate = endDate;
            tempAdFilterData.prevStartDate = "";
            tempAdFilterData.prevEndDate = "";
            tempAdFilterData.appliedDate = appDate;
            tempAdFilterData.selCustomRangeCheckBox = isCustomCheckbox;
            props.diPopulateFilterAPI(tempAdFilterData);
            props.fetchDecisionInsightData(
                url.decsionInsightAPI, 
                tempAdFilterData, 
                1, 
                "", 
                actionTypes[props.reportActionType][props.getActionType], 
                "", 
                props.reportName, 
                props.reportActionType
            );
            setFilterPopup("filter-popup-close");
            setFilterSelectFocus("filter-icon");
            setFilterIconColor("material-icons grey");
        }
    }

    const clearFilters = () => {
        setClear(true);
        setTimeout(() => {
            setClear(false);
        }, 1500);
        props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].CLEARCOMMONFILTER, reportType: props.reportActionType });
    }

    return (
        <div>
            <DateComponent {...props} applyFilters={applyFilters} isClear={clear} isComparePeriod={false}/>
            <div className="popup-component">
                <div
                    className={filterSelectFocus}
                    style={{ marginTop: "-50px" }}
                    onClick={() => {
                        setFilterSelectFocus("filter-icon-selected");
                        setFilterPopup("filter-popup-open");
                        setFilterIconColor("material-icons orange");
                    }}
                >
                    <i className={filterIconColor}>filter_list</i>
                </div>
                <div className={filterPopup}>
                    <div className="filter-header">
                        <div className="filter-text"><p>Filters</p></div>
                        <div
                            className="filter-close-icon"
                            onClick={() => {
                                setFilterSelectFocus("filter-icon");
                                setFilterPopup("filter-popup-close");
                            }}
                        >
                            <i className="material-icons grey" onClick={() => setFilterIconColor("material-icons grey")}>clear</i>
                        </div>
                    </div>
                    {/* filter section */}
                    <Form>
                        <div className="ad-search-filter">
                            <Form.Group widths="equal">
                                <OrgSection {...props} singleSelect={true}/>
                                <OrderMultiSelect {...props} />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <CampaignSelection {...props} singleSelectForOrg={true}/>
                                <Site
                                    value={props.filterData.site}
                                    onChange={(e, { value }) => dispatchDynamicSelectAction(props, "site", value)}
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <AccountActivityTrend
                                    value={props.filterData.accountActivityTrend}
                                    onChange={(e, { value }) => dispatchDynamicSelectAction(props, "accountActivityTrend", value)}
                                />
                                <AccountActivityLevel
                                    value={props.filterData.accountActivityLevel} 
                                    onChange={(e, { value }) => dispatchDynamicSelectAction(props, "accountActivityLevel", value)}
                                />
                            </Form.Group>
                            <Form.Group widths="equal" style={{ "marginTop": "13px" }}>
                                <CompanySelection {...props} />
                                <AccountIntrestedByKeywordSelection {...props} />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <ParentCompanySelection {...props} />
                                <Segments {...props} /> 
                            </Form.Group>
                               
                            <div style={{ "marginTop": "2em" }}><FirmographicsFilterComponent {...props} isApplicable={false} isRevenueVisible={false}/></div>
                            <Form.Group widths="equal" style={{ marginTop: '2em'}}>
                                <Toggle 
                                    label={EXCLUDE_INTERNAL_EVENTS}
                                    onChange={() => { dispatchDynamicSelectAction(props,"isExcludeInternalEvents",!props.filterData.isExcludeInternalEvents); }}
                                    checked={props.filterData.isExcludeInternalEvents}
                                />
                                <Toggle 
                                    label={SHOW_COMPETITOR}
                                    style={{ marginLeft: '1em' }}
                                    onChange={() => { dispatchDynamicSelectAction(props,"showCompetitors",!props.filterData.showCompetitors); }}
                                    checked={props.filterData.showCompetitors}
                                />
                            </Form.Group>
                            <Form.Group widths="equal" style={{ marginTop: '2em', marginBottom: '2em'}}>
                                <Toggle 
                                    label={EXCLUDE_ANONYMOUS_USERS}
                                    onChange={() => { dispatchDynamicSelectAction(props,"excludeAnonymousUsers",!props.filterData.excludeAnonymousUsers); }}
                                    checked={props.filterData.excludeAnonymousUsers}
                                />
                            </Form.Group>
                        </div>
                    </Form>

                    <div className="filter-buttons">
                        <button className="cancel-date-button extra-space" onClick={clearFilters}>Clear Filters</button>
                        <button
                            type="submit"
                            className="apply-filter-button"
                            onClick={() => {
                                applyFilters(
                                    props.filterData.startDate,
                                    props.filterData.endDate,
                                    props.filterData.appliedDate,
                                    checkSelectState
                                );
                            }}
                        >
                            Apply Filters
                        </button>
                    </div>
                </div>
            </div>
            <ToastContainer autoClose={3000}/>
        </div>
    );
};

export default compose(connect(null, {
    selectValue,
    clearSearchForAllReport,
    fetchDecisionInsightData,
    diPopulateFilterAPI,
}))(DateAndFilter);