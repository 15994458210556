import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import OrganizationPicker from "../organization";
import { ORGANIZATION } from "../../config/const";
import * as actionTypes from "../../../actions/actionTypes";
import { fetchOrganizations, selectValue, addSelectedValue, fetchCampaigns, clearSearchForAllReport } from "../../../actions";
import _ from "lodash";
import SingleSelectDropDown from "../../base/singleSelectDropDown";

const OrgSection = (props) => {

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    const searchOrg = (e) => {
        if (e.target.value.length > 1) {
            props.fetchOrganizations(
                props.filterData.excludeQACampaign,
                e.target.value,
                actionTypes[props.reportActionType].FETCH_ORGANIZATIONS,
                props.commonSelectData.orgs,
                undefined,
                props.reportActionType
            );
        }
    };

    const onSelectOrg = (e, { value }) => {
        if(props.singleSelect) {
            dispatchDynamicSelectAction(props, "organizationSingleSelect", value);
        } else {
            dispatchDynamicSelectAction(props, ORGANIZATION, value);
        }
        
        props.addSelectedValue({ dispatchType: actionTypes[props.reportActionType].ADD_ORGANIZATIONS, reportType: props.reportActionType, value });
        if (!_.isEmpty(props.filterData.campaign)) {
            props.fetchCampaigns(
                "",
                props.filterData.site,
                props.filterData.selTagsIN,
                props.filterData.selTagsEX,
                props.filterData.excludeQACampaign,
                "",
                props.singleSelect ? [value] : value,
                props.filterData.campaignStatus,
                "start_date",
                actionTypes[props.reportActionType].FETCH_CAMPAIGNS,
                props.filterData.campaign,
                true,
                actionTypes[props.reportActionType].RESET_CAMPAIGNS,
                props.reportActionType,
            );
        }
    };

    const onClickOrg = () => {
        props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].CLEAR_ORGANIZATIONS, reportType: props.reportActionType });
    };

    return (
        props.singleSelect ?
        <SingleSelectDropDown
            className="no-blink"
            clearable
            options={props?.commonSelectData?.orgs?.map((org, index) => ({
                key: index,
                text: org.name,
                value: org.uuid,
                className: org && org.deleted_at ? 'deleted' : ''
            }))}
            onSearchChange={searchOrg}
            onChange={onSelectOrg}
            onClick={onClickOrg}
            value={props.filterData.organizationSingleSelect}
            label='Account'
            placeholder='Account'
        />
        :
        <OrganizationPicker
            onSearchChange={searchOrg}
            value={props.filterData.organization}
            onChange={onSelectOrg}
            onClick={onClickOrg}
            organizations={props.commonSelectData.orgs}
        />
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        commonSelectData: state.allReportCommonReducer[ownProps.reportActionType],
    };
};

export default compose(connect(mapStateToProps, {
    fetchOrganizations,
    selectValue,
    addSelectedValue,
    fetchCampaigns,
    clearSearchForAllReport
}))(OrgSection);