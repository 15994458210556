import React, { useState, useEffect } from 'react';
import { Message } from 'semantic-ui-react';
// import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { appLogout, removeErrorCode } from '../actions';
import { getCookie } from '../utilities/envCommon';

const HttpErrorView = (props) => {
  const [visible, setVisible] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);

  useEffect(() => {
    const visibleTimeout = setTimeout(() => {
      setVisible(true);
    }, 5000);

    const errorVisibleTimeout = setTimeout(() => {
      setErrorVisible(true);
    }, 15000);

    return () => {
      clearTimeout(visibleTimeout);
      clearTimeout(errorVisibleTimeout);
    };
  }, []);

  useEffect(() => {
    if (props.code === 401 && visible) {
      props.appLogout();
      // Perform redirect or other actions after logout here if needed
    }
  }, [props.code, visible, props]);

  useEffect(() => {
    if (props.code >= 400 && props.code < 600 && errorVisible) {
      props.removeErrorCode();
    }
  }, [props.code, errorVisible, props]);

  const handleDismiss = () => {
    setErrorVisible(true);
  };

  if (props.code === 401) {
    return (
      getCookie('token') !== null &&
      <div className='error-message-block'>
        <Message negative hidden={visible} compact>
          <Message.Header>Your session is expired.</Message.Header>
          <p>You will be redirected to the Login screen.</p>
        </Message>
      </div>
    );
  } else if (props.code >= 400 && props.code < 600) {
    return (
      <div className='error-message-block'>
        <Message
          negative
          hidden={errorVisible}
          compact
          onDismiss={handleDismiss}
        >
          <Message.Header>An error has occurred.</Message.Header>
          <p>Unable to process your request at this moment.</p>
        </Message>
      </div>
    );
  }
  return null; // Added return null for other cases
};

const mapStateToProps = (state) => {
  return {
    loginLink: state.links.loginLink,
  };
};

export default connect(mapStateToProps, { appLogout, removeErrorCode })(HttpErrorView);
