import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";

const LeadValidationMultiDropDown = (props) => {
  const stateOptions = {
    "Received": [
      {
        id: "2",
        value: "Received",
        text: "Received",
      },
    ],
    "In Review": [
      {
        id: "3",
        value: "Invalid - Missing Member Data",
        text: "Invalid - Missing Member Data",
      },
      {
        id: "4",
        value: "Invalid - Missing Org Data",
        text: "Invalid - Missing Org Data",
      },

      {
        id: "5",
        value: "Invalid - Unmatched Organization",
        text: "Invalid - Unmatched Organization",
      },
      {
        id: "6",
        value: "Invalid - Competitor",
        text: "Invalid - Competitor",
      },
      {
        id: "7",
        value: "Invalid - GEO",
        text: "Invalid - GEO",
      },
      {
        id: "45",
        value: "Invalid - Unmatched Firmographic",
        text: "Invalid - Unmatched Firmographic",
      },
      {
        id: "10",
        value: "Invalid - Unmatched Privacy data",
        text: "Invalid - Unmatched Privacy data",
      },
      {
        id: "11",
        value: "Invalid - Unmatched Other",
        text: "Invalid - Unmatched Other",
      },
      { id: "12", value: "Filtered", text: "Filtered" },
      { id: "24", value: "Excluded Firmographic", text: "Excluded Firmographic" },
      { id: "25", value: "Excluded Persona", text: "Excluded Persona" },
    ],
    "Accepted": [
      {
        id: "13",
        value: "Pending Delivery",
        text: "Pending Delivery",
      },
      {
        id: "14",
        value: "Delivered",
        text: "Delivered",
      },
    ],
    "Rejected": [
      {
        id: "16",
        value: "Bounced Email",
        text: "Bounced Email",
      },
      {
        id: "17",
        value: "SDxCentral Manual",
        text: "SDxCentral Manual",
      },
      {
        id: "18",
        value: "Rejected - Client",
        text: "Rejected - Client",
      },
      {
        id: "26",
        value: "Rejected - SDxCentral Excluded Competitors",
        text : "Rejected - SDxCentral Excluded Competitors"
      },
      {
        id: "27",
        value: "Rejected - SDxCentral  Excluded Domains",
        text : "Rejected - SDxCentral  Excluded Domains"
      },
      {
        id: "28",
        value: "Rejected - SDxCentral  Excluded Email",
        text : "Rejected - SDxCentral  Excluded Email"
      },
      {
        id: "29",
        value: "Rejected - SDxCentral Excluded GEO",
        text: "Rejected - SDxCentral Excluded GEO",
      },
      {
        id: "30",
        value: "Rejected - SDxCentral Non-Included GEO",
        text : "Rejected - SDxCentral Non-Included GEO",
      },
      {
        id: "31",
        value: "Rejected - SDxCentral Non-Included Account or Domain",
        text: "Rejected - SDxCentral Non-Included Account or Domain",
      },
      {
        id: "33",
        value: "Rejected - SDxCentral Excluded Vertical Filters",
        text: "Rejected - SDxCentral Excluded Vertical Filters",
      },
      {
        id: "34",
        value: "Rejected - SDxCentral Non-Included Vertical Filters",
        text: "Rejected - SDxCentral Non-Included Vertical Filters",
      },
      {
        id: "35",
        value: "Rejected - SDxCentral Excluded Account Filters",
        text: "Rejected - SDxCentral Excluded Account Filters",
      },
      {
        id: "36",
        value: "Rejected - SDxCentral Non-Included Organization Types",
        text: "Rejected - SDxCentral Non-Included Organization Types",
      },
      {
        id: "37",
        value: "Rejected - SDxCentral Non-Included Organization Sizes",
        text: "Rejected - SDxCentral Non-Included Organization Sizes",
      },
      {
        id: "38",
        value: "Rejected - SDxCentral Non-Included Estimated Revenue",
        text: "Rejected - SDxCentral Non-Included Estimated Revenue",
      },
      {
        id: "39",
        value: "Rejected - SDxCentral Excluded Seniorities",
        text: "Rejected - SDxCentral Excluded Seniorities",
      },
      {
        id: "40",
        value: "Rejected - SDxCentral Excluded Job Functions",
        text: "Rejected - SDxCentral Excluded Job Functions",
      },
      {
        id: "41",
        value: "Rejected - SDxCentral Excluded Job Titles",
        text: "Rejected - SDxCentral Excluded Job Titles",
      },
      {
        id: "42",
        value: "Rejected - SDxCentral Non-Included Seniorities",
        text: "Rejected - SDxCentral Non-Included Seniorities",
      },
      {
        id: "43",
        value: "Rejected - SDxCentral Non-Included Job Functions",
        text: "Rejected - SDxCentral Non-Included Job Functions",
      },
      {
        id: "44",
        value: "Rejected - SDxCentral Non-Included Job Titles",
        text: "Rejected - SDxCentral Non-Included Job Titles",
      },
    ],
  };
  const [options, setOptions] = useState([]);
  useEffect(() => {
    let opts = stateOptions[props.verificationStatus];
    setOptions(opts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.verificationStatus]);

  return (
    <>
      <Dropdown
        show
        floating
        placeholder="Select State"
        onChange={props.onChange}
        value={props.verificationState}
        options={options}
        upward={false}
        scrolling={true}
      />
    </>
  );
};

export default LeadValidationMultiDropDown;
