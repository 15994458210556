import React, { useState } from "react";
import { Table } from "semantic-ui-react";
import _ from "lodash";
import ExpandCollapse from "../../common/ExpandCollapse";
import { SESSION_ENGAGEMENTS, SESSION_ID, SESSION_PAGEVIEWS } from "../../config/TableHeaderNames";
import Pages from "./Pages";
import useStickyColumnsEffect from "../../../utilities/stickeyStyle";

const Session = ({  props: { props }, data, count }) => {
   const {freezeCols, isFreeze} = props
   const [expanded, setExpanded] = useState(false);
   const stickeyClassName = freezeCols >= 0 ? (isFreeze ? "sticky-cols-sticky" : "sticky-cols" ) : ""

   useStickyColumnsEffect(freezeCols)
   return (
      <>
         <Table.Row
            className="li-children-v2"
            onDoubleClick={() => { setExpanded(!expanded) }}
         >
         {
            data && data.pages && !_.isEmpty(data.pages) &&
            <Table.Cell className={stickeyClassName}>
               {
                  <ExpandCollapse data={data.pages} expanded={expanded} setExpanded={setExpanded} marginLeft={"50px"} />
               }
            </Table.Cell>
         }
         {
            props && props.finalHeaderData.map((header, cellIndex) => {
               const freezeClassName = freezeCols >= 0 ? (cellIndex <= freezeCols ? (isFreeze  ? "sticky-cols-sticky" : "sticky-cols") : "" ) : ""
               let cellContent = "";
               if(_.includes([SESSION_ID, SESSION_PAGEVIEWS, SESSION_ENGAGEMENTS], header.headerName)) {
                  const val = data[header.sorted];
                  cellContent =  <Table.Cell 
                                    key={cellIndex}
                                    textAlign={_.includes([SESSION_PAGEVIEWS, SESSION_ENGAGEMENTS], header.headerName) ? "right" : "left"}
                                    className={freezeClassName}
                                 >
                                    {val}
                                 </Table.Cell>
               } else {
                  cellContent = <Table.Cell key={cellIndex} className={freezeClassName}></Table.Cell>
               }
            
               return cellContent;
            })
         }

         </Table.Row>

         {data && data.pages && !_.isEmpty(Object.values(data.pages)) && Object.values(data.pages).map(
            (page, index) => expanded && <Pages props={props} data={page} key={index} freezeCols={freezeCols} isFreeze = {isFreeze}/>
         )}
      </>
   );
};

export default Session;