import React from "react";
import { Table } from "semantic-ui-react";
import { getTimeEng } from "../../../../utilities/common";

const TimeComponent = ({ data }) => {
    return (
        <Table.Cell className="space_height" title={getTimeEng(data.created_at)}>
            {getTimeEng(data.created_at)}
        </Table.Cell>
    );
};

export default TimeComponent;
