import React from "react";
import { connect } from "react-redux";
import AdsPage from "./../adsPage";
import * as constName from "./../../components/config/const";
import { ROLEBASED_ELEMENT_INSTANT_MESSAGE_ADS } from "../../components/config/rolebasedReportNames";
import { getReportsByElement } from "../../utilities/common";

const InstantMessage = (props) => {
    const instantMessageAdsReports = getReportsByElement(props?.roleFilterData?.currentUserReportRoleData, props?.roleFilterData?.getAllReports, ROLEBASED_ELEMENT_INSTANT_MESSAGE_ADS);

    return (
        <AdsPage
            reportActionType={constName.MODULE_INSTANT_MESSAGE}
            reportName={constName.INSTANT_MESSAGE_REPORT_NAME}
            filterData={props.filterData}
            reportDescription={props.reportDescription.find(report => report.name === "Ads - Instant Message")?.description}
            label={constName.INSTANT_MESSAGE_LABEL}
            roleReports={instantMessageAdsReports}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        filterData: state.commonFilterData[constName.MODULE_INSTANT_MESSAGE],
        reportDescription: state.allFilterReportData.reportDescription,
        roleFilterData: state.allFilterReportData,
    };
};

export default connect(mapStateToProps)(InstantMessage);