import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getDateEng, getTimeEng, numFormat, titleCase, removeDuplicateVal } from "../../utilities/common";
import MultiLinks from "../hyperlinks/multiLinks";
import Link from "../hyperlinks/link";
import { Table } from "semantic-ui-react";
import _ from "lodash";

const SidebarDetailRow = (props) => {
  const [e, setE] = useState([]);
  const [tableCellData, setTableCellData] = useState([]);

  useEffect(() => {
    setE(props.sidebarRow);
  }, [props.sidebarRow]);

  useEffect(() => {
    getTableCellRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [e, props.headerName]);

  const renderTableCell = (value, userUuid) => {
    const isAnonymousOrNA = value === "Anonymous" || value === "N/A";
    const displayValue = isAnonymousOrNA ? "Anonymous" : value;
  
    return (
      !isAnonymousOrNA ?
        <Table.Cell title={value} className="blogs_link space_height">
          <Link type="members" urlParam={userUuid} refValue={value}></Link>
        </Table.Cell> :
        <Table.Cell title={value} className="space_height">{displayValue}</Table.Cell>
    );
  }

  const getTableCellRows = () => {
    const tableCell = [];

    if(props.headerName.indexOf("ORDER") !== -1) {
      const order = e?.campaign_data?.[0]?.order || "N/A";
      tableCell.push(
        e.campaign_data?.length > 0 ? (
          <Table.Cell title={order.name} className="blogs_link space_height">
            <Link type="orders" urlParam={order.uuid} refValue={order.name}></Link>
          </Table.Cell>
        ) : (
          <Table.Cell className="space_height"> {"N/A"}</Table.Cell>
        )
      )
    }

    if (props.headerName.indexOf("CAMPAIGN") !== -1) {
      tableCell.push(
        <>
        {
          e.campaign_data ?
           <Table.Cell title={e.campaign_name} className="blogs_link space_height">
            {e.campaign_data ? (
              <MultiLinks type="campaigns" object={e.campaign_data}></MultiLinks>
            ) : (
              e.campaign_name
            )}
          </Table.Cell>
          :
          <Table.Cell>N/A</Table.Cell>
        }
        </>
      );
    }

    if (props.headerName.indexOf("SIDEBAR") !== -1) {
      tableCell.push(
        // <Table.Cell title={e.sidebar_name}>{e.sidebar_name}</Table.Cell>
        <Table.Cell title={e.sidebar_name} className="blogs_link">
						{e.sidebar_name ? (
							<Link
								type={"sidebars"}
								urlParam={e.sidebar_uuid}
								refValue={e.sidebar_name}
							></Link>
						) : (
							""
						)}
					</Table.Cell>
      );
    }

    if (props.headerName.indexOf("DATE") !== -1) {
      tableCell.push(
        <Table.Cell title={getDateEng(e.created_at)} className="space_height">
          {getDateEng(e.created_at)}
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("TIME") !== -1) {
      tableCell.push(
        <Table.Cell title={getTimeEng(e.created_at)} className="space_height">
          {getTimeEng(e.created_at)}
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("SESSION ID") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={e.session_uuid}>{e.session_uuid}</Table.Cell>
      );
    }

    if (props.headerName.indexOf("GROUP ID") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={e.group_uuid}>{e.group_uuid}</Table.Cell>
      );
    }

    if (props.headerName.indexOf("ACTION") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={titleCase(e.action)}>
          {titleCase(e.action)}
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("IP Address") !== -1) {
      const ipAddress = e && e.ip_address && (e.ip_address).split('/');
      tableCell.push(
        e.ip_address ?
        <Table.Cell title={e.ip_address} className="blogs_link space_height">
          {e.ip_address ? (
            <Link
              type={"ip_address"}
              urlParam={ipAddress[0]}
              refValue={e.ip_address}
            ></Link>
          ) : (
            ""
          )}
        </Table.Cell>
        :
        <Table.Cell>N/A</Table.Cell>
      );
    }

    if (props.headerName.indexOf("PAGE TITLE") !== -1) {
      tableCell.push(
        <Table.Cell title={e.page_title} className="blogs_link space_height">
          <a href={`https://${e.domain}${e.page_path}`} target="blank">
            {e.page_title}
          </a>
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("TOPIC TAGS") !== -1) {
      tableCell.push(
        <Table.Cell title={removeDuplicateVal(e.topic_tags)}>{removeDuplicateVal(e.topic_tags)}</Table.Cell>
      );
    }

    if (props.headerName.indexOf("ORGANIZATION TAGS") !== -1) {
      tableCell.push(
        <Table.Cell title={removeDuplicateVal(e.organization_tags)}>{removeDuplicateVal(e.organization_tags)}</Table.Cell>
      );
    }

    if (props.headerName.indexOf("PLACEMENT TAGS") !== -1) {
      tableCell.push(
          <Table.Cell title={removeDuplicateVal(e.placement_tags)}>{removeDuplicateVal(e.placement_tags)}</Table.Cell>
      );
    }

    if (props.headerName.indexOf("ORGANIZATION") !== -1) {
      tableCell.push(
        <>
          {
            e.organization ? 
            <Table.Cell title={e.organization} className="blogs_link space_height">
              {e.organization ? (
                <Link
                  type={"organizations"}
                  urlParam={e.organization_uuid}
                  refValue={e.organization}
                ></Link>
              ) : (
                ""
              )}
            </Table.Cell>
          :
          <Table.Cell>Anonymous</Table.Cell>
        }
        </>
      );
    }

    if (props.headerName.indexOf("ORGANIZATION TYPE") !== -1) {
      tableCell.push(
        <Table.Cell title={e.organization_type}>{e.organization_type}</Table.Cell>
      );
    }

    if (props.headerName.indexOf("ORGANIZATION REVENUE") !== -1) {
      tableCell.push(
        <Table.Cell title={e.estimated_revenue}>{e.estimated_revenue}</Table.Cell>
      );
    }

    if (props.headerName.indexOf("ORGANIZATION SIZE") !== -1) {
      tableCell.push(
        <Table.Cell textAlign="right" title={e.organization_size}>
          {e.organization_size ? numFormat(e.organization_size) : 0 }
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("SECTOR") !== -1) {
      tableCell.push(
        <Table.Cell title={e.organization_sector}>{e.organization_sector}</Table.Cell>
      );
    }

    if (props.headerName.indexOf("INDUSTRY GROUP") !== -1) {
      tableCell.push(
        <Table.Cell title={e.organization_industry_group}>{e.organization_industry_group}</Table.Cell>
      );
    }

    if (props.headerName.indexOf("INDUSTRY") !== -1){
      tableCell.push(
        <Table.Cell title={e.organization_industry}>{e.organization_industry}</Table.Cell>
      );
    }

    if (props.headerName.indexOf("SUB INDUSTRY") !== -1) {
      tableCell.push(
        <Table.Cell title={e.organization_sub_industry}>{e.organization_sub_industry}</Table.Cell>
      );
    }

    if (props.headerName.indexOf("FIRST NAME") !== -1) {
      tableCell.push(renderTableCell(e.first_name, e.user_uuid));
    }

    if (props.headerName.indexOf("LAST NAME") !== -1) {
      tableCell.push(renderTableCell(e.last_name, e.user_uuid));
    }

    if (props.headerName.indexOf("MEMBER SENIORITY") !== -1) {
      tableCell.push(
        <Table.Cell title={e.seniority} className="space_height">{e.seniority}</Table.Cell>
      );
    }

    if (props.headerName.indexOf("JOB FUNCTION") !== -1) {
      tableCell.push(
        <Table.Cell title={e.job_function} className="space_height">{e.job_function}</Table.Cell>
      );
    }

    if (props.headerName.indexOf("JOB TITLE") !== -1) {
      tableCell.push(<Table.Cell title={e.job_title} className="space_height">{e.job_title}</Table.Cell>);
    }

    if (props.headerName.indexOf("CITY") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={e.city}>{e.city}</Table.Cell>
      );
    }

    if (props.headerName.indexOf("STATE/PROVINCE") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={e.state}>{e.state}</Table.Cell>
      );
    }

    if (props.headerName.indexOf("COUNTRY") !== -1) {
      tableCell.push(
          <Table.Cell title={e.member_country} className="space_height">{e.member_country}</Table.Cell>
      );
    }

    if(props.headerName.indexOf("POSTAL CODE/ZIP") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={e.postal_code}>{e.postal_code}</Table.Cell>
      );
    }

    if(props.headerName.indexOf("TIMEZONE") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={e.member_timezone}>{e.member_timezone}</Table.Cell>
      );
    }

    if(props.headerName.indexOf("TRAFFIC SOURCE") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={e.traffic_source}>{e.traffic_source}</Table.Cell>
      );
    }

    if(props.headerName.indexOf("UTM SOURCE") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={e.source}>{e.source}</Table.Cell>
      );
    }

    if(props.headerName.indexOf("UTM MEDIUM") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={e.utm_medium}>{e.utm_medium}</Table.Cell>
      );
    }

    if(props.headerName.indexOf("UTM CAMPAIGN") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={e.utm_campaign}>{e.utm_campaign}</Table.Cell>
      );
    }

    if(props.headerName.indexOf("UTM TERM") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={e.utm_term}>{e.utm_term}</Table.Cell>
      );
    }

    if(props.headerName.indexOf("UTM CONTENT") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={e.utm_content}>{e.utm_content}</Table.Cell>
      );
    }

    if (props.headerName.indexOf("WIDGET TYPE") !== -1) {
      tableCell.push(
        <Table.Cell title={e.widget_type}>
          {e.widget_type}
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("WIDGET TITLE") !== -1) {
      tableCell.push(
        <Table.Cell title={e.widget_title}>
          {e.widget_title}
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("SLOT") !== -1) {
      tableCell.push(
          <Table.Cell title={e.slot}>{e.slot}</Table.Cell>
      );
    }

    if (props.headerName.indexOf("CLIENT LINK") !== -1) {
      tableCell.push(
        e.links && _.size(e.links[0]) > 0 ? (
          <Table.Cell>
            <MultiLinks
              type="sidebar"
              object={e.links[0].links}
            />
          </Table.Cell>
        ) : (
          <Table.Cell>N/A</Table.Cell>
        )
      );
    }

    if (props.headerName.indexOf("LOCATION") !== -1) {
      tableCell.push(
        <Table.Cell title={e.location}>{e.location}</Table.Cell>
      );
    }

    
    setTableCellData(tableCell);
  };

  return tableCellData;
};

const mapStateToProps = (state) => {
  return {
    topicFilterData: state.topicFilterData,
    isFilApplied: state.topicFilterData.isFilApplied,
    filStartDate: state.topicFilterData.startDate,
    filEndDate: state.topicFilterData.endDate,
  };
};

export default connect(mapStateToProps)(SidebarDetailRow);
