import React, { useState, useRef, useEffect } from "react";

import { Form } from "semantic-ui-react";
import DatePicker from "../common/datePicker";
import { compose } from "redux";
import { connect } from "react-redux";
import * as actionTypes from "../../actions/actionTypes";
import { getDisplayDate, getStartEndDates, getYesterdaysDate } from "../../utilities/common";
import { differenceInDays, subDays, subYears } from "date-fns";
import { DateRangePicker } from "react-date-range";
// import OrganizationPicker from "../common/organization";
import _ from "lodash"
// import OrderPicker from "../common/order";
import {
    selEditorialDetailsFilterValue,
    fetchOrganizations,
    fetchCampaigns,
    clearSearchOrgs,
    fetchOrder,
    addOrder,
    clearSearchOrder,
    addOrganization,
    resetOrgsOrCampaigns,
    addCampaigns,
    clearSearchCampaigns,
    fetchPgAuthors,
    clearEditorialDetailsSerchedValue,
    fetchTags,
    selEDFilterValue,
    fetchPgCountry,
    addCountries,
    selEDToggles,
    firmographicOrgsTypeCall,
    SenioritiesList,
    fetchPageTitle,
    fetchUrlParams,
    fetchEngagedMember,
    fetchPrimaryTopic,
    applyEDFilter,
    fetchPageviewTableData,
    fetchEngagedOrg,
    applyIndDetailsDate
} from "../../actions";
// import StatusPicker from "../common/status";
// import CampaignPicker from "../common/campaign";
import AuthorPicker from "../common/author";
import Site from "../common/site";
import TagsPicker from "../common/tags";
import CountryMultiSelectPicker from "../common/countryPicker";
import EngagedOrganization from "../common/engagedOrganization";
import FirmographicFilter from "../common/firmographicFilter";
import PageFirmoGraphic from "../config/FilterFirmogaphicData";
import Toggle from "../base/toggle";
import { url } from "../../api/urlHelper";
import PersonaFilter from "../common/personaFilter";
import FilterDateRange from "./filterDateRange";
import UrlParamsPicker from "../common/urlParams";
import SingleSelectDropDown from "../base/singleSelectDropDown";
import PrimaryTopic from "../common/primaryTopic";
import EngagedMember from "../common/engagedMember";
import TrafficSource from "../common/trafficSource";
import TrafficFilterPicker from "../common/trafficeFilter";
import PageviewType from "../common/PageviewType";
import CustomDatePickerWithCompare from "../common/CustomDatePickerWithCompare";
import SearchFilterIcon from "../common/SearchFilterIcon";
import SelectDatePicker from "../common/SelectDatePicker";

const SearchFilter = (props) => {
  const [datePopup, setDatePopup] = useState("custom-date-popup-close");
  const [checkSelectState, setCheckSelectState] = useState(false);
  const [filterSelectFocus, setFilterSelectFocus] = useState("filter-icon");
  const [filterPopup, setFilterPopup] = useState("filter-popup-close");
  const [filterIconColor, setFilterIconColor] = useState("material-icons grey");
  const [comparePeriod, setComparePeriod] = useState("Previous Period");
  const [orgType, setOrgType] = useState([]);
  const [orgSize, setOrgSize] = useState([]);
  const [orgRevenue, setOrgRevenue] = useState([]);
  const [sector, setSector] = useState([]);
  const [industryGroup, setIndustryGroup] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [subIndustry, setSubIndustry] = useState([]);
  const [senioritiesIN, setSenioritiesIn] = useState([]);
  const [senioritiesEX, setSenioritiesEX] = useState([]);
  const [JobFunctionsIN, setJobFunctionsIN] = useState([]);
  const [JobFunctionsEX, setJobFunctionsEX] = useState([]);
  const [datePopupFilter, setDatePopupFilter] = useState("custom-date-popup-close");
  const tempSelection = useRef({});
  const clearfilterClick = useRef(false);

  //Date range event handling and state management
  const [dateRange, setDateRange] = useState({
    selection: {
      startDate: getYesterdaysDate(),
      endDate: getYesterdaysDate(),
      key: "selection",
    },
  });

  const [dateRangeFilter, setDateRangeFilter] = useState({
    selection: {
      startDate: getYesterdaysDate(),
      endDate: getYesterdaysDate(),
      key: "selection",
      //color:"#e5582b",
    },
  });

  useEffect(() => {
    if (
      document.getElementsByClassName("rdrDateDisplayWrapper")?.length !== 0
    ) {
      document.getElementsByClassName(
        "rdrDateDisplayWrapper"
      )[0].style.display = "none";
    }
  }, [checkSelectState]);

  const selectDateRange = (e, { value }) => {
    props.selEditorialDetailsFilterValue( value, actionTypes.APPLYEDITORIALDETAILSDATE);
    setDatePopup("custom-date-popup-close");
    if (value === "Custom Date Range") {
      setDatePopup("custom-date-popup-open");
    } else {
      let dates = getStartEndDates(value);
      props.selEditorialDetailsFilterValue(
        dates.startDate,
        actionTypes.SELEDITORIALDETAILSSTARTDATE
      );
      props.selEditorialDetailsFilterValue(
        dates.endDate,
        actionTypes.SELEDITORIALDETAILSENDDATE
      );
      cancelDate();
      if(props.isFilApplied) {
        applyFilters(dates.startDate, dates.endDate, false);
      }
    }
  };

  const cancelDate = () => {
    setCheckSelectState(false);
    setDateRange({
      selection: {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    });
    setDatePopup("custom-date-popup-close");
  };

  const cutomDate = (startDate, endDate) => {
    return getDisplayDate(startDate) + " - " + getDisplayDate(endDate);
  };

  const previousPeriodData = (startDate, endDate, comparePeriod) => {
    props.selEditorialDetailsFilterValue(
      checkSelectState,
      actionTypes.SELEDITORIALDETAILSCUSTOMRANGE
    );
    let differenceinDays = differenceInDays(endDate, startDate);
    let prevEndDate = subDays(startDate, 1);
    let prevStartDate = subDays(new Date(prevEndDate), differenceinDays);
    if (comparePeriod === "Previous Year") {
      prevStartDate = subYears(new Date(startDate), 1);
      prevEndDate = subYears(new Date(endDate), 1);
    }
    setDateRange({
      selection1: {
        startDate: startDate,
        endDate: endDate,
        key: "selection1",
        color: "#e5582b",
      },
      selection2: getCompareState(prevStartDate, prevEndDate),
    });
    return cutomDate(prevStartDate, prevEndDate);
  };

  const getCompareState = (prevStartDate, prevEndDate) => {
    return {
      startDate: prevStartDate,
      endDate: prevEndDate,
      key: "selection2",
      color: "#CC167F",
    };
  };

  const getClearDate = () => {
    setCheckSelectState(false);
    let sel = dateRange.selection1;
    sel.key = "selection";
    setDateRange({
      selection: sel,
    });
  };

  const applyDate = () => {
    const start_date = dateRange.hasOwnProperty("selection") ? tempSelection.current.startDate : dateRange?.selection1?.startDate;
    const end_date = dateRange.hasOwnProperty("selection") ? tempSelection.current.endDate : dateRange?.selection1?.endDate;
    props.editorialDetailsFilterData.selCutomRangeChkBox = checkSelectState;
    if (dateRange.hasOwnProperty("selection")) {
      tempSelection.current = dateRange.selection;
    } else if (dateRange.hasOwnProperty("selection1")) {
      tempSelection.current = dateRange.selection1;
    }
    if (dateRange.hasOwnProperty("selection")) {
      props.selEditorialDetailsFilterValue(
        getDisplayDate(tempSelection.current?.startDate),
        actionTypes.SELEDITORIALDETAILSSTARTDATE
      );
      props.selEditorialDetailsFilterValue(
        getDisplayDate(tempSelection.current?.endDate),
        actionTypes.SELEDITORIALDETAILSENDDATE
      );
    } else if (dateRange.hasOwnProperty("selection1")) {
      props.selEditorialDetailsFilterValue(
        getDisplayDate(dateRange?.selection1?.startDate),
        actionTypes.SELEDITORIALDETAILSSTARTDATE
      );
      props.selEditorialDetailsFilterValue(
        getDisplayDate(dateRange?.selection1?.endDate),
        actionTypes.SELEDITORIALDETAILSENDDATE
      );
      props.selEditorialDetailsFilterValue(
        getDisplayDate(dateRange?.selection2?.startDate),
        actionTypes.SELEDITORIALDETAILSPREVSTARTDATE
      );
      props.selEditorialDetailsFilterValue(
        getDisplayDate(dateRange?.selection2?.endDate),
        actionTypes.SELEDITORIALDETAILSPREVENDDATE
      );
    }
    props.editorialDetailsFilterData.previousStartDate = getDisplayDate(dateRange?.selection2?.startDate);
    props.editorialDetailsFilterData.previousEndDate = getDisplayDate(dateRange?.selection2?.endDate);
    setDatePopup("custom-date-popup-close");

    if (props.isFilApplied) {
      props.selEditorialDetailsFilterValue( getDisplayDate(start_date), actionTypes.SELEDITORIALDETAILSSTARTDATE );
      props.selEditorialDetailsFilterValue( getDisplayDate(end_date), actionTypes.SELEDITORIALDETAILSENDDATE );
      props.editorialDetailsFilterData.previousStartDate = getDisplayDate( dateRange?.selection2?.startDate );
      props.editorialDetailsFilterData.previousEndDate = getDisplayDate( dateRange?.selection2?.endDate );
      applyFilters( getDisplayDate(tempSelection.current.startDate),  getDisplayDate(tempSelection.current.endDate), checkSelectState );
    }
  };

  // const searchOrg = (e) => {
  //   if (e.target.value.length > 1) {
  //     props.fetchOrganizations(
  //       props.editorialDetailsFilterData.excludeQACampaign,
  //       e.target.value,
  //       actionTypes.FETCHEDITORIALDETAILSORGS,
  //       props.organizations,
  //     );
  //   }
  // };

  // const onClickOrg = () => {
  //   props.clearSearchOrgs(actionTypes.CLEAREDITORIALDETAILSORGS);
  // };

  // const onSelectOrg = (e, { value }) => {
  //   props.selEditorialDetailsFilterValue(
  //     value,
  //     actionTypes.SELEDITORIALDETAILSORGS
  //   );
  //   props.addOrganization(value, actionTypes.ADDEDITORIALDETAILSORGS);
  //   if (!_.isEmpty(props.editorialDetailsFilterData.campaign)) {
  //     props.fetchCampaigns(
  //       "",
  //       props.editorialDetailsFilterData.site,
  //       props.editorialDetailsFilterData.selTagsIN,
  //       props.editorialDetailsFilterData.selTagsEX,
  //       props.editorialDetailsFilterData.excludeQACampaign,
  //       "",
  //       value,
  //       "",
  //       "start_date",
  //       actionTypes.FETCHSELSPONSOREDCONTENTCAMPAIGNS,
  //       props.editorialDetailsFilterData.campaign,
  //       true,
  //       actionTypes.RESETSELSPONSOREDCONTENTCAMPAIGNS
  //     );
  //   }
  // };

  // const searchOrder = (e) => {
  //   if (e.target.value.length > 1) {
  //     props.fetchOrder(
  //       e.target.value,
  //       "name",
  //       actionTypes.FETCHEDITORIALDETAILSORDER
  //     );
  //   }
  // };

  //Order event handlers
  // const onSelectOrder = (e, { value }) => {
  //   props.selEditorialDetailsFilterValue(
  //     value,
  //     actionTypes.SELEDITORIALDETAILSORDER
  //   );
  //   props.addOrder(value, actionTypes.ADDEDITORIALDETAILSORDER);
  // };
  
  // const onClickOrder = () => {
  //   props.clearSearchOrder(actionTypes.CLEAREDITORIALDETAILSORDER);
  // };

  // const onSelectCampaignStatus = (e, { value }) => {
  //   props.selEditorialDetailsFilterValue(
  //     value,
  //     actionTypes.SELEDITORIALDETAILSSTATUS
  //   );
  //   props.resetOrgsOrCampaigns(actionTypes.RESETEDITORIALDETAILSCAMPAIGNS);
  //   props.selEditorialDetailsFilterValue(
  //     [],
  //     actionTypes.SELEDITORIALDETAILSCAMPAIGNS
  //   );
  // };

  // const onSelectCampaigns = (e, { value }) => {
  //   props.addCampaigns(value, actionTypes.ADDEDITORIALDETAILSCAMPAIGNS);
  //   props.selEditorialDetailsFilterValue(
  //     value,
  //     actionTypes.SELEDITORIALDETAILSCAMPAIGNS
  //   );
  // };

  // const searchCampaign = (e) => {
  //   if (e.target.value.length > 1) {
  //     props.fetchCampaigns(
  //       "",
  //       props.editorialDetailsFilterData.site,
  //       props.editorialDetailsFilterData.selTagsIN,
  //       props.editorialDetailsFilterData.selTagsEX,
  //       props.editorialDetailsFilterData.excludeQACampaign,
  //       e.target.value,
  //       props.editorialDetailsFilterData.organization,
  //       "",
  //       //'',
  //       "name",
  //       actionTypes.FETCHEDITORIALDETAILSCAMPAIGNS
  //     );
  //   }
  // };

  //Campaign event handling ans state management
  // const onClickCampaign = () => {
  //   if (_.isEmpty(props.editorialDetailsFilterData.organization)) {
  //     props.clearSearchCampaigns(actionTypes.CLEAREDITORIALDETAILSCAMPAIGNS);
  //   } else if (!_.isEmpty(props.editorialDetailsFilterData.organization)) {
  //     props.fetchCampaigns(
  //       "",
  //       props.editorialDetailsFilterData.site,
  //       props.editorialDetailsFilterData.selTagsIN,
  //       props.editorialDetailsFilterData.selTagsEX,
  //       props.editorialDetailsFilterData.excludeQACampaign,
  //       "",
  //       props.editorialDetailsFilterData.organization,
  //       "",
  //       "start_date",
  //       actionTypes.FETCHEDITORIALDETAILSCAMPAIGNS
  //     );
  //   }
  // };

   //Author event handling and state management
  const searchAuthor = (e, { value }) => {
    if (e.target.value.length > 1) {
      props.fetchPgAuthors(e.target.value, actionTypes.FETCHEDITORIALDETAILSAUTHOR);
    } else {
      props.clearEditorialDetailsSerchedValue(actionTypes.CLEAREDITORIALDETAILSAUTHOR);
    }
  };

  const onSelectAuthor = (e, { value }) => {
    props.selEditorialDetailsFilterValue(value, actionTypes.SELEDITORIALDETAILSAUTHOR);
  };

  const onClickAuthor = () => {
    if (_.isEmpty(props.editorialDetailsFilterData.selAuthor)) {
      props.clearEditorialDetailsSerchedValue(actionTypes.CLEAREDITORIALDETAILSAUTHOR);
    }
  };

  //sites 
  const onSelectSite = (e, { value }) => {
    props.selEditorialDetailsFilterValue(value, actionTypes.SELEDITORIALDETAILSSITE);
  };

  const searchTopicTagsIn = (e) => {
    if (e.target.value.length > 1) {
      props.fetchTags(
        e.target.value,
        "topic",
        actionTypes.FETCHEDITORIALDETAILSTOPICTAGSIN,
        props.editorialDetailsFilterData.selTopicTagsEX,
        props.editorialDetailsFilterData.site
      );
    }
  };

  const onSelectTopicTagsIn = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.editorialDetailsFilterData.selTopicTagsEX.indexOf(selectedValue) === -1){
    props.selEditorialDetailsFilterValue(value, actionTypes.APPLYEDITORIALDETAILSTOPICTAGSIN);
    props.selEDFilterValue({ key: "selTopicTagsIN", value });
    }
  };

  const onClickTopicTagIn = () => {
    props.clearEditorialDetailsSerchedValue(actionTypes.CLEAREDITORIALDETAILSTOPICTAGIN);
  };

  const searchTopicTagsEx = (e) => {
    if (e.target.value.length > 1) {
      props.fetchTags(
        e.target.value,
        "topic",
        actionTypes.FETCHEDITORIALDETAILSTOPICTAGSEX,
        props.editorialDetailsFilterData.selTopicTagsIN,
        props.editorialDetailsFilterData.site
      );
    }
  };

  const onSelectTopicTagsEx = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.editorialDetailsFilterData.selTopicTagsIN.indexOf(selectedValue) === -1){
    props.selEditorialDetailsFilterValue(value,actionTypes.APPLYEDITORIALDETAILSTOPICTAGSEX);
    props.selEDFilterValue({ key: "selTopicTagsEX", value });
    }
  };

  const onClickTopicTagEx = () => {
    props.clearEditorialDetailsSerchedValue(actionTypes.CLEAREDITORIALDETAILSTOPICTAGEX);
  };

  const searchPlacementTagsIn = (e) => {
    if (e.target.value.length > 1) {
      props.fetchTags(
        e.target.value,
        "placement",
        actionTypes.FETCHEDITORIALDETAILSPLACEMENTTAGSIN,
        props.editorialDetailsFilterData.selPlacementTagsEX,
        props.editorialDetailsFilterData.site
      );
    }
  };

  const onSelectPlacementTagsIn = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.editorialDetailsFilterData.selPlacementTagsEX.indexOf(selectedValue) === -1){
    props.selEditorialDetailsFilterValue(value, actionTypes.APPLYEDITORIALDETAILSPLACEMENTTAGSIN);
    props.selEDFilterValue({ key: "selPlacementTagsIN", value });
    }
  };

  const onClickPlacementTagIn = () => {
    props.clearEditorialDetailsSerchedValue(actionTypes.CLEAREDITORIALDETAILSPLACEMENTTAGIN);
  };

  const searchPlacementTagsEx = (e) => {
    if (e.target.value.length > 1) {
      props.fetchTags(
        e.target.value,
        "placement",
        actionTypes.FETCHEDITORIALDETAILSPLACEMENTTAGSEX,
        props.editorialDetailsFilterData.selPlacementTagsIN,
        props.editorialDetailsFilterData.site
      );
    }
  };

  const onSelectPlacementTagsEx = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.editorialDetailsFilterData.selPlacementTagsIN.indexOf(selectedValue) === -1){
    props.selEditorialDetailsFilterValue(value, actionTypes.APPLYEDITORIALDETAILSPLACEMENTTAGSEX);
    props.selEDFilterValue({ key: "selPlacementTagsEX", value });
    }
  };

  const onClickPlacementTagEx = () => {
    props.clearEditorialDetailsSerchedValue(actionTypes.CLEAREDITORIALDETAILSPLACEMENTTAGEX);
  };

  const searchOrgTagsIn = (e) => {
    if (e.target.value.length > 1) {
      props.fetchTags(
        e.target.value,
        "organization",
        actionTypes.FETCHEDITORIALDETAILSORGTAGSIN,
        props.editorialDetailsFilterData.selOrgTagsEX,
        props.editorialDetailsFilterData.site
      );
    }
  };

  const onSelectOrgTagsIn = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.editorialDetailsFilterData.selOrgTagsEX.indexOf(selectedValue) === -1){
    props.selEditorialDetailsFilterValue(value, actionTypes.APPLYEDITORIALDETIALSORGTAGSIN);
    props.selEDFilterValue({ key: "selOrgTagsIN", value });
    }
  };

  const onClickOrgTagIn = () => {
    props.clearEditorialDetailsSerchedValue(actionTypes.CLEAREDITORIALDETAILSORGTAGIN);
  };

  const searchOrgTagsEx = (e) => {
    if (e.target.value.length > 1) {
      props.fetchTags(
        e.target.value,
        "organization",
        actionTypes.FETCHEDITORIALDETAILSORGTAGSEX,
        props.editorialDetailsFilterData.selOrgTagsIN,
        props.editorialDetailsFilterData.site
      );
    }
  };

  const onSelectOrgTagsEx = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.editorialDetailsFilterData.selOrgTagsIN.indexOf(selectedValue) === -1){
    props.selEditorialDetailsFilterValue(value, actionTypes.APPLYEDITORIALDETAILSORGTAGSEX);
    props.selEDFilterValue({ key: "selOrgTagsEX", value });
    }
  };

  const onClickOrgTagEx = () => {
    props.clearEditorialDetailsSerchedValue(actionTypes.CLEAREDITORIALDETAILSORGTAGEX);
  };

  const searchCountry = (e) => {
    if (e.target.value.length > 1) {
      props.fetchPgCountry(e.target.value, actionTypes.FETCHEDITORIALDETAILSCOUNTRY);
    }
  };

  const onSelectCountry = (e, { value }) => {
    props.selEditorialDetailsFilterValue(value, actionTypes.SELEDITORIALDETAILSCOUNTRY);
    props.addCountries(value, actionTypes.ADDEDITORIALDETAILSCONTRIES);
  };

  const onClickCountry = () => {
    if (_.isEmpty(props.editorialDetailsFilterData.selCountry)) {
      props.clearEditorialDetailsSerchedValue(actionTypes.CLEAREDITORIALDETAILSCOUNTRY);
    }
  };

  const searchPageViewByOrg = (e, { value }) => {
    if (e.target.value.length > 1) {
      props.fetchEngagedOrg(
        props.editorialDetailsFilterData.excludeQACampaign,
        e.target.value,
        actionTypes.FETCHEDITORIALDETAILSENGORGS,
        true,
        props.editorialDetailsFilterData.organization,
        props.editorialDetailsFilterData.edIsIncludeParent,
        props.editorialDetailsFilterData.edIsIncludeChild,
      );
    }
  };

  const onSelectPageViewByOrg = (e, { value }) => {
    props.selEditorialDetailsFilterValue(value, actionTypes.SELEDITORIALDETAILSENGORGS);
    props.selEditorialDetailsFilterValue(value, actionTypes.ADDEDITORIALDETAILSENGORGS);
  };

  const onClickPageViewByOrgs = () => {
    // if (_.isEmpty(props.editorialDetailsFilterData.organization)) {
    //   props.clearEditorialDetailsSerchedValue(actionTypes.CLEAREDITORIALDETIALSENGORGS);
    // } else 
    if (!_.isEmpty(props.editorialDetailsFilterData.organization) && (props.editorialDetailsFilterData.edIsIncludeParent || props.editorialDetailsFilterData.edIsIncludeChild)) {
      props.fetchEngagedOrg(
        props.editorialDetailsFilterData.excludeQACampaign,
        "",
        actionTypes.FETCHEDITORIALDETAILSENGORGS,
        true,
        props.editorialDetailsFilterData.organization,
        props.editorialDetailsFilterData.edIsIncludeParent,
        props.editorialDetailsFilterData.edIsIncludeChild,
      );
    }
  };

  const firmogaphicFilterData = PageFirmoGraphic(
    props.editorialDetailsFilterData,
    props.firmographicSector,
    props.firmographicIndustryGroup,
    props.firmographicIndustry,
    props.firmographicSubIndustry,
    props.firmographicOrgsType,
    props.firmographicOrgSize,
    props.firmographicOrgsRevenue
  );

  const onSearchChangeFirmographic = (e, answerKey, value) => {
    if (answerKey === "org_type") {
      setOrgType([]);
      if (e.target.value?.length > 0) {
        setOrgType(
          props.orgTypes
            .filter((orgType) => {
              return orgType.value
                .toLowerCase()
                .includes(e.target.value.toLowerCase());
            })
            .map((orgType) => {
              orgType.disabled = false;
              return orgType;
            })
        );
        props.firmographicOrgsTypeCall(orgType, actionTypes.FETCHEDITORIALDETAILSFIRMOGRAPHICORGSTYPE, "");
      }
    } else if (answerKey === "org_size") {
      setOrgSize([]);
      if (e.target.value?.length > 1) {
        setOrgSize(
          props.orgSize
            .filter((orgSize) => {
              return orgSize.value
                .toLowerCase()
                .includes(e.target.value.toLowerCase());
            })
            .map((orgSize) => {
              orgSize.disabled = false;
              return orgSize;
            })
        );
        props.firmographicOrgsTypeCall(
          orgSize,
          actionTypes.FETCHEDITORIALDETAILSORGSSIZE,
          ""
        );
      }
    } else if (answerKey === "org_revenue_range") {
      setOrgRevenue([]);
      if (e.target.value?.length > 1) {
        setOrgRevenue(
          props.orgRevenue
            .filter((orgRevenue) => {
              return orgRevenue.value
                .toLowerCase()
                .includes(e.target.value.toLowerCase());
            })
            .map((orgRevenue) => {
              orgRevenue.disabled = false;
              return orgRevenue;
            })
        );
        props.firmographicOrgsTypeCall(
          orgRevenue,
          actionTypes.FETCHEDITORIALDETAILSORGSREVENUE,
          ""
        );
      }
    } else if (answerKey === "sector") {
      setSector([]);
      if (e.target.value?.length > 1) {
        setSector(
          props.sector
            .filter((sector) => {
              return sector;
            })
            .map((sector) => {
              sector.disabled = false;
              return sector;
            })
        );
        props.firmographicOrgsTypeCall(sector, actionTypes.FETCHEDSECTOR, "");
      }
    } else if (answerKey === "industry_group") {
      setIndustryGroup([]);
      if (e.target.value?.length > 1) {
        setIndustryGroup(
          props.industryGroup
            .filter((industryGroup) => {
              return industryGroup;
            })
            .map((industryGroup) => {
              industryGroup.disabled = false;
              return industryGroup;
            })
        );
        props.firmographicOrgsTypeCall(
          industryGroup,
          actionTypes.FETCHEDINDUSTRYGROUP,
          ""
        );
      }
    } else if (answerKey === "industry") {
      setIndustry([]);
      if (e.target.value?.length > 1) {
        setIndustry(
          props.industry
            .filter((industry) => {
              return industry;
            })
            .map((industry) => {
              industry.disabled = false;
              return industry;
            })
        );
        props.firmographicOrgsTypeCall(
          industry,
          actionTypes.FETCHEDINDUSTRY,
          ""
        );
      }
    } else if (answerKey === "sub_industry") {
      setSubIndustry([]);
      if (e.target.value?.length > 1) {
        setSubIndustry(
          props.subIndustry
            .filter((subIndustry) => {
              return subIndustry;
            })
            .map((subIndustry) => {
              subIndustry.disabled = false;
              return subIndustry;
            })
        );
        props.firmographicOrgsTypeCall(
          subIndustry,
          actionTypes.FETCHEDSUBINDUSTRY,
          ""
        );
      }
    }
  };

  const onSelectFirmographic = (e, answerKey, value) => {
    if (answerKey === "org_type") {
      props.selEditorialDetailsFilterValue(value, actionTypes.APPLYEDITORIALDETAILSORGSTYPE);
      props.selEDFilterValue({ key: "selOrgType", value });
    } else if (answerKey === "org_size") {
      props.selEditorialDetailsFilterValue(value, actionTypes.APPLYEDITORIALDETAILSORGSSIZE);
      props.selEDFilterValue({ key: "selOrgSize", value });
    } else if (answerKey === "org_revenue_range") {
      props.selEditorialDetailsFilterValue(value, actionTypes.APPLYEDITORIALDETAILSREVENUE);
      props.selEDFilterValue({ key: "selOrgRevenue", value });
    } else if (answerKey === "sector") {
      props.selEditorialDetailsFilterValue(value, actionTypes.APPLYEDSECTOR);
      props.selEDFilterValue({ key: "selSector", value });
    } else if (answerKey === "industry_group") {
      props.selEditorialDetailsFilterValue(value, actionTypes.APPLYEDINDUSTRYGROUP);
      props.selEDFilterValue({ key: "selIndustryGroup", value });
    } else if (answerKey === "industry") {
      props.selEditorialDetailsFilterValue(value, actionTypes.APPLYEDINDUSTRY);
      props.selEDFilterValue({ key: "selIndustry", value });
    } else if (answerKey === "sub_industry") {
      props.selEditorialDetailsFilterValue(value, actionTypes.APPLYEDSUBINDUSTRY);
      props.selEDFilterValue({ key: "selSubIndustry", value });
    }
  };

  const onClickFirmographic = (e, answerKey) => {
    if (answerKey === "org_type") {
      props.clearEditorialDetailsSerchedValue(actionTypes.CLEAREDITORIALDETAILSORGSTYPE);
    } else if (answerKey === "org_size") {
      props.clearEditorialDetailsSerchedValue(actionTypes.CLEAREDITORIALDETAILSORGSSIZE);
    } else if (answerKey === "org_revenue_range") {
      props.clearEditorialDetailsSerchedValue(actionTypes.CLEAREDITORIALDETAILSORGSREVENUE);
    } else if (answerKey === "sector") {
      props.clearEditorialDetailsSerchedValue(actionTypes.CLEARSEARCHEDSECTOR);
    } else if (answerKey === "industry_group") {
      props.clearEditorialDetailsSerchedValue(actionTypes.CLEARSEARCHEDINDUSTRYGROUP);
    } else if (answerKey === "industry") {
      props.clearEditorialDetailsSerchedValue(actionTypes.CLEARSEARCHEDINDUSTRY);
    } else if (answerKey === "sub_industry") {
      props.clearEditorialDetailsSerchedValue(actionTypes.CLEARSEARCHEDSUBINDUSTRY);
    }
  };

  const searchSenioritiesIn = (e) => {
    setSenioritiesIn([]);
    if (e.target.value.length > 1) {
      setSenioritiesIn(
        props.seniorities
          .filter((seniority) => {
            return seniority.name
              .toLowerCase()
              .includes(e.target.value.toLowerCase());
          })
          .map((seniority) => {
            seniority.disabled = false;
            return seniority;
          })
      );
      props.SenioritiesList(
        senioritiesIN,
        actionTypes.FETCHEDSENIORITIESIN,
        props.editorialDetailsFilterData.selSenioritiesEX
      );
    }
  };

  const onSelectSenioritiesIn = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.editorialDetailsFilterData.selSenioritiesEX.indexOf(selectedValue) === -1){
      props.selEditorialDetailsFilterValue(value, actionTypes.APPLYEDSENIORITIESIN);
      props.selEDFilterValue({ key: "selSenioritiesIN", value });
    }
  };

  const onClickSenioritiesIn = () => {
    props.clearEditorialDetailsSerchedValue(actionTypes.CLEARSEARCHEDSENIORITIESIN);
  };

  //Ex
  const searchSenioritiesEx = (e) => {
    setSenioritiesEX([]);
    if (e.target.value.length > 1) {
      setSenioritiesEX(
        props.seniorities
          .filter((seniority) => {
            return seniority.name
              .toLowerCase()
              .includes(e.target.value.toLowerCase());
          })
          .map((seniority) => {
            seniority.disabled = false;
            return seniority;
          })
      );
      props.SenioritiesList(
        senioritiesEX,
        actionTypes.FETCHEDSENIORITIESEX,
        props.editorialDetailsFilterData.selSenioritiesIN
      );
    }
  };

  const onSelectSenioritiesEx = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.editorialDetailsFilterData.selSenioritiesIN.indexOf(selectedValue) === -1){
      props.selEditorialDetailsFilterValue(value, actionTypes.APPLYEDSENIORITIESEX);
      props.selEDFilterValue({ key: "selSenioritiesEX", value });
    }
  };

  const onClickSenioritiesEx = () => {
    props.clearEditorialDetailsSerchedValue(actionTypes.CLEARSEARCHEDSENIORITIESEX);
  };

  let personaSeniority = [
    {
      value: props.editorialDetailsFilterData.selSenioritiesIN,
      label: "Seniority (Include)",
      placeholder: "Seniority (Include)",
      options: props.senioritiesIn,
      onClick: onClickSenioritiesIn,
      onChange: onSelectSenioritiesIn,
      onSearchChange: searchSenioritiesIn,
    },
    {
      value: props.editorialDetailsFilterData.selSenioritiesEX,
      label: "Seniority (Exclude)",
      placeholder: "Seniority (Exclude)",
      options: props.senioritiesEx,
      onClick: onClickSenioritiesEx,
      onChange: onSelectSenioritiesEx,
      onSearchChange: searchSenioritiesEx,
    },
  ];

  const searchJobFunctionsIn = (e) => {
    setJobFunctionsIN([]);
    if (e.target.value.length > 1) {
      setJobFunctionsIN(
        props.jobfunctions
          .filter((jobfunction) => {
            return jobfunction.function
              .toLowerCase()
              .includes(e.target.value.toLowerCase());
          })
          .map((jobfunction) => {
            jobfunction.disabled = false;
            return jobfunction;
          })
      );
      props.SenioritiesList(
        JobFunctionsIN,
        actionTypes.FETCHEDJOBFUNCTIONSIN,
        props.editorialDetailsFilterData.selJobFunctionsEX
      );
    }
  };

  const onSelectJobFunctionIn = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.editorialDetailsFilterData.selJobFunctionsEX.indexOf(selectedValue) === -1){
      props.selEditorialDetailsFilterValue(value, actionTypes.APPLYEDJOBFUNCTIONSIN);
      props.selEDFilterValue({ key: "selJobFunctionsIN", value });
    }
  };

  const onClickJobFunctionsIn = () => {
    props.clearEditorialDetailsSerchedValue(actionTypes.CLEARSEARCHEDJOBFUNCTIONSIN);
  };

  const searchJobFunctionsEx = (e) => {
    setJobFunctionsEX([]);
    if (e.target.value.length > 1) {
      setJobFunctionsEX(
        props.jobfunctions
          .filter((jobfunction) => {
            return jobfunction.function
              .toLowerCase()
              .includes(e.target.value.toLowerCase());
          })
          .map((jobfunction) => {
            jobfunction.disabled = false;
            return jobfunction;
          })
      );
      props.SenioritiesList(
        JobFunctionsEX,
        actionTypes.FETCHEDJOBFUNCTIONSEX,
        props.editorialDetailsFilterData.selJobFunctionsIN
      );
    }
  };

  const onSelectJobFunctionsEx = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.editorialDetailsFilterData.selJobFunctionsIN.indexOf(selectedValue) === -1){
      props.selEditorialDetailsFilterValue(value, actionTypes.APPLYEDJOBFUNCTIONSEX);
      props.selEDFilterValue({ key: "selJobFunctionsEX", value });
    }
  };

  const onClickJobFunctionsEx = () => {
    props.clearEditorialDetailsSerchedValue(actionTypes.CLEARSEARCHEDJOBFUNCTIONSEX);
  };

  let personaJobFunction = [
    {
      value: props.editorialDetailsFilterData.selJobFunctionsIN,
      label: "Job Function (Include)",
      placeholder: "Job Function (Include)",
      options: props.JobFunctionsIN,
      tags: props.JobFunctionsIN,
      onClick: onClickJobFunctionsIn,
      onChange: onSelectJobFunctionIn,
      onSearchChange: searchJobFunctionsIn,
    },
    {
      tags: props.JobFunctionsEX,
      value: props.editorialDetailsFilterData.selJobFunctionsEX,
      label: "Job Function (Exclude)",
      placeholder: "Job Function (Exclude)",
      options: props.JobFunctionsEX,
      onClick: onClickJobFunctionsEx,
      onChange: onSelectJobFunctionsEx,
      onSearchChange: searchJobFunctionsEx,
    },
  ];

  const onTypeJobTitleIN = (e, { value }) => {
    props.selEDFilterValue({ key: "selJobTitlesIN", value });
  };

  const onTypeJobTitleEX = (e, { value }) => {
    props.selEDFilterValue({ key: "selJobTitlesEX", value });
  };

  let personaJobTitleIn = [{
      onChange: onTypeJobTitleIN,
      placeholder: "Job Title (Include)",
      value: props.editorialDetailsFilterData.selJobTitlesIN,
  }];

  let personaJobTitleEx = [{
      onChange: onTypeJobTitleEX,
      placeholder: "Job Title (Exclude)",
      value: props.editorialDetailsFilterData.selJobTitlesEX,
  }];

  //Date picker for filter
  const selectDateRangeFilter = (e, { value }) => {
    props.selEditorialDetailsFilterValue(value, actionTypes.APPLYEDDATEFILTER);
    setDatePopupFilter("custom-date-popup-close");
    if (value === "Custom Date Range") {
      setDatePopupFilter("custom-date-popup-open");
    } else {
      let dates = getStartEndDates(value);
      props.selEditorialDetailsFilterValue(getDisplayDate(dates.startDate), actionTypes.SELEDWPSTARTDATE);
      props.selEditorialDetailsFilterValue(getDisplayDate(dates.endDate), actionTypes.SELEDWPENDDATE);
    }
  };
  
  //publish dates event handler
  const cancelDateFilter = () => {
    setDateRangeFilter({
      selection: { startDate: new Date(), endDate: new Date(), key: "selection" }
    });
    setDatePopupFilter("custom-date-popup-close");
  };

  const applyDateFilter = () => {
    props.selEditorialDetailsFilterValue(getDisplayDate(dateRangeFilter.selection.startDate), actionTypes.SELEDWPSTARTDATE);
    props.selEditorialDetailsFilterValue(getDisplayDate(dateRangeFilter.selection.endDate), actionTypes.SELEDWPENDDATE);
    setDatePopupFilter("custom-date-popup-close");
  };

  const searchPageTitle = (e) => {
    if (e.target.value.length > 1) {
      props.fetchPageTitle(e.target.value, actionTypes.FETCHEDTITLE);
    } else {
      props.clearEditorialDetailsSerchedValue(actionTypes.CLEARSEARCHEDPAGETITLE);
    }
  };

  const onSelectPageTitle = (e, { value }) => {
    props.selEDFilterValue({ key: "selPageTitle", value });
 };

 const onClickPageTitle = () => {
  if (_.isEmpty(props.editorialDetailsFilterData.selPageTitle)) {
    props.clearEditorialDetailsSerchedValue(actionTypes.CLEARSEARCHEDPAGETITLE);
  }
};

  const searchUrlSource = (e) => {
    if (e.target.value.length > 1) {
      props.fetchUrlParams(
        e.target.value,
        "source",
        actionTypes.FETCHEDURLSOURCE,
        "",
        "pageview"
      );
    } else {
      props.clearEditorialDetailsSerchedValue(actionTypes.CLEARSEARCHEDURLSOURCE);
    }
  };

  const onSelectUrlSource = (e, { value }) => { 
    props.clearEditorialDetailsSerchedValue(actionTypes.RESETSELEDURLCAMPANDMEDIUM);
    props.clearEditorialDetailsSerchedValue(actionTypes.CLEARSEARCHEDURLCAMPAIGNS);
    props.clearEditorialDetailsSerchedValue(actionTypes.CLEARSEARCHEDURLMEDIUM);
    props.selEDFilterValue({ key: "selUrlSource", value });
  };

  const onClickUrlSource = () => {
    if (_.isEmpty(props.editorialDetailsFilterData.selUrlSource)) {
      props.clearEditorialDetailsSerchedValue(actionTypes.CLEARSEARCHEDURLSOURCE);
    }
  };

  const searchUrlMedium = (e) => {
    if (e.target.value.length > 1) {
      props.fetchUrlParams(
        e.target.value,
        "medium",
        actionTypes.FETCHEDURLMEDIUM,
        props.editorialDetailsFilterData.selUrlSource,
        "pageview"
      );
    } else {
      props.clearEditorialDetailsSerchedValue(actionTypes.CLEARSEARCHEDURLMEDIUM);
    }
  };

  const onSelectUrlMedium = (e, { value }) => {
    props.selEDFilterValue({ key: "selUrlMedium", value });
  };

  const onClickUrlMedium = () => {
    if (_.isEmpty(props.editorialDetailsFilterData.selUrlMedium)) {
      props.clearEditorialDetailsSerchedValue(actionTypes.CLEARSEARCHEDURLMEDIUM);
    }
  };

  const searchUrlCampaigns = (e) => {
    if (e.target.value.length > 1) {
      props.fetchUrlParams(
        e.target.value,
        "campaign",
        actionTypes.FETCHEDURLCAMPAIGNS,
        props.editorialDetailsFilterData.selUrlSource,
        "pageview"
      );
    } else {
      props.clearEditorialDetailsSerchedValue(actionTypes.CLEARSEARCHEDURLCAMPAIGNS);
    }
  };

  const onSelectUrlCampaigns = (e, { value }) => {
    props.selEDFilterValue({ key: "selUrlCampaign", value });
  };

  const onClickUrlCampaign = () => {
    if (_.isEmpty(props.editorialDetailsFilterData.selUrlCampaign)) {
      props.clearEditorialDetailsSerchedValue(actionTypes.CLEARSEARCHEDURLCAMPAIGNS);
    }
  };

  const searchUrlTerm = (e) => {
    if (e.target.value.length > 1) {
      props.fetchUrlParams(
        e.target.value,
        "term",
        actionTypes.FETCHEDURLTERM,
        props.editorialDetailsFilterData.selUrlSource,
        "pageview"
      );
    } else {
      props.clearEditorialDetailsSerchedValue(actionTypes.CLEARSEARCHEDURLTERM);
    }
  };

  const onSelectUrlTerm = (e, { value }) => {
    props.selEDFilterValue({ key: "selUrlTerm", value });
  };

  const onClickUrlTerm = () => {
    if (_.isEmpty(props.editorialDetailsFilterData.selUrlTerm)) {
      props.clearEditorialDetailsSerchedValue(actionTypes.CLEARSEARCHEDURLTERM);
    }
  };

  const searchUrlContent = (e) => {
    if (e.target.value.length > 1) {
      props.fetchUrlParams(
        e.target.value,
        "content",
        actionTypes.FETCHEDURLCONTENT,
        props.editorialDetailsFilterData.selUrlSource,
        "pageview"
      );
    } else {
      props.clearEditorialDetailsSerchedValue(actionTypes.CLEARSEARCHEDURLCONTENT);
    }
  };

  const onSelectUrlContent = (e, { value }) => {
    props.selEDFilterValue({ key: "selUrlContent", value });
  };

  const onClickUrlContent = () => {
    if (_.isEmpty(props.editorialDetailsFilterData.selUrlContent)) {
      props.clearEditorialDetailsSerchedValue(actionTypes.CLEARSEARCHEDURLCONTENT);
    }
  };

  const searchEngagedMember = (e) => {
    if (e.target.value.length > 1) {
      props.fetchEngagedMember(e.target.value, actionTypes.FETCHEDENGMEMBER);
    }
  };

  const onSelectEngagedMember = (e, { value }) => {
    props.selEDFilterValue({ key: "selEnagedMember", value });
    props.selEditorialDetailsFilterValue(value, actionTypes.ADDEDENGMEMBER);
  };

  const onClickEngagedMember = () => {
    if (_.isEmpty(props.editorialDetailsFilterData.selEnagedMember)) {
      props.clearEditorialDetailsSerchedValue(actionTypes.FETCHEDENGMEMBER);
    }
  };

  const searchPrimaryTopic = (e) => {
    if (e.target.value.length > 1) {
      props.fetchPrimaryTopic(e.target.value, actionTypes.FETCHEDPRIMARYTOPIC);
    }
  };

  const onSelectPrimaryTopic = (e, { value }) => {
    props.selEDFilterValue({ key: "selPrimaryTopic", value });
    props.selEditorialDetailsFilterValue(value, actionTypes.ADDEDPRIMARYTOPIC);
  };

  const onClickPrimaryTopic = (e, { value }) => {
    if (_.isEmpty(props.editorialDetailsFilterData.selPrimaryTopic)) {
      props.clearEditorialDetailsSerchedValue(actionTypes.CLEARSEARCHEDPRIMARYTOPIC);
    }
  };

  const onSelectTrafficSource = (e, { value }) => {
    props.selEDFilterValue({ key: "selTrafficSource", value });
  };

  const clearFilters = () => {
    clearfilterClick.current = true;
    if (
      clearfilterClick.current &&
      props.editorialDetailsFilterData.appliedDate === "Custom Date Range"
    ) {
      setDateRange({
        selection: { startDate: new Date(), endDate: new Date(), key: "selection" },
      });
    }
    setCheckSelectState(false);
    props.clearEditorialDetailsSerchedValue(actionTypes.CLEAREDFILTER);
    setDatePopup("custom-date-popup-close");

    const actionTypesToClear = [
      actionTypes.RESETEDITORIALDETAILORGS,
      actionTypes.RESETEDITORIALCAMPAIGNS,
      actionTypes.RESETEDORDER,
      actionTypes.RESETEDTOPICTAGIN,
      actionTypes.RESETEDTOPICTAGEX,
      actionTypes.RESETEDPLACEMENTTAGIN,
      actionTypes.RESETEDPLACEMENTTAGEX,
      actionTypes.RESETEDORGTAGIN,
      actionTypes.RESETEDORGTAGEX,
      actionTypes.RESETEDENGORGS,
      actionTypes.RESETEDCOUNTRY,
      actionTypes.RESETEDORGSTYPE,
      actionTypes.RESETEDORGSSIZE,
      actionTypes.RESETEDORGSREVENUE,
      actionTypes.RESETEDSECTOR,
      actionTypes.RESETEDINDUSTRYGROUP,
      actionTypes.RESETEDINDUSTRY,
      actionTypes.RESETEDSUBINDUSTRY,
      actionTypes.RESETEDSENIORITIESIN,
      actionTypes.RESETEDSENIORITIESEX,
      actionTypes.RESETEDJOBFUNCTIONSIN,
      actionTypes.RESETEDJOBFUNCTIONSEX,
      actionTypes.RESETEDENGMEMBER,
      actionTypes.RESETEDPRIMARYTOPIC,
    ];

    actionTypesToClear.forEach((actionType) => {
      props.clearEditorialDetailsSerchedValue(actionType);
    });

  };

  const applyFilters = (startDate, endDate, isCustomCheckbox) => {
    let tempPgFilterDAta = props.editorialDetailsFilterData;
    tempPgFilterDAta.startDate = startDate;
    tempPgFilterDAta.endDate = endDate;
    tempPgFilterDAta.prevStartDate = "";
    tempPgFilterDAta.prevEndDate = "";
    tempPgFilterDAta.selCutomRangeChkBox = isCustomCheckbox;

    props.selEditorialDetailsFilterValue(startDate, actionTypes.SELEDITORIALDETAILSSTARTDATE);
    props.selEditorialDetailsFilterValue(endDate, actionTypes.SELEDITORIALDETAILSENDDATE);
    props.selEditorialDetailsFilterValue("", actionTypes.SELEDITORIALDETAILSPREVSTARTDATE);
    props.selEditorialDetailsFilterValue("", actionTypes.SELEDITORIALDETAILSPREVENDDATE);

    props.applyEDFilter();
    let resEDetails;
    props.fetchPageviewTableData(
      url.pageviewDetailed,
      tempPgFilterDAta,
      1,
      "",
      actionTypes.EDITORIALDETAILS,
      null,
      null,
      "independent-details",
      resEDetails
    );
    setFilterPopup("filter-popup-close");
    setFilterIconColor("material-icons grey");
    setFilterSelectFocus("filter-icon");
    setDatePopup("custom-date-popup-close");
  };

  // const onChangeIncludeParent = (e) => {
  //   if(props.editorialDetailsFilterData.edIsIncludeChild){
  //     props.selEDToggles(actionTypes.SELEDPARENTTOOGLE);
  //     props.selEDToggles(actionTypes.SELEDCHILDTOOGLE);
  //   } else {
  //     props.selEDToggles(actionTypes.SELEDPARENTTOOGLE);
  //   }
  // }

  // const onChangeIncludeChild = (e) => {
  //   if(props.editorialDetailsFilterData.edIsIncludeParent){
  //     props.selEDToggles(actionTypes.SELEDPARENTTOOGLE);
  //     props.selEDToggles(actionTypes.SELEDCHILDTOOGLE);
  //   } else {
  //     props.selEDToggles(actionTypes.SELEDCHILDTOOGLE);
  //   }
  // }

  const onChangeExcludeOrgsMember = (e, value) => {
    if(value === "orgs"){
      props.selEDToggles(actionTypes.SELEDEXANONYMOUSORGS);
    } else if(value === "member"){
      props.selEDToggles(actionTypes.SELEDEXANONYMOUSMEMBERS);
    }
  }

  const onSelectTrafficFilter = (e, { value }) => {
    props.selEDFilterValue({ key: "trafficType", value });
  }

  const onSelectPageViewType = (e, { value }) => {
    props.selEDFilterValue({ key: "pageviewType", value });
  }

  return (
    <div>
      <div className="date-picker">
        {/* Date picker */}
        <SelectDatePicker
          props={props}
          selectDateRange={selectDateRange}
          data={props.editorialDetailsFilterData}
          applyDate={props.applyIndDetailsDate}
          setDatePopup={setDatePopup}
          dateRange={dateRange}
          checkSelectState={checkSelectState}
        />
        {/* Filter icon */}
        <SearchFilterIcon
          filterSelectFocus={filterSelectFocus}
          setFilterSelectFocus={setFilterSelectFocus}
          setFilterPopup={setFilterPopup}
          setFilterIconColor={setFilterIconColor}
          filterIconColor={filterIconColor}
        />
      </div>
      <div className="popup-component">
        {/* Custom date picker component */}
        <CustomDatePickerWithCompare
          datePopup={datePopup}
          setDatePopup={setDatePopup}
          checkSelectState={checkSelectState}
          cutomDate={cutomDate}
          dateRange={dateRange}
          setCheckSelectState={setCheckSelectState}
          setComparePeriod={setComparePeriod}
          previousPeriodData={previousPeriodData}
          comparePeriod={comparePeriod}
          getClearDate={getClearDate}
          setDateRange={setDateRange}
          cancelDate={cancelDate}
          applyDate={applyDate}
          isComparePeriod={true}
        />
          {/* Search filter popup */}
          <div className={filterPopup} 
          // style={{ overflowX: "scroll", width: "auto", height: "62rem", scrollbarWidth: "thin" }}
          >
            <div className="filter-header">
                <div className="filter-text">
                  <p>Filters</p>
                </div>
                <div className="filter-close-icon"
                  onClick={() => {
                    setFilterSelectFocus("filter-icon");
                    setFilterPopup("filter-popup-close");
                  }}
                >
                  <i className="material-icons grey" onClick={() => setFilterIconColor("material-icons grey")} > clear </i>
                </div>
            </div>
            <Form>
              <div className="pg-search-filter">
                {/* <Form.Group widths="equal">
                  <OrganizationPicker
                    value={props.editorialDetailsFilterData.organization}
                    onChange={onSelectOrg}
                    onSearchChange={searchOrg}
                    onClick={onClickOrg}
                    organizations={props.organizations}
                  />
                  <OrderPicker
                    onSearchChange={searchOrder}
                    value={props.editorialDetailsFilterData.order}
                    onChange={onSelectOrder}
                    onClick={onClickOrder}
                    order={props.order}
                  />
                </Form.Group>
                <Form.Group widths="2">
                  <StatusPicker
                    value={props.editorialDetailsFilterData.campaignStatus}
                    onChange={onSelectCampaignStatus}
                  />
                  <CampaignPicker
                    value={props.editorialDetailsFilterData.campaign}
                    onChange={onSelectCampaigns}
                    onSearchChange={searchCampaign}
                    onClick={onClickCampaign}
                    campaigns={props.campaigns}
                  />
                </Form.Group> */}
                                <Toggle
                  label="Page Parameters"
                  onChange={() => {
                    props.selEDToggles(actionTypes.SELEDPAGEPARAMSTOGGLE);
                  }}
                  checked={props.editorialDetailsFilterData.pageParams}
                />
                {props.editorialDetailsFilterData.pageParams && (
                  <Form.Group widths="equal">
                    <DatePicker
                      value={props.editorialDetailsFilterData.appliedDateFilter}
                      onChange={selectDateRangeFilter}
                      showAllDates={true}
                      openDateRange={(e, { value }) => {
                        if (value === "Custom Date Range") {
                          document.getElementsByClassName(
                            "rdrDateInput"
                          )[2].style.display = "none";
                          document.getElementsByClassName(
                            "rdrDateInput"
                          )[3].style.display = "none";
                          document.getElementsByClassName(
                            "rdrDateDisplayWrapper"
                          )[1].style.display = "none";
                          props.selEditorialDetailsFilterValue(
                            "Custom Date Range",
                            actionTypes.APPLYSCDATEFILTER
                          );
                          setDatePopupFilter("custom-date-popup-open");
                        }
                      }}
                    />
                    <span style={{ width: "25em" }}>
                      <FilterDateRange style={{ paddingBottom: "0px" }} />
                    </span>
                  </Form.Group>
                )}
                {props.editorialDetailsFilterData.pageParams && (
                  <Form>
                    <div className={datePopupFilter}>
                      <div
                        className="date-picker-close-icon"
                        onClick={() => {setDatePopupFilter("custom-date-popup-close") }}
                      >
                        <i className="material-icons grey">clear</i>
                      </div>
                      <div>
                        <DateRangePicker
                          minDate={new Date("2019-12-13")}
                          maxDate={
                            props.editorialDetailsFilterData.endDate
                              ? new Date(props.editorialDetailsFilterData.endDate)
                              : new Date()
                          }
                          onChange={(item) => { setDateRangeFilter({ ...dateRangeFilter, ...item }) }}
                          months={2}
                          moveRangeOnFirstSelection={false}
                          direction="horizontal"
                          ranges={[dateRangeFilter.selection]}
                          rangeColors={["#e5582b"]}
                        />
                      </div>
                      <div className="date-buttons">
                        <button
                          type="button"
                          className="cancel-date-button"
                          onClick={cancelDateFilter}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="apply-date-button"
                          onClick={applyDateFilter}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                    <Form.Group widths="equal">
                      <SingleSelectDropDown
                        clearable
                        options={props.pageTitle.map((org, index) => ({
                          key: index,
                          text: org.title,
                          value: org.title,
                        }))}
                        value={props.editorialDetailsFilterData.selPageTitle}
                        label="Page Title"
                        placeholder="Page Title"
                        onClick={onClickPageTitle}
                        onChange={onSelectPageTitle}
                        onSearchChange={searchPageTitle}
                      />
                      <UrlParamsPicker
                        style={{ width: "24em" }}
                        options={props.urlSource?.map((url, index) => ({
                          key: index,
                          text: url.source,
                          value: url.source,
                        }))}
                        value={props.editorialDetailsFilterData.selUrlSource}
                        label="UTM Source"
                        placeholder="UTM Source"
                        onClick={onClickUrlSource}
                        onChange={onSelectUrlSource}
                        onSearchChange={searchUrlSource}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <UrlParamsPicker
                        options={props.urlMedium?.map((url, index) => ({
                          key: index,
                          text: url.medium,
                          value: url.medium,
                        }))}
                        value={props.editorialDetailsFilterData.selUrlMedium}
                        label="UTM Medium"
                        placeholder=" UTM Medium"
                        onClick={onClickUrlMedium}
                        onChange={onSelectUrlMedium}
                        onSearchChange={searchUrlMedium}
                      />
                      <UrlParamsPicker
                        options={props.urlCampaigns?.map((url, index) => ({
                          key: index,
                          text: url.campaign,
                          value: url.campaign,
                        }))}
                        value={props.editorialDetailsFilterData.selUrlCampaign}
                        label="UTM Campaign"
                        placeholder="UTM Campaign"
                        onClick={onClickUrlCampaign}
                        onChange={onSelectUrlCampaigns}
                        onSearchChange={searchUrlCampaigns}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <UrlParamsPicker
                        label="UTM Term"
                        placeholder="UTM Term"
                        options={props.urlTerm?.map((url, index) => ({
                          key: index,
                          text: url.term,
                          value: url.term,
                        }))}
                        value={props.editorialDetailsFilterData.selUrlTerm}
                        onClick={onClickUrlTerm}
                        onChange={onSelectUrlTerm}
                        onSearchChange={searchUrlTerm}
                      />
                      <UrlParamsPicker
                        label="UTM Content"
                        placeholder="UTM Content"
                        options={props.urlContent?.map((url, index) => ({
                          key: index,
                          text: url.content,
                          value: url.content,
                        }))}
                        value={props.editorialDetailsFilterData.selUrlContent}
                        onClick={onClickUrlContent}
                        onChange={onSelectUrlContent}
                        onSearchChange={searchUrlContent}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <div className="field">
                      <PrimaryTopic
                      label="Primary Topic"
                      placeholder="Primary Topic"
                      value={props.editorialDetailsFilterData.selPrimaryTopic}
                      primaryTopic={props.scPrimaryTopic}
                      onClick={onClickPrimaryTopic}
                      onChange={onSelectPrimaryTopic}
                      onSearchChange={searchPrimaryTopic}
                    />
                      </div>
                      <div className="field"></div>
                    </Form.Group>
                  </Form>
                )}
                <Form.Group widths="equal">
                <CountryMultiSelectPicker
                    countries={props.countries}
                    value={props.editorialDetailsFilterData.selCountry}
                    onClick={onClickCountry}
                    onChange={onSelectCountry}
                    onSearchChange={searchCountry}
                    label="Pageviews by Country"
                  />
                  <Site
                    value={props.editorialDetailsFilterData.site}
                    onChange={onSelectSite}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <TagsPicker
                    sites={props.sites}
                    tags={props.topicTagsIn}
                    value={props.editorialDetailsFilterData.selTopicTagsIN}
                    label="Topic Tags (Include)"
                    placeholder="Topic Tags (Include)"
                    onClick={onClickTopicTagIn}
                    onChange={onSelectTopicTagsIn}
                    onSearchChange={searchTopicTagsIn}
                  />
                  <TagsPicker
                    sites={props.sites}
                    tags={props.topicTagsEx}
                    value={props.editorialDetailsFilterData.selTopicTagsEX}
                    label="Topic Tags (Exclude)"
                    placeholder="Topic Tags (Exclude)"
                    onClick={onClickTopicTagEx}
                    onChange={onSelectTopicTagsEx}
                    onSearchChange={searchTopicTagsEx}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <TagsPicker
                    sites={props.sites}
                    tags={props.placementTagsIn}
                    value={props.editorialDetailsFilterData.selPlacementTagsIN}
                    label="Placement Tags (Include)"
                    placeholder="Placement Tags (Include)"
                    onClick={onClickPlacementTagIn}
                    onChange={onSelectPlacementTagsIn}
                    onSearchChange={searchPlacementTagsIn}
                  />
                  <TagsPicker
                    sites={props.sites}
                    tags={props.placementTagsEx}
                    value={props.editorialDetailsFilterData.selPlacementTagsEX}
                    label="Placement Tags (Exclude)"
                    placeholder="Placement Tags (Exclude)"
                    onClick={onClickPlacementTagEx}
                    onChange={onSelectPlacementTagsEx}
                    onSearchChange={searchPlacementTagsEx}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <TagsPicker
                    sites={props.sites}
                    tags={props.orgTagsIn}
                    value={props.editorialDetailsFilterData.selOrgTagsIN}
                    label="Organization Tags (Include)"
                    placeholder="Organization Tags (Include)"
                    onClick={onClickOrgTagIn}
                    onChange={onSelectOrgTagsIn}
                    onSearchChange={searchOrgTagsIn}
                  />
                  <TagsPicker
                    sites={props.sites}
                    tags={props.orgTagsEx}
                    value={props.editorialDetailsFilterData.selOrgTagsEX}
                    label="Organization Tags (Exclude)"
                    placeholder="Organization Tags (Exclude)"
                    onClick={onClickOrgTagEx}
                    onChange={onSelectOrgTagsEx}
                    onSearchChange={searchOrgTagsEx}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                <AuthorPicker
                    authors={props.author}
                    value={props.editorialDetailsFilterData.selAuthor}
                    onClick={onClickAuthor}
                    onChange={onSelectAuthor}
                    onSearchChange={searchAuthor}
                  />
                  <TrafficSource
                     value={props.editorialDetailsFilterData.selTrafficSource}
                     onChange={onSelectTrafficSource}
                   />
                </Form.Group>
                <Form.Group widths="equal">
                  <TrafficFilterPicker
                    value={props.editorialDetailsFilterData.trafficType}
                    onChange={onSelectTrafficFilter}
                  />
                  <PageviewType
                    value={props.editorialDetailsFilterData.pageviewType}
                    onChange={onSelectPageViewType}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                <Form className="search-filter-column-1" style={{paddingLeft: "7px"}}>
                  <EngagedOrganization
                      organizations={props.editorialDetailsEnagedOrgs}
                      value={props.editorialDetailsFilterData.selPageViewsByOrg}
                      label="Engaged Organizations"
                      placeholder="Engaged Organizations"
                      onClick={onClickPageViewByOrgs}
                      onChange={onSelectPageViewByOrg}
                      onSearchChange={searchPageViewByOrg}
                      type="addOrgLink"
                    />
                <div className="engagements-search-filter">
                <Form className="search-filter-column-1">
                {/* <div style={{marginTop: "20px"}}>
                  <Toggle
                        label="Include Parent"
                        onChange={onChangeIncludeParent}
                        checked={props.editorialDetailsFilterData.edIsIncludeParent}
                      />
                </div>
                <div style={{marginTop: "12px"}}>
                  <Toggle
                        label="Include Children"
                        onChange={onChangeIncludeChild}
                        checked={props.editorialDetailsFilterData.edIsIncludeChild}
                      />
                </div> */}
                <div style={{marginTop: "20px"}}>
                    <Toggle
                      label="Exclude Anonymous Organizations"
                      onChange={(e)=>{
                        onChangeExcludeOrgsMember(e, "orgs")
                      }}
                      checked={props.editorialDetailsFilterData.exAnonymousOrgs}
                    />
                  </div>
                </Form>
              </div>
              </Form>
                <Form className="search-filter-column-2">
                <Form className="search-filter-column-1">
                  <EngagedMember
                        label="Engagement Member"
                        placeholder="Engagement Member"
                        engMember={props.scEngMember}
                        value={props.editorialDetailsFilterData.selEnagedMember}
                        onClick={onClickEngagedMember}
                        onChange={onSelectEngagedMember}
                        onSearchChange={searchEngagedMember}
                        type="addMemberLink"
                      />
                <div className="engagements-search-filter">
                <Form className="search-filter-column-1" style={{marginLeft: "10px", paddingRight: "6px"}}>
                  <div style={{marginTop: "20px"}}>
                    <Toggle
                      label="Exclude Anonymous Members"
                      onChange={(e)=>{
                        onChangeExcludeOrgsMember(e, "member")
                      }}
                      checked={props.editorialDetailsFilterData.exAnonymousMember}
                    />
                  </div>
                </Form>
              </div>
                </Form>
                </Form>
                </Form.Group>
                <br />
                <div>
                  <Toggle
                    label="Firmographic (Only applicable for xlsx)"
                    onChange={() => {
                      props.selEDToggles(actionTypes.SELEDFIRMOGRAPHICTOGGLE);
                    }}
                    checked={props.editorialDetailsFilterData.firmographicToggle}
                  />
                  {props.editorialDetailsFilterData.firmographicToggle && (
                    <FirmographicFilter
                      rowGap="0px"
                      firmogaphicFilterData={firmogaphicFilterData}
                      onSearchChange={onSearchChangeFirmographic}
                      onChange={onSelectFirmographic}
                      onClick={onClickFirmographic}
                    />
                  )}
                </div>
                <br />
                <Toggle
                  label="Persona"
                  onChange={() => { props.selEDToggles(actionTypes.SELEDPERSONATOGGLE) }}
                  checked={props.editorialDetailsFilterData.personaToggle}
                />
                {props.editorialDetailsFilterData.personaToggle && (
                  <PersonaFilter
                    personaSenority={personaSeniority}
                    personaJobFunction={personaJobFunction}
                    personaJobTitleIn={personaJobTitleIn}
                    personaJobTitleEx={personaJobTitleEx}
                    labelJobTitleIn="Job Title (Include)"
                    labelJobTitleEx="Job Title (Exclude)"
                  />
                )}
                <Form.Group>
                <div className="engagements-search-filter">
						      <Form className="search-filter-column-1"></Form>
							      <Form className="search-filter-column-2">
                      <div className="ad-toggle-label">
                        <p>Event Filters</p>
                      </div>
                      <Toggle
                        className="ad-child-toggle independant-event-filters"
                        label="Exclude Internal Events"
                        onChange={() => { props.selEDToggles(actionTypes.SELEDINTERNALEVENTSTOGGLE) }}
                        checked={props.editorialDetailsFilterData.excludeInternalEvents}
                      />
                      {/* <Toggle
                        className="ad-child-toggle"
                        label="Exclude QA Campaign"
                        onChange={() => { props.selEDToggles(actionTypes.SELEDQATOGGLE) }}
                        checked={props.editorialDetailsFilterData.excludeQACampaign}
                      />
                      <Toggle
                        className="ad-child-toggle"
                        label="Exclude Internal Campaign"
                        onChange={() => {props.selEDToggles(actionTypes.SELEDINTOGGLE)}}
                        checked={props.editorialDetailsFilterData.excludeInternalCampaign}
                      /> */}
                    </Form>  
                    </div>   
                </Form.Group>
              </div>
            </Form>
            <div className="filter-buttons">
              <button className="cancel-date-button extra-space" onClick={clearFilters}>
                Clear Filters
              </button>
              <button
                type="submit"
                className="apply-filter-button"
                onClick={() => {
                  applyFilters( props.editorialDetailsFilterData.startDate, props.editorialDetailsFilterData.endDate, checkSelectState );
                }}
              >
                Apply Filters
              </button>
            </div>
          </div>
        </div>

    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    editorialDetailsFilterData: state.editorialDetailsFilterData,
    organizations: Object.values(state.editorialDetailsOrganizations),
    order: Object.values(state.editorialDetailsOrder),
    campaigns: Object.values(state.editorialDetailsCampaigns),
    author: Object.values(state.editorialDetailsAuthor),
    sites: Object.values(state.sites),
    topicTagsIn: Object.values(state.editorialDetailsTopicTagsIn),
    topicTagsEx: Object.values(state.editorialDetailsTopicTagsEx),
    placementTagsIn: Object.values(state.editorialDetailsPlacementTagsIn),
    placementTagsEx: Object.values(state.editorialDetailsPlacementTagsEx),
    orgTagsIn: Object.values(state.editorialDetailsOrgTagsIn),
    orgTagsEx: Object.values(state.editorialDetailsOrgTagsEx),
    countries: Object.values(state.editorialDetailsCountry),
    editorialDetailsEnagedOrgs: Object.values(state.editorialDetailsEngOrg),
    firmographicSector: Object.values(state.editorialDetailsFirmographicSector),
    firmographicIndustryGroup: Object.values(state.editorialDetailsFirmographicIndustryGroup),
    firmographicIndustry: Object.values(state.editorialDetailsFirmographicIndustry),
    firmographicSubIndustry: Object.values(state.editorialDetailsFirmographicSubIndustry),
    firmographicOrgsType: Object.values(state.editorialDetailsFirmographicOrgsType),
    firmographicOrgSize: Object.values(state.editorialDetailsFirmographicOrgsSize),
    firmographicOrgsRevenue: Object.values(state.editorialDetailsFirmographicOrgsRevenue),
    orgTypes: Object.values(state.orgTypesList),
    orgSize: Object.values(state.orgSizeList),
    orgRevenue: Object.values(state.orgRevenueList),
    sector: Object.values(state.sectorList),
    industryGroup: Object.values(state.industryGroupList),
    industry: Object.values(state.industryList),
    subIndustry: Object.values(state.subIndustryList),
    seniorities: Object.values(state.seniorities),
    senioritiesIn: Object.values(state.editorialDetailsSenioritiesIn),
    senioritiesEx: Object.values(state.editorialDetailsSenioritiesEx),
    jobfunctions: Object.values(state.jobfunctions),
    JobFunctionsIN: Object.values(state.editorialDetailsJobfunctionsIn),
    JobFunctionsEX: Object.values(state.editorialDetailsJobfunctionsEx),
    pageTitle: Object.values(state.EdPageviewTitle),
    scEngMember: Object.values(state.edEngMember),
    scPrimaryTopic: Object.values(state.edPrimaryTopic),
    urlSource: Object.values(state.EdUrlSource),
    urlMedium: Object.values(state.EdUrlMedium),
    urlCampaigns: Object.values(state.EdUrlCampaigns),
    urlTerm: Object.values(state.EdUrlTerm),
    urlContent: Object.values(state.EdUrlContent),
    isFilApplied: state.editorialDetailsFetchData.isFilApplied,
  };
};

export default compose(connect(mapStateToProps, {
    selEditorialDetailsFilterValue,
    fetchOrganizations,
    fetchCampaigns,
    clearSearchOrgs,
    fetchOrder,
    addOrder,
    clearSearchOrder,
    addOrganization,
    resetOrgsOrCampaigns,
    addCampaigns,
    clearSearchCampaigns,
    fetchPgAuthors,
    clearEditorialDetailsSerchedValue,
    fetchTags,
    selEDFilterValue,
    fetchPgCountry,
    addCountries,
    selEDToggles,
    firmographicOrgsTypeCall,
    SenioritiesList,
    fetchPageTitle,
    fetchUrlParams,
    fetchEngagedMember,
    fetchPrimaryTopic,
    applyEDFilter,
    fetchPageviewTableData,
    fetchEngagedOrg,
    applyIndDetailsDate
}))(SearchFilter);
