import React from "react";
import { Table } from "semantic-ui-react";

const AgencyComponent = ({ data }) => {
    return (
        <Table.Cell title={data?.agency?.name} >{data?.agency?.name}</Table.Cell>
    );
};

export default AgencyComponent;
