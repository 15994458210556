import React from "react";
import _ from "lodash";
import { getCommaSeparatedVal, getName, getNameJob } from "../../utilities/common";
import { List } from "semantic-ui-react";

const Personas = (props) => {
  let personaData = props.object;
  return (
    <div className="main-div">
      <List>
        <List.Item className="drawer-value">
          <label className="filter-text"> INCLUDED SENIORITIES </label>
          <List.Item className="drawer-text">
            {personaData.length > 0
              ? personaData.map((e) => {
                  if (!_.isEmpty(e.included_seniorities)) {
                    return getName(e.included_seniorities, "name");
                  } else {
                    return "-";
                  }
                })
              : "-"}
          </List.Item>
        </List.Item>
      </List>
      <List>
        <List.Item className="drawer-value">
          <label className="filter-text"> EXCLUDED SENIORITIES </label>
          <List.Item className="drawer-text">
            {personaData.length > 0
              ? personaData.map((e) => {
                  if (!_.isEmpty(e.excluded_seniorities)) {
                    return getName(e.excluded_seniorities, "name");
                  } else {
                    return "-";
                  }
                })
              : "-"}
          </List.Item>
        </List.Item>
      </List>
      <List>
        <List.Item className="drawer-value">
          <label className="filter-text"> INCLUDED JOB FUNCTIONS </label>
          <List.Item className="drawer-text">
            {personaData.length > 0
              ? personaData.map((e) => {
                  if (!_.isEmpty(e.included_job_functions)) {
                    return getNameJob(e.included_job_functions);
                  } else {
                    return "-";
                  }
                })
              : "-"}
          </List.Item>
        </List.Item>
      </List>
      <List>
        <List.Item className="drawer-value">
          <label className="filter-text"> EXCLUDED JOB FUNCTIONS </label>
          <List.Item className="drawer-text">
            {personaData.length > 0
              ? personaData.map((e) => {
                  if (!_.isEmpty(e.excluded_job_functions)) {
                    return getNameJob(e.excluded_job_functions);
                  } else {
                    return "-";
                  }
                })
              : "-"}
          </List.Item>
        </List.Item>
      </List>
      <List>
        <List.Item className="drawer-value">
          <label className="filter-text"> INCLUDED JOB TITLES </label>
          <List.Item className="drawer-text">
            {personaData.length > 0
              ? personaData.map((e) => {
                  if (!_.isEmpty(e.included_member_job_titles)) {
                    return getCommaSeparatedVal(e.included_member_job_titles);
                  } else {
                    return "-";
                  }
                })
              : "-"}
          </List.Item>
        </List.Item>
      </List>
      <List>
        <List.Item className="drawer-value">
          <label className="filter-text"> EXCLUDED JOB TITLES </label>
          <List.Item className="drawer-text">
            {personaData.length > 0
              ? personaData.map((e) => {
                  if (!_.isEmpty(e.excluded_member_job_titles)) {
                    return getCommaSeparatedVal(e.excluded_member_job_titles);
                  } else {
                    return "-";
                  }
                })
              : "-"}
          </List.Item>
        </List.Item>
      </List>
      <List>
        <List.Item className="drawer-value">
          <label className="filter-text"> INCLUDED USER AGENTS</label>
          <List.Item className="drawer-text">
            {personaData.length > 0
              ? personaData.map((e) => {
                  if (!_.isEmpty(e.included_user_agents)) {
                    return getCommaSeparatedVal(e.included_user_agents);
                  } else {
                    return "-";
                  }
                })
              : "-"}
          </List.Item>
        </List.Item>
      </List>
      <List>
        <List.Item className="drawer-value">
          <label className="filter-text"> EXCLUDED USER AGENTS</label>
          <List.Item className="drawer-text">
            {personaData.length > 0
              ? personaData.map((e) => {
                  if (!_.isEmpty(e.excluded_user_agents)) {
                    return getCommaSeparatedVal(e.excluded_user_agents);
                  } else {
                    return "-";
                  }
                })
              : "-"}
          </List.Item>
        </List.Item>
      </List>
    </div>
  );
};

export default Personas;
