import React, { useRef } from 'react';
import './layout.css';
import Header from '../navigation/header';
import SideDrawer from '../navigation/sideDrawer';
import StickySidebar from '../navigation/stickySidebar';
import { connect } from 'react-redux';
import { Sticky, Grid } from 'semantic-ui-react';
import { capitalizeName } from '../utilities/common';

const Layout = (props) => {
  const parentRef = useRef(null);

  let container = '';
  if (!props.authorized.token) {
   container = (
      <Grid columns={1}>
        <Grid.Row>
          <Grid.Column className='main-content' width={16}>
            <div className='app-children'>{props.children}</div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
   else {
    container = (
      <Grid columns={2}>
        <Grid.Row style={{ display: "flex", height: "100vh" }}>
          <Grid.Column className='sticky-sidebar' id={(props.menuVisible) ? "sticky-sidebar-width" : ""} width={props.sidebarColumn}>
            {props.menuVisible ? (
              <SideDrawer></SideDrawer>
            ) : (
              <StickySidebar></StickySidebar>
            )}
          </Grid.Column>
          <Grid.Column className='main-content' id={(props.menuVisible) ? "main-content-width" : ""} width={props.contentColumn}>
          <div className='app-children'>{props.children}</div>

          </Grid.Column>
          
        </Grid.Row>
      </Grid>
    );
  }
  
  return (
    <div ref={parentRef}>
      <Sticky context={parentRef}>
        <Header
          isAuth={props.authorized.token}
          user={capitalizeName(props.authorized.userName)}
          loginLink={props.loginLink}
          wpLink={props.wpLink}
        ></Header>
      </Sticky>
      {container}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    filterData: state.allFilterReportData,
    loginLink: state.links.loginLink,
    wpLink: state.links.wpLink,
    authorized: state.authorized,
    menuVisible: state.menuVisible.visible,
    sidebarColumn: state.menuVisible.sidebarColumn,
    contentColumn: state.menuVisible.contentColumn,
    pgFilterData: state.sponsoredContentFilterData,
  };
};

export default connect(mapStateToProps)(Layout);
