import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { connect } from "react-redux";
import { numFormat } from "../../../utilities/common";

const LeadEngsProgressChart = (props) => {
  const [legendClicked, setLegendClicked] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    let engsStatusChartPreparedData = leadEngsValidationBarChart();
    setChartData(engsStatusChartPreparedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legendClicked]);

  const onLegendClick = (e) => {
    if (legendClicked.includes(e)) {
      if (e === "Not Started") {
        setLegendClicked((prev) => prev.filter((i) => i !== e));
      } else {
        setLegendClicked((prev) => prev.filter((i) => i !== e));
      }
    } else {
      if (e === "Not Started") {
        setLegendClicked((prev) => [...prev, e]);
      } else {
        setLegendClicked((prev) => [...prev, e]);
      }
    }
  };

  const colors = [
      "#B6B7B8",
      "#FCAF17",
      "#F15A29",
      "#0077B7",
      "#33AC2E",
      "#D63649",
    ]
  const keyNames = [
      "Not Started",
      "Received",
      "In Review",
      "Accepted not Delivered",
      "Delivered to Customer",
      "Rejected",
    ];

  const getSeries = () => {
    let series = {};
    for (let i = 0; i < keyNames.length; i++) {
      let j = i;
      let color;
      color = legendClicked.indexOf(keyNames[i]) === -1 && colors[i];
      legendClicked.indexOf(keyNames[i]) === -1 ?
      series[j] = { axis: keyNames[i], color: color } :
      series[j] = {axis: keyNames[i], targetAxisIndex:1, color: "transparent" }

      series[6] = { axis: "Engagements Purchased", color: "#838486" };
    }
    return series;
  };

  const leadEngsValidationBarChart = () => {
    var data = [];
    let d = [];

    data.push([
      "",
      { type: "string", role: "tooltip", p: { html: true } },
      "Not Started",
      "Received",
      "In Review",
      "Accepted not Delivered",
      "Delivered to Customer",
      "Rejected",
      "Engagements Purchased",
    ]);
    d = [];
    const receivedCount =
      props.leadEngsValidationBarChart.engagement_progress?.received_count;
    const inReviewCount =
      props.leadEngsValidationBarChart.engagement_progress?.in_review_count;
    const acceptedCount =
      props.leadEngsValidationBarChart.engagement_progress?.accepted_count;
    const engagementDelivered =
      props.leadEngsValidationBarChart.engagement_progress
        ?.engagement_delivered;
    const rejectedCount =
      props.leadEngsValidationBarChart.engagement_progress?.rejected_count;
    const engagementPurchased =
      props.leadEngsValidationBarChart.engagement_progress
        ?.engagement_purchased;
    d.push("");
    d.push(
      `<div class="tooltip-pageview-values" style='width: auto; padding-top: 5px; padding-left: 5px; margin-bottom:0px;'>` +
        "<span style='height: 6px; width: 6px; margin-right: 5px; background-color:#B6B7B8;border-radius: 50%; display: inline-block;'></span>" +
        numFormat(
          props.leadEngsValidationBarChart.engagement_progress?.not_started
        ) +
        ` - Not Started` +
        `<br />` +
        "<span style='height: 6px; width: 6px; margin-right: 5px; background-color:#FCAF17;border-radius: 50%; display: inline-block;'></span>" +
        numFormat(receivedCount) +
        ` - Received` +
        `<br />` +
        "<span style='height: 6px; width: 6px; margin-right: 5px; background-color:#F15A29;border-radius: 50%; display: inline-block;'></span>" +
        numFormat(inReviewCount) +
        ` - In Review` +
        `<br />` +
        "<span style='height: 6px; width: 6px; margin-right: 5px; background-color:#0077B7;border-radius: 50%; display: inline-block;'></span>" +
        numFormat(acceptedCount) +
        ` - Accepted not Delivered` +
        `<br />` +
        "<span style='height: 6px; width: 6px; margin-right: 5px; background-color: #33AC2E;border-radius: 50%; display: inline-block;'></span>" +
        numFormat(engagementDelivered) +
        ` - Delivered to Customer ` +
        `<br />` +
        "<span style='height: 6px; width: 6px; margin-right: 5px; background-color:#D63649;border-radius: 50%; display: inline-block;'></span>" +
        numFormat(rejectedCount) +
        ` - Rejected` +
        `</div>`
    );
    d.push(props.leadEngsValidationBarChart.engagement_progress?.not_started);
    d.push(receivedCount);
    d.push(inReviewCount);
    d.push(acceptedCount);
    d.push(engagementDelivered);
    d.push(rejectedCount);
    d.push(null);

    data.push(d);
    data.push([
      "",
      `<div class="tooltip-pageview-values" style='width: auto; padding-top: 10px; padding-left: 5px;padding-bottom: 10px;'>` +
        "<span style='height: 6px; width: 6px; margin-right: 5px; background-color:#838486;border-radius: 50%; display: inline-block;'></span>" +
        numFormat(engagementPurchased) +
        ` - Engagements Purchased ` +
        `<br />` +
        `</div>`,
      null,
      null,
      null,
      null,
      null,
      null,
      engagementPurchased,
    ]);

    return data;
  };

  const options = {
    // colors: ["#B6B7B8", "#FCAF17", "#F15A29","#0077B7", "#33AC2E", "#D63649"],
    legend: "none",
    chartArea: { left: "4%", top: "15%", width: "92%", height: "auto" },
    isStacked: true,
    crosshair: {
      orientation: "vertical",
      color: "grey",
      opacity: 2,
      trigger: "both",
    },
    hAxis: {
      baselineColor: "#fff",
      gridlineColor: "#fff",
      minValue: 0,
    },
    vAxis: {
      baselineColor: "#fff",
      gridlineColor: "#fff",
    },
    tooltip: { isHtml: true },
    focusTarget: "category",
    //   series: {
    //     6: {
    //         color: "#838486",
    //     }
    // }
    series: getSeries(),
  };

  return (
    <div className="engs-barchart bar-chart-fill" style={{ height: "230px" }}>
      <div>
        <div>
          <div
            style={{
              marginLeft: -17,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="legend-item" style={{ marginLeft: "24px" }}>
              <span
                style={{ backgroundColor: "#838486"}}
                className="legend-circle"
              />
              <text style={{ color: "#656669" }} className="topic-text" >
                Engagements Purchased
              </text>
            </div>
            <div style={{marginRight: 72, paddingTop: 10}}>
            <div className="legend-item"
                onClick={() => { onLegendClick("Not Started")}}
                style={{ marginLeft: "5px", cursor: "pointer" }}>
              <span
                style={{ backgroundColor: !legendClicked.includes("Not Started") ? "#B6B7B8" : "grey", cursor: "pointer"}}
                className="legend-circle"
              />
              <text style={{ color: "#656669" }} className="topic-text" >
                Not Started
              </text>
            </div>
            <div className="legend-item"
             style={{ paddingLeft: "5px", cursor: "pointer" }}
             onClick={() => { onLegendClick("Received")}}
             > 
              <span
                style={{ backgroundColor: !legendClicked.includes("Received") ?  "#FCAF17" : "grey", cursor: "pointer"}}
                className="legend-circle"
              />
              <text style={{ color: "#656669" }} className="topic-text">
                Received
              </text>
            </div>
            <div className="legend-item" style={{ paddingLeft: 5, cursor: "pointer" }}
              onClick={() => { onLegendClick("In Review")}}
            >
              <span
                style={{ backgroundColor: !legendClicked.includes("In Review") ?  "#F15A29" : "grey", cursor: "pointer" }}
                className="legend-circle"
              />
              <text
                style={{ color: "#656669", marginRight: 40 }}
                className="topic-text"
              >
                In Review
              </text>
            </div>
            <div className="legend-item" style={{ marginLeft: "-30px", cursor: "pointer" }}
              onClick={() => { onLegendClick("Accepted not Delivered")}}
            >
              <span
                style={{ backgroundColor: !legendClicked.includes("Accepted not Delivered") ?  "#0077B7": "grey", cursor: "pointer"}}
                className="legend-circle"
              />
              <text
                style={{ color: "#656669", marginRight: 40 }}
                className="topic-text"
              >
                Accepted not Delivered
              </text>
            </div>

            <div className="legend-item" style={{ marginLeft: "-30px", cursor: "pointer" }}
                onClick={() => { onLegendClick("Delivered to Customer")}}
            >
              <span
                style={{ backgroundColor: !legendClicked.includes("Delivered to Customer") ?  "#33AC2E" : "grey", cursor: "pointer"}}
                className="legend-circle"
              />
              <text
                style={{ color: "#656669", marginRight: 40 }}
                className="topic-text"
              >
                Delivered to Customer
              </text>
            </div>
            <div className="legend-item" 
            style={{ marginLeft: "-30px", cursor: "pointer" }}
            onClick={() => { onLegendClick("Rejected")}}
            >
              <span
                style={{ backgroundColor: !legendClicked.includes("Rejected") ?  "#D63649" : "grey", cursor: "pointer"}}
                className="legend-circle"
              />
              <text
                style={{ color: "#656669", marginRight: 30 }}
                className="topic-text"
              >
                Rejected
              </text>
            </div>
            </div>
            
          </div>
        </div>
        <Chart
          chartType="BarChart"
          width="100%"
          height="150px"
          data={chartData}
          options={options}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    leadEngsValidationBarChart: state.leadFetchData.leadEngsValidationBarChart,
    isFilApplied: state.leadFetchData.isFilApplied,
  };
};

export default connect(mapStateToProps)(LeadEngsProgressChart);
