import React, { useState } from 'react';
import ActionsSelector from '../common/ActionsSelector';
import ActionsMultiSelect from '../common/ActionsMultiSelect';
import activityFilterDropdownJson from '../../reducers/ActivityFilterDropdown.json';
import * as actionTypes from "../../actions/actionTypes";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { connect } from "react-redux";
import { filterSelectValue, addFilterById, clearSearch } from "../../actions";
import _ from 'lodash';

const DynamicDropdownsFilter = (props) => {
   const [dropdownValues, setDropdownValues] = useState('');
   const [selectNumbers, setSelectNumbers] = useState(0);
   const dispatch = useDispatch();

   const handleDropdownChange = (value) => {
      setDropdownValues(value);
   };

   const searchData = () => {
      dispatch({
         type: actionTypes.FETCHACTIVITYACTION,
         payload: activityFilterDropdownJson,
      });
   }

   const onSearchAction = (e) => {
      searchData();
   };

   const onSelectActions = (e, { value }) => {
      props.filterSelectValue({ key: "filterDropdowns", value });
      props.addFilterById(value, actionTypes.ADDACTIVITYACTIONS);
	};

   const onClickActions = () => {
      searchData();
	};

   const onChangeHandler = (e) =>{
      setSelectNumbers(e.target.value);
	}

   const onApplyHandler = () => {
      if(dropdownValues !== '') {
         if(props.filterData.filterDropdowns && props.filterData.filterDropdowns.length > 0) {
            dispatchFunction();
            setSelectNumbers();
         } else if(selectNumbers) {
            dispatchFunction();
            props.filterSelectValue({ key: "filterDropdowns", value: [] });
         } else {
            clearAndResetData();
            props.filterSelectValue({ key: "filterDropdowns", value: [] });
            setSelectNumbers();
         }
      }
   };

   const dispatchFunction = () => {
      dispatch({
         type: actionTypes.APPLYACTIVITACTIONS,
         actionValue: dropdownValues,
         numberValue: selectNumbers,
         multiSelectedValue: props.filterData.filterDropdowns
      });
   };

   const onClearAllData = () => {
      clearAndResetData();
      setDropdownValues('');
      props.filterSelectValue({ key: "filterDropdowns", value: [] });
      setSelectNumbers();
   };

   const clearAndResetData = () => {
      dispatch({
         type: actionTypes.RESETACTUALACTIVITYDATA,
         payload: props.backupActivityData
      });
   };

   return (
      <div>
         <div className="dropdown-row">
            <ActionsSelector
               value={dropdownValues}
               onChange={(e, { value }) => handleDropdownChange(value)}
            />
            {
               dropdownValues === 'actions' &&
               <ActionsMultiSelect
                  value={props.filterData.filterDropdowns}
                  label="search actions"
                  style={{ width: '19.5em' }}
                  onSearchChange={onSearchAction}
                  onChange={onSelectActions}
                  onClick={onClickActions}
                  data={props.actionData}
               />
            }
            {
               (_.includes(["organization_actions_taken", "member_actions_taken"], dropdownValues)) &&
               <input
                  className='input_form-control'
                  onChange={onChangeHandler}
                  placeholder="Enter number"
                  value={props.filterData.selectNumbers}
                  type='number'
               />
            }
            <div className="filter-buttons_actions">
               <button className="apply-filter-button" onClick={onApplyHandler}>
                  Apply
               </button>
               <button className="cancel-date-button extra-space" onClick={onClearAllData}>
                  Clear
               </button>
            </div>
         </div>
      </div>
   );
};

const mapStateToProps = (state) => {
   return {
      filterData: state.filterData,
      actionData: Object.values(state.activityActions),
      backupActivityData: state.activityFetchData.backupActivityData,
   };
};

export default compose( connect(mapStateToProps, {
   filterSelectValue,
   addFilterById,
   clearSearch
})
)(DynamicDropdownsFilter);

