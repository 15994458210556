import React from "react";
import { connect } from "react-redux";
import CommonSummaryReport from "./commonSummaryReport";
import { Message, Loader } from "semantic-ui-react";
import _ from "lodash"
// import * as actionTypes from "../../actions/actionTypes";
import { clearSiteSort, fetchSiteAllPGSummaryReport, showMoreTableData } from "../../actions";
// import { url } from "../../api/urlHelper";

const SiteAllPGSummaryReport = (props) => {
    // const [isShow, setShow] = useState(false);
	let loading;
	let noresult;

    if (props.loading) {
        loading = (
          <div className="show-loader">
            <Loader active inline="centered" />
          </div>
        );
    }

    if (!props.loading && _.isEmpty(props.siteAllPgSummaryData) && props.isFilter) {
        noresult = <Message>No results found.</Message>;
    }

    // const showMoreClick = () => {
    //     setShow(true)
    // }

//     const showMore = async () => {
// 		props.clearSiteSort(actionTypes.CLEARALLPGSORT);
// 		props.showMoreTableData(actionTypes.MOREALLPGSUMMARYLOADING);
// 		props.fetchSiteAllPGSummaryReport(
//             url.sitePGSummaryReport,
//             props.filterData.startDate,
//             props.filterData.endDate,
//             (props.filterData.allPageCount) + 1
//         );
// 		props.showMoreTableData(actionTypes.SETALLPAGEVIEWSUMMARYPGCOUNT);
//   };

	return (
		<div>
			<CommonSummaryReport 
                column={props.column}
                direction={props.direction}
                type="all_summary_report"
                filterData={props.siteAllPgSummaryData}
                // parentShowMore={showMoreClick}
            />
			{noresult}
			{loading}
			{/* {
                (isShow && !_.isEmpty(props.siteAllPgSummaryData) && !props.siteReportFilterData.resPageviewSummaryValue) ?(

                <div className="table-divider">
                    <Divider>
                        <div className="show-more-component">
                            <button className="show-more-button" onClick={showMore}> Show More </button>
                        </div>
                    </Divider>
                </div>
                ): (
                    <div className="table-divider">
                    <Divider>
                        <div className="show-more-component">
                            <button className="show-more-button-disable">
                            Show More
                        </button>
                        </div>
                        </Divider>
                </div>
            )} */}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
        filterData: state.siteReportFilterData,
		siteAllPgSummaryData: state.siteReportFilterData.siteAllPgData,
        loading: state.siteReportFilterData.isLoadingSiteAllPgData,
        isFilter: state.siteReportFilterData.isApplied,
        siteReportFilterData: state.siteReportFilterData
	};
};

export default connect(mapStateToProps, {
    clearSiteSort,
    fetchSiteAllPGSummaryReport,
    showMoreTableData
})(SiteAllPGSummaryReport);
