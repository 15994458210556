import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import * as actionTypes from "../../../actions/actionTypes";
import { fetchOrganizations, selectValue, addSelectedValue, clearSearchForAllReport } from "../../../actions";
import MultipleSelectDropDown from "../../base/multiSelectDropdown";

const CompanySection = (props) => {

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    const onSearchHandler = (e) => {
        if (e.target.value.length > 1) {
            props.fetchOrganizations(
                props.filterData.excludeQACampaign,
                e.target.value,
                actionTypes[props.reportActionType].FETCH_COMPANY_NAME,
                undefined,
                undefined,
                props.reportActionType
            );
        }
    };

    const onSelectHandler = (e, { value }) => {
        dispatchDynamicSelectAction(props, "companyData", value);
        props.addSelectedValue({ dispatchType: actionTypes[props.reportActionType].ADD_COMPANY_NAME, reportType: props.reportActionType, value });
    };

    const onClickHandler = () => {
        props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].CLEAR_COMPANY_NAME, reportType: props.reportActionType });
    };

    return (
        <MultipleSelectDropDown
            label='Company Name'
            placeholder='Company Name'
            options={props?.commonSelectData?.companyData?.map((org, index) => ({
                key: index,
                text: org.name,
                value: org.uuid,
            }))}
            onSearchChange={onSearchHandler}
            value={props.filterData.companyData}
            onChange={onSelectHandler}
            onClick={onClickHandler}
        />
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        commonSelectData: state.allReportCommonReducer[ownProps.reportActionType],
    };
};

export default compose(connect(mapStateToProps, {
    fetchOrganizations,
    selectValue,
    addSelectedValue,
    clearSearchForAllReport
}))(CompanySection);