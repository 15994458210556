export const ALL_SUMMARY_REPORT = "all_summary_report";
export const EDITORIAL_SUMMARY_REPORT = "editorial_summary_report";
export const SPONSORED_SUMMARY_REPORT = "sponsored_summary_report";
export const START_DATE = "startDate";
export const END_DATE = "endDate";
export const PREVIOUS_START_DATE = "prevstartDate";
export const PREVIOUS_END_DATE = "prevendDate";
export const APPLIED_DATE = "appliedDate";
export const PREVIOUS_APPLIED_DATE = "prevAppliedDate";
export const CUSTOM_DATE_RANGE = "Custom Date Range";
export const HORIZONTAL = "horizontal";
export const ORGANIZATION = "organization";
export const CAMPAIGN_STATUS = "campaignStatus"
export const CAMPAIGN = "campaign";
export const CLIENT_DELIVERD_FILTER_STATE = "clientDeliverdFilterState";
export const ASSET_TYPE = "assetType";
export const ALL = "All";
export const ENGAGED_ORGANIZATIONS = "engagedOrganizations";

export const CLEAR_COLUMNS_LABEL = "Clear Columns";
export const REFRESH_CAMPAIGNS = "Refresh Campaigns";
export const DOWNLOADS = "Downloads";
export const LOGOUT = "Logout";
export const FILTER_PROFILE = "Filter Profile";
export const ADD_NEW_PROFILE = "Add Filter Profile";
export const EDIT_PROFILE = "Edit Filter Profile";
export const DELETE_PROFILE = "Delete Filter Profile";
export const FILTER_PROFILE_NAME_ALREADY_EXISTS = "Filter profile name already exists";
export const PLEASE_ENTER_VALUE = "Please enter value";
export const SHARE_PROFILE = "Share Profile";

export const ENGAGEMENT_SOURCE = "Engagement Source";
export const ENGAGEMENT_STATUS = "Engagement Status";
export const VERIFICATION_STATUS = "Verification Status";
export const ENGAGED_ORGANIZATION = "Engaged Organization";
export const OVERRIDE_REPORT_FILTER = "Override Report Filters";
export const ACCOUNT = "Account";
export const VERTICAL = "Vertical";
export const GEO = "Geo";
export const CUSTOM_QUESTIONS = "Custom Questions";
export const EMAIL_SUPRESSION = "Email Supression";
export const FIRMOGRAPHIC = "Firmographic";
export const TYPES = "Types";
export const SIZE = "Size";
export const REVENUE = "Revenue";
export const PERSONAS = "Personas";
export const SENIORITY = "Seniority";
export const JOB_FUNCTIONS = "Job Functions";
export const JOB_TITLES = "Job Titles";
export const UTM_PARAMETERS_LABEL = "UTM Parameters";
export const UTM_SOURCE_LABEL = "UTM Source";
export const UTM_MEDIUM_LABEL = "UTM Medium";
export const UTM_CAMPAIGN_LABEL = "UTM Campaign";
export const UTM_TERM_LABEL = "UTM Term";
export const UTM_CONTENT_LABEL = "UTM Content";
export const EXCLUDE_GATED_PAGEVIEWS = "Exclude Gated Pageviews";
export const EXCLUDE_INTERNAL_CAMPAIGN = "Exclude Internal Campaign";
export const EXLUDE_QA_CAMPAIGN = "Exclude QA Campaigns";
export const ENGAGED_MEMBER_LABEL = "Engaged Member";
export const EXCLUDE_ANONYMOUS_ORGANIZATIONS_LABEL = "Exclude Anonymous Organizations";
export const EXCLUDE_ANONYMOUS_MEMBERS_LABEL = "Exclude Anonymous Members";
export const EXCLUDE_INTERNAL_EVENTS = "Exclude Internal Events";
export const FIRMOGRAPHIC_TOGGLE_LABEL = "Firmographic (Only applicable for xlsx)";
export const FIRMOGRAPHIC_TOGGLE_LABEL2 = "Firmographic";
export const PERSONA_TOGGLE_LABEL = "Persona (Only applicable for xlsx)";
export const PERSONA_TOGGLE_LABEL2 = "Persona";
export const EXCLUDE_SDXCENTRAL_LABEL = "Exclude Internal Campaigns";
export const EXCLUDE_COMPANY_EVENTS = "Exclude Company Events";
export const SHOW_COMPETITOR = "Show Competitor";
export const EXCLUDE_ANONYMOUS_USERS = "Exclude Anonymous Users";

//Report modules constants
export const MODULE_SIDEBAR_ADS = "MODULE_SIDEBAR_ADS";
export const MODULE_ANNOUNCEMENT_ADS = "MODULE_ANNOUNCEMENT_ADS";
export const MODULE_TEXT_ADS = "MODULE_TEXT_ADS";
export const MODULE_ATF_HOMEPAGE = "MODULE_ATF_HOMEPAGE";
export const MODULE_INSTANT_MESSAGE = "MODULE_INSTANT_MESSAGE";
export const MODULE_NEWSLETTER_BODY = "MODULE_NEWSLETTER_BODY";
export const MODULE_DISPLAY_ADS = "MODULE_DISPLAY_ADS";
export const MODULE_CONTENT_BODY_ADS = "MODULE_CONTENT_BODY_ADS";
export const MODULE_INTERSTITIAL_ADS = "MODULE_INTERSTITIAL_ADS";
export const MODULE_NEWSLETTER_ADS = "MODULE_NEWSLETTER_ADS";
export const MODULE_ROADBLOCK_ADS = "MODULE_ROADBLOCK_ADS";
export const MODULE_NEWSLETTERS = "MODULE_NEWSLETTERS";
export const MODULE_ORDER_REPORT = "MODULE_ORDER_REPORT";
export const MODULE_ALL_ADS_REPORT = "MODULE_ALL_ADS_REPORT";
export const MODULE_CONTENT_PERFORMANCE = "MODULE_CONTENT_PERFORMANCE";
export const MODULE_AUTHOR_REPORT = "MODULE_AUTHOR_REPORT";
// di modules
export const MODULE_DI_EARNED_MEDIA = "MODULE_DI_EARNED_MEDIA";
export const MODULE_DI_BASIC = "MODULE_DI_BASIC";
export const MODULE_DI_PRO = "MODULE_DI_PRO";
export const MODULE_DI_UPGRADE = "MODULE_DI_UPGRADE";


//Report names/types constants
export const SIDEBAR_ADS_REPORT_NAME = "sidebar";
export const ANNOUNCEMENT_REPORT_NAME = "announcement";
export const TEXT_REPORT_NAME = "text";
export const ATF_HOMEPAGE_REPORT_NAME = "iab";
export const INSTANT_MESSAGE_REPORT_NAME = "instant-message";
export const NEWSLETTER_BODY_REPORT_NAME = "newsletter-body";
export const DISPLAY_ADS_REPORT_NAME = "display";
export const CONTENT_BODY_ADS_REPORT_NAME = "incontent";
export const INTERSTITIAL_ADS_REPORT_NAME = "interstitial";
export const NEWSLETTER_ADS_REPORT_NAME = "newsletter";
export const ROADBLOCK_ADS_REPORT_NAME = "roadblock";
export const NEWSLETTERS_REPORT_NAME = "newsletter-summary";
export const ORDER_REPORT_NAME = "order";
export const ALL_ADS_REPORT_NAME = "";
export const CONTENT_PERFORMANCE_REPORT_NAME = "content-performance";
export const AUTHOR_REPORT_NAME = "author";
export const DI_EARNED_MEDIA_REPORT_NAME = "earned_media";
export const DI_BASIC_REPORT_NAME = "basic";
export const DI_PRO_REPORT_NAME = "pro";
export const DI_UPGRADE_REPORT_NAME = "upgrade";

//Report labels constants
export const SIDEBAR_ADS_LABEL = "Sidebar";
export const TEXT_ADS_LABEL = "Text";
export const ANNOUNCEMENT_ADS_LABEL = "Announcement";
export const ATF_HOMEPAGE_LABEL = "ATF Homepage";
export const INSTANT_MESSAGE_LABEL = "Instant Message";
export const NEWSLETTER_BODY_LABEL = "Newsletter Body";
export const DISPLAY_ADS_LABEL = "Display";
export const CONTENT_BODY_ADS_LABEL = "Content Body";
export const INTERSTITIAL_ADS_LABEL = "Interstitial";
export const NEWSLETTER_ADS_LABEL = "Newsletter";
export const ROADBLOCK_ADS_LABEL = "Roadblock";
export const NEWSLETTERS_LABEL = "Newsletters";
export const ORDER_LABEL = "Orders";
export const ALL_ADS_REPORT_LABEL = "Ads";
export const CONTENT_PERFORMANCE_LABEL = "Content Performance";
export const AUTHOR_REPORT_LABEL = "Author";
export const DI_EARNED_MEDIA_LABEL = "Earned Media";
export const DI_BASIC_LABEL = "Base";
export const DI_PRO_LABEL = "Pro";
export const DI_UPGRADE_LABEL = "Upgrade";

// Tooltip/message constants
export const DOWNLOAD_TOOLTIP_TEXT = "The file has not been generated yet. Please wait until processing is complete";
export const DELETE_TOOLTIP_TEXT = "The file is currently being processed. Deletion is unavailable until processing finishes";
export const DELETE_CONFIRMATION_POPUP_TEXT = "Are you sure you want to delete this file?";
export const BUTTON_DISABLE_DELETE_TOOLTIP_TEXT = "Please select at least one row to delete";
export const CHART_CONTEXT = "The chart will be displayed once the table data is loaded";
export const DOWNLOAD_CSV = "Download CSV";
export const GENERATE_XLSX = "Generate XLSX";
export const GENERATE_CSV = "Generate CSV";
export const VIEW = "view";
export const CHART = "chart";
export const GENERATE_LIGHT_XLSX = "Generate Light XLSX";
export const DATE_RANGE_EXCEED_MESSAGE = "Please select Date Range which should not exceed day count more than 90 days";
export const FILTER_SESSION_MESSAGE = "You must switch profiles before deleting the active one.";

export const TOPIC_TAGS_INCLUDE_LABEL  = "Topic Tags (Include)";
export const TOPIC_TAGS_EXCLUDE_LABEL  = "Topic Tags (Exclude)";
export const PLACEMENT_TAG_IN_LABEL  = "Placement Tags (Include)";
export const PLACEMENT_TAG_EX_LABEL  = "Placement Tags (Exclude)";
export const ORGANIZATION_TAGS_IN_LABEL  = "Organization Tags (Include)";
export const ORGANIZATION_TAGS_EX_LABEL  = "Organization Tags (Exclude)";
export const PAGEVIEW_RANGE_LABEL = "Pageview Range";
export const ENGAGEMENT_MEMBER_LABEL = "Engagement Member";
export const PAGE_PARAMETER_LABEL = "Page Parameters";
export const PUBLISHED_DATE_RANGE = "Published Date Range";
export const COMPETITOR_FILTER = "Exclude Competitor Filtering";
export const EXCLUDE_PRE_PUBLISH_PAGEVIEWS  = "Exclude Pre Published Pageviews";

// privacy policy constants
export const PRIVACY_PARAMETERS = "Privacy Parameters";
export const REGULATION_NAME = "Regulation Name";
export const PURPOSE_CONSENT_LABEL = "Purpose Consent";
export const MARKETING_CONSENT_LABEL = "Marketing Consent";
export const DOUBLE_OPTIN_LABEL = "Double Opt-in";
export const DO_NOT_SELL_MY_PERSONAL_INFO_LABEL = "Do Not Sell My Personal Information";
export const CONDITIONING_CONSENT_LABEL = "Conditioning Consent";
export const AGE_VERFICATION_LABEL = "Age Verification";
export const RIGHT_TO_BE_FORGOTTEN_LABEL = "Right To Be Forgotten";
