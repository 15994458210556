import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { fetchOrder, selectValue, addSelectedValue } from "../../../actions";
import OrderPicker from "../order";
import * as actionTypes from "../../../actions/actionTypes";

const OrderMultiSelect = (props) => {

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    const searchOrder = (e) => {
        if (e.target.value.length > 1) {
            props.fetchOrder(
                e.target.value,
                "name",
                actionTypes[props.reportActionType].FETCH_ORDERS,
                undefined,
                undefined,
                props.reportActionType,
            );
        }
    };

    const onSelectOrder = (e, { value }) => {
        dispatchDynamicSelectAction(props, "order", value);
        props.addSelectedValue({ dispatchType: actionTypes[props.reportActionType].ADD_ORDERS, reportType: props.reportActionType, value });
    };

    const onClickOrder = () => {
        props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].CLEAR_ORDERS, reportType: props.reportActionType });
    };

    return (
        <OrderPicker
            onSearchChange={searchOrder}
            value={props.filterData.order}
            onChange={onSelectOrder}
            onClick={onClickOrder}
            order={props.commonSelectData.order}
        />
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        commonSelectData: state.allReportCommonReducer[ownProps.reportActionType],
    };
};

export default compose(connect(mapStateToProps, {
    fetchOrder,
    selectValue,
    addSelectedValue,
}))(OrderMultiSelect);