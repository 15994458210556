import * as actionTypes from "./actionTypes";
import axios from "../api/axios";
import { headers } from "../api/apiConstants";
import { url } from "../api/urlHelper";
import { getApiDate, sanitizePageviewRequestTag } from "../utilities/common";
import { findReportFromURL } from "../components/config/FindReportFromURL";
import { getCookie } from "../utilities/envCommon";

export const filterSelectValue = (payload) => ({
	type: actionTypes.SELVALUES, 
	payload
});

export const addFilterById = (id, dispatchType) => {
	return {
		type: dispatchType,
		payload: id,
	};
};

export const clearSearch = (dispatchType) => {
	return {
	  type: dispatchType,
	};
};

export const selectToggleValue = (payload) => ({
	type: payload.dispatchType,
	payload
});

export const clearFilter = (dispatchType, data) => (dispatch) => {
	dispatch({
		type: dispatchType,
		payload: data
	});
};

export const commonSelectedValue = (payload) => ({
	type: payload.dispatchType,
	payload
});

export const activityCsvLoading = () => {
	return {
		type: actionTypes.ACTIVITYCSVLOADING,
	};
};

export const sortActivityColumn = (column) => {
	return {
		type: actionTypes.SORTACTIVITYREPORT,
		column: column,
	};
};

export const fetchData = (filterData, page, type = "", dispatchType, csvType = "", newEmail, isLeadClient = false, rName = "activity") => async (dispatch) => {
	const reportData = findReportFromURL(url.activityDetailsURL, "");
	const payload = {
		Is_lead_client: isLeadClient,
		organizations: filterData.organization,
		campaigns: filterData.campaign,
		engaged_organizations: filterData.engagedOrganizations,
		orders: filterData.order,
		site: filterData.site !== "all" ? filterData.site : "",
		start_date: getApiDate(filterData.startDate).concat(" 00:00:00"),
		end_date: getApiDate(filterData.endDate).concat(" 23:59:59"),
		override_lead_report_filters: filterData.overrideActivityReportFilters,
		exclude_gated_pageviews: filterData.excludeGatedPageviews,
		order: "ASC",
		order_by: "id",
		include_deleted: filterData.campaignStatus === "all" ? true : false,
		limit: 50,
		page: page,
		export: type,
		engaged_members: filterData?.selEnagedMember,
		applied_date_filter: filterData.appliedDate,
		exclude_email_suppression: filterData.excludeEmailSupression,
		page_header_title: filterData.title,
		utm_source: filterData.selUrlSource,
		utm_medium: filterData.selUrlMedium,
		utm_campaign: filterData.selUrlCampaign,
		utm_term: filterData.selUrlTerm,
		utm_content: filterData.selUrlContent,
		report: rName,
		email: newEmail ? newEmail : filterData.email,
		utm_filters: filterData.utmParams,
		traffic_type: filterData?.trafficType !== "all" ? filterData.trafficType : "",
		traffic_source: filterData?.selTrafficSource !== "All" ? filterData.selTrafficSource : "",
		exclude_anonymous_organization: filterData?.exAnonymousOrgs,
		exclude_anonymous_member: filterData?.exAnonymousMember,
		exclude_internal_events: filterData?.exInternalEvents,
		topic_tags: {
			included_tags: sanitizePageviewRequestTag(filterData?.selTopicTagsIN),
			excluded_tags: sanitizePageviewRequestTag(filterData?.selTopicTagsEX),
		},
		placement_tags: {
			included_tags: sanitizePageviewRequestTag(filterData?.selPlacementTagsIN),
			excluded_tags: sanitizePageviewRequestTag(filterData?.selPlacementTagsEX),
		},
		organization_tags: {
			included_tags: sanitizePageviewRequestTag(filterData?.selOrgTagsIN),
			excluded_tags: sanitizePageviewRequestTag(filterData?.selOrgTagsEX),
		},
		actions: {
			included: filterData?.includeActions,
			excluded: filterData?.excludeActions,
		},
		report_name: reportData ? reportData.report : "",
		report_table_name: rName === "activity" ? (reportData ? reportData.report_table_name : "") : "Light Activity",
		session_id: getCookie("session_id"),
		exclude_competitor_orgs: filterData?.competitorFiltering,
		mentions_views: filterData?.mentions_views ?? false,
		marketplace_views: filterData?.marketplace_views ?? false,
		is_automated: filterData?.isAutomated ?? false,
		automated_header: filterData?.automatedHeader ?? "",
	}
	console.log("🚀 ~ activity ~ data:", payload);
	await axios.post( url.activityDetailsURL, 
		payload,
		{
			headers: headers(),
		}
	)
	.then((res) => {
		if (res.status === 200) {
			if (type) {
				dispatch({
					type: csvType,
					payload: url.activityDetailsURL,
					email: newEmail ? newEmail : filterData.email,
					csv: res.data && res.data,
				});
			} else {
				dispatch({
					type: dispatchType,
					payload: res.data,
					filterData: filterData,
				});
			}
		} else if (res.status === 202) {
			if (type) {
				dispatch({
					type: csvType,
					payload: url.activityDetailsURL,
					email: newEmail ? newEmail : filterData.email,
					csv: res.data && res.data,
				});
			}
		} else if (res.status === 400) {
			dispatch({
				type: actionTypes.ERRORCODE,
				payload: res.data.status,
			});
		}
	})
	.catch((err) => {
		if (err.response) {
			dispatch({
				type: actionTypes.ERRORCODE,
				payload: err.response.status,
			});
		}
	});
};