export const ROLEBASED_ELEMENT_ORDER = "Order";

export const ROLEBASED_ELEMENT_ALL_ADS = "All Ads";
export const ROLEBASED_ELEMENT_DISPLAY_ADS = "Display Ads";
export const ROLEBASED_ELEMENT_TEXT_ADS = "Text Ads";
export const ROLEBASED_ELEMENT_INTERSTITIAL_ADS = "Interstitial Ads";
export const ROLEBASED_ELEMENT_CONTENT_BODY_ADS = "Content Body Ads";
export const ROLEBASED_ELEMENT_NEWSLETTER_ADS = "Newsletter Ads";
export const ROLEBASED_ELEMENT_NEWSLETTER_BODY_ADS = "Newsletter Body Ads";
export const ROLEBASED_ELEMENT_ROADBLOCK_ADS = "Roadblock Ads";
export const ROLEBASED_ELEMENT_ANNOUNCEMENT_ADS = "Announcements Ads";
export const ROLEBASED_ELEMENT_ATF_ADS = "ATF Homepage Ads";
export const ROLEBASED_ELEMENT_INSTANT_MESSAGE_ADS = "Instant Message Ads";
export const ROLEBASED_ELEMENT_SIDEBAR_ADS = "Sidebar Ads";

export const ROLEBASED_ELEMENT_LEAD = "Lead";
export const ROLEBASED_ELEMENT_ENGAGEMENT = "Engagement";
export const ROLEBASED_ELEMENT_SPONSORED = "Sponsored";

export const ROLEBASED_ELEMENT_ACTIVITY = "Activity";
export const ROLEBASED_ELEMENT_AUTHOR = "Author";
export const ROLEBASED_ELEMENT_NEWSLETTERS = "Newsletters";
export const ROLEBASED_ELEMENT_PAGEVIEW = "Pageview";
export const ROLEBASED_ELEMENT_CONTENT_PERFORMANCE = "Content Performance";
export const ROLEBASED_ELEMENT_SIDEBAR = "Sidebar";
export const ROLEBASED_ELEMENT_GOALS = "Goals";
export const ROLEBASED_ELEMENT_SITE = "Site";

export const ROLEBASED_ELEMENT_DECISION_INSIGHTS_BASE = "Decision Insights Base";
export const ROLEBASED_ELEMENT_DECISION_INSIGHTS_PRO = "Decision Insights Pro";
export const ROLEBASED_ELEMENT_DECISION_INSIGHTS_PRO_PLUS = "Decision Insights Pro+";

export const ROLEBASED_ELEMENT_ROLE_MAPPING = "Role Mapping";
export const ROLEBASED_ELEMENT_REPORT_DESCRIPTION = "Report Description";

export const ROLEBASED_ADMIN_ROLES = ["Admin", "Okta Administrators", "Executive Admin", "SDx Reports Admins"];

// charts
export const DISPLAY_AD_CHART = "Display Ad Impression And Click Through Rate (CTR)";
export const INTERSTITIAL_AD_CHART = "Interstitial Ad Impression And Click Through Rate (CTR)";
export const CONTENT_BODY_AD_CHART = "Content Body Ad Impression And Click Through Rate (CTR)";
export const NEWSLETTER_AD_CHART = "Newsletter Ad Impression And Click Through Rate (CTR)";
export const NEWSLETTER_BODY_AD_CHART = "Newsletter Body Ad Impression And Click Through Rate (CTR)";
export const ROADBLOCK_AD_CHART = "Roadblock Ad Impression And Click Through Rate (CTR)";
export const ANNOUNCEMENT_AD_CHART = "Announcements Ad Impression And Click Through Rate (CTR)";
export const ATF_AD_CHART = "ATF Homepage Ad Impression And Click Through Rate (CTR)";
export const INSTANT_MESSAGE_AD_CHART = "Instant Message Ad Impression And Click Through Rate (CTR)";
export const TEXT_AD_CHART = "Text Ad Impression And Click Through Rate (CTR)";
export const SIDEBAR_AD_CHART = "Sidebar Ad Impression And Click Through Rate (CTR)";

// element ads summary based
export const DISPLAY_CAMPAIGN_SUMMARY = "Display Campaign Summary";
export const INTERSTITIAL_CAMPAIGN_SUMMARY = "Interstitial Campaign Summary";
export const CONTENT_BODY_CAMPAIGN_SUMMARY = "Content Body Campaign Summary";
export const NEWSLETTER_CAMPAIGN_SUMMARY = "Newsletter Campaign Summary";
export const NEWSLETTER_BODY_CAMPAIGN_SUMMARY = "Newsletter Body Campaign Summary";
export const ROADBLOCK_CAMPAIGN_SUMMARY = "Roadblock Campaign Summary";
export const ANNOUNCEMENT_CAMPAIGN_SUMMARY = "Announcements Campaign Summary";
export const ATF_CAMPAIGN_SUMMARY = "ATF Homepage Campaign Summary";
export const INSTANT_MESSAGE_CAMPAIGN_SUMMARY = "Instant Message Campaign Summary";
export const TEXT_CAMPAIGN_SUMMARY = "Text Campaign Summary";
export const SIDEBAR_AD_CAMPAIGN_SUMMARY = "Sidebar Campaign Summary";

// element ads campaign details 
export const DISPLAY_AD_CAMPAIGN_DETAILS = "Display Campaign Details";
export const INTERSTITIAL_AD_CAMPAIGN_DETAILS = "Interstitial Campaign Details";
export const CONTENT_BODY_AD_CAMPAIGN_DETAILS = "Content Body Campaign Details";
export const NEWSLETTER_AD_CAMPAIGN_DETAILS = "Newsletter Campaign Details";
export const NEWSLETTER_BODY_AD_CAMPAIGN_DETAILS = "Newsletter Body Campaign Details";
export const ROADBLOCK_AD_CAMPAIGN_DETAILS = "Roadblock Campaign Details";
export const ANNOUNCEMENT_AD_CAMPAIGN_DETAILS = "Announcements Campaign Details";
export const ATF_AD_CAMPAIGN_DETAILS = "ATF Homepage Campaign Details";
export const INSTANT_MESSAGE_AD_CAMPAIGN_DETAILS = "Instant Message Campaign Details";
export const TEXT_AD_CAMPAIGN_DETAILS = "Text Campaign Details";
export const ALL_ADS = "All Ads";
export const SIDEBAR_AD_CAMPAIGN_DETAILS = "Sidebar Campaign Details";

// element ads details
export const DISPLAY_AD_DETAILS = "Display Details";
export const INTERSTITIAL_AD_DETAILS = "Interstitial Details";
export const CONTENT_BODY_AD_DETAILS = "Content Body Details";
export const NEWSLETTER_AD_DETAILS = "Newsletter Details";
export const NEWSLETTER_BODY_AD_DETAILS = "Newsletter Body Details";
export const ROADBLOCK_AD_DETAILS = "Roadblock Details";
export const ANNOUNCEMENT_AD_DETAILS = "Announcements Details";
export const ATF_AD_DETAILS = "ATF Homepage Details";
export const INSTANT_MESSAGE_AD_DETAILS = "Instant Message Details";
export const TEXT_AD_DETAILS = "Text Details";
export const SIDEBAR_AD_DETAILS = "Sidebar Details";

export const allowedAdChart = [
    DISPLAY_AD_CHART,
    CONTENT_BODY_AD_CHART,
    NEWSLETTER_AD_CHART,
    NEWSLETTER_BODY_AD_CHART,
    ROADBLOCK_AD_CHART,
    ANNOUNCEMENT_AD_CHART,
    ATF_AD_CHART,
    INSTANT_MESSAGE_AD_CHART,
    TEXT_AD_CHART,
    INTERSTITIAL_AD_CHART,
    SIDEBAR_AD_CHART
]

export const allowedAdSummaryElements = [
    DISPLAY_CAMPAIGN_SUMMARY,
    CONTENT_BODY_CAMPAIGN_SUMMARY,
    NEWSLETTER_CAMPAIGN_SUMMARY,
    NEWSLETTER_BODY_CAMPAIGN_SUMMARY,
    ROADBLOCK_CAMPAIGN_SUMMARY,
    ANNOUNCEMENT_CAMPAIGN_SUMMARY,
    ATF_CAMPAIGN_SUMMARY,
    INSTANT_MESSAGE_CAMPAIGN_SUMMARY,
    TEXT_CAMPAIGN_SUMMARY,
    INTERSTITIAL_CAMPAIGN_SUMMARY,
    SIDEBAR_AD_CAMPAIGN_SUMMARY
];

export const allowedCampaignDetailElements = [
    DISPLAY_AD_CAMPAIGN_DETAILS,
    CONTENT_BODY_AD_CAMPAIGN_DETAILS,
    NEWSLETTER_AD_CAMPAIGN_DETAILS,
    NEWSLETTER_BODY_AD_CAMPAIGN_DETAILS,
    ROADBLOCK_AD_CAMPAIGN_DETAILS,
    ANNOUNCEMENT_AD_CAMPAIGN_DETAILS,
    ATF_AD_CAMPAIGN_DETAILS,
    INSTANT_MESSAGE_AD_CAMPAIGN_DETAILS,
    TEXT_AD_CAMPAIGN_DETAILS,
    INTERSTITIAL_AD_CAMPAIGN_DETAILS,
    SIDEBAR_AD_CAMPAIGN_DETAILS
]

export const allowedDetailsElements = [
    DISPLAY_AD_DETAILS,
    CONTENT_BODY_AD_DETAILS,
    NEWSLETTER_AD_DETAILS,
    NEWSLETTER_BODY_AD_DETAILS,
    ROADBLOCK_AD_DETAILS,
    ANNOUNCEMENT_AD_DETAILS,
    ATF_AD_DETAILS,
    INSTANT_MESSAGE_AD_DETAILS,
    TEXT_AD_DETAILS,
    INTERSTITIAL_AD_DETAILS,
    SIDEBAR_AD_DETAILS,
]

// orders
export const ORDER_SUMMARY = "Order Summary";

//sidebar role report
export const SIDEBAR_CHART = "Sidebar";
export const SIDEBAR_CAMPAIGN_SUMMARY = "Sidebar Campaign Summary";
export const SIDEBAR_CAMPAIGN_DETAILS = "Sidebar Campaign Detail";
export const SIDEBAR_DETAILS = "Sidebar Detail";

// leads
export const LEAD_PACING = "Pacing";
export const LEAD_STATUS = "Lead Status";
export const LEAD_ACCEPTANCE_RATE = "Lead Acceptance Rate";
export const LEAD_CAMPAIGN_SUMMARY = "Lead Campaign Summary";
export const LEAD_DETAILS = "Lead Detail";

// engagements
export const ENGAGEMENT_PACING = "Pacing";
export const ENGAGEMENT_STATUS_CHART = "Engagement Status";
export const ENGAGEMENT_ACCEPTANCE_RATE = "Engagement Acceptance Rate";
export const ENGAGEMENT_CAMPAIGN_SUMMARY = "Engagement Campaign Summary";
export const ENGAGEMENT_WORKBOOK = "Engagements Workbook";

// activity
export const ACTIVITY = "Activity";

// goals
export const GOALS = "Goals";

// sponosored
export const SPONSORED_CHART = "Sponsored";
export const SPONSORED_CAMPAIGN_SUMMARY = "Sponsored Campaign Summary";
export const SPONSORED_CAMPAIGN_PAGE_DETAILS = "Sponsored Campaign Page Details";
export const SPONSORED_CAMPAIGN_PAGE_VIEW_DETAILS = "Sponsored Campaign Page View Details";

// pageview
export const PAGEVIEW_CHART = "Pageview";
export const PRIMARY_TOPIC_SUMMARY = "Primary Topic Summary";
export const TOPIC_TAG_SUMMARY = "Topic Tag Summary";
export const ORGANIZATION_TAG_SUMMARY = "Organization Tag Summary";
export const PLACEMENT_TAG_SUMMARY = "Placement Tag Summary";
export const PAGE_SUMMARY = "Page Summary";
export const PAGEVIEW_DETAILS_TABLE = "Pageview Details Table";

// author
export const AUTHOR_SUMMARY = "Author Summary";

// content performance
export const CONTENT_PERFORMANCE_SUMMARY_IPA = "Content Performance Summary: IPA";
export const CONTENT_PERFORMANCE_SUMMARY_SENORITY = "Content Performance Summary: Senority";
export const CONTENT_PERFORMANCE_SUMMARY_SECTOR = "Content Performance Summary: Sector";
export const CONTENT_PERFORMANCE_DETAILS = "Content Performance Details";

// sites
export const ALL_PAGEVIEW_SUMMARY = "All Pageview Summary";
export const INDEPENDENT_PAGEVIEW_SUMMARY = "Independent Pageview Summary";
export const SPONSORED_PAGEVIEW_SUMMARY = "Sponsored Pageview Summary";

// newsletter summary
export const NEWSLETTERS_SUMMARY = "Newsletters Summary";
export const NEWSLETTERS_DETAILS = "Newsletters Details";

// decision insights
export const DECISION_INSIGHTS_BASIC = "Decision Insights Base";
export const DECISION_INSIGHTS_PRO = "Decision Insights Pro";
export const DECISION_INSIGHTS_PRO_PLUS = "Decision Insights Pro+";