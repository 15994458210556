import React from "react";
import { Table } from "semantic-ui-react";

const NewsletterTimeComponent = ({ data }) => {
    return (
        <Table.Cell className="space_height" title={data.time}>{data.time}</Table.Cell>
    );
};

export default NewsletterTimeComponent;
