import React from "react";

import * as actionTypes from "../../../actions/actionTypes";
import SingleSelectDropDown from "../../base/singleSelectDropDown";

const NewsletterStatus = (props) => {
  
    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    const val = props.filterData?.newslettersOptions?.statuses ?? []
    const addExtraObj = { label: "All", value: "all" };
    const finalArr = [addExtraObj, ...val]

    return (
        <SingleSelectDropDown
            clearable
            className="no-blink"
            options={finalArr?.map((type, index) => ({
                key: index,
                text: type.label,
                value: type.value,
            })) || []}
            onChange={(e, { value }) => dispatchDynamicSelectAction(props, "newsletterStatus", value)}
            label="Newsletter Status"
            value={props.filterData?.newsletterStatus}
            placeholder="All"
        />
    );
};

export default NewsletterStatus;
