import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { useDispatch } from "react-redux";

import ActionsMultiSelect from "./ActionsMultiSelect";
import activityFilterDropdownJson from "../../reducers/ActivityFilterDropdown.json";
import { filterSelectValue, addFilterById } from "../../actions";

const ActionsCommonMultiSelect = (props) => {
    const dispatch = useDispatch();

    const searchData = () => {
        dispatch({
            type: props.FETCH,
            payload: activityFilterDropdownJson,
            selected: props.selected
        });
    }

    const onSearchAction = (e) => {
        searchData();
    };

    const onSelectActions = (e, { value }) => {
        props.filterSelectValue({ key: props.keyInput, value });
        props.addFilterById(value, props.ADD);
    };

    const onClickActions = () => {
        searchData();
    };

    return (
        <ActionsMultiSelect
            data={props.actionData}
            value={props.value}
            label={props.label}
            // style={{ width: '19.5em' }}
            onSearchChange={onSearchAction}
            onChange={onSelectActions}
            onClick={onClickActions}

        />
    );
};

export default compose( connect(null, {
    filterSelectValue,
    addFilterById,
 })
 )(ActionsCommonMultiSelect);