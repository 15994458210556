import React from "react";
import SearchFilter from "../components/clientLeadEng/searchFilter";
import { connect } from "react-redux";
import { fetchClientLeadEngs, clientLeadCsvLoading, selClientLeadFilterValue, clientLeadDetailCsvLoading, fetchLeadEngSummary, fetchClientLeadEngsSummaryDownloadCsvXlsx } from "../actions";
import "../css/common.css";
import ClientLeadEngSummaryTable from "../components/clientLeadEng/clientLeadEngSummaryTable";
import ClientLeadEngsTable from "../components/clientLeadEng/clientLeadEngsTable";
import { Message } from "semantic-ui-react";
// import ReuseableEmail from "../components/common/reuseableEmail";
import NotifyEmail from "../components/common/notifyEmailConfirmation";
import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";
import ClientLeadChart from "../components/clientLeadEng/clientLeadChart";
import DownloadXLSX from "../components/common/downloadXLSX";
import LeadStatusChart from "../components/clientLeadEng/barCharts/leadStatusChart";
import LeadAcceptanceChart from "../components/clientLeadEng/barCharts/leadAcceptanceChart";
import { downloadCSVData, getReportsByElement } from "../utilities/common";
import { LEAD_ACCEPTANCE_RATE, LEAD_CAMPAIGN_SUMMARY, LEAD_DETAILS, LEAD_PACING, LEAD_STATUS, ROLEBASED_ELEMENT_LEAD } from "../components/config/rolebasedReportNames";
import { CHART, DOWNLOAD_CSV, GENERATE_XLSX, VIEW } from "../components/config/const";

class ClientLeadEngReport extends React.Component {

	getExcelReport = (btnSpecificAction, table_name) => {
		DownloadXLSX(
			btnSpecificAction,
			table_name,
			"client_lead_eng",
			this.props.filterData,
			this.props.userEmail,
			actionTypes.CLIENTLEADDETAILEXCEL,
			this.props.fetchClientLeadEngsSummaryDownloadCsvXlsx
		)
	};

	getLeadEngsCSVData = async () => {
		this.props.clientLeadCsvLoading();
		await this.props.fetchClientLeadEngsSummaryDownloadCsvXlsx(
			this.props.filterData,
			1,
			"CSV",
			"",
			actionTypes.CLIENTLEADENGSCSV,
			"",
			true
		);
		if (this.props.leadEngsCsv) {
			downloadCSVData(this.props.leadEngsCsv, this.props.downloadFileName)
		}
	};

	render() {
		let tableView;
		let leadEngsDownload;
		let excelDownloadButton;
		const roleReports = getReportsByElement(this?.props?.roleFilterData?.currentUserReportRoleData, this?.props?.roleFilterData?.getAllReports, ROLEBASED_ELEMENT_LEAD);

		if (this.props.isFilApplied) {
			excelDownloadButton = (
				<div>
					<button
						className="download-button"
						disabled={false}
						onClick={() => this.getExcelReport(actionTypes.CLIENTLEADEXCELSUMMARY, "client_lead_eng")}
					>
						Generate XLSX
					</button>
				</div>
			)
			leadEngsDownload = (
				<div>
					<button
						className="download-button"
						disabled={false}
						onClick={() => this.getLeadEngsCSVData()}
					>
						Download CSV
					</button>
				</div>
			);
		} else {
			tableView = <Message style={{ marginTop: "50px" }}>Please proceed with applying filter.</Message>;
		}
		return (
			<div>
				<div className="page-name-comp">
					<p className="page-name">Leads</p>
					<p>
						{this.props.reportDescription.find(report => report.name === "Leads")?.description}
					</p>
				</div>
				<SearchFilter roleReports={roleReports}/>
				{
					(roleReports?.some((report) => [LEAD_PACING].includes(report.element) && report.permission === CHART)) &&
					<ClientLeadChart/>
				}
				{
					(roleReports?.some((report) => [LEAD_STATUS].includes(report.element) && report.permission === CHART)) &&
					<LeadStatusChart/>
				}
				{
					(roleReports?.some((report) => [LEAD_ACCEPTANCE_RATE].includes(report.element) && report.permission === CHART)) &&
					<LeadAcceptanceChart/>
				}
				<div>
					{
						roleReports?.some(
							(report) =>
								[LEAD_CAMPAIGN_SUMMARY].includes(report.element) &&
								[VIEW, DOWNLOAD_CSV, GENERATE_XLSX].includes(report.permission)
						) &&
						<div className="table-heading-block">
							<div className="table-name">
								<p>Lead Campaign Summary</p>
							</div>
							<div className="class-relative">
								{
									this.props.isFilApplied && (roleReports?.some((report) => [LEAD_CAMPAIGN_SUMMARY].includes(report.element) && report.permission === DOWNLOAD_CSV)) &&
									<div className="csv-button">
										{leadEngsDownload}
									</div>
								}
								{
									this.props.isFilApplied && (roleReports?.some((report) => [LEAD_CAMPAIGN_SUMMARY].includes(report.element) && report.permission === GENERATE_XLSX)) &&
									<div className="excel-button">
										{excelDownloadButton}
										{this.props.postEmailUpdateClientLeadSummary ? (
											<NotifyEmail
												email={this.props.postEmailUpdateClientLeadSummary}
												table="client_lead_summary"
												cardPopup={this.props.postEmailUpdateClientLeadSummary}
												cardPopupClose={actionTypes.SETCLIENTLEADSUMMARYPOPUPPOSTEMAIL}
											/>
										) : null}
									</div>
								}
								
							</div>
						</div>
					}
				</div>
				{
					(roleReports?.some((report) => [LEAD_CAMPAIGN_SUMMARY].includes(report.element) && report.permission === VIEW)) &&
					<div><ClientLeadEngSummaryTable/></div>
				}
				
				{
					(roleReports?.some((report) => [LEAD_DETAILS].includes(report.element) && report.permission === VIEW)) &&
					<React.Fragment>
						<div className="table-heading-block">
							<div className="table-name">
								Lead Detail
							</div>
							{this.props.isFilApplied && !_.isEmpty(this.props.leadEngs) && <p className="total_lead_count" style={{ marginTop: "5px" }}>Totals: {this.props.totalLeadCount}</p>}
						</div>
						<ClientLeadEngsTable roleReports={roleReports}/>
					</React.Fragment>
				}
				
				{tableView}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		leadValidationBarChart: state.clientLeadFetchData.leadValidationBarChart,
		leadValidationChart: state.clientLeadFetchData.leadValidationChart,
		isFilApplied: state.clientLeadFetchData.isFilApplied,
		leadEngsCsv: state.clientLeadFetchData.leadEngsCsv,
		filterData: state.clientLeadFilterData,
		isLoadingLeadEngsCsv: state.clientLeadFetchData.isLoadingLeadEngsCsv,
		userEmail: state.authorized.userEmail,
		emailClientLeadSummary: state.clientLeadFetchData.emailClientLeadSummary,
		cardPopupClientLeadSummary: state.clientLeadFetchData.cardPopupClientLeadSummary,
		postEmailUpdateClientLeadSummary:
			state.clientLeadFetchData.postEmailUpdateClientLeadSummary,
		totalLeadCount: state.clientLeadFetchData.totalLeadCount,
		leadEngs: state.clientLeadFetchData.leadEngs,
		downloadFileName: state.clientLeadFetchData.downloadFileName,
		reportDescription: state.allFilterReportData.reportDescription,
		roleFilterData: state.allFilterReportData,
	};
};

export default connect(mapStateToProps, {
	fetchClientLeadEngs,
	clientLeadCsvLoading,
	selClientLeadFilterValue,
	clientLeadDetailCsvLoading,
	fetchLeadEngSummary,
	fetchClientLeadEngsSummaryDownloadCsvXlsx
})(ClientLeadEngReport);
