import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { fetchTags, addSelectedValue, clearSearchForAllReport, } from "../../../actions";
import * as actionTypes from "../../../actions/actionTypes";
import _ from "lodash";
import { ORGANIZATION_TAGS_IN_LABEL } from "../../config/const";
import TagsPicker from "../tags";

const PlacementTagInSelection = (props) => {

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    const onSearchHandler = (e) => {
        if (e.target.value.length > 1) {
            props.fetchTags(
                e.target.value,
                "organization",
                actionTypes[props.reportActionType].FETCH_ORGANIZATION_TAGS_IN,
                props.filterData.selOrganiztionTagEX,
                props.filterData.site,
                props.reportActionType
            );
        }
    };

    const onChangeHandler = (e, { value }) => {
        const selectedValue = value[value.length - 1];
        if (props.filterData.selOrganiztionTagEX.indexOf(selectedValue) === -1) {
            dispatchDynamicSelectAction(props, "selOrganiztionTagIN", value);
            props.addSelectedValue({ dispatchType: actionTypes[props.reportActionType].ADD_ORGANIZATION_TAGS_IN, reportType: props.reportActionType, value });
        }
    };

    const onClickHandler = () => {
        if (_.isEmpty(props.filterData.selOrganiztionTagIN)) {
            props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].CLEAR_ORGANIZATION_TAGS_IN, reportType: props.reportActionType });
        }
    };

    return (
        <TagsPicker
            sites={props.sites}
            tags={props.commonSelectData.organizationTagsIn}
            value={props.filterData.selOrganiztionTagIN}    
            onSearchChange={onSearchHandler}
            onClick={onClickHandler}
            onChange={onChangeHandler}
            label={ORGANIZATION_TAGS_IN_LABEL}
            placeholder={ORGANIZATION_TAGS_IN_LABEL}
        />
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        commonSelectData: state.allReportCommonReducer[ownProps.reportActionType],
        sites: Object.values(state.sites),
    };
};

export default compose(connect(mapStateToProps, {
    fetchTags,
    addSelectedValue,
    clearSearchForAllReport,
}))(PlacementTagInSelection);