export const HeaderData = [
    { headerName: "Status", headerKey: "status"},
    { headerName: "Newsletter Type", headerKey: "type"},
    { headerName: "Newsletter Template", headerKey: "template", className: "custom-column-large" },
    { headerName: "Newsletter Name", headerKey: "newsletter_title", className: "custom-column-large" },
    { headerName: "Newsletter Send Date", headerKey: "date_newsletter"},
    { headerName: "Newsletter Send time", headerKey: "time_newsletter" },
    { headerName: "Day Of Week", headerKey: "day_of_week" },
    { headerName: "Subject Line", headerKey: "subject", className: "custom-column-large" },
    { headerName: "Emails Sent", headerKey: "sends", textAlign: "right" },
    { headerName: "Bounced Emails", headerKey: "bounced_emails", textAlign: "right" },
    { headerName: "Blocked Emails", headerKey: "blocked_emails", textAlign: "right" },
    { headerName: "Reported as Spam Email", headerKey: "spam_reports", textAlign: "right" },
    { headerName: "Emails Delivered", headerKey: "emails_delivered", textAlign: "right" },
    { headerName: "Opens", headerKey: "opens", textAlign: "right" },
    { headerName: "Unique Opens", headerKey: "unique_opens", textAlign: "right" },
    { headerName: "Open Rate", headerKey: "open_rate", textAlign: "right" },
    { headerName: "Unique Open Rate", headerKey: "unique_open_rate", textAlign: "right" },
    { headerName: "Slot No.", headerKey: "slot_no", textAlign: "right" },
    { headerName: "Slot Name", headerKey: "id" },
    { headerName: "Sub Slot Name", headerKey: "sub_slot_name" },
    { headerName: "Slot Item", headerKey: "slot_item", className: "custom-column-large" },
    { headerName: "Clicks", headerKey: "clicks", textAlign: "right" },
    { headerName: "Unique Clicks", headerKey: "unique_clicks", textAlign: "right" },
    { headerName: "Click Rate", headerKey: "ctr", textAlign: "right" },
    { headerName: "Unique Click Rate", headerKey: "unique_ctr", textAlign: "right" },
];