import React, { useCallback, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import DateAndFilter from "../components/Activity/DateAndFilter";
import EngagementWorkbench from "../components/Activity/EngagementWorkbench";
import DownloadXLSX from "../components/common/downloadXLSX";
import NotifyEmailConfirmation from "../components/common/notifyEmailConfirmation";
import { fetchData, clearSearch, selectToggleValue, filterSelectValue, addFilterById } from "../actions";
import * as actionTypes from "../actions/actionTypes";
import "../css/common.css";
import { clearCookie, getCookie } from "../utilities/envCommon";
import { getDisplayDate, getSelectedIds, getStartEndDates } from "../utilities/common";
import _ from "lodash";
import { getReportsByElement } from "../utilities/common";
import { ACTIVITY, ROLEBASED_ELEMENT_ACTIVITY } from "../components/config/rolebasedReportNames";
import { GENERATE_CSV, GENERATE_LIGHT_XLSX, VIEW } from "../components/config/const";
import ActivityFilterDropDown from "../reducers/ActivityFilterDropdown.json";

const Activity = (props) => {
    const { isFilApplied, postEmailUpdate, filterData, userEmail, fetchData, lightPostEmailUpdate } = props;
    const [isLoading, setIsLoading] = React.useState(false);
    const dispatch = useDispatch();

    const handleActionFilter = (key, filterKey, actionTypeFetch, actionTypeSelect, element) => {
        if (element?.populate_tags_filter?.action_in?.[filterKey]) {
            const result = ActivityFilterDropDown.filter(action => 
                element?.populate_tags_filter?.action_in?.[filterKey].includes(action.value)
            );
            
            props.filterSelectValue({ key, value: element?.populate_tags_filter?.action_in?.[filterKey] });
    
            dispatch({
                type: actionTypeFetch,
                payload: ActivityFilterDropDown,
                selected: result,
            });
    
            dispatch({
                type: actionTypeSelect,
                data: result || [],
            });
        }
    };

    const processFilterData = () => async (dispatch, getState) => {
        const diDataFilter = getCookie("di_filter_data");
        const element = JSON.parse(diDataFilter);

        if (!diDataFilter) return;
        setIsLoading(true);
        dispatch({
            type: actionTypes.SELINIACTIVITYENGORGS,
            data: element?.eng_filter ?? [],
        });
        dispatch({
            type: actionTypes.SELINIACTIVITYORGS,
            data: element?.org_filter ?? [],
        });
        dispatch({
            type: actionTypes.SELINIACTIVITYTOPICTAGSIN,
            data: getSelectedIds(element?.tags, element?.populate_tags_filter?.topic_tags?.included_tags || []),
        });
        dispatch({
            type: actionTypes.SELINIACTIVITYTOPICTAGSEX,
            data: getSelectedIds(element?.tags, element?.populate_tags_filter?.topic_tags?.excluded_tags || []),
        });

        dispatch({
            type: actionTypes.SELINIACTIVITYPLACEMENTTAGSIN,
            data: getSelectedIds(element?.tags, element?.populate_tags_filter?.placement_tags?.included_tags || []),
        });
        dispatch({
            type: actionTypes.SELINIACTIVITYPLACEMENTTAGSEX,
            data: getSelectedIds(element?.tags, element?.populate_tags_filter?.placement_tags?.excluded_tags || []),
        });

        dispatch({
            type: actionTypes.SELINIACTIVITYORGANIZATIONTAGSIN,
            data: getSelectedIds(element?.tags, element?.populate_tags_filter?.organization_tags?.included_tags || []),
        });
        dispatch({
            type: actionTypes.SELINIACTIVITYORGANIZATIONTAGSEX,
            data: getSelectedIds(element?.tags, element?.populate_tags_filter?.organization_tags?.excluded_tags || []),
        });

        handleActionFilter(
            "includeActions",
            "included",
            actionTypes.FETCH_INCLUDE_ACTION_ACTIVITY,
            actionTypes.SELECT_INCLUDE_ACTION_ACTIVITY,
            element
        );
        
        handleActionFilter(
            "excludeActions",
            "excluded",
            actionTypes.FETCH_EXCLUDE_ACTION_ACTIVITY,
            actionTypes.SELECT_EXCLUDE_ACTION_ACTIVITY,
            element
        );
    
        props.selectToggleValue({ dispatchType: actionTypes.SELECTED_TOGGLE, key: 'exAnonymousMember', value: element?.exclude_anonymous_member });
        props.selectToggleValue({ dispatchType: actionTypes.SELECTED_TOGGLE, key: 'exInternalEvents', value: element?.exclude_internal_events });

        let dates = element?.applied_date_filter ? getStartEndDates(element.applied_date_filter) : "2";
        const orgUuids = _.map(element?.org_filter, "uuid");
        const engUuids = _.map(element?.eng_filter, "uuid");
        
        let tempData = props.filterData;
        tempData.startDate = element.applied_date_filter === "Custom Date Range" ? getDisplayDate(element.start_date) : dates.startDate;
        tempData.endDate = element.applied_date_filter === "Custom Date Range" ? getDisplayDate(element.end_date) : dates.endDate;
        tempData.appliedDate = element.applied_date_filter;
        tempData.site = element.site;
        tempData.selTopicTagsIN = element?.populate_tags_filter?.topic_tags?.included_tags || [];
        tempData.selTopicTagsEX = element?.populate_tags_filter?.topic_tags?.excluded_tags || [];
        tempData.selPlacementTagsIN = element?.populate_tags_filter?.placement_tags?.included_tags || [];
        tempData.selPlacementTagsEX = element?.populate_tags_filter?.placement_tags?.excluded_tags || [];
        tempData.selOrgTagsIN = element?.populate_tags_filter?.organization_tags?.included_tags || [];
        tempData.selOrgTagsEX = element?.populate_tags_filter?.organization_tags?.excluded_tags || [];
        tempData.organization = orgUuids || [];
        tempData.engagedOrganizations = engUuids || [];
        tempData.mentions_views = element?.mentions_views;
        tempData.marketplace_views = element?.marketplace_views;
        tempData.exAnonymousMember = element?.exclude_anonymous_member;
        tempData.exInternalEvents = element?.exclude_internal_events;
        tempData.isAutomated = element?.isAutomated;
        tempData.automatedHeader = element?.automatedHeader;
        
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);

        props.clearSearch(actionTypes.APPLYACTIVITYFILTER);
        await props.fetchData(props.filterData, 1, "", actionTypes.ACTIVITYTABLE);
        clearCookie("di_filter_data");
    }

    useEffect(() => {
        dispatch(processFilterData());
        // eslint-disable-next-line
    }, []);

    const roleReports = getReportsByElement(props?.roleFilterData?.currentUserReportRoleData, props?.roleFilterData?.getAllReports, ROLEBASED_ELEMENT_ACTIVITY);

    const getExcelReport = useCallback((btnSpecificAction, table_name, reportNames) => {
        DownloadXLSX(
            btnSpecificAction,
            table_name,
            reportNames,
            filterData,
            userEmail,
            reportNames === "activity" ? actionTypes.ACTIVITYEXCEL : actionTypes.APPLY_LIGHT_ACTIVITY_XLSX,
            fetchData
        );
    }, [filterData, userEmail, fetchData]);

    return (
        <React.Fragment>
            {
                isLoading && <div className="main-loader-overlay"><div className="main-loader"></div></div>
            }
            <div className="page-name-comp">
                <p className="page-name">Activity Report</p>
                <p>{props.reportDescription.find(report => report.name === "Activity")?.description}</p>
            </div>
            <DateAndFilter roleReports={roleReports}/>
            <div>
            {
                roleReports?.some(
                    (report) =>
                        [ACTIVITY].includes(report.element) &&
                        [VIEW, GENERATE_CSV, GENERATE_LIGHT_XLSX].includes(report.permission)
                ) &&
                <div className="table-heading-block">
                    <div className="table-name">
                        <p>Activity Report</p>
                    </div>
                    <div className="class-relative">
                        {
                            isFilApplied && (roleReports?.some((report) => [ACTIVITY].includes(report.element) && report.permission === GENERATE_CSV)) &&
                            <React.Fragment>
                                <div className="excel-button">
                                    <div>
                                        <button
                                            className="download-button"
                                            onClick={() => getExcelReport("", "activity_report", "activity")}
                                        >
                                            Generate CSV
                                        </button>
                                    </div>
                                </div>
                                <div className="pg popup">
                                    {postEmailUpdate && (
                                        <NotifyEmailConfirmation
                                            email={postEmailUpdate}
                                            table="activity_details"
                                            cardPopup={postEmailUpdate}
                                            cardPopupClose={actionTypes.SETACTIVITYPOPUPPOSTEMAIL}
                                            csvExcel={true}
                                        />
                                    )}
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    { isFilApplied && (roleReports?.some((report) => [ACTIVITY].includes(report.element) && report.permission === GENERATE_LIGHT_XLSX)) && (
                        <div className="class-relative">
                            <div className="excel-button">
                                <button
                                    className={`download-button`}
                                    onClick={() => getExcelReport("", "light_activity_report", "light-activity")}
                                >
                                    Generate Light XLSX
                                </button>
                            </div>
                            <div className="pg popup">
                                {lightPostEmailUpdate && (
                                    <NotifyEmailConfirmation
                                        email={lightPostEmailUpdate}
                                        table="light_activity_details"
                                        cardPopup={lightPostEmailUpdate}
                                        cardPopupClose={actionTypes.SET_LIGHT_ACTIVITY_POPUP_POST_EMAIL}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            }
            </div>
            {
                (roleReports?.some((report) => [ACTIVITY].includes(report.element) && report.permission === VIEW)) &&
                <EngagementWorkbench props={props} />
            }
        </React.Fragment>
    );
};


const mapStateToProps = (state) => ({
    isFilApplied: state.activityFetchData.isFilApplied,
    filterData: state.filterData,
    activityCsv: state.activityFetchData.activityCsv,
    activityData: state.activityFetchData.activityData,
    userEmail: state.authorized.userEmail,
    postEmailUpdate: state.activityFetchData.postEmailUpdate,
    lightPostEmailUpdate: state.activityFetchData.lightPostEmailUpdate,
    reportDescription: state.allFilterReportData.reportDescription,
    roleFilterData: state.allFilterReportData,
});

export default compose(connect(mapStateToProps, {
    fetchData,
    clearSearch,
    selectToggleValue,
    filterSelectValue,
    addFilterById
}))(Activity);
