import React, {useEffect, useState} from "react";
import Accordion from "../campaign/accordion";
import {
  getDateEng,
  getTimeEng
} from "../../utilities/common";
import {url} from "../../api/urlHelper";
import * as actionTypes from "../../actions/actionTypes";
import axios from "../../api/axios";
import { headers } from "../../api/apiConstants";
import { titleCase } from "../../utilities/common";

const ClientLeadChangedLog = (props) => {

  const [state, setState] = useState([])

  useEffect((dispatch)=>{
    axios.post(
      url.changedLogs,
      {
        Is_lead_client: props.isLeadClient,
        item_ids: props.data.item_ids,
      },
      {
        headers: headers(),
      }
    ).then((res) => {
      setState(res.data);
  })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: actionTypes.ERRORCODE,
          payload: err.response.status,
        });
      }
    });
// eslint-disable-next-line
  },[])

  let items = []
  if (state.length >0) {
    
    
    state.forEach((e)=>{
      let item = {}
      let content = {}
      if (!e.changed_by_wp_user.name.length > 0 ){
      item.title = getDateEng(e.created_at) +" "+ getTimeEng(e.created_at)
      content.new_value = e.new_val
      content.old_value = e.old_val
      item.content = content
    }else{
      item.title = titleCase(e.changed_by_wp_user.name) +" "+ getDateEng(e.created_at) +" "+ getTimeEng(e.created_at)
      content.new_value = e.new_val
      content.old_value = e.old_val
      item.content = content
    }

    items.push(item)

    })
  }

  return (
    <div>
      <Accordion items={items} report={props.report}></Accordion>
    </div>
  );
};

export default ClientLeadChangedLog;
