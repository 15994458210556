export const AdsDetailHeader = [
    { headerName: "ORDER", headerKey: "order_detail" }, 
    { headerName: "CAMPAIGN", headerKey: "campaign_detail" }, 
    { headerName: "AD ORDER", headerKey: "ad_order" }, 
    { headerName: "DATE", headerKey: "date" }, 
    { headerName: "TIME", headerKey: "time" }, 
    { headerName: "SESSION ID", headerKey: "session_id" }, 
    { headerName: "GROUP ID", headerKey: "group_uuid" }, 
    { headerName: "LINE ITEM", headerKey: "line_item" }, 
    { headerName: "CREATIVE", headerKey: "creative" }, 
    { headerName: "CREATIVE SIZE", headerKey: "creative_size" }, 
    { headerName: "AD UNIT", headerKey: "ad_unit" }, 
    { headerName: "MEDIA TYPE", headerKey: "media_type" }, 
    { headerName: "ACTION", headerKey: "action" },
    { headerName: "IP ADDRESS", headerKey: "ip_address" },
    { headerName: "PAGE TITLE", headerKey: "page_title" },
    { headerName: "PRIMARY TOPIC", headerKey: "primary_topic" }, 
    { headerName: "TOPIC TAGS", headerKey: "topic_tags" },
    { headerName: "ORGANIZATION TAGS", headerKey: "organization_tags" },
    { headerName: "PLACEMENT TAGS", headerKey: "placement_tags" },
    { headerName: "ORGANIZATION", headerKey: "organization" },
    { headerName: "ORGANIZATION TYPE", headerKey: "organization_type" },
    { headerName: "ORGANIZATION REVENUE", headerKey: "estimated_revenue" },
    { headerName: "ORGANIZATION SIZE", headerKey: "size" },
    { headerName: "SECTOR", headerKey: "sector" },
    { headerName: "INDUSTRY GROUP", headerKey: "industry_group" },
    { headerName: "INDUSTRY", headerKey: "industry" }, 
    { headerName: "SUB INDUSTRY", headerKey: "sub_industry" },
    { headerName: "FIRST NAME", headerKey: "first_name" },
    { headerName: "LAST NAME", headerKey: "last_name" },
    { headerName: "MEMBER SENIORITY", headerKey: "seniority" },
    { headerName: "JOB FUNCTION", headerKey: "job_function" },
    { headerName: "JOB TITLE", headerKey: "job_title" },
    { headerName: "CITY", headerKey: "city" },
    { headerName: "STATE/PROVINCE", headerKey: "state" },
    { headerName: "COUNTRY", headerKey: "country" },
    { headerName: "POSTAL CODE/ZIP", headerKey: "postal_code" }, 
    { headerName: "TIMEZONE", headerKey: "timezone" },
    { headerName: "TRAFFIC SOURCE", headerKey: "traffic_source" },
    { headerName: "UTM SOURCE", headerKey: "source" },
    { headerName: "UTM MEDIUM", headerKey: "utm_medium" },
    { headerName: "UTM CAMPAIGN", headerKey: "utm_campaign" },
    { headerName: "UTM TERM", headerKey: "utm_term" },
    { headerName: "UTM CONTENT", headerKey: "utm_content" },
];