import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getTableHeaders, sendTableHeaders, sortActivityColumn } from "../../actions";
import * as actionTypes from "../../actions/actionTypes";
import { SORT_ICON } from "../config/TableHeaderNames";
import _ from "lodash";
import ActivityHeader from "./ActivityHeader";
import SortedMenuComponent from "../common/SortedMenuComponent";
import HeadersWithoutSortable from "../common/HeadersWithoutSortable";
import { Table, Message } from "semantic-ui-react";
import Loading from "../common/Loding";
import ActivityMain from "./ActivityDetails/Main";
import DynamicDropdownsFilter from "./DynamicDropdownsFilter";
import useStickyColumnsEffect, { resetLeftStyles } from "../../utilities/stickeyStyle";

const EngagementWorkbench = (props) => {
   const [selectHeaders, setSelectHeaders] = useState();
   const [finalHeaderData, setFinalHeaderData] = useState();
   const [isSortIcon, setSortIcon] = useState(false);
   const addSortIcon = { headerName: "SORT_ICON", sorted: "", name: "" };
   const [freezeColPos, setFreezeColPos] = useState(-1)

   const handleRightClickColumn = (callIndex) => (event) => {
      event.preventDefault();
      const { activityData } = props.props

      if (!_.isEmpty(activityData)) {
         if (freezeColPos === callIndex) {
            resetLeftStyles()
            setFreezeColPos(-1)
         } else {
            resetLeftStyles()
            setFreezeColPos(callIndex)
         }
      }
   }

   useStickyColumnsEffect(freezeColPos)

   useEffect(()=> {
      props.getTableHeaders("engagement_workbench", actionTypes.GETHEADER);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      const headerDataVal = props.getDynamicHeaderData;
      if(!_.isEmpty(headerDataVal)){
         setSelectHeaders(headerDataVal)
         setFinalHeaderData(headerDataVal)
      } else if(headerDataVal === undefined || _.size(headerDataVal) === 0) {
         setSelectHeaders(ActivityHeader);
         setFinalHeaderData(ActivityHeader);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.getDynamicHeaderData]);

   const clickOnSortIcon = () => {
      setSortIcon(!isSortIcon);
   };

   const clickOnSortHeader = (e, extraData, headerData) => {
      if (extraData.checked) {
        setSelectHeaders([...selectHeaders, headerData]);
      } else {
         const removeData = selectHeaders.filter(
            (hName) => hName.headerName !== headerData.headerName
         );
         setSelectHeaders(removeData);
      }
    };

   const applyButtonClick = () => {
      const removeSortIconVal = selectHeaders.filter(rowData => rowData.headerName !== SORT_ICON);

      //added new funcatality accroding to sortable column
      const constantHeadersArr = [...ActivityHeader, addSortIcon];
      let finalHeaderArray = [];
      constantHeadersArr.map((mainHeader)=> {
         return _.map(removeSortIconVal, function(selectHeader) {
            if(mainHeader.headerName === selectHeader.headerName) {
               finalHeaderArray.push(selectHeader);
            }
         })
      });
      setFinalHeaderData(finalHeaderArray);
      props.sendTableHeaders("engagement_workbench", finalHeaderArray, actionTypes.SETHEADER)
      setSortIcon(!isSortIcon);
   };

   const freezeClassName = freezeColPos >= 0 ? "sticky-cols-header" : ""
   return (
      <React.Fragment>
         {
            !_.isEmpty(props.backupActivityData) &&
            <div>
               <DynamicDropdownsFilter props={props} filterData={props.filterData}/>
            </div>
         }
         <SortedMenuComponent
            isSortIcon={isSortIcon}
            clickOnSortIcon={clickOnSortIcon}
            EngsTableData={ActivityHeader}
            selectHeaders={selectHeaders}
            clickOnSortHeader={clickOnSortHeader}
            applyButtonClick={applyButtonClick}
            notClickableHeaders={[]}
         />
         <div className="sortable-table-component" style={{ paddingTop: "30px" }}>
            <div className="sticky-table-container">
               <Table sortable fixed singleLine>
                  <Table.Header>
                     <Table.Row>
                     <Table.HeaderCell className={freezeClassName}></Table.HeaderCell>
                     {
                        !_.isEmpty(finalHeaderData) &&
                        <HeadersWithoutSortable
                           propData={props}
                           headerData={finalHeaderData}
                           action={props.sortActivityColumn}
                           column={props.column}
                           direction={props.direction}
                           handleRightClickColumn={handleRightClickColumn}
                           freezeCols={freezeColPos}
                        />
                     }
                     </Table.Row>
                  </Table.Header>
                  {
                     !_.isEmpty(props.activityData) &&
                     <ActivityMain
                        props={props}
                        finalHeaderData={finalHeaderData}
                        freezeCols={freezeColPos}
                     />
                  }
               </Table>
            </div>
         </div>
         { 
            props.isFilApplied && !props.loading && _.isEmpty(props.activityData) &&
               <Message>No results found.</Message> 
         }
         { props.loading && <Loading/> }
      </React.Fragment>
   );
};

const mapStateToProps = (state) => {
   return {
      getDynamicHeaderData: state.activityFetchData.getDynamicHeaderData,
      loading: state.activityFetchData.isLoading,
      activityData: state.activityFetchData.activityData,
      isFilApplied: state.activityFetchData.isFilApplied,
      column: state.activityFetchData.column,
      direction: state.activityFetchData.direction,
      backupActivityData: state.activityFetchData.backupActivityData,
   };
};

export default connect(mapStateToProps, {
   getTableHeaders,
   sendTableHeaders,
   sortActivityColumn
})(EngagementWorkbench);