import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Loader, Message, Table } from "semantic-ui-react";
import _ from "lodash";

import GenericTable from "./GenericTable";
import { columnConfigs } from "./ColumnConfig";
import { selectValue, fetchAdCampaignSummary } from "../../../actions";
import { AdSummaryHeader } from "./HeaderData/AdsSummaryHeader";
import { AdSNewsletterHeader } from "./HeaderData/AdsNewsletterHeader";
import { AdsRoadblockHeader } from "./HeaderData/AdsRoadblockHeader";
import * as actionTypes from "../../../actions/actionTypes";
import ShowMoreButton from "../../common/showMoreComponent";
import { NewsletterReportLocation, RoadblockReportLocation } from "../../config/PathNames";
import { useLocation } from "react-router-dom";

const headerMap = {
    [NewsletterReportLocation]: AdSNewsletterHeader,
    [RoadblockReportLocation]: AdsRoadblockHeader
};

const AdsSummary = (props) => {
    const [summaryHeader, setSummaryHeader] = useState([]);
    const location = useLocation();

    const showMore = async () => {
        props.selectValue({ 
            dispatchType: actionTypes[props.reportActionType].SELECTVALUE, 
            reportType: props.reportActionType, 
            key: "isLoadingCampaign", 
            value: true
        });
        props.fetchAdCampaignSummary(
            props.filterData,
            props.filterData.campaignPageCount,
            "",
            actionTypes[props.reportActionType].SHOW_MORE_CAMPAIGN,
            props.reportName,
            props.reportActionType,
        );
    };

    useEffect(() => {
        setSummaryHeader(headerMap[location.pathname] || AdSummaryHeader);
    }, [location.pathname]);

    return (
        <div>
            <div className="sortable-table-component">
                <Table sortable fixed singleLine>
                    <GenericTable
                        data={props.filterData.campaignSummaryData}
                        headerOrder={summaryHeader}
                        columnConfigs={columnConfigs}
                    />
                </Table>
            </div>
            {
                props.filterData.isLoadingCampaign &&
                <div className="show-loader">
                    <Loader active inline="centered" />
                </div>
            }
            {
                !_.isEmpty(props.filterData.campaignSummaryData) &&
                <ShowMoreButton onClick={showMore} isDisabled={props.filterData.isCampaignShowMore}/>
            }
            {
                props.filterData.isFilApplied && !props.filterData.isLoadingCampaign && _.isEmpty(props.filterData.campaignSummaryData) &&
                <Message>No results found.</Message>
            }
        </div>
    );
};

export default compose(connect(null, {
    selectValue,
    fetchAdCampaignSummary,

}))(AdsSummary);