import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";
import {
  getDisplayDate,
  getSortDirAbbr,
  getYesterdaysDate,
  getLastDays,
} from "../utilities/common";
import { siteValue } from "../constants";

const editorialFilterData = {
  startDate: getDisplayDate(getLastDays(0)),
  endDate: getDisplayDate(getYesterdaysDate()),
  appliedDate: "2",
  previousStartDate: "",
  previousEndDate: "",
  prevYearEndDate: "",
  selCutomRangeChkBox: false,
  checkboxState: false,
  organization: [],
  campaign: [],
  order: [],
  site: siteValue,
  campaignStatus: "",
  selPageTitle: "",
  selAuthor: "",
  selCountry: [],
  selOrgTagsIN: [],
  selOrgTagsEX: [],
  selTopicTagsIN: [],
  selTopicTagsEX: [],
  selPlacementTagsIN: [],
  selPlacementTagsEX: [],
  selSenioritiesIN: [],
  selSenioritiesEX: [],
  selJobFunctionsEX: [],
  selJobFunctionsIN: [],
  selJobTitlesIN: "",
  selJobTitlesEX: "",
  selSector: [],
  selIndustryGroup: [],
  selIndustry: [],
  selSubIndustry: [],
  selOrgType: [],
  selOrgSize: [],
  selOrgRevenue: [],
  selUrlSource: "",
  selUrlMedium: "",
  selUrlCampaign: "",
  selUrlTerm: "",
  selUrlContent: "",
  selPageViewsByOrg: [],
  excludeInternalEvents: false,
  excludeInternalCampaign: false,
  showPageviewChart: true,
  selectTopicSummaryTable: false,
  selectPlaceSummaryTable: false,
  selectOrganizationSummaryTable: false,
  selectPageSummaryTable: false,
  firmographicToggle: false,
  email: "",
  includedVerticals: [],
  personaToggle: false,
  pageParams: false,
  selEnagedMember: [],
  appliedDateFilter: "2",
  wp_startDate: getDisplayDate(getLastDays(0)),
  wp_endDate: getDisplayDate(getYesterdaysDate()),
  selPrimaryTopic: [],
  openPageviewMenus: false,
  showEditorialChart: false,
  selTrafficSource: "",
  editorialIsIncludeParent: false,
  editorialIsIncludeChild: false,
  topicSummaryToggleValue: false,
  orgsSummaryToggleValue: false,
  placementSummaryToggleValue: false,
  pageSummaryToggleValue: false,
  trafficType: "all",
  isTopicSummary: false,
  indTopicSummaryToggleValue: false,
  indPrimaryTopicCount: {},
  pageSummaryCount: {},
  exAnonymousOrgs: false,
  exAnonymousMember: false,
  pageviewType: "all",
  isAuthorToggle: false,
  selectPageDetailTable: false,
  pageviewDetailsToggelValue: false,
  pageview_config_data: {},
  timeOfDay: "all",
};

const editorialSelData = {
  orgs: [],
  campaigns: [],
  order: [],
  engOrgs: [],
  country: [],
  engMember: [],
  primaryTopic: [],
};

const selectedTags = {
  orgTagsIn: [],
  orgTagsEx: [],
  topicTagsIn: [],
  topicTagsEx: [],
  placementTagsIn: [],
  placementTagsEx: [],
  senioritiesIN: [],
  senioritiesEX: [],
  jobfunctionsIN: [],
  jobfunctionsEX: [],
  sector: [],
  industry_group: [],
  industry: [],
  sub_industry: [],
  orgsType: [],
  orgsSize: [],
  orgsRevenue: [],
};

const editorialFetchData = {
  isFilApplied: false,
  isLoadingChart: false,
  scCampaignDetail: {},
  placementEditorialSummary: [],
  isLoadingPlacementEditorialSummary: false,
  isLoadingScCampaignDetail: false,
  editorialPageSummary: [],
  pageviewDetailed: {},
  pageCount: {},
  pageviewCharts: [],
  countAPI: false,
  isLoadingScSummary: false,
  isLoadingEditorialPageSummary: false,
  pageviewDetailCsv: "",
  emailPageSummary: "",
  emailPageDetail: "",
  cardPopupPageSummary: "",
  cardPopupPrimarySummary: "",
  emailPrimarySummary: "",
  emailPlacementSummary: "",
  cardPopupPlacementSummary: "",
  emailTopicTagsSummary:"",
  cardPopupTopicTagsSummary: "",
  emailPageSummaryCSV: "",
  cardPopupPageSummaryCSV: "",
  cardPopupPageDetail: "",
  postEmailUpdate: "",
  postEmailUpdatePageSummary: "",
  postEmailUpdatePrimaryTopicSummaryCSV: "",
  postEmailUpdatePlacementeSummaryCSV: "",
  postEmailUpdateTopicTagsSummaryCSV: "",
  confirmEmail: false,
  isLoadingPageviewDetailCsv: false,
  topicPageviewSummarycolumn: null,
  authorSummaryColumn: null,
  authorSummaryDirection: null,
  placementPageviewSummarycolumn: null,
  pageviewDetailedColumn: null,
  column: null,
  topicPageviewSummarydirection: null,
  placementPageviewSummarydirection: null,
  pageviewDetailedDirection: null,
  direction: null,
  editorialPrimaryTopicSummaryDirection: null,
  placementPageviewSummarytype: "",
  topicPageviewSummarytype: "",
  type: "",
  placementPageviewSummaryLoad: false,
  topicPageviewSummaryLoad: false,
  scSummary: false,
  isLoadingEditorialPrimaryTopicSummary: false,
  editorialPrimaryTopicSummary: {},
  editorialPrimaryTopicPageCount: "",
  editorialPlacementSummaryPageCount: "",
  editorialTopicSummaryPageCount: "",
  postEmailUpdatePageviewDetails: "",
  postEmailUpdatePageSummaryCSV: "",
  editorialCharts: [],
  getDynamicEditorialPageSummaryHeaderData: [],
  dynamicEditorialPageSummaryHeaderArray: [],
  getDynamicEditorialPlacementSummaryHeaderData: [],
  dynamicEditorialPlacementSummaryHeaderArray: [],
  getDynamicEditorialTopicDetailsHeaderData: [],
  dynamicEditorialTopicDetailsHeaderArray: [],
  resIndependantPrimaryTopic: null,
  resTopicPageviewSummary: null,
  resPlacementPageviewSummary: null,
  resPageSummary: null,
  getDynamicEditorialOrganizationSummaryHeaderData:[],
  dynamicEditorialOrganizationSummaryHeaderArray: [],
  isLoadingEditorialOrganizationSummary: false,
  resOrganizationPageviewSummary: null,
  editorialOrganizationSummaryPageCount: "",
  editorialOrganizationSummary: [],
  postEmailUpdateOrganizationSummaryCSV: "",
  orgsSummarydirection: null,
  orgsSummarycolumn: null,
  cardPopupOrganizationSummaryCSV: "",
  topicEditorialSummary:[],
  isLoadingPageveiwAuthorSummary: false,
  pageviewAuthorSummary: {},
  pageviewAuthorSummaryEmail: "",
  pageviewAuthorSummaryCSV: "",
  cardPopupAuthorSummaryCSV: "",
  isLoadingPageviewDetailed: false,
  pageviewDetailedData: {},
  detailedPageCount: 1,
  getDynamicEDPageDetailHeaderData: [],
  dynamicEDPageDetailHeaderArray: [],
};

const mapSummaryElement = (element) => {
  return {
    ...element,
    tag: element.tag === "" ? "N/A" : element.tag
  };
};

export const editorialFilterDataReducer = (
  state = editorialFilterData,
  action
) => {
  //SPONSOREDCONTENT
  switch (action.type) {
    case actionTypes.SET_PAGEVIEW_VALUES:
			return { ...state, [action.payload.key]: action.payload.value };
    case actionTypes.PAGEVIEW_CONFIG_LIST:
      return { ...state, pageview_config_data: action.payload };
    case actionTypes.SELEDITORIALSTARTDATE:
      return { ...state, startDate: action.payload };
    case actionTypes.SELEDITORIALENDDATE:
      return { ...state, endDate: action.payload };
    case actionTypes.SELEDITORIALPREVSTARTDATE:
      return { ...state, previousStartDate: action.payload };
    case actionTypes.SELEDITORIALPREVENDDATE:
      return { ...state, previousEndDate: action.payload };
    case actionTypes.APPLYEDITORIALDATE:
      return { ...state, appliedDate: action.payload };
    case actionTypes.EDITORIALCUSTOMRANGE:
      return { ...state, selCutomRangeChkBox: action.payload };
    case actionTypes.SELEDITORIALFILTERVALUE:
      return { ...state, [action.payload.key]: action.payload.value };
    case actionTypes.RESETSELEDITORIALURLCAMPANDMEDIUM:
      return { ...state, selUrlMedium: "", selUrlCampaign: "" };
    case actionTypes.RESETSELEDITORIALCAMPAIGNS:
      return { ...state, campaign: action.payload };

    case actionTypes.SELEDITORIALPARENTTOOGLE:
      return { ...state, editorialIsIncludeParent: !state.editorialIsIncludeParent };
    case actionTypes.SELEDITORIALCHILDTOOGLE:
      return { ...state, editorialIsIncludeChild: !state.editorialIsIncludeChild };
    case actionTypes.SELEDITORIALFIRMOGRAPHICTOGGLE:
      return {
        ...state,
        firmographicToggle: !state.firmographicToggle,
      };
    case actionTypes.SELEDITORIALPERSONATOGGLE:
      return {
        ...state,
        personaToggle: !state.personaToggle,
      };
    case actionTypes.SELEDITORIALWPSTARTDATE:
      return { ...state, wp_startDate: action.payload };
    case actionTypes.SELEDITORIALWPENDDATE:
      return { ...state, wp_endDate: action.payload };
    case actionTypes.SELEDITORIALPAGEPARAMSTOGGLE:
      return {
        ...state,
        pageParams: !state.pageParams,
      };
    case actionTypes.APPLYEDITORIALDATEFILTER:
      return { ...state, appliedDateFilter: action.payload };
    case actionTypes.SELINDINTERNALTOGGLE:
      return { ...state, excludeInternalEvents: !state.excludeInternalEvents };
    case actionTypes.SELEDITORIALINTOGGLE:
      return {
        ...state,
        excludeInternalCampaign: !state.excludeInternalCampaign,
      };
    case actionTypes.APPLYEDITORIALFILTER:
      return {
        ...state,
        isFilApplied: true,
        checkboxState: true,
        //checkboxState: state.selCutomRangeChkBox
      };
    case actionTypes.SHOWEDITORIALCHARTS:
      return {
        ...state,
        showEditorialChart: !state.showEditorialChart,
      };
    case actionTypes.TOPICSUMMARYTOGGLEVALUE:
      return {
        ...state,
        topicSummaryToggleValue: action.payload,
      };  
    case actionTypes.ORGSSUMMARYTOGGLEVALUE:
      return {
        ...state,
        orgsSummaryToggleValue: action.payload,
      }; 
    case actionTypes.PLACEMENTSUMMARYTOGGLEVALUE:
      return {
        ...state,
        placementSummaryToggleValue: action.payload,
      }; 
    case actionTypes.PAGESUMMARYTOGGLEVALUE:
      return {
        ...state,
        pageSummaryToggleValue: action.payload,
      }; 
    case actionTypes.PAGEVIEW_DETAILS_TOGGLE:
      return {
        ...state,
        pageviewDetailsToggelValue: action.payload,
      }
    case actionTypes.SEL_ED_TABLE_TOGGLE:
      return { ...state, selectPageDetailTable: !state.selectPageDetailTable };

    case actionTypes.SELEDITORIALPAGESUMMARYTABLETOGGLE:
      return { ...state, selectPageSummaryTable: !state.selectPageSummaryTable};
    
    case actionTypes.SELEDITORIALTOPICSUMMMARYTABLETOGGLE:
      return { ...state, selectTopicSummaryTable: !state.selectTopicSummaryTable};
    case actionTypes.SELEDITORIALPLACESUMMMARYTABLETOGGLE:
      return { ...state, selectPlaceSummaryTable: !state.selectPlaceSummaryTable};
    case actionTypes.SELEDITORIALORGANIZATIONSUMMARYTABLETOGGLE:
      return { ...state, selectOrganizationSummaryTable: !state.selectOrganizationSummaryTable};  
    case actionTypes.CHECKINGTOOGLE:
      return { ...state, [action.payload.key]: !action.payload.value };
    case actionTypes.INDTOPICSUMMARYTOGGLEVALUE:
      return { ...state, indTopicSummaryToggleValue: action.payload, }; 
    case actionTypes.PAGESUMMARYCOUNT: {
      return { ...state, pageSummaryCount: action.payload }
    }
    case actionTypes.CLEAREDITORIALFILTER:
      return {
        startDate: getDisplayDate(getLastDays(7)),
        endDate: getDisplayDate(getYesterdaysDate()),
        appliedDate: "7",
        previousStartDate: "",
        previousEndDate: "",
        prevYearEndDate: "",
        selCutomRangeChkBox: false,
        checkboxState: true,
        organization: [],
        campaign: [],
        order: [],
        site: siteValue,
        campaignStatus: "",
        selPageTitle: "",
        selAuthor: "",
        selCountry: [],
        selOrgTagsIN: [],
        selOrgTagsEX: [],
        selTopicTagsIN: [],
        selTopicTagsEX: [],
        selPlacementTagsIN: [],
        selPlacementTagsEX: [],
        selSenioritiesIN: [],
        selSenioritiesEX: [],
        selJobFunctionsEX: [],
        selJobFunctionsIN: [],
        selJobTitlesIN: "",
        selJobTitlesEX: "",
        selSector: [],
        selIndustryGroup: [],
        selIndustry: [],
        selSubIndustry: [],
        selOrgType: [],
        selOrgSize: [],
        selOrgRevenue: [],
        selUrlSource: "",
        selUrlMedium: "",
        selUrlCampaign: "",
        selUrlTerm: "",
        selUrlContent: "",
        selPageViewsByOrg: [],
        excludeInternalEvents: false,
        excludeInternalCampaign: false,
        showPageviewChart: true,
        selectPageSummaryTable: false,
        firmographicToggle: false,
        email: "",
        includedVerticals: [],
        personaToggle: false,
        pageParams: false,
        selEnagedMember: [],
        appliedDateFilter: "2",
        wp_startDate: getDisplayDate(getLastDays(0)),
        wp_endDate: getDisplayDate(getYesterdaysDate()),
        selPrimaryTopic: [],
        openPageviewMenus: false,
        showEditorialChart: false,
        selTrafficSource: "",
        trafficType: "all",
        selectPlaceSummaryTable: false,
        selectTopicSummaryTable: false,
        selectOrganizationSummaryTable: false,
        isTopicSummary: false,
        exAnonymousOrgs: false,
        exAnonymousMember: false,
        pageviewType: "all",
        isAuthorToggle: false,
        dayOfWeek: [],
        pageviewRanges: "all",
        timeOfDay: "all",
      };
    default:
      return state;
  }
};

export const editorialOrganizationsReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALORGS:
      return {
        ..._.mapKeys(editorialSelData.orgs, "uuid"),
        ..._.mapKeys(action.payload, "uuid"),
      };
    case actionTypes.ADDEDITORIALORGS:
      editorialSelData.orgs = [];
      const orgIds = action.payload;
      orgIds.forEach(function (orgId) {
        editorialSelData.orgs.push(state[orgId]);
      });
      return { ..._.mapKeys(editorialSelData.orgs, "uuid") };
    case actionTypes.CLEARSEARCHEDITORIALORGS:
      return { ..._.mapKeys(editorialSelData.orgs, "uuid") };
    case actionTypes.RESETEDITORIALORGS:
      editorialSelData.orgs = [];
      return editorialSelData.orgs;
    default:
      return state;
  }
};

export const editorialCampaignsReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALCAMPAIGNS:
      return {
        ...state,
        ..._.mapKeys(editorialSelData.campaigns),
        ..._.mapKeys(action.payload, "uuid"),
      };
    case actionTypes.ADDEDITORIALCAMPAIGNS:
      editorialSelData.campaigns = [];
      const campaignIds = action.payload;
      campaignIds.forEach(function (campaignId) {
        editorialSelData.campaigns.push(state[campaignId]);
      });
      return { ..._.mapKeys(editorialSelData.campaigns, "uuid") };
    case actionTypes.FETCHSELEDITORIALCAMPAIGNS:
      editorialSelData.campaigns = action.payload;
      return { ..._.mapKeys(editorialSelData.campaigns, "uuid") };
    case actionTypes.RESETEDITORIALCAMPAIGNS:
      editorialSelData.campaigns = [];
      return editorialSelData.campaigns;
    case actionTypes.CLEARSEARCHEDITORIALCAMPAIGNS:
      return { ..._.mapKeys(editorialSelData.campaigns, "uuid") };
    default:
      return state;
  }
};

export const editorialOrderReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALORDER:
      return {
        ..._.mapKeys(editorialSelData.order, "uuid"),
        ..._.mapKeys(action.payload, "uuid"),
      };
    case actionTypes.ADDEDITORIALORDER:
      editorialSelData.order = [];
      const orderIds = action.payload;
      orderIds.forEach(function (orderId) {
        editorialSelData.order.push(state[orderId]);
      });
      return { ..._.mapKeys(editorialSelData.order, "uuid") };
    case actionTypes.RESETEDITORIALORDER:
      editorialSelData.order = [];
      return editorialSelData.order;
    case actionTypes.CLEARSEARCHEDITORIALORDER:
      return { ..._.mapKeys(editorialSelData.order, "uuid") };
    default:
      return state;
  }
};

export const editorialAuthorReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALAUTHOR:
      const orderArray = action.payload.map(item => item.id);
      const mappedObject = _.mapKeys(action.payload, "id");
      return orderArray.map(id => mappedObject[id]);
    case actionTypes.CLEARSEARCHEDITORIALAUTHOR:
      return (state = []);
    default:
      return state;
  }
};

export const editorialTopicTagsInReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALTOPICTAGSIN:
      action.selected.forEach(function (id) {
        action.payload.forEach((tag) => {
          if (tag.key === id) {
            tag.disabled = true;
          }
        });
      });
      return { ...state, ..._.mapKeys(action.payload, "key") };
    case actionTypes.APPLYEDITORIALTOPICTAGSIN:
      selectedTags.topicTagsIn = [];
      const ids = action.payload;
      ids.forEach(function (id) {
        selectedTags.topicTagsIn.push(state[id]);
      });
      return { ..._.mapKeys(selectedTags.topicTagsIn, "key") };
    case actionTypes.CLEARSEARCHEDITORIALTOPICTAGIN:
      return { ..._.mapKeys(selectedTags.topicTagsIn, "key") };
    case actionTypes.RESETEDITORIALTOPICTAGIN:
      selectedTags.topicTagsIn = [];
      return selectedTags.topicTagsIn;
    default:
      return state;
  }
};

export const editorialTopicTagsExReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALTOPICTAGSEX:
      action.selected.forEach(function (id) {
        action.payload.forEach((tag) => {
          if (tag.key === id) {
            tag.disabled = true;
          }
        });
      });
      return { ...state, ..._.mapKeys(action.payload, "key") };
    case actionTypes.APPLYEDITORIALTOPICTAGSEX:
      selectedTags.topicTagsEx = [];
      const ids = action.payload;
      ids.forEach(function (id) {
        selectedTags.topicTagsEx.push(state[id]);
      });
      return { ..._.mapKeys(selectedTags.topicTagsEx, "key") };
    case actionTypes.CLEARSEARCHEDITORIALTOPICTAGEX:
      return { ..._.mapKeys(selectedTags.topicTagsEx, "key") };
    case actionTypes.RESETEDITORIALTOPICTAGEX:
      selectedTags.topicTagsEx = [];
      return selectedTags.topicTagsEx;
    default:
      return state;
  }
};

export const editorialPlacementTagsInReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALPLACEMENTTAGSIN:
      action.selected.forEach(function (id) {
        action.payload.forEach((tag) => {
          if (tag.key === id) {
            tag.disabled = true;
          }
        });
      });
      return { ...state, ..._.mapKeys(action.payload, "key") };
    case actionTypes.APPLYEDITORIALPLACEMENTTAGSIN:
      selectedTags.placementTagsIn = [];
      const ids = action.payload;
      ids.forEach(function (id) {
        selectedTags.placementTagsIn.push(state[id]);
      });
      return { ..._.mapKeys(selectedTags.placementTagsIn, "key") };
    case actionTypes.CLEARSEARCHEDITORIALPLACEMENTTAGIN:
      return { ..._.mapKeys(selectedTags.placementTagsIn, "key") };
    case actionTypes.RESETEDITORIALPLACEMENTTAGIN:
      selectedTags.placementTagsIn = [];
      return selectedTags.placementTagsIn;
    default:
      return state;
  }
};

export const editorialPlacementTagsExReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALPLACEMENTTAGSEX:
      action.selected.forEach(function (id) {
        action.payload.forEach((tag) => {
          if (tag.key === id) {
            tag.disabled = true;
          }
        });
      });
      return { ...state, ..._.mapKeys(action.payload, "key") };
    case actionTypes.APPLYEDITORIALPLACEMENTTAGSEX:
      selectedTags.placementTagsEx = [];
      const ids = action.payload;
      ids.forEach(function (id) {
        selectedTags.placementTagsEx.push(state[id]);
      });
      return { ..._.mapKeys(selectedTags.placementTagsEx, "key") };
    case actionTypes.CLEARSEARCHEDITORIALPLACEMENTTAGEX:
      return { ..._.mapKeys(selectedTags.placementTagsEx, "key") };
    case actionTypes.RESETEDITORIALPLACEMENTTAGEX:
      selectedTags.placementTagsEx = [];
      return selectedTags.placementTagsEx;
    default:
      return state;
  }
};

export const editorialOrgTagsInReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALORGTAGSIN:
      action.selected.forEach(function (id) {
        action.payload.forEach((tag) => {
          if (tag.key === id) {
            tag.disabled = true;
          }
        });
      });
      return { ...state, ..._.mapKeys(action.payload, "key") };
    case actionTypes.APPLYEDITORIALORGTAGSIN:
      selectedTags.orgTagsIn = [];
      const ids = action.payload;
      ids.forEach(function (id) {
        selectedTags.orgTagsIn.push(state[id]);
      });
      return { ..._.mapKeys(selectedTags.orgTagsIn, "key") };
    case actionTypes.CLEARSEARCHEDITORIALORGTAGIN:
      return { ..._.mapKeys(selectedTags.orgTagsIn, "key") };
    case actionTypes.RESETEDITORIAORGTAGIN:
      selectedTags.orgTagsIn = [];
      return selectedTags.orgTagsIn;
    default:
      return state;
  }
};

export const editorialOrgTagsExReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALORGTAGSEX:
      action.selected.forEach(function (id) {
        action.payload.forEach((tag) => {
          if (tag.key === id) {
            tag.disabled = true;
          }
        });
      });
      return { ...state, ..._.mapKeys(action.payload, "key") };
    case actionTypes.APPLYEDITORIALORGTAGSEX:
      selectedTags.orgTagsEx = [];
      const ids = action.payload;
      ids.forEach(function (id) {
        selectedTags.orgTagsEx.push(state[id]);
      });
      return { ..._.mapKeys(selectedTags.orgTagsEx, "key") };
    case actionTypes.CLEARSEARCHEDITORIALORGTAGEX:
      return { ..._.mapKeys(selectedTags.orgTagsEx, "key") };
    case actionTypes.RESETEDITORIAORGTAGEX:
      selectedTags.orgTagsEx = [];
      return selectedTags.orgTagsEx;
    default:
      return state;
  }
};

export const editorialCountryReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALCOUNTRY:
      return {
        ...state,
        ..._.mapKeys(action.payload, "name"),
      };
    case actionTypes.ADDEDITORIALCONTRIES:
      editorialSelData.country = [];
      const countryData = action.payload;
      countryData.forEach(function (countryId) {
        editorialSelData.country.push(state[countryId]);
      });
      return { ..._.mapKeys(editorialSelData.country, "name") };
    case actionTypes.CLEARSEARCHEDITORIALCOUNTRY:
      //return (state = []);
      return { ...state, ..._.mapKeys(editorialSelData.country, "id") };
    case actionTypes.RESETEDITORIACOUNTRY:
      editorialSelData.country = [];
      return editorialSelData.country;
    default:
      return state;
  }
};

export const editorialEngOrgReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALENGORGS:
      return {
        ..._.mapKeys(editorialSelData.engOrgs, "uuid"),
        ..._.mapKeys(action.payload, "uuid"),
      };
    case actionTypes.ADDEDITORIALENGORGS:
      editorialSelData.engOrgs = [];
      const pgengorgIds = action.payload;
      pgengorgIds.forEach(function (pgengorgId) {
        editorialSelData.engOrgs.push(state[pgengorgId]);
      });
      return { ..._.mapKeys(editorialSelData.engOrgs, "uuid") };
    case actionTypes.CLEARSEARCHEDITORIALENGORGS:
      return (state = []);
    case actionTypes.RESETEDITORIAENGORGS:
      editorialSelData.engOrgs = [];
      return editorialSelData.engOrgs;
    default:
      return state;
  }
};

export const editorialFirmographicOrgsTypeReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALFIRMOGRAPHICORGSTYPE:
      return { ...state, ..._.mapKeys(action.payload, "value") };
    case actionTypes.APPLYEDITORIALORGSTYPE:
      selectedTags.orgsType = [];
      const ids = action.payload;
      ids.forEach(function (id) {
        selectedTags.orgsType.push(state[id]);
      });
      return { ..._.mapKeys(selectedTags.orgsType, "value") };
    case actionTypes.CLEARSEARCHEDITORIALORGSTYPE:
      return { ..._.mapKeys(selectedTags.orgsType, "value") };
      case actionTypes.RESETEDITORIALORGSTYPE:
        selectedTags.orgsType = [];
        return selectedTags.orgsType;
    default:
      return state;
  }
};

export const editorialFirmographicOrgsSizeReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALORGSSIZE:
      return { ...state, ..._.mapKeys(action.payload, "value") };
    case actionTypes.APPLYEDITORIALORGSSIZE:
      selectedTags.orgsSize = [];
      const ids = action.payload;
      ids.forEach(function (id) {
        selectedTags.orgsSize.push(state[id]);
      });
      return { ..._.mapKeys(selectedTags.orgsSize, "value") };
    case actionTypes.CLEARSEARCHEDITORIALORGSSIZE:
      return { ..._.mapKeys(selectedTags.orgsSize, "value") };
    case actionTypes.RESETEDITORIALORGSIZE:
      selectedTags.orgsSize = [];
      return selectedTags.orgsSize;
    default:
      return state;
  }
};

export const editorialFirmographicOrgsRevenueReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALORGSREVENUE:
      return { ...state, ..._.mapKeys(action.payload, "value") };
    case actionTypes.APPLYEDITORIALREVENUE:
      selectedTags.orgsRevenue = [];
      const ids = action.payload;
      ids.forEach(function (id) {
        selectedTags.orgsRevenue.push(state[id]);
      });
      return { ..._.mapKeys(selectedTags.orgsRevenue, "value") };
    case actionTypes.CLEARSEARCHEDITORIALORGSREVENUE:
      return { ..._.mapKeys(selectedTags.orgsRevenue, "value") };
    case actionTypes.RESETEDITORIALORGSREVENUE:
      selectedTags.orgsRevenue = [];
      return selectedTags.orgsRevenue;
    default:
      return state;
  }
};

export const editorialFirmographicSectorReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALSECTOR:
      return { ...state, ..._.mapKeys(action.payload, "sector") };
    case actionTypes.APPLYEDITORIALSECTOR:
      return { ...state, ..._.mapKeys(selectedTags.sector, "sector") };
    case actionTypes.CLEARSEARCHEDITORIALSECTOR:
      return { ...state, ..._.mapKeys(selectedTags.sector, "sector") };
    case actionTypes.RESETEDITORIALSECTOR:
      selectedTags.sector = [];
      return selectedTags.sector;
    default:
      return state;
  }
};

export const editorialFirmographicIndustryGroupReducer = (
  state = [],
  action
) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALINDUSTRYGROUP:
      return { ...state, ..._.mapKeys(action.payload, "industry_group") };
    case actionTypes.APPLYEDITORIALINDUSTRYGROUP:
      return { ...state, ..._.mapKeys(action.payload, "industry_group") };
    case actionTypes.CLEARSEARCHEDITORIALINDUSTRYGROUP:
      return {
        ...state,
        ..._.mapKeys(selectedTags.industry_group, "industry_group"),
      };
    case actionTypes.RESETEDITORIALINDUSTRYGROUP:
      selectedTags.industry_group = [];
      return selectedTags.industry_group;
    default:
      return state;
  }
};

export const editorialFirmographicIndustryReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALINDUSTRY:
      return { ...state, ..._.mapKeys(action.payload, "industry") };
    case actionTypes.APPLYEDITORIALINDUSTRY:
      return { ...state, ..._.mapKeys(action.payload, "industry") };
    case actionTypes.CLEARSEARCHEDITORIALINDUSTRY:
      return { ...state, ..._.mapKeys(selectedTags.industry, "industry") };
    case actionTypes.RESETEDITORIALINDUSTRY:
      selectedTags.industry = [];
      return selectedTags.industry;
    default:
      return state;
  }
};

export const editorialFirmographicSubIndustryReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALSUBINDUSTRY:
      return { ...state, ..._.mapKeys(action.payload, "sub_industry") };
    case actionTypes.APPLYEDITORIALSUBINDUSTRY:
      return { ...state, ..._.mapKeys(action.payload, "sub_industry") };
    case actionTypes.CLEARSEARCHEDITORIALSUBINDUSTRY:
      return {
        ...state,
        ..._.mapKeys(selectedTags.sub_industry, "sub_industry"),
      };
    case actionTypes.RESETEDITORIALSUBINDUSTRY:
      selectedTags.sub_industry = [];
      return selectedTags.sub_industry;
    default:
      return state;
  }
};

export const editorialSenioritiesInReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALSENIORITIESIN:
      action.selected.forEach(function (id) {
        action.payload.forEach((tag) => {
          if (tag.id === id) {
            tag.disabled = true;
          }
        });
      });
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case actionTypes.APPLYEDITORIALSENIORITIESIN:
      selectedTags.senioritiesIN = [];
      const ids = action.payload;
      ids.forEach(function (id) {
        selectedTags.senioritiesIN.push(state[id]);
      });
      return { ..._.mapKeys(selectedTags.senioritiesIN, "id") };
    case actionTypes.CLEARSEARCHEDITORIALSENIORITIESIN:
      return { ..._.mapKeys(selectedTags.senioritiesIN, "id") };
    case actionTypes.RESETEDITORIALSENIORITIESIN:
      selectedTags.senioritiesIN = [];
      return selectedTags.senioritiesIN;
    default:
      return state;
  }
};

export const editorialSenioritiesExReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALSENIORITIESEX:
      action.selected.forEach(function (id) {
        action.payload.forEach((tag) => {
          if (tag.id === id) {
            tag.disabled = true;
          }
        });
      });
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case actionTypes.APPLYEDITORIALSENIORITIESEX:
      selectedTags.senioritiesEX = [];
      const ids = action.payload;
      ids.forEach(function (id) {
        selectedTags.senioritiesEX.push(state[id]);
      });
      return { ..._.mapKeys(selectedTags.senioritiesEX, "id") };
    case actionTypes.CLEARSEARCHEDITORIALSENIORITIESEX:
      return { ..._.mapKeys(selectedTags.senioritiesEX, "id") };
    case actionTypes.RESETEDITORIALSENIORITIESEX:
      selectedTags.senioritiesEX = [];
      return selectedTags.senioritiesEX;
    default:
      return state;
  }
};

export const editorialJobfunctionsInReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALJOBFUNCTIONSIN:
      action.selected.forEach(function (id) {
        action.payload.forEach((tag) => {
          if (tag.id === id) {
            tag.disabled = true;
          }
        });
      });
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case actionTypes.APPLYEDITORIALJOBFUNCTIONSIN:
      selectedTags.jobfunctionsIN = [];
      const ids = action.payload;
      ids.forEach(function (id) {
        selectedTags.jobfunctionsIN.push(state[id]);
      });
      return { ..._.mapKeys(selectedTags.jobfunctionsIN, "id") };
    case actionTypes.CLEARSEARCHEDITORIALJOBFUNCTIONSIN:
      return { ..._.mapKeys(selectedTags.jobfunctionsIN, "id") };
    case actionTypes.RESETEDITORIALJOBFUNCTIONSIN:
      selectedTags.jobfunctionsIN = [];
      return selectedTags.jobfunctionsIN;
    default:
      return state;
  }
};

export const editorialJobfunctionsExReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALJOBFUNCTIONSEX:
      action.selected.forEach(function (id) {
        action.payload.forEach((tag) => {
          if (tag.id === id) {
            tag.disabled = true;
          }
        });
      });
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case actionTypes.APPLYEDITORIALJOBFUNCTIONSEX:
      selectedTags.jobfunctionsEX = [];
      const ids = action.payload;
      ids.forEach(function (id) {
        selectedTags.jobfunctionsEX.push(state[id]);
      });
      return { ..._.mapKeys(selectedTags.jobfunctionsEX, "id") };
    case actionTypes.CLEARSEARCHEDITORIALJOBFUNCTIONSEX:
      return { ..._.mapKeys(selectedTags.jobfunctionsEX, "id") };
    case actionTypes.RESETEDITORIALJOBFUNCTIONSEX:
      selectedTags.jobfunctionsEX = [];
      return selectedTags.jobfunctionsEX;
    default:
      return state;
  }
};

export const editorialPageviewTitleReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALTITLE:
      const orderArray = action.payload.map(item => item.id);
      const mappedObject = _.mapKeys(action.payload, "id");
      return orderArray.map(id => mappedObject[id]);
    case actionTypes.CLEARSEARCHEDITORIALPAGETITLE:
      return (state = []);
    default:
      return state;
  }
};

export const editorialUrlSourceReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALURLSOURCE:
      return action.payload;
    case actionTypes.CLEARSEARCHEDITORIALURLSOURCE:
      return (state = []);
    default:
      return state;
  }
};

export const editorialUrlMediumReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALURLMEDIUM:
      return action.payload;
    case actionTypes.CLEARSEARCHEDITORIALURLMEDIUM:
      return (state = []);
    default:
      return state;
  }
};

export const editorialUrlCampaignsReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALURLCAMPAIGNS:
      return action.payload;
    case actionTypes.CLEARSEARCHEDITORIALURLCAMPAIGNS:
      return (state = []);
    default:
      return state;
  }
};

export const editorialUrlTermReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALURLTERM:
      return action.payload;
    case actionTypes.CLEARSEARCHEDITORIALURLTERM:
      return (state = []);
    default:
      return state;
  }
};

export const editorialUrlContentReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALURLCONTENT:
      return action.payload;
    case actionTypes.CLEARSEARCHEDITORIALURLCONTENT:
      return (state = []);
    default:
      return state;
  }
};

export const editorialEngMemberReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALENGMEMBER:
      return {
        ..._.mapKeys(editorialSelData.engMember, "user_uuid"),
        ..._.mapKeys(action.payload, "user_uuid"),
      };
    case actionTypes.ADDEDITORIALENGMEMBER:
      editorialSelData.engMember = [];
      const pgengorgIds = action.payload;
      pgengorgIds.forEach(function (pgengorgId) {
        editorialSelData.engMember.push(state[pgengorgId]);
      });
      return { ..._.mapKeys(editorialSelData.engMember, "user_uuid") };
    case actionTypes.CLEARSEARCHEDITORIALENGMEMBER:
      return (state = []);
    case actionTypes.RESETEDITORIALENGMEMBER:
      editorialSelData.engMember = [];
      return editorialSelData.engMember;
    default:
      return state;
  }
};

export const editorialPrimaryTopicReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHEDITORIALPRIMARYTOPIC:
      return {
        ..._.mapKeys(editorialSelData.primaryTopic, "tag_id"),
        ..._.mapKeys(action.payload, "tag_id"),
      };
    case actionTypes.ADDEDITORIALPRIMARYTOPIC:
      editorialSelData.primaryTopic = [];
      const pgengorgIds = action.payload;
      pgengorgIds.forEach(function (pgengorgId) {
        editorialSelData.primaryTopic.push(state[pgengorgId]);
      });
      return { ..._.mapKeys(editorialSelData.primaryTopic, "tag_id") };
    case actionTypes.CLEARSEARCHEDITORIAALPRIMARYTOPIC:
      return (state = []);
    case actionTypes.RESETEDITORIALPRIMARYTOPIC:
      editorialSelData.primaryTopic = [];
      return editorialSelData.primaryTopic;
    default:
      return state;
  }
};

export const editorialDetailReducer = (state = editorialFetchData, action) => {
  switch (action.type) {
    case actionTypes.APPLYEDITORIALFILTER:
      return {
        ...state,
        filterData: action.payload,
        isFilApplied: true,
        isLoadingChart: true,
        placementEditorialSummary: [],
        topicEditorialSummary: [],
        editorialPageSummary: [],
        pageviewDetailed: {},
        pageCount: {},
        pageviewCharts: [],
        isLoadingPlacementEditorialSummary: true,
        isLoadingTopicEditorialSummary: true,
        isLoadingEditorialPageSummary: true,
        isLoadingPageviewDetail: true,
        topicPageviewSummarycolumn: null,
        authorSummaryColumn: null,
        authorSummaryDirection: null,
        placementPageviewSummarycolumn: null,
        column: null,
        topicPageviewSummarydirection: null,
        placementPageviewSummarydirection: null,
        direction: null,
        editorialPrimaryTopicSummaryDirection: null,
        topicPageCount: 2,
        detailPageCount: 2,
        detailedPageCount: 2,
        placementPageCount: 2,
        isLoadingEditorialPrimaryTopicSummary: true,
        editorialPrimaryTopicSummary: {},
        editorialPrimaryTopicPageCount: 2,
        editorialPlacementSummaryPageCount: 2,
        editorialTopicSummaryPageCount: 2,
        editorialPageSummaryPageCount: 2,
        editorialCharts: [],
        resIndependantPrimaryTopic: null,
        resTopicPageviewSummary: null,
        resPlacementPageviewSummary: null,
        resPageSummary: null,
        isLoadingEditorialOrganizationSummary: true,
        resOrganizationPageviewSummary: null,
        editorialOrganizationSummaryPageCount: 2,
        editorialOrganizationSummary: [],
        isLoadingPageveiwAuthorSummary: true,
        pageviewAuthorSummary: {},
        isLoadingPageviewDetailed: true,
        pageviewDetailedData: {},
      };

    case actionTypes.UPDATE_ED_LOADER:
      return { ...state, isLoadingPageviewDetailed: true };
    case actionTypes.COMMONINDEPENDENTPAGESUMMARY:
      const data = action.payload;
      return {
        ...state,
        topicEditorialSummary: data.topic_tags ? data.topic_tags.map(mapSummaryElement) : [],
        placementEditorialSummary: data.placement_tags ? data.placement_tags.map(mapSummaryElement) : [],
        editorialOrganizationSummary: data.organization_tags ? data.organization_tags.map(mapSummaryElement) : [],
        isLoadingTopicEditorialSummary: false,
        isLoadingPlacementEditorialSummary: false,
        isLoadingEditorialOrganizationSummary: false,
      };
      case actionTypes.MOREEDITORIALDETAILS:
      if (action.payload === null) {
          return { ...state, isLoadingPageviewDetailed: false, 
            resEDetails: action.resEDetails
          };
      } else {
          return { ...state, pageviewDetailedData: [...state.pageviewDetailedData, ...action.payload], isLoadingPageviewDetailed: false };
      }
    case actionTypes.EDITORIALDETAILS:
          return { 
            ...state,
            pageviewDetailedData: action?.payload || [],
            grandTotal: action.grandTotal ? action.grandTotal : 0,
            isLoadingPageviewDetailed: false 
          };
      case actionTypes.MOREEDITORIALDETAILSLOADING:
          return { ...state, isLoadingPageviewDetailed: true };
      case actionTypes.SORTEDDETAILS:
        const directionValueDetails = state.pageviewDetailedDirection === "ascending" ? "desc" : "asc";
        const pageviewDetailedData= _.orderBy(state.pageviewDetailedData, [(item) => typeof(item[action.column]) === "string" ? item[action.column].toLowerCase() : item[action.column]], [directionValueDetails])
        const pageviewDetailedDirection= state.pageviewDetailedDirection === "ascending" ? "descending" : "ascending"

          if (state.pageviewDetailedColumn === action.column) {
              return {
                ...state,
                pageviewDetailedData,
                pageviewDetailedDirection,
              };
          }
          return {
              ...state,
              pageviewDetailedColumn: action.column,
              pageviewDetailedData,
              pageviewDetailedDirection,
          };
        case actionTypes.RESET_ED_DETAILS_DATA:
          return { ...state, pageviewDetailedData: [], isLoadingPageviewDetailed: false };
      case actionTypes.SETDETAILEDEDCOUNT:
        return { ...state, detailedPageCount: state.detailedPageCount + 1 };
    case actionTypes.EDITORIALPRIMARYTOPIC:
      if (action.payload === null) {
        return {
          ...state,
          isLoadingEditorialPrimaryTopicSummary: false,
        };
      } else {
        return {
          ...state,
          editorialPrimaryTopicSummary: action.payload.map((element)=>{
            return {
              ...element, 
              primary_topic: element.primary_topic === "" ? "N/A" : element.primary_topic,
          }}),
          isLoadingEditorialPrimaryTopicSummary: false,
        };
      }

    case actionTypes.EDITORIALAUTHORSUMMARY:
      if (action.payload === null) {
        return {
          ...state,
          isLoadingPageveiwAuthorSummary: false,
        };
      } else {
        return {
          ...state,
          pageviewAuthorSummary: action.payload.map((element)=>{
            return {
              ...element, 
              author_name: element.author_name === "" ? "N/A" : element.author_name,
          }}),
          isLoadingPageveiwAuthorSummary: false,
        };
      }

      //
    case actionTypes.SORTAUTHOREDITORIALSUMMARY:
      const authorDirection = state.authorSummaryDirection === "ascending" ? "desc" : "asc";
      const pageviewAuthorSummary= _.orderBy(state.pageviewAuthorSummary, [(item) => 
        typeof(item[action.authorSummaryColumn]) === "string" ? 
        item[action.authorSummaryColumn].toLowerCase() : 
        item[action.authorSummaryColumn]], [authorDirection])
      const authorSummaryDirection= state.authorSummaryDirection === "ascending" ? "descending" : "ascending"

      if (state.authorSummaryColumn === action.authorSummaryColumn) {
        return {
          ...state,
          pageviewAuthorSummary,
          authorSummaryDirection,
        };
      }
      return {
        ...state,
        authorSummaryColumn: action.authorSummaryColumn,
        pageviewAuthorSummary,
        authorSummaryDirection,
      };
      //

    case actionTypes.INDPRIMARYTOPICCOUNT:
      return { ...state, indPrimaryTopicCount: action.payload }

    case actionTypes.PAGEVIEWCOUNTAPIHIT:
      return {
        ...state,
        countAPI: false,
      };


    case actionTypes.PAGEVIEWCHARTS:
      return {
        ...state,
        pageviewCharts: action.payload,
        isLoadingChart: false,
      };
    
    /* falls through */

    case actionTypes.SORTPAGEVIEWDETAILED:
      if (state.pageviewDetailedColumn === action.column) {
        return {
          ...state,
          pageviewDetailed: state.pageviewDetailed.reverse(),
          pageviewDetailedDirection:
            state.pageviewDetailedDirection === "ascending"
              ? "descending"
              : "ascending",
        };
      }
      return {
        ...state,
        pageviewDetailedColumn: action.column,
        pageviewDetailed: _.sortBy(state.pageviewDetailed, [action.column]),
        pageviewDetailedDirection: "ascending",
      };
      case actionTypes.SORTEDITORIALPAGESUMMARY: {
        const { column } = action;
        const sortDirection = state.direction === 'ascending' ? 'desc' : 'asc';
        const direction= state.direction === "ascending" ? "descending" : "ascending";

        const sortFunction = (item) => {
          if (column === 'published_date') {
            return new Date(item.published_date);
          } else if (_.includes(['days_since_published', 'days_since_updated'], column)) {
            return item[column]['value'];
          } else {
            return typeof item[column] === 'string' ? item[column].toLowerCase() : item[column];
          }
        };
      
        return {
          ...state,
          column,
          editorialPageSummary: _.orderBy(state.editorialPageSummary, sortFunction, [sortDirection]),
          direction,
          type: action.type,
        };
      }
    case actionTypes.SORTEDITORIALPRIMARYTOPICSUMMARY:
      let direc =
        state.topicPageviewSummarydirection === "descending"
          ? "ascending"
          : "descending";
      if (state.topicPageviewSummarycolumn === action.column) {
        return {
          ...state,
          editorialPrimaryTopicSummary: action.loadMoreSort
            ? _.orderBy(
                state.editorialPrimaryTopicSummary,
                [action.column],
                [getSortDirAbbr(action.direction)]
              )
            : _.orderBy(
                state.editorialPrimaryTopicSummary,
                [action.column],
                [getSortDirAbbr(direc)]
              ),
          topicPageviewSummarydirection: action.loadMoreSort
            ? action.direction
            : direc,
          topicPageviewSummarytype: action.type,
        };
      }
      return {
        ...state,
        topicPageviewSummarycolumn: action.column,
        editorialPrimaryTopicSummary: action.direction
          ? _.orderBy(
              state.editorialPrimaryTopicSummary,
              [action.column],
              [getSortDirAbbr(action.direction)]
            )
          : _.sortBy(state.editorialPrimaryTopicSummary, [action.column]),
        topicPageviewSummarydirection: action.direction
          ? action.direction
          : "ascending",
        topicPageviewSummarytype: action.type,
      };
    case actionTypes.SORTPLACEMENTEDITORIALSUMMARY:
      const directionValuePlacementSummary = state.placementPageviewSummarydirection === "ascending" ? "desc" : "asc";
      const placementEditorialSummary= _.orderBy(state.placementEditorialSummary, [(item) => typeof(item[action.placementPageviewSummarycolumn]) === "string" ? item[action.placementPageviewSummarycolumn].toLowerCase() : item[action.placementPageviewSummarycolumn]], [directionValuePlacementSummary])
      const placementPageviewSummarydirection= state.placementPageviewSummarydirection === "ascending" ? "descending" : "ascending"

      if (state.placementPageviewSummarycolumn === action.placementPageviewSummarycolumn) {
        return {
          ...state,
          placementEditorialSummary,
          placementPageviewSummarydirection,
          placementPageviewSummarytype: action.type,
        };
      }
      return {
        ...state,
        placementPageviewSummarycolumn: action.placementPageviewSummarycolumn,
        placementEditorialSummary,
        placementPageviewSummarydirection,
        placementPageviewSummarytype: action.type,
      };
    case actionTypes.SORTTOPICEDITORIALSUMMARY:
      const directionValueTopicTagSummary = state.topicPageviewSummarydirection === "ascending" ? "desc" : "asc";
      const topicEditorialSummary= _.orderBy(state.topicEditorialSummary, [(item) => typeof(item[action.topicPageviewSummarycolumn]) === "string" ? item[action.topicPageviewSummarycolumn].toLowerCase() : item[action.topicPageviewSummarycolumn]], [directionValueTopicTagSummary])
      const topicPageviewSummarydirection= state.topicPageviewSummarydirection === "ascending" ? "descending" : "ascending"

      if (state.topicPageviewSummarycolumn === action.topicPageviewSummarycolumn) {
        return {
          ...state,
          topicEditorialSummary,
          topicPageviewSummarydirection,
        };
      }
      return {
        ...state,
        topicPageviewSummarycolumn: action.topicPageviewSummarycolumn,
        topicEditorialSummary,
        topicPageviewSummarydirection,
      };

    case actionTypes.CLEAREDITORIALSORT:
      return {
        ...state,
        column: null,
        direction: null,
        editorialPrimaryTopicSummaryDirection: null
      };

    case actionTypes.SETPGPAGESUMMARYPOPUPCLOSE:
      return {
        ...state,
        cardPopupPageSummary: action.payload,
      };
    case actionTypes.SETPGPAGEDETAILPOPUPCLOSE: {
      return {
        ...state,
        cardPopupPageDetail: action.payload,
      };
    }
    case actionTypes.SETPGPOSTEMAILUPDATE:
      return {
        ...state,
        postEmailUpdate: action.payload,
      };
    case actionTypes.SETPGPOSTEMAILUPDATEPAGESUMMARY:
      return {
        ...state,
        postEmailUpdatePageSummary: action.payload,
      };
    case actionTypes.MOREEDITORIALSUMMARY:
      if (action.payload === null  || action.payload.length === 0) {
        return {
          ...state,
          isLoadingEditorialPrimaryTopicSummary: false,
          resIndependantPrimaryTopic: true
        };
      } else {
        return {
          ...state,
          editorialPrimaryTopicSummary: [...state.editorialPrimaryTopicSummary, ...action.payload.map((element)=>{
            return {
              ...element, 
              primary_topic: element.primary_topic === "" ? "N/A" : element.primary_topic,
          }}),],
          isLoadingEditorialPrimaryTopicSummary: false,
        };
      }
    case actionTypes.MOREEDITORIALPRIMARYTOPICLOADING:
      return {
        ...state,
        isLoadingEditorialPrimaryTopicSummary: true,
      };
    case actionTypes.SETEDITORIALPRIMARYTOPICPGCOUNT:
      return {
        ...state,
        editorialPrimaryTopicPageCount:
          state.editorialPrimaryTopicPageCount + 1,
      };

    case actionTypes.EDITORIALPAGEVIEWSUMMARY:
      if (action.payload === null || _.isEmpty(action.payload)) {
        return {
          ...state,
          isLoadingEditorialPageSummary: false,
          // pageviewDetailLoad: true,
        };
      } else {
        return {
          ...state,
          editorialPageSummary: action.payload.map((element)=>{
            return {
              ...element, 
              primary_topic: element.primary_topic === "" ? "N/A" : element.primary_topic,
              topic_tags: element.topic_tags === "" ? "N/A" : element.topic_tags,
              organization_tags: element.organization_tags === "" ? "N/A" : element.organization_tags,
              placement_tags: element.placement_tags === "" ? "N/A" : element.placement_tags,
          }}),
          isLoadingEditorialPageSummary: false,
        };
      }

    case actionTypes.MOREEDITORIALPAGEVIEWSUMMARYLOADING:
      return {
        ...state,
        isLoadingEditorialPageSummary: true,
      };
    case actionTypes.MOREEDITORIALPAGEVIEWSUMMARY:
      if (action.payload === null || action.payload.length === 0) {
        return {
          ...state,
          isLoadingEditorialPageSummary: false,
          resPageSummary: true
        };
      } else {
        return {
          ...state,
          editorialPageSummary: [...state.editorialPageSummary, ...action.payload.map((element)=>{
            return {
              ...element, 
              primary_topic: element.primary_topic === "" ? "N/A" : element.primary_topic,
              topic_tags: element.topic_tags === "" ? "N/A" : element.topic_tags,
              organization_tags: element.organization_tags === "" ? "N/A" : element.organization_tags,
              placement_tags: element.placement_tags === "" ? "N/A" : element.placement_tags,
          }})],
          isLoadingEditorialPageSummary: false,
        };
      }
    case actionTypes.SETEDITORIALPAGEVIEWSUMMARYPGCOUNT:
      return {
        ...state,
        editorialPageSummaryPageCount:
          state.editorialPageSummaryPageCount + 1,
      };
    case actionTypes.EDITORIALDETAILEXCEL:
      const reportTypeStateMap = {
        topic_tag: 'postEmailUpdateTopicTagsSummaryCSV',
        placement_tag: 'postEmailUpdatePlacementeSummaryCSV',
        organization_tag: 'postEmailUpdateOrganizationSummaryCSV',
      };

      if (action.payload === "reports/pageviews/primary_topic") {
        return {
          ...state,
          postEmailUpdatePageSummary: action.email,
          postEmailUpdatePrimaryTopicSummaryCSV: action.email
        };
      } else if (action.payload === "/reports/pageviews/detail") {
        return {
          ...state,
          postEmailUpdatePageviewDetails: action.email,
          postEmailUpdatePageSummaryCSV: action.email
        };
      } else if(action.payload === "reports/pageviews/tags"){
          const newStateProperty = reportTypeStateMap[action.reportType];
          if (newStateProperty) {
            return {
              ...state,
              [newStateProperty]: action.email,
            };
          }
      } else if(action.payload === "/reports/pageviews/authors") {
        return {
          ...state,
          pageviewAuthorSummaryEmail: action.email,
          pageviewAuthorSummaryCSV: action.email,
        };
      }
    /* falls through */
    case actionTypes.PAGEVIEWEMAILAUTHORSUMMARYCSV:
      return {
        ...state,
        pageviewAuthorSummaryCSV: action.payload.email,
        cardPopupAuthorSummaryCSV: action.payload.class,
      };
    case actionTypes.SETPAGEVIEWAUTHORSUMMARY:
      return { ...state, pageviewAuthorSummaryCSV: action.payload };
    case actionTypes.SETSCPOSTEMAILUPDATECAMPAIGNSUMMARY:
      return {
        ...state,
        postEmailUpdatePageSummary: action.payload,
      };
    case actionTypes.SETINDEPENDATNTPOSTEMAILUPDATEPRIMARYTOPICSUMMARY:
      return {
        ...state,
        postEmailUpdatePrimaryTopicSummaryCSV: action.payload,
      };
    case actionTypes.SETINDEPENDATNTPOSTEMAILUPDATEPLACEMENTSUMMARY:
      return {
        ...state,
        postEmailUpdatePlacementeSummaryCSV: action.payload,
      };
    case actionTypes.SETINDEPENDATNTPOSTEMAILUPDATETOPICTAGSSUMMARY:
      return {
        ...state,
        postEmailUpdateTopicTagsSummaryCSV: action.payload,
      };
    case actionTypes.SETEDITORIALPAGEVIEWPOSTEMAILUPDATE:
      return {
        ...state,
        postEmailUpdatePageviewDetails: action.payload,
      };
    case actionTypes.SETEDITORIALPAGESUMMARYCSV:
      return {
        ...state,
        postEmailUpdatePageSummaryCSV: action.payload,
      };
    case actionTypes.EDITORIALEMAILPAGESUMMARY:
      return {
        ...state,
        emailPageSummary: action.payload.email,
        cardPopupPageSummary: action.payload.class,
      };
    case actionTypes.SETEDITORIALCAMPAIGNPAGEVIEWPOPUPCLOSE: {
      return {
        ...state,
        cardPopupPageSummary: action.payload,
      };
    }
    case actionTypes.EDITORIALEMAILPRIMARYESUMMARY:
      return {
        ...state,
        emailPrimarySummary: action.payload.email,
        cardPopupPrimarySummary: action.payload.class,
      };
    case actionTypes.SETEDITORIALPRIMARYTOPICPOPUPCLOSE: {
      return {
        ...state,
        cardPopupPrimarySummary: action.payload,
      };
    }
    case actionTypes.EDITORIALEMAILPLACEMENTESUMMARY:
      return {
        ...state,
        emailPlacementSummary: action.payload.email,
        cardPopupPlacementSummary: action.payload.class,
      };
    case actionTypes.SETEDITORIALPLACEMENTPOPUPCLOSE: {
      return {
        ...state,
        cardPopupPlacementSummary: action.payload,
      };
    }
    case actionTypes.EDITORIALEMAILTOPICTAGSUMMARY:
      return {
        ...state,
        emailTopicTagsSummary: action.payload.email,
        cardPopupTopicTagsSummary: action.payload.class,
      };
    case actionTypes.SETEDITORIALTOPICTAGSPOPUPCLOSE: {
      return {
        ...state,
        cardPopupTopicTagsSummary: action.payload,
      };
    }
    case actionTypes.EDITORIALEMAILPAGEDETAIL:
			return {
				...state,
				emailPageDetail: action.payload.email,
				cardPopupPageDetail: action.payload.class,
			};
    case actionTypes.SETEDITORIALPAGESUMMARYOPUPCLOSE: {
      return {
        ...state,
        cardPopupPageDetail: action.payload,
      };
    }

    case actionTypes.EDITORIALEMAILPAGESUMMARYCSV:
			return {
				...state,
				emailPageSummaryCSV: action.payload.email,
				cardPopupPageSummaryCSV: action.payload.class,
			};
    case actionTypes.SETEDITORIALPAGESUMMARYCSVOPUPCLOSE: {
      return {
        ...state,
        cardPopupPageSummaryCSV: action.payload,
      };
    }

    case actionTypes.EDITORIALCHARTS:
      //let checkedState = action.filterData.selCutomRangeChkBox && action.filterData.appliedDate === "Custom Date Range";
      return {
        ...state,
        //	checkboxState: checkedState,
        editorialCharts: action.payload,
        isLoadingChart: false,
      };
      case actionTypes.SORTEDITORIALPRIMARYTOPIC:
        const directionValuePrimaryTopic = state.editorialPrimaryTopicSummaryDirection === "ascending" ? "desc" : "asc";
        const editorialPrimaryTopicSummary= _.orderBy(state.editorialPrimaryTopicSummary, [(item) => typeof(item[action.column]) === "string" ? item[action.column].toLowerCase() : item[action.column]], [directionValuePrimaryTopic])
        const editorialPrimaryTopicSummaryDirection= state.editorialPrimaryTopicSummaryDirection === "ascending" ? "descending" : "ascending"
      
        if (state.column === action.column) {
          return {
            ...state,
            editorialPrimaryTopicSummary,
            editorialPrimaryTopicSummaryDirection
          };
        }
        return {
          ...state,
          column: action.column,
          editorialPrimaryTopicSummary,
          editorialPrimaryTopicSummaryDirection,
        };
        case actionTypes.GETEDITORIALPAGESUMMARYDYNAMICTABLEHEADER:
          return {
            ...state, getDynamicEditorialPageSummaryHeaderData: action.payload
          }
        case actionTypes.SENDEDITORIALPAGESUMMARYDYANMICTABLEHEADER:
          return {
            ...state, dynamicEditorialPageSummaryHeaderArray: action.payload
          }
        case actionTypes.GETEDITORIALPLACEMENTSUMMARYDYNAMICTABLEHEADER:
          return {
            ...state, getDynamicEditorialPlacementSummaryHeaderData: action.payload
          }
        case actionTypes.SENDEDITORIALPLACEMENTSUMMARYDYANMICTABLEHEADER:
          return {
            ...state, dynamicEditorialPlacementSummaryHeaderArray: action.payload
          }
        case actionTypes.GETEDITORIALTOPICDETAILDYNAMICTABLEHEADER:
          return {
            ...state, getDynamicEditorialTopicDetailsHeaderData: action.payload
          }
        case actionTypes.SENDEDITORIALTOPICDETAILDYANMICTABLEHEADER:
          return {
            ...state, dynamicEditorialTopicDetailsHeaderArray: action.payload
          }
        case actionTypes.GETEDITORIALORGANIZATIONLDYNAMICTABLEHEADER:
        return {
          ...state, getDynamicEditorialOrganizationSummaryHeaderData: action.payload
        }
        case actionTypes.SENDEDITORIALORGANIZATIONDYANMICTABLEHEADER:
          return {
            ...state, dynamicEditorialOrganizationSummaryHeaderArray: action.payload
          }
        
        case actionTypes.EDITORIALEMAILORGANIZATIONSUMMARYCSV:
          return {
            ...state,
            emailOrganizationSummaryCSV: action.payload.email,
            cardPopupOrganizationSummaryCSV: action.payload.class,
          };

        case actionTypes.SETEDITORIALORGANIZATIONSUMMARYCSV:
          return {
            ...state,
            postEmailUpdateOrganizationSummaryCSV: action.payload,
          };
          case actionTypes.EDITORIALORGANIZATIONSUMMARY:
            if (action.payload === null || _.isEmpty(action.payload)) {
              return {
                ...state,
                isLoadingEditorialOrganizationSummary: false,
                // pageviewDetailLoad: true,
              };
            } else {
              return {
                ...state,
                editorialOrganizationSummary: action.payload.map((element)=>{
                  return {
                    ...element, 
                    organization_tag: element.organization_tag === "" ? "N/A" : element.organization_tag,
                }}),
                isLoadingEditorialOrganizationSummary: false,
              };
            }
          
        case actionTypes.GETEDITORIALDETAILSPAGESUMMARYDYNAMICTABLEHEADER:
          return {
            ...state, getDynamicEDPageDetailHeaderData: action.payload
          }
        case actionTypes.SENDEDITORIALDETAILSPAGESUMMARYDYANMICTABLEHEADER:
          return {
            ...state, dynamicEDPageDetailHeaderArray: action.payload
          }
            
          case actionTypes.RESETSTATESTOPICTAGESUMMARY:
            return { ...state, topicEditorialSummary: [], isLoadingTopicEditorialSummary: false };
          case actionTypes.RESETSTATESORGSTAGESUMMARY:
            return { ...state, editorialOrganizationSummary: [], isLoadingEditorialOrganizationSummary: false };
          case actionTypes.UPDATESCORGSTAGESUMMARYLOADER:
            return { ...state, isLoadingEditorialOrganizationSummary: true };
          case actionTypes.RESETSTATESPLACEMENTTAGESUMMARY:
            return { ...state, placementEditorialSummary: [], isLoadingPlacementEditorialSummary: false };
          case actionTypes.UPDATEPLACEMENTTAGESUMMARYLOADER:
            return { ...state, isLoadingPlacementEditorialSummary: true };
          case actionTypes.RESETSTATESPAGESUMMARY:
            return { ...state, editorialPageSummary: [], isLoadingEditorialPageSummary: false };
          case actionTypes.UPDATEPAGESUMMARYLOADER:
            return { ...state, isLoadingEditorialPageSummary: true };
          case actionTypes.UPDATEINDTOPICSUMMARYLOADER:
            return { ...state, isLoadingEditorialPrimaryTopicSummary: true };
          case actionTypes.RESETINDTOPICSUMMARY:
            return { ...state, editorialPrimaryTopicSummary: [], isLoadingEditorialPrimaryTopicSummary: false };
            
        case actionTypes.SORTORGANIZATIONEDITORIALSUMMARY:
          const directionValueOrgsSummary = state.orgsSummarydirection === "ascending" ? "desc" : "asc";
          const editorialOrganizationSummary= _.orderBy(state.editorialOrganizationSummary, [(item) => typeof(item[action.orgsSummarycolumn]) === "string" ? item[action.orgsSummarycolumn].toLowerCase() : item[action.orgsSummarycolumn]], [directionValueOrgsSummary])
          const orgsSummarydirection= state.orgsSummarydirection === "ascending" ? "descending" : "ascending"

            if (state.orgsSummarycolumn === action.orgsSummarycolumn) {
              return {
                ...state,
                editorialOrganizationSummary,
                orgsSummarydirection,
                placementPageviewSummarytype: action.type,
              };
            }
            return {
              ...state,
              orgsSummarycolumn: action.orgsSummarycolumn,
              editorialOrganizationSummary,
              orgsSummarydirection,
              placementPageviewSummarytype: action.type,
            };
        
    default:
      return state;
  }
};
