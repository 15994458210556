import { formatDate } from "../utilities/common";
import * as actionTypes from "./actionTypes";
import axios from "../api/axios";
import { headers } from "../api/apiConstants";
import { url } from "../api/urlHelper";
import { getApiDate } from "../utilities/common";

export const downloadSelectValue = (payload) => ({
    type: actionTypes.SELDOWNLOADVALUES,
    payload
});

export const downloadAddFilterById = (id, dispatchType) => {
	return {
		type: dispatchType,
		payload: id,
	};
};

export const downloadClearSearch = (dispatchType, data) => (dispatch) => {
	dispatch({
		type: dispatchType,
		payload: data
	});
};

export const downloadCommonDispatch = (dispatchType) => (dispatch) => {
	dispatch({
		type: dispatchType,
	});
}

export const fetchDownloadData = (filterData, page, type = "", dispatchType, csvType = "") => async (dispatch) => {
	await axios.post( url.downloadURL, 
		{
			orders: filterData.order,
			campaigns: filterData.campaign,
			organizations: filterData.organization,
			site: filterData.site !== "all" ? filterData.site : "",
			start_date: getApiDate(filterData.startDate).concat(" 00:00:00"),
			end_date: getApiDate(filterData.endDate).concat(" 23:59:59"),
			report: "download",
			order: "ASC",
			order_by: "id",
			limit: 50,
			page: page,
			export: type,
			applied_date_filter: filterData.appliedDate,
			requested_start_date: formatDate(filterData.wp_startDate, " 00:00:00"),
			requested_end_date: formatDate(filterData?.wp_endDate, " 23:59:59"),
			status: filterData.requestedStatus !== 'all' ? filterData.requestedStatus : "",
			requsted_user_id: filterData.requestedUser ? filterData.requestedUser : 0,
			is_automated: filterData.isAutomated,
		},
		{
			headers: headers(),
		}
	)
	.then((res) => {
		if (res.status === 200) {
			if (type) {
				dispatch({
					type: csvType,
					payload: url.downloadURL,
					email: filterData.email,
					csv: res.data && res.data,
				});
			} else {
				dispatch({
					type: dispatchType,
					payload: res.data,
					filterData: filterData,
				});
			}
		} else if (res.status >= 500 && res.status < 600) {
			if (type) {
				dispatch({
					type: dispatchType,
					payload: [],
				});
			}
		}
	})
	.catch((err) => {
		if (err.response) {
			dispatch({
				type: actionTypes.ERRORCODE,
				payload: err.response.status,
			});
		}
	});
};

// download actions api
const downloadActionApiRequest = async (method, url, data, dispatch, dispatchType, fileName) => {
  try {
    const response = await axios({
      method,
      url,
      data,
      headers: headers(),
    });
    handleResponse(dispatch, dispatchType, fileName)(response);
  } catch (error) {
    handleError(dispatch)(error);
  }
};

const handleResponse = (dispatch, dispatchType, fileName) => (res) => {
	if (res.status === 200 || res.status === 204) {
		dispatch({
			type: dispatchType,
			payload: res.data,
			fileName
		});
	}
 };
 
 const handleError = (dispatch) => (err) => {
	if (err.response) {
	  dispatch({
		 type: actionTypes.ERRORCODE,
		 payload: err.response.status,
	  });
	}
 };

export const downloadAndSendAPI = (urlLink, id, dispatchType, fileName) => async (dispatch) => {
  await downloadActionApiRequest('POST', urlLink, { 'request_id': id }, dispatch, dispatchType, fileName);
};

export const deleteActionAPI = (urlLink, id, dispatchType, fileName) => async (dispatch) => {
  await downloadActionApiRequest('DELETE', urlLink, { 'request_ids': id }, dispatch, dispatchType, fileName);
};

export const fetchRequestedUsers = (queryString, dispatchType, reportType = "") => async (dispatch) => {
	await axios
		.post(
			url.requestedUsers,
			{
				user_name: queryString,
				limit: 20,
				page: 1,
			},
			{
				headers: headers(),
			}
		)
		.then((res) => {
			dispatch({
				type: dispatchType,
				payload: res.data,
				reportType,
			});
		})
		.catch((err) => {
			if (err.response) {
				dispatch({ type: actionTypes.ERRORCODE, payload: err.response.status });
			}
		});
};