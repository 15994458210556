import React from "react";
import Link from "../../../hyperlinks/link";
import { Table } from "semantic-ui-react";

const OrderAdDetailsComponent = ({ data }) => {
    const order = data.campaign_data?.length > 0 ? data.campaign_data[0].order : "N/A";
    return (
        data?.campaign_data?.length > 0 ? (
            <Table.Cell title={order.name} className="blogs_link space_height">
                <Link type="orders" urlParam={order.uuid} refValue={order.name}></Link>
            </Table.Cell>
        ) : (
            <Table.Cell className="space_height"> {"N/A"}</Table.Cell>
        )
    );
};

export default OrderAdDetailsComponent;
