import * as actionTypes from "./actionTypes";

export const selEditorialDetailsFilterValue = (value, dispatchType, key) => {
	return {
		type: dispatchType,
		payload: value,
	};
};

export const applyIndDetailsDate = (val) => {
	return {
		type: actionTypes.APPLYEDITORIALDETAILSDATE,
		payload: val,
	};
};

export const clearEditorialDetailsSerchedValue = (dispatchType) => {
	return {
		type: dispatchType,
	};
};

export const selEDFilterValue = (payload) =>({ 
    type: actionTypes.SELPGEDITORIALDETAILSTAGSIN, 
    payload
});

export const selEDToggles = (dispatchType) => (dispatch) => {
	dispatch({
		type: dispatchType,
	});
};

export const sortEditorialDetailsTable = (column) => {
	return {
		type: actionTypes.SORTEDDETAILS,
		column: column,
	};
};

export const editorialDetailsCsvLoading = (dispatchType) => {
	return {
		type: dispatchType,
	};
};

export const fetchEDDetailCsvLoading = () => {
	return {
		type: actionTypes.EDITORIALDETAILCSVLOADING,
	};
};
export const applyEDFilter = () => {
	return {
		type: actionTypes.APPLYEDITORIALDETAILSFILTER,
	};
};
export const clearEDSort = () => {
	return {
		type: actionTypes.CLEAREDSORT,
	};
};