import React from "react";
import "../css/common.css";
import * as actionTypes from "../actions/actionTypes";
import SearchFilter from "../components/topicSponsorships/searchFilter";
import TopicDetailTable from "../components/topicSponsorships/topicDetailTableComp/topicDetailsTable";
import TopicSummaryTable from "../components/topicSponsorships/topicSummaryTable";
import {
	fetchTopicDetail,
	fetchTopicSummary,
	fetchTopicCampaignSummaryCsvLoading,
	topicCsvLoading,
	selTopicFilterValue,
	selTopicToggles,
	fetchTopicData
} from "../actions";
import { connect } from "react-redux";
import _ from "lodash";
import { Message, Confirm } from "semantic-ui-react";
import SidebarDetail from "../components/topicSponsorships/sidebarDetail";
import NotifyEmail from "../components/common/notifyEmailConfirmation";
import { getDays, getReportsByElement } from "../utilities/common";
import Toggle from "../components/base/toggle";
import TopicChart from "../components/topicSponsorships/topicChart";
import DownloadXLSX from "../components/common/downloadXLSX";
import { downloadCSVData } from "../utilities/common";
import { ROLEBASED_ELEMENT_SIDEBAR, SIDEBAR_CAMPAIGN_DETAILS, SIDEBAR_CAMPAIGN_SUMMARY } from "../components/config/rolebasedReportNames";
import { DOWNLOAD_CSV, GENERATE_XLSX, VIEW } from "../components/config/const";

class TopicSponsorshipsReport extends React.Component {
	state = {
		popup: false,
	}

	getTopicCsv = async () => {
		this.props.topicCsvLoading();
		await this.props.fetchTopicDetail(this.props.filterData, 1, "CSV", "", actionTypes.TOPICDETAILCSV);
		if (!_.isEmpty(this.props.topicDetail || !_.isEmpty(this.props.topicSummary))) {
			downloadCSVData(this.props.topicDetailCsv, this.props.downloadFileName)
		}
	};

	getTopicCampaignCsvData = async () => {
		await this.props.fetchTopicSummary(this.props.filterData, 1, "CSV", "", actionTypes.TOPICSUMMARYCSV);
		if (this.props.topicCampaignCsv) {
			downloadCSVData(this.props.topicCampaignCsv, this.props.downloadFileName)
		}
	};


	getExcelReport = (btnSpecificAction, table_name) => {
		let days = getDays(this.props.filterData.startDate, this.props.filterData.endDate)
		let api_calling = ""
		if (table_name === "summary_table_sidebar") {
			api_calling = this.props.fetchTopicSummary
		} else if (table_name === "campaign_detail_sidebar") {
			api_calling = this.props.fetchTopicDetail
		} else if (table_name === "detail_sidebar") {
			api_calling = this.props.fetchTopicData
		} else if (table_name === "detail_sidebar_csv") {
			api_calling = this.props.fetchTopicData
		}
		if (btnSpecificAction === actionTypes.SIDEBARCSVEXCELDETAIL && days > 90) {
			this.setState({
				popup: true
			})
			setTimeout(() => {
				this.setState({
					popup: false
				})
			}, 5000)
		} else {
			DownloadXLSX(
				btnSpecificAction,
				table_name,
				"sidebar",
				this.props.filterData,
				this.props.userEmail,
				actionTypes.SIDEBARDETAILEXCEL,
				api_calling
			)
		}
	};

	getCsvReport = (btnSpecificAction, table_name) => {
		let api_calling = ""
		if (table_name === "detail_sidebar_csv") {
			api_calling = this.props.fetchTopicData
		}
		DownloadXLSX(
			btnSpecificAction,
			table_name,
			"sidebar",
			this.props.filterData,
			this.props.userEmail,
			actionTypes.SIDEBARDETAILEXCEL,
			api_calling
		)
	}

	handleConfirm = () => this.setState({ popup: false })


	render() {
		let tableView;
		let downloadButton;
		let topicCampaignDownload;
		let campaignExcelBtn;
		let excelBtn;
		let csvCampaignDetailExcelBtn;
		let topicDetailCsvLoading;
		const roleReports = getReportsByElement(this?.props?.roleFilterData?.currentUserReportRoleData, this?.props?.roleFilterData?.getAllReports, ROLEBASED_ELEMENT_SIDEBAR);

		if (this.state.popup) {
			return <Confirm open={true}
				cancelButton={null}
				content={"Please select Date Range which should not exceed day count more than 90 days"}
				onConfirm={this.handleConfirm}
			>
			</Confirm>
		}

		if (this.props.isFilApplied) {
			excelBtn = (
				<div>
					<button
						className="download-button"
						disabled={false}
						onClick={() => this.getExcelReport(actionTypes.SIDEBAREXCELCAMPAIGNDETAIL, "campaign_detail_sidebar")}
					>
						Generate XLSX
					</button>
				</div>
			)

			campaignExcelBtn = (
				<div>
					<button
						className="download-button"
						disabled={false}
						onClick={() => this.getExcelReport(actionTypes.SIDEBAREXCELCAMPAIGNSUMMARY, "summary_table_sidebar")}
					>
						Generate XLSX
					</button>
				</div>
			)

			topicCampaignDownload = (
				<div>
					<button
						className="download-button"
						disabled={false}
						onClick={() => this.getTopicCampaignCsvData()}
					>
						Download CSV
					</button>
				</div>
			);



			downloadButton = (
				<div>
					<button
						className="download-button"
						disabled={false}
						onClick={() => this.getTopicCsv()}
					>
						Download CSV
					</button>
				</div>
			);
			csvCampaignDetailExcelBtn = (
				<div>
					<button
						className="download-button"
						disabled={false}
						onClick={() => this.getCsvReport(actionTypes.SIDEBARCSVEXCELDETAIL, "detail_sidebar_csv")}
					>
						Generate CSV
					</button>
				</div>
			)
		} else {
			tableView = <Message>Please proceed with applying filter.</Message>;
		}

		return (
			<div>
				<div className="page-name-comp">
					<p className="page-name">Sidebars</p>
					<p>{this.props.reportDescription.find(report => report.name === "Sidebars")?.description}</p>
				</div>
				<SearchFilter></SearchFilter>
				{/* <TopicGraph /> */}
				<TopicChart />
				<div>
					<div className="table-heading-block">
						{
							(
								(roleReports?.some((report) => [SIDEBAR_CAMPAIGN_SUMMARY].includes(report.element) && report.permission === VIEW)) ||
								(roleReports?.some((report) => [SIDEBAR_CAMPAIGN_SUMMARY].includes(report.element) && report.permission === DOWNLOAD_CSV)) ||
								(roleReports?.some((report) => [SIDEBAR_CAMPAIGN_SUMMARY].includes(report.element) && report.permission === GENERATE_XLSX))
							) &&
							<React.Fragment>
								<div className="table-name">
								<p>Sidebar Campaign Summary</p>
							</div>
							<div className="class-relative">
								{
									this.props.isFilApplied && (roleReports?.some((report) => [SIDEBAR_CAMPAIGN_SUMMARY].includes(report.element) && report.permission === "Download CSV")) &&
									<div className="csv-button">
										{topicCampaignDownload}
									</div>
								}
								{
									this.props.isFilApplied && (roleReports?.some((report) => [SIDEBAR_CAMPAIGN_SUMMARY].includes(report.element) && report.permission === "Generate XLSX")) &&
									<div className="excel-button">
										{campaignExcelBtn}
										{this.props.postEmailUpdateSidebarSummary ? (
											<NotifyEmail
												email={this.props.postEmailUpdateSidebarSummary}
												table="sidebar_summary"
												cardPopup={this.props.postEmailUpdateSidebarSummary}
												cardPopupClose={actionTypes.SETSIDEBARSUMMARYPOPUPPOSTEMAIL}
											/>
										) : null}
									</div>
								}
								
							</div>
							</React.Fragment>
						}
						
					</div>
				</div>
				{
					(roleReports?.some((report) => [SIDEBAR_CAMPAIGN_SUMMARY].includes(report.element) && report.permission === VIEW)) &&
					<TopicSummaryTable/>
				}
				
				<div className="table-heading-block">
					{
						(
							(roleReports?.some((report) => [SIDEBAR_CAMPAIGN_DETAILS].includes(report.element) && report.permission === VIEW)) ||
							(roleReports?.some((report) => [SIDEBAR_CAMPAIGN_DETAILS].includes(report.element) && report.permission === DOWNLOAD_CSV)) ||
							(roleReports?.some((report) => [SIDEBAR_CAMPAIGN_DETAILS].includes(report.element) && report.permission === GENERATE_XLSX))
						) &&
						<div className="table-name">
							<p>Sidebar Campaign Detail</p>
						</div>
					}
					{
						this.props.isFilApplied && (roleReports?.some((report) => [SIDEBAR_CAMPAIGN_DETAILS].includes(report.element) && report.permission === DOWNLOAD_CSV)) &&
						<div className="csv-button">
							{downloadButton}
							{topicDetailCsvLoading}
						</div>
					}
					{
						this.props.isFilApplied && (roleReports?.some((report) => [SIDEBAR_CAMPAIGN_DETAILS].includes(report.element) && report.permission === GENERATE_XLSX)) &&
						<div className="class-relative">
							<div className="pg detail-excel-button">
								{excelBtn}
							</div>
							<div className="pg popup">
								{this.props.postEmailSidebarCampaignUpdate ? (
									<NotifyEmail
										email={this.props.postEmailSidebarCampaignUpdate}
										table="sidebar_campaign_detail"
										cardPopup={this.props.postEmailSidebarCampaignUpdate}
										cardPopupClose={actionTypes.SETSIDEBARCAMPAIGNDETAILPOPUPPOSTEMAIL}
									/>
								) : null}
							</div>
						</div>
					}
				</div>
				{
					(roleReports?.some((report) => [SIDEBAR_CAMPAIGN_DETAILS].includes(report.element) && report.permission === VIEW)) &&
					<TopicDetailTable/>
				}
				
				<div>
					<div className="table-heading-block">
						<div className="table-name">
							<p>Sidebar Detail</p>
						</div>
						<div className="class-relative">
							<div className="pg detail-excel-button">
								{csvCampaignDetailExcelBtn}
							</div>
							<div className="pg popup">
								{this.props.postEmailCsvSidebarUpdate ? (
									<NotifyEmail
										email={this.props.postEmailCsvSidebarUpdate}
										table="sidebar_details_table"
										cardPopup={this.props.postEmailCsvSidebarUpdate}
										cardPopupClose={actionTypes.SETSIDEBARDETAILCSVPOPUPPOSTEMAIL}
										csvExcel={true}
									/>
								) : null}
							</div>
						</div>
						{
							<Toggle
								label="Show"
								disabled={!this.props.isFilApplied}
								style={{ marginBottom: "20px", paddingLeft: "30px", paddingTop: "10px" }}
								onChange={() => {
									this.props.selTopicToggles(actionTypes.SELSIDEBARDETAILTABLETOGGLE);
								}}
								checked={this.props.filterData.selectSidebarDetailTable}
							/>
						}
					</div>
				</div>
				<SidebarDetail></SidebarDetail>
				{tableView}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		isFilApplied: state.topicFetchData.isFilApplied,
		filterData: state.topicFilterData,
		topicDetail: state.topicFetchData.topicDetail,
		topicSummary: state.topicFetchData.topicSummary,
		topicDetailCsv: state.topicFetchData.topicDetailCsv,
		topicCampaignCsv: state.topicFetchData.topicCampaignCsv,
		userEmail: state.authorized.userEmail,
		emailSidebarSummary: state.topicFetchData.emailSidebarSummary,
		emailSidebarDetail: state.topicFetchData.emailSidebarDetail,
		cardPopupSidebarSummary: state.topicFetchData.cardPopupSidebarSummary,
		cardPopupSidebarDetail: state.topicFetchData.cardPopupSidebarDetail,
		postEmailSidebarUpdate: state.topicFetchData.postEmailSidebarUpdate,
		postEmailUpdateSidebarSummary: state.topicFetchData.postEmailUpdateSidebarSummary,
		postEmailSidebarCampaignUpdate: state.topicFetchData.postEmailSidebarCampaignUpdate,
		cardPopupSidebarCampaignDetail: state.topicFetchData.cardPopupSidebarCampaignDetail,
		emailSidebarCampaignDetail: state.topicFetchData.emailSidebarCampaignDetail,
		postEmailCsvSidebarUpdate: state.topicFetchData.postEmailCsvSidebarUpdate,
		downloadFileName: state.topicFetchData.downloadFileName,
		reportDescription: state.allFilterReportData.reportDescription,
		roleFilterData: state.allFilterReportData,
	};
};

export default connect(mapStateToProps, {
	fetchTopicDetail,
	topicCsvLoading,
	fetchTopicSummary,
	fetchTopicCampaignSummaryCsvLoading,
	selTopicFilterValue,
	selTopicToggles,
	fetchTopicData
})(TopicSponsorshipsReport);
