export const NewsletterDetailsHeader = [
    { headerName: "Date", headerKey: "date" }, 
    { headerName: "Time", headerKey: "time" }, 
    { headerName: "Newsletter Type", headerKey: "newsletter_type"},
    { headerName: "Newsletter Template", headerKey: "template"},
    { headerName: "Newsletter Name", headerKey: "newsletter_title"},
    { headerName: "Newsletter Send Date", headerKey: "date"},
    { headerName: "Newsletter Send Time", headerKey: "time"},
    { headerName: "Subject Line", headerKey: "subject", className: "custom-column-large" },
    { headerName:"Action", headerKey:"action"},
    { headerName: "Slot Item Clicked", headerKey: "slot_item"},
    { headerName: "Organization", headerKey: "organization" },
    { headerName: "Organization Type", headerKey: "organization_type" },
    { headerName: "Organization Revenue", headerKey: "estimated_revenue" },
    { headerName: "Organization Size", headerKey: "size" },
    { headerName: "Sector", headerKey: "sector" },
    { headerName: "Industry Group", headerKey: "industry_group" },
    { headerName: "Industry", headerKey: "industry" }, 
    { headerName: "Sub Industry", headerKey: "sub_industry" },
    { headerName: "First Name", headerKey: "first_name" },
    { headerName: "Last Name", headerKey: "last_name" },
    { headerName: "Member Seniority", headerKey: "seniority" },
    { headerName: "Job Function", headerKey: "job_function" },
    { headerName: "Job Title", headerKey: "job_title" },
    { headerName: "Email Address", headerKey: "email_address"},
    { headerName: "City", headerKey: "city" },
    { headerName: "State/Province", headerKey: "state" },
    { headerName: "Country", headerKey: "country" },
    { headerName: "Postal Code/Zip", headerKey: "postal_code" }, 
    { headerName: "Timezone", headerKey: "timezone"},
];