import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Table } from "semantic-ui-react";
import Link from "../../hyperlinks/link";
import MultiLinks from "../../hyperlinks/multiLinks";
import {
  capitalizeFirstLetter,
  getAttachedPercentage,
  
  numFormat,
} from "../../../utilities/common";

const SCCampaignSummaryRow = (props) => {
  const [campaignValues, setCampaignValues] = useState([]);
  const [tableCellData, setTableCellData] = useState([]);

  useEffect(() => {
    setCampaignValues(props.summaryRow);
  }, [props.summaryRow]);

  useEffect(() => {
    getTableCellRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignValues, props.headerName]);

  const getTableCellRows = () => {
    const tableCell = [];

    if (props.headerName.indexOf("AGENCY") !== -1) {
      tableCell.push(<Table.Cell className="space_height" title= {campaignValues.agency_name}>{campaignValues.agency_name}</Table.Cell>);
    }
    //account name column
    if (props.headerName.indexOf("ACCOUNT") !== -1) {
      tableCell.push(
        <Table.Cell title={campaignValues.account} className="blogs_link space_height">
          {campaignValues.organizations ? (
            <MultiLinks
              type="organizations"
              object={campaignValues.organizations}
            ></MultiLinks>
          ) : (
            campaignValues.account
          )}
        </Table.Cell>
      );
    }
    if (props.headerName.indexOf("ORDER") !== -1) {
      tableCell.push(
        <Table.Cell title={campaignValues?.Order?.name} className="blogs_link space_height">
          {campaignValues?.Order?.name ? (
            <Link
              type={"orders"}
              urlParam={campaignValues.Order.uuid}
              refValue={campaignValues.Order.name}
            ></Link>
          ) : (
            ""
          )}
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("CAMPAIGN") !== -1) {
      tableCell.push(
        <Table.Cell title={campaignValues.name} className="blogs_link space_height">
          {campaignValues.name ? (
            <Link
              type={"campaigns"}
              urlParam={campaignValues.uuid}
              refValue={campaignValues.name}
            ></Link>
          ) : (
            ""
          )}
        </Table.Cell>
      );
    }
    if (props.headerName.indexOf("CSM") !== -1) {
      tableCell.push(
        <Table.Cell title = {campaignValues?.client_success_manager?.name} className="space_height">{campaignValues?.client_success_manager?.name}</Table.Cell>
      );
    }
    if (props.headerName.indexOf("START DATE") !== -1) {
      tableCell.push(
        <Table.Cell title = {campaignValues.start_date} className="space_height">{campaignValues.start_date}</Table.Cell>
      );
    }
    if (props.headerName.indexOf("END DATE") !== -1) {
      tableCell.push(
        <Table.Cell title={campaignValues.end_date} className="space_height">{campaignValues.end_date}</Table.Cell>
      );
    }
    if (props.headerName.indexOf("CAMPAIGN STATUS") !== -1) {
      tableCell.push(
        <Table.Cell title={capitalizeFirstLetter(campaignValues.status)} className="space_height">{capitalizeFirstLetter(campaignValues.status)}</Table.Cell>
      );
    }

    //Lead Delivery State
    if (props.headerName.indexOf("DELIVERY STATUS") !== -1) {
      tableCell.push(
        <Table.Cell title = {capitalizeFirstLetter(campaignValues.delivery_state)} className="space_height">
          {capitalizeFirstLetter(campaignValues.delivery_state)}
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("PAGEVIEWS GOAL") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" title={campaignValues.pageviews_goal === "N/A" ? "N/A" : numFormat(campaignValues.pageviews_goal)} textAlign="right">
          {campaignValues.pageviews_goal === "N/A" ? "N/A" : numFormat(campaignValues.pageviews_goal)}
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("PAGEVIEWS DELIVERED IN RANGE") !== -1) {
      tableCell.push(
        <Table.Cell className="space_height" textAlign="right" title={numFormat(campaignValues.pageviews_delivered_in_date_range)}>
          {numFormat(campaignValues.pageviews_delivered_in_date_range)}
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("PAGEVIEWS DELIVERED TOTAL") !== -1) {
      tableCell.push(
        <Table.Cell title={numFormat(campaignValues.total_pageviews_delivered)} className="space_height" textAlign="right">
          {numFormat(campaignValues.total_pageviews_delivered)}
        </Table.Cell>
      );
    }
    if (props.headerName.indexOf("PERCENT DELIVERED") !== -1) {
      tableCell.push(
        <Table.Cell title = {getAttachedPercentage(campaignValues.percent_delivered)} className="space_height" textAlign="right">
          {campaignValues.percent_delivered === "N/A"
            ? "N/A"
            : getAttachedPercentage(campaignValues.percent_delivered)}
        </Table.Cell>
      );
    }

    if (props.headerName.indexOf("PERCENT ELAPSED TIME") !== -1) {
      tableCell.push(
        <Table.Cell title={getAttachedPercentage(campaignValues.percent_completed)} className="space_height" textAlign="right">
          {getAttachedPercentage(campaignValues.percent_completed)}
        </Table.Cell>
      );
    }
    if (props.headerName.indexOf("PACE") !== -1) {
      tableCell.push(
        <>
        {campaignValues.percent_delivered === "N/A"
            ? <Table.Cell textAlign="right" title={campaignValues.pace}>
            <button className="button_color_grey" />
          </Table.Cell>
            : campaignValues.pace === "GREEN" ? (
              <Table.Cell textAlign="right" title={campaignValues.pace}>
                <button className="button_color_green" />
              </Table.Cell>
            ) : campaignValues.pace === "YELLOW" ? (
              <Table.Cell textAlign="right" title={campaignValues.pace}>
                <button className="button_color_yellow" />
              </Table.Cell>
            ) : (
              <Table.Cell textAlign="right" title={campaignValues.pace}> 
                <button className="button_color_red" />
              </Table.Cell>
            )}
        </>
      );
    }
    setTableCellData(tableCell);
  };
  return tableCellData;
};

const mapStateToProps = (state) => {
  return {
    sponsoredContentFilterData: state.sponsoredContentFilterData,
    filStartDate: state.sponsoredContentFilterData.startDate,
    filEndDate: state.sponsoredContentFilterData.endDate,
  };
};

export default connect(mapStateToProps)(SCCampaignSummaryRow);
