import React from "react";
import SingleSelectDropDown from "../base/singleSelectDropDown";
import "../../css/common.css";

const PrivacyFilter = (props) => {
   return (
      <SingleSelectDropDown
         label={props.label}
         placeholder={props.placeholder}
         value={props.value}
         onChange={props.onChange}
         options={[
            {
               key: "0",
               text: "All",
               value: "all",
            },
            {
               key: "1",
               text: "Yes",
               value: "yes",
            },
            {
               key: "2",
               text: "No",
               value: "no",
            },
         ]}
      ></SingleSelectDropDown>
   );
};

export default PrivacyFilter;