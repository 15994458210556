import React, { useState } from "react";
import { Icon, Menu } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import SiteIcon from '@mui/icons-material/DomainAdd';
import ActivityIcon from '@mui/icons-material/PersonSearch';
import WebIcon from "@mui/icons-material/Web";
import InsightsIcon from '@mui/icons-material/Insights';
import TimelineIcon from "@mui/icons-material/Timeline";
import MergeTypeIcon from "@mui/icons-material/MergeType";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import OrderIcon from "@mui/icons-material/ViewCompactOutlined";
import AssessmentIcon from '@mui/icons-material/Assessment';
import PageviewIcon from '@mui/icons-material/Pageview';
import DomainIcon from '@mui/icons-material/Domain';
import BasicIcon from '@mui/icons-material/Compress';
import ProIcon from '@mui/icons-material/CreditCard';
import ProPlushIcon from '@mui/icons-material/AddCard';
import AdminPanelSettingsIcon from '@mui/icons-material/SupervisorAccount';
import RoleMangement from '@mui/icons-material/ManageAccounts';
import RoleReportDescription from '@mui/icons-material/Description';
import "../hoc/layout.css";
import { toggleNestedMenu } from "../actions";
import _ from "lodash";
import { ROLEBASED_ADMIN_ROLES } from "../components/config/rolebasedReportNames";
import { getRolebasedReportFunction } from "../utilities/common";

 
const MenuItem = ({ item, location, onClick, isOpen, depth = 0, filterData }) => {
    const [isNestedOpen, setNestedOpen] = useState(filterData?.nestedMenus?.name === item.name || false);
 
    const handleToggle = () => setNestedOpen(!isNestedOpen);
 
    return (
        <>
            <Menu.Item
                as={item.link ? Link : "div"}
                to={item.link || undefined}
                name={item.name}
                position="left"
                active={location.pathname === item.link}
                onClick={onClick}
                className={isOpen && `line-vertical${item.name === "pageviews" ? "-pageview" : ""}`}
            >
                <div
                    className="menu-items"
                    onClick={item.children ? handleToggle : undefined} // Add click handler here
                    style={{ cursor: "pointer" }}
                >
                    {item.icon
                        ? React.cloneElement(item.icon, {
                            style: { color: isOpen && "#8A8A8A" },
                            className: "sidebar-icons",
                        })
                        : item.image && (
                            <img
                                src={item.image}
                                alt={item.label}
                                className="sticky-icons-image"
                            />
                        )}
                    <p
                        className="menu-name"
                        style={{
                                height: isOpen && item.name === "ads" ? "32em" : isOpen && (item.name === "content" || item.name === "decision-insights") ? "13em" : undefined,
                                marginLeft: (!isNestedOpen && !item.children) && "5px",
                            }}
                    >
                        {item.label}
                        {isOpen && item.component}
                    </p>
                    {item.children && (
                        <Icon
                            name={isNestedOpen ? "angle down" : "angle up"}
                            className="toggle-icon"
                        />
                    )}
                </div>
            </Menu.Item>
            {isNestedOpen && item.children && (
                <Menu.Menu
                    className={`nested-menu ${isNestedOpen ? "nested-menu-open" : "nested-menu-closed"}`}
                    style={{
                        paddingLeft: `${item.notMargin ? "2em" : "5em"}`,
                        borderLeft: "2px solid #f15a29",
                    }}
                >
                    {item.children.map((child, index) => (
                        <MenuItem
                            key={index}
                            item={child}
                            location={location}
                            onClick={child.action}
                            isOpen={isOpen}
                            depth={depth + 1}
                        />
                    ))}
                </Menu.Menu>
            )}
 
        </>
    );
};

const filterMenuItemsByReports = (menuItems, dataReports, userRoles) => {
    const reportSet = new Set(dataReports.map(item => item.report));

    const filterItems = (items) => {
        return items
            .map(item => {
                if (item.name === "admin" && userRoles.some(role => ROLEBASED_ADMIN_ROLES.includes(role))) {
                    return item;
                }

                const hasMatchingReport = reportSet.has(item.report);
                const filteredChildren = item.children ? filterItems(item.children) : [];

                if (hasMatchingReport || filteredChildren.length > 0) {
                    return {
                        ...item,
                        ...(filteredChildren.length > 0 && { children: filteredChildren }),
                    };
                }

                return null;
            })
            .filter(Boolean);
    };

    return filterItems(menuItems);
};

const SideDrawer = (props) => {
    const location = useLocation();
 
    const menuItems = [
        {
            name: "customerReporting",
            icon: <AssessmentIcon />,
            label: "Customer Reporting",
            notMargin: true,
            reports: "Customer",
            children: [
                {
                    name: "orderDetail",
                    icon: <OrderIcon />,
                    label: "Orders",
                    link: "/orders",
                    report: "Order",
                },
                {
                    name: "ads",
                    icon: <TimelineIcon />,
                    label: "Advertising",
                    reports: "Advertising",
                    children: [
                        { name: "all", label: "All", link: "/ads", report: "All Ads" },
                        { name: "contentBody", label: "Content Body", link: "/ads-content-body", report: "Content Body Ads" },
                        { name: "display", label: "Display", link: "/ads-display", report: "Display Ads" },
                        { name: "text", label: "Text", link: "/ads-text", report: "Text Ads" },
                        { name: "interstitial", label: "Interstitial", link: "/ads-interstitial", report: "Interstitial Ads" },
                        {
                            name: "newsletter",
                            label: "Newsletter",
                            notMargin: true,
                            reports: "Newsletters",
                            children: [
                                { name: "adsNewsletters", label: "Newsletter", link: "/ads-newsletter", report: "Newsletter Ads" },
                                { name: "newsletterBody", label: "Newsletter Body", link: "/ads-newsletter-body", report: "Newsletter Body Ads" },
                            ],
                        },
                        { name: "roadblock", label: "Roadblock", link: "/ads-roadblock", report: "Roadblock Ads" },
                        { name: "sidebarAds", label: "Sidebar", link: "/ads-sidebar", report: "Sidebar Ads" },
                        {
                            name: "beta",
                            label: "Beta",
                            notMargin: true,
                            reports: "Beta",
                            children: [
                                { name: "announcement", label: "Announcement", link: "/ads-announcement", report: "Announcements Ads" },
                                { name: "atfHomepage", label: "ATF Homepage", link: "/ads-atf-homepage", report: "ATF Homepage Ads" },
                                { name: "instantMessage", label: "Instant Message", link: "/ads-instant-message", report: "Instant Message Ads" },
                            ],
                        },
                    ],
                },
                {
                    name: "leads",
                    icon: <MergeTypeIcon />,
                    label: "Leads",
                    reports: "Leads",
                    children: [
                        { name: "lead", label: "Leads", link: "/leads", report: "Lead" },
                        { name: "engagements", label: "Engagements", link: "/engagements", report: "Engagement" },
                    ],
                },
                {
                    name: "sponsored",
                    icon: <PageviewIcon />,
                    label: "Pageviews",
                    link: "/sponsored",
                    report: "Sponsored",
                },
            ]
        },
        {
            name: "sites",
            icon: <SiteIcon />,
            label: "Sites",
            notMargin: true,
            reports: "Site",
            children: [
                {
                    name: "activity",
                    icon: <ActivityIcon />,
                    label: "Activity",
                    link: "/activity",
                    report: "Activity",
                },
                {
                    name: "content",
                    icon: <WebIcon />,
                    label: "Content",
                    reports: "Content",
                    children: [
                        { name: "author", label: "Author", link: "/author", report: "Author" },
                        { name: "newsletterSummary", label: "Newsletters", link: "/newsletters", report: "Newsletters" },
                        { name: "pageview", label: "Pageview", link: "/pageview", report: "Pageview" },
                        { name: "contentPerformance", label: "Content Performance", link: "/content-performance", report: "Content Performance" },
                        { name: "topicSponsorship", label: "Sidebars", link: "/sidebars", report: "Sidebar" },
                    ],
                },
                {
                    name: "productivityDetail",
                    icon: <TrendingUpIcon />,
                    label: "Goals",
                    link: "/productivity",
                    report: "Goals",
                },
                {
                    name: "sitePerformance",
                    icon: <DomainIcon />,
                    label: "Site Performance",
                    link: "/site",
                    report: "Site",
                },
            ]
        },
        {
            name: "decisionInsights",
            icon: <InsightsIcon />,
            label: "Decision Insights",
            notMargin: true,
            children: [
                { name: "basic", icon: <BasicIcon />, label: "Basic", link: "/decision-insights-basic", report: "Decision Insights Base"  },
                { name: "pro", icon: <ProIcon />, label: "Pro", link: "/decision-insights-pro", report: "Decision Insights Pro" },
                { name: "pro+", icon: <ProPlushIcon />, label: "Pro+", link: "/decision-insights-pro+", report: "Decision Insights Pro+" },
                // { name: "decision-insights-upgrade", label: "Decision Insights Upgrade", link: "/decision-insights-upgrade" },
            ]
        },
        {
            name: "admin",
            icon: <AdminPanelSettingsIcon />,
            label: "Admin",
            reports: "Admin",
            notMargin: true,
            children: [
                { name: "roles", icon: <RoleMangement />, label: "Roles", link: "/role-mapping", report: "Role Mapping" },
                { name: "reportDescription", icon: <RoleReportDescription />, label: "Report Description", link: "/report-description", report: "Report Description" },
            ]
        },
    ];

    const reportUuids = getRolebasedReportFunction(props?.filterData?.currentUserReportRoleData);
    const matchedData = props?.filterData?.getAllReports?.filter(item => reportUuids?.includes(item.uuid));

    const userRoles = _.flatMap(props?.filterData?.currentUserReportRoleData, "role_name");
    const filteredMenuItems = filterMenuItemsByReports(menuItems, matchedData || [], userRoles);

    return (
        <div className="side-drawer expanded">
            <Menu text vertical>
                {filteredMenuItems.map((item, index) => (
                    <MenuItem
                        key={index}
                        item={item}
                        location={location}
                        onClick={item.action}
                        isOpen={item.condition}
                        filterData={props.filterData}
                    />
                ))}
            </Menu>
        </div>
    );
};
 
const mapStateToProps = (state) => ({
    menuVisible: state.menuVisible.visible,
    filterData: state.allFilterReportData,
});
 
export default compose(connect(mapStateToProps, { toggleNestedMenu }))(SideDrawer);