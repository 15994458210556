import React from "react";
import { connect } from "react-redux";
import * as constName from "../components/config/const";
import DateAndFilter from "../components/OrderReport/DateAndFilter";
import Main from "../components/OrderReport/Main";
import DownloadCsvXlsx from "../components/common/DownloadCsvXlsx";
import EmailPopUp from "../components/common/EmailPopUp";
import { fetchOrderReportData } from "../actions";
import * as actionTypes from "../actions/actionTypes";
import { ORDER_SUMMARY, ROLEBASED_ELEMENT_ORDER } from "../components/config/rolebasedReportNames";
import { getReportsByElement } from "../utilities/common";

const OrderReport = (props) => {

    const handleGenerateCSV = () => {
        props.fetchOrderReportData(
            props.filterData,
            1,
            "CSV",
            actionTypes[constName.MODULE_ORDER_REPORT].GENERATE_ORDER_SUMMARY_CSV,
            constName.ORDER_REPORT_NAME,
            constName.MODULE_ORDER_REPORT,
            props.userEmail
        );
    }

    const roleReports = getReportsByElement(props?.roleFilterData?.currentUserReportRoleData, props?.roleFilterData?.getAllReports, ROLEBASED_ELEMENT_ORDER);

    return (
        <div>
            <div className="page-name-comp">
                <p className="page-name">{constName.ORDER_LABEL}</p>
                <p>{props.reportDescription.find(report => report.name === "Orders")?.description}</p>
            </div>
            <DateAndFilter 
                filterData={props.filterData} 
                reportActionType={constName.MODULE_ORDER_REPORT} 
                reportName={constName.ORDER_REPORT_NAME} 
                reportType="order"
            />
            <div>
                <div className="table-heading-block">
                    <div className="table-name">
                        <p>{`Order Summary`}</p>
                    </div>
                    {
                        (props?.filterData?.isFilApplied && (roleReports?.some((report) => [ORDER_SUMMARY].includes(report.element) && report.permission === constName.GENERATE_CSV))) && (
                            <div className="class-relative">
                                <DownloadCsvXlsx
                                    onClick={handleGenerateCSV}
                                    label={constName.GENERATE_CSV}
                                    disabled={!props.filterData.isFilApplied}
                                />
                                <div className="pg popup">
                                    {props.filterData.OrederSummayCSV && (
                                        <EmailPopUp
                                            email={props.filterData.OrederSummayCSV}
                                            table="ads_common"
                                            cardPopup={props.filterData.OrederSummayCSV}
                                            csvExcel={true}
                                            emailKey={`OrederSummayCSV`}
                                            reportActionType={constName.MODULE_ORDER_REPORT}
                                        />
                                    )}
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            {
                (roleReports?.some((report) => [ORDER_SUMMARY].includes(report.element) && report.permission === constName.VIEW)) &&
                <Main 
                    filterData={props.filterData} 
                    reportActionType={constName.MODULE_ORDER_REPORT} 
                    reportName={constName.ORDER_REPORT_NAME} 
                    reportType="order"
                />
            }
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        filterData: state.commonFilterData[constName.MODULE_ORDER_REPORT],
        userEmail: state.authorized?.userEmail,
        reportDescription: state.allFilterReportData.reportDescription,
        roleFilterData: state.allFilterReportData,
    };
};

export default connect(mapStateToProps, {
    fetchOrderReportData
})(OrderReport);