import React from "react";
import MultipleSelectDropDown from "../base/multiSelectDropdown";


const FirmographicFilter = (props) => {
  return (
    <>
      <div style={{display: "flex", flexWrap: "wrap", width: "100%", gap: "12px", rowGap: props.rowGap}}>
        {props.firmogaphicFilterData?.map((includeData, index) => {
          if(includeData?.isEmpty){
            return(
              <div key={index} style={{ width: "24rem" }}></div>
            )
          }
         return(
          <MultipleSelectDropDown
            options = {includeData.options}
            key= {index}
            value={includeData.value}
            label={includeData.label}
            placeholder={includeData.placeholder}
            style={{ width: "23.8rem" }}
            onSearchChange={ (e, {value}) =>{
              props.onSearchChange(e, includeData?.answerKey);
            }}
            onChange={(e, {value}) => {
               props.onChange(e, includeData?.answerKey, value);
            }}
            onClick = { (e)=>{
              props.onClick(e, includeData?.answerKey);
            }}
            />
         )
          })}
      </div>
    </>
  );
};

export default FirmographicFilter;
