import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Loader, Message, Table } from "semantic-ui-react";
import _ from "lodash";

import CampaignImpressionDetailsHeader from "./CampaignImpressionDetailsHeader";
import CampaignDetailsRow from "../../common/campaignDetailsRow";
import ShowMoreButton from "../../common/showMoreComponent";
import * as actionTypes from "../../../actions/actionTypes";
import { selectValue, fetchAdImpressions } from "../../../actions";

const CampaignDetailsTable = (props) => {
    const { filterData } = props;
    let conditionChkboxAppFilter = filterData.selCustomRangeCheckBox && filterData.isFilApplied && filterData.appliedDate === "Custom Date Range";

    const showMore = async () => {
        props.selectValue({ 
            dispatchType: actionTypes[props.reportActionType].SELECTVALUE, 
            reportType: props.reportActionType, 
            key: "isLoadingImpression",
            value: true
        });
        await props.fetchAdImpressions(
            filterData,
            filterData.impressionPageCount,
            "",
            actionTypes[props.reportActionType].SHOW_MORE_CAMPAIGN_IMPRESSIONS,
            props.reportName,
            props.reportActionType,
        );
    };

    return (
        <div>
            <div className="sortable-table-component">
                <Table sortable fixed singleLine>
                    <CampaignImpressionDetailsHeader {...props} />
                    {
                        !_.isEmpty(filterData.campaignImpressionDetailData) &&
                        <CampaignDetailsRow
                            data={filterData.campaignImpressionDetailData}
                            conditionChkboxAppFilter={conditionChkboxAppFilter}
                            {...props}
                        />
                    }
                </Table>
            </div>
            {
                props.filterData.isLoadingImpression &&
                <div className="show-loader">
                    <Loader active inline="centered" />
                </div>
            }
            {
                filterData.isFilApplied && !filterData.isLoadingImpression && _.isEmpty(filterData.campaignImpressionDetailData) &&
                <Message>No results found.</Message>
            }
            {
                !_.isEmpty(filterData.campaignImpressionDetailData) &&
                <ShowMoreButton onClick={showMore} isDisabled={filterData.isImpressionShowMore} />
            }
        </div>
    );
};

const mapStateToProps = () => {
    return {};
};

export default compose(connect(mapStateToProps, {
    selectValue,
    fetchAdImpressions,
}))(CampaignDetailsTable);