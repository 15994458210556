import React from 'react';
import SingleSelectDropDown from '../base/singleSelectDropDown';
import { connect } from 'react-redux';

const TypePicker = (props) => {
  return (
    <SingleSelectDropDown
      clearable
      options={props.assetTypes.map((type, index) => ({
        key: index,
        text: type.label,
        value: type.value,
      }))}
      label='Asset Type'
      placeholder='Asset Type'
      value={props.value}
      onChange={props.onChange}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    assetTypes: Object.values(state.assetTypes),
  };
};

export default connect(mapStateToProps, {})(TypePicker);
