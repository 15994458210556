import React from "react";
import { connect } from "react-redux";
import AdsPage from "./../adsPage";
import * as constName from "./../../components/config/const";
import { getReportsByElement } from "../../utilities/common";
import { ROLEBASED_ELEMENT_DISPLAY_ADS } from "../../components/config/rolebasedReportNames";

const AdsDisplay = (props) => {
    const displayAdsReports = getReportsByElement(props?.roleFilterData?.currentUserReportRoleData, props?.roleFilterData?.getAllReports, ROLEBASED_ELEMENT_DISPLAY_ADS);

    return (
        <AdsPage
            reportActionType={constName.MODULE_DISPLAY_ADS}
            reportName={constName.DISPLAY_ADS_REPORT_NAME}
            filterData={props.filterData}
            reportDescription={props.reportDescription.find(report => report.name === "Ads - Display")?.description}
            label={constName.DISPLAY_ADS_LABEL}
            roleReports={displayAdsReports}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        filterData: state.commonFilterData[constName.MODULE_DISPLAY_ADS],
        reportDescription: state.allFilterReportData.reportDescription,
        roleFilterData: state.allFilterReportData,
    };
};

export default connect(mapStateToProps)(AdsDisplay);