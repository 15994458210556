import React, { Component } from "react";
import { connect } from "react-redux";

import GlobalFilter from "../components/ads/common/GlobalFilter";
import AdsSummary from "../components/ads/common/Summary";
import CampaignImpressionDetailsTable from "../components/ads/common/CampaignImpressionDetailsTable";
import Toggle from "../components/base/toggle";
import { Confirm, Message } from "semantic-ui-react";
import Details from "../components/ads/common/Details";
import Graph from "../components/ads/common/Graphs";
import * as actionTypes from "../actions/actionTypes";
import { selectValue, fetchAdCampaignSummary, fetchAdImpressions, fetchAdData, } from "../actions";
import { downloadCSVData, getDays } from "../utilities/common";
import DownloadCsvXlsx from "../components/common/DownloadCsvXlsx";
import * as configName from "../components/config/const";
import * as downloadHelper from "../components/CommonFilter/downloadHelper";
import EmailPopUp from "../components/common/EmailPopUp";
import { url } from "../api/urlHelper";
import * as roleReport from "../components/config/rolebasedReportNames";

class AdsPage extends Component {
    state = {
        popup: false,
    };

    handleCampaignSummary = async () => {
        await downloadHelper.getAdCampaignCsvData(this.props, this.props.reportActionType, this.props.reportName);
        if (this.props.filterData.campaignCsvData) {
            downloadCSVData(this.props.filterData.campaignCsvData, this.props.filterData.downloadFileName);
        }
    };

    handleCampaignImpressionDetail = async () => {
        await downloadHelper.getAdImpressionCsvData(this.props, this.props.reportActionType, this.props.reportName);
        if (this.props.filterData.campaignImpressionCsvData) {
            downloadCSVData(this.props.filterData.campaignImpressionCsvData, this.props.filterData.downloadFileName);
        }
    };

    handleCampaignSummaryXlsx = async (tableName) => {
        let days = getDays(this.props.filterData.startDate, this.props.filterData.endDate);
        if (tableName === "details" && days > 90) {
            this.setState({ popup: true });
            setTimeout(() => {
                this.setState({ popup: false });
            }, 5000);
        } else if (tableName === "summary") {
            downloadHelper.getCampaignExcel(this.props, url.adCampaignReport, this.props.userEmail);
        } else if (tableName === "campaign_impression_detail") {
            downloadHelper.getCampaignExcel(this.props, url.adImpressionReport, this.props.userEmail);
        } else if (tableName === "details") {
            downloadHelper.getCampaignExcel(this.props, url.adDetail, this.props.userEmail);
        }
    };

    handleToggleChange = () => {
        this.props.selectValue({
            dispatchType: actionTypes[this.props.reportActionType].SELECTVALUE,
            reportType: this.props.reportActionType,
            key: 'isSelectDetailToggle',
            value: !this.props.filterData.isSelectDetailToggle
        });
    };

    handleConfirm = () => this.setState({ popup: false });

    render() {
        const { filterData, reportActionType, reportName, label, reportDescription } = this.props;

        return (
            <div>
                <div className="page-name-comp">
                    <p className="page-name">{label}</p>
                    <p>{reportDescription}</p>
                </div>
                <GlobalFilter
                    reportActionType={reportActionType}
                    reportName={reportName}
                    filterData={filterData}
                    roleReports={this?.props?.roleReports}
                />
                {
                    (this?.props?.roleReports?.some((report) => roleReport.allowedAdChart.includes(report.element) && report.permission === configName.CHART)) &&
                    <Graph
                        reportActionType={reportActionType}
                        reportName={reportName}
                        filterData={filterData}
                    />
                }
                
                <div>
                    {
                        (
                            this?.props?.roleReports?.some(
                                (report) =>
                                    roleReport.allowedAdSummaryElements.includes(report.element) &&
                                    [configName.VIEW, configName.DOWNLOAD_CSV, configName.GENERATE_XLSX].includes(report.permission)
                            )
                        ) &&
                            <div className="table-heading-block">
                                <div className="table-name">
                                    <p>{`${label} Campaign Summary`}</p>
                                </div>
                                
                                <div className="class-relative">
                                    {
                                        ((this?.props?.roleReports?.some((report) => roleReport.allowedAdSummaryElements.includes(report.element) && report.permission === configName.DOWNLOAD_CSV)) &&
                                        this?.props?.filterData?.isFilApplied) &&
                                        <div className="csv-button">
                                            <DownloadCsvXlsx
                                                onClick={this.handleCampaignSummary}
                                                label={configName.DOWNLOAD_CSV}
                                                disabled={!filterData.isFilApplied}
                                            />
                                        </div>
                                    }
                                    
                                    {
                                        ((this?.props?.roleReports?.some((report) => roleReport.allowedAdSummaryElements.includes(report.element) && report.permission === configName.GENERATE_XLSX)) &&
                                        this?.props?.filterData?.isFilApplied) &&
                                        <div className="excel-button">
                                            <DownloadCsvXlsx
                                                onClick={() => this.handleCampaignSummaryXlsx("summary")}
                                                label={configName.GENERATE_XLSX}
                                                disabled={!filterData.isFilApplied}
                                            />
                                            {
                                                this.props.filterData.postEmailSummary &&
                                                <EmailPopUp
                                                    email={this.props.filterData.postEmailSummary}
                                                    table="ads_common"
                                                    cardPopup={this.props.filterData.postEmailSummary}
                                                    csvExcel={false}
                                                    emailKey="postEmailSummary"
                                                    reportActionType={reportActionType}
                                                />
                                            }
                                        </div>
                                    }
                                    
                                </div>
                                
                            </div>
                    }
                    
                </div>
                {
                    (this?.props?.roleReports?.some((report) => roleReport.allowedAdSummaryElements.includes(report.element) && report.permission === configName.VIEW)) &&
                    <AdsSummary
                        reportActionType={reportActionType}
                        reportName={reportName}
                        filterData={filterData}
                    />
                }
                
                <div>
                    {
                        this?.props?.roleReports?.some(
                            (report) =>
                                roleReport.allowedCampaignDetailElements.includes(report.element) &&
                                [configName.VIEW, configName.DOWNLOAD_CSV, configName.GENERATE_XLSX].includes(report.permission)
                        ) &&
                        <div className="table-heading-block">
                            <div className="table-name">
                                <p>{`${label} Campaign Details`}</p>
                            </div>
                            <div className="class-relative">
                                {
                                    ((this?.props?.roleReports?.some((report) => roleReport.allowedCampaignDetailElements.includes(report.element) && report.permission === configName.DOWNLOAD_CSV)) &&
                                    this?.props?.filterData?.isFilApplied) &&
                                    <div className="csv-button">
                                        <DownloadCsvXlsx
                                            onClick={this.handleCampaignImpressionDetail}
                                            label={configName.DOWNLOAD_CSV}
                                            disabled={!filterData.isFilApplied}
                                        />
                                    </div>
                                }
                                {
                                    ((this?.props?.roleReports?.some((report) => roleReport.allowedCampaignDetailElements.includes(report.element) && report.permission === configName.GENERATE_XLSX)) &&
                                    this?.props?.filterData?.isFilApplied) &&
                                    <div className="excel-button">
                                        <DownloadCsvXlsx
                                            onClick={() => this.handleCampaignSummaryXlsx("campaign_impression_detail")}
                                            label={configName.GENERATE_XLSX}
                                            disabled={!filterData.isFilApplied}
                                        />
                                        {
                                            this.props.filterData.postEmailImpressionDetail &&
                                            <EmailPopUp
                                                email={this.props.filterData.postEmailImpressionDetail}
                                                table="ads_common"
                                                cardPopup={this.props.filterData.postEmailImpressionDetail}
                                                csvExcel={false}
                                                emailKey="postEmailImpressionDetail"
                                                reportActionType={reportActionType}
                                            />
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
                {
                    (this?.props?.roleReports?.some((report) => roleReport.allowedCampaignDetailElements.includes(report.element) && report.permission === configName.VIEW)) &&
                    <CampaignImpressionDetailsTable
                        reportActionType={reportActionType}
                        reportName={reportName}
                        filterData={filterData}
                    />
                }
                
                <div>
                    {
                        this?.props?.roleReports?.some(
                            (report) =>
                                roleReport.allowedDetailsElements.includes(report.element) &&
                                [configName.VIEW, configName.GENERATE_CSV].includes(report.permission)
                        ) &&
                        <div className="table-heading-block">
                            <div className="table-name">
                                <p>{`${label} Details`}</p>
                            </div>
                            {
                                ((this?.props?.roleReports?.some((report) => roleReport.allowedDetailsElements.includes(report.element) && report.permission === configName.GENERATE_CSV)) &&
                                this?.props?.filterData?.isFilApplied) &&
                                <div className="class-relative">
                                    <DownloadCsvXlsx
                                        onClick={() => this.handleCampaignSummaryXlsx("details")}
                                        label={configName.GENERATE_CSV}
                                        disabled={!filterData.isFilApplied}
                                    />
                                    {this.state.popup &&
                                        <Confirm
                                            open={true}
                                            cancelButton={null}
                                            content={configName.DATE_RANGE_EXCEED_MESSAGE}
                                            onConfirm={this.handleConfirm}
                                        >
                                        </Confirm>
                                    }
                                    <div className="pg popup">
                                        {
                                            this.props.filterData.postEmailDetails &&
                                            <EmailPopUp
                                                email={this.props.filterData.postEmailDetails}
                                                table="ads_common"
                                                cardPopup={this.props.filterData.postEmailDetails}
                                                csvExcel={true}
                                                emailKey="postEmailDetails"
                                                reportActionType={reportActionType}
                                            />
                                        }
                                    </div>
                                </div>
                            }
                            {
                                (this?.props?.roleReports?.some((report) => roleReport.allowedDetailsElements.includes(report.element) && report.permission === configName.VIEW)) &&
                                <Toggle
                                    label={filterData.isSelectDetailToggle ? "Hide" : "Show"}
                                    disabled={!filterData.isFilApplied}
                                    style={{ marginBottom: "20px", paddingLeft: "30px", paddingTop: "10px" }}
                                    onChange={this.handleToggleChange}
                                    checked={filterData.isSelectDetailToggle}
                                />
                            }
                        </div>
                    }
                    {
                        (this?.props?.roleReports?.some((report) => roleReport.allowedDetailsElements.includes(report.element) && report.permission === configName.VIEW)) &&
                        <Details
                            reportActionType={reportActionType}
                            reportName={reportName}
                            filterData={filterData}
                        />
                    }
                </div>
                {!filterData.isFilApplied && <Message>Please proceed with applying filter.</Message>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userEmail: state.authorized.userEmail,
    };
};

export default connect(mapStateToProps, {
    selectValue,
    fetchAdCampaignSummary,
    fetchAdImpressions,
    fetchAdData,
})(AdsPage);
