import React from "react";
import SingleSelectDropDown from "../base/singleSelectDropDown";

const NewslettersActionsPicker = (props) => {
	return (
		<SingleSelectDropDown
			className="no-blink"
			options={[
                {
					key: "0",
					text: "All",
					value: "all",
				},
				{
					key: "1",
					text: "Click",
					value: "click",
				},
				{
					key: "2",
					text: "Open",
					value: "open",
				},
				{
					key: "3",
					text: "Received",
					value: "received",
				}
			]}
			label="Action"
			placeholder="Action"
			{...props}
		/>
	);
};

export default NewslettersActionsPicker;
