import _ from "lodash";
import React, {useRef} from "react";
import { connect } from "react-redux";
import { Checkbox, Loader } from "semantic-ui-react";
import { selSiteReportToggles } from "../../actions";
import * as actionTypes from "../../actions/actionTypes";
import { getCapital, getDisplayDateChart } from "../../utilities/common";
import DownloadChart from "../common/downloadChart";
import SiteChartWithoutCompare from "./siteChartWithoutCompare";
import SiteReportComparedChart from "./siteReportComparedChart";

const SiteChart = (props) => {
  const chartNameDiv = useRef(null);
  let loading;
  let conditionCheck = props.isFilter && props.siteReportFilterData.selCutomRangeChkBox === true && props.siteReportFilterData.appliedDate === "Custom Date Range";
  if (props.loading) {
    loading = (
      <div className="show-loader">
        <Loader active inline="centered" />
      </div>
    );
  }

  return (
    <>
      <div className="ad-graph">
        <div ref={chartNameDiv}>
        {!_.isEmpty(props.siteReportCharts) && props.siteReportFilterData.showSiteChart  &&
          <DownloadChart
            chartNameDiv= {chartNameDiv.current}
            chartName = {(`Site-Chart-${getDisplayDateChart(new Date())}.jpg`)}
            style={{width:"36px", height: "36px", marginTop: 19, marginRight: 56, color: "#838486" }}
       />
        }
          <p className="ad-chart-name">
            {getCapital("Total Pageviews Per Day")}
            <Checkbox
              className="checkbox-label"
              data-html2canvas-ignore="true"
              toggle
              style={{
                color: "white",
                fontFamily: "Roboto",
                fontSize: "16px",
                paddingLeft: "20px",
                minHeight: 15,
              }}
              label={
                props.siteReportFilterData.showSiteChart ? "Hide" : "Show"
              }
              onChange={() => {
                props.selSiteReportToggles(actionTypes.SHOWSITECHARTS);
              }}
              checked={props.siteReportFilterData.showSiteChart}
            />
            {props.isFilter &&
              (_.isEmpty(props.siteAllPgSummaryData) ||
                _.isEmpty(props.siteEDPgSummaryData) ||
                _.isEmpty(props.siteSponsoredPgSummaryData)) &&
              props.siteReportFilterData.showSiteChart && (
                <span
                  className="message-chart-text"
                  style={{ marginLeft: "30px" }}
                >
                  {props.isFilter &&
                    (_.isEmpty(props.siteAllPgSummaryData) ||
                      _.isEmpty(props.siteEDPgSummaryData) ||
                      _.isEmpty(props.siteSponsoredPgSummaryData)) &&
                    props.siteReportFilterData.showSiteChart &&
                    "The chart will be displayed once the table data is loaded"}
                </span>
              )}
            {props.isFilter &&
              props.siteReportFilterData.showSiteChart &&
              _.size(props.siteAllPgSummaryData) > 0 &&
              _.size(props.siteEDPgSummaryData) > 0 &&
              _.size(props.siteSponsoredPgSummaryData) > 0 &&
              loading}
            {!_.isEmpty(props.siteReportCharts) &&
              props.siteReportFilterData.showSiteChart  && (
                conditionCheck ? <SiteReportComparedChart /> : <SiteChartWithoutCompare />
              )}
          </p>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    siteReportCharts: state.siteReportFilterData.siteReportCharts,
    siteReportFilterData: state.siteReportFilterData,
    siteAllPgSummaryData: state.siteReportFilterData.siteAllPgData,
    siteEDPgSummaryData: state.siteReportFilterData.siteEditorialData,
    siteSponsoredPgSummaryData: state.siteReportFilterData.siteSponsoredData,
    isFilter: state.siteReportFilterData.isApplied,
    loading: state.siteReportFilterData.isLoadingChart,
  };
};

export default connect(mapStateToProps, { selSiteReportToggles })(SiteChart);
