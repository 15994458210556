import React from "react";
import { connect } from "react-redux";
import { Chart } from "react-google-charts";
//import { rawData } from "../../components/pageview/data";
import { getDateEng, numFormat } from "../../utilities/common";
import _ from "lodash";

let tickSize = [];
const SponsoredGraph = (props) => {
	const pageViewData = () => {
        let conditionCheck = props.filterData.selCutomRangeChkBox === true && props.isFilApplied && props.filterData.appliedDate === "Custom Date Range";
		let data = [];
		let d = [];
		d.push({ type: "date" });
		d.push({
			type: "string",
			role: "tooltip",	
			p: { html: true },
		});
		d.push("number");
		(conditionCheck) && d.push("number");
		(conditionCheck) && d.push({
			type: "boolean",
			role: "certainty",
		});
		data.push(d);
		tickSize = [];
		
			if (props.sponsoredContentCharts) {
				props.sponsoredContentCharts.forEach((e) => {
				//rawData.forEach((e) => {
				d = [];
				d.push(new Date(e.date));
				d.push(
					(conditionCheck) ?
					 `<div class="tooltip-pageview-values" style='width: 200px; padding-top: 10px'>` +
					 	getDateEng(e.date) +
					 	`<hr></hr>` +
						 " <span style='height: 6px; width: 6px; margin-right: 5px; background-color: #CC167F;border-radius: 50%; display: inline-block;'></span>" +
						 numFormat(e.page_views) +
						` - Pageviews` +
						`<br />` +
						" <span style='height: 6px; width: 6px; margin-right: 5px; background-color: #CC167F;border-radius: 50%; display: inline-block;'></span>" +
						numFormat(e.prev_page_views) +
						" <span style='display: inline-block;'></span>" +
						` - Previous Pageviews` +
					 `</div>` :
					//  `</style>`:
					`<div class="tooltip-pageview-values"  style='padding-top: 15px; width: 160px;'>` +
						getDateEng(e.date) +
						`<hr></hr>` +
						" <span style='height: 6px; width: 6px; margin-right: 5px; background-color: #CC167F;border-radius: 50%; display: inline-block;'></span>" +
						numFormat(e.page_views) +
						` - Pageviews` +
					`</div>`
				);	
				d.push(e.page_views);
				(conditionCheck) && d.push(e.prev_page_views);
				(conditionCheck) && d.push(false);
				data.push(d);
				tickSize.push(new Date(e.date));
			});
		}
		return data;
	};

	var pData = pageViewData();

	const options = {
		colors: ["#CC167F", "#CC167F"],
		legend: "none",
		chartArea: { left: "3%", top: "13%", width: '90%',height: '80%', right: "3%"},
		intervals: { style: "line" },
		vAxis: {
			format: "short",
			minValue: 0,
			baselineColor: "#B6B7B8",
			minorGridlines: {
				count: 0,
			},
		},
		hAxis: {
			baselineColor: "#fff",
			gridlineColor: "#fff",
			format: "MMM dd",
			ticks: tickSize,
			// gridlines: {
			// 	minSpacing: 0,
			// },
			minorGridlines: {
				count: 0,
			},
			viewWindowMode: "pretty",
		},
		explorer: {
			actions: ["dragToZoom", "rightClickToReset"],
			axis: "horizontal",
			keepInBounds: true,
			maxZoomIn: 2,
			//  zoomDelta: 2,
		},
		crosshair: {
			orientation: "vertical",
			color: "grey",
			opacity: 2,
			trigger: "both",
		},
		focusTarget: "category",
		//focusTarget: "category",
		tooltip: { isHtml: true },
	};
	if (!_.isEmpty(props.sponsoredContentCharts) && props.isFilApplied) {
	//	if(props.sponsoredContentCharts){
		return (
			<>
				<div className="pageview-graph" id="chart-div" style={{ height: "430px" }}>
					<div style={{ marginBottom: "-30px", marginLeft: -10}}>
					<div
						style={{
							position: "relative",
							display: "flex",
							justifyContent: "space-between",
							zIndex: 1,
							marginLeft: -49,
							marginTop: 20
						}}
					>
						<div>
							<div className="legend-item">
								<span
									style={{ backgroundColor: "#CC167F", marginLeft: 50 }}
									className="legend-circle"
								/>
								<text
									style={{ color: "#656669", marginRight: 80 }}
									className="pageview-text"
								>
									Pageviews
								</text>
							</div>
						</div>
					</div>
					</div>
					<Chart
						chartType="LineChart"
						width="100%"
						height="400px"
						data={pData}
						options={options}
					/>
				</div>
			</>
		);
	}
	return <></>;
};

const mapStateToProps = (state) => {
	return {
		filterData: state.sponsoredContentFilterData,
		filStartDate: state.sponsoredContentFilterData.startDate,
		filEndDate: state.sponsoredContentFilterData.endDate,
		sponsoredContentCharts: state.sponsoredContentFetchData.sponsoredContentCharts,
		isFilApplied: state.sponsoredContentFetchData.isFilApplied,
		sponsoredContentFetchData: state.sponsoredContentFetchData
	};
};

export default connect(mapStateToProps)(SponsoredGraph);