import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { useLocation } from "react-router-dom";
import { Loader, Message, Table } from "semantic-ui-react";
import GenericTable from "./GenericTable";
import { columnConfigs } from "./ColumnConfig";
import { fetchAdData, selectValue, getTableHeaders, sendTableHeaders } from "../../../actions";
import * as actionTypes from "../../../actions/actionTypes";
import { url } from "../../../api/urlHelper";
import { AdsDetailHeader } from "./HeaderData/AdsDetailsHeader";
import ShowMoreButton from "../../common/showMoreComponent";
import _ from "lodash";
import DynamicHeaderColumnSelector from "../../common/DynamicHeaderColumnSelector";
import { AllAdsLocation } from "../../config/PathNames";

const AdDetails = (props) => {
    const [isSortIcon, setSortIcon] = useState(false);
    const [selectHeaders, setSelectHeaders] = useState([]);
    const [finalHeaderData, setFinalHeaderData] = useState();

    const location = useLocation();

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    useEffect(() => {
        props.getTableHeaders(
            `ads_${props.reportName}_details`,
            actionTypes[props.reportActionType].GET_DYNAMIC_TABLE_HEADER,
            props.reportActionType,
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const headerDataVal = props.filterData.getDynamicHeaderData;
        if (!_.isEmpty(headerDataVal)) {
            setSelectHeaders(headerDataVal);
            setFinalHeaderData(headerDataVal);
        } else if (headerDataVal === undefined || _.size(headerDataVal) === 0) {
            setSelectHeaders(AdsDetailHeader);
            setFinalHeaderData(AdsDetailHeader);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filterData.getDynamicHeaderData]);

    useEffect(() => {
        if (!props.filterData.detailTableToggleValue && props.filterData.isSelectDetailToggle && location.pathname !== AllAdsLocation) {
            props.fetchAdData(
                url.adDetail,
                props.filterData,
                1,
                "",
                actionTypes[props.reportActionType].AD_DETAILS,
                null,
                null,
                null,
                props.reportName,
                props.reportActionType,
            );
            dispatchDynamicSelectAction(props, "detailTableToggleValue", true);
            dispatchDynamicSelectAction(props, "isLoadingDetail", true);
        } else if (!props.filterData.isSelectDetailToggle) {
            dispatchDynamicSelectAction(props, "adDetailsData", []);
            dispatchDynamicSelectAction(props, "isLoadingDetail", false);
        }
        if (!props.filterData.isSelectDetailToggle) {
            dispatchDynamicSelectAction(props, "detailTableToggleValue", false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filterData.isSelectDetailToggle, props.filterData.isFilApplied]);

    const showMore = async () => {
        dispatchDynamicSelectAction(props, "isLoadingDetail", true);
        await props.fetchAdData(
            url.adDetail,
            props.filterData,
            props.filterData.detailsPageCount,
            "",
            actionTypes[props.reportActionType].AD_DETAILS_SHOW_MORE,
            null,
            null,
            null,
            props.reportName,
            props.reportActionType,
        );
    };

    const clickOnSortHeader = (e, extraData, tableData) => {
        if (extraData.checked) {
            setSelectHeaders([...selectHeaders, { headerName: tableData.headerName, headerKey: tableData.headerKey }]);
        } else {
            const removeData = selectHeaders.filter(
                (hName) => hName.headerKey !== tableData.headerKey
            );
            setSelectHeaders(removeData);
        }
    };

    const applyButtonClick = () => {
        let finalHeaderArray = [];
        AdsDetailHeader.forEach((mainHeader) => {
            selectHeaders.forEach((selectHeader) => {
                if (mainHeader.headerKey === selectHeader.headerKey) {
                    finalHeaderArray.push(selectHeader);
                }
            });
        });
        setFinalHeaderData(finalHeaderArray);
        props.sendTableHeaders(
            `ads_${props.reportName}_details`,
            finalHeaderArray,
            actionTypes[props.reportActionType].SEND_DYANMIC_TABLE_HEADER,
            props.reportActionType,
        );
        setSortIcon(!isSortIcon);
    };

    return (
        <div style={{ padding: "1px" }}>
            <div>
                <DynamicHeaderColumnSelector
                    isSortIcon={isSortIcon}
                    clickOnSortIcon={() => setSortIcon(!isSortIcon)}
                    TableHeaderData={AdsDetailHeader}
                    selectHeaders={selectHeaders}
                    clickOnSortHeader={clickOnSortHeader}
                    applyButtonClick={applyButtonClick}
                />
            </div>
            <div style={{ marginTop: "25px" }}>
                <div className="sortable-table-component">
                    <Table sortable fixed singleLine>
                        <GenericTable
                            data={props.filterData.adDetailsData}
                            headerOrder={finalHeaderData}
                            columnConfigs={columnConfigs}
                        />
                    </Table>
                </div>
                {
                    props.filterData.isSelectDetailToggle && props.filterData.isLoadingDetail &&
                    <div className="show-loader">
                        <Loader active inline="centered" />
                    </div>
                }
                {
                    props.filterData.isSelectDetailToggle && !_.isEmpty(props.filterData.adDetailsData) &&
                    <ShowMoreButton onClick={showMore} isDisabled={props.filterData.isDetailShowMore} />
                }
                {
                    props.filterData.isSelectDetailToggle && props.filterData.isFilApplied && !props.filterData.isLoadingDetail && _.isEmpty(props.filterData.adDetailsData) &&
                    <Message>No results found.</Message>
                }
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {};
};

export default compose(connect(mapStateToProps, {
    fetchAdData,
    selectValue,
    getTableHeaders,
    sendTableHeaders,
}))(AdDetails);
