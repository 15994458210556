import { subDays, differenceInDays } from 'date-fns';
import { getStartEndDates } from './common';

export const getCompareDateFunction = (props, dispatchDynamicSelectAction, period) => {
    const dates = getStartEndDates(period);
    dispatchDynamicSelectAction(props, "startDate", dates.startDate);
    dispatchDynamicSelectAction(props, "endDate", dates.endDate);

    const startDate = new Date(dates.startDate);
    const endDate = new Date(dates.endDate);
    const differenceinDays = differenceInDays(endDate, startDate);
    const prevEndDate = subDays(startDate, 1);
    const prevStartDate = subDays(new Date(prevEndDate), differenceinDays);
    dispatchDynamicSelectAction(props, "previousStartDate", prevStartDate);
    dispatchDynamicSelectAction(props, "previousEndDate", prevEndDate);

    return { startDate, endDate, prevStartDate, prevEndDate };
};

export const getCompareDateWithOtherFunction = (dispatchActionType, action, period) => {
    const dates = getStartEndDates(period);
    dispatchActionType(action, {key: "startDate", value: dates.startDate});
    dispatchActionType(action, {key: "endDate", value: dates.endDate});

    const startDate = new Date(dates.startDate);
    const endDate = new Date(dates.endDate);
    const differenceinDays = differenceInDays(endDate, startDate);
    const prevEndDate = subDays(startDate, 1);
    const prevStartDate = subDays(new Date(prevEndDate), differenceinDays);
    dispatchActionType(action, {key: "previousStartDate", value: prevStartDate});
    dispatchActionType(action, {key: "previousEndDate", value: prevEndDate});

    return { startDate, endDate, prevStartDate, prevEndDate };
};
