import React from "react";
import { Table } from "semantic-ui-react";
import MultiLinks from "../../../hyperlinks/multiLinks";

const CampaignComponent = ({ data }) => {
    return (
        data.campaign_name === "N/A" ?
            <Table.Cell className="space_height"> {"N/A"}</Table.Cell> 
            :
            <Table.Cell
                title={data.campaign_name}
                className="blogs_link space_height"
            >
                {
                    data.campaign_data ? <MultiLinks type="campaigns" object={data.campaign_data} /> : data.campaign_name
                }
            </Table.Cell>
    );
};

export default CampaignComponent;
