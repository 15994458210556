import * as constName from "../components/config/const";

export const FETCHASSETTYPES = "FETCHASSETTYPES";
export const FETCHSITES = "FETCHSITES";
export const FETCHSTATUSES = "FETCHSTATUSES";
export const FETCHLEADPARTNER = "FETCHLEADPARTNER";
export const FETCHLOCATIONS = "FETCHLOCATIONS";
export const FETCHSENIORITIES = "FETCHSENIORITIES";
export const FETCHJOBFUNCTIONS = "FETCHJOBFUNCTIONS";
export const FETCHFIRMOGRAPHICORSTYPE = "FETCHFIRMOGRAPHICORSTYPE"
//Auth Action Types
export const WPLINK = "WPLINK";
export const AUTHLOGIN = "AUTHLOGIN";
export const AUTHLOGOUT = "AUTHLOGOUT";
export const ISLOGGEDIN = "ISLOGGEDIN";
export const ERRORCODE = "ERRORCODE";
export const REMOVEERRORCODE = "REMOVEERRORCODE";
export const EMAIL = "EMAIL";
export const SHOWMORECLICKEDCOUNT = "SHOWMORECLICKEDCOUNT";
export const REFRESHCAMMPAIGNS = "REFRESHCAMMPAIGNS";
export const CLEAR_COLUMNS = "CLEAR_COLUMNS";
export const FILTER_PROFILE = "FILTER_PROFILE";
export const GET_ALL_FILTER_PROFILES = "GET_ALL_FILTER_PROFILES";
export const PROFILE_ACTIONS = "PROFILE_ACTIONS";
export const FETCH_USER_LIST = "FETCH_USER_LIST";
export const ADD_USER_LIST = "ADD_USER_LIST";
export const CLEAR_USER_LIST = "CLEAR_USER_LIST";
export const GET_ALL_SHARED_FILTER_PROFILES = "GET_ALL_SHARED_FILTER_PROFILES";
export const SELECTED_SHARED_USER_LIST = "SELECTED_SHARED_USER_LIST";
export const SET_SHARED_USERS = "SET_SHARED_USERS";
export const LOG_IN_WITH_OKTA = "LOG_IN_WITH_OKTA";
export const LOG_IN_WITH_WORDPRESS = "LOG_IN_WITH_WORDPRESS";
export const TOGGLE_NESTED_MENU = "TOGGLE_NESTED_MENU";
export const GET_ALL_REPORTS = "GET_ALL_REPORTS";
export const GET_CURRENT_USER_ROLE = "GET_CURRENT_USER_ROLE";
export const GET_ALL_ROLES = "GET_ALL_ROLES";
export const SELECT_ROLE_VALUES = "SELECT_ROLE_VALUES";
export const SAVE_ROLE_BASED_REPORT = "SAVE_ROLE_BASED_REPORT";
export const GET_ROLE_REPORT_DETAILS = "GET_ROLE_REPORT_DETAILS";

/*
 *******************************************************
 **********************Ad Manager***********************
 ******************************************************* */

export const FETCHAPPLIEDFILTER = "FETCHAPPLIEDFILTER";
export const READORGSID = "READORGSID";
export const SELECTEDADORGS = "SELECTEDADORGS";
export const READCAMPID = "READCAMPID";
export const READPGENORGSID = "READPGENORGSID";
export const READTOPICORGSID = "READTOPICORGSID";
export const READENGAGEMENTCAMPID = "READENGAGEMENTCAMPID";
export const READLEADCAMPSID = "READLEADCAMPSID";
export const OPENADSIDEBARMENUS = "OPENADSIDEBARMENUS";

//Ad manager SF Action Types
export const OPENADMENUS = "OPENADMENUS";
export const FETCHADCAMPAIGNS = "FETCHCAMPAIGNS";
export const FETCHADORDERS = "FETCHADORDERS";
export const FETCHADORDER = "FETCHADORDER";
export const FETCHSELADCAMPAIGNS = "FETCHSELADCAMPAIGNS";
export const FETCHSELADORDERS = "FETCHSELADORDERS";

//export const FETCHSELADORDER = "FETCHSELADORDER";
export const ADDADCAMPAIGNS = "ADDCAMPAIGNS";
export const ADDADORDERS = "ADDADORDERS";
export const RESETADCAMPAIGN = "RESETADCAMPAIGN";
export const RESETSELADCAMPAIGNS = "RESETSELADCAMPAIGNS";
export const RESETSELADORDERS = "RESETSELADORDERS";
//export const RESETSELADORDER = "RESETSELADORDER";
export const RESETADORDERS = "RESETADORDERS";
export const RESETADORDER = "RESETADORDER";
export const RESETADORGS = "RESETADORGS";
export const RESETADTAGIN = "RESETADTAGIN";
export const RESETADTAGEX = "RESETADTAGEX";
export const CLEARSEARCHADTAGIN = "CLEARSEARCHADTAGIN";
export const CLEARSEARCHADTAGEX = "CLEARSEARCHADTAGEX";
export const CLEARSEARCHADCAMPAIGN = "CLEARSEARCHADCAMPAIGN";
export const CLEARSEARCHADORDERS = "CLEARSEARCHADORDERS";
export const CLEARSEARCHADORGS = "CLEARSEARCHADORGS";
export const CLEARSEARCHADORDER = "CLEARSEARCHADORDER";
export const CLEARSEARCHADCOUNTRY = "CLEARSEARCHADCOUNTRY";
export const FETCHADENGORGS = "FETCHADENGORGS";
export const CLEARSEARCHADENGORGS = "CLEARSEARCHADENGORGS";
export const ADDADENGORGS = "ADDADENGORGS";
export const SETCAMPAIGNSUMMARYPGCOUNT = "SETCAMPAIGNSUMMARYPGCOUNT";
export const SETIMPRESSIONTABLEPGCOUNT = "SETIMPRESSIONTABLEPGCOUNT";
export const ADCAMPAIGNSUMMARYCSV = "ADCAMPAIGNSUMMARYCSV";
export const ADIMPRESSIONCSV = "ADIMPRESSIONCSV"
export const SETADDETAILPOPUPPOSTEMAIL = "SETADDETAILPOPUPPOSTEMAIL"

//Ads Excel Button
export const ADEXCELCAMPAIGNSUMMARY = "ADEXCELCAMPAIGNSUMMARY";
export const ADEXCELDETAIL = "ADEXCELDETAIL";
export const ADDETAILEXCEL = "ADDETAILEXCEL";
export const ADEXCELIMPRESSION = "ADEXCELIMPRESSION";
export const SETADSUMMARYPOPUPCLOSE = "SETADSUMMARYPOPUPCLOSE";
export const SETADSUMMARYPOPUPPOSTEMAIL = "SETADSUMMARYPOPUPPOSTEMAIL";
export const SETADDETAILPOPUPCLOSE = "SETADDETAILPOPUPCLOSE";
//Ads table column action types
export const ORGTARGETSCOL = "ORGTARGETSCOL";
export const GEOTARGETCOL = "GEOTARGETCOL";
export const VERTICALTARGETCOL = "VERTICALTARGETCOL";
export const INDUSTRYTARGETCOL = "INDUSTRYTARGETCOL";
export const ORGSIZETARGETCOL = "ORGSIZETARGETCOL";
export const JOBROLETARGETCOL = "JOBROLETARGETCOL";
export const JOBTITLETARGETCOL = "JOBTITLETARGETCOL";
export const RDCAMPCOL = "RDCAMPCOL";

/*
 *******************************************************
 ********************Lead Engagement*********************
 ******************************************************* */

//Lead SF action types
export const FETCHLEADORGS = "FETCHLEADORGS";
export const ADDLEADORGS = "ADDLEADORGS";
export const FETCHLEADCAMPAIGNS = "FETCHLEADCAMPAIGNS";
export const FETCHSELLEADCAMPAIGNS = "FETCHSELLEADCAMPAIGNS";
export const ADDLEADCAMPAIGNS = "ADDLEADCAMPAIGNS";
export const RESETLEADCAMPAIGN = "RESETLEADCAMPAIGN";
export const RESETSELLEADCAMPAIGNS = "RESETSELLEADCAMPAIGNS";
export const RESETLEADORGS = "RESETLEADORGS";
export const RESETLEADENGORDER = "RESETLEADENGORDER";
export const CLEARSEARCHLEADCAMPAIGN = "CLEARSEARCHLEADCAMPAIGN";
export const CLEARSEARCHLEADORGS = "CLEARSEARCHLEADORGS";
export const CLEARSEARCHLEADPAGETITLE = "CLEARSEARCHLEADPAGETITLE";
export const FETCHLEADENGORGS = "FETCHLEADENGORGS";
export const FETCHLEADENGORDER = "FETCHLEADENGORDER";
export const FETCHLEADPAGETITLE = "FETCHLEADPAGETITLE";
export const CLEARSEARCHLEADENGORGS = "CLEARSEARCHLEADENGORGS";
export const CLEARSEARCHLEADENGORDER = "CLEARSEARCHLEADENGORDER";
export const ADDLEADENGORGS = "ADDLEADENGORGS";
export const ADDLEADENGORDER = "ADDLEADENGORDER";
export const ADDLEADPAGETITLE = "ADDLEADPAGETITLE";
export const SETLEADENGSUMMARYPGCOUNT = "SETLEADENGSUMMARYPGCOUNT";
export const SETLEADENGDETAILPGCOUNT = "SETLEADENGDETAILPGCOUNT";
export const SELENGAGEMENTDELIVERYSTATE = "SELENGAGEMENTDELIVERYSTATE";
export const SELENGSHEADERDROPDOWNOPTIONVALUE = "SELENGSHEADERDROPDOWNOPTIONVALUE";
export const SHOWMOREENGSCLICKED = "SHOWMOREENGSCLICKED";
export const SELUTMPARAMENGSTOGGLE = "SELUTMPARAMENGSTOGGLE";
export const SELENGSSTATUSCHART = "SELENGSSTATUSCHART"
export const SELENGSACCEPTANCECHART = "SELENGSACCEPTANCECHART"
export const LEADENGSACCEPTANCEBARCHART = "LEADENGSACCEPTANCEBARCHART"
export const ENGAGEMENT_DETAIL_TABLE = "ENGAGEMENT_DETAIL_TABLE"
export const ENGAGEMENT_DETAIL_CSV = "ENGAGEMENT_DETAIL_CSV"
export const ENGSMAILDELIVERY = "ENGSMAILDELIVERY"
export const SETENGSMAILDELIVERYPOPUP = "SETENGSMAILDELIVERYPOPUP"
export const RESETLEADPAGETITLE= "RESETLEADPAGETITLE";
export const RESETLEADENGORGS= "RESETLEADENGORGS";
export const SELLEADENGTRAFFIC = "SELLEADENGTRAFFIC";
export const SELECTED_ENG_TOGGLE = "SELECTED_ENG_TOGGLE";
export const FETCH_ENG_REGULATION = "FETCH_ENG_REGULATION";
export const ADD_ENG_REGULATION = "ADD_ENG_REGULATION";
export const SEL_ENG_REGULATION = "SEL_ENG_REGULATION";
export const CLEAR_ENG_REGULATION = "CLEAR_ENG_REGULATION";
export const RESET_ENG_REGULATION = "RESET_ENG_REGULATION";

//URL filter action types
export const SELLEADENGURLSOURCE = "SELLEADENGURLSOURCE";
export const CLEARSEARCHLEADENGURLSOURCE = "CLEARSEARCHLEADENGURLSOURCE";
export const FETCHLEADENGURLSOURCE = "FETCHLEADENGURLSOURCE";
export const FETCHLEADENGURLMEDIUM = "FETCHLEADENGURLMEDIUM";
export const CLEARSEARCHLEADENGURLMEDIUM = "CLEARSEARCHLEADENGURLMEDIUM";
export const SELLEADENGURLMEDIUM = "SELLEADENGURLMEDIUM";
export const FETCHLEADENGURLCAMPAIGNS = "FETCHLEADENGURLCAMPAIGNS";
export const CLEARSEARCHLEADENGURLCAMPAIGNS = "CLEARSEARCHLEADENGURLCAMPAIGNS";
export const SELLEADENGURLCAMPAIGNS = "SELLEADENGURLCAMPAIGNS";
export const SELLEADENGURLTERM = "SELLEADENGURLTERM";
export const FETCHLEADENGURLTERM = "FETCHLEADENGURLTERM";
export const CLEARSEARCHLEADENGURLTERM = "CLEARSEARCHLEADENGURLTERM";
export const SELLEADENGURLCONTENT = "SELLEADENGURLCONTENT";
export const FETCHLEADENGURLCONTENT = "FETCHLEADENGURLCONTENT";
export const CLEARSEARCHLEADENGURLCONTENT = "CLEARSEARCHLEADENGURLCONTENT";
//Lead SF Sel Action Types
export const SELLEADSTARTDATE = "SELLEADSTARTDATE";
export const SELLEADENDDATE = "SELLEADENDDATE";
export const APPLYLEADDATE = "APPLYLEADDATE";
export const SELLEADSTARTDATEPREV = "SELLEADSTARTDATEPREV";
export const SELLEADPREVAPPLIEDDATE = "SELLEADPREVAPPLIEDDATE";
export const SELLEADENDDATEPREV = "SELLEADENDDATEPREV";
export const SELLEADPAGETITLE = "SELLEADPAGETITLE";
export const SELLEADORGS = "SELLEADORGS";
export const SELLEADCAMPAIGNS = "SELLEADCAMPAIGNS";
export const SELLEADORDER = "SELLEADORDER";
export const SELLEADSITE = "SELLEADSITE";
export const SELLEADSTATUS = "SELLEADSTATUS";
export const SELLEADTYPE = "SELLEADTYPE";
export const SELLEADENGORGS = "SELLEADENGORGS";
export const SELLEADPARTNER = "SELLEADPARTNER";
export const SELEESTOGGLE = "SELEESTOGGLE";
export const SELOLTOGGLE = "SELOLTOGGLE";
export const SELORFTOGGLE = "SELORFTOGGLE";
export const SELEFTOGGLE = "SELEFTOGGLE";
export const RESETLEADORFTOGGLE = "RESETLEADORFTOGGLE";
export const RESETLEADFTOGGLE = "RESETLEADFTOGGLE";
export const RESETLEADPTOGGLE = "RESETLEADPTOGGLE";
export const SELEIETOGGLE = "SELEIETOGGLE";
export const SELEOETOGGLE = "SELEOETOGGLE";
export const SELECETOGGLE = "SELECETOGGLE";
export const SELEQACTOGGLE = "SELEQACTOGGLE";
export const SELEICTOGGLE = "SELEICTOGGLE";
export const SELEFEETOGGLE = "SELEFEETOGGLE";
export const SELEGPTOGGLE = "SELEGPTOGGLE";
export const SELATOGGLE = "SELATOGGLE";
export const SELVTOGGLE = "SELVTOGGLE";
export const SELGEOTOGGLE = "SELGEOTOGGLE";
export const SELCQTOGGLE = "SELCQTOGGLE";
export const SELFTOGGLE = "SELFTOGGLE";
export const SELOOTTOGGLE = "SELOOTTOGGLE";
export const SELSIZETOGGLE = "SELSIZETOGGLE";
export const SELRTOGGLE = "SELRTOGGLE";
export const SELPTOGGLE = "SELPTOGGLE";
export const SELJFTOGGLE = "SELJFTOGGLE";
export const SELJTTOGGLE = "SELJTTOGGLE";
export const SELSTOGGLE = "SELSTOGGLE";
export const SELCFTOGGLE = "SELCFTOGGLE";
export const APPLYLEADFILTER = "APPLYLEADFILTER";
export const CLEARLEADFILTER = "CLEARLEADFILTER";
export const FETCHINILEADFILTER = "FETCHINILEADFILTER";
export const SELINILEADCAMPAIGNS = "SELINILEADCAMPAIGNS";
export const SELINILEADORGS = "SELINILEADORGS";
export const SELINILEADENGEDORGS = "SELINILEADENGEDORGS";
export const SELINILEADENGORDER = "SELINILEADENGORDER";
export const SELINILEADPAGETITLE = "SELINILEADPAGETITLE";
export const SELINILEADENGUTMSOURCE = "SELINILEADENGUTMSOURCE";
export const SELINILEADENGUTMMEDIUM = "SELINILEADENGUTMMEDIUM";
export const SELINILEADENGUTMCAMPAIGN = "SELINILEADENGUTMCAMPAIGN";
export const SELINILEADENGUTMTERM = "SELINILEADENGUTMTERM";
export const SELINILEADENGUTMCONTENT = "SELINILEADENGUTMCONTENT";
export const SELLEADENGSCHARTTOGGLE = "SELLEADENGSCHARTTOGGLE";
export const SELLEADENGSPACINGFILTERVALUE = "SELLEADENGSPACINGFILTERVALUE";
export const SELCLIENTUTMPARAMSTOGGLE = "SELCLIENTUTMPARAMSTOGGLE";
export const SHOWMESSAGEPOPUPENGS = "SHOWMESSAGEPOPUPENGS"
export const SHOWMESSAGEBOXREFRESHPOPUPENGS = "SHOWMESSAGEBOXREFRESHPOPUPENGS";
export const SETENGSDETAILPGAFTERREFRESHCOUNT = "SETENGSDETAILPGAFTERREFRESHCOUNT";
export const FETCHENGSREQVALIDATE = "FETCHENGSREQVALIDATE"
export const SELECTENGDOMAINFILTER = "SELECTENGDOMAINFILTER";
export const FETCH_LEAD_REGULATION = "FETCH_LEAD_REGULATION";
export const ADD_LEAD_REGULATION = "ADD_LEAD_REGULATION";
export const SEL_LEAD_REGULATION = "SEL_LEAD_REGULATION";
export const CLEAR_LEAD_REGULATION = "CLEAR_LEAD_REGULATION";
export const RESET_LEAD_REGULATION = "RESET_LEAD_REGULATION";

//Lead table action types
export const LEADENGSUMMARY = "LEADENGSUMMARY";
export const LEADENGSUMMARYCOUNT = "LEADENGSUMMARYCOUNT";
export const LEADENGSUMMARYSCV = "LEADENGSUMMARYSCV";
export const LEADENGCAMPAIGNSUMMARYSCV = "LEADENGCAMPAIGNSUMMARYSCV";
export const SORTLEADENGSUMMARY = "SORTLEADENGSUMMARY";
export const MORELEADENGSUMMARY = "MORELEADENGSUMMARY";
export const MORELEADENGSUMMARYLOADING = "MORELEADENGSUMMARYLOADING";
export const MORELEADENGCAMPAIGNSUMMARYLOADING =
  "MORELEADENGCAMPAIGNSUMMARYLOADING";
export const LEADENGS = "LEADENGS";
export const LEADENGSCSV = "LEADENGSCSV";
export const SORTLEADENGS = "SORTLEADENGS";
export const MORELEADENGSDATA = "MORELEADENGSDATA";
export const MORELEADENGSLOADING = "MORELEADENGSLOADING";
export const CLEARLEADSORT = "CLEARLEADSORT";
export const LEADCSVLOADING = "LEADCSVLOADING";
export const LEADCHANGEDLOGS = "LEADCHANGEDLOGS";
export const LEADEXCELSUMMARY = "LEADEXCELSUMMARY";
export const LEADDETAILEXCEL = "LEADDETAILEXCEL";
export const SETLEADSUMMARYPOPUPCLOSE = "SETLEADSUMMARYPOPUPCLOSE";
export const SETLEADSUMMARYPOPUPPOSTEMAIL = "SETLEADSUMMARYPOPUPPOSTEMAIL";
export const FETCHREQSTATUSROWWISE = "FETCHREQSTATUSROWWISE";
export const FETCHENGSREQ = "FETCHENGSREQ";
export const FETCHENGSREQROWWISE = "FETCHENGSREQROWWISE";
export const TOTAL_LEAD_COUNT = "TOTAL_LEAD_COUNT";
export const TOTAL_LEAD_DETAIL_COUNT = "TOTAL_LEAD_DETAIL_COUNT"
export const SHOWMESSAGEPOPUP = "SHOWMESSAGEPOPUP";
export const SHOWMESSAGEBOXREFRESHPOPUP = "SHOWMESSAGEBOXREFRESHPOPUP";
/*
 *******************************************************
 *************************Pageviews*********************
 ******************************************************* */

//Job titles
export const SELJOBTITLESEX = "SELJOBTITLESEX";
export const SELJOBTITLESIN = "SELJOBTITLESIN";
export const PAGEVIEWCHARTS = "PAGEVIEWCHARTS";
export const SETPGPOSTEMAILUPDATEPAGESUMMARY =
  "SETPGPOSTEMAILUPDATEPAGESUMMARY";
export const SETPGPAGESUMMARYPOPUPCLOSE = "SETPGPAGESUMMARYPOPUPCLOSE";
export const SETPGPAGEDETAILPOPUPCLOSE = "SETPGPAGEDETAILPOPUPCLOSE";
export const SETPGPOSTEMAILUPDATE = "SETPGPOSTEMAILUPDATE";
export const PGEMAILPAGEDETAIL = "PGEMAILPAGEDETAIL";
export const PGEMAILPAGESUMMARY = "PGEMAILPAGESUMMARY";
export const SHOWPAGEVIEWCHARTS = "SHOWPAGEVIEWCHARTS";
export const SELPAGEVIEWDETAILTABLETOGGLE = "SELPAGEVIEWDETAILTABLETOGGLE";
export const FETCHPGFIRMOGRAPHICORGSTYPE = "FETCHPGFIRMOGRAPHICORGSTYPE";
export const SELPGORGTYPE = "SELPGORGTYPE"; 
export const FETCHPGORGSSIZE = "FETCHPGORGSSIZE";
export const OPENPAGEVIEWMENUS = "OPENPAGEVIEWMENUS";
export const SELPGPARENTTOOGLE = "SELPGPARENTTOOGLE";
export const SELPGCHILDTOOGLE = "SELPGCHILDTOOGLE";
export const OPENPAGEVIEWSIDEBARMENUS = "OPENPAGEVIEWSIDEBARMENUS";
export const SET_PAGEVIEW_VALUES = "SET_PAGEVIEW_VALUES";
export const OPENDIMENUS = "OPEN_DI_MENUS";
export const OPENDISIDEBARMENUS = "OPEN_DI_SIDEBAR_MENUS";

//for Pageview detailed table
export const MOREPAGEVIEWDETAILEDLOADING = "MOREPAGEVIEWDETAILEDLOADING";
export const MOREPAGEVIEWDETAILED = "MOREPAGEVIEWDETAILED";
export const SORTPAGEVIEWDETAILED = "SORTPAGEVIEWDETAILED";
export const SETDETAILEDPGCOUNT = "SETDETAILEDPGCOUNT";
export const PAGEVIEWDETAILED = "PAGEVIEWDETAILED";
//Pageview Toggles

export const PAGEVIEWREPORTLOADING = "PAGEVIEWREPORTLOADING";
export const PAGEVIEWCOUNTAPIHIT = "PAGEVIEWCOUNTAPIHIT";
export const FETCHFIRMOGRAPHICORSSIZE = "FETCHFIRMOGRAPHICORSSIZE";
export const FETCHFIRMOGRAPHICORSREVENUE = "FETCHFIRMOGRAPHICORSREVENUE";
export const FETCHFIRMOGRAPHICSECTOR = "FETCHFIRMOGRAPHICSECTOR";
export const FETCHFIRMOGRAPHICINDUSTRYGROUP = "FETCHFIRMOGRAPHICINDUSTRYGROUP";
export const FETCHFIRMOGRAPHICINDUSTRY = "FETCHFIRMOGRAPHICINDUSTRY";
export const FETCHFIRMOGRAPHICSUBINDUSTRY = "FETCHFIRMOGRAPHICSUBINDUSTRY";
/*
 *******************************************************
 **********************Topic sponsorship*****************
 ******************************************************* */

//Topic sponsorship SF action types
export const FETCHTOPICCOUNTRY = "FETCHTOPICCOUNTRY";
export const FETCHTOPICORGS = "FETCHTOPICORGS";
export const ADDTOPICORGS = "ADDTOPICORGS";
export const FETCHTOPICORDER = "FETCHTOPICORDER";
export const ADDTOPICORDER = "ADDTOPICORDER";
export const FETCHTOPICCAMPAIGNS = "FETCHTOPICCAMPAIGNS";
export const FETCHSELTOPICCAMPAIGNS = "FETCHSELTOPICCAMPAIGNS";
export const ADDTOPICCAMPAIGNS = "ADDTOPICCAMPAIGNS";
export const RESETTOPICCAMPAIGN = "RESETTOPICCAMPAIGN";
export const RESETSELTOPICCAMPAIGNS = "RESETSELTOPICCAMPAIGNS";
export const RESETTOPICORGS = "RESETTOPICORGS";
export const RESETTOPICORDER = "RESETTOPICORDER";
export const CLEARSEARCHTOPICCAMPAIGN = "CLEARSEARCHTOPICCAMPAIGN";
export const CLEARSEARCHTOPICORGS = "CLEARSEARCHTOPICORGS";
export const CLEARSEARCHTOPICORDER = "CLEARSEARCHTOPICORDER";
export const CLEARSEARCHTOPICCOUNTRY = "CLEARSEARCHTOPICCOUNTRY";
export const FETCHTOPICENGORGS = "FETCHTOPICENGORGS";
export const CLEARSEARCHTOPICENGORGS = "CLEARSEARCHTOPICENGORGS";
export const ADDTOPICENGORGS = "ADDTOPICENGORGS";
export const SETTOPSUMMARYPGCOUNT = "SETSUMMARYPGCOUNT";
export const SETTOPDETAILTABLEPGCOUNT = "SETDETAILTABLEPGCOUNT";
export const SELTOPICPREVSTARTDATE = "SELTOPICPREVSTARTDATE";
export const SELTOPICPREVENDDATE = "SELTOPICPREVENDDATE";
export const SELTOPICCUSTOMRANGECHXBOX = "SELTOPICCUSTOMRANGECHXBOX";
export const SELTOPICPARENTTOOGLE = "SELTOPICPARENTTOOGLE";
export const SELTOPICCHILDTOOGLE = "SELTOPICCHILDTOOGLE";
export const SETSIDEBARDETAILCSVPOPUPPOSTEMAIL = "SETSIDEBARDETAILCSVPOPUPPOSTEMAIL";
export const SIDEBARCSVEXCELDETAIL = "SIDEBARCSVEXCELDETAIL"
export const RESETTOPICENGORGS = "RESETTOPICENGORGS";
export const RESETTOPICCOUNTRY = "RESETTOPICCOUNTRY";
export const RESETTOPICENGAGEDMEMBER = "RESETTOPICENGAGEDMEMBER";
export const RESETSIDEBARORGSTYPE = "RESETSIDEBARORGSTYPE";
export const RESETSIDEBARORGSSIZE = "RESETSIDEBARORGSSIZE";
export const RESETSIDEBARORGREVENUE = "RESETSIDEBARORGREVENUE";
export const RESETSIDEBARSECTOR = "RESETSIDEBARSECTOR";
export const RESETSIDEBARINDUSTRYGROUP = "RESETSIDEBARINDUSTRYGROUP";
export const RESETSIDEBARINDUSTRY = "RESETSIDEBARINDUSTRY";
export const RESETSIDEBARSUBINDUSTRY = "RESETSIDEBARSUBINDUSTRY";
export const RESETSIDEBARSENORITIESIN = "RESETSIDEBARSENORITIESIN";
export const RESETSIDEBARSENORITIESEX = "RESETSIDEBARSENORITIESEX";
export const RESETSIDEBARJOBFUNCTIONIN = "RESETSIDEBARJOBFUNCTIONIN";
export const RESETSIDEBARJOBFUNCTIONEX = "RESETSIDEBARJOBFUNCTIONEX";
export const SELSIDEBARDELIVERYSTATE = "SELSIDEBARDELIVERYSTATE";
export const SELSIDEBARPACINGFILTERVALUE = "SELSIDEBARPACINGFILTERVALUE";
export const FETCH_SIDEBAR_CSM = "FETCH_SIDEBAR_CSM";
export const ADD_SIDEBAR_CSM = "ADD_SIDEBAR_CSM";
export const CLEAR_SIDEBAR_CSM = "CLEAR_SIDEBAR_CSM";

//Topic sponsorship sel action types
export const SELTOPICCOUNTRY = "SELTOPICCOUNTRY";
export const SELTOPICSTARTDATE = "SELTOPICSTARTDATE";
export const SELTOPICENDDATE = "SELTOPICENDDATE";
export const SELTOPICSTARTDATEPREV = "SELTOPICSTARTDATEPREV";
export const SELTOPICPREVAPPLIEDDATE = "SELTOPICPREVAPPLIEDDATE";
export const SELTOPICENDDATEPREV = "SELTOPICENDDATEPREV";
export const APPLYTOPICDATE = "APPLYTOPICDATE";
export const SELTOPICORGS = "SELTOPICORGS";
export const SELTOPICORDER = "SELTOPICORDER";
export const SELTOPICCAMPAIGNS = "SELTOPICCAMPAIGNS";
export const SELTOPICSITE = "SELTOPICSITE";
export const SELTOPICSTATUS = "SELTOPICSTATUS";
export const SELTOPQATOGGLE = "SELTOPQATOGGLE";
export const SELTOPINTOGGLE = "SELTOPINTOGGLE";
export const SELTOPICENGORGS = "SELTOPICENGORGS";
export const APPLYTOPICFILTER = "APPLYTOPICFILTER";
export const CLEARTOPICFILTER = "CLEARTOPICFILTER";
export const FETCHINITOPICFILTER = "FETCHINITOPICFILTER";
export const SELINITOPICCAMPAIGNS = "SELINITOPICCAMPAIGNS";
export const SELINITOPICORGS = "SELINITOPICORGS";
export const SELINITOPICORDER = "SELINITOPICORDER";
export const SELINITOPICENGORGS = "SELINITOPICENGORGS";
export const SELINITOPICCOUNTRY = "SELINITOPICCOUNTRY";
export const SELSIDEBARDETAILTABLETOGGLE = "SELSIDEBARDETAILTABLETOGGLE";
export const SELSCPARENTTOOGLE = "SELSCPARENTTOOGLE";
export const SELSCCHILDTOOGLE = "SELSCCHILDTOOGLE";
export const DETAILTABLESIDEBARTOGGLLEVALUE = "DETAILTABLESIDEBARTOGGLLEVALUE";
export const SELECTED_SIDEBAR_TOGGLE = "SELECTED_SIDEBAR_TOGGLE";

//Topic Sponsorship table action types
export const TOPICSUMMARY = "TOPICSUMMARY";
export const TOPICSUMMARYCSV = "TOPICSUMMARYCSV";
export const TOPICDETAIL = "TOPICDETAIL";
export const TOPICDETAILCSV = "TOPICDETAILCSV";
export const SORTTOPICSUMMARY = "SORTTOPICSUMMARY";
export const CLEARTOPICSUMMARYSORT = "CLEARTOPICSUMMARYSORT";
export const MORETOPICSUMMARYDATA = "MORETOPICSUMMARYDATA";
export const MORETOPICSUMMARYLOADING = "MORETOPICSUMMARYLOADING";
export const SORTTOPICDETAIL = "SORTTOPICDETAIL";
export const CLEARTOPICDETAILSORT = "CLEARTOPICDETAILSORT";
export const MORETOPICDETAILDATA = "MORETOPICDETAILDATA";
export const MORETOPICDETAILLOADING = "MORETOPICDETAILLOADING";
export const TOPICCSVLOADING = "TOPICCSVLOADING";
export const TOPICCAMPAIGNCSVLOADING = "TOPICCAMPAIGNCSVLOADING";
export const SIDEBARDETAIL = "SIDEBARDETAIL";
export const CLEARSIDEBARDETAILSORT = "CLEARSIDEBARDETAILSORT";
export const SIDEBARDETAILSORT = "SIDEBARDETAILSORT";
export const MORESIDEBARDETAILDATA = "MORESIDEBARDETAILDATA";
export const MORESIDEBARDETAILLOADING = "MORESIDEBARDETAILLOADING";
export const SETSIDEBARDETAILPGCOUNT = "SETSIDEBARDETAILPGCOUNT";
export const SIDEBAREXCELCAMPAIGNSUMMARY = "SIDEBAREXCELCAMPAIGNSUMMARY";
export const SIDEBAREXCELDETAIL = "SIDEBAREXCELDETAIL";
export const SIDEBAREXCELCAMPAIGNDETAIL = "SIDEBAREXCELCAMPAIGNDETAIL";
export const SETSIDEBARSUMMARYPOPUPCLOSE = "SETSIDEBARSUMMARYPOPUPCLOSE";
export const SETSIDEBARDETAILPOPUPCLOSE = "SETSIDEBARDETAILPOPUPCLOSE";
export const SETSIDEBARCAMPAIGNDETAILPOPUPCLOSE =
  "SETSIDEBARCAMPAIGNDETAILPOPUPCLOSE";
export const SIDEBARDETAILEXCEL = "SIDEBARDETAILEXCEL";
export const SETSIDEBARSUMMARYPOPUPPOSTEMAIL =
  "SETSIDEBARSUMMARYPOPUPPOSTEMAIL";
export const SETSIDEBARDETAILPOPUPPOSTEMAIL = "SETSIDEBARDETAILPOPUPPOSTEMAIL";
export const SETSIDEBARCAMPAIGNDETAILPOPUPPOSTEMAIL =
  "SETSIDEBARCAMPAIGNDETAILPOPUPPOSTEMAIL";
export const SELTOPICFIRMOGRAPHIC = "SELTOPICFIRMOGRAPHIC";
export const SELTOPICORGTYPE = "SELTOPICORGTYPE";
export const SELTOPICORGSIZE = "SELTOPICORGSIZE";
export const SELTOPICORGSREVENUE = "SELTOPICORGSREVENUE";
export const SELTOPICSECTOR = "SELTOPICSECTOR";
export const SELTOPICINDUSTRYGROUP = "SELTOPICINDUSTRYGROUP";
export const SELTOPICINDUSTRY = "SELTOPICINDUSTRY";
export const SELTOPICSUBINDUSTRY = "SELTOPICSUBINDUSTRY";
export const APPLYSIDEBARORGSTYPE = "APPLYSIDEBARORGSTYPE";
export const CLEARSEARCHSIDEBARORGSTYPE = "CLEARSEARCHSIDEBARORGSTYPE";
export const FETCHSIDEBARFIRMOGRAPHICORGSTYPE = "FETCHSIDEBARFIRMOGRAPHICORGSTYPE";
export const FETCHSIDEBARORGSSIZE = "FETCHSIDEBARORGSSIZE";
export const APPLYSIDEBARORGSSIZE = "APPLYSIDEBARORGSSIZE";
export const CLEARSEARCHSIDEBARORGSSIZE = "CLEARSEARCHSIDEBARORGSSIZE";
export const FETCHSIDEBARORGSREVENUE = "FETCHSIDEBARORGSREVENUE";
export const APPLYSIDEBARREVENUE = "APPLYSIDEBARREVENUE";
export const CLEARSEARCHSIDEBARORGSREVENUE = "CLEARSEARCHSIDEBARORGSREVENUE";
export const FETCHSIDEBARSECTOR = "FETCHSIDEBARSECTOR";
export const APPLYSIDEBARSECTOR = "APPLYSIDEBARSECTOR";
export const CLEARSEARCHSIDEBARSECTOR = "CLEARSEARCHSIDEBARSECTOR";
export const FETCHSIDEBARINDUSTRYGROUP = "FETCHSIDEBARINDUSTRYGROUP";
export const APPLYSIDEBARINDUSTRYGROUP = "APPLYSIDEBARINDUSTRYGROUP";
export const CLEARSEARCHSIDEBARINDUSTRYGROUP = "CLEARSEARCHSIDEBARINDUSTRYGROUP";
export const FETCHSIDEBARINDUSTRY = "FETCHSIDEBARINDUSTRY";
export const APPLYSIDEBARINDUSTRY = "APPLYSIDEBARINDUSTRY";
export const CLEARSEARCHSIDEBARINDUSTRY = "CLEARSEARCHSIDEBARINDUSTRY";
export const FETCHSIDEBARSUBINDUSTRY = "FETCHSIDEBARSUBINDUSTRY";
export const APPLYSIDEBARSUBINDUSTRY = "APPLYSIDEBARSUBINDUSTRY";
export const CLEARSEARCHSIDEBARSUBINDUSTRY = "CLEARSEARCHSIDEBARSUBINDUSTRY";
export const ADDSIDEBARCONTRIES = "ADDSIDEBARCONTRIES";
export const SELSIDEBARENGAGEDMEMBER = "SELSIDEBARENGAGEDMEMBER";
export const FETCHSIDEBARENGMEMBER = "FETCHSIDEBARENGMEMBER";
export const ADDSIDEBARENGMEMBER = "ADDSIDEBARENGMEMBER";
export const CLEARSEARCHSIDEBARENGMEMBER = "CLEARSEARCHSIDEBARENGMEMBER";
export const SELTOPICPERSONA = "SELTOPICPERSONA";
export const CLEARSEARCHSIDEBARSENIORITIESIN = "CLEARSEARCHSIDEBARSENIORITIESIN";
export const FETCHSIDEBARSENIORITIESIN = "FETCHSIDEBARSENIORITIESIN";
export const APPLYSIDEBARSENIORITIESIN = "APPLYSIDEBARSENIORITIESIN";
export const SELSIDEBARSENIORITIESIN = "SELSIDEBARSENIORITIESIN";
export const CLEARSEARCHSIDEBARSENIORITIESEX = "CLEARSEARCHSIDEBARSENIORITIESEX";
export const FETCHSIDEBARSENIORITIESEX = "FETCHSIDEBARSENIORITIESEX";
export const APPLYSIDEBARSENIORITIESEX = "APPLYSIDEBARSENIORITIESEX";
export const SELSIDEBARSENIORITIESEX = "SELSIDEBARSENIORITIESEX";
export const FETCHSIDEBARJOBFUNCTIONSIN = "FETCHSIDEBARJOBFUNCTIONSIN";
export const APPLYSIDEBARJOBFUNCTIONSIN = "APPLYSIDEBARJOBFUNCTIONSIN";
export const CLEARSEARCHSIDEBARJOBFUNCTIONSIN = "CLEARSEARCHSIDEBARJOBFUNCTIONSIN";
export const FETCHSIDEBARJOBFUNCTIONSEX = "FETCHSIDEBARJOBFUNCTIONSEX";
export const APPLYSIDEBARJOBFUNCTIONSEX = "APPLYSIDEBARJOBFUNCTIONSEX";
export const CLEARSEARCHSIDEBARJOBFUNCTIONSEX = "CLEARSEARCHSIDEBARJOBFUNCTIONSEX";
export const SELSIDEBARJOBFUNCTIONSIN = "SELSIDEBARJOBFUNCTIONSIN";
export const SELSIDEBARJOBFUNCTIONSEX = "SELSIDEBARJOBFUNCTIONSEX";
export const SELSIDEBARJOBTITLESEX = "SELSIDEBARJOBTITLESEX";
export const SELSIDEBARJOBTITLESIN = "SELSIDEBARJOBTITLESIN";
export const SELINISIDEBARSECTOR = "SELINISIDEBARSECTOR";
export const SELINISIDEBARINDUSTRYGROUP = "SELINISIDEBARINDUSTRYGROUP";
export const SELINISIDEBARINDUSTRY = "SELINISIDEBARINDUSTRY";
export const SELINISIDEBARSUBINDUSTRY = "SELINISIDEBARSUBINDUSTRY";
export const SELINISIDEBARORGSTYPE = "SELINISIDEBARORGSTYPE";
export const SELINISIDEBARORGSSIZE = "SELINISIDEBARORGSSIZE";
export const SELINISIDEBARORGREVENUE = "SELINISIDEBARORGREVENUE";
export const SELINISIDEBARSENORITIESIN = "SELINISIDEBARSENORITIESIN";
export const SELINISIDEBARSENORITIESEX = "SELINISIDEBARSENORITIESEX";
export const SELINISIDEBARJOBFUNCTIONIN = "SELINISIDEBARJOBFUNCTIONIN";
export const SELINISIDEBARJOBFUNCTIONEX = "SELINISIDEBARJOBFUNCTIONEX";
export const SELINISIDEBARENGAGEDMEMBER = "SELINISIDEBARENGAGEDMEMBER";
export const GETSIDEBARDYNAMICTABLEHEADER = "GETSIDEBARDYNAMICTABLEHEADER";
export const SENDSIDEBARDYANMICTABLEHEADER = "SENDSIDEBARDYANMICTABLEHEADER";
export const UPDATESIDEBARDETAILLOADER = "UPDATESIDEBARDETAILLOADER"
export const RESETSTATESSIDEBARDETAIL = "RESETSTATESSIDEBARDETAIL"
export const SELSIDEBARTRAFFICFILTERVALUE = "SELSIDEBARTRAFFICFILTERVALUE";
export const SELECTED_SPONSORED_TOGGLE = "SELECTED_SPONSORED_TOGGLE";
/*
 *******************************************************
 ****************************Css************************
 ******************************************************* */

export const CHANGEANIMATION = "CHANGEANIMATION";
export const CHANGEGRID = "CHANGEGRID";

/*
 *******************************************************
 ********************Lead Engagement Client*********************
 ******************************************************* */

//Lead Client SF action types
export const FETCHCLIENTLEADORGS = "FETCHCLIENTLEADORGS";
export const FETCHCLIENTLEADORDER = "FETCHCLIENTLEADORDER";
export const ADDCLIENTLEADORGS = "ADDCLIENTLEADORGS";
export const ADDCLIENTLEADORDER = "ADDCLIENTLEADORDER";
export const FETCHCLIENTLEADCAMPAIGNS = "FETCHCLIENTLEADCAMPAIGNS";
export const FETCHSELCLIENTLEADCAMPAIGNS = "FETCHSELCLIENTLEADCAMPAIGNS";
export const ADDCLIENTLEADCAMPAIGNS = "ADDCLIENTLEADCAMPAIGNS";
export const RESETCLIENTLEADCAMPAIGN = "RESETCLIENTLEADCAMPAIGN";
export const RESETSELCLIENTLEADCAMPAIGNS = "RESETSELCLIENTLEADCAMPAIGNS";
export const RESETCLIENTLEADORGS = "RESETCLENTLEADORGS";
export const RESETCLIENTLEADORDER = "RESETCLIENTLEADORDER";
export const CLEARSEARCHCLIENTLEADCAMPAIGN = "CLEARSEARCHCLIENTLEADCAMPAIGN";
export const CLEARSEARCHCLIENTLEADORGS = "CLEARSEARCHCLIENTLEADORGS";
export const CLEARSEARCHCLIENTLEADORDER = "CLEARSEARCHCLIENTLEADORDER";
export const CLEARSEARCHCLIENTLEADPAGETITLE = "CLEARSEARCHCLIENTLEADPAGETITLE";
export const FETCHCLIENTLEADENGORGS = "FETCHCLIENTLEADENGORGS";
export const FETCHCLIENTLEADPAGETITLE = "FETCHCLIENTLEADPAGETITLE";
export const CLEARSEARCHCLIENTLEADENGORGS = "CLEARSEARCHCLIENTLEADENGORGS";
export const ADDCLIENTLEADENGORGS = "ADDCLIENTLEADENGORGS";
export const ADDCLIENTLEADPAGETITLE = "ADDCLIENTLEADPAGETITLE";
export const SETCLIENTSUMMARYPGCOUNT = "SETCLIENTSUMMARYPGCOUNT";
export const SETCLIENTDETAILPGCOUNT = "SETCLIENTDETAILPGCOUNT";
export const TOPICHUBCHART = "TOPICHUBCHART";
export const SELCLIENTLEADFILTERSTATUS = "SELCLIENTLEADFILTERSTATUS";
export const SELCLIENTLEADFILTERSTATE = "SELCLIENTLEADFILTERSTATE";
export const SELGLOBALLEADSTATUS = "SELGLOBALLEADSTATUS";
export const APPLYDATESTATE = "APPLYDATESTATE";
export const SELGLOBALVERIFICATIONSTATE = "SELGLOBALVERIFICATIONSTATE";
export const SELLEADSTATUSROWWISE = "SELLEADSTATUSROWWISE";
export const SELLEADCHECKBOXSTATE = "SELLEADCHECKBOXSTATE";
export const SELVERIFICATIONSTATEROWWISE = "SELVERIFICATIONSTATEROWWISE";
export const SHOWDOWNLOADBUTTON = "SHOWDOWNLOADBUTTON";
export const SELLEADPARENTTOOGLE = "SELLEADPARENTTOOGLE";
export const SELLEADCHILDTOOGLE = "SELLEADCHILDTOOGLE";
export const RESETCLIENTLEADENGEDORGS = "RESETCLIENTLEADENGEDORGS";
export const RESETCLIENTLEADPAGETITLE = "RESETCLIENTLEADPAGETITLE";
//Lead Client SF Sel Action Types
export const SELCLIENTLEADSTARTDATE = "SELCLIENTLEADSTARTDATE";
export const SELCLIENTLEADENDDATE = "SELCLIENTLEADENDDATE";
export const SELCLIENTSTARTDATEPREV = "SELCLIENTSTARTDATEPREV";
export const SELCLIENTPREVAPPLIEDDATE = "SELCLIENTPREVAPPLIEDDATE";
export const SELCLIENTENDDATEPREV = "SELCLIENTENDDATEPREV";
export const APPLYCLIENTLEADDATE = "APPLYCLIENTLEADDATE";
export const SELCLIENTLEADPAGETITLE = "SELCLIENTLEADPAGETITLE";
export const SELCLIENTLEADORGS = "SELCLIENTLEADORGS";
export const SELCLIENTLEADORDER = "SELCLIENTLEADORDER";
export const SELCLIENTLEADCAMPAIGNS = "SELCLIENTLEADCAMPAIGNS";
export const SELCLIENTLEADSITE = "SELCLIENTLEADSITE";
export const SELCLIENTLEADSTATUS = "SELCLIENTLEADSTATUS";
export const SELCLIENTLEADPARTNER = "SELCLIENTLEADPARTNER";
export const SELCLIENTLEADTYPE = "SELCLIENTLEADTYPE";
export const SELCLIENTLEADENGORGS = "SELCLIENTLEADENGORGS";
export const SELCLIENTORFTOGGLE = "SELCLIENTORFTOGGLE";
export const SELCLIENTORATOGGLE = "SELCLIENTORATOGGLE";
export const RESETCLIENTLEADTOGGLE = "RESETCLIENTLEADTOGGLE";
export const SELCLIENTEIETOGGLE = "SELCLIENTEIETOGGLE";
export const SELCLIENTEOETOGGLE = "SELCLIENTEOETOGGLE";
export const SELCLIENTEFEETOGGLE = "SELCLIENTEFEETOGGLE";
export const SELCLIENTECTOGGLE = "SELCLIENTECTOGGLE";
export const SELCLIENTEESTOGGLE = "SELCLIENTEESTOGGLE";
export const SELCLIENTOLTOGGLE = "SELCLIENTOLTOGGLE";
export const SELCLIENTQATOGGLE = "SELCLIENTQATOGGLE";
export const SELCLIENTINTOGGLE = "SELCLIENTINTOGGLE";
export const SELCLIENTATOGGLE = "SELCLIENTATOGGLE";
export const SELCLIENTVTOGGLE = "SELCLIENTVTOGGLE";
export const SELCLIENTGTOGGLE = "SELCLIENTGTOGGLE";
export const SELCLIENTCQTOGGLE = "SELCLIENTCQTOGGLE";
export const SELCLIENTFTOGGLE = "SELCLIENTFTOGGLE";
export const SELCLIENTTTOGGLE = "SELCLIENTOOTTOGGLE";
export const SELCLIENTSIZETOGGLE = "SELCLIENTSIZETOGGLE";
export const SELCLIENTRTOGGLE = "SELCLIENTRTOGGLE";
export const SELCLIENTPTOGGLE = "SELCLIENTPTOGGLE";
export const SELCLIENTSTOGGLE = "SELCLIENTSTOGGLE";
export const SELCLIENTJFTOGGLE = "SELCLIENTJFTOGGLE";
export const SELCLIENTJTTOGGLE = "SELCLIENTJTTOGGLE";
export const RESETCLIENTORATOGGLE = "RESETCLIENTORATOGGLE";
export const RESETCLIENTFTOGGLE = "RESETCLIENTFTOGGLE";
export const RESETCLIENTPTOGGLE = "RESETCLIENTPTOGGLE";
export const APPLYCLIENTLEADFILTER = "APPLYCLIENTLEADFILTER";
export const CLEARCLIENTLEADFILTER = "CLEARCLIENTLEADFILTER";
export const FETCHINICLIENTLEADFILTER = "FETCHINICLIENTLEADFILTER";
export const SELINICLIENTLEADCAMPAIGNS = "SELINICLIENTLEADCAMPAIGNS";
export const SELINICLIENTLEADORGS = "SELINICLIENTLEADORGS";
export const SELINICLIENTLEADENGEDORGS = "SELINICLIENTLEADENGEDORGS";
export const SELINICLIENTLEADORDER = "SELINICLIENTLEADORDER";
export const SELINICLIENTLEADPAGETITLE = "SELINICLIENTLEADPAGETITLE";
export const SELINICLIENTLEADENGUTMSOURCE = "SELINICLIENTLEADENGUTMSOURCE";
export const SELINICLIENTLEADENGUTMMEDIUM = "SELINICLIENTLEADENGUTMMEDIUM";
export const SELINICLIENTLEADENGUTMCAMPAIGN = "SELINICLIENTLEADENGUTMCAMPAIGN";
export const SELINICLIENTLEADENGUTMTERM = "SELINICLIENTLEADENGUTMTERM";
export const SELINICLIENTLEADENGUTMCONTENT = "SELINICLIENTLEADENGUTMCONTENT";
export const SELLEADENGSFILTERSTATUS = "SELLEADENGSFILTERSTATUS";
export const SELLEADENGSFILTERSSTATE = "SELLEADENGSFILTERSSTATE";
export const SELGLOBALLEADENGSSTATUS = "SELGLOBALLEADENGSSTATUS";
export const SELGLOBALLEADENGSVERIFICATIONSTATE =
  "SELGLOBALLEADENGSVERIFICATIONSTATE";
export const SELLEADENGSVERIFICATIONSTATEROWWISE =
  "SELLEADENGSVERIFICATIONSTATEROWWISE";
export const SELLEADENGSSTATUSROWWISE = "SELLEADENGSSTATUSROWWISE";
export const APPLYDATECALENDARSTATE = "APPLYDATECALENDARSTATE";
export const SELLEADENGSCHECKBOXSTATE = "SELLEADENGSCHECKBOXSTATE";
export const CLIENTLEADCHART = "CLIENTLEADCHART";
export const CLIENTLEADBARCHART = "CLIENTLEADBARCHART";
export const ENGSVALIDATIONCHART = "ENGSVALIDATIONCHART";
export const LEADENGSBARCHART = "LEADENGSBARCHART";
export const SELCLIENTCHARTTOGGLE = "SELCLIENTCHARTTOGGLE";
export const SELCLIENTLEADPACINGFILTERVALUE = "SELCLIENTLEADPACINGFILTERVALUE";
export const SELCLPARENTTOOGLE = "SELCLPARENTTOOGLE";
export const SELCLCHILDTOOGLE = "SELCLCHILDTOOGLE";
export const SELHEADERDROPDOWNOPTIONVALUE = "SELHEADERDROPDOWNOPTIONVALUE";
export const SHOWMORECLICKED = "SHOWMORECLICKED";
export const LEADENGSDETAILEXCEL = "LEADENGSDETAILEXCEL"
export const SETENGSLEADDETAILPOPUPPOSTEMAIL = "SETENGSLEADDETAILPOPUPPOSTEMAIL";
export const SELECTED_LEAD_TOGGLE = "SELECTED_LEAD_TOGGLE";

//URL Filter options of client lead
export const SHOWDROPDOWNSTATE = "SHOWDROPDOWNSTATE";
export const SHOWDROPDOWNSTATUS = "SHOWDROPDOWNSTATUS";
export const SELECTEDDROPDOWNVALUE = "SELECTEDDROPDOWNVALUE";
export const UPDATEDDROPDOWNVALUES = "UPDATEDDROPDOWNVALUES";
export const FETCHREQSTATUS = "FETCHREQSTATUS";
export const SELCLIENTLEADURLSOURCE = "SELCLIENTLEADURLSOURCE";
export const SELCLIENTLEADURLTERM = "SELCLIENTLEADURLTERM";
export const SELCLIENTLEADURLCONTENT = "SELCLIENTLEADURLCONTENT";
export const FETCHCLIENTLEADURLSOURCE = "FETCHCLIENTLEADURLSOURCE";
export const CLEARSEARCHCLIENTLEADURLSOURCE = "CLEARSEARCHCLIENTLEADURLSOURCE";
export const SELCLIENTLEADURLMEDIUM = "SELCLIENTLEADURLMEDIUM";
export const FETCHCLIENTLEADURLMEDIUM = "FETCHCLIENTLEADURLMEDIUM";
export const CLEARSEARCHCLIENTLEADURLMEDIUM = "CLEARSEARCHCLIENTLEADURLMEDIUM";
export const SELCLIENTLEADURLCAMPAIGNS = "SELCLIENTLEADURLCAMPAIGNS";
export const FETCHCLIENTLEADURLCAMPAIGNS = "FETCHCLIENTLEADURLCAMPAIGNS";
export const CLEARSEARCHCLIENTLEADURLCAMPAIGNS =
  "CLEARSEARCHCLIENTLEADURLCAMPAIGNS";
export const FETCHCLIENTLEADURLTERM = "FETCHCLIENTLEADURLTERM";
export const CLEARSEARCHCLIENTLEADURLTERM = "CLEARSEARCHCLIENTLEADURLTERM";
export const FETCHCLIENTLEADURLCONTENT = "FETCHCLIENTLEADURLCONTENT";
export const CLEARSEARCHCLIENTLEADURLCONTENT =
  "CLEARSEARCHCLIENTLEADURLCONTENT";
export const FETCHREQVALIDATE = "FETCHREQVALIDATE";
export const SETCLIENTDETAILPGAFTERREFRESHCOUNT = "SETCLIENTDETAILPGAFTERREFRESHCOUNT";
export const SELLEADSTATUSCHART = "SELLEADSTATUSCHART"
export const LEADACCEPTANCEBARCHART = "LEADACCEPTANCEBARCHART"
export const SELLEADACCEPTANCECHART = "SELLEADACCEPTANCECHART";
export const FETCH_LEAD_CSM = "FETCH_LEAD_CSM";
export const ADD_LEAD_CSM = "ADD_LEAD_CSM";
export const SELECT_LEAD_CSM = "SELECT_LEAD_CSM";
export const CLEAR_LEAD_AUTHOR = "CLEAR_LEAD_AUTHOR";

//Lead Client table action types
export const CLIENTLEADENGSUMMARY = "CLIENTLEADENGSUMMARY";
export const CLIENTLEADENGSUMMARYCOUNT = "CLIENTLEADENGSUMMARYCOUNT";

export const CLIENTLEADENGSUMMARYSCV = "CLIENTLEADENGSUMMARYSCV";
export const SORTCLIENTLEADENGSUMMARY = "SORTCLIENTLEADENGSUMMARY";
export const MORECLIENTLEADENGSUMMARYDATA = "MORECLIENTLEADENGSUMMARYDATA";
export const MORECLIENTLEADENGSUMMARYLOADING =
  "MORECLIENTLEADENGSUMMARYLOADING";
export const ISTOTALLEADCOUNT = "ISTOTALLEADCOUNT";
export const CLIENTLEADENGS = "CLIENTLEADENGS";
export const CLIENTLEADENGSCSV = "CLIENTLEADENGSCSV";
export const SORTCLIENTLEADENGS = "SORTCLIENTLEADENGS";
export const MORECLIENTLEADENGSDATA = "MORECLIENTLEADENGSDATA";
export const MORECLIENTLEADENGSLOADING = "MORECLIENTLEADENGSLOADING";
export const CLEARCLIENTLEADSORT = "CLEARCLIENTLEADSORT";
export const CLIENTLEADCSVLOADING = "CLIENTLEADCSVLOADING";
export const CLIENTLEADCHANGEDLOGS = "CLIENTLEADCHANGEDLOGS";
export const CLIENTLEADEXCELSUMMARY = "CLIENTLEADEXCELSUMMARY";
export const SETCLIENTLEADSUMMARYPOPUPCLOSE = "SETCLIENTLEADSUMMARYPOPUPCLOSE";
export const SETCLIENTLEADDETAILPOPUPCLOSE = "SETCLIENTLEADDETAILPOPUPCLOSE";
export const CLIENTLEADEXCELDETAIL = "CLIENTLEADEXCELDETAIL";
export const CLIENTLEADDETAILEXCEL = "CLIENTLEADDETAILEXCEL";
export const SETCLIENTLEADSUMMARYPOPUPPOSTEMAIL =
  "SETCLIENTLEADSUMMARYPOPUPPOSTEMAIL";
export const CLIENTLEADMAILDELIVERY = "CLIENTLEADMAILDELIVERY";
export const UPDATEDCHARTSTATUS = "UPDATEDCHARTSTATUS";
export const SHOWTOPICCHARTS = "SHOWTOPICCHARTS";
export const SENDDYANMICTABLEHEADER = "SENDDYANMICTABLEHEADER";
export const GETDYNAMICTABLEHEADER = "GETDYNAMICTABLEHEADER";
export const FETCHDELIVERYSTATE = "FETCHDELIVERYSTATE";
export const SELDELIVERYSTATE = "SELDELIVERYSTATE";
export const CLIENTLEADDETAILCSV = "CLIENTLEADDETAILCSV";
export const CLIENTLEADDETAILCSVLOADING = "CLIENTLEADDETAILCSVLOADING";
export const SETCLIENTLEADDETAILPOPUPPOSTEMAIL = "SETCLIENTLEADDETAILPOPUPPOSTEMAIL";
export const GETENGSDYNAMICTABLEHEADER = "GETENGSDYNAMICTABLEHEADER";
export const SENDENGSDYANMICTABLEHEADER = "SENDENGSDYANMICTABLEHEADER";
export const SELECTCLIENTDOMAINFILTER = "SELECTCLIENTDOMAINFILTER";

//Editorial report
export const APPLYEDITORIALDETAILSDATE = "APPLYEDITORIALDETAILSDATE"
export const SELEDITORIALDETAILSSTARTDATE = "SELEDITORIALDETAILSSTARTDATE"
export const SELEDITORIALDETAILSENDDATE = "SELEDITORIALDETAILSENDDATE"
export const SELEDITORIALDETAILSCUSTOMRANGE = "SELEDITORIALDETAILSCUSTOMRANGE"
export const SELEDITORIALDETAILSPREVSTARTDATE = "SELEDITORIALDETAILSPREVSTARTDATE"
export const SELEDITORIALDETAILSPREVENDDATE = "SELEDITORIALDETAILSPREVENDDATE"
export const FETCHEDITORIALDETAILSORGS = "FETCHEDITORIALDETAILSORGS"
export const SELEDITORIALDETAILSORGS = "SELEDITORIALDETAILSORGS"
export const ADDEDITORIALDETAILSORGS = "ADDEDITORIALDETAILSORGS"
export const CLEAREDITORIALDETAILSORGS = "CLEAREDITORIALDETAILSORGS"
export const FETCHEDITORIALDETAILSORDER = "FETCHEDITORIALDETAILSORDER"
export const SELEDITORIALDETAILSORDER = "SELEDITORIALDETAILSORDER"
export const ADDEDITORIALDETAILSORDER = "ADDEDITORIALDETAILSORDER"
export const CLEAREDITORIALDETAILSORDER = "CLEAREDITORIALDETAILSORDER"
export const SELEDITORIALDETAILSSTATUS = "SELEDITORIALDETAILSSTATUS"
export const RESETEDITORIALDETAILSCAMPAIGNS = "RESETEDITORIALDETAILSCAMPAIGNS"
export const SELEDITORIALDETAILSCAMPAIGNS = "SELEDITORIALDETAILSCAMPAIGNS"
export const ADDEDITORIALDETAILSCAMPAIGNS = "ADDEDITORIALDETAILSCAMPAIGNS"
export const FETCHEDITORIALDETAILSCAMPAIGNS = "FETCHEDITORIALDETAILSCAMPAIGNS"
export const CLEAREDITORIALDETAILSCAMPAIGNS = "CLEAREDITORIALDETAILSCAMPAIGNS"
export const FETCHEDITORIALDETAILSAUTHOR = "FETCHEDITORIALDETAILSAUTHOR"
export const CLEAREDITORIALDETAILSAUTHOR = "CLEAREDITORIALDETAILSAUTHOR"
export const SELEDITORIALDETAILSAUTHOR = "SELEDITORIALDETAILSAUTHOR"
export const SELEDITORIALDETAILSSITE = "SELEDITORIALDETAILSSITE"
export const FETCHEDITORIALDETAILSTOPICTAGSIN = "FETCHEDITORIALDETAILSTOPICTAGSIN"
export const APPLYEDITORIALDETAILSTOPICTAGSIN = "APPLYEDITORIALDETAILSTOPICTAGSIN"
export const SELPGEDITORIALDETAILSTAGSIN = "SELPGEDITORIALDETAILSTAGSIN"
export const CLEAREDITORIALDETAILSTOPICTAGIN = "CLEAREDITORIALDETAILSTOPICTAGIN"
export const FETCHEDITORIALDETAILSTOPICTAGSEX = "FETCHEDITORIALDETAILSTOPICTAGSEX"
export const APPLYEDITORIALDETAILSTOPICTAGSEX = "APPLYEDITORIALDETAILSTOPICTAGSEX"
export const CLEAREDITORIALDETAILSTOPICTAGEX = "CLEAREDITORIALDETAILSTOPICTAGEX"
export const FETCHEDITORIALDETAILSPLACEMENTTAGSIN = "FETCHEDITORIALDETAILSPLACEMENTTAGSIN"
export const APPLYEDITORIALDETAILSPLACEMENTTAGSIN = "APPLYEDITORIALDETAILSPLACEMENTTAGSIN"
export const CLEAREDITORIALDETAILSPLACEMENTTAGIN = "CLEAREDITORIALDETAILSPLACEMENTTAGIN"
export const FETCHEDITORIALDETAILSPLACEMENTTAGSEX = "FETCHEDITORIALDETAILSPLACEMENTTAGSEX"
export const APPLYEDITORIALDETAILSPLACEMENTTAGSEX = "APPLYEDITORIALDETAILSPLACEMENTTAGSEX"
export const CLEAREDITORIALDETAILSPLACEMENTTAGEX = "CLEAREDITORIALDETAILSPLACEMENTTAGEX"
export const FETCHEDITORIALDETAILSORGTAGSIN = "FETCHEDITORIALDETAILSORGTAGSIN"
export const APPLYEDITORIALDETIALSORGTAGSIN = "APPLYEDITORIALDETIALSORGTAGSIN"
export const CLEAREDITORIALDETAILSORGTAGIN = "CLEAREDITORIALDETAILSORGTAGIN"
export const FETCHEDITORIALDETAILSORGTAGSEX = "FETCHEDITORIALDETAILSORGTAGSEX"
export const APPLYEDITORIALDETAILSORGTAGSEX = "APPLYEDITORIALDETAILSORGTAGSEX"
export const CLEAREDITORIALDETAILSORGTAGEX = "CLEAREDITORIALDETAILSORGTAGEX"
export const FETCHEDITORIALDETAILSCOUNTRY = "FETCHEDITORIALDETAILSCOUNTRY"
export const SELEDITORIALDETAILSCOUNTRY = "SELEDITORIALDETAILSCOUNTRY"
export const ADDEDITORIALDETAILSCONTRIES = "ADDEDITORIALDETAILSCONTRIES"
export const CLEAREDITORIALDETAILSCOUNTRY = "CLEAREDITORIALDETAILSCOUNTRY"
export const FETCHEDITORIALDETAILSENGORGS = "FETCHEDITORIALDETAILSENGORGS"
export const SELEDITORIALDETAILSENGORGS = "SELEDITORIALDETAILSENGORGS"
export const ADDEDITORIALDETAILSENGORGS = "ADDEDITORIALDETAILSENGORGS"
export const CLEAREDITORIALDETIALSENGORGS = "CLEAREDITORIALDETIALSENGORGS"
export const SELEDFIRMOGRAPHICTOGGLE = "SELEDFIRMOGRAPHICTOGGLE"
export const FETCHEDITORIALDETAILSFIRMOGRAPHICORGSTYPE = "FETCHEDITORIALDETAILSFIRMOGRAPHICORGSTYPE"
export const FETCHEDITORIALDETAILSORGSSIZE = "FETCHEDITORIALDETAILSORGSSIZE"
export const FETCHEDITORIALDETAILSORGSREVENUE = "FETCHEDITORIALDETAILSORGSREVENUE"
export const FETCHEDSECTOR = "FETCHEDSECTOR"
export const FETCHEDINDUSTRYGROUP = "FETCHEDINDUSTRYGROUP"
export const FETCHEDINDUSTRY = "FETCHEDINDUSTRY"
export const FETCHEDSUBINDUSTRY = "FETCHEDSUBINDUSTRY"
export const APPLYEDITORIALDETAILSORGSTYPE = "APPLYEDITORIALDETAILSORGSTYPE"
export const APPLYEDITORIALDETAILSORGSSIZE = "APPLYEDITORIALDETAILSORGSSIZE"
export const APPLYEDITORIALDETAILSREVENUE = "APPLYEDITORIALDETAILSREVENUE"
export const APPLYEDSECTOR = "APPLYEDSECTOR"
export const APPLYEDINDUSTRYGROUP = "APPLYEDINDUSTRYGROUP"
export const APPLYEDINDUSTRY = "APPLYEDINDUSTRY"
export const APPLYEDSUBINDUSTRY = "APPLYEDSUBINDUSTRY"
export const CLEAREDITORIALDETAILSORGSTYPE = "CLEAREDITORIALDETAILSORGSTYPE"
export const CLEAREDITORIALDETAILSORGSSIZE = "CLEAREDITORIALDETAILSORGSSIZE"
export const CLEAREDITORIALDETAILSORGSREVENUE = "CLEAREDITORIALDETAILSORGSREVENUE"
export const CLEARSEARCHEDSECTOR = "CLEARSEARCHEDSECTOR"
export const CLEARSEARCHEDINDUSTRYGROUP = "CLEARSEARCHEDINDUSTRYGROUP"
export const CLEARSEARCHEDINDUSTRY = "CLEARSEARCHEDINDUSTRY"
export const CLEARSEARCHEDSUBINDUSTRY = "CLEARSEARCHEDSUBINDUSTRY"
export const SELEDPERSONATOGGLE = "SELEDPERSONATOGGLE"
export const FETCHEDSENIORITIESIN = "FETCHEDSENIORITIESIN"
export const APPLYEDSENIORITIESIN = "APPLYEDSENIORITIESIN"
export const CLEARSEARCHEDSENIORITIESIN = "CLEARSEARCHEDSENIORITIESIN"
export const FETCHEDSENIORITIESEX = "FETCHEDSENIORITIESEX"
export const CLEARSEARCHEDSENIORITIESEX = "CLEARSEARCHEDSENIORITIESEX"
export const FETCHEDJOBFUNCTIONSIN = "FETCHEDJOBFUNCTIONSIN"
export const APPLYEDJOBFUNCTIONSIN = "APPLYEDJOBFUNCTIONSIN"
export const CLEARSEARCHEDJOBFUNCTIONSIN = "CLEARSEARCHEDJOBFUNCTIONSIN"
export const FETCHEDJOBFUNCTIONSEX = "FETCHEDJOBFUNCTIONSEX"
export const APPLYEDJOBFUNCTIONSEX = "APPLYEDJOBFUNCTIONSEX"
export const CLEARSEARCHEDJOBFUNCTIONSEX = "CLEARSEARCHEDJOBFUNCTIONSEX"
export const APPLYEDSENIORITIESEX = "APPLYEDSENIORITIESEX"
export const SELEDPAGEPARAMSTOGGLE = "SELEDPAGEPARAMSTOGGLE"
export const APPLYEDDATEFILTER = "APPLYEDDATEFILTER"
export const SELEDWPSTARTDATE = "SELEDWPSTARTDATE"
export const SELEDWPENDDATE = "SELEDWPENDDATE"
export const FETCHEDTITLE = "FETCHEDTITLE"
export const CLEARSEARCHEDPAGETITLE = "CLEARSEARCHEDPAGETITLE"
export const FETCHEDURLSOURCE = "FETCHEDURLSOURCE"
export const CLEARSEARCHEDURLSOURCE = "CLEARSEARCHEDURLSOURCE"
export const RESETSELEDURLCAMPANDMEDIUM = "RESETSELEDURLCAMPANDMEDIUM"
export const CLEARSEARCHEDURLCAMPAIGNS = "CLEARSEARCHEDURLCAMPAIGNS"
export const CLEARSEARCHEDURLMEDIUM = "CLEARSEARCHEDURLMEDIUM"
export const FETCHEDURLMEDIUM = "FETCHEDURLMEDIUM"
export const FETCHEDURLCAMPAIGNS = "FETCHEDURLCAMPAIGNS"
export const FETCHEDURLTERM = "FETCHEDURLTERM"
export const CLEARSEARCHEDURLTERM = "CLEARSEARCHEDURLTERM"
export const FETCHEDURLCONTENT = "FETCHEDURLCONTENT"
export const CLEARSEARCHEDURLCONTENT = "CLEARSEARCHEDURLCONTENT"
export const FETCHEDENGMEMBER = "FETCHEDENGMEMBER"
export const ADDEDENGMEMBER = "ADDEDENGMEMBER"
export const FETCHEDPRIMARYTOPIC = "FETCHEDPRIMARYTOPIC"
export const ADDEDPRIMARYTOPIC = "ADDEDPRIMARYTOPIC"
export const CLEARSEARCHEDPRIMARYTOPIC = "CLEARSEARCHEDPRIMARYTOPIC"
export const SELEDQATOGGLE = "SELEDQATOGGLE"
export const SELEDINTERNALEVENTSTOGGLE = "SELEDINTERNALEVENTSTOGGLE"
export const SELEDINTOGGLE = "SELEDINTOGGLE"
export const CLEAREDFILTER = "CLEAREDFILTER"
export const MOREEDITORIALDETAILSLOADING = "MOREEDITORIALDETAILSLOADING"
export const MOREEDITORIALDETAILS = "MOREEDITORIALDETAILS"
export const SETDETAILEDEDCOUNT = "SETDETAILEDEDCOUNT"
export const SORTEDDETAILS = "SORTEDDETAILS"
export const SELEDITORIALPARENTTOOGLE = "SELEDITORIALPARENTTOOGLE";
export const SELEDITORIALCHILDTOOGLE = "SELEDITORIALCHILDTOOGLE";
export const SELEDPARENTTOOGLE = "SELEDPARENTTOOGLE";
export const SELEDCHILDTOOGLE = "SELEDCHILDTOOGLE";
export const SELEDITORIALPAGESUMMARYTABLETOGGLE = "SELEDITORIALPAGESUMMARYTABLETOGGLE";
export const COMMONINDEPENDENTPAGESUMMARY = "COMMONINDEPENDENTPAGESUMMARY"
export const SELEDITORIALPLACESUMMMARYTABLETOGGLE = "SELEDITORIALPLACESUMMMARYTABLETOGGLE";
export const SELEDITORIALTOPICSUMMMARYTABLETOGGLE = "SELEDITORIALTOPICSUMMMARYTABLETOGGLE";
export const SELEDITORIALORGANIZATIONSUMMARYTABLETOGGLE = "SELEDITORIALORGANIZATIONSUMMARYTABLETOGGLE"
export const CHECKINGTOOGLE = "CHECKINGTOOGLE";
export const INDTOPICSUMMARYTOGGLEVALUE = "INDTOPICSUMMARYTOGGLEVALUE";
export const UPDATEINDTOPICSUMMARYLOADER = "UPDATEINDTOPICSUMMARYLOADER";
export const RESETINDTOPICSUMMARY = "RESETINDTOPICSUMMARY";
export const SORTAUTHOREDITORIALSUMMARY = "SORTAUTHOREDITORIALSUMMARY";
export const EDITORIALAUTHORSUMMARY = "EDITORIALAUTHORSUMMARY";
export const PAGEVIEWEMAILAUTHORSUMMARYCSV = "PAGEVIEWEMAILAUTHORSUMMARYCSV";
export const SETPAGEVIEWAUTHORSUMMARY = "SETPAGEVIEWAUTHORSUMMARY";

//Sponsored content filter action types
export const APPLYSPONSOREDCONTENTDATE = "APPLYSPONSOREDCONTENTDATE";
export const SELSPONSOREDCONTENTSTARTDATE = "SELSPONSOREDCONTENTSTARTDATE";
export const SELSPONSOREDCONTENTENDDATE = "SELSPONSOREDCONTENTENDDATE";
export const SELSPONSOREDCONTENTPREVSTARTDATE = "SELSPONSOREDCONTENTPREVSTARTDATE";
export const SELSPONSOREDCONTENTPREVENDDATE = "SELSPONSOREDCONTENTPREVENDDATE";
export const SELSPONSOREDCONTENTCUSTOMRANGE = "SELSPONSOREDCONTENTCUSTOMRANGE";
export const FETCHSPONSOREDCONTENTORGS = "FETCHSPONSOREDCONTENTORGS";
export const ADDSPONSOREDCONTENTORGS = "ADDSPONSOREDCONTENTORGS";
export const CLEARSEARCHSPONSOREDCONTENTORGS = "CLEARSEARCHSPONSOREDCONTENTORGS";
export const FETCHSELSPONSOREDCONTENTCAMPAIGNS = "FETCHSELSPONSOREDCONTENTCAMPAIGNS";
export const SELSPONSOREDCONTENTORGS = "SELSPONSOREDCONTENTORGS";
export const RESETSELSPONSOREDCONTENTCAMPAIGNS = "RESETSELSPONSOREDCONTENTCAMPAIGNS";
export const SELSPONSOREDCONTENTORDER = "SELSPONSOREDCONTENTORDER";
export const ADDSPONSOREDCONTENTORDER = "ADDSPONSOREDCONTENTORDER";
export const FETCHSPONSOREDCONTENTORDER = "FETCHSPONSOREDCONTENTORDER";
export const CLEARSEARCHSPONSOREDCONTENTORDER = "CLEARSEARCHSPONSOREDCONTENTORDER";
export const SELSPONSOREDCONTENTSTATUS = "SELSPONSOREDCONTENTSTATUS";
export const RESETSPONSOREDCONTENTCAMPAIGNS = "RESETSPONSOREDCONTENTCAMPAIGNS";
export const SELSPONSOREDCONTENTCAMPAIGNS = "SELSPONSOREDCONTENTCAMPAIGNS";
export const CLEARSEARCHSPONSOREDCONTENTCAMPAIGNS = "CLEARSEARCHSPONSOREDCONTENTCAMPAIGNS";
export const FETCHSPONSOREDCONTENTCAMPAIGNS = "FETCHSPONSOREDCONTENTCAMPAIGNS";
export const ADDSPONSOREDCONTENTCAMPAIGNS = "ADDSPONSOREDCONTENTCAMPAIGNS";
export const CLEARSEARCHSPONSOREDCONTENTAUTHOR = "CLEARSEARCHSPONSOREDCONTENTAUTHOR";
export const FETCHSPONSOREDCONTENTAUTHOR = "FETCHSPONSOREDCONTENTAUTHOR";
export const SELSPONSOREDCONTENTAUTHOR = "SELSPONSOREDCONTENTAUTHOR";
export const SELSPONSOREDCONTENTSITE = "SELSPONSOREDCONTENTSITE";
export const CLEARSEARCHSPONSOREDCONTENTTOPICTAGIN = "CLEARSEARCHSPONSOREDCONTENTTOPICTAGIN";
export const FETCHSPONSOREDCONTENTTOPICTAGSIN = "FETCHSPONSOREDCONTENTTOPICTAGSIN";
export const APPLYSPONSOREDCONTENTTOPICTAGSIN = "APPLYSPONSOREDCONTENTTOPICTAGSIN";
export const SELPGSPONSOREDCONTENTTAGSIN = "SELPGSPONSOREDCONTENTTAGSIN";
export const CLEARSEARCHSPONSOREDCONTENTTOPICTAGEX = "CLEARSEARCHSPONSOREDCONTENTTOPICTAGEX";
export const FETCHSPONSOREDCONTENTTOPICTAGSEX = "FETCHSPONSOREDCONTENTTOPICTAGSEX";
export const APPLYSPONSOREDCONTENTTOPICTAGSEX = "APPLYSPONSOREDCONTENTTOPICTAGSEX";
export const SELSPONSOREDCONTENTTOPICTAGSEX = "SELSPONSOREDCONTENTTOPICTAGSEX";
export const CLEARSEARCHSPONSOREDCONTENTPLACEMENTTAGIN = "CLEARSEARCHSPONSOREDCONTENTPLACEMENTTAGIN";
export const FETCHSPONSOREDCONTENTPLACEMENTTAGSIN = "FETCHSPONSOREDCONTENTPLACEMENTTAGSIN";
export const APPLYSPONSOREDCONTENTPLACEMENTTAGSIN = "APPLYSPONSOREDCONTENTPLACEMENTTAGSIN";
export const SELSPONSOREDCONTENTPLACEMENTTAGSIN = "SELSPONSOREDCONTENTPLACEMENTTAGSIN";
export const CLEARSEARCHSPONSOREDCONTENTPLACEMENTTAGEX = "CLEARSEARCHSPONSOREDCONTENTPLACEMENTTAGEX";
export const FETCHSPONSOREDCONTENTPLACEMENTTAGSEX = "FETCHSPONSOREDCONTENTPLACEMENTTAGSEX";
export const APPLYSPONSOREDCONTENTPLACEMENTTAGSEX = "APPLYSPONSOREDCONTENTPLACEMENTTAGSEX";
export const SELSPONSOREDCONTENTPLACEMENTTAGSEX = "SELSPONSOREDCONTENTPLACEMENTTAGSEX";
export const CLEARSEARCHSPONSOREDCONTENTORGTAGIN = "CLEARSEARCHSPONSOREDCONTENTORGTAGIN";
export const FETCHSPONSOREDCONTENTORGTAGSIN = "FETCHSPONSOREDCONTENTORGTAGSIN";
export const APPLYSPONSOREDCONTENTORGTAGSIN = "APPLYSPONSOREDCONTENTORGTAGSIN";
export const SELSPONSOREDCONTENTORGTAGSIN = "SELSPONSOREDCONTENTORGTAGSIN";
export const CLEARSEARCHSPONSOREDCONTENTORGTAGEX = "CLEARSEARCHSPONSOREDCONTENTORGTAGEX";
export const FETCHSPONSOREDCONTENTORGTAGSEX = "FETCHSPONSOREDCONTENTORGTAGSEX";
export const APPLYSPONSOREDCONTENTORGTAGSEX = "APPLYSPONSOREDCONTENTORGTAGSEX";
export const SELSPONSOREDCONTENTORGTAGSEX = "SELSPONSOREDCONTENTORGTAGSEX";
export const CLEARSEARCHSPONSOREDCONTENTCOUNTRY = "CLEARSEARCHSPONSOREDCONTENTCOUNTRY";
export const FETCHSPONSOREDCONTENTCOUNTRY = "FETCHSPONSOREDCONTENTCOUNTRY";
export const SELSPONSOREDCONTENTCOUNTRY = "SELSPONSOREDCONTENTCOUNTRY";
export const ADDSPONSOREDCONTENTCONTRIES = "ADDSPONSOREDCONTENTCONTRIES";
export const CLEARSEARCHSPONSOREDCONTENTENGORGS = "CLEARSEARCHSPONSOREDCONTENTENGORGS";
export const FETCHSPONSOREDCONTENTENGORGS = "FETCHSPONSOREDCONTENTENGORGS";
export const SELSPONSOREDCONTENTENGORGS = "SELSPONSOREDCONTENTENGORGS";
export const ADDSPONSOREDCONTENTENGORGS = "ADDSPONSOREDCONTENTENGORGS";
export const FETCHSPONSOREDCONTENTFIRMOGRAPHICORGSTYPE = "FETCHSPONSOREDCONTENTFIRMOGRAPHICORGSTYPE";
export const APPLYSPONSOREDCONTENTORGSTYPE = "APPLYSPONSOREDCONTENTORGSTYPE";
export const CLEARSEARCHSPONSOREDCONTENTORGSTYPE = "CLEARSEARCHSPONSOREDCONTENTORGSTYPE";
export const FETCHSPONSOREDCONTENTORGSSIZE = "FETCHSPONSOREDCONTENTORGSSIZE";
export const APPLYSPONSOREDCONTENTORGSSIZE = "APPLYSPONSOREDCONTENTORGSSIZE";
export const CLEARSEARCHSPONSOREDCONTENTORGSSIZE = "CLEARSEARCHSPONSOREDCONTENTORGSSIZE";
export const FETCHSPONSOREDCONTENTORGSREVENUE = "FETCHSPONSOREDCONTENTORGSREVENUE";
export const APPLYSPONSOREDCONTENTREVENUE = "APPLYSPONSOREDCONTENTREVENUE";
export const CLEARSEARCHSPONSOREDCONTENTORGSREVENUE = "CLEARSEARCHSPONSOREDCONTENTORGSREVENUE";
export const FETCHSCSECTOR = "FETCHSCSECTOR";
export const APPLYSCSECTOR = "APPLYSCSECTOR";
export const CLEARSEARCHSCSECTOR = "CLEARSEARCHSCSECTOR";
export const FETCHSCINDUSTRYGROUP = "FETCHSCINDUSTRYGROUP";
export const APPLYSCINDUSTRYGROUP = "APPLYSCINDUSTRYGROUP";
export const CLEARSEARCHSCINDUSTRYGROUP = "CLEARSEARCHSCINDUSTRYGROUP";
export const FETCHSCINDUSTRY = "FETCHSCINDUSTRY";
export const APPLYSCINDUSTRY = "APPLYSCINDUSTRY";
export const CLEARSEARCHSCINDUSTRY = "CLEARSEARCHSCINDUSTRY";
export const FETCHSCSUBINDUSTRY = "FETCHSCSUBINDUSTRY";
export const APPLYSCSUBINDUSTRY = "APPLYSCSUBINDUSTRY";
export const CLEARSEARCHSCSUBINDUSTRY = "CLEARSEARCHSCSUBINDUSTRY";
export const SELSCFIRMOGRAPHICTOGGLE = "SELSCFIRMOGRAPHICTOGGLE";
export const SELSCPERSONATOGGLE = "SELSCPERSONATOGGLE";
export const CLEARSEARCHSCSENIORITIESIN = "CLEARSEARCHSCSENIORITIESIN";
export const FETCHSCSENIORITIESIN = "FETCHSCSENIORITIESIN";
export const APPLYSCSENIORITIESIN = "APPLYSCSENIORITIESIN";
export const CLEARSEARCHSCSENIORITIESEX = "CLEARSEARCHSCSENIORITIESEX";
export const FETCHSCSENIORITIESEX = "FETCHSCSENIORITIESEX";
export const APPLYSCSENIORITIESEX = "APPLYSCSENIORITIESEX";
export const CLEARSEARCHSCJOBFUNCTIONSIN = "CLEARSEARCHSCJOBFUNCTIONSIN";
export const FETCHSCJOBFUNCTIONSIN = "FETCHSCJOBFUNCTIONSIN";
export const APPLYSCJOBFUNCTIONSIN = "APPLYSCJOBFUNCTIONSIN";
export const CLEARSEARCHSCJOBFUNCTIONSEX = "CLEARSEARCHSCJOBFUNCTIONSEX";
export const FETCHSCJOBFUNCTIONSEX = "FETCHSCJOBFUNCTIONSEX";
export const APPLYSCJOBFUNCTIONSEX = "APPLYSCJOBFUNCTIONSEX";
export const CLEARSEARCHSCPAGETITLE = "CLEARSEARCHSCPAGETITLE";
export const FETCHSCTITLE = "FETCHSCTITLE";
export const CLEARSEARCHSCURLSOURCE = "CLEARSEARCHSCURLSOURCE";
export const FETCHSCURLSOURCE = "FETCHSCURLSOURCE";
export const RESETSELSCURLCAMPANDMEDIUM = "RESETSELSCURLCAMPANDMEDIUM";
export const CLEARSEARCHSCURLCAMPAIGNS = "CLEARSEARCHSCURLCAMPAIGNS";
export const FETCHSCURLCAMPAIGNS = "FETCHSCURLCAMPAIGNS";
export const CLEARSEARCHSCURLMEDIUM = "CLEARSEARCHSCURLMEDIUM";
export const FETCHSCURLMEDIUM = "FETCHSCURLMEDIUM";
export const CLEARSEARCHSCURLTERM = "CLEARSEARCHSCURLTERM";
export const FETCHSCURLTERM = "FETCHSCURLTERM";
export const CLEARSEARCHSCURLCONTENT = "CLEARSEARCHSCURLCONTENT";
export const FETCHSCURLCONTENT = "FETCHSCURLCONTENT";
export const SELSCWPSTARTDATE = "SELSCWPSTARTDATE";
export const SELSCWPENDDATE = "SELSCWPENDDATE";
export const APPLYSCDATEFILTER = "APPLYSCDATEFILTER";
export const SELSCPAGEPARAMSTOGGLE = "SELSCPAGEPARAMSTOGGLE";
export const CLEARSEARCHSCENGMEMBER = "CLEARSEARCHSCENGMEMBER";
export const FETCHSCENGMEMBER = "FETCHSCENGMEMBER";
export const ADDSCENGMEMBER = "ADDSCENGMEMBER";
export const CLEARSEARCHSCPRIMARYTOPIC = "CLEARSEARCHSCPRIMARYTOPIC";
export const ADDSCPRIMARYTOPIC = "ADDSCPRIMARYTOPIC";
export const FETCHSCPRIMARYTOPIC = "FETCHSCPRIMARYTOPIC";
export const SELSCINTOGGLE = "SELSCINTOGGLE";
export const SELSCQATOGGLE = "SELSCQATOGGLE";
export const CLEARSCFILTER = "CLEARSCFILTER";
export const APPLYSCFILTER = "APPLYSCFILTER";
export const EDCSVLOADING = "EDCSVLOADING"
export const EDEMAILPAGEDETAIL = "EDEMAILPAGEDETAIL"
export const SETEDITORIALDETAILSPOPUPCLOSE = "SETEDITORIALDETAILSPOPUPCLOSE"
export const EDITORIALDETAILSEXCEL = "EDITORIALDETAILSEXCEL"
export const SETEDPOSTEMAILUPDATE = "SETEDPOSTEMAILUPDATE"
export const EDITORIALDETAILCSVLOADING = "EDITORIALDETAILCSVLOADING"
export const EDVIEWDETAILSCSV = "EDVIEWDETAILSCSV"
export const SELSCCAMPPAGEVIEWDETAILSTABLETOGGLE = "SELSCCAMPPAGEVIEWDETAILSTABLETOGGLE";
export const EDEMAILCSVPAGEDETAIL = "EDEMAILCSVPAGEDETAIL"
export const SETEDPOSTEMAILUPDATECSV = "SETEDPOSTEMAILUPDATECSV"
export const EDEXCEL = "EDEXCEL"
export const EDGENERATECSVLOADING = "EDGENERATECSVLOADING"
export const SETSCCSVPOSTEMAILUPDATE = "SETSCCSVPOSTEMAILUPDATE"
export const SELSCEXANONYMOUSORGS = "SELSCEXANONYMOUSORGS"
export const SELSCEXANONYMOUSMEMBERS = "SELSCEXANONYMOUSMEMBERS"
export const RESETSPONSOREDCONTENTORGS = "RESETSPONSOREDCONTENTORGS"
export const RESETSPONSOREDCONTENTORDER = "RESETSPONSOREDCONTENTORDER"
export const RESETSPONSOREDCONTENTTOPICTAGIN = "RESETSPONSOREDCONTENTTOPICTAGIN"
export const RESETSPONSOREDCONTENTTOPICTAGEX = "RESETSPONSOREDCONTENTTOPICTAGEX"
export const RESETSCPLACEMENTTAGIN = "RESETSCPLACEMENTTAGIN"
export const RESETSCPLACEMENTTAGEX = "RESETSCPLACEMENTTAGEX"
export const RESETSCORGTAGIN = "RESETSCORGTAGIN"
export const RESETSCORGTAGEX = "RESETSCORGTAGEX"
export const RESETSCENGORGS = "RESETSCENGORGS"
export const RESETSCCOUNTRY = "RESETSCCOUNTRY"
export const RESETSCORGSTYPE = "RESETSCORGSTYPE"
export const RESETSCORGSSIZE = "RESETSCORGSSIZE"
export const RESETSCORGSREVENUE = "RESETSCORGSREVENUE"
export const RESETSCSECTOR = "RESETSCSECTOR"
export const RESETSCORGSINDUSTRYGROUP = "RESETSCORGSINDUSTRYGROUP"
export const RESETSCORGSINDUSTRY = "RESETSCORGSINDUSTRY"
export const RESETSCSUBINDUSTRY = "RESETSCSUBINDUSTRY"
export const RESETSCSENIORITIESIN = "RESETSCSENIORITIESIN"
export const RESETSCSENIORITIESEX = "RESETSCSENIORITIESEX"
export const RESETSCJOBFUNCTIONSIN = "RESETSCJOBFUNCTIONSIN"
export const RESETSCJOBFUNCTIONSEX = "RESETSCJOBFUNCTIONSEX"
export const RESETSCENGMEMBER = "RESETSCENGMEMBER"
export const RESETSCPRIMARYTOPIC = "RESETSCPRIMARYTOPIC"
export const DETAILTABLESCTOGGLLEVALUE = "DETAILTABLESCTOGGLLEVALUE";
export const SET_SPONSORED_VALUES = "SET_SPONSORED_VALUES";
export const FETCH_SPONSORED_CSM = "FETCH_SPONSORED_CSM";
export const SELECT_SPONSORED_CSM = "SELECT_SPONSORED_CSM";
export const ADD_SPONSORED_CSM = "ADD_SPONSORED_CSM";
export const CLEAR_SPONSORED_CSM = "CLEAR_SPONSORED_CSM";

//Sponsored content table action types
export const SCSUMMARY = "SCSUMMARY";
export const SCCAMPAIGNDETAILS = "SCCAMPAIGNDETAILS";
export const SORTSCCAMPAIGN = "SORTSCCAMPAIGN";
export const SORTSCCAMPAIGNDETAILS = "SORTSCCAMPAIGNDETAILS";
export const CLEARSCSORT = "CLEARSCSORT";
export const MORESCCAMPAIGNSUMMARYLOADING = "MORESCCAMPAIGNSUMMARYLOADING"
export const MORESCSUMMARY = "MORESCSUMMARY";
export const MORESCCAMPAIGNDETAIL = "MORESCCAMPAIGNDETAIL";
export const MORESCCAMPAIGNPAGELOADING = "MORESCCAMPAIGNPAGELOADING";
export const MORESCCAMPAIGNPAGEVIEWDETAILLOADING = "MORESCCAMPAIGNPAGEVIEWDETAILLOADING";
export const MORESCCAMPAIGNPAGEVIEWDETAIL = "MORESCCAMPAIGNPAGEVIEWDETAIL";
export const SCCAMPAIGNPAGEDETAILSUMMARY = "SCCAMPAIGNPAGEDETAILSUMMARY";
export const SETSCCAMPAIGNPAGEPGCOUNT = "SETSCCAMPAIGNPAGEPGCOUNT";
export const SETSCCAMPAIGNPAGEVIEWPGCOUNT = "SETSCCAMPAIGNPAGEVIEWPGCOUNT";
export const SETSCCAMPAIGNSUMMARYPGCOUNT = "SETSCCAMPAIGNSUMMARYPGCOUNT";
export const SHOWSCCHARTS = "SHOWSCCHARTS";
export const SORTSCCAMPAIGNPAGEDETAIL = "SORTSCCAMPAIGNPAGEDETAIL";
export const SPPONSOREDCHARTS = "SPPONSOREDCHARTS";
export const CAMPAIGNSUMMARYCSVLOADING = "CAMPAIGNSUMMARYCSVLOADING";
export const SCCAMPAIGNSUMMARYCSV = "SCCAMPAIGNSUMMARYCSV";
export const CAMPAIGNPAGEDETAILSCSVLOADING = "CAMPAIGNPAGEDETAILSCSVLOADING";
export const SCCAMPAIGNPAGEVIEWDETAILSCSV = "SCCAMPAIGNPAGEVIEWDETAILSCSV";
export const SCEMAILCAMPAIGNPAGEDETAIL = "SCEMAILCAMPAIGNPAGEDETAIL";
export const SCDETAILEXCEL = "SCDETAILEXCEL";
export const SETSCPAGESUMMARYPOPUPCLOSE = "SETSCPAGESUMMARYPOPUPCLOSE";
export const SETSCCAMPAIGNPAGEVIEWPOPUPCLOSE = "SETSCCAMPAIGNPAGEVIEWPOPUPCLOSE";
export const SETSCPOSTEMAILUPDATECAMPAIGNPAGEVIEW = "SETSCPOSTEMAILUPDATECAMPAIGNPAGEVIEW";
export const SCEMAILCAMAPIGNSUMMARY = "SCEMAILCAMAPIGNSUMMARY";
export const SETSCCAMPAIGNSUMMARYPOPUPCLOSE = "SETSCCAMPAIGNSUMMARYPOPUPCLOSE";
export const SETSCPOSTEMAILUPDATECAMPAIGNSUMMARY = "SETSCPOSTEMAILUPDATECAMPAIGNSUMMARY";
export const SETSCPOSTEMAILUPDATE = "SETSCPOSTEMAILUPDATE";
export const GETSCSUMMARYDYNAMICTABLEHEADER = "GETSCSUMMARYDYNAMICTABLEHEADER";
export const SENDSCSUMMARYDYANMICTABLEHEADER = "SENDSCSUMMARYDYANMICTABLEHEADER";
export const GETSCPAGEDETAILSDYNAMICTABLEHEADER = "GETSCPAGEDETAILSDYNAMICTABLEHEADER";
export const SENDSCPAGEDETAILSDYANMICTABLEHEADER = "SENDSCPAGEDETAILSDYANMICTABLEHEADER";
export const GETEDITORIALTOPICDETAILDYNAMICTABLEHEADER = "GETEDITORIALTOPICDETAILDYNAMICTABLEHEADER"
export const SENDEDITORIALTOPICDETAILDYANMICTABLEHEADER = "SENDEDITORIALTOPICDETAILDYANMICTABLEHEADER"
export const GETSCPAGEVIEWDETAILSDYNAMICTABLEHEADER = "GETSCPAGEVIEWDETAILSDYNAMICTABLEHEADER"
export const SENDSCPAGEVIEWDETAILSDYANMICTABLEHEADER = "SENDSCPAGEVIEWDETAILSDYANMICTABLEHEADER"
export const GETEDITORIALORGANIZATIONLDYNAMICTABLEHEADER = "GETEDITORIALORGANIZATIONLDYNAMICTABLEHEADER"
export const SENDEDITORIALORGANIZATIONDYANMICTABLEHEADER = "SENDEDITORIALORGANIZATIONDYANMICTABLEHEADER"
export const EDITORIALORGANIZATIONSUMMARY = "EDITORIALORGANIZATIONSUMMARY"
export const EDITORIALEMAILORGANIZATIONSUMMARYCSV = "EDITORIALEMAILORGANIZATIONSUMMARYCSV"
export const SETEDITORIALORGANIZATIONSUMMARYCSV = "SETEDITORIALORGANIZATIONSUMMARYCSV"
export const SORTORGANIZATIONEDITORIALSUMMARY = "SORTORGANIZATIONEDITORIALSUMMARY"
export const RESETSTATESSCPAGEVIEWDETAILS = "RESETSTATESSCPAGEVIEWDETAILS"
export const UPDATESCPAGEVIEWDETAILSLOADER = "UPDATESCPAGEVIEWDETAILSLOADER"
export const RESETSTATESTOPICTAGESUMMARY = "RESETSTATESTOPICTAGESUMMARY"
export const UPDATESCORGSTAGESUMMARYLOADER = "UPDATESCORGSTAGESUMMARYLOADER"
export const RESETSTATESORGSTAGESUMMARY = "RESETSTATESORGSTAGESUMMARY"
export const UPDATEPLACEMENTTAGESUMMARYLOADER = "UPDATEPLACEMENTTAGESUMMARYLOADER"
export const RESETSTATESPLACEMENTTAGESUMMARY = "RESETSTATESPLACEMENTTAGESUMMARY"
export const UPDATEPAGESUMMARYLOADER = "UPDATEPAGESUMMARYLOADER"
export const RESETSTATESPAGESUMMARY = "RESETSTATESPAGESUMMARY"
export const SELSCTRAFFICFILTER = "SELSCTRAFFICFILTER"

//Editorial action types
export const APPLYEDITORIALDATE = "APPLYEDITORIALDATE";
export const SELEDITORIALSTARTDATE = "SELEDITORIALSTARTDATE";
export const SELEDITORIALENDDATE = "SELEDITORIALENDDATE";
export const SELEDITORIALPREVSTARTDATE = "SELEDITORIALPREVSTARTDATE";
export const SELEDITORIALPREVENDDATE = "SELEDITORIALPREVENDDATE";
export const SELEDITORIALFILTERVALUE = "SELEDITORIALFILTERVALUE";
export const EDITORIALCUSTOMRANGE = "EDITORIALCUSTOMRANGE";
export const FETCHEDITORIALORGS = "FETCHEDITORIALORGS";
export const ADDEDITORIALORGS = "ADDEDITORIALORGS";
export const CLEARSEARCHEDITORIALORGS = "CLEARSEARCHEDITORIALORGS";
export const FETCHSELEDITORIALCAMPAIGNS = "FETCHSELEDITORIALCAMPAIGNS";
export const FETCHEDITORIALORDER = "FETCHEDITORIALORDER";
export const ADDEDITORIALORDER = "ADDEDITORIALORDER";
export const CLEARSEARCHEDITORIALORDER = "CLEARSEARCHEDITORIALORDER";
export const RESETSELEDITORIALCAMPAIGNS = "RESETSELEDITORIALCAMPAIGNS";
export const FETCHEDITORIALCAMPAIGNS = "FETCHEDITORIALCAMPAIGNS";
export const ADDEDITORIALCAMPAIGNS = "ADDEDITORIALCAMPAIGNS";
export const RESETEDITORIALCAMPAIGNS = "RESETEDITORIALCAMPAIGNS";
export const CLEARSEARCHEDITORIALCAMPAIGNS = "CLEARSEARCHEDITORIALCAMPAIGNS";
export const FETCHEDITORIALAUTHOR = "FETCHEDITORIALAUTHOR";
export const CLEARSEARCHEDITORIALAUTHOR = "CLEARSEARCHEDITORIALAUTHOR";
export const CLEARSEARCHEDITORIALTOPICTAGEX = "CLEARSEARCHEDITORIALTOPICTAGEX";
export const APPLYEDITORIALTOPICTAGSEX = "APPLYEDITORIALTOPICTAGSEX";
export const FETCHEDITORIALTOPICTAGSEX = "FETCHEDITORIALTOPICTAGSEX";
export const FETCHEDITORIALTOPICTAGSIN = "FETCHEDITORIALTOPICTAGSIN";
export const APPLYEDITORIALTOPICTAGSIN = "APPLYEDITORIALTOPICTAGSIN";
export const CLEARSEARCHEDITORIALTOPICTAGIN = "CLEARSEARCHEDITORIALTOPICTAGIN";
export const FETCHEDITORIALPLACEMENTTAGSEX = "FETCHEDITORIALPLACEMENTTAGSEX";
export const APPLYEDITORIALPLACEMENTTAGSEX = "APPLYEDITORIALPLACEMENTTAGSEX";
export const CLEARSEARCHEDITORIALPLACEMENTTAGEX = "CLEARSEARCHEDITORIALPLACEMENTTAGEX";
export const FETCHEDITORIALPLACEMENTTAGSIN = "FETCHEDITORIALPLACEMENTTAGSIN";
export const APPLYEDITORIALPLACEMENTTAGSIN = "APPLYEDITORIALPLACEMENTTAGSIN";
export const CLEARSEARCHEDITORIALPLACEMENTTAGIN = "CLEARSEARCHEDITORIALPLACEMENTTAGIN";
export const FETCHEDITORIALORGTAGSIN = "FETCHEDITORIALORGTAGSIN";
export const APPLYEDITORIALORGTAGSIN = "APPLYEDITORIALORGTAGSIN";
export const CLEARSEARCHEDITORIALORGTAGIN = "CLEARSEARCHEDITORIALORGTAGIN";
export const FETCHEDITORIALORGTAGSEX = "FETCHEDITORIALORGTAGSEX";
export const APPLYEDITORIALORGTAGSEX = "APPLYEDITORIALORGTAGSEX";
export const CLEARSEARCHEDITORIALORGTAGEX = "CLEARSEARCHEDITORIALORGTAGEX";
export const ADDEDITORIALENGORGS = "ADDEDITORIALENGORGS";
export const FETCHEDITORIALENGORGS = "FETCHEDITORIALENGORGS";
export const CLEARSEARCHEDITORIALENGORGS = "CLEARSEARCHEDITORIALENGORGS";
export const CLEARSEARCHEDITORIALCOUNTRY = "CLEARSEARCHEDITORIALCOUNTRY";
export const ADDEDITORIALCONTRIES = "ADDEDITORIALCONTRIES";
export const FETCHEDITORIALCOUNTRY = "FETCHEDITORIALCOUNTRY";
export const FETCHEDITORIALFIRMOGRAPHICORGSTYPE = "FETCHEDITORIALFIRMOGRAPHICORGSTYPE";
export const APPLYEDITORIALORGSTYPE = "APPLYEDITORIALORGSTYPE";
export const CLEARSEARCHEDITORIALORGSTYPE = "CLEARSEARCHEDITORIALORGSTYPE";
export const FETCHEDITORIALORGSSIZE = "FETCHEDITORIALORGSSIZEFETCHEDITORIALORGSSIZE";
export const APPLYEDITORIALORGSSIZE = "APPLYEDITORIALORGSSIZE";
export const CLEARSEARCHEDITORIALORGSSIZE = "CLEARSEARCHEDITORIALORGSSIZE";
export const FETCHEDITORIALORGSREVENUE = "FETCHEDITORIALORGSREVENUE";
export const APPLYEDITORIALREVENUE = "APPLYEDITORIALREVENUE";
export const CLEARSEARCHEDITORIALORGSREVENUE = "CLEARSEARCHEDITORIALORGSREVENUE";
export const FETCHEDITORIALSECTOR = "FETCHEDITORIALSECTOR";
export const APPLYEDITORIALSECTOR = "APPLYEDITORIALSECTOR";
export const CLEARSEARCHEDITORIALSECTOR = "CLEARSEARCHEDITORIALSECTOR";
export const FETCHEDITORIALINDUSTRYGROUP = "FETCHEDITORIALINDUSTRYGROUP";
export const APPLYEDITORIALINDUSTRYGROUP = "APPLYEDITORIALINDUSTRYGROUP";
export const CLEARSEARCHEDITORIALINDUSTRYGROUP = "CLEARSEARCHEDITORIALINDUSTRYGROUP";
export const FETCHEDITORIALINDUSTRY = "FETCHEDITORIALINDUSTRY";
export const APPLYEDITORIALINDUSTRY = "APPLYEDITORIALINDUSTRY";
export const CLEARSEARCHEDITORIALINDUSTRY = "CLEARSEARCHEDITORIALINDUSTRY";
export const FETCHEDITORIALSUBINDUSTRY = "FETCHEDITORIALSUBINDUSTRY";
export const APPLYEDITORIALSUBINDUSTRY = "APPLYEDITORIALSUBINDUSTRY";
export const CLEARSEARCHEDITORIALSUBINDUSTRY = "CLEARSEARCHEDITORIALSUBINDUSTRY";
export const SELEDITORIALFIRMOGRAPHICTOGGLE = "SELEDITORIALFIRMOGRAPHICTOGGLE";
export const SELEDITORIALPERSONATOGGLE = "SELEDITORIALPERSONATOGGLE";
export const FETCHEDITORIALSENIORITIESIN = "FETCHEDITORIALSENIORITIESIN"
export const APPLYEDITORIALSENIORITIESIN = "APPLYEDITORIALSENIORITIESIN" 
export const CLEARSEARCHEDITORIALSENIORITIESIN = "CLEARSEARCHEDITORIALSENIORITIESIN" 
export const FETCHEDITORIALSENIORITIESEX = "FETCHEDITORIALSENIORITIESEX" 
export const APPLYEDITORIALSENIORITIESEX = "APPLYEDITORIALSENIORITIESEX" 
export const CLEARSEARCHEDITORIALSENIORITIESEX = "CLEARSEARCHEDITORIALSENIORITIESEX" 
export const FETCHEDITORIALJOBFUNCTIONSIN = "FETCHEDITORIALJOBFUNCTIONSIN" 
export const APPLYEDITORIALJOBFUNCTIONSIN = "APPLYEDITORIALJOBFUNCTIONSIN" 
export const CLEARSEARCHEDITORIALJOBFUNCTIONSIN = "CLEARSEARCHEDITORIALJOBFUNCTIONSIN" 
export const FETCHEDITORIALJOBFUNCTIONSEX = "FETCHEDITORIALJOBFUNCTIONSEX" 
export const APPLYEDITORIALJOBFUNCTIONSEX = "APPLYEDITORIALJOBFUNCTIONSEX"
export const CLEARSEARCHEDITORIALJOBFUNCTIONSEX = "CLEARSEARCHEDITORIALJOBFUNCTIONSEX"
export const SELEDITORIALWPSTARTDATE = "SELEDITORIALWPSTARTDATE";
export const SELEDITORIALWPENDDATE = "SELEDITORIALWPENDDATE";
export const APPLYEDITORIALDATEFILTER = "APPLYEDITORIALDATEFILTER";
export const SELEDITORIALPAGEPARAMSTOGGLE = "SELEDITORIALPAGEPARAMSTOGGLE";
export const FETCHEDITORIALTITLE = "FETCHEDITORIALTITLE";
export const CLEARSEARCHEDITORIALPAGETITLE = "CLEARSEARCHEDITORIALPAGETITLE";
export const FETCHEDITORIALURLSOURCE = "FETCHEDITORIALURLSOURCE";
export const CLEARSEARCHEDITORIALURLSOURCE = "CLEARSEARCHEDITORIALURLSOURCE";
export const FETCHEDITORIALURLMEDIUM = "FETCHEDITORIALURLMEDIUM";
export const CLEARSEARCHEDITORIALURLMEDIUM = "CLEARSEARCHEDITORIALURLMEDIUM";
export const FETCHEDITORIALURLCAMPAIGNS = "FETCHEDITORIALURLCAMPAIGNS";
export const CLEARSEARCHEDITORIALURLCAMPAIGNS = "CLEARSEARCHEDITORIALURLCAMPAIGNS";
export const FETCHEDITORIALURLTERM = "FETCHEDITORIALURLTERM";
export const CLEARSEARCHEDITORIALURLTERM = "CLEARSEARCHEDITORIALURLTERM";
export const FETCHEDITORIALURLCONTENT = "FETCHEDITORIALURLCONTENT";
export const CLEARSEARCHEDITORIALURLCONTENT = "CLEARSEARCHEDITORIALURLCONTENT";
export const RESETSELEDITORIALURLCAMPANDMEDIUM = "RESETSELEDITORIALURLCAMPANDMEDIUM";
export const FETCHEDITORIALENGMEMBER = "FETCHEDITORIALENGMEMBER";
export const ADDEDITORIALENGMEMBER = "ADDEDITORIALENGMEMBER";
export const CLEARSEARCHEDITORIALENGMEMBER = "CLEARSEARCHEDITORIALENGMEMBER";
export const FETCHEDITORIALPRIMARYTOPIC = "FETCHEDITORIALPRIMARYTOPIC";
export const ADDEDITORIALPRIMARYTOPIC = "ADDEDITORIALPRIMARYTOPIC";
export const CLEARSEARCHEDITORIAALPRIMARYTOPIC = "CLEARSEARCHEDITORIAALPRIMARYTOPIC";
export const SELINDINTERNALTOGGLE = "SELINDINTERNALTOGGLE";
export const SELEDITORIALINTOGGLE = "SELEDITORIALINTOGGLE";
export const CLEAREDITORIALFILTER = "CLEAREDITORIALFILTER";
export const APPLYEDITORIALFILTER = "APPLYEDITORIALFILTER";
export const EDITORIALDETAILS = "EDITORIALDETAILS";
export const APPLYEDITORIALDETAILSFILTER = "APPLYEDITORIALDETAILSFILTER";
export const SETEDITORIALPAGEVIEWPOSTEMAILUPDATE = "SETEDITORIALPAGEVIEWPOSTEMAILUPDATE"
export const SELEDEXANONYMOUSMEMBERS = "SELEDEXANONYMOUSMEMBERS"
export const SELEDEXANONYMOUSORGS = "SELEDEXANONYMOUSORGS"
export const RESETEDITORIALORGS = "RESETEDITORIALORGS";
export const RESETEDITORIALORDER = "RESETEDITORIALORDER";
export const RESETEDITORIALTOPICTAGIN = "RESETEDITORIALTOPICTAGIN";
export const RESETEDITORIALTOPICTAGEX = "RESETEDITORIALTOPICTAGEX";
export const RESETEDITORIALPLACEMENTTAGIN = "RESETEDITORIALPLACEMENTTAGIN";
export const RESETEDITORIALPLACEMENTTAGEX = "RESETEDITORIALPLACEMENTTAGEX";
export const RESETEDITORIAORGTAGIN = "RESETEDITORIAORGTAGIN";
export const RESETEDITORIAORGTAGEX = "RESETEDITORIAORGTAGEX";
export const RESETEDITORIAENGORGS = "RESETEDITORIAENGORGS";
export const RESETEDITORIACOUNTRY = "RESETEDITORIACOUNTRY";
export const RESETEDITORIALORGSTYPE = "RESETEDITORIALORGSTYPE";
export const RESETEDITORIALORGSIZE = "RESETEDITORIALORGSIZE";
export const RESETEDITORIALORGSREVENUE = "RESETEDITORIALORGSREVENUE";
export const RESETEDITORIALSECTOR = "RESETEDITORIALSECTOR";
export const RESETEDITORIALINDUSTRYGROUP = "RESETEDITORIALINDUSTRYGROUP";
export const RESETEDITORIALINDUSTRY = "RESETEDITORIALINDUSTRY";
export const RESETEDITORIALSUBINDUSTRY = "RESETEDITORIALSUBINDUSTRY";
export const RESETEDITORIALSENIORITIESIN = "RESETEDITORIALSENIORITIESIN"
export const RESETEDITORIALSENIORITIESEX = "RESETEDITORIALSENIORITIESEX"
export const RESETEDITORIALJOBFUNCTIONSIN = "RESETEDITORIALJOBFUNCTIONSIN"
export const RESETEDITORIALJOBFUNCTIONSEX = "RESETEDITORIALJOBFUNCTIONSEX"
export const RESETEDITORIALENGMEMBER = "RESETEDITORIALENGMEMBER";
export const RESETEDITORIALPRIMARYTOPIC = "RESETEDITORIALPRIMARYTOPIC";
export const TOPICSUMMARYTOGGLEVALUE = "TOPICSUMMARYTOGGLEVALUE"
export const ORGSSUMMARYTOGGLEVALUE = "ORGSSUMMARYTOGGLEVALUE"
export const PLACEMENTSUMMARYTOGGLEVALUE = "PLACEMENTSUMMARYTOGGLEVALUE"
export const PAGESUMMARYTOGGLEVALUE = "PAGESUMMARYTOGGLEVALUE"
export const SETTOGGLEVALUE = "SETTOGGLEVALUE";
export const PAGEVIEW_CONFIG_LIST = "PAGEVIEW_CONFIG_LIST";

//independent table action types
export const SORTPLACEMENTEDITORIALSUMMARY = "SORTPLACEMENTEDITORIALSUMMARY";
export const PLACEMENTEDITORIALSUMMARY = "PLACEMENTEDITORIALSUMMARY";
export const SORTEDITORIALPAGESUMMARY = "SORTEDITORIALPAGESUMMARY";
export const SORTSCTOPICPAGEVIEWSUMMARY = "SORTSCTOPICPAGEVIEWSUMMARY";
export const SORTTOPICEDITORIALSUMMARY = "SORTTOPICEDITORIALSUMMARY";
export const EDITORIALPRIMARYTOPIC = "EDITORIALPRIMARYTOPIC";
export const INDPRIMARYTOPICCOUNT = "INDPRIMARYTOPICCOUNT";
export const SORTEDITORIALPRIMARYTOPICSUMMARY = "SORTEDITORIALPRIMARYTOPICSUMMARY";
export const CLEAREDITORIALSORT = "CLEAREDITORIALSORT";
export const MOREEDITORIALPRIMARYTOPICLOADING = "MOREEDITORIALPRIMARYTOPICLOADING";
export const MOREEDITORIALSUMMARY = "MOREEDITORIALSUMMARY";
export const SETEDITORIALPRIMARYTOPICPGCOUNT = "SETEDITORIALPRIMARYTOPICPGCOUNT";
export const EDITORIALPAGEVIEWSUMMARY = "EDITORIALPAGEVIEWSUMMARY";
export const MOREEDITORIALPAGEVIEWSUMMARY = "MOREEDITORIALPAGEVIEWSUMMARY";
export const MOREEDITORIALPAGEVIEWSUMMARYLOADING = "MOREEDITORIALPAGEVIEWSUMMARYLOADING";
export const SETEDITORIALPAGEVIEWSUMMARYPGCOUNT = "SETEDITORIALPAGEVIEWSUMMARYPGCOUNT";
export const EDITORIALEMAILPAGESUMMARY = "EDITORIALEMAILPAGESUMMARY";
export const EDITORIALDETAILEXCEL = "EDITORIALDETAILEXCEL";
export const SETEDITORIALCAMPAIGNPAGEVIEWPOPUPCLOSE = "SETEDITORIALCAMPAIGNPAGEVIEWPOPUPCLOSE";
export const EDITORIALEMAILPAGEDETAIL = "EDITORIALEMAILPAGEDETAIL";
export const SETEDITORIALPAGESUMMARYOPUPCLOSE = "SETEDITORIALPAGESUMMARYOPUPCLOSE";
export const SHOWEDITORIALCHARTS = "SHOWEDITORIALCHARTS";
export const EDITORIALCHARTS = "EDITORIALCHARTS";
export const SORTEDITORIALPRIMARYTOPIC = "SORTEDITORIALPRIMARYTOPIC";
export const SCEMAILCAMPAIGNPAGEDETAILS = "SCEMAILCAMPAIGNPAGEDETAILS";
export const SETSCCAMPAIGNPAGEDETAILSPOPUPCLOSE = "SETSCCAMPAIGNPAGEDETAILSPOPUPCLOSE";
export const SETSCPOSTEMAILUPDATEPAGEDETAIL = "SETSCPOSTEMAILUPDATEPAGEDETAIL";
export const GETEDITORIALPAGESUMMARYDYNAMICTABLEHEADER = "GETEDITORIALPAGESUMMARYDYNAMICTABLEHEADER";
export const SENDEDITORIALPAGESUMMARYDYANMICTABLEHEADER = "SENDEDITORIALPAGESUMMARYDYANMICTABLEHEADER";
export const GETEDITORIALPLACEMENTSUMMARYDYNAMICTABLEHEADER = "GETEDITORIALPLACEMENTSUMMARYDYNAMICTABLEHEADER";
export const SENDEDITORIALPLACEMENTSUMMARYDYANMICTABLEHEADER = "SENDEDITORIALPLACEMENTSUMMARYDYANMICTABLEHEADER";
export const CLEAREDSORT = "CLEAREDSORT";
export const GETEDITORIALDETAILSPAGESUMMARYDYNAMICTABLEHEADER = "GETEDITORIALDETAILSPAGESUMMARYDYNAMICTABLEHEADER"
export const SENDEDITORIALDETAILSPAGESUMMARYDYANMICTABLEHEADER = "SENDEDITORIALDETAILSPAGESUMMARYDYANMICTABLEHEADER"
export const SETINDEPENDATNTPOSTEMAILUPDATEPRIMARYTOPICSUMMARY = "SETINDEPENDATNTPOSTEMAILUPDATEPRIMARYTOPICSUMMARY"
export const EDITORIALEMAILPRIMARYESUMMARY = "EDITORIALEMAILPRIMARYESUMMARY"
export const SETEDITORIALPRIMARYTOPICPOPUPCLOSE = "SETEDITORIALPRIMARYTOPICPOPUPCLOSE"
export const EDITORIALEMAILPAGESUMMARYCSV = "EDITORIALEMAILPAGESUMMARYCSV"
export const SETEDITORIALPAGESUMMARYCSV = "SETEDITORIALPAGESUMMARYCSV"
export const SETEDITORIALPAGESUMMARYCSVOPUPCLOSE = "SETEDITORIALPAGESUMMARYCSVOPUPCLOSE"
export const SETINDEPENDATNTPOSTEMAILUPDATEPLACEMENTSUMMARY = "SETINDEPENDATNTPOSTEMAILUPDATEPLACEMENTSUMMARY"
export const EDITORIALEMAILPLACEMENTESUMMARY = "EDITORIALEMAILPLACEMENTESUMMARY"
export const SETEDITORIALPLACEMENTPOPUPCLOSE = "SETEDITORIALPLACEMENTPOPUPCLOSE"
export const EDITORIALEMAILTOPICTAGSUMMARY = "EDITORIALEMAILTOPICTAGSUMMARY"
export const SETEDITORIALTOPICTAGSPOPUPCLOSE = "SETEDITORIALTOPICTAGSPOPUPCLOSE"
export const SETINDEPENDATNTPOSTEMAILUPDATETOPICTAGSSUMMARY = "SETINDEPENDATNTPOSTEMAILUPDATETOPICTAGSSUMMARY"
export const RESETEDITORIALDETAILORGS = "RESETEDITORIALDETAILORGS";
export const RESETEDORDER = "RESETEDORDER";
export const RESETEDTOPICTAGIN = "RESETEDTOPICTAGIN";
export const RESETEDTOPICTAGEX = "RESETEDTOPICTAGEX";
export const RESETEDPLACEMENTTAGIN = "RESETEDPLACEMENTTAGIN";
export const RESETEDPLACEMENTTAGEX = "RESETEDPLACEMENTTAGEX";
export const RESETEDORGTAGIN = "RESETEDORGTAGIN";
export const RESETEDORGTAGEX = "RESETEDORGTAGEX";
export const RESETEDENGORGS = "RESETEDENGORGS";
export const RESETEDCOUNTRY = "RESETEDCOUNTRY";
export const RESETEDORGSTYPE = "RESETEDORGSTYPE";
export const RESETEDORGSSIZE = "RESETEDORGSSIZE";
export const RESETEDORGSREVENUE = "RESETEDORGSREVENUE";
export const RESETEDSECTOR = "RESETEDSECTOR";
export const RESETEDINDUSTRYGROUP = "RESETEDINDUSTRYGROUP";
export const RESETEDINDUSTRY = "RESETEDINDUSTRY";
export const RESETEDSUBINDUSTRY = "RESETEDSUBINDUSTRY";
export const RESETEDSENIORITIESIN = "RESETEDSENIORITIESIN";
export const RESETEDSENIORITIESEX = "RESETEDSENIORITIESEX";
export const RESETEDJOBFUNCTIONSIN = "RESETEDJOBFUNCTIONSIN";
export const RESETEDJOBFUNCTIONSEX = "RESETEDJOBFUNCTIONSEX";
export const RESETEDENGMEMBER = "RESETEDENGMEMBER";
export const RESETEDPRIMARYTOPIC = "RESETEDPRIMARYTOPIC";
export const PAGESUMMARYCOUNT = "PAGESUMMARYCOUNT"
export const SEL_ED_TABLE_TOGGLE = "SEL_ED_TABLE_TOGGLE";
export const RESET_ED_DETAILS_DATA = "RESET_ED_DETAILS_DATA";
export const PAGEVIEW_DETAILS_TOGGLE = "PAGEVIEW_DETAILS_TOGGLE";
export const UPDATE_ED_LOADER = "UPDATE_ED_LOADER";
//Site report action types
export const SELSITEREPORTSTARTDATE = "SELSITEREPORTSTARTDATE";
export const SELSITEREPORTENDDATE = "SELSITEREPORTENDDATE";
export const SELSITEREPORTPREVSTARTDATE = "SELSITEREPORTPREVSTARTDATE";
export const SELSITEREPORTPREVENDDATE = "SELSITEREPORTPREVENDDATE";
export const APPLYSITEREPORTDATE = "APPLYSITEREPORTDATE";
export const SELSITEREPORTCUSTOMRANGE = "SELSITEREPORTCUSTOMRANGE";
export const SHOWSITECHARTS = "SHOWSITECHARTS";
export const SORTALLPGSUMMARYTABLE = "SORTALLPGSUMMARYTABLE"
export const SORTEDITORIALPGSUMMARYTABLE = "SORTEDITORIALPGSUMMARYTABLE"
export const SORTSPONSOREDPGSUMMARYTABLE = "SORTSPONSOREDPGSUMMARYTABLE"
export const SITEALLPGSUMMARYREPORTDATA = "SITEALLPGSUMMARYREPORTDATA"
export const ISLOADINGSITEALLPGSUMMARYTABLE = "ISLOADINGSITEALLPGSUMMARYTABLE"
export const ISLOADINGSITEEDITORIALPGSUMMARYTABLE = "ISLOADINGSITEEDITORIALPGSUMMARYTABLE"
export const ISLOADINGSITESPRONSOREDPGSUMMARYTABLE = "ISLOADINGSITESPRONSOREDPGSUMMARYTABLE"
export const SITEEDITORIALPGSUMMARYREPORTDATA = "SITEEDITORIALPGSUMMARYREPORTDATA"
export const SITESPONSOREDPGSUMMARYREPORTDATA = "SITESPONSOREDPGSUMMARYREPORTDATA"
export const CLEARALLPGSORT = "CLEARALLPGSORT"
export const CLEAREDPGSORT = "CLEAREDPGSORT"
export const CLEARSPPGSORT = "CLEARSPPGSORT"
export const MOREALLPGSUMMARYLOADING = "MOREALLPGSUMMARYLOADING"
export const MOREEDITORIALPGSUMMARYLOADING = "MOREEDITORIALPGSUMMARYLOADING"
export const MORESPONSOREDPGSUMMARYLOADING = "MORESPONSOREDPGSUMMARYLOADING"
export const SETALLPAGEVIEWSUMMARYPGCOUNT = "SETALLPAGEVIEWSUMMARYPGCOUNT"
export const SETEDPAGEVIEWSUMMARYPGCOUNT = "SETEDPAGEVIEWSUMMARYPGCOUNT"
export const SETSPONSOREDPAGEVIEWSUMMARYPGCOUNT = "SETSPONSOREDPAGEVIEWSUMMARYPGCOUNT"
export const SITEREPORTCHARTS = "SITEREPORTCHARTS";
export const SITEREPORTLOADINGCHARTS = "SITEREPORTLOADINGCHARTS";
export const SET_SITE_VALUES = "SET_SITE_VALUES";

//download component search
export const CLEARDOWNLOADFILTER = "CLEARDOWNLOADFILTER";
export const SELDOWNLOADVALUES = "SELDOWNLOADVALUES";
export const FETCHDOWNLOADORGS = "FETCHDOWNLOADORGS";
export const ADDDOWNLOADORGS = "ADDDOWNLOADORGS";
export const FETCHDOWNLOADCAMPAIGNS = "FETCHDOWNLOADCAMPAIGNS";
export const RESETDOWNLOADCAMPAIGNS = "RESETDOWNLOADCAMPAIGNS";
export const CLEARDOWNLOADORGS = "CLEARDOWNLOADORGS";
export const FETCHDOWNLOADORDER = "FETCHDOWNLOADORDER";
export const ADDDOWNLOADORDER = "ADDDOWNLOADORDER";
export const CLEARDOWNLOADORDER = "CLEARDOWNLOADORDER";
export const ADDDOWNLOADCAMPAIGNS = "ADDDOWNLOADCAMPAIGNS";
export const CLEARDOWNLOADCAMPAIGNS = "CLEARDOWNLOADCAMPAIGNS";
export const FETCHDOWNLOADDATA = "FETCHDOWNLOADDATA";
export const RESETDOWNLOADORGS = "RESETDOWNLOADORGS";
export const RESETDOWNLOADORDER = "RESETDOWNLOADORDER";
export const APPLYDOWNLOADFILTER = "APPLYDOWNLOADFILTER";
export const REQUESTDOWNLOADURL = "REQUESTDOWNLOADURL";
export const REQUESTRESENDURL = "REQUESTRESENDURL";
export const REQUESTDELETEURL = "REQUESTDELETEURL";
export const AGAINREQUESTRESENDURL = "AGAINREQUESTRESENDURL";
export const AGAINREQUESTDELETEURL = "AGAINREQUESTDELETEURL";
export const FETCHDOWNLOADREQUESTEDBY = "FETCHDOWNLOADREQUESTEDBY";
export const CLEARDOWNLOADREQUESTEDBY = "CLEARDOWNLOADREQUESTEDBY";
export const RESETDOWNLOADREQUESTEDBY = "RESETDOWNLOADREQUESTEDBY";
export const SHOWMORELOADING = "SHOWMORELOADING";
export const SETDOWNLOADPAGECOUNT = "SETDOWNLOADPAGECOUNT";
export const MOREDOWNLOADDATA = "MOREDOWNLOADDATA";
export const ISLOADING = "ISLOADING";

//activity search 
export const SELVALUES = "SELVALUES";
export const FETCHACTIVITYORGS = "FETCHACTIVITYORGS";
export const ADDACTIVITYORGS = "ADDACTIVITYORGS";
export const CLEARACTIVITYORGS = "CLEARACTIVITYORGS";
export const SELINIACTIVITYORGS = "SELINIACTIVITYORGS";
export const RESETACTIVITYORGS = "RESETACTIVITYORGS";
export const FETCHACTIVITYCAMPAIGNS = "FETCHACTIVITYCAMPAIGNS";
export const ADDACTIVITYCAMPAIGNS = "ADDACTIVITYCAMPAIGNS";
export const CLEARACTIVITYCAMPAIGNS = "CLEARACTIVITYCAMPAIGNS";
export const SELINIACTIVITYCAMPAIGNS = "SELINIACTIVITYCAMPAIGNS";
export const RESETACTIVITYCAMPAIGNS = "RESETACTIVITYCAMPAIGNS";
export const FETCHACTIVITYENGORGS = "FETCHACTIVITYENGORGS";
export const ADDACTIVITYENGORGS = "ADDACTIVITYENGORGS";
export const CLEARACTIVITYENGORGS = "CLEARACTIVITYENGORGS";
export const SELINIACTIVITYENGORGS = "SELINIACTIVITYENGORGS";
export const RESETACTIVITYENGORGS = "RESETACTIVITYENGORGS";
export const SELECTED_TOGGLE = "SELECTED_TOGGLE";
export const RESET_TOGGLE = "RESET_TOGGLE";
export const ACTIVITYTABLE = "ACTIVITYTABLE";
export const GETHEADER = "GETHEADER";
export const SETHEADER = "SETHEADER";
export const FETCHACTIVITYORDER = "FETCHACTIVITYORDER";
export const ADDACTIVITYORDER = "ADDACTIVITYORDER";
export const CLEARACTIVITYORDER = "CLEARACTIVITYORDER";
export const FETCHACTIVITYPAGETITLE = "FETCHACTIVITYPAGETITLE";
export const ADDACTIVITYPAGETITLE = "ADDACTIVITYPAGETITLE";
export const CLEARACTIVITYPAGETITLE = "CLEARACTIVITYPAGETITLE";
export const FETCH_ACTIVITY_SOURCE = "FETCH_ACTIVITY_SOURCE";
export const SELINI_ACTIVITY_UTMSOURCE = "SELINI_ACTIVITY_UTMSOURCE";
export const CLEARSEARCH_ACTIVITY_URLSOURCE = "CLEARSEARCH_ACTIVITY_URLSOURCE";
export const FETCH_ACTIVITY_MEDIUM = "FETCH_ACTIVITY_MEDIUM";
export const SELINI_ACTIVITY_MEDIUM = "SELINI_ACTIVITY_MEDIUM";
export const CLEARSEARCH_ACTIVITY_MEDIUM = "CLEARSEARCH_ACTIVITY_MEDIUM";
export const FETCH_ACTIVITY_CAMPAIGN = "FETCH_ACTIVITY_CAMPAIGN";
export const SELINI_ACTIVITY_CAMPAIGN = "SELINI_ACTIVITY_CAMPAIGN";
export const CLEARSEARCH_ACTIVITY_CAMPAIGN = "CLEARSEARCH_ACTIVITY_CAMPAIGN";
export const FETCH_ACTIVITY_TERM = "FETCH_ACTIVITY_TERM";
export const SELINI_ACTIVITY_TERM = "SELINI_ACTIVITY_TERM";
export const CLEARSEARCH_ACTIVITY_TERM = "CLEARSEARCH_ACTIVITY_TERM";
export const FETCH_ACTIVITY_CONTENT = "FETCH_ACTIVITY_CONTENT";
export const SELINI_ACTIVITY_CONTENT = "SELINI_ACTIVITY_CONTENT";
export const CLEARSEARCH_ACTIVITY_CONTENT = "CLEARSEARCH_ACTIVITY_CONTENT";
export const CLEARACTIVITYFILTER = "CLEARACTIVITYFILTER";
export const RESETACTIVITYORDER = "RESETACTIVITYORDER";
export const RESETACTIVITYTITLE = "RESETACTIVITYTITLE";
export const APPLYACTIVITYFILTER = "APPLYACTIVITYFILTER";
export const FETCHACTIVITYFILTER = "FETCHACTIVITYFILTER";
export const ACTIVITYGENERATEXLSX = "ACTIVITYGENERATEXLSX";
export const ACTIVITYDETAILEXCEL = "ACTIVITYDETAILEXCEL";
export const ACTIVITYCSV = "ACTIVITYCSV";
export const ACTIVITYCSVLOADING = "ACTIVITYCSVLOADING";
export const FETCHACTIVITYENGMEMBER = "FETCHACTIVITYENGMEMBER";
export const CLEARACTIVITYMEMBER = "CLEARACTIVITYMEMBER";
export const ADDACTIVITYMEMBER = "ADDACTIVITYMEMBER";
export const RESETACTIVITYMEMBER = "RESETACTIVITYMEMBER";
export const SELINIACTIVITYENGAGEDMEMBER = "SELINIACTIVITYENGAGEDMEMBER";
export const SELINIACTIVITYORDER = "SELINIACTIVITYORDER";
export const SELINIACTIVITYPAGETITLE = "SELINIACTIVITYPAGETITLE";
export const FETCHACTIVITYTOPICTAGSIN = "FETCHACTIVITYTOPICTAGSIN";
export const ADDACTIVITYTOPICTAGSIN = "ADDACTIVITYTOPICTAGSIN";
export const CLEARACTIVITYTOPICTAGSIN = "CLEARACTIVITYTOPICTAGSIN";
export const SELINIACTIVITYTOPICTAGSIN = "SELINIACTIVITYTOPICTAGSIN";
export const RESETACTIVITYTOPICTAGSIN = "RESETACTIVITYTOPICTAGSIN";
export const FETCHACTIVITYTOPICTAGSEX = "FETCHACTIVITYTOPICTAGSEX";
export const ADDACTIVITYTOPICTAGSEX = "ADDACTIVITYTOPICTAGSEX";
export const CLEARACTIVITYTOPICTAGSEX = "CLEARACTIVITYTOPICTAGSEX";
export const SELINIACTIVITYTOPICTAGSEX = "SELINIACTIVITYTOPICTAGSEX";
export const RESETACTIVITYTOPICTAGSEX = "RESETACTIVITYTOPICTAGSEX";
export const FETCHACTIVITYPLACEMENTTAGSIN = "FETCHACTIVITYPLACEMENTTAGSIN";
export const ADDACTIVITYPLACEMENTTAGSIN = "ADDACTIVITYPLACEMENTTAGSIN";
export const CLEARACTIVITYPLACEMENTTAGSIN = "CLEARACTIVITYPLACEMENTTAGSIN";
export const SELINIACTIVITYPLACEMENTTAGSIN = "SELINIACTIVITYPLACEMENTTAGSIN";
export const RESETACTIVITYPLACEMENTTAGSIN = "RESETACTIVITYPLACEMENTTAGSIN";
export const FETCHACTIVITYPLACEMENTTAGSEX = "FETCHACTIVITYPLACEMENTTAGSEX";
export const ADDACTIVITYPLACEMENTTAGSEX = "ADDACTIVITYPLACEMENTTAGSEX";
export const CLEARACTIVITYPLACEMENTTAGSEX = "CLEARACTIVITYPLACEMENTTAGSEX";
export const SELINIACTIVITYPLACEMENTTAGSEX = "SELINIACTIVITYPLACEMENTTAGSEX";
export const RESETACTIVITYPLACEMENTTAGSEX = "RESETACTIVITYPLACEMENTTAGSEX";
export const FETCHACTIVITYORGANIZATIONTAGSIN = "FETCHACTIVITYORGANIZATIONTAGSIN";
export const ADDACTIVITYORGANIZATIONTAGSIN = "ADDACTIVITYORGANIZATIONTAGSIN";
export const CLEARACTIVITYORGANIZATIONTAGSIN = "CLEARACTIVITYORGANIZATIONTAGSIN";
export const SELINIACTIVITYORGANIZATIONTAGSIN = "SELINIACTIVITYORGANIZATIONTAGSIN";
export const RESETACTIVITYORGANIZATIONTAGSIN = "RESETACTIVITYORGANIZATIONTAGSIN";
export const FETCHACTIVITYORGANIZATIONTAGSEX = "FETCHACTIVITYORGANIZATIONTAGSEX";
export const ADDACTIVITYORGANIZATIONTAGSEX = "ADDACTIVITYORGANIZATIONTAGSEX";
export const CLEARACTIVITYORGANIZATIONTAGSEX = "CLEARACTIVITYORGANIZATIONTAGSEX";
export const SELINIACTIVITYORGANIZATIONTAGSEX = "SELINIACTIVITYORGANIZATIONTAGSEX";
export const RESETACTIVITYORGANIZATIONTAGSEX = "RESETACTIVITYORGANIZATIONTAGSEX";
export const SORTACTIVITYREPORT = "SORTACTIVITYREPORT";
//activity actions
export const DI_POPULATE_FILTER_DATA = "DI_POPULATE_FILTER_DATA";
export const FETCHACTIVITYACTION = "FETCHACTIVITYACTION";
export const ADDACTIVITYACTIONS = "ADDACTIVITYACTIONS";
export const CLEARACTIVITYACTIONS = "CLEARACTIVITYACTIONS";
export const APPLYACTIVITACTIONS = "APPLYACTIVITACTIONS";
export const RESETACTUALACTIVITYDATA = "RESETACTUALACTIVITYDATA";
export const ACTIVITYEXCEL = "ACTIVITYEXCEL";
export const SETACTIVITYPOPUPPOSTEMAIL = "SETACTIVITYPOPUPPOSTEMAIL";
export const SET_ACTIVITY_ENGAGEMENT = "SET_ACTIVITY_ENGAGEMENT";
export const APPLY_LIGHT_ACTIVITY_XLSX = "APPLY_LIGHT_ACTIVITY_XLSX";
export const SET_LIGHT_ACTIVITY_POPUP_POST_EMAIL = "SET_LIGHT_ACTIVITY_POPUP_POST_EMAIL";
export const FETCH_ENG_CSM = "FETCH_ENG_CSM";
export const ADD_ENG_CSM = "ADD_ENG_CSM";
export const SELECT_ENG_CSM = "SELECT_ENG_CSM";
export const CLEAR_ENG_CSM = "CLEAR_ENG_CSM";
export const FETCH_INCLUDE_ACTION_ACTIVITY = "FETCH_INCLUDE_ACTION_ACTIVITY";
export const ADD_INCLUDE_ACTION_ACTIVITY = "ADD_INCLUDE_ACTION_ACTIVITY";
export const CLEAR_INCLUDE_ACTION_ACTIVITY = "CLEAR_INCLUDE_ACTION_ACTIVITY";
export const FETCH_EXCLUDE_ACTION_ACTIVITY = "FETCH_EXCLUDE_ACTION_ACTIVITY";
export const ADD_EXCLUDE_ACTION_ACTIVITY = "ADD_EXCLUDE_ACTION_ACTIVITY";
export const CLEAR_EXCLUDE_ACTION_ACTIVITY = "CLEAR_EXCLUDE_ACTION_ACTIVITY";
export const ACTIVITY_SAME_ACTIONS_ERROR = "ACTIVITY_SAME_ACTIONS_ERROR";
export const SELECT_INCLUDE_ACTION_ACTIVITY = "SELECT_INCLUDE_ACTION_ACTIVITY";
export const SELECT_EXCLUDE_ACTION_ACTIVITY = "SELECT_EXCLUDE_ACTION_ACTIVITY";

export const GET_LIST_DATA = "GET_LIST_DATA";
export const GET_REPORT_DESCRIPTION = "GET_REPORT_DESCRIPTION";
export const POST_REPORT_DESCRIPTION = "POST_REPORT_DESCRIPTION";

export const COMMON_SEARCH = {
  SELECT_START_DATE: "SELECT_START_DATE",
  SELECT_END_DATE: "SELECT_END_DATE",
  SELECT_PREV_START_DATE: "SELECT_PREV_START_DATE",
  SELECT_PREV_END_DATE: "SELECT_PREV_END_DATE",

  FETCH_ORDERS: "FETCH_ORDERS",
  FETCH_ORGANIZATIONS: "FETCH_ORGANIZATIONS",
  FETCH_CAMPAIGNS: "FETCH_CAMPAIGNS",
  FETCH_AD_ORDERS: "FETCH_AD_ORDERS",
  FETCH_ENG_ORGANIZATIONS: "FETCH_ENG_ORGANIZATIONS",
  FETCH_TAGS: "FETCH_TAGS",
  FETCH_AD_UNIT: "FETCH_AD_UNIT",
  FETCH_COUNTRIES: "FETCH_COUNTRIES",
  FETCH_ENG_MEMBERS: "FETCH_ENG_MEMBERS",
  FETCH_SECTORS: "FETCH_SECTORS",
  FETCH_INDUSTRY_GROUPS: "FETCH_INDUSTRY_GROUPS",
  FETCH_INDUSTRIES : "FETCH_INDUSTRIES",
  FETCH_SUB_INDUSTRIES : "FETCH_SUB_INDUSTRIES",
  FETCH_ORGANIZATION_TYPES: "FETCH_ORGANIZATION_TYPES",
  FETCH_ORGANIZATION_SIZES: "FETCH_ORGANIZATION_SIZES",
  FETCH_ORGANIZATION_REVENUES: "FETCH_ORGANIZATION_REVENUES",
  FETCH_SENORITY_IN : "FETCH_SENORITY_IN",
  FETCH_SENORITY_EX : "FETCH_SENORITY_EX",
  FETCH_JOB_FUNCTION_IN : "FETCH_JOB_FUNCTION_IN",
  FETCH_JOB_FUNCTION_EX : "FETCH_JOB_FUNCTION_EX",
  FETCH_AUTHORS : "FETCH_AUTHORS",
  FETCH_NEWSLETTERS_AUTHORS : "FETCH_NEWSLETTERS_AUTHORS",
  FETCH_PAGE_TITLES : "FETCH_PAGE_TITLES",
  FETCH_UTM_SOURCES : "FETCH_UTM_SOURCES",
  FETCH_UTM_MEDIUMS : "FETCH_UTM_MEDIUMS",
  FETCH_UTM_CAMPAIGNS : "FETCH_UTM_CAMPAIGNS",
  FETCH_UTM_CONTENTS : "FETCH_UTM_CONTENTS",
  FETCH_UTM_TERMS : "FETCH_UTM_TERMS",
  FETCH_PRIMARY_TOPICS : "FETCH_PRIMARY_TOPICS",
  FETCH_TOPIC_TAGS_IN : "FETCH_TOPIC_TAGS_IN",
  FETCH_TOPIC_TAGS_EX : "FETCH_TOPIC_TAGS_EX",
  FETCH_PLACEMENT_TAGS_IN : "FETCH_PLACEMENT_TAGS_IN",
  FETCH_PLACEMENT_TAGS_EX : "FETCH_PLACEMENT_TAGS_EX",
  FETCH_ORGANIZATION_TAGS_IN : "FETCH_ORGANIZATION_TAGS_IN",
  FETCH_ORGANIZATION_TAGS_EX : "FETCH_ORGANIZATION_TAGS_EX",
  FETCH_CSM: "FETCH_CSM",
  FETCH_AUTHOR: "FETCH_AUTHOR",
  FETCH_COMPANY_NAME: "FETCH_COMPANY_NAME",
  FETCH_ACCOUNT_INTERESTED_BY_KEYWORD: "FETCH_ACCOUNT_INTERESTED_BY_KEYWORD",
  FETCH_PARENT_COMPANY: "FETCH_PARENT_COMPANY",
  FETCH_SEGMENTS: "FETCH_SEGMENTS",

  ADD_ORDERS: "ADD_ORDERS",
  ADD_ORGANIZATIONS: "ADD_ORGANIZATIONS",
  ADD_CAMPAIGNS: "ADD_CAMPAIGNS",
  ADD_AD_ORDERS: "ADD_AD_ORDERS",
  ADD_TAGS: "ADD_TAGS",
  ADD_ENG_ORGANIZATIONS: "ADD_ENG_ORGANIZATIONS",
  ADD_AD_UNIT: "ADD_AD_UNIT",
  ADD_COUNTRIES: "ADD_COUNTRIES",
  ADD_ENG_MEMBERS: "ADD_ENG_MEMBERS",
  ADD_SECTORS: "ADD_SECTORS",
  ADD_INDUSTRY_GROUPS: "ADD_INDUSTRY_GROUPS",
  ADD_INDUSTRIES : "ADD_INDUSTRIES",
  ADD_SUB_INDUSTRIES : "ADD_SUB_INDUSTRIES",
  ADD_ORGANIZATION_TYPES: "ADD_ORGANIZATION_TYPES",
  ADD_ORGANIZATION_SIZES: "ADD_ORGANIZATION_SIZES",
  ADD_ORGANIZATION_REVENUES: "ADD_ORGANIZATION_REVENUES",
  ADD_SENORITY_IN : "ADD_SENORITY_IN",
  ADD_SENORITY_EX : "ADD_SENORITY_EX",
  ADD_JOB_FUNCTION_IN : "ADD_JOB_FUNCTION_IN",
  ADD_JOB_FUNCTION_EX : "ADD_JOB_FUNCTION_EX",
  ADD_AUTHORS : "ADD_AUTHORS",
  ADD_NEWSLETTERS_AUTHORS : "ADD_NEWSLETTERS_AUTHORS",
  ADD_PAGE_TITLES : "ADD_PAGE_TITLES",
  ADD_UTM_SOURCES : "ADD_UTM_SOURCES",
  ADD_UTM_MEDIUMS : "ADD_UTM_MEDIUMS",
  ADD_UTM_CAMPAIGNS : "ADD_UTM_CAMPAIGNS",
  ADD_UTM_CONTENTS : "ADD_UTM_CONTENTS",
  ADD_UTM_TERMS : "ADD_UTM_TERMS",
  ADD_PRIMARY_TOPICS : "ADD_PRIMARY_TOPICS",
  ADD_TOPIC_TAGS_IN : "ADD_TOPIC_TAGS_IN",
  ADD_TOPIC_TAGS_EX : "ADD_TOPIC_TAGS_EX",
  ADD_PLACEMENT_TAGS_IN : "ADD_PLACEMENT_TAGS_IN",
  ADD_PLACEMENT_TAGS_EX : "ADD_PLACEMENT_TAGS_EX",
  ADD_ORGANIZATION_TAGS_IN : "ADD_ORGANIZATION_TAGS_IN",
  ADD_ORGANIZATION_TAGS_EX : "ADD_ORGANIZATION_TAGS_EX",
  ADD_CSM: "ADD_CSM",
  ADD_AUTHOR: "ADD_AUTHOR",
  ADD_COMPANY_NAME: "ADD_COMPANY_NAME",
  ADD_ACCOUNT_INTERESTED_BY_KEYWORD: "ADD_ACCOUNT_INTERESTED_BY_KEYWORD",
  ADD_PARENT_COMPANY: "ADD_PARENT_COMPANY",
  ADD_SEGMENTS: "ADD_SEGMENTS",

  SELECT_ORDERS: "SELECT_ORDERS",
  SELECT_CAMPAIGNS: "SELECT_CAMPAIGNS",
  SELECT_ORGANIZATIONS: "SELECT_ORGANIZATIONS",
  SELECT_AD_ORDERS: "SELECT_AD_ORDERS",
  SELECT_TAGS: "SELECT_TAGS",
  SELECT_ENG_ORGANIZATIONS: "SELECT_ENG_ORGANIZATIONS",
  SELECT_AD_UNIT: "SELECT_AD_UNIT",
  SELECT_COUNTRIES: "SELECT_COUNTRIES",
  SELECT_ENG_MEMBERS: "SELECT_ENG_MEMBERS",
  SELECT_SECTORS: "SELECT_SECTORS",
  SELECT_INDUSTRY_GROUPS: "SELECT_INDUSTRY_GROUPS",
  SELECT_INDUSTRIES : "SELECT_INDUSTRIES",
  SELECT_SUB_INDUSTRIES : "SELECT_SUB_INDUSTRIES",
  SELECT_ORGANIZATION_TYPES: "SELECT_ORGANIZATION_TYPES",
  SELECT_ORGANIZATION_SIZES: "SELECT_ORGANIZATION_SIZES",
  SELECT_ORGANIZATION_REVENUES: "SELECT_ORGANIZATION_REVENUES",
  SELECT_SENORITY_IN : "SELECT_SENORITY_IN",
  SELECT_SENORITY_EX : "SELECT_SENORITY_EX",
  SELECT_JOB_FUNCTION_IN : "SELECT_JOB_FUNCTION_IN",
  SELECT_JOB_FUNCTION_EX : "SELECT_JOB_FUNCTION_EX",
  SELECT_AUTHORS : "SELECT_AUTHORS",
  SELECT_NEWSLETTERS_AUTHORS : "SELECT_NEWSLETTERS_AUTHORS",
  SELECT_PAGE_TITLES : "SELECT_PAGE_TITLES",
  SELECT_UTM_SOURCES : "SELECT_UTM_SOURCES",
  SELECT_UTM_MEDIUMS : "SELECT_UTM_MEDIUMS",
  SELECT_UTM_CAMPAIGNS : "SELECT_UTM_CAMPAIGNS",
  SELECT_UTM_CONTENTS : "SELECT_UTM_CONTENTS",
  SELECT_UTM_TERMS : "SELECT_UTM_TERMS",
  SELECT_PRIMARY_TOPICS : "SELECT_PRIMARY_TOPICS",
  SELECT_TOPIC_TAGS_IN : "SELECT_TOPIC_TAGS_IN",
  SELECT_TOPIC_TAGS_EX : "SELECT_TOPIC_TAGS_EX",
  SELECT_PLACEMENT_TAGS_IN : "SELECT_PLACEMENT_TAGS_IN",
  SELECT_PLACEMENT_TAGS_EX : "SELECT_PLACEMENT_TAGS_EX",
  SELECT_ORGANIZATION_TAGS_IN : "SELECT_ORGANIZATION_TAGS_IN",
  SELECT_ORGANIZATION_TAGS_EX : "SELECT_ORGANIZATION_TAGS_EX",
  SELECT_CSM: "SELECT_CSM",
  SELECT_AUTHOR: "SELECT_AUTHOR",
  SELECT_COMPANY_NAME: "SELECT_COMPANY_NAME",
  SELECT_ACCOUNT_INTERESTED_BY_KEYWORD: "SELECT_ACCOUNT_INTERESTED_BY_KEYWORD",
  SELECT_PARENT_COMPANY: "SELECT_PARENT_COMPANY",
  SELECT_SEGMENTS: "SELECT_SEGMENTS",

  CLEAR_ORDERS: "CLEAR_ORDERS",
  CLEAR_CAMPAIGNS: "CLEAR_CAMPAIGNS",
  CLEAR_ORGANIZATIONS: "CLEAR_ORGANIZATIONS",
  CLEAR_AD_ORDERS: "CLEAR_AD_ORDERS",
  CLEAR_TAGS: "CLEAR_TAGS",
  CLEAR_ENG_ORGANIZATIONS: "CLEAR_ENG_ORGANIZATIONS",
  CLEAR_AD_UNIT: "CLEAR_AD_UNIT",
  CLEAR_COUNTRIES: "CLEAR_COUNTRIES",
  CLEAR_ENG_MEMBERS: "CLEAR_ENG_MEMBERS",
  CLEAR_SECTORS: "CLEAR_SECTORS",
  CLEAR_INDUSTRY_GROUPS: "CLEAR_INDUSTRY_GROUPS",
  CLEAR_INDUSTRIES : "CLEAR_INDUSTRIES",
  CLEAR_SUB_INDUSTRIES : "CLEAR_SUB_INDUSTRIES",
  CLEAR_ORGANIZATION_TYPES: "CLEAR_ORGANIZATION_TYPES",
  CLEAR_ORGANIZATION_SIZES: "CLEAR_ORGANIZATION_SIZES",
  CLEAR_ORGANIZATION_REVENUES: "CLEAR_ORGANIZATION_REVENUES",
  CLEAR_SENORITY_IN : "CLEAR_SENORITY_IN",
  CLEAR_SENORITY_EX : "CLEAR_SENORITY_EX",
  CLEAR_JOB_FUNCTION_IN : "CLEAR_JOB_FUNCTION_IN",
  CLEAR_JOB_FUNCTION_EX : "CLEAR_JOB_FUNCTION_EX",
  CLEAR_AUTHORS : "CLEAR_AUTHORS",
  CLEAR_NEWSLETTERS_AUTHORS : "CLEAR_NEWSLETTERS_AUTHORS",
  CLEAR_PAGE_TITLES : "CLEAR_PAGE_TITLES",
  CLEAR_UTM_SOURCES : "CLEAR_UTM_SOURCES",
  CLEAR_UTM_MEDIUMS : "CLEAR_UTM_MEDIUMS",
  CLEAR_UTM_CAMPAIGNS : "CLEAR_UTM_CAMPAIGNS",
  CLEAR_UTM_CONTENTS : "CLEAR_UTM_CONTENTS",
  CLEAR_UTM_TERMS : "CLEAR_UTM_TERMS",
  CLEAR_PRIMARY_TOPICS : "CLEAR_PRIMARY_TOPICS",
  CLEAR_TOPIC_TAGS_IN : "CLEAR_TOPIC_TAGS_IN",
  CLEAR_TOPIC_TAGS_EX : "CLEAR_TOPIC_TAGS_EX",
  CLEAR_PLACEMENT_TAGS_IN : "CLEAR_PLACEMENT_TAGS_IN",
  CLEAR_PLACEMENT_TAGS_EX : "CLEAR_PLACEMENT_TAGS_EX",
  CLEAR_ORGANIZATION_TAGS_IN : "CLEAR_ORGANIZATION_TAGS_IN",
  CLEAR_ORGANIZATION_TAGS_EX : "CLEAR_ORGANIZATION_TAGS_EX",
  CLEAR_CSM: "CLEAR_CSM",
  CLEAR_AUTHOR: "CLEAR_AUTHOR",
  CLEAR_COMPANY_NAME: "CLEAR_COMPANY_NAME",
  CLEAR_ACCOUNT_INTERESTED_BY_KEYWORD: "CLEAR_ACCOUNT_INTERESTED_BY_KEYWORD",
  CLEAR_PARENT_COMPANY: "CLEAR_PARENT_COMPANY",
  CLEAR_SEGMENTS: "CLEAR_SEGMENTS",

  RESET_ORDERS: "RESET_ORDERS",
  RESET_CAMPAIGNS: "RESET_CAMPAIGNS",
  RESET_ORGANIZATIONS: "RESET_ORGANIZATIONS",
  RESET_AD_ORDERS: "RESET_AD_ORDERS",
  RESET_TAGS: "RESET_TAGS",
  RESET_ENG_ORGANIZATIONS: "RESET_ENG_ORGANIZATIONS",
  RESET_AD_UNIT: "RESET_AD_UNIT",
  RESET_COUNTRIES: "RESET_COUNTRIES",
  RESET_ENG_MEMBERS: "RESET_ENG_MEMBERS",
  RESET_SECTORS: "RESET_SECTORS",
  RESET_INDUSTRY_GROUPS: "RESET_INDUSTRY_GROUPS",
  RESET_INDUSTRIES : "RESET_INDUSTRIES",
  RESET_SUB_INDUSTRIES : "RESET_SUB_INDUSTRIES",
  RESET_ORGANIZATION_TYPES: "RESET_ORGANIZATION_TYPES",
  RESET_ORGANIZATION_SIZES: "RESET_ORGANIZATION_SIZES",
  RESET_ORGANIZATION_REVENUES: "RESET_ORGANIZATION_REVENUES",
  RESET_SENORITY_IN : "RESET_SENORITY_IN",
  RESET_SENORITY_EX : "RESET_SENORITY_EX",
  RESET_JOB_FUNCTION_IN : "RESET_JOB_FUNCTION_IN",
  RESET_JOB_FUNCTION_EX : "RESET_JOB_FUNCTION_EX",
  RESET_AUTHORS : "RESET_AUTHORS",
  RESET_NEWSLETTERS_AUTHORS : "RESET_NEWSLETTERS_AUTHORS",
  RESET_PAGE_TITLES : "RESET_PAGE_TITLES",
  RESET_UTM_SOURCES : "RESET_UTM_SOURCES",
  RESET_UTM_MEDIUMS : "RESET_UTM_MEDIUMS",
  RESET_UTM_CAMPAIGNS : "RESET_UTM_CAMPAIGNS",
  RESET_UTM_CONTENTS : "RESET_UTM_CONTENTS",
  RESET_UTM_TERMS : "RESET_UTM_TERMS",
  RESET_PRIMARY_TOPICS : "RESET_PRIMARY_TOPICS",
  RESET_TOPIC_TAGS_IN : "RESET_TOPIC_TAGS_IN",
  RESET_TOPIC_TAGS_EX : "RESET_TOPIC_TAGS_EX",
  RESET_PLACEMENT_TAGS_IN : "RESET_PLACEMENT_TAGS_IN",
  RESET_PLACEMENT_TAGS_EX : "RESET_PLACEMENT_TAGS_EX",
  RESET_ORGANIZATION_TAGS_IN : "RESET_ORGANIZATION_TAGS_IN",
  RESET_ORGANIZATION_TAGS_EX : "RESET_ORGANIZATION_TAGS_EX",
  RESET_CSM: "RESET_CSM",
  RESET_AUTHOR: "RESET_AUTHOR",
  RESET_COMPANY_NAME: "RESET_COMPANY_NAME",
  RESET_ACCOUNT_INTERESTED_BY_KEYWORD: "RESET_ACCOUNT_INTERESTED_BY_KEYWORD",
  RESET_PARENT_COMPANY: "RESET_PARENT_COMPANY",
  RESET_SEGMENTS: "RESET_SEGMENTS",

  RESET_SEARCH: "RESET_SEARCH",

  SELECTVALUE: "SELECTVALUE",
  CLEARCOMMONFILTER: "CLEARCOMMONFILTER",
  APPLY_FILTERS: "APPLY_FILTERS",

  CAMPAIGN_SUMMARY: "CAMPAIGN_SUMMARY",
  SHOW_MORE_CAMPAIGN: "SHOW_MORE_CAMPAIGN",

  CAMPAIGN_AD_DETAILS: "CAMPAIGN_AD_DETAILS",
  COLUMN_SELECTED: "COLUMN_SELECTED",
  SHOW_MORE_CAMPAIGN_IMPRESSIONS: "SHOW_MORE_CAMPAIGN_IMPRESSIONS",

  AD_DETAILS: "AD_DETAILS",
  AD_DETAILS_SHOW_MORE: "AD_DETAILS_SHOW_MORE",

  GET_DYNAMIC_TABLE_HEADER: "GET_DYNAMIC_TABLE_HEADER",
  GET_DYNAMIC_TABLE_HEADER_2: "GET_DYNAMIC_TABLE_HEADER_2",
  SEND_DYANMIC_TABLE_HEADER: "SEND_DYANMIC_TABLE_HEADER",
  SEND_DYANMIC_TABLE_HEADER_2: "SEND_DYANMIC_TABLE_HEADER_2",

  UPDATED_CHART_STATUS: "UPDATED_CHART_STATUS",
  DISPLAYING_CHARTS: "DISPLAYING_CHARTS",

  CAMPAIGN_SUMMARY_CSV: "CAMPAIGN_SUMMARY_CSV",
  CAMPAIGN_IMPRESSION_DETAIL_CSV: "CAMPAIGN_IMPRESSION_DETAIL_CSV",

  AD_CAMPAIGN_XLSX: "AD_CAMPAIGN_XLSX",
  AUTHOR_SUMMARY_CSV: "AUTHOR_SUMMARY_CSV",

  FETCH_INITIAL_DATA: "FETCH_INITIAL_DATA",
  RESET_ALL_DATA: "RESET_ALL_DATA",

  ORDER_SUMMARY: "ORDER_SUMMARY",
  SHOW_MORE_ORDER_SUMMARY: "SHOW_MORE_ORDER_SUMMARY",

  NEWSLETTERS_SUMMARY: "NEWSLETTERS_SUMMARY",
  NEWSLETTERS_SUMMARY_CSV: "NEWSLETTERS_SUMMARY_CSV",
  SHOW_MORE_NEWSLETTERS_SUMMARY: "SHOW_MORE_NEWSLETTERS_SUMMARY",

  NEWSLETTER_DETAILS: "NEWSLETTER_DETAILS",
  SHOW_MORE_NEWSLETTER_DETAILS: "SHOW_MORE_NEWSLETTER_DETAILS",
  GENERATE_NEWSLETTER_CSV: "GENERATE_NEWSLETTER_CSV",

  CONTENT_PERFORMANCE_SUMMARY_IPA: "CONTENT_PERFORMANCE_SUMMARY_IPA",
  CONTENT_PERFORMANCE_SUMMARY_SENORITY: "CONTENT_PERFORMANCE_SUMMARY_SENORITY",
  CONTENT_PERFORMANCE_SUMMARY_SECTOR: "CONTENT_PERFORMANCE_SUMMARY_SECTOR",
  CONTENT_PERFORMANCE_DETAILS: "CONTENT_PERFORMANCE_DETAILS",
  CONTENT_PERFORMANCE_DETAILS_SHOW_MORE: "CONTENT_PERFORMANCE_DETAILS_SHOW_MORE",

  AUTHOR_REPORT_SUMMARY: "AUTHOR_REPORT_SUMMARY",
  DESCISION_INSIGHTS_CSV: "DESCISION_INSIGHTS_CSV",
}

export const MODULE_DISPLAY_ADS = {
  ...COMMON_SEARCH
};

export const MODULE_CONTENT_BODY_ADS = {
  ...COMMON_SEARCH
};

export const MODULE_INTERSTITIAL_ADS = {
  ...COMMON_SEARCH
};

export const MODULE_NEWSLETTER_ADS = {
  ...COMMON_SEARCH
};

export const MODULE_ROADBLOCK_ADS = {
  ...COMMON_SEARCH
};

export const MODULE_TEXT_ADS = {
  ...COMMON_SEARCH,
};

export const MODULE_ANNOUNCEMENT_ADS = {
  ...COMMON_SEARCH
};

export const MODULE_ATF_HOMEPAGE = {
  ...COMMON_SEARCH
};

export const MODULE_INSTANT_MESSAGE = {
  ...COMMON_SEARCH
};

export const MODULE_NEWSLETTER_BODY = {
  ...COMMON_SEARCH
};

export const MODULE_ORDER_REPORT = {
  ...COMMON_SEARCH,
  GENERATE_ORDER_SUMMARY_CSV: "GENERATE_ORDER_SUMMARY_CSV",
};

export const MODULE_NEWSLETTERS = {
  ...COMMON_SEARCH
};

export const MODULE_ALL_ADS_REPORT = {
  ...COMMON_SEARCH
};

export const MODULE_CONTENT_PERFORMANCE = {
  ...COMMON_SEARCH
};

export const MODULE_AUTHOR_REPORT = {
  ...COMMON_SEARCH
};

export const MODULE_DI_EARNED_MEDIA = {
  ...COMMON_SEARCH,
  GET_DI_EARNED_MEDIA: "GET_DI_EARNED_MEDIA",
}

export const MODULE_DI_BASIC = {
  ...COMMON_SEARCH,
  GET_DI_BASIC: "GET_DI_BASIC",
}

export const MODULE_DI_PRO = {
  ...COMMON_SEARCH,
  GET_DI_PRO: "GET_DI_PRO",
}

export const MODULE_DI_UPGRADE = {
  ...COMMON_SEARCH,
  GET_DI_UPGRADE: "GET_DI_UPGRADE",
}

export const MODULE_SIDEBAR_ADS = {
  ...COMMON_SEARCH
}

export const ALL_MODULES = [
  constName.MODULE_DISPLAY_ADS,
  constName.MODULE_CONTENT_BODY_ADS,
  constName.MODULE_INTERSTITIAL_ADS,
  constName.MODULE_NEWSLETTER_ADS,
  constName.MODULE_ROADBLOCK_ADS,
  constName.MODULE_TEXT_ADS,
  constName.MODULE_ANNOUNCEMENT_ADS,
  constName.MODULE_ATF_HOMEPAGE,
  constName.MODULE_INSTANT_MESSAGE,
  constName.MODULE_NEWSLETTER_BODY,
  constName.MODULE_ORDER_REPORT,
  constName.MODULE_NEWSLETTERS,
  constName.MODULE_ALL_ADS_REPORT,
  constName.MODULE_CONTENT_PERFORMANCE,
  constName.MODULE_AUTHOR_REPORT,
  constName.MODULE_DI_EARNED_MEDIA,
  constName.MODULE_DI_BASIC,
  constName.MODULE_DI_PRO,
  constName.MODULE_DI_UPGRADE,
  constName.MODULE_SIDEBAR_ADS
];

// productivity 
export const GET_PRODUCTIVITY_DATA = "GET_PRODUCTIVITY_DATA";
export const APPLY_PRODUCTIVITY_FILTER = "APPLY_PRODUCTIVITY_FILTER";