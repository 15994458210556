import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

const Link = (props) => {
	const { type, urlParam, refValue, domain="" } = props;
	const ipInfo = "https://ipinfo.io";
	let link;
	if(_.includes(["topic", "placement", "primary", "core"], type)) {
		if(domain) {
			link = `https://${domain}/tag/${urlParam}`
		} else {
			link = `${props.wpLink}/tag/${urlParam}`;
		}
	} else if (_.includes(["members", "orders", "sidebars", "campaigns"], type)) {
		link = `${props.wpLink}/admin/${type}/#/${urlParam}`;
	}else if(type === "ip_address") {
		link = `${ipInfo}/${urlParam}`;
	} else if (type === "client_link") {
		link = type
	} else if(type === "sidebar") {
		link = urlParam;
	} else if(type === 'orgs') {
		link = `${props.wpLink}/directory/${urlParam}`;
	} else if(_.includes(["newsletters", "groups"], type)) {
		link = `${props.wpLink}/admin/email-manager/#/${type}/${urlParam}`;
	} else {
		link = `${props.wpLink}/admin/${type}/#/${urlParam}`;
	}

	return (
		<a href={link} className="blogs_link" target="_blank" rel="noopener noreferrer">
			{refValue}
		</a>
	);
};
const mapStateToProps = (state) => {
	return {
		wpLink: state.links.wpLink,
	};
};

export default connect(mapStateToProps)(Link);
