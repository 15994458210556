import React from "react";
import ClientLeadDetailCampaign from "../campaign/clientLeadDetailCampaign";
import ClientLeadChangedLog from "./clientLeadChangedTab";
import { Tab } from "semantic-ui-react";

const ClientLeadDetailCampaignDetails = (props) => {
  const panes = [
    { menuItem: 'CAMPAIGN HIGHLIGHTS', render: () => <Tab.Pane> <ClientLeadDetailCampaign data= {props.data} /></Tab.Pane> },
    { menuItem: 'CHANGED LOG', render: () => <Tab.Pane>  <ClientLeadChangedLog data={props.data} isLeadClient={true}/></Tab.Pane> },
  ]

  return (
    <>
      <Tab style={{ marginTop: 20, paddingLeft:5 }} panes={panes} />
    </>
  );
};

export default ClientLeadDetailCampaignDetails;
