import React from "react";
import { List } from "semantic-ui-react";

const CampaignReport = (props) =>{
    let campaignData = props;
    return (
      <div className="main-div">
        <List>
          <List.Item className="drawer-value">
            <label className="filter-text"> {campaignData.label} </label>
            <List.Item className="drawer-text">{campaignData.object}</List.Item>
          </List.Item>
        </List>
      </div>
    );
  }


export default CampaignReport;
