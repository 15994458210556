import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    Loader,
    Message,
    Table,
} from "semantic-ui-react";
import {
    sortSponsoredContentCampaignPageDetail,
    clearScSort,
    showMoreSponsoredTableData,
    fetchPageviewTableData,
    sendTableHeaders,
    getTableHeaders,
} from "../../../actions";
import * as actionTypes from "../../../actions/actionTypes";
import _ from "lodash";
import { SCHeaderData } from "./HeaderData";
import GenericTableForExpandable from "../../common/GenericTableForExpandable";
import { columnConfigs } from "../../ads/common/ColumnConfig";
import DynamicHeaderColumnSelector from "../../common/DynamicHeaderColumnSelector";

const MainSCPageDetails = (props) => {
    const [selectHeaders, setSelectHeaders] = useState();
    const [finalHeaderData, setFinalHeaderData] = useState();
    const [headerColumn, setHeaderColumn] = useState([]); // eslint-disable-line
    const [isSortIcon, setSortIcon] = useState(false);

    let showMoreButton;
    let loading;

    useEffect(() => {
        props.getTableHeaders(
            "sponsored_page_details",
            actionTypes.GETSCPAGEDETAILSDYNAMICTABLEHEADER
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const headerDataVal = props.getDynamicHeaderData;
        if (!_.isEmpty(headerDataVal)) {
            setSelectHeaders(headerDataVal);
            setFinalHeaderData(headerDataVal);
        } else if (headerDataVal === undefined || _.size(headerDataVal) === 0) {
            const showFirstHeaderVal = { headerName: "", headerKey: "blank"}
            const constantHeadersArr = [showFirstHeaderVal, ...SCHeaderData]
            setSelectHeaders(constantHeadersArr);
            setFinalHeaderData(constantHeadersArr);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.getDynamicHeaderData]);

    useEffect(() => {
        const headerName = [];
        finalHeaderData &&
            finalHeaderData.map((name) => {
                headerName.push(name.headerName);
                return "";
            });
        setHeaderColumn(headerName);
    }, [finalHeaderData]);

    if (props.loading) {
        loading = (
            <div className="show-loader">
                <Loader active inline="centered" />
            </div>
        );
    }

    const clickOnSortHeader = (e, extraData, tableData) => {
        if (extraData.checked) {
            setSelectHeaders([...selectHeaders, { headerName: tableData.headerName, headerKey: tableData.headerKey }]);
        } else {
            const removeData = selectHeaders.filter(
                (hName) => hName.headerKey !== tableData.headerKey
            );
            setSelectHeaders(removeData);
        }
    };

    const applyButtonClick = () => {
        let finalHeaderArray = [];
        const showFirstHeaderVal = { headerName: "", headerKey: "blank" };
        const constantHeadersArr = [showFirstHeaderVal, ...SCHeaderData];

        constantHeadersArr.forEach((mainHeader) => {
            selectHeaders.forEach((selectHeader) => {
                if (mainHeader.headerKey === selectHeader.headerKey) {
                    finalHeaderArray.push(selectHeader);
                }
            });
        });
        setFinalHeaderData(finalHeaderArray);
        props.sendTableHeaders(
            "sponsored_page_details",
            finalHeaderArray,
            actionTypes.SENDSCSUMMARYDYANMICTABLEHEADER
        );
        setSortIcon(!isSortIcon);
    };

    return (
        <div>
            <div style={{ paddingBottom: "3em" }}>
                <DynamicHeaderColumnSelector
                    isSortIcon={isSortIcon}
                    clickOnSortIcon={() => setSortIcon(!isSortIcon)}
                    TableHeaderData={SCHeaderData}
                    selectHeaders={selectHeaders}
                    clickOnSortHeader={clickOnSortHeader}
                    applyButtonClick={applyButtonClick}
                />
            </div>
            <div className="sortable-table-component" style={{ paddingTop: "30px" }}>
                <Table sortable fixed singleLine>
                    <GenericTableForExpandable 
                        data={props?.scCampaignPageDetail}
                        headerOrder={finalHeaderData}
                        columnConfigs={columnConfigs}
                    />
                </Table>
            </div>
            {loading}
            {showMoreButton}
            {
                props.isFilApplied && !props.loading && _.isEmpty(props.scCampaignPageDetail) && 
                <Message>No results found.</Message>
            }
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        scCampaignPageDetail: state.sponsoredContentFetchData.scCampaignPageDetail,
        loading: state.sponsoredContentFetchData.isLoadingScCampaignPageDetail,
        isFilApplied: state.sponsoredContentFetchData.isFilApplied,
        getDynamicHeaderData: state.sponsoredContentFetchData.getDynamicPageDetailsHeaderData,
        sponsoredContentFetchData: state.sponsoredContentFetchData
    };
};

export default connect(mapStateToProps, {
    sortSponsoredContentCampaignPageDetail,
    clearScSort,
    showMoreSponsoredTableData,
    fetchPageviewTableData,
    sendTableHeaders,
    getTableHeaders,
})(MainSCPageDetails);
