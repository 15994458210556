import { 
  ORGANIZATION_TAGS, 
  PLACEMENT_TAGS, 
  SITES, TOPIC_TAGS, 
  TOTAL_PAGEVIEWS_BY_TAG, 
  UNIQUE_PAGEVIEWS_BY_TAG,
  AUTHOR_NAME,
  TOTAL_PAGEVIEWS_BY_AUTHOR,
  UNIQUE_PAGEVIEWS_BY_AUTHOR,
  PUBLISHED_BY_AUTHOR,
  PAGEVIEWS_PER_ARTICLE,
} from "../config/TableHeaderNames";

const createTableData = (headerName, sorted, name, options = {}) => {
    return [
      {
        headerName,
        sorted,
        name,
        ...options,
      },
      {
        headerName: SITES,
        sorted: "site",
        name: "Sites",
      },
      {
        headerName: TOTAL_PAGEVIEWS_BY_TAG,
        sorted: "page_views",
        className: "custom-column-extra-medium",
        name: "Total Pageviews by Tag",
        currentKey: "page_views",
        previousKey: "previous_period_pageviews",
        textAlign: "right",
      },
      {
        headerName: UNIQUE_PAGEVIEWS_BY_TAG,
        sorted: "unique_impressions_count",
        className: "custom-column-extra-medium",
        name: "Unique Pageviews by Tag",
        currentKey: "unique_impressions_count",
        previousKey: "previous_period_unique_pageviews",
        textAlign: "right",
      },
    ];
  };

  const createTableDataForAuthor = () => {
    return [
      {
        headerName: AUTHOR_NAME,
        sorted: "author_name",
        name: "Author Name",
        className: "custom-column-clicks",
      },
      {
        headerName: SITES,
        sorted: "display_domain",
        name: "Sites",
      },
      {
        headerName: TOTAL_PAGEVIEWS_BY_AUTHOR,
        sorted: "pageviews",
        className: "custom-column-extra-medium",
        name: "Total Pageviews by Author",
        currentKey: "pageviews",
        previousKey: "previous_period_pageviews",
        textAlign: "right",
      },
      {
        headerName: UNIQUE_PAGEVIEWS_BY_AUTHOR,
        sorted: "unique_pageviews",
        className: "custom-column-extra-medium",
        name: "Unique Pageviews by Author",
        currentKey: "unique_pageviews",
        previousKey: "previous_period_unique_pageviews",
        textAlign: "right",
      },
      {
        headerName: PUBLISHED_BY_AUTHOR,
        sorted: "published_articles",
        className: "custom-column-extra-medium",
        name: "Published By Author",
        currentKey: "published_articles",
        previousKey: "prev_published_articles",
        textAlign: "right",
      },
      {
        headerName: PAGEVIEWS_PER_ARTICLE,
        sorted: "average_pageviews_by_article",
        className: "custom-column-extra-medium",
        name: "Pageviews Per Article",
        currentKey: "average_pageviews_by_article",
        previousKey: "previous_period_average_pageviews_by_article",
        textAlign: "right",
      },
    ];
  };


  
  export const TopicTableData = createTableData(TOPIC_TAGS, "tag", "Topic Tag", { className: "custom-column-clicks" });
  export const OrganizationTableData = createTableData(ORGANIZATION_TAGS, "tag", "Organization Tag", { className: "custom-column-clicks" });
  export const PlacementTableData = createTableData(PLACEMENT_TAGS, "tag", "Placement Tag", { className: "custom-column-clicks" });
  export const AuthorTableData = createTableDataForAuthor();
  