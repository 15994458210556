import React from 'react'
import { connect } from 'react-redux';

import DateAndFilterSelection from "./DateAndFilterSection";
import AuthorSummary from './AuthorSummary';
import * as configName from "../config/const";
import DownloadCsvXlsx from "../../components/common/DownloadCsvXlsx";
import * as downloadHelper from "../../components/CommonFilter/downloadHelper";
import { fetchEditorialTableData } from "../../actions";
import { url } from '../../api/urlHelper';
import EmailPopUp from '../common/EmailPopUp';
import { Message } from 'semantic-ui-react';
import { AUTHOR_SUMMARY } from '../config/rolebasedReportNames';

const AuthorMain = (props) => {

    const onDownloadClick = () => {
        downloadHelper.contentAuthorSummaryCsvData(props, url.pageviewAuthorSummary, props.userEmail);
    }

    return (
        <div>
            <div className="page-name-comp">
                <p className="page-name">{props.label}</p>
                <p>{props.reportDescription}</p>
            </div>
            <DateAndFilterSelection {...props} />
            { 
                props.roleReports?.some((report) =>
                [AUTHOR_SUMMARY].includes(report.element) &&
                [configName.VIEW, configName.GENERATE_CSV].includes(report.permission)
                ) &&
                <div className="table_gap">
                    <div className="table-heading-block">
                        <div className="table-name">
                            <p>{`${props.label} Summary`}</p>
                        </div>
                            {props.filterData.isFilApplied && (props.roleReports?.some((report) => [AUTHOR_SUMMARY].includes(report.element) && report.permission === configName.GENERATE_CSV)) && 
                            <div className="class-relative">
                                <DownloadCsvXlsx
                                    onClick={onDownloadClick}
                                    label={configName.GENERATE_CSV}
                                    disabled={!props.filterData.isFilApplied}
                                />
                                <div className="pg popup">
                                    {
                                        props.filterData.AuthorEmailSummary &&
                                        <EmailPopUp
                                            email={props.filterData.AuthorEmailSummary}
                                            table="ads_common"
                                            cardPopup={props.filterData.AuthorEmailSummary}
                                            csvExcel={true}
                                            emailKey="AuthorEmailSummary"
                                            reportActionType={props.reportActionType}
                                        />
                                    }
                                </div>
                            </div>
                        }
                        </div>
                        {
                            (props.roleReports?.some((report) => [AUTHOR_SUMMARY].includes(report.element) && report.permission === configName.VIEW)) &&
                            <AuthorSummary
                                reportActionType={props.reportActionType}
                                reportName={props.reportName}
                                filterData={props.filterData}
                            />
                        }    
                </div>
            }         
            {!props.filterData.isFilApplied && <Message>Please proceed with applying filter.</Message>}
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        userEmail: state.authorized.userEmail,
    };
};

export default connect(mapStateToProps, {
    fetchEditorialTableData,
})(AuthorMain);