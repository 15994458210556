import React, { Component } from "react";
import Drawer from "@mui/material/Drawer";
import "../../css/common.css";

export default function HocDrawer(
  PlaceHolderComponent,
  DrawableComponent,
  title,
  data
) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        draw: false,
        title: title,
        data: data,
      };
    }
    toggle = () => {
      let newState = this.state;
      newState.draw = !newState.draw;
      this.setState({
        newState,
      });
    };

    render() {
      if (!this.state.draw) {
        return (
          <PlaceHolderComponent
            style={{ color: "grey" }}
            {...this.props}
            onClick={() => {
              this.toggle();
            }}
          ></PlaceHolderComponent>
        );
      } else {
        return (
          <Drawer
            hideBackdrop={true}
            open={this.state.draw}
            anchor="right"
            close={() => {
              this.toggle();
            }}
             //style={{ marginRight:'40rem', marginBottom: '20rem'}}
            key={this.state.data.uuid}
          >
            <div>
              <div>
                <div className="filter-header" style={{ marginTop: 20, paddingLeft:5 }}>
                  <div className="filter-text">
                    <p>{this.state.title}</p>
                  </div>
                  <div className="filter-close-icon">
                    <i
                      className="material-icons grey"
                      onClick={() => {
                        this.toggle();
                      }}
                    >
                      clear
                    </i>
                  </div>
                </div>
              </div>
              <div>
                <DrawableComponent data={this.state.data} {...this.props} />
              </div>
            </div>
          </Drawer>
        );
      }
    }
  };
}
