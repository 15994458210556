import React from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import './index.css'
import 'semantic-ui-css/semantic.min.css'
import App from './App';
import reducers from './reducers';
import { thunk } from 'redux-thunk';
import { BrowserRouter as Router } from 'react-router-dom';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

const app =(
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(app);