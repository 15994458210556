import AccountComponent from "./TableCellCustomComponent/AccountComponent";
import OrderComponent from "./TableCellCustomComponent/OrderComponent";
import CampaignComponent from "./TableCellCustomComponent/CampaignComponent";
import PaceComponent from "./PaceComponent";
import { capitalizeFirstLetter, getAttachedPercentage, getDisplayDate, impressionNumFormat, numFormat } from "../../../utilities/common";
import OrderAdDetailsComponent from "./TableCellCustomComponent/OrderAdDetailsComponent";
import CampaignAdDetailsComponent from "./TableCellCustomComponent/CampaignAdDetailsComponent";
import AdOrderComponent from "./TableCellCustomComponent/AdOrderComponent";
import TimeComponent from "./TableCellCustomComponent/TimeComponent";
import DateComp from "./TableCellCustomComponent/DateComp";
import IpAddressComponent from "./TableCellCustomComponent/IpAddressComponent";
import PageTitleComponent from "./TableCellCustomComponent/PageTitleComponent";
import SponsoredPageTitleComponent from "./TableCellCustomComponent/SponsoredPageTitleComponent";
import PrimaryTopicComponent from "./TableCellCustomComponent/PrimaryTopicComponent";
import TopicTagsComp from "./TableCellCustomComponent/TopicTagsComp";
import OrgTagsComp from "./TableCellCustomComponent/OrgTagsComp";
import PlacementTagComp from "./TableCellCustomComponent/PlacementTagComp";
import OrgComp from "./TableCellCustomComponent/OrgComp";
import FirstNameComponent from "./TableCellCustomComponent/FirstNameComponent";
import LastNameComponent from "./TableCellCustomComponent/LastNameComponent";
import SortIconComponent from "./TableCellCustomComponent/SortIconComponent";
import NewsletterDateComponent from "./TableCellCustomComponent/NewsletterDateComponent";
import NewsletterNameComponent from "./TableCellCustomComponent/NewsletterNameComponent";
import NewsletterTimeComponent from "./TableCellCustomComponent/NewsletterTimeComponent";
import NewsletterTemplateComponent from "./TableCellCustomComponent/NewsletterTemplateComponent";
import NewsletterSlotItemComponent from "./TableCellCustomComponent/NewsletterSlotItemComponent";
import CSMComponent from "./TableCellCustomComponent/CSMComponent";
import AccountIntreseedByKeywordsComponent from "./TableCellCustomComponent/AccountIntreseedByKeywordsComponent";
import LinkComponent from "./TableCellCustomComponent/LinkComponent";
import AgencyComponent from "./TableCellCustomComponent/AgencyComponent";
import PrimaryTopicSCComponent from "./TableCellCustomComponent/PrimaryTopicSCComponent";
import AccountOrderComponent from "./TableCellCustomComponent/AccountOrderComponent";
import DIPopulateFilterComponent from "./TableCellCustomComponent/DIPopulateFilterComponent";

export const columnConfigs = {
    // summary table 
    agency_name: {
        header: "Agency",
        sortable: true,
        sortBy: "agency_name",
    },
    account: {
        header: "Account",
        sortable: true,
        sortBy: "account",
        render: value => value,
        component: AccountComponent,
    },
    order: {
        header: "Order",
        sortable: true,
        sortBy: "Order.name",
        render: value => value,
        component: OrderComponent,
    },
    campaign: {
        header: "Campaign",
        sortable: false,
        sortBy: "name",
        className: "custom-column-large",
        render: value => value,
        component: CampaignComponent,
    },
    csm: {
        header: "CSM",
        sortable: true,
        sortBy: "client_success_manager.name",
        render: value => value,
        component: CSMComponent,
    },
    start_date: {
        header: "Start Date",
        sortable: true,
        sortBy: "start_date",
        render: value => getDisplayDate(value),
    },
    end_date: {
        header: "End Date",
        sortable: true,
        sortBy: "end_date",
        render: value => getDisplayDate(value)
    },
    status: {
        header: "Campaign Status",
        sortable: true,
        className: "custom-column-medium",
        sortBy: "status",
        render: value => capitalizeFirstLetter(value),
    },
    delivery_state: {
        header: "Delivery Status",
        sortable: true,
        sortBy: "delivery_state",
        render: value => capitalizeFirstLetter(value),
    },
    total_impressions_to_be_delivered: {
        header: "Total Impressions Purchased",
        sortable: true,
        textAlign: "right",
        sortBy: "total_impressions_to_be_delivered",
        className: "total-impressions",
        render: value => impressionNumFormat(value),
    },
    impressions: {
        header: "Total Impressions Delivered",
        sortable: true,
        textAlign: "right",
        sortBy: "impressions",
        className: "impressions",
        render: value => numFormat(value)
    },
    impressions_outstanding: {
        header: "Impressions Outstanding",
        sortable: true,
        textAlign: "right",
        sortBy: "impressions_outstanding",
        className: "impressions",
        render: value => numFormat(value)
    },
    clicks: {
        header: "Clicks",
        sortable: true,
        textAlign: "right",
        sortBy: "clicks",
        className: "clicks",
        render: value => numFormat(value)
    },
    ctr: {
        header: "Click Through Rate",
        sortable: true,
        textAlign: "right",
        sortBy: "ctr",
        render: value => getAttachedPercentage(value)
    },
    percentage_served_to_date: {
        header: "Percent Delivered",
        sortable: true,
        textAlign: "right",
        sortBy: "percentage_served_to_date",
        className: "percentage-served",
        render: value => getAttachedPercentage(value)
    },
    percent_complete: {
        header: "Percent Complete",
        sortable: true,
        textAlign: "right",
        sortBy: "percent_complete",
        render: value => getAttachedPercentage(value)
    },
    pace: {
        header: "Pace",
        sortable: false,
        textAlign: "right",
        className: "impressions",
        sortBy: "pace",
        render: value => value,
        component: PaceComponent,
    },

    // ads details
    order_detail: {
        header: "Order",
        sortable: true,
        sortBy: "campaign_data.0.order.name",
        render: value => value,
        className: "custom-column-large",
        component: OrderAdDetailsComponent,
    },
    campaign_detail: {
        header: "Campaign",
        sortable: true,
        sortBy: "campaign_name",
        className: "custom-column-large",
        render: value => value,
        component: CampaignAdDetailsComponent,
    },


    ad_order: {
        header: "Ad Order",
        sortable: true,
        sortBy: "ad_order",
        render: value => value,
        component: AdOrderComponent,
    },
    date: {
        header: "Date",
        sortable: true,
        sortBy: "created_at",
        render: value => value,
        component: DateComp,
    },
    time: {
        header: "Time",
        sortable: true,
        sortBy: "created_at",
        render: value => value,
        component: TimeComponent,
    },
    session_id: {
        header: "Session ID",
        sortable: true,
        sortBy: "session_id",
        className: "custom-column-large",
        render: value => value,
    },
    group_uuid: {
        header: "Group ID",
        sortable: true,
        sortBy: "group_uuid",
        className: "custom-column-large",
        render: value => value,
    },
    line_item: {
        header: "Line Item Name",
        sortable: true,
        sortBy: "line_item",
        render: value => value,
    },
    creative: {
        header: "Creative Name",
        sortable: true,
        sortBy: "creative",
        render: value => value,
    },
    creative_size: {
        header: "Creative Size",
        sortable: true,
        sortBy: "creative_size",
        render: value => value,
    },
    ad_unit: {
        header: "Ad Unit",
        sortable: true,
        sortBy: "ad_unit",
        render: value => value,
    },
    media_type: {
        header: "Media Type",
        sortable: true,
        sortBy: "media_type",
        render: value => value,
    },
    action: {
        header: "Action",
        sortable: true,
        sortBy: "action",
        render: value => value,
    },
    ip_address: {
        header: "IP Address",
        sortable: true,
        sortBy: "ip_address",
        className: "custom-column-large",
        render: value => value,
        component: IpAddressComponent,
    },
    page_title: {
        header: "Page Title",
        sortable: true,
        sortBy: "page_title",
        className: "custom-column-xlarge",
        render: value => value,
        component: PageTitleComponent,
    },
    sponsored_page_title: {
        header: "Page Title",
        sortable: false,
        sortBy: "page_title",
        className: "custom-column-xlarge",
        render: value => value,
        component: SponsoredPageTitleComponent,
    },
    primary_topic: {
        header: "Primary Topic",
        sortable: true,
        sortBy: "primary_topic",
        className: "custom-column-large",
        render: value => value,
        component: PrimaryTopicComponent,
    },
    topic_tags: {
        header: "Topic Tags",
        sortable: true,
        sortBy: "topic_tags",
        className: "custom-column-large",
        render: value => value,
        component: TopicTagsComp,
    },
    organization_tags: {
        header: "Organization Tags",
        sortable: true,
        sortBy: "organization_tags",
        className: "custom-column-large",
        render: value => value,
        component: OrgTagsComp,
    },
    placement_tags: {
        header: "Placement Tags",
        sortable: true,
        sortBy: "placement_tags",
        className: "custom-column-large",
        render: value => value,
        component: PlacementTagComp,
    },
    organization: {
        header: "Organization",
        sortable: true,
        sortBy: "organization",
        render: value => value,
        component: OrgComp,
    },
    organization_type: {
        header: "Organization Type",
        sortable: true,
        sortBy: "organization_type",
        render: value => value,
    },
    estimated_revenue: {
        header: "Organization Revenue",
        sortable: true,
        sortBy: "estimated_revenue",
        className: "right aligned impressions",
        textAlign: "right",
        render: value => value,
    },
    size: {
        header: "Organization Size",
        sortable: true,
        sortBy: "size",
        className: "right aligned impressions",
        textAlign: "right",
        render: value => numFormat(value),
    },
    sector: {
        header: "Sector",
        sortable: true,
        sortBy: "sector",
        render: value => value,
    },
    industry_group: {
        header: "Industry Group",
        sortable: true,
        sortBy: "industry_group",
        render: value => value,
    },
    industry: {
        header: "Industry",
        sortable: true,
        sortBy: "industry",
        render: value => value,
    },
    sub_industry: {
        header: "Sub Industry",
        sortable: true,
        sortBy: "sub_industry",
        render: value => value,
    },
    first_name: {
        header: "First Name",
        sortable: true,
        sortBy: "first_name",
        render: value => value,
        component: FirstNameComponent,
    },
    last_name: {
        header: "Last Name",
        sortable: true,
        sortBy: "last_name",
        render: value => value,
        component: LastNameComponent,
    },
    seniority: {
        header: "Member Seniority",
        sortable: true,
        sortBy: "seniority",
        render: value => value,
    },
    job_function: {
        header: "Job Function",
        sortable: true,
        sortBy: "job_function",
        render: value => value,
    },
    job_title: {
        header: "Job Title",
        sortable: true,
        sortBy: "job_title",
        render: value => value,
    },
    city: {
        header: "City",
        sortable: true,
        sortBy: "city",
        render: value => value,
    },
    state: {
        header: "State/Province",
        sortable: true,
        sortBy: "state",
        render: value => value,
    },
    country: {
        header: "Country",
        sortable: true,
        sortBy: "country",
        render: value => value,
    },
    postal_code: {
        header: "Postal Code/Zip",
        sortable: true,
        textAlign: "right",
        sortBy: "postal_code",
        render: value => value,
    },
    timezone: {
        header: "Timezone",
        sortable: true,
        sortBy: "timezone",
        render: value => value,
    },
    traffic_source: {
        header: "Traffic Source",
        sortable: true,
        sortBy: "traffic_source",
        render: value => value,
    },
    source: {
        header: "UTM Source",
        sortable: true,
        sortBy: "source",
        render: value => value,
    },
    utm_medium: {
        header: "UTM Medium",
        sortable: true,
        sortBy: "utm_medium",
        render: value => value,
    },
    utm_campaign: {
        header: "UTM Campaign",
        sortable: true,
        sortBy: "utm_campaign",
        render: value => value,
    },
    utm_term: {
        header: "UTM Term",
        sortable: true,
        sortBy: "utm_term",
        render: value => value,
    },
    utm_content: {
        header: "UTM Content",
        sortable: true,
        sortBy: "utm_content",
        render: value => value,
    },
    email_sent: {
        sortable: true,
        sortBy: "email_sent",
        render: value => value,
    },
    emails_sent: {
        sortable: true,
        sortBy: "emails_sent",
        render: value => value,
    },
    unique_page_impressions: {
        sortable: true,
        textAlign: "right",
        sortBy: "unique_page_impressions",
        className: "impressions",
        render: value => numFormat(value)
    },
    sort_roadblock_icon: {
        sortable: false,
        sortBy: "",
        className: "icon",
        render: value => value,
        component: SortIconComponent
    },

    // newsletters summary table
    type: {
        header: "Newsletter Type",
        sortable: false,
        sortBy: "type",
        render: value => value,
    },
    template: {
        header: "Newsletter Template",
        className: "custom-column-large",
        sortable: true,
        sortBy: "template",
        render: value => value,
        component: NewsletterTemplateComponent
    },
    newsletter_title: {
        header: "Newsletter Name",
        className: "custom-column-large",
        sortable: true,
        sortBy: "newsletter_title",
        render: value => value,
        component: NewsletterNameComponent
    },
    date_newsletter: {
        header: "Newsletter Send Date",
        sortable: true,
        sortBy: "date",
        render: value => value,
        component: NewsletterDateComponent
    },
    time_newsletter: {
        header: "Newsletter Send Time",
        sortable: true,
        sortBy: "time",
        render: value => value,
        component: NewsletterTimeComponent
    },
    subject: {
        header: "Subject Line",
        className: "custom-column-large",
        sortable: true,
        sortBy: "subject",
        render: value => value,
    },
    sends: {
        header: "Emails Sent",
        sortable: true,
        sortBy: "sends",
        textAlign: "right",
        render: value => numFormat(value),
    },
    email_address: {
        header: "Email Address",
        sortable: true,
        sortBy: "email_address",
        render: value => value,
    },
    bounced_emails: {
        header: "Bounced Emails",
        sortable: true,
        sortBy: "bounced_emails",
        textAlign: "right",
        render: value => numFormat(value),
    },
    blocked_emails: {
        header: "Blocked Emails",
        sortable: true,
        sortBy: "blocked_emails",
        textAlign: "right",
        render: value => numFormat(value),
    },
    spam_reports: {
        header: "Reported as Spam Email",
        sortable: true,
        sortBy: "spam_reports",
        textAlign: "right",
        render: value => numFormat(value),
    },
    emails_delivered: {
        header: "Emails Delivered",
        sortable: true,
        sortBy: "emails_delivered",
        textAlign: "right",
        render: value => numFormat(value),
    },
    opens: {
        header: "Opens",
        sortable: true,
        sortBy: "opens",
        textAlign: "right",
        render: value => numFormat(value),
    },
    unique_opens: {
        header: "Unique Opens",
        sortable: true,
        sortBy: "unique_opens",
        textAlign: "right",
        render: value => numFormat(value),
    },
    open_rate: {
        header: "Open Rate",
        sortable: true,
        sortBy: "open_rate",
        textAlign: "right",
        render: value => getAttachedPercentage(value),
    },
    unique_open_rate: {
        header: "Unique Open Rate",
        sortable: true,
        sortBy: "unique_open_rate",
        textAlign: "right",
        render: value => getAttachedPercentage(value),
    },
    slot_no: {
        header: "Slot No.",
        sortable: true,
        sortBy: "slot_no",
        textAlign: "right",
        render: value => value,
    },
    sub_slot_name: {
        header: "Sub Slot Name",
        sortable: true,
        sortBy: "sub_slot_name",
        render: value => value,
    },
    id: {
        header: "Layout Row",
        sortable: false,
        textAlign: "right",
        render: value => value,
    },
    slot: {
        header: "Slot",
        sortable: false,
        textAlign: "right",
        render: value => value,
    },
    slot_item: {
        header: "Slot Item",
        sortable: false,
        className: "custom-column-large",
        render: value => value,
        component: NewsletterSlotItemComponent
    },
    unique_clicks: {
        header: "Unique Clicks",
        sortable: true,
        sortBy: "unique_clicks",
        textAlign: "right",
        render: value => numFormat(value),
    },
    day_of_week: {
        header: "Day Of Week",
        sortable: true,
        sortBy: "day_of_week",
        render: value => value?.trim() ?? '',
    },
    unique_ctr: {
        header: "Unique Click Rate",
        sortable: true,
        textAlign: "right",
        sortBy: "unique_ctr",
        render: value => getAttachedPercentage(value)
    },

    //content performance columns
    name: {
        header: "Name",
        sortable: true,
        sortBy: "name",
        render: value => value
    },
    pageviews: {
        header: "Value",
        sortable: true,
        sum: true,
        textAlign: "right",
        sortBy: "pageviews",
        render: value => numFormat(value)
    },
    pageviews_percent: {
        header: "Value",
        sortable: true,
        textAlign: "right",
        sortBy: "pageviews_percent",
        render: value => value
    },
    unique_pageviews: {
        header: "Value",
        sortable: true,
        sum: true,
        textAlign: "right",
        sortBy: "unique_pageviews",
        render: value => numFormat(value)
    },
    unique_pageviews_percent: {
        header: "Value",
        sortable: true,
        textAlign: "right",
        sortBy: "unique_pageviews_percent",
        render: value => value
    },
    org_sector: {
        header: "Secotor",
        sortable: true,
        sortBy: "org_sector",
        render: value => value
    },
    author: {
        header: "Author",
        sortable: true,
        sortBy: "author",
        render: value => value
    },
    published_date: {
        header: "Publish Date",
        sortable: true,
        sortBy: "published_date",
        render: value => getDisplayDate(value)
    },
    days_since_published: {
        header: "Day Since Published",
        sortable: true,
        sortBy: "days_since_published",
        render: value => value
    },
    pageviews_goal: {
        header: "Pageview Goal",
        sortable: true,
        sortBy: "pageviews_goal",
        textAlign: "right",
        render: value => numFormat(value)
    },
    pageviews_total: {
        header: "Pageviews Total",
        sortable: true,
        sortBy: "pageviews_total",
        textAlign: "right",
        render: value => numFormat(value)
    },
    pageviews_range: {
        header: "Pageviews Range",
        sortable: true,
        sortBy: "pageviews_range",
        textAlign: "right",
        render: value => numFormat(value)
    },
    pageview_goal_achieved: {
        header: "% Goal Achieved",
        sortable: true,
        textAlign: "right",
        sortBy: "pageview_goal_achieved",
        render: value => numFormat(value)
    },
    
    //Author summary columns
    author_name: {
        header: "Author",
        sortable: true,
        sortBy: "author_name",
        render: value => value
    },
    display_domain: {
        header: "Site",
        sortable: true,
        sortBy: "display_domain",
        render: value => value
    },
    published_articles: {
        header: "Value",
        sortable: true,
        sum: true,
        textAlign: "right",
        sortBy: "published_articles",
        render: value => numFormat(value)
    },
    average_pageviews_by_article: {
        header: "Value",
        sortable: true,
        sum: true,
        textAlign: "right",
        sortBy: "average_pageviews_by_article",
        render: value => numFormat(value)
    },
    previous_period_pageviews: {
        header: "Value",
        sortable: true,
        sum: true,
        textAlign: "center",
        sortBy: "previous_period_pageviews",
        render: value => numFormat(value)
    },
    previous_period_unique_pageviews: {
        header: "Value",
        sortable: true,
        sum: true,
        textAlign: "center",
        sortBy: "previous_period_unique_pageviews",
        render: value => numFormat(value)
    },
    prev_published_articles: {
        header: "Value",
        sortable: true,
        sum: true,
        textAlign: "center",
        sortBy: "prev_published_articles",
        render: value => numFormat(value)
    },
    previous_period_average_pageviews_by_article: {
        header: "Value",
        sortable: true,
        sum: true,
        textAlign: "center",
        sortBy: "previous_period_average_pageviews_by_article",
        render: value => numFormat(value)
    },

    // Dicision Insights headers
    company_name: {
        header: "Company Name",
        sortable: true,
        sortBy: "company_name",
        keyMappings: {label: "company_name", value: "uuid", type: "organizations"}, 
        render: value => value,
        component: LinkComponent
    },
    domain_name: {
        header: "Domain Name",
        sortable: true,
        sortBy: "domain_name",
        render: value => value
    },
    parent: {
        header: "Parent",
        sortable: true,
        sortBy: "parent_org.name",
        keyMappings: {label: "parent_org.name", value: "parent_org.uuid", type: "organizations"}, 
        render: value => value,
        className: "custom-column-medium",
        component: LinkComponent
    },
    parent_primary_domain_name: {
        header: "Parent Primary Domain",
        sortable: true,
        sortBy: "parent_primary_domain_name",
        render: value => value
    },
    organization_size: {
        header: "Organization Size",
        sortable: true,
        sortBy: "organization_size",
        render: value => value,
    },
    organization_revenue: {
        header: "Organization Revenue",
        sortable: true,
        sortBy: "revenue_sorting_key",
        render: value => value,
    },
    organization_employee: {
        header: "Organization Employee",
        sortable: true,
        sortBy: "organization_employee",
        textAlign: "right",
        render: value => numFormat(value),
    },
    location: {
        header: "Location",
        sortable: true,
        sortBy: "location_sorting_key",
        textAlign: "right",
        render: value => value,
    },
    sic: {
        header: "SIC",
        sortable: true,
        sortBy: "sic",
        textAlign: "right",
        render: value => value,
    },
    naics: {
        header: "NAICS",
        sortable: true,
        sortBy: "naics",
        textAlign: "right",
        render: value => value,
    },
    ticker_symbol: {
        header: "Ticker Symbol",
        sortable: true,
        sortBy: "ticker_symbol",
        render: value => value,
    },
    fiscal_year_end: {
        header: "Fiscal Year End",
        sortable: true,
        sortBy: "fiscal_year_end",
        textAlign: "right",
        render: value => value,
    },

    headquarter_country: {
        header: "Headquarters Country",
        sortable: true,
        sortBy: "headquarter_country",
        render: value => value,
    },
    headquarter_region: {
        header: "Headquarters State",
        sortable: true,
        sortBy: "headquarter_region",
        render: value => value,
    },
    headquarter_city: {
        header: "Headquarters City",
        sortable: true,
        sortBy: "headquarter_city",
        render: value => value,
    },

    // DI earned media
    // editorial_and_marketplace_views: {
    //     header: "Editorial Views",
    //     sortable: true,
    //     sortBy: "editorial_and_marketplace_views",
    //     keyMappings: { label: "EDITORIAL_MARKETPLACE_VIEWS_EX_SDX", value: "editorial_and_marketplace_views" }, 
    //     render: value => value,
    //     component: DIPopulateFilterComponent
    // },
    marketplace_views: {
        header: "Marketplace Views",
        sortable: true,
        sortBy: "marketplace_views",
        keyMappings: { label: "MARKETPLACE_VIEWS_EX_SDX", value: "marketplace_views" }, 
        render: value => value,
        textAlign: "right",
        component: DIPopulateFilterComponent
    },
    editorial_views: {
        header: "Editorial",
        sortable: true,
        sortBy: "editorial_views",
        keyMappings: { label: "EDITORIAL_MARKETPLACE_VIEWS_EX_SDX", value: "editorial_views" }, 
        render: value => value,
        textAlign: "right",
        component: DIPopulateFilterComponent
    },
    mentions_views: {
        header: "Mentions View",
        sortable: true,
        sortBy: "mentions_views",
        keyMappings: { label: "MENTIONS_VIEWS_EX_SDX", value: "mentions_views" }, 
        render: value => value,
        textAlign: "right",
        component: DIPopulateFilterComponent
    },
    stringer_ai_announcement_views: {
        header: "stringerAI Announcement Views",
        sortable: true,
        sortBy: "stringer_ai_announcement_views",
        keyMappings: { label: "STRINGER_AI_ANNOUNCEMENT_VIEWS_IN_SDX", value: "stringer_ai_announcement_views" }, 
        render: value => value,
        textAlign: "right",
        component: DIPopulateFilterComponent
    },
    stringer_ai_pr_views: {
        header: "stringerAI PR Views",
        sortable: true,
        sortBy: "stringer_ai_pr_views",
        keyMappings: { label: "STRINGER_AI_PR_VIEWS_IN_SDX", value: "stringer_ai_pr_views" }, 
        render: value => value,
        textAlign: "right",
        component: DIPopulateFilterComponent
    },
    stringer_ai_security_alert_views: {
        header: "stringerAI Security Alert Views",
        sortable: true,
        sortBy: "stringer_ai_security_alert_views",
        keyMappings: { label: "STRINGER_AI_SECURITY_ALERT_VIEWS_IN_SDX", value: "stringer_ai_security_alert_views" }, 
        render: value => value,
        textAlign: "right",
        component: DIPopulateFilterComponent
    },
    sponsored_content_views: {
        header: "Sponsored Content Views",
        sortable: true,
        sortBy: "sponsored_content_views",
        template: true,
        keyMappings: { label: "SPONSORED_CONTENT_VIEWS_IN_SDX", value: "sponsored_content_views" }, 
        render: value => value,
        textAlign: "right",
        component: DIPopulateFilterComponent
    },

    // DI Basic
    asset_downloads: {
        header: "Asset Download",
        sortable: true,
        sortBy: "asset_downloads",
        textAlign: "right",
        keyMappings: { label: "ASSET_DOWNLOAD_SDX", value: "asset_downloads" }, 
        render: value => value,
        component: DIPopulateFilterComponent
    },
    digital_event_webinar_registrations: {
        header: "Digital Event / Webinar Registrations",
        sortable: true,
        sortBy: "digital_event_webinar_registrations",
        keyMappings: { label: "DEGITAL_EVENT_VIEWS_IN_SDX", value: "digital_event_webinar_registrations" }, 
        render: value => value,
        component: DIPopulateFilterComponent
    },
    video_views: {
        header: "Video Views",
        sortable: true,
        sortBy: "video_views",
        textAlign: "right",
        keyMappings: { label: "VIDEO_VIEWS_SDX", value: "video_views" }, 
        render: value => value,
        component: DIPopulateFilterComponent
    },
    ad_views: {
        header: "Ad Views",
        sortable: true,
        sortBy: "ad_views",
        textAlign: "right",
        keyMappings: { label: "AD_VIEWS_SDX", value: "ad_views" }, 
        render: value => value,
        component: DIPopulateFilterComponent
    },
    ad_clicks: {
        header: "Ad Clicks",
        sortable: true,
        sortBy: "ad_clicks",
        textAlign: "right",
        keyMappings: { label: "AD_CLICKS_SDX", value: "ad_clicks" }, 
        render: value => value,
        component: DIPopulateFilterComponent
    },
    account_interest_by_keywords: {
        header: "Account Interest by your Keywords",
        sortable: false,
        render: value => value,
        className: 'custom-column-large',
        component: AccountIntreseedByKeywordsComponent,
    },
    account_activity_trend: {
        header: "Account Activity Trend",
        sortable: true,
        sortBy: "account_activity_trend_sorting_key",
        textAlign: "right",
        render: value => value,
    },
    account_activity_level: {
        header: "Account Activity Level",
        sortable: true,
        sortBy: "account_activity_level_sorting_key",
        render: value => value,
    },
    competitor_influence: {
        header: "Competitor Influence",
        sortable: true,
        sortBy: "competitor_influence",
        render: value => value,
    },

    //pro
    activity_trend_for_leaders: {
        header: "Activity Trend for Leaders",
        sortable: true,
        sortBy: "activity_trend_for_leaders",
        render: value => value,
    },
    activity_for_leaders: {
        header: "Activity for Leaders",
        sortable: true,
        sortBy: "activity_for_leaders",
        render: value => value,
    },
    activity_trend_for_experts: {
        header: "Activity Trend for Experts",
        sortable: true,
        sortBy: "activity_trend_for_experts",
        render: value => value,
    },

    // upgrade
    competitor_influence_1: {
        header: "Competitor Influence #1",
        sortable: true,
        sortBy: "competitor_influence_1",
        render: value => value,
    },
    competitor_influence_2: {
        header: "Competitor Influence #2",
        sortable: true,
        sortBy: "competitor_influence_2",
        render: value => value,
    },
    competitor_influence_3: {
        header: "Competitor Influence #3",
        sortable: true,
        sortBy: "competitor_influence_3",
        render: value => value,
    },
    competitor_influence_4: {
        header: "Competitor Influence #4",
        sortable: true,
        sortBy: "competitor_influence_4",
        render: value => value,
    },
    competitor_influence_5: {
        header: "Competitor Influence #5",
        sortable: true,
        sortBy: "competitor_influence_5",
        render: value => value,
    },
    competitor_influence_6: {
        header: "Competitor Influence #6",
        sortable: true,
        sortBy: "competitor_influence_6",
        render: value => value,
    },
    competitor_influence_7: {
        header: "Competitor Influence #7",
        sortable: true,
        sortBy: "competitor_influence_7",
        render: value => value,
    },
    competitor_influence_8: {
        header: "Competitor Influence #8",
        sortable: true,
        sortBy: "competitor_influence_8",
        render: value => value,
    },
    competitor_influence_9: {
        header: "Competitor Influence #9",
        sortable: true,
        sortBy: "competitor_influence_9",
        render: value => value,
    },
    competitor_influence_10: {
        header: "Competitor Influence #10",
        sortable: true,
        sortBy: "competitor_influence_10",
        render: value => value,
    },

    // Order report
    agency: {
        header: "Agency",
        sortable: false,
        sortBy: "agency",
        render: value => value,
        component: AgencyComponent
    },
    order_account: {
        header: "Order",
        sortable: false,
        sortBy: "order_account",
        render: value => value,
        component: AccountOrderComponent
    },
    order_status: {
        header: "Status",
        sortable: true,
        sortBy: "order_status",
        render: value => value,
    },
    category: {
        header: "Category",
        sortable: false,
        sortBy: "category",
        render: value => value,
    },
    line_item_page: {
        header: "Line Item/Page",
        sortable: false,
        sortBy: "line_item_page",
        className: "custom-column-large",
        render: value => value,
    },
    actual_start_date: {
        header: "Actual Start Date",
        sortable: true,
        sortBy: "actual_start_date",
        render: value => getDisplayDate(value),
    },
    projected_end_date: {
        header: "Projected End Date",
        sortable: true,
        sortBy: "projected_end_date",
        render: value => getDisplayDate(value),
    },
    percent_time_elapsed: {
        header: "% Elapsed Time (a)",
        sortable: true,
        sortBy: "percent_time_elapsed",
        render: value => getAttachedPercentage(value),
    },
    kpi_roadblock: {
        header: "KPI Roadblock",
        sortable: true,
        sortBy: "kpi_roadblock",
        textAlign: "right",
        render: value => value,
    },
    kpi: {
        header: "KPI Type",
        sortable: false,
        sortBy: "kpi",
        render: value => value,
    },
    kpi_commited: {
        header: "KPI Committed",
        sortable: true,
        sortBy: "kpi_commited",
        textAlign: "right",
        render: value => value,
    },
    kpi_delivered_total: {
        header: "KPI Delivered Overall (Standard)",
        sortable: true,
        sortBy: "kpi_delivered_total",
        textAlign: "right",
        render: value => value,
    },
    kpi_delivered_roadblock: {
        header: "KPI Delivered Overall (Roadblock)",
        sortable: true,
        sortBy: "kpi_delivered_roadblock",
        textAlign: "right",
        render: value => value,
    },
    kpi_delivered: {
        header: "KPI Delivered in Range",
        sortable: true,
        sortBy: "kpi_delivered",
        textAlign: "right",
        render: value => value,
    },
    complete_percent: {
        header: "Complete Percent",
        sortable: true,
        textAlign: "right",
        sortBy: "complete_percent",
        render: value => getAttachedPercentage(value)
    },
    order_clicks: {
        header: "Clicks",
        textAlign: "right",
        sortBy: "order_clicks",
        className: "clicks",
        sortable: true,
        render: value => value === "N/A" ? "N/A" : numFormat(value)
    },
    order_ctr: {
        header: "Click Through Rate",
        textAlign: "right",
        sortBy: "order_ctr",
        sortable: true,
        render: value => value === "N/A" ? "N/A" : getAttachedPercentage(value)
    },

    // newsletter details
    newsletter_type: {
        header: "Newsletter Type",
        sortable: true,
        sortBy: "newsletter_type",
        render: value => value,
    },
    newsletter_template: {
        header: "Newsletter Template",
        sortable: true,
        sortBy: "newsletter_template",
        render: value => value,
    },
    
    sc_primary_topic: {
        header: "Primary Topic",
        sortable: false,
        sortBy: "primary_topic",
        render: value => value,
        className: "custom-column-large",
        component: PrimaryTopicSCComponent,
    },
    str_topic_tags: {
        header: "Topic Tags",
        sortable: false,
        sortBy: "str_topic_tags",
        className: "custom-column-large",
        render: value => value,
    },
    str_placement_tags: {
        header: "Placement Tags",
        sortable: false,
        sortBy: "str_placement_tags",
        className: "custom-column-large",
        render: value => value,
    },
    publish_date: {
        header: "Publish Date",
        sortable: false,
        sortBy: "publish_date",
        render: value => value,
    },
    pageviews_delivered_in_date_range: {
        header: "Pageviews Delivered In Range",
        sortable: false,
        sortBy: "pageviews_delivered_in_date_range",
        textAlign: "right",
        render: value => value,
    },
    total_pageviews_delivered: {
        header: "Pageviews Delivered Total",
        sortable: false,
        sortBy: "total_pageviews_delivered",
        textAlign: "right",
        render: value => value,
    },
    percent_delivered: {
        header: "Percent Delivered",
        sortable: false,
        sortBy: "percent_delivered",
        textAlign: "right",
        render: value => value,
    },
    pageview_goal: {
        header: "Pageview Goal",
        sortable: false,
        sortBy: "pageview_goal",
        textAlign: "right",
        render: value => value,
    }
};