import React from "react";
import SingleSelectDropDown from "../base/singleSelectDropDown";

const DeliveryPicker = (props) => {
	const val = props?.delivery_states ?? []
    const addExtraObj = {label: "All", value: " ", inactive: false};
    const finalArr = [addExtraObj, ...val]
	return (
		<SingleSelectDropDown
			clearable
			className="no-blink"
			options={finalArr && finalArr.map((type, index) => ({
				key: index,
				text: type?.label,
				value: type?.value,
			}))}
			label="Campaign Delivery State"
			placeholder="All"
			{...props}
		/>
	);
};

export default DeliveryPicker
