import { stagingKey, responseType } from "../constants";

export const getBaseUrl = () => {
    const url = window.location.href;
    let arr = url.split("/");
    return arr[0] + "//" + arr[2];
};

// export const getcallBackUrl = () => {
//   const url = window.location.href;
//   let arr = url.split('/');
//   const callBackUrl = arr[0] + '//' + arr[2] + '/callback';
//   return callBackUrl;
// };

export const getLoginUrl = (wpLink, apiClientId, apiOAuthState) => {
    const url = window.location.href;
    let arr = url.split("/");
    const callBackUrl = arr[0] + "//" + arr[2] + "/callback";
    const loginUri = `${wpLink}/oauth/authorize/?client_id=${apiClientId}&redirect_uri=${encodeURIComponent(
        callBackUrl
    )}&response_type=${responseType}&scope=&staging_key=${stagingKey}&state=${apiOAuthState}`;
    return loginUri;
};

export const getCookie = (name) => {
    const re = new RegExp(name + "=([^;]+)");
    var value = re.exec(document.cookie);
    return value != null ? unescape(value[1]) : null;
};

// export const removeCookies = () => {
//     const res = document.cookie;
//     var multiple = res.split(";");
//     for (var i = 0; i < multiple.length; i++) {
//         var key = multiple[i].split("=");
//         document.cookie =
//             key[0] + " =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
//     }
// };

export const removeCookies = () => {
    const cookies = document.cookie.split(";");
  
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
  };

export const clearCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
};