import React from 'react';
import { connect } from 'react-redux';
import { loginWithWordPressAPI, loginWithOktaAPI } from '../../actions';
import './auth.css';

class Login extends React.Component {

    handleWordpress = () => {
        this.props.loginWithWordPressAPI();
    }

    handleOkta = () => {
        this.props.loginWithOktaAPI();
    }

    render() {
        return (
            <React.Fragment>
                <div className='login-page-message'>
                    <p>Welcome To SDxCentral Reporting Dashboard.</p>
                </div>
                {
                    this?.props?.authorized?.token === null &&
                    <div className='login_parent-container'>
                        <div className='new-app-login-block'>
                            <button type="button" className="login-button" onClick={this.handleWordpress}>
                                Login with WordPress
                            </button>
                            <button type="button" className="login-button" onClick={this.handleOkta}>
                                Login with Okta
                            </button>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
	return {
		authorized: state.authorized,
	};
};

const mapDispatchToProps = {
    loginWithWordPressAPI,
    loginWithOktaAPI
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
