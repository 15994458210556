import React ,{useState}from 'react';
import {titleCase} from "../../utilities/common"

const Accordion = ({items, report}) => {
    const [activeIndex,setActiveIndex] = useState(null);
    let tableBody

    const onTitleClick = (index) => {
        setActiveIndex(index);
    };

    const getKeys = (data) => {
      let keys = {}

      if (data.old_value) {
        Object.keys(data.old_value).forEach((k) => {
          keys[k] = 0
        })
      }
      

      if (data.new_value) {
        Object.keys(data.new_value).forEach((k) => {
          keys[k] = 0
        })
      }
      return Object.keys(keys)
    } 

  const createRow = (data) => {
    
    let keys = getKeys(data)

    if (keys.length > 0) {
      let tableRow = [];
      keys.forEach((k) =>{
        let tableCell = [];
        if ( k !== "changed_by_wp_id"){
          if (titleCase(k.replaceAll('_', ' ')) === "Verification Status"){
            k = "Lead Status"
            if (report === "engagement"){
              k = "Engagement Status"
            }
        }
        tableCell.push(
          <td data-label="Column">{titleCase(k.replaceAll('_', ' '))}</td>
          );
            if (k === "Lead Status" || k === "Engagement Status"){
             k = "verification_status"
            }
        tableCell.push(
        <td data-label="Old Value">{data.old_value[k]}</td>
        );
        tableCell.push(
          <td data-label="New Value">{data.new_value[k]}</td>
        )
    
      }
        tableRow.push(<tr children={tableCell} />);
      })
      
      return tableRow;
    }
  };


   const renderedItems = items.map((item,index)=>
    {
        const active = index === activeIndex ? 'active' : '';

        tableBody = <tbody>{createRow(item.content)}</tbody>
        return(

              <div key={item.title}>
                <div 
                onClick={() => onTitleClick(index)}
                className={`title ${active}`}
                >
                <i className="dropdown icon"></i>
                    {item.title}
                </div>
                <div className={`content ${active}`}>
                {/* <p>{item.content}</p> */}

                <table class="ui celled table">
                      <thead>
                        <tr>
                          <th>Column</th>
                        <th>Old Value</th>
                        <th>New Value</th>
                      </tr>
                      </thead>
                      {tableBody}
                  </table>
                </div>
            </div>
      
        );
    })
    return(
     <div className="ui styled accordion">
         {renderedItems}
     </div>
    );
};

export default Accordion;