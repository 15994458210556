import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";

import { clearSearchForAllReport, selectValue, fetchOrderReportData } from "../../actions";
import * as actionTypes from "../../actions/actionTypes";
import { Form } from "semantic-ui-react";
import DateComponent from "../CommonFilter/DateComponent";
import OrgSection from "../common/FilterSection/OrgSection";
import OrderSingleSection from "../common/FilterSection/OrderSingleSection";
import OrderStatusPicker from "../common/OrderStatus";
import CampaignStatusPicker from "../common/FilterSection/CampaignStatusPicker";
import { COMPETITOR_FILTER } from "../config/const";
import Toggle from "../base/toggle";
import { EXCLUDE_SDXCENTRAL_LABEL } from "../config/const";
import PaidPicker from "../common/paidUnpaidPicker";

const GlobalFilter = (props) => {
    const [filterSelectFocus, setFilterSelectFocus] = useState("filter-icon");
    const [filterPopup, setFilterPopup] = useState("filter-popup-close");
    const [filterIconColor, setFilterIconColor] = useState("material-icons grey");
    const [checkSelectState, setCheckSelectState] = useState(false);
    const [clear, setClear] = useState(false);

    const dispatchAction = useDispatch();

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    useEffect(() => {
        dispatchDynamicSelectAction(props, "excludeSdxCentral", true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(props.filterData.orderSingleStatus === "") {
            dispatchDynamicSelectAction(props, "competitorFiltering", false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filterData.orderSingleStatus]);

    const applyFilters = (startDate, endDate, appDate, isCustomCheckbox) => {
        setCheckSelectState(isCustomCheckbox);
        props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].APPLY_FILTERS, reportType: props.reportActionType });
        let tempAdFilterData = props.filterData;
        tempAdFilterData.startDate = startDate;
        tempAdFilterData.endDate = endDate;
        tempAdFilterData.prevStartDate = "";
        tempAdFilterData.prevEndDate = "";
        tempAdFilterData.appliedDate = appDate;
        tempAdFilterData.selCustomRangeCheckBox = isCustomCheckbox;

        props.fetchOrderReportData(
            tempAdFilterData,
            1,
            "",
            actionTypes[props.reportActionType].ORDER_SUMMARY,
            props.reportName,
            props.reportActionType,
        );
        
        setFilterPopup("filter-popup-close");
        setFilterIconColor("material-icons grey");
        setFilterSelectFocus("filter-icon");
    }

    const clearFilters = () => {
        setClear(true);
        setTimeout(() => {
            setClear(false);
        }, 1500);
        props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].CLEARCOMMONFILTER, reportType: props.reportActionType });
    }

    return (
        <div>
            <DateComponent {...props} applyFilters={applyFilters} isClear={clear} />
            <div className="popup-component">
                <div
                    className={filterSelectFocus}
                    style={{ marginTop: "-50px" }}
                    onClick={() => {
                        setFilterSelectFocus("filter-icon-selected");
                        setFilterPopup("filter-popup-open");
                        setFilterIconColor("material-icons orange");
                    }}
                >
                    <i className={filterIconColor}>filter_list</i>
                </div>
                <div className={filterPopup}>
                    <div className="filter-header">
                        <div className="filter-text">
                            <p>Filters</p>
                        </div>
                        <div
                            className="filter-close-icon"
                            onClick={() => {
                                setFilterSelectFocus("filter-icon");
                                setFilterPopup("filter-popup-close");
                            }}
                        >
                            <i
                                className="material-icons grey"
                                onClick={() => setFilterIconColor("material-icons grey")}
                            >
                                clear
                            </i>
                        </div>
                    </div>
                    
                    <Form>
                        <div className="ad-search-filter">
                            <Form.Group widths="equal">
                                <OrgSection {...props} />
                                <OrderSingleSection {...props} />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <OrderStatusPicker
                                    value={props.filterData.orderStatus}
                                    onChange={(e, {value} ) => dispatchDynamicSelectAction(props, "orderStatus", value) }
                                />
                                <CampaignStatusPicker {...props} />
                            </Form.Group>
                            <Form.Group widths="equal" style={{ width: "23.8em" }}>
                                <PaidPicker
                                    style={{ width: "24rem" }}
                                    value={props.filterData.adOrderType}
                                    onChange={(e, { value }) => dispatchDynamicSelectAction(props, "adOrderType", value)}
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Toggle
                                    className="eng_toggle"
                                    label={COMPETITOR_FILTER}
                                    disabled={(props.filterData.orderSingleStatus !== "") ? false : true}
                                    onChange={() => dispatchDynamicSelectAction(props, "competitorFiltering", !props.filterData.competitorFiltering)}
                                    checked={props.filterData.competitorFiltering}
                                />
                                <Toggle
                                    className="eng_toggle"
                                    label={EXCLUDE_SDXCENTRAL_LABEL}
                                    onChange={(e) => dispatchDynamicSelectAction(props, "excludeSdxCentral", !props?.filterData?.excludeSdxCentral) }
                                    checked={props?.filterData?.excludeSdxCentral}
                                />
                            </Form.Group>
                        </div>
                    </Form>
                    
                    <div className="filter-buttons">
                        <button className="cancel-date-button extra-space" onClick={clearFilters}>Clear Filters</button>
                        <button
                            type="submit"
                            className="apply-filter-button"
                            onClick={() => {
                                applyFilters(
                                    props.filterData.startDate,
                                    props.filterData.endDate,
                                    props.filterData.appliedDate,
                                    checkSelectState
                                );
                                if (props.filterData.isFilApplied) {
                                    dispatchAction({
                                        type: actionTypes[props.reportActionType].UPDATED_CHART_STATUS,
                                        reportType: props.reportActionType,
                                        payload: {
                                            chartApiCompleted: false,
                                        },
                                    });
                                }
                            }}
                        >
                            Apply Filters
                        </button>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default compose(connect(null, {
    clearSearchForAllReport,
    selectValue,
    fetchOrderReportData,
}))(GlobalFilter);