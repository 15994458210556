import React from "react";
import SingleSelectDropDown from "../base/singleSelectDropDown";

const TimeOfDay = (props) => {
	const val = props?.ranges ?? []
    const addExtraObj = { label: "All", value: "all" };
    const finalArr = [addExtraObj, ...val]
	return (
		<SingleSelectDropDown
            clearable
			className="no-blink"
			options={finalArr && finalArr.map((type, index) => ({
				key: index,
				text: type?.label,
				value: type?.value,
			}))}
			label={"Time of Day"}
			placeholder="All"
            {...props}
		/>
	);
};

export default TimeOfDay;
