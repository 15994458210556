import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";
import {
  getDisplayDate,
  getYesterdaysDate,
  getLastDays,
  getStartEndDates,
} from "../utilities/common";
import { siteValue } from "../constants";
import createCommonReducer from "./createCommonReducer";

const clientLeadFilterData = {
  startDate: getDisplayDate(getLastDays(0)),
  endDate: getDisplayDate(getYesterdaysDate()),
  appliedDate: "2",
  prevendDate: getDisplayDate(getYesterdaysDate()),
  prevstartDate: getDisplayDate(getLastDays(7)),
  prevAppliedDate: "",
  assetType: "",
  organization: [],
  campaign: [],
  order: [],
  title: [],
  site: siteValue,
  campaignStatus: "active",
  clientLeadFilterStatus: "",
  clientLeadFilterVerificationState: "",
  globalLeadStatus: "",
  globalVerificationState: "",
  applyDateState: false,
  leadStatusRowWise: [],
  verificationStateRowWise: [],
  leadCheckboxState: false,
  updatedDropdownValues: {},
  showDropDownState: false,
  showDropDownStatus: false,
  dropDownSelectedValue: [],
  leadPartner: "",
  engagedOrganizations: [],
  overrideLeadReportFilters: true,
  overrideReportingAttributes: false,
  excludeCompetitorEvents: true,
  excludeEmailSupression: false,
  //organizationLimits: false,
  excludeInternalEvents: false,
  excludeOrganizationEvents: false,
  excludeFreeEmailEvents: false,
  excludeQACampaign: false,
  excludeInternalCampaign: false,
  account: false,
  vertical: false,
  geo: false,
  selClientLeadChart: false,
  customQuestions: false,
  firmoGraphic: false,
  types: false,
  size: false,
  revenue: false,
  personas: false,
  seniority: false,
  jobFunctions: false,
  jobTitles: false,
  selUrlSource: "",
  selUrlMedium: "",
  selUrlCampaign: "",
  selUrlTerm: "",
  selUrlContent: "",
  showDownloadButton: false,
  clientDeliverdFilterState: "",
  clientDeliverdFilterStateOptions: [],
  pacingFilter: "",
  clIsIncludeParent: false,
  clIsIncludeChild: false,
  selHeaderDropdownOptionValue: "",
  showMoreClicked: false,
  messageBox: true,
  messageBoxRefresh: true,
  utmParams: false,
  domainFilter: "all",
  competitorFiltering: false,
  setCSM: [],
  privacyParams: false,
  regulationName: [],
  purposeConsent: "all",
  marketingConsent: "all",
  doubleOptin: "all",
  dnsmsPersonalInfo: "all",
  rtbForgotten: "all",
  conditioningConsent: "all",
  ageVerification: "all",
};

const clientLeadFetchData = {
  isFilApplied: false,
  leadValidationChart: [],
  isLoadingChart: false,
  leadValidationBarChart: {},
  leadEngSummary: {},
  leadEngSummaryCount: {},
  isLoadingLeadEngSummary: false,
  leadEngs: {},
  totalLeadCount: 0,
  isLoadingLeadEngs: false,
  leadEngsCsv: "",
  isLoadingLeadEngsCsv: false,
  leadEngSummarycolumn: null,
  column: null,
  direction: null,
  leadEngSummarydirection: null,
  summaryPageCount: "",
  detailPageCount: "",
  //campaignSummaryPageCount: "",
  changedLogs: {},
  emailClientLeadSummary: "",
  cardPopupClientLeadSummary: "",
  postEmailUpdateClientLeadSummary: "",
  mailDelivery: {},
  cardPopupMailDelivery: "",
  postMailDeliveryConfirmation: "",
  leadDetailCsv: "",
  isLoadingLeadDetailCsv: false,
  emailClientLeadDetail: "",
  cardPopupClientLeadDetail: "",
  postEmailUpdateClientLeadDetail: "",
  leadPurchasedCount: 0,
  receivedCount: 0,
  inReviewCount: 0,
  accepted_not_delivered: 0,
  leadDeliveredcount: 0,
  prevDeliverdCount: 0,
  totalLeadDelveredCount: 0,
  leadOutstandingCount: 0,
  rejectedCount: 0,
  totalTableCell: 0,
  isShowMore: false,
  isTotalLeadCount: false,
  leadStatusChart: false,
	isLoadingStatusChart: false,
	leadAcceptanceChart: false,
	isLoadingAcceptanceChart: false,
	leadAcceptanceBarChart: {},
  resCampaignSummaryLeadEngsValue: null,
  resLeadDetailsValue: null,
  downloadFileName: ""
};

const selectedArray = {
  csmArray: [],
  regulationData: [],
}

export const clientLeadFilterDataReducer = (
  state = clientLeadFilterData,
  action
) => {
  switch (action.type) {
    case actionTypes.SELECTED_LEAD_TOGGLE:
      return { ...state, [action.payload.key]: action.payload.value };
    case actionTypes.SELCLIENTLEADSTARTDATE:
      return { ...state, startDate: action.payload };
    case actionTypes.SELCLIENTLEADENDDATE:
      return { ...state, endDate: action.payload };
    case actionTypes.SELCLIENTPREVAPPLIEDDATE:
      return { ...state, prevAppliedDate: action.payload };
    case actionTypes.SELCLIENTENDDATEPREV:
      return { ...state, prevendDate: action.payload };
    case actionTypes.SELCLIENTSTARTDATEPREV:
      return { ...state, prevstartDate: action.payload };
    case actionTypes.APPLYCLIENTLEADDATE:
      return { ...state, appliedDate: action.payload };
    case actionTypes.APPLYDATESTATE:
      return { ...state, applyDateState: action.payload };
    case actionTypes.SELCLIENTLEADORGS:
      return { ...state, organization: action.payload };
    case actionTypes.SELCLIENTLEADPAGETITLE:
      return { ...state, title: action.payload };
    case actionTypes.SELCLIENTLEADCAMPAIGNS:
      return { ...state, campaign: action.payload };
    case actionTypes.RESETSELCLIENTLEADCAMPAIGNS:
      return { ...state, campaign: action.payload };
    case actionTypes.SELCLIENTLEADORDER:
      return { ...state, order: action.payload };
    case actionTypes.SELCLIENTLEADSITE:
      return { ...state, site: action.payload };
    case actionTypes.SELCLIENTLEADSTATUS:
      return { ...state, campaignStatus: action.payload };
    case actionTypes.SELCLIENTLEADFILTERSTATUS:
      return { ...state, clientLeadFilterStatus: action.payload };
    case actionTypes.SELGLOBALLEADSTATUS:
      return { ...state, globalLeadStatus: action.payload };
    case actionTypes.SELGLOBALVERIFICATIONSTATE:
      return { ...state, globalVerificationState: action.payload };
    case actionTypes.FETCHDELIVERYSTATE:
      return { ...state, clientDeliverdFilterStateOptions: action.payload.delivery_states };
    case actionTypes.SELDELIVERYSTATE:
      return { ...state, clientDeliverdFilterState: action.payload };
    case actionTypes.SELLEADSTATUSROWWISE:
      return {
        ...state,
        leadStatusRowWise: action.payload,
        leadStatusRowWiseValue: action.value,
      };
    case actionTypes.UPDATEDDROPDOWNVALUES:
      return { ...state, updatedDropdownValues: action.payload };
    case actionTypes.FETCHREQSTATUS:
      return { ...state, fetchApiReq: action.payload };
    case actionTypes.FETCHREQSTATUSROWWISE:
      return { ...state, fetchApiReqRowWise: action.payload };
    case actionTypes.FETCHREQVALIDATE:
      return { ...state, fetchValidateReq: action.payload };
    case actionTypes.SHOWDOWNLOADBUTTON:
      return { ...state, showDownloadButton: action.payload };
    case actionTypes.SELLEADCHECKBOXSTATE:
      return { ...state, leadCheckboxState: action.payload };
    case actionTypes.SHOWDROPDOWNSTATE:
      return { ...state, showDropDownState: action.payload };
    case actionTypes.SHOWDROPDOWNSTATUS:
      return { ...state, showDropDownStatus: action.payload };
    case actionTypes.SELECTEDDROPDOWNVALUE:
      return { ...state, dropDownSelectedValue: action.payload };
    case actionTypes.SELVERIFICATIONSTATEROWWISE:
      return { ...state, verificationStateRowWise: action.payload };
    case actionTypes.SELCLIENTLEADFILTERSTATE:
      return { ...state, clientLeadFilterVerificationState: action.payload };
    case actionTypes.SELCLIENTLEADPARTNER:
      return { ...state, leadPartner: action.payload };
    case actionTypes.SELCLIENTLEADTYPE:
      return { ...state, assetType: action.payload };
    case actionTypes.SELCLIENTLEADENGORGS:
      return { ...state, engagedOrganizations: action.payload };
    case actionTypes.SELCLIENTLEADURLSOURCE:
      return { ...state, selUrlSource: action.payload };
    case actionTypes.SELCLIENTLEADURLMEDIUM:
      return { ...state, selUrlMedium: action.payload };
    case actionTypes.SELCLIENTLEADURLCAMPAIGNS:
      return { ...state, selUrlCampaign: action.payload };
    case actionTypes.SELCLIENTLEADURLTERM:
      return { ...state, selUrlTerm: action.payload };
    case actionTypes.SELCLIENTLEADURLCONTENT:
      return { ...state, selUrlContent: action.payload };

    case actionTypes.SELCLPARENTTOOGLE:
        return { ...state, clIsIncludeParent: !state.clIsIncludeParent };
    case actionTypes.SELCLCHILDTOOGLE:
        return { ...state, clIsIncludeChild: !state.clIsIncludeChild };
    case actionTypes.SELCLIENTLEADPACINGFILTERVALUE:
      return { ...state, pacingFilter: action.payload };  
    case actionTypes.SELCLIENTORFTOGGLE:
      return {
        ...state,
        overrideLeadReportFilters: !state.overrideLeadReportFilters,
      };
    case actionTypes.SELCLIENTORATOGGLE:
      return {
        ...state,
        overrideReportingAttributes: !state.overrideReportingAttributes,
      };
    case actionTypes.SELCLIENTECTOGGLE:
      return {
        ...state,
        excludeCompetitorEvents: !state.excludeCompetitorEvents,
      };
    case actionTypes.SELCLIENTEESTOGGLE:
      return {
        ...state,
        excludeEmailSupression: !state.excludeEmailSupression,
      };
    // case actionTypes.SELCLIENTOLTOGGLE:
    // 	return {
    // 		...state,
    // 		organizationLimits: !state.organizationLimits,
    // 	};
    case actionTypes.SELCLIENTQATOGGLE:
      return {
        ...state,
        excludeQACampaign: !state.excludeQACampaign,
      };
    case actionTypes.SELCLIENTINTOGGLE:
      return {
        ...state,
        excludeInternalCampaign: !state.excludeInternalCampaign,
      };
    case actionTypes.SELCLIENTEIETOGGLE:
      return {
        ...state,
        excludeInternalEvents: !state.excludeInternalEvents,
      };
    case actionTypes.SELCLIENTEOETOGGLE:
      return {
        ...state,
        excludeOrganizationEvents: !state.excludeOrganizationEvents,
      };
    case actionTypes.SELCLIENTEFEETOGGLE:
      return {
        ...state,
        excludeFreeEmailEvents: !state.excludeFreeEmailEvents,
      };
    case actionTypes.SELCLIENTATOGGLE:
      return {
        ...state,
        account: !state.account,
      };
    case actionTypes.SELCLIENTVTOGGLE:
      return {
        ...state,
        vertical: !state.vertical,
      };
    case actionTypes.SELCLIENTGTOGGLE:
      return {
        ...state,
        geo: !state.geo,
      };
    case actionTypes.SELCLIENTCHARTTOGGLE:
      return {
        ...state,
        selClientLeadChart: !state.selClientLeadChart,
      };
    case actionTypes.SELCLIENTCQTOGGLE:
      return {
        ...state,
        customQuestions: !state.customQuestions,
      };
    case actionTypes.SELCLIENTFTOGGLE:
      return {
        ...state,
        firmoGraphic: !state.firmoGraphic,
      };
    case actionTypes.SELCLIENTTTOGGLE:
      return {
        ...state,
        types: !state.types,
      };
    case actionTypes.SELCLIENTSIZETOGGLE:
      return {
        ...state,
        size: !state.size,
      };
    case actionTypes.SELCLIENTRTOGGLE:
      return {
        ...state,
        revenue: !state.revenue,
      };
    case actionTypes.SELCLIENTPTOGGLE:
      return {
        ...state,
        personas: !state.personas,
      };
    case actionTypes.SELCLIENTSTOGGLE:
      return {
        ...state,
        seniority: !state.seniority,
      };
    case actionTypes.SELCLIENTJFTOGGLE:
      return {
        ...state,
        jobFunctions: !state.jobFunctions,
      };
    case actionTypes.SELCLIENTJTTOGGLE:
      return {
        ...state,
        jobTitles: !state.jobTitles,
      };
    case actionTypes.RESETCLIENTORATOGGLE:
      if (state.overrideLeadReportFilters) {
        return {
          ...state,
          account: true,
          vertical: true,
          geo: true,
          selClientLeadChart: false,
          customQuestions: true,
          firmoGraphic: true,
          types: true,
          size: true,
          revenue: true,
          personas: true,
          seniority: true,
          jobFunctions: true,
          jobTitles: true,
        };
      } else {
        return state;
      }
    case actionTypes.RESETCLIENTFTOGGLE:
      if (state.firmoGraphic) {
        return state;
      } else {
        return {
          ...state,
          types: false,
          size: false,
          revenue: false,
        };
      }
    case actionTypes.RESETCLIENTPTOGGLE:
      if (state.personas) {
        return state;
      } else {
        return {
          ...state,
          seniority: false,
          jobFunctions: false,
          jobTitles: false,
        };
      }
    case actionTypes.SELCLIENTUTMPARAMSTOGGLE:
      return {
        ...state,
        utmParams: !state.utmParams,
      };
    case actionTypes.SHOWMESSAGEPOPUP:
      return { ...state, messageBox: action.messageBox };
    case actionTypes.SHOWMESSAGEBOXREFRESHPOPUP:
      return { ...state, messageBoxRefresh: action.messageBoxRefresh };
    case actionTypes.SELHEADERDROPDOWNOPTIONVALUE:
      return {...state, selHeaderDropdownOptionValue: action.payload}
    case actionTypes.SHOWMORECLICKED:
      return{...state, showMoreClicked: action.payload}
    case actionTypes.SELECTCLIENTDOMAINFILTER:
      return { ...state, domainFilter: action.payload };
    case actionTypes.FETCHINICLIENTLEADFILTER:
      let data = action.payload;
      let dates = data.applied_date_filter
        ? getStartEndDates(data.applied_date_filter)
        : state;
      return {
        ...state,
        startDate:
          data.applied_date_filter === "Custom Date Range"
            ? getDisplayDate(data.start_date)
            : dates.startDate,
        endDate:
          data.applied_date_filter === "Custom Date Range"
            ? getDisplayDate(data.end_date)
            : dates.endDate,
        prevstartDate:
          data.applied_date_filter === "Custom Date Range"
            ? getDisplayDate(data.start_date)
            : dates.startDate,
        prevendDate:
          data.applied_date_filter === "Custom Date Range"
            ? getDisplayDate(data.end_date)
            : dates.endDate,
        leadPartner: data.lead_partner ? data.lead_partner : "",
        campaign: data.campaigns ? data.campaigns : [],
        organization: data.organizations ? data.organizations : [],
        site: data.site ? data.site : "all",
        campaignStatus: data.status ? data.status : "all",
        assetType: data.asset_type ? data.asset_type : "all",
        appliedDate: data.applied_date_filter
          ? data.applied_date_filter
          : state.appliedDate,
        engagedOrganizations: data.engaged_organizations
          ? data.engaged_organizations
          : [],
        title: data.page_title ? data.page_title : [],
        excludeCompetitorEvents: data.exclude_competitors,
        excludeInternalEvents: data.exclude_internal_events,
        excludeOrganizationEvents: data.exclude_organization_events,
        excludeFreeEmailEvents: data.exclude_free_email_registrations,
        excludeQACampaign: data.exclude_qa,
        overrideLeadReportFilters: data.override_lead_report_filters,
        // overrideReportingAttributes: data.override_campaign_report_setup,
        excludeInternalCampaign: data.exclude_internal,
        account: data.override_account_filters,
        vertical: data.override_vertical_filters,
        geo: data.override_geo_filters,
        customQuestions: data.override_custom_questions,
        firmoGraphic: data.override_firmographic_filters,
        types: data.override_organization_types_filters,
        size: data.override_organization_sizes_filters,
        revenue: data.override_estimate_revenues_filters,
        personas: data.override_personas_filters,
        seniority: data.override_seniority_filters,
        jobFunctions: data.override_job_functions_filters,
        jobTitles: data.override_job_titles_filters,
        excludeEmailSupression: data.exclude_email_suppression,
        selUrlSource: data.utm_source,
        selUrlMedium: data.utm_medium,
        selUrlCampaign: data.utm_campaign,
        selUrlTerm: data.utm_term,
        selUrlContent: data.utm_content,
        clientLeadFilterStatus: data.verification_status
          ? data.verification_status
          : "All",
        clientLeadFilterVerificationState: data.verification_state
          ? data.verification_state
          : "All",
        clientDeliverdFilterState: data.deliverd_state ? data.deliverd_state : "",
        pacingFilter: data.pace ? data.pace : "",
        utmParams: data.utm_filters,
        order: data.orders ? data.orders : [],
        domainFilter: data.domain_filter ? data.domain_filter : "all",
        setCSM: data.csm ? data.csm : [],
        regulationName: data.regulation_names ? data.regulation_names : [],
        purposeConsent: data.purpose_consent !== undefined ? (data.purpose_consent ? "yes" : "no") : "all",
        marketingConsent: data?.marketing_consent !== undefined ? (data.marketing_consent ? "yes" : "no") : "all",
        doubleOptin: data.double_optin !== undefined ? (data.double_optin ? "yes" : "no") : "all",
        dnsmsPersonalInfo: data.dnsmpi !== undefined ? (data.dnsmpi ? "yes" : "no") : "all",
        rtbForgotten: data.right_to_be_forgotten !== undefined ? (data.right_to_be_forgotten ? "yes" : "no") : "all",
        conditioningConsent: data.conditioning_consent !== undefined ? (data.conditioning_consent ? "yes" : "no") : "all",
        ageVerification: data.age_verification !== undefined ? (data.age_verification ? "yes" : "no") : "all",
      };

    case actionTypes.CLEARCLIENTLEADFILTER:
      return {
        startDate: getDisplayDate(getLastDays(7)),
        endDate: getDisplayDate(getYesterdaysDate()),
        appliedDate: "7",
        prevendDate: getDisplayDate(getYesterdaysDate()),
        prevstartDate: getDisplayDate(getLastDays(7)),
        prevAppliedDate: "",
        assetType: "",
        organization: [],
        campaign: [],
        order: [],
        title: [],
        leadPartner: "",
        site: siteValue,
        campaignStatus: "active",
        clientLeadFilterStatus: "All",
        clientLeadFilterVerificationState: "All",
        globalLeadStatus: "",
        globalVerificationState: "",
        engagedOrganizations: [],
        overrideLeadReportFilters: true,
        // overrideReportingAttributes: false,
        excludeCompetitorEvents: true,
        excludeEmailSupression: false,
        //organizationLimits : false,
        excludeInternalEvents: false,
        excludeOrganizationEvents: false,
        excludeFreeEmailEvents: false,
        excludeQACampaign: false,
        excludeInternalCampaign: false,
        account: true,
        vertical: true,
        geo: true,
        selClientLeadChart: false,
        customQuestions: true,
        firmoGraphic: true,
        types: true,
        size: true,
        revenue: true,
        personas: true,
        seniority: true,
        jobFunctions: true,
        jobTitles: true,
        selUrlSource: "",
        selUrlMedium: "",
        selUrlCampaign: "",
        selUrlTerm: "",
        selUrlContent: "",
        clientDeliverdFilterState: "",
        clientDeliverdFilterStateOptions: action.payload,
        pacingFilter: "",
        isTotalLeadCount: false,
        utmParams: false,
        domainFilter: "all",
        setCSM: [],
        privacyParams: false,
        regulationName: [],
        purposeConsent: "all",
        marketingConsent: "all",
        doubleOptin: "all",
        dnsmsPersonalInfo: "all",
        rtbForgotten: "all",
        conditioningConsent: "all",
        ageVerification: "all",
      };
    default:
      return state;
  }
};

export const clientLeadFetchDataReducer = (
  state = clientLeadFetchData,
  action
) => {
  switch (action.type) {
    case actionTypes.APPLYCLIENTLEADFILTER:
      return {
        ...state,
        leadValidationChart: [],
        leadValidationBarChart: {},
        isLoadingChart: true,
        leadEngSummary: {},
        leadEngCampaignSummary: {},
        leadEngSummaryCount: {},
        leadEngs: {},
        totalLeadCount: 0,
        isFilApplied: true,
        leadCheckboxState: true,
        isLoadingLeadEngSummary: true,
        isLoadingLeadEngCampaignSummary: true,
        isLoadingLeadEngs: true,
        summaryPageCount: 2,
        detailPageCount: 2,
        //campaignSummaryPageCount: 2,
        leadEngSummarycolumn: null,
        leadEngCampaignSummarycolumn: null,
        column: null,
        direction: null,
        leadEngCampaignSummarydirection: null,
        leadEngSummarydirection: null,
        changedLogs: {},
        dynamicHeaderArray: [],
        getDynamicHeaderData: [],
        leadEngsValidationBarChart: {},
				isLoadingStatusChart: true,
				isLoadingAcceptanceChart: true,
				leadAcceptanceBarChart: {},
        resCampaignSummaryLeadEngsValue: null,
        resLeadDetailsValue: null
      };
    case actionTypes.CLIENTLEADENGSUMMARY:
      if (action.payload === null) {
        return {
          ...state,
          isLoadingLeadEngSummary: false,
        };
      } else {
        action.payload.forEach((e) => {
          e.percent_delivered = parseFloat(e.percent_delivered);
        });
        return {
          ...state,
          leadEngSummary: action.payload,
          isLoadingLeadEngSummary: false,
        };
      }
    case actionTypes.TOTAL_LEAD_COUNT:
        return {
          ...state,
          leadPurchasedCount: action.leadPurchasedCount,
          receivedCount: action.receivedCount,
          inReviewCount: action.inReviewCount,
          accepted_not_delivered: action.accepted_not_delivered,
          leadDeliveredcount: action.leadDeliveredcount,
          prevDeliverdCount: action.prevDeliverdCount,
          totalLeadDelveredCount: action.totalLeadDelveredCount,
          leadOutstandingCount: action.leadOutstandingCount,
          rejectedCount: action.rejectedCount

        };
        case actionTypes.SHOWMORECLICKEDCOUNT:
          return {  
            ...state,
            isShowMore: action.payload
          };
    case actionTypes.CLIENTLEADENGSUMMARYCOUNT:
      if (action.payload === null) {
        return {
          ...state,
        };
      } else {
        return {
          ...state,
          leadEngSummaryCount: action.payload,
        };
      }
    case actionTypes.MORECLIENTLEADENGSUMMARYLOADING:
      return {
        ...state,
        isLoadingLeadEngSummary: true,
      };
    case actionTypes.ISTOTALLEADCOUNT:
      return { ...state, isTotalLeadCount: action.payload }
    case actionTypes.MORECLIENTLEADENGSUMMARYDATA:
      if (action.payload === null) {
        return {
          ...state,
          isLoadingLeadEngSummary: false,
          resCampaignSummaryLeadEngsValue: action.resCampaignSummaryLeadEngsValue
        };
      } else {
        action.payload.forEach((e) => {
          e.percent_delivered = parseFloat(e.percent_delivered);
        });
        return {
          ...state,
          leadEngSummary: [...state.leadEngSummary, ...action.payload],
          isLoadingLeadEngSummary: false,
        };
      }
    case actionTypes.CLIENTLEADENGSCSV:
      return {
        ...state,
        leadEngsCsv: action.csv,
        isLoadingLeadEngsCsv: false,
        downloadFileName: action.downloadFileName
      };
    case actionTypes.CLIENTLEADCSVLOADING:
      return {
        ...state,
        isLoadingLeadEngsCsv: true,
      };
    case actionTypes.CLIENTLEADDETAILCSV:
      return {
        ...state,
        leadDetailCsv: action.csv && action.csv,
        isLoadingLeadDetailCsv: false,
        downloadFileName: action.downloadFileName
      };
    case actionTypes.CLIENTLEADDETAILCSVLOADING:
      return {
        ...state,
        isLoadingLeadDetailCsv: true,
      };
    case actionTypes.CLIENTLEADENGS:
      if (action.payload === null) {
        return {
          ...state,
          isLoadingLeadEngs: false,
          leadEngs: {},
          totalLeadCount: 0
        };
      } else {
        const response = action.payload.map((row) => ({
          ...row,
          uniqueId: _.uniqueId("ROW"),
        }));
        return {
          ...state,
          leadEngs: response,
          totalLeadCount: action.totalLeadCount,
          // {
          //   ..._.mapKeys(action.payload, "email"),
          // },
          isLoadingLeadEngs: false,
        };
      }
    case actionTypes.CLIENTLEADCHART:
      return { ...state,
        isLoadingChart: false,
        leadValidationChart: action.payload };
    case actionTypes.CLIENTLEADBARCHART:
      return { ...state, isLoadingStatusChart: false, leadValidationBarChart: action.payload };
    case actionTypes.MORECLIENTLEADENGSLOADING:
      return {
        ...state,
        isLoadingLeadEngs: true,
      };
    case actionTypes.MORECLIENTLEADENGSDATA:
      if (action.payload === null) {
        return {
          ...state,
          isLoadingLeadEngs: false,
          resLeadDetailsValue: action.resLeadDetailsValue
        };
      } else {
        const response = action.payload.map((row) => ({
          ...row,
          uniqueId: _.uniqueId("ROW"),
        }));
        return {
          ...state,
          leadEngs: [...state.leadEngs, ...response],
          isLoadingLeadEngs: false,
        };
      }
    case actionTypes.SORTCLIENTLEADENGSUMMARY:
      const directionValueLeadSummary = state.leadEngSummarydirection === "ascending" ? "desc" : "asc";
      const leadEngSummary= _.orderBy(state.leadEngSummary, [(item) => typeof(item[action.column]) === "string" ? item[action.column].toLowerCase() : item[action.column]], [directionValueLeadSummary])
      const leadEngSummarydirection= state.leadEngSummarydirection === "ascending" ? "descending" : "ascending"

      if (state.leadEngSummarycolumn === action.column) {
        if(action.column === "organization_name" || action.column === "campaign_name") {
          return {
            ...state,
            leadEngSummarycolumn: action.column,
            leadEngSummary: state.leadEngSummarydirection === "ascending" ?
              _.orderBy(state.leadEngSummary, [data => (data[action.column]).toUpperCase()], ['desc'])
              :
              _.orderBy(state.leadEngSummary, [data => (data[action.column]).toUpperCase()], ['asc']),
            leadEngSummarydirection: state.leadEngSummarydirection === "ascending" ? "descending" : "ascending",
          };
        } else if (action.column === "order.name") {
          return {
            ...state,
            leadEngSummarycolumn: action.column,
            leadEngSummary: state.leadEngSummarydirection === "ascending" ?
              _.orderBy(state.leadEngSummary, [data => data.order.name ? (data.order.name).toUpperCase() : data.order.name], ['desc'])
              :
              _.orderBy(state.leadEngSummary, [data => data.order.name ? (data.order.name).toUpperCase() : data.order.name], ['asc']),
            leadEngSummarydirection: state.leadEngSummarydirection === "ascending" ? "descending" : "ascending",
          };
        } else {
          return {
            ...state,
            leadEngSummary: leadEngSummary,
            leadEngSummarydirection:leadEngSummarydirection
          };
        }
        
      }
      return {
        ...state,
        leadEngSummarycolumn: action.column,
        leadEngSummary: _.sortBy(state.leadEngSummary, [action.column]),
        leadEngSummarydirection: "ascending",
      };

    case actionTypes.SORTCLIENTLEADENGS:
      const directionValueLeadDetail = state.direction === "ascending" ? "desc" : "asc";
      const leadEngs= _.orderBy(state.leadEngs, [(item) => typeof(item[action.column]) === "string" ? item[action.column].toLowerCase() : item[action.column]], [directionValueLeadDetail])
      const direction= state.direction === "ascending" ? "descending" : "ascending"
      if (state.column === action.column) {
        if(state.column === "name"){
          return {
            ...state,
            leadEngs: state.direction === "ascending" ?
            _.orderBy(state.leadEngs, [data => data.campaign_data[0].order.name ? (data.campaign_data[0].order.name).toUpperCase() : data.campaign_data[0].order.name], ['desc'])
            :
            _.orderBy(state.leadEngs, [data => data.campaign_data[0].order.name ? (data.campaign_data[0].order.name).toUpperCase() : data.campaign_data[0].order.name], ['asc']),
            direction: state.direction === "ascending" ? "descending" : "ascending",
          };
        } else {
          return {
            ...state,
            leadEngs,
            direction,
          };
        }  
      }
      return {
        ...state,
        column: action.column,
        leadEngs,
        direction,
      };
    case actionTypes.SETCLIENTSUMMARYPGCOUNT:
      return { ...state, summaryPageCount: state.summaryPageCount + 1 };
    case actionTypes.SETCLIENTDETAILPGCOUNT:
      return { ...state, detailPageCount: state.detailPageCount + 1 };
    case actionTypes.SETCLIENTDETAILPGAFTERREFRESHCOUNT:
      return { ...state, detailPageCount: action.payload};
    case actionTypes.CLEARCLIENTLEADSORT:
      return {
        ...state,
        leadEngSummarycolumn: null,
        leadEngCampaignSummarycolumn: null,
        column: null,
        direction: null,
        leadEngCampaignSummarydirection: null,
        leadEngSummarydirection: null,
      };
    case actionTypes.CLIENTLEADCHANGEDLOGS:
      return {
        ...state,
        changedLogs: action.payload,
      };
    case actionTypes.CLIENTLEADEXCELSUMMARY:
      return {
        ...state,
        emailClientLeadSummary: action.payload.email,
        cardPopupClientLeadSummary: action.payload.class,
      };
    case actionTypes.CLIENTLEADEXCELDETAIL:
      return {
        ...state,
        emailClientLeadDetail: action.payload.email,
        cardPopupClientLeadDetail: action.payload.class,
      };
    case actionTypes.CLIENTLEADDETAILEXCEL:
      if (action.payload === "/lead_engagement_summary/search") {
        return {
          ...state,
          postEmailUpdateClientLeadSummary: action.email,
        }
      } 
      if (action.payload === "/lead_engagement_client/search") {
        return {
          ...state,
          postEmailUpdateClientLeadDetail: action.email
        };
      }
    /* falls through */
    case actionTypes.SETCLIENTLEADSUMMARYPOPUPCLOSE:
      return {
        ...state,
        cardPopupClientLeadSummary: action.payload,
      };
    case actionTypes.SETCLIENTLEADDETAILPOPUPCLOSE:
      return {
        ...state,
        cardPopupClientLeadDetail: action.payload,
      }; 
    case actionTypes.SETCLIENTLEADSUMMARYPOPUPPOSTEMAIL:
      return {
        ...state,
        postEmailUpdateClientLeadSummary: action.payload,
      };
    case actionTypes.SETCLIENTLEADDETAILPOPUPPOSTEMAIL:
      return {
        ...state,
        postEmailUpdateClientLeadDetail: action.payload,
      };
    case actionTypes.CLIENTLEADMAILDELIVERY:
      return {
        ...state,
        mailDelivery: action.payload,
      };
    case actionTypes.SENDDYANMICTABLEHEADER:
      return {
        ...state, dynamicHeaderArray: action.payload
      }
    case actionTypes.GETDYNAMICTABLEHEADER:
      return {
        ...state, getDynamicHeaderData: action.payload
      }
    case actionTypes.LEADACCEPTANCEBARCHART:
      return{
          ...state,
          isLoadingAcceptanceChart: false,
          leadAcceptanceBarChart: action.payload
        }
    case actionTypes.SELLEADSTATUSCHART:
      return {
        ...state,
        leadStatusChart: !state.leadStatusChart,
      };
    case actionTypes.SELLEADACCEPTANCECHART:
      return {
        ...state,
        leadAcceptanceChart: !state.leadAcceptanceChart,
      };
    default:
      return state;
  }
};

export const urlClientLeadSourceReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHCLIENTLEADURLSOURCE:
      return action.payload;
    case actionTypes.CLEARSEARCHCLIENTLEADURLSOURCE:
      return (state = []);
    case actionTypes.SELINICLIENTLEADENGUTMSOURCE:
      return action.utm_source;
    default:
      return state;
  }
};

export const urlClientLeadMediumReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHCLIENTLEADURLMEDIUM:
      return action.payload;
    case actionTypes.CLEARSEARCHCLIENTLEADURLMEDIUM:
      return (state = []);
    case actionTypes.SELINICLIENTLEADENGUTMMEDIUM:
      return action.utm_medium;
    default:
      return state;
  }
};

export const urlClientLeadCampaignsReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHCLIENTLEADURLCAMPAIGNS:
      return action.payload;
    case actionTypes.CLEARSEARCHCLIENTLEADURLCAMPAIGNS:
      return (state = []);
    case actionTypes.SELINICLIENTLEADENGUTMCAMPAIGN:
      return action.utm_campaign;
    default:
      return state;
  }
};

export const urlClientLeadTermReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHCLIENTLEADURLTERM:
      return action.payload;
    case actionTypes.CLEARSEARCHCLIENTLEADURLTERM:
      return (state = []);
    case actionTypes.SELINICLIENTLEADENGUTMTERM:
      return action.utm_term;
    default:
      return state;
  }
};

export const urlClientLeadContentReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHCLIENTLEADURLCONTENT:
      return action.payload;
    case actionTypes.CLEARSEARCHCLIENTLEADURLCONTENT:
      return (state = []);
    case actionTypes.SELINICLIENTLEADENGUTMCONTENT:
      return action.utm_content;
    default:
      return state;
  }
};

export const leadAuthorReducer = createCommonReducer(
  selectedArray.csmArray,
	{
		FETCH: actionTypes.FETCH_LEAD_CSM,
    ADD: actionTypes.ADD_LEAD_CSM, 
    SELECTINITIAL: actionTypes.SELECT_LEAD_CSM,
		CLEAR: actionTypes.CLEAR_LEAD_AUTHOR,
	},
	'id'
);

export const reguationManagerReducer = createCommonReducer(
	selectedArray.regulationData,
	{
		FETCH: actionTypes.FETCH_LEAD_REGULATION,
		ADD: actionTypes.ADD_LEAD_REGULATION,
		CLEARSEARCH: actionTypes.CLEAR_LEAD_REGULATION,
		SELECTINITIAL: actionTypes.SEL_LEAD_REGULATION,
		RESET: actionTypes.RESET_LEAD_REGULATION,
	},
  'uuid'
);