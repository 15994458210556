import React from "react";
import MultipleSelectDropDown from "../base/multiSelectDropdown";

const DayOfWeek = (props) => {
	return (
		<MultipleSelectDropDown
			options={props?.weekDays?.map((day, index) => ({
				key: index,
				text: day.label,
				value: day.value,
			}))}
			label="Day of week"
			placeholder="Day of week"
			value={props.value}
			onChange={props.onChange}
		/>
	);
};

export default DayOfWeek;
