import React from "react";
import { Table } from "semantic-ui-react";
import { connect } from "react-redux";
import { compose } from "redux";

import { columnSelected } from "../../../actions";
import * as actionTypes from "../../../actions/actionTypes";

const CampaignDetailsHeader = (props) => {
    const { filterData } = props;
    let conditionChkboxAppFilter = filterData.selCustomRangeCheckBox && filterData.isFilApplied && filterData.appliedDate === "Custom Date Range";

    const columnSelectedFun = (column) => {
        props.columnSelected({ dispatchType: actionTypes[props.reportActionType].COLUMN_SELECTED, column_selected: column, reportType: props.reportActionType });
    }

    return (
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell className="toggle-row"></Table.HeaderCell>
                <Table.HeaderCell
                    sorted={filterData.column_selected === "status" ? filterData.column_direction : null}
                    onClick={() => columnSelectedFun("status")}
                >
                    Status
                </Table.HeaderCell>
                <Table.HeaderCell className="custom-column-large"
                    sorted={filterData.column_selected === "name" ? filterData.column_direction : null}
                    onClick={() => columnSelectedFun("name")}
                >
                    Campaign / Ad Order
                </Table.HeaderCell>
                <Table.HeaderCell
                    sorted={filterData.column_selected === "start_date" ? filterData.column_direction : null}
                    onClick={() => columnSelectedFun("start_date")}
                >Start Date</Table.HeaderCell>
                <Table.HeaderCell
                    sorted={filterData.column_selected === "end_date" ? filterData.column_direction : null}
                    onClick={() => columnSelectedFun("end_date")}
                >
                    End Date
                </Table.HeaderCell>
                <Table.HeaderCell>Ad Unit</Table.HeaderCell>
                <Table.HeaderCell className="custom-column-large">
                    Line Item Name / Creative Name
                </Table.HeaderCell>
                <Table.HeaderCell>Media Type</Table.HeaderCell>
                <Table.HeaderCell>Creative Size</Table.HeaderCell>
                <Table.HeaderCell
                    className={conditionChkboxAppFilter ? "custom-column-large" : ""}
                    colSpan={conditionChkboxAppFilter ? 2 : 0}
                    textAlign={conditionChkboxAppFilter ? "center" : "right"}
                >
                    {props.reportName === "roadblock" ? "Roadblock Delivered" : "Impressions Delivered" }
                    {conditionChkboxAppFilter && (
                        <Table>
                            <Table.Row>
                                <Table.HeaderCell style={{ borderBottom: "none" }} textAlign="center"> Current </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "none" }} textAlign="center"> Previous </Table.HeaderCell>
                            </Table.Row>
                        </Table>
                    )}
                </Table.HeaderCell>
                {
                    props.reportName === "roadblock" &&
                    <Table.HeaderCell
                        className={conditionChkboxAppFilter ? "custom-column-large" : ""}
                        colSpan={conditionChkboxAppFilter ? 2 : 0}
                        textAlign={conditionChkboxAppFilter ? "center" : "right"}
                    >
                    Roadblock Impressions
                    {conditionChkboxAppFilter && (
                        <Table>
                            <Table.Row>
                                <Table.HeaderCell style={{ borderBottom: "none" }} textAlign="center"> Current </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "none" }} textAlign="center"> Previous </Table.HeaderCell>
                            </Table.Row>
                        </Table>
                    )}
                </Table.HeaderCell>
                }
                <Table.HeaderCell
                    className={
                        conditionChkboxAppFilter ? "custom-column-clicks" : ""
                    }
                    colSpan={conditionChkboxAppFilter ? 2 : 0}
                    textAlign={conditionChkboxAppFilter ? "center" : "right"}
                >
                    Clicks
                    {conditionChkboxAppFilter && (
                        <Table>
                            <Table.Row>
                                <Table.HeaderCell
                                    style={{ borderBottom: "none" }}
                                    textAlign="center"
                                >
                                    Current
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    style={{ borderBottom: "none" }}
                                    textAlign="center"
                                >
                                    Previous
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table>
                    )}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                    Click Through Rate
                </Table.HeaderCell>
            </Table.Row>
        </Table.Header>
    );
};

const mapStateToProps = () => {
    return {};
};

export default compose(connect(mapStateToProps, {
    columnSelected,
}))(CampaignDetailsHeader);