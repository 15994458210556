export const multipleOptions = [
    {
        key: "All",
        text: "All",
        value: "All",
        //keyvalue: "All",
        allkey: "All"
      },
    { key: 'Received', text: 'Received', value: 'Received', keyvalue: 'Received', allkey: "All"},

    // In-Reivew options
    { key: 'All', text: 'All', value: 'All', keyvalue: 'In Review'},
    { key: 'Invalid - Missing Member Data', text: 'Invalid - Missing Member Data', value: 'Invalid - Missing Member Data', keyvalue: 'In Review', allkey: "All" },
    { key: 'Invalid - Missing Org Data', text: 'Invalid - Missing Org Data', value: 'Invalid - Missing Org Data', keyvalue: 'In Review', allkey: "All" },
    { key: 'Invalid - Unmatched Organization', text: 'Invalid - Unmatched Organization', value: 'Invalid - Unmatched Organization' , keyvalue: 'In Review', allkey: "All"},
    { key: 'Invalid - Competitor', text: 'Invalid - Competitor', value: 'Invalid - Competitor', keyvalue: 'In Review', allkey: "All" },
    { key: 'Invalid - GEO', text: 'Invalid - GEO', value: 'Invalid - GEO', keyvalue: 'In Review', allkey: "All" },
    { key: 'Invalid - Unmatched Firmographic', text: 'Invalid - Unmatched Firmographic', value: 'Invalid - Unmatched Firmographic' , keyvalue: 'In Review', allkey: "All"},
    { key: 'Invalid - Unmatched Persona data', text: 'Invalid - Unmatched Persona data', value: 'Invalid - Unmatched Persona data', keyvalue: 'In Review', allkey: "All" },
    { key: 'Invalid - Unmatched Privacy data', text: 'Invalid - Unmatched Privacy data', value: 'Invalid - Unmatched Privacy data', keyvalue: 'In Review', allkey: "All"},
    { key: 'Invalid - Unmatched Other', text: 'Invalid - Unmatched Other', value: 'Invalid - Unmatched Other', keyvalue: 'In Review', allkey: "All" },
    { key: 'Filtered', text: 'Filtered', value: 'Filtered', keyvalue: 'In Review',allkey: "All"},
    { key: 'Excluded Firmographic', text: 'Excluded Firmographic', value: 'Excluded Firmographic', keyvalue: 'In Review',allkey: "All"},
    { key: 'Excluded Persona', text: 'Excluded Persona', value: 'Excluded Persona', keyvalue: 'In Review',allkey: "All"},
    { key: 'Unmatech Persona', text: 'Unmatech Persona', value: 'Unmatech Persona', keyvalue: 'In Review',allkey: "All"},

    { key: 'All', text: 'All', value: 'All', keyvalue: 'Accepted'},
    { key: 'Pending Delivery', text: 'Pending Delivery', value: 'Pending Delivery', keyvalue: 'Accepted', allkey: "All" },
    { key: 'Delivered', text: 'Delivered', value: 'Delivered', keyvalue: 'Accepted', allkey: "All" },
    
    // Reject options
    { key: 'All', text: 'All', value: 'All', keyvalue: 'Rejected'},
    { key: 'Bounced Email', text: 'Bounced Email', value: 'Bounced Email', keyvalue: 'Rejected', allkey: "All" },
    { key: 'SDxCentral Manual', text: 'SDxCentral Manual', value: 'SDxCentral Manual', keyvalue: 'Rejected', allkey: "All" },
    { key: 'Rejected - Client', text: 'Rejected - Client', value: 'Rejected - Client', keyvalue: 'Rejected', allkey: "All" },
    { key: 'Rejected - SDxCentral Excluded Competitors', text: 'Rejected - SDxCentral Excluded Competitors', value: 'Rejected - SDxCentral Excluded Competitors', keyvalue: 'Rejected', allkey: "All" },
    { key: 'Rejected - SDxCentral  Excluded Domains', text: 'Rejected - SDxCentral  Excluded Domains', value: 'Rejected - SDxCentral  Excluded Domains', keyvalue: 'Rejected', allkey: "All" },
    { key: 'Rejected - SDxCentral  Excluded Email', text: 'Rejected - SDxCentral  Excluded Email', value: 'Rejected - SDxCentral  Excluded Email', keyvalue: 'Rejected', allkey: "All" },
    { key: 'Rejected - SDxCentral Excluded GEO', text: 'Rejected - SDxCentral Excluded GEO', value: 'Rejected - SDxCentral Excluded GEO', keyvalue: 'Rejected', allkey: "All" },
    { key: 'Rejected - SDxCentral Non-Included GEO', text: 'Rejected - SDxCentral Non-Included GEO', value: 'Rejected - SDxCentral Non-Included GEO', keyvalue: 'Rejected', allkey: "All" },
    { key: 'Rejected - SDxCentral Non-Included Account or Domain', text: 'Rejected - SDxCentral Non-Included Account or Domain', value: 'Rejected - SDxCentral Non-Included Account or Domain', keyvalue: 'Rejected', allkey: "All" },
    { key: 'Rejected - SDxCentral Excluded Vertical Filters', text: 'Rejected - SDxCentral Excluded Vertical Filters', value: 'Rejected - SDxCentral Excluded Vertical Filters', keyvalue: 'Rejected', allkey: "All" },
    { key: 'Rejected - SDxCentral Non-Included Vertical Filters', text: 'Rejected - SDxCentral Non-Included Vertical Filters', value: 'Rejected - SDxCentral Non-Included Vertical Filters', keyvalue: 'Rejected', allkey: "All" },
    { key: 'Rejected - SDxCentral Excluded Account Filters', text: 'Rejected - SDxCentral Excluded Account Filters', value: 'Rejected - SDxCentral Excluded Account Filters', keyvalue: 'Rejected', allkey: "All" },
    { key: 'Rejected - SDxCentral Non-Included Organization Types', text: 'Rejected - SDxCentral Non-Included Organization Types', value: 'Rejected - SDxCentral Non-Included Organization Types', keyvalue: 'Rejected', allkey: "All" },
    { key: 'Rejected - SDxCentral Non-Included Organization Sizes', text: 'Rejected - SDxCentral Non-Included Organization Sizes', value: 'Rejected - SDxCentral Non-Included Organization Sizes', keyvalue: 'Rejected', allkey: "All" },
    { key: 'Rejected - SDxCentral Non-Included Estimated Revenue', text: 'Rejected - SDxCentral Non-Included Estimated Revenue', value: 'Rejected - SDxCentral Non-Included Estimated Revenue', keyvalue: 'Rejected', allkey: "All" },
    { key: 'Rejected - SDxCentral Excluded Seniorities', text: 'Rejected - SDxCentral Excluded Seniorities', value: 'Rejected - SDxCentral Excluded Seniorities', keyvalue: 'Rejected', allkey: "All" },
    { key: 'Rejected - SDxCentral Excluded Job Functions', text: 'Rejected - SDxCentral Excluded Job Functions', value: 'Rejected - SDxCentral Excluded Job Functions', keyvalue: 'Rejected', allkey: "All" },
    { key: 'Rejected - SDxCentral Excluded Job Titles', text: 'Rejected - SDxCentral Excluded Job Titles', value: 'Rejected - SDxCentral Excluded Job Titles', keyvalue: 'Rejected', allkey: "All" },
    { key: 'Rejected - SDxCentral Non-Included Seniorities', text: 'Rejected - SDxCentral Non-Included Seniorities', value: 'Rejected - SDxCentral Non-Included Seniorities', keyvalue: 'Rejected', allkey: "All" },
    { key: 'Rejected - SDxCentral Non-Included Job Functions', text: 'Rejected - SDxCentral Non-Included Job Functions', value: 'Rejected - SDxCentral Non-Included Job Functions', keyvalue: 'Rejected', allkey: "All" },
    { key: 'Rejected - SDxCentral Non-Included Job Titles', text: 'Rejected - SDxCentral Non-Included Job Titles', value: 'Rejected - SDxCentral Non-Included Job Titles', keyvalue: 'Rejected', allkey: "All" },
    { key: 'Rejected - SDxCentral Custom Question', text: 'Rejected - SDxCentral Custom Question', value: 'Rejected - SDxCentral Custom Question', keyvalue: 'Rejected', allkey: "All" },
];

export const options=[
    {
        id: "1",
        key: "0",
        text: "All",
        value: "All",
        allkey: "All"
      },
      {
        id: "2",
        key: "1",
        text: "Received",
        value: "Received",
        keyvalue: 'Received',
        allkey: "All"
      },
      {
        id: "3",
        key: "2",
        text: "In Review",
        value: "In Review",
        keyvalue: 'In Review',
        allkey: "All"
      },
      {
        id: "4",
        key: "3",
        text: "Accepted",
        value: "Accepted",
        keyvalue: 'Accepted',
        allkey: "All"
      },
      {
        id: "5",
        key: "4",
        text: "Rejected",
        value: "Rejected",
        keyvalue: 'Rejected',
        allkey: "All"
      },
];