import React from "react";
import DateAndFilter from "../components/DownloadComponent/DateAndFilter";
import Main from "../components/DownloadComponent/Main";


const Downloads = () => {
   return (
      <React.Fragment>
         <div className="page-name-comp">
            <p className="page-name" style={{ height: "50px" }}>Downloads</p>
         </div>
         <DateAndFilter />
         <div>
            <div className="table-heading-block">
               <div className="table-name">
                  <p>Download Report</p>
               </div>
            </div>
         </div>
         <Main/>
      </React.Fragment>
   );
};

export default Downloads;