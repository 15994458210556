import * as actionTypes from '../actions/actionTypes';

export const errorReducer = (state = null, action) => {
  switch (action.type) {
    case actionTypes.ERRORCODE:
      return action.payload;
    case actionTypes.REMOVEERRORCODE:
      state = null
      return state;
    default:
      return state;
  }
};
