import React, { useEffect, useState } from "react";
import { Form, Icon, Menu, MenuItem, Modal } from "semantic-ui-react";
import { 
    appLogout, 
    clearCampaignCache, 
    clearCacheAPI, 
    addFilterProfile, 
    editFilterProfile, 
    fetchappliedFilter, 
    deleteFilterProfile, 
    userSharedFilterProfile,
    fetchUserSharedFilterProfile,
    saveProfileAction,
} from "../actions";
import { connect, useDispatch } from "react-redux";
import { url } from "../api/urlHelper";
import * as actionTypes from "../actions/actionTypes";
import { ADD_NEW_PROFILE, CLEAR_COLUMNS_LABEL, DELETE_PROFILE, DOWNLOADS, EDIT_PROFILE, FILTER_PROFILE_NAME_ALREADY_EXISTS, FILTER_SESSION_MESSAGE, LOGOUT, PLEASE_ENTER_VALUE, REFRESH_CAMPAIGNS, SHARE_PROFILE } from "../components/config/const";
import SingleSelectDropDown from "../components/base/singleSelectDropDown";
import { getCookie } from "../utilities/envCommon";
import { Tooltip } from "@mui/material";
import CommonUserListMultiSelect from "../components/common/CommonUserListMultiSelect";

 const tooltipStyle = {
    fontSize: '16px',
 }

const UserProfile = (props) => {
    const [selectedProfile, setSelectedProfile] = useState("");
    const [modalInfo, setModalInfo] = useState({ isOpen: false, mode: "add", profileName: "", profileId: null });
    const [errorMessage, setErrorMessage] = useState("");

    const dispatch = useDispatch();

    useEffect(() => {
        if (props.filterProfile) {
            setSelectedProfile(props.filterProfile);
        } else if (props.userProfileData && props.userProfileData.length > 0) {
            setSelectedProfile(props.userProfileData[0].session_id);
        }
    }, [props.filterProfile, props.userProfileData]);

    const handleLogout = () => {
        props.openMenuBar();
        props.appLogout();
    };

    const handleClearCampCache = () => {
        props.openMenuBar();
        props.clearCampaignCache(url.clearCampaignsCacheURL, actionTypes.REFRESHCAMMPAIGNS);
    };

    const handleClearColumn = () => {
        props.openMenuBar();
        props.clearCacheAPI(url.clearColumnsURL, actionTypes.CLEAR_COLUMNS);
    };

    const onChangeFilterProfile = (e, { value }) => {
        props.openMenuBar(value);
        const date = new Date(getCookie("expiry") * 1000);
		document.cookie = `session_id=${value}; expires=${date}; path=/`;
        props.fetchappliedFilter(value);
        dispatch({ type: actionTypes.FILTER_PROFILE, payload: value });
        window.location.reload(true);
    };

    const onEditDeleteIconClick = (e, option, mode) => {
        e.stopPropagation();
        setModalInfo({ isOpen: true, mode, profileName: option.session_name, profileId: option.session_id });
    };

    const onAddIconClick = () => {
        setModalInfo({ isOpen: true, mode: "add", profileName: "", profileId: null });
    };

    const onShareIconClick = (e, option, mode) => {
        e.stopPropagation();
        props.saveProfileAction({ key: "requestUserList", value: [] });
        props.fetchUserSharedFilterProfile({ sessionId: option.session_id });
        setModalInfo({ isOpen: true, mode, profileName: option.session_name, profileId: option.session_id });
    };

    const handleMenuItemClick = (e) => {
        if (e.type === "click") {
            // Left click
            props.openMenuBar();
            // navigate("/downloads");
            window.open("/downloads", "_blank", "noopener,noreferrer");
        } else if (e.type === "contextmenu" || e.button === 1) {
            // Right click or middle click
            e.preventDefault(); // Prevent the default context menu from appearing
            window.open("/downloads", "_blank");
        }
    };

    const renderDropdownItem = (option) => {
        const isDefaultSession = option.session_id === getCookie("session_id");

        const handleOptionClick = (e) => {
            e.stopPropagation();
            onChangeFilterProfile(null, { value: option.session_id });
        };
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        return (
            <span className="set_middle">
                <Tooltip title={option.session_name} sx={tooltipStyle}>
                    <span className={`${!option.is_shared ? 'fixed-width' : ''} ${(isSafari && selectedProfile === option.session_id) ? 'custom_font_safari' : ''}`} onClick={handleOptionClick}>
                        {option.session_name}
                    </span>
                </Tooltip>
                {
                    !option.is_shared && 
                    <span className="icon-container">
                        <Icon 
                            className='only_cursor'
                            name="edit outline" 
                            onClick={(e) => onEditDeleteIconClick(e, option, "edit")} 
                        />
                        {
                            !isDefaultSession ?
                                <Icon 
                                    className='only_cursor'
                                    name="trash alternate outline" 
                                    onClick={(e) => onEditDeleteIconClick(e, option, "delete")} 
                                />
                            :
                                <Tooltip title={FILTER_SESSION_MESSAGE} sx={tooltipStyle}>
                                    <span>
                                        <Icon className='disabled cursor-not-allowed' name="trash alternate outline"/>
                                    </span>
                                </Tooltip>
                        }
                        <Icon 
                            className="only_cursor"
                            name="share square" 
                            onClick={(e) => onShareIconClick(e, option, "share")} 
                        />
                    </span>
                }
            </span>
        );
    };

    const onProfileClose = () => {
        setModalInfo({ ...modalInfo, isOpen: false })
        setErrorMessage("");
    };

    const handleSaveProfile = () => {
        const { profileName, mode, profileId } = modalInfo;

        if(mode !== "delete" && mode !== "share") {
            if (profileName === "") {
                return setErrorMessage(PLEASE_ENTER_VALUE);
            } else if (props.userProfileData.some(profile => profile.session_name.toLowerCase() === profileName.toLowerCase())) {
                return setErrorMessage(FILTER_PROFILE_NAME_ALREADY_EXISTS);
            }
        }
    
        const userData = props.authorized;
        const requestData = { userData, sessionName: profileName };

        if (mode === "add") {
            props.addFilterProfile(requestData);
        } else if (mode === "edit") {
            props.editFilterProfile({ ...requestData, sessionId: profileId });
        } else if (mode === "delete") {
            props.deleteFilterProfile({ ...requestData, sessionId: profileId });
        } else if (mode === "share") {
            props.userSharedFilterProfile({ ...requestData, sessionId: profileId, userId: props.filterData.requestUserList });
        }
    
        setModalInfo({ ...modalInfo, isOpen: false });
        setErrorMessage("");
    };
    

    return (
        <Menu vertical>
            <MenuItem>
                <span className="set_middle">
                    <SingleSelectDropDown
                        // onChange={onChangeFilterProfile}
                        options={
                            props.userProfileData && props.userProfileData.map(option => ({
                                key: option.session_id,
                                value: option.session_id,
                                text: option.session_name,
                                content: renderDropdownItem(option)
                            }))
                        }
                        value={selectedProfile}
                        style={{ marginRight: "1em" }}
                    />
                    <Icon className="only_cursor" name="add square" size="large" onClick={onAddIconClick} />
                </span>
            </MenuItem>
            <MenuItem
                name={REFRESH_CAMPAIGNS}
                onClick={handleClearCampCache}
            />
            <MenuItem
                name={CLEAR_COLUMNS_LABEL}
                onClick={handleClearColumn}
            />
            <MenuItem
                name={DOWNLOADS}
                onClick={handleMenuItemClick}
                onContextMenu={handleMenuItemClick}
                onMouseDown={handleMenuItemClick}
            />
            <MenuItem
                name={LOGOUT}
                onClick={handleLogout}
            />

            <Modal style={{ width: "25%" }} open={modalInfo.isOpen} onClose={onProfileClose}>
                <Modal.Header>
                    {({ add: ADD_NEW_PROFILE, edit: EDIT_PROFILE, delete: DELETE_PROFILE, share: `${SHARE_PROFILE}: ${modalInfo.profileName}` }[modalInfo.mode] || '')}
                </Modal.Header>
                <Modal.Content>
                    {(modalInfo.mode === "add" || modalInfo.mode === "edit") && (
                        <Form>
                            <label>Filter Profile Name</label>
                            <input
                                className='input_form-control'
                                label="Profile Name"
                                value={modalInfo.profileName}
                                onChange={(e) => {
                                    setModalInfo({ ...modalInfo, profileName: e.target.value });
                                    setErrorMessage("");
                                }}
                            />
                            {errorMessage && <label style={{ color: "red" }}>{errorMessage}</label>}
                        </Form>
                    )}
                    {modalInfo.mode === "delete" && (
                        <p>Are you sure you want to delete <b>{modalInfo.profileName}</b>?</p>
                    )}
                    {modalInfo.mode === "share" && (
                        <CommonUserListMultiSelect
                            value={props.filterData.requestUserList}
                            data={props.requestedUserData}
                            filterData={props.filterData}
                            fetchAction={actionTypes.FETCH_USER_LIST}
                            addAction={actionTypes.ADD_USER_LIST}
                            clearAction={actionTypes.CLEAR_USER_LIST}
                        />
                    )}
                </Modal.Content>
                <Modal.Actions>
                    <button className="apply-filter-button" onClick={handleSaveProfile}>APPLY</button>
                    <button className="cancel-date-button extra-space" onClick={onProfileClose}>CANCEL</button>
                </Modal.Actions>
            </Modal>


        </Menu>
    );
};

const mapStateToProps = (state) => {
    return {
        refreshCampaignStatus: state.filterData.refreshCampaignStatus,
        filterProfile: state.allFilterReportData.filterProfile,
        userProfileData: state.allFilterReportData.userFilterProfileData,
        authorized: state.authorized,
        filterData: state.allFilterReportData,
    };
};

export default connect(mapStateToProps, {
    appLogout,
    clearCampaignCache,
    clearCacheAPI,
    addFilterProfile,
    editFilterProfile,
    fetchappliedFilter,
    deleteFilterProfile,
    userSharedFilterProfile,
    fetchUserSharedFilterProfile,
    saveProfileAction,
})(UserProfile);