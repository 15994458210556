const ContentPerformanceHeaders = [
    { headerName: "PAGEVIEWS", headerKey: "pageviews" }, 
    { headerName: "% PAGEVIEWS", headerKey: "pageviews_percent" }, 
    { headerName: "UNIQUE PAGEVIEWS", headerKey: "unique_pageviews" }, 
    { headerName: "% UNIQUE PAGEVIEWS", headerKey: "unique_pageviews_percent" },
];

export const CPSummaryHeaderIPA = [
    { headerName: "", headerKey: "name" }, 
    ...ContentPerformanceHeaders
];

export const CPSummaryHeaderSenority = [
    { headerName: "Seniority", headerKey: "seniority" }, 
    ...ContentPerformanceHeaders
];

export const CPSummaryHeaderSector = [
    { headerName: "Sector", headerKey: "org_sector" }, 
    ...ContentPerformanceHeaders
];

export const CPDetailsHeader = [
    { headerName: "Page", headerKey: "page_title" },
    { headerName: "Author", headerKey: "author" },
    { headerName: "Publish Date", headerKey: "published_date" },
    { headerName: "Day Since Published", headerKey: "days_since_published" },
    { headerName: "Pageview Goal", headerKey: "pageviews_goal" },
    { headerName: "Pageviews Total", headerKey: "pageviews" },
    { headerName: "Pageviews Range", headerKey: "pageviews_range" },
    { headerName: "% Goal Achieved", headerKey: "pageview_goal_achieved" },
    { headerName: "On Pace", headerKey: "pace" },
]