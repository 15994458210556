import React from "react";
import Link from "./../../hyperlinks/link";
import { Table } from "semantic-ui-react";
import _ from "lodash";
import CreateTableRowComponent from "./CreateTableRowComponent";
import OverrideFilterToggle from "./OverrideFilterToggle";
import UTMParametersSection from "./UTMParametersSection";

const LeadEngagementSection = ({ data }) => {
   const userInfo = data.search_query;

   const createTableURLRows = (data, label, linkType) => {
      if (data.length > 0) {
         let tableRows = [];
         
         tableRows.push(<Table.Row key={0}><Table.Cell rowSpan={data.length+1}>{label}</Table.Cell></Table.Row>);
         data.forEach((val, index) => {
            tableRows.push(
               <Table.Row key={val.id}>
                  <Table.Cell key={index + "_value"}>
                     <Link type={linkType} urlParam={val.url} refValue={val.title}></Link>
                  </Table.Cell>
               </Table.Row>
            );
         });
     
         return tableRows;
      }
   };

   return (
      <React.Fragment>
         {<CreateTableRowComponent name="ASSET TYPE" value={userInfo.asset_type}/>}
         {<CreateTableRowComponent name="ASSET TYPE" value={userInfo.lead_partner}/>}
         {
            !_.isEmpty(data.page_title) && 
            <Table.Body>{createTableURLRows(data.page_title, 'ACCOUNT', 'sidebar')}</Table.Body>
         }
         {<CreateTableRowComponent name="ENGAGEMENT/LEAD STATUS" value={userInfo.verification_status}/>}
         {<CreateTableRowComponent name="VERIFICATION STATE" value={userInfo.verification_state}/>}
         <OverrideFilterToggle data={data} />
         <UTMParametersSection data={data} />
      </React.Fragment>
   );
};

export default LeadEngagementSection;
