import React, { useEffect } from "react";
import { selectValue } from "../../actions";
import { connect } from "react-redux";
import "../../css/common.css";
import * as actionTypes from "../../actions/actionTypes";

const EmailPopUp = (props) => {

    const filterActions = {
        ads_common: { action: props.selectValue, dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key: props.emailKey, value: false },
    };

    const handleFilterAction = (props) => {
        const filterAction = filterActions[props.table];
        if (filterAction) {
            const { action, dispatchType, reportType, key, value } = filterAction;
            action({ dispatchType, reportType, key, value });
        }
    };

	useEffect(() => {
		const timeId = setTimeout(() => {
            handleFilterAction(props);
            clearTimeout(timeId);
		}, 5000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.cardPopup]);

	return (
		<div className={props.cardPopup ? "ui card" : "card-popup-close"}>
			<div className="content">
				<div
					className="card-close-icon"
					onClick={() => handleFilterAction(props)}
				>
					<i className="material-icons grey">clear</i>
				</div>
				<div className="header">{props.csvExcel? "Generate CSV" : "Generate XLSX"}</div>
			</div>
			<div className="content">
				{/* <h4 className="ui sub header">Current Email</h4> */}
				<div className="ui small feed">
					<div className="event">
						<div className="content">
							<div className="summary">
								<p>{props.csvExcel ? "CSV file is being generated and will be send shortly" : "XLSX file is being generated and will be send shortly"}</p>
							</div>
						</div>
					</div>
					<div className="event">
						<div className="content">
							<div className="summary">
								<a href={props.email}>{props.email}</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default connect(null, {
    selectValue,
})(EmailPopUp);
