import React from "react";
import { connect } from "react-redux";

import * as constName from "../../components/config/const";
import Main from "../../components/DecisionInsightComponent/ProPlus/Main";

const DIPro = (props) => {
    return (
        <Main
            reportActionType={constName.MODULE_DI_PRO}
            reportName={constName.DI_PRO_REPORT_NAME}
            filterData={props.filterData}
            label={constName.DI_PRO_LABEL}
        />
    );
};

const mapStateToProps = ({ commonFilterData }) => {
    return {
        filterData: commonFilterData[constName.MODULE_DI_PRO],
    };
};

export default connect(mapStateToProps)(DIPro);