import * as actionTypes from "../actions/actionTypes";
import {
	getDisplayDate,
	getYesterdaysDate,
	getLastDays,
	getStartEndDates,
	getName,
	getTimeEng,
	getDateEng,
} from "../utilities/common";
import { siteValue } from "../constants";
import _ from "lodash";
import createCommonReducer from "./createCommonReducer";

const topicFilterData = {
	startDate: getDisplayDate(getLastDays(0)),
	endDate: getDisplayDate(getYesterdaysDate()),
	previousStartDate: "",
	previousEndDate: "",
	selCustomRangeCheckBox: false, 
	appliedDate: "2",
	prevendDate: getDisplayDate(getYesterdaysDate()),
	prevstartDate: getDisplayDate(getLastDays(7)),
	prevAppliedDate: "",
	organization: [],
	campaign: [],
	order: [],
	selCountry: [],
	site: siteValue,
	campaignStatus: "active",
	isFilApplied: false,
	engagedOrganizations: [],
	excludeQACampaign: false,
	excludeInternalCampaign: false,
	email:"",
	selectSidebarDetailTable: false,
	showTopicChart: false,
	firmographicToggle: false,
	selEnagedMember: [],
	selSector: [],
	selIndustryGroup: [],
	selIndustry: [],
	selSubIndustry: [],
	selOrgType: [],
	selOrgSize: [],
	selOrgRevenue: [],
	personaToggle: false,
	selSenioritiesIN: [],
	selSenioritiesEX: [],
	selJobFunctionsEX: [],
	selJobFunctionsIN: [],
	selJobTitlesIN: "",
	selJobTitlesEX: "",
	topicIsIncludeParent: false,
	topicIsIncludeChild: false,
	detailTableSidebarToggleValue: false,
	deliverdFilterState: "",
	deliverdFilterStateOptions: [],
	pacingFilter: "",
	trafficType: "",
	competitorFiltering: false,
	setCSM: [],
};

const topicFetchData = {
	isFilApplied: false,
	topicSummary: {},
	topicDetail: {},
	sidebarDetail:[],
	isLoadingTopicSummary: false,
	isLoadingTopicDetail: false,
	isLoadingSidebarDetail: false,
	topicDetailCsv: "",
	topicCampaignCsv: "",
	isLoadingTopicCsv: false,
	isLoadingTopicCampaignCsv: false,
	column: null,
	direction: null,
	summaryPageCount: "",
	detailPageCount: "",
	sidebardetailPageCount:"",
	topichubChart: [],
	emailSidebarSummary: "",
	emailSidebarDetail:"",
	emailSidebarCampaignDetail:"",
	cardPopupSidebarSummary: "",
	cardPopupSidebarCampaignDetail:"",
	cardPopupSidebarDetail: "",
	postEmailSidebarUpdate:"",
	postEmailCsvSidebarUpdate: "",
	postEmailUpdateSidebarSummary:"",
	postEmailSidebarCampaignUpdate:"",
	isLoadingChart: false,
	getDynamicHeaderData: [],
	dynamicHeaderArray: [],
	resSidebarCampaignSummaryValue: null,
	resSidebarCampaignDetailsValue: null,
	resSidebarDetailsValue: null,
	detailDirection: null,
	downloadFileName: ""
};

const selectedTags = {
	orgTagsIn: [],
	orgTagsEx: [],
	topicTagsIn: [],
	topicTagsEx: [],
	placementTagsIn: [],
	placementTagsEx: [],
	senioritiesIN: [],
	senioritiesEX: [],
	jobfunctionsIN: [],
	jobfunctionsEX: [],
	sector: [],
	industry_group: [],
	industry: [],
	sub_industry: [],
	orgsType: [],
	orgsSize: [],
	orgsRevenue: [],
	csmArray: [],
};

export const topicFilterDataReducer = (state = topicFilterData, action) => {
	switch (action.type) {
		case actionTypes.SELECTED_SIDEBAR_TOGGLE:
      		return { ...state, [action.payload.key]: action.payload.value };
		case actionTypes.SELTOPICSTARTDATE:
			return { ...state, startDate: action.payload };
		case actionTypes.SELTOPICENDDATE:
			return { ...state, endDate: action.payload };
		case actionTypes.SELTOPICPREVSTARTDATE:
			return { ...state, previousStartDate: action.payload };
		case actionTypes.SELTOPICPREVENDDATE:
			return { ...state, previousEndDate: action.payload };
		case actionTypes.SELTOPICCUSTOMRANGECHXBOX:
			return { ...state, selCustomRangeCheckBox: action.payload };
		case actionTypes.SELTOPICPREVAPPLIEDDATE:
			return { ...state, prevAppliedDate: action.payload };
		case actionTypes.SELTOPICENDDATEPREV:
			return { ...state, prevendDate: action.payload };
		case actionTypes.SELTOPICSTARTDATEPREV:
			return { ...state, prevstartDate: action.payload };
		case actionTypes.APPLYTOPICDATE:
			// let datesData = getStartEndDates(action.payload);
			// let stateData = state
			// stateData.appliedDate = action.payload;
			// stateData.prevAppliedDate = action.payload;
			// stateData.startDate = datesData.startDate;
			// stateData.endDate = datesData.endDate;
			// stateData.prevstartDate = datesData.startDate;
			// stateData.prevendDate = datesData.endDate;
			// if(action.payload !== "Custom Date Range"){
			// 	stateData.selCutomRangeChkBox = false
			// 	//stateData.checkboxState = false
			// }
			return { ...state, appliedDate: action.payload };	
		case actionTypes.SELTOPICORGS:
			return { ...state, organization: action.payload };
		case actionTypes.SELTOPICCAMPAIGNS:
			return { ...state, campaign: action.payload };
		case actionTypes.SELTOPICORDER:
			return { ...state, order: action.payload };
		case actionTypes.RESETSELTOPICCAMPAIGNS:
			return { ...state, campaign: action.payload };
		case actionTypes.SELTOPICCOUNTRY:
			return { ...state, selCountry: action.payload };
		case actionTypes.SELTOPICSITE:
			return { ...state, site: action.payload };
		case actionTypes.SELTOPICSTATUS:
			return { ...state, campaignStatus: action.payload };
		case actionTypes.SELTOPICENGORGS:
			return { ...state, engagedOrganizations: action.payload };
		case actionTypes.SELTOPQATOGGLE:
			return {
				...state,
				excludeQACampaign: !state.excludeQACampaign,
			};
		case actionTypes.SELTOPINTOGGLE:
			return {
				...state,
				excludeInternalCampaign: !state.excludeInternalCampaign,
			};
		case actionTypes.SELTOPICFIRMOGRAPHIC:
			return {
				...state,
				firmographicToggle: !state.firmographicToggle,
			};
		case actionTypes.SELTOPICPERSONA:
			return {
				...state,
				personaToggle: !state.personaToggle,
			};
		case actionTypes.SELSIDEBARENGAGEDMEMBER:
			return { ...state, selEnagedMember: action.payload };
		case actionTypes.APPLYTOPICFILTER:
			return { ...state, isFilApplied: true };
		case actionTypes.SELSIDEBARDETAILTABLETOGGLE:
				return {
					...state,
					selectSidebarDetailTable: !state.selectSidebarDetailTable,
				};
		case actionTypes.SHOWTOPICCHARTS:
				return {
					...state,
					showTopicChart: !state.showTopicChart,
				};
		case actionTypes.SELTOPICPARENTTOOGLE:
			return { ...state, topicIsIncludeParent: !state.topicIsIncludeParent };
		case actionTypes.SELTOPICCHILDTOOGLE:
			return { ...state, topicIsIncludeChild: !state.topicIsIncludeChild };

		case actionTypes.SELTOPICORGTYPE:
			return { ...state, selOrgType: action.payload };
		case actionTypes.SELTOPICSECTOR:
			return { ...state, selSector: action.payload };
		case actionTypes.SELTOPICINDUSTRYGROUP:
			return { ...state, selIndustryGroup: action.payload };
		case actionTypes.SELTOPICINDUSTRY:
			return { ...state, selIndustry: action.payload };
		case actionTypes.SELTOPICSUBINDUSTRY:
			return { ...state, selSubIndustry: action.payload };
		case actionTypes.SELTOPICORGSIZE:
			return { ...state, selOrgSize: action.payload };
		case actionTypes.SELTOPICORGSREVENUE:
			return { ...state, selOrgRevenue: action.payload };
		case actionTypes.SELSIDEBARSENIORITIESIN:
				return { ...state, selSenioritiesIN: action.payload };
		case actionTypes.SELSIDEBARSENIORITIESEX:
				return { ...state, selSenioritiesEX: action.payload };
		case actionTypes.SELSIDEBARJOBFUNCTIONSIN:
				return { ...state, selJobFunctionsIN: action.payload };
		case actionTypes.SELSIDEBARJOBFUNCTIONSEX:
				return { ...state, selJobFunctionsEX: action.payload };
		case actionTypes.SELSIDEBARJOBTITLESEX:
				return { ...state, selJobTitlesEX: action.payload };
		case actionTypes.SELSIDEBARJOBTITLESIN:
				return { ...state, selJobTitlesIN: action.payload };	
		case actionTypes.DETAILTABLESIDEBARTOGGLLEVALUE:
			return { ...state, detailTableSidebarToggleValue: action.payload };	
		case actionTypes.SELSIDEBARDELIVERYSTATE:
			return { ...state, deliverdFilterState: action.payload };
		case actionTypes.FETCHDELIVERYSTATE:
			const deliveryStatusOpt = action.payload && action.payload.delivery_states ? action.payload.delivery_states : []
			return { ...state, deliverdFilterStateOptions: deliveryStatusOpt };
		case actionTypes.SELSIDEBARPACINGFILTERVALUE:
			return { ...state, pacingFilter: action.payload };
		case actionTypes.SELSIDEBARTRAFFICFILTERVALUE:
			return { ...state, trafficType: action.payload }; 
		case actionTypes.FETCHINITOPICFILTER:
			let data = action.payload;
			let orgType = data?.organization_types?.map( a => a.charAt(0).toLowerCase() + a.substr(1));
			let orgRevenue = data?.organization_revenues?.map( a => a.toLowerCase());

			let dates = data.applied_date_filter
				? getStartEndDates(data.applied_date_filter)
				: state;
			return {
				...state,
				startDate:
					data.applied_date_filter === "Custom Date Range"
						? getDisplayDate(data.start_date)
						: dates.startDate,
				endDate:
					data.applied_date_filter === "Custom Date Range"
						? getDisplayDate(data.end_date)
						: dates.endDate,
				prevstartDate:
					data.applied_date_filter === "Custom Date Range"
						? getDisplayDate(data.start_date)
						: dates.startDate,
				prevendDate:
					data.applied_date_filter === "Custom Date Range"
						? getDisplayDate(data.end_date)
						: dates.endDate,
				campaign: data.campaigns ? data.campaigns : [],
				appliedDate: data.applied_date_filter
					? data.applied_date_filter
					: state.appliedDate,
				site: data.site ? data.site : "all",
				assetType: data.asset_type ? data.asset_type : "all",
				tag_type: data.tag_type,
				topic_tags: data.topic_tags,
				organization: data.organizations ? data.organizations : [],
				engagedOrganizations: data.engaged_organizations
					? data.engaged_organizations
					: [],
				selCountry: data.country ? data.country : [],
				campaignStatus: data.status ? data.status : "all",
				isFilApplied: data.isFilApplied,
				excludeQACampaign: data.exclude_qa,
				excludeInternalCampaign: data.exclude_internal,
				selEnagedMember: data.engaged_members ? data.engaged_members : [],
				selSector: data.sector ? data.sector : [],
				selIndustryGroup: data.industry_group ? data.industry_group : [],
				selIndustry: data.industry ? data.industry : [],
				selSubIndustry: data.sub_industry ? data.sub_industry : [],
				//selOrgType: data.organization_types ? data.organization_types : [],
				selOrgType: orgType,
				selOrgSize: data.organization_sizes ? data.organization_sizes : [],
				selOrgRevenue: orgRevenue,
				selSenioritiesIN: data.seniorities.included_tags ? data.seniorities.included_tags : [],
				selSenioritiesEX: data.seniorities.excluded_tags ? data.seniorities.excluded_tags : [],
				selJobFunctionsEX: data.job_functions.excluded_tags ? data.job_functions.excluded_tags : [],
				selJobFunctionsIN: data.job_functions.included_tags ? data.job_functions.included_tags : [],
				selJobTitlesIN: data.job_title.included_job_title,
				selJobTitlesEX: data.job_title.excluded_job_title,
				personaToggle : data?.persona_toggle,
				firmographicToggle: data?.firmographic_toggle,
				pacingFilter: data.pace ? data.pace : "",
				deliverdFilterState: data.delivery_state ? data.delivery_state : "",
				trafficType: data.traffic_type ? data.traffic_type : "all",
				csm: data.csm ? data.csm : [],
			};

		case actionTypes.CLEARTOPICFILTER:
			return {
				startDate: getDisplayDate(getLastDays(7)),
				endDate: getDisplayDate(getYesterdaysDate()),
				previousStartDate: "",
				previousEndDate: "",
				selCustomRangeCheckBox: false,
				appliedDate: "7",
				prevendDate: getDisplayDate(getYesterdaysDate()),
				prevstartDate: getDisplayDate(getLastDays(7)),
				prevAppliedDate: "",
				organization: [],
				selCountry: [],
				campaign: [],
				order: [],
				site: siteValue,
				campaignStatus: "active",
				isFilApplied: false,
				engagedOrganizations: [],
				excludeQACampaign: false,
				excludeInternalCampaign: false,
				email:"",
				personaToggle: false,
				firmographicToggle: false,
				selEnagedMember: [],
				selSector: [],
				selIndustryGroup: [],
				selIndustry: [],
				selSubIndustry: [],
				selOrgType: [],
				selOrgSize: [],
				selOrgRevenue: [],
				selSenioritiesIN: [],
				selSenioritiesEX: [],
				selJobFunctionsEX: [],
				selJobFunctionsIN: [],
				selJobTitlesIN: "",
				selJobTitlesEX: "",
				deliverdFilterState: "",
				pacingFilter: "",
				deliverdFilterStateOptions: action.payload,
				trafficType: "all",
				setCSM: [],
			};
			case actionTypes.EMAIL:
			return {
				...state,
				email: action.payload,
			};
		default:
			return state;
	}
};

export const topicFetchDataReducer = (state = topicFetchData, action) => {
	switch (action.type) {
		case actionTypes.APPLYTOPICFILTER:
			return {
				...state,
				isFilApplied: true,
				topicSummary: {},
				topicDetail: {},
				isLoadingTopicDetail: true,
				isLoadingTopicSummary: true,
				isLoadingSidebarDetail: true,
				summaryPageCount: 2,
				detailPageCount: 2,
				sidebardetailPageCount:2,
				topichubChart: [],
				isLoadingChart: true,
				sidebarDetail:[],
				resSidebarCampaignSummaryValue: null,
				resSidebarCampaignDetailsValue: null,
				resSidebarDetailsValue: null
			};
		// case actionTypes.UPDATEDCHARTSTATUS:
		// 		return{
		// 			...state, chartStatus: {
		// 				...state.chartStatus, ...action.payload
		// 			}
		// 		}
		case actionTypes.TOPICDETAIL:
			if (action.payload === null) {
				return {
					...state,
					isLoadingTopicDetail: false,
				};
			} else {
				return {
					...state,
					topicDetail: action.payload,
					// {
					//   ..._.mapKeys(action.payload, "uuid"),
					// },
					isLoadingTopicDetail: false,
				};
			}
		case actionTypes.MORETOPICDETAILLOADING:
			return {
				...state,
				isLoadingTopicDetail: true,
			};
		case actionTypes.MORETOPICDETAILDATA:
			if (action.payload === null) {
				return {
					...state,
					isLoadingTopicDetail: false,
					resSidebarCampaignDetailsValue: action.resSidebarCampaignDetailsValue
				};
			} else {
				return {
					...state,
					topicDetail: [...state.topicDetail, ...action.payload],
					isLoadingTopicDetail: false,
				};
			}
		case actionTypes.SORTTOPICDETAIL:
			if (state.column === action.column) {
				return {
					...state,
					topicDetail: state.topicDetail.reverse(),
					direction:
						state.direction === "ascending" ? "descending" : "ascending",
				};
			}
			return {
				...state,
				column: action.column,
				topicDetail: _.sortBy(state.topicDetail, [action.column]),
				direction: "ascending",
			};
		case actionTypes.CLEARTOPICDETAILSORT:
			return {
				...state,
				column: null,
				direction: null,
				detailDirection: null
			};
		case actionTypes.TOPICSUMMARY:
			if (action.payload === null) {
				return {
					...state,
					isLoadingTopicSummary: false,
				};
			} else {
				return {
					...state,
					topicSummary: action.payload.map((element)=>{
						return {
							...element,
							impressions_timing_met: element.impressions_timing_met ? true : false
						}
					}),
					isLoadingTopicSummary: false,
				};
			}
		case actionTypes.MORETOPICSUMMARYLOADING:
			return {
				...state,
				isLoadingTopicSummary: true,
			};
		case actionTypes.MORETOPICSUMMARYDATA:
			if (action.payload === null) {
				return {
					...state,
					isLoadingTopicSummary: false,
					resSidebarCampaignSummaryValue: action.resSidebarCampaignSummaryValue
				};
			} else {
				return {
					...state,
					topicSummary: [...state.topicSummary, ...action.payload.map((element)=>{
						return {
							...element,
							impressions_timing_met: element.impressions_timing_met ? true : false

						}
					}),],
					
					isLoadingTopicSummary: false,
				};
			}
		case actionTypes.TOPICSUMMARYCSV:
			return {
				...state,
				topicCampaignCsv: action.csv,
				isLoadingTopicCampaignCsv: false,
				downloadFileName: action.downloadFileName
			};
		case actionTypes.TOPICCAMPAIGNCSVLOADING:
			return {
				...state,
				isLoadingTopicCampaignCsv: true,
			};
		case actionTypes.TOPICDETAILCSV:
			return {
				...state,
				topicDetailCsv: action.csv,
				isLoadingTopicCsv: false,
				downloadFileName: action.downloadFileName
			};
		case actionTypes.TOPICCSVLOADING:
			return {
				...state,
				isLoadingTopicCsv: true,
			};
			case actionTypes.SORTTOPICSUMMARY:
				const isAscending = state.direction === "ascending";
				const newDirection = isAscending ? "descending" : "ascending";
				let sortedTopicSummary;

				const sortByNestedName = (data, key) =>
					key.split('.').reduce((acc, part) => acc && acc[part], data) 
					? key.split('.').reduce((acc, part) => acc && acc[part], data).toUpperCase() 
					: key.split('.').reduce((acc, part) => acc && acc[part], data);

				const sortByPace = (data) =>
					data[action.column] === '' ? data["red"] : data[action.column];

				const defaultSort = (item) =>
					typeof item[action.column] === "string" ? item[action.column].toLowerCase() : item[action.column];

				if (state.column === action.column) {
					if (["order.name", "client_success_manager.name"].includes(action.column)) {
						sortedTopicSummary = _.orderBy(state.topicSummary, 
							[data => sortByNestedName(data, action.column)], 
							[isAscending ? "desc" : "asc"]);
					} else if (action.column === "pace") {
						sortedTopicSummary = _.orderBy(state.topicSummary, [sortByPace], [isAscending ? "desc" : "asc"]);
					} else {
						sortedTopicSummary = _.orderBy(state.topicSummary, [defaultSort], [isAscending ? "desc" : "asc"]);
					}
				} else {
					sortedTopicSummary = _.orderBy(state.topicSummary, [defaultSort], [newDirection]);
				}

				return {
					...state,
					column: action.column,
					topicSummary: sortedTopicSummary,
					direction: newDirection,
				};
		
		case actionTypes.SETTOPSUMMARYPGCOUNT:
			return { ...state, summaryPageCount: state.summaryPageCount + 1 };
		case actionTypes.SETTOPDETAILTABLEPGCOUNT:
			return { ...state, detailPageCount: state.detailPageCount + 1 };
		case actionTypes.CLEARTOPICSUMMARYSORT:
			return {
				...state,
				column: null,
				direction: null,
			};
		case actionTypes.TOPICHUBCHART:
			return {
				...state,
				topichubChart: action.payload,
				isLoadingChart: false,
			};
			case actionTypes.SIDEBARDETAIL:
				if(action.payload === null){
					return{
					  ...state,
					  isLoadingSidebarDetail: false,
					}
				  } else {
					return{
						...state,
						sidebarDetail: action.payload.map((element)=>{
							return {
								...element,
								topic_tags: element.topic_tags ? element.topic_tags : "N/A",
								exclude_topic_tags: element.exclude_topic_tags ? element.exclude_topic_tags : "N/A",
								organization_tags: element.organization_tags ? element.organization_tags : "N/A",
								exclude_organization_tags:element.exclude_organization_tags ? element.exclude_organization_tags : "N/A",
								placement_tags:element.placement_tags ? element.placement_tags : "N/A",
								exclude_placement_tags:element.exclude_placement_tags ? element.exclude_placement_tags : "N/A",
								location:element.location ? element.location : "N/A",
								widgets:element.widgets ? element.widgets : "N/A",
								links:  element.widgets ? element.widgets.filter((widget) => widget.links !== null) : "N/A",
								slot:element.slot ? element.slot : "N/A",
								client_link_title:element.client_link_title ? element.client_link_title : "N/A",
								action:element.action ? element.action : "N/A",
								ip_address:element.ip_address ? element.ip_address : "N/A",
								member_country:element.member_country  ? element.member_country : "N/A",
								organization_type:element.organization_type ? element.organization_type : "N/A",
								estimated_revenue:element.estimated_revenue ? element.estimated_revenue : "N/A",
								organization_size:element.organization_size ? element.organization_size : 0,
								organization_sector:element.organization_sector ? element.organization_sector : "N/A",
								organization_industry_group:element.organization_industry_group ? element.organization_industry_group : "N/A",
								organization_industry:element.organization_industry ? element.organization_industry : "N/A",
								organization_sub_industry:element.organization_sub_industry ? element.organization_sub_industry : "N/A",
								seniority:element.seniority ? element.seniority : "N/A",
								job_function:element.job_function ? element.job_function : "N/A",
								job_title: element.job_title ? element.job_title : "N/A",
								widget_type: element.widgets ? getName(element.widgets, "widget_type"): "N/A",
								widget_title: element.widgets ? getName(element.widgets, "title") : "N/A" 
							}
						}),
						isLoadingSidebarDetail: false,
					}
				  }
				
		case actionTypes.SIDEBARDETAILSORT:
			const directionValueSidebarDetail = state.detailDirection === "ascending" ? "desc" : "asc";
			const sidebarDetail= _.orderBy(state.sidebarDetail, [(item) => typeof(item[action.column]) === "string" ? item[action.column].toLowerCase() : item[action.column]], [directionValueSidebarDetail])
			const detailDirection= state.detailDirection === "ascending" ? "descending" : "ascending"

			if (state.column === action.column) {
				if(action.column === 'campaign_name') {
					return {
						...state,
						sidebarDetail: state.detailDirection === "ascending" ? 
						_.orderBy(state.sidebarDetail, [data=> (data.campaign_data[0])['name']], ['desc'])
					  :
						_.orderBy(state.sidebarDetail, [data=> (data.campaign_data[0])['name']], ['asc']),
						detailDirection:
							state.detailDirection === "ascending" ? "descending" : "ascending",
					};
				} else if (action.column === 'widget_type') {
					return {
						...state,
						sidebarDetail: state.detailDirection === "ascending" ? 
						_.orderBy(state.sidebarDetail, [data=> (data.widgets[0])['widget_type']], ['desc'])
					  :
					 	 _.orderBy(state.sidebarDetail, [data=> (data.widgets[0])['widget_type']], ['asc']),
						detailDirection:
							state.detailDirection === "ascending" ? "descending" : "ascending",
					};
				} else if (action.column === 'widget_title') {
					return {
						...state,
						sidebarDetail: state.detailDirection === "ascending" ? 
						_.orderBy(state.sidebarDetail, [data=> (data.widgets[0])['title']], ['desc'])
					  :
					 	 _.orderBy(state.sidebarDetail, [data=> (data.widgets[0])['title']], ['asc']),
						detailDirection:
							state.detailDirection === "ascending" ? "descending" : "ascending",
					};
				} else if (action.column === 'client_link_title') {
					return {
						...state,
						sidebarDetail: state.detailDirection === "ascending"
						  ? _.orderBy(state.sidebarDetail, [(data) => _.get(data, 'links[0].links[0].text', '')], ['desc'])
						  : _.orderBy(state.sidebarDetail, [(data) => _.get(data, 'links[0].links[0].text', '')], ['asc']),
						detailDirection: state.detailDirection === "ascending" ? "descending" : "ascending",
					  };
				} else if(action.column === "date"){
					return {
						...state,
						column: action.column,
						sidebarDetail: state.detailDirection === "ascending" ?
						  _.orderBy(state.sidebarDetail, [data => getDateEng(data.created_at).toLowerCase()], ['desc'])
						  :
						  _.orderBy(state.sidebarDetail, [data => getDateEng(data.created_at).toLowerCase()], ['asc']),
						  detailDirection: state.detailDirection === "ascending" ? "descending" : "ascending",
					  };
				} else if(action.column === "time"){
					return {
						...state,
						column: action.column,
						sidebarDetail: state.detailDirection === "ascending" ?
						  _.orderBy(state.sidebarDetail, [data => getTimeEng(data.created_at).toLowerCase()], ['desc'])
						  :
						  _.orderBy(state.sidebarDetail, [data => getTimeEng(data.created_at).toLowerCase()], ['asc']),
						  detailDirection: state.detailDirection === "ascending" ? "descending" : "ascending",
					  };
				} else {
					return {
						...state,
						sidebarDetail,
						detailDirection,
					};
				}	
			}
			return {
				...state,
				column: action.column,
				sidebarDetail,
				detailDirection,
			};

		case actionTypes.CLEARSIDEBARDETAILSORT:
			return {
				...state,
				column: null,
				detailDirection: null,
			};	
				
		case actionTypes.MORESIDEBARDETAILDATA:
			if (action.payload === null) {
				return {
					...state,
					isLoadingSidebarDetail: false,
					resSidebarDetailsValue: action.resSidebarDetailsValue
				};
			} else {
				return {
					...state,
					sidebarDetail: [...state.sidebarDetail, ...action.payload.map((element)=>{
						return {
							...element,
							topic_tags: element.topic_tags ? element.topic_tags : "N/A",
							exclude_topic_tags: element.exclude_topic_tags ? element.exclude_topic_tags : "N/A",
							organization_tags: element.organization_tags ? element.organization_tags : "N/A",
							exclude_organization_tags:element.exclude_organization_tags ? element.exclude_organization_tags : "N/A",
							placement_tags:element.placement_tags ? element.placement_tags : "N/A",
							exclude_placement_tags:element.exclude_placement_tags ? element.exclude_placement_tags : "N/A",
							location:element.location ? element.location : "N/A",
							widgets:element.widgets ? element.widgets : "N/A",
							links:  element.widgets ? element.widgets.filter((widget) => widget.links !== null) : "N/A",
							slot:element.slot ? element.slot : "N/A",
							client_link_title:element.client_link_title ? element.client_link_title : "N/A",
							action:element.action ? element.action : "N/A",
							ip_address:element.ip_address ? element.ip_address : "N/A",
							member_country:element.member_country  ? element.member_country : "N/A",
							organization_type:element.organization_type ? element.organization_type : "N/A",
							estimated_revenue:element.estimated_revenue ? element.estimated_revenue : "N/A",
							organization_size:element.organization_size ? element.organization_size : 0,
							organization_sector:element.organization_sector ? element.organization_sector : "N/A",
							organization_industry_group:element.organization_industry_group ? element.organization_industry_group : "N/A",
							organization_industry:element.organization_industry ? element.organization_industry : "N/A",
							organization_sub_industry:element.organization_sub_industry ? element.organization_sub_industry : "N/A",
							seniority:element.seniority ? element.seniority : "N/A",
							job_function:element.job_function ? element.job_function : "N/A",
							job_title: element.job_title ? element.job_title : "N/A",
							widget_type: element.widgets ? getName(element.widgets, "widget_type"): "N/A",
							widget_title: element.widgets ? getName(element.widgets, "title") : "N/A" 
						}
					}),],
					isLoadingSidebarDetail: false,
				};
			}

		case actionTypes.MORESIDEBARDETAILLOADING:
			return {
				...state,
				isLoadingSidebarDetail: true,
			};

		case actionTypes.SETSIDEBARDETAILPGCOUNT:
			return { ...state, sidebardetailPageCount: state.sidebardetailPageCount + 1 };

		case actionTypes.SIDEBAREXCELCAMPAIGNSUMMARY:
			return{...state,
			emailSidebarSummary: action.payload.email,
			cardPopupSidebarSummary: action.payload.class,	
		}
		case actionTypes.SIDEBAREXCELDETAIL:
			return{...state,
				emailSidebarDetail: action.payload.email,
				cardPopupSidebarDetail: action.payload.class,	
			}
		case actionTypes.SIDEBARCSVEXCELDETAIL:
			return{...state,
				emailSidebarDetail: action.payload.email,
				cardPopupSidebarDetail: action.payload.class,	
			}
		case actionTypes.SIDEBAREXCELCAMPAIGNDETAIL:
			return{...state,
				emailSidebarCampaignDetail:action.payload.email,
				cardPopupSidebarCampaignDetail:	action.payload.class,

			}	
		case actionTypes.SIDEBARDETAILEXCEL:
			if (action.payload === "/reports/sidebar/summary") {
				return {
					...state,
					postEmailUpdateSidebarSummary: action.email,
				};
			} else if (action.payload === "/reports/sidebar/detail") {
				return {
					...state,
					postEmailSidebarCampaignUpdate: action.email,
				};
			}else if (action.payload === "reports/sidebar/details") {
				return {
					...state,
					postEmailSidebarUpdate: action.email,
					postEmailCsvSidebarUpdate: action.email
				};
			}
		/* falls through */
		case actionTypes.SETSIDEBARSUMMARYPOPUPCLOSE:
			return {
				...state,
				cardPopupSidebarSummary: action.payload,
			};
		case actionTypes.SETSIDEBARDETAILPOPUPCLOSE:
			return{
				...state,
				cardPopupSidebarDetail: action.payload
			}
		case actionTypes.SETSIDEBARCAMPAIGNDETAILPOPUPCLOSE:
				return{
					...state,
					cardPopupSidebarCampaignDetail: action.payload
				}	
		case actionTypes.SETSIDEBARDETAILPOPUPPOSTEMAIL:
			return{
				...state,
				postEmailSidebarUpdate: action.payload
			}
		case actionTypes.SETSIDEBARDETAILCSVPOPUPPOSTEMAIL:
			return{
				...state,
				postEmailCsvSidebarUpdate: action.payload
			}
		case actionTypes.SETSIDEBARCAMPAIGNDETAILPOPUPPOSTEMAIL:
				return{
					...state,
					postEmailSidebarCampaignUpdate: action.payload
				}	
		case actionTypes.SETSIDEBARSUMMARYPOPUPPOSTEMAIL:
			return{
				...state,
				postEmailUpdateSidebarSummary: action.payload
			}
		case actionTypes.GETSIDEBARDYNAMICTABLEHEADER:
			return {
				...state, getDynamicHeaderData: action.payload
			}
		case actionTypes.SENDSIDEBARDYANMICTABLEHEADER:
			return {
				...state, dynamicHeaderArray: action.payload
			}
		case actionTypes.RESETSTATESSIDEBARDETAIL:
			return { ...state, sidebarDetail: [], isLoadingSidebarDetail: false };
		case actionTypes.UPDATESIDEBARDETAILLOADER:
			return { ...state, isLoadingSidebarDetail: true };
		default:
			return state;
	}
};

export const firmographicSidebarOrgsTypeReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHSIDEBARFIRMOGRAPHICORGSTYPE:
			return { ...state, ..._.mapKeys(action.payload, "value") };
		case actionTypes.APPLYSIDEBARORGSTYPE:
			selectedTags.orgsType = [];
			const ids = action.payload;
			ids.forEach(function (id) {
				selectedTags.orgsType.push(state[id]);
			});
			return { ..._.mapKeys(selectedTags.orgsType, "value") };
		case actionTypes.CLEARSEARCHSIDEBARORGSTYPE:
			return { ..._.mapKeys(selectedTags.orgsType, "value") };
		case actionTypes.SELINISIDEBARORGSTYPE:
			selectedTags.orgsType = action.organization_types;
			return {
			//	...state,
			//  ..._.mapKeys(action.organization_types, "value"),
			 ..._.mapKeys(selectedTags.orgsType, "value")
			 };
		case actionTypes.RESETSIDEBARORGSTYPE:
			selectedTags.orgsType = [];
			return selectedTags.orgsType;
		default:
			return state;
	}
};

export const firmographicSidebarOrgsSizeReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHSIDEBARORGSSIZE:
			return { ...state, ..._.mapKeys(action.payload, "value") };
		case actionTypes.APPLYSIDEBARORGSSIZE:
			selectedTags.orgsSize = [];
			const ids = action.payload;
			ids.forEach(function (id) {
				selectedTags.orgsSize.push(state[id]);
			});
			return { ..._.mapKeys(selectedTags.orgsSize, "value") };
		case actionTypes.CLEARSEARCHSIDEBARORGSSIZE:
			return { ..._.mapKeys(selectedTags.orgsSize, "value") };
		case actionTypes.SELINISIDEBARORGSSIZE:
				selectedTags.orgsSize = action.organization_sizes;
			return { ..._.mapKeys(selectedTags.orgsSize, "value")};	 
		case actionTypes.RESETSIDEBARORGSSIZE:
			selectedTags.orgsSize = [];
			return selectedTags.orgsSize;
		default:
			return state;
	}
};

export const firmographicSidebarOrgsRevenueReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHSIDEBARORGSREVENUE:
			return { ...state, ..._.mapKeys(action.payload, "value") };
		case actionTypes.APPLYSIDEBARREVENUE:
			selectedTags.orgsRevenue = [];
			const ids = action.payload;
			ids.forEach(function (id) {
				selectedTags.orgsRevenue.push(state[id]);
			});
			return { ..._.mapKeys(selectedTags.orgsRevenue, "value") };
		case actionTypes.CLEARSEARCHSIDEBARORGSREVENUE:
			return { ..._.mapKeys(selectedTags.orgsRevenue, "value") };
		case actionTypes.SELINISIDEBARORGREVENUE: 
				selectedTags.orgsRevenue = action.estimated_revenues;
			return {
				..._.mapKeys(selectedTags.orgsRevenue, "value")
			}
		case actionTypes.RESETSIDEBARORGREVENUE:
			selectedTags.orgsRevenue = [];
			return selectedTags.orgsRevenue;
		default:
			return state;
	}
};

export const firmographicSidebarSectorReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHSIDEBARSECTOR:
			return { ...state, ..._.mapKeys(action.payload, "sector") };
		case actionTypes.APPLYSIDEBARSECTOR:
			return { ...state, ..._.mapKeys(selectedTags.sector, "sector") };
		case actionTypes.CLEARSEARCHSIDEBARSECTOR:
			return {...state, ..._.mapKeys(selectedTags.sector, "sector") };
		case actionTypes.SELINISIDEBARSECTOR:
			selectedTags.sector = action.sector;
			return {
				//...state,
				 ..._.mapKeys(selectedTags.sector, "sector"), 
				// ..._.mapKeys(action.sector, "sector") 
				};
		case actionTypes.RESETSIDEBARSECTOR:
			selectedTags.sector = [];
			return selectedTags.sector;
		default:
			return state;
	}
};

export const firmographicSidebarIndustryGroupReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHSIDEBARINDUSTRYGROUP:
			return { ...state, ..._.mapKeys(action.payload, "industry_group") };
		case actionTypes.APPLYSIDEBARINDUSTRYGROUP:
			return { ...state, ..._.mapKeys(action.payload, "industry_group") };
		case actionTypes.CLEARSEARCHSIDEBARINDUSTRYGROUP:
			return {...state, ..._.mapKeys(selectedTags.industry_group, "industry_group") };
		case actionTypes.SELINISIDEBARINDUSTRYGROUP:
			selectedTags.industry_group = action.industry_group;
			return {
				..._.mapKeys(selectedTags.industry_group, "industry_group") };
		case actionTypes.RESETSIDEBARINDUSTRYGROUP:
			selectedTags.industry_group = [];
			return selectedTags.industry_group;
		default:
			return state;
	}
};

export const firmographicSidebarIndustryReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHSIDEBARINDUSTRY:
			return { ...state, ..._.mapKeys(action.payload, "industry") };
		case actionTypes.APPLYSIDEBARINDUSTRY:
			return { ...state, ..._.mapKeys(action.payload, "industry") };
		case actionTypes.CLEARSEARCHSIDEBARINDUSTRY:
			return {...state, ..._.mapKeys(selectedTags.industry, "industry") };
		case actionTypes.SELINISIDEBARINDUSTRY:
			selectedTags.industry = action.industry;
			return {..._.mapKeys(selectedTags.industry, "industry") };		
		case actionTypes.RESETSIDEBARINDUSTRY:
			selectedTags.industry = [];
			return selectedTags.industry;
		default:
			return state;
	}
};

export const firmographicSidebarSubIndustryReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHSIDEBARSUBINDUSTRY:
			return { ...state, ..._.mapKeys(action.payload, "sub_industry") };
		case actionTypes.APPLYSIDEBARSUBINDUSTRY:
			return { ...state, ..._.mapKeys(action.payload, "sub_industry") };
		case actionTypes.CLEARSEARCHSIDEBARSUBINDUSTRY:
			return { ...state, ..._.mapKeys(selectedTags.sub_industry, "sub_industry") };
		case actionTypes.SELINISIDEBARSUBINDUSTRY:
			selectedTags.sub_industry = action.sub_industry;
			return { ..._.mapKeys(selectedTags.sub_industry, "sub_industry")};	
		case actionTypes.RESETSIDEBARSUBINDUSTRY:
			selectedTags.sub_industry = [];
			return selectedTags.sub_industry;
		default:
			return state;
	}
};

export const senioritiesInSidebarReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHSIDEBARSENIORITIESIN:
			action.selected.forEach(function (id) {
				action.payload.forEach((tag) => {
					if (tag.id === id) {
						tag.disabled = true;
					}
				});
			});
			return { ...state, ..._.mapKeys(action.payload, "id") };
		case actionTypes.APPLYSIDEBARSENIORITIESIN:
			selectedTags.senioritiesIN = [];
			const ids = action.payload;
			ids.forEach(function (id) {
				selectedTags.senioritiesIN.push(state[id]);
			});
			return { ..._.mapKeys(selectedTags.senioritiesIN, "id") };
		case actionTypes.CLEARSEARCHSIDEBARSENIORITIESIN:
			return { ..._.mapKeys(selectedTags.senioritiesIN, "id") };
		case actionTypes.SELINISIDEBARSENORITIESIN:
			selectedTags.senioritiesIN = action.senoritiesIn;
			return { ..._.mapKeys(selectedTags.senioritiesIN)}
		case actionTypes.RESETSIDEBARSENORITIESIN:
			selectedTags.senioritiesIN = [];
			return selectedTags.senioritiesIN;
		default:
			return state;
	}
};

export const senioritiesExSidebarReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHSIDEBARSENIORITIESEX:
			action.selected.forEach(function (id) {
				action.payload.forEach((tag) => {
					if (tag.id === id) {
						tag.disabled = true;
					}
				});
			});
			return { ...state, ..._.mapKeys(action.payload, "id") };
		case actionTypes.APPLYSIDEBARSENIORITIESEX:
			selectedTags.senioritiesEX = [];
			const ids = action.payload;
			ids.forEach(function (id) {
				selectedTags.senioritiesEX.push(state[id]);
			});
			return { ..._.mapKeys(selectedTags.senioritiesEX, "id") };
		case actionTypes.CLEARSEARCHSIDEBARSENIORITIESEX:
			return { ..._.mapKeys(selectedTags.senioritiesEX, "id") };
		case actionTypes.SELINISIDEBARSENORITIESEX: 
			selectedTags.senioritiesEX = action.senoritiesEx;
			return {
			//	...state,
			//	..._.mapKeys(action.senoritiesEx, "id"),
				..._.mapKeys(selectedTags.senioritiesEX, "id")
		}
		case actionTypes.RESETSIDEBARSENORITIESEX:
			selectedTags.senioritiesEX = [];
			return selectedTags.senioritiesEX;
		default:
			return state;
	}
};

export const jobfunctionsInSidebarReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHSIDEBARJOBFUNCTIONSIN:
			action.selected.forEach(function (id) {
				action.payload.forEach((tag) => {
					if (tag.id === id) {
						tag.disabled = true;
					}
				});
			});
			return { ...state, ..._.mapKeys(action.payload, "id") };
		case actionTypes.APPLYSIDEBARJOBFUNCTIONSIN:
			selectedTags.jobfunctionsIN = [];
			const ids = action.payload;
			ids.forEach(function (id) {
				selectedTags.jobfunctionsIN.push(state[id]);
			});
			return { ..._.mapKeys(selectedTags.jobfunctionsIN, "id") };
		case actionTypes.CLEARSEARCHSIDEBARJOBFUNCTIONSIN:
			return { ..._.mapKeys(selectedTags.jobfunctionsIN, "id") };
		case actionTypes.SELINISIDEBARJOBFUNCTIONIN: 
			selectedTags.jobfunctionsIN = action.jobFunctionIn;
			return { ..._.mapKeys(selectedTags.jobfunctionsIN, "id")};
		case actionTypes.RESETSIDEBARJOBFUNCTIONIN:
			selectedTags.jobfunctionsIN = [];
			return selectedTags.jobfunctionsIN;
		default:
			return state;
	}
};

export const jobfunctionsExSidebarReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHSIDEBARJOBFUNCTIONSEX:
			action.selected.forEach(function (id) {
				action.payload.forEach((tag) => {
					if (tag.id === id) {
						tag.disabled = true;
					}
				});
			});
			return { ...state, ..._.mapKeys(action.payload, "id") };
		case actionTypes.APPLYSIDEBARJOBFUNCTIONSEX:
			selectedTags.jobfunctionsEX = [];
			const ids = action.payload;
			ids.forEach(function (id) {
				selectedTags.jobfunctionsEX.push(state[id]);
			});
			return { ..._.mapKeys(selectedTags.jobfunctionsEX, "id") };
		case actionTypes.CLEARSEARCHSIDEBARJOBFUNCTIONSEX:
			return { ..._.mapKeys(selectedTags.jobfunctionsEX, "id") };
		case actionTypes.SELINISIDEBARJOBFUNCTIONEX: 
			selectedTags.jobfunctionsEX = action.jobFunctionEx;
		return { ..._.mapKeys(selectedTags.jobfunctionsEX, "id")};
		case actionTypes.RESETSIDEBARJOBFUNCTIONEX:
			selectedTags.jobfunctionsEX = [];
			return selectedTags.jobfunctionsEX;
		default:
			return state;
	}
};				

export const sidebarAuthorReducer = createCommonReducer(
	selectedTags.csmArray,
	{
		FETCH: actionTypes.FETCH_SIDEBAR_CSM,
		ADD: actionTypes.ADD_SIDEBAR_CSM,
		CLEARSEARCH: actionTypes.CLEAR_SIDEBAR_CSM,
	},
	'id'
);