import React from "react";
import { Table } from "semantic-ui-react";
import MultiTagLinks from "../../../hyperlinks/multiTagLinks";

const PlacementTagComp = ({ data }) => {
    return (
        data.placement_tags !== "N/A" ? (
            <Table.Cell title={data.placement_tags} className="blogs_link">
                <MultiTagLinks
                    type="placement"
                    object={data.tags_data}
                    refValue={data.placement_tags}
                ></MultiTagLinks>
            </Table.Cell>
        ) : (
            <Table.Cell title={data.placement_tags} >{data.placement_tags}</Table.Cell>
        )
    );
};

export default PlacementTagComp;