import React from "react";
import { Table } from "semantic-ui-react";

const PaceComponent = ({ data }) => {
    // Check if the relevant keys exist and their values
    const isGreyCondition =
        (data.kpi_commited === 0 || data.kpi_commited === "N/A") ||
        (data.pageview_goal === 0 || data.pageview_goal === "N/A");

    return (
        <Table.Cell>
            {isGreyCondition ? (
                <span title={data.pace}>
                    <button className="button_color_grey" />
                </span>
            ) : data.pace === "GREEN" ? (
                <span title={data.pace}>
                    <button className="button_color_green" />
                </span>
            ) : data.pace === "YELLOW" ? (
                <span title={data.pace}>
                    <button className="button_color_yellow" />
                </span>
            ) : (
                <span title={data.pace}>
                    <button className="button_color_red" />
                </span>
            )}
        </Table.Cell>
    );
};

export default PaceComponent;
