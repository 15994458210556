import _ from "lodash";
import React, { useRef }from "react";
import { connect } from "react-redux";
import { Checkbox, Loader } from "semantic-ui-react";
import { selClientLeadToggles } from "../../../actions";
import * as actionTypes from "../../../actions/actionTypes";
import { getCapital, getDisplayDateChart } from "../../../utilities/common";
import DownloadChart from "../../common/downloadChart";
import ClientLeadQualificationChart from "./clientLeadQualificationChart";

const LeadAcceptanceChart = (props) => {
  const chartNameDiv = useRef(null);
  let loading;
  if (props.loading) {
    loading = (
      <div className="show-loader">
        <Loader active inline="centered" />
      </div>
    );
  }

  return (
    <>
      <div className="ad-graph" id="charts-gap">
        <div ref={chartNameDiv}>
        {!_.isEmpty(props.leadAcceptanceBarChart) && props.clientLeadFetchData.leadAcceptanceChart &&
          <DownloadChart
            chartNameDiv= {chartNameDiv.current}
            chartName = {(`Lead-Acceptance-Rate-Chart-${getDisplayDateChart(new Date())}.jpg`)}
            style={{fontSize:"35px", marginTop: 19, marginRight: 56, color: "#838486" }}
          />
        }
          <p className="ad-chart-name">
            {getCapital("Lead Acceptance Rate")}
            <Checkbox
              data-html2canvas-ignore="true"
              className="checkbox-label"
              // slider
              toggle
              style={{
                color: "white",
                fontFamily: "Roboto",
                fontSize: "16px",
                paddingLeft: "20px",
                minHeight: 15,
              }}
              label={props.clientLeadFetchData.leadAcceptanceChart ? "Hide" : "Show"}
              onChange={() =>
                props.selClientLeadToggles(actionTypes.SELLEADACCEPTANCECHART)
              }
              checked={props.clientLeadFetchData.leadAcceptanceChart}
              disabled={!props.isFilApplied}
            />
            {
              props.isFilApplied && props.clientLeadFetchData.leadAcceptanceChart &&
                loading
            }
            {
            !_.isEmpty(props.leadAcceptanceBarChart) && props.clientLeadFetchData.leadAcceptanceChart && 
              <ClientLeadQualificationChart />
            }
          </p>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    leadAcceptanceBarChart: state.clientLeadFetchData.leadAcceptanceBarChart,
    isFilApplied: state.clientLeadFetchData.isFilApplied,
    clientLeadFetchData: state.clientLeadFetchData,
    loading: state.clientLeadFetchData.isLoadingAcceptanceChart,
    leadFilterData: state.clientLeadFilterData,
  };
};

export default connect(mapStateToProps, { selClientLeadToggles })(
  LeadAcceptanceChart
);
