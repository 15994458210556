export const selectValue = (payload) => ({
    type: payload.dispatchType,
    payload
});

export const addSelectedValue = (payload) => ({
    type: payload.dispatchType,
    payload: payload.value,
    reportType: payload.reportType,
    selected: payload.selected || [],
});

export const clearSearchForAllReport = (payload) => {
    return {
        type: payload.dispatchType,
        reportType: payload.reportType,
    };
};

export const adjustTableValue = (payload) => {
    return {
        type: payload.dispatchType,
        reportType: payload.reportType,
    };
};

export const columnSelected = (payload) => {
    return {
        type: payload.dispatchType,
        column_selected: payload.column_selected,
        reportType: payload.reportType,
    };
}

export const updateCommonData = (dispatchType, data) => {
    return {
        type: dispatchType,
        payload: data
    }
}