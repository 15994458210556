import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Checkbox, Divider, Form, Icon, Loader, Menu, Message, Table } from 'semantic-ui-react';
import {
   sortSponsoredContentCampaignDetail,
   clearScSort,
   showMoreSponsoredTableData,
   fetchPageviewTableData,
   sendTableHeaders,
   getTableHeaders,
   clearSponsoredContentSerchedValue,
   selSponsoredContentFilterValue
} from "../../../actions";
import * as actionTypes from "../../../actions/actionTypes"
import _ from 'lodash';
import { url } from "../../../api/urlHelper";
import SCPageviewDetailsRow from './scPageviewDetailsRow';
import TableHeader from './tableHeader';
import SCPageviewDetailsTableData from './scPageviewDetailsTableData';


const SponsoredCampaignPageviewDetails = (props) => {
   const [selectHeaders, setSelectHeaders] = useState();
   const [finalHeaderData, setFinalHeaderData] = useState();
   const [headerColumn, setHeaderColumn] = useState([]);
   const [isSortIcon, setSortIcon] = useState(false);

   let tableBody;
   let showMoreButton;
   let loading;
   let noresult;

   useEffect(() => {
      props.getTableHeaders(
         "sponsored_pageview_details",
         actionTypes.GETSCPAGEVIEWDETAILSDYNAMICTABLEHEADER
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      const headerDataVal = props.getDynamicHeaderData;
      if (!_.isEmpty(headerDataVal)) {
         setSelectHeaders(headerDataVal);
         setFinalHeaderData(headerDataVal);
      } else if (headerDataVal === undefined || _.size(headerDataVal) === 0) {
         const constantHeadersArr = [...SCPageviewDetailsTableData];
         setSelectHeaders(constantHeadersArr);
         setFinalHeaderData(constantHeadersArr);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.getDynamicHeaderData]);

   useEffect(() => {
      const headerName = [];
      finalHeaderData &&
         finalHeaderData.map((name) => {
            headerName.push(name.headerName);
            return "";
         });
      setHeaderColumn(headerName);
   }, [finalHeaderData]);

   useEffect(() => {
      if (!props.sponsoredContentFilterData.detailTableSponsoredPageviewToggleValue && props.sponsoredContentFilterData.selectCampPageviewDetailsTable) {
         props.fetchPageviewTableData(
            url.sponsoredDetail,
            props.sponsoredContentFilterData,
            1,
            "",
            actionTypes.SCCAMPAIGNDETAILS,
            null,
            null,
            "sponsored-content"
         );
         props.selSponsoredContentFilterValue(true, actionTypes.DETAILTABLESCTOGGLLEVALUE);
         props.clearSponsoredContentSerchedValue(actionTypes.UPDATESCPAGEVIEWDETAILSLOADER)
      } else if (!props.sponsoredContentFilterData.selectCampPageviewDetailsTable) {
         props.clearSponsoredContentSerchedValue(actionTypes.RESETSTATESSCPAGEVIEWDETAILS)
      }
      if (!props.sponsoredContentFilterData.selectCampPageviewDetailsTable) {
         props.selSponsoredContentFilterValue(false, actionTypes.DETAILTABLESCTOGGLLEVALUE);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.sponsoredContentFilterData.selectCampPageviewDetailsTable, props.isFilApplied]);


   if (props.loading && props.sponsoredContentFilterData.selectCampPageviewDetailsTable) {
      loading = (
         <div className="show-loader">
            <Loader active inline="centered" />
         </div>
      );
   }

   const showMore = async () => {
      props.clearScSort()
      props.showMoreSponsoredTableData(actionTypes.MORESCCAMPAIGNPAGEVIEWDETAILLOADING);
      await props.fetchPageviewTableData(
         url.sponsoredDetail,
         props.sponsoredContentFilterData,
         props.PageCount,
         "",
         actionTypes.MORESCCAMPAIGNPAGEVIEWDETAIL,
         null,
         null,
         "sponsored-content",
         "resSponsoredCampaignPageviewDetails"
      );
      props.showMoreSponsoredTableData(actionTypes.SETSCCAMPAIGNPAGEVIEWPGCOUNT);
   };

   const createRow = (data) => {
      if (data.length > 0) {
         let tableRow = [];
         data.forEach((campaignValues, id) => {
            const htmlData = (
               <SCPageviewDetailsRow
                  pageviewDetailsRow={campaignValues}
                  headerName={headerColumn}
               />
            );
            tableRow.push(<Table.Row key={id} children={htmlData} />);
         });
         return tableRow;
      }
   };

   if (!_.isEmpty(props.scCampaignDetail) && props.sponsoredContentFilterData.selectCampPageviewDetailsTable) {
      tableBody = (
         <Table.Body>{createRow(props.scCampaignDetail)}</Table.Body>
      );
      showMoreButton = (
         <div className="table-divider">
            <Divider>
               <div className="show-more-component">
                  {!props.sponsoredContentFetchData.resSponsoredCampaignPageviewDetails ?
                     (
                        <button className="show-more-button" onClick={showMore}>
                           Show More
                        </button>
                     ) : (
                        <button className="show-more-button-disable">
                           Show More
                        </button>
                     )}

               </div>
            </Divider>
         </div>
      );
   } else if (
      props.isFilApplied &&
      !props.loading &&
      _.isEmpty(props.scCampaignDetail) &&
      props.sponsoredContentFilterData.selectCampPageviewDetailsTable
   ) {
      noresult = <Message>No results found.</Message>;
   }

   const clickOnSortIcon = () => {
      setSortIcon(!isSortIcon);
   };

   const clickOnSortHeader = (e, extraData, headerData) => {
      if (extraData.checked) {
         setSelectHeaders([...selectHeaders, headerData]);
      } else {
         const removeData = selectHeaders.filter(
            (hName) => hName.headerName !== headerData.headerName
         );
         setSelectHeaders(removeData);
      }
   };

   const applyButtonClick = () => {
      const removeSortIconVal = selectHeaders.filter(
         (rowData) => rowData.headerName !== "SORT_ICON"
      );
      const addLastArr = [
         { headerName: "SORT_ICON", sorted: "", className: "icon" },
      ];
      const allElements = [].concat(removeSortIconVal, addLastArr);
      const constantHeadersArr = [...SCPageviewDetailsTableData];
      let finalHeaderArray = [];
      constantHeadersArr.map((mainHeader) => {
         return _.map(allElements, function (selectHeader) {
            if (mainHeader.headerName === selectHeader.headerName) {
               finalHeaderArray.push(selectHeader);
            }
         });
      });

      setFinalHeaderData(finalHeaderArray);
      props.sendTableHeaders(
         "sponsored_pageview_details",
         finalHeaderArray,
         actionTypes.SENDSCPAGEVIEWDETAILSDYANMICTABLEHEADER
      );
      setSortIcon(!isSortIcon);
   };


   return (
      <div>
         <>
            <Form>
               <div className="sorted_icon">
                  <Table.HeaderCell style={{ paddingBottom: "20px" }}>
                     <Icon
                        name="edit"
                        style={{ color: `${isSortIcon ? "#f15a29" : "#b2b3b3"}` }}
                        size="large"
                        onClick={clickOnSortIcon}
                     />
                  </Table.HeaderCell>
                  {isSortIcon && (
                     <div
                        className="menu-list-items"
                        style={{ height: "0px", float: "right" }}
                     >
                        <Menu vertical className="dynamic_table--list">
                           {Object.values(SCPageviewDetailsTableData).map(
                              (tableHeader, index) => (
                                 <div key={index}>
                                    <Checkbox
                                       className="table_header--checkbox"
                                       style={{ color: "#838486" }}
                                       label={tableHeader.name}
                                       checked={
                                          selectHeaders &&
                                             selectHeaders.some(
                                                (el) => el.headerName === tableHeader.headerName
                                             )
                                             ? true
                                             : false
                                       }
                                       onClick={(e, data) =>
                                          clickOnSortHeader(e, data, tableHeader)
                                       }
                                    />
                                 </div>
                              )
                           )}
                           <button
                              className="apply_menu--btn"
                              onClick={applyButtonClick}
                           >
                              Apply
                           </button>
                        </Menu>
                     </div>
                  )}
               </div>
            </Form>
         </>

         <div className="sortable-table-component" style={{ paddingTop: "30px" }}>
            <Table sortable fixed singleLine>
               <Table.Header>
                  <Table.Row>
                     {!_.isEmpty(finalHeaderData) && (
                        <TableHeader
                           propData={props}
                           headerData={finalHeaderData}
                        ></TableHeader>
                     )}
                  </Table.Row>
               </Table.Header>
               {tableBody}
            </Table>
         </div>
         {loading}
         {showMoreButton}
         {noresult}
      </div>
   );
};

const mapStateToProps = (state) => {
   return {
      scCampaignDetail: state.sponsoredContentFetchData.scCampaignDetail,
      loading: state.sponsoredContentFetchData.isLoadingScCampaignDetail,
      filStartDate: state.sponsoredContentFilterData.startDate,
      filEndDate: state.sponsoredContentFilterData.endDate,
      column: state.sponsoredContentFetchData.column,
      direction: state.sponsoredContentFetchData.scCampaignPageviewDirection,
      isFilApplied: state.sponsoredContentFetchData.isFilApplied,
      PageCount: state.sponsoredContentFetchData.scCampaignPageviewPageCount,
      sponsoredContentFilterData: state.sponsoredContentFilterData,
      sponsoredContentFetchData: state.sponsoredContentFetchData,
      getDynamicHeaderData:
         state.sponsoredContentFetchData.getDynamicPageviewDetailsHeaderData,
   };
};

export default connect(mapStateToProps, {
   sortSponsoredContentCampaignDetail,
   clearScSort,
   showMoreSponsoredTableData,
   fetchPageviewTableData,
   sendTableHeaders,
   getTableHeaders,
   clearSponsoredContentSerchedValue,
   selSponsoredContentFilterValue
})(SponsoredCampaignPageviewDetails);