import React from "react";
import { connect } from "react-redux";

import * as constName from "../../components/config/const";
import Main from "../../components/DecisionInsightComponent/Pro/Main";

const DIBasic = (props) => {
    return (
        <Main
            reportActionType={constName.MODULE_DI_BASIC}
            reportName={constName.DI_BASIC_REPORT_NAME}
            filterData={props.filterData}
            label={constName.DI_BASIC_LABEL}
        />
    );
};

const mapStateToProps = ({ commonFilterData }) => {
    return {
        filterData: commonFilterData[constName.MODULE_DI_BASIC],
    };
};

export default connect(mapStateToProps)(DIBasic);