import React from 'react';
import { connect } from 'react-redux';

const ClientLeadGraph = (props) => {
	
	

  return (
    <div className='ad-graph-date'>
		<p>{props.filStartDate}-{props.filEndDate}</p>
	</div>
  );
};

const mapStateToProps = (state) => {
	return {
		filStartDate: state.clientLeadFilterData.startDate,
		filEndDate: state.clientLeadFilterData.endDate,
	};
  };

export default connect(mapStateToProps)(ClientLeadGraph);