import React from "react";
import SingleSelectDropDown from "../base/singleSelectDropDown";

const StatusPicker = (props) => {
	return (
		<SingleSelectDropDown
			className="no-blink"
			options={[
				{
					key: "0",
					text: "Active",
					value: "active",
				},
				{
					key: "3",
					text: "Archived",
					value: "archived",
				},
				{
					key: "4",
					text: "Paused",
					value: "paused",
				},
				{
					key: "5",
					text: "Scheduled",
					value: "scheduled",
				},
				{
					key: "6",
					text: "Completed",
					value: "completed",
				},
				{
					key: "2",
					text: "All",
					value: "all",
				},
			]}
			label="Campaign Status"
			placeholder="Campaign Status"
			{...props}
		/>
	);
};

export default StatusPicker;
