import React from "react";
import { connect } from "react-redux";
import AdsPage from "./../adsPage";
import * as constName from "./../../components/config/const";
import { getReportsByElement } from "../../utilities/common";
import { ROLEBASED_ELEMENT_ROADBLOCK_ADS } from "../../components/config/rolebasedReportNames";

const AdsRoadblock = (props) => {
    const roadblockAdsReports = getReportsByElement(props?.roleFilterData?.currentUserReportRoleData, props?.roleFilterData?.getAllReports, ROLEBASED_ELEMENT_ROADBLOCK_ADS);

    return (
        <AdsPage
            reportActionType={constName.MODULE_ROADBLOCK_ADS}
            reportName={constName.ROADBLOCK_ADS_REPORT_NAME}
            filterData={props.filterData}
            reportDescription={props.reportDescription.find(report => report.name === "Ads - Roadblock")?.description}
            label={constName.ROADBLOCK_ADS_LABEL}
            roleReports={roadblockAdsReports}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        filterData: state.commonFilterData[constName.MODULE_ROADBLOCK_ADS],
        reportDescription: state.allFilterReportData.reportDescription,
        roleFilterData: state.allFilterReportData,
    };
};

export default connect(mapStateToProps)(AdsRoadblock);