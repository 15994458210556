import React from "react";

const ApplyAndCancelButton = (props) => {
    return (
        <div className="date-buttons">
            <button type="button" className="cancel-date-button" onClick={props.cancelDate}>
                Cancel
            </button>
            <button type="button" className="apply-date-button" onClick={props.applyDate}>
                Apply
            </button>
        </div>
    );
}

export default ApplyAndCancelButton;