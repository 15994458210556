import React from "react";
import { Table } from "semantic-ui-react";
import _ from "lodash"
import { numFormat } from "../../utilities/common";

const SiteCommonSummaryReport = (props) => {
    let tableBody;

    const createRow = (data) => {
        if (data.length > 0) {
            let tableRow = [];
            data.forEach((element) => {
                let tableCell = [];
                tableCell.push(
                    <Table.Cell textAlign="right" title={numFormat(element.page_views_count)}>{numFormat(element.page_views_count)}</Table.Cell>
                );
                tableCell.push(
                    <Table.Cell textAlign="right" title={numFormat(element.unique_page_views)}>{numFormat(element.unique_page_views)}</Table.Cell>
                );
                tableCell.push(
                    <Table.Cell textAlign="right" title={numFormat(element.total_page_views_by_month)}>{numFormat(element.total_page_views_by_month)}</Table.Cell>
                );
                tableCell.push(
                    <Table.Cell textAlign="right" title={numFormat(element.total_page_views_by_quarter)}>{numFormat(element.total_page_views_by_quarter)}</Table.Cell>
                );
                tableCell.push(
                    <Table.Cell textAlign="right" title={numFormat(element.total_page_views_by_year)}>{numFormat(element.total_page_views_by_year)}</Table.Cell>
                );
                
                tableRow.push(<Table.Row children={tableCell} />);
            });
            return tableRow;
        }
    };

    if (!_.isEmpty(props.filterData)) {
        tableBody = <Table.Body>{createRow(props.filterData)}</Table.Body>;
        props.parentShowMore && props.parentShowMore();
    }

    const sortClick = (sortVal) => {}

	return (
        <div className="sortable-table-component">
            <Table sortable fixed singleLine>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell
                            sorted={props.column === "page_views_count" ? props.direction : null}
                            textAlign="right"
                            onClick={sortClick("page_views_count")}
                            style={{width:"40px"}}
                        >
                            Pageviews
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={props.column === "unique_page_views" ? props.direction : null}
                            textAlign="right"
                            onClick={sortClick("unique_page_views")}
                        >
                            Unique Pageviews
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={ props.column === "total_page_views_by_month" ? props.direction : null }
                            textAlign="right"
                            onClick={sortClick("total_page_views_by_month")}
                        >
                            Total Pageviews for Current Month
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={ props.column === "total_page_views_by_quarter" ? props.direction : null }
                            textAlign="right"
                            onClick={sortClick("total_page_views_by_quarter")}
                        >
                            Total Pageviews for Current Quarter
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={ props.column === "total_page_views_by_year" ? props.direction : null }
                            textAlign="right"
                            onClick={sortClick("total_page_views_by_year")}
                        >
                            Total Pageviews For Current Year
                        </Table.HeaderCell>
                
                    </Table.Row>
                </Table.Header>

                {tableBody}
            </Table>
        </div>
	);
};

export default SiteCommonSummaryReport;
