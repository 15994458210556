import React from "react";
import Toggle from "../base/toggle";
import * as actionTypes from "../../actions/actionTypes";

const InnerToggles = (props) => {

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    return (
        <React.Fragment>
        {
            props.toggleData.map(({ key, label }) => (
                <Toggle
                    key={key}
                    className={props?.applyClass}
                    label={label}
                    onChange={() => { dispatchDynamicSelectAction(props, key, !props.filterData[key]) }}
                    checked={props?.filterData[key]}
                />
            ))
        } 
        </React.Fragment>
    );
};

export default InnerToggles;