import axios from "../api/axios";
import { url } from "../api/urlHelper";
import * as actionTypes from "./actionTypes";
import { headers } from "../api/apiConstants";
import { getApiDate, sanitizePageviewRequestTag, formatDate, extractFileName } from "../utilities/common";
import { findReportFromURL } from "../components/config/FindReportFromURL";

export const setPageCount = (dispatchType) => {
	return {
		type: dispatchType,
	};
};
export const selPgFilterValue = (value, dispatchType) => {
	return {
		type: dispatchType,
		payload: value,
	};
};

export const applyPgSelectedValue = (value, dispatchType) => {
	return {
		type: dispatchType,
		payload: value,
	};
};

export const clearPgSerchedValue = (dispatchType) => {
	return {
		type: dispatchType,
	};
};

export const resetPgTopicIn = (dispatchType) => {
	return {
		type: dispatchType,
	};
};

export const resetPgTopicEx = (dispatchType) => {
	return {
		type: dispatchType,
	};
};

export const resetPgOrgIn = (dispatchType) => {
	return {
		type: dispatchType,
	};
};
export const resetPgOrgEx = (dispatchType) => {
	return {
		type: dispatchType,
	};
};
export const resetPgPlacementIn = (dispatchType) => {
	return {
		type: dispatchType,
	};
};

export const resetPgPlacementEx = (dispatchType) => {
	return {
		type: dispatchType,
	};
};

export const resetPgSenioritiesEx = (dispatchType) => {
	return {
		type: dispatchType,
	};
};

export const resetPgSenioritiesIn = (dispatchType) => {
	return {
		type: dispatchType,
	};
};

export const resetPgDropdownValue = (dispatchType) => {
	return {
		type: dispatchType,
	};
};


export const fetchPageTitle = (queryString, dispatchType, reportType = "", wpStartDate="", wpEndDate="") => async (dispatch) => {
	await axios
		.post(
			url.pageTitle,
			{
				q: queryString,
				order: "ASC",
				order_by: "title",
				limit: 20,
				page: 1,
				wp_page_start_date: wpStartDate === "" ? "" : getApiDate(wpStartDate).concat(" 00:00:00"),
				wp_page_end_date: wpEndDate === "" ? "" : getApiDate(wpEndDate).concat(" 23:59:59"),
			},
			{
				headers: headers(),
			}
		)
		.then((res) => {
			dispatch({
				type: dispatchType,
				payload: res.data,
				reportType,
			});
		})
		.catch((err) => {
			if (err.response) {
				dispatch({ type: actionTypes.ERRORCODE, payload: err.response.status });
			}
		});
};

export const SenioritiesList = (list, dispatchType, selected) => (dispatch) => {
	dispatch({
		type: dispatchType,
		payload: list,
		selected,
	});
};

export const fetchTags =
	(queryString, tagType, dispatchType, selected, domain, reportType = "") =>
	async (dispatch) => {
		await axios
			.post(
				url.tags,
				{
					q: queryString,
					tag_type: tagType,
					order: "ASC",
					order_by: "",
					limit: 20,
					page: 1,
					site: domain,
				},
				{
					headers: headers(),
				}
			)
			.then((res) => {
				res.data.forEach(tag => {
					tag.key = tag.id + "|" + tag.domain;
				});
				dispatch({
					type: dispatchType,
					payload: res.data,
					selected,
					reportType,
				});
			})
			.catch((err) => {
				if (err.response) {
					dispatch({
						type: actionTypes.ERRORCODE,
						payload: err.response.status,
					});
				}
			});
	};

export const fetchUrlParams =
	(queryString, type, dispatchType, selected = "", report, reportType = "") =>
	async (dispatch) => {
		await axios
			.post(
				url.urlParams,
				{
					q: queryString,
					source: selected,
					report: report,
					url_param_field: type,
					order: "ASC",
					order_by: type,
					limit: 20,
					page: 1,
				},
				{
					headers: headers(),
				}
			)
			.then((res) => {
				dispatch({
					type: dispatchType,
					payload: res.data,
					reportType,
				});
			})
			.catch((err) => {
				if (err.response) {
					dispatch({
						type: actionTypes.ERRORCODE,
						payload: err.response.status,
					});
				}
			});
	};

export const fetchPageviewTableData =
	(uri, filterData, page, type = "", dispatchType, csvType = "", newEmail, reportType, resSponsored) =>
	async (dispatch) => {
		let siteValue = filterData?.site;
		if (filterData?.site === "all") {
			siteValue = "";
		}
		let limit = 50;
		if (uri === url.topicPageviewSummary) {
			limit = 5;
		}
		let status = filterData?.campaignStatus;
		let IncludeDeleted = false;
		if (filterData?.campaignStatus === "all") {
			status = "";
			IncludeDeleted = true;
		} else if (filterData?.campaignStatus === "archived") {
			status = "archived";
			IncludeDeleted = true;
		}
		if (newEmail) {
			filterData.email = newEmail;
		}

		let wpStartDate = "";
		let wpEndDate = "";
		if(filterData?.pageParams === true){ 
			wpStartDate =  formatDate(filterData.wp_startDate, " 00:00:00")
			wpEndDate =  formatDate(filterData?.wp_endDate, " 23:59:59")
		}
		let orgType = filterData && filterData.selOrgType ? (filterData.selOrgType.map( a => a.charAt(0).toUpperCase() + a.substr(1))) : [];
		let deliveryState = filterData?.scDeliverdFilterState &&(filterData?.scDeliverdFilterState).trim();

		let trafficSource = filterData?.selTrafficSource;
		if(filterData?.selTrafficSource === "All"){
			trafficSource = "";
		}
		let onPaceValue = filterData?.pacingFilter
		if(filterData && filterData.pacingFilter === "all"){
			onPaceValue = ""
		}
		if(filterData.trafficType === "all") {
			filterData.trafficType = "";
		}
		const reportData = findReportFromURL(uri, "");
		await axios
			.post(
				uri,
				{
					organizations: filterData?.organization,
					campaigns: filterData?.campaign,
					orders: filterData?.order,
					site: siteValue,
					status: status,
					include_deleted: IncludeDeleted,
					topic_tags: {
						included_tags: sanitizePageviewRequestTag(filterData?.selTopicTagsIN),
						excluded_tags: sanitizePageviewRequestTag(filterData?.selTopicTagsEX),
					},
					organization_tags: {
						included_tags: sanitizePageviewRequestTag(filterData?.selOrgTagsIN),
						excluded_tags: sanitizePageviewRequestTag(filterData?.selOrgTagsEX),
					},
					placement_tags: {
						included_tags: sanitizePageviewRequestTag(filterData?.selPlacementTagsIN),
						excluded_tags: sanitizePageviewRequestTag(filterData?.selPlacementTagsEX),
					},
					page_title: filterData?.selPageTitle,
					utm_source: filterData?.selUrlSource,
					utm_medium: filterData?.selUrlMedium,
					utm_campaign: filterData?.selUrlCampaign,
					utm_term: filterData?.selUrlTerm,
					utm_content: filterData?.selUrlContent,
					pageviews_by_organizations: filterData?.selPageViewsByOrg,
					author: filterData?.selAuthor,
					country: filterData?.selCountry,
					start_date: getApiDate(filterData?.startDate).concat(" 00:00:00"),
					end_date: getApiDate(filterData?.endDate).concat(" 23:59:59"), 	
					previous_start_date: formatDate(filterData?.previousStartDate, " 00:00:00"),
        			previous_end_date: formatDate(filterData?.previousEndDate, " 23:59:59"),
					compare:filterData?.selCutomRangeChkBox,
					applied_date_filter: filterData?.appliedDate,
					order: "DESC",
					order_by: "page_views_count",
					limit: limit,
					page: page,
					export: type,
					exclude_qa: filterData?.excludeQACampaign,
					exclude_internal: filterData?.excludeInternalCampaign,
					...(filterData?.personaToggle && {
						job_title: {
							included_job_title: filterData?.selJobTitlesIN,
							excluded_job_title: filterData?.selJobTitlesEX,
						},
						seniorities: {
							included_tags: filterData?.selSenioritiesIN,
							excluded_tags: filterData?.selSenioritiesEX,
						},
						job_functions: {
							included_tags: filterData?.selJobFunctionsIN,
							excluded_tags: filterData?.selJobFunctionsEX,
						},
					}),
					email: filterData?.email,
					engaged_members:filterData?.selEnagedMember,
					organization_types: orgType,
					organization_sizes: filterData?.selOrgSize,
					organization_revenues: filterData?.selOrgRevenue,
					sector:filterData?.selSector,
					industry: filterData?.selIndustry,
					sub_industry: filterData?.selSubIndustry,
					industry_group: filterData?.selIndustryGroup,
					wp_page_start_date: wpStartDate,
					wp_page_end_date: wpEndDate,
					primary_topic_ids:{
						tag_ids:filterData?.selPrimaryTopic
					},
					type: reportType && reportType,
					delivery_state: deliveryState && deliveryState,
					traffic_source: trafficSource,
					pace: onPaceValue,
					exclude_internal_events: filterData?.excludeInternalEvents && filterData?.excludeInternalEvents,
					exclude_anonymous_organization: filterData?.exAnonymousOrgs && filterData?.exAnonymousOrgs,
					exclude_anonymous_member: filterData?.exAnonymousMember && filterData?.exAnonymousMember,
					traffic_filter: filterData?.trafficType,
					page_type: filterData?.pageviewType === "all" ? "" : filterData?.pageviewType,
					report_name: reportData ? reportData.report : "",
					report_table_name: reportData ? reportData.report_table_name : "",
					exclude_competitor_orgs: filterData?.competitorFiltering,
					csm: filterData?.setCSM,
					exclude_pre_publish_views: filterData?.excludePrePublishedViews,
				},
				{
					headers: headers(),
				}
			)
			.then((res) => {
				const fileName = extractFileName(res.headers)
				if (res.status === 200) {
					if (type) {
						dispatch({
							type: csvType,
							payload: uri,
							email: filterData.email,
							csv: res.data,
							downloadFileName: fileName
						});
					} else {
						dispatch({
							type: dispatchType,
							payload: res.data,
							filterData: filterData,
							resEDetails : res?.data === null && true,
							grandTotal: 0
						});
					}
				} else if (res.status === 202) {
					if (type) {
						dispatch({
							type: csvType,
							payload: uri,
							email: filterData.email,
							downloadFileName: fileName
						});
					} else {
						dispatch({
							type: actionTypes.PAGEVIEWREPORTLOADING,
							payload: uri,
							filterData,
						});
					}
				} else if (res.status === 425) {
					dispatch({
						type: actionTypes.PAGEVIEWREPORTLOADING,
						payload: uri,
						filterData,
					});
				} else if (res.status >= 500 && res.status < 600) {
					dispatch({
						type: dispatchType,
						payload: [],
					});
				}
			})
			.catch((err) => {
				if (err.response) {
					dispatch({
						type: dispatchType,
						payload: [],
					});
				}
			});
	};

export const sortPageviewTableData = (
	type,
	column,
	direction = "",
	loadMoreSort = false
) => {
	return {
		type,
		column,
		direction,
		loadMoreSort,
	};
};

export const sortPageviewDetailedTable = (column) => {
	return {
		type: actionTypes.SORTPAGEVIEWDETAILED,
		column: column,
	};
};

export const showMorePageviewTableData = (type) => {
	return {
		type,
	};
};

export const fetchPgAuthors = (queryString, dispatchType, reportType = "") => async (dispatch) => {
	await axios
		.post(
			url.searchAuthors,
			{
				q: queryString,
				limit: 20,
				page: 1,
			},
			{
				headers: headers(),
			}
		)
		.then((res) => {
			dispatch({
				type: dispatchType,
				payload: res.data,
				reportType,
			});
		})
		.catch((err) => {
			if (err.response) {
				dispatch({ type: actionTypes.ERRORCODE, payload: err.response.status });
			}
		});
};

export const fetchPgCountry = (queryString, dispatchType) => async (dispatch) => {
	await axios
		.post(
			url.searchCountry,
			{
				q: queryString,
				limit: 20,
				page: 1,
			},
			{
				headers: headers(),
			}
		)
		.then((res) => {
			dispatch({
				type: dispatchType,
				payload: res.data,
			});
		})
		.catch((err) => {
			if (err.response) {
				dispatch({ type: actionTypes.ERRORCODE, payload: err.response.status });
			}
		});
};

export const selPGToggles = (dispatchType) => (dispatch) => {
	dispatch({
		type: dispatchType,
	});
};

export const setCountAPI = () => {
	return {
		type: actionTypes.PAGEVIEWCOUNTAPIHIT,
	};
};
export const selPgCustomRange =  (value, dispatchType) =>{	
	return{
		type:dispatchType,
		payload: value
	}
}

export const firmographicOrgsTypeCall = (list, dispatchType, selected) => (dispatch) => {
	dispatch({
		type: dispatchType,
		payload: list,
		selected,
	});
};

export const openPageviewMenus = (value, dispatchType) => {
	return {
		type: dispatchType,
		payload: value,
	};
};

export const openDIMenus = (value, dispatchType) => {
	return {
		type: dispatchType,
		payload: value,
	};
};