import React from "react";
import { Form } from "semantic-ui-react";
import moment from "moment";
import { connect } from "react-redux";
import { compose } from "redux";

import WpDateComponent from "../../CommonFilter/WpDateComponent";
import Toggle from "../../base/toggle";
import { PAGE_PARAMETER_LABEL, PAGEVIEW_RANGE_LABEL, PUBLISHED_DATE_RANGE } from "../../config/const";
import MultipleSelectDropDown from "../../base/multiSelectDropdown";
import DayOfWeek from "../../common/DayOfWeek";
import TimeOfDay from "../../common/TimeOfDay";
import Site from "../../common/site";
import CountrySelection from "../../common/FilterSection/CountrySelection";
import TopicTagInSelection from "../../common/FilterSection/TopicTagInSelection";
import TopicTagExSelection from "../../common/FilterSection/TopicTagExSelection";
import PlacementTagInSelection from "../../common/FilterSection/PlacementTagInSelection";
import PlacementTagExSelection from "../../common/FilterSection/PlacementTagExSelection";
import OrganizationTagInSelection from "../../common/FilterSection/OrganizationTagInSelection";
import OrganizationTagExSelection from "../../common/FilterSection/OrganizationTagExSelection";
import TrafficSource from "../../common/trafficSource";
import TrafficFilterPicker from "../../common/trafficeFilter";
import PageviewType from "../../common/PageviewType";
import EngagedOrgSelection from "../../common/FilterSection/EngagedOrgSelection";
import EngagedMemberSelection from "../../common/FilterSection/EngagedMemberSelection";
import EngagedCommonToggleFilter from "../../ads/common/EngagedCommonToggleFilter";
import FirmographicsFilterComponent from "../../ads/common/FirmographicsFilterComponent";
import PersonaFilterComponent from "../../ads/common/PersonaFilterComponent";
import EventFilterToggle from "../../ads/common/EventFilterToggle";
import PageTitleSelection from "../../common/FilterSection/PageTitleSelection";
import UTMSourceSelection from "../../common/FilterSection/UTMSourceSelection";
import UTMMediumSelection from "../../common/FilterSection/UTMMediumSelection";
import UTMCampaignSelection from "../../common/FilterSection/UTMCampaignSelection";
import UTMTermSelection from "../../common/FilterSection/UTMTermSelection";
import UTMContentSelection from "../../common/FilterSection/UTMContentSelection";
import PrimaryTopicSelection from "../../common/FilterSection/PrimaryTopicSelection";
import * as actionTypes from "../../../actions/actionTypes";
import { selectValue } from "../../../actions";
import AuthorsSelection from "../FilterSection/AuthorsSelection";

const ContentFilterSection = (props) => {

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };
    
    return (
        <Form>
            <div className="ad-search-filter">
                <Toggle
                    label={PAGE_PARAMETER_LABEL}
                    onChange={() => {
                        dispatchDynamicSelectAction(
                            props,
                            "isPageParameter",
                            !props.filterData.isPageParameter
                        );
                    }}
                    checked={props.filterData.isPageParameter}
                />
                {props.filterData.isPageParameter && (
                    <>
                        <label className="job-Titles" style={{ marginLeft: "5px", lineHeight: "2em" }}>{PUBLISHED_DATE_RANGE}</label>
                        <Form.Group widths="equal">
                            <WpDateComponent {...props} isClear={props.clear} />
                            <span style={{ width: "25em" }}>
                                <div className='ad-graph-date'>
                                    {props.filterData.wp_startDate && props.filterData.wp_endDate &&
                                        <p>{moment(props.filterData.wp_startDate).format("MM/DD/YYYY")} - {moment(props.filterData.wp_endDate).format("MM/DD/YYYY")}</p>}
                                </div>
                            </span>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <PageTitleSelection {...props} />
                            <UTMSourceSelection {...props} />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <UTMMediumSelection {...props} />
                            <UTMCampaignSelection {...props} />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <UTMTermSelection {...props} />
                            <UTMContentSelection {...props} />
                        </Form.Group>
                        <Form.Group widths="equal" style={{ width: "24.8em" }}>
                            <PrimaryTopicSelection {...props} />
                        </Form.Group>
                    </>
                )}
                <Form.Group widths="equal">
                    <CountrySelection {...props} />
                    <Site
                        value={props.filterData.site}
                        onChange={(e, { value }) => dispatchDynamicSelectAction(props, "site", value)}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <TopicTagInSelection {...props} />
                    <TopicTagExSelection {...props} />
                </Form.Group>
                <Form.Group widths="equal">
                    <PlacementTagInSelection {...props} />
                    <PlacementTagExSelection {...props} />
                </Form.Group>
                <Form.Group widths="equal">
                    <OrganizationTagInSelection {...props} />
                    <OrganizationTagExSelection {...props} />
                </Form.Group>
                <Form.Group widths="equal">
                    <AuthorsSelection {...props} />
                    <TrafficSource
                        value={props.filterData.selTrafficSource}
                        onChange={(e, { value }) => { dispatchDynamicSelectAction(props, "selTrafficSource", value); }}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <TrafficFilterPicker
                        value={props.filterData.selTrafficType}
                        onChange={(e, { value }) => { dispatchDynamicSelectAction(props, "selTrafficType", value); }}
                    />
                    <PageviewType
                        value={props.filterData.selPageviewType}
                        onChange={(e, { value }) => { dispatchDynamicSelectAction(props, "selPageviewType", value); }}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <DayOfWeek
                        weekDays={props?.filterData?.dayOfWeekOptions?.days_of_week}
                        onChange={(e, { value }) => { dispatchDynamicSelectAction(props, "selDayOfWeeks", value); }}
                        value={props?.filterData?.selDayOfWeeks}
                    />
                    <MultipleSelectDropDown
                        options={props?.filterData?.dayOfWeekOptions?.ranges?.map((day, index) => ({
                            key: index,
                            text: day.label,
                            value: day.value,
                        }))}
                        value={props?.filterData?.selRange}
                        onChange={(e, { value }) => { dispatchDynamicSelectAction(props, "selRange", value); }}
                        label={PAGEVIEW_RANGE_LABEL}
                        placeholder={PAGEVIEW_RANGE_LABEL}
                    />
                </Form.Group>
                <Form.Group widths="equal" style={{ width: "24.8em" }}>
                    <TimeOfDay
                        ranges={props?.filterData?.dayOfWeekOptions?.time_of_day}
                        onChange={(e, { value }) => { dispatchDynamicSelectAction(props, "selTimeOfDay", value); }}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <EngagedOrgSelection {...props} />
                    <EngagedMemberSelection {...props} />
                </Form.Group>
                <Form.Group widths="equal">
                    <EngagedCommonToggleFilter {...props} />
                </Form.Group>
                <FirmographicsFilterComponent {...props} isApplicable={true} />
                <PersonaFilterComponent {...props} isApplicable={false} />
                <Form.Group>
                    <div className="engagements-search-filter">
                        <Form className="search-filter-column-1"></Form>
                        <Form className="search-filter-column-2">
                            <EventFilterToggle {...props} />
                        </Form>
                    </div>
                </Form.Group>
            </div>
        </Form>
    );
};

export default compose(connect(null, {
    selectValue,
}))(ContentFilterSection);