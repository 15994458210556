import React, { useState } from "react";
import { Table } from "semantic-ui-react";
import _ from "lodash";

import { ACTION, AD_TYPE, AD_ORDER, ENGAGEMENT_DATE, ENGAGEMENT_TIME, PAGEVIEWS, PAGE_ELEMENTS, TARGET_NAME, ORDER, CAMPAIGN } from "../../config/TableHeaderNames";
import useStickyColumnsEffect from "../../../utilities/stickeyStyle";
import GenerateCellContent from "./GenerateCellContent";
import ExpandCollapse from "../../common/ExpandCollapse";
import PageviewActivity from "./PageviewActivity";
import MultiLinks from "../../hyperlinks/multiLinks";

const AdActivity2 = ({  props, data, count, freezeCols, isFreeze }) => {
    const [adPage2, setAdPage2] = useState(false);

    const stickeyClassName = freezeCols >= 0 ? (isFreeze ? "sticky-cols-sticky" : "sticky-cols" ) : ""
    useStickyColumnsEffect(freezeCols);

    return (
        <React.Fragment>
            <Table.Row className="li-children2" onDoubleClick={() => setAdPage2(!adPage2)}>
                <Table.Cell className={stickeyClassName}>
                {
                    !_.isEmpty(data?.on_page_activity) &&
                    <ExpandCollapse data={data.on_page_activity} expanded={adPage2} setExpanded={setAdPage2} marginLeft={"90px"}/>
                }
                </Table.Cell>
                {
                    props && props.finalHeaderData.map((header, cellIndex) => {
                        const freezeClassName = freezeCols >= 0 ? (cellIndex <= freezeCols ? (isFreeze  ? "sticky-cols-sticky" : "sticky-cols") : "" ) : ""
                        let cellContent = "";
                        if(_.includes([ACTION, ENGAGEMENT_DATE, ENGAGEMENT_TIME, TARGET_NAME, PAGE_ELEMENTS, PAGEVIEWS, AD_ORDER, AD_TYPE, CAMPAIGN, ORDER], header.headerName)) {
                            const campaignData = data?.campaign_data ?? [];
                            if(header.headerName === TARGET_NAME) {
                                if(data.target_name !== "") {
                                    const targetName = data.target_name ? data.target_name : data.target_id
                                    cellContent = ( <Table.Cell key={cellIndex} title={targetName} className={`blogs_link space_height ${freezeClassName}`}>
                                                        <a href={data.target_id} target="_blank" rel="noreferrer noopener">
                                                            {targetName}
                                                        </a>
                                                    </Table.Cell>);
                                } else {
                                    cellContent = <Table.Cell key={cellIndex} className={freezeClassName}/>
                                }
                            } else if(header.headerName === AD_ORDER) {
                                cellContent = GenerateCellContent(data?.ad_name, data?.ad_uuid, "ads", cellIndex, freezeClassName);
                            } else if (header.headerName === CAMPAIGN && data.page_elements === "creative-view") {
                                if(campaignData) {
                                    cellContent = (<Table.Cell key={cellIndex} className={`blogs_link ${freezeClassName}`}>
                                                        <MultiLinks
                                                            type="campaigns"
                                                            object={campaignData}
                                                        />
                                                </Table.Cell>);
                                } else {
                                    cellContent = <Table.Cell key={cellIndex} className={freezeClassName}/>
                                }
                             } else if (header.headerName === ORDER && data.page_elements === "creative-view") {
                                if(campaignData) {
                                    cellContent = (<Table.Cell key={cellIndex} className={`blogs_link ${freezeClassName}`}>
                                                        <MultiLinks
                                                            type="orders"
                                                            object={campaignData.map((c)=> c.order)}
                                                        />
                                                </Table.Cell>);
                                } else {
                                    cellContent = <Table.Cell key={cellIndex} className={freezeClassName}/>
                                }
                             }else {
                                cellContent = <Table.Cell key={cellIndex} title={data[header.sorted]} className={freezeClassName}>{data[header.sorted]}</Table.Cell>
                            }
                        } else {
                            cellContent = <Table.Cell key={cellIndex} className={freezeClassName}/>
                        }
                        
                        return cellContent;
                    })
                }
            </Table.Row>
            {
                !_.isEmpty(data?.on_page_activity) && data?.on_page_activity?.map(
                    (ap2, index) => adPage2 && <PageviewActivity props={props} data={ap2} key={`ad2-${data.id}-${index}`} freezeCols={freezeCols} isFreeze={isFreeze}/>
                )
            }
        </React.Fragment>
    );
};

export default AdActivity2;