import React, { useState } from "react";
import Toggle from "../../base/toggle";
import { PERSONA_TOGGLE_LABEL, PERSONA_TOGGLE_LABEL2 } from "../../config/const";
import * as actionTypes from "../../../actions/actionTypes";
import { connect } from "react-redux";
import { compose } from "redux";
import {
    selectValue,
    addSelectedValue,
    clearSearchForAllReport,
} from "../../../actions";
import PersonaFilter from "../../common/personaFilter";

const FiromographicsFilterComponent = (props) => {
    const [senioritiesIN, setSenioritiesIN] = useState([]);
    const [senioritiesEX, setSenioritiesEX] = useState([]);
    const [JobFunctionsIN, setJobFunctionsIN] = useState([]);
    const [JobFunctionsEX, setJobFunctionsEX] = useState([]);

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({
            dispatchType: actionTypes[props.reportActionType].SELECTVALUE,
            reportType: props.reportActionType,
            key,
            value,
        });
    };

    const searchFunction = (e, setData, callAPI, fetch, value, oppositeKey, key) => {
        setData([]);
        if (e.target.value.length > 1) {
            setData(
                callAPI
                    .filter((val) => {
                        return val[key]
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase());
                    })
                    .map((val) => {
                        val.disabled = false;
                        return val;
                    })
            );
            props.addSelectedValue({
                dispatchType: actionTypes[props.reportActionType][fetch],
                reportType: props.reportActionType,
                value,
                selected: props.filterData[oppositeKey],
            });
        }
    };

    const onSelectFunctions = (value, select, key, addAction) => {
        const selectedValue = value[value.length - 1];
        if (props.filterData[select].indexOf(selectedValue) === -1) {
            dispatchDynamicSelectAction(props, key, value);
            props.addSelectedValue({ dispatchType: actionTypes[props.reportActionType][addAction], reportType: props.reportActionType, value });
        };
    };

    const onClickFunction = (clearAction) => {
        props.clearSearchForAllReport({
            dispatchType: actionTypes[props.reportActionType].CLEAR_SENORITY_IN,
            reportType: props.reportActionType
        });
    }

    let personaSeniority = [
        {
            value: props.filterData.selSenioritiesIN,
            label: "Seniority (Include)",
            placeholder: "Seniority (Include)",
            options: props.commonSelectData.senioritiesIn,
            onSearchChange: (e)=> searchFunction(e, setSenioritiesIN, props.seniorities, "FETCH_SENORITY_IN", senioritiesIN, "selSenioritiesEX", "name"),
            onChange: (e, {value})=> onSelectFunctions(value, "selSenioritiesEX", "selSenioritiesIN", "ADD_SENORITY_IN"),
            onClick: ()=> onClickFunction("CLEAR_SENORITY_IN"),
        },
        {
            value: props.filterData.selSenioritiesEX,
            label: "Seniority (Exclude)",
            placeholder: "Seniority (Exclude)",
            options: props.commonSelectData.senioritiesEx,
            onSearchChange: (e)=> searchFunction(e, setSenioritiesEX, props.seniorities, "FETCH_SENORITY_EX", senioritiesEX, "selSenioritiesIN", "name"),
            onChange: (e, {value})=> onSelectFunctions(value, "selSenioritiesIN", "selSenioritiesEX", "ADD_SENORITY_EX"),
            onClick: ()=> onClickFunction("CLEAR_SENORITY_EX"),
        },
    ];

    let personaJobFunction = [
        {
            value: props.filterData.selJobFunctionsIN,
            label: "Job Function (Include)",
            placeholder: "Job Function (Include)",
            options: props.commonSelectData.jobFunctionsIn,
            tags: props.commonSelectData.jobFunctionsIn,
            // onSearchChange: searchJobFunctionsIn,
            onSearchChange: (e)=> searchFunction(e, setJobFunctionsIN, props.jobfunctions, "FETCH_JOB_FUNCTION_IN", JobFunctionsIN, "selJobFunctionsEX", "function"),
            onChange: (e, {value})=> onSelectFunctions(value, "selJobFunctionsEX", "selJobFunctionsIN", "ADD_JOB_FUNCTION_IN"),
            onClick: ()=> onClickFunction("CLEAR_JOB_FUNCTION_IN"),
        },
        {
            value: props.filterData.selJobFunctionsEX,
            label: "Job Function (Exclude)",
            placeholder: "Job Function (Exclude)",
            options: props.commonSelectData.jobFunctionsEx,
            tags: props.commonSelectData.jobFunctionsEx,
            onSearchChange: (e)=> searchFunction(e, setJobFunctionsEX, props.jobfunctions, "FETCH_JOB_FUNCTION_EX", JobFunctionsEX, "selJobFunctionsIN", "function"),
            onChange: (e, {value})=> onSelectFunctions(value, "selJobFunctionsIN", "selJobFunctionsEX", "ADD_JOB_FUNCTION_EX"),
            onClick: ()=> onClickFunction("CLEAR_JOB_FUNCTION_EX"),
        },
    ];

    const onTypeJobTitleIN = (e, { value }) =>{
		dispatchDynamicSelectAction(props, "selJobTitlesIN", value);
	}

	const onTypeJobTitleEX = (e, { value }) =>{
		dispatchDynamicSelectAction(props, "selJobTitlesEX", value);
	}

    let personaJobTitleIn = [
		{
			onChange: onTypeJobTitleIN,
			placeholder: "Job Title (Include)",
		   	value: props.filterData.selJobTitlesIN,
		},
	];

  let personaJobTitleEx = [
		{
			onChange: onTypeJobTitleEX,
			placeholder: "Job Title (Exclude)",
		   	value: props.filterData.selJobTitlesEX,
		}
	]

    return (
        <React.Fragment>
            <Toggle
                label={props.isApplicable ? PERSONA_TOGGLE_LABEL : PERSONA_TOGGLE_LABEL2 }
                onChange={() => dispatchDynamicSelectAction(props, "personaToggle", !props.filterData.personaToggle)}
                checked={props.filterData.personaToggle}
                className="persona_toggle"
            />
            {props.filterData.personaToggle && (
                <PersonaFilter
                    personaSenority={personaSeniority}
                    personaJobFunction={personaJobFunction}
                    personaJobTitleIn={personaJobTitleIn}
                    personaJobTitleEx={personaJobTitleEx}
                    labelJobTitleIn="Job Title (Include)"
                    labelJobTitleEx="Job Title (Exclude)"
                />
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        commonSelectData: state.allReportCommonReducer[ownProps.reportActionType],
        seniorities: Object.values(state.seniorities),
        jobfunctions: Object.values(state.jobfunctions),
    };
};

export default compose(
    connect(mapStateToProps, {
        selectValue,
        addSelectedValue,
        clearSearchForAllReport,
    })
)(FiromographicsFilterComponent);
