import React from "react";
import { Form, Input } from "semantic-ui-react";
import MultipleSelectDropDown from "../base/multiSelectDropdown";

const PersonaFilter = (props) => {
  return (
    <Form>
      <Form.Group widths={"equal"}>
        {props.personaSenority.map((seniorities, idx) => (
          <MultipleSelectDropDown
            key={idx}
            options={seniorities.options.map((tags, index) => ({
                key: index,
                text: tags.name,
                //description: sites[tags.domain],
                value: tags.id,
                disabled: tags.disabled,
            }))}
            value={seniorities.value}
            label={seniorities.label}
            placeholder={seniorities.placeholder}
            onClick={seniorities.onClick}
            style={{ width: "24rem" }}
            onChange={seniorities.onChange}
            onSearchChange={seniorities.onSearchChange}
            
          />
        ))}
      </Form.Group>
      <Form.Group widths={"equal"}>
        {props.personaJobFunction.map((jobfunctions, idx) => (
          <MultipleSelectDropDown
            key={idx}
            options={jobfunctions.options.map((tags, index) => ({
                key: `${tags.id}-${index}`,
				        text: tags.sub_function ? `${tags.function} (${tags.sub_function})` : tags.function,
                value: tags.id,
                disabled: tags.disabled,
            }))}
            tags={jobfunctions.tags}
            value={jobfunctions.value}
            label={jobfunctions.label}
            placeholder={jobfunctions.placeholder}
            onClick={jobfunctions.onClick}
            style={{ width: "24rem" }}
            onChange={jobfunctions.onChange}
            onSearchChange={jobfunctions.onSearchChange}
            clearable = {jobfunctions.clearable}
          />
        ))}
      </Form.Group>
      <Form.Group widths="equal">
		<label className="job-Titles"style={{marginLeft: "5px"}}>Job Title (Include)</label> 
		<label className="job-Titles" style={{marginLeft: "237px"}}>Job Title (Exclude)</label> 
	</Form.Group>
      <Form.Group widths={"equal"}>
      {props.personaJobTitleIn && (
        <>	
         {props.personaJobTitleIn.map((jobTitle, index) => (
            <Input
            key={index}
            style={{ width: "337px", marginLeft: "7px" }}
            onChange={jobTitle.onChange}
            placeholder={jobTitle.placeholder}
            value={jobTitle.value}
            focus
            />
         ))}
        </>
      )}
       {props.personaJobTitleEx && (
        <>	
         {props.personaJobTitleEx.map((jobTitle, index) => (
            <Input
            key={index}
            style={{ width: "337px", marginLeft: "14px" }}
            onChange={jobTitle.onChange}
            placeholder={jobTitle.placeholder}
            value={jobTitle.value}
            />
         ))}
        </>
      )}
      
      </Form.Group>
    </Form>
  );
};

export default PersonaFilter;
