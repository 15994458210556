import React from 'react';
import MultipleSelectDropDown from '../base/multiSelectDropdown';

const CountryMultiSelectPicker = (props) => {
  return (
	<MultipleSelectDropDown
    options={props.countries.map((country, index) => ({
        key: index,
        text: country.name,
        value: country.name,
    }))}
    label={props.label}
    placeholder={props.label}
    onSearchChange={props.onSearchChange}
    value={props.value}
    onChange={props.onChange}
    onClick={props.onClick}
  />
  )
};

export default CountryMultiSelectPicker;
