import React from "react";
import SingleSelectDropDown from "../base/singleSelectDropDown";

const OrderStatusPicker = (props) => {
	return (
		<SingleSelectDropDown
			className="no-blink"
			options={[
				{
					key: "0",
					text: "All",
					value: "all",
				},
				{
					key: "1",
					text: "Active",
					value: "active",
				},
				{
					key: "2",
					text: "Archived",
					value: "archived",
				},
			]}
			label="Order Status"
			placeholder="Order Status"
			{...props}
		/>
	);
};

export default OrderStatusPicker;
