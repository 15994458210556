import { headers } from "../api/apiConstants";
import axios from "../api/axios";
import { LIMIT_50 } from "../components/config/TableHeaderNames";
import { PageviewPayloadData, addExtraContentPerformanceData } from "../utilities/PageviewPayload";
import { extractFileName } from "../utilities/common";


export const fetchContentReportTableData = (uri, filterData, page, type = "", dispatchType, csvType = "", newEmail, reportType, setReportType) =>
    async (dispatch) => {
        const data = {
            ...PageviewPayloadData(filterData, page, type, reportType, LIMIT_50, uri),
            ...addExtraContentPerformanceData(filterData),
        }
        delete data.status;

        await axios.post(uri, data,
            {
                headers: headers(),
            })
            .then((res) => {
                const fileName = extractFileName(res.headers)
                if (res.status === 200) {
                    dispatch({
                        type: dispatchType,
                        payload: res.data,
                        reportType: setReportType,
                        downloadFileName: fileName,
                    });
                } else if (res.status >= 500 && res.status < 600) {
                    dispatch({
                        type: dispatchType,
                        payload: [],
                        reportType: setReportType,
                    });
                }
            })
            .catch((err) => {
                if (err.response) {
                    dispatch({
                        type: dispatchType,
                        payload: [],
                    });
                }
            });
    };