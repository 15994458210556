import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Divider, Loader, Message, Table } from "semantic-ui-react";
// import TableHeaderComponent from "../common/tableHeader";
import { sortEditorialPrimaryTopic,
  clearEditorialSort,
  fetchEditorialTableData,
  showMoreEditorialTableData,
  selEditorialFilterValue,
  clearEditorialSerchedValue,
 } from "../../actions";
import * as actionTypes from "../../actions/actionTypes";
import _ from "lodash";
import { url } from "../../api/urlHelper";
import { numFormat, calculateTotalCount } from "../../utilities/common";
import MultiTagLinks from "../hyperlinks/multiTagLinks";
import CurrentPreviousHeader from "../common/CurrentPreviousHeader";

const EditorialPrimaryTopicSummary = (props) => {
  let tableBody;
  let showMoreButton;
  let loading;
  let noresult;
  let resIndependantPrimaryTopic;
  const conditionPrevious =
    props?.filterData?.selCutomRangeChkBox &&
    props.isFilApplied &&
    props.filterData.appliedDate === "Custom Date Range";

  useEffect(() => {
    if(!props.filterData.indTopicSummaryToggleValue && props.filterData.isTopicSummary) {
      // API calling for primary topic and it's count 
      apiCall(url.pageviewPrimaryTopic, props.filterData, actionTypes.EDITORIALPRIMARYTOPIC, resIndependantPrimaryTopic);
      apiCall(url.independentTopicSummaryCount, props.filterData, actionTypes.INDPRIMARYTOPICCOUNT, "");

      props.selEditorialFilterValue(true, actionTypes.INDTOPICSUMMARYTOGGLEVALUE);
      props.clearEditorialSerchedValue(actionTypes.UPDATEINDTOPICSUMMARYLOADER)
    } else if(!props.filterData.isTopicSummary){
      props.clearEditorialSerchedValue(actionTypes.RESETINDTOPICSUMMARY)
    }
    if(!props.filterData.isTopicSummary){
      props.selEditorialFilterValue(false, actionTypes.INDTOPICSUMMARYTOGGLEVALUE);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterData.isTopicSummary, props.isFilApplied])

  const apiCall = (url, data, actionType, topic) => {
    props.fetchEditorialTableData(
      url,
      data,
      1,
      "",
      actionType,
      null,
      null,
      "independent",
      topic
    );
  }

  if (props.loading && props.filterData.isTopicSummary) {
    loading = (
      <div className="show-loader">
        <Loader active inline="centered" />
      </div>
    );
  }

  const showMore = async () => {
    props.clearEditorialSort()
    props.showMoreEditorialTableData(actionTypes.MOREEDITORIALPRIMARYTOPICLOADING);
    await props.fetchEditorialTableData(
      url.pageviewPrimaryTopic,
      props.editorialFilterData,
      props.PageCount,
      "",
      actionTypes.MOREEDITORIALSUMMARY,
      null,
      null,
      "independent"
    );
		props.showMoreEditorialTableData(actionTypes.SETEDITORIALPRIMARYTOPICPGCOUNT);
  };

  const createRow = (data) => {
    let tableTotalCell = [];
    let tableGrandTotalCell = [];

    const calculateTotalCountByMetric = (metric) => {
      return calculateTotalCount(data, metric);
    };

    const totalPageviewsCount =()=> calculateTotalCountByMetric('page_views_count');
    const preTotalPageviewsCount =()=> calculateTotalCountByMetric('previous_period_pageviews');
    const totalUniquePageviewsCount =()=> calculateTotalCountByMetric('unique_page_views');
    const preTotalUniquePageviewsCount =()=> calculateTotalCountByMetric('previous_period_unique_pageviews');
    const totalPageviewsByMonth =()=> calculateTotalCountByMetric('total_page_views_by_month');
    const totalPageviewsByQuarter =()=> calculateTotalCountByMetric('total_page_views_by_quarter');
    const totalPageviewsByYear =()=> calculateTotalCountByMetric('total_page_views_by_year');

    if (data.length > 0) {
      let tableRow = [];
      data.forEach((campaignValues) => {
        let tableCell = [];
        //account name column
        tableCell.push(
          campaignValues.primary_topic ? (
            <Table.Cell title={campaignValues.primary_topic} className="blogs_link">
                <MultiTagLinks
                  type="primary"
                  object={campaignValues.tags_data}
                  refValue={campaignValues.primary_topic}
                ></MultiTagLinks>
              </Table.Cell>
              ) : (
                <Table.Cell title={campaignValues.primary_topic} >{campaignValues.primary_topic}</Table.Cell>
              )
        );
        tableCell.push(
          <Table.Cell title={campaignValues.sites}>
            {(campaignValues.sites)}
          </Table.Cell>
        );

        conditionPrevious ? 
        tableCell.push(
          <Table.Cell title={campaignValues.page_views_count} textAlign="center">{numFormat(campaignValues.page_views_count)}</Table.Cell>,
          <Table.Cell title={campaignValues.previous_period_pageviews} textAlign="center">{numFormat(campaignValues.previous_period_pageviews)}</Table.Cell>
        ) 
        :
        tableCell.push(
          <Table.Cell title={campaignValues.page_views_count} textAlign="right">
            {numFormat(campaignValues.page_views_count)}
          </Table.Cell>
        );

        conditionPrevious ? 
        tableCell.push(
          <Table.Cell title={campaignValues.unique_page_views} textAlign="center">{numFormat(campaignValues.unique_page_views)}</Table.Cell>,
          <Table.Cell title={campaignValues.previous_period_unique_pageviews} textAlign="center">{numFormat(campaignValues.previous_period_unique_pageviews)}</Table.Cell>
        ) 
        :
        tableCell.push(
          <Table.Cell title={campaignValues.unique_page_views} textAlign="right">
            {numFormat(campaignValues.unique_page_views)}
          </Table.Cell>
        );

        tableCell.push(<Table.Cell textAlign="right" title={numFormat(campaignValues.total_page_views_by_month)}>{numFormat(campaignValues.total_page_views_by_month)}</Table.Cell>);
        tableCell.push(
          <Table.Cell textAlign="right" title={numFormat(campaignValues.total_page_views_by_quarter)}>{numFormat(campaignValues.total_page_views_by_quarter)}</Table.Cell>
        );
        tableCell.push(
          <Table.Cell textAlign="right" title={numFormat(campaignValues.total_page_views_by_year)} >{numFormat(campaignValues.total_page_views_by_year)}</Table.Cell>
        );

        tableRow.push(<Table.Row children={tableCell} />);
      });
      tableTotalCell.push(<Table.Cell title={"Total"}>Total:</Table.Cell>);
      tableTotalCell.push(<Table.Cell>-</Table.Cell>)

      conditionPrevious ? 
        tableTotalCell.push(
          <Table.Cell textAlign="center" title={numFormat(totalPageviewsCount())}>{numFormat(totalPageviewsCount())}</Table.Cell>,
          <Table.Cell textAlign="center" title={numFormat(preTotalPageviewsCount())}>{numFormat(preTotalPageviewsCount())}</Table.Cell>
        )
      :
        tableTotalCell.push(<Table.Cell textAlign="right" title={numFormat(totalPageviewsCount())}>{numFormat(totalPageviewsCount())}</Table.Cell>);
			
      conditionPrevious ?
        tableTotalCell.push(
          <Table.Cell textAlign="center" title={numFormat(totalUniquePageviewsCount())}>{numFormat(totalUniquePageviewsCount())}</Table.Cell>,
          <Table.Cell textAlign="center" title={numFormat(preTotalUniquePageviewsCount())}>{numFormat(preTotalUniquePageviewsCount())}</Table.Cell>
        )
      :
      tableTotalCell.push(<Table.Cell textAlign="right" title={numFormat(totalUniquePageviewsCount())}>{numFormat(totalUniquePageviewsCount())}</Table.Cell>);

      
      tableTotalCell.push(<Table.Cell textAlign="right" title={numFormat(totalPageviewsByMonth())}>{numFormat(totalPageviewsByMonth())}</Table.Cell>);
      tableTotalCell.push(<Table.Cell textAlign="right" title={numFormat(totalPageviewsByQuarter())}>{numFormat(totalPageviewsByQuarter())}</Table.Cell>);
      tableTotalCell.push(<Table.Cell textAlign="right" title={numFormat(totalPageviewsByYear())}>{numFormat(totalPageviewsByYear())}</Table.Cell>);

      tableGrandTotalCell.push(<Table.Cell title={"Total"}>Grand Total:</Table.Cell>);
      tableGrandTotalCell.push(<Table.Cell>-</Table.Cell>);

      conditionPrevious ?
        tableGrandTotalCell.push(
          <Table.Cell textAlign="center">{numFormat(props?.indPrimaryTopicCount?.sum_page_views_count)}</Table.Cell>,
          <Table.Cell textAlign="center">{numFormat(props?.indPrimaryTopicCount?.sum_prev_page_views_count)}</Table.Cell>
        )
      :
        tableGrandTotalCell.push(<Table.Cell textAlign="right">{numFormat(props?.indPrimaryTopicCount?.sum_page_views_count)}</Table.Cell>);
			
        conditionPrevious ?
        tableGrandTotalCell.push(
          <Table.Cell textAlign="center">{numFormat(props?.indPrimaryTopicCount?.sum_unique_impressions_count)}</Table.Cell>,
          <Table.Cell textAlign="center">{numFormat(props?.indPrimaryTopicCount?.sum_prev_unique_impressions_count)}</Table.Cell>
        )
      :
        tableGrandTotalCell.push(<Table.Cell textAlign="right">{numFormat(props?.indPrimaryTopicCount?.sum_unique_impressions_count)}</Table.Cell>);

      tableGrandTotalCell.push(<Table.Cell textAlign="right">{numFormat(props?.indPrimaryTopicCount?.total_page_views_by_month)}</Table.Cell>);
      tableGrandTotalCell.push(<Table.Cell textAlign="right">{numFormat(props?.indPrimaryTopicCount?.total_page_views_by_quarter)}</Table.Cell>);
      tableGrandTotalCell.push(<Table.Cell textAlign="right">{numFormat(props?.indPrimaryTopicCount?.total_page_views_by_year)}</Table.Cell>);

      tableRow.push(<Table.Row children={tableTotalCell} />);
      tableRow.push(<Table.Row children={tableGrandTotalCell} />);

			return tableRow;
    }
  };

  if (!_.isEmpty(props.editorialPrimaryTopicSummary) && props.filterData.isTopicSummary) {
    tableBody = <Table.Body>{createRow(props.editorialPrimaryTopicSummary)}</Table.Body>;
    showMoreButton = (
      <div className="table-divider">
        <Divider>
          <div className="show-more-component">
            {!props.editorialFetchDetailData.resIndependantPrimaryTopic ? 
            (
            <button className="show-more-button" onClick={showMore}>
              Show More
            </button>
            ):(
            <button className="show-more-button-disable">
              Show More
            </button>
            )}
            
          </div>
        </Divider>
      </div>
    );
  } else if (
    props.isFilApplied &&
    !props.loading &&
    _.isEmpty(props.editorialPrimaryTopicSummary) && 
    props.filterData.isTopicSummary
  ) {
    noresult = <Message>No results found.</Message>;
  }

  return (
    <div>
      <div className="sortable-table-component">
        <Table sortable fixed singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell 
                className="custom-column-large"
                sorted={props.column === "primary_topic" ? props.direction : null}
                onClick={ () => props.sortEditorialPrimaryTopic("primary_topic") }
              >
                Primary Topic
              </Table.HeaderCell>
              <Table.HeaderCell 
                sorted={props.column === "sites" ? props.direction : null}
                onClick={ () => props.sortEditorialPrimaryTopic("sites") }
              >
                Sites
              </Table.HeaderCell>
              <Table.HeaderCell 
                colSpan={conditionPrevious && "2"}
                className="custom-column-large"
                sorted={ !conditionPrevious && (props.column === "page_views_count" ? props.direction : null) }
                onClick={ () => {
                  if(!conditionPrevious) {
                    props.sortEditorialPrimaryTopic("page_views_count");
                  }
                }}
                textAlign={conditionPrevious ? "center" : "right"}
              >
              Pageviews
              { conditionPrevious && (<CurrentPreviousHeader props={props} currentVal="page_views_count" previousVal="previous_period_pageviews"/>)}
              </Table.HeaderCell>
              

              <Table.HeaderCell 
                colSpan={conditionPrevious && "2"}
                className="custom-column-large"
                sorted={ !conditionPrevious && (props.column === "unique_page_views" ? props.direction : null) }
                onClick={ () => {
                  if(!conditionPrevious) {
                    props.sortEditorialPrimaryTopic("unique_page_views");
                  }
                }}
                textAlign={conditionPrevious ? "center" : "right"}
              >
              Unique Pageviews
              { conditionPrevious && (<CurrentPreviousHeader props={props} currentVal="unique_page_views" previousVal="previous_period_unique_pageviews" />)}
              </Table.HeaderCell>

              <Table.HeaderCell
                className="custom-column-large"
                sorted={props.column === "total_page_views_by_month" ? props.direction : null}
                onClick={ () => props.sortEditorialPrimaryTopic("total_page_views_by_month") }
                textAlign="right"
              >
                Total Pageviews for Month
              </Table.HeaderCell>

              <Table.HeaderCell 
                className="custom-column-large"
                sorted={props.column === "total_page_views_by_quarter" ? props.direction : null}
                onClick={ () => props.sortEditorialPrimaryTopic("total_page_views_by_quarter") }
                textAlign="right"
              >
                Total Pageviews for Quarter
              </Table.HeaderCell>

              <Table.HeaderCell 
                className="custom-column-large"
                sorted={props.column === "total_page_views_by_year" ? props.direction : null}
                onClick={ () => props.sortEditorialPrimaryTopic("total_page_views_by_year") }
                textAlign="right"
              >
                Total Pageviews For Year
              </Table.HeaderCell>



            </Table.Row>
          </Table.Header>
          {tableBody}
        </Table>
      </div>
      {loading}
      {showMoreButton}
      {noresult}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    editorialPrimaryTopicSummary: state.editorialFetchDetailData.editorialPrimaryTopicSummary,
    loading: state.editorialFetchDetailData.isLoadingEditorialPrimaryTopicSummary,
    filStartDate: state.editorialFilterData.startDate,
    filEndDate: state.editorialFilterData.endDate,
    column: state.editorialFetchDetailData.column,
    direction: state.editorialFetchDetailData.editorialPrimaryTopicSummaryDirection,
    isFilApplied: state.editorialFetchDetailData.isFilApplied,
    PageCount: state.editorialFetchDetailData.editorialPrimaryTopicPageCount,
    editorialFilterData: state.editorialFilterData,
    editorialFetchDetailData: state.editorialFetchDetailData,
    filterData: state.editorialFilterData,
    isTopicSummary: state.editorialFetchDetailData.isTopicSummary,
    indPrimaryTopicCount: state.editorialFetchDetailData.indPrimaryTopicCount
  };
};

export default connect(mapStateToProps, {
  sortEditorialPrimaryTopic,
  clearEditorialSort,
  fetchEditorialTableData,
  showMoreEditorialTableData,
  selEditorialFilterValue,
  clearEditorialSerchedValue,
})(EditorialPrimaryTopicSummary);
