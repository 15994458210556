import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { connect } from "react-redux";
import { numFormat } from "../../../utilities/common";

const ClientLeadProgressChart = (props) => {
  const [legendClicked, setLegendClicked] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    let leadStatusChartPreparedData = leadValidationBarChart();
    setChartData(leadStatusChartPreparedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legendClicked]);

  const onLegendClick = (e) => {
    if (legendClicked.includes(e)) {
      if (e === "Not Started") {
        setLegendClicked((prev) => prev.filter((i) => i !== e));
      } else {
        setLegendClicked((prev) => prev.filter((i) => i !== e));
      }
    } else {
      if (e === "Not Started") {
        setLegendClicked((prev) => [...prev, e]);
      } else {
        setLegendClicked((prev) => [...prev, e]);
      }
    }
  };

  const colors = [
      "#B6B7B8",
      "#FCAF17",
      "#F15A29",
      "#0077B7",
      "#33AC2E",
      "#D63649",
    ];
  const keyNames = [
      "Not Started",
      "Received",
      "In Review",
      "Accepted not Delivered",
      "Delivered to Customer",
      "Rejected",
    ];


  const getSeries = () => {
    let series = {};
    for (let i = 0; i < keyNames.length; i++) {
      let j = i;
      let color;
      color =
        legendClicked.indexOf(keyNames[i]) === -1 &&  colors[i];
      legendClicked.indexOf(keyNames[i]) === -1 ?
      series[j] = { axis: keyNames[i], color: color } :
      series[j] = {axis: keyNames[i], targetAxisIndex:1, color: "transparent" }
      // newSeries[j + 0] = { axis: keyNames[i], targetAxisIndex:1, color:updatedColors[i]}

     series[6] = { axis: "Leads Purchased", color: "#838486" };

    }
    return series;
  };

  const leadValidationBarChart = () => {
    var data = [];
    let d = [];

    data.push([
      "",
      { type: "string", role: "tooltip", p: { html: true } },
      "Not Started",
      "Received",
      "In Review",
      "Accepted not Delivered",
      "Delivered to Customer",
      "Rejected",
      "Leads Purchased",
    ]);
    d = [];
    // const leadOutstanding = props.leadValidationBarChart.leads_progress?.lead_outstanding;
    const receivedCount =
      props.leadValidationBarChart.leads_progress?.received_count;
    const inReviewCount =
      props.leadValidationBarChart.leads_progress?.in_review_count;
    const acceptedCount =
      props.leadValidationBarChart.leads_progress?.accepted_count;
    const leadsDelivered =
      props.leadValidationBarChart.leads_progress?.leads_delivered;
    const rejectedCount =
      props.leadValidationBarChart.leads_progress?.rejected_count;
    const leadsPurchased =
      props.leadValidationBarChart.leads_progress?.leads_purchased;
    d.push("");
    d.push(
      `<div class="tooltip-pageview-values" style='width: auto; padding-top: 10px; padding-left: 3px; margin-bottom:0px;'>` +
        "<span style='height: 6px; width: 6px; margin-right: 5px; background-color:#B6B7B8;border-radius: 50%; display: inline-block;'></span>" +
        numFormat(props.leadValidationBarChart.leads_progress?.not_started) +
        ` - Not Started` +
        `<br />` +
        "<span style='height: 6px; width: 6px; margin-right: 5px; background-color:#FCAF17;border-radius: 50%; display: inline-block;'></span>" +
        numFormat(receivedCount) +
        ` - Received` +
        `<br />` +
        "<span style='height: 6px; width: 6px; margin-right: 5px; background-color:#F15A29;border-radius: 50%; display: inline-block;'></span>" +
        numFormat(inReviewCount) +
        ` - In Review` +
        `<br />` +
        "<span style='height: 6px; width: 6px; margin-right: 5px; background-color:#0077B7;border-radius: 50%; display: inline-block;'></span>" +
        numFormat(acceptedCount) +
        ` - Accepted not Delivered` +
        `<br />` +
        "<span style='height: 6px; width: 6px; margin-right: 5px; background-color: #33AC2E;border-radius: 50%; display: inline-block;'></span>" +
        numFormat(leadsDelivered) +
        ` - Delivered to Customer` +
        `<br />` +
        "<span style='height: 6px; width: 6px; margin-right: 5px; background-color:#D63649;border-radius: 50%; display: inline-block;'></span>" +
        numFormat(rejectedCount) +
        ` - Rejected` +
        `</div>`
    );
    // d.push( props.leadValidationBarChart.leads_progress?.not_started);
    d.push(props.leadValidationBarChart.leads_progress?.not_started);
    d.push(receivedCount);
    d.push(inReviewCount);
    d.push(acceptedCount);
    d.push(leadsDelivered);
    d.push(rejectedCount);
    d.push(null);

    data.push(d);
    data.push([
      "",
      `<div class="tooltip-pageview-values" style='width: auto; padding-top: 10px; padding-left: 5px; padding-bottom: 10px;'>` +
        "<span style='height: 6px; width: 6px; margin-right: 5px; background-color:#838486;border-radius: 60%; display: inline-block;'></span>" +
        numFormat(leadsPurchased) +
        ` - Leads Purchased` +
        `<br />` +
        `</div>`,
      null,
      null,
      null,
      null,
      null,
      null,
      leadsPurchased,
    ]);

    return data;
  };

  const options = {
    //  colors: ["#B6B7B8", "#FCAF17", "#F15A29","#0077B7", "#33AC2E", "#D63649"],
    legend: "none",
    chartArea: { left: "2%", top: "15%", width: "92%", height: "auto"},
    isStacked: true,
    crosshair: {
      orientation: "vertical",
      color: "grey",
      opacity: 2,
      trigger: "both",
    },
    hAxis: {
      baselineColor: "#fff",
      gridlineColor: "#fff",
      minValue: 0,
    },
    vAxis: {
      baselineColor: "#fff",
      gridlineColor: "#fff",
      format: "short"
    },
    tooltip: { isHtml: true },
    focusTarget: "category",
    //   series: {
    //     6: {
    //         color: "#838486",
    //     }
    // }
    series: getSeries(),
  };
  return (
    <div
      className="lead-validation-barchart bar-chart-fill"
      style={{ height: "240px" }}
    >
      <div>
        <div>
          <div
            style={{
              marginLeft: -51,
              marginTop: 22,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="legend-item" style={{ marginLeft: "25px" }}>
              <span
                style={{backgroundColor:"#838486"}}
                className="legend-circle"
              />
              <text
                style={{ color: "#656669" }}
                className="topic-text"
              >
                Leads Purchased
              </text>
            </div>
            <div style={{marginRight: 72, paddingTop: 10}}>
            <div className="legend-item"
                onClick={() => {
                  onLegendClick("Not Started");
                }}
            style={{ marginLeft: "5px", cursor: "pointer" }}>
              <span
                style={{
                  backgroundColor: !legendClicked.includes("Not Started")
                    ? "#B6B7B8"
                    : "grey",
                    cursor: "pointer"
                }}
                className="legend-circle"
              />
              <text
                style={{ color: "#656669" }}
                className="topic-text"
              >
                Not Started
              </text>
            </div>
            <div className="legend-item" 
              onClick={() => {
                onLegendClick("Received");
              }}
            style={{ paddingLeft: 5, cursor: "pointer" }}>
              <span
                style={{
                  backgroundColor: !legendClicked.includes("Received")
                    ? "#FCAF17"
                    : "grey",
                    cursor: "pointer"
                }}
                className="legend-circle"
              />
              <text
                style={{ color: "#656669" }}
                className="topic-text"
              >
                Received
              </text>
            </div>
            <div className="legend-item" 
                onClick={() => {
                  onLegendClick("In Review");
                }}
            style={{ paddingLeft: 5, cursor: "pointer" }}>
              <span
                style={{
                  backgroundColor: !legendClicked.includes("In Review")
                    ? "#F15A29"
                    : "grey",
                    cursor: "pointer"
                }}
                className="legend-circle"
              />
              <text
                style={{ color: "#656669", marginRight: 30 }}
                className="topic-text"
              >
                In Review
              </text>
            </div>
            <div className="legend-item" 
                onClick={() => {
                  onLegendClick("Accepted not Delivered");
                }}
            style={{ marginLeft: "-30px", cursor: "pointer" }}>
              <span
                style={{
                  backgroundColor: !legendClicked.includes(
                    "Accepted not Delivered"
                  )
                    ? "#0077B7"
                    : "grey",
                    cursor: "pointer"
                }}
                className="legend-circle"
              />
              <text
                style={{ color: "#656669", marginRight: 30 }}
                className="topic-text"
              >
                Accepted not Delivered
              </text>
            </div>
            <div className="legend-item"
                onClick={() => {
                  onLegendClick("Delivered to Customer");
                }}
            style={{ marginLeft: "-30px", cursor: "pointer" }}>
              <span
                style={{
                  backgroundColor: !legendClicked.includes(
                    "Delivered to Customer"
                  )
                    ? "#33AC2E"
                    : "grey",
                    cursor: "pointer"
                }}
                className="legend-circle"
              />
              <text
                style={{ color: "#656669", marginRight: 30 }}
                className="topic-text"
              >
                Delivered to Customer
              </text>
            </div>
            <div className="legend-item"
              onClick={() => {
                onLegendClick("Rejected");
              }}
            style={{ marginLeft: "-30px", cursor: "pointer" }}>
              <span
                style={{
                  backgroundColor: !legendClicked.includes("Rejected")
                    ? "#D63649"
                    : "grey",
                    cursor: "pointer"
                }}
                className="legend-circle"
              />
              <text
                style={{ color: "#656669", marginRight: 30 }}
                className="topic-text"
              >
                Rejected
              </text>
            </div>
            </div>
          </div>
        </div>
        <Chart
          chartType="BarChart"
          width="100%"
          height="150px"
          data={chartData}
          // diffdata={leadValidationBarChart()}
          options={options}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    leadValidationBarChart: state.clientLeadFetchData.leadValidationBarChart,
    isFilApplied: state.clientLeadFetchData.isFilApplied,
  };
};

export default connect(mapStateToProps)(ClientLeadProgressChart);
