import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import SelectDatePicker from "../common/SelectDatePicker";
import {
    selectValue
} from "../../actions/commonFilterActions";
import { getDisplayDate, getStartEndDates, getYesterdaysDate } from "../../utilities/common";
import CustomDatePickerWithCompare from "../common/CustomDatePickerWithCompare";
import { differenceInDays, subDays, subYears } from "date-fns";
import * as actionTypes from "../../actions/actionTypes";
import { getCompareDateFunction } from "../../utilities/Calendar";
import _ from "lodash";

const DateComponent = (props) => {
    const [datePopup, setDatePopup] = useState("custom-date-popup-close");
    const [checkSelectState, setCheckSelectState] = useState(false);
    const [comparePeriod, setComparePeriod] = useState("Previous Period");

    const [dateRange, setDateRange] = useState({
        selection: {
            startDate: getYesterdaysDate(),
            endDate: getYesterdaysDate(),
            key: "selection",
        },
    });

    const tempSelection = useRef({});
    const dispatchAction = useDispatch();

    useEffect(() => {
        if (document.getElementsByClassName("rdrDateDisplayWrapper")?.length !== 0) {
            document.getElementsByClassName("rdrDateDisplayWrapper")[0].style.display = "none";
        }
    }, [checkSelectState]);

    useEffect(() => {
        if (props.isClear) {
            setDateRange({ selection: { startDate: new Date(), endDate: new Date(), key: "selection" } });
            setCheckSelectState(false);
            setDatePopup("custom-date-popup-close");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isClear]);

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    const selectDateRange = async (e, { value }) => {
        dispatchDynamicSelectAction(props, "appliedDate", value);
        setDatePopup("custom-date-popup-close");
        if (value === "Custom Date Range") {
            setDatePopup("custom-date-popup-open");
        } else {
            let dates = getStartEndDates(value);
            dispatchDynamicSelectAction(props, "startDate", dates.startDate);
            dispatchDynamicSelectAction(props, "endDate", dates.endDate);
            dispatchDynamicSelectAction(props, "previousStartDate", dates.startDate);
            dispatchDynamicSelectAction(props, "previousEndDate", dates.endDate);
            dispatchDynamicSelectAction(props, "selCustomRangeCheckBox", false);

            cancelDate();

            if (props.filterData.isFilApplied) {
                props.applyFilters(dates.startDate, dates.endDate, value, false);
                dispatchAction({
                    type: actionTypes[props.reportActionType].UPDATED_CHART_STATUS,
                    reportType: props.reportActionType,
                    payload: {
                        chartApiCompleted: false,
                    },
                });
            }
        }
    };

    const cancelDate = () => {
        setCheckSelectState(false);
        setDateRange({
            selection: { startDate: new Date(), endDate: new Date(), key: "selection" },
        });
        setDatePopup("custom-date-popup-close");
    };

    const cutomDate = (startDate, endDate) => {
        return getDisplayDate(startDate) + " - " + getDisplayDate(endDate);
    };

    const previousPeriodData = (startDate, endDate, comparePeriod) => {
        dispatchDynamicSelectAction(props, "selCustomRangeCheckBox", checkSelectState);
        let prevStartDate, prevEndDate;
        
        if (comparePeriod === "Previous Year") {
            prevStartDate = subYears(new Date(startDate), 1);
            prevEndDate = subYears(new Date(endDate), 1);
        } else if (_.includes(["7", "30", "90"], comparePeriod)) {
            const result = getCompareDateFunction(props, dispatchDynamicSelectAction, comparePeriod);
            startDate = new Date(result.startDate);
            endDate = new Date(result.endDate);
            prevStartDate = new Date(result.prevStartDate);
            prevEndDate = new Date(result.prevEndDate);
        } else if (comparePeriod === "Previous Day of Week") {
            endDate = startDate;
            prevStartDate = subDays(startDate, 7); 
            prevEndDate = subDays(startDate, 7); 
        } else {
            let differenceinDays = differenceInDays(endDate, startDate);
            prevEndDate = subDays(new Date(startDate), 1);
            prevStartDate = subDays(new Date(prevEndDate), differenceinDays);
        }
        
        setDateRange({
            selection1: { startDate: startDate, endDate: endDate, key: "selection1", color: "#e5582b" },
            selection2: { startDate: prevStartDate, endDate: prevEndDate, key: "selection2", color: "#CC167F" },
        });

        return cutomDate(prevStartDate, prevEndDate);
    };

    const getClearDate = () => {
        setCheckSelectState(false);
        let sel = checkSelectState ? dateRange.selection1 : dateRange.selection;
        sel.key = "selection";
        setDateRange({ selection: sel });
    };

    const applyDate = () => {
        dispatchDynamicSelectAction(props, "selCustomRangeCheckBox", checkSelectState);
        if (dateRange.hasOwnProperty("selection")) {
            tempSelection.current = dateRange.selection;
            dispatchDynamicSelectAction(props, "startDate", getDisplayDate(tempSelection.current?.startDate));
            dispatchDynamicSelectAction(props, "endDate", getDisplayDate(tempSelection.current?.endDate));
        } else if (dateRange.hasOwnProperty("selection1")) {
            tempSelection.current = dateRange.selection1;
            dispatchDynamicSelectAction(props, "startDate", getDisplayDate(dateRange?.selection1?.startDate));
            dispatchDynamicSelectAction(props, "endDate", getDisplayDate(dateRange?.selection1?.endDate));
            dispatchDynamicSelectAction(props, "previousStartDate", getDisplayDate(dateRange?.selection2?.startDate));
            dispatchDynamicSelectAction(props, "previousEndDate", getDisplayDate(dateRange?.selection2?.endDate));
        }
        props.filterData.previousStartDate = getDisplayDate(dateRange?.selection2?.startDate);
        props.filterData.previousEndDate = getDisplayDate(dateRange?.selection2?.endDate);

        setDatePopup("custom-date-popup-close");
        if (props.filterData.isFilApplied) {
            props.applyFilters(tempSelection.current.startDate, tempSelection.current.endDate, "Custom Date Range", checkSelectState);
            dispatchAction({
                type: actionTypes[props.reportActionType].UPDATED_CHART_STATUS,
                reportType: props.reportActionType,
                payload: {
                    chartApiCompleted: false,
                },
            });
        }
    };

    return (
        <div>
            <div className="date-picker">
                {/* Date picker */}
                <SelectDatePicker
                    {...props}
                    selectDateRange={selectDateRange}
                    data={props.filterData}
                    applyDate={props.selectValue}
                    commonForSelect={true}
                    setDatePopup={setDatePopup}
                    dateRange={dateRange}
                    checkSelectState={checkSelectState}
                />
                <CustomDatePickerWithCompare
                    datePopup={datePopup}
                    setDatePopup={setDatePopup}
                    checkSelectState={checkSelectState}
                    cutomDate={cutomDate}
                    dateRange={dateRange}
                    setCheckSelectState={setCheckSelectState}
                    setComparePeriod={setComparePeriod}
                    previousPeriodData={previousPeriodData}
                    comparePeriod={comparePeriod}
                    getClearDate={getClearDate}
                    setDateRange={setDateRange}
                    cancelDate={cancelDate}
                    applyDate={applyDate}
                    isComparePeriod={props?.isComparePeriod ?? true}
                />
            </div>
        </div>
    );
}

const mapStateToProps = ({ commonFilterData }) => {
    return {
        isFilApplied: commonFilterData.isFilApplied,
    };
};

export default compose(connect(mapStateToProps, {
    selectValue,
}))(DateComponent);