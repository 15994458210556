import React from "react";
import { Table } from "semantic-ui-react";
import Link from "../hyperlinks/link";

const UserNameComponent = ({ value, userUuid }) => {
   const isAnonymousOrNA = value === "Anonymous" || value === "N/A";
   const displayValue = isAnonymousOrNA ? "Anonymous" : value;

   return (
      !isAnonymousOrNA ?
        <Table.Cell title={value} className="blogs_link space_height">
          <Link type="members" urlParam={userUuid} refValue={value}></Link>
        </Table.Cell> :
        <Table.Cell title={value} className="space_height">{displayValue}</Table.Cell>
    );
};

export default UserNameComponent;