import React from 'react';
import { Table, Icon, Menu, Checkbox } from 'semantic-ui-react';

const DynamicHeaderColumnSelector = ({
    isSortIcon,
    clickOnSortIcon,
    TableHeaderData,
    selectHeaders,
    clickOnSortHeader,
    applyButtonClick,
}) => (
    <div className="sorted_icon_for_ads">
        <Table.HeaderCell style={{ paddingBottom: "20px" }}>
            <Icon
                name="edit"
                style={{ color: `${isSortIcon ? "#f15a29" : "#b2b3b3"}` }}
                size="large"
                onClick={clickOnSortIcon}
            />
        </Table.HeaderCell>
        {isSortIcon && (
            <div
                className="menu-list-items"
                style={{ height: "0px", float: "right" }}
            >
                <Menu vertical className="dynamic_table--list_for_ads">
                    {TableHeaderData.map((val, index) => (
                        <div key={index} className={`${val.headerName === "" && "domain"}`}>
                            <Checkbox
                                className="table_header--checkbox"
                                style={{ color: "#838486" }}
                                label={val.headerName}
                                checked={
                                    selectHeaders &&
                                    selectHeaders.some(
                                        (el) => el.headerKey === val.headerKey
                                    )
                                }
                                onClick={(e, data) => clickOnSortHeader(e, data, val)}
                            />
                        </div>
                    ))}
                    <button className="apply_menu--btn" onClick={applyButtonClick}>
                        Apply
                    </button>
                </Menu>
            </div>
        )}
    </div>
);

export default DynamicHeaderColumnSelector;
