import React from "react";
import { connect } from "react-redux";
import * as constName from "../components/config/const";
import AuthorMain from "../components/AuthorReport/Main";
import { ROLEBASED_ELEMENT_AUTHOR } from "../components/config/rolebasedReportNames";
import { getReportsByElement } from "../utilities/common";

const Author = (props) => {
    const roleReports = getReportsByElement(props?.roleFilterData?.currentUserReportRoleData, props?.roleFilterData?.getAllReports, ROLEBASED_ELEMENT_AUTHOR);
    
    return (
        <AuthorMain
            reportActionType={constName.MODULE_AUTHOR_REPORT}
            reportName={constName.AUTHOR_REPORT_NAME}
            filterData={props.filterData}
            reportDescription={props.reportDescription.find(report => report.name === "Author")?.description}
            label={constName.AUTHOR_REPORT_LABEL}
            roleReports={roleReports}
        />
    );
};

const mapStateToProps = ({ commonFilterData, allFilterReportData }) => {
    return {
        filterData: commonFilterData[constName.MODULE_AUTHOR_REPORT],
        reportDescription: allFilterReportData.reportDescription,
        roleFilterData: allFilterReportData,
    };
};

export default connect(mapStateToProps, {
    getReportsByElement
})(Author);