import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { compose } from "redux";

import { CPSummaryHeaderSector } from "./ContentPerformanceHeaders";
import { selectValue, getTableHeaders, sendTableHeaders } from "../../actions";
import { Loader, Message, Table } from "semantic-ui-react";
import GenericTable from "../ads/common/GenericTable";
import { columnConfigs } from "../ads/common/ColumnConfig";

const SummarySector = (props) => {

    return (
        <div>
            <div className="sortable-table-component">
                <Table sortable fixed singleLine>
                    <GenericTable 
                        data={props.filterData.contentPerformanceSummarySectorData}
                        headerOrder={CPSummaryHeaderSector}
                        columnConfigs={columnConfigs}
                    />
                </Table>
            </div>
            {
                props.filterData.isLoadingSector &&
                <div className="show-loader">
                    <Loader active inline="centered" />
                </div>
            }
            {
                props.filterData.isFilApplied && !props.filterData.isLoadingSector && _.isEmpty(props.filterData.contentPerformanceSummarySectorData) &&
                <Message>No results found.</Message>
            }
        </div>
    );
};

export default compose(connect(null, {
    selectValue,
    getTableHeaders,
    sendTableHeaders
}))(SummarySector);
