import React, { useEffect } from "react";
import * as actionTypes from "../../actions/actionTypes";
import { selPgFilterValue,
	 selTopicFilterValue, 
	 seladFilterValue,
	 selClientLeadFilterValue,
	 selLeadEngFilterValue,
	 selEditorialDetailsFilterValue,
	 selSponsoredContentFilterValue,
	 selEditorialFilterValue
	} from "../../actions";
import { connect } from "react-redux";
import "../../css/common.css";

const NotifyEmail = (props) => {
	useEffect(() => {
		const timeId = setTimeout(() => {
			if (props.table === "summary") {
				props.selPgFilterValue(
					false,
					actionTypes.SETPGPOSTEMAILUPDATEPAGESUMMARY
				);
				clearTimeout(timeId);

				//);
			} else if (props.table === "detail") {
				props.selPgFilterValue(false, actionTypes.SETPGPOSTEMAILUPDATE);
				clearTimeout(timeId);
			} else if (props.table === "independent-detail") {
				props.selEditorialDetailsFilterValue(false, actionTypes.SETEDPOSTEMAILUPDATE);
				clearTimeout(timeId);
			} else if(props.table === "sidebar_summary"){
				props.selTopicFilterValue(false, props.cardPopupClose)
				clearTimeout(timeId);
			}else if (props.table === "sidebar_detail"){
				props.selTopicFilterValue(false, props.cardPopupClose)
				clearTimeout(timeId);
			}else if(props.table === "sidebar_campaign_detail"){
				props.selTopicFilterValue(false, props.cardPopupClose)
				clearTimeout(timeId);
			}else if(props.table === "ad_summary"){
				props.seladFilterValue(false, props.cardPopupClose)
				clearTimeout(timeId);
			}else if (props.table === "ad_detail"){
				props.seladFilterValue(false, props.cardPopupClose)
				clearTimeout(timeId);
			}else if (props.table === "takeover_detail"){
				props.selTakeoverValue(false, props.cardPopupClose)
				clearTimeout(timeId);
			}else if (props.table === "lead_summary"){
				props.selLeadEngFilterValue(false, props.cardPopupClose)
				clearTimeout(timeId);
			}else if (props.table === "client_lead_summary"){
				props.selClientLeadFilterValue(false, props.cardPopupClose)
			}else if (props.table === "ad_detail_impression"){
				props.seladFilterValue(false, props.cardPopupClose)
				clearTimeout(timeId);
			} else if (props.table === "campaign_summary") {
				props.selSponsoredContentFilterValue(false, actionTypes.SETSCPOSTEMAILUPDATECAMPAIGNSUMMARY);
				clearTimeout(timeId);
			} else if (props.table === "campaign_pageview_details") {
				props.selSponsoredContentFilterValue(false, actionTypes.SETSCPOSTEMAILUPDATE);
				clearTimeout(timeId);
			} else if (props.table === "primary_topic") {
				props.selEditorialFilterValue(false, props.cardPopupClose);
				clearTimeout(timeId);
			}  else if (props.table === "page_summary") {
				props.selEditorialFilterValue(false, props.cardPopupClose);
				clearTimeout(timeId);
			} else if (props.table === "campaign_page_details") {
				props.selSponsoredContentFilterValue(false, actionTypes.SETSCPOSTEMAILUPDATEPAGEDETAIL);
				clearTimeout(timeId);
			} else if (props.table === "client_lead_Detail"){
				props.selClientLeadFilterValue(false, props.cardPopupClose)
			} else if (props.table === "independent-detail-CSV") {
				props.selEditorialDetailsFilterValue(false, actionTypes.SETEDPOSTEMAILUPDATECSV);
				clearTimeout(timeId);
			} else if (props.table === "campaign_pageview_details_csv_table") {
				props.selSponsoredContentFilterValue(false, actionTypes.SETSCCSVPOSTEMAILUPDATE);
				clearTimeout(timeId);
			} else if (props.table === "sidebar_details_table"){
				props.selTopicFilterValue(false, props.cardPopupClose)
				clearTimeout(timeId);
			}  else if (props.table === "primary_topic_CSV") {
				props.selEditorialFilterValue(false, props.cardPopupClose);
				clearTimeout(timeId);
			} else if (props.table === "page_summary_csv") {
				props.selEditorialFilterValue(false, props.cardPopupClose);
				clearTimeout(timeId);
			}  else if (props.table === "placement_topic_CSV") {
				props.selEditorialFilterValue(false, props.cardPopupClose);
				clearTimeout(timeId);
			}  else if (props.table === "author_summary_csv") {
				props.selEditorialFilterValue(false, props.cardPopupClose);
				clearTimeout(timeId);
			}  else if (props.table === "engagement_detail"){
				props.selLeadEngFilterValue(false, props.cardPopupClose)
				clearTimeout(timeId);
			} else if (props.table === "organization_summary_csv") {
				props.selEditorialFilterValue(false, props.cardPopupClose);
				clearTimeout(timeId);
			} else if (props.table === "activity_details") {
				props.selLeadEngFilterValue(false, props.cardPopupClose);
				clearTimeout(timeId);
			} else if (props.table === "light_activity_details") {
				props.selLeadEngFilterValue(false, props.cardPopupClose);
				clearTimeout(timeId);
			}
		}, 5000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.cardPopup]);

	return (
		<div className={props.cardPopup ? "ui card" : "card-popup-close"}>
			<div className="content">
				<div
					className="card-close-icon"
					onClick={() => {
						//	setcardPopup("card-popup-close");
						if (props.table === "summary") {
							props.selPgFilterValue(
								false,
								actionTypes.SETPGPOSTEMAILUPDATEPAGESUMMARY
							);
						} else if (props.table === "detail") {
							props.selPgFilterValue(false, actionTypes.SETPGPOSTEMAILUPDATE);
						} else if (props.table === "independent-detail") {
							props.selEditorialDetailsFilterValue(false, actionTypes.SETEDPOSTEMAILUPDATE);
						} else if(props.table === "sidebar_summary"){
							props.selTopicFilterValue(false, props.cardPopupClose)
						}else if (props.table === "sidebar_detail"){
							props.selTopicFilterValue(false, props.cardPopupClose)
						}else if (props.table === "sidebar_campaign_detail"){
							props.selTopicFilterValue(false, props.cardPopupClose)
						}else if(props.table === "ad_summary"){
							props.seladFilterValue(false, props.cardPopupClose)
						}else if (props.table === "ad_detail"){
							props.seladFilterValue(false, props.cardPopupClose)
						}else if (props.table === "takeover_detail"){
							props.selTakeoverValue(false, props.cardPopupClose)
						}else if (props.table === "lead_summary"){
							props.selLeadEngFilterValue(false, props.cardPopupClose)
						}else if (props.table === "client_lead_summary"){
							props.selClientLeadFilterValue(false, props.cardPopupClose)
						}else if (props.table === "ad_detail_impression"){
							props.seladFilterValue(false, props.cardPopupClose)
						} else if (props.table === "campaign_summary") {
							props.selSponsoredContentFilterValue(false, actionTypes.SETSCPOSTEMAILUPDATECAMPAIGNSUMMARY);
						} else if (props.table === "campaign_pageview_details") {
							props.selSponsoredContentFilterValue(false, actionTypes.SETSCPOSTEMAILUPDATE);
						}  else if (props.table === "primary_topic") {
							props.selEditorialFilterValue(false, props.cardPopupClose);
						}  else if (props.table === "page_summary") {
							props.selEditorialFilterValue(false, props.cardPopupClose);
						} else if (props.table === "campaign_page_details") {
							props.selSponsoredContentFilterValue(false, actionTypes.SETSCPOSTEMAILUPDATEPAGEDETAIL);
						} else if (props.table === "client_lead_Detail"){
							props.selClientLeadFilterValue(false, props.cardPopupClose)
						}  else if (props.table === "independent-detail-CSV") {
							props.selEditorialDetailsFilterValue(false, actionTypes.SETEDPOSTEMAILUPDATECSV);
						}  else if (props.table === "campaign_pageview_details_csv_table") {
							props.selSponsoredContentFilterValue(false, actionTypes.SETSCCSVPOSTEMAILUPDATE);
						} else if (props.table === "sidebar_details_table"){
							props.selTopicFilterValue(false, props.cardPopupClose)
						}  else if (props.table === "primary_topic_CSV") {
							props.selEditorialFilterValue(false, props.cardPopupClose);
						}  else if (props.table === "page_summary_csv") {
							props.selEditorialFilterValue(false, props.cardPopupClose);
						}  else if (props.table === "placement_topic_CSV") {
							props.selEditorialFilterValue(false, props.cardPopupClose);
						}  else if (props.table === "author_summary_csv") {
							props.selEditorialFilterValue(false, props.cardPopupClose);
						}  else if (props.table === "engagement_detail"){
							props.selLeadEngFilterValue(false, props.cardPopupClose)
						} else if (props.table === "organization_summary_csv") {
							props.selEditorialFilterValue(false, props.cardPopupClose);
						} else if (props.table === "activity_details") {
							props.selLeadEngFilterValue(false, props.cardPopupClose);
						} else if (props.table === "light_activity_details") {
							props.selLeadEngFilterValue(false, props.cardPopupClose);
						}
					}}
				>
					<i className="material-icons grey">clear</i>
				</div>
				<div className="header">{props.csvExcel? "Generate CSV" : "Generate XLSX"}</div>
			</div>
			<div className="content">
				{/* <h4 className="ui sub header">Current Email</h4> */}
				<div className="ui small feed">
					<div className="event">
						<div className="content">
							<div className="summary">
								<p>{props.csvExcel ? "CSV file is being generated and will be send shortly" : "XLSX file is being generated and will be send shortly"}</p>
							</div>
						</div>
					</div>
					<div className="event">
						<div className="content">
							<div className="summary">
								<a href={props.email}>{props.email}</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default connect("", {
	selPgFilterValue,
	selTopicFilterValue,
	seladFilterValue,
	selClientLeadFilterValue,
	selLeadEngFilterValue,
	selEditorialDetailsFilterValue,
	selSponsoredContentFilterValue,
	selEditorialFilterValue,
})(NotifyEmail);
