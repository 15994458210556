import React from "react";
import { Message, Table } from "semantic-ui-react";

import DateAndFilters from "../DateAndFilters";
import GenericTable from "../../ads/common/GenericTable";
import { diUpgradeHeaders } from "../DIHeaders";
import { columnConfigs } from "../../ads/common/ColumnConfig";

const Main = (props) => {
    return (
        <div>
            <div className="page-name-comp">
                <p className="page-name">{props.label}</p>
            </div>
            <DateAndFilters {...props} />
            <div className="table_gap">
                <div className="table-heading-block">
                    <div className="table-name">
                        <p>{`Decision Insights ${props.label}`}</p>
                    </div>
                    {/*  */}
                </div>
                <div className="sortable-table-component">
                    <Table sortable fixed singleLine>
                        <GenericTable
                            data={[]}
                            headerOrder={diUpgradeHeaders}
                            columnConfigs={columnConfigs}
                        />
                    </Table>
                </div>
            </div>
            
            {!props.filterData.isFilApplied && <Message>Please proceed with applying filter.</Message>}
        </div>
    );
};

export default Main;