import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";

import { clearSearchForAllReport, selectValue, fetchNewslettersReportData, fetchNewslettersDetailsData } from "../../actions";
import * as actionTypes from "../../actions/actionTypes";
import { Form } from "semantic-ui-react";
import DateComponent from "../CommonFilter/DateComponent";
import OrgSection from "../common/FilterSection/OrgSection";
import CampaignStatusPicker from "../common/FilterSection/CampaignStatusPicker";
import OrderMultiSelect from "../common/FilterSection/OrderMultiSelect";
import CampaignSelection from "../common/FilterSection/CampaignSelection";
import AuthorOrCreatedBySelection from "../common/FilterSection/AuthorOrCreatedBySelection";
import Site from "../common/site";
import CountrySelection from "../common/FilterSection/CountrySelection";
import EngagedOrgSelection from "../common/FilterSection/EngagedOrgSelection";
import FirmographicsFilterComponent from "../ads/common/FirmographicsFilterComponent";
import PersonaFilterComponent from "../ads/common/PersonaFilterComponent";
import NewsletterTypes from "../common/FilterSection/NewsletterTypes";
import NewsletterStatus from "../common/FilterSection/NewsletterStatusSelection";
import CompetitorFilterToggle from "../common/FilterSection/CompetitorFilterToggle";
import NewsActionsPicker from "../common/FilterSection/NewsActionsPicker";
import { NEWSLETTERS_DETAILS, NEWSLETTERS_SUMMARY } from "../config/rolebasedReportNames";
import { VIEW } from "../config/const";

const DateAndFilter = (props) => {
    const [filterSelectFocus, setFilterSelectFocus] = useState("filter-icon");
    const [filterPopup, setFilterPopup] = useState("filter-popup-close");
    const [filterIconColor, setFilterIconColor] = useState("material-icons grey");
    const [checkSelectState, setCheckSelectState] = useState(false);
    const [clear, setClear] = useState(false);

    const dispatchAction = useDispatch();

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    const applyFilters = (startDate, endDate, appDate, isCustomCheckbox) => {
        setCheckSelectState(isCustomCheckbox);
        props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].APPLY_FILTERS, reportType: props.reportActionType });
        let tempAdFilterData = props.filterData;
        tempAdFilterData.startDate = startDate;
        tempAdFilterData.endDate = endDate;
        tempAdFilterData.prevStartDate = "";
        tempAdFilterData.prevEndDate = "";
        tempAdFilterData.appliedDate = appDate;
        tempAdFilterData.selCustomRangeCheckBox = isCustomCheckbox;
        props.roleReports?.some((report) => [NEWSLETTERS_SUMMARY].includes(report.element) && report.permission === VIEW) &&
            props.fetchNewslettersReportData(
            tempAdFilterData,
            1,
            "",
            actionTypes[props.reportActionType].NEWSLETTERS_SUMMARY,
            props.reportName,
            props.reportActionType,
        );
        
        props.filterData.isSelectDetailToggle && props.roleReports?.some((report) => [NEWSLETTERS_DETAILS].includes(report.element) && report.permission === VIEW) && 
            props.fetchNewslettersDetailsData(
                tempAdFilterData,
                1,
                "",
                actionTypes[props.reportActionType].NEWSLETTER_DETAILS,
                props.reportName,
                props.reportActionType,
            );
        
        
        
        setFilterPopup("filter-popup-close");
        setFilterIconColor("material-icons grey");
        setFilterSelectFocus("filter-icon");
    }

    const clearFilters = () => {
        setClear(true);
        setTimeout(() => {
            setClear(false);
        }, 1500);
        props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].CLEARCOMMONFILTER, reportType: props.reportActionType });
    }

    return (
        <div>
            <DateComponent {...props} applyFilters={applyFilters} isClear={clear} isComparePeriod={false}/>
            <div className="popup-component">
                <div
                    className={filterSelectFocus}
                    style={{ marginTop: "-50px" }}
                    onClick={() => {
                        setFilterSelectFocus("filter-icon-selected");
                        setFilterPopup("filter-popup-open");
                        setFilterIconColor("material-icons orange");
                    }}
                >
                    <i className={filterIconColor}>filter_list</i>
                </div>
                <div className={filterPopup}>
                    <div className="filter-header">
                        <div className="filter-text">
                            <p>Filters</p>
                        </div>
                        <div
                            className="filter-close-icon"
                            onClick={() => {
                                setFilterSelectFocus("filter-icon");
                                setFilterPopup("filter-popup-close");
                            }}
                        >
                            <i
                                className="material-icons grey"
                                onClick={() => setFilterIconColor("material-icons grey")}
                            >
                                clear
                            </i>
                        </div>
                    </div>
                    
                    <Form>
                        <div className="ad-search-filter">
                            <Form.Group widths="equal">
                                <OrgSection {...props} />
                                <OrderMultiSelect {...props} />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <CampaignStatusPicker {...props} />
                                <CampaignSelection {...props} />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <AuthorOrCreatedBySelection {...props} />
                                <Site
                                    value={props.filterData.site}
                                    onChange={(e, { value }) => dispatchDynamicSelectAction(props, "site", value)}
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <NewsletterTypes {...props} />
                                <NewsletterStatus {...props} />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <CountrySelection {...props} />
                                <EngagedOrgSelection {...props} />
                            </Form.Group>
                            <Form.Group widths="equal" style={{ marginBottom: "3em", width: "24.8em" }}>
                                <NewsActionsPicker {...props} />
                            </Form.Group>
                            <FirmographicsFilterComponent {...props} isApplicable={false}/>
                            <PersonaFilterComponent {...props} isApplicable={false}/>
                            
                            <Form.Group widths="equal">
                                <div className="search-filter-column-1">
                                    <CompetitorFilterToggle {...props} style={{ marginTop: "10px", marginLeft: "10px" }} />
                                </div>
                                <div className="search-filter-column-2"></div>
                            </Form.Group>
                        </div>
                    </Form>
                    
                    <div className="filter-buttons">
                        <button className="cancel-date-button extra-space" onClick={clearFilters}>Clear Filters</button>
                        <button
                            type="submit"
                            className="apply-filter-button"
                            onClick={() => {
                                applyFilters(
                                    props.filterData.startDate,
                                    props.filterData.endDate,
                                    props.filterData.appliedDate,
                                    checkSelectState
                                );
                                if (props.filterData.isFilApplied) {
                                    dispatchAction({
                                        type: actionTypes[props.reportActionType].UPDATED_CHART_STATUS,
                                        reportType: props.reportActionType,
                                        payload: {
                                            chartApiCompleted: false,
                                        },
                                    });
                                }
                            }}
                        >
                            Apply Filters
                        </button>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default compose(connect(null, {
    clearSearchForAllReport,
    selectValue,
    fetchNewslettersReportData,
    fetchNewslettersDetailsData,
}))(DateAndFilter);