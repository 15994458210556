import * as actionTypes from "../actions/actionTypes";
import { getLoginUrl, removeCookies } from "../utilities/envCommon";

const inititalState = {
	token: null,
	userId: null,
	userName: null,
	userEmail: null,
};

export const authReducer = (state = inititalState, action) => {
	switch (action.type) {
		case actionTypes.AUTHLOGIN:
			return {
				token: action.payload.token,
				userId: action.payload.user.ID,
				userName: action.isWordPress ? action.payload.user.display_name : action.payload.user.first_name + " " + action.payload.user.last_name,
				userEmail: action.isWordPress ? action.payload.user.user_email : action.payload.user.email,
			};
		case actionTypes.AUTHLOGOUT:
			removeCookies();
			return {
				token: null,
				userId: null,
				userName: null,
				userEmail: null,
			};
		case actionTypes.ISLOGGEDIN:
			return {
				token: action.token,
				userId: action.userId,
				userName: action.userName,
				userEmail: action.userEmail,
			};
		default:
			return state;
	}
};

export const wpLinkReducer = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.WPLINK:
			let wpLink, apiClientId, apiOAuthState;
			action.payload.forEach((config) => {
				if (config.label === "sdx_wp_url") {
					wpLink = config.value;
				}

				if (config.label === "sdx_wp_client_id") {
					apiClientId = config.value;
				}

				if (config.label === "sdx_wp_oauth_state") {
					apiOAuthState = config.value;
				}
			});
			const loginLink = getLoginUrl(wpLink, apiClientId, apiOAuthState);
			return { wpLink, loginLink };
		default:
			return state;
	}
};
