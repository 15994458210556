import React from 'react';
import './Error.css';
import { Message } from 'semantic-ui-react';

class ErrorPage extends React.Component {
  render() {
    return (
      <Message warning>
        <Message.Header>An error has occurred.</Message.Header>
        <p>Unable to process your request at this moment.</p>
      </Message>
    );
  }
}

export default ErrorPage;
