import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";
import {
	getDisplayDate,
	getYesterdaysDate,
	getLastDays,
	getStartEndDates,
	getDateEng,
	getTimeEng,
} from "../utilities/common";
import { siteValue } from "../constants";
import createCommonReducer from "./createCommonReducer";

const leadFilterData = {
	startDate: getDisplayDate(getLastDays(0)),
	endDate: getDisplayDate(getYesterdaysDate()),
	appliedDate: "2",
	prevendDate: getDisplayDate(getYesterdaysDate()),
	prevstartDate: getDisplayDate(getLastDays(7)),
	prevAppliedDate: "",
	assetType: "",
	organization: [],
	campaign: [],
	order: [],
	title: [],
	site: siteValue,
	leadPartner: "",
	campaignStatus: "active",
	engagedOrganizations: [],
	leadEngsFilterStatus: "",
	leadEngsFilterVerificationState: "",
	globalLeadEngsStatus: "",
	globalLeadEngsVerificationState: "",
	applyDateCalendarState: false,
	leadEngsStatusRowWise: [],
	verificationStateRowWise: [],
	leadEngsCheckboxState: [],
	excludeEmailSupression: false,
	//organizationLimits:false,
	overrideLeadReportFilters: true,
	overrideReportingAttributes: false,
	excludeCompetitorEvents: true,
	excludeInternalEvents: true,
	excludeOrganizationEvents: true,
	selLeadEngChart: false,
	excludeGatedPageviews: false,
	excludeFreeEmailEvents: true,
	excludeQACampaign: true,
	excludeInternalCampaign: true,
	account: false,
	vertical: false,
	geo: false,
	customQuestions: false,
	firmoGraphic: false,
	types: false,
	size: false,
	revenue: false,
	personas: false,
	seniority: false,
	jobFunctions: false,
	jobTitles: false,
	selUrlSource: "",
	selUrlMedium: "",
	selUrlCampaign: "",
	selUrlTerm: "",
	selUrlContent: "",
	clientDeliverdFilterState: "",
  	clientDeliverdFilterStateOptions: [],
	pacingFilter: "",
	leadIsIncludeParent: false,
	leadIsIncludeChild: false,
	selHeaderDropdownOptionValue: "",
	showMoreClicked: false,
	utmParams: false,
	messageBox: true,
	messageBoxRefresh: true,
	trafficType: "",
	domainFilter: "all",
	competitorFiltering: false,
	setCSM: [],
	privacyParams: false,
	regulationName: [],
	purposeConsent: "all",
	marketingConsent: "all",
	doubleOptin: "all",
	dnsmsPersonalInfo: "all",
	rtbForgotten: "all",
	conditioningConsent: "all",
	ageVerification: "all",
};

const leadFetchData = {
	isFilApplied: false,
	engagementValidationChart: [],
	isLoadingChart: false,
	leadEngsValidationBarChart: {},
	leadEngSummary: {},
	leadEngSummaryCount: {},
	leadEngCampaignSummary: {},
	isLoadingLeadEngSummary: false,
	leadEngs: {},
	isLoadingLeadEngs: false,
	leadEngsCsv: "",
	isLoadingLeadEngsCsv: false,
	leadEngSummarycolumn: null,
	leadEngCampaignSummarycolumn: null,
	column: null,
	direction: null,
	leadEngCampaignSummarydirection: null,
	leadEngSummarydirection: null,
	summaryPageCount: "",
	detailPageCount: "",
	changedLogs:{},
	emailLeadSummary:"",
	cardPopupLeadSummary:"",
	postEmailUpdateLeadSummary:"",
	postEmailUpdateLeadDetail: "",
	getDynamicHeaderData: [],
	dynamicHeaderArray: [],
	engsStatusChart: false,
	isLoadingStatusChart: false,
	engsAcceptanceChart: false,
	isLoadingAcceptanceChart: false,
	leadEngsAcceptanceBarChart: {},
	resCampaignSummaryLeadEngsValue: null,
	resEngsDetailsValue: null,
	totalLeadCount: 0,
	emailEngDetail: "",
	cardPopupEngDetail: "",
	engDetailCsv: "",
	isLoadingEngDetailCsv: false,
	mailDelivery: {},
	downloadFileName: "",
};

const selectedArray = {
	csmArray: [],
	regulationData: [],
}

export const leadFilterDataReducer = (state = leadFilterData, action) => {
	switch (action.type) {
		case actionTypes.SELECTED_ENG_TOGGLE:
      		return { ...state, [action.payload.key]: action.payload.value };
		case actionTypes.SELLEADSTARTDATE:
			return { ...state, startDate: action.payload };
		case actionTypes.SELLEADENDDATE:
			return { ...state, endDate: action.payload };
		case actionTypes.SELLEADPREVAPPLIEDDATE:
			return { ...state, prevAppliedDate: action.payload };
		case actionTypes.SELLEADENDDATEPREV:
			return { ...state, prevendDate: action.payload };
		case actionTypes.SELLEADSTARTDATEPREV:
			return { ...state, prevstartDate: action.payload };
		case actionTypes.APPLYLEADDATE:
			return { ...state, appliedDate: action.payload };
		case actionTypes.FETCHENGSREQ:
			return { ...state, fetchApiReq: action.payload };	
		case actionTypes.FETCHENGSREQROWWISE:
			return { ...state, fetchApiReqRowWise: action.payload };
		case actionTypes.SHOWMESSAGEPOPUPENGS:
			return { ...state, messageBox: action.messageBox };
		case actionTypes.SHOWMESSAGEBOXREFRESHPOPUPENGS:
			return { ...state, messageBoxRefresh: action.messageBoxRefresh };
		case actionTypes.FETCHENGSREQVALIDATE:
			return { ...state, fetchValidateReq: action.payload };	
		case actionTypes.SELLEADPAGETITLE:
			return { ...state, title: action.payload };
		case actionTypes.SELLEADORGS:
			return { ...state, organization: action.payload };
		case actionTypes.SELLEADCAMPAIGNS:
			return { ...state, campaign: action.payload };
		case actionTypes.SELLEADORDER:
			return { ...state, order: action.payload };
		case actionTypes.SELLEADPARTNER:
			return { ...state, leadPartner: action.payload };
		case actionTypes.RESETSELLEADCAMPAIGNS:
			return { ...state, campaign: action.payload };
		case actionTypes.SELLEADSITE:
			return { ...state, site: action.payload };
		case actionTypes.SELLEADSTATUS:
			return { ...state, campaignStatus: action.payload };
		case actionTypes.SELLEADENGSFILTERSTATUS:
			return { ...state, leadEngsFilterStatus: action.payload };
		case actionTypes.SELLEADENGSFILTERSSTATE:
			return { ...state, leadEngsFilterVerificationState: action.payload };
		case actionTypes.SELGLOBALLEADENGSSTATUS:
			return { ...state, globalLeadEngsStatus: action.payload };
		case actionTypes.SELGLOBALLEADENGSVERIFICATIONSTATE:
			return { ...state, globalLeadEngsVerificationState: action.payload };
		case actionTypes.SELLEADENGSVERIFICATIONSTATEROWWISE:
			return { ...state, verificationStateRowWise: action.payload };	
		case actionTypes.SELLEADENGSSTATUSROWWISE:
			return { ...state, leadEngsStatusRowWise: action.payload, leadEngsStatusRowWiseValue: action.value };
		case actionTypes.APPLYDATECALENDARSTATE:
			return { ...state, applyDateCalendarState: action.payload };
		case actionTypes.SELLEADENGSCHECKBOXSTATE:
			return { ...state, leadEngsCheckboxState: action.payload };	
		case actionTypes.SELLEADTYPE:
			return { ...state, assetType: action.payload };
		case actionTypes.SELLEADENGORGS:
			return { ...state, engagedOrganizations: action.payload };
		case actionTypes.FETCHDELIVERYSTATE:
      		return { ...state, clientDeliverdFilterStateOptions: action.payload.delivery_states };
		case actionTypes.SELENGAGEMENTDELIVERYSTATE:
      		return { ...state, clientDeliverdFilterState: action.payload };
		case actionTypes.SELLEADENGSPACINGFILTERVALUE:
			return { ...state, pacingFilter: action.payload };  
		case actionTypes.SELEESTOGGLE:
			return {
				...state,
				excludeEmailSupression: !state.excludeEmailSupression,
			};

		case actionTypes.SELLEADPARENTTOOGLE:
        	return { ...state, leadIsIncludeParent: !state.leadIsIncludeParent };
    	case actionTypes.SELLEADCHILDTOOGLE:
        	return { ...state, leadIsIncludeChild: !state.leadIsIncludeChild };
		// case actionTypes.SELOLTOGGLE:
		// 	return {
		// 		...state,
		// 		organizationLimits: !state.organizationLimits,
		// 	};
		case actionTypes.SELORFTOGGLE:
			return {
				...state,
				overrideLeadReportFilters: !state.overrideLeadReportFilters,
			};
		case actionTypes.SELEFTOGGLE:
			return {
				...state,
				overrideReportingAttributes: !state.overrideReportingAttributes,
			};
		case actionTypes.SELEIETOGGLE:
			return {
				...state,
				excludeInternalEvents: !state.excludeInternalEvents,
			};
		case actionTypes.SELEOETOGGLE:
			return {
				...state,
				excludeOrganizationEvents: !state.excludeOrganizationEvents,
			};
		case actionTypes.SELLEADENGSCHARTTOGGLE:
			return {
				...state,
				selLeadEngChart: !state.selLeadEngChart,
			};
		case actionTypes.SELECETOGGLE:
			return {
				...state,
				excludeCompetitorEvents: !state.excludeCompetitorEvents,
			};
		case actionTypes.SELEICTOGGLE:
			return {
				...state,
				excludeInternalCampaign: !state.excludeInternalCampaign,
			};
		case actionTypes.SELEQACTOGGLE:
			return {
				...state,
				excludeQACampaign: !state.excludeQACampaign,
			};
		case actionTypes.SELEFEETOGGLE:
			return {
				...state,
				excludeFreeEmailEvents: !state.excludeFreeEmailEvents,
			};
		case actionTypes.SELEGPTOGGLE:
			return {
				...state,
				excludeGatedPageviews: !state.excludeGatedPageviews,
			};
		case actionTypes.SELUTMPARAMENGSTOGGLE:
			return {
				...state,
				utmParams: !state.utmParams,
			};
		case actionTypes.SELATOGGLE:
			return {
				...state,
				account: !state.account,
			};
		case actionTypes.SELVTOGGLE:
			return {
				...state,
				vertical: !state.vertical,
			};
		case actionTypes.SELGEOTOGGLE:
			return {
				...state,
				geo: !state.geo,
			};
		case actionTypes.SELCQTOGGLE:
			return {
				...state,
				customQuestions: !state.customQuestions,
			};
		case actionTypes.SELFTOGGLE:
			return {
				...state,
				firmoGraphic: !state.firmoGraphic,
			};
		case actionTypes.SELOOTTOGGLE:
			return {
				...state,
				types: !state.types,
			};
		case actionTypes.SELSIZETOGGLE:
			return {
				...state,
				size: !state.size,
			};
		case actionTypes.SELRTOGGLE:
			return {
				...state,
				revenue: !state.revenue,
			};
		case actionTypes.SELPTOGGLE:
			return {
				...state,
				personas: !state.personas,
			};
		case actionTypes.SELSTOGGLE:
			return {
				...state,
				seniority: !state.seniority,
			};
		case actionTypes.SELJFTOGGLE:
			return {
				...state,
				jobFunctions: !state.jobFunctions,
			};
		case actionTypes.SELJTTOGGLE:
			return {
				...state,
				jobTitles: !state.jobTitles,
			};
		case actionTypes.RESETLEADORFTOGGLE:
			if (state.overrideLeadReportFilters) {
				return {
					...state,
					account: true,
					vertical: true,
					geo: true,
					customQuestions:true,
					firmoGraphic: true,
					types: true,
					size: true,
					revenue: true,
					personas: true,
					seniority: true,
					jobFunctions: true,
					jobTitles: true,
				};
			} else {
				return state;
			}
		case actionTypes.RESETLEADFTOGGLE:
			if (state.firmoGraphic) {
				return state;
			} else {
				return {
					...state,
					types: false,
					size: false,
					revenue: false,
				};
			}
		case actionTypes.RESETLEADPTOGGLE:
			if (state.personas) {
				return state;
			} else {
				return {
					...state,
					seniority: false,
					jobFunctions: false,
					jobTitles: false,
				};
			}
		case actionTypes.SELLEADENGURLSOURCE:
			return { ...state, selUrlSource: action.payload };
		case actionTypes.SELLEADENGURLMEDIUM:
			return { ...state, selUrlMedium: action.payload };
		case actionTypes.SELLEADENGURLCAMPAIGNS:
			return { ...state, selUrlCampaign: action.payload };
		case actionTypes.SELLEADENGURLTERM:
			return { ...state, selUrlTerm: action.payload };
		case actionTypes.SELLEADENGURLCONTENT:
			return { ...state, selUrlContent: action.payload };
		case actionTypes.SELENGSHEADERDROPDOWNOPTIONVALUE:
			return {...state, selHeaderDropdownOptionValue: action.payload}
		case actionTypes.SHOWMOREENGSCLICKED:
			return{...state, showMoreClicked: action.payload}
		case actionTypes.ENGAGEMENT_DETAIL_TABLE:
			return {
				...state,
				emailEngDetail: action.payload.email,
				cardPopupEngDetail: action.payload.class,
			};
		case actionTypes.SELLEADENGTRAFFIC:
			return { ...state, trafficType: action.payload };
		case actionTypes.FETCHINILEADFILTER:
			let data = action.payload;
			let dates = data.applied_date_filter
				? getStartEndDates(data.applied_date_filter)
				: state;
			return {
				...state,
				startDate:
					data.applied_date_filter === "Custom Date Range"
						? getDisplayDate(data.start_date)
						: dates.startDate,
				endDate:
					data.applied_date_filter === "Custom Date Range"
						? getDisplayDate(data.end_date)
						: dates.endDate,
				prevstartDate:
					data.applied_date_filter === "Custom Date Range"
						? getDisplayDate(data.start_date)
						: dates.startDate,
				prevendDate:
					data.applied_date_filter === "Custom Date Range"
						? getDisplayDate(data.end_date)
						: dates.endDate,
				site: data.site ? data.site : "all",
				appliedDate: data.applied_date_filter
					? data.applied_date_filter
					: state.appliedDate,
				leadPartner: data.lead_partner ? data.lead_partner : "",
				campaign: data.campaigns ? data.campaigns : [],
				organization: data.organizations ? data.organizations : [],
				campaignStatus: data.status ? data.status : "all",
				assetType: data.asset_type ? data.asset_type : "all",
				engagedOrganizations: data.engaged_organizations
					? data.engaged_organizations
					: [],
				title: data.page_title ? data.page_title : [],
				excludeCompetitorEvents: data.exclude_competitors,
				excludeEmailSupression: data.exclude_email_suppression,
				excludeInternalEvents: data.exclude_internal_events,
				excludeOrganizationEvents: data.exclude_organization_events,
				excludeFreeEmailEvents: data.exclude_free_email_registrations,
				overrideLeadReportFilters: data.override_lead_report_filters,
				excludeQACampaign: data.exclude_qa,
				excludeGatedPageviews: data.exclude_gated_pageviews,
				excludeInternalCampaign: data.exclude_internal,
				account: data.override_account_filters,
				vertical: data.override_vertical_filters,
				geo: data.override_geo_filters,
				customQuestions: data.override_custom_questions,
				firmoGraphic: data.override_firmographic_filters,
				types: data.override_organization_types_filters,
				size: data.override_organization_sizes_filters,
				revenue: data.override_estimate_revenues_filters,
				personas: data.override_personas_filters,
				seniority: data.override_seniority_filters,
				jobFunctions: data.override_job_functions_filters,
				jobTitles: data.override_job_titles_filters,
				selUrlSource: data.utm_source,
				selUrlMedium: data.utm_medium,
				selUrlCampaign: data.utm_campaign,
				selUrlTerm: data.utm_term,
				selUrlContent: data.utm_content,
				leadEngsFilterVerificationState: data.verification_state ? data.verification_state :  "All",
				leadEngsFilterStatus: data.verification_status ? data.verification_status : "All",
				clientDeliverdFilterState: data.deliverd_state ? data.deliverd_state : "",
				pacingFilter: data.pace ? data.pace : "",
				utmParams: data.utm_filters,
				order: data.orders ? data.orders : [],
				trafficType: data.traffic_filter ? data.traffic_filter : "all",
				domainFilter: data.domain_filter ? data.domain_filter : "all",
				setCSM: data.csm ? data.csm : [],
				regulationName: data.regulation_names ? data.regulation_names : [],
				purposeConsent: data.purpose_consent !== undefined ? (data.purpose_consent ? "yes" : "no") : "all",
				marketingConsent: data?.marketing_consent !== undefined ? (data.marketing_consent ? "yes" : "no") : "all",
				doubleOptin: data.double_optin !== undefined ? (data.double_optin ? "yes" : "no") : "all",
				dnsmsPersonalInfo: data.dnsmpi !== undefined ? (data.dnsmpi ? "yes" : "no") : "all",
				rtbForgotten: data.right_to_be_forgotten !== undefined ? (data.right_to_be_forgotten ? "yes" : "no") : "all",
				conditioningConsent: data.conditioning_consent !== undefined ? (data.conditioning_consent ? "yes" : "no") : "all",
				ageVerification: data.age_verification !== undefined ? (data.age_verification ? "yes" : "no") : "all",
			};

		case actionTypes.CLEARLEADFILTER:
			return {
				startDate: getDisplayDate(getLastDays(7)),
				endDate: getDisplayDate(getYesterdaysDate()),
				appliedDate: "7",
				prevendDate: getDisplayDate(getYesterdaysDate()),
				prevstartDate: getDisplayDate(getLastDays(7)),
				prevAppliedDate: "",
				assetType: "",
				organization: [],
				campaign: [],
				order: [],
				title: [],
				leadPartner: "",
				site: siteValue,
				campaignStatus: "active",
				engagedOrganizations: [],
				leadEngsFilterStatus: "All",
				leadEngsFilterVerificationState: "All",
				excludeEmailSupression: false,
				//organizationLimits: false,
				overrideLeadReportFilters: true,
				// overrideReportingAttributes: false,
				excludeCompetitorEvents: true,
				excludeInternalEvents: false,
				excludeOrganizationEvents: false,
				selLeadEngChart: false,
				excludeGatedPageviews: false,
				excludeFreeEmailEvents: false,
				excludeQACampaign: false,
				excludeInternalCampaign: false,
				account: true,
				vertical: true,
				geo: true,
				customQuestions: true,
				firmoGraphic: true,
				types: true,
				size: true,
				revenue: true,
				personas: true,
				seniority: true,
				jobFunctions: true,
				jobTitles: true,
				selUrlSource: "",
				selUrlMedium: "",
				selUrlCampaign: "",
				selUrlTerm: "",
				selUrlContent: "",
				clientDeliverdFilterState: "",
        		clientDeliverdFilterStateOptions: action.payload,
				pacingFilter: "all",
				utmParams: false,
				trafficType: "all",
				domainFilter: "all",
				setCSM: [],
				privacyParams: false,
				regulationName: [],
				purposeConsent: "all",
				marketingConsent: "all",
				doubleOptin: "all",
				dnsmsPersonalInfo: "all",
				rtbForgotten: "all",
				conditioningConsent: "all",
				ageVerification: "all",
			};
		case actionTypes.SELECTENGDOMAINFILTER:
			return { ...state, domainFilter: action.payload };
		default:
			return state;
	}
};

export const leadFetchDataReducer = (state = leadFetchData, action) => {
	switch (action.type) {
		case actionTypes.APPLYLEADFILTER:
			return {
				...state,
				leadEngSummary: {},
				leadEngSummaryCount: {},
				leadEngs: {},
				isFilApplied: true,
				isLoadingLeadEngSummary: true,
				isLoadingLeadEngs: true,
				summaryPageCount: 2,
				detailPageCount: 2,
				leadEngSummarycolumn: null,
				column: null,
				direction: null,
				leadEngSummarydirection: null,
				changedLogs:{},
				engagementValidationChart: [],
				isLoadingChart: true,
				leadEngsValidationBarChart: {},
				isLoadingStatusChart: true,
				isLoadingAcceptanceChart: true,
				leadEngsAcceptanceBarChart: {},
				resCampaignSummaryLeadEngsValue: null,
				resEngsDetailsValue: null,
				totalLeadCount: 0
			};
		case actionTypes.ENGSVALIDATIONCHART:
			return {...state,
				isLoadingChart: false,
				engagementValidationChart: action.payload};
		case actionTypes.ENGAGEMENT_DETAIL_CSV:
			return {
				...state,
				engDetailCsv: action.csv,
				isLoadingEngDetailCsv: false,
				downloadFileName: action.downloadFileName
			};
		case actionTypes.LEADENGSBARCHART:
			return{
					...state,
					isLoadingStatusChart: false,
					leadEngsValidationBarChart: action.payload
				}
		case actionTypes.LEADENGSACCEPTANCEBARCHART:
			return{
					...state,
					isLoadingAcceptanceChart: false,
					leadEngsAcceptanceBarChart: action.payload
				}
		case actionTypes.SELENGSSTATUSCHART:
			return {
				...state,
				engsStatusChart: !state.engsStatusChart,
			};
		case actionTypes.SELENGSACCEPTANCECHART:
			return {
				...state,
				engsAcceptanceChart: !state.engsAcceptanceChart,
			};
		case actionTypes.LEADENGSUMMARY:
			if (action.payload === null) {
				return {
					...state,
					isLoadingLeadEngSummary: false,
				};
			} else {
				action.payload.forEach((e) => {
					e.percent_delivered = parseFloat(e.percent_delivered);
				});
				return {
					...state,
					leadEngSummary: action.payload,
					isLoadingLeadEngSummary: false,
				};
			}
		case actionTypes.LEADENGSUMMARYCOUNT:
			if (action.payload === null) {
				return {
					...state,
				};
			} else {
				return {
					...state,
					leadEngSummaryCount: action.payload,
				};
			}
		case actionTypes.MORELEADENGSUMMARYLOADING:
			return {
				...state,
				isLoadingLeadEngSummary: true,
			};
		case actionTypes.MORELEADENGSUMMARY:
			if (action.payload === null) {
				return {
					...state,
					isLoadingLeadEngSummary: false,
					resCampaignSummaryLeadEngsValue: action.resCampaignSummaryLeadEngsValue
				};
			} else {
				return {
					...state,
					leadEngSummary: [
						...state.leadEngSummary,
						...action.payload,
						//...Object.values(action.payload),
					],
					isLoadingLeadEngSummary: false,
				};
			}
		case actionTypes.LEADENGS:
			if (action.payload === null) {
				return {
					...state,
					isLoadingLeadEngs: false,
					leadEngs: {},
					totalLeadCount: 0
				};
			} else {
				const response = action.payload.map((row)=>({
						...row, 
						uniqueId: _.uniqueId("ROW"),
						campaign_name: row.campaign_name === "" ? "N/A" : row.campaign_name,
						created_at:  row.created_at === "" ? "N/A" : row.created_at,
						lead_partner: row.lead_partner === "" ? "N/A" : row.lead_partner,
						source: row.source === "" ? "N/A" : row.source,
						utm_medium: row.utm_medium === "" ? "N/A" : row.utm_medium,
						utm_campaign: row.utm_campaign === "" ? "N/A" : row.utm_campaign,
						utm_term: row.utm_term === "" ? "N/A" : row.utm_term,
						utm_content: row.utm_content === "" ? "N/A" : row.utm_content,
						new_asset_type: row.new_asset_type === "" ? "N/A" : row.new_asset_type,
						asset_title: row.asset_title === "" ? "N/A" : row.asset_title,
						member_country: row.member_country === "" ? "N/A" : row.member_country,
						organization_sector: row.organization_sector === "" ? "N/A" : row.organization_sector,
						organization_industry_group: row.organization_industry_group === "" ? "N/A" : row.organization_industry_group,
						organization_industry: row.organization_industry === "" ? "N/A" : row.organization_industry,
						organization_sub_industry: row.organization_sub_industry === "" ? "N/A" : row.organization_sub_industry,
						estimated_revenue: row.estimated_revenue === "" ? "N/A" : row.estimated_revenue,
						employees: row.employees === "" ? "N/A" : row.employees,
						type: row.type === "" ? "N/A" : row.type,
						seniority: row.seniority === "" ? "N/A" : row.seniority,
						primary_role: row.primary_role === "" ? "N/A" : row.primary_role,
						member_title: row.member_title === "" ? "N/A" : row.member_title,
						phone_number: row.phone_number === "" ? "N/A" : row.phone_number,
						custom_question_responses: row.custom_question_responses?.Department === undefined ? "N/A" : row.custom_question_responses? "N/A" : row.custom_question_responses,
						ip_address: row.ip_address === "" ? "N/A" : row.ip_address,
						action: row.action === "" ? "N/A" : row.action
					}))
				return {
					...state,
					leadEngs: response,
					totalLeadCount: action.totalLeadCount,
					// {
					//   ..._.mapKeys(action.payload, "id"),
					// },
					isLoadingLeadEngs: false,
				};
			}
		case actionTypes.MORELEADENGSLOADING:
			return {
				...state,
				isLoadingLeadEngs: true,
			};
		case actionTypes.MORELEADENGSDATA:
			if (action.payload === null) {
				return {
					...state,
					isLoadingLeadEngs: false,
					resEngsDetailsValue: action.resEngsDetailsValue
				};
			} else {
				const response = action.payload.map((row)=>({
					...row, 
					uniqueId: _.uniqueId("ROW"),
					campaign_name: row.campaign_name === "" ? "N/A" : row.campaign_name,
					created_at:  row.created_at === "" ? "N/A" : row.created_at,
					lead_partner: row.lead_partner === "" ? "N/A" : row.lead_partner,
					source: row.source === "" ? "N/A" : row.source,
					utm_medium: row.utm_medium === "" ? "N/A" : row.utm_medium,
					utm_campaign: row.utm_campaign === "" ? "N/A" : row.utm_campaign,
					utm_term: row.utm_term === "" ? "N/A" : row.utm_term,
					utm_content: row.utm_content === "" ? "N/A" : row.utm_content,
					new_asset_type: row.new_asset_type === "" ? "N/A" : row.new_asset_type,
					asset_title: row.asset_title === "" ? "N/A" : row.asset_title,
					member_country: row.member_country === "" ? "N/A" : row.member_country,
					organization_sector: row.organization_sector === "" ? "N/A" : row.organization_sector,
					organization_industry_group: row.organization_industry_group === "" ? "N/A" : row.organization_industry_group,
					organization_industry: row.organization_industry === "" ? "N/A" : row.organization_industry,
					organization_sub_industry: row.organization_sub_industry === "" ? "N/A" : row.organization_sub_industry,
					estimated_revenue: row.estimated_revenue === "" ? "N/A" : row.estimated_revenue,
					employees: row.employees === "" ? "N/A" : row.employees,
					type: row.type === "" ? "N/A" : row.type,
					seniority: row.seniority === "" ? "N/A" : row.seniority,
					primary_role: row.primary_role === "" ? "N/A" : row.primary_role,
					member_title: row.member_title === "" ? "N/A" : row.member_title,
					phone_number: row.phone_number === "" ? "N/A" : row.phone_number,
					custom_question_responses: row.custom_question_responses?.Department === undefined ? "N/A" : row.custom_question_responses? "N/A" : row.custom_question_responses,
					ip_address: row.ip_address === "" ? "N/A" : row.ip_address,
					action: row.action === "" ? "N/A" : row.action
				}))
				return {
					...state,
					leadEngs: [...state.leadEngs, ...response],
					isLoadingLeadEngs: false,
				};
			}
		// return {
		//   ...state,
		//   leadEngs: {
		//     ...state.leadEngs,
		//     ..._.mapKeys(action.payload, "id"),
		//   },
		//   isLoadingLeadEngs: false,
		// };
		case actionTypes.LEADCSVLOADING:
			return {
				...state,
				isLoadingLeadEngsCsv: true,
			};
		case actionTypes.LEADENGSCSV:
			return {
				...state,
				leadEngsCsv: action.csv,
				isLoadingLeadEngsCsv: false,
				downloadFileName: action.downloadFileName
			};
		case actionTypes.SORTLEADENGSUMMARY:
			const directionValueEngsSummary = state.leadEngSummarydirection === "ascending" ? "desc" : "asc";
			const leadEngSummary= _.orderBy(state.leadEngSummary, [(item) => typeof(item[action.column]) === "string" ? item[action.column].toLowerCase() : item[action.column]], [directionValueEngsSummary])
			const leadEngSummarydirection= state.leadEngSummarydirection === "ascending" ? "descending" : "ascending"
			if (state.leadEngSummarycolumn === action.column) {
				return {
					...state,
					leadEngSummary,
					leadEngSummarydirection
				};
			}
			return {
				...state,
				leadEngSummarycolumn: action.column,
				leadEngSummary,
				leadEngSummarydirection
			};
		case actionTypes.SORTLEADENGS:
			const directionValueEngsDetail = state.direction === "ascending" ? "desc" : "asc";
			const leadEngs= _.orderBy(state.leadEngs, [(item) => typeof(item[action.column]) === "string" ? item[action.column].toLowerCase() : item[action.column]], [directionValueEngsDetail])
			const direction= state.direction === "ascending" ? "descending" : "ascending"	
				if (state.column === action.column) {
					if(state.column === "name"){
					return {
						...state,
						leadEngs: state.direction === "ascending" ?
						_.orderBy(state.leadEngs, [data => data.campaign_data[0].order.name ? (data.campaign_data[0].order.name).toUpperCase() : data.campaign_data[0].order.name], ['desc'])
						:
						_.orderBy(state.leadEngs, [data => data.campaign_data[0].order.name ? (data.campaign_data[0].order.name).toUpperCase() : data.campaign_data[0].order.name], ['asc']),
						direction: state.direction === "ascending" ? "descending" : "ascending",
					} 
					} else if(action.column === "date"){
						return {
							...state,
							column: action.column,
							leadEngs: state.direction === "ascending" ?
							  _.orderBy(state.leadEngs, [data => getDateEng(data.created_at).toLowerCase()], ['desc'])
							  :
							  _.orderBy(state.leadEngs, [data => getDateEng(data.created_at).toLowerCase()], ['asc']),
							direction: state.direction === "ascending" ? "descending" : "ascending",
						  };
					} else if(action.column === "time"){
						return {
							...state,
							column: action.column,
							leadEngs: state.direction === "ascending" ?
							  _.orderBy(state.leadEngs, [data => getTimeEng(data.created_at).toLowerCase()], ['desc'])
							  :
							  _.orderBy(state.leadEngs, [data => getTimeEng(data.created_at).toLowerCase()], ['asc']),
							direction: state.direction === "ascending" ? "descending" : "ascending",
						  };
					}
					else {
					return {
						...state,
						leadEngs,
						direction,
					};
					}
				}
			return {
				...state,
				column: action.column,
				leadEngs,
				direction,
			};
		case actionTypes.SETLEADENGSUMMARYPGCOUNT:
			return { ...state, summaryPageCount: state.summaryPageCount + 1 };
		case actionTypes.SETLEADENGDETAILPGCOUNT:
			return { ...state, detailPageCount: state.detailPageCount + 1 };
		case actionTypes.SETENGSDETAILPGAFTERREFRESHCOUNT:
			return { ...state, detailPageCount: action.payload};
		case actionTypes.CLEARLEADSORT:
			return {
				...state,
				leadEngSummarycolumn: null,
				column: null,
				direction: null,
				leadEngSummarydirection: null,
			};
		case actionTypes.LEADCHANGEDLOGS:
			return{
				...state,
				changedLogs: action.payload
			}
			case actionTypes.LEADEXCELSUMMARY:
				return{...state,
					emailLeadSummary: action.payload.email,
					cardPopupLeadSummary: action.payload.class,	
				}
		case actionTypes.LEADDETAILEXCEL:
				if (_.includes(["/reports/engagements/summary", "/leadengagements/search"], action.payload)) {
					return {
						...state,
						postEmailUpdateLeadSummary: action.email,
					};
				}
				/* falls through */
			case actionTypes.SETLEADSUMMARYPOPUPCLOSE:
				return {
						...state,
						cardPopupLeadSummary: action.payload,
					};
			case actionTypes.SETLEADSUMMARYPOPUPPOSTEMAIL:
				return{
						...state,
							postEmailUpdateLeadSummary: action.payload	
					}
			case actionTypes.GETENGSDYNAMICTABLEHEADER:
				return {
					...state, getDynamicHeaderData: action.payload
				}
			case actionTypes.SENDENGSDYANMICTABLEHEADER:
				return {
					...state, dynamicHeaderArray: action.payload
				}
		case actionTypes.LEADENGSDETAILEXCEL:
				if (action.payload === "/lead_engagement_summary/search") {
					return {
					...state,
					postEmailUpdateLeadSummary: action.email,
					}
				} 
				if (action.payload === "/lead_engagement_client/search") {
					return {
					...state,
					postEmailUpdateLeadDetail: action.email
					};
				}
				/* falls through */
		case actionTypes.SETENGSLEADDETAILPOPUPPOSTEMAIL:
			return {
				...state,
				postEmailUpdateLeadDetail: action.email,
			};
		case actionTypes.ENGSMAILDELIVERY:
			return {
				...state,
				mailDelivery: action.payload,
			};
		case actionTypes.SETENGSMAILDELIVERYPOPUP:
			return {
				...state,
				cardPopupMailDelivery: action.payload.class,
			};
		default:
			return state;
	}
};

export const urlLeadEngSourceReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHLEADENGURLSOURCE:
			return action.payload;
		case actionTypes.CLEARSEARCHLEADENGURLSOURCE:
			return (state = []);
		case actionTypes.SELINILEADENGUTMSOURCE:
			return action.utm_source;
		default:
			return state;
	}
};

export const urlLeadEngMediumReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHLEADENGURLMEDIUM:
			return action.payload;
		case actionTypes.CLEARSEARCHLEADENGURLMEDIUM:
			return (state = []);
		case actionTypes.SELINILEADENGUTMMEDIUM:
			return action.utm_medium;
		default:
			return state;
	}
};

export const urlLeadEngCampaignsReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHLEADENGURLCAMPAIGNS:
			return action.payload;
		case actionTypes.CLEARSEARCHLEADENGURLCAMPAIGNS:
			return (state = []);
		case actionTypes.SELINILEADENGUTMCAMPAIGN:
			return action.utm_campaign;
		default:
			return state;
	}
};

export const urlLeadEngTermReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHLEADENGURLTERM:
			return action.payload;
		case actionTypes.CLEARSEARCHLEADENGURLTERM:
			return (state = []);
		case actionTypes.SELINILEADENGUTMTERM:
			return action.utm_term;
		default:
			return state;
	}
};

export const urlLeadEngContentReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCHLEADENGURLCONTENT:
			return action.payload;
		case actionTypes.CLEARSEARCHLEADENGURLCONTENT:
			return (state = []);
		case actionTypes.SELINILEADENGUTMCONTENT:
			return action.utm_content;
		default:
			return state;
	}
};

export const engAuthorReducer = createCommonReducer(
	selectedArray.csmArray,
	{
		FETCH: actionTypes.FETCH_ENG_CSM,
		ADD: actionTypes.ADD_ENG_CSM, 
		SELECTINITIAL: actionTypes.SELECT_ENG_CSM,
		CLEAR: actionTypes.CLEAR_ENG_CSM,
	},
	'id'
);

export const reguationManagerEngReducer = createCommonReducer(
	selectedArray.regulationData,
	{
		FETCH: actionTypes.FETCH_ENG_REGULATION,
		ADD: actionTypes.ADD_ENG_REGULATION,
		CLEARSEARCH: actionTypes.CLEAR_ENG_REGULATION,
		SELECTINITIAL: actionTypes.SEL_ENG_REGULATION,
		RESET: actionTypes.RESET_ENG_REGULATION,
	},
  'uuid'
);