import React from "react";
import { Table } from "semantic-ui-react";
import { getDisplayDateTime } from "../../utilities/common";
import _ from "lodash";

const RequesterDetail = (props) => {
   const createRow = (data) => {
      if (data.length > 0) {
         let tableRow = [];
         data.map((val, index) => {
            let tableCell = [];
            tableCell.push(<Table.Cell key={index + "_name"} title={val?.users?.name} >{val?.users?.name}</Table.Cell>);
            tableCell.push(<Table.Cell key={index + "_email"} title={val.email} >{val.email}</Table.Cell>);
            tableCell.push(<Table.Cell key={index + "_created"} title={getDisplayDateTime(val.created_at)} >{getDisplayDateTime(val.created_at)}</Table.Cell>);

            return tableRow.push(<Table.Row key={index} children={tableCell} />);
         });

         return tableRow;
      }
   }

   return (
      <div className="sortable-table-component">
         <Table sortable fixed singleLine>
            <Table.Header>
               <Table.Row>
                  <Table.HeaderCell>NAME</Table.HeaderCell>
                  <Table.HeaderCell>EMAIL ID</Table.HeaderCell>
                  <Table.HeaderCell style={{ width: '15em' }}>CREATED AT</Table.HeaderCell>
               </Table.Row>
            </Table.Header>
            {
               !_.isEmpty(props.data) && 
               <Table.Body>{createRow(props?.data?.requesters)}</Table.Body>
            }
         </Table>
      </div>
   );
};

export default RequesterDetail;