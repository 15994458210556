import React from "react";

import { HeaderData } from "./HeadersName";
import GenericTableForExpandable from "../common/GenericTableForExpandable";
import { Loader, Message, Table } from "semantic-ui-react";
import { columnConfigs } from "../ads/common/ColumnConfig";
import _ from "lodash";

const Main = (props) => {

    return (
        <div>
            <div className="sortable-table-component">
                <Table sortable fixed singleLine>
                    <GenericTableForExpandable
                        data={props.filterData.orderSummaryData}
                        headerOrder={HeaderData}
                        columnConfigs={columnConfigs}
                    />
                </Table>
            </div>
            {
                props.filterData.isLoadingOrder &&
                <div className="show-loader">
                    <Loader active inline="centered" />
                </div>
            }
            {
                props.filterData.isFilApplied && !props.filterData.isLoadingOrder && _.isEmpty(props.filterData.orderSummaryData) &&
                <Message>No results found.</Message>
            }
        </div>
    )    
}

export default Main;