import React from "react";

import DateAndFilter from "./DateAndFilter";
import SummaryIPA from "./SummaryIPA";
import * as configName from "../config/const";
import { downloadCSVData, toTitleCase } from "../../utilities/common";
import { Message } from "semantic-ui-react";
import SummarySenority from "./SummarySenority";
import SummarySector from "./SummarySector";
import CPDetails from "./CPDetails";
import moment from "moment";
import { CONTENT_PERFORMANCE_DETAILS, CONTENT_PERFORMANCE_SUMMARY_IPA, CONTENT_PERFORMANCE_SUMMARY_SECTOR, CONTENT_PERFORMANCE_SUMMARY_SENORITY } from "../config/rolebasedReportNames";

const Main = (props) => {
    const CurrentDate = moment(new Date()).format("MM/DD/YYYY hh:mm:ss");

    const dataToCSVConversion = (data, fileName) => {
        if (!Array.isArray(data) || data.length === 0) {
            return [];
        }
    
        const headers = Object.keys(data[0]).map(toTitleCase);
        const rows = data?.map(row => Object.values(row));
        const csvContent = [
            headers.join(','),
            ...rows.map(row => row.join(',')) 
        ].join('\n'); 
        downloadCSVData(csvContent, fileName);
    };
    
    const onDownloadClick = (data, fileName) => {
        if (!data || data.length === 0) {
            return;
        }
        dataToCSVConversion(data, fileName);
    };
    


    return (
        <div>
            <div className="page-name-comp">
                <p className="page-name">{props.label}</p>
                <p>
                    {props.reportDescription}
                </p>
            </div>
            <DateAndFilter {...props} />
             
            { 
                 props.roleReports?.some(
                              (report) =>
                         [CONTENT_PERFORMANCE_SUMMARY_IPA].includes(report.element) &&
                                            [configName.VIEW, configName.DOWNLOAD_CSV].includes(report.permission)
                ) &&
                <div className="table_gap">
                    <div className="table-heading-block">
                        <div className="table-name">
                            <p>{`${props.label} Summary: IPA`}</p>
                        </div>
                        {
                            props.filterData.isFilApplied && (props.roleReports?.some((report) => [CONTENT_PERFORMANCE_SUMMARY_IPA].includes(report.element) && report.permission === configName.DOWNLOAD_CSV)) &&
                            <div className="class-relative">
                                <button className="download-button" onClick={() => onDownloadClick(props.filterData.contentPerformanceSummaryIPAData, `${CurrentDate} Unfiltered Content Performance Summary IPA.csv`)}>{configName.DOWNLOAD_CSV}</button>
                            </div>
                        }

                        </div>
                        { 
                            (props.roleReports?.some((report) => [CONTENT_PERFORMANCE_SUMMARY_IPA].includes(report.element) && report.permission === configName.VIEW)) &&
                            <SummaryIPA
                                reportActionType={props.reportActionType}
                                reportName={props.reportName}
                                filterData={props.filterData}
                            />
                        }
                  
                </div>
            }
              
            { 
                props.roleReports?.some(
                    (report) =>
                        [CONTENT_PERFORMANCE_SUMMARY_SENORITY].includes(report.element) &&
                        [configName.VIEW, configName.DOWNLOAD_CSV].includes(report.permission)
                ) &&
                <div className="table_gap">
                    <div className="table-heading-block">
                        <div className="table-name">
                            <p>{`${props.label} Summary: Senority`}</p>
                        </div>
                        {
                            props.filterData.isFilApplied && (props.roleReports?.some((report) => [CONTENT_PERFORMANCE_SUMMARY_SENORITY].includes(report.element) && report.permission === configName.DOWNLOAD_CSV)) &&
                            <div className="class-relative">
                                <button className="download-button" onClick={() => onDownloadClick(props.filterData.contentPerformanceSummarySenorityData, `${CurrentDate} Unfiltered Content Performance Summary Senority.csv`)}>{configName.DOWNLOAD_CSV}</button>
                            </div>
                        }
                        </div>
                        {
                            (props.roleReports?.some((report) => [CONTENT_PERFORMANCE_SUMMARY_SENORITY].includes(report.element) && report.permission === configName.VIEW)) &&
                            <SummarySenority
                                reportActionType={props.reportActionType}
                                reportName={props.reportName}
                                filterData={props.filterData}
                            />
                        }
                </div>
            }
           
            { 
                props.roleReports?.some(
                    (report) =>
                        [CONTENT_PERFORMANCE_SUMMARY_SECTOR].includes(report.element) &&
                        [configName.VIEW, configName.DOWNLOAD_CSV].includes(report.permission)
                ) &&
                <div className="table_gap">
                    <div className="table-heading-block">
                        <div className="table-name">
                            <p>{`${props.label} Summary: Sector`}</p>
                        </div>
                        {
                            props.filterData.isFilApplied && (props.roleReports?.some((report) => [CONTENT_PERFORMANCE_SUMMARY_SECTOR].includes(report.element) && report.permission === configName.DOWNLOAD_CSV)) &&
                            <div className="class-relative">
                                <button className="download-button" onClick={() => onDownloadClick(props.filterData.contentPerformanceSummarySectorData, `${CurrentDate} Unfiltered Content Performance Summary Sector.csv`)}>{configName.DOWNLOAD_CSV}</button>
                            </div>
                        }
                        </div>
                        {
                            (props.roleReports?.some((report) => [CONTENT_PERFORMANCE_SUMMARY_SECTOR].includes(report.element) && report.permission === configName.VIEW)) &&
                            <SummarySector
                                reportActionType={props.reportActionType}
                                reportName={props.reportName}
                                filterData={props.filterData}
                            />
                        }
                </div>

            }


            {props.roleReports?.some(
                (report) =>
                    [CONTENT_PERFORMANCE_DETAILS].includes(report.element) &&
                    [configName.VIEW, configName.DOWNLOAD_CSV].includes(report.permission)
            ) &&
                <div>
                    <div className="table-heading-block">
                        <div className="table-name">
                            <p>{`${props.label} Details`}</p>
                        </div>
                    </div>
                    { 
                        (props.roleReports?.some((report) => [CONTENT_PERFORMANCE_DETAILS].includes(report.element) && report.permission === configName.VIEW)) &&
                        <CPDetails
                            reportActionType={props.reportActionType}
                            reportName={props.reportName}
                            filterData={props.filterData}
                        />
                    }
                
                </div>
            }
           
           
     
            {!props.filterData.isFilApplied && <Message>Please proceed with applying filter.</Message>}
        </div>
    );
};

export default Main;