import React, { useState } from "react";
import { connect } from "react-redux";
import { Table } from "semantic-ui-react";
import { numFormat } from "../../../utilities/common";
import MultiLinks from "../../hyperlinks/multiLinks";
import Widgets from "./widget";
import _ from "lodash";

const Sidebar = (props) => {
  const [expanded, setExpanded] = useState(false);
  let conditionChkboxAppFilter = props.filterData.selCustomRangeCheckBox && props.isFilApplied && props.filterData.appliedDate ==="Custom Date Range";

  return (
    <>
      <Table.Row className={expanded && "topic-camp-selected"}
        onDoubleClick={() => {
          if (!expanded) {
            setExpanded(true);
          } else if (expanded) {
            setExpanded(false);
          }
        }}
      >
        <Table.Cell></Table.Cell>
        {
          <Table.Cell>
            {!_.isNull(props.data.widgets) ? (
              !expanded ? (     
              <i
                className="material-icons"
                onClick={() => {
                  setExpanded(true);
                }}
              >
                add
              </i>
              ) : (
                <i
                className="material-icons"
                onClick={() => {
                  setExpanded(false);
                }}
              >
                remove
              </i>
              )
            ) : (
              ""
            )}
          </Table.Cell>
        }
        <Table.Cell title={props.data.name}>
          {<MultiLinks type="sidebars" object={[props.data]}></MultiLinks>}
        </Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
        {/* <Table.Cell></Table.Cell> */}
        <Table.Cell></Table.Cell>
          {(conditionChkboxAppFilter)  ?  
          <>
               <Table.Cell textAlign="right" title={numFormat(props.data.impressions)}> {numFormat(props.data.impressions)} </Table.Cell>
              <Table.Cell textAlign="right" title={numFormat(props.data.prev_impressions)}> {numFormat(props.data.prev_impressions)} </Table.Cell> 
          </>
          : 
          <>
          	<Table.Cell textAlign="right" title={numFormat(props.data.impressions)}>{numFormat(props.data.impressions)}</Table.Cell>
          </>
             
          }  
        {/* <Table.Cell textAlign="right">{numFormat(props.data.impressions)}</Table.Cell> */}
        {(conditionChkboxAppFilter)  ?  
          <>
               <Table.Cell textAlign="right" title={numFormat(props.data.unique_impressions)}> {numFormat(props.data.unique_impressions)} </Table.Cell>
              <Table.Cell textAlign="right" title={numFormat(props.data.prev_unique_impressions)}> {numFormat(props.data.prev_unique_impressions)} </Table.Cell> 
          </>
          : 
          <>
          	<Table.Cell textAlign="right" title={numFormat(props.data.unique_impressions)}>{numFormat(props.data.unique_impressions)}</Table.Cell>
          </>
             
          }
        {/* <Table.Cell></Table.Cell> */}
        {(conditionChkboxAppFilter)  ?  
          <>
               <Table.Cell textAlign="right" title={numFormat(props.data.clicks)}> {numFormat(props.data.clicks)} </Table.Cell>
              <Table.Cell textAlign="right" title={numFormat(props.data.prev_clicks)}> {numFormat(props.data.prev_clicks)} </Table.Cell> 
          </>
          : 
          <>
          	<Table.Cell textAlign="right" title={numFormat(props.data.clicks)}>{numFormat(props.data.clicks)}</Table.Cell>
          </>
             
          }
         {(conditionChkboxAppFilter)  ?  
          <>
               <Table.Cell textAlign="right" title={numFormat(props.data.unique_clicks)}> {numFormat(props.data.unique_clicks)} </Table.Cell>
              <Table.Cell textAlign="right" title={numFormat(props.data.prev_unique_clicks)}> {numFormat(props.data.prev_unique_clicks)} </Table.Cell> 
          </>
          : 
          <>
          	<Table.Cell textAlign="right" title={numFormat(props.data.unique_clicks)}>{numFormat(props.data.unique_clicks)}</Table.Cell>
          </>
             
          }
        <Table.Cell textAlign="right" title={props.data.ctr}>{props.data.ctr}</Table.Cell>
      </Table.Row>
      {!_.isEmpty(props.data.widgets) && props.data.widgets.map(
        (widgetsElements, index) => expanded && <Widgets data={widgetsElements} count={index}/>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
	return {
    isFilApplied: state.topicFetchData.isFilApplied,
		filterData: state.topicFilterData,
	};
};

export default connect(mapStateToProps)(Sidebar); 
