import { 
    MEMBER_SENIORITY,
    ORGANIZATION_TYPE,
    DATE,
    TIME,
    ORGANIZATION,
    SECTOR,
    INDUSTRY_GROUP,
    INDUSTRY,
    SUB_INDUSTRY,
    COUNTRY,
    JOB_FUNCTION,
    AUTHOR,
    PRIMARY_TOPIC,
    TOPIC_TAGS,
    ORGANIZATION_TAGS,
    PLACEMENT_TAGS,
    TRAFFIC_SOURCE,
    UTM_SOURCE,
    UTM_MEDIUM,
    UTM_CAMPAIGN,
    UTM_TERM,
    UTM_CONTENT,
    ORGANIZATION_REVENUE,
    ORGANIZATION_SIZE,
    IP_ADDRESS,
    JOB_TITLE,
    ACTION,
    SESSION_ID,
    GROUP_ID,
    ASSET_TYPE,
    PAGE_TITLE,
    WP_ID,
    FIRST_NAME,
    LAST_NAME,
    CITY,
    EMAIL_ADDRESS,
    STATE_OR_PROVINCE,
    POSTAL_CODE_OR_ZIP,
    TIMEZONE
} from "../../config/TableHeaderNames";

const EDDetailTableData=[
    { headerName: DATE, sorted: "created_at", name: "Date" },
    { headerName: TIME, sorted: "time", name: "Time" },
    { headerName: SESSION_ID, sorted: "session_uuid", className: "custom-column-large", name: "Session ID" },
    { headerName: GROUP_ID, sorted: "group_uuid", className: "custom-column-large", name: "Group ID" },
    { headerName: ACTION, sorted: "action", name: "Action" },
    { headerName: IP_ADDRESS, sorted: "ip_address", className: "custom-column-large", name: "IP Address" },
    { headerName: ASSET_TYPE, sorted: "asset_type", name: "Asset Type" },
    { headerName: PAGE_TITLE, sorted: "page_title", className: "custom-column-xlarge", name: "Page" },
    { headerName: AUTHOR, sorted: "author", className: "custom-column-author", name: "Author" },
    { headerName: WP_ID, sorted: "wp_page_id", className: "custom-column-large", name: "WP ID" },
    { headerName: PRIMARY_TOPIC, sorted: "primary_topic", className: "custom-column-large", name: "Primary Topic" },
    { headerName: TOPIC_TAGS, sorted: "str_topic_tags", className: "custom-column-large", name: "Topic Tags" },
    { headerName: ORGANIZATION_TAGS, sorted: "str_organization_tags", className: "custom-column-large", name: "Organization Tags" },
    { headerName: PLACEMENT_TAGS, sorted: "str_placement_tags", className: "custom-column-large", name: "Placement Tags" },
    { headerName: ORGANIZATION, sorted: "organization_name", name: "Organization" , title: "Organization" },
    { headerName: ORGANIZATION_TYPE, className:"", sorted: "type",  name: "Organization Type" },
    { headerName: ORGANIZATION_REVENUE, sorted: "estimated_revenue", name: "Organization Revenue" },
    { headerName: ORGANIZATION_SIZE, sorted: "size", className: "text_right_align", name: "Organization Size" },
    { headerName: SECTOR, sorted: "sector", name: "Sector" },
    { headerName: INDUSTRY_GROUP, sorted: "industry_group", name: "Industry Group" },
    { headerName: INDUSTRY, sorted: "industry", name: "Industry" },
    { headerName: SUB_INDUSTRY, sorted: "sub_industry", name: "Sub Industry" },
    { headerName: FIRST_NAME, sorted: "first_name", name: "First Name" },
    { headerName: LAST_NAME, sorted: "last_name", name: "Last Name" },
    { headerName: MEMBER_SENIORITY, sorted: "seniority", name: "Member Seniority" },
    { headerName: JOB_FUNCTION, sorted: "job_function", name: "Job Function" },
    { headerName: JOB_TITLE, sorted: "job_title", name: "Job Title" },
    { headerName: EMAIL_ADDRESS, sorted: "email", name: "Job Title" },
    { headerName: CITY, sorted: "city", name: "City" },
    { headerName: STATE_OR_PROVINCE, sorted: "state", name: "State/Province" },
    { headerName: COUNTRY, sorted: "member_country", name: "Country" },
    { headerName: POSTAL_CODE_OR_ZIP, sorted: "postal_code", name: "Postal Code/Zip" },
    { headerName: TIMEZONE, sorted: "member_timezone", name: "Timezone" },
    { headerName: TRAFFIC_SOURCE, sorted: "traffic_source", name: "Traffic Source" },
    { headerName: UTM_SOURCE, sorted: "source", name: "UTM Source" },
    { headerName: UTM_MEDIUM, sorted: "utm_medium", name: "UTM Medium" },
    { headerName: UTM_CAMPAIGN, sorted: "utm_campaign", name: "UTM Campaign" },
    { headerName: UTM_TERM, sorted: "utm_term", name: "UTM Term" },
    { headerName: UTM_CONTENT, sorted: "utm_content", name: "UTM Content" },
]
export default EDDetailTableData;