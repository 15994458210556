import { getDisplayDate, getLastDays, getStartEndDates, getYesterdaysDate } from "../utilities/common";
import * as actionTypes from "../actions/actionTypes";
import genericFilterReducer from "./genericFilterReducer";
import { siteValue } from "../constants";
import _ from "lodash";
import { transformContentPerformanceData, transformNewsletterData, transformOrderSummaryData } from "./TransformData";
import { transformLocationsAndRevenue } from "./TransformData";

const pageviewSearchFilters = {
    isPagePerameters: false,
    appliedDateFilter: "2",
    wp_startDate: getDisplayDate(getLastDays(0)),
    wp_endDate: getDisplayDate(getYesterdaysDate()),
    isPageParameter: false,
    selPageTitle: "",
    selUrlSource: "",
    selUrlMedium: "",
    selUrlCampaign: "",
    selUrlContent: "",
    selUrlTerm: "",
    selPrimaryTopic: [],
    selTopicTagsIN: [],
    selTopicTagsEX: [],
    selPlacementTagsIN: [],
    selPlacementTagsEX: [],
    selOrganiztionTagIN: [],
    selOrganiztionTagEX: [],
    selTrafficSource: "",
    selTrafficType: "",
    selPageviewType: "",
    selDayOfWeeks: [],
    selRange: [],
    selTimeOfDay: "",
};

const searchFilters = {
    startDate: getDisplayDate(getLastDays(0)),
    endDate: getDisplayDate(getYesterdaysDate()),
    appliedDate: "2",
    previousStartDate: getDisplayDate(getLastDays(7)),
    previousEndDate: getDisplayDate(getYesterdaysDate()),
    prevAppliedDate: "",
    organization: [],
    campaign: [],
    order: [],
    title: '',
    organizationSingleSelect: "",
    selCustomRangeCheckBox: false,
    site: siteValue,
    selTagsIN: [],
    selTagsEX: [],
    campaignStatus: "active",
    deliverdFilterState: "",
    pacingFilter: "",
    adOrderType: "all",
    adorderStatus: "",
    adorder: [],
    adUnitSelected: [],
    trafficType: "",
    selCountry: [],
    engagedOrganizations: [],
    selEnagedMember: [],
    exAnonymousMember: false,
    exInternalEvents: false,
    exAnonymousOrgs: false,
    exUTMParameters: false,
    exGatedPageviews: false,
    firmographicToggle: false,
    selSector: [],
    selIndustryGroup: [],
    selIndustry: [],
    selSubIndustry: [],
    selOrgType: [],
    selOrgSize: [],
    selOrgRevenue: [],
    personaToggle: false,
    selSenioritiesIN: [],
    selSenioritiesEX: [],
    selJobFunctionsIN: [],
    selJobFunctionsEX: [],
    selJobTitlesIN: "",
    selJobTitlesEX: "",
    excludeQACampaign: false,
    excludeInternalCampaign: false,
    orderStatus: "all",
    orderSingleStatus: "",
    requestedUser: "",
    newsletterType: "",
    newsletterStatus: "",
    newslettersAuthor: "",
    competitorFiltering: false,
    excludeSdxCentral: false,
    excludeInternalEvents: false,
    ...pageviewSearchFilters,
    setCSM: [],
    selAuthor: "",
    isExcludeInternalEvents: true,
    isExcludeCompanyEvents: true,
    accountActivityTrend: "all",
    accountActivityLevel: "all",
    companyData: [],
    accountInterestedByKeywords: [],
    ParentCompanyArray: [],
    showCompetitors: false,
    selectNewsletterAction: "all",
    segmentsArray:[],
    excludeAnonymousUsers: true
}

const tableInitialState = {
    deliverdFilterStateOptions: [],
    newslettersOptions: {},
    dayOfWeekOptions: {},
    // campaign table data
    isFilApplied: false,
    campaignSummaryData: {},
    isLoadingCampaign: false,
    campaignPageCount: 1,
    isCampaignShowMore: false,

    // campaign detail table data
    campaignImpressionDetailData: {},
    isLoadingImpression: false,
    impressionPageCount: 1,
    isImpressionShowMore: false,
    column_selected: null,
    column_direction: null,

    // detail table data
    isSelectDetailToggle: false,
    adDetailsData: {},
    isLoadingDetail: false,
    detailsPageCount: 1,
    isDetailShowMore: false,
    detailTableToggleValue: false,

    // dynamic header data
    dynamicHeaderArray: [],
    getDynamicHeaderData: [],
    getDynamicHeaderData_2: [],

    orderSummaryData: {},
    isLoadingOrder: false,
    orderPageCount: 1,
    isOrderShowMore: false,

    newsletterSummaryData: {},
    isLoadingNewsletter: false,
    newsletterPageCount: 1,
    isNewsletterShowMore: false,

    newsletterDetailsData: {},
    isLoadingNewsletterDetails: false,
    newsletterDetailsPageCount: 1,
    isNewsletterDetailsShowMore: false,

    //pageview table data
    contentPerformanceSummaryIPAData: {},
    isLoadingIPA: false,
    contentPerformanceSummarySenorityData: {},
    isLoadingSenority: false,
    contentPerformanceSummarySectorData: {},
    isLoadingSector: false,
    contentPerformanceDetailsData: {},
    isLoadingCPDetails: false,
    cpDetailsPageCount: 1,
    cpDetailShowMore: false,

    //author table data
    authorSummaryData: {},
    isLoadingAuthor: false,
    authorPageCount: 1,
    isAuthorShowMore: false,

    //di table data
    diEarnedMediaData: {},
    isLoadingDIEarnedMedia: false,
    diBasicData: {},
    isLoadingDIBasic: false,
    diProData: {},
    isLoadingDIPro: false,
};

const chartInitialState = {
    chartStatus: {
        campaignLoaded: false,
        detailLoaded: false,
        chartApiCalled: false,
        chartApiCompleted: false,
    },
    showChart: false,
    isLoadingChart: false,
    chartData: {},
};

const downloadCsvXlsxInitialState = {
    campaignCsvData: "",
    downloadFileName: "",
    campaignImpressionCsvData: "",
    postEmailSummary: "",
    postEmailImpressionDetail: "",
    postEmailDetails: "",
    newslettersCsvData: "",
    AuthorEmailSummary: "",
    DIEarnedMediaCSV: "",
    DIBasicCSV: "",
    DIProCSV: "",
    OrederSummayCSV: "",
    NewslettersDetailCSV: "",
};

const generateInitialFilterState = () => {
    const defaultFilters = {
        ...searchFilters,
        ...tableInitialState,
        ...chartInitialState,
        ...downloadCsvXlsxInitialState,

        // set list data 
        getListData: {}
    };

    const initialState = {};

    actionTypes.ALL_MODULES.forEach(module => {
        initialState[module] = { ...defaultFilters };
    });

    return initialState;
};

const defaultSelectData = {
    orgs: [],
    campaigns: [],
    order: [],
    adorders: [],
    adUnit: [],
    country: [],
    engOrgs: [],
    engMembers: [],
    sectors: [],
    industries: [],
    subIndustries: [],
    industryGroups: [],
    organizationTypes: [],
    organizationSizes: [],
    organizationRevenues: [],
    senioritiesIn: [],
    senioritiesEx: [],
    jobFunctionsIn: [],
    jobFunctionsEx: [],
    selJobTitlesIN: "",
    selJobTitlesEX: "",
    authorsOrCreatedBy: [],
    newsletterAuthors: [],
    
    //pageview arrays
    pageTitles: [],
    utmSources: [],
    utmCampaigns: [],
    utmContents: [],
    utmMediums: [],
    utmTerms: [],
    primaryTopics: [],
    topicTagsIn: [],
    topicTagsEx: [],
    placementTagsIn: [],
    placementTagsEx: [],
    organizationTagsIn: [],
    organizationTagsEx: [],
    csmArray: [],
    authors: [],

    // DI data
    companyData: [],
    accIntrestedByKeywords: [],
    parentCompanyArr: [],
    segmentsArr: [],
}

const adStates = {
    MODULE_SIDEBAR_ADS: { ...defaultSelectData },
    MODULE_TEXT_ADS: { ...defaultSelectData },
    MODULE_ANNOUNCEMENT_ADS: { ...defaultSelectData },
    MODULE_ATF_HOMEPAGE: { ...defaultSelectData },
    MODULE_INSTANT_MESSAGE: { ...defaultSelectData },
    MODULE_NEWSLETTER_BODY: { ...defaultSelectData },
    MODULE_DISPLAY_ADS: { ...defaultSelectData },
    MODULE_CONTENT_BODY_ADS: { ...defaultSelectData },
    MODULE_INTERSTITIAL_ADS: { ...defaultSelectData },
    MODULE_NEWSLETTER_ADS: { ...defaultSelectData },
    MODULE_ROADBLOCK_ADS: { ...defaultSelectData },
    MODULE_ORDER_REPORT: { ...defaultSelectData },
    MODULE_NEWSLETTERS: { ...defaultSelectData },
    MODULE_ALL_ADS_REPORT: { ...defaultSelectData },
    MODULE_CONTENT_PERFORMANCE: { ...defaultSelectData },
    MODULE_AUTHOR_REPORT: { ...defaultSelectData },
    MODULE_DI_EARNED_MEDIA: { ...defaultSelectData },
    MODULE_DI_BASIC: { ...defaultSelectData },
    MODULE_DI_PRO: { ...defaultSelectData },
    MODULE_DI_UPGRADE: { ...defaultSelectData },
}

const getReportData = (state, action, key, loadingKey) => {
    return {
        ...state,
        [action.reportType]: {
            ...state[action.reportType],
            [key]: action.payload,
            [loadingKey]: false,
        },
    };
};

const showMoreData = (state, action, dataKey, loadingKey, showMoreKey, pageCountKey) => {
    if (action.payload === null) {
        return {
            ...state,
            [action.reportType]: {
                ...state[action.reportType],
                [loadingKey]: false,
                [showMoreKey]: true,
            }
        };
    } else {
        return {
            ...state,
            [action.reportType]: {
                ...state[action.reportType],
                [dataKey]: [...state[action.reportType][dataKey], ...action.payload],
                [loadingKey]: false,
                [showMoreKey]: false,
                [pageCountKey]: state[action.reportType][pageCountKey] + 1,
            }
        };
    }
};

const updateCsvData = (state, action, dataKey) => ({
    ...state,
    [action.reportType]: {
        ...state[action.reportType],
        [dataKey]: action.payload,
        downloadFileName: action.downloadFileName
    }
});

function processNewsletterPayload(action) {
    if (action.payload !== null && action.payload.length !== 0) {
        action.payload = transformNewsletterData(action.payload);
    }
}

function processContentPerformancePayload(action) {
    if (action.payload !== null && action.payload.length !== 0) {
        action.payload = transformContentPerformanceData(action.payload);
    }
}

const updateClearReportState = (state, action, extraFields = {}) => {
    return {
        ...state,
        [action.reportType]: {
            ...state[action.reportType],
            ...searchFilters,
            ...defaultSelectData,
            ...extraFields
        }
    };
};

const setEarnedDataFunction = (action, getListData) => {
    if (action.payload !== null && action.payload.length !== 0) {
        action.payload = Object.values(action?.payload)?.map((item) => {
            const { decision_insights, ...rest } = item;
            const transformedInsights = transformLocationsAndRevenue(decision_insights, getListData, {});
            return { ...transformedInsights, ...rest };
        });
    }
}

const setBasicDataFunction = (action, getListData) => {
    action.payload = Object.values(action.payload)?.map((item) => {
        const { decision_insights_earned, ...rest } = item;
        const { decision_insights } = decision_insights_earned || {};
        const transformedInsights = transformLocationsAndRevenue(decision_insights, getListData, rest);
        return { ...transformedInsights, ...rest, ...item.decision_insights_earned };
    });
}

const setProDataFunction = (action, getListData) => {
    action.payload = Object.values(action?.payload)?.map((item) => {
        const { decision_insights_basic, ...rest } = item;
        const { decision_insights_earned, ...basicRest } = decision_insights_basic || {};
        const { decision_insights, ...earnedRest } = decision_insights_earned || {};
        const transformedInsights = transformLocationsAndRevenue(decision_insights, getListData, basicRest);
        return { ...transformedInsights, ...earnedRest, ...basicRest, ...rest };
    });
};

const getEmailField = (actionType, payload, rType) => {
    const adCampaignFields = {
        "/ad_campaigns": "postEmailSummary",
        "/runs": "postEmailImpressionDetail",
        "reports/ad/detail": "postEmailDetails",
        "/reports/pageviews/authors": "AuthorEmailSummary"
    };

    const decisionInsightsFields = {
        earned_media: "DIEarnedMediaCSV",
        basic: "DIBasicCSV",
        pro: "DIProCSV"
    };

    if (actionType === actionTypes.COMMON_SEARCH.AD_CAMPAIGN_XLSX) {
        return adCampaignFields[payload] || null;
    }

    if (actionType === actionTypes.COMMON_SEARCH.DESCISION_INSIGHTS_CSV && payload === "/decision/insights/report") {
        return decisionInsightsFields[rType] || null;
    }

    return null;
};

const generateCSVData = (state, action, emailField) => {
    return {
        ...state,
        [action.reportType]: {
            ...state[action.reportType],
            [emailField]: action.email
        }
    };
}

export const commonFilterDataReducer = (state = generateInitialFilterState(), action) => {
    switch (action.type) {
        case actionTypes.COMMON_SEARCH.SELECTVALUE:
            const { key, value, reportType } = action.payload;
            return {
                ...state,
                [reportType]: { ...state[reportType], [key]: value }
            };
        case actionTypes.COMMON_SEARCH.CLEARCOMMONFILTER:
            const extraFields = action.reportType === "MODULE_ORDER_REPORT" ? { campaignStatus: "all" } : {};
            return updateClearReportState(state, action, extraFields);
        case actionTypes.COMMON_SEARCH.FETCH_INITIAL_DATA:
            const data = action.payload;
            const orgType = data?.organization_types?.map(a => a.charAt(0).toLowerCase() + a.substr(1));
            const orgRevenue = data?.organization_revenues?.map(a => a.toLowerCase());
            const dates = data?.applied_date_filter ? getStartEndDates(data.applied_date_filter) : state;

            return {
                ...state,
                [action.reportType]: {
                    ...state[action.reportType],
                    startDate: data.applied_date_filter === "Custom Date Range" ? getDisplayDate(data.start_date) : dates.startDate,
                    endDate: data.applied_date_filter === "Custom Date Range" ? getDisplayDate(data.end_date) : dates.endDate,
                    prevstartDate: data.applied_date_filter === "Custom Date Range" ? getDisplayDate(data.start_date) : dates.startDate,
                    prevendDate: data.applied_date_filter === "Custom Date Range" ? getDisplayDate(data.end_date) : dates.endDate,
                    campaign: data.campaigns ? data.campaigns : [],
                    adorder: data.ads ? data.ads : [],
                    order: data.orders ? data.orders : [],
                    adorderStatus: data.ad_status ? data.ad_status : "",
                    selTagsIN: action.tag ? action.tag.map((tag) => tag = tag.id + "|" + tag.domain) : [],
                    selTagsEX: action.tagEx ? action.tagEx.map((tag) => tag = tag.id + "|" + tag.domain) : [],
                    appliedDate: data.applied_date_filter ? data.applied_date_filter : state.appliedDate,
                    site: data.site ? data.site : "all",
                    campaignStatus: data.status ? data.status : "all",
                    organization: data.organizations ? data.organizations : [],
                    engagedOrganizations: data.engaged_organizations ? data.engaged_organizations : [],
                    adUnitSelected: data.ad_unit ? data.ad_unit : [],
                    selCountry: data.country ? data.country : [],
                    selSector: data.sector ? data.sector : [],
                    selIndustryGroup: data.industry_group ? data.industry_group : [],
                    selIndustry: data.industry ? data.industry : [],
                    selSubIndustry: data.sub_industry ? data.sub_industry : [],
                    selOrgType: orgType,
                    selOrgSize: data.organization_sizes ? data.organization_sizes : [],
                    selOrgRevenue: orgRevenue,
                    selSenioritiesIN: data.seniorities.included_tags ? data.seniorities.included_tags : [],
                    selSenioritiesEX: data.seniorities.excluded_tags ? data.seniorities.excluded_tags : [],
                    selJobFunctionsEX: data.job_functions.excluded_tags ? data.job_functions.excluded_tags : [],
                    selJobFunctionsIN: data.job_functions.included_tags ? data.job_functions.included_tags : [],
                    selJobTitlesIN: data.job_title.included_job_title ? data.job_title.included_job_title : "",
                    selJobTitlesEX: data.job_title.excluded_job_title ? data.job_title.excluded_job_title : "",
                    personaToggle: data?.persona_toggle,
                    firmographicToggle: data?.firmographic_toggle,
                    selEnagedMember: data.engaged_members ? data.engaged_members : [],
                    deliverdFilterState: data.delivery_state ? data.delivery_state : "",
                    excludeQACampaign: data.exclude_qa,
                    excludeInternalCampaign: data.exclude_internal,
                    pacingFilter: data.pace ? data.pace : "",
                    exAnonymousMember: data.exclude_anonymous_organization,
                    exAnonymousOrgs: data.exclude_anonymous_member,
                    adOrderType: data.ad_order_type ? data.ad_order_type : "all",
                    trafficType: data.traffic_filter ? data.traffic_filter : "all",
                    newsletterType: data.newsletter_type !== "" ? data.newsletter_type : "all",
                    newsletterStatus: data.newsletter_status !== "" ? data.newsletter_status : "all",
                    newslettersAuthor: data.author_id ? (data.author_id).toString() : "",
                    setCSM: data.csm ? data.csm : [],
                }
            }
        case actionTypes.COMMON_SEARCH.APPLY_FILTERS:
            return {
                ...state,
                [action.reportType]: {
                    ...state[action.reportType],
                    isFilApplied: true,
                    campaignSummaryData: {},
                    isLoadingCampaign: true,
                    campaignPageCount: 2,
                    isCampaignShowMore: false,
                    campaignImpressionDetailData: {},
                    isLoadingImpression: true,
                    impressionPageCount: 2,
                    isImpressionShowMore: false,
                    adDetailsData: {},
                    isLoadingDetail: true,
                    detailsPageCount: 2,
                    isDetailShowMore: false,
                    isLoadingChart: true,
                    orderSummaryData: {},
                    isLoadingOrder: true,
                    orderPageCount: 2,
                    isOrderShowMore: false,
                    newsletterSummaryData: {},
                    isLoadingNewsletter: true,
                    newsletterPageCount: 2,
                    isNewsletterShowMore: false,
                    contentPerformanceSummaryIPAData: {},
                    isLoadingIPA: true,
                    contentPerformanceSummarySenorityData: {},
                    isLoadingSenority: true,
                    contentPerformanceSummarySectorData: {},
                    isLoadingSector: true,
                    contentPerformanceDetailsData: {},
                    isLoadingCPDetails: true,
                    cpDetailsPageCount: 2,
                    cpDetailShowMore: false,
                    authorSummaryData: {},
                    isLoadingAuthor: true,
                    authorPageCount: 2,
                    isAuthorShowMore: false,
                    diEarnedMediaData: {},
                    isLoadingDIEarnedMedia: true,
                    diBasicData: {},
                    isLoadingDIBasic: true,
                    diProData: {},
                    isLoadingDIPro: true,
                    newsletterDetailsData: {},
                    isLoadingNewsletterDetails: true,
                    newsletterDetailsPageCount: 2,
                    newsletterDetailShowMore: false,
                }
            };
        case actionTypes.COMMON_SEARCH.CAMPAIGN_SUMMARY:
            return getReportData(state, action, 'campaignSummaryData', 'isLoadingCampaign');
        case actionTypes.COMMON_SEARCH.SHOW_MORE_CAMPAIGN:
            return showMoreData(state, action, 'campaignSummaryData', 'isLoadingCampaign', 'isCampaignShowMore', 'campaignPageCount');

        case actionTypes.COMMON_SEARCH.CAMPAIGN_AD_DETAILS:
            return getReportData(state, action, 'campaignImpressionDetailData', 'isLoadingImpression');
        case actionTypes.COMMON_SEARCH.SHOW_MORE_CAMPAIGN_IMPRESSIONS:
            return showMoreData(state, action, 'campaignImpressionDetailData', 'isLoadingImpression', 'isImpressionShowMore', 'impressionPageCount');

        case actionTypes.COMMON_SEARCH.COLUMN_SELECTED:
            const rState = state[action.reportType];
            return {
                ...state,
                [action.reportType]: {
                    ...state[action.reportType],
                    campaignImpressionDetailData: rState.column_direction === "ascending" ?
                        _.orderBy(rState.campaignImpressionDetailData, [action.column_selected], ['desc'])
                        :
                        _.orderBy(rState.campaignImpressionDetailData, [action.column_selected], ['asc']),
                    column_direction: rState.column_direction === "ascending" ? "descending" : "ascending",
                    column_selected: action.column_selected,
                }
            };

        case actionTypes.COMMON_SEARCH.AD_DETAILS:
            return getReportData(state, action, 'adDetailsData', 'isLoadingDetail');
        case actionTypes.COMMON_SEARCH.AD_DETAILS_SHOW_MORE:
            return showMoreData(state, action, 'adDetailsData', 'isLoadingDetail', 'isDetailShowMore', 'detailsPageCount');

        case actionTypes.COMMON_SEARCH.SEND_DYANMIC_TABLE_HEADER:
            return {
                ...state,
                [action.reportType]: {
                    ...state[action.reportType],
                    dynamicHeaderArray: action.payload,
                }
            }

        case actionTypes.COMMON_SEARCH.GET_DYNAMIC_TABLE_HEADER:
            return {
                ...state,
                [action.reportType]: {
                    ...state[action.reportType],
                    getDynamicHeaderData: action.payload,
                }
            }

        case actionTypes.COMMON_SEARCH.GET_DYNAMIC_TABLE_HEADER_2:
            return {
                ...state,
                [action.reportType]: {
                    ...state[action.reportType],
                    getDynamicHeaderData_2: action.payload,
                }
            }

        case actionTypes.COMMON_SEARCH.SEND_DYANMIC_TABLE_HEADER_2:
            return {
                ...state,
                [action.reportType]: {
                    ...state[action.reportType],
                    dynamicHeaderArray_2: action.payload,
                }
            }

        case actionTypes.COMMON_SEARCH.UPDATED_CHART_STATUS:
            return {
                ...state,
                [action.reportType]: {
                    ...state[action.reportType],
                    chartStatus: {
                        ...state[action.reportType].chartStatus,
                        ...action.payload
                    }
                }
            }

        case actionTypes.COMMON_SEARCH.DISPLAYING_CHARTS:
            return {
                ...state,
                [action.reportType]: {
                    ...state[action.reportType],
                    isLoadingChart: false,
                    chartData: action.payload
                }
            }

        case actionTypes.COMMON_SEARCH.CAMPAIGN_SUMMARY_CSV:
            return {
                ...state,
                [action.reportType]: {
                    ...state[action.reportType],
                    campaignCsvData: action.payload,
                    downloadFileName: action.downloadFileName
                }
            }

        case actionTypes.COMMON_SEARCH.CAMPAIGN_IMPRESSION_DETAIL_CSV:
            return {
                ...state,
                [action.reportType]: {
                    ...state[action.reportType],
                    campaignImpressionCsvData: action.payload,
                    downloadFileName: action.downloadFileName
                }
            }

        case actionTypes.COMMON_SEARCH.AD_CAMPAIGN_XLSX:
        case actionTypes.COMMON_SEARCH.DESCISION_INSIGHTS_CSV:
            const emailField = getEmailField(action.type, action.payload, action.typeOfReport);
            
            if (emailField) {
                return {
                    ...state,
                    [action.reportType]: {
                        ...state[action.reportType],
                        [emailField]: action.email
                    }
                };
            }
            return state;

        case actionTypes.COMMON_SEARCH.ORDER_SUMMARY:
            action.payload = transformOrderSummaryData(action.payload);
            return getReportData(state, action, 'orderSummaryData', 'isLoadingOrder');
        case actionTypes.COMMON_SEARCH.SHOW_MORE_ORDER_SUMMARY:
            return showMoreData(state, action, 'orderSummaryData', 'isLoadingOrder', 'isOrderShowMore', 'orderPageCount');

        case actionTypes.MODULE_ORDER_REPORT.GENERATE_ORDER_SUMMARY_CSV:
            return generateCSVData(state, action, 'OrederSummayCSV');

        case actionTypes.COMMON_SEARCH.NEWSLETTERS_SUMMARY:
            processNewsletterPayload(action);
            return getReportData(state, action, 'newsletterSummaryData', 'isLoadingNewsletter');

        case actionTypes.COMMON_SEARCH.SHOW_MORE_NEWSLETTERS_SUMMARY:
            processNewsletterPayload(action);
            return showMoreData(state, action, 'newsletterSummaryData', 'isLoadingNewsletter', 'isNewsletterShowMore', 'newsletterPageCount');
      
        case actionTypes.COMMON_SEARCH.NEWSLETTER_DETAILS:
            return getReportData(state, action, 'newsletterDetailsData', 'isLoadingNewsletterDetails');
        
        case actionTypes.COMMON_SEARCH.SHOW_MORE_NEWSLETTER_DETAILS:
            return showMoreData(state, action, 'newsletterDetailsData', 'isLoadingNewsletterDetails', 'isNewsletterDetailsShowMore', 'newsletterDetailsPageCount');
        
        case actionTypes.COMMON_SEARCH.GENERATE_NEWSLETTER_CSV:
            const emailFieldForNewsletter = "NewslettersDetailCSV";
            if (emailFieldForNewsletter) {
                return {
                    ...state,
                    [action.reportType]: {
                        ...state[action.reportType],
                        [emailFieldForNewsletter]: action.email
                    }
                };
            }
            return state;

        case actionTypes.COMMON_SEARCH.NEWSLETTERS_SUMMARY_CSV:
            return updateCsvData(state, action, 'newslettersCsvData');

        case actionTypes.COMMON_SEARCH.CONTENT_PERFORMANCE_SUMMARY_IPA:
            processContentPerformancePayload(action);
            return getReportData(state, action, 'contentPerformanceSummaryIPAData', 'isLoadingIPA');

        case actionTypes.COMMON_SEARCH.CONTENT_PERFORMANCE_SUMMARY_SENORITY:
            return getReportData(state, action, 'contentPerformanceSummarySenorityData', 'isLoadingSenority');

        case actionTypes.COMMON_SEARCH.CONTENT_PERFORMANCE_SUMMARY_SECTOR:
            return getReportData(state, action, 'contentPerformanceSummarySectorData', 'isLoadingSector');
            
        case actionTypes.COMMON_SEARCH.CONTENT_PERFORMANCE_DETAILS:
            return getReportData(state, action, 'contentPerformanceDetailsData', 'isLoadingCPDetails');

        case actionTypes.COMMON_SEARCH.CONTENT_PERFORMANCE_DETAILS_SHOW_MORE:
            return showMoreData(state, action, 'contentPerformanceDetailsData', 'isLoadingCPDetails', 'cpDetailShowMore', 'cpDetailsPageCount');    

        case actionTypes.COMMON_SEARCH.AUTHOR_REPORT_SUMMARY:
            return getReportData(state, action, 'authorSummaryData', 'isLoadingAuthor');

        case actionTypes.MODULE_DI_EARNED_MEDIA.GET_DI_EARNED_MEDIA:
            setEarnedDataFunction(action, state.getListData);
            return getReportData(state, action, 'diEarnedMediaData', 'isLoadingDIEarnedMedia');

        case actionTypes.MODULE_DI_BASIC.GET_DI_BASIC:
            setBasicDataFunction(action, state.getListData);
            return getReportData(state, action, 'diBasicData', 'isLoadingDIBasic');

        case actionTypes.MODULE_DI_PRO.GET_DI_PRO :
            setProDataFunction(action, state.getListData);
            return getReportData(state, action, 'diProData', 'isLoadingDIPro');

        case actionTypes.GET_LIST_DATA:
            return { ...state, getListData: action.payload };

        default:
            return state;
    }
};

const actions = {
    orgs: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_ORGANIZATIONS,
        ADD: actionTypes.COMMON_SEARCH.ADD_ORGANIZATIONS,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_ORGANIZATIONS,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_ORGANIZATIONS,
        RESET: actionTypes.COMMON_SEARCH.RESET_ORGANIZATIONS
    },
    campaigns: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_CAMPAIGNS,
        ADD: actionTypes.COMMON_SEARCH.ADD_CAMPAIGNS,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_CAMPAIGNS,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_CAMPAIGNS,
        RESET: actionTypes.COMMON_SEARCH.RESET_CAMPAIGNS
    },
    order: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_ORDERS,
        ADD: actionTypes.COMMON_SEARCH.ADD_ORDERS,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_ORDERS,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_ORDERS,
        RESET: actionTypes.COMMON_SEARCH.RESET_ORDERS
    },
    ad_orders: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_AD_ORDERS,
        ADD: actionTypes.COMMON_SEARCH.ADD_AD_ORDERS,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_AD_ORDERS,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_AD_ORDERS,
        RESET: actionTypes.COMMON_SEARCH.RESET_AD_ORDERS
    },
    ad_units: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_AD_UNIT,
        ADD: actionTypes.COMMON_SEARCH.ADD_AD_UNIT,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_AD_UNIT,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_AD_UNIT,
        RESET: actionTypes.COMMON_SEARCH.RESET_AD_UNIT
    },
    country: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_COUNTRIES,
        ADD: actionTypes.COMMON_SEARCH.ADD_COUNTRIES,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_COUNTRIES,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_COUNTRIES,
        RESET: actionTypes.COMMON_SEARCH.RESET_COUNTRIES
    },
    engOrgs: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_ENG_ORGANIZATIONS,
        ADD: actionTypes.COMMON_SEARCH.ADD_ENG_ORGANIZATIONS,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_ENG_ORGANIZATIONS,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_ENG_ORGANIZATIONS,
        RESET: actionTypes.COMMON_SEARCH.RESET_ENG_ORGANIZATIONS
    },
    engMember: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_ENG_MEMBERS,
        ADD: actionTypes.COMMON_SEARCH.ADD_ENG_MEMBERS,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_ENG_MEMBERS,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_ENG_MEMBERS,
        RESET: actionTypes.COMMON_SEARCH.RESET_ENG_MEMBERS
    },
    sectors: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_SECTORS,
        ADD: actionTypes.COMMON_SEARCH.ADD_SECTORS,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_SECTORS,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_SECTORS,
        RESET: actionTypes.COMMON_SEARCH.RESET_SECTORS
    },
    industrys: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_INDUSTRIES,
        ADD: actionTypes.COMMON_SEARCH.ADD_INDUSTRIES,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_INDUSTRIES,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_INDUSTRIES,
        RESET: actionTypes.COMMON_SEARCH.RESET_INDUSTRIES
    },
    industry_groups: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_INDUSTRY_GROUPS,
        ADD: actionTypes.COMMON_SEARCH.ADD_INDUSTRY_GROUPS,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_INDUSTRY_GROUPS,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_INDUSTRY_GROUPS,
        RESET: actionTypes.COMMON_SEARCH.RESET_INDUSTRY_GROUPS
    },
    sub_industries: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_SUB_INDUSTRIES,
        ADD: actionTypes.COMMON_SEARCH.ADD_SUB_INDUSTRIES,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_SUB_INDUSTRIES,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_SUB_INDUSTRIES,
        RESET: actionTypes.COMMON_SEARCH.RESET_SUB_INDUSTRIES
    },
    organization_size: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_ORGANIZATION_SIZES,
        ADD: actionTypes.COMMON_SEARCH.ADD_ORGANIZATION_SIZES,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_ORGANIZATION_SIZES,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_ORGANIZATION_SIZES,
        RESET: actionTypes.COMMON_SEARCH.RESET_ORGANIZATION_SIZES,
    },
    organization_type: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_ORGANIZATION_TYPES,
        ADD: actionTypes.COMMON_SEARCH.ADD_ORGANIZATION_TYPES,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_ORGANIZATION_TYPES,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_ORGANIZATION_TYPES,
        RESET: actionTypes.COMMON_SEARCH.RESET_ORGANIZATION_TYPES
    },
    organization_revenue: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_ORGANIZATION_REVENUES,
        ADD: actionTypes.COMMON_SEARCH.ADD_ORGANIZATION_REVENUES,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_ORGANIZATION_REVENUES,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_ORGANIZATION_REVENUES,
        RESET: actionTypes.COMMON_SEARCH.RESET_ORGANIZATION_REVENUES
    },
    seniorities_in: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_SENORITY_IN,
        ADD: actionTypes.COMMON_SEARCH.ADD_SENORITY_IN,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_SENORITY_IN,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_SENORITY_IN,
        RESET: actionTypes.COMMON_SEARCH.RESET_SENORITY_IN
    },
    seniorities_ex: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_SENORITY_EX,
        ADD: actionTypes.COMMON_SEARCH.ADD_SENORITY_EX,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_SENORITY_EX,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_SENORITY_EX,
        RESET: actionTypes.COMMON_SEARCH.RESET_SENORITY_EX
    },
    jobfunction_in: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_JOB_FUNCTION_IN,
        ADD: actionTypes.COMMON_SEARCH.ADD_JOB_FUNCTION_IN,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_JOB_FUNCTION_IN,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_JOB_FUNCTION_IN,
        RESET: actionTypes.COMMON_SEARCH.RESET_JOB_FUNCTION_IN
    },
    jobfunction_ex: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_JOB_FUNCTION_EX,
        ADD: actionTypes.COMMON_SEARCH.ADD_JOB_FUNCTION_EX,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_JOB_FUNCTION_EX,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_JOB_FUNCTION_EX,
        RESET: actionTypes.COMMON_SEARCH.RESET_JOB_FUNCTION_EX
    },
    created_by: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_AUTHORS,
        ADD: actionTypes.COMMON_SEARCH.ADD_AUTHORS,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_AUTHORS,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_AUTHORS,
        RESET: actionTypes.COMMON_SEARCH.RESET_AUTHORS
    },
    newsletters_author: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_NEWSLETTERS_AUTHORS,
        ADD: actionTypes.COMMON_SEARCH.ADD_NEWSLETTERS_AUTHORS,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_NEWSLETTERS_AUTHORS,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_NEWSLETTERS_AUTHORS,
        RESET: actionTypes.COMMON_SEARCH.RESET_NEWSLETTERS_AUTHORS
    },
    page_titles: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_PAGE_TITLES,
        ADD: actionTypes.COMMON_SEARCH.ADD_PAGE_TITLES,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_PAGE_TITLES,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_PAGE_TITLES,
        RESET: actionTypes.COMMON_SEARCH.RESET_PAGE_TITLES
    },
    utm_source: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_UTM_SOURCES,
        ADD: actionTypes.COMMON_SEARCH.ADD_UTM_SOURCES,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_UTM_SOURCES,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_UTM_SOURCES,
        RESET: actionTypes.COMMON_SEARCH.RESET_UTM_SOURCES
    },
    utm_medium: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_UTM_MEDIUMS,
        ADD: actionTypes.COMMON_SEARCH.ADD_UTM_MEDIUMS,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_UTM_MEDIUMS,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_UTM_MEDIUMS,
        RESET: actionTypes.COMMON_SEARCH.RESET_UTM_MEDIUMS
    },
    utm_campaign: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_UTM_CAMPAIGNS,
        ADD: actionTypes.COMMON_SEARCH.ADD_UTM_CAMPAIGNS,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_UTM_CAMPAIGNS,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_UTM_CAMPAIGNS,
        RESET: actionTypes.COMMON_SEARCH.RESET_UTM_CAMPAIGNS
    },
    utm_content: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_UTM_CONTENTS,
        ADD: actionTypes.COMMON_SEARCH.ADD_UTM_CONTENTS,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_UTM_CONTENTS,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_UTM_CONTENTS,
        RESET: actionTypes.COMMON_SEARCH.RESET_UTM_CONTENTS
    },
    utm_term: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_UTM_TERMS,
        ADD: actionTypes.COMMON_SEARCH.ADD_UTM_TERMS,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_UTM_TERMS,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_UTM_TERMS,
        RESET: actionTypes.COMMON_SEARCH.RESET_UTM_TERMS
    },
    primary_topic: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_PRIMARY_TOPICS,
        ADD: actionTypes.COMMON_SEARCH.ADD_PRIMARY_TOPICS,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_PRIMARY_TOPICS,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_PRIMARY_TOPICS,
        RESET: actionTypes.COMMON_SEARCH.RESET_PRIMARY_TOPICS
    },
    topic_tag_in: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_TOPIC_TAGS_IN,
        ADD: actionTypes.COMMON_SEARCH.ADD_TOPIC_TAGS_IN,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_TOPIC_TAGS_IN,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_TOPIC_TAGS_IN,
        RESET: actionTypes.COMMON_SEARCH.RESET_TOPIC_TAGS_IN
    },
    topic_tag_ex: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_TOPIC_TAGS_EX,
        ADD: actionTypes.COMMON_SEARCH.ADD_TOPIC_TAGS_EX,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_TOPIC_TAGS_EX,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_TOPIC_TAGS_EX,
        RESET: actionTypes.COMMON_SEARCH.RESET_TOPIC_TAGS_EX
    },
    placement_tag_in: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_PLACEMENT_TAGS_IN,
        ADD: actionTypes.COMMON_SEARCH.ADD_PLACEMENT_TAGS_IN,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_PLACEMENT_TAGS_IN,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_PLACEMENT_TAGS_IN,
        RESET: actionTypes.COMMON_SEARCH.RESET_PLACEMENT_TAGS_IN
    },
    placement_tag_ex: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_PLACEMENT_TAGS_EX,
        ADD: actionTypes.COMMON_SEARCH.ADD_PLACEMENT_TAGS_EX,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_PLACEMENT_TAGS_EX,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_PLACEMENT_TAGS_EX,
        RESET: actionTypes.COMMON_SEARCH.RESET_PLACEMENT_TAGS_EX
    },
    organization_tag_in: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_ORGANIZATION_TAGS_IN,
        ADD: actionTypes.COMMON_SEARCH.ADD_ORGANIZATION_TAGS_IN,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_ORGANIZATION_TAGS_IN,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_ORGANIZATION_TAGS_IN,
        RESET: actionTypes.COMMON_SEARCH.RESET_ORGANIZATION_TAGS_IN
    },
    organization_tag_ex: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_ORGANIZATION_TAGS_EX,
        ADD: actionTypes.COMMON_SEARCH.ADD_ORGANIZATION_TAGS_EX,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_ORGANIZATION_TAGS_EX,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_ORGANIZATION_TAGS_EX,
        RESET: actionTypes.COMMON_SEARCH.RESET_ORGANIZATION_TAGS_EX
    },
    csm: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_CSM,
        ADD: actionTypes.COMMON_SEARCH.ADD_CSM,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_CSM,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_CSM,
        RESET: actionTypes.COMMON_SEARCH.RESET_CSM
    },
    authors: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_AUTHOR,
        ADD: actionTypes.COMMON_SEARCH.ADD_AUTHOR,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_AUTHOR,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_AUTHOR,
        RESET: actionTypes.COMMON_SEARCH.RESET_AUTHOR
    },
    companyData: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_COMPANY_NAME,
        ADD: actionTypes.COMMON_SEARCH.ADD_COMPANY_NAME,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_COMPANY_NAME,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_COMPANY_NAME,
        RESET: actionTypes.COMMON_SEARCH.RESET_COMPANY_NAME
    },
    accInterstedByKeyword: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_ACCOUNT_INTERESTED_BY_KEYWORD,
        ADD: actionTypes.COMMON_SEARCH.ADD_ACCOUNT_INTERESTED_BY_KEYWORD,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_ACCOUNT_INTERESTED_BY_KEYWORD,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_ACCOUNT_INTERESTED_BY_KEYWORD,
        RESET: actionTypes.COMMON_SEARCH.RESET_ACCOUNT_INTERESTED_BY_KEYWORD
    },
    parentCompany: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_PARENT_COMPANY,
        ADD: actionTypes.COMMON_SEARCH.ADD_PARENT_COMPANY,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_PARENT_COMPANY,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_PARENT_COMPANY,
        RESET: actionTypes.COMMON_SEARCH.RESET_PARENT_COMPANY
    },
    segments: {
        FETCH: actionTypes.COMMON_SEARCH.FETCH_SEGMENTS,
        ADD: actionTypes.COMMON_SEARCH.ADD_SEGMENTS,
        CLEARSEARCH: actionTypes.COMMON_SEARCH.CLEAR_SEGMENTS,
        SELECTINITIAL: actionTypes.COMMON_SEARCH.SELECT_SEGMENTS,
        RESET: actionTypes.COMMON_SEARCH.RESET_SEGMENTS
    }
};

export const allReportCommonReducers = (state = { ...adStates }, action) => {
    switch (action.type) {
        case actionTypes.COMMON_SEARCH.FETCH_ORGANIZATIONS:
        case actionTypes.COMMON_SEARCH.ADD_ORGANIZATIONS:
        case actionTypes.COMMON_SEARCH.CLEAR_ORGANIZATIONS:
        case actionTypes.COMMON_SEARCH.SELECT_ORGANIZATIONS:
        case actionTypes.COMMON_SEARCH.RESET_ORGANIZATIONS:
            return genericFilterReducer(state, actions.orgs, "uuid", "orgs")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_CAMPAIGNS:
        case actionTypes.COMMON_SEARCH.ADD_CAMPAIGNS:
        case actionTypes.COMMON_SEARCH.CLEAR_CAMPAIGNS:
        case actionTypes.COMMON_SEARCH.SELECT_CAMPAIGNS:
        case actionTypes.COMMON_SEARCH.RESET_CAMPAIGNS:
            return genericFilterReducer(state, actions.campaigns, "uuid", "campaigns")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_ORDERS:
        case actionTypes.COMMON_SEARCH.ADD_ORDERS:
        case actionTypes.COMMON_SEARCH.CLEAR_ORDERS:
        case actionTypes.COMMON_SEARCH.SELECT_ORDERS:
        case actionTypes.COMMON_SEARCH.RESET_ORDERS:
            return genericFilterReducer(state, actions.order, "uuid", "order")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_AD_ORDERS:
        case actionTypes.COMMON_SEARCH.ADD_AD_ORDERS:
        case actionTypes.COMMON_SEARCH.CLEAR_AD_ORDERS:
        case actionTypes.COMMON_SEARCH.SELECT_AD_ORDERS:
        case actionTypes.COMMON_SEARCH.RESET_AD_ORDERS:
            return genericFilterReducer(state, actions.ad_orders, "uuid", "adorders")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_AD_UNIT:
        case actionTypes.COMMON_SEARCH.ADD_AD_UNIT:
        case actionTypes.COMMON_SEARCH.CLEAR_AD_UNIT:
        case actionTypes.COMMON_SEARCH.SELECT_AD_UNIT:
        case actionTypes.COMMON_SEARCH.RESET_AD_UNIT:
            return genericFilterReducer(state, actions.ad_units, "value", "adUnit")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_COUNTRIES:
        case actionTypes.COMMON_SEARCH.ADD_COUNTRIES:
        case actionTypes.COMMON_SEARCH.CLEAR_COUNTRIES:
        case actionTypes.COMMON_SEARCH.SELECT_COUNTRIES:
        case actionTypes.COMMON_SEARCH.RESET_COUNTRIES:
            return genericFilterReducer(state, actions.country, "name", "country")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_ENG_ORGANIZATIONS:
        case actionTypes.COMMON_SEARCH.ADD_ENG_ORGANIZATIONS:
        case actionTypes.COMMON_SEARCH.CLEAR_ENG_ORGANIZATIONS:
        case actionTypes.COMMON_SEARCH.SELECT_ENG_ORGANIZATIONS:
        case actionTypes.COMMON_SEARCH.RESET_ENG_ORGANIZATIONS:
            return genericFilterReducer(state, actions.engOrgs, "uuid", "engOrgs")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_ENG_MEMBERS:
        case actionTypes.COMMON_SEARCH.ADD_ENG_MEMBERS:
        case actionTypes.COMMON_SEARCH.CLEAR_ENG_MEMBERS:
        case actionTypes.COMMON_SEARCH.SELECT_ENG_MEMBERS:
        case actionTypes.COMMON_SEARCH.RESET_ENG_MEMBERS:
            return genericFilterReducer(state, actions.engMember, "user_uuid", "engMembers")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_SECTORS:
        case actionTypes.COMMON_SEARCH.ADD_SECTORS:
        case actionTypes.COMMON_SEARCH.CLEAR_SECTORS:
        case actionTypes.COMMON_SEARCH.SELECT_SECTORS:
        case actionTypes.COMMON_SEARCH.RESET_SECTORS:
            return genericFilterReducer(state, actions.sectors, "sector", "sectors")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_INDUSTRY_GROUPS:
        case actionTypes.COMMON_SEARCH.ADD_INDUSTRY_GROUPS:
        case actionTypes.COMMON_SEARCH.CLEAR_INDUSTRY_GROUPS:
        case actionTypes.COMMON_SEARCH.SELECT_INDUSTRY_GROUPS:
        case actionTypes.COMMON_SEARCH.RESET_INDUSTRY_GROUPS:
            return genericFilterReducer(state, actions.industry_groups, "industry_group", "industryGroups")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_INDUSTRIES:
        case actionTypes.COMMON_SEARCH.ADD_INDUSTRIES:
        case actionTypes.COMMON_SEARCH.CLEAR_INDUSTRIES:
        case actionTypes.COMMON_SEARCH.SELECT_INDUSTRIES:
        case actionTypes.COMMON_SEARCH.RESET_INDUSTRIES:
            return genericFilterReducer(state, actions.industrys, "industry", "industries")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_SUB_INDUSTRIES:
        case actionTypes.COMMON_SEARCH.ADD_SUB_INDUSTRIES:
        case actionTypes.COMMON_SEARCH.CLEAR_SUB_INDUSTRIES:
        case actionTypes.COMMON_SEARCH.SELECT_SUB_INDUSTRIES:
        case actionTypes.COMMON_SEARCH.RESET_SUB_INDUSTRIES:
            return genericFilterReducer(state, actions.sub_industries, "sub_industry", "subIndustries")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_ORGANIZATION_TYPES:
        case actionTypes.COMMON_SEARCH.ADD_ORGANIZATION_TYPES:
        case actionTypes.COMMON_SEARCH.CLEAR_ORGANIZATION_TYPES:
        case actionTypes.COMMON_SEARCH.SELECT_ORGANIZATION_TYPES:
        case actionTypes.COMMON_SEARCH.RESET_ORGANIZATION_TYPES:
            return genericFilterReducer(state, actions.organization_type, "value", "organizationTypes")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_ORGANIZATION_SIZES:
        case actionTypes.COMMON_SEARCH.ADD_ORGANIZATION_SIZES:
        case actionTypes.COMMON_SEARCH.CLEAR_ORGANIZATION_SIZES:
        case actionTypes.COMMON_SEARCH.SELECT_ORGANIZATION_SIZES:
        case actionTypes.COMMON_SEARCH.RESET_ORGANIZATION_SIZES:
            return genericFilterReducer(state, actions.organization_size, "value", "organizationSizes")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_ORGANIZATION_REVENUES:
        case actionTypes.COMMON_SEARCH.ADD_ORGANIZATION_REVENUES:
        case actionTypes.COMMON_SEARCH.CLEAR_ORGANIZATION_REVENUES:
        case actionTypes.COMMON_SEARCH.SELECT_ORGANIZATION_REVENUES:
        case actionTypes.COMMON_SEARCH.RESET_ORGANIZATION_REVENUES:
            return genericFilterReducer(state, actions.organization_revenue, "value", "organizationRevenues")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_SENORITY_IN:
        case actionTypes.COMMON_SEARCH.ADD_SENORITY_IN:
        case actionTypes.COMMON_SEARCH.CLEAR_SENORITY_IN:
        case actionTypes.COMMON_SEARCH.SELECT_SENORITY_IN:
        case actionTypes.COMMON_SEARCH.RESET_SENORITY_IN:
            return genericFilterReducer(state, actions.seniorities_in, "id", "senioritiesIn")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_SENORITY_EX:
        case actionTypes.COMMON_SEARCH.ADD_SENORITY_EX:
        case actionTypes.COMMON_SEARCH.CLEAR_SENORITY_EX:
        case actionTypes.COMMON_SEARCH.SELECT_SENORITY_EX:
        case actionTypes.COMMON_SEARCH.RESET_SENORITY_EX:
            return genericFilterReducer(state, actions.seniorities_ex, "id", "senioritiesEx")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_JOB_FUNCTION_IN:
        case actionTypes.COMMON_SEARCH.ADD_JOB_FUNCTION_IN:
        case actionTypes.COMMON_SEARCH.CLEAR_JOB_FUNCTION_IN:
        case actionTypes.COMMON_SEARCH.SELECT_JOB_FUNCTION_IN:
        case actionTypes.COMMON_SEARCH.RESET_JOB_FUNCTION_IN:
            return genericFilterReducer(state, actions.jobfunction_in, "id", "jobFunctionsIn")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_JOB_FUNCTION_EX:
        case actionTypes.COMMON_SEARCH.ADD_JOB_FUNCTION_EX:
        case actionTypes.COMMON_SEARCH.CLEAR_JOB_FUNCTION_EX:
        case actionTypes.COMMON_SEARCH.SELECT_JOB_FUNCTION_EX:
        case actionTypes.COMMON_SEARCH.RESET_JOB_FUNCTION_EX:
            return genericFilterReducer(state, actions.jobfunction_ex, "id", "jobFunctionsEx")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_AUTHORS:
        case actionTypes.COMMON_SEARCH.ADD_AUTHORS:
        case actionTypes.COMMON_SEARCH.CLEAR_AUTHORS:
        case actionTypes.COMMON_SEARCH.SELECT_AUTHORS:
        case actionTypes.COMMON_SEARCH.RESET_AUTHORS:
            return genericFilterReducer(state, actions.created_by, "id", "authorsOrCreatedBy")(state, action);

        
        case actionTypes.COMMON_SEARCH.FETCH_NEWSLETTERS_AUTHORS:
        case actionTypes.COMMON_SEARCH.ADD_NEWSLETTERS_AUTHORS:
        case actionTypes.COMMON_SEARCH.CLEAR_NEWSLETTERS_AUTHORS:
        case actionTypes.COMMON_SEARCH.SELECT_NEWSLETTERS_AUTHORS:
        case actionTypes.COMMON_SEARCH.RESET_NEWSLETTERS_AUTHORS:
            return genericFilterReducer(state, actions.newsletters_author, "id", "newsletterAuthors")(state, action);
        
        case actionTypes.COMMON_SEARCH.FETCH_PAGE_TITLES:
        case actionTypes.COMMON_SEARCH.ADD_PAGE_TITLES:
        case actionTypes.COMMON_SEARCH.CLEAR_PAGE_TITLES:
        case actionTypes.COMMON_SEARCH.SELECT_PAGE_TITLES:
        case actionTypes.COMMON_SEARCH.RESET_PAGE_TITLES:
            return genericFilterReducer(state, actions.page_titles, "id", "pageTitles")(state, action);
        
        case actionTypes.COMMON_SEARCH.FETCH_UTM_SOURCES:
        case actionTypes.COMMON_SEARCH.ADD_UTM_SOURCES:
        case actionTypes.COMMON_SEARCH.CLEAR_UTM_SOURCES:
        case actionTypes.COMMON_SEARCH.SELECT_UTM_SOURCES:
        case actionTypes.COMMON_SEARCH.RESET_UTM_SOURCES:
            return genericFilterReducer(state, actions.utm_source, "source", "utmSources")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_UTM_MEDIUMS:
        case actionTypes.COMMON_SEARCH.ADD_UTM_MEDIUMS:
        case actionTypes.COMMON_SEARCH.CLEAR_UTM_MEDIUMS:
        case actionTypes.COMMON_SEARCH.SELECT_UTM_MEDIUMS:
        case actionTypes.COMMON_SEARCH.RESET_UTM_MEDIUMS:
            return genericFilterReducer(state, actions.utm_medium, "medium", "utmMediums")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_UTM_CAMPAIGNS:
        case actionTypes.COMMON_SEARCH.ADD_UTM_CAMPAIGNS:
        case actionTypes.COMMON_SEARCH.CLEAR_UTM_CAMPAIGNS:
        case actionTypes.COMMON_SEARCH.SELECT_UTM_CAMPAIGNS:
        case actionTypes.COMMON_SEARCH.RESET_UTM_CAMPAIGNS:
            return genericFilterReducer(state, actions.utm_campaign, "campaign", "utmCampaigns")(state, action);
        
        case actionTypes.COMMON_SEARCH.FETCH_UTM_CONTENTS:
        case actionTypes.COMMON_SEARCH.ADD_UTM_CONTENTS:
        case actionTypes.COMMON_SEARCH.CLEAR_UTM_CONTENTS:
        case actionTypes.COMMON_SEARCH.SELECT_UTM_CONTENTS:
        case actionTypes.COMMON_SEARCH.RESET_UTM_CONTENTS:
            return genericFilterReducer(state, actions.utm_content, "content", "utmContents")(state, action);
        
        case actionTypes.COMMON_SEARCH.FETCH_UTM_TERMS:
        case actionTypes.COMMON_SEARCH.ADD_UTM_TERMS:
        case actionTypes.COMMON_SEARCH.CLEAR_UTM_TERMS:
        case actionTypes.COMMON_SEARCH.SELECT_UTM_TERMS:
        case actionTypes.COMMON_SEARCH.RESET_UTM_TERMS:
            return genericFilterReducer(state, actions.utm_term, "term", "utmTerms")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_PRIMARY_TOPICS:
        case actionTypes.COMMON_SEARCH.ADD_PRIMARY_TOPICS:
        case actionTypes.COMMON_SEARCH.CLEAR_PRIMARY_TOPICS:
        case actionTypes.COMMON_SEARCH.SELECT_PRIMARY_TOPICS:
        case actionTypes.COMMON_SEARCH.RESET_PRIMARY_TOPICS:
            return genericFilterReducer(state, actions.primary_topic, "tag_id", "primaryTopics")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_TOPIC_TAGS_IN:
        case actionTypes.COMMON_SEARCH.ADD_TOPIC_TAGS_IN:
        case actionTypes.COMMON_SEARCH.CLEAR_TOPIC_TAGS_IN:
        case actionTypes.COMMON_SEARCH.SELECT_TOPIC_TAGS_IN:
        case actionTypes.COMMON_SEARCH.RESET_TOPIC_TAGS_IN:
            return genericFilterReducer(state, actions.topic_tag_in, "key", "topicTagsIn")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_TOPIC_TAGS_EX:
        case actionTypes.COMMON_SEARCH.ADD_TOPIC_TAGS_EX:
        case actionTypes.COMMON_SEARCH.CLEAR_TOPIC_TAGS_EX:
        case actionTypes.COMMON_SEARCH.SELECT_TOPIC_TAGS_EX:
        case actionTypes.COMMON_SEARCH.RESET_TOPIC_TAGS_EX:
            return genericFilterReducer(state, actions.topic_tag_ex, "key", "topicTagsEx")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_PLACEMENT_TAGS_IN:
        case actionTypes.COMMON_SEARCH.ADD_PLACEMENT_TAGS_IN:
        case actionTypes.COMMON_SEARCH.CLEAR_PLACEMENT_TAGS_IN:
        case actionTypes.COMMON_SEARCH.SELECT_PLACEMENT_TAGS_IN:
        case actionTypes.COMMON_SEARCH.RESET_PLACEMENT_TAGS_IN:
            return genericFilterReducer(state, actions.placement_tag_in, "key", "placementTagsIn")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_PLACEMENT_TAGS_EX:
        case actionTypes.COMMON_SEARCH.ADD_PLACEMENT_TAGS_EX:
        case actionTypes.COMMON_SEARCH.CLEAR_PLACEMENT_TAGS_EX:
        case actionTypes.COMMON_SEARCH.SELECT_PLACEMENT_TAGS_EX:
        case actionTypes.COMMON_SEARCH.RESET_PLACEMENT_TAGS_EX:
            return genericFilterReducer(state, actions.placement_tag_ex, "key", "placementTagsEx")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_ORGANIZATION_TAGS_IN:
        case actionTypes.COMMON_SEARCH.ADD_ORGANIZATION_TAGS_IN:
        case actionTypes.COMMON_SEARCH.CLEAR_ORGANIZATION_TAGS_IN:
        case actionTypes.COMMON_SEARCH.SELECT_ORGANIZATION_TAGS_IN:
        case actionTypes.COMMON_SEARCH.RESET_ORGANIZATION_TAGS_IN:
            return genericFilterReducer(state, actions.organization_tag_in, "key", "organizationTagsIn")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_ORGANIZATION_TAGS_EX:
        case actionTypes.COMMON_SEARCH.ADD_ORGANIZATION_TAGS_EX:
        case actionTypes.COMMON_SEARCH.CLEAR_ORGANIZATION_TAGS_EX:
        case actionTypes.COMMON_SEARCH.SELECT_ORGANIZATION_TAGS_EX:
        case actionTypes.COMMON_SEARCH.RESET_ORGANIZATION_TAGS_EX:
            return genericFilterReducer(state, actions.organization_tag_ex, "key", "organizationTagsEx")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_CSM:
        case actionTypes.COMMON_SEARCH.ADD_CSM:
        case actionTypes.COMMON_SEARCH.CLEAR_CSM:
        case actionTypes.COMMON_SEARCH.SELECT_CSM:
        case actionTypes.COMMON_SEARCH.RESET_CSM:
            return genericFilterReducer(state, actions.csm, "id", "csmArray")(state, action);
        
        case actionTypes.COMMON_SEARCH.FETCH_AUTHOR:
        case actionTypes.COMMON_SEARCH.ADD_AUTHOR:
        case actionTypes.COMMON_SEARCH.CLEAR_AUTHOR:
        case actionTypes.COMMON_SEARCH.SELECT_AUTHOR:
        case actionTypes.COMMON_SEARCH.RESET_AUTHOR:
            return genericFilterReducer(state, actions.authors, "id", "authors")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_COMPANY_NAME:
        case actionTypes.COMMON_SEARCH.ADD_COMPANY_NAME:
        case actionTypes.COMMON_SEARCH.CLEAR_COMPANY_NAME:
        case actionTypes.COMMON_SEARCH.SELECT_COMPANY_NAME:
        case actionTypes.COMMON_SEARCH.RESET_COMPANY_NAME:
            return genericFilterReducer(state, actions.companyData, "uuid", "companyData")(state, action);
        
        case actionTypes.COMMON_SEARCH.FETCH_ACCOUNT_INTERESTED_BY_KEYWORD:
        case actionTypes.COMMON_SEARCH.ADD_ACCOUNT_INTERESTED_BY_KEYWORD:
        case actionTypes.COMMON_SEARCH.CLEAR_ACCOUNT_INTERESTED_BY_KEYWORD:
        case actionTypes.COMMON_SEARCH.SELECT_ACCOUNT_INTERESTED_BY_KEYWORD:
        case actionTypes.COMMON_SEARCH.RESET_ACCOUNT_INTERESTED_BY_KEYWORD:
            return genericFilterReducer(state, actions.accInterstedByKeyword, "key", "accIntrestedByKeywords")(state, action);

        case actionTypes.COMMON_SEARCH.FETCH_PARENT_COMPANY:
        case actionTypes.COMMON_SEARCH.ADD_PARENT_COMPANY:
        case actionTypes.COMMON_SEARCH.CLEAR_PARENT_COMPANY:
        case actionTypes.COMMON_SEARCH.SELECT_PARENT_COMPANY:
        case actionTypes.COMMON_SEARCH.RESET_PARENT_COMPANY:
            return genericFilterReducer(state, actions.parentCompany, "uuid", "parentCompanyArr")(state, action);
        
        case actionTypes.COMMON_SEARCH.FETCH_SEGMENTS:
        case actionTypes.COMMON_SEARCH.ADD_SEGMENTS:
        case actionTypes.COMMON_SEARCH.CLEAR_SEGMENTS:
        case actionTypes.COMMON_SEARCH.SELECT_SEGMENTS:
        case actionTypes.COMMON_SEARCH.RESET_SEGMENTS:
            return genericFilterReducer(state, actions.segments, "uuid", "segmentsArr")(state, action);

        default:
            return state;
    }
};