import React, { useEffect, useState, useRef } from "react";
import Layout from "./hoc/layout";
import Login from "./pages/auth/login";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import LeadEngagementReport from "./pages/leadEngagementReport";
import ClientLeadEngReport from "./pages/clientLeadEngReport";
import TopicSponsorshipsReport from "./pages/topicSponsorshipReport";
import Callback from "./pages/auth/callback";
import { connect } from "react-redux";
import _ from "lodash";

import {
	checkAuth,
	fetchConstants,
	fetchappliedFilter,
	fetchCharts,
	heartbeat,
	fetchFilterProfile,
	filterProfile,
	getAllReportsAPI,
	getAllRoles,
	getCurrentUserRole,
	getRoleReportDetails,
	fetchReportDescription
} from "./actions";
import ErrorPage from "./pages/ErrorAndInProgress/error";
import { clearCookie, getCookie } from "./utilities/envCommon";
import HttpErrorView from "./httpErrors/httpErrorsView";
import ErrorBoundary from "./errorBoundaries";
import AdminLogin from "./pages/admin";
import SponsoredContent from "./pages/sponsoredContent";
import Editorial from "./pages/editorial";
import EditorialDetails from "./pages/editorialDetails";
import Site from "./pages/siteReport";
import Activity from "./pages/Activity";
import MessageComponent from "./components/common/MessageComponent";
import Downloads from "./pages/Downloads";
import TextAds from "./pages/Ads/adsText";
import AnnouncementAds from "./pages/Ads/adsAnnouncement";
import Productivity from "./pages/Productivity";
import AtfHomePage from "./pages/Ads/adsAtfHomePage";
import InstantMessage from "./pages/Ads/adsInstantMessage";
import NewsletterBody from "./pages/Ads/adsNewsletterBody";
import DisplayAds from "./pages/Ads/adsDisplay";
import ContentBodyAds from "./pages/Ads/adsContentBody";
import InterstitialAds from "./pages/Ads/adsInterstitial";
import NewsletterAds from "./pages/Ads/adsNewsletter";
import RoadblockAds from "./pages/Ads/adsRoadblock";
import Newsletters from "./pages/Newsletters";
import OrderReport from "./pages/orderReport";
import AllAdsReport from "./pages/Ads/allAdsReport";
import AuthorReport from "./pages/author";
import ContentPerformance from "./pages/contentPerformance";
import DIBasic from "./pages/DecisionInsights/diBasic";
import DIPro from "./pages/DecisionInsights/diPro";
import RoleMapping from "./pages/roleMapping";
import DIUpgrade from "./pages/DecisionInsights/diUpgrade";
import DIProPlus from "./pages/DecisionInsights/diProPlus";
import ReportDescription from "./pages/ReportDescription";
import SidebarAds from "./pages/Ads/adsSidebar";

const App = (props) => {
	const [data, setData] = useState(true);
	const [authenticatedUser, setAuthenticatedUser] = useState(false);
	const [isLogin, setIsLogin] = useState(true);
	const intervalIdRef = useRef(null);

	const navigate = useNavigate();
	const location = useLocation();

	const onVisibilityStateChange = () => {
		intervalIdRef.current = setInterval(() => {
			const token = getCookie("token");
			if (document.visibilityState !== "hidden" && token) {
				props.heartbeat();
			} else if (token === null && isLogin && location.pathname === "/") {
				props.heartbeat();
				setIsLogin(false);
			}
		}, 5000);
	};

	const allowedPaths = new Set([
		"/", "/callback", "/okta/oauth/callback", "/admin", "/sidebars", "/engagements", "/activity", "/leads", "/error", "/ads-display", "/ads-content-body", 
		"/ads-interstitial", "/ads-newsletter", "/ads-roadblock", "/sponsored", "/pageview", "/pageview-details", "/site", "/downloads", 
		"/productivity", "/ads-text", "/ads-announcement", "/ads-instant-message", "/ads-atf-homepage", "/ads-newsletter-body", "/orders", 
		"/newsletters", "/ads", "/content-performance", "/author", "/decision-insights-basic", "/decision-insights-pro", 
		"/decision-insights-pro+", "/decision-insights-upgrade", "/role-mapping", "/report-description", "/ads-sidebar"
	]);

	useEffect(() => {
		if (!allowedPaths.has(location.pathname)) {
			navigate("/");
		}
		props.checkAuth();
		props.fetchConstants();

		return () => {
			document.removeEventListener("visibilitychange", onVisibilityStateChange);
			clearInterval(intervalIdRef.current);
			intervalIdRef.current = null;
		  };
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const readUrlId = location.hash;
		if (
			props.isAuthorized &&
			data &&
			!(
				readUrlId.includes("#/?campaign_uuid") ||
				readUrlId.includes("#/?organization_uuid")
			)
		) {
			onVisibilityStateChange();
			props.getAllRoles();
			setData(false);
			setAuthenticatedUser(true);
			setTimeout(() => {
				setAuthenticatedUser(false);
			}, 5000);

			props.getCurrentUserRole().then((res) => {
				if (res) {
					const roleIds = _.map(res, 'role_id');
					if (roleIds && _.size(roleIds) > 0) {
						props.getRoleReportDetails(roleIds);
					}
				}
			});
			props.getAllReportsAPI();
			props.fetchReportDescription();
			props.fetchFilterProfile(props.authorized)
            .then((response) => {
				let sessionId = getCookie("session_id");
				const profileFirstData = _.size(response) > 0 ? response[0].session_id : null;
				const foundSession = _.find(response, { session_id: sessionId });
				if (!foundSession || foundSession.length === 0) {
					sessionId = profileFirstData;
				}
				const defaultId = sessionId || profileFirstData;
				if (defaultId && _.size(response) > 0) {
					const date = new Date(getCookie("expiry") * 1000);
					document.cookie = `session_id=${defaultId}; expires=${date}; path=/`;
					props.fetchappliedFilter(defaultId);
					props.filterProfile(defaultId);
				} else {
					clearCookie('session_id');
				}
            });
		}
		// eslint-disable-next-line
	}, [props.isAuthorized, data]);

	let routes;
	let errorView;

	if (getCookie("token") === "") {
		routes = <Navigate to="/" />;
	}

	if (props.errorCode && props.errorCode !== 425) {
			errorView = <HttpErrorView code={props.errorCode}></HttpErrorView>;
	}

	return (
		<Layout className="Main">
			<div className="App">
				{
					props.isLoadingForReports && getCookie("userEmail") &&
					<div className="main-loader-overlay">
						<div className="main-loader"></div>
					</div>
				}
				<header className="App-header">
					{routes}
					<Routes>
						<Route exact path="/" element={<Login />} />
						<Route exact path="/okta/oauth/callback" element={<Callback />} />
						<Route exact path="/callback" element={<Callback />} />
						<Route exact path="/admin" element={<AdminLogin />} />
						<Route
							path="/sidebars"
							element={
								<ErrorBoundary>
									<TopicSponsorshipsReport />
								</ErrorBoundary>
							}
						/>
						<Route
							path="/engagements"
							element={
								<ErrorBoundary>
									<LeadEngagementReport />
								</ErrorBoundary>
							}
						/>
						<Route
							path="/activity"
							element={
								<ErrorBoundary>
									<Activity />
								</ErrorBoundary>
							}
						/>
						<Route
							path="/leads"
							element={
								<ErrorBoundary>
									<ClientLeadEngReport />
								</ErrorBoundary>
							}
						/>
						<Route path="/error" element={<ErrorPage />} />
						<Route exact path="/ads-sidebar"
							element={
								<ErrorBoundary>
									<SidebarAds />
								</ErrorBoundary>
							}
						/>
						<Route exact path="/ads-display"
							element={
								<ErrorBoundary>
									<DisplayAds />
								</ErrorBoundary>
							}
						/>
						<Route exact path="/ads-content-body"
							element={
								<ErrorBoundary>
									<ContentBodyAds />
								</ErrorBoundary>
							}
						/>
						<Route exact path="/ads-interstitial"
							element={
								<ErrorBoundary>
									<InterstitialAds />
								</ErrorBoundary>
							}
						/>
						<Route exact path="/ads-newsletter"
							element={
								<ErrorBoundary>
									<NewsletterAds />
								</ErrorBoundary>
							}
						/>
						<Route path="/ads-roadblock"
							element={
								<ErrorBoundary>
									<RoadblockAds />
								</ErrorBoundary>
							}
						/>
						<Route exact path="/ads-text"
							element={
								<ErrorBoundary>
									<TextAds />
								</ErrorBoundary>
							} 
						/>
						<Route exact path="/ads-announcement"
							element={
								<ErrorBoundary>
									<AnnouncementAds />
								</ErrorBoundary>
							} 
						/>
						<Route exact path="/ads-atf-homepage"
							element={
								<ErrorBoundary>
									<AtfHomePage />
								</ErrorBoundary>
							} 
						/>
						<Route exact path="/ads-instant-message"
							element={
								<ErrorBoundary>
									<InstantMessage />
								</ErrorBoundary>
							} 
						/>
						<Route exact path="/ads-newsletter-body"
							element={
								<ErrorBoundary>
									<NewsletterBody />
								</ErrorBoundary>
							} 
						/>
						<Route exact path="/orders"
							element={
								<ErrorBoundary>
									<OrderReport />
								</ErrorBoundary>
							} 
						/>
						<Route exact path="/sponsored"
							element={
								<ErrorBoundary>
									<SponsoredContent />
								</ErrorBoundary>
							}
						/>
						<Route exact path="/pageview"
							element={
								<ErrorBoundary>
									<Editorial />
								</ErrorBoundary>
							}
						/>
						<Route exact path="/pageview-details"
							element={
								<ErrorBoundary>
									<EditorialDetails />
								</ErrorBoundary>
							}
						/>
						<Route exact path="/site"
							element={
								<ErrorBoundary>
									<Site />
								</ErrorBoundary>
							}
						/>
						<Route exact path="/downloads"
							element={
								<ErrorBoundary>
									<Downloads />
								</ErrorBoundary>
							}
						/>
						<Route
							path="/productivity"
							element={
								<ErrorBoundary>
									<Productivity />
								</ErrorBoundary>
							}
						/>
						<Route
							path="/newsletters"
							element={
								<ErrorBoundary>
									<Newsletters />
								</ErrorBoundary>
							}
						/>
						<Route
							path="/ads"
							element={
								<ErrorBoundary>
									<AllAdsReport />
								</ErrorBoundary>
							}
						/>
						<Route
							path="/content-performance"
							element={
								<ErrorBoundary>
									<ContentPerformance />
								</ErrorBoundary>
							}
						/>
						<Route
							path="/author"
							element={
								<ErrorBoundary>
									<AuthorReport />
								</ErrorBoundary>
							}
						/>
						<Route
							path="/decision-insights-basic"
							element={
								<ErrorBoundary>
									<DIBasic />
								</ErrorBoundary>
							}
						/>
						<Route
							path="/decision-insights-pro"
							element={
								<ErrorBoundary>
									<DIPro />
								</ErrorBoundary>
							}
						/>
						<Route
							path="/decision-insights-upgrade"
							element={
								<ErrorBoundary>
									<DIUpgrade />
								</ErrorBoundary>
							}
						/>
						<Route
							path="/decision-insights-pro+"
							element={
								<ErrorBoundary>
									<DIProPlus />
								</ErrorBoundary>
							}
						/>
						<Route
							path="/role-mapping"
							element={
								<ErrorBoundary>
									<RoleMapping />
								</ErrorBoundary>
							}
						/>
						<Route
							path="/report-description"
							element={
								<ErrorBoundary>
									<ReportDescription />
								</ErrorBoundary>
							}
						/>
					</Routes>
					{errorView}
				</header>
				{authenticatedUser &&
					<MessageComponent msgHeader="You are authenticated user" msgBody="You can continue with your operations" />
				}
				{
					props.refreshCampaignStatus &&
					<MessageComponent msgHeader="" msgBody="Campaigns has been refreshed" />
				}
				{
					props.clearColumnsStatus &&
					<MessageComponent msgHeader="Column Cache Cleared!" msgBody="Column cache has been cleared. Your browser tab will now refresh to reflect the updated data" />
				}
			</div>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		isAuthorized: state.authorized.token != null,
		errorCode: state.errorCode,
		refreshCampaignStatus: state.filterData.refreshCampaignStatus,
		clearColumnsStatus: state.filterData.clearColumnsStatus,
		authorized: state.authorized,
		userProfileData: state.allFilterReportData.userFilterProfileData,
		isLoadingForReports: state.allFilterReportData.isLoadingForAllRepport
	};
};

export default connect(mapStateToProps, {
	checkAuth,
	fetchConstants,
	fetchappliedFilter,
	fetchCharts,
	heartbeat,
	fetchFilterProfile,
	filterProfile,
	getAllReportsAPI,
	getAllRoles,
	getCurrentUserRole,
	getRoleReportDetails,
	fetchReportDescription,
})(App);