export const AdsRoadblockHeader = [
    { headerName: "AGENCY", headerKey: "agency_name"}, 
    { headerName: "ACCOUNT", headerKey: "account"}, 
    { headerName: "ORDER", headerKey: "order"}, 
    { headerName: "CAMPAIGN", headerKey: "campaign"}, 
    { headerName: "START DATE", headerKey: "start_date"}, 
    { headerName: "END DATE", headerKey: "end_date"},
    { headerName: "CAMPAIGN STATUS", headerKey: "status"}, 
    { headerName: "DELIVERY STATUS", headerKey: "delivery_state"}, 
    { headerName: "ROADBLOCK IMPRESSIONS PURCHASED", headerKey: "total_impressions_to_be_delivered"}, 
    { headerName: "ROADBLOCK IMPRESSIONS DELIVERED", headerKey: "unique_page_impressions"},
    { headerName: "ROADBLOCK IMPRESSIONS OUTSTANDING", headerKey: "impressions_outstanding"}, 
    { headerName: "CLICKS", headerKey: "clicks"}, 
    { headerName: "CLICK THROUGH RATE", headerKey: "ctr"}, 
    { headerName: "PERCENTAGE DELIVERED", headerKey: "percentage_served_to_date"}, 
    { headerName: "PERCENTAGE COMPLETE", headerKey: "percent_complete"}, 
    { headerName: "PACE", headerKey: "pace"},
    { headerName: "sort_roadblock_icon", headerKey: "sort_roadblock_icon"},
];