import * as actionTypes from "../../actions/actionTypes";
import { url } from "../../api/urlHelper";

export const getAdCampaignCsvData = async (props, reportActionType, reportName) => {
    await props.fetchAdCampaignSummary(props.filterData, 1, "CSV", "", reportName, reportActionType);
};

export const getCampaignExcel = async (props, url, userEmail) => {
    await props.fetchAdData(
        url,
        props.filterData,
        1,
        "XLSX",
        "",
        actionTypes[props.reportActionType].AD_CAMPAIGN_XLSX,
        userEmail,
        null,
        props.reportName,
        props.reportActionType,
    );
}

export const getAdImpressionCsvData = async (props, reportActionType, reportName) => {
    await props.fetchAdImpressions(props.filterData, 1, "CSV", "", reportName, reportActionType);
};

export const getNewslettersSummaryCsvData = async (props, reportActionType, reportType) => {
    await props.fetchNewslettersReportData(props.filterData, 1, "CSV", reportActionType, "", reportType);
}

export const getNewslettersDetailsCsvData = async (props, reportActionType, reportName) => {
    await props.fetchNewslettersDetailsData(props.filterData, 1, "CSV", "", reportName, reportActionType);
}

export const contentAuthorSummaryCsvData = async (props, url, userEmail) => { 
    props.fetchEditorialTableData(
        url,
        props.filterData,
        1,
        "CSV",
        "",
        actionTypes[props.reportActionType].AD_CAMPAIGN_XLSX,
        userEmail,
        "independent",
        "",
        props.reportActionType
    );
}

export const decisionInsightsCSVData = async (props) => {
    props.fetchDecisionInsightData(
        url.decsionInsightAPI, 
        props.filterData, 
        1, 
        "CSV", 
        "", 
        actionTypes[props.reportActionType].DESCISION_INSIGHTS_CSV, 
        props.reportName, 
        props.reportActionType,
        props.userEmail
    );
}