import React from 'react';
import MultipleSelectDropDown from '../base/multiSelectDropdown';

const OrganizationPicker = (props) => {
  return (
	<MultipleSelectDropDown
    label='Account'
    placeholder='Account'
    options={props.organizations.map((org, index) => ({
      key: index,
      text: org?.name,
      value: org?.uuid,
      className: org && org.deleted_at ? 'deleted' : ''
    }))}
    
    onSearchChange={props.onSearchChange}
    value={props.value}
    onChange={props.onChange}
    onClick={props.onClick}
  />
  )
};

export default OrganizationPicker;
