import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";
import {
   getDisplayDate,
   getYesterdaysDate,
   getLastDays,
   downloadWithURL,
} from "../utilities/common";
import { siteValue } from "../constants";
import createCommonReducer from "./createCommonReducer";

const downloadSelData = {
   orgs: [],
	campaigns: [],
	order: [],
};

const filterData = {
	startDate: getDisplayDate(getLastDays(30, 0)),
	endDate: getDisplayDate(getYesterdaysDate(0)),
	appliedDate: "30",
	prevAppliedDate: "",
   organization: [],
	campaign: [],
	order: [],
	site: siteValue,
   excludeQACampaign: false,
   selTagsIN: [],
   selTagsEX: [],
   appliedDateFilter: "all",
   wp_startDate: "",
   wp_endDate: "",
   downloadDataResponse: "",
   reSendResponse: false,
   deleteResponse: false,
   requestedStatus: "all",
   requestedUser: "",
   isAutomated: false,
};

const downloadFetchData = {
	downladData: {},
	isLoading: false,
	isFilApplied: false,
   isShowMoreDisabled: false,
   pageCount: 2,
   isShowMore: false,
}

export const DownloadFilterDataReducer = (state = filterData, action) => {
   switch (action.type) {
      case actionTypes.SELDOWNLOADVALUES:
         return { ...state, [action.payload.key]: action.payload.value }
      case actionTypes.REQUESTDOWNLOADURL:
         downloadWithURL(action.payload.responseUrl, action.payload.fileName);
         return { ...state, downloadDataResponse: action.payload.responseUrl }
      case actionTypes.REQUESTRESENDURL:
         return { ...state, reSendResponse: true }
      case actionTypes.AGAINREQUESTRESENDURL:
         return { ...state, reSendResponse: false }
      case actionTypes.REQUESTDELETEURL:
         return { ...state, deleteResponse: true }
      case actionTypes.AGAINREQUESTDELETEURL:
         return { ...state, deleteResponse: false }
      case actionTypes.CLEARDOWNLOADFILTER:
         return {
            ...filterData,
         };
      default:
         return state;
   }
};

export const DownloadFetchDataReducer = (state = downloadFetchData, action) => {
   switch (action.type) {
      case actionTypes.APPLYDOWNLOADFILTER:
			return {
				...state,
				isLoading: true,
            downladData: {},
            isFilApplied: true,
            isShowMoreDisabled: false,
            pageCount: 2,
			};
      case actionTypes.FETCHDOWNLOADDATA:
         if (action.payload === null) {
            return {
               ...state,
               isLoading: false,
               downladData: [],
            };
         } else {
            return {
               ...state,
               downladData: action.payload,
               isLoading: false,
               isShowMoreDisabled: false,
            };
         }
      case actionTypes.SHOWMORELOADING:
         return { ...state, isLoading: true, isShowMore: true };
      case actionTypes.MOREDOWNLOADDATA:
         if (action.payload === null || _.isEmpty(action.payload)) {
            return {
               ...state,
               isLoading: false,
               isShowMoreDisabled: true,
            }
         } else {
            return {
               ...state,
               downladData: [...state.downladData, ...action.payload],
               isLoading: false,
               isShowMoreDisabled: false,
            }
         }
      case actionTypes.SETDOWNLOADPAGECOUNT:
         return { ...state, pageCount: state.pageCount + 1 };
      case actionTypes.ISLOADING:
         return { ...state, isLoading: true }
      default:
         return state;
   }
}

export const downloadOrgReducer = createCommonReducer(downloadSelData.orgs, 
   {
      FETCH: actionTypes.FETCHDOWNLOADORGS,
      ADD: actionTypes.ADDDOWNLOADORGS,
      CLEARSEARCH: actionTypes.CLEARDOWNLOADORGS,
      RESET: actionTypes.RESETDOWNLOADORGS,
   },
   "uuid"
);

export const downloadCampaignReducer = createCommonReducer(downloadSelData.campaigns, 
   {
      FETCH: actionTypes.FETCHDOWNLOADCAMPAIGNS,
      ADD: actionTypes.ADDDOWNLOADCAMPAIGNS,
      CLEARSEARCH: actionTypes.CLEARDOWNLOADCAMPAIGNS,
      RESET: actionTypes.RESETDOWNLOADCAMPAIGNS,
   },
   "uuid"
);

export const downloadOrderReducer = createCommonReducer(downloadSelData.order, 
   {
      FETCH: actionTypes.FETCHDOWNLOADORDER,
      ADD: actionTypes.ADDDOWNLOADORDER,
      CLEARSEARCH: actionTypes.CLEARDOWNLOADORDER,
      RESET: actionTypes.RESETDOWNLOADORDER,
   },
   "uuid"
);

export const downloadReqestedByReducer = (state = [], action) => {
   switch (action.type) {
     case actionTypes.FETCHDOWNLOADREQUESTEDBY:
       const orderArray = action.payload.map(item => item.id);
       const mappedObject = _.mapKeys(action.payload, "id");
       return orderArray.map(id => mappedObject[id]);
     case actionTypes.CLEARDOWNLOADREQUESTEDBY:
       return (state = []);
     default:
       return state;
   }
 };