import React, { useState } from "react";
import _ from "lodash";
import { Table } from "semantic-ui-react";
import {
    getDisplayDate,
    capitalize,
    numFormat,
    getCreativeType,
    getCreativeName,
    getCapital,
    capitalizeName
  } from "../../utilities/common";
import Link from "../hyperlinks/link";

const CampaignDetailsRow = (props) => {
  const [rdCampUuid, setRdCampUuid] = useState([]);
  const [adUuid, setAdUuid] = useState([]);
  const [liUuid, setLiUuid] = useState([]);

  const adExpand = (id) => {
    setAdUuid(adUuid.concat(id));
  };

  const adCompress = (id) => {
    const arr = adUuid.filter(function (item) {
      return item !== id;
    });
    setAdUuid(arr);
    setLiUuid([]);
  };

  const liExpand = (id) => {
    setLiUuid(liUuid.concat(id));
  };

  const liCompress = (id) => {
    const arr = liUuid.filter(function (i) {
      return i !== id;
    });
    setLiUuid(arr);
  };

  const rdCampExpand = (id) => {
		setRdCampUuid(rdCampUuid.concat(id));
	};

	const rdCampCompress = (id) => {
		const arr = rdCampUuid.filter(function (item) {
			return item !== id;
		});
		setRdCampUuid(arr);
		setAdUuid([]);
		setLiUuid([]);
	};

  const createRow = (data) => {
    if (data.length > 0) {
      let tableRow = [];
      // let tableTotalCell = [];
    //   let props.conditionChkboxAppFilter = props.allFilterReportData.selCustomRangeCheckBox &&  props.allFilterReportData.appliedDate === "Custom Date Range";
      data.forEach((camp) => {
        let classes = {};
        let adTableCell = [];
        let liTableCell = [];
        let cTableCell = [];
        let ctTableCell = [];
        // let deletedTitle = "";
        let rdTableCell = [];
        classes.rdUuid = camp.uuid;

        // if (camp.roadblock === false) {
					if (rdCampUuid.indexOf(classes.rdUuid) !== -1) {
						rdTableCell.push(
							<Table.Cell>
								<i
									className="material-icons"
									onClick={() => rdCampCompress(camp.uuid)}
								>
									remove
								</i>
							</Table.Cell>
						);
					} else if (rdCampUuid.indexOf(classes.rdUuid) === -1) {
						rdTableCell.push(
							<Table.Cell>
								<i
									className="material-icons"
									onClick={() => rdCampExpand(camp.uuid)}
								>
									add
								</i>
							</Table.Cell>
						);
					}
          
					rdTableCell.push(<Table.Cell title={capitalizeName(camp.status)}>{capitalizeName(camp.status)}</Table.Cell>);
					rdTableCell.push(
						<Table.Cell title={camp.name} className="blogs_link">
							{camp.name ? (
								<Link
									type={"campaigns"}
									urlParam={camp.uuid}
									refValue={camp.name}
								></Link>
							) : (
								""
							)}
						</Table.Cell>
					);
					rdTableCell.push(<Table.Cell title={getDisplayDate(camp.start_date)}>{getDisplayDate(camp.start_date)}</Table.Cell>);
					rdTableCell.push(<Table.Cell title={getDisplayDate(camp.end_date)}>{getDisplayDate(camp.end_date)}</Table.Cell>);
					rdTableCell.push(<Table.Cell></Table.Cell>);
					rdTableCell.push(<Table.Cell></Table.Cell>);
					rdTableCell.push(<Table.Cell></Table.Cell>);
					rdTableCell.push(<Table.Cell></Table.Cell>);

					props.conditionChkboxAppFilter ? (
						rdTableCell.push(
							<Table.Cell title={numFormat(camp.impressions)} textAlign="center">
							{numFormat(camp.impressions)}
							</Table.Cell>, 
							<Table.Cell title={numFormat(camp.prev_impressions)} textAlign="center">
								{numFormat(camp.prev_impressions)}	
						</Table.Cell>
						) ):(
						rdTableCell.push(
								<Table.Cell title={numFormat(camp.impressions)} textAlign="right">
									{numFormat(camp.impressions)}
								</Table.Cell>,
							)
						)

            props.reportName === "roadblock" &&
            (props.conditionChkboxAppFilter ? (
              rdTableCell.push(
                <Table.Cell textAlign="right">
                {numFormat(camp.unique_page_impressions)}
                </Table.Cell>, 
                <Table.Cell textAlign="right">
                  {numFormat(camp.prev_unique_page_impressions)}	
              </Table.Cell>
              ) ):(
              rdTableCell.push(
                  <Table.Cell textAlign="right">
                    {numFormat(camp.unique_page_impressions)}
                  </Table.Cell>,
                )
              )
            )

					props.conditionChkboxAppFilter ? (
						rdTableCell.push(
							<Table.Cell textAlign="center" title={numFormat(camp.clicks)}>
							{numFormat(camp.clicks)}
							</Table.Cell>, 
							<Table.Cell title={numFormat(camp.prev_clicks)} textAlign="center">
								{numFormat(camp.prev_clicks)}	
						</Table.Cell>
						) ):(
						rdTableCell.push(
								<Table.Cell textAlign="right" title={numFormat(camp.clicks)}>
									{numFormat(camp.clicks)}
								</Table.Cell>,
							)
						)
					rdTableCell.push(
						<Table.Cell textAlign="right" title={numFormat(camp.ctr)}>{camp.ctr}</Table.Cell>
					);
					if (rdCampUuid.indexOf(classes.rdUuid) !== -1) {
						classes.campSelected = "rdCamp-selected";
					} else if (rdCampUuid.indexOf(classes.rdUuid) === -1) {
						classes.campSelected = "rdCamp";
					}
					tableRow.push(
						<Table.Row
							className={classes.campSelected}
							children={rdTableCell}
							onDoubleClick={() => {
								if (rdCampUuid.indexOf(classes.rdUuid) === -1) {
									rdCampExpand(camp.uuid);
								} else if (rdCampUuid.indexOf(classes.rdUuid) !== -1) {
									rdCampCompress(camp.uuid);
								}
							}}
						/>
					);
					rdTableCell = [];
				// }

        if (camp.runs && camp.runs.length > 0) {
          // if (camp.roadblock === false) {
            camp.runs.sort((a, b) => b.roadblock - a.roadblock);
          // }
          camp.runs.forEach((e) => {
            classes.adChildren = "";
            classes.adVisible = "";
            classes.uuid = e.uuid;

            // if (camp.roadblock === false) {
							if (
								rdCampUuid.indexOf(classes.rdUuid) !== -1 &&
								e.roadblock === (props.reportName === "roadblock" ? true : false)
							) {
								classes.adChildren = "ad-children";
								classes.adVisible = "";
							} else if (
								rdCampUuid.indexOf(classes.rdUuid) === -1 &&
								e.roadblock === (props.reportName === "roadblock" ? true : false)
							) {
								classes.adVisible = "ad-hide";
								classes.adChildren = "";
              }
						// }

            if (e.deleted_at !== null) {
              classes.deleted = "deleted-row";
              //deletedTitle = `Archived/Inactive since ${getDisplayDate(e.deleted_at)}`;
            }

            if (adUuid.indexOf(classes.uuid) !== -1) {
              adTableCell.push(
                <Table.Cell>
                  <i
                    className="material-icons"
                    onClick={() => adCompress(e.uuid)}
                  >
                    remove
                  </i>
                </Table.Cell>
              );
            } else if (adUuid.indexOf(classes.uuid) === -1) {
              adTableCell.push(
                <Table.Cell>
                  <i
                    className="material-icons"
                    onClick={() => adExpand(e.uuid)}
                  >
                    add
                  </i>
                </Table.Cell>
              );
            }
            adTableCell.push(
              <Table.Cell title={capitalize(e.status)}>
                {capitalize(e.status)}
              </Table.Cell>
            );
            adTableCell.push(
              <Table.Cell title={e.name} className="ad-order-name blogs_link">
                {e.name ? (
                  <Link type={props.reportName !== "sidebar" ? "ads" : "sidebars"} urlParam={e.uuid} refValue={e.name}></Link>
                ) : (
                  ""
                )}
              </Table.Cell>
            );
            adTableCell.push(
              <Table.Cell title={getDisplayDate(e.start_date)}>{getDisplayDate(e.start_date)}</Table.Cell>
            );
            adTableCell.push(
              <Table.Cell title={getDisplayDate(e.end_date)}>{getDisplayDate(e.end_date)}</Table.Cell>
            );
            adTableCell.push(<Table.Cell title={capitalize(e.location)}>{capitalize(e.location)}</Table.Cell>);
            adTableCell.push(<Table.Cell></Table.Cell>);
            adTableCell.push(
              <Table.Cell title={e.media_type === "html" ? getCapital(e.media_type): capitalize(e.media_type)}>{e.media_type === "html" ? getCapital(e.media_type): capitalize(e.media_type)}</Table.Cell>
            );
            adTableCell.push(<Table.Cell></Table.Cell>);
            props.conditionChkboxAppFilter
              ? adTableCell.push(
                  <Table.Cell title={numFormat(e.impressions)}textAlign="center">
                    {numFormat(e.impressions)}
                  </Table.Cell>,
                  <Table.Cell title={numFormat(e.prev_impressions)} textAlign="center">
                    {numFormat(e.prev_impressions)}
                  </Table.Cell>
                )
              : adTableCell.push(
                  <Table.Cell title={numFormat(e.impressions)} textAlign="right">
                    {numFormat(e.impressions)}
                  </Table.Cell>
                );
            // for roadblock
            props.reportName === "roadblock" &&
              (
                props.conditionChkboxAppFilter
                ? adTableCell.push(
                    <Table.Cell textAlign="right">{numFormat(e.unique_page_impressions)}</Table.Cell>,
                    <Table.Cell textAlign="right">{numFormat(e.prev_unique_page_impressions)}</Table.Cell>
                  )
                : adTableCell.push(<Table.Cell textAlign="right">{numFormat(e.unique_page_impressions)}</Table.Cell>)
              );
            props.conditionChkboxAppFilter
              ? adTableCell.push(
                  <Table.Cell title={numFormat(e.clicks)} textAlign="center">
                    {numFormat(e.clicks)}
                  </Table.Cell>,
                  <Table.Cell title={numFormat(e.prev_clicks)} textAlign="center">
                    {numFormat(e.prev_clicks)}
                  </Table.Cell>
                )
              : adTableCell.push(
                  <Table.Cell title={numFormat(e.clicks)} textAlign="right">
                    {numFormat(e.clicks)}
                  </Table.Cell>
                );
            adTableCell.push(
              <Table.Cell textAlign="right" title={e.ctr}>{e.ctr}</Table.Cell>
            );
            if (adUuid.indexOf(classes.uuid) !== -1) {
              classes.adSelected = "ad-selected";
            } else if (adUuid.indexOf(classes.uuid) === -1) {
              classes.adSelected = "";
            }
            tableRow.push(
              <Table.Row
                className={`${classes.adChildren} ${classes.adVisible} ${classes.adSelected}`}
                children={adTableCell}
                onDoubleClick={() => {
                  if (adUuid.indexOf(e.uuid) === -1) {
                    adExpand(e.uuid);
                  } else if (adUuid.indexOf(e.uuid) !== -1) {
                    adCompress(e.uuid);
                  }
                }}
              />
            );
            adTableCell = [];
            if (e.line_items && e.line_items.length > 0) {
              if (adUuid.indexOf(classes.uuid) !== -1) {
                classes.liChildren = "li-children";
                classes.liVisible = "";
              } else if (adUuid.indexOf(classes.uuid) === -1) {
                classes.liVisible = "li-hide";
                classes.liChildren = "";
              }
              e.line_items.forEach((item) => {
                classes.liUuid = item.uuid;
                liTableCell.push(<Table.Cell></Table.Cell>);
                
                if(props.reportName !== "sidebar") {
                  if (liUuid.indexOf(classes.liUuid) !== -1) {
                    liTableCell.push(
                      <Table.Cell>
                        <i
                          className="material-icons"
                          onClick={() => liCompress(item.uuid)}
                        >
                          remove
                        </i>
                      </Table.Cell>
                    );
                  } else if (liUuid.indexOf(classes.liUuid) === -1) {
                    liTableCell.push(
                      <Table.Cell>
                        <i
                          className="material-icons"
                          onClick={() => liExpand(item.uuid)}
                        >
                          add
                        </i>
                      </Table.Cell>
                    );
                  }
                } else {
                  liTableCell.push(<Table.Cell></Table.Cell>);
                }
                
                liTableCell.push(<Table.Cell></Table.Cell>);
                liTableCell.push(<Table.Cell title={getDisplayDate(e.start_date)}>{getDisplayDate(item.start_date !== null ? item.start_date : e.start_date)}</Table.Cell>);
                liTableCell.push(<Table.Cell title={getDisplayDate(e.end_date)}>{getDisplayDate(item.end_date !== null ? item.end_date : e.end_date)}</Table.Cell>);
                liTableCell.push(<Table.Cell></Table.Cell>);
                if(props.reportName === "sidebar") {
                  liTableCell.push(<Table.Cell title={item.title}>{item.title}</Table.Cell>);
                } else {
                  liTableCell.push(
                    <Table.Cell title={item.name}>{item.name}</Table.Cell>
                  );
                }
                
                if(props.reportName === "sidebar") {
                  liTableCell.push(<Table.Cell title={item.widget_type}>{item.widget_type}</Table.Cell>);
                } else {
                  liTableCell.push(<Table.Cell></Table.Cell>);
                }
                
                liTableCell.push(<Table.Cell></Table.Cell>);
                props.conditionChkboxAppFilter
                  ? liTableCell.push(
                      <Table.Cell textAlign="center" title={numFormat(item.impressions)}>
                        {numFormat(item.impressions)}
                      </Table.Cell>,
                      <Table.Cell textAlign="center" title={numFormat(item.prev_impressions)}>
                        {numFormat(item.prev_impressions)}
                      </Table.Cell>
                    )
                  : liTableCell.push(
                      <Table.Cell title={numFormat(item.impressions)} textAlign="right">
                        {numFormat(item.impressions)}
                      </Table.Cell>
                    );
                props.reportName === "roadblock" &&
                (
                  props.conditionChkboxAppFilter
                  ? liTableCell.push(
                    <Table.Cell textAlign="right"/>,
                    <Table.Cell textAlign="right"/>
                    )
                  : liTableCell.push(<Table.Cell textAlign="right"/>)
                );
                props.conditionChkboxAppFilter
                  ? liTableCell.push(
                      <Table.Cell textAlign="center" title={numFormat(item.clicks)}>
                        {numFormat(item.clicks)}
                      </Table.Cell>,
                      <Table.Cell textAlign="center" title={numFormat(item.prev_clicks)}>
                        {numFormat(item.prev_clicks)}
                      </Table.Cell>
                    )
                  : liTableCell.push(
                      <Table.Cell textAlign="right" title={numFormat(item.clicks)}>
                        {numFormat(item.clicks)}
                      </Table.Cell>
                    );
                liTableCell.push(
                  <Table.Cell textAlign="right" title={item.ctr}>
                    {item.ctr}
                  </Table.Cell>
                );
                if (e.location === "newsletter") {
                  item.creatives.forEach((ele) => {
                    ctTableCell.push(<Table.Cell></Table.Cell>);
                    ctTableCell.push(<Table.Cell></Table.Cell>);
                    ctTableCell.push(<Table.Cell></Table.Cell>);
                    ctTableCell.push(<Table.Cell></Table.Cell>);
                    ctTableCell.push(<Table.Cell></Table.Cell>);
                    ctTableCell.push(<Table.Cell></Table.Cell>);
                    ctTableCell.push(
                      <Table.Cell title={ele.text}>{ele.text}</Table.Cell>
                    );
                    ctTableCell.push(<Table.Cell>Text</Table.Cell>);
                    ctTableCell.push(<Table.Cell></Table.Cell>);

                    props.conditionChkboxAppFilter
                      ? ctTableCell.push(
                          <Table.Cell textAlign="center" title={numFormat(ele.impressions)}>
                            {numFormat(ele.impressions)}
                          </Table.Cell>,
                          <Table.Cell textAlign="center" title={numFormat(ele.prev_impressions)}>
                            {numFormat(ele.prev_impressions)}
                          </Table.Cell>
                        )
                      : ctTableCell.push(
                          <Table.Cell textAlign="right" title={numFormat(ele.impressions)}>
                            {numFormat(ele.impressions)}
                          </Table.Cell>
                        );
                    props.reportName === "roadblock" &&
                    (
                      props.conditionChkboxAppFilter
                      ? ctTableCell.push(
                        <Table.Cell textAlign="right"/>,
                        <Table.Cell textAlign="right"/>
                        )
                      : ctTableCell.push(<Table.Cell textAlign="right"/>)
                    );
                    props.conditionChkboxAppFilter
                      ? ctTableCell.push(
                          <Table.Cell textAlign="center" title={numFormat(ele.clicks)}>
                            {numFormat(ele.clicks)}
                          </Table.Cell>,
                          <Table.Cell textAlign="center" title={numFormat(ele.prev_clicks)}>
                            {numFormat(ele.prev_clicks)}
                          </Table.Cell>
                        )
                      : ctTableCell.push(
                          <Table.Cell textAlign="right" title={numFormat(ele.clicks)}>
                            {numFormat(ele.clicks)}
                          </Table.Cell>
                        );
                    ctTableCell.push(
                      <Table.Cell textAlign="right" title={ele.ctr}>
                        {ele.ctr}
                      </Table.Cell>
                    );
                  });
                }
                if (liUuid.indexOf(classes.liUuid) !== -1) {
                  classes.children = "li-children-selected";
                } else if (liUuid.indexOf(classes.liUuid) === -1) {
                  classes.children = "li-children";
                }
                tableRow.push(
                  <Table.Row
                    className={`${classes.liChildren} ${classes.liVisible}`}
                    children={liTableCell}
                    onDoubleClick={() => {
                      if (liUuid.indexOf(item.uuid) === -1) {
                        liExpand(item.uuid);
                      } else if (liUuid.indexOf(item.uuid) !== -1) {
                        liCompress(item.uuid);
                      }
                    }}
                  />
                );
                liTableCell = [];
                if (item.creatives && item.creatives.length > 0) {
                  if (liUuid.indexOf(classes.liUuid) !== -1) {
                    classes.cChildren = "c-children";
                    classes.cVisible = "";
                    if (e.location === "newsletter") {
                      tableRow.push(
                        <Table.Row
                          className={`${classes.liChildren} ${classes.liVisible}`}
                          children={ctTableCell}
                        />
                      );
                    }
                  } else if (liUuid.indexOf(classes.liUuid) === -1) {
                    classes.cVisible = "c-hide";
                  }
                  ctTableCell = [];
                  if (item.creatives) {
                    item.creatives.forEach((c) => {
                      if (e.location === "display" || e.location === "bookend") {
                        if (c.impressions !== 0 || c.clicks !== 0) {
                          cTableCell.push(<Table.Cell></Table.Cell>);
                          cTableCell.push(<Table.Cell></Table.Cell>);
                          cTableCell.push(<Table.Cell></Table.Cell>);
                          cTableCell.push(<Table.Cell></Table.Cell>);
                          cTableCell.push(<Table.Cell></Table.Cell>);
                          cTableCell.push(<Table.Cell></Table.Cell>);
                          cTableCell.push(
                            <Table.Cell
                              title={getCreativeName(c.name, c.text)}
                              className="custom-column-large-name"
                            >
                              {getCreativeName(c.name, c.text)}
                            </Table.Cell>
                          );
                          cTableCell.push(<Table.Cell></Table.Cell>);
                          cTableCell.push(<Table.Cell>  {`${c.wp_image_width}x${c.wp_image_height}`}</Table.Cell>);

                          props.conditionChkboxAppFilter
                            ? cTableCell.push(
                                <Table.Cell textAlign="center" title={numFormat(c.impressions)}>
                                  {numFormat(c.impressions)}
                                </Table.Cell>,
                                <Table.Cell textAlign="center" title={numFormat(c.prev_impressions)}>
                                  {numFormat(c.prev_impressions)}
                                </Table.Cell>
                              )
                            : cTableCell.push(
                                <Table.Cell textAlign="right" title={numFormat(c.impressions)}>
                                  {numFormat(c.impressions)}
                                </Table.Cell>
                              );
                            props.reportName === "roadblock" &&
                            (
                              props.conditionChkboxAppFilter
                              ? cTableCell.push(
                                  <Table.Cell textAlign="right"/>,
                                  <Table.Cell textAlign="right"/>
                                )
                              : cTableCell.push(<Table.Cell textAlign="right"/>)
                            );
                          props.conditionChkboxAppFilter
                            ? cTableCell.push(
                                <Table.Cell textAlign="center" title={numFormat(c.clicks)}>
                                  {numFormat(c.clicks)}
                                </Table.Cell>,
                                <Table.Cell textAlign="center" title={numFormat(c.prev_clicks)}>
                                  {numFormat(c.prev_clicks)}
                                </Table.Cell>
                              )
                            : cTableCell.push(
                                <Table.Cell textAlign="right" title={numFormat(c.clicks)}>
                                  {numFormat(c.clicks)}
                                </Table.Cell>
                              );
                          cTableCell.push(
                            <Table.Cell textAlign="right" title={c.ctr}>
                              {c.ctr}
                            </Table.Cell>
                          );
                          tableRow.push(
                            <Table.Row
                              className={Object.values(classes).join(" ")}
                              children={cTableCell}
                            />
                          );
                          cTableCell = [];
                        }
                        const cArr = ["header", "sidebar", "bookend", "iab"];
                        cArr.forEach((value) => {
                          if (
                            c[value].name ||
                            (c[value].htmls !== null &&
                              !_.isEmpty(c[value].htmls))
                          ) {
                            let cRowSpan = 1;
                            if (
                              c[value].htmls !== null &&
                              !_.isEmpty(c[value].htmls)
                            ) {
                              cRowSpan = c[value].htmls.length;
                            } else if (c[value].images.length > 0) {
                              cRowSpan = c[value].images.length;
                            }
                            cTableCell.push(
                              <Table.Cell rowSpan={cRowSpan}></Table.Cell>
                            );
                            cTableCell.push(
                              <Table.Cell rowSpan={cRowSpan}></Table.Cell>
                            );
                            cTableCell.push(
                              <Table.Cell rowSpan={cRowSpan}></Table.Cell>
                            );
                            cTableCell.push(
                              <Table.Cell rowSpan={cRowSpan}></Table.Cell>
                            );
                            cTableCell.push(
                              <Table.Cell rowSpan={cRowSpan}></Table.Cell>
                            );
                            cTableCell.push(
                              <Table.Cell rowSpan={cRowSpan}></Table.Cell>
                            );
                            if (e.media_type === "html") {
                              cTableCell.push(
                                <Table.Cell
                                  rowSpan={cRowSpan}
                                  className="custom-column-large-name"
                                  title={getCreativeType(value)}
                                >
                                  {getCreativeType(value)}
                                </Table.Cell>
                              );
                              cTableCell.push(
                                <Table.Cell rowSpan={cRowSpan}></Table.Cell>
                              );
                              c[value].htmls.forEach((html, i) => {
                                cTableCell.push(
                                  <Table.Cell>{`${html.width}x${html.height}`}</Table.Cell>
                                );
                                if (i > 0) {
                                  props.conditionChkboxAppFilter
                                    ? cTableCell.push(
                                        <Table.Cell textAlign="center" title={numFormat(html.impressions)}>
                                          {numFormat(html.impressions)}
                                        </Table.Cell>,
                                        <Table.Cell textAlign="center" title={numFormat(html.prev_impressions)}>
                                          {numFormat(html.prev_impressions)}
                                        </Table.Cell>
                                      )
                                    : cTableCell.push(
                                        <Table.Cell textAlign="right" title={numFormat(html.impressions)}>
                                          {numFormat(html.impressions)}
                                        </Table.Cell>
                                      );
                                  props.reportName === "roadblock" &&
                                  (
                                    props.conditionChkboxAppFilter
                                    ? cTableCell.push(
                                      <Table.Cell textAlign="right"/>,
                                      <Table.Cell textAlign="right"/>
                                      )
                                    : cTableCell.push(<Table.Cell textAlign="right"/>)
                                  );
                                  props.conditionChkboxAppFilter
                                    ? cTableCell.push(
                                        <Table.Cell textAlign="center" title={numFormat(html.clicks)}>
                                          {numFormat(html.clicks)}
                                        </Table.Cell>,
                                        <Table.Cell textAlign="center" title={numFormat(html.prev_clicks)}>
                                          {numFormat(html.prev_clicks)}
                                        </Table.Cell>
                                      )
                                    : cTableCell.push(
                                        <Table.Cell textAlign="right" title={numFormat(html.clicks)}>
                                          {numFormat(html.clicks)}
                                        </Table.Cell>
                                      );
                                  cTableCell.push(
                                    <Table.Cell textAlign="right" title={html.ctr}>
                                      {html.ctr}
                                    </Table.Cell>
                                  );
                                  tableRow.push(
                                    <Table.Row
                                      className={Object.values(classes).join(
                                        " "
                                      )}
                                      children={cTableCell}
                                    />
                                  );
                                  cTableCell = [];
                                } else {
                                  props.conditionChkboxAppFilter
                                    ? cTableCell.push(
                                        <Table.Cell textAlign="center" title={numFormat(html.impressions)}>
                                          {numFormat(html.impressions)}
                                        </Table.Cell>,
                                        <Table.Cell textAlign="center" title={numFormat(html.prev_impressions)}>
                                          {numFormat(html.prev_impressions)}
                                        </Table.Cell>
                                      )
                                    : cTableCell.push(
                                        <Table.Cell textAlign="right" title={numFormat(html.impressions)}>
                                          {numFormat(html.impressions)}
                                        </Table.Cell>
                                      );
                                  props.reportName === "roadblock" &&
                                  (
                                    props.conditionChkboxAppFilter
                                    ? cTableCell.push(
                                      <Table.Cell textAlign="right"/>,
                                      <Table.Cell textAlign="right"/>
                                      )
                                    : cTableCell.push(<Table.Cell textAlign="right"/>)
                                  );
                                  props.conditionChkboxAppFilter
                                    ? cTableCell.push(
                                        <Table.Cell textAlign="center" title={numFormat(html.clicks)}>
                                          {numFormat(html.clicks)}
                                        </Table.Cell>,
                                        <Table.Cell textAlign="center" title={numFormat(html.prev_clicks)}>
                                          {numFormat(html.prev_clicks)}
                                        </Table.Cell>
                                      )
                                    : cTableCell.push(
                                        <Table.Cell textAlign="right" title={numFormat(html.clicks)}>
                                          {numFormat(html.clicks)}
                                        </Table.Cell>
                                      );
                                  cTableCell.push(
                                    <Table.Cell textAlign="right" title={html.ctr}>
                                      {html.ctr}
                                    </Table.Cell>
                                  );
                                  tableRow.push(
                                    <Table.Row
                                      className={Object.values(classes).join(
                                        " "
                                      )}
                                      children={cTableCell}
                                    />
                                  );
                                  cTableCell = [];
                                }
                              });
                            } else {
                              cTableCell.push(
                                <Table.Cell
                                  rowSpan={cRowSpan}
                                  className="custom-column-large-name"
                                  title={c[value].name}
                                >
                                  {c[value].name}
                                </Table.Cell>
                              );
                              cTableCell.push(
                                <Table.Cell rowSpan={cRowSpan}></Table.Cell>
                              );
                              c[value].images.forEach((image, idx) => {
                                cTableCell.push(
                                  <Table.Cell>{`${image.image.width}x${image.image.height}`}</Table.Cell>
                                );
                                if (idx > 0) {
                                  props.conditionChkboxAppFilter
                                    ? cTableCell.push(
                                        <Table.Cell textAlign="center" title={numFormat(image.impressions)}>
                                          {numFormat(image.impressions)}
                                        </Table.Cell>,
                                        <Table.Cell textAlign="center" title={numFormat(image.prev_impressions)}>
                                          {numFormat(image.prev_impressions)}
                                        </Table.Cell>
                                      )
                                    : cTableCell.push(
                                        <Table.Cell textAlign="right" title={numFormat(image.impressions)}>
                                          {numFormat(image.impressions)}
                                        </Table.Cell>
                                      );
                                  props.reportName === "roadblock" &&
                                  (
                                    props.conditionChkboxAppFilter
                                    ? cTableCell.push(
                                      <Table.Cell textAlign="right"/>,
                                      <Table.Cell textAlign="right"/>
                                      )
                                    : cTableCell.push(<Table.Cell textAlign="right"/>)
                                  );
                                  props.conditionChkboxAppFilter
                                    ? cTableCell.push(
                                        <Table.Cell textAlign="center" title={numFormat(image.clicks)}>
                                          {numFormat(image.clicks)}
                                        </Table.Cell>,
                                        <Table.Cell textAlign="center" title={numFormat(image.prev_clicks)}>
                                          {numFormat(image.prev_clicks)}
                                        </Table.Cell>
                                      )
                                    : cTableCell.push(
                                        <Table.Cell textAlign="right" title={numFormat(image.clicks)}>
                                          {numFormat(image.clicks)}
                                        </Table.Cell>
                                      );
                                  cTableCell.push(
                                    <Table.Cell textAlign="right" title={image.ctr}>
                                      {image.ctr}
                                    </Table.Cell>
                                  );
                                  tableRow.push(
                                    <Table.Row
                                      className={Object.values(classes).join(
                                        " "
                                      )}
                                      children={cTableCell}
                                    />
                                  );
                                  cTableCell = [];
                                } else {
                                  props.conditionChkboxAppFilter
                                    ? cTableCell.push(
                                        <Table.Cell textAlign="center" title={numFormat(image.impressions)}>
                                          {numFormat(image.impressions)}
                                        </Table.Cell>,
                                        <Table.Cell textAlign="center" title={numFormat(image.prev_impressions)}>
                                          {numFormat(image.prev_impressions)}
                                        </Table.Cell>
                                      )
                                    : cTableCell.push(
                                        <Table.Cell textAlign="right" title={numFormat(image.impressions)}>
                                          {numFormat(image.impressions)}
                                        </Table.Cell>
                                      );
                                  props.reportName === "roadblock" &&
                                  (
                                    props.conditionChkboxAppFilter
                                    ? cTableCell.push(
                                      <Table.Cell textAlign="right"/>,
                                      <Table.Cell textAlign="right"/>
                                      )
                                    : cTableCell.push(<Table.Cell textAlign="right"/>)
                                  );
                                  props.conditionChkboxAppFilter
                                    ? cTableCell.push(
                                        <Table.Cell textAlign="center" title={numFormat(image.clicks)}>
                                          {numFormat(image.clicks)}
                                        </Table.Cell>,
                                        <Table.Cell textAlign="center" title={numFormat(image.prev_clicks)}>
                                          {image.prev_clicks}
                                        </Table.Cell>
                                      )
                                    : cTableCell.push(
                                        <Table.Cell textAlign="right" title={numFormat(image.clicks)}>
                                          {numFormat(image.clicks)}
                                        </Table.Cell>
                                      );
                                  cTableCell.push(
                                    <Table.Cell textAlign="right" title={(image.ctr)}>
                                      {image.ctr}
                                    </Table.Cell>
                                  );
                                  tableRow.push(
                                    <Table.Row
                                      className={Object.values(classes).join(
                                        " "
                                      )}
                                      children={cTableCell}
                                    />
                                  );
                                  cTableCell = [];
                                }
                              });
                            }
                          }
                        });
                      } else {
                        cTableCell.push(<Table.Cell></Table.Cell>);
                        cTableCell.push(<Table.Cell></Table.Cell>);
                        cTableCell.push(<Table.Cell></Table.Cell>);
                        cTableCell.push(<Table.Cell></Table.Cell>);
                        cTableCell.push(<Table.Cell></Table.Cell>);
                        cTableCell.push(<Table.Cell></Table.Cell>);
                        cTableCell.push(
                          <Table.Cell
                            title={getCreativeName(c.name, c.text)}
                            className="custom-column-large-name"
                          >
                            {getCreativeName(c.name, c.text)}
                          </Table.Cell>
                        );
                        cTableCell.push(
                          <Table.Cell title={e.location === "newsletter" && "Display"}>
                            {e.location === "newsletter" ? "Display" : capitalize(e.location)}
                          </Table.Cell>
                        );
                        (e.location === "sidebar" || e.media_type === "text") ?
                          cTableCell.push(<Table.Cell></Table.Cell>)
                        :
                          cTableCell.push(
                            <Table.Cell title={e.location === "newsletter" && `${c.wp_image_width}x${c.wp_image_height}`}>
                              {
                                `${c.image.width}x${c.image.height}`}
                            </Table.Cell>
                          );

                        props.conditionChkboxAppFilter
                          ? cTableCell.push(
                              <Table.Cell textAlign="center" title={e.location === "newsletter"? numFormat(c.newsletter_display_impression_summary.impressions)
                              : numFormat(c.impressions)}>
                                {e.location === "newsletter"
                                  ? numFormat(
                                      c.newsletter_display_impression_summary
                                        .impressions
                                    )
                                  : numFormat(c.impressions)}
                              </Table.Cell>,
                              <Table.Cell textAlign="center" title={e.location === "newsletter"? numFormat(c.newsletter_display_impression_summary.prev_impressions)
                              : numFormat(c.prev_impressions)}>
                                {e.location === "newsletter"
                                  ? numFormat(
                                      c.newsletter_display_impression_summary
                                        .prev_impressions
                                    )
                                  : numFormat(c.prev_impressions)}
                              </Table.Cell>
                            )
                          : cTableCell.push(
                              <Table.Cell textAlign="right" title={e.location === "newsletter"? numFormat(c.newsletter_display_impression_summary.impressions)
                              : numFormat(c.impressions)}>
                                {e.location === "newsletter"
                                  ? numFormat(
                                      c.newsletter_display_impression_summary
                                        .impressions
                                    )
                                  : numFormat(c.impressions)}
                              </Table.Cell>
                            );
                        // for roadblock
                        props.reportName === "roadblock" &&
                        (props.conditionChkboxAppFilter
                          ? cTableCell.push(
                              <Table.Cell textAlign="right">
                                {e.location === "newsletter"
                                  ?  c.newsletter_display_impression_summary
                                  .unique_page_impressions === 0 ? "" : numFormat(
                                      c.newsletter_display_impression_summary
                                        .unique_page_impressions
                                    )
                                  : numFormat(c.unique_page_impressions)}
                              </Table.Cell>,
                              <Table.Cell textAlign="right">
                                {e.location === "newsletter"
                                  ? c.newsletter_display_impression_summary
                                  .prev_unique_page_impressions === 0 ? "" : numFormat(
                                      c.newsletter_display_impression_summary
                                        .prev_unique_page_impressions
                                    )
                                  : numFormat(c.prev_unique_page_impressions)}
                              </Table.Cell>
                            )
                          : cTableCell.push(
                              <Table.Cell textAlign="right"/>
                          ));

                        props.conditionChkboxAppFilter
                          ? cTableCell.push(
                              <Table.Cell textAlign="center" title={e.location === "newsletter"? numFormat( c.newsletter_display_impression_summary.clicks)
                              : numFormat(c.clicks)}>
                                {e.location === "newsletter"
                                  ? numFormat(
                                      c.newsletter_display_impression_summary
                                        .clicks
                                    )
                                  : numFormat(c.clicks)}
                              </Table.Cell>,
                              <Table.Cell textAlign="center" title={e.location === "newsletter"? numFormat(c.newsletter_display_impression_summary.prev_clicks)
                              : numFormat(c.prev_clicks)}>
                                {e.location === "newsletter"
                                  ? numFormat(
                                      c.newsletter_display_impression_summary
                                        .prev_clicks
                                    )
                                  : numFormat(c.prev_clicks)}
                              </Table.Cell>
                            )
                          : cTableCell.push(
                              <Table.Cell textAlign="right" title={e.location === "newsletter"? numFormat(c.newsletter_display_impression_summary.clicks)
                              : numFormat(c.clicks)}>
                                {e.location === "newsletter"
                                  ? numFormat(
                                      c.newsletter_display_impression_summary
                                        .clicks
                                    )
                                  : numFormat(c.clicks)}
                              </Table.Cell>
                            );
                        cTableCell.push(
                          <Table.Cell textAlign="right" title={e.location === "newsletter"? (c.newsletter_display_impression_summary.ctr): (c.ctr)}>
                            {e.location === "newsletter"
                              ? (
                                  c.newsletter_display_impression_summary.ctr
                                )
                              : (c.ctr)}
                          </Table.Cell>
                        );
                        tableRow.push(
                          <Table.Row
                            className={Object.values(classes).join(" ")}
                            children={cTableCell}
                          />
                        );
                        cTableCell = [];
                      }
                    });
                  }
                }
              });
            }
          });
        }
      });
      return tableRow;
    }
  };
  return <Table.Body>{createRow(props.data)}</Table.Body>;
};

export default CampaignDetailsRow;