import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { selectValue } from "../../../actions";
import { EXCLUDE_INTERNAL_CAMPAIGN, EXLUDE_QA_CAMPAIGN } from "../../config/const";
import InnerToggles from "../../common/InnerToggles";

const CampaignToggleComponent = (props) => {

    const toggleOptions = [
        { label: EXCLUDE_INTERNAL_CAMPAIGN, key: 'excludeInternalCampaign' },
        { label: EXLUDE_QA_CAMPAIGN, key: 'excludeQACampaign' },
      ];

    return (
        <div className="field">
            <div className="ad-toggle-label">
                <p>Campaign Filters</p>
            </div>
            <InnerToggles
                {...props}
                toggleData={toggleOptions}
                applyClass="ad-child-toggle"
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {};
};

export default compose(connect(mapStateToProps, {
    selectValue,
}))(CampaignToggleComponent);