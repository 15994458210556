import React, { useEffect, useState } from "react";
//import React, { Component } from 'react';
import { Table, Checkbox } from "semantic-ui-react";
import "../../css/common.css";
import { connect } from "react-redux";
import { updateLeadValidation, fetchLeadEngSummary, fetchClientLeadEngs } from "../../actions";
// import { url } from "../../api/urlHelper";
import {
   getDateEng,
   getDomainStatus,
   getOrderInCampaigns,
   numFormat,
} from "../../utilities/common";
import Link from "../hyperlinks/link";
import MultiLinks from "../hyperlinks/multiLinks";
import HocDrawer from "../common/hocDrawer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ClientLeadDetailCampaignDetails from "./clientLeadDetailCampaignDetails";
import LeadValidationDropDown from "./leadValidationDropDown";
import LeadValidationMultiDropDown from "./leadValidationMultiDropDown";
import { options } from "../common/options";
import * as actionTypes from "../../actions/actionTypes";
import _ from "lodash"
import {
   LEAD_STATUS,
   VERIFICATION_STATUS,
   ORDER,
   CAMPAIGN,
   LEAD_SOURCE,
   UTM_TERM,
   UTM_CONTENT,
   ASSET_TYPE,
   COUNTRY,
   ORGANIZATION,
   MEMBER_SENIORITY,
   JOB_FUNCTION,
   SECTOR,
   INDUSTRY_GROUP,
   INDUSTRY,
   SUB_INDUSTRY,
   UTM_SOURCE,
   UTM_MEDIUM,
   UTM_CAMPAIGN,
   PHONE_NUMBER,
   CUSTOM_QUESTION_RESPONSES,
   JOB_TITLE,
   DATE,
   TIME,
   EMAIL_ADDRESS,
   FIRST_NAME,
   LAST_NAME,
   ADDRESS1,
   ADDRESS2,
   CITY,
   STATE_OR_PROVINCE,
   POSTAL_CODE_OR_ZIP,
   TIMEZONE,
   REGULATION_NAME,
   PURPOSE_CONSENT,
   MARKETING_CONSENT,
   DOUBLE_OPT_IN,
   OPT_OUT_OF_SALE,
   CONDITIONING_CONSENT,
   AGE_VERIFICATION,
   RIGHT_TO_BE_FORGOTTEN,
   OPT_IN_DATE,
   OPT_IN_TIME,
   ENGAGEMENT_PROCESSED_DATE,
   ENGAGEMENT_PROCESSED_TIME,
   ACCEPTED_DATE,
   ACCEPTED_TIME,
   DELIVERY_DATE,
   DELIVERY_TIME,
   PAGE_TITLE,
   WP_ID,
   ORGANIZATION_TYPE,
   ORGANIZATION_REVENUE,
   ORGANIZATION_SIZE,
} from "../config/TableHeaderNames";
import UserNameComponent from "../common/UserNameComponent";

const LeadTableRow = (props) => {
   const [e, setE] = useState([]);
   const [checkboxChecked, setCheckboxChecked] = useState(false);
   const [verificationStatus, setVerificationStatus] = useState(
      props.leadRow.verification_status
   );
   const [verificationState, setVerificationState] = useState(
      props.leadRow.verification_state
   );

   useEffect(() => {
      setVerificationStatus(props.leadRow.verification_status);
      setVerificationState(props.leadRow.verification_state);
      setCheckboxChecked(props.allChildChecked);
      setE(props.leadRow);
   }, [props.leadRow, props.allChildChecked]);

   useEffect(() => {
      if (!_.isEmpty(Object.keys(_.get(props, 'rowIds', {})))) {
         props.rowIds.map((val, idx) => {
            if (_.includes(Object.keys(_.get(props, `rowIds[${idx}]`)), _.get(props, 'leadRow.uniqueId'))) {
               setCheckboxChecked(true);
            }
            return {};
         })
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.rowIds])

   useEffect(() => {
      setCheckboxChecked(false);
   }, [props.syncSuccess]);

   useEffect(() => {
      if (props.headerChecboxChecked) {
         setCheckboxChecked(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.filterData.showMoreClicked]);

   useEffect(() => {
      if (props.headerChecboxChecked) {
         setCheckboxChecked(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.headerChecboxChecked]);

   let OrgVert = {};
   OrgVert.sector = e.sector;
   OrgVert.industry_group = e.industry_group;
   OrgVert.industry = e.industry;
   OrgVert.sub_industry = e.sub_industry;

   let OrderData;
   let orders = [];
   let order = {};
   let parentOrgName = {};

   const ClientLeadDetailDrawer = HocDrawer(
      ArrowForwardIcon,
      ClientLeadDetailCampaignDetails,
      "",
      e
   );

   const parentOrgsName = (parentOrgs) => {
      parentOrgs.map((element) => {
         parentOrgName = element.name;
         return parentOrgName;
      });
      return parentOrgName;
   };

   const checkboxAndDuplicationHandle = (uniqueId) => {
      let includeValue = false
      if (!_.isEmpty(Object.keys(_.get(props, 'rowIds', {})))) {
         props.rowIds.map((val, idx) => {
            if (_.includes(Object.keys(_.get(props, `rowIds[${idx}]`)), uniqueId)) {
               includeValue = true;
            }
            return {};
         })
         if (!includeValue) {
            handleCheckboxValues();
         }
      } else {
         handleCheckboxValues();
      }
   }

   const handleCheckboxValues = () => {
      setCheckboxChecked(true);
      props.setCheckedFromChild(
         true,
         props.leadRow.item_ids,
         props.leadRow.campaign_uuid,
         props.leadRow.uniqueId
      );
   }

   if (e.campaign_data !== null) {
      OrderData = getOrderInCampaigns(e.campaign_data);
      OrderData.map((order) => {
         orders.push(order.name);
         return orders;
      });
   }
   order.name = orders;
   if (props.headerName === "SHOWN") {
      return (
         <Table.Cell>
            <Checkbox
               id={props.leadRow.uniqueId}
               style={{ color: "white" }}
               className="calcheckbox"
               checked={checkboxChecked}
               //onChange={handleCheckbox(e, checkboxChecked, props.leadRow)}
               onChange={(e, { checked }) => {
                  setCheckboxChecked(checked);
                  props.setCheckedFromChild(
                     checked,
                     props.leadRow.item_ids,
                     props.leadRow.campaign_uuid,
                     e.target.id,
                     props.leadRow.user_uuid
                  );
               }}
            />
         </Table.Cell>
      )
   }

   if (props.headerName === LEAD_STATUS || props.headerName === VERIFICATION_STATUS) {
      return (
         <>
            {props.headerName === LEAD_STATUS &&
               <Table.Cell style={{ overflow: "visible" }}>
                  <LeadValidationDropDown
                     value={verificationStatus}
                     options={options}
                     onChange={(e, { value }) => {
                        checkboxAndDuplicationHandle(props.leadRow.uniqueId);
                        setVerificationStatus(value);
                        setVerificationState("");

                     }}
                  />
               </Table.Cell>
            }

            {(props.headerName === VERIFICATION_STATUS || verificationStatus || verificationStatus === "") &&
               <Table.Cell style={{ overflow: "visible", "display": `${props.headerName === VERIFICATION_STATUS ? "none" : ""}` }}>
                  <LeadValidationMultiDropDown
                     verificationStatus={verificationStatus ? verificationStatus : ""}
                     verificationState={verificationState ? verificationState : ""}
                     arrayRowState={e.item_ids}
                     onChange={(e, { value }) => {
                        setVerificationState(value);
                        checkboxAndDuplicationHandle(props.leadRow.uniqueId);
                        props.updateLeadValidation(
                           props.leadRow.item_ids,
                           verificationStatus,
                           value,
                           actionTypes.FETCHREQSTATUSROWWISE,
                           "",
                           "",
                           true
                        );
                        // callAPIData();
                     }}
                  />
               </Table.Cell>
            }
         </>
      )
   }

   if (props.headerName === ORDER) {
      return (
         <Table.Cell title={order.name} className="blogs_link">
            {OrderData ? (
               <MultiLinks type="orders" object={OrderData}></MultiLinks>
            ) : (
               order.name
            )}
         </Table.Cell>
      );
   }

   if (props.headerName === CAMPAIGN) {
      return (
         <Table.Cell title={e.campaign_name} className="blogs_link">
            {e.campaign_data ? (
               <MultiLinks type="campaigns" object={e.campaign_data}></MultiLinks>
            ) : (
               e.campaign_name
            )}
         </Table.Cell>
      );
   }

   /* // Lead Date & Lead Time  */
   if (props.headerName === DATE) {
      return (
         <Table.Cell title={getDateEng(e.lead_date)}>
            {getDateEng(e.lead_date)}
         </Table.Cell>
      )
   }

   if (props.headerName === TIME) {
      return (
         <Table.Cell title={e.lead_time}>{e.lead_time}</Table.Cell>
      )
   }

   if (props.headerName === OPT_IN_DATE) {
      return (
         <Table.Cell title={e.opt_in_date} className="space_height">{e.opt_in_date ? e.opt_in_date : "N/A"}</Table.Cell>
      );
   }

   if (props.headerName === OPT_IN_TIME) {
      return (
         <Table.Cell title={e.opt_in_time} className="space_height">{e.opt_in_time ? e.opt_in_time : "N/A"}</Table.Cell>
      );
   }

   if (props.headerName === ENGAGEMENT_PROCESSED_DATE) {
      return (
         <Table.Cell title={e.processing_date} className="space_height">{e.processing_date ? e.processing_date : "N/A"}</Table.Cell>
      );
   }

   if (props.headerName === ENGAGEMENT_PROCESSED_TIME) {
      return (
         <Table.Cell title={e.processing_time} className="space_height">{e.processing_time ? e.processing_time : "N/A"}</Table.Cell>
      );
   }

   if (props.headerName === ACCEPTED_DATE) {
      return (
         <Table.Cell title={e.accepted_at_date} className="space_height">{e.accepted_at_date ? e.accepted_at_date : "N/A"}</Table.Cell>
      );
   }

   if (props.headerName === ACCEPTED_TIME) {
      return (
         <Table.Cell title={e.accepted_at_time} className="space_height">{e.accepted_at_time ? e.accepted_at_time : "N/A"}</Table.Cell>
      );
   }

   if (props.headerName === DELIVERY_DATE) {
      return (
         <Table.Cell title={e.delivered_at_date} className="space_height">{e.delivered_at_date ? e.delivered_at_date : "N/A"}</Table.Cell>
      );
   }

   if (props.headerName === DELIVERY_TIME) {
      return (
         <Table.Cell title={e.delivered_at_time} className="space_height">{e.delivered_at_time ? e.delivered_at_time : "N/A"}</Table.Cell>
      );
   }

   if (props.headerName === ASSET_TYPE) {
      return (
         <Table.Cell title={e.new_asset_type}>{e.new_asset_type}</Table.Cell>
      )
   }

   if (props.headerName === PAGE_TITLE) {
      return (
         (_.includes(["N/A", ""], e.page_title)) ?
            <Table.Cell className="space_height">{"N/A"}</Table.Cell>
            :
            <Table.Cell title={e.page_title} className="blogs_link space_height">
               <a href={`https://${e.domain}${e.page_path}`} target="blank">
                  {e.page_title}
               </a>
            </Table.Cell>
      );
   }

   if (props.headerName === WP_ID) {
      return (
         <Table.Cell title={e.wp_page_id} className="space_height">{e.wp_page_id}</Table.Cell>
      );
   }

   if (props.headerName === ORGANIZATION) {
      return (
         (e.organization_name && e.organization_name.length > 0 && e.organization_uuid !== "N/A") ? (
          <Table.Cell
            className="space_height"
            title={e.parent_organizations ? e.organization_name + ", " + parentOrgsName(e.parent_organizations) : (e.organization_name ? e.organization_name : "Anonymous")}
          >
            <span className="blogs_link" title={e.organization_name}>
              <Link
                type="organizations"
                urlParam={e.organization_uuid}
                refValue={e.organization_name}
              ></Link>
            </span>
            {e.parent_organizations && " ("}
            <span title={e.parent_organizations && parentOrgsName(e.parent_organizations)}>
              {e.parent_organizations ? (
                <MultiLinks
                  type="organizations"
                  object={e.parent_organizations}
                ></MultiLinks>
              ) : (
                e.parent_organization_name
              )}
            </span>
            {e.parent_organizations && ")"}
          </Table.Cell>
        ) :
        <Table.Cell> {((e.organization_name && e.organization_uuid.length > 0 && (e.email_domain_status.length > 0 && e.email_domain_status !== "valid" && e.email_domain_status !== "N/A")) ? getDomainStatus(e.email_domain_status) : "Anonymous")}</Table.Cell>
      );
   }

   if (props.headerName === ORGANIZATION_TYPE) {
      return(<Table.Cell title={e.type} className="space_height">{e.type}</Table.Cell>);
   }
  
   if (props.headerName === ORGANIZATION_REVENUE) {
      return(
         <Table.Cell title={e.estimated_revenue} className="space_height">
            {e.estimated_revenue}
         </Table.Cell>
      );
   }
  
   if (props.headerName === ORGANIZATION_SIZE) {
   return(
      <Table.Cell textAlign="right" title={numFormat(e.employees)} className="space_height">
         {numFormat(e.employees)}
      </Table.Cell>
   );
   }

   if (props.headerName === SECTOR) {
      return (
         <Table.Cell title={e.sector}>{e.sector}</Table.Cell>
      )
   }

   if (props.headerName === INDUSTRY_GROUP) {
      return (
         <Table.Cell title={e.industry_group}>{e.industry_group}</Table.Cell>
      )
   }

   if (props.headerName === INDUSTRY) {
      return (
         <Table.Cell title={e.industry}>{e.industry}</Table.Cell>
      )
   }

   if (props.headerName === SUB_INDUSTRY) {
      return (
         <Table.Cell title={e.sub_industry}>{e.sub_industry}</Table.Cell>
      )
   }

   if(props.headerName === FIRST_NAME) {
      return (
         <UserNameComponent value={e.first_name} userUuid={e.user_uuid} />
      )
   }
  
   if(props.headerName === LAST_NAME) {
      return (
         <UserNameComponent value={e.last_name} userUuid={e.user_uuid} />
      )
   }

   if (props.headerName === MEMBER_SENIORITY) {
      return (
         <Table.Cell title={e.seniority}>{e.seniority}</Table.Cell>
      )
   }

   if (props.headerName === JOB_FUNCTION) {
      return (
         <Table.Cell title={e.primary_role}>{e.primary_role}</Table.Cell>
      )
   }

   if (props.headerName === JOB_TITLE) {
      return (
         <Table.Cell title={e.member_title}>{e.member_title}</Table.Cell>
      )
   }

   if (props.headerName === PHONE_NUMBER) {
      return (
         <Table.Cell title={e.phone_number}>{e.phone_number}</Table.Cell>
      )
   }

   if (props.headerName === EMAIL_ADDRESS) {
      return(<Table.Cell title={e.email}>{e.email ? e.email : "N/A"}</Table.Cell>);
    }
  
    if (props.headerName === ADDRESS1) {
      return(<Table.Cell title={e.address_1}>{e.address_1 ? e.address_1 : "N/A"}</Table.Cell>);
    }
  
    if (props.headerName === ADDRESS2) {
      return(<Table.Cell title={e.address_2}>{e.address_2 ? e.address_2 : "N/A"}</Table.Cell>);
    }
  
    if (props.headerName === CITY) {
      return(<Table.Cell title={e.city}>{e.city}</Table.Cell>);
    }
  
    if (props.headerName === STATE_OR_PROVINCE) {
      return(<Table.Cell title={e.state}>{e.state}</Table.Cell>);
    }

   if (props.headerName === COUNTRY) {
      return (
         <Table.Cell title={e.member_country}>{e.member_country}</Table.Cell>
      )
   }

   if (props.headerName === POSTAL_CODE_OR_ZIP) {
      return(<Table.Cell title={e.postal_code}>{e.postal_code}</Table.Cell>);
   }
  
   if (props.headerName === TIMEZONE) {
      return(<Table.Cell title={e.member_timezone}>{e.member_timezone}</Table.Cell>);
   }

   if (props.headerName === UTM_SOURCE) {
      return (
         <Table.Cell title={e.source?.String}>{e.source?.String}</Table.Cell>
      )
   }

   if (props.headerName === UTM_MEDIUM) {
      return (
         <Table.Cell title={e.utm_medium?.String}>{e.utm_medium?.String}</Table.Cell>
      )
   }

   if (props.headerName === UTM_CAMPAIGN) {
      return (
         <Table.Cell title={e.utm_campaign?.String}>{e.utm_campaign?.String}</Table.Cell>
      )
   }

   if (props.headerName === UTM_TERM) {
      return (
         <Table.Cell title={e.utm_term?.String}>{e.utm_term?.String}</Table.Cell>
      )
   }

   if (props.headerName === UTM_CONTENT) {
      return (
         <Table.Cell title={e.utm_content?.String}>{e.utm_content?.String}</Table.Cell>
      )
   }

   if (props.headerName === CUSTOM_QUESTION_RESPONSES) {
      return (
         <Table.Cell title={e.custom_question_responses?.Department ? `Department: ${e.custom_question_responses?.Department}` : ""}>{e.custom_question_responses?.Department ? `Department: ${e.custom_question_responses?.Department}` : ""}</Table.Cell>
      )
   }

   if (props.headerName === REGULATION_NAME) {
      return(
        <Table.Cell title={e.regulation_name} className="space_height">{e.regulation_name}</Table.Cell>
      );
   }
  
   if(props.headerName === PURPOSE_CONSENT) {
      return(
         <Table.Cell title={e.purpose_consent ? "Yes" : "No"} className="space_height">{e.purpose_consent ? "Yes" : "No"}</Table.Cell>
      );
   }

   if(props.headerName === MARKETING_CONSENT) {
      return(
         <Table.Cell title={e.marketing_consent ? "Yes" : "No"} className="space_height">{e.marketing_consent ? "Yes" : "No"}</Table.Cell>
      );
   }

   if(props.headerName === DOUBLE_OPT_IN) {
      return(
         <Table.Cell title={e.double_optin_pending ? "Yes" : "No"} className="space_height">{e.double_optin_pending ? "Yes" : "No"}</Table.Cell>
      );
   }

   if(props.headerName === OPT_OUT_OF_SALE) {
      return(
         <Table.Cell title={e.dnsmpi ? "Yes" : "No"} className="space_height">{e.dnsmpi ? "Yes" : "No"}</Table.Cell>
      );
   }

   if(props.headerName === CONDITIONING_CONSENT) {
      return(
         <Table.Cell title={e.conditioning_consent ? "Yes" : "No"} className="space_height">{e.conditioning_consent ? "Yes" : "No"}</Table.Cell>
      );
   }

   if(props.headerName === AGE_VERIFICATION) {
      return(
         <Table.Cell title={e.age_verification ? "Yes" : "No"} className="space_height">{e.age_verification ? "Yes" : "No"}</Table.Cell>
      );
   }

   if(props.headerName === RIGHT_TO_BE_FORGOTTEN) {
      return(
         <Table.Cell title={e.right_to_be_forgotten ? "Yes" : "No"} className="space_height">{e.right_to_be_forgotten ? "Yes" : "No"}</Table.Cell>
      );
   }

   if (props.headerName === LEAD_SOURCE) {
      return (
         <Table.Cell title={e.lead_partner?.String}>{e.lead_partner?.String}</Table.Cell>
      )
   }

   if (props.headerName === "SORT_ICON") {
      return (
         <Table.Cell>
            <ClientLeadDetailDrawer />
         </Table.Cell>
      )
   }

   return null;
};

const mapStateToProps = (state) => {
   return {
      filterData: state.clientLeadFilterData,
   };
};

export default connect(mapStateToProps, {
   updateLeadValidation,
   fetchLeadEngSummary,
   fetchClientLeadEngs
})(LeadTableRow);
