import React from "react";
import CreateTableRowComponent from "./CreateTableRowComponent";

const EventFilterSection = ({ data }) => {
   const userInfo = data.search_query;
   const eventData = [
      {
         name: "EXCLUDE INTERNAL EVENTS",
         value: userInfo.exclude_internal_events
      },
      {
         name: "EXCLUDE ORGANIZATION EVENTS",
         value: userInfo.exclude_organization_events
      },
      {
         name: "EXCLUDE FREE EMAIL EVENTS",
         value: userInfo.exclude_free_email_registrations
      },
      {
         name: "EXCLUDE COMPETITOR EVENTS",
         value: userInfo.exclude_competitors
      }
   ]
   return (
      <React.Fragment>
         {
            eventData.map((event, index) => {
               return <CreateTableRowComponent key={index} name={event.name} value={event.value ? "Yes" : "No"} />
            })
         }
      </React.Fragment>
   );
};

export default EventFilterSection;