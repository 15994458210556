import React from "react";
import SearchFilter from "../components/leadEngagement/searchFilter";
import { connect } from "react-redux";
import { fetchClientLeadEngsSummaryDownloadCsvXlsx, leadCsvLoading, selLeadEngFilterValue } from "../actions";
import "../css/common.css";
import LeadEngagementSummaryTable from "../components/leadEngagement/leadEngSummaryTable";
import LeadEngagementsTable from "../components/leadEngagement/leadEngsTable";
import { Message } from "semantic-ui-react";
import NotifyEmail from "../components/common/notifyEmailConfirmation";
import * as actionTypes from "../actions/actionTypes";
import LeadEngsChart from "../components/leadEngagement/leadEngsChart";
import DownloadXLSX from "../components/common/downloadXLSX";
import EngagementStatusChart from "../components/leadEngagement/barCharts/engsStatusChart";
import EngagementAcceptanceChart from "../components/leadEngagement/barCharts/engsAcceptanceChart";
import _ from "lodash";
import { downloadCSVData, getReportsByElement } from "../utilities/common";
import { ENGAGEMENT_ACCEPTANCE_RATE, ENGAGEMENT_CAMPAIGN_SUMMARY, ENGAGEMENT_PACING, ENGAGEMENT_STATUS_CHART, ENGAGEMENT_WORKBOOK, ROLEBASED_ELEMENT_ENGAGEMENT } from "../components/config/rolebasedReportNames";
import { CHART, DOWNLOAD_CSV, GENERATE_XLSX, VIEW } from "../components/config/const";

class LeadEngagementReport extends React.Component {

  getExcelReport = (btnSpecificAction, table_name) => {
    DownloadXLSX(
      btnSpecificAction, 
      table_name, 
      "lead_eng",
      this.props.filterData,
      this.props.userEmail,
      actionTypes.LEADDETAILEXCEL,
      this.props.fetchClientLeadEngsSummaryDownloadCsvXlsx
    );
	};

  getLeadEngsCSVData = async () => {
    this.props.leadCsvLoading();
    await this.props.fetchClientLeadEngsSummaryDownloadCsvXlsx(this.props.filterData, 1, "CSV","", actionTypes.LEADENGSCSV, "");
    if (this.props.leadEngsCsv) {
      downloadCSVData(this.props.leadEngsCsv, this.props.downloadFileName)
    }
  };

  render() {
    let tableView;
    let leadEngsDownload;
    let excelDownloadButton;
    const roleReports = getReportsByElement(this?.props?.roleFilterData?.currentUserReportRoleData, this?.props?.roleFilterData?.getAllReports, ROLEBASED_ELEMENT_ENGAGEMENT);

    if (this.props.isFilApplied) {
      excelDownloadButton = (
        <div>
        <button
          className="download-button"
          disabled={false}
          onClick={() => this.getExcelReport(actionTypes.LEADEXCELSUMMARY, "lead_eng")}
        >
          Generate XLSX
        </button>
      </div>
      )	
      leadEngsDownload = (
        <div>
          <button
            className="download-button"
            disabled={false}
            onClick={() => this.getLeadEngsCSVData()}
          >
            Download CSV
          </button>
        </div>
      );
    } else {
      tableView = <Message>Please proceed with applying filter.</Message>;
    }
    return (
      <div>
        <div className="page-name-comp">
          <p className="page-name">Engagements</p>
          <p>{this.props.reportDescription.find(report => report.name === "Engagements")?.description}</p>
        </div>
        <SearchFilter roleReports={roleReports} />
        {
          (roleReports?.some((report) => [ENGAGEMENT_PACING].includes(report.element) && report.permission === CHART)) &&
          <LeadEngsChart />
        }
        {
          (roleReports?.some((report) => [ENGAGEMENT_STATUS_CHART].includes(report.element) && report.permission === CHART)) &&
          <EngagementStatusChart />
        }
        {
          (roleReports?.some((report) => [ENGAGEMENT_ACCEPTANCE_RATE].includes(report.element) && report.permission === CHART)) &&
          <EngagementAcceptanceChart />
        }
        <div>
          {
            roleReports?.some(
              (report) =>
                [ENGAGEMENT_CAMPAIGN_SUMMARY].includes(report.element) &&
                            [VIEW, DOWNLOAD_CSV, GENERATE_XLSX].includes(report.permission)
            ) &&
            <div className="table-heading-block">
              <div className="table-name">
                <p>Engagement Campaign Summary</p>
              </div>
                <div className="class-relative">
                  { 
                    this.props.isFilApplied && (roleReports?.some((report) => [ENGAGEMENT_CAMPAIGN_SUMMARY].includes(report.element) && report.permission === DOWNLOAD_CSV)) &&
                    <div className="csv-button">
                      {leadEngsDownload}
                    </div>
                  }
                  { 
                    this.props.isFilApplied && (roleReports?.some((report) => [ENGAGEMENT_CAMPAIGN_SUMMARY].includes(report.element) && report.permission === GENERATE_XLSX)) &&
                    <div className="excel-button">
                      {excelDownloadButton}
                      {this.props.postEmailUpdateLeadSummary ? (
                        <NotifyEmail
                          email={this.props.postEmailUpdateLeadSummary}
                          table="lead_summary"
                          cardPopup={this.props.postEmailUpdateLeadSummary}
                          cardPopupClose={actionTypes.SETLEADSUMMARYPOPUPPOSTEMAIL}
                        />
                      ) : null}
                    </div>
                  }
          
              </div>
            </div>
          }
        </div>
        {
          (roleReports?.some((report) => [ENGAGEMENT_CAMPAIGN_SUMMARY].includes(report.element) && report.permission === VIEW)) &&
          <div>
            <LeadEngagementSummaryTable></LeadEngagementSummaryTable>
          </div>
        }
        {
          (roleReports?.some((report) => [ENGAGEMENT_WORKBOOK].includes(report.element) && report.permission === VIEW)) &&
          <React.Fragment>
            <div className="table-heading-block">
              <div className="table-name">
                <p>Engagements Workbook</p>
              </div>
              {this.props.isFilApplied && !_.isEmpty(this.props.leadEngs) && <p className="total_lead_count"
                style={{
                  marginTop: "5px", paddingLeft: "22.5rem",
                  paddingRight: "20rem",
                  fontSize: "16px",
                  fontWeight: 1000,
                  color: "#838486"
                }}>Totals: {this.props.totalLeadCount}</p>}
            </div>
              <LeadEngagementsTable
                roleReports={roleReports}
                props={this.props}>
              </LeadEngagementsTable>
          </React.Fragment>
        }
        {tableView}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    leadEngsValidationBarChart: state.leadFetchData.leadEngsValidationBarChart,
    engagementValidationChart: state.leadFetchData.engagementValidationChart,
    isFilApplied: state.leadFetchData.isFilApplied,
    leadEngsCsv: state.leadFetchData.leadEngsCsv,
    filterData: state.leadFilterData,
    isLoadingLeadEngsCsv: state.leadFetchData.isLoadingLeadEngsCsv,
    userEmail: state.authorized.userEmail,
		emailLeadSummary: state.leadFetchData.emailLeadSummary,
		cardPopupLeadSummary: state.leadFetchData.cardPopupLeadSummary,
		postEmailUpdateLeadSummary:
			state.leadFetchData.postEmailUpdateLeadSummary,
    leadEngs: state.leadFetchData.leadEngs,
    totalLeadCount: state.leadFetchData.totalLeadCount,
    engDetailCsv: state.leadFetchData.engDetailCsv,
    downloadFileName: state.leadFetchData.downloadFileName,
    reportDescription: state.allFilterReportData.reportDescription,
    roleFilterData: state.allFilterReportData,
  };
};

export default connect(mapStateToProps, { fetchClientLeadEngsSummaryDownloadCsvXlsx, leadCsvLoading, selLeadEngFilterValue })(
  LeadEngagementReport
);
