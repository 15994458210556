import React from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import SingleSelectDropDown from "../base/singleSelectDropDown";
import "../../css/common.css";
//import { getDisplayDate, getLastDays } from '../../utilities/common';
//import { subYears } from 'date-fns';

const DatePicker = (props) => {
	const options = [
		{
			key: "7",
			text: "Yesterday",
			value: "2",
		},
		{
			key: "1",
			text: "Last 7 Days",
			value: "7",
		},
		{
			key: "2",
			text: "Last 30 Days",
			value: "30",
		},
		{
			key: "3",
			text: "Last 90 Days",
			value: "90",
		},
		{
			key: "4",
			text: "Last 12 Months",
			value: "1",
		},
		{
			key: "6",
			text: "True Year To Date",
			value: "01",
		},
		{
			key: "5",
			text: "Custom Date Range",
			value: "Custom Date Range",
			onClick: props.openDateRange,
		},
	];
	
	if(props?.showAllDates) {
		const allDates = {
			key: "8",
			text: "All Dates",
			value: "all",
		}
		options.push(allDates)
	}
	
	return (
		<SingleSelectDropDown 
			scrolling 
			placeholder="Select Date Range"
			width={5}
			options={options}
			className="no-blink"
			upward={false}
			value={props.value}
			onChange={props.onChange}
		></SingleSelectDropDown>
	);
};

export default DatePicker;
