import React from "react";
import { Form } from "semantic-ui-react";
import OrganizationPicker from "../../common/organization";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import {
    fetchOrganizations,
    selectValue,
    addSelectedValue,
    clearSearchForAllReport,
    fetchCampaigns,
    fetchOrder,
    fetchAdorders,
    fetchCountry,
} from "../../../actions";
import * as actionTypes from "../../../actions/actionTypes";
import { CAMPAIGN, ORGANIZATION } from "../../config/const";
import CampaignPicker from "../../common/campaign";
import _ from "lodash";
import StatusPicker from "../../common/status";
import OrderPicker from "../../common/order";
import DeliveryPicker from "../../common/deliveryState";
import PacingFilter from "../../common/pacingFilter";
import PaidPicker from "../../common/paidUnpaidPicker";
import Site from "../../common/site";
import Adorderstatus from "../../common/adorderstatus";
import AdOrderPicker from "../../common/adorder";
import CommonMultiSelector from "../../common/commonMultiSelector";
import { AdUnitMultipleOptions } from "../../common/adUnitOptions";
import TrafficFilterPicker from "../../common/trafficeFilter";
import CountryMultiSelectPicker from "../../common/countryPicker";
import CSMSingleSelection from "../../common/FilterSection/CSMSingleSelection";

const CollectiveCommonFilter = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();

    // Organization handlers
    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    const searchOrg = (e) => {
        if (e.target.value.length > 1) {
            props.fetchOrganizations(
                props.filterData.excludeQACampaign,
                e.target.value,
                actionTypes[props.reportActionType].FETCH_ORGANIZATIONS,
                props.commonSelectData.orgs,
                undefined,
                props.reportActionType
            );
        }
    };

    const onSelectOrg = (e, { value }) => {
        dispatchDynamicSelectAction(props, ORGANIZATION, value);
        props.addSelectedValue({ dispatchType: actionTypes[props.reportActionType].ADD_ORGANIZATIONS, reportType: props.reportActionType, value });
        if (!_.isEmpty(props.filterData.campaign)) {
            props.fetchCampaigns(
                "",
                props.filterData.site,
                props.filterData.selTagsIN,
                props.filterData.selTagsEX,
                props.filterData.excludeQACampaign,
                "",
                value,
                props.filterData.campaignStatus,
                "start_date",
                actionTypes[props.reportActionType].FETCH_CAMPAIGNS,
                props.filterData.campaign,
                true,
                actionTypes[props.reportActionType].RESET_CAMPAIGNS,
                props.reportActionType,
            );
        }
    };

    const onClickOrg = () => {
        props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].CLEAR_ORGANIZATIONS, reportType: props.reportActionType });
    };

    // Campaign handlers
    const searchCampaign = (e) => {
        if (e.target.value.length > 1) {
            props.fetchCampaigns(
                "",
                props.filterData.site,
                props.filterData.selTagsIN,
                props.filterData.selTagsEX,
                props.filterData.excludeQACampaign,
                e.target.value,
                props.filterData.organization,
                props.filterData.campaignStatus,
                "start_date",
                actionTypes[props.reportActionType].FETCH_CAMPAIGNS,
                undefined,
                undefined,
                undefined,
                props.reportActionType,
            );
        }
    };

    const onSelectCampaigns = (e, { value }) => {
        dispatchDynamicSelectAction(props, CAMPAIGN, value);
        props.addSelectedValue({ dispatchType: actionTypes[props.reportActionType].ADD_CAMPAIGNS, reportType: props.reportActionType, value });
    };

    const onClickCampaign = () => {
        if (_.isEmpty(props.filterData.organization)) {
            props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].CLEAR_CAMPAIGNS, reportType: props.reportActionType });
        } else if (!_.isEmpty(props.filterData.organization)) {
            props.fetchCampaigns(
                "",
                props.filterData.site,
                props.filterData.selTagsIN,
                props.filterData.selTagsEX,
                props.filterData.excludeQACampaign,
                "",
                props.filterData.organization,
                props.filterData.campaignStatus,
                "start_date",
                actionTypes[props.reportActionType].FETCH_CAMPAIGNS,
                undefined,
                undefined,
                undefined,
                props.reportActionType,
            );
        }
    };

    // Order handlers
    const searchOrder = (e) => {
        if (e.target.value.length > 1) {
            props.fetchOrder(
                e.target.value,
                "name",
                actionTypes[props.reportActionType].FETCH_ORDERS,
                undefined,
                undefined,
                props.reportActionType,
            );
        }
    };

    const onSelectOrder = (e, { value }) => {
        dispatchDynamicSelectAction(props, "order", value);
        props.addSelectedValue({ dispatchType: actionTypes[props.reportActionType].ADD_ORDERS, reportType: props.reportActionType, value });
    };

    const onClickOrder = () => {
        props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].CLEAR_ORDERS, reportType: props.reportActionType });
    };

    // Campaign event handlers
    const onSelectCampaignStatus = (e, { value }) => {
        dispatchDynamicSelectAction(props, "campaignStatus", value);
        props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].RESET_CAMPAIGNS, reportType: props.reportActionType });
        dispatchDynamicSelectAction(props, "campaign", []);
    };

    const onSelectAdorderStatus = (e, { value }) => {
        dispatchDynamicSelectAction(props, "adorderStatus", value);
        if (value !== "all") {
            props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].RESET_ORDERS, reportType: props.reportActionType });
            dispatchDynamicSelectAction(props, "order", []);
        }
    };

    const searchAdOrder = (e, { value }) => {
        if (e.target.value.length > 1) {
            props.fetchAdorders(
                props.filterData,
                e.target.value,
                "name",
                actionTypes[props.reportActionType].FETCH_AD_ORDERS,
                "",
                "",
                props.reportName,
                props.reportActionType,
            );
        }
    };

    const onSelectAdorders = (e, { value }) => {
        dispatchDynamicSelectAction(props, "adorder", value);
        props.addSelectedValue({ dispatchType: actionTypes[props.reportActionType].ADD_AD_ORDERS, reportType: props.reportActionType, value });
    };

    const onClickAdOrder = () => {
        if (_.isEmpty(props.filterData.campaign)) {
            props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].CLEAR_AD_ORDERS, reportType: props.reportActionType });
        } else if (!_.isEmpty(props.filterData.campaign)) {
            props.fetchAdorders(
                props.filterData,
                "",
                "start_date",
                actionTypes[props.reportActionType].FETCH_AD_ORDERS,
                undefined,
                undefined,
                undefined,
                props.reportActionType,
            );
        }
        if (_.isEmpty(props.filterData.organization)) {
            props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].CLEAR_AD_ORDERS, reportType: props.reportActionType });
        } else if (!_.isEmpty(props.filterData.organization)) {
            props.fetchAdorders(
                props.filterData,
                "",
                "start_date",
                actionTypes[props.reportActionType].FETCH_AD_ORDERS,
                undefined,
                undefined,
                undefined,
                props.reportActionType,
            );
        }
    };

    const searchCommon = (e) => {
        dispatch({
            type: actionTypes[props.reportActionType].FETCH_AD_UNIT,
            payload: AdUnitMultipleOptions,
            reportType: props.reportActionType,
        });
    }

    const onSelectCommon = (e, { value }) => {
        dispatchDynamicSelectAction(props, "adUnitSelected", value);
        props.addSelectedValue({ dispatchType: actionTypes[props.reportActionType].ADD_AD_UNIT, reportType: props.reportActionType, value });
    }

    const onClickCommon = () => {
        props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].CLEAR_AD_UNIT, reportType: props.reportActionType });
    }

    const searchCountry = (e) => {
        if (e.target.value.length > 1) {
            props.fetchCountry(
                e.target.value, 
                actionTypes[props.reportActionType].FETCH_COUNTRIES,
                props.reportActionType
            );
        }
    };

    const onSelectCountry = (e, { value }) => {
        dispatchDynamicSelectAction(props, "selCountry", value);
        props.addSelectedValue({ dispatchType: actionTypes[props.reportActionType].ADD_COUNTRIES, reportType: props.reportActionType, value });
    };

    const onClickCountry = () => {
        if (_.isEmpty(props.filterData.selCountry)) {
            props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].CLEAR_COUNTRIES, reportType: props.reportActionType });
        }
    };

    return (
        <Form>
            <div className="ad-search-filter">
                <Form.Group widths="equal">
                    <OrganizationPicker
                        onSearchChange={searchOrg}
                        value={props.filterData.organization}
                        onChange={onSelectOrg}
                        onClick={onClickOrg}
                        organizations={props.commonSelectData.orgs}
                    />
                    <OrderPicker
                        onSearchChange={searchOrder}
                        value={props.filterData.order}
                        onChange={onSelectOrder}
                        onClick={onClickOrder}
                        order={props.commonSelectData.order}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <StatusPicker
                        value={props.filterData.campaignStatus}
                        onChange={onSelectCampaignStatus}
                    />
                    <CampaignPicker
                        onSearchChange={searchCampaign}
                        value={props.filterData.campaign}
                        onChange={onSelectCampaigns}
                        campaigns={props.commonSelectData.campaigns}
                        onClick={onClickCampaign}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <DeliveryPicker
                        style={{ width: "24rem" }}
                        value={props.filterData.deliverdFilterState}
                        onChange={(e, { value }) => dispatchDynamicSelectAction(props, "deliverdFilterState", value)}
                        delivery_states={props.filterData.deliverdFilterStateOptions}
                    />
                    <PacingFilter
                        value={props.filterData.pacingFilter}
                        onChange={(e, { value }) => dispatchDynamicSelectAction(props, "pacingFilter", value)}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <PaidPicker
                        style={{ width: "24rem" }}
                        value={props.filterData.adOrderType}
                        onChange={(e, { value }) => dispatchDynamicSelectAction(props, "adOrderType", value)}
                    />
                    <Site
                        value={props.filterData.site}
                        onChange={(e, { value }) => dispatchDynamicSelectAction(props, "site", value)}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <Adorderstatus
                        value={props.filterData.adorderStatus}
                        onChange={onSelectAdorderStatus}
                        label="Ad Order Status"
                        placeholder="Ad Order Status"
                    />
                    <AdOrderPicker
                        onSearchChange={searchAdOrder}
                        value={props.filterData.adorder}
                        onChange={onSelectAdorders}
                        adorders={props.commonSelectData.adorders}
                        onClick={onClickAdOrder}
                    />
                </Form.Group>
                <Form.Group widths="equal" style={location.pathname !== "/ads" ? { width: "24.8em" } : {}}>
                    <TrafficFilterPicker
                        value={props.filterData.trafficType}
                        onChange={(e, { value }) => dispatchDynamicSelectAction(props, "trafficType", value)}
                    />
                    { location.pathname === "/ads" &&
                        <CommonMultiSelector
                            value={props.filterData.adUnitSelected}
                            onSearchChange={searchCommon}
                            onChange={onSelectCommon}
                            onClick={onClickCommon}
                            label="Ad Unit"
                            optionsValue={props.commonSelectData.adUnit}
                        />
                    }
                </Form.Group>
                <Form.Group widths="equal">
                    <CountryMultiSelectPicker
                        value={props.filterData.selCountry}
                        onSearchChange={searchCountry}
                        onChange={onSelectCountry}
                        onClick={onClickCountry}
                        label="Country"
                        countries={props.commonSelectData.country}
                    />
                    <CSMSingleSelection {...props} labelName="CSM" />
                </Form.Group>
            </div>
        </Form>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        commonSelectData: state.allReportCommonReducer[ownProps.reportActionType],
    };
};

export default compose(connect(mapStateToProps, {
    fetchOrganizations,
    selectValue,
    addSelectedValue,
    clearSearchForAllReport,
    fetchCampaigns,
    fetchOrder,
    fetchAdorders,
    fetchCountry,
}))(CollectiveCommonFilter);