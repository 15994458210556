import React from "react";

const SearchFilterIcon = (props) => {
    return (
        <div
          className={props?.filterSelectFocus}
          onClick={() => {
            props && props.setFilterSelectFocus("filter-icon-selected");
            props && props.setFilterPopup("filter-popup-open");
            props && props.setFilterIconColor("material-icons orange");
          }}
        >
          <i className={props?.filterIconColor}>filter_list</i>
        </div>
    )
}

export default SearchFilterIcon;