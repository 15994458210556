import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import * as actionTypes from "../../../actions/actionTypes";
import { selectValue, getCSMAuthors, addSelectedValue, clearSearchForAllReport } from "../../../actions";
import MultipleSelectDropDown from "../../base/multiSelectDropdown";

const CSMSingleSelection = (props) => {

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    const onSearchHandler = (e) => {
		if (e.target.value.length > 1) {
            props.getCSMAuthors(e.target.value, actionTypes[props.reportActionType].FETCH_CSM, props.reportActionType);
		}
	};

    return (
        <MultipleSelectDropDown
            options={props?.commonSelectData?.csmArray.map((csm, index) => ({
                key: index,
                text: csm.name,
                value: csm.id
            }))}
            onSearchChange={onSearchHandler}
            onChange={(e, { value }) => { 
                dispatchDynamicSelectAction(props, "setCSM", value);
                props.addSelectedValue({ dispatchType: actionTypes[props.reportActionType].ADD_CSM, reportType: props.reportActionType, value });
            }}
            onClick={() => props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].CLEAR_CSM, reportType: props.reportActionType })}
            value={props.filterData.setCSM}
            label="CSM"
            placeholder="CSM"
        />
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        commonSelectData: state.allReportCommonReducer[ownProps.reportActionType],
    };
};

export default compose(connect(mapStateToProps, {
    selectValue,
    getCSMAuthors,
    addSelectedValue,
    clearSearchForAllReport
}))(CSMSingleSelection);