import { toTitleCase } from "../utilities/common";

export const transformNewsletterData = (data) => {
    try {
        return data.map(({ slot_represent, ...rest }) => {
            const transformedLayoutRows = slot_represent.map(({ slot_items, ...rowRest }, rowIndex) => {
                const transformedPosts = slot_items?.map((item) => ({
                    ...item,
                    key: `${rest.newsletter_uuid}|${rowRest.slot_name}|${item.page_title}`,
                    level: 3
                }));
                return {
                    ...rowRest,
                    id: rowRest.slot_name || "other",
                    key: `${rest.newsletter_uuid}|${rowRest.sub_slot_name}|${rowRest.slot_name || "other"}`,
                    level: 2,
                    children: transformedPosts
                };
            });

            return {
                ...rest,
                key: rest.newsletter_uuid,
                level: 1,
                children: [...transformedLayoutRows]
            };
        });
    } catch (error) {
        return [];
    }
};

export const transformContentPerformanceData = (data) => {
    try {
        const convertedArray = Object.entries(data).map(([key, value]) => ({
            name: toTitleCase(key),
            ...value
        }));
        
        return convertedArray;
    } catch (error) {
        return [];
    };
};

const accountActivityTrend = {
    "Low Engagement": 1,
    "Fading": 2,
    "Stable": 3,
    "Rising": 4,
};

const accountActivityLevel = {
    "Low": 1,
    "Medium": 2,
    "High": 3
};

const findMatchingValue = (key, dataSet) => {
    return dataSet ? (dataSet[key] || 0) : 0;
};

export const transformLocationsAndRevenue = (decision_insights, data, baseLevelData) => {
    const locationNewKey = findMatchingValue(decision_insights.location, data?.organization_locations);
    const revenueNewKey = findMatchingValue(decision_insights.organization_revenue, data?.organization_revenue_ranges);
    const accountTrendNewKey = findMatchingValue(baseLevelData.account_activity_trend, accountActivityTrend);
    const accountLevelNewKey = findMatchingValue(baseLevelData.account_activity_level, accountActivityLevel);
    
    return {
        ...decision_insights,
        location_sorting_key: locationNewKey,
        revenue_sorting_key: revenueNewKey,
        account_activity_trend_sorting_key: accountTrendNewKey,
        account_activity_level_sorting_key: accountLevelNewKey
    };
};

export const transformOrderSummaryData = (data) => {
    return data?.map(({ ads, pages, sidebars, leads, engagement, roadblock, ...rest }) => {
        const children = [];

        const addCategoryChild = (categoryKey, categoryData) => {
            if (categoryData?.campaign?.length) {
                children.push({
                    ...categoryData,
                    key: `${categoryKey}-${Math.floor((Math.random() * 100000) + 1)}`,
                    category: categoryKey,
                    level: 2,
                    kpi_delivered_roadblock: categoryData.kpi_delivered_total_rb,
                    order_clicks: (!categoryData.clicks) ? "N/A" : (ads ? categoryData.clicks : (categoryData.clicks === 0) ? "N/A" : categoryData.clicks),
                    order_ctr: (!categoryData.ctr) ? "N/A" : (ads ? categoryData.ctr : (categoryData.ctr === 0) ? "N/A" : categoryData.ctr),
                    kpi_roadblock: (categoryData.kpi !== "Roadblock" && !categoryData.kpi_roadblock) ? 0 : (categoryData.kpi === "Roadblock" ? (categoryData.kpi_roadblock || 0) : (categoryData.kpi_roadblock === 0 ? "N/A" : categoryData.kpi_roadblock)),
                    children: categoryData.campaign.map(({ line_item_summary_data, ...campaignRest }) => ({
                        ...campaignRest,
                        key: campaignRest.campaign_uuid + Math.floor((Math.random() * 100000) + 1),
                        level: 3,
                        uuid: campaignRest.campaign_uuid,
                        type: campaignRest.type,
                        kpi_delivered_total: campaignRest.type === "" ? campaignRest.kpi_delivered_total : (campaignRest.kpi !== "Roadblock" ? campaignRest.kpi_delivered_total : "N/A"),
                        kpi_delivered_roadblock: campaignRest.type === "" ? campaignRest.kpi_delivered_total : (campaignRest.kpi === "Roadblock" ? campaignRest.kpi_delivered_total : "N/A"),
                        kpi_roadblock: campaignRest.type === "" ? campaignRest.kpi_roadblock : (campaignRest.kpi === "Roadblock" ? campaignRest.kpi_roadblock : (campaignRest.kpi_roadblock === 0 ? "N/A" : campaignRest.kpi_roadblock)),
                        order_clicks: categoryKey === "Ads" ? campaignRest.clicks : ((campaignRest.clicks === 0) ? "N/A" : campaignRest.clicks),
                        order_ctr: categoryKey === "Ads" ? campaignRest.ctr : ((campaignRest.ctr === 0) ? "N/A" : campaignRest.ctr),
                        children: line_item_summary_data?.map((lineItem) => {
                            const { name, ...restLineItem } = lineItem.kpi_data; // Destructure to exclude `name`
                            return {
                                ...lineItem,
                                ...restLineItem,
                                line_item_page: name, 
                                key: Math.floor((Math.random() * 100000) + 1),
                                kpi_delivered_total: restLineItem.kpi !== "Roadblock" ? restLineItem.kpi_delivered_total : "N/A",
                                kpi_delivered_roadblock: restLineItem.kpi === "Roadblock" ? restLineItem.kpi_delivered_total : "N/A",
                                kpi_roadblock: restLineItem.kpi === "Roadblock" ? restLineItem.kpi_roadblock : (restLineItem.kpi_roadblock === 0 ? "N/A" : restLineItem.kpi_roadblock),
                                level: 4,
                                order_clicks: categoryKey === "Ads" ? restLineItem.clicks : ((restLineItem.clicks === 0) ? "N/A" : restLineItem.clicks),
                                order_ctr: categoryKey === "Ads" ? restLineItem.ctr : ((restLineItem.ctr === 0) ? "N/A" : restLineItem.ctr)
                            };
                        }),
                    })),
                });
            }
        };

        addCategoryChild("Ads", ads);
        addCategoryChild("Content", pages);
        addCategoryChild("Sidebars", sidebars);
        addCategoryChild("Leads", leads);
        addCategoryChild("Engagement", engagement);

        const orderObject = {
            name: rest.name,
            uuid: rest.uuid,
        };
        delete rest.name;
        delete rest.uuid;

        return {
            ...rest,
            key: rest.id,
            level: 1,
            report_name: "Order Summary",
            Order: orderObject,
            children,
            kpi_delivered_roadblock: rest.kpi_delivered_total_roadblock,
            order_clicks: ads ? rest.clicks : ((rest.clicks === 0) ? "N/A" : rest.clicks),
            order_ctr: ads ? rest.ctr : ((rest.ctr === 0) ? "N/A" : rest.ctr)
        };
    });
};

export const transformSponsoredContentPageDetails = (data) => {
    return data?.map(({ Campaign, ...rest }) => {
        return {
            ...rest,
            key: rest.order_uuid,
            level: 1,
            Order: {
                name: rest.order,
                uuid: rest.order_uuid
            },
            children: Campaign.map(({ Page, ...campaignRest }) => ({
                ...campaignRest,
                key: campaignRest.uuid,
                level: 2,
                children: Page.map((page) => ({
                    ...page,
                    key: page.wp_page_id,
                    level: 3,
                    primary_topic: page.primary_topic === "" ? "N/A" : page.primary_topic,
                    str_topic_tags: page.str_topic_tags === "" ? "N/A" : page.str_topic_tags,   
                    str_placement_tags: page.str_placement_tags === "" ? "N/A" : page.str_placement_tags,
                }))
            }))
        };
    });
};
