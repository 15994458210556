import React from 'react';
import { connect } from 'react-redux';

const LeadGraph = (props) => {
  return (
    <div className='ad-graph-date'>
		<p>{props.filStartDate} - {props.filEndDate}</p>
	</div>
  );
};

const mapStateToProps = (state) => {
	return {
		filStartDate: state.leadFilterData.startDate,
		filEndDate: state.leadFilterData.endDate,
	};
  };

export default connect(mapStateToProps)(LeadGraph);