import React from "react";
import GridIcons from "./gridIcons";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import TrendingUpSharpIcon from "@mui/icons-material/TrendingUpSharp";
import DemandGeneration from "./demandGeneration";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import StoreIcon from "@mui/icons-material/Store";
import {
  getDisplayDate,
  numFormat,
  getOrgsInCampaigns,
  getOrderInCampaigns,
  getCommaSeparatedVal,
  getAttachedPercentage
} from "../../utilities/common";
import Link from "../hyperlinks/link";
import MultiLinks from "../hyperlinks/multiLinks";
import CampaignReport from "./campaignReport";

const iconsDesign = {
  height: "1.9rem",
  textAlign: "centre",
};
const LeadEnagagementCampaign = (props) => {
  let leadEngagementCampaign = props.data;
  let percentDelivered = getAttachedPercentage(leadEngagementCampaign.percent_delivered);
  const Cards = [
    {
      title: "Engagements Delivered",
      value: leadEngagementCampaign.engagement_count,
      icon: <TouchAppIcon style={iconsDesign} />,
    },
    {
      title: "Completion %",
      value: percentDelivered !== "" ? percentDelivered : "-",
      icon: <TrendingUpSharpIcon style={iconsDesign} />,
    },
    {
      title: "Engagements Purchased",
      value: numFormat(leadEngagementCampaign.engagement_purchased),
      icon: <StoreIcon style={iconsDesign} />,
    },
    {
      title: "Engagements Outstandings",
      value: numFormat(leadEngagementCampaign.engagement_outstanding),
      icon: <CallSplitIcon style={iconsDesign} />,
    },
  ];

  let OrderData;
  let orders = [];
  let order = {};
  leadEngagementCampaign.campaigns.forEach((e) => {
    if (e.campaigns !== null) {
      OrderData = getOrderInCampaigns(leadEngagementCampaign.campaigns);
      OrderData.map((order) => {
        orders.push(order.name);
        return orders;
      });
    }
  });
  order.name = orders;

  return (
    <div>
      <GridIcons data={Cards} />
      <CampaignReport
        object={
          leadEngagementCampaign.campaigns ? (
            <MultiLinks
              type="organizations"
              object={getOrgsInCampaigns(leadEngagementCampaign.campaigns)}
            ></MultiLinks>
          ) : (
            leadEngagementCampaign.organization_name
          )
        }
        label={"ACCOUNT"}
      />
      <CampaignReport
        object={
          leadEngagementCampaign.campaigns[0].order.name ? (
            OrderData ? (
              <MultiLinks type="orders" object={OrderData}></MultiLinks>
            ) : (
              order.name
            )
          ) : (
            "-"
          )
        }
        label={"ORDER"}
      />
      <CampaignReport
        object={
          leadEngagementCampaign.campaign_name ? (
            <Link
              type="campaigns"
              urlParam={leadEngagementCampaign.campaign_uuid}
              refValue={leadEngagementCampaign.campaign_name}
            ></Link>
          ) : (
            ""
          )
        }
        label={"CAMPAIGN NAME"}
      />

      <CampaignReport
        object={
          leadEngagementCampaign.start_date
            ? getDisplayDate(leadEngagementCampaign.start_date)
            : " - "
        }
        label={"START DATE"}
      />
      <CampaignReport
        object={
          leadEngagementCampaign.end_date
            ? getDisplayDate(leadEngagementCampaign.end_date)
            : " - "
        }
        label={"END DATE"}
      />

      {leadEngagementCampaign.demand_generation ? (
        <DemandGeneration data={leadEngagementCampaign.demand_generation} />
      ) : (
        ""
      )}
        <CampaignReport
        object={
          leadEngagementCampaign.excluded_competitors
            ? getCommaSeparatedVal(leadEngagementCampaign.excluded_competitors)
            : " - "
        }
        label={"EXCLUDED COMPETITORS"}
      />
    </div>
  );
};

export default LeadEnagagementCampaign;
