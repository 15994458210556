import React from "react";
import { Menu } from "semantic-ui-react";
import { connect } from "react-redux";
import { Tooltip } from "@mui/material";
import { changeSidebarAnimation, toggleNestedMenu } from "../actions";
import AssessmentIcon from '@mui/icons-material/Assessment';
import SiteIcon from '@mui/icons-material/DomainAdd';
import InsightsIcon from '@mui/icons-material/Insights';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import "../hoc/layout.css";
import * as roleElement from "../components/config/rolebasedReportNames";
import _ from "lodash";
import { getRolebasedReportFunction } from "../utilities/common";

const menuConfig = [
    {
        name: "customerReporting",
        icon: AssessmentIcon,
        tooltip: "Customer Reporting",
        reports: [
            roleElement.ROLEBASED_ELEMENT_ORDER, roleElement.ROLEBASED_ELEMENT_ALL_ADS, roleElement.ROLEBASED_ELEMENT_DISPLAY_ADS, 
            roleElement.ROLEBASED_ELEMENT_INTERSTITIAL_ADS, roleElement.ROLEBASED_ELEMENT_CONTENT_BODY_ADS, roleElement.ROLEBASED_ELEMENT_NEWSLETTER_ADS, 
            roleElement.ROLEBASED_ELEMENT_NEWSLETTER_BODY_ADS, roleElement.ROLEBASED_ELEMENT_ROADBLOCK_ADS, 
            roleElement.ROLEBASED_ELEMENT_ANNOUNCEMENT_ADS, roleElement.ROLEBASED_ELEMENT_ATF_ADS,
            roleElement.ROLEBASED_ELEMENT_INSTANT_MESSAGE_ADS, roleElement.ROLEBASED_ELEMENT_LEAD, roleElement.ROLEBASED_ELEMENT_ENGAGEMENT,
            roleElement.ROLEBASED_ELEMENT_SPONSORED, roleElement.ROLEBASED_ELEMENT_SIDEBAR_ADS
        ],
    },
    {
        name: "sites",
        icon: SiteIcon,
        tooltip: "Sites",
        reports: [
            roleElement.ROLEBASED_ELEMENT_ACTIVITY, roleElement.ROLEBASED_ELEMENT_AUTHOR, roleElement.ROLEBASED_ELEMENT_NEWSLETTERS, 
            roleElement.ROLEBASED_ELEMENT_PAGEVIEW, roleElement.ROLEBASED_ELEMENT_CONTENT_PERFORMANCE, 
            roleElement.ROLEBASED_ELEMENT_SIDEBAR, roleElement.ROLEBASED_ELEMENT_GOALS, roleElement.ROLEBASED_ELEMENT_SITE
        ],
    },
    {
        name: "decisionInsights",
        icon: InsightsIcon,
        tooltip: "Decision Insights",
        reports: [
            roleElement.ROLEBASED_ELEMENT_DECISION_INSIGHTS_BASE, roleElement.ROLEBASED_ELEMENT_DECISION_INSIGHTS_PRO, 
            roleElement.ROLEBASED_ELEMENT_DECISION_INSIGHTS_PRO_PLUS
        ],
    },
    {
        name: "admin",
        icon: SupervisorAccountIcon,
        tooltip: "Admin",
        reports: [
            roleElement.ROLEBASED_ELEMENT_ROLE_MAPPING, roleElement.ROLEBASED_ELEMENT_REPORT_DESCRIPTION
        ],
    },
];

const StickySidebar = ({ filterData, changeSidebarAnimation, toggleNestedMenu }) => {
    const handleMenuClick = (item) => {
        toggleNestedMenu({ name: item.name, tooltip: item.tooltip });
        changeSidebarAnimation();
    };

    const getFilteredMenuItems = (data, userRoles) => {
        const availableReports = data?.map(data => data.report) || [];
        
        return menuConfig.filter(menuItem => {
            if (menuItem.name === "admin" && userRoles?.some(role => roleElement.ROLEBASED_ADMIN_ROLES.includes(role))) {
                return true;
            }
            
            return menuItem.reports.some(report => availableReports.includes(report));
        });
    };
    

    const reportUuids = getRolebasedReportFunction(filterData?.currentUserReportRoleData);
    const matchedData = filterData?.getAllReports?.filter(item => reportUuids.includes(item.uuid));
    const userRoles = _.flatMap(filterData?.currentUserReportRoleData, "role_name");
    const filteredMenuItems = getFilteredMenuItems(matchedData, userRoles);

    return (
        <div className="icons-sticky-sidebar">
            <Menu text vertical>
                {filteredMenuItems.map(item => (
                    <Menu.Item key={item.name} onClick={() => handleMenuClick(item)}>
                        <Tooltip title={<span className="tooltip-title">{item.tooltip}</span>} arrow>
                            {item.icon && 
                                <item.icon 
                                    style={{ color: filterData[item.name] && "#8A8A8A" }}
                                    className="sticky-icons"
                                />
                            }
                        </Tooltip>
                    </Menu.Item>
                ))}
            </Menu>
        </div>
    );
};

const mapStateToProps = (state) => ({
    filterData: state.allFilterReportData,
});

export default connect(mapStateToProps, { changeSidebarAnimation, toggleNestedMenu })(StickySidebar);