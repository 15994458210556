import React from "react";
import { Tab } from "semantic-ui-react";
import ClientLeadChangedLog from "../clientLeadEng/clientLeadChangedTab";
import LeadEngagementsDetailCampaign from "../campaign/leadEngagementsDetailCampaign";

const EngagementDetailCampaignDetails = (props) => {
  const panes = [
    { menuItem: 'CAMPAIGN HIGHLIGHTS', render: () => <Tab.Pane> <LeadEngagementsDetailCampaign data= {props.data} /></Tab.Pane> },
    { menuItem: 'CHANGED LOG', render: () => <Tab.Pane>  <ClientLeadChangedLog data={props.data} report={"engagement"}  isLeadClient={false}/></Tab.Pane> },
  ]

  return (
    <>
      <Tab style={{ marginTop: 20, paddingLeft:5 }} panes={panes} />
    </>
  );
};

export default EngagementDetailCampaignDetails;
