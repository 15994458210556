import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";
import {
  getDisplayDate,
  getYesterdaysDate,
  getLastDays,
} from "../utilities/common";
import { siteValue } from "../constants";
import createCommonReducer from "./createCommonReducer";
import { transformSponsoredContentPageDetails } from "./TransformData";

const sponsoredContentFilterData = {
  startDate: getDisplayDate(getLastDays(0)),
  endDate: getDisplayDate(getYesterdaysDate()),
  appliedDate: "2",
  previousStartDate: "",
  previousEndDate: "",
  prevYearEndDate: "",
  selCutomRangeChkBox: false,
  checkboxState: false,
  organization: [],
  campaign: [],
  order: [],
  site: siteValue,
  campaignStatus: "active",
  selPageTitle: "",
  selAuthor: "",
  selCountry: [],
  selOrgTagsIN: [],
  selOrgTagsEX: [],
  selTopicTagsIN: [],
  selTopicTagsEX: [],
  selPlacementTagsIN: [],
  selPlacementTagsEX: [],
  selSenioritiesIN: [],
  selSenioritiesEX: [],
  selJobFunctionsEX: [],
  selJobFunctionsIN: [],
  selJobTitlesIN: "",
  selJobTitlesEX: "",
  selSector: [],
  selIndustryGroup: [],
  selIndustry: [],
  selSubIndustry: [],
  selOrgType: [],
  selOrgSize: [],
  selOrgRevenue: [],
  selUrlSource: "",
  selUrlMedium: "",
  selUrlCampaign: "",
  selUrlTerm: "",
  selUrlContent: "",
  selPageViewsByOrg: [],
  excludeQACampaign: false,
  excludeInternalCampaign: false,
  showScChart: false,
  selectCampPageviewDetailsTable: false,
  firmographicToggle: false,
  email: "",
  includedVerticals: [],
  personaToggle: false,
  pageParams: false,
  selEnagedMember: [],
  appliedDateFilter: "2",
  wp_startDate: getDisplayDate(getLastDays(0)),
  wp_endDate: getDisplayDate(getYesterdaysDate()),
  selPrimaryTopic: [],
  openPageviewMenus: false,
  scDeliverdFilterStateOptions: [],
  scDeliverdFilterState: "",
  selTrafficSource: "",
  scIsIncludeParent: false,
  scIsIncludeChild: false,
  pacingFilter: "",
  exAnonymousMember: false,
  exAnonymousOrgs: false,
  detailTableSponsoredPageviewToggleValue: false,
	openPageviewSidebarMenus: false,
  trafficType: "all",
  competitorFiltering: false,
  setCSM: [],
  excludePrePublishedViews: false,
};

const sponsoredContentSelData = {
  orgs: [],
  campaigns: [],
  order: [],
  engOrgs: [],
  country: [],
  engMember: [],
  primaryTopic: [],
};

const selectedTags = {
  orgTagsIn: [],
  orgTagsEx: [],
  topicTagsIn: [],
  topicTagsEx: [],
  placementTagsIn: [],
  placementTagsEx: [],
  senioritiesIN: [],
  senioritiesEX: [],
  jobfunctionsIN: [],
  jobfunctionsEX: [],
  sector: [],
  industry_group: [],
  industry: [],
  sub_industry: [],
  orgsType: [],
  orgsSize: [],
  orgsRevenue: [],
  csmArray: [],
};

const sponsoredContentFetchData = {
  isFilApplied: false,
  isLoadingChart: false,
  scCampaignDetail: [],
  placementPageviewSummary: {},
  isLoadingPlacementPageviewSummary: false,
  isLoadingScCampaignDetail: false,
  pageviewDetail: {},
  pageviewDetailed: {},
  pageCount: {},
  sponsoredContentCharts: [],
  countAPI: false,
  isLoadingScSummary: false,
  isLoadingPageviewDetailed: false,
  scCampaignSummaryCsv: "",
  scCampaignPageviewDetailsCsv: "",
  emailPageSummary: "",
  emailPageDetail: "",
  cardPopupPageSummary: "",
  cardPopupPageDetail: "",
  postEmailUpdate: "",
  postEmailUpdatePageSummary: "",
  postEmailUpdatePageviewDetails: "",
  postEmailCsvUpdatePageviewDetails: "",
  confirmEmail: false,
  isLoadingCampaignSummaryCsv: false,
  isLoadingCampaignPageviewDetailsCsv: false,
  topicPageviewSummarycolumn: null,
  placementPageviewSummarycolumn: null,
  pageviewDetailedColumn: null,
  column: null,
  summaryColumn: null,
  topicPageviewSummarydirection: null,
  placementPageviewSummarydirection: null,
  pageviewDetailedDirection: null,
  direction: null,
  summaryDirection: null,
  campaignPageDirection: null,
  scCampaignPageviewDirection: null,
  placementPageviewSummarytype: "",
  topicPageviewSummarytype: "",
  type: "",
  scCamapignSummaryPageCount: "",
  scCampaignPageviewPageCount: "",
  scCampaignPageCount: "",
  placementPageCount: "",
  placementPageviewSummaryLoad: false,
  topicPageviewSummaryLoad: false,
  scSummary: {},
  scCampaignPageDetail: {},
  isLoadingScCampaignPageDetail: false,
  getDynamicSummaryHeaderData: [],
  dynamicSummaryHeaderArray: [],
  getDynamicPageDetailsHeaderData: [],
  dynamicPageDetailsHeaderArray: [],
  resSponsoredSummary: null,
  resSponsoredCampaignPageDetails: null,
  resSponsoredCampaignPageviewDetails: null,
  getDynamicPageviewDetailsHeaderData: [],
  dynamicPageviewDetailsHeaderArray: [],
  downloadFileName: ""
};

export const sponsoredContentDataReducer = (
  state = sponsoredContentFilterData,
  action
) => {
  //SPONSOREDCONTENT
  switch (action.type) {
    case actionTypes.SELECTED_SPONSORED_TOGGLE:
      return { ...state, [action.payload.key]: action.payload.value };
    case actionTypes.SET_SPONSORED_VALUES:
			return { ...state, [action.payload.key]: action.payload.value };
    case actionTypes.SELSPONSOREDCONTENTSTARTDATE:
      return { ...state, startDate: action.payload };
    case actionTypes.SELSPONSOREDCONTENTENDDATE:
      return { ...state, endDate: action.payload };
    case actionTypes.SELSPONSOREDCONTENTPREVSTARTDATE:
      return { ...state, previousStartDate: action.payload };
    case actionTypes.SELSPONSOREDCONTENTPREVENDDATE:
      return { ...state, previousEndDate: action.payload };
    case actionTypes.APPLYSPONSOREDCONTENTDATE:
      return { ...state, appliedDate: action.payload };
    case actionTypes.SELSPONSOREDCONTENTCUSTOMRANGE:
      return { ...state, selCutomRangeChkBox: action.payload };
    case actionTypes.RESETSELSPONSOREDCONTENTCAMPAIGNS:
      return { ...state, campaign: action.payload };
    case actionTypes.SELSPONSOREDCONTENTORGS:
      return { ...state, organization: action.payload };
    case actionTypes.SELSPONSOREDCONTENTORDER:
      return { ...state, order: action.payload };
    case actionTypes.SELSPONSOREDCONTENTSTATUS:
      return { ...state, campaignStatus: action.payload };
    case actionTypes.SELSPONSOREDCONTENTCAMPAIGNS:
      return { ...state, campaign: action.payload };
    case actionTypes.SELSPONSOREDCONTENTAUTHOR:
      return { ...state, selAuthor: action.payload };
    case actionTypes.SELSPONSOREDCONTENTSITE:
      return { ...state, site: action.payload };
    case actionTypes.SELPGSPONSOREDCONTENTTAGSIN:
      return { ...state, [action.payload.key]: action.payload.value };
    case actionTypes.RESETSELSCURLCAMPANDMEDIUM:
      return { ...state, selUrlMedium: "", selUrlCampaign: "" };
    case actionTypes.SELSPONSOREDCONTENTCOUNTRY:
      return { ...state, selCountry: action.payload };
    case actionTypes.SELSPONSOREDCONTENTENGORGS:
      return { ...state, selPageViewsByOrg: action.payload };
      
    case actionTypes.SELSCPARENTTOOGLE:
        return { ...state, scIsIncludeParent: !state.scIsIncludeParent };
    case actionTypes.SELSCCHILDTOOGLE:
        return { ...state, scIsIncludeChild: !state.scIsIncludeChild };
    case actionTypes.SELSCFIRMOGRAPHICTOGGLE:
      return {
        ...state,
        firmographicToggle: !state.firmographicToggle,
      };
    case actionTypes.SELSCPERSONATOGGLE:
      return {
        ...state,
        personaToggle: !state.personaToggle,
      };
    case actionTypes.SELSCWPSTARTDATE:
      return { ...state, wp_startDate: action.payload };
    case actionTypes.SELSCWPENDDATE:
      return { ...state, wp_endDate: action.payload };
    case actionTypes.SELSCPAGEPARAMSTOGGLE:
      return {
        ...state,
        pageParams: !state.pageParams,
      };
    case actionTypes.APPLYSCDATEFILTER:
      return { ...state, appliedDateFilter: action.payload };
    case actionTypes.SELSCQATOGGLE:
      return {
        ...state,
        excludeQACampaign: !state.excludeQACampaign,
      };
    case actionTypes.SELSCINTOGGLE:
      return {
        ...state,
        excludeInternalCampaign: !state.excludeInternalCampaign,
      };
    case actionTypes.SHOWSCCHARTS:
      return {
        ...state,
        showScChart: !state.showScChart,
      };
    case actionTypes.FETCHDELIVERYSTATE:
      return { ...state, scDeliverdFilterStateOptions: action?.payload?.delivery_states };
    case actionTypes.SELSCCAMPPAGEVIEWDETAILSTABLETOGGLE:
      return {
        ...state,
        selectCampPageviewDetailsTable: !state.selectCampPageviewDetailsTable,
      };
    case actionTypes.SELSCEXANONYMOUSORGS:
      return { ...state, exAnonymousOrgs: !state.exAnonymousOrgs };
    case actionTypes.SELSCEXANONYMOUSMEMBERS:
      return { ...state, exAnonymousMember: !state.exAnonymousMember };
    case actionTypes.DETAILTABLESCTOGGLLEVALUE:
      return { ...state, detailTableSponsoredPageviewToggleValue: action.payload };
    case actionTypes.CLEARSCFILTER:
      return {
        startDate: getDisplayDate(getLastDays(0)),
        endDate: getDisplayDate(getYesterdaysDate()),
        appliedDate: "2",
        previousStartDate: "",
        previousEndDate: "",
        prevYearEndDate: "",
        selCutomRangeChkBox: false,
        checkboxState: false,
        organization: [],
        campaign: [],
        order: [],
        site: siteValue,
        campaignStatus: "active",
        selPageTitle: "",
        selAuthor: "",
        selCountry: [],
        selOrgTagsIN: [],
        selOrgTagsEX: [],
        selTopicTagsIN: [],
        selTopicTagsEX: [],
        selPlacementTagsIN: [],
        selPlacementTagsEX: [],
        selSenioritiesIN: [],
        selSenioritiesEX: [],
        selJobFunctionsEX: [],
        selJobFunctionsIN: [],
        selJobTitlesIN: "",
        selJobTitlesEX: "",
        selSector: [],
        selIndustryGroup: [],
        selIndustry: [],
        selSubIndustry: [],
        selOrgType: [],
        selOrgSize: [],
        selOrgRevenue: [],
        selUrlSource: "",
        selUrlMedium: "",
        selUrlCampaign: "",
        selUrlTerm: "",
        selUrlContent: "",
        selPageViewsByOrg: [],
        excludeQACampaign: false,
        excludeInternalCampaign: false,
        showScChart: false,
        selectCampPageviewDetailsTable: false,
        firmographicToggle: false,
        email: "",
        includedVerticals: [],
        personaToggle: false,
        pageParams: false,
        selEnagedMember: [],
        appliedDateFilter: "2",
        wp_startDate: getDisplayDate(getLastDays(0)),
        wp_endDate: getDisplayDate(getYesterdaysDate()),
        selPrimaryTopic: [],
        openPageviewMenus: false,
        scDeliverdFilterStateOptions: action.payload,
        scDeliverdFilterState: "",
        selTrafficSource: "",
        pacingFilter: "",
        exAnonymousMember: false,
				exAnonymousOrgs: false,
        trafficType: "all",
        setCSM: [],
        excludePrePublishedViews: false,
      };
    default:
      return state;
  }
};

export const sponsoredContentOrganizationsReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHSPONSOREDCONTENTORGS:
      return {
        ..._.mapKeys(sponsoredContentSelData.orgs, "uuid"),
        ..._.mapKeys(action.payload, "uuid"),
      };
    case actionTypes.ADDSPONSOREDCONTENTORGS:
      sponsoredContentSelData.orgs = [];
      const orgIds = action.payload;
      orgIds.forEach(function (orgId) {
        sponsoredContentSelData.orgs.push(state[orgId]);
      });
      return { ..._.mapKeys(sponsoredContentSelData.orgs, "uuid") };
    case actionTypes.CLEARSEARCHSPONSOREDCONTENTORGS:
      return { ..._.mapKeys(sponsoredContentSelData.orgs, "uuid") };
    case actionTypes.RESETSPONSOREDCONTENTORGS:
      sponsoredContentSelData.orgs = [];
      return sponsoredContentSelData.orgs;
    default:
      return state;
  }
};

export const sponsoredContentCampaignsReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHSPONSOREDCONTENTCAMPAIGNS:
      return {
        ...state,
        ..._.mapKeys(sponsoredContentSelData.campaigns),
        ..._.mapKeys(action.payload, "uuid"),
      };
    case actionTypes.ADDSPONSOREDCONTENTCAMPAIGNS:
      sponsoredContentSelData.campaigns = [];
      const campaignIds = action.payload;
      campaignIds.forEach(function (campaignId) {
        sponsoredContentSelData.campaigns.push(state[campaignId]);
      });
      return { ..._.mapKeys(sponsoredContentSelData.campaigns, "uuid") };
    case actionTypes.FETCHSELSPONSOREDCONTENTCAMPAIGNS:
      sponsoredContentSelData.campaigns = action.payload;
      return { ..._.mapKeys(sponsoredContentSelData.campaigns, "uuid") };
    case actionTypes.RESETSPONSOREDCONTENTCAMPAIGNS:
      sponsoredContentSelData.campaigns = [];
      return sponsoredContentSelData.campaigns;
    case actionTypes.CLEARSEARCHSPONSOREDCONTENTCAMPAIGNS:
      return { ..._.mapKeys(sponsoredContentSelData.campaigns, "uuid") };
    default:
      return state;
  }
};

export const sponsoredContentOrderReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHSPONSOREDCONTENTORDER:
      return {
        ..._.mapKeys(sponsoredContentSelData.order, "uuid"),
        ..._.mapKeys(action.payload, "uuid"),
      };
    case actionTypes.ADDSPONSOREDCONTENTORDER:
      sponsoredContentSelData.order = [];
      const orderIds = action.payload;
      orderIds.forEach(function (orderId) {
        sponsoredContentSelData.order.push(state[orderId]);
      });
      return { ..._.mapKeys(sponsoredContentSelData.order, "uuid") };
    case actionTypes.RESETSPONSOREDCONTENTORDER:
      sponsoredContentSelData.order = [];
      return sponsoredContentSelData.order;
    case actionTypes.CLEARSEARCHSPONSOREDCONTENTORDER:
      return { ..._.mapKeys(sponsoredContentSelData.order, "uuid") };
    default:
      return state;
  }
};

export const sponsoredContentAuthorReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHSPONSOREDCONTENTAUTHOR:
      return { ..._.mapKeys(action.payload, "id") };
    case actionTypes.CLEARSEARCHSPONSOREDCONTENTAUTHOR:
      return (state = []);
    default:
      return state;
  }
};

export const sponsoredContentTopicTagsInReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHSPONSOREDCONTENTTOPICTAGSIN:
      action.selected.forEach(function (id) {
        action.payload.forEach((tag) => {
          if (tag.key === id) {
            tag.disabled = true;
          }
        });
      });
      return { ...state, ..._.mapKeys(action.payload, "key") };
    case actionTypes.APPLYSPONSOREDCONTENTTOPICTAGSIN:
      selectedTags.topicTagsIn = [];
      const ids = action.payload;
      ids.forEach(function (id) {
        selectedTags.topicTagsIn.push(state[id]);
      });
      return { ..._.mapKeys(selectedTags.topicTagsIn, "key") };
    case actionTypes.CLEARSEARCHSPONSOREDCONTENTTOPICTAGIN:
      return { ..._.mapKeys(selectedTags.topicTagsIn, "key") };
    case actionTypes.RESETSPONSOREDCONTENTTOPICTAGIN:
      selectedTags.topicTagsIn = [];
      return selectedTags.topicTagsIn;
    default:
      return state;
  }
};

export const sponsoredContentTopicTagsExReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHSPONSOREDCONTENTTOPICTAGSEX:
      action.selected.forEach(function (id) {
        action.payload.forEach((tag) => {
          if (tag.key === id) {
            tag.disabled = true;
          }
        });
      });
      return { ...state, ..._.mapKeys(action.payload, "key") };
    case actionTypes.APPLYSPONSOREDCONTENTTOPICTAGSEX:
      selectedTags.topicTagsEx = [];
      const ids = action.payload;
      ids.forEach(function (id) {
        selectedTags.topicTagsEx.push(state[id]);
      });
      return { ..._.mapKeys(selectedTags.topicTagsEx, "key") };
    case actionTypes.CLEARSEARCHSPONSOREDCONTENTTOPICTAGEX:
      return { ..._.mapKeys(selectedTags.topicTagsEx, "key") };
    case actionTypes.RESETSPONSOREDCONTENTTOPICTAGEX:
      selectedTags.topicTagsEx = [];
      return selectedTags.topicTagsEx;
    default:
      return state;
  }
};

export const sponsoredContentPlacementTagsInReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHSPONSOREDCONTENTPLACEMENTTAGSIN:
      action.selected.forEach(function (id) {
        action.payload.forEach((tag) => {
          if (tag.key === id) {
            tag.disabled = true;
          }
        });
      });
      return { ...state, ..._.mapKeys(action.payload, "key") };
    case actionTypes.APPLYSPONSOREDCONTENTPLACEMENTTAGSIN:
      selectedTags.placementTagsIn = [];
      const ids = action.payload;
      ids.forEach(function (id) {
        selectedTags.placementTagsIn.push(state[id]);
      });
      return { ..._.mapKeys(selectedTags.placementTagsIn, "key") };
    case actionTypes.CLEARSEARCHSPONSOREDCONTENTPLACEMENTTAGIN:
      return { ..._.mapKeys(selectedTags.placementTagsIn, "key") };
    case actionTypes.RESETSCPLACEMENTTAGIN:
      selectedTags.placementTagsIn = [];
      return selectedTags.placementTagsIn;
    default:
      return state;
  }
};

export const sponsoredContentPlacementTagsExReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHSPONSOREDCONTENTPLACEMENTTAGSEX:
      action.selected.forEach(function (id) {
        action.payload.forEach((tag) => {
          if (tag.key === id) {
            tag.disabled = true;
          }
        });
      });
      return { ...state, ..._.mapKeys(action.payload, "key") };
    case actionTypes.APPLYSPONSOREDCONTENTPLACEMENTTAGSEX:
      selectedTags.placementTagsEx = [];
      const ids = action.payload;
      ids.forEach(function (id) {
        selectedTags.placementTagsEx.push(state[id]);
      });
      return { ..._.mapKeys(selectedTags.placementTagsEx, "key") };
    case actionTypes.CLEARSEARCHSPONSOREDCONTENTPLACEMENTTAGEX:
      return { ..._.mapKeys(selectedTags.placementTagsEx, "key") };
    case actionTypes.RESETSCPLACEMENTTAGEX:
      selectedTags.placementTagsEx = [];
      return selectedTags.placementTagsEx;
    default:
      return state;
  }
};

export const sponsoredContentOrgTagsInReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHSPONSOREDCONTENTORGTAGSIN:
      action.selected.forEach(function (id) {
        action.payload.forEach((tag) => {
          if (tag.key === id) {
            tag.disabled = true;
          }
        });
      });
      return { ...state, ..._.mapKeys(action.payload, "key") };
    case actionTypes.APPLYSPONSOREDCONTENTORGTAGSIN:
      selectedTags.orgTagsIn = [];
      const ids = action.payload;
      ids.forEach(function (id) {
        selectedTags.orgTagsIn.push(state[id]);
      });
      return { ..._.mapKeys(selectedTags.orgTagsIn, "key") };
    case actionTypes.CLEARSEARCHSPONSOREDCONTENTORGTAGIN:
      return { ..._.mapKeys(selectedTags.orgTagsIn, "key") };
    case actionTypes.RESETSCORGTAGIN:
      selectedTags.orgTagsIn = [];
      return selectedTags.orgTagsIn;
    default:
      return state;
  }
};

export const sponsoredContentOrgTagsExReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHSPONSOREDCONTENTORGTAGSEX:
      action.selected.forEach(function (id) {
        action.payload.forEach((tag) => {
          if (tag.key === id) {
            tag.disabled = true;
          }
        });
      });
      return { ...state, ..._.mapKeys(action.payload, "key") };
    case actionTypes.APPLYSPONSOREDCONTENTORGTAGSEX:
      selectedTags.orgTagsEx = [];
      const ids = action.payload;
      ids.forEach(function (id) {
        selectedTags.orgTagsEx.push(state[id]);
      });
      return { ..._.mapKeys(selectedTags.orgTagsEx, "key") };
    case actionTypes.CLEARSEARCHSPONSOREDCONTENTORGTAGEX:
      return { ..._.mapKeys(selectedTags.orgTagsEx, "key") };
    case actionTypes.RESETSCORGTAGEX:
      selectedTags.orgTagsEx = [];
      return selectedTags.orgTagsEx;
    default:
      return state;
  }
};

export const sponsoredContentCountryReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHSPONSOREDCONTENTCOUNTRY:
      return {
        ...state,
        ..._.mapKeys(action.payload, "name"),
      };
    case actionTypes.ADDSPONSOREDCONTENTCONTRIES:
      sponsoredContentSelData.country = [];
      const countryData = action.payload;
      countryData.forEach(function (countryId) {
        sponsoredContentSelData.country.push(state[countryId]);
      });
      return { ..._.mapKeys(sponsoredContentSelData.country, "name") };
    case actionTypes.CLEARSEARCHSPONSOREDCONTENTCOUNTRY:
      //return (state = []);
      return { ...state, ..._.mapKeys(sponsoredContentSelData.country, "id") };
    case actionTypes.RESETSCCOUNTRY:
      sponsoredContentSelData.country = [];
      return sponsoredContentSelData.country;
    default:
      return state;
  }
};

export const sponsoredContentEngOrgReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHSPONSOREDCONTENTENGORGS:
      return {
        ..._.mapKeys(sponsoredContentSelData.engOrgs, "uuid"),
        ..._.mapKeys(action.payload, "uuid"),
      };
    case actionTypes.ADDSPONSOREDCONTENTENGORGS:
      sponsoredContentSelData.engOrgs = [];
      const pgengorgIds = action.payload;
      pgengorgIds.forEach(function (pgengorgId) {
        sponsoredContentSelData.engOrgs.push(state[pgengorgId]);
      });
      return { ..._.mapKeys(sponsoredContentSelData.engOrgs, "uuid") };
    case actionTypes.CLEARSEARCHSPONSOREDCONTENTENGORGS:
      return (state = []);
    case actionTypes.RESETSCENGORGS:
      sponsoredContentSelData.engOrgs = [];
      return sponsoredContentSelData.engOrgs;
    default:
      return state;
  }
};

export const sponsoredContentFirmographicOrgsTypeReducer = (
  state = [],
  action
) => {
  switch (action.type) {
    case actionTypes.FETCHSPONSOREDCONTENTFIRMOGRAPHICORGSTYPE:
      return { ...state, ..._.mapKeys(action.payload, "value") };
    case actionTypes.APPLYSPONSOREDCONTENTORGSTYPE:
      selectedTags.orgsType = [];
      const ids = action.payload;
      ids.forEach(function (id) {
        selectedTags.orgsType.push(state[id]);
      });
      return { ..._.mapKeys(selectedTags.orgsType, "value") };
    case actionTypes.CLEARSEARCHSPONSOREDCONTENTORGSTYPE:
      return { ..._.mapKeys(selectedTags.orgsType, "value") };
    case actionTypes.RESETSCORGSTYPE:
      selectedTags.orgsType = [];
      return selectedTags.orgsType;
    default:
      return state;
  }
};

export const sponsoredContentFirmographicOrgsSizeReducer = (
  state = [],
  action
) => {
  switch (action.type) {
    case actionTypes.FETCHSPONSOREDCONTENTORGSSIZE:
      return { ...state, ..._.mapKeys(action.payload, "value") };
    case actionTypes.APPLYSPONSOREDCONTENTORGSSIZE:
      selectedTags.orgsSize = [];
      const ids = action.payload;
      ids.forEach(function (id) {
        selectedTags.orgsSize.push(state[id]);
      });
      return { ..._.mapKeys(selectedTags.orgsSize, "value") };
    case actionTypes.CLEARSEARCHSPONSOREDCONTENTORGSSIZE:
      return { ..._.mapKeys(selectedTags.orgsSize, "value") };
    case actionTypes.RESETSCORGSSIZE:
      selectedTags.orgsSize = [];
      return selectedTags.orgsSize;
    default:
      return state;
  }
};

export const sponsoredContentFirmographicOrgsRevenueReducer = (
  state = [],
  action
) => {
  switch (action.type) {
    case actionTypes.FETCHSPONSOREDCONTENTORGSREVENUE:
      return { ...state, ..._.mapKeys(action.payload, "value") };
    case actionTypes.APPLYSPONSOREDCONTENTREVENUE:
      selectedTags.orgsRevenue = [];
      const ids = action.payload;
      ids.forEach(function (id) {
        selectedTags.orgsRevenue.push(state[id]);
      });
      return { ..._.mapKeys(selectedTags.orgsRevenue, "value") };
    case actionTypes.CLEARSEARCHSPONSOREDCONTENTORGSREVENUE:
      return { ..._.mapKeys(selectedTags.orgsRevenue, "value") };
    case actionTypes.RESETSCORGSREVENUE:
      selectedTags.orgsRevenue = [];
      return selectedTags.orgsRevenue;
    default:
      return state;
  }
};

export const sponsoredContentFirmographicSectorReducer = (
  state = [],
  action
) => {
  switch (action.type) {
    case actionTypes.FETCHSCSECTOR:
      return { ...state, ..._.mapKeys(action.payload, "sector") };
    case actionTypes.APPLYSCSECTOR:
      return { ...state, ..._.mapKeys(selectedTags.sector, "sector") };
    case actionTypes.CLEARSEARCHSCSECTOR:
      return { ...state, ..._.mapKeys(selectedTags.sector, "sector") };
      case actionTypes.RESETSCSECTOR:
        selectedTags.sector = [];
        return selectedTags.sector;
    default:
      return state;
  }
};

export const sponsoredContentFirmographicIndustryGroupReducer = (
  state = [],
  action
) => {
  switch (action.type) {
    case actionTypes.FETCHSCINDUSTRYGROUP:
      return { ...state, ..._.mapKeys(action.payload, "industry_group") };
    case actionTypes.APPLYSCINDUSTRYGROUP:
      return { ...state, ..._.mapKeys(action.payload, "industry_group") };
    case actionTypes.CLEARSEARCHSCINDUSTRYGROUP:
      return {
        ...state,
        ..._.mapKeys(selectedTags.industry_group, "industry_group"),
      };
    case actionTypes.RESETSCORGSINDUSTRYGROUP:
      selectedTags.industry_group = [];
      return selectedTags.industry_group;
    default:
      return state;
  }
};

export const sponsoredContentFirmographicIndustryReducer = (
  state = [],
  action
) => {
  switch (action.type) {
    case actionTypes.FETCHSCINDUSTRY:
      return { ...state, ..._.mapKeys(action.payload, "industry") };
    case actionTypes.APPLYSCINDUSTRY:
      return { ...state, ..._.mapKeys(action.payload, "industry") };
    case actionTypes.CLEARSEARCHSCINDUSTRY:
      return { ...state, ..._.mapKeys(selectedTags.industry, "industry") };
    case actionTypes.RESETSCORGSINDUSTRY:
      selectedTags.industry = [];
      return selectedTags.industry;
    default:
      return state;
  }
};

export const sponsoredContentFirmographicSubIndustryReducer = (
  state = [],
  action
) => {
  switch (action.type) {
    case actionTypes.FETCHSCSUBINDUSTRY:
      return { ...state, ..._.mapKeys(action.payload, "sub_industry") };
    case actionTypes.APPLYSCSUBINDUSTRY:
      return { ...state, ..._.mapKeys(action.payload, "sub_industry") };
    case actionTypes.CLEARSEARCHSCSUBINDUSTRY:
      return {
        ...state,
        ..._.mapKeys(selectedTags.sub_industry, "sub_industry"),
      };
    case actionTypes.RESETSCSUBINDUSTRY:
      selectedTags.sub_industry = [];
      return selectedTags.sub_industry;
    default:
      return state;
  }
};

export const sponsoredContentSenioritiesInReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHSCSENIORITIESIN:
      action.selected.forEach(function (id) {
        action.payload.forEach((tag) => {
          if (tag.id === id) {
            tag.disabled = true;
          }
        });
      });
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case actionTypes.APPLYSCSENIORITIESIN:
      selectedTags.senioritiesIN = [];
      const ids = action.payload;
      ids.forEach(function (id) {
        selectedTags.senioritiesIN.push(state[id]);
      });
      return { ..._.mapKeys(selectedTags.senioritiesIN, "id") };
    case actionTypes.CLEARSEARCHSCSENIORITIESIN:
      return { ..._.mapKeys(selectedTags.senioritiesIN, "id") };
    case actionTypes.RESETSCSENIORITIESIN:
      selectedTags.senioritiesIN = [];
      return selectedTags.senioritiesIN;
    default:
      return state;
  }
};

export const sponsoredContentSenioritiesExReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHSCSENIORITIESEX:
      action.selected.forEach(function (id) {
        action.payload.forEach((tag) => {
          if (tag.id === id) {
            tag.disabled = true;
          }
        });
      });
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case actionTypes.APPLYSCSENIORITIESEX:
      selectedTags.senioritiesEX = [];
      const ids = action.payload;
      ids.forEach(function (id) {
        selectedTags.senioritiesEX.push(state[id]);
      });
      return { ..._.mapKeys(selectedTags.senioritiesEX, "id") };
    case actionTypes.CLEARSEARCHSCSENIORITIESEX:
      return { ..._.mapKeys(selectedTags.senioritiesEX, "id") };
    case actionTypes.RESETSCSENIORITIESEX:
      selectedTags.senioritiesEx = [];
      return selectedTags.senioritiesEx;
    default:
      return state;
  }
};

export const sponsoredContentJobfunctionsInReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHSCJOBFUNCTIONSIN:
      action.selected.forEach(function (id) {
        action.payload.forEach((tag) => {
          if (tag.id === id) {
            tag.disabled = true;
          }
        });
      });
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case actionTypes.APPLYSCJOBFUNCTIONSIN:
      selectedTags.jobfunctionsIN = [];
      const ids = action.payload;
      ids.forEach(function (id) {
        selectedTags.jobfunctionsIN.push(state[id]);
      });
      return { ..._.mapKeys(selectedTags.jobfunctionsIN, "id") };
    case actionTypes.CLEARSEARCHSCJOBFUNCTIONSIN:
      return { ..._.mapKeys(selectedTags.jobfunctionsIN, "id") };
    case actionTypes.RESETSCJOBFUNCTIONSIN:
      selectedTags.jobfunctionsIN = [];
      return selectedTags.jobfunctionsIN;
    default:
      return state;
  }
};

export const sponsoredContentJobfunctionsExReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHSCJOBFUNCTIONSEX:
      action.selected.forEach(function (id) {
        action.payload.forEach((tag) => {
          if (tag.id === id) {
            tag.disabled = true;
          }
        });
      });
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case actionTypes.APPLYSCJOBFUNCTIONSEX:
      selectedTags.jobfunctionsEX = [];
      const ids = action.payload;
      ids.forEach(function (id) {
        selectedTags.jobfunctionsEX.push(state[id]);
      });
      return { ..._.mapKeys(selectedTags.jobfunctionsEX, "id") };
    case actionTypes.CLEARSEARCHSCJOBFUNCTIONSEX:
      return { ..._.mapKeys(selectedTags.jobfunctionsEX, "id") };
    case actionTypes.RESETSCJOBFUNCTIONSEX:
      selectedTags.jobfunctionsEX = [];
      return selectedTags.jobfunctionsEX;
    default:
      return state;
  }
};

export const ScPageviewTitleReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHSCTITLE:
      return { ..._.mapKeys(action.payload, "id") };
    case actionTypes.CLEARSEARCHSCPAGETITLE:
      return (state = []);
    default:
      return state;
  }
};

export const ScUrlSourceReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHSCURLSOURCE:
      return action.payload;
    case actionTypes.CLEARSEARCHSCURLSOURCE:
      return (state = []);
    default:
      return state;
  }
};

export const ScUrlMediumReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHSCURLMEDIUM:
      return action.payload;
    case actionTypes.CLEARSEARCHSCURLMEDIUM:
      return (state = []);
    default:
      return state;
  }
};

export const ScUrlCampaignsReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHSCURLCAMPAIGNS:
      return action.payload;
    case actionTypes.CLEARSEARCHSCURLCAMPAIGNS:
      return (state = []);
    default:
      return state;
  }
};

export const ScUrlTermReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHSCURLTERM:
      return action.payload;
    case actionTypes.CLEARSEARCHSCURLTERM:
      return (state = []);
    default:
      return state;
  }
};

export const ScUrlContentReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHSCURLCONTENT:
      return action.payload;
    case actionTypes.CLEARSEARCHSCURLCONTENT:
      return (state = []);
    default:
      return state;
  }
};

export const scEngMemberReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHSCENGMEMBER:
      return {
        ..._.mapKeys(sponsoredContentSelData.engMember, "user_uuid"),
        ..._.mapKeys(action.payload, "user_uuid"),
      };
    case actionTypes.ADDSCENGMEMBER:
      sponsoredContentSelData.engMember = [];
      const pgengorgIds = action.payload;
      pgengorgIds.forEach(function (pgengorgId) {
        sponsoredContentSelData.engMember.push(state[pgengorgId]);
      });
      return { ..._.mapKeys(sponsoredContentSelData.engMember, "user_uuid") };
    case actionTypes.CLEARSEARCHSCENGMEMBER:
      return (state = []);
    case actionTypes.RESETSCENGMEMBER:
      sponsoredContentSelData.engMember = [];
      return sponsoredContentSelData.engMember;
    default:
      return state;
  }
};

export const scPrimaryTopicReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCHSCPRIMARYTOPIC:
      return {
        ..._.mapKeys(sponsoredContentSelData.primaryTopic, "tag_id"),
        ..._.mapKeys(action.payload, "tag_id"),
      };
    case actionTypes.ADDSCPRIMARYTOPIC:
      sponsoredContentSelData.primaryTopic = [];
      const pgengorgIds = action.payload;
      pgengorgIds.forEach(function (pgengorgId) {
        sponsoredContentSelData.primaryTopic.push(state[pgengorgId]);
      });
      return { ..._.mapKeys(sponsoredContentSelData.primaryTopic, "tag_id") };
    case actionTypes.CLEARSEARCHSCPRIMARYTOPIC:
      return (state = []);
    case actionTypes.RESETSCPRIMARYTOPIC:
      sponsoredContentSelData.primaryTopic = [];
      return sponsoredContentSelData.primaryTopic;
    default:
      return state;
  }
};

export const sponsoredContentFetchDataReducer = (
  state = sponsoredContentFetchData,
  action
) => {
  switch (action.type) {
    case actionTypes.APPLYSCFILTER:
      return {
        ...state,
        filterData: action.payload,
        isFilApplied: true,
        isLoadingChart: true,
        placementPageviewSummary: {},
        scCampaignDetail: [],
        scCampaignPageDetail: {},
        pageviewDetail: {},
        pageviewDetailed: {},
        pageCount: {},
        sponsoredContentCharts: [],
        isLoadingPlacementPageviewSummary: true,
        isLoadingScCampaignDetail: true,
        isLoadingPageviewDetailed: true,
        isLoadingScSummary: true,
        topicPageviewSummarycolumn: null,
        placementPageviewSummarycolumn: null,
        column: null,
        summaryColumn: null,
        topicPageviewSummarydirection: null,
        placementPageviewSummarydirection: null,
        direction: null,
        summaryDirection: null,
        campaignPageDirection: null,
        scCampaignPageviewDirection: null,
        scCamapignSummaryPageCount: 2,
        scCampaignPageviewPageCount: 2,
        scCampaignPageCount: 2,
        placementPageCount: 2,
        scSummary: {},
        isLoadingScCampaignPageDetail: true,
        resSponsoredSummary: null,
        resSponsoredCampaignPageDetails: null,
        resSponsoredCampaignPageviewDetails: null,
      };
    // case actionTypes.PLACEMENTPAGEVIEWSUMMARY:
    //   if (action.payload === null) {
    //     return {
    //       ...state,
    //       isLoadingPlacementPageviewSummary: false,
    //       placementPageviewSummaryLoad: true,
    //     };
    //   } else {
    //     return {
    //       ...state,
    //       placementPageviewSummary: action.payload,
    //       isLoadingPlacementPageviewSummary: false,
    //     };
    //   }
    // case actionTypes.MOREPLACEMENTPAGEVIEWSUMMARY:
    //   if (action.payload === null) {
    //     return {
    //       ...state,
    //       isLoadingPlacementPageviewSummary: false,
    //     };
    //   } else {
    //     return {
    //       ...state,
    //       placementPageviewSummary: [
    //         ...state.placementPageviewSummary,
    //         ...action.payload,
    //       ],
    //       isLoadingPlacementPageviewSummary: false,
    //     };
    //   }
    case actionTypes.PAGEVIEWREPORTLOADING:
      if (action.payload === "/reports/pageviews/detail") {
        return {
          ...state,
          isLoadingScSummary: true,
          countAPI: false,
          filterData: action.filterData,
        };
      } else if (
        action.payload === "/reports/pageviews/summary/placemenet_tags"
      ) {
        return {
          ...state,
          isLoadingPlacementPageviewSummary: true,
          filterData: action.filterData,
        };
      } else if (action.payload === "reports/pageviews/summary/topic_tags") {
        return {
          ...state,
          isLoadingScCampaignDetail: true,
          filterData: action.filterData,
        };
      } else {
        return;
      }

    case actionTypes.SCCAMPAIGNDETAILS:
      if (action.payload === null) {
        return {
          ...state,
          isLoadingScCampaignDetail: false,
          topicPageviewSummaryLoad: true,
        };
      } else {
        return {
          ...state,
          scCampaignDetail: action.payload,
          isLoadingScCampaignDetail: false,
        };
      }
    // case actionTypes.MORETOPICPAGEVIEWSUMMARYLOADING:
    //   return {
    //     ...state,
    //     isLoadingScCampaignDetail: true,
    //   };
    // case actionTypes.MORETOPICPAGEVIEWSUMMARY:
    //   if (action.payload === null) {
    //     return {
    //       ...state,
    //       isLoadingScCampaignDetail: false,
    //     };
    //   } else {
    //     return {
    //       ...state,
    //       scCampaignDetail: action.payload,
    //       isLoadingScCampaignDetail: false,
    //     };
    //   }
    case actionTypes.PAGEVIEWCOUNTAPIHIT:
      return {
        ...state,
        countAPI: false,
      };
    case actionTypes.MOREPAGEVIEWDETAILEDLOADING:
      return {
        ...state,
        isLoadingPageviewDetailed: true,
      };
    case actionTypes.MOREPAGEVIEWDETAILED:
      if (action.payload === null) {
        return {
          ...state,
          isLoadingPageviewDetailed: false,
        };
      } else {
        return {
          ...state,
          pageviewDetailed: [...state.pageviewDetailed, ...action.payload],
          isLoadingPageviewDetailed: false,
        };
      }
    case actionTypes.SCSUMMARY:
      if (action.payload === null || _.isEmpty(action.payload)) {
        return {
          ...state,
          isLoadingScSummary: false,
        };
      } else {
        return {
          ...state,
          isLoadingScSummary: false,
          scSummary: action.payload,
          filterData: action.filterData,
          countAPI: true,
        };
      }
    case actionTypes.MORESCCAMPAIGNSUMMARYLOADING:
      return {
        ...state,
        isLoadingScSummary: true,
      };

    case actionTypes.MORESCCAMPAIGNPAGELOADING:
      return {
        ...state,
        isLoadingScCampaignPageDetail: true,
      };

    case actionTypes.SCCAMPAIGNPAGEDETAILSUMMARY:
      if (action.payload === null || _.isEmpty(action.payload)) {
        return {
          ...state,
          isLoadingScCampaignPageDetail: false,
        };
      } else {
        return {
          ...state,
          isLoadingScCampaignPageDetail: false,
          scCampaignPageDetail: transformSponsoredContentPageDetails(action.payload),
          filterData: action.filterData,
          countAPI: true,
        };
      }
    case actionTypes.MORESCSUMMARY:
      if (action.payload === null) {
        return {
          ...state,
          isLoadingScSummary: false, resSponsoredSummary: true
         // resSponsoredSummary: action.sponsoredPayload.map((name)=> name.isSponsored)
        };
      } else {
        return {
          ...state,
          scSummary: [...state.scSummary, ...action.payload],
          isLoadingScSummary: false,
        };
      }
    case actionTypes.MORESCCAMPAIGNDETAIL:
      if (action.payload === null) {
        return {
          ...state,
          isLoadingScCampaignPageDetail: false, resSponsoredCampaignPageDetails: true
         // resSponsoredCampaignPageDetails: action.sponsoredPayload.map((name)=> name.isSponsored)
        };
      } else {
        return {
          ...state,
          scCampaignPageDetail: [...state.scCampaignPageDetail, ...action.payload.map((element)=>{
            return {
              ...element, 
              primary_topic: element.primary_topic === "" ? "N/A" : element.primary_topic,
              str_topic_tags: element.str_topic_tags === "" ? "N/A" : element.str_topic_tags,   
              str_placement_tags: element.str_placement_tags === "" ? "N/A" : element.str_placement_tags,
          }})],
          isLoadingScCampaignPageDetail: false,
        };
      }
    // case actionTypes.MORESCCAMPAIGNPAGELOADING:
    //   return {
    //     ...state,
    //     isLoadingScSummary: true,
    //   };

    case actionTypes.MORESCCAMPAIGNPAGEVIEWDETAILLOADING:
      return {
        ...state,
        isLoadingScCampaignDetail: true,
      };
    case actionTypes.MORESCCAMPAIGNPAGEVIEWDETAIL:
      if (action.payload === null) {
        return {
          ...state,
          isLoadingScCampaignDetail: false, resSponsoredCampaignPageviewDetails: true
          //resSponsoredCampaignPageviewDetails: action.sponsoredPayload.map((name)=> name.isSponsored)
        };
      } else {
        return {
          ...state,
          scCampaignDetail: [...state.scCampaignDetail, ...action.payload],
          isLoadingScCampaignDetail: false,
        };
      }
    case actionTypes.SPPONSOREDCHARTS:
      //let checkedState = action.filterData.selCutomRangeChkBox && action.filterData.appliedDate === "Custom Date Range";
      return {
        ...state,
        //	checkboxState: checkedState,
        sponsoredContentCharts: action.payload,
        isLoadingChart: false,
      };
    case actionTypes.SCCAMPAIGNSUMMARYCSV:
      return {
        ...state,
        scCampaignSummaryCsv: action.csv,
        isLoadingCampaignSummaryCsv: false,
        downloadFileName: action.downloadFileName
      };
    case actionTypes.SCCAMPAIGNPAGEVIEWDETAILSCSV:
        return {
          ...state,
          scCampaignPageviewDetailsCsv: action.csv,
          isLoadingCampaignPageviewDetailsCsv: false,
          downloadFileName: action.downloadFileName
      };
    case actionTypes.SCDETAILEXCEL:
      if (action.payload === "reports/pageviews/sponsored_summary") {
        return {
          ...state,
          postEmailUpdatePageSummary: action.email,
          isLoadingCampaignSummaryCsv: false,
        };
      } else if (action.payload === "reports/pageviews/sponsored_details") {
        return {
          ...state,
          postEmailUpdatePageviewDetails: action.email,
          postEmailCsvUpdatePageviewDetails: action.email
          // isLoadingCampaignPageviewDetailsCsv: false,
        }; 
      }  else if (action.payload === "reports/pageviews/sponsored_detail/v1") {
        return {
          ...state,
          postEmailUpdatePageviewDetail: action.email,
          // isLoadingCampaignPageviewDetailsCsv: false,
        }; 
      }
    /* falls through */
    case actionTypes.CAMPAIGNSUMMARYCSVLOADING:
      return {
        ...state,
        isLoadingCampaignSummaryCsv: true,
      };
    case actionTypes.CAMPAIGNPAGEDETAILSCSVLOADING:
      return {
        ...state,
        isLoadingCampaignPageviewDetailsCsv: true,
      };
    case actionTypes.SORTSCCAMPAIGN:
      const directionValueCampSummary = state.summaryDirection === "ascending" ? "desc" : "asc";
      const scSummary= _.orderBy(state.scSummary, [(item) => typeof(item[action.column]) === "string" ? item[action.column].toLowerCase() : item[action.column]], [directionValueCampSummary])
      const summaryDirection= state.summaryDirection === "ascending" ? "descending" : "ascending"
      if (state.column === action.column) {
        if (action.column === "Order.name") {
					return {
					  ...state,
					  column: action.column,
					  scSummary: state.summaryDirection === "ascending" ?
						_.orderBy(state.scSummary, [data => data.Order.name ? (data.Order.name).toUpperCase() : data.Order.name], ['desc'])
						:
						_.orderBy(state.scSummary, [data => data.Order.name ? (data.Order.name).toUpperCase() : data.Order.name], ['asc']),
					  summaryDirection: state.summaryDirection === "ascending" ? "descending" : "ascending",
					};
				}
				return {
					...state,
          scSummary,
					summaryDirection
				};
			} else{
        return {
          ...state,
          column: action.column,
          scSummary,
          summaryDirection
        };
      }
      
      case actionTypes.SORTSCCAMPAIGNPAGEDETAIL:
        const directionValuePageDetail = state.campaignPageDirection === "ascending" ? "desc" : "asc";
        const scCampaignPageDetail = _.orderBy(state.scCampaignPageDetail, [(item) => typeof(item[action.column]) === "string" ? item[action.column].toLowerCase() : item[action.column]], [directionValuePageDetail]);
        const campaignPageDirection = state.campaignPageDirection === "ascending" ? "descending" : "ascending"
        if (state.column === action.column) {
          if (state.column === "Order.name") {
            return {
              ...state,
              scCampaignPageDetail: state.campaignPageDirection === "ascending" ?
                _.orderBy(state.scCampaignPageDetail, [(data) => data.campaign_data.Order.name ? data.campaign_data.Order.name.toLowerCase() : data.campaign_data.Order.name], ['desc'])
                :
                _.orderBy(state.scCampaignPageDetail, [(data) => data.campaign_data.Order.name ? data.campaign_data.Order.name.toLowerCase() : data.campaign_data.Order.name], ['asc']),
              campaignPageDirection: state.campaignPageDirection === "ascending" ? "descending" : "ascending",
            };
          } else {
            return {
              ...state,
               scCampaignPageDetail,
              campaignPageDirection
            };
          }
        } else {
          return {
            ...state,
            column: action.column,
            scCampaignPageDetail,
            campaignPageDirection
          };
        }

    case actionTypes.SORTSCCAMPAIGNDETAILS:
      let directionPageviewValue = state.scCampaignPageviewDirection === "ascending" ? "desc" : "asc"
      const scCampaignPageviewDirection =  state.scCampaignPageviewDirection === "ascending" ? "descending" : "ascending"
      const scCampaignDetail = _.orderBy(state.scCampaignDetail,[(item) => typeof(item[action.column]) === "string" ?  item[action.column].toLowerCase() : item[action.column]],[directionPageviewValue])
    
      if (state.column === action.column) {
        return {
          ...state,
          scCampaignDetail,
        //scCampaignDetail: _.orderBy(state.scCampaignDetail,[(item) => typeof(item[action.column]) === "string" ?  item[action.column].toLowerCase() : item[action.column]],[directionPageviewValue]),
        scCampaignPageviewDirection
        };
      } else{
        return {
          ...state,
          column: action.column,
          scCampaignDetail,
          scCampaignPageviewDirection,
        };
      }

    case actionTypes.CLEARSCSORT:
      return {
        ...state,
        column: null,
        direction: null,
        summaryDirection: null,
        campaignPageDirection: null,
        scCampaignPageviewDirection: null,
      };
    case actionTypes.SETSCCAMPAIGNSUMMARYPGCOUNT:
      return {
        ...state,
        scCamapignSummaryPageCount: state.scCamapignSummaryPageCount + 1,
      };
    case actionTypes.SETSCCAMPAIGNPAGEPGCOUNT:
      return { ...state, scCampaignPageCount: state.scCampaignPageCount + 1 };
    case actionTypes.SETSCCAMPAIGNPAGEVIEWPGCOUNT:
      return {
        ...state,
        scCampaignPageviewPageCount: state.scCampaignPageviewPageCount + 1,
      };

    case actionTypes.SCEMAILCAMAPIGNSUMMARY:
      return {
        ...state,
        emailPageSummary: action.payload.email,
        cardPopupPageSummary: action.payload.class,
      };
    case actionTypes.SCEMAILCAMPAIGNPAGEDETAIL:
      return {
        ...state,
        emailPageDetail: action.payload.email,
        cardPopupPageDetail: action.payload.class,
      };
    case actionTypes.SCEMAILCAMPAIGNPAGEDETAILS:
      return {
        ...state,
        emailPageDetails: action.payload.email,
        cardPopupPageDetails: action.payload.class,
      };
    case actionTypes.SETSCCAMPAIGNSUMMARYPOPUPCLOSE:
      return {
        ...state,
        cardPopupPageSummary: action.payload,
      };
    case actionTypes.SETSCCAMPAIGNPAGEVIEWPOPUPCLOSE: {
      return {
        ...state,
        cardPopupPageDetail: action.payload,
      };
    }
    case actionTypes.SETSCCAMPAIGNPAGEDETAILSPOPUPCLOSE: {
      return {
        ...state,
        cardPopupPageDetails: action.payload,
      };
    }
    case actionTypes.SETSCPOSTEMAILUPDATE:
      return {
        ...state,
        postEmailUpdatePageviewDetails: action.payload,
      };
    case actionTypes.SETSCCSVPOSTEMAILUPDATE:
      return {
        ...state,
        postEmailCsvUpdatePageviewDetails: action.payload,
      };  
    case actionTypes.SETSCPOSTEMAILUPDATEPAGEDETAIL:
      return {
        ...state,
        postEmailUpdatePageviewDetail: action.payload,
      };  
    case actionTypes.SETSCPOSTEMAILUPDATECAMPAIGNSUMMARY:
      return {
        ...state,
        postEmailUpdatePageSummary: action.payload,
      };
    case actionTypes.GETSCSUMMARYDYNAMICTABLEHEADER:
      return {
        ...state, getDynamicSummaryHeaderData: action.payload
      }
    case actionTypes.SENDSCSUMMARYDYANMICTABLEHEADER:
      return {
        ...state, dynamicSummaryHeaderArray: action.payload
      }
    case actionTypes.GETSCPAGEDETAILSDYNAMICTABLEHEADER:
      return {
        ...state, getDynamicPageDetailsHeaderData: action.payload
      }
    case actionTypes.SENDSCPAGEDETAILSDYANMICTABLEHEADER:
      return {
        ...state, dynamicPageDetailsHeaderArray: action.payload
      }
    case actionTypes.GETSCPAGEVIEWDETAILSDYNAMICTABLEHEADER:
      return {
        ...state, getDynamicPageviewDetailsHeaderData: action.payload
      }
    case actionTypes.SENDSCPAGEVIEWDETAILSDYANMICTABLEHEADER:
      return {
        ...state, dynamicPageviewDetailsHeaderArray: action.payload
      }
    case actionTypes.RESETSTATESSCPAGEVIEWDETAILS:
      return { ...state, scCampaignDetail: [], isLoadingScCampaignDetail: false };
    case actionTypes.UPDATESCPAGEVIEWDETAILSLOADER:
      return { ...state, isLoadingScCampaignDetail: true };
    default:
      return state;
  }
};

export const spnosoredCSMReducer = createCommonReducer(
	selectedTags.csmArray,
	{
		FETCH: actionTypes.FETCH_SPONSORED_CSM,
		ADD: actionTypes.ADD_SPONSORED_CSM, 
    SELECTINITIAL: actionTypes.SELECT_SPONSORED_CSM,
		CLEAR: actionTypes.CLEAR_SPONSORED_CSM,
	},
	'id'
);