const processAdActivity = (adActivity, reqActions, engagementActions, session) => {
    const filteredOnPageAdActivity = [];

    // Helper function to check and process actions
    const processActivity = (activity) => {
        if (reqActions.includes(activity.action) || reqActions.includes(activity.page_elements)) {
            filteredOnPageAdActivity.push(activity);

            if (engagementActions.includes(activity.action)) {
                session.session_engagement_count++;
            }
        }
    };

    // Process the main adActivity
    processActivity(adActivity);

    // Process on_page_activity if it exists
    adActivity.on_page_activity?.forEach(processActivity);

    // Process on_page_ad_activity recursively
    adActivity.on_page_ad_activity && Object.values(adActivity.on_page_ad_activity).forEach((nestedAdActivity) => {
        filteredOnPageAdActivity.push(
            ...processAdActivity(nestedAdActivity, reqActions, engagementActions, session)
        );
    });

    return filteredOnPageAdActivity;
};


export const filterByActions = (data, action, num, reqActions, engagementActions) => {
    if (action === 'actions') {
        return data.map((org) => {
            const organization = {
                ...org,
                user_activities: [],
                organization_pageviews: 0,
                organization_session_count: 0,
                organization_other_actions: 0,
                organization_engagement_count: 0
            }

            const filteredUserActivities = []
            org.user_activities.forEach((user) => {
                let userActivity = {
                    ...user,
                    sessions: {},
                    user_pageviews: 0,
                    user_session_count: 0,
                    user_other_actions: 0,
                    user_engagement_count: 0
                }

                for (const [session_uuid, s] of Object.entries(user.sessions)) {
                    const session = {
                        ...s,
                        pages: {},
                        session_pageviews: 0,
                        session_other_actions: 0,
                        session_engagement_count: 0
                    }

                    for (const [group_uuid, p] of Object.entries(s.pages)) {
                        const page = {
                            ...p
                        }

                        if (reqActions.length === 1 && reqActions[0] === "pageview") {
                            if (reqActions.includes(page.pageview)) {
                                session.pages[group_uuid] = {
                                    ...page,
                                    on_page_activity: null
                                };
                            }

                            continue;
                        }

                        // Filter on_page_activity based on reqActions
                        const filteredOnPageActivity = [];
                        page?.on_page_activity && page.on_page_activity.forEach((activity) => {
                            if (reqActions.includes(activity.action) || reqActions.includes(activity.page_elements)) {
                                filteredOnPageActivity.push(activity);

                                if (engagementActions.includes(activity.action)) {
                                    session.session_engagement_count++;
                                }
                            }
                        });

                        // Handle on_page_ad_activity logic, including recursion
                        const filteredOnPageAdActivity = [];
                        page?.on_page_ad_activity && Object.values(page.on_page_ad_activity).forEach((adActivity) => {
                            if (adActivity.ad_type === "Roadblock") {
                                filteredOnPageAdActivity.push(
                                    ...processAdActivity(adActivity, reqActions, engagementActions, session)
                                );
                            } else {
                                filteredOnPageAdActivity.push(
                                    ...processAdActivity(adActivity, reqActions, engagementActions, session)
                                );
                            }
                        });

                        if (filteredOnPageActivity.length > 0) {
                            session.session_other_actions = filteredOnPageActivity.length;
                            session.pages[group_uuid] = {
                                ...page,
                                on_page_activity: filteredOnPageActivity
                            };
                        }

                        // If there are filtered activities, add them to the session
                        if (filteredOnPageActivity.length > 0 || filteredOnPageAdActivity.length > 0) {
                            session.session_other_actions = filteredOnPageActivity.length + filteredOnPageAdActivity.length;
                            session.pages[group_uuid] = {
                                ...page,
                                on_page_activity: filteredOnPageActivity,
                                on_page_ad_activity: filteredOnPageAdActivity
                            };
                        }
                    };

                    session.session_pageviews = Object.values(session.pages).length
                    if (session.session_pageviews === 0) {
                        continue;
                    }

                    userActivity.user_pageviews += session.session_pageviews
                    userActivity.user_other_actions += session.session_other_actions
                    userActivity.user_engagement_count += session.session_engagement_count
                    userActivity.user_session_count += 1
                    userActivity.sessions[session_uuid] = session
                };

                if (userActivity.user_pageviews === 0) {
                    return;
                }

                organization.organization_pageviews += userActivity.user_pageviews
                organization.organization_session_count += userActivity.user_session_count
                organization.organization_other_actions += userActivity.user_other_actions
                organization.organization_engagement_count += userActivity.user_engagement_count
                filteredUserActivities.push(userActivity)
            });

            if (filteredUserActivities.length === 0) {
                return null;
            }

            organization.user_activities = filteredUserActivities
            return organization;
        }).filter(Boolean);
    } else if (action === 'organization_actions_taken') {
        return data.filter((organization) => organization.organization_other_actions >= num);
    } else if (action === 'member_actions_taken') {
        const filteredActivities = data.map(org => {
            const filteredUsers = Object.values(org.user_activities).filter(activity => activity.user_other_actions >= num);
            const organization_other_actions = filteredUsers.reduce((sum, activity) => sum + activity.user_other_actions, 0);
            return {
                ...org,
                organization_other_actions,
                user_activities: filteredUsers
            };
        }).filter(org => org.user_activities.length > 0);

        return filteredActivities;
    }
}
