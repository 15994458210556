import React from "react";
import { Table } from "semantic-ui-react";
import HocDrawer from "../../../common/hocDrawer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AdCampaign from "../../../campaign/adCampaign";

const SortIconComponent = ({ data }) => {

    const AdCampaignRoadBlockDrawer = HocDrawer(
        ArrowForwardIcon,
        AdCampaign,
        "CAMPAIGN HIGHLIGHTS ",
        data
    );

    return (
        <Table.Cell textAlign="left">
            <AdCampaignRoadBlockDrawer />
        </Table.Cell>
    );
};

export default SortIconComponent;