import React, { useEffect, useRef, useState, useMemo } from "react";
import { connect } from "react-redux";
import { Chart } from "react-google-charts";
import { getDateEng } from "../../utilities/common";
import { isEmpty } from "lodash";
import { numFormat } from "../../utilities/common";

let formattedData = [];
const AdReportChart = (props) => {
  const [legendClicked, setLegendClicked] = useState([]);
  const [chartData, setChartData] = useState([]);
  const category = [];
  const chartRefValue = useRef(null);
  const [date, setDate] = useState([]);
  const [ticks, setTicks] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [ticksY1, setTicksY1] = useState(0);
  const [minValueY1, setMinValueY1] = useState(0);
  const [maxValueY1, setMaxValueY1] = useState(0);

  let chartRef = useRef();

  const calculateTicks = (min, max, tickCount) => {
    var span = max - min,
      step = Math.pow(10, Math.floor(Math.log(span / tickCount) / Math.LN10)),
      err = (tickCount / span) * step;

    // Filter ticks to get closer to the desired count.
    if (err <= 0.15) step *= 10;
    else if (err <= 0.35) step *= 5;
    else if (err <= 0.75) step *= 2;

    // Round start and stop values to step interval.
    var tstart = Math.ceil(min / step) * step,
      tstop = Math.floor(max / step) * step + step * 0.5,
      ticks = [];

    // now generate ticks
    for (let i = tstart; i < tstop; i += step) {
      ticks.push(i);
    }
    return ticks;
  };

  const calculationY2Ticks = useMemo(
    () => calculateTicks(0, maxValue + 0.01, ticks),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [minValue, maxValue, ticks]
  );

  const calculationY1Ticks = useMemo(
    () => calculateTicks(minValueY1, maxValueY1, ticksY1),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [minValue, maxValue, ticks]
  );

  useEffect(() => {
    setMinValue(Math.min(...countCtrTicks()));
    setMaxValue(Math.max(...countCtrTicks()));
    setTicks(5);

    setMinValueY1(Math.min(...countImpressionTicks()));
    setMaxValueY1(Math.max(...countImpressionTicks()));
    setTicksY1(5);

        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let adPreparedData = adChartData();
    setChartData(adPreparedData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legendClicked]);

  const getCoords = (chart) => {
    const chartLayout = chart.getChartLayoutInterface();
    const chartBounds = chartLayout.getChartAreaBoundingBox();

    const minX = chartLayout.getHAxisValue(chartBounds.left);
    const maxX = chartLayout.getHAxisValue(
      chartBounds.width + chartBounds.left
    );
    const minY = chartLayout.getVAxisValue(chartBounds.top);
    const maxY = chartLayout.getVAxisValue(
      chartBounds.height + chartBounds.top
    );

    return {
      x: { min: minX, max: maxX },
      y: { min: minY, max: maxY },
    };
  };
  const addChartGradient = (chart) => {
    const chartDiv = chart.getContainer("chart-div-ad");
    const svg = chartDiv.getElementsByTagName("svg")[0];
    const properties = {
      id: "chartGradient",
      x1: "0%",
      y1: "10%",
      x2: "0%",
      y2: "100%",
      stops: [
        { offset: "25%", "stop-color": "#E68BBF" },
        { offset: "95%", "stop-color": "rgba(255,255,255,0)" },
      ],
    };
    createGradient(svg, properties);
    const chartPath = svg.getElementsByTagName("path")[0];
    chartPath.setAttribute("fill", "url(#chartGradient)");
  };
  const createGradient = (svg, properties) => {
    const svgNS = svg.namespaceURI;
    const grad = document.createElementNS(svgNS, "linearGradient");
    grad.setAttribute("id", properties.id);

    for (const name of ["x1", "y1", "x2", "y2"]) {
      if (properties.hasOwnProperty(name)) {
        grad.setAttribute(name, properties[name]);
      }
    }

    for (const attrs of properties.stops) {
      const stop = document.createElementNS(svgNS, "stop");
      for (const attr in attrs) {
        if (attrs.hasOwnProperty(attr)) {
          stop.setAttribute(attr, attrs[attr]);
        }
      }
      grad.appendChild(stop);
    }

    const defs = svg.querySelector("defs");
    defs.insertBefore(document.createElementNS(svgNS, "defs"), defs.firstChild);
    return defs.appendChild(grad);
  };

  formattedData.map((item, index) => {
    if (index === 0) {
      category.push(item?.key);
    } else {
      !category.includes(item?.key) && category.push(item?.key);
    }
    return "";
  });

  let colors = [
    "#FED78B",
    "#FCAF17",
    "#FA911D",
    "#F15A29",
    "#0077B8",
    "#AD064F",
    "#80BBDC",
    "#FDEBE5",
    "#F8AD94",
    "#F9E3F0",
    "#005AA0",
    "#E0EFF6",
  ];

  let ctrKeys = [];
  const getY2SeriesCount = () => {
    formattedData.forEach((element) => {
      if (!ctrKeys.includes(element.key)) {
        ctrKeys.push(element.key);
      }
    });
  };

  const ctrFill = () => Array.from({ length: ctrKeys.length }, () => 0.0);
  const getSeries = () => {
    getY2SeriesCount();
    const series = {
      0: {
        axis: "Impressions",
        targetAxisIndex: 0,
        color: legendClicked.includes("impressions")
          ? "transparent"
          : "#CC167F",
        tooltip: false,
        type: legendClicked.includes("impressions") ? null : "area",
      },
      1: { tooltip: { trigger: "selection" } },
    };
    for (let i = 0; i < ctrKeys.length; i++) {
      const j = i + 1;
      const color = legendClicked.includes(ctrKeys[i])
        ? "transparent"
        : colors[i];
      series[j] = {
        axis: ctrKeys[i - 1],
        targetAxisIndex: 1,
        color: ctrKeys[i].includes("impressions") ? "transparent" : color,
      };
    }
    return series;
  };

  const mergeData = (data) => {
    Object.keys(data).forEach((e) => {
      if (!isEmpty(e)) {
        data[e].map((d) => formattedData.push({ ...d, date: e }));
      }
    });
    return formattedData;
  };
  formattedData = mergeData(props.chartData);

  const onLegendClick = (e) => {
    if (legendClicked.includes(e)) {
      setLegendClicked((prev) => prev.filter((i) => i !== e));
    } else {
      setLegendClicked((prev) => [...prev, e]);
    }
  };

  const adChartData = () => {
    getY2SeriesCount();

    let data = [];

    let header = [
      { type: "date" },
      { type: "string", role: "tooltip", p: { html: true } },
      { type: "number" },
      ...ctrKeys.map((e) => ({ type: "number" })),
    ];
    data.push(header);

    let legendCircle =
      "<span style='height: 6px; width: 6px; margin-right: 5px; background-color: ##COLOR##;border-radius: 50%; display: inline-block;'></span>";

    for (const [day, items] of Object.entries(props.chartData)) {
      let tooltipItems = [];
      let d1 = new Date(day);
      setDate(d1);

      let dataTooltip = [];
      items.forEach((item) => {
        dataTooltip.push(item.key);
        if (item.key === "impressions") {
          let l = legendCircle.replace("##COLOR##", "#CC167F");
          tooltipItems.push(l + numFormat(item.impressions) + ` - ` + item.key);
        } else {
          let idx = ctrKeys.indexOf(item.key);
          let l = legendCircle.replace("##COLOR##", colors[idx]);
          let ctrValue = item.ctr === "" ? "No Data" : item.ctr + "%";
          tooltipItems.push(l + ctrValue + ` - ` + item.key + " CTR");
        }
      });

      ctrKeys.forEach((ctrkey) => {
        let idx = ctrKeys.indexOf(ctrkey);
        let l = legendCircle.replace("##COLOR##", colors[idx]);
        let ctrNames = ctrkey.includes("impressions")
          ? ctrkey
          : ctrkey + " CTR";
        if (!dataTooltip.includes(ctrkey)) {
          tooltipItems.push(l + "No Data  - " + ctrNames);
        }
      });

      let adChartDataArr = [
        new Date(day),
        `<div class="tooltip-pageview-values" style= 'text-transform: capitalize; width: 170px; padding-top: 10px; margin-bottom: 0px; ' >` +
          getDateEng(day) +
          `<hr></hr>` +
          tooltipItems.join("<br />") +
          "<br /><br />" +
          `</div>`,
        0,
        ...ctrFill(),
      ];

      items.forEach((item) => {
        if (legendClicked.indexOf(item.key) === -1) {
          if (item.key === "impressions") {
            adChartDataArr[2] = item.impressions;
          } else {
            let idx = ctrKeys.indexOf(item.key);
            adChartDataArr[idx + 3] =
              item.ctr === "" ? 0.0 : parseFloat(item.ctr) / 100;
          }
        }
      });
      data.push(adChartDataArr);
    }
    return data;
  };

  const countCtrTicks = () => {
    let ctrValues = [];
    for (let key in props.chartData) {
      if (props.chartData.hasOwnProperty(key)) {
        props.chartData[key].forEach((dataObject) => {
          ctrValues.push(
            dataObject?.ctr === "" ? 0 : parseFloat(dataObject?.ctr) / 100
          );
        });
      }
    }
    return ctrValues;
  };

  const countImpressionTicks = () => {
    let impressionValues = [];
    for (const key in props.chartData) {
      if (props.chartData.hasOwnProperty(key)) {
        props.chartData[key].forEach((dataObject) => {
          if (dataObject && dataObject.impressions) {
            impressionValues.push(dataObject.impressions);
          }
        });
      }
    }
    return impressionValues;
  };

  // let adPreparedData = adChartData();
  const options = {
    chartArea: { left: "5%", top: "13%", width: "90%", height: "80%" },
    intervals: { style: "line" },
    legend: "none",
    tooltip: { isHtml: true, showColorCode: false },
    // Gives each series an axis that matches the vAxes number below.
    vAxes: [
      {
        // gridlines: {
        //   interval: 1,
        // },
        format: "short",
        baselineColor: "#B6B7B8",
        tooltip: { isHtml: true },
        minorGridlines: {
          count: 1,
        },
        //  ticks: calculationY1Ticks,
        minValue: Math.min(...calculationY1Ticks),
        maxValue: Math.max(...calculationY1Ticks),
      },
      {
        gridlines: { color: "none" },
        minValue: Math.max(...calculationY2Ticks),
        format: "percent",
        // minorGridlines: {
        //   count: 1
        // },
        // ticks: calculateY2Steps(),
        // gridlines: {
        //   interval: 0,
        // },
        // minorGridlines: {
        //   count: 1,
        // },
        // maxValue:
        //ticks: calculateTicks(Math.min(...countCtrTicks()), Math.max(...countCtrTicks()), 6)/100
      },
    ],
    hAxis: {
      gridlines: {
        interval: 1,
      },
      baselineColor: "#fff",
      gridlineColor: "#fff",
      ticks: date,
      format: "MMM dd",
      minorGridlines: {
        count: 0,
      },
      tooltip: false,
    },

    crosshair: {
      orientation: "vertical",
      color: "grey",
      opacity: 2,
      trigger: "both",
    },
    explorer: {
      actions: ["dragToZoom", "rightClickToReset"],
      axis: "horizontal",
      keepInBounds: true,
      maxZoomIn: 2,
      //zoomDelta: 1,
    },
    seriesType: "line",
    series: getSeries(),
    focusTarget: "category",
  };

  return (
    <div ref={chartRef} className="ad-chart" id="chart-div-ad">
      <div style={{ marginBottom: "-50px", position: "relative", zIndex: 1}}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {category.includes("impressions") ? (
          <div
            style={{
              marginLeft: 23,
              cursor: "pointer",
              color: legendClicked.includes("impressions")
                ? "#D3D3D3"
                : "#656669",
                zIndex: 1
            }}
            //className={`legend-item ${legendClicked.includes("impressions") ? "impression_legent-item" : "not_impression_legent-item"}`}
            className="legend-item"
            onClick={() => {
              onLegendClick("impressions");
            }}
          >
            <span
              style={{
                backgroundColor: !legendClicked.includes("impressions")
                  ? "#CC167F"
                  : "grey",
                float: "left",
                marginTop: "4px",
                marginRight: "10px",
                //  marginLeft: 80
                //  color:!legendClicked ? "pink" : "red"
              }}
              className="legend-circle"
            />
            Impressions
          </div>
        ) : (
          <div></div>
        )}
        <div>
          <ul className="legends" style={{marginRight: "55px"}}>
            {category.map((e, i) => {
              //   if (e === "impressions") {
              //     return (
              //       <li
              //         className="legend-item"
              //         onClick={() => {
              //           onLegendClick("all creatives");
              //         }}
              //       >
              //         <span
              //           style={{
              //             backgroundColor: !legendClicked.includes(
              //               "all creatives"
              //             )
              //               ? colors[i]
              //               : "grey",
              //           }}
              //           className="legend-circle"
              //         />
              //         <label
              //           style={{
              //             color: legendClicked.includes("all creatives")
              //               ? "#D3D3D3"
              //               : "#656669",
              //           }}
              //         >
              //           All Creatives
              //         </label>
              //       </li>
              //     );
              //   } else if (e !== "impressions") {
              if (e !== "impressions") {
                return (
                  <li
                    className="legend-item"
                    style={{
                      color: legendClicked.includes(e) ? "#D3D3D3" : "#656669",
                      zIndex: 1
                    }}
                    onClick={() => {
                      onLegendClick(e);
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: legendClicked.includes(e)
                          ? "grey"
                          : colors[i],
                        marginTop: "2px",
                        marginRight: "10px",
                      }}
                      className="legend-circle"
                    />
                    {e}
                  </li>
                );
              }
              return "";
            })}
          </ul>
        </div>
      </div>
      
      <Chart
        ref={chartRefValue}
        chartType="LineChart"
        width="100%"
        height="450px"
        data={chartData}
        options={options}
        chartEvents={[
          {
            eventName: "ready",
            callback: ({ chartWrapper }) => {
              addChartGradient(chartWrapper);
            },
          },
          {
            eventName: "select",
            callback: ({ chartWrapper }) => {
              addChartGradient(chartWrapper);
            },
          },
          {
            eventName: "ready",
            callback: ({ chartWrapper, google, e }) => {
              const chart = chartWrapper.getChart();
              let zoomLast = getCoords(chart);
              let debouncer;

              const observerCallback = () => {
                const zoomCurrent = getCoords(chart);
                if (JSON.stringify(zoomLast) !== JSON.stringify(zoomCurrent)) {
                  zoomLast = getCoords(chart);
                  addChartGradient(chartWrapper);
                }
                clearTimeout(debouncer);
                debouncer = setTimeout(() => {
                  addChartGradient(chartWrapper);
                }, 300);
              };

              const observerOptions = {
                childList: true,
                subtree: true,
              };

              const observer = new MutationObserver(observerCallback);
              observer.observe(chartRef.current, observerOptions);
            },
          },
        ]}
      />
    </div>
    </div>
  );

};
const mapStateToProps = (state) => {
  return {
    //adCharts: state.adFetchData.adCharts,
  };
};

export default connect(mapStateToProps)(AdReportChart);
