import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from "lodash";

import Toggle from "../../base/toggle";
import { selectValue } from "../../../actions";
import { COMPETITOR_FILTER } from "../../config/const";
import * as actionTypes from "../../../actions/actionTypes";

const CompetitorFilterToggle = (props) => {
    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    useEffect(() => {
        if(_.size(props.filterData.order) === 0 && _.size(props.filterData.campaign) === 0) {
            dispatchDynamicSelectAction(props, "competitorFiltering", false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filterData.order, props.filterData.campaign]);

    return (
        <Toggle
            label={COMPETITOR_FILTER}
            disabled={(_.size(props.filterData.order) > 0 || _.size(props.filterData.campaign) > 0) ? false : true}
            onChange={() => dispatchDynamicSelectAction(props, "competitorFiltering", !props.filterData.competitorFiltering)}
            checked={props.filterData.competitorFiltering}
            className={props.className}
            style={props.style}
        />
    )
};

export default compose(connect(null, {
    selectValue,
})
)(CompetitorFilterToggle);