import React from "react";
import MultipleSelectDropDown from "../base/multiSelectDropdown";
import { connect } from "react-redux";

const ActionsMultiSelect = (props) => {
	return (
		<MultipleSelectDropDown
			options={props.data.map((action, index) => ({
				key: index,
				text: action.name,
				value: action.value,
				disabled: action.disabled
			}))}
			placeholder={props.label}
			onSearchChange={props.onSearchChange}
			value={props.value}
			onChange={props.onChange}
			onClick={props.onClick}
		/>
	);
};

const mapStateToProps = (state) => {
    return {
        
    };
 };
 
 export default connect(mapStateToProps, {
    
 })(ActionsMultiSelect);
