import React from "react";
import MultipleSelectDropDown from "../base/multiSelectDropdown";

const TagsPicker = (props) => {
	let sites = {};
	props.sites &&
		props.sites.forEach((site) => {
			sites[site.value] = site.label;
		});
	return (
		<MultipleSelectDropDown
			options={props.tags.map((tag, index) => ({
				key: index,
				text: tag?.name,
				description: sites[tag?.domain],
				value: tag?.id + "|" + tag?.domain,
				disabled: tag?.disabled,
			}))}
			label={props.label}
			placeholder={props.label}
			onSearchChange={props.onSearchChange}
			value={props.value}
			onChange={props.onChange}
			onClick={props.onClick}
			selectOnBlur={false}	
		/>
	);
};

export default TagsPicker;
