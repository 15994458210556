import React from "react";
import { Table } from "semantic-ui-react";
import _ from "lodash";
import CreateTableRowComponent from "./CreateTableRowComponent";
import Link from "./../../hyperlinks/link";

const CommonUserInfo = ({ data }) => {

   const userInfo = data.search_query;
   const createTableRows = (data, label, linkType) => {
      if (data.length > 0) {
         let tableRows = [];
         
         tableRows.push(<Table.Row key={0}><Table.Cell rowSpan={data.length+1}>{label}</Table.Cell></Table.Row>);
         data.forEach((val, index) => {
            tableRows.push(
               <Table.Row key={val.uuid}>
                  <Table.Cell key={index + "_value"}>
                     <Link type={linkType} urlParam={val.uuid} refValue={val.name}></Link>
                  </Table.Cell>
               </Table.Row>
            );
         });
     
         return tableRows;
      }
   };

   const createTableRowsWithoutLink = (data, label) => {
      if (data.length > 0) {
         let tableRows = [];
         
         tableRows.push(<Table.Row key={0}><Table.Cell rowSpan={data.length+1}>{label}</Table.Cell></Table.Row>);
         data.forEach((val, index) => {
            tableRows.push(<Table.Row key={val.uuid}><Table.Cell key={index + "_name"}>{val.name}</Table.Cell></Table.Row>);
         });
     
         return tableRows;
      }
   };

   return (
      <React.Fragment>
         {
            !_.isEmpty(data.organizations) && 
            <Table.Body>{createTableRows(data.organizations, 'ACCOUNT', 'organizations')}</Table.Body>
         }
         {
            !_.isEmpty(data.orders) && 
            <Table.Body>{createTableRows(data.orders, 'ORDERS', 'orders')}</Table.Body>
         }
         <Table.Body>
            <Table.Row><Table.Cell rowSpan={2}>CAMPAIGN STATUS</Table.Cell></Table.Row>
            <Table.Row><Table.Cell>{userInfo.status}</Table.Cell></Table.Row>
         </Table.Body>
         {
            !_.isEmpty(data.campaigns) && 
            <Table.Body>{createTableRows(data.campaigns, 'CAMPAINS', 'campaigns')}</Table.Body>
         }
         {<CreateTableRowComponent name="DELIVERY STATE" value={userInfo.delivery_state}/>}
         {<CreateTableRowComponent name="PACING" value={userInfo.pace}/>}
         {<CreateTableRowComponent name="AD ORDER TYPE" value={userInfo.ad_order_type}/>}
         {<CreateTableRowComponent name="SITE" value={userInfo.site}/>}
         {<CreateTableRowComponent name="AD ORDER STATUS" value={userInfo.ad_status}/>}
         {
            !_.isEmpty(data.ad_unit) && 
            <Table.Body>{createTableRowsWithoutLink(data.ad_unit, 'AD UNIT')}</Table.Body>
         }
         {<CreateTableRowComponent name="TRAFFIC FILTER" value={userInfo.traffic_filter}/>}
         {
            !_.isEmpty(data.country) && 
            <Table.Body>{createTableRowsWithoutLink(data.country, 'COUNTRY')}</Table.Body>
         }
         {
            !_.isEmpty(data.engaged_organizations) && 
            <Table.Body>{createTableRows(data.engaged_organizations, 'ENGAGED ORGANIZATIONS', 'organizations')}</Table.Body>
         }
      </React.Fragment>
   );
};

export default CommonUserInfo;
