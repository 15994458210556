import { 
    CAMPAIGN,
    AGENCY,
    ACCOUNT,
    ORDER,
    START_DATE,
    END_DATE,
    CAMPAIGN_STATUS,
    DELIVERY_STATUS,
    PACE,
    PAGEVIEWS_DELIVERED_IN_RANGE,
    PAGEVIEWS_DELIVERED_TOTAL,
    PAGEVIEWS_GOAL,
    PERCENT_DELIVERED,
    PERCENT_ELAPSED_TIME,
    CSM
} from "../../config/TableHeaderNames";

const SCSummaryTableData=[
    { headerName: AGENCY, sorted: "agency_name", name: "Agency" },
    { headerName: ACCOUNT, sorted: "account", name: "Account" },
    { headerName: ORDER, sorted: "Order.name", name: "Order" },
    { headerName: CAMPAIGN, sorted: "name", className: "custom-column-large", name: "Campaign" },
    { headerName: CSM, sorted: "client_success_manager.name", name: "CSM" },
    { headerName: START_DATE, sorted: "start_date", name: "Start Date" },
    { headerName: END_DATE, sorted: "end_date", name: "End Date" },
    { headerName: CAMPAIGN_STATUS, sorted: "status", className: "custom-column-medium", name: "Campaign Status" },
    { headerName: DELIVERY_STATUS, sorted: "delivery_state", name: "Delivery Status" },
    { headerName: PAGEVIEWS_GOAL, sorted: "pageviews_goal", name: "Pageviews Goal", textAlign: "right" },
    { headerName: PAGEVIEWS_DELIVERED_IN_RANGE, sorted: "pageviews_delivered_in_date_range", className: "pageviews_delivered_in_date_range", name: "Pageviews Delivered in Range",  textAlign: "right", },
    { headerName: PAGEVIEWS_DELIVERED_TOTAL, sorted: "total_pageviews_delivered", className: "impressions", name: "Pageviews Delivered Total", textAlign: "right",},
    { headerName: PERCENT_DELIVERED, sorted: "percent_delivered", className: "percentage_served_to_date", name: "Percent Delivered", textAlign: "right"},
    { headerName: PERCENT_ELAPSED_TIME, sorted: "percent_completed", className: "percentage_served_to_date", name: "Percent Elapsed Time", textAlign: "right" },
    { headerName: PACE, sorted: "pace", name: "Pace", textAlign: "right" },
]
export default SCSummaryTableData;