import React from 'react';
import CommonRowData from "./editorialTopicDetails/commonRowData";
import { Table } from 'semantic-ui-react';
import { SITES, TOPIC_TAGS, ORGANIZATION_TAGS, PLACEMENT_TAGS, TOTAL_PAGEVIEWS_BY_TAG, UNIQUE_PAGEVIEWS_BY_TAG, AUTHOR_NAME, TOTAL_PAGEVIEWS_BY_AUTHOR, UNIQUE_PAGEVIEWS_BY_AUTHOR, PUBLISHED_BY_AUTHOR, PAGEVIEWS_PER_ARTICLE } from '../config/TableHeaderNames';
import { getHeaderIndices, numFormat } from '../../utilities/common';
import _ from 'lodash';

const pushTotalCell = (idx, index, tableTotalCell) => {
   if (idx === index) {
     return tableTotalCell.push(<Table.Cell key={idx} textAlign="left">Total:</Table.Cell>);
   }
 };

const createTableRow = (data, finalHeaderData, conditionPrevious, displayRows, headerColumn, tableName) => {
   if (data.length > 0) {
      const tableRow = data.slice(0, displayRows).map((e, id) => (
         <Table.Row key={id} children={<CommonRowData key={id} topicRow={e} headerName={headerColumn} tableName={tableName}/>} />
      ));

      const calculateTotalCount = (data, key) => {
         return _.sumBy(data, val => val[key] || 0);
      };
      
      const totalPageviewsCount = () => {
         return calculateTotalCount(data, tableName === 'author' ? 'pageviews' : 'page_views');
      };
      
      const totalPreviousPageviewsCount = () => {
         return calculateTotalCount(data, 'previous_period_pageviews');
      };
      
      const totalUniquePageviewsCount = () => {
         return calculateTotalCount(data, tableName === 'author' ? 'unique_pageviews' : 'unique_impressions_count');
      };
      
      const totalPreviousUniquePageviewsCount = () => {
         return calculateTotalCount(data, 'previous_period_unique_pageviews');
      };

      const totalPublishedByAuthor = () => {
         return calculateTotalCount(data, 'published_articles');
      };

      const totalPreviousPublishedByAuthor = () => {
         return calculateTotalCount(data, 'prev_published_articles');
      };

      const totalPageviewsPerArticle = () => {
         return calculateTotalCount(data, 'average_pageviews_by_article');
      };

      const totalPreviousPageviewsPerArticle = () => {
         return calculateTotalCount(data, 'previous_period_average_pageviews_by_article');
      };

      const tableTotalCell = [];
      const topicIndex = getHeaderIndices(finalHeaderData, TOPIC_TAGS);
      const orgIndex = getHeaderIndices(finalHeaderData, ORGANIZATION_TAGS);
      const placementIndex = getHeaderIndices(finalHeaderData, PLACEMENT_TAGS);
      const authorIndex = getHeaderIndices(finalHeaderData, AUTHOR_NAME);

      const siteIndex = getHeaderIndices(finalHeaderData, SITES);
      let pageViewIndex;
      let uniquePageIndex;
      let publishedByAuthorIndex;
      let pageViewPerArticleIndex;

      if(tableName === 'author') {
         pageViewIndex = getHeaderIndices(finalHeaderData, TOTAL_PAGEVIEWS_BY_AUTHOR);
         uniquePageIndex = getHeaderIndices(finalHeaderData, UNIQUE_PAGEVIEWS_BY_AUTHOR);
         publishedByAuthorIndex = getHeaderIndices(finalHeaderData, PUBLISHED_BY_AUTHOR);
         pageViewPerArticleIndex = getHeaderIndices(finalHeaderData, PAGEVIEWS_PER_ARTICLE);
      } else {
         pageViewIndex = getHeaderIndices(finalHeaderData, TOTAL_PAGEVIEWS_BY_TAG);
         uniquePageIndex = getHeaderIndices(finalHeaderData, UNIQUE_PAGEVIEWS_BY_TAG);
      }

      finalHeaderData && finalHeaderData.forEach((hData, idx) => {
         if (tableName === "topic") {
            pushTotalCell(idx, topicIndex, tableTotalCell);
          } else if (tableName === "organization") {
            pushTotalCell(idx, orgIndex, tableTotalCell);
          } else if (tableName === "placement") {
            pushTotalCell(idx, placementIndex, tableTotalCell);
          } else if (tableName === "author") {
            pushTotalCell(idx, authorIndex, tableTotalCell);
          }

         if (idx === siteIndex) {
            tableTotalCell.push(<Table.Cell>-</Table.Cell>);
         }
         if (idx === pageViewIndex) {
            tableTotalCell.push(<Table.Cell title={numFormat(totalPageviewsCount())} textAlign={conditionPrevious ? "center" : "right"}>{numFormat(totalPageviewsCount())}</Table.Cell>);
            if(conditionPrevious) {
               tableTotalCell.push(<Table.Cell title={numFormat(totalPreviousPageviewsCount())} textAlign="center">{numFormat(totalPreviousPageviewsCount())}</Table.Cell>);
            }
         }
         if (idx === uniquePageIndex) {
            tableTotalCell.push(<Table.Cell title={numFormat(totalUniquePageviewsCount())} textAlign={conditionPrevious ? "center" : "right"}>{numFormat(totalUniquePageviewsCount())}</Table.Cell>);
            if (conditionPrevious) {
               tableTotalCell.push(<Table.Cell title={numFormat(totalPreviousUniquePageviewsCount())} textAlign="center">{numFormat(totalPreviousUniquePageviewsCount())}</Table.Cell>);
            }
         }
         if(tableName === 'author') {
            if(idx === publishedByAuthorIndex) {
               tableTotalCell.push(<Table.Cell title={numFormat(totalPublishedByAuthor())} textAlign={conditionPrevious ? "center" : "right"}>{numFormat(totalPublishedByAuthor())}</Table.Cell>);
               if(conditionPrevious) {
                  tableTotalCell.push(<Table.Cell title={numFormat(totalPreviousPublishedByAuthor())} textAlign="center">{numFormat(totalPreviousPublishedByAuthor())}</Table.Cell>);
               }
            }
            if(idx === pageViewPerArticleIndex) {
               tableTotalCell.push(<Table.Cell title={numFormat(totalPageviewsPerArticle())} textAlign={conditionPrevious ? "center" : "right"}>{numFormat(totalPageviewsPerArticle())}</Table.Cell>);
               if(conditionPrevious) {
                  tableTotalCell.push(<Table.Cell title={numFormat(totalPreviousPageviewsPerArticle())} textAlign="center">{numFormat(totalPreviousPageviewsPerArticle())}</Table.Cell>);
               }
            }
         }
      });

      const finalGrandTotal = [<Table.Row key={1} children={tableTotalCell} />];
      const finalRow = _.concat(tableRow, finalGrandTotal);
      return finalRow;
   }
};

export default createTableRow;
   
   