import React from 'react';
import moment from 'moment';

const DateRange = (props) => {
  const startDate = moment(new Date(props.startDate)).format("MM/DD/YYYY");
  const endDate = moment(new Date(props.endDate)).format("MM/DD/YYYY");
  return (
    <div className='ad-graph-date'>
        <p>
            {startDate} - {endDate}
        </p>
	</div>
  );
};
  
export default DateRange;