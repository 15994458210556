import _ from "lodash";
import React, { useEffect, useState }  from "react";
import { Table, Loader, Message, Divider, Form, Icon, Menu, Checkbox } from "semantic-ui-react";
import "../../../css/common.css"
import { connect } from "react-redux";
import {
	setPageCount,
	sortEditorialDetailsTable,
	fetchPageviewTableData,
	showMorePageviewTableData,
	clearEDSort,
	sendTableHeaders,
	getTableHeaders,
	clearEditorialSerchedValue,
	selEditorialFilterValue,
} from "../../../actions";
import * as actionTypes from "../../../actions/actionTypes";
import { url } from "../../../api/urlHelper";
import {
	numFormat,
} from "../../../utilities/common";
import EDDetailRow from "./edDetailRow";
import TableHeader from "./tableHeader";
import EDDetailTableData from "./edDetailTableData";

const EditorialDetailsTableDetail = (props) => {
	const [selectHeaders, setSelectHeaders] = useState();
	const [finalHeaderData, setFinalHeaderData] = useState();
	const [isSortIcon, setSortIcon] = useState(false);

	let tableBody;
	let showMoreButton;

	useEffect(() => {
		props.getTableHeaders(
		  "independent_details_detail",
		  actionTypes.GETEDITORIALDETAILSPAGESUMMARYDYNAMICTABLEHEADER
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	  }, []);

	useEffect(() => {
	const headerDataVal = props.getDynamicHeaderData;
	if (!_.isEmpty(headerDataVal)) {
		setSelectHeaders(headerDataVal);
		setFinalHeaderData(headerDataVal);
	} else if (headerDataVal === undefined || _.size(headerDataVal) === 0 ) {
		const constantHeadersArr = [...EDDetailTableData];
		setSelectHeaders(constantHeadersArr);
		setFinalHeaderData(constantHeadersArr);
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.getDynamicHeaderData]);

	useEffect(() => {
		if( !props.filterData.pageviewDetailsToggelValue && props.filterData.selectPageDetailTable) {
			props.fetchPageviewTableData(
				url.pageviewDetailed,
				props.filterData,
				1,
				"",
				actionTypes.EDITORIALDETAILS,
				null,
				null,
				"independent-details",
				""
			);
			props.selEditorialFilterValue(true, actionTypes.PAGEVIEW_DETAILS_TOGGLE);
			props.clearEditorialSerchedValue(actionTypes.UPDATE_ED_LOADER)
		} else if(!props.filterData.selectPageDetailTable){
			props.clearEditorialSerchedValue(actionTypes.RESET_ED_DETAILS_DATA)
		}
		if(!props.filterData.selectPageDetailTable) {
			props.selEditorialFilterValue(false, actionTypes.PAGEVIEW_DETAILS_TOGGLE);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	 }, [props.isFilApplied, props.filterData.selectPageDetailTable]);

	 //props.filterData.selectPageSummaryTable, props.isFilApplied

	let tableTotalCell = [];
    const createRow = (data) => {
		if (data.length > 0) {
			const finalGrandTotal = [];
			const getTotals = (data) => {
				let total = 0;
				data.forEach((e) => {
					total += 1;
				});
				return total;
			};
			let tableRow = [];
			data.forEach((campaignValues, id) => {
				let tableCell = [];
				finalHeaderData && finalHeaderData.map((headerD, index) => {
				const htmlData = (
					<EDDetailRow 
						key={index}
						detailRow={campaignValues}
						headerName={headerD.headerName}
					/>
				);
				return tableCell.push(htmlData);
			});
			tableRow.push(<Table.Row key={id} children={tableCell} />);
		});

			finalHeaderData && finalHeaderData.map((hData, idx) => {
				if(idx === 0){
			 		tableTotalCell.push(<Table.Cell textAlign="left">Total Shown:</Table.Cell>);
				}else if (idx === 1){
					tableTotalCell.push(<Table.Cell title={numFormat(getTotals(props.pageviewDetailed))} textAlign="left">{numFormat(getTotals(props.pageviewDetailed))}</Table.Cell>);
				} else {
					tableTotalCell.push(<Table.Cell>-</Table.Cell>) 
				}
				return "";
			  });

			finalGrandTotal.push(<Table.Row key={1} children={tableTotalCell} />);
			//tableRow.push(<Table.Row children={tableTotalCell} />);
			const finalRow = _.concat(tableRow, finalGrandTotal)

			return finalRow;
		}
	};

    const showMore = async () => {
		clearEDSort();
		props.showMorePageviewTableData(actionTypes.MOREEDITORIALDETAILSLOADING);
		await props.fetchPageviewTableData(
			url.pageviewDetailed,
			props.filterData,
			props.PageCount,
			"",
			actionTypes.MOREEDITORIALDETAILS,
			null,
			null,
			"independent-details"
		);
		props.setPageCount(actionTypes.SETDETAILEDEDCOUNT);
	};


	if (!_.isEmpty(props.pageviewDetailed) && props.filterData.selectPageDetailTable) {
		tableBody = <Table.Body>{createRow(props.pageviewDetailed)}</Table.Body>;
	    showMoreButton = (
			<div className="table-divider">
			  <Divider>
				<div className="show-more-component">
				  {!props.editorialFetchDetailData.resEDetails ? 
				  (
				  <button className="show-more-button" onClick={showMore}>
					Show More
				  </button>
				  ):(
				  <button className="show-more-button-disable">
					Show More
				  </button>
				  )}
				  
				</div>
			  </Divider>
			</div>
		  );
	}

	const clickOnSortIcon = () => {
		setSortIcon(!isSortIcon);
	  };
	
	  const clickOnSortHeader = (e, extraData, headerData) => {
		if (extraData.checked) {
		  setSelectHeaders([...selectHeaders, headerData]);
		} else {
		  const removeData = selectHeaders.filter(
			(hName) => hName.headerName !== headerData.headerName
		  );
		  setSelectHeaders(removeData);
		}
	  };
	
	  const applyButtonClick = () => {
		const removeSortIconVal = selectHeaders.filter(
		  (rowData) => rowData.headerName !== "SORT_ICON"
		);
		const addLastArr = [
		  { headerName: "SORT_ICON", sorted: "", className: "icon" },
		];
		const allElements = [].concat(removeSortIconVal, addLastArr);
	
		const constantHeadersArr = [...EDDetailTableData];
		let finalHeaderArray = [];
		constantHeadersArr.map((mainHeader) => {
		  return _.map(allElements, function (selectHeader) {
			if (mainHeader.headerName === selectHeader.headerName) {
			  finalHeaderArray.push(selectHeader);
			}
		  });
		});

		setFinalHeaderData(finalHeaderArray);
		props.sendTableHeaders(
		  "independent_details_detail",
		  finalHeaderArray,
		  actionTypes.SENDEDITORIALDETAILSPAGESUMMARYDYANMICTABLEHEADER
		);
		setSortIcon(!isSortIcon);
	  };


	return (
		<div>
			<>
			<Form>
				<div className="sorted_icon">
				<Table.HeaderCell style={{ paddingBottom: "20px" }}>
				<Icon
					name="edit"
					style={{ color: `${isSortIcon ? "#f15a29" : "#b2b3b3"}` }}
					size="large"
					onClick={clickOnSortIcon}
				/>
				</Table.HeaderCell>
				{isSortIcon && (
				<div
					className="menu-list-items"
					style={{ height: "0px", float: "right" }}
				>
					<Menu vertical className="dynamic_table--list">
					{Object.values(EDDetailTableData).map(
						(tableHeader, index) => (
						<div key={index}>
							<Checkbox
							className="table_header--checkbox"
							style={{ color: "#838486" }}
							label={tableHeader.name}
							checked={
								selectHeaders &&
								selectHeaders.some(
								(el) => el.headerName === tableHeader.headerName
								)
								? true
								: false
							}
							onClick={(e, data) =>
								clickOnSortHeader(e, data, tableHeader)
							}
							/>
						</div>
						)
					)}
					<button
						className="apply_menu--btn"
						onClick={applyButtonClick}
					>
						Apply
					</button>
					</Menu>
				</div>
				)}
				</div>
			</Form>
			</>
			<div className="sortable-table-component" style={{ paddingTop: "30px" }}>
				<Table sortable fixed singleLine>
				<Table.Header>
					<Table.Row>
					{!_.isEmpty(finalHeaderData) && (
						<TableHeader
						propData={props}
						headerData={finalHeaderData}
						></TableHeader>
					)}
					</Table.Row>
				</Table.Header>
				{tableBody}
				</Table>
      		</div>
			
			{
				props.isFilApplied && props.loading && props.filterData.selectPageDetailTable &&
				<div className="show-loader">
					<Loader active inline="centered" />
				</div>
			}
			{
				props.isFilApplied && !props.loading && _.isEmpty(props.pageviewDetailed) && props.filterData.selectPageDetailTable &&
				<Message>No results found.</Message>
			}
			{showMoreButton}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		filterData: state.editorialFilterData,
        pageviewDetailed: Object.values(state.editorialFetchDetailData.pageviewDetailedData),
        loading: state.editorialFetchDetailData.isLoadingPageviewDetailed,
        PageCount: state.editorialFetchDetailData.detailedPageCount,
		pageviewDetailedColumn: state.editorialFetchDetailData.pageviewDetailedColumn,
    	pageviewDetailedDirection: state.editorialFetchDetailData.pageviewDetailedDirection,
        isFilApplied: state.editorialFetchDetailData.isFilApplied,
		editorialFetchDetailData: state.editorialFetchDetailData,
		grandTotal: state.editorialFetchDetailData.grandTotal,
		getDynamicHeaderData: state.editorialFetchDetailData.getDynamicEDPageDetailHeaderData,
	};
};

export default connect(mapStateToProps, {
    fetchPageviewTableData,
    showMorePageviewTableData,
    setPageCount,
    sortEditorialDetailsTable,
	clearEDSort,
	sendTableHeaders,
	getTableHeaders,
	clearEditorialSerchedValue,
	selEditorialFilterValue,
})(EditorialDetailsTableDetail);
