import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import * as actionTypes from "../../../actions/actionTypes";
import { fetchOrder, selectValue, fetchOrderReportData, clearSearchForAllReport } from "../../../actions";
import SingleSelectDropDown from "../../base/singleSelectDropDown";
import { useLocation } from "react-router-dom";

const OrderSingleSection = (props) => {
    const {
        fetchOrder,
        selectValue,
        reportActionType,
        commonSelectData,
    } = props;

    const location = useLocation();
    const [selectedOrderUuid, setSelectedOrderUuid] = useState("");
    const deletedOrders = {}

    const params = new URLSearchParams(location.search);
    const uuid = params.get('uuid');

    const dispatchDynamicSelectAction = useCallback((key, value) => {
        selectValue({
            dispatchType: actionTypes[reportActionType].SELECTVALUE,
            reportType: reportActionType,
            key,
            value
        });
    }, [reportActionType, selectValue]);

    const searchOrder = (e) => {
        if (e.target.value.length > 1) {
            fetchOrder(
                e.target.value,
                "name",
                actionTypes[reportActionType].FETCH_ORDERS,
                undefined,
                undefined,
                reportActionType,
            );
        }
    };

    useEffect(() => {
        if (uuid) {
            if (!commonSelectData.order || commonSelectData.order.length === 0) {
                fetchOrder("", "name", actionTypes[reportActionType].FETCH_ORDERS, undefined, undefined, reportActionType, [uuid]);
            }
        }
        // eslint-disable-next-line
    }, [reportActionType, commonSelectData.order]);

    useEffect(() => {
        if (uuid) {
            setSelectedOrderUuid(uuid);
            dispatchDynamicSelectAction("orderSingleStatus", uuid);
            props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].APPLY_FILTERS, reportType: reportActionType });

            props.filterData.orderSingleStatus = uuid;
            props.fetchOrderReportData(
                props.filterData,
                1,
                "",
                actionTypes[props.reportActionType].ORDER_SUMMARY,
                props.reportName,
                reportActionType,
            );
        }
        // eslint-disable-next-line
    }, [location.search, dispatchDynamicSelectAction]);

    const options = commonSelectData.order.map((org, index) => {
        if (org.deleted_at) {
            deletedOrders[org.uuid] = "deleted-item";
        }

        return {
            key: index,
            text: org && org.name,
            value: org && org.uuid,
            color: org && org.deleted_at ? "deleted" : "",
        }
    });

    return (
        <SingleSelectDropDown
            className={`no-blink ${deletedOrders[selectedOrderUuid]}`}
            options={options}
            onSearchChange={searchOrder}
            onChange={(e, { value }) => {
                dispatchDynamicSelectAction("orderSingleStatus", value);
                setSelectedOrderUuid(value);
            }}
            value={selectedOrderUuid}
            label="Order"
            placeholder="Order"
            clearable
        />
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        commonSelectData: state.allReportCommonReducer[ownProps.reportActionType],
    };
};

export default compose(connect(mapStateToProps, {
    fetchOrder,
    selectValue,
    fetchOrderReportData,
    clearSearchForAllReport,
}))(OrderSingleSection);
