import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
//import React, { Component } from 'react';
import {
    Table,
    Message,
    Loader,
    Divider,
    Checkbox,
    Form,
    Confirm,
    Menu,
    Dropdown,
} from "semantic-ui-react";
import "../../css/common.css";
import { connect } from "react-redux";
import {
    clientSetPageCount,
    fetchClientLeadEngs,
    sortClientLeadEngs,
    clearClientLeadSort,
    showMoreClientLeadEngsLoading,
    selGlobalLeadStatus,
    selGlobalVerificationState,
    updateLeadValidation,
    fetchLeadEngSummary,
    selShowDropDown,
    fetchSyncValidate,
    fetchClientLeadData,
    selClientLeadFilterValue,
    sendTableHeaders,
    getTableHeaders,
    clientLeadCsvLoading,
    clientLeadDetailCsvLoading,
    fetchLeadValidationBarChart,
    fetchLeadValidationChart
} from "../../actions";
import * as actionTypes from "../../actions/actionTypes";
import { Icon } from "semantic-ui-react";
import SingleSelectDropDown from "../base/singleSelectDropDown";
import { url } from "../../api/urlHelper";
import LeadTableRow from "./leadTableRow";
import { options, multiOptions } from "../common/options";
//import dummyData from "../../clientLeadEngs.json";
import TableHeaderData from "./TableHeaderData";
import HeaderComponent from "./TableHeader";
import { LEAD_STATUS, VERIFICATION_STATUS } from "../config/TableHeaderNames";
import DownloadXLSX from "../common/downloadXLSX";
import NotifyEmailConfirmation from "../common/notifyEmailConfirmation";
import { numFormat } from "../../utilities/common";
import { downloadCSVData } from "../../utilities/common";
import { LEAD_DETAILS } from "../config/rolebasedReportNames";
import { DOWNLOAD_CSV, GENERATE_XLSX } from "../config/const";

const ClientLeadEngsTable = (props) => {
    const [headerChecboxChecked, setHeaderChecboxChecked] = useState(false);
    const [allChildChecked, setAllChildChecked] = useState(false);
    const [leadStatusValue, setLeadStatusValue] = useState(false);
    const [checkedLength, setCheckedLength] = useState(0);
    const [verificationStateOptions, setVerificationStateOptions] = useState([
        multiOptions[0],
    ]);
    const [checkedItemIds, setCheckedItemIds] = useState([]);
    const [syncSuccess, setSyncSuccess] = useState(false);
    const syncedIdsObject = useRef([]);
    const [syncValidation, setSyncValidation] = useState(false);
    const [isSortIcon, setSortIcon] = useState(false);
    const [selectHeaders, setSelectHeaders] = useState();
    const [finalHeaderData, setFinalHeaderData] = useState();
    const [rowIds, setRowIds] = useState();
    const [refreshPage, setRefreshPage] = useState(1);
    const [update, setUpdate] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const syncedUserIdsObject = useRef([]);
    const [rowUserIds, setUserIds] = useState();
    const [download, setDownload] = useState(false);

    let tableBody;
    let showMoreButton;
    let loading;
    let noresult;
    let testify = ["", "Select"];
    let mailDeliveryPopup;
    let notifyMailDelivery;
    let leadDetailDownload;
    let excelDownloadButtonDetail;

    useEffect(() => {
        props.getTableHeaders("lead_engagement_detail", actionTypes.GETDYNAMICTABLEHEADER)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isFilApplied])

    useEffect(() => {
        const headerDataVal = props.getDynamicHeaderData;
        if (!_.isEmpty(headerDataVal)) {
            setSelectHeaders(headerDataVal)
            setFinalHeaderData(headerDataVal)
        } else if (headerDataVal === undefined || _.size(headerDataVal) === 0) {
            const showFirstHeaderVal = { headerName: "SHOWN", sorted: "", className: "custom-column-medium", name: "" }
            const addLastArr = { headerName: "SORT_ICON", sorted: "", className: "icon" };
            const constantHeadersArr = [showFirstHeaderVal, ...TableHeaderData, addLastArr]
            setSelectHeaders(constantHeadersArr)
            setFinalHeaderData(constantHeadersArr)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.getDynamicHeaderData]);

    useEffect(() => {
        if (props.filterData.showMoreClicked && headerChecboxChecked) {
            checkBoxChangedValue(true);
        } else {
            checkBoxChangedValue(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.leadEngs, props.filterData.showMoreClicked]);

    useEffect(() => {
        if (props.filterData.fetchApiReq === true) {
            // setTimeout(() => {
            props.fetchLeadEngSummary(
                url.leadEngagementSummaryReport,
                props.filterData,
                1,
                "",
                actionTypes.CLIENTLEADENGSUMMARY,
                true
            );
            props.fetchClientLeadEngs(
                props.filterData,
                1,
                "",
                actionTypes.CLIENTLEADENGS,
                "",
                "",
                true
            );
            props.fetchLeadValidationBarChart(
                props.filterData,
                1,
                "",
                actionTypes.CLIENTLEADBARCHART,
                true
            );
            setHeaderChecboxChecked(false);
            setAllChildChecked(false);
            setCheckedItemIds([]);
            props.selGlobalLeadStatus("Select");
            props.selGlobalVerificationState("Select");
            props.selShowDropDown("", actionTypes.SELHEADERDROPDOWNOPTIONVALUE)
            setUpdate(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filterData.fetchApiReq]);

    useEffect(() => {
        if (props.filterData.fetchValidateReq) {
            setCheckedItemIds([]);
            props.fetchLeadEngSummary(
                url.leadEngagementSummaryReport,
                props.filterData,
                1,
                "",
                actionTypes.CLIENTLEADENGSUMMARY,
                true
            );
            props.fetchClientLeadEngs(
                props.filterData,
                1,
                "",
                actionTypes.CLIENTLEADENGS,
                "",
                "",
                true
            );
            props.fetchLeadValidationBarChart(
                props.filterData,
                1,
                "",
                actionTypes.CLIENTLEADBARCHART,
                true
            );
            if (checkedItemIds.length > 0) {
                setSyncSuccess(true);
                setSyncValidation(false);
                setCheckedItemIds([]);
            }
            setHeaderChecboxChecked(false);
            setAllChildChecked(false);
            setCheckedItemIds([]);
            props.selGlobalLeadStatus("Select");
            props.selGlobalVerificationState("Select");
            props.selShowDropDown("", actionTypes.SELHEADERDROPDOWNOPTIONVALUE);
            props.selShowDropDown(undefined, actionTypes.FETCHREQVALIDATE)
            setRefresh(false);
            setSyncSuccess(false);
            syncedIdsObject.current = [];
            syncedUserIdsObject.current = [];
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filterData.fetchValidateReq]);

    useEffect(() => {
        if (_.size(props.leadDetailCsv) > 0 && download) {
            downloadCSVData(props.leadDetailCsv, props.downloadFileName)
            setDownload(false);
        }
    }, [props.leadDetailCsv, props.downloadFileName, download]);

    const getExcelReport = (btnSpecificAction, table_name) => {
        DownloadXLSX(
            btnSpecificAction,
            table_name,
            "client_lead_detail",
            props.filterData,
            props.userEmail,
            actionTypes.CLIENTLEADDETAILEXCEL,
            props.fetchClientLeadEngs
        )
    };

    const getLeadDetailCSVData = async () => {
        props.clientLeadDetailCsvLoading();
        setDownload(true);
        await props.fetchClientLeadEngs(
            props.filterData,
            1,
            "CSV",
            "",
            actionTypes.CLIENTLEADDETAILCSV,
            "",
            true
        );
    };

    if (props.isFilApplied) {
        leadDetailDownload = (
            <div>
                <button
                    className="download-button"
                    disabled={false}
                    onClick={() => getLeadDetailCSVData()}
                    style={{ marginLeft: "10px" }}
                >
                    Download CSV
                </button>
            </div>
        );
        excelDownloadButtonDetail = (
            <div>
                <button
                    className="download-button"
                    disabled={false}
                    onClick={() => getExcelReport(actionTypes.CLIENTLEADEXCELDETAIL, "client_lead_detail")}
                >
                    Generate XLSX
                </button>
            </div>
        )
    }

    const setCheckedFromChild = (checked, itemIds, campaignUuid, rowId, userUuid) => {
        let l = checkedLength;
        let ids = checkedItemIds;
        if (checked) {
            l++;
            ids = [...ids, ...itemIds];
            setCheckedLength(l);
            setCheckedItemIds(ids);
            syncedIdsObject.current = [
                ...syncedIdsObject.current,
                {
                    [rowId]: campaignUuid,
                },
            ];
            syncedUserIdsObject.current = [
                ...syncedUserIdsObject.current,
                {
                    [rowId]: userUuid,
                }
            ]
            setRowIds(syncedIdsObject.current)
            setUserIds(syncedUserIdsObject.current)
        } else {
            l--;
            setHeaderChecboxChecked(checked);
            setCheckedLength(l);
            ids = ids.filter((i) => {
                return !itemIds.includes(i);
            });
            syncedIdsObject.current = syncedIdsObject.current.filter(
                (i) => Object.keys(i)[0] !== rowId
            );
            syncedUserIdsObject.current = syncedUserIdsObject.current.filter(
                (i) => Object.keys(i)[0] !== rowId
            );
            props.selShowDropDown("Select Option", actionTypes.SELHEADERDROPDOWNOPTIONVALUE);
            setCheckedItemIds(ids);
        }
        if (props.leadEngs.length === l) {
            setHeaderChecboxChecked(true);
            props.selShowDropDown("shown", actionTypes.SELHEADERDROPDOWNOPTIONVALUE);
        } else if (l === 0) {
            setAllChildChecked(false);
        }
    };
    //const [pageCount, setPageCount] = useState(2);

    const showMore = async () => {
        props.selShowDropDown(true, actionTypes.SHOWMORECLICKED);
        props.selShowDropDown(true, actionTypes.SHOWMORECLICKEDCOUNT);
        props.clearClientLeadSort();
        props.showMoreClientLeadEngsLoading();
        await props.fetchClientLeadEngs(
            props.filterData,
            props.PageCount,
            // pageCount,
            "",
            actionTypes.MORECLIENTLEADENGSDATA,
            "",
            "",
            true
        );
        props.clientSetPageCount(actionTypes.SETCLIENTDETAILPGCOUNT);
        setRefreshPage(props.PageCount)
        //setPageCount(pageCount + 1);
    };

    if (props.loading) {
        loading = (
            <div className="show-loader">
                <Loader active inline="centered" />
            </div>
        );
    }

    const createRow = (data) => {
        const getTotals = (data) => {
            let total = 0;
            data.forEach((e) => {
                total += 1;
            });
            return total;
        };
        if (data.length > 0) {
            let tableRow = [];
            const totalTableRow = [];
            const finalTotal = [];
            const totalGrandTableRow = [];
            const finalGrandTotal = [];
            data.forEach((e, id) => {
                let tableCell = [];
                finalHeaderData && finalHeaderData.map((headerD, index) => {
                    // const htmlData = GetHeaderReleatedData(headerD.headerName, e, checkedItemIds, setCheckedFromChild, allChildChecked, headerChecboxChecked, setAllChildChecked, checkedLength, syncSuccess );
                    let htmlData = (
                        <LeadTableRow
                            key={index}
                            checkedItemIds={checkedItemIds}
                            setCheckedFromChild={setCheckedFromChild}
                            allChildChecked={allChildChecked}
                            headerChecboxChecked={headerChecboxChecked}
                            setAllChildChecked={setAllChildChecked}
                            leadRow={e}
                            checkedLength={checkedLength}
                            syncSuccess={syncSuccess}
                            headerName={headerD.headerName}
                            rowIds={rowIds}
                            rowUserIds={rowUserIds}
                        />
                    );
                    return tableCell.push(htmlData);
                });
                tableRow.push(<Table.Row key={id} children={tableCell} />);
            });

            finalHeaderData && finalHeaderData.map((hData, idx) => {
                if (idx === 2) {
                    totalTableRow.push(<Table.Cell title="total">Total Shown: {numFormat(getTotals(props.leadEngs))}</Table.Cell>)
                        && totalGrandTableRow.push(<Table.Cell title="Grand total">Grand total: {numFormat(props.totalLeadCount)} </Table.Cell>)
                } else {
                    totalTableRow.push(<Table.Cell title="-">-</Table.Cell>)
                        && totalGrandTableRow.push(<Table.Cell>-</Table.Cell>)
                }
                return "";
            });
            finalTotal.push(<Table.Row key={1} children={totalTableRow} />);
            finalGrandTotal.push(<Table.Row key={2} children={totalGrandTableRow} />);
            const finalData = _.concat(tableRow, finalTotal, finalGrandTotal);
            return finalData;
        }
    };

    if (!_.isEmpty(props.leadEngs)) {
        tableBody = <Table.Body>{createRow(props.leadEngs)}</Table.Body>;
        showMoreButton = (
            <div className="table-divider">
                <Divider>
                    <div className="show-more-component">
                        {!props.clientLeadFetchData.resLeadDetailsValue ?
                            (
                                <button className="show-more-button" onClick={showMore}>
                                    Show More
                                </button>
                            ) : (
                                <button className="show-more-button-disable">
                                    Show More
                                </button>
                            )}

                    </div>
                </Divider>
            </div>
        );
    } else if (
        props.isFilApplied &&
        !props.loading &&
        _.isEmpty(props.leadEngs)
    ) {
        noresult = <Message>No results found.</Message>;
    }
    const handleCancel = (e) => {
        e.stopPropagation();
        setLeadStatusValue(false);
    };

    const handleConfirm = (e) => {
        e.stopPropagation();
        setLeadStatusValue(false);
        let testify = ["", "Select"];
        if (
            checkedItemIds.length !== 0 &&
            !testify.includes(props.filterData.globalVerificationState) &&
            !testify.includes(props.filterData.globalLeadStatus)
        ) {
            props.updateLeadValidation(
                props.filterData.selHeaderDropdownOptionValue === "all" ? [] : checkedItemIds,
                props.filterData.globalLeadStatus,
                props.filterData.globalVerificationState,
                actionTypes.FETCHREQSTATUS,
                props.filterData,
                props.filterData.selHeaderDropdownOptionValue,
                true
            );
            setUpdate(true)
        }
    };

    const onChangeGlobalStatus = (e, { value }) => {
        const updateState = multiOptions.filter((name) => name.keyvalue === value);
        if (value !== "Select") {
            updateState.unshift({
                id: "1",
                value: "Select",
                text: "Select",
                keyvalue: "Select",
            });
        }
        setVerificationStateOptions(updateState);
        props.selGlobalVerificationState(options[0].value);
        if (props.filterData.globalLeadStatus !== value) {
            props.selGlobalLeadStatus(value);
        }
    };

    const onChangeVerificationState = (e, { value }) => {
        if (props.filterData.globalVerificationState !== value) {
            props.selGlobalVerificationState(value);
        }
    };

    const checkBoxChangedValue = (checked, rowId) => {
        setHeaderChecboxChecked(checked);
        setAllChildChecked(checked);
        if (checked) {
            setCheckedLength(props.leadEngs.length);
            let ids = [];
            props.leadEngs.forEach((o) => {
                syncedIdsObject.current = [
                    ...syncedIdsObject.current,
                    {
                        [rowId]: o.campaign_uuid,
                    },
                ];
                syncedUserIdsObject.current = [
                    ...syncedUserIdsObject.current,
                    {
                        [rowId]: o.user_uuid,
                    },
                ];
                ids = [...ids, ...o.item_ids];
            });
            setCheckedItemIds(ids);
        } else {
            setCheckedLength(0);
            setCheckedItemIds([]);
            syncedIdsObject.current = [];
            syncedUserIdsObject.current = [];
        }
    };
    const handleConfirmSync = () => {
        setSyncValidation(false);
        setCheckedItemIds([]);
    };

    const syncValidate = () => {
        setSyncValidation(true);
        let campaignIdUpdate = [];
        let userIdUpdate = [];
        syncedIdsObject.current.map((campId) => {
            campaignIdUpdate.push(Object.values(campId))
            return "";
        });
        syncedUserIdsObject.current.map((userUd) => {
            userIdUpdate.push(Object.values(userUd))
            return "";
        });
        const uniqueIds = campaignIdUpdate.flat();
        const updatedUniqueIds = [...new Set(uniqueIds)]

        const uniqueUserIds = userIdUpdate.flat();
        const updatedUniqueUserIds = [...new Set(uniqueUserIds)]

        checkedItemIds.length > 0 &&
            props.fetchSyncValidate(
                props.filterData,
                refreshPage,
                "",
                actionTypes.FETCHREQVALIDATE,
                true,
                checkedItemIds,
                updatedUniqueIds,
                props.filterData.selHeaderDropdownOptionValue,
                props.filterData.selHeaderDropdownOptionValue === "shown" ? false : true,
                props.filterData.selHeaderDropdownOptionValue === "shown" ? true : false,
                updatedUniqueUserIds
            );
        checkedItemIds.length > 0 && props.selClientLeadFilterValue(2, actionTypes.SETCLIENTDETAILPGAFTERREFRESHCOUNT);
        checkedItemIds.length > 0 && setRefresh(true)
        //setSyncSuccess(true);
    };

    const clickOnSortIcon = () => {
        setSortIcon(!isSortIcon);
    };

    const clickOnSortHeader = (e, extraData, headerData) => {
        if (extraData.checked) {
            setSelectHeaders([...selectHeaders, headerData]);
        } else {
            const removeData = selectHeaders.filter(
                (hName) => hName.headerName !== headerData.headerName
            );
            setSelectHeaders(removeData);
        }
    };

    const applyButtonClick = () => {
        const removeSortIconVal = selectHeaders.filter(rowData => rowData.headerName !== "SORT_ICON");
        const addLastArr = [{ headerName: "SORT_ICON", sorted: "", className: "icon" }];
        const allElements = [].concat(removeSortIconVal, addLastArr);


        //added new funcatality accroding to sortable column
        const showFirstHeaderVal = { headerName: "SHOWN", sorted: "", className: "custom-column-medium", name: "" }
        const addLastVal = { headerName: "SORT_ICON", sorted: "", className: "icon" };
        const constantHeadersArr = [showFirstHeaderVal, ...TableHeaderData, addLastVal]
        let finalHeaderArray = []
        constantHeadersArr.map((mainHeader) => {
            return _.map(allElements, function (selectHeader) {
                if (mainHeader.headerName === selectHeader.headerName) {
                    finalHeaderArray.push(selectHeader)
                }
            })
        })
        /////////////////////////////

        // setFinalHeaderData(allElements);
        // props.sendTableHeaders("lead_engagement_detail", allElements, actionTypes.SENDDYANMICTABLEHEADER)
        setFinalHeaderData(finalHeaderArray);
        props.sendTableHeaders("lead_engagement_detail", finalHeaderArray, actionTypes.SENDDYANMICTABLEHEADER)
        setSortIcon(!isSortIcon);
    };

    const onChangeHeaderDropdown = (e, { value }) => {
        props.selShowDropDown(value, actionTypes.SELHEADERDROPDOWNOPTIONVALUE);
        if (value === "all") {
            setHeaderChecboxChecked(true);
            setAllChildChecked(true);
            checkBoxChangedValue(true, e.target.id);
        }
        if (value === "shown") {
            setHeaderChecboxChecked(true);
            setAllChildChecked(true);
            checkBoxChangedValue(true, e.target.id);
        }
    }

    const onClickHeaderDropdown = () => {
        document.getElementById("select_option").style.display = "none"
    }

    return (
        <div>
            <>
                <Form>
                    {!_.isEmpty(props.leadEngs) && props.isFilApplied && (
                        <Form.Group>
                            <div style={{ display: "inline-flex", width: "100%", float: "left" }}>
                                <div>
                                    <p
                                        className="filter-text"
                                        style={{ marginLeft: "5px", textTransform: "uppercase" }}
                                    >
                                        Lead Status
                                    </p>
                                    <SingleSelectDropDown
                                        onChange={onChangeGlobalStatus}
                                        options={options}
                                        value={
                                            props.filterData.globalLeadStatus || options[0].value
                                        }
                                        style={{ marginLeft: "10px" }}
                                    />
                                </div>
                                <div>
                                    <p
                                        className="filter-text"
                                        style={{ marginLeft: "15px", textTransform: "uppercase" }}
                                    >
                                        Verification Status
                                    </p>
                                    <SingleSelectDropDown
                                        style={{ marginLeft: "20px" }}
                                        onChange={onChangeVerificationState}
                                        options={verificationStateOptions}
                                        value={
                                            props.filterData.globalVerificationState ||
                                            options[0].value
                                        }
                                    />
                                </div>
                                <div>
                                    <button
                                        className="apply_basic_button"
                                        style={{ width: "170px" }}
                                        value={update}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setLeadStatusValue(true);
                                            e.stopPropagation();
                                        }}
                                    >
                                        <span>Apply Updates</span>
                                    </button>
                                </div>
                                <div>
                                    <button
                                        style={{ marginLeft: "10px" }}
                                        value={refresh}
                                        className="apply_basic_button"
                                        onClick={(e) => {
                                            syncValidate();
                                        }}
                                    >
                                        <span>Refresh</span>
                                    </button>
                                    <br />
                                    {refresh && props.filterData.messageBoxRefresh && (
                                        <div className="error-message-block">
                                            <Message positive compact>
                                                <p>Member and organization data for selected leads is being refreshed and filters reapplied</p>
                                            </Message>
                                        </div>
                                    )}
                                </div>
                            </div>


                            <Form.Group>
                                {mailDeliveryPopup}
                                {notifyMailDelivery}
                                {checkedItemIds.length === 0 && (
                                    <Confirm
                                        open={syncValidation}
                                        content={
                                            checkedItemIds.length === 0 &&
                                            "Please proceed by selecting at least one Lead"
                                        }
                                        cancelButton={null}
                                        confirmButton={checkedItemIds.length === 0 && "Okay"}
                                        onConfirm={handleConfirmSync}
                                    />
                                )}
                            </Form.Group>
                            <Confirm
                                open={leadStatusValue}
                                content={
                                    checkedItemIds.length === 0
                                        ? "Please proceed by selecting at least one Lead"
                                        : // : testify.includes(props.filterData.globalLeadStatus) ?
                                        testify.includes(props.filterData.globalLeadStatus) &&
                                            testify.includes(
                                                props.filterData.globalVerificationState
                                            )
                                            ? "Please proceed by selecting Lead Status & Verification Status"
                                            : // ? "Please proceed by selecting Lead Status"
                                            testify.includes(props.filterData.globalVerificationState)
                                                ? "Please proceed by selecting Verification Status"
                                                : "Are you sure you want to update the changes?"
                                }
                                cancelButton={
                                    checkedItemIds.length !== 0 &&
                                        !testify.includes(props.filterData.globalLeadStatus) &&
                                        !testify.includes(props.filterData.globalVerificationState)
                                        ? "Cancel"
                                        : null
                                }
                                confirmButton={
                                    checkedItemIds.length !== 0 &&
                                        !testify.includes(props.filterData.globalLeadStatus) &&
                                        !testify.includes(props.filterData.globalVerificationState)
                                        ? "Update"
                                        : "Okay"
                                }
                                onCancel={handleCancel}
                                onConfirm={handleConfirm}
                            />

                            {/* start from here */}
                            <div style={{ marginTop: "27px", float: "right", display: "inline-flex" }}>
                                {
                                    props?.isFilApplied && (props?.roleReports?.some((report) => [LEAD_DETAILS].includes(report.element) && report.permission === GENERATE_XLSX)) &&
                                    <div className="excel-button" style={{ marginLeft: "10px" }}>
                                        {excelDownloadButtonDetail}
                                        {props.postEmailUpdateClientLeadDetail ? (
                                            <NotifyEmailConfirmation
                                                email={props.postEmailUpdateClientLeadDetail}
                                                table="client_lead_Detail"
                                                cardPopup={props.postEmailUpdateClientLeadDetail}
                                                cardPopupClose={actionTypes.SETCLIENTLEADDETAILPOPUPPOSTEMAIL}
                                            />
                                        ) : null}
                                    </div>
                                }
                                {
                                    props?.isFilApplied && (props?.roleReports?.some((report) => [LEAD_DETAILS].includes(report.element) && report.permission === DOWNLOAD_CSV)) &&
                                    <div className="csv-button">
                                        {leadDetailDownload}
                                    </div>
                                }
                            </div>
                        </Form.Group>
                    )}
                    <div className="sorted_icon">
                        <Table.HeaderCell style={{ paddingBottom: "20px" }}>
                            <Icon name="edit" style={{ color: `${isSortIcon ? "#f15a29" : "#b2b3b3"}` }} size="large" onClick={clickOnSortIcon} />
                        </Table.HeaderCell>
                        {isSortIcon && (
                            <div
                                className="menu-list-items"
                                style={{ height: "0px", float: "right" }}
                            >
                                <Menu vertical className="dynamic_table--list">
                                    {Object.values(TableHeaderData).map((tableHeader, index) => (
                                        <div
                                            key={index}
                                            className={
                                                tableHeader.headerName === LEAD_STATUS ||
                                                    tableHeader.headerName === VERIFICATION_STATUS
                                                    ? "sort__header-table-list"
                                                    : ""
                                            }
                                        >
                                            <Checkbox
                                                className="table_header--checkbox"
                                                style={{ color: "#838486" }}
                                                label={tableHeader.name}
                                                checked={
                                                    selectHeaders && selectHeaders.some(
                                                        (el) => el.headerName === tableHeader.headerName
                                                    )
                                                        ? true
                                                        : false
                                                }
                                                onClick={(e, data) =>
                                                    clickOnSortHeader(e, data, tableHeader)
                                                }
                                            />
                                        </div>
                                    ))}
                                    <button className="apply_menu--btn" onClick={applyButtonClick}>
                                        Apply
                                    </button>
                                </Menu>
                            </div>
                        )}
                    </div>
                </Form>
            </>


            <div className="sortable-table-component" style={{ paddingTop: "30px", overflowY: "hidden" }}>
                <Table sortable fixed singleLine>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                style={{
                                    overflow: "visible",
                                    verticalAlign: "top",
                                    paddingTop: "1px",
                                    width: "15em"
                                }}
                            >
                                {!_.isEmpty(props.leadEngs) && props.isFilApplied && (
                                    <Form>
                                        <Checkbox
                                            id={props.leadEngs.uniqueId}
                                            style={{
                                                color: "white",
                                                marginLeft: "0px",
                                                marginRight: "16px",
                                                paddingTop: "4px",
                                            }}
                                            className="calcheckbox"
                                            checked={headerChecboxChecked}
                                            onChange={(e, { checked }) => {
                                                checkBoxChangedValue(checked, e.target.id);
                                                if (checked) {
                                                    props.selShowDropDown("shown", actionTypes.SELHEADERDROPDOWNOPTIONVALUE);
                                                } else {
                                                    props.selShowDropDown("Select Option", actionTypes.SELHEADERDROPDOWNOPTIONVALUE);
                                                }
                                            }}
                                        />
                                        <Dropdown
                                            style={{
                                                borderColor: "#e5582b",
                                                color: "#838486",
                                                fontFamily: "Roboto",
                                                fontSize: "12px",
                                                letterSpacing: 0,
                                                lineHeight: "16px",
                                                border: "0px",
                                                outline: "10px",
                                                backgroundColor: "#edeeee",
                                                marginTop: "10px",
                                            }}
                                            value={props.filterData.selHeaderDropdownOptionValue}
                                            onChange={onChangeHeaderDropdown}
                                            onClick={onClickHeaderDropdown}
                                            placeholder="Select Option"
                                            options={[
                                                {
                                                    key: "1",
                                                    text: "Select Option",
                                                    value: "Select Option",
                                                    id: "select_option"
                                                },
                                                {
                                                    key: "2",
                                                    text: "All",
                                                    value: "all",
                                                },
                                                {
                                                    key: "3",
                                                    text: "Shown",
                                                    value: "shown",
                                                },
                                            ]}
                                        />
                                    </Form>
                                )}
                            </Table.HeaderCell>
                            {
                                (!_.isEmpty(finalHeaderData)) &&
                                <HeaderComponent
                                    propData={props}
                                    headerData={finalHeaderData}
                                ></HeaderComponent>
                            }

                        </Table.Row>
                    </Table.Header>
                    {tableBody}
                    {props.filterData.messageBox && update && (
                        <div className="error-message-block">
                            <Message positive compact>
                                <p>Lead Status and Verification Status are being updated and filters reapplied</p>
                            </Message>
                        </div>
                    )}
                    {props.filterData.fetchApiReq === "" && (
                        <div className="error-message-block">
                            <Message negative compact>
                                <p>
                                    Unable to update the selected leads. An error has occurred
                                </p>
                            </Message>
                        </div>
                    )}
                    {props.filterData.fetchApiReqRowWise && (
                        <div className="error-message-block">
                            <Message positive compact>
                                <p>The lead is updated successfully</p>
                            </Message>
                        </div>
                    )}
                    {props.filterData.fetchApiReqRowWise === "" && (
                        <div className="error-message-block">
                            <Message negative compact>
                                <p> Unable to update the lead. An error has occurred</p>
                            </Message>
                        </div>
                    )}
                </Table>
            </div>
            {noresult}
            {loading}
            {showMoreButton}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        wpLink: state.links.wpLink,
        isFilApplied: state.clientLeadFetchData.isFilApplied,
        clientLeadFetchData: state.clientLeadFetchData,
        assetType: Object.values(state.assetTypes),
        filterData: state.clientLeadFilterData,
        leadEngs: state.clientLeadFetchData.leadEngs,
        totalLeadCount: state.clientLeadFetchData.totalLeadCount,
        loading: state.clientLeadFetchData.isLoadingLeadEngs,
        column: state.clientLeadFetchData.column,
        direction: state.clientLeadFetchData.direction,
        PageCount: state.clientLeadFetchData.detailPageCount,
        mailDelivery: state.clientLeadFetchData.mailDelivery,
        cardPopupMailDelivery: state.clientLeadFetchData.cardPopupMailDelivery,
        postMailDeliveryConfirmation:
            state.clientLeadFetchData.postMailDeliveryConfirmation,
        getDynamicHeaderData: state.clientLeadFetchData.getDynamicHeaderData,
        sendDynamicHeaderArray: state.clientLeadFetchData.dynamicHeaderArray,
        leadDetailCsv: state.clientLeadFetchData.leadDetailCsv,
        isLoadingLeadDetailCsv: state.clientLeadFetchData.isLoadingLeadDetailCsv,
        postEmailUpdateClientLeadDetail: state.clientLeadFetchData.postEmailUpdateClientLeadDetail,
        userEmail: state.authorized.userEmail,
        downloadFileName: state.clientLeadFetchData.downloadFileName
    };
};

export default connect(mapStateToProps, {
    fetchClientLeadEngs,
    sortClientLeadEngs,
    clearClientLeadSort,
    showMoreClientLeadEngsLoading,
    clientSetPageCount,
    selGlobalLeadStatus,
    selGlobalVerificationState,
    updateLeadValidation,
    fetchLeadEngSummary,
    selShowDropDown,
    fetchSyncValidate,
    fetchClientLeadData,
    selClientLeadFilterValue,
    sendTableHeaders,
    getTableHeaders,
    clientLeadCsvLoading,
    clientLeadDetailCsvLoading,
    fetchLeadValidationBarChart,
    fetchLeadValidationChart
})(ClientLeadEngsTable);
