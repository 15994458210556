import _ from "lodash";
import React from "react";
import { List } from "semantic-ui-react";
import { getName, getCommaSeparatedVal } from "../../utilities/common";
import Firmographic from "./firmographic";
import Personas from "./personas";

const DemandGeneration = (props) => {
  let demandData = props.data;
  return (
    <div>
      <h5 className="drawer-heading-text">DEMAND GENERATION</h5>
      <List>
        {
          <List.Item className="drawer-value">
            <label className="filter-text"> EXCLUDED DOMAINS </label>
            <List.Item className="drawer-text">
              {!_.isEmpty(demandData.excluded_domain_filters)
                ? getCommaSeparatedVal(
                    demandData.excluded_domain_filters,
                    "name"
                  )
                : "-"}
            </List.Item>
          </List.Item>
        }
      </List>
      <List>
        {
          <List.Item className="drawer-value">
            <label className="filter-text"> EXCLUDED EMAIL ADDRESS </label>
            <List.Item className="drawer-text">
              {!_.isEmpty(demandData.excluded_email_addresses)
                ? getCommaSeparatedVal(
                    demandData.excluded_email_addresses,
                    "name"
                  )
                : "-"}
            </List.Item>
          </List.Item>
        }
      </List>
      <List>
        {
          <List.Item className="drawer-value">
            <label className="filter-text"> EXCLUDED ORGANIZATION </label>
            <List.Item className="drawer-text">
              {!_.isEmpty(demandData.excluded_organizations)
                ? getName(demandData.excluded_organizations, "name")
                : "-"}
            </List.Item>
          </List.Item>
        }
      </List>
      <h5 className="drawer-heading-text"> FIRMOGRAPHIC FILTERING</h5>
      {demandData.firmographics.map((firmographic) => (
        <Firmographic object={firmographic} />
      ))}
      <h5 className="drawer-heading-text">PERSONA FILTERING</h5>
      <Personas object={demandData.personas} />
    </div>
  );
};

export default DemandGeneration;
