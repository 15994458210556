import React, { useState } from 'react';
import { Form } from "semantic-ui-react";
import { fetchProductivityDetail, getAuthorsInfo } from '../../actions';
import { connect, useDispatch } from "react-redux";
import * as actionTypes from "../../actions/actionTypes";
import SingleSelectDropDown from '../base/singleSelectDropDown';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SearchProductivity = (props) => {
   const [selectedYear, setSelectedYear] = useState(null);
   const [selectedQuarter, setSelectedQuarter] = useState(null);
   const [selectedAuthor, setSelectedAuthor] = useState(null);
   const [authorData, setAuthorData] = useState([]);
   const [filterSelectFocus, setFilterSelectFocus] = useState("filter-icon");
   const [filterPopup, setFilterPopup] = useState("filter-popup-close");
   const [filterIconColor, setFilterIconColor] = useState("material-icons grey");
   const dispatch = useDispatch();

   const currentYear = new Date().getFullYear();
   const years = Array.from({ length: currentYear - 2018 + 1 }, (_, i) => ({
      key: currentYear - i,
      text: currentYear - i,
      value: currentYear - i
   }));

   const defaultSelectedYear = { key: 'Select Year', text: 'Select Year', value: null };
   years.unshift(defaultSelectedYear); // Add default value to the beginning of the array

   const handleYearChange = (event, { value }) => {
      setSelectedYear(value); // Update selectedYear directly from the event
   };

   const handleQuarterChange = (event, { value }) => {
      setSelectedQuarter(value); // Update selectedQuarter directly from the event
   };

   const clearFilters = () => {
      setSelectedYear(null);
      setSelectedQuarter(null);
      setSelectedAuthor(null);
   };

   const applyFilters = async () => { // Make the function asynchronous
      const obj = {};
      if (selectedYear !== null) {
         obj["year"] = selectedYear;
      }
      if (selectedQuarter !== null) {
         obj["quarter"] = selectedQuarter;
      }
      if (selectedAuthor !== null) {
          obj["id"] = parseInt(selectedAuthor, 10);
      }
      
      if (selectedAuthor > 0) {
         setFilterPopup("filter-popup-close");
         dispatch({ type: actionTypes.APPLY_PRODUCTIVITY_FILTER });
         props.fetchProductivityDetail(obj, actionTypes.GET_PRODUCTIVITY_DATA);
      } else {
         toast.error("Please select an Author");
      }
   };

   const quarterOptions = [
      { key: 0, value: 0, text: 'All Quarters' },
      { key: 1, value: 1, text: 'Q1' },
      { key: 2, value: 2, text: 'Q2' },
      { key: 3, value: 3, text: 'Q3' },
      { key: 4, value: 4, text: 'Q4' },
   ];

   const searchAuthor = async (e) => {
      if (e.target.value.length > 1) {
         const data = await getAuthorsInfo(e.target.value);
         setAuthorData(data);
      } else {
         setAuthorData([]);
      }
   };

   return (
      <div>
         <div className="date-picker">
            {/* Filter icon */}
            <div
               className={filterSelectFocus}
               onClick={() => {
                  setFilterSelectFocus("filter-icon-selected");
                  setFilterPopup("filter-popup-open");
                  setFilterIconColor("material-icons orange");
               }}
            >
               <i className={filterIconColor}>filter_list</i>
            </div>
         </div>
         <div className="popup-component">
            <div className={filterPopup}>
               <div className="filter-header">
                  <div className="filter-text">
                     <p>Filters</p>
                  </div>
                  <div
                     className="filter-close-icon"
                     onClick={() => {
                        setFilterSelectFocus("filter-icon");
                        setFilterPopup("filter-popup-close");
                     }}
                  >
                     <i className="material-icons grey" onClick={() => setFilterIconColor("material-icons grey")}>clear</i>
                  </div>
               </div>
               <Form>
                  <div className="ad-search-filter">
                     <Form.Group widths="equal">
                        <SingleSelectDropDown
                           label="Year"
                           placeholder="Select Year"
                           value={selectedYear}
                           onChange={handleYearChange}
                           options={years}
                        />
                        <SingleSelectDropDown
                           label="Quarter"
                           placeholder="Select Quarter"
                           value={selectedQuarter}
                           onChange={handleQuarterChange}
                           options={quarterOptions}
                        />
                     </Form.Group>
                     <Form.Group widths="equal">
                        <SingleSelectDropDown
                           clearable
                           value={selectedAuthor}
                           options={authorData.map((author, index) => ({
                              key: index,
                              text: author.name,
                              value: author.id
                           }))}
                           onClick={()=> selectedAuthor === "" && setSelectedAuthor(null)}
                           onChange={(e, {value}) => {setSelectedAuthor(value)}}
                           onSearchChange={searchAuthor}
                           label="Author"
                           placeholder="Author"
                        />
                     </Form.Group>
                  </div>
               </Form>
               <div className="filter-buttons">
                  <button className="cancel-date-button extra-space" onClick={clearFilters}>Clear Filters</button>
                  <button className="apply-filter-button" onClick={applyFilters}>Apply Filters</button>
               </div>
            </div>
            <ToastContainer autoClose={3000}/>
         </div>
      </div>
   );
};

const mapStateToProps = (state) => {
   return {
      
   };
};

export default connect(mapStateToProps, {
   fetchProductivityDetail
})(SearchProductivity);

