import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from "lodash";

import Toggle from "../base/toggle";
import { COMPETITOR_FILTER } from "../config/const";
import { selectToggleValue } from "../../actions";

const CompetitorFilterToggleForOthers = (props) => {

    useEffect(() => {
        if(_.size(props.filterData.order) === 0 && _.size(props.filterData.campaign) === 0) {
            props.selectToggleValue({
                dispatchType: props.action,
                key: 'competitorFiltering',
                value: false
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filterData.order, props.filterData.campaign]);

    return (
        <Toggle
            label={COMPETITOR_FILTER}
            disabled={(_.size(props.filterData.order) > 0 || _.size(props.filterData.campaign) > 0) ? false : true}
            onChange={() => {
                props.selectToggleValue({
                    dispatchType: props.action,
                    key: 'competitorFiltering',
                    value: !props.filterData.competitorFiltering
                });
            }}
            checked={props.filterData.competitorFiltering}
            className={props.className}
            style={props.style}
        />
    )
}

export default compose(connect(null, {
    selectToggleValue,
})
)(CompetitorFilterToggleForOthers);