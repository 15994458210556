import React from "react";
import { connect } from "react-redux";
import { Loader, Message } from "semantic-ui-react";
import { 
    showMoreTableData,
    fetchSiteEditorialPGSummaryReport,
    clearSiteSort 
} from "../../actions";
import CommonSummaryReport from "./commonSummaryReport";
import _ from "lodash"
// import * as actionTypes from "../../actions/actionTypes";
// import { url } from "../../api/urlHelper";

const SiteEditorialPGSummaryReport = (props) => {
	// const [isShow, setShow] = useState(false);
	let loading;
	let noresult;

	if (props.loading) {
        loading = (
          <div className="show-loader">
            <Loader active inline="centered" />
          </div>
        );
    }

	if (!props.loading && _.isEmpty(props.siteEDPgSummaryData) && props.isFilter) {
        noresult = <Message>No results found.</Message>;
    }

// 	const showMoreClick = () => {
//         setShow(true)
//     }

//     const showMore = async () => {
// 		props.clearSiteSort(actionTypes.CLEAREDPGSORT);
// 		props.showMoreTableData(actionTypes.MOREEDITORIALPGSUMMARYLOADING);
// 		props.fetchSiteEditorialPGSummaryReport(
//             url.siteEditorialPGSummaryReprot,
//             props.filterData.startDate,
//             props.filterData.endDate,
//             (props.filterData.edPageCount) + 1
//         );
// 		props.showMoreTableData(actionTypes.SETEDPAGEVIEWSUMMARYPGCOUNT);
//   };

	return (
		<div>
			<CommonSummaryReport 
                column={props.column}
                direction={props.direction}
				type="editorial_summary_report"
                filterData={props.siteEDPgSummaryData}
				// parentShowMore={showMoreClick}
            />
			{noresult}
			{loading}
            {/* {
                (isShow && !_.isEmpty(props.siteEDPgSummaryData) && !props.siteReportFilterData.resEditorialPageviewSummaryValue) ?(

                <div className="table-divider">
                    <Divider>
                        <div className="show-more-component">
                            <button className="show-more-button" onClick={showMore}> Show More </button>
                        </div>
                    </Divider>
                </div>
                ): (
                    <div className="table-divider">
                    <Divider>
                        <div className="show-more-component">
                            <button className="show-more-button-disable">
                            Show More
                        </button>
                        </div>
                        </Divider>
                </div>
            )} */}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
        filterData: state.siteReportFilterData,
		siteEDPgSummaryData: state.siteReportFilterData.siteEditorialData,
        loading: state.siteReportFilterData.isLoadingSiteEditorialPgData,
		isFilter: state.siteReportFilterData.isApplied,
        siteReportFilterData: state.siteReportFilterData
	};
};

export default connect(mapStateToProps, {
    showMoreTableData,
    fetchSiteEditorialPGSummaryReport,
    clearSiteSort
})(SiteEditorialPGSummaryReport);
