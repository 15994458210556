import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAuthToken } from '../../actions';
import { useNavigate, useLocation } from 'react-router-dom';

const Login = ({ authorized, getAuthToken }) => {
	const [searchParam, setSearchParam] = useState(null);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (searchParam === null) {
			if(!authorized.token ) {
				getAuthToken(location.search, location.pathname);
				setSearchParam(location.search);
			}
		}
		// eslint-disable-next-line
	}, [searchParam, getAuthToken]);

	useEffect(() => {
		if (authorized.token) {
			navigate('/');
		}
		// eslint-disable-next-line
	}, [authorized.token]);

	return (
		<div className="login_parent-container" style={{ marginTop: '10em' }}>
			{!authorized.token ? <div className="spinner"></div> : null}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		authorized: state.authorized
	};
};

export default connect(mapStateToProps, { getAuthToken })(Login);