import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import DatePicker from "../common/datePicker";
import { compose } from "redux";
import { 
   filterSelectValue,
   fetchOrganizations,
   addFilterById,
   fetchCampaigns,
   clearSearch,
   fetchEngagedOrg,
   selectToggleValue,
   fetchOrder,
   fetchTitle,
   fetchUrlParams,
   clearFilter,
   fetchData,
   fetchEngagedMember,
   commonSelectedValue,
} from "../../actions";
import { connect } from "react-redux";
import { getStartEndDates, getDisplayDate } from "../../utilities/common";
import {
  APPLIED_DATE,
  CUSTOM_DATE_RANGE,
  END_DATE,
  ENGAGED_MEMBER_LABEL,
  ENGAGED_ORGANIZATION,
  ENGAGED_ORGANIZATIONS,
  EXCLUDE_ANONYMOUS_MEMBERS_LABEL,
  EXCLUDE_ANONYMOUS_ORGANIZATIONS_LABEL,
  EXCLUDE_GATED_PAGEVIEWS,
  EXCLUDE_INTERNAL_EVENTS,
  HORIZONTAL,
  ORGANIZATION,
  PREVIOUS_APPLIED_DATE,
  PREVIOUS_END_DATE,
  PREVIOUS_START_DATE,
  START_DATE,
  UTM_CAMPAIGN_LABEL,
  UTM_CONTENT_LABEL,
  UTM_MEDIUM_LABEL,
  UTM_PARAMETERS_LABEL,
  UTM_SOURCE_LABEL,
  UTM_TERM_LABEL,
  VIEW,
} from "../config/const";
import CommonDateRange from "../common/CommonDateRange";
import { DateRangePicker } from "react-date-range";
import OrganizationPicker from "../common/organization";
import * as actionTypes from "../../actions/actionTypes";
import _ from "lodash";
import EngagedOrgPicker from "../common/engagedOrganization";
import OrderPicker from "../common/order";
import CampaignPicker from "../common/campaign";
import SitePicker from "../common/site";
import Toggle from "../base/toggle";
import UrlParamsPicker from "../common/urlParams";
import EngagedMember from "../common/engagedMember";
import TrafficFilterPicker from "../common/trafficeFilter";
import TrafficSource from "../common/trafficSource";
import TagsComponent from "../common/TagsComponent"
import FetchPageTitle from "../common/FetchPageTitle";
import CompetitorFilterToggleForOthers from "../common/CompetitorFilterToggleForOthers";
import ActionsCommonMultiSelect from "../common/ActionsCommonMultiSelect";
import { ACTIVITY } from "../config/rolebasedReportNames";

const DateAndFilter = (props) => {
   const [datePopup, setDatePopup] = useState("custom-date-popup-close");
   const [filterSelectFocus, setFilterSelectFocus] = useState("filter-icon");
   const [filterPopup, setFilterPopup] = useState("filter-popup-close");
   const [filterIconColor, setFilterIconColor] = useState("material-icons grey");
   const [dateRange, setDateRange] = useState({
      selection: {
         startDate: new Date(),
         endDate: new Date(),
         key: "selection",
      },
   });

  const selectDateRange = async (e, { value }) => {
    props.filterSelectValue({ key: APPLIED_DATE, value });
    setDatePopup("custom-date-popup-close");
    if (value === CUSTOM_DATE_RANGE) {
      setDatePopup("custom-date-popup-open");
    } else {
      let dates = getStartEndDates(value);
      props.filterSelectValue({ key: START_DATE, value: dates.startDate });
      props.filterSelectValue({ key: END_DATE, value: dates.endDate });
      props.filterSelectValue({ key: PREVIOUS_START_DATE, value: dates.startDate });
      props.filterSelectValue({ key: PREVIOUS_END_DATE, value: dates.endDate });
      props.filterSelectValue({ key: PREVIOUS_APPLIED_DATE, value });
      if (props.isFilApplied) {
        applyFilters(dates.startDate, dates.endDate, value);
      }
    }
  };

   const applyFilters = (startDate, endDate, appDate) => {
		props.clearSearch(actionTypes.APPLYACTIVITYFILTER);
		props.filterData.startDate = startDate;
		props.filterData.endDate = endDate;
		props.filterData.appliedDate = appDate;
      props.filterSelectValue({ key: "globalActivityEngsStatus", value: "Select" });
      props.filterSelectValue({ key: "globalActivityEngsVerificationState", value: "Select" });

      if(props?.roleReports?.some((report) => [ACTIVITY].includes(report.element) && report.permission === VIEW)) {
         props.fetchData(props.filterData, 1, "", actionTypes.ACTIVITYTABLE);
      }
		setFilterPopup("filter-popup-close");
		setFilterIconColor("material-icons grey");
		setFilterSelectFocus("filter-icon");
		setDatePopup("custom-date-popup-close");
	};

   const cancelDate = () => {
      setDateRange({
			selection: {
				startDate: new Date(),
				endDate: new Date(),
				key: "selection",
			},
		});
      props.filterSelectValue({ key: "startDate", value: getDisplayDate(props.filterData.prevstartDate) });
      props.filterSelectValue({ key: "endDate", value: getDisplayDate(props.filterData.prevendDate) });
		if (props.filterData.prevAppliedDate === "Custom Date Range") {
         props.filterSelectValue({ key: "appliedDate", value: "Custom Date Range" });
		} else {
         props.filterSelectValue({ key: "appliedDate", value: props.filterData.prevAppliedDate });
		}
		setDatePopup("custom-date-popup-close");
   }

   const applyDate = () => {
      props.filterSelectValue({ key: "startDate", value: getDisplayDate(dateRange.selection.startDate) });
      props.filterSelectValue({ key: "endDate", value: getDisplayDate(dateRange.selection.endDate) });
		setDatePopup("custom-date-popup-close");

      props.filterSelectValue({ key: "prevstartDate", value: getDisplayDate(dateRange.selection.startDate) });
      props.filterSelectValue({ key: "prevendDate", value: getDisplayDate(dateRange.selection.endDate) });
      props.filterSelectValue({ key: "prevAppliedDate", value: "Custom Date Range" });
		if (props.isFilApplied) {
			applyFilters(
				dateRange.selection.startDate,
				dateRange.selection.endDate,
				props.filterData.appliedDate
			);
		}
   }

   const searchOrg = (e) => {
      if (e.target.value.length > 1) {
         props.fetchOrganizations(
            props.filterData.excludeQACampaign,
            e.target.value,
            actionTypes.FETCHACTIVITYORGS,
            props.organizations,
         );
      }
   };

   const onSelectOrg = (e, { value }) => {
      props.filterSelectValue({ key: ORGANIZATION, value });
      props.addFilterById(value, actionTypes.ADDACTIVITYORGS);
      if (!_.isEmpty(props.filterData.campaign)) {
         props.fetchCampaigns(
            "",
            props.filterData.site,
            props.filterData.selTagsIN,
            props.filterData.selTagsEX,
            props.filterData.excludeQACampaign,
            "",
            value,
            props.filterData.campaignStatus,
            "start_date",
            actionTypes.FETCHACTIVITYCAMPAIGNS,
            props.filterData.campaign,
            true,
            actionTypes.RESETACTIVITYCAMPAIGNS
         );
      }
   };


   const onClickOrg = () => {
      props.clearSearch(actionTypes.CLEARACTIVITYORGS);
   };

   const searchEngOrg = (e, { value }) => {
      if (e.target.value.length > 1) {
         props.fetchEngagedOrg(
            props.filterData.excludeQACampaign,
            e.target.value,
            actionTypes.FETCHACTIVITYENGORGS,
            true,
            props.filterData.organization,
            props.filterData.activityIsIncludeParent,
            props.filterData.activityIsIncludeChild,
         );
      }
   };

   const onSelectEngOrg = (e, { value }) => {
      props.filterSelectValue({ key: ENGAGED_ORGANIZATIONS, value });
      props.addFilterById(value, actionTypes.ADDACTIVITYENGORGS);
   };

   const onClickEngOrg = () => {
      if (!_.isEmpty(props.filterData.organization) && (props.filterData.activityIsIncludeParent || props.filterData.activityIsIncludeChild)) {
         props.fetchEngagedOrg(
            props.filterData.excludeQACampaign,
            "",
            actionTypes.FETCHACTIVITYENGORGS,
            true,
            props.filterData.organization,
            props.filterData.activityIsIncludeParent,
            props.filterData.activityIsIncludeChild,
         );
      }
   };

   const searchOrder = (e) => {
		if (e.target.value.length > 1) {
			props.fetchOrder(e.target.value, "name", actionTypes.FETCHACTIVITYORDER);
		}
	};

   const onSelectOrder = (e, { value }) => {
      props.filterSelectValue({ key: "order", value });
      props.addFilterById(value, actionTypes.ADDACTIVITYORDER);
	};

   const onClickOrder = () => {
      props.clearSearch(actionTypes.CLEARACTIVITYORDER);
	};

   const searchCampaign = (e, { value }) => {
		if (e.target.value.length > 1) {
			props.fetchCampaigns(
				"",
				props.filterData.site,
				props.filterData.selTagsIN,
				props.filterData.selTagsEX,
				props.filterData.excludeQACampaign,
				e.target.value,
				props.filterData.organization,
				props.filterData.campaignStatus,
				"start_date",
				actionTypes.FETCHACTIVITYCAMPAIGNS
			);
		}
	};

   const onSelectCampaigns = (e, { value }) => {
      props.filterSelectValue({ key: "campaign", value });
      props.addFilterById(value, actionTypes.ADDACTIVITYCAMPAIGNS);
	};

   const onClickCampaign = () => {
		if (_.isEmpty(props.filterData.organization)) {
			props.clearSearch(actionTypes.CLEARACTIVITYCAMPAIGNS);
		} else if (!_.isEmpty(props.filterData.organization)) {
			props.fetchCampaigns(
				"",
				props.filterData.site,
				props.filterData.selTagsIN,
				props.filterData.selTagsEX,
				props.filterData.excludeQACampaign,
				"",
				props.filterData.organization,
				props.filterData.campaignStatus,
				"start_date",
				actionTypes.FETCHACTIVITYCAMPAIGNS
			);
		}
	};

   const searchUrl = (type, fetchAction, clearAction, e) => {
		if (e.target.value.length > 1) {
			props.fetchUrlParams(
				e.target.value,
				type,
				fetchAction,
				"",
				"engagement"
			);
		} else {
         props.clearSearch(clearAction);
		}
	};

   const onClickUrl = (val, clearAction) => {
		if (_.isEmpty(val)) {
			props.clearSearch(clearAction);
		}
	};

   const onSelectUrl = (key, e, {value} ) => {
      props.filterSelectValue({ key, value });
	};

   const clearFilters = () => {
		props.clearFilter(actionTypes.CLEARACTIVITYFILTER, props.delivery_states);
		props.clearSearch(actionTypes.CLEARSEARCHLEADPAGETITLE);
		setDateRange({
			selection: {
				startDate: new Date(),
				endDate: new Date(),
				key: "selection",
			},
		});
		setDatePopup("custom-date-popup-close");
      props.filterSelectValue({ key: "activityVerificationState", value: "" });
      props.clearSearch(actionTypes.RESETACTIVITYORGS);
      props.clearSearch(actionTypes.RESETACTIVITYCAMPAIGNS);
      props.clearSearch(actionTypes.RESETACTIVITYENGORGS);
      props.clearSearch(actionTypes.RESETACTIVITYORDER);
      props.clearSearch(actionTypes.RESETACTIVITYTITLE);
      props.clearSearch(actionTypes.RESETACTIVITYMEMBER);
	};

   const searchEngagedMember = (e) => {
		if (e.target.value.length > 1) {
			props.fetchEngagedMember(e.target.value, actionTypes.FETCHACTIVITYENGMEMBER);
		}
	};

   const onClickEngagedMember = () => {
		if (_.isEmpty(props.filterData.selEnagedMember)) {
         props.clearSearch(actionTypes.CLEARACTIVITYMEMBER);
		}
	};

   const onSelectEngagedMember = (e, { value }) => {
      props.filterSelectValue({ key: "selEnagedMember", value });
      props.addFilterById(value, actionTypes.ADDACTIVITYMEMBER);
	};

   const onSelectTrafficFilter = (e, { value }) => {
      props.filterSelectValue({ key: "trafficType", value });
   }

   return (
      <React.Fragment>
         <div>
            <div className="date-picker">
               {/* Date picker */}
               <div>
                  <Form>
                  <Form.Group widths="equal">
                     <DatePicker
                        floating
                        value={props.filterData.appliedDate}
                        onChange={selectDateRange}
                        openDateRange={(e, { value }) => {
                           if (value === CUSTOM_DATE_RANGE) {
                              props.filterSelectValue({
                                 key: APPLIED_DATE,
                                 value: CUSTOM_DATE_RANGE,
                              });
                              setDatePopup("custom-date-popup-open");
                           }
                        }}
                     />
                     <CommonDateRange
                        startDate={props.filterData.startDate}
                        endDate={props.filterData.endDate}
                     />
                  </Form.Group>
                  </Form>
               </div>
               {/* Filter icon */}
               <div
                  className={filterSelectFocus}
                  onClick={() => {
                  setFilterSelectFocus("filter-icon-selected");
                  setFilterPopup("filter-popup-open");
                  setFilterIconColor("material-icons orange");
                  }}
               >
                  <i className={filterIconColor}>filter_list</i>
               </div>
            </div>

            <div className="popup-component">
               {/* Custom date picker component */}
               <div className={datePopup}>
                  <div className="date-picker-close-icon" onClick={() => { setDatePopup("custom-date-popup-close"); }} >
                  <i className="material-icons grey">clear</i>
                  </div>
                  <div>
                     <DateRangePicker
                        minDate={new Date("2019-12-13")}
                        maxDate={new Date()}
                        onChange={(item) => {
                           setDateRange({ ...dateRange, ...item });
                        }}
                        months={2}
                        moveRangeOnFirstSelection={false}
                        direction={HORIZONTAL}
                        ranges={[dateRange.selection]}
                        rangeColors={["#e5582b"]}
                     />
                  </div>
                  <div className="date-buttons">
                     <button type="button" className="cancel-date-button" onClick={cancelDate}>
                        Cancel
                     </button>
                     <button type="button" className="apply-date-button" onClick={applyDate}>
                        Apply
                     </button>
                  </div>
               </div>

               {/* Search filter popup */}
               <div className={filterPopup}>
                  <div className="filter-header">
                     <div className="filter-text">
                        <p>Filters</p>
                     </div>
                     <div 
                        className="filter-close-icon"
                        onClick={() => {
                           setFilterSelectFocus("filter-icon");
                           setFilterPopup("filter-popup-close");
                        }}
                     >
                        <i className="material-icons grey" onClick={() => setFilterIconColor("material-icons grey")}>clear</i>
                     </div>
                  </div>
                  <div className="engagements-search-filter">
                     <div className="engagements-search-filter">
                        <Form className="search-filter-column-1">
                           <OrganizationPicker
                              onSearchChange={searchOrg}
                              value={props.filterData.organization}
                              onChange={onSelectOrg}
                              onClick={onClickOrg}
                              organizations={props.organizations}
                           />
                           <CampaignPicker
                              onSearchChange={searchCampaign}
                              value={props.filterData.campaign}
                              onChange={onSelectCampaigns}
                              campaigns={props.campaigns}
                              onClick={onClickCampaign}
                           />
                           <TrafficFilterPicker
                              value={props.filterData.trafficType}
                              onChange={onSelectTrafficFilter}
                           />
                           <TrafficSource
                              value={props.filterData.selTrafficSource}
                              onChange={(e, {value})=> props.filterSelectValue({ key: "selTrafficSource", value }) }
                           />
                           <TagsComponent 
                              type="topic"
                              keyValue="selTopicTagsIN"
                              filterData={props.filterData} 
                              tags={props.topicTagsIn}
                              value={props.filterData.selTopicTagsIN}
                              exValue={props.filterData.selTopicTagsEX}
                              selectDispatchType={actionTypes.SELVALUES}
                              tagFetchActionType={actionTypes.FETCHACTIVITYTOPICTAGSIN}
                              tagAddActionsType={actionTypes.ADDACTIVITYTOPICTAGSIN}
                              tagClearActionsType={actionTypes.CLEARACTIVITYTOPICTAGSIN}
                              label={"Topic Tags (Include)"}
                           />
                           <TagsComponent 
                              type="placement"
                              keyValue="selPlacementTagsIN"
                              filterData={props.filterData} 
                              tags={props.placementTagsIn}
                              value={props.filterData.selPlacementTagsIN}
                              exValue={props.filterData.selPlacementTagsEX}
                              selectDispatchType={actionTypes.SELVALUES}
                              tagFetchActionType={actionTypes.FETCHACTIVITYPLACEMENTTAGSIN}
                              tagAddActionsType={actionTypes.ADDACTIVITYPLACEMENTTAGSIN}
                              tagClearActionsType={actionTypes.CLEARACTIVITYPLACEMENTTAGSIN}
                              label={"Placement Tags (Include)"}
                           />
                           <TagsComponent 
                              type="organization"
                              keyValue="selOrgTagsIN"
                              filterData={props.filterData} 
                              tags={props.organizationTagsIn}
                              value={props.filterData.selOrgTagsIN}
                              exValue={props.filterData.selOrgTagsEX}
                              selectDispatchType={actionTypes.SELVALUES}
                              tagFetchActionType={actionTypes.FETCHACTIVITYORGANIZATIONTAGSIN}
                              tagAddActionsType={actionTypes.ADDACTIVITYORGANIZATIONTAGSIN}
                              tagClearActionsType={actionTypes.CLEARACTIVITYORGANIZATIONTAGSIN}
                              label={"Organization Tags (Include)"}
                           />
                           <label className="filter-text" style={{ paddingLeft: "0px" }}> Include Actions </label>
                           <ActionsCommonMultiSelect
                              {...props}
                              actionData={props.includeActions}
                              keyInput="includeActions"
                              value={props.filterData.includeActions}
                              label="Include Actions"
                              selected={props.filterData.excludeActions}
                              FETCH={actionTypes.FETCH_INCLUDE_ACTION_ACTIVITY}
                              ADD={actionTypes.ADD_INCLUDE_ACTION_ACTIVITY}
                           />
                           <EngagedOrgPicker
                              value={props.filterData.engagedOrganizations}
                              label={ENGAGED_ORGANIZATION}
                              placeholder={ENGAGED_ORGANIZATION}
                              onChange={onSelectEngOrg}
                              onClick={onClickEngOrg}
                              onSearchChange={searchEngOrg}
                              organizations={props.engOrg}
                           />
                           <Toggle
                              label={EXCLUDE_ANONYMOUS_ORGANIZATIONS_LABEL}
                              onChange={() => { props.selectToggleValue({ 
                                    dispatchType: actionTypes.SELECTED_TOGGLE, 
                                    key: 'exAnonymousOrgs', 
                                    value: !props.filterData.exAnonymousOrgs 
                                 }); 
                              }}
                              checked={props.filterData.exAnonymousOrgs}
                              className="utm-params_style"
                           />
                           <Toggle
                              label={EXCLUDE_INTERNAL_EVENTS}
                              onChange={() => { props.selectToggleValue({ 
                                    dispatchType: actionTypes.SELECTED_TOGGLE, 
                                    key: 'exInternalEvents', 
                                    value: !props.filterData.exInternalEvents 
                                 }); 
                              }}
                              checked={props.filterData.exInternalEvents}
                              className="utm-params_style"
                           />
                           <CompetitorFilterToggleForOthers
                              filterData={props.filterData}
                              action={actionTypes.SELECTED_TOGGLE}
                              style={{ marginTop: "15px", marginLeft: "5px" }}
                           />
                        </Form>
                        <Form className="search-filter-column-2">
                           <OrderPicker
                              onSearchChange={searchOrder}
                              value={props.filterData.order}
                              onChange={onSelectOrder}
                              onClick={onClickOrder}
                              order={props.order}
                           />
                           <SitePicker
                              value={props.filterData.site}
                              onChange={(e, {value})=> props.filterSelectValue({ key: "site", value }) }
                           />
                           <FetchPageTitle
                              keyValue="title"
                              value={props.filterData.title}
                              selectDispatchType={actionTypes.SELVALUES}
                              clearActionsType={actionTypes.CLEARACTIVITYPAGETITLE}
                              fetchActionType={actionTypes.FETCHACTIVITYPAGETITLE}
                              titles={props.pageTitles}
                           />
                           <div className="extra-space_filter_item"></div>
                           <TagsComponent 
                              type="topic"
                              keyValue="selTopicTagsEX"
                              filterData={props.filterData} 
                              tags={props.topicTagsEx}
                              value={props.filterData.selTopicTagsEX}
                              exValue={props.filterData.selTopicTagsIN}
                              selectDispatchType={actionTypes.SELVALUES}
                              tagFetchActionType={actionTypes.FETCHACTIVITYTOPICTAGSEX}
                              tagAddActionsType={actionTypes.ADDACTIVITYTOPICTAGSEX}
                              tagClearActionsType={actionTypes.CLEARACTIVITYTOPICTAGSEX}
                              label={"Topic Tags (Exclude)"}
                           />
                           <TagsComponent 
                              type="placement"
                              keyValue="selPlacementTagsEX"
                              filterData={props.filterData} 
                              tags={props.placementTagsEx}
                              value={props.filterData.selPlacementTagsEX}
                              exValue={props.filterData.selPlacementTagsIN}
                              selectDispatchType={actionTypes.SELVALUES}
                              tagFetchActionType={actionTypes.FETCHACTIVITYPLACEMENTTAGSEX}
                              tagAddActionsType={actionTypes.ADDACTIVITYPLACEMENTTAGSEX}
                              tagClearActionsType={actionTypes.CLEARACTIVITYPLACEMENTTAGSEX}
                              label={"Placement Tags (Exclude)"}
                           />
                           <TagsComponent 
                              type="organization"
                              keyValue="selOrgTagsEX"
                              filterData={props.filterData} 
                              tags={props.organizationTagsEx}
                              value={props.filterData.selOrgTagsEX}
                              exValue={props.filterData.selOrgTagsIN}
                              selectDispatchType={actionTypes.SELVALUES}
                              tagFetchActionType={actionTypes.FETCHACTIVITYORGANIZATIONTAGSEX}
                              tagAddActionsType={actionTypes.ADDACTIVITYORGANIZATIONTAGSEX}
                              tagClearActionsType={actionTypes.CLEARACTIVITYORGANIZATIONTAGSEX}
                              label={"Organization Tags (Exclude)"}
                           />
                           <label className="filter-text" style={{ paddingLeft: "0px" }}> Exclude Actions </label>
                           <ActionsCommonMultiSelect
                              {...props}
                              actionData={props.excludeActions}
                              keyInput="excludeActions"
                              value={props.filterData.excludeActions}
                              label="Exclude Actions"
                              selected={props.filterData.includeActions}
                              FETCH={actionTypes.FETCH_EXCLUDE_ACTION_ACTIVITY}
                              ADD={actionTypes.ADD_EXCLUDE_ACTION_ACTIVITY}
                           />
                           <EngagedMember
                              label={ENGAGED_MEMBER_LABEL}
                              placeholder={ENGAGED_MEMBER_LABEL}
                              engMember={props.engMember}
                              value={props.filterData.selEnagedMember}
                              onSearchChange={searchEngagedMember}
                              onClick={onClickEngagedMember}
                              onChange={onSelectEngagedMember}
                              type="addMemberLink"
                           />
                           <Toggle
                              label={EXCLUDE_ANONYMOUS_MEMBERS_LABEL}
                              onChange={() => { props.selectToggleValue({ 
                                    dispatchType: actionTypes.SELECTED_TOGGLE, 
                                    key: 'exAnonymousMember', 
                                    value: !props.filterData.exAnonymousMember 
                                 }); 
                              }}
                              checked={props.filterData.exAnonymousMember}
                              className="utm-params_style"
                           />
                           <Toggle
                              label={UTM_PARAMETERS_LABEL}
                              onChange={() => { props.selectToggleValue({ 
                                    dispatchType: actionTypes.SELECTED_TOGGLE, 
                                    key: 'utmParams', 
                                    value: !props.filterData.utmParams 
                                 }); 
                              }}
                              checked={props.filterData.utmParams}
                              className="utm-params_style"
                           />
                           {
                              props.filterData.utmParams && 
                              <React.Fragment>
                                 <UrlParamsPicker
                                    style={{ width: "24em" }}
                                    options={props.urlSource?.map((url, index) => ({
                                       key: index,
                                       text: url.source,
                                       value: url.source,
                                    }))}
                                    value={props.filterData.selUrlSource}
                                    label={UTM_SOURCE_LABEL}
                                    placeholder={UTM_SOURCE_LABEL}
                                    onSearchChange={(e) => searchUrl("source", actionTypes.FETCH_ACTIVITY_SOURCE, actionTypes.CLEARSEARCH_ACTIVITY_URLSOURCE, e)}
                                    onClick={(e) => onClickUrl(props.filterData.selUrlSource, actionTypes.CLEARSEARCH_ACTIVITY_URLSOURCE)}
                                    onChange={(e, data) => onSelectUrl("selUrlSource", e, data)}
                                 />
                                 <UrlParamsPicker
                                    options={props.urlMedium?.map((url, index) => ({
                                       key: index,
                                       text: url.medium,
                                       value: url.medium,
                                    }))}
                                    value={props.filterData.selUrlMedium}
                                    label={UTM_MEDIUM_LABEL}
                                    placeholder={UTM_MEDIUM_LABEL}
                                    onSearchChange={(e) => searchUrl("medium", actionTypes.FETCH_ACTIVITY_MEDIUM, actionTypes.CLEARSEARCH_ACTIVITY_MEDIUM, e)}
                                    onClick={(e) => onClickUrl(props.filterData.selUrlMedium, actionTypes.CLEARSEARCH_ACTIVITY_MEDIUM)}
                                    onChange={(e, data) => onSelectUrl("selUrlMedium", e, data)}
                                 />
                                 <UrlParamsPicker
                                    options={props.urlCampaigns.map((url, index) => ({
                                       key: index,
                                       text: url.campaign,
                                       value: url.campaign,
                                    }))}
                                    value={props.filterData.selUrlCampaign}
                                    label={UTM_CAMPAIGN_LABEL}
                                    placeholder={UTM_CAMPAIGN_LABEL}
                                    onSearchChange={(e) => searchUrl("campaign", actionTypes.FETCH_ACTIVITY_CAMPAIGN, actionTypes.CLEARSEARCH_ACTIVITY_CAMPAIGN, e)}
                                    onClick={(e) => onClickUrl(props.filterData.selUrlCampaign, actionTypes.CLEARSEARCH_ACTIVITY_CAMPAIGN)}
                                    onChange={(e, data) => onSelectUrl("selUrlCampaign", e, data)}
                                 />
                                 <UrlParamsPicker
                                    label={UTM_TERM_LABEL}
                                    placeholder={UTM_TERM_LABEL}
                                    options={props.urlTerm?.map((url, index) => ({
                                       key: index,
                                       text: url.term,
                                       value: url.term,
                                    }))}
                                    value={props.filterData.selUrlTerm}
                                    onSearchChange={(e) => searchUrl("term", actionTypes.FETCH_ACTIVITY_TERM, actionTypes.CLEARSEARCH_ACTIVITY_TERM, e)}
                                    onClick={(e) => onClickUrl(props.filterData.selUrlTerm, actionTypes.CLEARSEARCH_ACTIVITY_TERM)}
                                    onChange={(e, data) => onSelectUrl("selUrlTerm", e, data)}
                                 />
                                 <UrlParamsPicker
                                    label={UTM_CONTENT_LABEL}
                                    placeholder={UTM_CONTENT_LABEL}
                                    options={props.urlContent?.map((url, index) => ({
                                       key: index,
                                       text: url.content,
                                       value: url.content,
                                    }))}
                                    value={props.filterData.selUrlContent}
                                    onSearchChange={(e) => searchUrl("content", actionTypes.FETCH_ACTIVITY_CONTENT, actionTypes.CLEARSEARCH_ACTIVITY_CONTENT, e)}
                                    onClick={(e) => onClickUrl(props.filterData.selUrlContent, actionTypes.CLEARSEARCH_ACTIVITY_CONTENT)}
                                    onChange={(e, data) => onSelectUrl("selUrlContent", e, data)}
                                 />
                              </React.Fragment>
                           }
                           <div className="toggle-label" style={{ paddingTop: "16px" }}>
                              <p>Content Filters</p>
                           </div>
                           <Toggle
                              className="child-toggle"
                              label={EXCLUDE_GATED_PAGEVIEWS}
                              onChange={() => { props.selectToggleValue({ 
                                    dispatchType: actionTypes.SELECTED_TOGGLE, 
                                    key: 'excludeGatedPageviews', 
                                    value: !props.filterData.excludeGatedPageviews 
                                 }); 
                              }}
                              checked={props.filterData.excludeGatedPageviews}
                           />
                        </Form>
                     </div>
                  </div>
                  <div className="filter-buttons">
                     <button className="cancel-date-button extra-space" onClick={clearFilters}>Clear Filters</button>
                     <button
                        type="submit"
                        className="apply-filter-button"
                        onClick={() =>
                           applyFilters(
                              props.filterData.startDate,
                              props.filterData.endDate,
                              props.filterData.appliedDate
                           )
                        }
                     >
                        Apply Filters
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </React.Fragment>
   );
};

const mapStateToProps = (state) => {
  return {
      filterData: state.filterData,
      organizations: Object.values(state.activityOrganizations),
      engOrg: Object.values(state.activityEngOrg),
      order: Object.values(state.activityOrder),
      campaigns: Object.values(state.activityCampaigns),
      pageTitles: Object.values(state.activityTitle),
      urlSource: Object.values(state.urlActivitySource),
      urlMedium: Object.values(state.urlActivityMedium),
      urlCampaigns: Object.values(state.urlActivityCampaigns),
		urlTerm: Object.values(state.urlActivityTerm),
		urlContent: Object.values(state.urlActivityContent),
      isFilApplied: state.activityFetchData.isFilApplied,
      engMember: Object.values(state.activityMember),
      topicTagsIn: Object.values(state.activityTopicTagsIn),
      topicTagsEx: Object.values(state.activityTopicTagsEx),
      placementTagsIn: Object.values(state.activityPlacementTagsIn),
      placementTagsEx: Object.values(state.activityPlacementTagsEx),
      organizationTagsIn: Object.values(state.activityOrganizationTagsIn),
      organizationTagsEx: Object.values(state.activityOrganizationTagsEx),
      includeActions: Object.values(state.activityIncludeActions),
      excludeActions: Object.values(state.activityExcludeActions),
  };
};

export default compose( connect(mapStateToProps, {
    filterSelectValue,
    fetchOrganizations,
    addFilterById,
    fetchCampaigns,
    clearSearch,
    fetchEngagedOrg,
    selectToggleValue,
    fetchOrder,
    fetchTitle,
    fetchUrlParams,
    clearFilter,
    fetchData,
    fetchEngagedMember,
    commonSelectedValue,
})
)(DateAndFilter);
