import React from "react";
import { Table } from "semantic-ui-react";
import MultiTagLinks from "../../../hyperlinks/multiTagLinks";

const OrgTagsComp = ({ data }) => {
    return (
        data.organization_tags !== "N/A" ? (
            <Table.Cell title={data.organization_tags} className="blogs_link">
                <MultiTagLinks
                    type="orgs"
                    object={data.tags_data}
                    refValue={data.organization_tags}
                ></MultiTagLinks>
            </Table.Cell>
        ) : (
            <Table.Cell>{"N/A"}</Table.Cell>
        )
    );
};

export default OrgTagsComp;