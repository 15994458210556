import * as configNames from "../config/TableHeaderNames";

const ActivityHeader=[
    // organization information
    { headerName: configNames.ORGANIZATION, sorted: "organization_name", className: "custom-column-large", name: "Organization" },
    { headerName: configNames.CHILD_ORGANIZATION, sorted: "child_organization", className: "custom-column-large", name: "Child Organization" },
    { headerName: configNames.ORGANIZATION_SESSIONS, sorted: "organization_session_count", className: "right aligned text_right_align", name: "Organization sessions actions" },
    { headerName: configNames.ORGANIZATION_PAGEVIEWS, sorted: "organization_pageviews", className: "right aligned text_right_align", name: "Organization pageview actions" },
    { headerName: configNames.ORGANIZATION_ACTIONS_TAKEN, sorted: "organization_other_actions", className: "right aligned text_right_align", name: "Organization brand impressions" },
    { headerName: configNames.ORGANIZATION_ENGAGGEMENTS, sorted: "organization_engagement_count", className: "right aligned text_right_align", name: "Organization engaged actions" },
    { headerName: configNames.SECTOR, sorted: "organization_sector", name: "Sector" },
    { headerName: configNames.INDUSTRY_GROUP, sorted: "organization_industry_group", name: "Industry Group" },
    { headerName: configNames.INDUSTRY, sorted: "organization_industry", name: "Industry" },
    { headerName: configNames.SUB_INDUSTRY, sorted: "organization_sub_industry", name: "Sub Industry" },
    { headerName: configNames.ORGANIZATION_TYPE, sorted: "type", name: "Organization Type" },
    { headerName: configNames.ORGANIZATION_REVENUE, sorted: "estimated_revenue", name: "Organization Revenue" },
    { headerName: configNames.ORGANIZATION_SIZE, sorted: "organization_size", className: "right aligned text_right_align", name: "Organization Size" },
    
    // user information
    { headerName: configNames.UNIQUE_MEMBERS, sorted: "unique_users", className: "right aligned text_right_align", name: "Unique Members" },
    { headerName: configNames.ANONYMOUS_USERS, sorted: "anonymous_users", className: "right aligned text_right_align", name: "Anonymous Users" },
    { headerName: configNames.FIRST_NAME, sorted: "first_name", name: "First Name" },
    { headerName: configNames.LAST_NAME, sorted: "last_name", name: "Last Name" },
    { headerName: configNames.MEMBER_SESSIONS, sorted: "user_session_count", className: "right aligned text_right_align", name: "Member sessions actions" },
    { headerName: configNames.MEMBER_PAGEVIEWS, sorted: "user_pageviews", className: "right aligned text_right_align", name: "Member pageview actions" },
    { headerName: configNames.MEMBER_ACTIONS_TAKEN, sorted: "user_other_actions", className: "right aligned text_right_align", name: "Member brand impressions" },
    { headerName: configNames.MEMBER_ENGAGGEMENTS, sorted: "user_engagement_count", className: "right aligned text_right_align", name: "Member engaged actions" },
    { headerName: configNames.MEMBER_SENIORITY, sorted: "seniority", name: "Member Seniority" },
    { headerName: configNames.JOB_FUNCTION, sorted: "primary_role", name: "Job Function" },
    { headerName: configNames.MEMBER_TITLE, sorted: "member_title", name: "Member Title" },
    { headerName: configNames.COUNTRY, sorted: "country", name: "Country" },

    // other information
    { headerName: configNames.SESSION_ID, sorted: "session_uuid", className: "custom-column-extra-large", name: "Session ID" },
    { headerName: configNames.SESSION_PAGEVIEWS, sorted: "session_pageviews", className: "right aligned text_right_align", name: "Session Pageview Count" },
    { headerName: configNames.SESSION_ENGAGEMENTS, sorted: "session_engagement_count", className: "right aligned text_right_align", name: "Session Engaged Count" },
    { headerName: configNames.GROUP_ID, sorted: "group_uuid", className: "custom-column-extra-large", name: "Group ID" },
    { headerName: configNames.PAGE_TITLE, sorted: "page_title", name: "Page Title" },
    { headerName: configNames.PAGEVIEWS, sorted: "pageview", name: "Pageviews" },
    { headerName: configNames.ORDER, sorted: "order_name", className: "custom-column-large", name: "Order" },
    { headerName: configNames.CAMPAIGN, sorted: "campaign_name", className: "custom-column-large", name: "Campaign" },

    { headerName: configNames.AD_ORDER, sorted: "ad_name", name: "Ad Order" },
    { headerName: configNames.AD_TYPE, sorted: "ad_type", name: "Ad Type" },

    { headerName: configNames.PAGE_ELEMENTS, sorted: "page_elements", name: "Page Elements" },
    { headerName: configNames.ACTION, sorted: "action", name: "Action" },
    { headerName: configNames.ENGAGEMENT_DATE, sorted: "date", name: "Engagement Date" },
    { headerName: configNames.ENGAGEMENT_TIME, sorted: "time", name: "Engagement Time" },

    // pageview information
    { headerName: configNames.TRAFFIC_SOURCE, sorted: "traffic_source", name: "Traffic Source" },
    { headerName: configNames.NEWSLETTER_NAME, sorted: "newsletter_name", className: "custom-column-medium", name: "Newsletter Name" },
    { headerName: configNames.UTM_SOURCE, sorted: "utm_source", name: "UTM Source" },
    { headerName: configNames.UTM_MEDIUM, sorted: "utm_medium", name: "UTM Medium" },
    { headerName: configNames.UTM_CAMPAIGN, sorted: "utm_campaign", name: "UTM Campaign" },
    { headerName: configNames.UTM_TERM, sorted: "utm_term", name: "UTM Term" },
    { headerName: configNames.UTM_CONTENT, sorted: "utm_content", name: "UTM Content" },

    { headerName: configNames.IP_ADDRESS, sorted: "ip_info", name: "IP Address" },
]
export default ActivityHeader;