import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import * as actionTypes from "../../../actions/actionTypes";
import { selectValue, addSelectedValue, clearSearchForAllReport, fetchEngagedMember } from "../../../actions";
import _ from "lodash";
import EngagedMember from "../../common/engagedMember";
import { ENGAGEMENT_MEMBER_LABEL } from "../../config/const";

const EngagedmemberSection = (props) => {

    const dispatchDynamicSelectAction = (props, key, value) => {
        props.selectValue({ dispatchType: actionTypes[props.reportActionType].SELECTVALUE, reportType: props.reportActionType, key, value });
    };

    const onSearchHandler = (e) => {
		if (e.target.value.length > 1) {
			props.fetchEngagedMember(
				e.target.value,
				actionTypes[props.reportActionType].FETCH_ENG_MEMBERS,
                props.reportActionType,
			);
		}
	};

    const onChangeHandler = (e, { value }) => {
        dispatchDynamicSelectAction(props, "selEnagedMember", value);
        props.addSelectedValue({ dispatchType: actionTypes[props.reportActionType].ADD_ENG_MEMBERS, reportType: props.reportActionType, value });
	};

    const onClickHandler = () => {
		if (_.isEmpty(props.filterData.selEnagedMember)) {
			props.clearSearchForAllReport({ dispatchType: actionTypes[props.reportActionType].CLEAR_ENG_ORGANIZATIONS, reportType: props.reportActionType });
		}
	};

    return (
        <EngagedMember
            value={props.filterData.selEnagedMember}
            onSearchChange={onSearchHandler}
            onChange={onChangeHandler}
            onClick={onClickHandler}
            type="addMemberLink"
            label={ENGAGEMENT_MEMBER_LABEL}
            placeholder={ENGAGEMENT_MEMBER_LABEL}
            engMember={props.commonSelectData.engMembers}	
        />
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        commonSelectData: state.allReportCommonReducer[ownProps.reportActionType],
    };
};

export default compose(connect(mapStateToProps, {
    fetchEngagedMember,
    selectValue,
    addSelectedValue,
    clearSearchForAllReport,
}))(EngagedmemberSection);