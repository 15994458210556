import React from "react";
import { Table } from "semantic-ui-react";
import Link from "../../../hyperlinks/link";

const FirstNameComponent = ({ data }) => {
    const isAnonymousOrNA = data.first_name === "Anonymous" || data.first_name === "N/A";
    const displayValue = isAnonymousOrNA ? "Anonymous" : data.first_name;
  
    return (
      !isAnonymousOrNA ?
        <Table.Cell title={data.first_name} className="blogs_link space_height">
          <Link type="members" urlParam={data.user_uuid} refValue={data.first_name}></Link>
        </Table.Cell> :
        <Table.Cell title={data.first_name} className="space_height">{displayValue}</Table.Cell>
    );
};

export default FirstNameComponent;