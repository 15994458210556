import React, { useEffect, useState } from "react";
import { Table } from "semantic-ui-react";
import { ANONYMOUS_USERS, CHILD_ORGANIZATION, INDUSTRY, INDUSTRY_GROUP, ORGANIZATION, ORGANIZATION_ACTIONS_TAKEN, ORGANIZATION_ENGAGGEMENTS, ORGANIZATION_PAGEVIEWS, ORGANIZATION_REVENUE, ORGANIZATION_SESSIONS, ORGANIZATION_SIZE, ORGANIZATION_TYPE, SECTOR, SUB_INDUSTRY, UNIQUE_MEMBERS } from "../../config/TableHeaderNames";
import _ from "lodash";
import UserDetails from "./UserDetails";
import { getFirstElementLeftBound, numFormat } from "../../../utilities/common";
import GenerateCellContent from "./GenerateCellContent";
import { resetLeftStyles } from "../../../utilities/stickeyStyle";

const Main = (props) => {
    const [campUuid, setCampUuid] = useState([]);
    const [freezeRows, setFreezeRows] = useState([]);
    const [unFreezeRows, setUnFreezeRows] = useState([...props.props.activityData]);
    const { freezeCols } = props

    const toggleFreezeRow = (uuid) => {
        const rowToFreeze = unFreezeRows.find(row => row.key === uuid);
        if (rowToFreeze) {
            setFreezeRows([...freezeRows, rowToFreeze]);
            setUnFreezeRows(unFreezeRows.filter(row => row.key !== uuid));
        }
    };

    const toggleUnFreezeRow = (uuid) => {
        const rowToUnFreeze = freezeRows.find(row => row.key === uuid);
        const index = props.props.activityData.findIndex(row => row.key === uuid);
        if (rowToUnFreeze) {
            // setUnFreezeRows([...unFreezeRows, rowToUnFreeze]);
            setUnFreezeRows(prevRows => {
                const newRows = [...prevRows];
                newRows.splice(index, 0, rowToUnFreeze);
                return newRows;
            });
            setFreezeRows(freezeRows.filter(row => row.key !== uuid));
        }
    };

    const toggleCamp = (id) => {
        if (campUuid.includes(id)) {
            setCampUuid(campUuid.filter(item => item !== id));
        } else {
            setCampUuid([...campUuid, id]);
        }
    };

    const handleRightClick = (uuid, isFreeze) => (event) => {
        // Prevent the default context menu from appearing
        event.preventDefault();

        if (isFreeze) {
            toggleFreezeRow(uuid)
        } else {
            toggleUnFreezeRow(uuid)
        }
    }

    useEffect(() => {
        if (freezeCols >= 0) {
            resetLeftStyles()
            var cellElements = []
            const stickyColStickey = document.querySelectorAll('.sticky-cols-sticky');
            const stickyCols = document.querySelectorAll('.sticky-cols');
            const stickyColsHeader = document.querySelectorAll('.sticky-cols-header');

            // Get the left bound of the first element in each NodeList
            const leftBoundSticky = getFirstElementLeftBound(stickyColStickey);
            const leftBoundCols = getFirstElementLeftBound(stickyCols);
            const leftBoundHeader = getFirstElementLeftBound(stickyColsHeader);

            // Find the smallest left bound among the three values
            const nonZeroValues = [leftBoundSticky, leftBoundCols, leftBoundHeader].filter(value => value !== 0);
            const smallestLeftBound = Math.min(...nonZeroValues);

            cellElements.push(...stickyColStickey, ...stickyCols, ...stickyColsHeader)

            cellElements.forEach(cellElement => {
                var bounds = cellElement.getBoundingClientRect()
                const leftValue = bounds.left - smallestLeftBound;
                cellElement.style.left = `${leftValue}px`;
            });
        }
    }, [freezeRows, freezeCols]);

    useEffect(() => {
        mergeDataFunction(freezeRows, props.props.activityData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.props.activityData]);

    const mergeDataFunction = (freezeData, latestData) => {
        if (freezeRows.length > 0) {
            const merged = freezeData.map(item1 => {
                const matchingItem = latestData.find(item2 => item2.organization_uuid === item1.organization_uuid);
                return matchingItem ? matchingItem : null;
            });
            setFreezeRows(merged.filter(item => item !== null));
            setUnFreezeRows(latestData.filter(item => !merged.includes(item)));
        } else {
            setUnFreezeRows(latestData);
        }
    };

    const createRow = (data, isFreeze) => {
        return data && Object.values(data).map((e) => {
            const uuid = e?.key;
            const isCampExpanded = campUuid.includes(uuid);
            const campTableCell = [];
            const campSelectedClass = isCampExpanded ? "topic-camp-selected-v2" : "topic-camp-un-selected";
            const stickeyClassName = freezeCols >= 0 ? (isFreeze ? "sticky-cols-sticky" : "sticky-cols") : ""

            if (!_.isNull(e.user_activities) && Object.keys(e.user_activities).length > 0) {
                campTableCell.push(
                    <Table.Cell key={uuid} className={`${stickeyClassName} ${campSelectedClass}`}>
                        <i className="material-icons" onClick={() => toggleCamp(uuid)}>
                            {isCampExpanded ? "remove" : "add"}
                        </i>
                    </Table.Cell>
                );
            } else {
                campTableCell.push(<Table.Cell key={uuid} className={`${stickeyClassName} ${campSelectedClass}`}></Table.Cell>);
            }

            props.finalHeaderData && props.finalHeaderData.forEach((header, cellIndex) => {
                const freezeClassName = freezeCols >= 0 ? (cellIndex <= freezeCols ? (isFreeze ? "sticky-cols-sticky" : "sticky-cols") : "") : ""
                var frezeClass = `${freezeClassName} ${campSelectedClass}`
                if (header.headerName === ORGANIZATION) {
                    if (e.parent_org.uuid === "" && e.organization_uuid === "") {
                        campTableCell.push(<Table.Cell key={cellIndex} className={frezeClass} >Annonymous</Table.Cell>);
                    } else if (e.parent_org.uuid !== "") {
                        campTableCell.push(GenerateCellContent(e.parent_org.name, e.parent_org.uuid, "organizations", cellIndex, frezeClass));
                    } else {
                        campTableCell.push(GenerateCellContent(e?.organization_name, e?.organization_uuid, "organizations", cellIndex, frezeClass));
                    }
                } else if (header.headerName === CHILD_ORGANIZATION) {
                    if (e.parent_org.uuid === "" || e.parent_org.uuid === e?.organization_uuid) {
                        campTableCell.push(<Table.Cell key={cellIndex} className={frezeClass} >-</Table.Cell>);
                    } else {
                        campTableCell.push(GenerateCellContent(e?.organization_name, e?.organization_uuid, "organizations", cellIndex, frezeClass));
                    }
                } else if (_.includes([SECTOR, INDUSTRY, INDUSTRY_GROUP, SUB_INDUSTRY, ORGANIZATION_TYPE, ORGANIZATION_REVENUE], header.headerName)) {
                    if (e[header.sorted] !== "") {
                        campTableCell.push(<Table.Cell key={cellIndex} className={frezeClass}>{e[header.sorted]}</Table.Cell>);
                    } else {
                        campTableCell.push(<Table.Cell key={cellIndex} className={frezeClass}>{"N/A"}</Table.Cell>);
                    }

                } else {
                    const val = e[header.sorted];
                    campTableCell.push(
                        <Table.Cell
                            style={{ height: "46px" }}
                            textAlign={_.includes([ORGANIZATION_SIZE, ORGANIZATION_ACTIONS_TAKEN, ORGANIZATION_PAGEVIEWS, ORGANIZATION_SESSIONS, ORGANIZATION_ENGAGGEMENTS, UNIQUE_MEMBERS, ANONYMOUS_USERS], header.headerName) ? "right" : "left"}
                            key={cellIndex}
                            className={frezeClass}
                        >
                            {(header.headerName === ORGANIZATION_SIZE) ? numFormat(val) : val}
                        </Table.Cell>
                    );
                }
            });

            return (
                <React.Fragment key={uuid}>
                    <Table.Row
                        className={campSelectedClass}
                        onDoubleClick={() => toggleCamp(uuid)}
                        onContextMenu={handleRightClick(uuid, isFreeze)}
                    >
                        {campTableCell}
                    </Table.Row>
                    {isCampExpanded && e.user_activities && Object.values(e.user_activities).map((user, idx) => (
                        <UserDetails data={user} props={props} key={idx} freezeCols={freezeCols} isFreeze={isFreeze} />
                    ))}
                </React.Fragment>
            );
        });
    };

    return (
        <React.Fragment>
            <Table.Body className="sticky-rows">  {createRow(freezeRows, false)} </Table.Body>
            <Table.Body>{createRow(unFreezeRows, true)}</Table.Body>
        </React.Fragment>
    );
};

export default Main;