import _ from "lodash";
import React from "react";
import { Table, Loader, Message, Divider } from "semantic-ui-react";
import "../../css/common.css";
import { connect } from "react-redux";
import {
	clientSetPageCount,
	fetchLeadEngSummary,
	sortClientLeadEngSummary,
	clearClientLeadSort,
	showMoreClientLeadEngSummaryLoading,
	isTotalCountShowMore
} from "../../actions";
import {
	getDisplayDate,
	//toFloat,
	numFormat,
	getOrgsInCampaigns,
	getOrderInCampaigns,
	capitalizeFirstLetter,
	impressionNumFormat,
	decimalFormat
} from "../../utilities/common";
import Link from "../hyperlinks/link";
import MultiLinks from "../hyperlinks/multiLinks";
import * as actionTypes from "../../actions/actionTypes";
import { url } from "../../api/urlHelper";
import SortIcon from "@mui/icons-material/Sort";
import LeadsCampaign from "../campaign/clientLeadsCampaign";
import HocDrawer from "../common/hocDrawer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TableHeaderComponent from "../common/tableHeader";

const ClientLeadEngSummaryTable = (props) => {
	let tableBody;
	let showMoreButton;
	let loading;
	let noresult;
	
	const showMore = async () => {
		props.clearClientLeadSort();
		props.showMoreClientLeadEngSummaryLoading();
		props.isTotalCountShowMore(true);
		await props.fetchLeadEngSummary(
			url.leadEngagementSummaryReport,
			props.filterData,
			props.PageCount,
			//pageCount,
			"",
			actionTypes.MORECLIENTLEADENGSUMMARYDATA,
			true
		);
		props.clientSetPageCount(actionTypes.SETCLIENTSUMMARYPGCOUNT);
		//setPageCount(pageCount + 1);
	};

	if (props.loading) {
		loading = (
			<div className="show-loader">
				<Loader active inline="centered" />
			</div>
		);
	}

	const createRow = (data) => {
		//lead purchased total count
		const getTotalLeadPurchase = () => {
			let total = 0;
			total = _.sumBy(data, val => {
				return val.leads_purchased;
			});
			return total;
		};

		//Not Started column count
		const getTotalNotStarted = () => {
			let total = 0;
			total = _.sumBy(data, val => {
				return val.leads_purchased - val.received - val.in_review - val.accepted_not_delivered - val.total_leads_delivered;
			});
			return total;
		};

		//Received count
		const getTotalReceived = () => {
			let total = 0;
			total = _.sumBy(data, val => {
				return val.received;
			});
			return total;
		};

		//In-review counts
		const getTotalInReveived = () => {
			let total = 0;
			total = _.sumBy(data, val => {
				return val.in_review;
			});
			return total;
		};

		//Accepted not delivered counts
		const getTotalAND = () => {
			let total = 0;
			total = _.sumBy(data, val => {
				return val.accepted_not_delivered;
			 });
			return total;
		};

		//Lead delivery counts
		const getTotalLeadDeliveryInDD = () => {
			let total = 0;
			total = _.sumBy(data, val => {
				return val.lead_count;
			});
			return total;
		};

		//Previous delivered counts
		const getTotalPreviousDeliverd = () => {
			let total = 0;
			total = _.sumBy(data, val => {
				return val.previously_delivered;
			});
			return total;
		};

		//Total lead delivered counts
		const getTotalLD = () => {
			let total = 0;
			total = _.sumBy(data, val => {
				return val.total_leads_delivered;
			});
			return total;
		};

		//Leads Outstanding counts
		const getTotalLeadOutstanding = () => {
			let total = 0;
			total = _.sumBy(data, val => {
				return val.leads_outstanding;
			});
			return total;
		};

		//Rejected counts
		const getTotalRejected = () => {
			let total = 0;
			total =_.sumBy(data, val => {
				return val.rejected;
			});
			return total;
		};

		let tableTotalCell = [];
		if (data.length > 0) {
			let tableRow = [];
			data.forEach((e) => {
				let tableCell = [];
				const LeadsCampaignDrawer = HocDrawer(
					ArrowForwardIcon,
					LeadsCampaign,
					"CAMPAIGN HIGHLIGHTS ",
					e
				);
				let OrderData;
				let orders = [];
				let order = {};
				if (e.campaigns !== null) {
					OrderData = getOrderInCampaigns(e.campaigns);
					OrderData.map((order) => {
						orders.push(order.name);
						return orders;
					});
				}
				order.name = orders;
				tableCell.push(
					<Table.Cell title={e.agency_name}>{e.agency_name}</Table.Cell>
				);
				tableCell.push(
					<Table.Cell title={e.organization_name} className="blogs_link">
						{e.campaigns ? (
							<MultiLinks
								type="organizations"
								object={getOrgsInCampaigns(e.campaigns)}
							></MultiLinks>
						) : (
							e.organization_name
						)}
					</Table.Cell>
				);
				tableCell.push(
					<Table.Cell title={order.name} className="blogs_link">
						{OrderData ? (
							<MultiLinks type="orders" object={OrderData}></MultiLinks>
						) : (
							order.name
						)}
					</Table.Cell>
				);
				tableCell.push(
					<Table.Cell title={e.campaign_name} className="blogs_link">
						{e.campaign_name ? (
							<Link
								type="campaigns"
								urlParam={e.campaign_uuid}
								refValue={e.campaign_name}
							></Link>
						) : (
							""
						)}
					</Table.Cell>
				);
				tableCell.push(<Table.Cell title={e.client_success_manager_name}>{e.client_success_manager_name}</Table.Cell>);
				tableCell.push(<Table.Cell title={getDisplayDate(e.start_date)}>{getDisplayDate(e.start_date)}</Table.Cell>);
				tableCell.push(<Table.Cell title={getDisplayDate(e.end_date)}>{getDisplayDate(e.end_date)}</Table.Cell>);
				tableCell.push(<Table.Cell title={capitalizeFirstLetter(e.status)}>{capitalizeFirstLetter(e.status)}</Table.Cell>);
				//Lead Delivery State
				tableCell.push(<Table.Cell title={capitalizeFirstLetter(e.delivery_state)}>{capitalizeFirstLetter(e.delivery_state)}</Table.Cell>);
				//Leads Purchased Column
				tableCell.push(
					<Table.Cell textAlign="right" title={numFormat(e.leads_purchased)}>
						{numFormat(e.leads_purchased)}
					</Table.Cell>
				);
				tableCell.push(<Table.Cell textAlign="right" title={numFormat(e.not_started)}>{numFormat(e.not_started)}</Table.Cell>);
				tableCell.push(<Table.Cell textAlign="right" title={numFormat(e.received)}>{numFormat(e.received)}</Table.Cell>);
				tableCell.push(<Table.Cell textAlign="right" title={numFormat(e.in_review)}>{numFormat(e.in_review)}</Table.Cell>);
				tableCell.push(<Table.Cell textAlign="right" title={numFormat(e.accepted_not_delivered)}>{numFormat(e.accepted_not_delivered)}</Table.Cell>);
				tableCell.push(
					<Table.Cell textAlign="right" title={e.lead_count === 0 ? e.lead_count : impressionNumFormat(e.lead_count)}>
						{e.lead_count === 0 ? e.lead_count : impressionNumFormat(e.lead_count)}
					</Table.Cell>
				);				
				tableCell.push(<Table.Cell textAlign="right" title={numFormat(e.previously_delivered)}>{numFormat(e.previously_delivered)}</Table.Cell>);
				//Leads Outstanding Column
				tableCell.push(
					<Table.Cell textAlign="right" title={numFormat(e.total_leads_delivered)}>
						{numFormat(e.total_leads_delivered)}
					</Table.Cell>
				);
				tableCell.push(
					<Table.Cell textAlign="right" title={numFormat(e.leads_outstanding)}>
						{numFormat(e.leads_outstanding)}
					</Table.Cell>
				);
				tableCell.push(<Table.Cell textAlign="right" title={e.rejected}>{e.rejected}</Table.Cell>);
				tableCell.push(
					<Table.Cell textAlign="right" title={isNaN(e.percent_delivered) ? 0.0 + "%" : decimalFormat(e.percent_delivered) + "%"}>
						{isNaN(e.percent_delivered) ? 0.0 + "%" : decimalFormat(e.percent_delivered) + "%"}
					</Table.Cell>
				);
				tableCell.push(
					<Table.Cell textAlign="right" title={isNaN(e.percentage_complete) ? 0.0 + "%" : decimalFormat(e.percentage_complete) + "%"}>
						{isNaN(e.percentage_complete) ? 0.0 + "%" : decimalFormat(e.percentage_complete) + "%" }
					</Table.Cell>
				);
				tableCell.push(
					<>
					{
					  e.pace === "green" ?
						<Table.Cell textAlign="right" title={"GREEN"}> <button className="button_color_green"/> </Table.Cell>
					  :
					  e.pace === "yellow" ?
						<Table.Cell textAlign="right" title={"YELLOW"}> <button className="button_color_yellow"/> </Table.Cell>
					  :
						<Table.Cell textAlign="right" title={"RED"}> <button className="button_color_red"/> </Table.Cell>
					}
					</>
				  );
				tableCell.push(
					<Table.Cell>
						<LeadsCampaignDrawer />
					</Table.Cell>
				);
				tableRow.push(<Table.Row children={tableCell} />);
			});
			tableTotalCell.push(<Table.Cell textAlign="center">-</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="center">-</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="center">-</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="center">-</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="center">-</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="center">-</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="center">-</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="center">-</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="center">Total:</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right">{numFormat(getTotalLeadPurchase())}</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right">{getTotalNotStarted() <= 0 ? 0 : numFormat(getTotalNotStarted())}</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right">{numFormat(getTotalReceived())}</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right">{numFormat(getTotalInReveived())}</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right">{numFormat(getTotalAND())}</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right">{numFormat(getTotalLeadDeliveryInDD())}</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right">{numFormat(getTotalPreviousDeliverd())}</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right">{numFormat(getTotalLD())}</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right">{numFormat(getTotalLeadOutstanding())}</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right">{numFormat(getTotalRejected())}</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right">-</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right">-</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="right">-</Table.Cell>);
			tableTotalCell.push(<Table.Cell textAlign="center">-</Table.Cell>);
			tableRow.push(<Table.Row children={tableTotalCell} />);
			return tableRow;
		}
	};

	if (!_.isEmpty(props.leadEngSummary)) {
		tableBody = <Table.Body>{createRow(props.leadEngSummary)}</Table.Body>;
		//tableBody = <Table.Body>{createRow(dummyData)}</Table.Body>
		showMoreButton = (
			<div className="table-divider">
			  <Divider>
				<div className="show-more-component">
				  {!props.clientLeadFetchData.resCampaignSummaryLeadEngsValue ? 
				  (
				  <button className="show-more-button" onClick={showMore}>
					Show More
				  </button>
				  ):(
				  <button className="show-more-button-disable">
					Show More
				  </button>
				  )}
				  
				</div>
			  </Divider>
			</div>
		  );
	} else if (
		props.isFilApplied &&
		!props.loading &&
		_.isEmpty(props.leadEngSummary)
	) {
		noresult = <Message>No results found.</Message>;
	}

	let tableHeaderData = [
		{
		  label: "Agency",
		  sorted: props.column === "agency_name" ? props.direction : null,
		  onClick: () => props.sortClientLeadEngSummary("agency_name"),
		},
		{
		  label: "Account",
		  sorted: props.column === "organization_name" ? props.direction : null,
		  onClick: () => props.sortClientLeadEngSummary("organization_name"),
		},
		{
		  label: "Order",
		  sorted: props.column === "order.name" ? props.direction : null,
		  onClick: () => props.sortClientLeadEngSummary("order.name"),
		},
		{
		  label: "Campaign",
		  sorted: props.column === "campaign_name" ? props.direction : null,
		  onClick: () => props.sortClientLeadEngSummary("campaign_name"),
		  className: "custom-column-large",
		},
		{
			label: "CSM",
			sorted: props.column === "client_success_manager_name" ? props.direction : null,
			onClick: () => props.sortClientLeadEngSummary("client_success_manager_name"),
		  },
		{
		  label: "Start Date",
		  sorted: props.column === "start_date" ? props.direction : null,
		  onClick: () => props.sortClientLeadEngSummary("start_date"),
		},
		{
		  label: "End Date",
		  sorted: props.column === "end_date" ? props.direction : null,
		  onClick: () => props.sortClientLeadEngSummary("end_date"),
		},
		{
			label: "Campaign Status",
			sorted: props.column === "status" ? props.direction : null,
			onClick: () => props.sortClientLeadEngSummary("status"),
			className: "custom-column-medium",
		  },
		{
		  label: "Delivery Status",
		  sorted: props.column === "delivery_state" ? props.direction : null,
		  onClick: () => props.sortClientLeadEngSummary("delivery_state"),
		},
		{
		  label: "Leads Purchased",
		  sorted:
			props.column === "leads_purchased"
			  ? props.direction
			  : null,
		  onClick: () =>
			props.sortClientLeadEngSummary("leads_purchased"),
		  className: "impressions",
		  textAlign: "right",
		},
		{
			label: "Not Started",
			sorted:
			  props.column === "not_started"
				? props.direction
				: null,
			onClick: () =>
			  props.sortClientLeadEngSummary("not_started"),
			className: "impressions",
			textAlign: "right",
		  },
		{
			label: "Received",
			sorted:
			  props.column === "received"
				? props.direction
				: null,
			onClick: () =>
			  props.sortClientLeadEngSummary("received"),
			className: "impressions",
			textAlign: "right",
		  },
		  {
			label: "In-Review",
			sorted:
			  props.column === "in_review"
				? props.direction
				: null,
			onClick: () =>
			  props.sortClientLeadEngSummary("in_review"),
			className: "impressions",
			textAlign: "right",
		  },
		  {
			label: "Accepted Pending Delivery",
			sorted: props.column === "accepted_not_delivered" ? props.direction : null,
			onClick: () => props.sortClientLeadEngSummary("accepted_not_delivered"),
			className: "impressions",
			textAlign: "right",
		  },
		  {
			  label: "Leads Delivered In Date Range",
			  textAlign: "right",
			  className: "counts",
			  sorted: props.column === "lead_count" ? props.direction : null,
			  onClick: () => props.sortClientLeadEngSummary("lead_count")
		  },
		{
		  label: "Previously Delivered",
		  sorted: props.column === "previously_delivered" ? props.direction : null,
		  onClick: () => props.sortClientLeadEngSummary("previously_delivered"),
		  className: "impressions",
		  textAlign: "right",
		},
		{
		  label: "Total Leads Delivered",
		  sorted: props.column === "total_leads_delivered" ? props.direction : null,
		  onClick: () => props.sortClientLeadEngSummary("total_leads_delivered"),
		  className: "percentage_served_to_date",
		  textAlign: "right",
		},
		{
		  label: "Leads Outstanding",
		  sorted: props.column === "leads_outstanding" ? props.direction : null,
		  onClick: () => props.sortClientLeadEngSummary("leads_outstanding"),
		  textAlign: "right",
		},
		{
		  label: "Rejected",
		  sorted: props.column === "rejected" ? props.direction : null,
		  onClick: () => props.sortClientLeadEngSummary("rejected"),
		  textAlign: "right",
		},
		{
			label: "Percent Delivered",
			sorted: props.column === "percent_delivered" ? props.direction : null,
			onClick: () => props.sortClientLeadEngSummary("percent_delivered"),
			textAlign: "right",
		},
		{
			label: "Percent Complete",
			sorted: props.column === "percentage_complete" ? props.direction : null,
			onClick: () => props.sortClientLeadEngSummary("percentage_complete"),
			textAlign: "right",
		},
		{
			label: "Pace",
			sorted: props.column === "pace" ? props.direction : null,
			onClick: () => props.sortClientLeadEngSummary("pace"),
			className: "clicks",
			textAlign: "right",
		},
		{
			label: <SortIcon />,
			textAlign: "left",
			className: "icon"
		},
	  ];

  return (
    <div>
      <div className="sortable-table-component">
        <Table sortable fixed singleLine>
          <Table.Header>
            <Table.Row>
              {tableHeaderData.map((header) => (
                <TableHeaderComponent
                  label={header.label}
                  sorted={header.sorted}
                  onClick={header.onClick}
                  className={header.className}
                  textAlign={header.textAlign}
                />
              ))}
            </Table.Row>
          </Table.Header>
          {tableBody}
        </Table>
      </div>
      {loading}
      {showMoreButton}
      {noresult}
    </div>
  );
};

const mapStateToProps = (state) => {
	return {
		wpLink: state.links.wpLink,
		isFilApplied: state.clientLeadFetchData.isFilApplied,
		assetType: Object.values(state.assetTypes),
		filterData: state.clientLeadFilterData,
		leadEngSummary: state.clientLeadFetchData.leadEngSummary,
		leadEngSummaryCount: state.clientLeadFetchData.leadEngSummaryCount,
		loading: state.clientLeadFetchData.isLoadingLeadEngSummary,
		column: state.clientLeadFetchData.leadEngSummarycolumn,
		direction: state.clientLeadFetchData.leadEngSummarydirection,
		PageCount: state.clientLeadFetchData.summaryPageCount,
		leadPurchasedCount: state.clientLeadFetchData.leadPurchasedCount,
		receivedCount: state.clientLeadFetchData.receivedCount,
		inReviewCount: state.clientLeadFetchData.inReviewCount,
		accepted_not_delivered: state.clientLeadFetchData.accepted_not_delivered,
		leadDeliveredcount: state.clientLeadFetchData.leadDeliveredcount,
		prevDeliverdCount: state.clientLeadFetchData.prevDeliverdCount,
		totalLeadDelveredCount: state.clientLeadFetchData.totalLeadDelveredCount,
		leadOutstandingCount: state.clientLeadFetchData.leadOutstandingCount,
		rejectedCount: state.clientLeadFetchData.rejectedCount,
		isTotalLeadCount: state.clientLeadFetchData.isTotalLeadCount,
		clientLeadFetchData: state.clientLeadFetchData
	};
};

export default connect(mapStateToProps, {
	fetchLeadEngSummary,
	sortClientLeadEngSummary,
	clearClientLeadSort,
	showMoreClientLeadEngSummaryLoading,
	clientSetPageCount,
	isTotalCountShowMore
})(ClientLeadEngSummaryTable);
