import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Form } from "semantic-ui-react";
import DatePicker from "../common/datePicker";
import { compose } from "redux";
import {
  selSponsoredContentFilterValue,
  clearSearchOrgs,
  fetchOrganizations,
  fetchCampaigns,
  addOrganization,
  addOrder,
  fetchOrder,
  clearSearchOrder,
  resetOrgsOrCampaigns,
  clearSearchCampaigns,
  addCampaigns,
  clearSponsoredContentSerchedValue,
  fetchPgAuthors,
  fetchTags,
  fetchPgCountry,
  addCountries,
  selSCFilterValue,
  selSCToggles,
  firmographicOrgsTypeCall,
  SenioritiesList,
  fetchPageTitle, 
  fetchUrlParams,
  fetchPrimaryTopic,
  fetchEngagedMember,
  applySponsoredContentFilter,
  fetchPageviewTableData,
  fetchEngagedOrg,
  applySponsoredDateFilterDate,
  updateCommonData,
  selectValue
} from "../../actions";
import { getDisplayDate, getStartEndDates, getYesterdaysDate } from "../../utilities/common";
import * as actionTypes from "../../actions/actionTypes";
import { differenceInDays, subDays, subYears } from "date-fns";
import { DateRangePicker } from "react-date-range";
import OrganizationPicker from "../common/organization";
import OrderPicker from "../common/order";
import _ from "lodash";
import StatusPicker from "../common/status";
import CampaignPicker from "../common/campaign";
import AuthorPicker from "../common/author";
import Site from "../common/site";
import TagsPicker from "../common/tags";
import CountryMultiSelectPicker from "../common/countryPicker";
import EngagedOrganization from "../common/engagedOrganization";
import FirmographicFilter from "../common/firmographicFilter";
import PageFirmoGraphic from "../config/FilterFirmogaphicData";
import Toggle from "../base/toggle";
import { url } from "../../api/urlHelper";
import PersonaFilter from "../common/personaFilter";
import FilterDateRange from "./filterDateRange";
import UrlParamsPicker from "../common/urlParams";
import SingleSelectDropDown from "../base/singleSelectDropDown";
import PrimaryTopic from "../common/primaryTopic";
import EngagedMember from "../common/engagedMember";
import DeliveryPicker from "../common/deliveryState";
import TrafficSource from "../common/trafficSource";
import PacingFilter from "../common/pacingFilter";
import TrafficFilterPicker from "../common/trafficeFilter";
import CustomDatePickerWithCompare from "../common/CustomDatePickerWithCompare";
import SearchFilterIcon from "../common/SearchFilterIcon";
import SelectDatePicker from "../common/SelectDatePicker";
import CompetitorFilterToggleForOthers from "../common/CompetitorFilterToggleForOthers";
import { getCompareDateWithOtherFunction } from "../../utilities/Calendar";
import { EXCLUDE_PRE_PUBLISH_PAGEVIEWS, PUBLISHED_DATE_RANGE, VIEW } from "../config/const";
import CSMSelection from "../common/CSMSelection";
import { SPONSORED_CAMPAIGN_PAGE_DETAILS, SPONSORED_CAMPAIGN_PAGE_VIEW_DETAILS, SPONSORED_CAMPAIGN_SUMMARY } from "../config/rolebasedReportNames";

const SearchFilter = (props) => {
  const [datePopup, setDatePopup] = useState("custom-date-popup-close");
  const [filterPopup, setFilterPopup] = useState("filter-popup-close");
  const [filterSelectFocus, setFilterSelectFocus] = useState("filter-icon");
  const [filterIconColor, setFilterIconColor] = useState("material-icons grey");
  const [senioritiesIN, setSenioritiesIn] = useState([]);
  const [senioritiesEX, setSenioritiesEX] = useState([]);
  const [JobFunctionsIN, setJobFunctionsIN] = useState([]);
  const [JobFunctionsEX, setJobFunctionsEX] = useState([]);
  const [checkSelectState, setCheckSelectState] = useState(false);
  const [comparePeriod, setComparePeriod] = useState("Previous Period");
  const [orgType, setOrgType] = useState([]);
  const [orgSize, setOrgSize] = useState([]);
  const [orgRevenue, setOrgRevenue] = useState([]);
  const [sector, setSector] = useState([]);
  const [industryGroup, setIndustryGroup] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [subIndustry, setSubIndustry] = useState([]);
  const tempSelection = useRef({});
  const clearfilterClick = useRef(false);
  const [datePopupFilter, setDatePopupFilter] = useState(
    "custom-date-popup-close"
  );

  //Date range event handling and state management
  const [dateRange, setDateRange] = useState({
    selection: {
      startDate: getYesterdaysDate(),
      endDate: getYesterdaysDate(),
      key: "selection",
      //color:"#e5582b",
    },
  });

  const [dateRangeFilter, setDateRangeFilter] = useState({
    selection: {
      startDate: getYesterdaysDate(),
      endDate: getYesterdaysDate(),
      key: "selection",
      //color:"#e5582b",
    },
  });

  useEffect(() => {
    if (document.getElementsByClassName("rdrDateDisplayWrapper")?.length !== 0) {
      document.getElementsByClassName("rdrDateDisplayWrapper")[0].style.display = "none";
    }
  }, [checkSelectState]);

  useEffect(()=>{
    if (props.isFilApplied && props.sponsoredContentFilterData.showScChart) {
          props.fetchPageviewTableData(
            url.pageviewReportCharts,
            props.sponsoredContentFilterData,
            1,
            "",
            actionTypes.SPPONSOREDCHARTS,
            null,
            null,
            "sponsored-content"
          );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [props.isFilApplied, props.sponsoredContentFilterData.showScChart]);

  const previousPeriodData = (startDate, endDate, comparePeriod) => {
    props.selSponsoredContentFilterValue( checkSelectState, actionTypes.SELSPONSOREDCONTENTCUSTOMRANGE);
    let prevStartDate, prevEndDate;
    
    if (comparePeriod === "Previous Year") {
      prevStartDate = subYears(new Date(startDate), 1);
      prevEndDate = subYears(new Date(endDate), 1);
    } else if (_.includes(["7", "30", "90"], comparePeriod)) {
      const result = getCompareDateWithOtherFunction(updateCommonData, actionTypes.SET_SPONSORED_VALUES, comparePeriod);
      startDate = new Date(result.startDate);
      endDate = new Date(result.endDate);
      prevStartDate = new Date(result.prevStartDate);
      prevEndDate = new Date(result.prevEndDate);
  } else if (comparePeriod === "Previous Day of Week") {
      endDate = startDate;
      prevStartDate = subDays(startDate, 7); // Subtract 7 days to get the previous occurrence of the same day
      prevEndDate = subDays(startDate, 7); // Similarly, adjust end date for consistency
  } else {
      let differenceinDays = differenceInDays(endDate, startDate);
      prevEndDate = subDays(new Date(startDate), 1);
      prevStartDate = subDays(new Date(prevEndDate), differenceinDays);
    }
    setDateRange({
      selection1: { startDate: startDate, endDate: endDate, key: "selection1", color: "#e5582b" },
      selection2: getCompareState(prevStartDate, prevEndDate),
    });
    return cutomDate(prevStartDate, prevEndDate);
  };

  const getCompareState = (prevStartDate, prevEndDate) => {
    return {
      startDate: prevStartDate,
      endDate: prevEndDate,
      key: "selection2",
      color: "#CC167F",
    };
  };

  const getClearDate = () => {
    setCheckSelectState(false);
    let sel = dateRange.selection1;
    sel.key = "selection";
    setDateRange({
      selection: sel,
    });
  };

  const cutomDate = (startDate, endDate) => {
    return getDisplayDate(startDate) + " - " + getDisplayDate(endDate);
  };

  const selectDateRange = (e, { value }) => {
    props.selSponsoredContentFilterValue(
      value,
      actionTypes.APPLYSPONSOREDCONTENTDATE
    );
    props.selSponsoredContentFilterValue(false, actionTypes.SELSPONSOREDCONTENTCUSTOMRANGE);

    setDatePopup("custom-date-popup-close");
    if (value === "Custom Date Range") {
      setDatePopup("custom-date-popup-open");
    } else {
      let dates = getStartEndDates(value);
      props.selSponsoredContentFilterValue(
        dates.startDate,
        actionTypes.SELSPONSOREDCONTENTSTARTDATE
      );
      props.selSponsoredContentFilterValue(
        dates.endDate,
        actionTypes.SELSPONSOREDCONTENTENDDATE
      );
      props.selSponsoredContentFilterValue(
        dates.startDate,
        actionTypes.SELSPONSOREDCONTENTPREVSTARTDATE
      );
      props.selSponsoredContentFilterValue(
        dates.endDate,
        actionTypes.SELSPONSOREDCONTENTPREVENDDATE
      );
      cancelDate();
      if (props.isFilApplied) {
        applyFilters(dates.startDate, dates.endDate, false);
      }
    }
  };

  const applyDate = () => {
    props.selSponsoredContentFilterValue(checkSelectState, actionTypes.SELSPONSOREDCONTENTCUSTOMRANGE);
    if (dateRange.hasOwnProperty("selection")) {
      tempSelection.current = dateRange.selection;
      props.selSponsoredContentFilterValue(
        getDisplayDate(tempSelection.current?.startDate),
        actionTypes.SELSPONSOREDCONTENTSTARTDATE
      );
      props.selSponsoredContentFilterValue(
        getDisplayDate(tempSelection.current?.endDate),
        actionTypes.SELSPONSOREDCONTENTENDDATE
      );
    } else if (dateRange.hasOwnProperty("selection1")) {
      tempSelection.current = dateRange.selection1;
      props.selSponsoredContentFilterValue(
        getDisplayDate(dateRange?.selection1?.startDate),
        actionTypes.SELSPONSOREDCONTENTSTARTDATE
      );
      props.selSponsoredContentFilterValue(
        getDisplayDate(dateRange?.selection1?.endDate),
        actionTypes.SELSPONSOREDCONTENTENDDATE
      );
      props.selSponsoredContentFilterValue(
        getDisplayDate(dateRange?.selection2?.startDate),
        actionTypes.SELSPONSOREDCONTENTPREVSTARTDATE
      );
      props.selSponsoredContentFilterValue(
        getDisplayDate(dateRange?.selection2?.endDate),
        actionTypes.SELSPONSOREDCONTENTPREVENDDATE
      );
    }
    setDatePopup("custom-date-popup-close");
    if (props.isFilApplied) {
      applyFilters(
        tempSelection.current.startDate,
        tempSelection.current.endDate,
        checkSelectState
      );
    }
  };

  const cancelDate = () => {
    setCheckSelectState(false);
    setDateRange({
      selection: {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    });

    setDatePopup("custom-date-popup-close");
  };

  //Org event handling and state management

  const onClickOrg = () => {
    props.clearSearchOrgs(actionTypes.CLEARSEARCHSPONSOREDCONTENTORGS);
  };

  const searchOrg = (e) => {
    if (e.target.value.length > 1) {
      props.fetchOrganizations(
        props.sponsoredContentFilterData.excludeQACampaign,
        e.target.value,
        actionTypes.FETCHSPONSOREDCONTENTORGS,
        props.organizations,
      );
    }
  };

  const onSelectOrg = (e, { value }) => {
    props.selSponsoredContentFilterValue(
      value,
      actionTypes.SELSPONSOREDCONTENTORGS
    );
    props.addOrganization(value, actionTypes.ADDSPONSOREDCONTENTORGS);
    if (!_.isEmpty(props.sponsoredContentFilterData.campaign)) {
      props.fetchCampaigns(
        "",
        props.sponsoredContentFilterData.site,
        props.sponsoredContentFilterData.selTagsIN,
        props.sponsoredContentFilterData.selTagsEX,
        props.sponsoredContentFilterData.excludeQACampaign,
        "",
        value,
        "",
        "start_date",
        actionTypes.FETCHSELSPONSOREDCONTENTCAMPAIGNS,
        props.sponsoredContentFilterData.campaign,
        true,
        actionTypes.RESETSELSPONSOREDCONTENTCAMPAIGNS
      );
    }
  };

  //Order event handlers

  const onSelectOrder = (e, { value }) => {
    props.selSponsoredContentFilterValue(
      value,
      actionTypes.SELSPONSOREDCONTENTORDER
    );
    props.addOrder(value, actionTypes.ADDSPONSOREDCONTENTORDER);
  };
  const searchOrder = (e) => {
    if (e.target.value.length > 1) {
      props.fetchOrder(
        e.target.value,
        "name",
        actionTypes.FETCHSPONSOREDCONTENTORDER
      );
    }
  };

  const onClickOrder = () => {
    props.clearSearchOrder(actionTypes.CLEARSEARCHSPONSOREDCONTENTORDER);
  };

  const onSelectCampaignStatus = (e, { value }) => {
    props.selSponsoredContentFilterValue(
      value,
      actionTypes.SELSPONSOREDCONTENTSTATUS
    );
    props.resetOrgsOrCampaigns(actionTypes.RESETSPONSOREDCONTENTCAMPAIGNS);
    props.selSponsoredContentFilterValue(
      [],
      actionTypes.SELSPONSOREDCONTENTCAMPAIGNS
    );
  };

  //Campaign event handling ans state management

  const onClickCampaign = () => {
    if (_.isEmpty(props.sponsoredContentFilterData.organization)) {
      props.clearSearchCampaigns(
        actionTypes.CLEARSEARCHSPONSOREDCONTENTCAMPAIGNS
      );
    } else if (!_.isEmpty(props.sponsoredContentFilterData.organization)) {
      props.fetchCampaigns(
        "",
        props.sponsoredContentFilterData.site,
        props.sponsoredContentFilterData.selTagsIN,
        props.sponsoredContentFilterData.selTagsEX,
        props.sponsoredContentFilterData.excludeQACampaign,
        "",
        props.sponsoredContentFilterData.organization,
        "",
        "start_date",
        actionTypes.FETCHSPONSOREDCONTENTCAMPAIGNS
      );
    }
  };

  const searchCampaign = (e) => {
    if (e.target.value.length > 1) {
      props.fetchCampaigns(
        "",
        props.sponsoredContentFilterData.site,
        props.sponsoredContentFilterData.selTagsIN,
        props.sponsoredContentFilterData.selTagsEX,
        props.sponsoredContentFilterData.excludeQACampaign,
        e.target.value,
        props.sponsoredContentFilterData.organization,
        "",
        //'',
        "name",
        actionTypes.FETCHSPONSOREDCONTENTCAMPAIGNS
      );
    }
  };

  const onSelectCampaigns = (e, { value }) => {
    props.addCampaigns(value, actionTypes.ADDSPONSOREDCONTENTCAMPAIGNS);
    props.selSponsoredContentFilterValue(
      value,
      actionTypes.SELSPONSOREDCONTENTCAMPAIGNS
    );
  };

  //Author event handling and state management

  const onClickAuthor = () => {
    if (_.isEmpty(props.sponsoredContentFilterData.selAuthor)) {
      props.clearSponsoredContentSerchedValue(
        actionTypes.CLEARSEARCHSPONSOREDCONTENTAUTHOR
      );
    }
  };

  const searchAuthor = (e, { value }) => {
    if (e.target.value.length > 1) {
      props.fetchPgAuthors(
        e.target.value,
        actionTypes.FETCHSPONSOREDCONTENTAUTHOR
      );
    } else {
      props.clearSponsoredContentSerchedValue(
        actionTypes.CLEARSEARCHSPONSOREDCONTENTAUTHOR
      );
    }
  };

  const onSelectAuthor = (e, { value }) => {
    props.selSponsoredContentFilterValue(
      value,
      actionTypes.SELSPONSOREDCONTENTAUTHOR
    );
  };

  const onSelectSite = (e, { value }) => {
    props.selSponsoredContentFilterValue(
      value,
      actionTypes.SELSPONSOREDCONTENTSITE
    );
  };

  //Topic tags event handling and state management

  const onClickTopicTagIn = () => {
    props.clearSponsoredContentSerchedValue(
      actionTypes.CLEARSEARCHSPONSOREDCONTENTTOPICTAGIN
    );
  };

  const searchTopicTagsIn = (e) => {
    if (e.target.value.length > 1) {
      props.fetchTags(
        e.target.value,
        "topic",
        actionTypes.FETCHSPONSOREDCONTENTTOPICTAGSIN,
        props.sponsoredContentFilterData.selTopicTagsEX,
        props.sponsoredContentFilterData.site
      );
    }
  };

  const onSelectTopicTagsIn = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.sponsoredContentFilterData.selTopicTagsEX.indexOf(selectedValue) === -1){
    props.selSponsoredContentFilterValue(value,actionTypes.APPLYSPONSOREDCONTENTTOPICTAGSIN);
    props.selSCFilterValue({ key: "selTopicTagsIN", value });
    }
  };

  const onClickTopicTagEx = () => {
    props.clearSponsoredContentSerchedValue(
      actionTypes.CLEARSEARCHSPONSOREDCONTENTTOPICTAGEX
    );
  };

  const searchTopicTagsEx = (e) => {
    if (e.target.value.length > 1) {
      props.fetchTags(
        e.target.value,
        "topic",
        actionTypes.FETCHSPONSOREDCONTENTTOPICTAGSEX,
        props.sponsoredContentFilterData.selTopicTagsIN,
        props.sponsoredContentFilterData.site
      );
    }
  };

  const onSelectTopicTagsEx = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.sponsoredContentFilterData.selTopicTagsIN.indexOf(selectedValue) === -1){
    props.selSponsoredContentFilterValue(value,actionTypes.APPLYSPONSOREDCONTENTTOPICTAGSEX);
    props.selSCFilterValue({ key: "selTopicTagsEX", value });
    }
  };

  //Placement tags event handling and state management

  const onClickPlacementTagIn = () => {
    props.clearSponsoredContentSerchedValue(
      actionTypes.CLEARSEARCHSPONSOREDCONTENTPLACEMENTTAGIN
    );
  };

  const searchPlacementTagsIn = (e) => {
    if (e.target.value.length > 1) {
      props.fetchTags(
        e.target.value,
        "placement",
        actionTypes.FETCHSPONSOREDCONTENTPLACEMENTTAGSIN,
        props.sponsoredContentFilterData.selPlacementTagsEX,
        props.sponsoredContentFilterData.site
      );
    }
  };

  const onSelectPlacementTagsIn = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.sponsoredContentFilterData.selPlacementTagsEX.indexOf(selectedValue) === -1){
    props.selSponsoredContentFilterValue(value,actionTypes.APPLYSPONSOREDCONTENTPLACEMENTTAGSIN);
    props.selSCFilterValue({ key: "selPlacementTagsIN", value });
    }
  };

  const onClickPlacementTagEx = () => {
    props.clearSponsoredContentSerchedValue(
      actionTypes.CLEARSEARCHSPONSOREDCONTENTPLACEMENTTAGEX
    );
  };

  const searchPlacementTagsEx = (e) => {
    if (e.target.value.length > 1) {
      props.fetchTags(
        e.target.value,
        "placement",
        actionTypes.FETCHSPONSOREDCONTENTPLACEMENTTAGSEX,
        props.sponsoredContentFilterData.selPlacementTagsIN,
        props.sponsoredContentFilterData.site
      );
    }
  };

  const onSelectPlacementTagsEx = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.sponsoredContentFilterData.selPlacementTagsIN.indexOf(selectedValue) === -1){
    props.selSponsoredContentFilterValue(value,actionTypes.APPLYSPONSOREDCONTENTPLACEMENTTAGSEX);
    props.selSCFilterValue({ key: "selPlacementTagsEX", value });
    }
  };

  //Org tags event handling and state management

  const onClickOrgTagIn = () => {
    props.clearSponsoredContentSerchedValue(
      actionTypes.CLEARSEARCHSPONSOREDCONTENTORGTAGIN
    );
  };

  const searchOrgTagsIn = (e) => {
    if (e.target.value.length > 1) {
      props.fetchTags(
        e.target.value,
        "organization",
        actionTypes.FETCHSPONSOREDCONTENTORGTAGSIN,
        props.sponsoredContentFilterData.selOrgTagsEX,
        props.sponsoredContentFilterData.site
      );
    }
  };

  const onSelectOrgTagsIn = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.sponsoredContentFilterData.selOrgTagsEX.indexOf(selectedValue) === -1){
    props.selSponsoredContentFilterValue(value,actionTypes.APPLYSPONSOREDCONTENTORGTAGSIN);
    props.selSCFilterValue({ key: "selOrgTagsIN", value });
    }
  };

  const onClickOrgTagEx = () => {
    props.clearSponsoredContentSerchedValue(
      actionTypes.CLEARSEARCHSPONSOREDCONTENTORGTAGEX
    );
  };

  const searchOrgTagsEx = (e) => {
    if (e.target.value.length > 1) {
      props.fetchTags(
        e.target.value,
        "organization",
        actionTypes.FETCHSPONSOREDCONTENTORGTAGSEX,
        props.sponsoredContentFilterData.selOrgTagsIN,
        props.sponsoredContentFilterData.site
      );
    }
  };

  const onSelectOrgTagsEx = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.sponsoredContentFilterData.selOrgTagsIN.indexOf(selectedValue) === -1){
    props.selSponsoredContentFilterValue(value,actionTypes.APPLYSPONSOREDCONTENTORGTAGSEX);
    props.selSCFilterValue({ key: "selOrgTagsEX", value });
    }
  };

  //Country event handling and state management

  const onClickCountry = () => {
    if (_.isEmpty(props.sponsoredContentFilterData.selCountry)) {
      props.clearSponsoredContentSerchedValue(
        actionTypes.CLEARSEARCHSPONSOREDCONTENTCOUNTRY
      );
    }
  };

  const searchCountry = (e) => {
    if (e.target.value.length > 1) {
      props.fetchPgCountry(
        e.target.value,
        actionTypes.FETCHSPONSOREDCONTENTCOUNTRY
      );
    }
  };

  const onSelectCountry = (e, { value }) => {
    props.selSponsoredContentFilterValue(
      value,
      actionTypes.SELSPONSOREDCONTENTCOUNTRY
    );
    props.addCountries(value, actionTypes.ADDSPONSOREDCONTENTCONTRIES);
  };

  //Enaged orgs event handlers
  const onClickPageViewByOrgs = () => {
    // if (_.isEmpty(props.sponsoredContentFilterData.organization)) {
    //   props.clearSponsoredContentSerchedValue(actionTypes.CLEARSEARCHSPONSOREDCONTENTENGORGS);
    // } else 
    if (!_.isEmpty(props.sponsoredContentFilterData.organization) && (props.sponsoredContentFilterData.scIsIncludeParent || props.sponsoredContentFilterData.scIsIncludeChild)) {
      props.fetchEngagedOrg(
        props.sponsoredContentFilterData.excludeQACampaign,
        "",
        actionTypes.FETCHSPONSOREDCONTENTENGORGS,
        true,
        props.sponsoredContentFilterData.organization,
        props.sponsoredContentFilterData.scIsIncludeParent,
        props.sponsoredContentFilterData.scIsIncludeChild,
      );
    }
  };

  const searchPageViewByOrg = (e, { value }) => {
    if (e.target.value.length > 1) {
      props.fetchEngagedOrg(
        props.sponsoredContentFilterData.excludeQACampaign,
        e.target.value,
        actionTypes.FETCHSPONSOREDCONTENTENGORGS,
        true,
        props.sponsoredContentFilterData.organization,
        props.sponsoredContentFilterData.scIsIncludeParent,
        props.sponsoredContentFilterData.scIsIncludeChild,
      );
    }
  };

  const onSelectPageViewByOrg = (e, { value }) => {
    props.selSponsoredContentFilterValue(
      value,
      actionTypes.SELSPONSOREDCONTENTENGORGS
    );
    props.selSponsoredContentFilterValue(
      value,
      actionTypes.ADDSPONSOREDCONTENTENGORGS
    );
  };

  const firmogaphicFilterData = PageFirmoGraphic(
    props.sponsoredContentFilterData,
    props.firmographicSector,
    props.firmographicIndustryGroup,
    props.firmographicIndustry,
    props.firmographicSubIndustry,
    props.firmographicOrgsType,
    props.firmographicOrgSize,
    props.firmographicOrgsRevenue
  );

  const onSearchChangeFirmographic = (e, answerKey, value) => {
    if (answerKey === "org_type") {
      setOrgType([]);
      if (e.target.value?.length > 0) {
        setOrgType(
          props.orgTypes
            .filter((orgType) => {
              return orgType.value
                .toLowerCase()
                .includes(e.target.value.toLowerCase());
            })
            .map((orgType) => {
              orgType.disabled = false;
              return orgType;
            })
        );
        props.firmographicOrgsTypeCall(
          orgType,
          actionTypes.FETCHSPONSOREDCONTENTFIRMOGRAPHICORGSTYPE,
          ""
        );
      }
    } else if (answerKey === "org_size") {
      setOrgSize([]);
      if (e.target.value?.length > 1) {
        setOrgSize(
          props.orgSize
            .filter((orgSize) => {
              return orgSize.value
                .toLowerCase()
                .includes(e.target.value.toLowerCase());
            })
            .map((orgSize) => {
              orgSize.disabled = false;
              return orgSize;
            })
        );
        props.firmographicOrgsTypeCall(
          orgSize,
          actionTypes.FETCHSPONSOREDCONTENTORGSSIZE,
          ""
        );
      }
    } else if (answerKey === "org_revenue_range") {
      setOrgRevenue([]);
      if (e.target.value?.length > 1) {
        setOrgRevenue(
          props.orgRevenue
            .filter((orgRevenue) => {
              return orgRevenue.value
                .toLowerCase()
                .includes(e.target.value.toLowerCase());
            })
            .map((orgRevenue) => {
              orgRevenue.disabled = false;
              return orgRevenue;
            })
        );
        props.firmographicOrgsTypeCall(
          orgRevenue,
          actionTypes.FETCHSPONSOREDCONTENTORGSREVENUE,
          ""
        );
      }
    } else if (answerKey === "sector") {
      setSector([]);
      if (e.target.value?.length > 1) {
        setSector(
          props.sector
            .filter((sector) => {
              return sector;
            })
            .map((sector) => {
              sector.disabled = false;
              return sector;
            })
        );
        props.firmographicOrgsTypeCall(sector, actionTypes.FETCHSCSECTOR, "");
      }
    } else if (answerKey === "industry_group") {
      setIndustryGroup([]);
      if (e.target.value?.length > 1) {
        setIndustryGroup(
          props.industryGroup
            .filter((industryGroup) => {
              return industryGroup;
            })
            .map((industryGroup) => {
              industryGroup.disabled = false;
              return industryGroup;
            })
        );
        props.firmographicOrgsTypeCall(
          industryGroup,
          actionTypes.FETCHSCINDUSTRYGROUP,
          ""
        );
      }
    } else if (answerKey === "industry") {
      setIndustry([]);
      if (e.target.value?.length > 1) {
        setIndustry(
          props.industry
            .filter((industry) => {
              return industry;
            })
            .map((industry) => {
              industry.disabled = false;
              return industry;
            })
        );
        props.firmographicOrgsTypeCall(
          industry,
          actionTypes.FETCHSCINDUSTRY,
          ""
        );
      }
    } else if (answerKey === "sub_industry") {
      setSubIndustry([]);
      if (e.target.value?.length > 1) {
        setSubIndustry(
          props.subIndustry
            .filter((subIndustry) => {
              return subIndustry;
            })
            .map((subIndustry) => {
              subIndustry.disabled = false;
              return subIndustry;
            })
        );
        props.firmographicOrgsTypeCall(
          subIndustry,
          actionTypes.FETCHSCSUBINDUSTRY,
          ""
        );
      }
    }
  };

  const onSelectFirmographic = (e, answerKey, value) => {
    if (answerKey === "org_type") {
      props.selSponsoredContentFilterValue(
        value,
        actionTypes.APPLYSPONSOREDCONTENTORGSTYPE
      );
      props.selSCFilterValue({ key: "selOrgType", value });
    } else if (answerKey === "org_size") {
      props.selSponsoredContentFilterValue(
        value,
        actionTypes.APPLYSPONSOREDCONTENTORGSSIZE
      );
      props.selSCFilterValue({ key: "selOrgSize", value });
    } else if (answerKey === "org_revenue_range") {
      props.selSponsoredContentFilterValue(
        value,
        actionTypes.APPLYSPONSOREDCONTENTREVENUE
      );
      props.selSCFilterValue({ key: "selOrgRevenue", value });
    } else if (answerKey === "sector") {
      props.selSponsoredContentFilterValue(value, actionTypes.APPLYSCSECTOR);
      props.selSCFilterValue({ key: "selSector", value });
    } else if (answerKey === "industry_group") {
      props.selSponsoredContentFilterValue(
        value,
        actionTypes.APPLYSCINDUSTRYGROUP
      );
      props.selSCFilterValue({ key: "selIndustryGroup", value });
    } else if (answerKey === "industry") {
      props.selSponsoredContentFilterValue(value, actionTypes.APPLYSCINDUSTRY);
      props.selSCFilterValue({ key: "selIndustry", value });
    } else if (answerKey === "sub_industry") {
      props.selSponsoredContentFilterValue(
        value,
        actionTypes.APPLYSCSUBINDUSTRY
      );
      props.selSCFilterValue({ key: "selSubIndustry", value });
    }
  };

  const onClickFirmographic = (e, answerKey) => {
    if (answerKey === "org_type") {
      props.clearSponsoredContentSerchedValue(
        actionTypes.CLEARSEARCHSPONSOREDCONTENTORGSTYPE
      );
    } else if (answerKey === "org_size") {
      props.clearSponsoredContentSerchedValue(
        actionTypes.CLEARSEARCHSPONSOREDCONTENTORGSSIZE
      );
    } else if (answerKey === "org_revenue_range") {
      props.clearSponsoredContentSerchedValue(
        actionTypes.CLEARSEARCHSPONSOREDCONTENTORGSREVENUE
      );
    } else if (answerKey === "sector") {
      props.clearSponsoredContentSerchedValue(actionTypes.CLEARSEARCHSCSECTOR);
    } else if (answerKey === "industry_group") {
      props.clearSponsoredContentSerchedValue(
        actionTypes.CLEARSEARCHSCINDUSTRYGROUP
      );
    } else if (answerKey === "industry") {
      props.clearSponsoredContentSerchedValue(
        actionTypes.CLEARSEARCHSCINDUSTRY
      );
    } else if (answerKey === "sub_industry") {
      props.clearSponsoredContentSerchedValue(
        actionTypes.CLEARSEARCHSCSUBINDUSTRY
      );
    }
  };

  //Seniorities Include

  const onClickSenioritiesIn = () => {
    props.clearSponsoredContentSerchedValue(actionTypes.CLEARSEARCHSCSENIORITIESIN);
  };

  const searchSenioritiesIn = (e) => {
    setSenioritiesIn([]);
    if (e.target.value.length > 1) {
      setSenioritiesIn(
        props.seniorities
          .filter((seniority) => {
            return seniority.name
              .toLowerCase()
              .includes(e.target.value.toLowerCase());
          })
          .map((seniority) => {
            seniority.disabled = false;
            return seniority;
          })
      );
      props.SenioritiesList(
        senioritiesIN,
        actionTypes.FETCHSCSENIORITIESIN,
        props.sponsoredContentFilterData.selSenioritiesEX
      );
    }
  };

  const onSelectSenioritiesIn = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.sponsoredContentFilterData.selSenioritiesEX.indexOf(selectedValue) === -1){
      props.selSponsoredContentFilterValue(value, actionTypes.APPLYSCSENIORITIESIN);
      props.selSCFilterValue({ key: "selSenioritiesIN", value });
    }
  };

  //Seniority Ex

  const onClickSenioritiesEx = () => {
    props.clearSponsoredContentSerchedValue(actionTypes.CLEARSEARCHSCSENIORITIESEX);
  };

  const searchSenioritiesEx = (e) => {
    setSenioritiesEX([]);
    if (e.target.value.length > 1) {
      setSenioritiesEX(
        props.seniorities
          .filter((seniority) => {
            return seniority.name
              .toLowerCase()
              .includes(e.target.value.toLowerCase());
          })
          .map((seniority) => {
            seniority.disabled = false;
            return seniority;
          })
      );
      props.SenioritiesList(
        senioritiesEX,
        actionTypes.FETCHSCSENIORITIESEX,
        props.sponsoredContentFilterData.selSenioritiesIN
      );
    }
  };

  const onSelectSenioritiesEx = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.sponsoredContentFilterData.selSenioritiesIN.indexOf(selectedValue) === -1){
      props.selSponsoredContentFilterValue(value, actionTypes.APPLYSCSENIORITIESEX);
      props.selSCFilterValue({ key: "selSenioritiesEX", value });
    }
  };

  let personaSeniority = [
    {
      value: props.sponsoredContentFilterData.selSenioritiesIN,
      label: "Seniority (Include)",
      placeholder: "Seniority (Include)",
      options: props.senioritiesIn,
      onClick: onClickSenioritiesIn,
      onChange: onSelectSenioritiesIn,
      onSearchChange: searchSenioritiesIn,
    },
    {
      value: props.sponsoredContentFilterData.selSenioritiesEX,
      label: "Seniority (Exclude)",
      placeholder: "Seniority (Exclude)",
      options: props.senioritiesEx,
      onClick: onClickSenioritiesEx,
      onChange: onSelectSenioritiesEx,
      onSearchChange: searchSenioritiesEx,
    },
  ];

  //JobFunctions Include

  const onClickJobFunctionsIn = () => {
    props.clearSponsoredContentSerchedValue(actionTypes.CLEARSEARCHSCJOBFUNCTIONSIN);
  };

  const searchJobFunctionsIn = (e) => {
    setJobFunctionsIN([]);
    if (e.target.value.length > 1) {
      setJobFunctionsIN(
        props.jobfunctions
          .filter((jobfunction) => {
            return jobfunction.function
              .toLowerCase()
              .includes(e.target.value.toLowerCase());
          })
          .map((jobfunction) => {
            jobfunction.disabled = false;
            return jobfunction;
          })
      );
      props.SenioritiesList(
        JobFunctionsIN,
        actionTypes.FETCHSCJOBFUNCTIONSIN,
        props.sponsoredContentFilterData.selJobFunctionsEX
      );
    }
  };

  const onSelectJobFunctionIn = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.sponsoredContentFilterData.selJobFunctionsEX.indexOf(selectedValue) === -1){
      props.selSponsoredContentFilterValue(value, actionTypes.APPLYSCJOBFUNCTIONSIN);
      props.selSCFilterValue({ key: "selJobFunctionsIN", value });
    }
  };

  //JobFunctions Ex

  const onClickJobFunctionsEx = () => {
    props.clearSponsoredContentSerchedValue(actionTypes.CLEARSEARCHSCJOBFUNCTIONSEX);
  };

  const searchJobFunctionsEx = (e) => {
    setJobFunctionsEX([]);
    if (e.target.value.length > 1) {
      setJobFunctionsEX(
        props.jobfunctions
          .filter((jobfunction) => {
            return jobfunction.function
              .toLowerCase()
              .includes(e.target.value.toLowerCase());
          })
          .map((jobfunction) => {
            jobfunction.disabled = false;
            return jobfunction;
          })
      );
      props.SenioritiesList(
        JobFunctionsEX,
        actionTypes.FETCHSCJOBFUNCTIONSEX,
        props.sponsoredContentFilterData.selJobFunctionsIN
      );
    }
  };

  const onSelectJobFunctionsEx = (e, { value }) => {
    const selectedValue = value[value.length-1];
    if(props.sponsoredContentFilterData.selJobFunctionsIN.indexOf(selectedValue) === -1){
      props.selSponsoredContentFilterValue(value, actionTypes.APPLYSCJOBFUNCTIONSEX);
      props.selSCFilterValue({ key: "selJobFunctionsEX", value });
    }
  };

  let personaJobFunction = [
    {
      value: props.sponsoredContentFilterData.selJobFunctionsIN,
      label: "Job Function (Include)",
      placeholder: "Job Function (Include)",
      options: props.JobFunctionsIN,
      tags: props.JobFunctionsIN,
      onClick: onClickJobFunctionsIn,
      onChange: onSelectJobFunctionIn,
      onSearchChange: searchJobFunctionsIn,
    },
    {
      tags: props.JobFunctionsEX,
      value: props.sponsoredContentFilterData.selJobFunctionsEX,
      label: "Job Function (Exclude)",
      placeholder: "Job Function (Exclude)",
      options: props.JobFunctionsEX,
      onClick: onClickJobFunctionsEx,
      onChange: onSelectJobFunctionsEx,
      onSearchChange: searchJobFunctionsEx,
    },
  ];

  const onTypeJobTitleIN = (e, { value }) => {
    props.selSCFilterValue({ key: "selJobTitlesIN", value });
  };
  const onTypeJobTitleEX = (e, { value }) => {
    props.selSCFilterValue({ key: "selJobTitlesEX", value });
  };

  let personaJobTitleIn = [
    {
      onChange: onTypeJobTitleIN,
      placeholder: "Job Title (Include)",
      value: props.sponsoredContentFilterData.selJobTitlesIN,
    },
  ];

  let personaJobTitleEx = [
    {
      onChange: onTypeJobTitleEX,
      placeholder: "Job Title (Exclude)",
      value: props.sponsoredContentFilterData.selJobTitlesEX,
    },
  ];

  //publish dates event handler
  const cancelDateFilter = () => {
    setDateRangeFilter({
      selection: {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    });
    setDatePopupFilter("custom-date-popup-close");
  };

  const applyDateFilter = () => {
    props.selSponsoredContentFilterValue(
      getDisplayDate(dateRangeFilter.selection.startDate),
      actionTypes.SELSCWPSTARTDATE
    );
    props.selSponsoredContentFilterValue(
      getDisplayDate(dateRangeFilter.selection.endDate),
      actionTypes.SELSCWPENDDATE
    );
    setDatePopupFilter("custom-date-popup-close");
  };

  const selectDateRangeFilter = (e, { value }) => {
    props.selSponsoredContentFilterValue(value, actionTypes.APPLYSCDATEFILTER);
    setDatePopupFilter("custom-date-popup-close");
    if (value === "Custom Date Range") {
      setDatePopupFilter("custom-date-popup-open");
    } else {
      let dates = getStartEndDates(value);
      props.selSponsoredContentFilterValue(
        getDisplayDate(dates.startDate),
        actionTypes.SELSCWPSTARTDATE
      );
      props.selSponsoredContentFilterValue(
        getDisplayDate(dates.endDate),
        actionTypes.SELSCWPENDDATE
      );
    }
  };

    //Page title event handling and state management

    const onClickPageTitle = () => {
      if (_.isEmpty(props.sponsoredContentFilterData.selPageTitle)) {
        props.clearSponsoredContentSerchedValue(actionTypes.CLEARSEARCHSCPAGETITLE);
      }
    };
  
    const searchPageTitle = (e) => {
      if (e.target.value.length > 1) {
        props.fetchPageTitle(
          e.target.value, 
          actionTypes.FETCHSCTITLE,
          "", 
          props.sponsoredContentFilterData.wp_startDate, 
          props.sponsoredContentFilterData.wp_endDate
        );
      } else {
        props.clearSponsoredContentSerchedValue(actionTypes.CLEARSEARCHSCPAGETITLE);
      }
    };
  
    const onSelectPageTitle = (e, { value }) => {
       props.selSCFilterValue({ key: "selPageTitle", value });
    };

      //Url parameters source event handling

  const onClickUrlSource = () => {
    if (_.isEmpty(props.sponsoredContentFilterData.selUrlSource)) {
      props.clearSponsoredContentSerchedValue(actionTypes.CLEARSEARCHSCURLSOURCE);
    }
  };

  const searchUrlSource = (e) => {
    if (e.target.value.length > 1) {
      props.fetchUrlParams(
        e.target.value,
        "source",
        actionTypes.FETCHSCURLSOURCE,
        "",
        "pageview"
      );
    } else {
      props.clearSponsoredContentSerchedValue(actionTypes.CLEARSEARCHSCURLSOURCE);
    }
  };

  const onSelectUrlSource = (e, { value }) => { 
    props.clearSponsoredContentSerchedValue(actionTypes.RESETSELSCURLCAMPANDMEDIUM);
    props.clearSponsoredContentSerchedValue(actionTypes.CLEARSEARCHSCURLCAMPAIGNS);
    props.clearSponsoredContentSerchedValue(actionTypes.CLEARSEARCHSCURLMEDIUM);
    props.selSCFilterValue({ key: "selUrlSource", value });
  };
      //Url parameters medium event handling

  const onClickUrlMedium = () => {
    if (_.isEmpty(props.sponsoredContentFilterData.selUrlMedium)) {
      props.clearSponsoredContentSerchedValue(actionTypes.CLEARSEARCHSCURLMEDIUM);
    }
  };

  const searchUrlMedium = (e) => {
    if (e.target.value.length > 1) {
      props.fetchUrlParams(
        e.target.value,
        "medium",
        actionTypes.FETCHSCURLMEDIUM,
        props.sponsoredContentFilterData.selUrlSource,
        "pageview"
      );
    } else {
      props.clearSponsoredContentSerchedValue(actionTypes.CLEARSEARCHSCURLMEDIUM);
    }
  };

  const onSelectUrlMedium = (e, { value }) => {
    props.selSCFilterValue({ key: "selUrlMedium", value });
  };

      //Url parameters campaign event handling

  const onClickUrlCampaign = () => {
    if (_.isEmpty(props.sponsoredContentFilterData.selUrlCampaign)) {
      props.clearSponsoredContentSerchedValue(actionTypes.CLEARSEARCHSCURLCAMPAIGNS);
    }
  };

  const searchUrlCampaigns = (e) => {
    if (e.target.value.length > 1) {
      props.fetchUrlParams(
        e.target.value,
        "campaign",
        actionTypes.FETCHSCURLCAMPAIGNS,
        props.sponsoredContentFilterData.selUrlSource,
        "pageview"
      );
    } else {
      props.clearSponsoredContentSerchedValue(actionTypes.CLEARSEARCHSCURLCAMPAIGNS);
    }
  };

  const onSelectUrlCampaigns = (e, { value }) => {
    props.selSCFilterValue({ key: "selUrlCampaign", value });
  };

  // URL Term event handlers
  const onClickUrlTerm = () => {
    if (_.isEmpty(props.sponsoredContentFilterData.selUrlTerm)) {
      props.clearSponsoredContentSerchedValue(actionTypes.CLEARSEARCHSCURLTERM);
    }
  };

  const searchUrlTerm = (e) => {
    if (e.target.value.length > 1) {
      props.fetchUrlParams(
        e.target.value,
        "term",
        actionTypes.FETCHSCURLTERM,
        props.sponsoredContentFilterData.selUrlSource,
        "pageview"
      );
    } else {
      props.clearSponsoredContentSerchedValue(actionTypes.CLEARSEARCHSCURLTERM);
    }
  };

  const onSelectUrlTerm = (e, { value }) => {
    props.selSCFilterValue({ key: "selUrlTerm", value });
  };

  // URL Content event handlers
  const onClickUrlContent = () => {
    if (_.isEmpty(props.sponsoredContentFilterData.selUrlContent)) {
      props.clearSponsoredContentSerchedValue(actionTypes.CLEARSEARCHSCURLCONTENT);
    }
  };

  const searchUrlContent = (e) => {
    if (e.target.value.length > 1) {
      props.fetchUrlParams(
        e.target.value,
        "content",
        actionTypes.FETCHSCURLCONTENT,
        props.sponsoredContentFilterData.selUrlSource,
        "pageview"
      );
    } else {
      props.clearSponsoredContentSerchedValue(actionTypes.CLEARSEARCHSCURLCONTENT);
    }
  };

  const onSelectUrlContent = (e, { value }) => {
    props.selSCFilterValue({ key: "selUrlContent", value });
  };

    //Engaged member eventy Handler

    const onClickEngagedMember = () => {
      if (_.isEmpty(props.sponsoredContentFilterData.selEnagedMember)) {
        props.clearSponsoredContentSerchedValue(actionTypes.CLEARSEARCHSCENGMEMBER);
      }
    };
  
    const searchEngagedMember = (e) => {
      if (e.target.value.length > 1) {
        props.fetchEngagedMember(e.target.value, actionTypes.FETCHSCENGMEMBER);
      }
    };
  
    const onSelectEngagedMember = (e, { value }) => {
      props.selSCFilterValue({ key: "selEnagedMember", value });
      props.selSponsoredContentFilterValue(value, actionTypes.ADDSCENGMEMBER);
    };
  
    const onClickPrimaryTopic = (e, { value }) => {
      if (_.isEmpty(props.sponsoredContentFilterData.selPrimaryTopic)) {
        props.clearSponsoredContentSerchedValue(actionTypes.CLEARSEARCHSCPRIMARYTOPIC);
      }
    };

    const onSelectPrimaryTopic = (e, { value }) => {
      props.selSCFilterValue({ key: "selPrimaryTopic", value });
      props.selSponsoredContentFilterValue(value, actionTypes.ADDSCPRIMARYTOPIC);
    };

    const searchPrimaryTopic = (e) => {
      if (e.target.value.length > 1) {
        props.fetchPrimaryTopic(e.target.value, actionTypes.FETCHSCPRIMARYTOPIC);
      }
    };

    const clearFilters = () => {
      clearfilterClick.current = true;
      if (
        clearfilterClick.current &&
        props.sponsoredContentFilterData.appliedDate === "Custom Date Range"
      ) {
        setDateRange({
          selection: {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
          },
        });
      }
      setCheckSelectState(false);
      props.clearSponsoredContentSerchedValue(actionTypes.CLEARSCFILTER);
		  props.selSponsoredContentFilterValue(props.delivery_states, actionTypes.CLEARSCFILTER);
      setDatePopup("custom-date-popup-close");
      const actionTypesToClear = [
        actionTypes.RESETSPONSOREDCONTENTORGS,
        actionTypes.RESETSPONSOREDCONTENTCAMPAIGNS,
        actionTypes.RESETSPONSOREDCONTENTORDER,
        actionTypes.RESETSPONSOREDCONTENTTOPICTAGIN,
        actionTypes.RESETSPONSOREDCONTENTTOPICTAGEX,
        actionTypes.RESETSCPLACEMENTTAGIN,
        actionTypes.RESETSCPLACEMENTTAGEX,
        actionTypes.RESETSCORGTAGIN,
        actionTypes.RESETSCORGTAGEX,
        actionTypes.RESETSCENGORGS,
        actionTypes.RESETSCCOUNTRY,
        actionTypes.RESETSCENGORGS,
        actionTypes.RESETSCORGSTYPE,
        actionTypes.RESETSCORGSSIZE,
        actionTypes.RESETSCORGSREVENUE,
        actionTypes.RESETSCSECTOR,
        actionTypes.RESETSCORGSINDUSTRYGROUP,
        actionTypes.RESETSCORGSINDUSTRY,
        actionTypes.RESETSCSUBINDUSTRY,
        actionTypes.RESETSCSENIORITIESIN,
        actionTypes.RESETSCSENIORITIESEX,
        actionTypes.RESETSCJOBFUNCTIONSIN,
        actionTypes.RESETSCJOBFUNCTIONSEX,
        actionTypes.RESETSCENGMEMBER,
        actionTypes.RESETSCPRIMARYTOPIC,
      ];
  
      actionTypesToClear.forEach((actionType) => {
        props.clearSponsoredContentSerchedValue(actionType);
      });
    };

  const onSelectDeliveryStatus = (e, { value }) => { 
    props.selSCFilterValue({ key: "scDeliverdFilterState", value });
  };

  const applyFilters = (startDate, endDate, isCustomCheckbox) => {
    let tempPgFilterDAta = props.sponsoredContentFilterData;
    let resSponsoredSummary;
    let resSponsoredCampaignPageDetails;
    let resSponsoredCampaignPageviewDetails;

    tempPgFilterDAta.startDate = startDate;
    tempPgFilterDAta.endDate = endDate;
    tempPgFilterDAta.prevStartDate = "";
    tempPgFilterDAta.prevEndDate = "";
    tempPgFilterDAta.selCustomRangeCheckBox = isCustomCheckbox;

    props.selSponsoredContentFilterValue(
      startDate,
      actionTypes.SELSPONSOREDCONTENTSTARTDATE
    );
    props.selSponsoredContentFilterValue(
      endDate,
      actionTypes.SELSPONSOREDCONTENTENDDATE
    );
    props.selSponsoredContentFilterValue(
      "",
      actionTypes.SELSPONSOREDCONTENTPREVSTARTDATE
    );
    props.selSponsoredContentFilterValue(
      "",
      actionTypes.SELSPONSOREDCONTENTPREVENDDATE
    );

    //tempPgFilterDAta.compare = checkSelectState;
    props.applySponsoredContentFilter();

    if(props?.roleReports?.some((report) => [SPONSORED_CAMPAIGN_SUMMARY].includes(report.element) && report.permission === VIEW)) {
      props.fetchPageviewTableData(
        url.sponsoredSummary,
        tempPgFilterDAta,
        1,
        "",
        actionTypes.SCSUMMARY,
        null,
        null,
        "sponsored-content",
        resSponsoredSummary
      );
    }
    
    if(props?.roleReports?.some((report) => [SPONSORED_CAMPAIGN_PAGE_DETAILS].includes(report.element) && report.permission === VIEW)) {
      props.fetchPageviewTableData(
        url.sponsoredPageDetails,
        tempPgFilterDAta,
        1,
        "",
        actionTypes.SCCAMPAIGNPAGEDETAILSUMMARY,
        null,
        null,
        "sponsored-content",
        resSponsoredCampaignPageDetails
      );
    }
    
    if(props?.roleReports?.some((report) => [SPONSORED_CAMPAIGN_PAGE_VIEW_DETAILS].includes(report.element) && report.permission === VIEW)) {
      props.sponsoredContentFilterData.selectCampPageviewDetailsTable &&
      props.fetchPageviewTableData(
        url.sponsoredDetail,
        tempPgFilterDAta,
        1,
        "",
        actionTypes.SCCAMPAIGNDETAILS,
        null,
        null,
        "sponsored-content",
        resSponsoredCampaignPageviewDetails
      );
    }
    
    if (props.isFilApplied && props.sponsoredContentFilterData.showScChart) {
      props.fetchPageviewTableData(
        url.pageviewReportCharts,
        props.sponsoredContentFilterData,
        1,
        "",
        actionTypes.SPPONSOREDCHARTS,
        null,
        null,
        "sponsored-content"
      );
    }
    setFilterPopup("filter-popup-close");
    setFilterIconColor("material-icons grey");
    setFilterSelectFocus("filter-icon");
    setDatePopup("custom-date-popup-close");
  };

  const onSelectTrafficSource = (e, { value }) => {
    props.selSCFilterValue({ key: "selTrafficSource", value });
  };

  // const onChangeIncludeParent = (e) => {
  //   if(props.sponsoredContentFilterData.scIsIncludeChild){
  //     props.selSCToggles(actionTypes.SELSCPARENTTOOGLE);
  //     props.selSCToggles(actionTypes.SELSCCHILDTOOGLE);
  //   } else {
  //     props.selSCToggles(actionTypes.SELSCPARENTTOOGLE);
  //   }
  // }

  // const onChangeIncludeChild = (e) => {
  //   if(props.sponsoredContentFilterData.scIsIncludeParent){
  //     props.selSCToggles(actionTypes.SELSCPARENTTOOGLE);
  //     props.selSCToggles(actionTypes.SELSCCHILDTOOGLE);
  //   } else {
  //     props.selSCToggles(actionTypes.SELSCCHILDTOOGLE);
  //   }
  // }

    //Pacing filter event handler
	const onSelectPacingFilter = (e, { value }) => {
    props.selSCFilterValue({ key: "pacingFilter", value });
	};

  const onChangeExcludeOrgsMember = (e, value) => {
    if(value === "orgs"){
      props.selSCToggles(actionTypes.SELSCEXANONYMOUSORGS);
    } else if(value === "member"){
      props.selSCToggles(actionTypes.SELSCEXANONYMOUSMEMBERS);
    }
  }

  const onSelectTrafficFilter = (e, { value }) => {
    props.selSCFilterValue({ key: "trafficType", value });
  }

  return (
    <div>
      <div className="date-picker">
        {/* Date picker */}
        <SelectDatePicker
          props={props}
          selectDateRange={selectDateRange}
          data={props.sponsoredContentFilterData}
          applyDate={props.applySponsoredDateFilterDate}
          setDatePopup={setDatePopup}
          dateRange={dateRange}
          checkSelectState={checkSelectState}
        />
        {/* Filter icon */}
        <SearchFilterIcon
          filterSelectFocus={filterSelectFocus}
          setFilterSelectFocus={setFilterSelectFocus}
          setFilterPopup={setFilterPopup}
          setFilterIconColor={setFilterIconColor}
          filterIconColor={filterIconColor}
        />
      </div>
      <div className="popup-component">
        {/* Custom date picker component */}
        <CustomDatePickerWithCompare
          datePopup={datePopup}
          setDatePopup={setDatePopup}
          checkSelectState={checkSelectState}
          cutomDate={cutomDate}
          dateRange={dateRange}
          setCheckSelectState={setCheckSelectState}
          setComparePeriod={setComparePeriod}
          previousPeriodData={previousPeriodData}
          comparePeriod={comparePeriod}
          getClearDate={getClearDate}
          setDateRange={setDateRange}
          cancelDate={cancelDate}
          applyDate={applyDate}
          isComparePeriod={true}
        />
        {/* Search filter popup */}
        <div className={filterPopup}>
          <div className="filter-header">
            <div className="filter-text">
              <p>Filters</p>
            </div>
            <div
              className="filter-close-icon"
              onClick={() => {
                setFilterSelectFocus("filter-icon");
                setFilterPopup("filter-popup-close");
              }}
            >
              <i
                className="material-icons grey"
                onClick={() => setFilterIconColor("material-icons grey")}
              >
                clear
              </i>
            </div>
          </div>
          <Form>
            <div className="pg-search-filter">
              <Form.Group widths="equal">
                <OrganizationPicker
                  value={props.sponsoredContentFilterData.organization}
                  onChange={onSelectOrg}
                  onSearchChange={searchOrg}
                  onClick={onClickOrg}
                  organizations={props.organizations}
                />
                <OrderPicker
                  onSearchChange={searchOrder}
                  value={props.sponsoredContentFilterData.order}
                  onChange={onSelectOrder}
                  onClick={onClickOrder}
                  order={props.order}
                />
              </Form.Group>
              <Form.Group widths="2">
                <StatusPicker
                  value={props.sponsoredContentFilterData.campaignStatus}
                  onChange={onSelectCampaignStatus}
                />
                <CampaignPicker
                  value={props.sponsoredContentFilterData.campaign}
                  onChange={onSelectCampaigns}
                  onSearchChange={searchCampaign}
                  onClick={onClickCampaign}
                  campaigns={props.campaigns}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <DeliveryPicker
                    value={props.sponsoredContentFilterData.scDeliverdFilterState}
                    onChange={onSelectDeliveryStatus}
                    delivery_states={props.delivery_states}
                  />
                <PacingFilter
                  value={props.sponsoredContentFilterData.pacingFilter}
                  onChange={onSelectPacingFilter}
                />
              </Form.Group>
                <Toggle
                label="Page Parameters"
                onChange={() => {
                  props.selSCToggles(actionTypes.SELSCPAGEPARAMSTOGGLE);
                }}
                checked={props.sponsoredContentFilterData.pageParams}
              />
              {props.sponsoredContentFilterData.pageParams && (
                <React.Fragment>
                  <label className="job-Titles"style={{marginLeft: "5px", lineHeight: "2em"}}>{PUBLISHED_DATE_RANGE}</label>
                  <Form.Group widths="equal">
                  <DatePicker
                    value={props.sponsoredContentFilterData.appliedDateFilter}
                    onChange={selectDateRangeFilter}
                    showAllDates={true}
                    openDateRange={(e, { value }) => {
                      if (value === "Custom Date Range") {
                        document.getElementsByClassName(
                          "rdrDateInput"
                        )[2].style.display = "none";
                        document.getElementsByClassName(
                          "rdrDateInput"
                        )[3].style.display = "none";
                        document.getElementsByClassName(
                          "rdrDateDisplayWrapper"
                        )[1].style.display = "none";
                        props.selSponsoredContentFilterValue(
                          "Custom Date Range",
                          actionTypes.APPLYSCDATEFILTER
                        );
                        setDatePopupFilter("custom-date-popup-open");
                      }
                    }}
                  />
                  <span style={{ width: "25em" }}>
                    <FilterDateRange style={{ paddingBottom: "0px" }} />
                  </span>
                </Form.Group>
                </React.Fragment>
              )}
              {props.sponsoredContentFilterData.pageParams && (
                <Form>
                  <div className={datePopupFilter}>
                    <div
                      className="date-picker-close-icon"
                      onClick={() => {
                        setDatePopupFilter("custom-date-popup-close");
                      }}
                    >
                      <i className="material-icons grey">clear</i>
                    </div>
                    <div>
                      <DateRangePicker
                        minDate={new Date("2019-12-13")}
                        maxDate={
                          props.sponsoredContentFilterData.endDate
                            ? new Date(props.sponsoredContentFilterData.endDate)
                            : new Date()
                        }
                        onChange={(item) => {
                          setDateRangeFilter({ ...dateRangeFilter, ...item });
                        }}
                        months={2}
                        moveRangeOnFirstSelection={false}
                        direction="horizontal"
                        ranges={[dateRangeFilter.selection]}
                        rangeColors={["#e5582b"]}
                      />
                    </div>
                    <div className="date-buttons">
                      <button
                        type="button"
                        className="cancel-date-button"
                        onClick={cancelDateFilter}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="apply-date-button"
                        onClick={applyDateFilter}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                  <Form.Group widths="equal">
                    <SingleSelectDropDown
                      clearable
                      options={props.pageTitle.map((org, index) => ({
                        key: index,
                        text: org.title,
                        value: org.title,
                      }))}
                      value={props.sponsoredContentFilterData.selPageTitle}
                      label="Page Title"
                      placeholder="Page Title"
                      onClick={onClickPageTitle}
                      onChange={onSelectPageTitle}
                      onSearchChange={searchPageTitle}
                      search={(options) => options}
                    />
                    <UrlParamsPicker
                      style={{ width: "24em" }}
                      options={props.urlSource?.map((url, index) => ({
                        key: index,
                        text: url.source,
                        value: url.source,
                      }))}
                      value={props.sponsoredContentFilterData.selUrlSource}
                      label="UTM Source"
                      placeholder="UTM Source"
                      onClick={onClickUrlSource}
                      onChange={onSelectUrlSource}
                      onSearchChange={searchUrlSource}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <UrlParamsPicker
                      options={props.urlMedium?.map((url, index) => ({
                        key: index,
                        text: url.medium,
                        value: url.medium,
                      }))}
                      value={props.sponsoredContentFilterData.selUrlMedium}
                      label="UTM Medium"
                      placeholder=" UTM Medium"
                      onClick={onClickUrlMedium}
                      onChange={onSelectUrlMedium}
                      onSearchChange={searchUrlMedium}
                    />
                    <UrlParamsPicker
                      options={props.urlCampaigns?.map((url, index) => ({
                        key: index,
                        text: url.campaign,
                        value: url.campaign,
                      }))}
                      value={props.sponsoredContentFilterData.selUrlCampaign}
                      label="UTM Campaign"
                      placeholder="UTM Campaign"
                      onClick={onClickUrlCampaign}
                      onChange={onSelectUrlCampaigns}
                      onSearchChange={searchUrlCampaigns}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <UrlParamsPicker
                      label="UTM Term"
                      placeholder="UTM Term"
                      options={props.urlTerm?.map((url, index) => ({
                        key: index,
                        text: url.term,
                        value: url.term,
                      }))}
                      value={props.sponsoredContentFilterData.selUrlTerm}
                      onClick={onClickUrlTerm}
                      onChange={onSelectUrlTerm}
                      onSearchChange={searchUrlTerm}
                    />
                    <UrlParamsPicker
                      label="UTM Content"
                      placeholder="UTM Content"
                      options={props.urlContent?.map((url, index) => ({
                        key: index,
                        text: url.content,
                        value: url.content,
                      }))}
                      value={props.sponsoredContentFilterData.selUrlContent}
                      onClick={onClickUrlContent}
                      onChange={onSelectUrlContent}
                      onSearchChange={searchUrlContent}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                  <div className="field">
                  <PrimaryTopic
                    label="Primary Topic"
                    placeholder="Primary Topic"
                    value={props.sponsoredContentFilterData.selPrimaryTopic}
                    primaryTopic={props.scPrimaryTopic}
                    onClick={onClickPrimaryTopic}
                    onChange={onSelectPrimaryTopic}
                    onSearchChange={searchPrimaryTopic}
                  />
                  </div>
                  <div className="field"></div>
                  </Form.Group>
                </Form>
              )}
              <Form.Group>
              <Form className="search-filter-column-1">
              <CountryMultiSelectPicker
                  countries={props.countries}
                  value={props.sponsoredContentFilterData.selCountry}
                  onClick={onClickCountry}
                  onChange={onSelectCountry}
                  onSearchChange={searchCountry}
                  label="Pageviews by Country"
                />
              </Form>
              <Form className="search-filter-column-2">
              <Site
                  value={props.sponsoredContentFilterData.site}
                  onChange={onSelectSite}
                />
              </Form>
              </Form.Group>
              <Form.Group widths="equal">
                <TagsPicker
                  sites={props.sites}
                  tags={props.topicTagsIn}
                  value={props.sponsoredContentFilterData.selTopicTagsIN}
                  label="Topic Tags (Include)"
                  placeholder="Topic Tags (Include)"
                  onClick={onClickTopicTagIn}
                  onChange={onSelectTopicTagsIn}
                  onSearchChange={searchTopicTagsIn}
                />
                <TagsPicker
                  sites={props.sites}
                  tags={props.topicTagsEx}
                  value={props.sponsoredContentFilterData.selTopicTagsEX}
                  label="Topic Tags (Exclude)"
                  placeholder="Topic Tags (Exclude)"
                  onClick={onClickTopicTagEx}
                  onChange={onSelectTopicTagsEx}
                  onSearchChange={searchTopicTagsEx}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <TagsPicker
                  sites={props.sites}
                  tags={props.placementTagsIn}
                  value={props.sponsoredContentFilterData.selPlacementTagsIN}
                  label="Placement Tags (Include)"
                  placeholder="Placement Tags (Include)"
                  onClick={onClickPlacementTagIn}
                  onChange={onSelectPlacementTagsIn}
                  onSearchChange={searchPlacementTagsIn}
                />
                <TagsPicker
                  sites={props.sites}
                  tags={props.placementTagsEx}
                  value={props.sponsoredContentFilterData.selPlacementTagsEX}
                  label="Placement Tags (Exclude)"
                  placeholder="Placement Tags (Exclude)"
                  onClick={onClickPlacementTagEx}
                  onChange={onSelectPlacementTagsEx}
                  onSearchChange={searchPlacementTagsEx}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <TagsPicker
                  sites={props.sites}
                  tags={props.orgTagsIn}
                  value={props.sponsoredContentFilterData.selOrgTagsIN}
                  label="Organization Tags (Include)"
                  placeholder="Organization Tags (Include)"
                  onClick={onClickOrgTagIn}
                  onChange={onSelectOrgTagsIn}
                  onSearchChange={searchOrgTagsIn}
                />
                <TagsPicker
                  sites={props.sites}
                  tags={props.orgTagsEx}
                  value={props.sponsoredContentFilterData.selOrgTagsEX}
                  label="Organization Tags (Exclude)"
                  placeholder="Organization Tags (Exclude)"
                  onClick={onClickOrgTagEx}
                  onChange={onSelectOrgTagsEx}
                  onSearchChange={searchOrgTagsEx}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <AuthorPicker
                  authors={props.author}
                  value={props.sponsoredContentFilterData.selAuthor}
                  onClick={onClickAuthor}
                  onChange={onSelectAuthor}
                  onSearchChange={searchAuthor}
                />
                <TrafficSource
                    value={props.sponsoredContentFilterData.selTrafficSource}
                    onChange={onSelectTrafficSource}
                  />
              </Form.Group>
              <Form.Group widths="equal">
                <TrafficFilterPicker
                  value={props.sponsoredContentFilterData.trafficType}
                  onChange={onSelectTrafficFilter}
                />
                <CSMSelection
                  authors={props.csmArray}
                  value={props.sponsoredContentFilterData.setCSM}
                  FETCH={actionTypes.FETCH_SPONSORED_CSM}
                  SELECT={actionTypes.SELECTED_SPONSORED_TOGGLE}
                  ADD={actionTypes.ADD_SPONSORED_CSM}
                  CLEAR={actionTypes.CLEAR_SPONSORED_CSM}
                />  
              </Form.Group>
              <Form.Group widths="equal">
              <Form className="search-filter-column-1" style={{paddingLeft: "10px"}}>
                <EngagedOrganization
                  organizations={props.sponsoredContentEnagedOrgs}
                  value={props.sponsoredContentFilterData.selPageViewsByOrg}
                  label="Engaged Organizations"
                  placeholder="Engaged Organizations"
                  onClick={onClickPageViewByOrgs}
                  onChange={onSelectPageViewByOrg}
                  onSearchChange={searchPageViewByOrg}
                  type="addOrgLink"
                />
              <div className="engagements-search-filter">
              <Form className="search-filter-column-1" style={{marginLeft: "10px", paddingRight: "6px"}}>
                {/* <div style={{marginTop: "20px"}}>
                  <Toggle
                        label="Include Parent"
                        onChange={onChangeIncludeParent}
                        checked={props.sponsoredContentFilterData.scIsIncludeParent}
                      />
                </div>
                <div style={{marginTop: "12px"}}>
                  <Toggle
                        label="Include Children"
                        onChange={onChangeIncludeChild}
                        checked={props.sponsoredContentFilterData.scIsIncludeChild}
                      />
                </div> */}
                <div style={{marginTop: "20px"}}>
                    <Toggle
                      label="Exclude Anonymous Organizations"
                      onChange={(e)=>{
                        onChangeExcludeOrgsMember(e, "orgs")
                      }}
                      checked={props.sponsoredContentFilterData.exAnonymousOrgs}
                    />
                  </div>
              </Form>
              </div>
            </Form>
                <Form className="search-filter-column-2" style={{paddingRight: "10px"}}>
                  <EngagedMember
                      label="Engagement Member"
                      placeholder="Engagement Member"
                      engMember={props.scEngMember}
                      value={props.sponsoredContentFilterData.selEnagedMember}
                      onClick={onClickEngagedMember}
                      onChange={onSelectEngagedMember}
                      onSearchChange={searchEngagedMember}
                      type="addMemberLink"
                    />
                <div className="engagements-search-filter">
                <Form className="search-filter-column-1" style={{marginLeft: "10px", paddingRight: "6px"}}>
                  <div style={{marginTop: "20px"}}>
                    <Toggle
                      label="Exclude Anonymous Members"
                      onChange={(e)=>{
                        onChangeExcludeOrgsMember(e, "member")
                      }}
                      checked={props.sponsoredContentFilterData.exAnonymousMember}
                    />
                  </div>
                </Form>
              </div>
                </Form>
              </Form.Group>
              <br />
              <div>
                <Toggle
                  label="Firmographic (Only applicable for xlsx)"
                  onChange={() => {
                    props.selSCToggles(actionTypes.SELSCFIRMOGRAPHICTOGGLE);
                  }}
                  checked={props.sponsoredContentFilterData.firmographicToggle}
                />
                {props.sponsoredContentFilterData.firmographicToggle && (
                  <FirmographicFilter
                    rowGap="0px"
                    firmogaphicFilterData={firmogaphicFilterData}
                    onSearchChange={onSearchChangeFirmographic}
                    onChange={onSelectFirmographic}
                    onClick={onClickFirmographic}
                  />
                )}
              </div>
              <br />
              <Toggle
                label="Persona"
                onChange={() => {
                  props.selSCToggles(actionTypes.SELSCPERSONATOGGLE);
                }}
                checked={props.sponsoredContentFilterData.personaToggle}
              />
              {props.sponsoredContentFilterData.personaToggle && (
                <PersonaFilter
                  personaSenority={personaSeniority}
                  personaJobFunction={personaJobFunction}
                  personaJobTitleIn={personaJobTitleIn}
                  personaJobTitleEx={personaJobTitleEx}
                  labelJobTitleIn="Job Title (Include)"
                  labelJobTitleEx="Job Title (Exclude)"
                />
              )}
              <Form.Group widths="equal" style={{ marginTop: "10px" }}>
                <CompetitorFilterToggleForOthers
                  filterData={props.sponsoredContentFilterData}
                  action={actionTypes.SELECTED_SPONSORED_TOGGLE}
                />
                <Toggle
                  label={EXCLUDE_PRE_PUBLISH_PAGEVIEWS}
                  onChange={() => props.selectValue({ dispatchType: actionTypes.SELECTED_SPONSORED_TOGGLE, key: "excludePrePublishedViews", value: !props.sponsoredContentFilterData.excludePrePublishedViews })}
                  checked={props.sponsoredContentFilterData.excludePrePublishedViews}
                />
                </Form.Group>
              <Form.Group widths="equal">
              <Form className="search-filter-column-1"></Form>
                <Form className="search-filter-column-2">
                    <div className="ad-toggle-label">
                      <p>Campaign Filters</p>
                    </div>
                    <Toggle
                      className="ad-child-toggle"
                      label="Exclude QA Campaign"
                      onChange={() => {
                        props.selSCToggles(actionTypes.SELSCQATOGGLE);
                      }}
                      checked={props.sponsoredContentFilterData.excludeQACampaign}
                    />
                    <Toggle
                      className="ad-child-toggle"
                      label="Exclude Internal Campaign"
                      onChange={() => {
                        props.selSCToggles(actionTypes.SELSCINTOGGLE);
                      }}
                      checked={props.sponsoredContentFilterData.excludeInternalCampaign}
                    />
                  </Form>
              </Form.Group>
            </div>
          </Form>
          <div className="filter-buttons">
            <button className="cancel-date-button extra-space" onClick={clearFilters}>
              Clear Filters
            </button>
            <button
              type="submit"
              className="apply-filter-button"
              onClick={() => {
                applyFilters(
                  props.sponsoredContentFilterData.startDate,
                  props.sponsoredContentFilterData.endDate,
                  checkSelectState
                );
              }}
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sponsoredContentFilterData: state.sponsoredContentFilterData,
    campaigns: Object.values(state.sponsoredContentCampaigns),
    organizations: Object.values(state.sponsoredContentOrganizations),
    order: Object.values(state.sponsoredContentOrder),
    author: Object.values(state.sponsoredContentAuthor),
    sites: Object.values(state.sites),
    topicTagsIn: Object.values(state.sponsoredContentTopicTagsIn),
    topicTagsEx: Object.values(state.sponsoredContentTopicTagsEx),
    placementTagsIn: Object.values(state.sponsoredContentPlacementTagsIn),
    placementTagsEx: Object.values(state.sponsoredContentPlacementTagsEx),
    orgTagsIn: Object.values(state.sponsoredContentOrgTagsIn),
    orgTagsEx: Object.values(state.sponsoredContentOrgTagsEx),
    countries: Object.values(state.sponsoredContentCountry),
    sponsoredContentEnagedOrgs: Object.values(state.sponsoredContentEngOrg),
    orgTypes: Object.values(state.orgTypesList),
    firmographicOrgsType: Object.values(
      state.sponsoredContentFirmographicOrgsType
    ),
    orgSize: Object.values(state.orgSizeList),
    firmographicOrgSize: Object.values(
      state.sponsoredContentFirmographicOrgsSize
    ),
    orgRevenue: Object.values(state.orgRevenueList),
    firmographicOrgsRevenue: Object.values(
      state.sponsoredContentFirmographicOrgsRevenue
    ),
    firmographicSector: Object.values(state.sponsoredContentFirmographicSector),
    sector: Object.values(state.sectorList),
    firmographicIndustryGroup: Object.values(
      state.sponsoredContentFirmographicIndustryGroup
    ),
    industryGroup: Object.values(state.industryGroupList),
    firmographicIndustry: Object.values(
      state.sponsoredContentFirmographicIndustry
    ),
    industry: Object.values(state.industryList),
    firmographicSubIndustry: Object.values(
      state.sponsoredContentFirmographicSubIndustry
    ),
    subIndustry: Object.values(state.subIndustryList),
    seniorities: Object.values(state.seniorities),
    senioritiesIn: Object.values(state.sponsoredContentSenioritiesIn),
    senioritiesEx: Object.values(state.sponsoredContentSenioritiesEx),
    jobfunctions: Object.values(state.jobfunctions),
    JobFunctionsEX: Object.values(state.sponsoredContentJobfunctionsEx),
    JobFunctionsIN: Object.values(state.sponsoredContentJobfunctionsIn),
    urlSource: Object.values(state.ScUrlSource),
    urlMedium: Object.values(state.ScUrlMedium),
    urlCampaigns: Object.values(state.ScUrlCampaigns),
    urlTerm: Object.values(state.ScUrlTerm),
    urlContent: Object.values(state.ScUrlContent),
    pageTitle: Object.values(state.ScPageviewTitle),
    scEngMember: Object.values(state.scEngMember),
    scPrimaryTopic: Object.values(state.scPrimaryTopic),
    isFilApplied: state.sponsoredContentFetchData.isFilApplied,
    scSummary: state.sponsoredContentFetchData.scSummary,
    scCampaignDetail: state.sponsoredContentFetchData.scCampaignDetail,
    scCampaignPageDetail: state.sponsoredContentFetchData.scCampaignPageDetail,
		delivery_states: state.sponsoredContentFilterData.scDeliverdFilterStateOptions,
    csmArray: Object.values(state.spnosoredCSM),
  };
};

export default compose(
  connect(mapStateToProps, {
    selSponsoredContentFilterValue,
    clearSearchOrgs,
    fetchOrganizations,
    fetchCampaigns,
    addOrganization,
    addOrder,
    fetchOrder,
    clearSearchOrder,
    resetOrgsOrCampaigns,
    clearSearchCampaigns,
    addCampaigns,
    clearSponsoredContentSerchedValue,
    fetchPgAuthors,
    fetchTags,
    fetchPgCountry,
    addCountries,
    selSCFilterValue,
    selSCToggles,
    firmographicOrgsTypeCall,
    SenioritiesList,
    fetchPageTitle, 
    fetchUrlParams,
    fetchPrimaryTopic,
    fetchEngagedMember,
    applySponsoredContentFilter,
    fetchPageviewTableData,
    fetchEngagedOrg,
    applySponsoredDateFilterDate,
    updateCommonData,
    selectValue,
  })
)(SearchFilter);
